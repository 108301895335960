import React from 'react'
import classNames from 'classnames'
import './ColumnSelector.css'
import DataTableBoardButton from './DataTableBoardButton'
import WrappedMenu from '../../components/Menu'
import Checkbox from './CheckBox'
import {EyeOff} from 'react-feather'
import PropTypes from 'prop-types'

const ColumnSelector = ({ opened, open, options, changeColumnSelection, t }) => {
    return (
        <div
            className={classNames('dropdown', {
                open: opened
            })}
        >
            <DataTableBoardButton
                onClick={event => {
                    open({ opened: !opened })
                    event.stopPropagation()
                }}
                title={t('hideColumns')}
            >
                <EyeOff className="DataTable-Board-icon" color="#e94d24de"/>
            </DataTableBoardButton>
            <WrappedMenu
                opened={opened}
                position="right"
                onClickOutside={() => open({ opened: false })}
                outsideClickIgnoreClass={'ColumnSelector-Button'}
            >
                <ul
                    className="Navbar-Menu-list"
                    aria-labelledby="dropdownMenuFlag"
                >
                    {options.map(option => (
                        <li
                            key={option.key}
                            className="Navbar-Menu-item"
                            onClick={event => {
                                event.stopPropagation()
                                changeColumnSelection({...option, selected: !option.selected})
                            }}
                        >
                            <Checkbox
                                selected={option.selected}
                                label={t(option.label)}
                            />
                        </li>
                    ))}
                </ul>
            </WrappedMenu>
        </div>
    )
}

ColumnSelector.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            selected: PropTypes.bool.isRequired
        })
    ).isRequired,
    changeColumnSelection: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired
}

export default ColumnSelector
