export const modules = [
    {
        object: 'Media',
        name: 'Media-Admin',
        category: {
            path: 'media',
            icon: 'media'
        },
        viewMap: {
            dt: [
                'category',
                'name',
                {path: 'commentsNumber', tKey: 'comments', tooltip: true}
            ],
            form: [
                'category',
                'name',
                {path: 'description', type: 'richTextEditor'},
                'files',
                {path: 'images', type: 'image', fieldPath: ['id', 'name', 'user.name', 'date']},
                {path: 'videos', type: 'video', fieldPath: ['id', 'name', 'user.name', 'date']},
                'comments',
            ],
        },
        filters: [
            'byMediaCategory'
        ],
    },

    {
        object: 'Media',
        category: {
            path: 'media',
            icon: 'media'
        },
        newable: false,
        removable: false,
        viewMap: {
            dt: [
                'category',
                'name',
                {path: 'commentsNumber', tKey: 'comments', tooltip: true}
            ],
            form: [
                {path: 'enhancedDescription', type: 'richTextEditor', editable: false, fullWidth: true},
                {path: 'files', fullWidth: true, editable: false},
                {path: 'images', type: 'image', fieldPath: ['id', 'name', 'user.name', 'date'], editable: false, fullWidth: true},
                {path: 'videos', type: 'video', fieldPath: ['id', 'name', 'user.name', 'date'], editable: false, fullWidth: true},
                {path: 'comments', fullWidth: true},
            ],
        },
        filters: [
            'byMediaCategory'
        ],
    }
]
