const moment = require('moment')
const _ = require('lodash')
const Errors = require("../../utils/Errors").default
const { basicContext } = require("../../utils/contextUtils")

async function findData(context) {

    const exercise = _.get(context, 'data.currentExercise.id')
    let user = _.get(context, 'data.byUser')

    const exerciseQuery = exercise
        ? { exercise: new global.ObjectID(exercise)}
        : {_id: null}

        if(context.clientContext.moduleId === 'm-R-myBinderAccountStatement') {
            try {
                user = await  global.app.R.CUsers.get({kpUser: new global.ObjectID(context.user.id)}, {
                    fieldPath: ['user.id'],
                    group: context.group
                })
            } catch(e) {
                throw new Errors.ValidationError('Il faut être un bénéficiaire pour utiliser ce module')
            }
        }

    const userQuery = user
        ? { user: new global.ObjectID(user.id)}
        : {}

    const benefitContext = {
        ...basicContext(context),
        query: exerciseQuery
    }

    const asContext = {
        ...basicContext(context),
        fieldPath: ['exercise.code', 'benefit.code', 'user.fullName'],
        query: {
            ...exerciseQuery,
            ...userQuery
        }
    }

    const benefits = await global.app.R.Benefit.find(benefitContext)
    const accountStatements = await global.app.R.AccountStatement.find(asContext)

    return accountStatements.map(as => ({
        ...as,
        exercise: as.exercise.code,
        benefit: as.benefit.name,
        beneficiary: as.user.fullName,
        formatedDate: moment(as.date).format('YYYY-MM-DD HH:mm:ss'),
        restByBenefit: benefits.reduce((acc, benefit) => ({
            ...acc,
            [`${benefit.code} (€)`]: as.restByBenefit[benefit.id]
                ? as.restByBenefit[benefit.id]
                : '0.00'
        }), {})
    }))
}

export const entities = [
        {
            name: 'AccountStatement',
            fields: [
                'Exercise',
                { path: 'user', type: 'CUsers', nullable: true },
                'AccountStatementReason',
                'AccountStatementOperation',
                'reference',
                'Benefit',
                { path: 'amount', type: 'financial' },
                { path: 'refund', type: 'financial', nullable: true },
                { path: 'globalRest', type: 'financial', nullable: true },
                'date',
                {
                    path: 'formatedDate',
                    fieldPath: ['date'],
                    f: function() {
                        return moment(this.date).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
            ]
        },
        {
            name: 'CalculatedAccountStatement',
            type: null,
            fields: [
                'exercise',
                'beneficiary',
                'formatedDate',
                'accountStatementReason',
                'accountStatementOperation',
                'reference',
                'benefit',
                'amount',
                'refund',
                'globalRest',
                'restByBenefit'
            ],
            find: function(context, callback) {
                this.prepareContext(context, 'L', (error, context) => {
                    if (error) callback(error)
                    else
                        findData(context)
                            .then(objects => callback(null, objects))
                            .catch(error => callback(error))
                })
            }
        }
    ]

export const myBinderAccountStatementModule = {
    object: 'CalculatedAccountStatement',
    name: 'myBinderAccountStatement',
    tKey: 'mTitle_accountStatement',
    protectedExport: true,
    displayLog: false,
    defaultSortBy: 'formatedDate',
    defaultSortDirection: 'ASC',
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    removable: false,
    viewMap: {
        dt: [
            {
                path: 'exercise',
                initiallyNotVisible: true,
                disableSort: true
            },
            {
                path: 'beneficiary',
                initiallyNotVisible: true,
                disableSort: true
            },
            { path: 'formatedDate', tKey: 'date', width: 400, disableSort: true },
            { path: 'accountStatementReason', tKey: 'reason', display: 'name' , translate: true, disableSort: true },
            { path: 'accountStatementOperation', display: 'name', tKey: 'posting',  translate: true, initiallyNotVisible: true, disableSort: true },
            { path: 'reference', tKey: 'number2', width: 400, disableSort: true },
            { path: 'benefit', width: 400, disableSort: true },
            {
                path: 'amount',
                tKey: 'amount(€)_abbreviation',
                tooltip: true,
                disableSort: true
            },
            { path: 'refund', tKey: 'consumptionAbbreviated', tooltip: true, disableSort: true },
            { path: 'globalRest', tKey: 'remainingAmount', disableSort: true},
            { path: 'restByBenefit', dynamic: true, disableColumnsSort: true, disableSort: true }
        ]
    },
    filters: [
        {
            path: 'currentExercise',
            tKey: 'exercise',
            object: 'Exercise',
            display: 'code',
            client: true,
            clearable: false,
            sorters:['byCode'],
            sortList: false
        }
    ]
}
export const refundAccountStatementModule = {
    object: 'CalculatedAccountStatement',
    name: 'refundAccountStatement',
    tKey: 'mTitle_accountStatement',
    displayLog: false,
    protectedExport: true,
    defaultSortBy: 'formatedDate',
    defaultSortDirection: 'ASC',
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    removable: false,
    viewMap: {
        dt: [
            {
                path: 'exercise',
                initiallyNotVisible: true,
                disableSort: true
            },
            {
                path: 'beneficiary',
                initiallyNotVisible: true,
                disableSort: true
            },
            { path: 'formatedDate', tKey: 'date', width: 500, disableSort: true },
            { path: 'accountStatementReason', tKey: 'reason', display: 'name' , translate: true, disableSort: true },
            { path: 'accountStatementOperation', display: 'name', tKey: 'posting',  translate: true, initiallyNotVisible: true, disableSort: true },
            { path: 'reference', tKey: 'number2', width: 400, disableSort: true  },
            { path: 'benefit', width: 400, disableSort: true },
            {
                path: 'amount',
                tKey: 'amount(€)_abbreviation',
                tooltip: true,
                disableSort: true
            },
            { path: 'refund', tKey: 'consumptionAbbreviated', tooltip: true, disableSort: true },
            { path: 'globalRest', tKey: 'remainingAmount', disableSort: true},
            { path: 'restByBenefit', dynamic: true, disableColumnsSort: true, disableSort: true }
        ]
    },
    filters: [
        {
            path: 'currentExercise',
            object: 'Exercise',
            tKey: 'exercise',
            display: 'code',
            client: true,
            clearable: false,
            sorters:['byCode'],
            sortList: false
        },
        {
            path: 'byUser',
            tKey: 'user',
            object: 'CUsers',
            display: 'fullName',
            client: true,
            clearable: false
        }
    ]
}
