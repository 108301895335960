const _ = require('lodash')
const moment = require('moment')
const { getData, getCellType } = require("../../../utils/exportState")

const {
    calculateEmpiricAccountModels
} = require('../engines/empiricAccountModelEngine')
const { basicContext } = require('../../../utils/contextUtils')
const { formatNumber, rowStyle } = require('../engines/functions')

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

export async function generateEmpiricFolder(folderExport, context) {
    try {
        console.log('--> Empiric Folder')

        const shopBPEndorsed = await global.app.B.ShopBPEndorsed.get(
            {_id: new global.ObjectID(folderExport.shopBPEndorsed.id)},
            {
                ...basicContext(context),
                fieldPath: [
                    'id', 'fullInformation', 'realOpening', 'bPEndorsed',
                    'bPEndorsed.bPProjectType',
                ]
            }
        )

        const userStateModelAssignment = await global.app.B.StateModelAssignment.find({
            ...basicContext(context),
            fieldPath: ['stateModels.id'],
            query: {
                user: new global.ObjectID(_.get(context, 'user.id'))
            }
        })
        const asignedStateModelIds = userStateModelAssignment[0]
            ? userStateModelAssignment[0].stateModels.map(sm => new global.ObjectID(sm.id))
            : []

        const folders = asignedStateModelIds.length
            ? await global.app.B.ModelFolder.find({
                ...basicContext(context),
                fieldPath: [
                    'bPProjectType',
                    'modelFolderType.id',
                    'projectContextM1M2.id',
                    'projectContextM1M2.name',
                    'stateModel.name',
                    'stateModel.yearOfStart.value',
                    'stateModel.yearOfEnd.value',
                    'stateModel.stateModelLines.order',
                    'stateModel.stateModelLines.modelFormat.id',
                    'stateModel.stateModelLines.modelStyle.id',
                    'stateModel.stateModelLines.accountModel.id',
                    'stateModel.stateModelLines.accountModel.code',
                    'stateModel.stateModelLines.accountModel.formula',
                    'stateModel.stateModelLines.accountModel.specificS2S1',
                    'stateModel.stateModelLines.accountModel.accountModelType.id',
                    'stateModel.stateModelLines.accountModel.dataType.id',
                    'stateModel.stateModelLines.accountModel.dataType.format',
                    'stateModel.stateModelLines.accountModel.dataType.precision',
                    'order',
                    'suffix',
                    'customStateName'
                ],
                query: {
                    modelFolderType: 'empiric',
                    bPProjectType: _.get(shopBPEndorsed, 'bPEndorsed.bPProjectType.id'),
                    stateModel: {$in: asignedStateModelIds}
                }
            })
            : []

        // if(folders.length === 0) {
        //     throw new Errors.ValidationError('noFolderForContextUserVersion')
        // }

        let foldersResults = []
        const sortedFolders = _.sortBy(folders, 'order')

        await asyncForEach(sortedFolders, async folder => {

            const realDate = moment(_.get(shopBPEndorsed, 'realOpening'), 'YYYY-MM-DD')

            const beginYear = realDate.year() - 1 + _.get(folder.stateModel, 'yearOfStart.value')
            const calculationYears = _.range(
                beginYear,
                realDate.year() + _.get(folder.stateModel, 'yearOfEnd.value')
            )

            const resultAccountModels = await calculateEmpiricAccountModels(
                shopBPEndorsed,
                calculationYears,
                folder.stateModel.stateModelLines
                    .map(sml => ({
                        accountModelCode: _.get(sml, 'accountModel.code'),
                        accountModelType: _.get(sml, 'accountModel.accountModelType.id')
                    })),
                context
            )


            foldersResults.push({
                folder,
                dataFolder: _.sortBy(folder.stateModel.stateModelLines, 'order')
                    .map(stateModelLine => {

                        const accountModelUnit = _.get(stateModelLine, 'accountModel.dataType.format.id')
                        const accountModelPrecision = _.get(stateModelLine, 'accountModel.dataType.precision.id')
                        const accountModelType = _.get(stateModelLine, 'accountModel.accountModelType.id')

                        return {
                            id: stateModelLine.id,
                            readOnly: true,
                            name: {
                                data: context.translateName(stateModelLine.wording),
                                style: {textAlign: 'left'}
                            },
                            order: stateModelLine.order,
                            style: rowStyle(stateModelLine.modelStyle.id),
                            value: accountModelType === 'value'
                                ? formatNumber(
                                    resultAccountModels[stateModelLine.accountModel.code],
                                    accountModelUnit,
                                    accountModelPrecision
                                )
                                : '',
                            years: accountModelType === 'year'
                                ? calculationYears.reduce((acc, year) => {
                                    return {
                                        ...acc,
                                        [year]: {
                                            data: formatNumber(
                                                resultAccountModels[
                                                    stateModelLine.accountModel.code
                                                    ][year],
                                                accountModelUnit,
                                                accountModelPrecision
                                            ),
                                            style: {textAlign: 'center'}
                                        }
                                    }
                                }, {})
                                : {},
                        }
                    })
            })
        })

        const shopBPEndorsedFullInformation = `${folderExport.shopBPEndorsed.shop.code} / ${folderExport.shopBPEndorsed.bPEndorsed.code} / ${moment(
            folderExport.shopBPEndorsed.bPEndorsed.endorsedOpening
        ).year()} / ${moment(folderExport.shopBPEndorsed.realOpening).year()}`

        const filename = `${shopBPEndorsedFullInformation}_Empiric.xlsx`

        const file = await generateExcel(filename, foldersResults, context)

        const empiricFolderExportCollection = global.app.B.EmpiricFolderExport.collection
        await empiricFolderExportCollection.updateOne(
            { _id: new global.ObjectID(folderExport.id)},
            { $set: { file: {
                        ...file,
                        id: new global.ObjectID(file.id),
                        user: _.get(context, 'user.name', 'unknown user'),
                        date: moment().format('YYYY-MM-DD HH:mm')
                    }}
            }
        )

        return {
           ...folderExport,
            date: moment(folderExport.date).format('YYYY-MM-DD HH:mm'),
            shopBPEndorsed: {
                fullInformation: shopBPEndorsedFullInformation
            },
            file: {
                ...file,
                user: _.get(context, 'user.name', 'unknown user'),
                date: moment().format('YYYY-MM-DD HH:mm')
            }
        }

    } catch (e) {
        throw(e)
    }
}

async function generateExcel(filename, foldersResults, context){
    return new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(foldersResults, context),
            filename,
            (err, file) => {
                if (err) reject(err)
                else resolve(file)
        })
    })
}

const configureWorkbook = (foldersResults, context) => workbook => {
    workbook.creator = 'Keenpoint'
    workbook.lastModifiedBy = 'Keenpoint'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]
    const firstFolder = foldersResults.length
        ? _.first(foldersResults)
        : {folder: {customStateName: false, suffix: ''}}

    const stateModelName = firstFolder.folder.customStateName
        ? context.translateName(firstFolder.folder.stateModel.name)
        : ''

    let dataSheet = workbook.addWorksheet( stateModelName + firstFolder.folder.suffix)
    let row = 1

    foldersResults.forEach(result => {
        let cell = {}
        let styles = {}
        result.dataFolder.forEach(result => {
            const lineStyle = _.pick(result, 'style')
            let col = 1
            cell = dataSheet.getRow(row).getCell(col)
            cell.value = result.name.data
            styles = getStyle(lineStyle, result.name)
            Object.keys(styles).forEach(key => {
                cell[key] = styles[key]
            })
            col++
            cell = dataSheet.getRow(row).getCell(col)
            cell.value = getData(result.value)
            const cellType = getCellType(result.value)
            if(cellType === 'percentage') {
                cell.numFmt = '0.00%'
            }
            styles = getStyle(lineStyle, getData(result.value))
            Object.keys(styles).forEach(key => {
                cell[key] = styles[key]
            })
            col++
            Object.keys(result.years).forEach(year => {
                cell = dataSheet.getRow(row).getCell(col)
                cell.value = getData(result.years[year])
                const cellType = getCellType(result.years[year])
                if(cellType === 'percentage') {
                    cell.numFmt = '0.00%'
                }
                styles = getStyle(lineStyle, getData(result.years[year]))
                Object.keys(styles).forEach(key => {
                    cell[key] = styles[key]
                })
                col++
            })
            row++
        })

        row += 2
    })

    return workbook
}

const getStyle = (line, cellData) => {
    let style = {}
    if(line.style.backgroundColor) {
        style.fill = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: line.style.backgroundColor.slice(-6)},
            fgColor: { argb: line.style.backgroundColor.slice(-6)}
        }
    }

    if(line.style.borderTop || line.style.borderBottom) {
        const bottomBorderStyle = line.style.borderBottom ? {bottom: {style:'thin'}} : {}
        const topBorderStyle = line.style.borderTop ? {top: {style:'thin'}} : {}
        style.border = {
            ...topBorderStyle,
            ...bottomBorderStyle
        }
    }
    const fontStyle = {}
    if(line.style) {
        if(line.style.fontWeight === 'bold') fontStyle.bold = true
        if(line.style.fontStyle === 'italic') fontStyle.italic = true
        if(line.style.fontSize) fontStyle.size = Number.parseInt(line.style.fontSize.slice(0, -2), 10)
        if(line.style.color) fontStyle.color = { argb: line.style.color.slice(-6) }
    }
    if(cellData && cellData.style) {
        if(cellData.style.fontWeight === 'bold') fontStyle.bold = true
        if(cellData.style.fontStyle === 'italic') fontStyle.italic = true
        if(cellData.style.fontSize) fontStyle.size = Number.parseInt(cellData.style.fontSize.slice(0, -2), 10)
        if(cellData.style.color) fontStyle.color = { argb: cellData.style.color.slice(-6) }

        if(cellData.style.backgroundColor) {
            style.fill = {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: cellData.style.backgroundColor.slice(-6)},
                fgColor: { argb: cellData.style.backgroundColor.slice(-6)}
            }
        }
    }
    style.font = fontStyle

    return style
}
