import _ from "lodash";
import {returnButton, saveButton} from "../utils";

export const preValidateButton = {
    action: 'preValidate',
    type: 'action',
    nextStatus: 'preValidated',
    tKey: 'Valider',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'preValidationConfirmationMessage',
};
export const updateButton = (nextStatus, tKey = 'JE METS À JOUR MON DOSSIER ') => ({
    action: 'update',
    type: 'action',
    nextStatus,
    tKey,
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'updateConfirmationMessage',
})

export const adjournButton = (nextStatus) => ({
    action: 'adjourn',
    type: 'action',
    nextStatus,
    tKey: 'Demander compléments',
    bsStyle: 'warning',
    getUserConfirmation: true,
    confirmationMessage: 'adjournmentConfirmationMessage',
})

export const validateTechButton = {
    action: 'validateTech',
    type: 'action',
    nextStatus: 'completed',
    tKey: 'Valider',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'visitLaunchConfirmationMessage',
};

export const launchVisitButton = {
    action: 'launchVisit',
    type: 'action',
    nextStatus: 'visitInProgress',
    tKey: 'Demander la visite',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'visitLaunchConfirmationMessage',
};

export const validateVisitButton = {
    action: 'validateVisit',
    type: 'action',
    nextStatus: 'visitCompleted',
    tKey: 'Valider la visite',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'acceptanceConfirmationMessage',
};
export const acceptButton = {
    action: 'accept',
    type: 'action',
    nextStatus: 'accepted',
    tKey: 'Accepter',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'acceptanceConfirmationMessage',
};

export const validateButton = {
    action: 'validate',
    type: 'action',
    nextStatus: 'validated',
    tKey: 'Valider',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
};

export const rejectButton = {
    action: 'reject',
    type: 'action',
    nextStatus: 'rejected',
    tKey: 'Refuser',
    bsStyle: 'danger',
    bypassFormValidation: true,
    getUserConfirmation: true,
    confirmationMessage: 'rejectionConfirmationMessage',
}

export const getButtonsForModule = (application, context) => {
    const statusId = _.get(application, 'status.id');
    const { moduleId } = context.clientContext;
    let buttons = []

    switch (moduleId) {
        case 'm-C-application':
            if (['filed', 'updated'].includes(statusId)) {
                buttons = [preValidateButton, adjournButton('incomplete'), rejectButton]
            }

            if(['filed2', 'updated2'].includes(statusId)) {
                buttons = [validateTechButton, adjournButton('adjourned')]
            }
            if(['completed'].includes(statusId)) {
                buttons = [launchVisitButton]
            }
            if(statusId === 'visitInProgress') {
                buttons = [validateVisitButton]
            }
            if(statusId === 'visitCompleted') {
                buttons = [acceptButton, adjournButton('adjournedBD'), rejectButton]
            }
            if(statusId === 'accepted') {
                buttons = [validateButton]
            }
            break
        case 'm-C-file':
            if (['preValidated', 'incomplete', 'adjourned', 'adjournedBD'].includes(statusId)) {
                let nextStatus = ''
                let buttonTKey = ''
                switch (statusId) {
                    case 'incomplete':
                        nextStatus = 'updated'
                        buttonTKey = 'JE TRANSMETS LE VOLET ADMINISTRATIF'
                        break
                    case 'adjourned':
                    case 'adjournedBD':
                        nextStatus = 'updated2'
                        buttonTKey = 'JE TRANSMETS LE VOLET TECHNIQUE'
                        break
                    case 'preValidated':
                        nextStatus = 'filed2'
                        buttonTKey = 'JE TRANSMETS LE VOLET TECHNIQUE'
                        break
                }

                buttons = [updateButton(nextStatus, buttonTKey), saveButton]
            }
            break
    }

    buttons.push(returnButton)

    return buttons
}
