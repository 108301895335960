const _ = require('lodash')

async function findData(context) {
    const language = _.get(context, 'language.id')
    return [
        {
            id: 'burnoutVideo',
            link: `${language}_burnoutVideo.mp4`,
            poster: `${language}_burnoutVideo.png`
        }
    ]
}
export const entity = {
    name: 'BurnoutVideo',
    dashboard: true,
    fields: ['id', 'link', 'poster'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
