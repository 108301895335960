export const dependencies =[
    {
        name: 'ExerciseStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'provisionalClosure', name: 'provisionalClosure' },
            { id: 'finalClosure', name: 'finalClosure' }
        ]
    },
    {
        name: 'UserStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'salaried', name: 'salaried' },
            { id: 'retired', name: 'retired' },
            { id: 'external', name: 'external'}
        ],
        filters: [
            {
                name: 'notExternal',
                isDefault: false,
                match: object => object.id !== 'external'
            }
        ]
    },
    {
        name: 'Civility',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'm', name: 'monsieur' },
            { id: 'mme', name: 'madame' },
            { id: 'mlle', name: 'mademoiselle' }
        ]
    },
    {
        name: 'Kinship',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'child', name: 'child' },
            { id: 'spouse', name: 'spouse' },
        ]
    },
    {
        name: 'Authorization',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '000001', name: 'activeUser' },
            { id: '000002', name: 'refundController' },
            { id: '000003', name: 'refundValidator' },
            { id: '000004', name: 'invoiceOperator' },
            { id: '000005', name: 'invoiceValidator' },
            { id: '000006', name: 'administrator' },
            { id: '000007', name: 'administrator+' },
            { id: '000008', name: 'accountant' },
            { id: '000009', name: 'transferBatchesManager' },
            { id: '000010', name: 'inactiveUser' }
        ]
    },
    {
        name: 'Action',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'validate', name: 'valider' },
            { id: 'refuse', name: 'refuser' },
            { id: 'rectify', name: 'à corriger' },
            { id: 'justify', name: 'à justifier' }
        ],
        filters: [
            {
                name: 'controlActions',
                isDefault: false,
                match: object => {
                    return ['validate', 'refuse', 'rectify'].includes(object.id)
                }
            },
            {
                name: 'validationActions',
                isDefault: false,
                match: object => {
                    return ['validate', 'refuse', 'justify'].includes(object.id)
                }
            }
        ]
    },
    {
        name: 'BenefitStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'En attente' },
            { id: 'validated', name: 'Validée' },
            { id: 'closed', name: 'Fermée' }
        ]
    },
    {
        name: 'RefundStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'validated', name: 'validated' },
            { id: 'refused', name: 'refused'},
            { id: 'controlled', name: 'controlled' },
            { id: 'rectification', name: 'rectification' },
            { id: 'justification', name: 'justification' },
            { id: 'paid', name: 'paid' },
            { id: 'waiting', name: 'waiting' },
            { id: 'actualized', name: 'actualized' },
            { id: 'reallocated', name: 'reallocated' },

        ]
    },
    {
        name: 'InvoiceStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'controlled', name: 'controlled' },
            { id: 'validated', name: 'validated' },
            { id: 'refused', name: 'refused'},
            { id: 'justification', name: 'justification' },
            { id: 'paid', name: 'paid' },
            { id: 'waiting', name: 'waiting' },
            { id: 'actualized', name: 'actualized' }
        ]
    },
    {
        name: 'BatchStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'waiting', name: 'waiting' },
            { id: 'validated', name: 'validated' },
            { id: 'paid', name: 'paid' },
            { id: 'toPay', name: 'toPay' },
            { id: 'actualized', name: 'actualized' }
        ]
    },
    {
        name: 'PaymentStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'controlled', name: 'controlled' },
            { id: 'paid', name: 'paid' }
        ]
    },
    {
        name: 'BenefitRecipient',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'salaried', name: 'salaried' },
            { id: 'retired', name: 'retired' },
            { id: 'rightHolder', name: 'rightHolder' }
        ]
    },
    {
        name: 'Journal',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0000001', name: 'refund' },
            { id: '0000002', name: 'purchase' },
            { id: '0000003', name: 'sale' },
            { id: '0000004', name: 'variousOperation' }
        ]
    },
    {
        name: 'SupplierJournal',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0000001', name: 'bank' },
            { id: '0000002', name: 'sale' },
            { id: '0000003', name: 'purchase' }
        ]
    },
    {
        name: 'Process',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'refund', name: 'refundRequest' },
            { id: 'invoice', name: 'supplierInvoice' },
        ]
    },
    {
        name: 'Global',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'exercise', 'code', 'name'],
        objects: [
            { id: 'global', code: 'global',  name: 'global', exercise: ''},
        ]
    },
    {
        name: 'AccountStatementReason',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'submitted', name: 'submitted' },
            { id: 'refused', name: 'refused' },
            { id: 'toRectify ', name: 'toRectify' },
        ]
    },
    {
        name: 'AccountStatementOperation',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'debit', name: 'debit' },
            { id: 'credit', name: 'credit' },
        ]
    },
    {
        name: 'ReportType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'consumptions', name: 'consumptions' },
            { id: 'taxReturnDocument', name: 'reimbursementCertificate' },
        ]
    },
    {
        name: 'Function',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'secretary', name: 'secretary' },
            { id: 'managementAssistant', name: 'managementAssistant' },
        ]
    },
    {
        name: 'CampaignType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'giftVoucher', name: 'giftVoucher' },
            { id: 'cultureVoucher', name: 'cultureVoucher' },
            { id: 'giftVoucher&CultureVoucher', name: 'giftVoucher&CultureVoucher' },
        ]
    },
    {
        name: 'DocumentVersion',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'simulation', name: 'simulation' },
            { id: 'distribution', name: 'distribution' }
        ]
    }
]
