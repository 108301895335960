import {decrypt} from "../../../utils/crypto";

const _ = require('lodash')
const moment = require('moment/moment')
const { invoiceTemplatesDirectoryPath } = require('./utils')
const { basicContext } = require("../../../utils/contextUtils");

const getQueryFromStatus = object => {
    switch (object.status) {
        case 'controlled':
            return {'authorizations.code': '000005'}
        case 'justification':
        case 'validated':
        case 'refused':
            return {_id: new global.ObjectID(object.user.id)}
        default:
            return {_id: null}
    }
}

export async function workflowMailing (invoiceObject, context) {

    const cUsers = await global.app.R.CUsers.collection
        .aggregate([
            { '$lookup': {
                    'from': 'profile',
                    'let': { 'authId': '$authorizations' },
                    'pipeline': [
                        { '$match': { '$expr': { '$in': ['$_id', '$$authId'] } } }
                    ],
                    'as':'authorizations'
                }
            },
            {
                $match: getQueryFromStatus(invoiceObject)
            },
        ])
        .toArray()

    if (cUsers.length !== 0 && invoiceObject.status !== 'draft') {

        const attachments = invoiceObject.files.map(file => {
            return {
                id: new global.ObjectID(file.id),
                filename: file.filename
            }
        })

        sendMail({
            users: cUsers,
            translate: context.tl,
            status: invoiceObject.status,
            object: invoiceObject,
            template: `${invoiceObject.status}Invoice.html`,
            url: global.isProd
                ? `https://${context.host}/business/${context.groupModel.id}`
                : `http://localhost:3000/business/${context.groupModel.id}`,
            attachments
        })
    }
}

const sendMail = ({ users, translate, status, object, template, url, attachments }) => {
    const logMessage = () => {
        console.log('...Mailing')
    }
    users.forEach(user => {
        const language = user.language || 'fr'
        const mail = _.defaults(
            {
                to: user.mail && decrypt(user.mail),
                context: {
                    user: user,
                    object,
                    invoiceDate: moment(object.invoiceDate).format('YYYY/MM/DD'),
                    deadline: moment(object.deadline).format('YYYY/MM/DD'),
                    comment: object.lastComment,
                    url
                },
                content: template,
                subject: {
                    template: `${translate('supplierInvoice', language)} ${object.wording} (${translate(status, language)})`
                },
                templateDir: invoiceTemplatesDirectoryPath(language),
                attachments
            },
            defaultMail
        )

        global.mailer.sendMail(mail, logMessage)
    })
}

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: 'assistance@mycsemanco.com',
    verbose: {
        general: true
    }
}
