export const entity = {
    name: "Zone",
    facets: [
        {name:"codeName", codeUnique: true}
    ]
}

export const module_ = {
    object: "Zone",
    tKey: "mTitle_zone",
    newable: false,
    removable: false,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            "code",
            "name"
        ],
        form: [
            {path: "code", required: true, editable: false},
            {path: "name", required: true}
        ]
    }
}
