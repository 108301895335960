import withQuery from 'with-query';
import download from "downloadjs"

async function sendResponse(response, responseType, params) {
    if (!response) {
        return {
            ok: false,
            message: "No response"
        }
    } else if (!response.ok) {
        const text = await response.text()

        return {
            ok: false,
            message: text || response.statusText,
            status: response.status
        }
    } else {

        const responseObject = {
            ok: true,
            status: response.status
        }

        switch (responseType) {
            case('stream'):
                response.blob().then(
                    blob => download(blob, params.context.fileName)
                )
                break
            default:
                responseObject.json = await response.json()
        }
        return responseObject
    }
}

async function externalFetchWithQueryString(method, url, responseType, params, headers) {
    const response = await fetch(withQuery(url, params), {
        method,
        headers
    })

    return await sendResponse(response, responseType, params)
}

export async function fetchWithQueryString(method, url, responseType, params = {}) {
    const response = await fetch(withQuery(window.location.origin + url, params), {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true
        },
        method,
        credentials: 'same-origin'
    })

    return await sendResponse(response, responseType, params)
}

async function externalFetchWithJsonBody(method, url, responseType, params = {}, headers) {
    const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(params)
    })

    return await sendResponse(response, responseType, params)
}

async function fetchWithJsonBody(method, url, responseType, params = {}) {
    const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
        credentials: 'same-origin'
    })

    return await sendResponse(response, responseType, params)
}

/*
* Fetches data with default configuration
* Returns a Promise that resolves to an object with the shape
*  - ok: was the request successful
*  - status: the HTTP status of the response (if exists)
*  - message: the error message (if error)
*  - json: the data response (if not error)
* */
export const kpFetch = (method, url, responseType, params) => {
    switch (method) {
        case 'GET':
            return fetchWithQueryString(method, url, responseType, params)
        case 'PUT':
        case 'POST':
        case 'DELETE':
            return fetchWithJsonBody(method, url, responseType, params)
        default:
            return Promise.reject(new Error(`Unexpected HTTP method: ${method}`))
    }
}

export const externalFetch = (method, url, params = {}, headers = {}) => {
    if (method === 'GET') {
        return externalFetchWithQueryString(method, url, '', params, headers)
    } else if (method === 'POST') {
        return externalFetchWithJsonBody(method, url, '', params, headers)
    } else {
        return Promise.reject(new Error(`Unexpected HTTP method: ${method}`))
    }
}
