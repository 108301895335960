const {fieldPathJoinGetter} = require('../../../utils/kp3Utils')

export const entity = {
    name: 'AccountsGroup',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"],
            joinString: " - "
        })
    ],
    filters: [
        {
            name: "hasTemplate",
            async: true,
            isDefault: false,
            match: function (object, context, callback) {
                const options = {
                    user: context.user,
                    group: context.group,
                    query: {
                        accountsGroup: new global.ObjectID(object.id)
                    }
                }
                global.app.C.AccountsTemplate.find(options, (e, objects) => {
                    if(e) callback(e)
                    callback(null, objects && objects.length > 0 )
                })
            }
        },
    ]
}

export const module_ = {
    object: 'AccountsGroup',
    tKey: 'mTitle_accountsGroup',
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'accounting',
        icon: 'book'
    },
    viewMap: {
        dt: ['code', 'name'],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
        ]
    }
}
