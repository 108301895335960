import _ from "lodash";
import moment from "moment";
import {basicContext} from "../../utils/contextUtils";

export const entities = [
    {
        name: "Status",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Actif"},
            {id:'2', name: "Inactif"},
        ]
    },
    {
        name: "Nature",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "référence"},
            {id:'2', name: "étrangère"},
        ]
    },
    {
        name: 'FiscalYearStatus',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            'name'
        ],
        objects: [
            { id: '1', name: 'en cours' },
            { id: '2', name: 'clôturé' },
            { id: '3', name: 'ouverture provisoire' }
        ]
    },
    {
        name: "Mesh",
        type: "static",
        fields: [
            //"mongoOperator",
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'000000000000000000000001', name: "business unit"},  //entités
            {id:'000000000000000000000002', name: "Groupe"},
        ]
    },
    {
        name: "DemandStatus",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'1', name: "draft", name2: "Brouillon" },
            {id:'2', name: "control", name2: "Contrôle"},
            {id:'3', name: "validation", name2: "Validation"},
            {id:'4', name: 'information request', name2: `Demande d'information`},
            {id:'5', name: "refused", name2: "Refusées"},
            {id:'6', name: "approved", name2: "Validées"},
            {id:'7', name: "realization", name2: "Réalisation"},
            {id:'8', name: "closed", name2: "Terminées"},
            {id:'9', name: "abandoned", name2: "Abandonnées"},
            {id:'10',name: "demand", name2: "Instruction" },
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "preValidation", name2: "Pré-validation"},
            {id:'13', name: "abandonedPostValidation", name2: "Abandonnées après validation"},
        ],
        filters: [
            { //verified
                name: "byProjectType",
                isDefault: false,
                query: function(context) {
                    const projectTypes = _.get(context.data, 'projectTypes')
                    console.log('projectTypeID', projectTypes)
                    const ongoingValidationStatusesIds = ['2', '3', '4', '5', '9', '10', '11']
                    const postValidationStatusesIds = ['6', '7', '8', '9']
                    const allStatuses = [ongoingValidationStatusesIds, postValidationStatusesIds]
                    return !!projectTypes && !!projectTypes.length && projectTypes.length === 1 ?
                        projectTypes[0].id === '2' ? {_id: {$in: ongoingValidationStatusesIds}} : {_id: {$in: postValidationStatusesIds}}
                        : {_id: {$in: allStatuses.flat()}}
                }
            },
        ],
    },
    {
        name: "DemandStep",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'1', name: "demand", name2: "Instruction" },
            {id:'2', name: "control", name2: "Contrôle"},
            {id:'3', name: "validation", name2: "Validation"},
            {id:'4', name: 'informationRequest', name2: `Demande d'information`},
            {id:'5', name: "approved", name2: "Validé"},
            {id:'6', name: "realization", name2: "Réalisation"},
            {id:'7', name: "closed", name2: "Terminé"},
            //{id:'8', name: "my actions", name2: "Mes actions"},
            {id:'9', name: "abandoned", name2: "Abandonné"},
            {id:'10', name: "draft", name2: "Mes brouillons" },
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "refused", name2: "Refusé"},
            {id:'13', name: "preValidation", name2: "Pré-validation"},
        ]
    },
    {
        name: "DemandStep2",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'8', name: "my actions", name2: "Mes actions"},
            {id:'10', name: "draft", name2: "Mes brouillons" },
            {id:'4', name: 'information request', name2: `Demande d'information`},
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "preValidation", name2: "pré-validation"},
        ]
    },
    {
        name: "InstructionModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'instruction', name: "Instruction" }
        ]
    },
    {
        name: "ControlModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'control', name: "control" }
        ]
    },
    {
        name: "ValidationModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'validation', name: "Validation" }
        ]
    },
    {
        name: "RealizationModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'realization', name: "realization" }
        ]
    },
    {
        name: "Category",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "projet"},
            {id:'2', name: "engagement"},
            //{id:'3', name: "3"}
        ]
    },
    {
        name: "ArbitrationType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Arbitrage multiple"},
            {id:'2', name: "Arbitrage final"}
        ]
    },
    {
        name: "DelegationOption",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Sans délégation"},
            {id:'2', name: "Avec délégation"}
        ]
    },
    {
        name: "FiscalYearFilter",
        type: "static",
        fields: [
            {path: "id", type: "integer"},
            {path: "name"}
        ],
        objects: _.range(moment().year() - 3, moment().year() + 4).map(
            year => ({id: year, name: `${year}`})
        )
    },
    {
        name: "BudgetColumnType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Global"},
            {id:'2', name: "Yearly"},
            // names should be unique !!!
        ]
    },
    {
        name: "StackOptions",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name', 'path', {path: 'translateName', type: 'boolean'}
        ],
        objects: [
            //{id:'1', name: "project", path: 'project.demandNumberAndTitle', translateName: false},
            //{id:'2', name: "organizationAndMesh", path: 'project.organizationAndMesh.name', translateName: false},
            {id:'3', name: "entity", path: 'organizationalMesh.nameWithOrganizationalAxis', translateName: false},
            {id:'4', name: "imputationType", path: 'imputationType.name', translateName: true},
            //{id:'5', name: "noStacking", path: '', translateName: false},
            //{id:'6', name: "amountType"}, //displayed amount
            //{id:'7', name: 'status'},
            //{id:'8', name: 'fiscalYear'}
        ]
    },
    {
        name: "AnalysisOption",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
            'path', {path: 'translateName', type: 'boolean'}
        ],
        objects: [
            //{id:'1', name: "temporalMonthly", path: 'month', translateName: false},
            {id:'000000000000000000000001', name: "fiscalYear", path: 'fiscalYear.code', translateName: false},
            //{id:'3', name: "organization", path: 'demand.organizationAndMesh.name', translateName: false},
            //{id:'4', name: "entity", path: 'revisedLine.entity.nameWithOrganizationalAxis', translateName: false},
            {id:'000000000000000000000002', name: "imputationType", path: "imputationType.name", translateName: true},
            {id:'000000000000000000000003', name: "project", path: "demand.title", translateName: false},
            {id:'000000000000000000000004', name: "category", path: "demand.demandNature.name", translateName: true},
        ]
    },
    {
        name: "GraphType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name','tKey'
        ],
        objects: [
            {id:'1', name: "stackedVertically", tKey: 'yes'},
            {id:'2', name: "stackedHorizontally", tKey: 'no'},
        ]
    },
    {
        name: "ColorRange",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: 'colorRange', list: true},
            'name'
        ],
        objects: [
            {id:'1', name: "", colorRange: ['#0076CE', '#E9DCC9']},
            {id:'2', name: "", colorRange: ['#6B8E23', '#E9DCC9']},
            {id:'3', name: "", colorRange: ['#008080', '#E9DCC9']},
            {id:'4', name: "", colorRange: ['#002D72', '#A4DDED']},
            {id:'5', name: "", colorRange: ['#0f91d3', '#F2ABCB']},
        ]
    },
    {
        name: "DisplayedAmount",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name', 'path', 'code'
        ],
        objects: [
            {id:'000000000000000000000007', name: "projectBudgetBud", path: 'budget', code: "bud"},
            {id:'000000000000000000000008', name: "projectEstimatedEst", path: 'estimated', code: "est"},
            {id:'000000000000000000000001', name: "projectValidatedPVal", path: 'validated', code: "pVal"},
            {id:'000000000000000000000009', name: "projectRevisedRev", path: 'revised', code: "rev"},
            {id:'000000000000000000000004', name: "demandValidatedDVal", path: 'demandValidated', code: "dVal"},
            {id:'000000000000000000000005', name: "demandOngoingValidatedDOngoing", path: 'demandOngoingValidated', code: "dOngoing"},
            {id:'000000000000000000000006', name: "demandValidatedAndOngoingValidatedDOngoingAndVal", path: 'demandValidatedAndOngoingValidated', code: "dOngoingAndVal"},
            //{id:'6', name: "realizationTypes", path: 'realizationTypes'},
            {id:'000000000000000000000002', name: "projectOngoingValidatedPOngoing", path: 'ongoingValidated', code: "pOngoing"},
            {id:'000000000000000000000003', name: "projectValidatedAndOngoingValidatedPValAndOngoing", path: 'validatedAndOngoingValidated', code: "pValAndOngoing"},
        ],
        filters: [
            { //verified
                name: "byProjectType",
                isDefault: false,
                query: function(context) {
                    const projectTypes = _.get(context.data, 'projectTypes')
                    console.log('projectTypeID', projectTypes)
                    const ongoingValidationStatusesIds = ['7']
                    const postValidationStatusesIds = ['1', '2', '3', '4', '5', '6']
                    return !!projectTypes && !!projectTypes.length && projectTypes.length === 1 ?
                        projectTypes[0].id === '2' ? {_id: {$in: ongoingValidationStatusesIds}} : {_id: {$in: postValidationStatusesIds}}
                        : {}
                }
            },
        ]
    },
    {
        name: "ProjectType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name'
        ],
        objects: [
            {id:'1', name: "postValidation"},
            {id:'2', name: "ongoingValidation"},
        ],
        filters: [
            { //verified
                name: "byFiscalYear",
                isDefault: false,
                async: true,
                query: async function(context, callback) {
                    const fiscalYears = _.get(context, 'data.fiscalYear', [])
                    const projects = await global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {category: '1'}}
                    )
                    let filteredProjectStatuses = []
                    projects.forEach(project => {
                        const startMonth = project.startMonth
                        const endMonth = project.endMonth
                        const fiscalYearForThisDemand = fiscalYears.filter(fiscalYear => {
                            const fiscalYearStartDate = moment(fiscalYear.fiscalYearRange[0])
                            const fiscalYearEndDate = moment(fiscalYear.fiscalYearRange[1])
                            const firstCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                            const secondCondition = fiscalYearStartDate.isSameOrAfter(startMonth, 'month') && fiscalYearEndDate.isSameOrBefore(endMonth, 'month')
                            const thirdCondition = fiscalYearStartDate.isSameOrBefore(endMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                            const fourthCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(startMonth, 'month')
                            return firstCondition || secondCondition || thirdCondition || fourthCondition
                        })
                        const isInFiscalYears = fiscalYearForThisDemand.map(FY=>global.ObjectID(FY.id).toString())
                            .some(id => fiscalYears.map(fy => global.ObjectID(fy.id).toString()).includes(id))
                        if (isInFiscalYears){
                            filteredProjectStatuses.push(global.ObjectID(project.statut.id))
                        }
                    })
                    const ongoingValidationStatusesIds = ['1', '2', '3', '4', '5', '10', '11', '12']
                    const postValidationStatusesIds = ['6', '7', '8']

                    const isInOngoingValidationStatusesIds = filteredProjectStatuses.some(id=>ongoingValidationStatusesIds.includes(id))
                    const isInPostValidationStatusesIds = filteredProjectStatuses.some(id=>postValidationStatusesIds.includes(id))
                    const isInBoth = isInOngoingValidationStatusesIds && isInPostValidationStatusesIds

                    const query = isInBoth ?
                        {}
                        : isInPostValidationStatusesIds ? {_id: '1'} : {_id: '2'}

                    callback (null, query)
                }
            }
        ]
    },
    {
        name: "HierarchyType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "name"}
        ],
        objects: [
            {id: '1', name: "verticalHierarchy"},
            {id: '2', name: "horizontalHierarchy"},
        ]
    },
    {
        name: 'AxisUtility',
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "name"}
        ],
        objects: [
            {id: '1', name: "functionAxis"},
            {id: '2', name: "workflowAxis"},
            {id: '3', name: "preValidationAxis"},
            {id: '4', name: "budgetAxis"},
            {id: '5', name: "projectAxis"},
        ]
    }
    /*
    {
        name: "PaymentType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "engaged"},
            {id:'2', name: "received"},
            {id:'3', name: "billed"},
        ]
    },

     */
    /*
    {
        name: "revisedTableColumn",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "engaged"},
            {id:'2', name: "received"},
            {id:'3', name: "billed"},
        ]
    },

     */
]
