const { translateName } = require("../../../utils/i18n");
const _ = require("lodash");

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "OrganizationJoin",
    fields: [
        "name",
        {
            path: "organizationAxisJoin",
            lazy: true,
            fieldPath: ["organizationAxis.id", "organizationAxis.name"],
            f: function () {
                return this.joinedEntity === "Store" ? global.app.S.StoreAxis.objects[0] : this.organizationAxis;
            }
        },
        {
            path: "completeName",
            lazy: true,
            fieldPath: ["organizationAxisJoin.name", "name"],
            f: function() {
                return this.organizationAxisJoin ? this.organizationAxisJoin.name + " : " + this.name : this.name;
            }
        },
        {
            path: "codeName",
            lazy: true,
            fieldPath: ["code", "name"],
            $f: (object, context, callback) => {
                const tName = translateName(object.name, _.get(context, "language.id"));
                callback(null, `${object.code} - ${tName}`);
            }
        },

        // Getter to define the category code to match with an habFunction.
        // A positive match define if the link is valid.
        {
            path: "habFunctionCategoryCode",
            lazy: true,
            f: function() {
                return this.joinedEntity === "Store"
                    ? global.app.S.StoreAxis.objects[0].id
                    : this.organizationAxis.toString()
            }
        }
    ]
}, ["Organization", "Store"])
