const _ = require('lodash')
export const entities = [
    {
        name: 'Media',
        facets: ['files', 'comments'],
        fields: [
            {path: 'category', type: 'MediaCategory'},
            {path: 'name', notEmpty: true},
            {path: 'description'},
            {path: 'images', type: 'Image', link: {type: 'MTM', deleteType: 'cascade'}},
            {path: 'videos', type: 'Video', link: {type: 'MTM', deleteType: 'cascade'}},
            {
                path: 'enhancedDescription',
                fieldPath: ['category.name', 'name', 'description'],
                f: function() { return `<strong>${this.category.name} - ${this.name}</strong><br /><br />${this.description}` }
            },
            {
                path: 'commentsNumber',
                f: function() { return this.comments.length }
            },
        ],
        filters: [
            {
                name: 'byMediaCategory',
                path: 'category',
                object: 'MediaCategory',
                display: 'name',
                client: true,
                clearable: true,
                query: function(context) {
                    const mediaCategoryId = _.get(context.data, 'category.id')
                    if(mediaCategoryId)
                        return {'category': mediaCategoryId}
                    else
                        return {}
                }
            },
        ]
    },

    {
        name: 'MediaCategory',
        type: 'static',
        fields: [
            {path: 'id', type: 'string'},
            'name'
        ],
        objects: [
            {id: 'communication', name: 'Communication'},
            {id: 'webinar', name: 'Webinar'},
            {id: 'private', name: 'Private'},
        ]
    },

    {
        name: 'Image',
        fields: [
            {path: 'name', notEmpty: true},
            'User',
            {path: 'date', notEmpty: true}
        ]
    },

    {
        name: 'Video',
        fields: [
            {path: 'name', notEmpty: true},
            'User',
            {path: 'date', notEmpty: true}
        ]
    }
]
