import {
    CREATE_OBJECT_SUCCESS,
    DELETE_OBJECT_FAILURE,
    DELETE_OBJECT_REQUEST,
    DELETE_OBJECT_SUCCESS,
    DT_FAILURE,
    DT_REQUEST,
    DT_SUCCESS,
    SAVE_OBJECT_SUCCESS,
    ACTION_OBJECT_REQUEST,
    ACTION_OBJECT_SUCCESS,
    ACTION_OBJECT_FAILURE
} from '../actions/api'
import { SortDirection } from 'react-virtualized/dist/commonjs/Table'
import _ from 'lodash'
import {
    SET_COLUMN_SELECTOR_OPENED,
    SET_ACTION_SELECTOR_OPENED,
    SET_FILTERS_OPENED,
    CLEAR_ERRORS,
    FILTER_DATA_TABLE,
    INITIALIZE_MODULE,
    SORT_DATA_TABLE,
    TOGGLE_COLUMN_VISIBILITY,
    SET_LIST_ELEMENT_SELECTION
} from '../actions'

const getDataIds = data => data.map(o => o.id)

function isEmpty(str) {
    return (!str || 0 === str.length);
}

function valueMatchesTarget(value, target) {
    if (isEmpty(target)) return true
    const matchAsString =
        _.isString(value) &&
        value.toLowerCase().search(target.toLowerCase()) !== -1
    const matchAsNumber = _.isNumber(value) && value === _.parseInt(target)
    return matchAsString || matchAsNumber
}

const sortGetterForFields = ({ listFieldsById }) => ({ fields, sortBy }) => {
    const sortedFieldId = fields.find(
        fieldId => listFieldsById[fieldId].path === sortBy
    )
    return listFieldsById[sortedFieldId].dataGetter
}

const filterGetterForFields = ({ listFieldsById }) => ({
    fields,
    visibleColumns
}) => {
    return fields.reduce((acc, fieldId) => {
        const field = listFieldsById[fieldId]
        if (
            !field.disableFilter &&
            field.dataGetter &&
            visibleColumns[field.path]
        )
            acc.push(field.dataGetter)
        return acc
    }, [])
}

const sort = (ids, getValueForId, sortDirection) =>
    _.orderBy(
        ids,
        getValueForId,
        sortDirection === SortDirection.ASC ? 'asc' : 'desc'
    )

const defaultState = {
    allIds: [],
    byId: {},
    columnSelectorOpened: false,
    actionSelectorOpened: false,
    deleteAccessId: null,
    error: null,
    errorStatus: null,
    initialFields: [],
    fields: [],
    filter: '',
    sortedIds: [],
    filteredSortedIds: [],
    filtersOpened: true,
    isFetching: false,
    sortBy: null,
    sortDirection: null,
    visibleColumns: {}
}

const list = (state = defaultState, action) => {
    const getSortDataGetter = action.meta && sortGetterForFields(action.meta)
    const getFilterDataGetter =
        action.meta && filterGetterForFields(action.meta)

    switch (action.type) {
        case INITIALIZE_MODULE: {
            return {
                ...defaultState,
                ...action.module.list,
                memorizeColumns: action.module.memorizeColumns,
                initialFields: action.module.list.fields,
                fieldsById: action.module.fields.byId,
                visibleColumns: action.module.list.fields.reduce(
                    (acc, fieldId) =>
                        Object.assign(acc, {
                            [action.module.fields.byId[fieldId].path]: !action.module.fields.byId[fieldId].initiallyNotVisible
                        }),
                    {}
                ),
                sortBy: action.module.defaultSortBy || defaultState.sortBy,
                sortDirection:
                    action.module.defaultSortDirection ||
                    defaultState.sortDirection
            }
        }

        case SET_COLUMN_SELECTOR_OPENED:
            return {
                ...state,
                columnSelectorOpened: action.opened
            }

        case SET_ACTION_SELECTOR_OPENED:
            return {
                ...state,
                actionSelectorOpened: action.opened
            }

        case SET_FILTERS_OPENED:
            return {
                ...state,
                filtersOpened: action.opened
            }

        case SORT_DATA_TABLE: {
            const dataGetter = getSortDataGetter({
                fields: state.fields,
                sortBy: action.sortBy
            })

            return {
                ...state,
                sortedIds: sort(
                    state.sortedIds,
                    id => dataGetter(state.byId[id]),
                    action.sortDirection
                ),
                filteredSortedIds: sort(
                    state.filteredSortedIds,
                    id => dataGetter(state.byId[id]),
                    action.sortDirection
                ),
                sortBy: action.sortBy,
                sortDirection: action.sortDirection
            }
        }

        case SET_LIST_ELEMENT_SELECTION: {
            return {
                ...state,
                byId : {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        selected: action.isChecked
                    }

                }
            }
        }

        case FILTER_DATA_TABLE: {
            const newFilterIsMoreStrict = valueMatchesTarget(
                action.value,
                state.filter
            )
            const idsToFilter = newFilterIsMoreStrict
                ? state.filteredSortedIds
                : state.sortedIds

            const dataGetters = getFilterDataGetter(state)

            return {
                ...state,
                filter: action.value,
                filteredSortedIds: idsToFilter.filter(id => {
                    const object = state.byId[id]

                    return dataGetters.some(dataGetter =>
                        valueMatchesTarget(dataGetter(object), action.value)
                    )
                })
            }
        }

        case TOGGLE_COLUMN_VISIBILITY: {

            const localStorageVisibleColumns = localStorage.getItem('visibleColumns');

            const previousVisibleColumns = state.memorizeColumns && localStorageVisibleColumns
                ? JSON.parse(localStorageVisibleColumns)[state.accessId] || state.visibleColumns
                : state.visibleColumns

            const visibleColumns = {
                ...previousVisibleColumns,
                [action.columnId]: !previousVisibleColumns[action.columnId]
            }

            if(state.memorizeColumns) {
                localStorage.setItem('visibleColumns',
                    JSON.stringify({
                        ...JSON.parse( localStorageVisibleColumns),
                        [state.accessId]: visibleColumns
                    })
                );
            }

            const sortColumnStillVisible = visibleColumns[state.sortBy]
            const sortBy = sortColumnStillVisible ? state.sortBy : null
            const sortDirection = sortColumnStillVisible
                ? state.sortDirection
                : null

            const dataGetters = getFilterDataGetter({
                visibleColumns,
                fields: state.fields
            })

            return {
                ...state,
                visibleColumns,
                sortBy,
                sortDirection,
                filteredSortedIds: state.sortedIds.filter(id => {
                    const object = state.byId[id]

                    return dataGetters.some(dataGetter =>
                        valueMatchesTarget(dataGetter(object), state.filter)
                    )
                })
            }
        }

        case DT_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
                errorStatus: null
            }

        case DT_SUCCESS:
            const dataGetter =
                state.sortBy &&
                getSortDataGetter({
                    fields: state.fields,
                    sortBy: state.sortBy
                })

            const allIds = getDataIds(action.data)
            const byId = action.data.reduce(
                (acc, o) => (
                    { ...acc,
                        [o.id]: {...o, selected: false}
                    }),
                {}
            )
            const sortedIds = state.sortBy
                ? sort(allIds, id => dataGetter(byId[id]), state.sortDirection)
                : allIds

            /* handle dynamic columns */

            const fields = []
            state.initialFields.forEach(fieldId => {
                if(!action.data.length || !state.fieldsById[fieldId] || !state.fieldsById[fieldId].dynamic) {
                    fields.push(fieldId)
                }else{
                    const tempoFields = []
                    action.data.forEach(
                        object => {
                            Object.keys(object[fieldId.split('_')[1]]).forEach(
                                key => {
                                    const newFieldId = `${fieldId}.${key}`
                                    if(!tempoFields.includes(newFieldId)) {
                                        tempoFields.push(newFieldId)
                                    }
                                }
                            )
                        }
                    )
                    if(state.fieldsById[fieldId].disableColumnsSort) {
                        tempoFields.forEach(fieldId => fields.push(fieldId))
                    } else {
                        tempoFields.sort().reverse().forEach(fieldId => fields.push(fieldId))
                    }


                }
            })

            return {
                ...state,
                sortedIds: sortedIds,
                filteredSortedIds: sortedIds,
                isFetching: false,
                allIds: allIds,
                byId: byId,
                fields: fields,
                visibleColumns: fields.reduce(
                    (acc, fieldId) => {
                        const dynamicFieldId = fieldId.split('.')[0]
                        const path = fieldId.slice(2)

                        return Object.assign(acc, {
                            [path]: state.visibleColumns[path]
                                ? state.visibleColumns[path]
                                : state.fieldsById[fieldId]
                                    ? !state.fieldsById[fieldId].initiallyNotVisible
                                    : !state.fieldsById[dynamicFieldId].initiallyNotVisible
                        })
                    },
                    {}
                ),

            }

        case DT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                errorStatus: action.status
            }

        case CREATE_OBJECT_SUCCESS: {
            if (!action.data || !action.data.id) return state

            const filterDataGetters = getFilterDataGetter(state)

            const allIds = [...state.allIds, action.data.id]
            const byId = {
                ...state.byId,
                [action.data.id]: action.data
            }

            let sortedIds

            if (state.sortBy) {
                const sortDataGetter = getSortDataGetter(state)

                sortedIds = sort(
                    allIds,
                    id => sortDataGetter(byId[id]),
                    state.sortDirection
                )
            } else {
                sortedIds = allIds
            }

            const filteredSortedIds = sortedIds.filter(id => {
                const object = byId[id]

                return filterDataGetters.some(dataGetter =>
                    valueMatchesTarget(dataGetter(object), state.filter)
                )
            })

            return {
                ...state,
                allIds,
                byId,
                sortedIds,
                filteredSortedIds
            }
        }

        case SAVE_OBJECT_SUCCESS: {
            let enhancer

            if (action.data) {
                let data = action.data
                if(! _.isArray(data)) data = [data]

                const byId = data.reduce((acc, obj) => {
                    return {
                        ...acc,
                        [obj.id]: obj
                    }
                }, state.byId)

                const allIds = state.allIds

                data.forEach(obj => {
                    if(!state.allIds.includes(obj.id)) {
                     allIds.push(obj.id)
                    }
                })

                let sortedIds = allIds
                if (state.sortBy) {
                    const sortDataGetter = getSortDataGetter(state)

                    sortedIds = sort(
                        sortedIds,
                        id => sortDataGetter(byId[id]),
                        state.sortDirection
                    )
                }

                const filterDataGetters = getFilterDataGetter(state)

                const filteredSortedIds = sortedIds.filter(id => {
                    const object = byId[id]

                    return filterDataGetters.some(dataGetter =>
                        valueMatchesTarget(dataGetter(object), state.filter)
                    )
                })

                enhancer = {
                    byId,
                    allIds,
                    sortedIds,
                    filteredSortedIds
                }
            } else {
                enhancer = {
                    allIds: state.allIds.filter(id => id !== action.id),
                    byId: Object.keys(state.byId).reduce(
                        (acc, id) =>
                            id === action.id
                                ? acc
                                : { ...acc, [id]: state.byId[id] },
                        {}
                    ),
                    sortedIds: state.sortedIds.filter(id => id !== action.id),
                    filteredSortedIds: state.filteredSortedIds.filter(
                        id => id !== action.id
                    )
                }
            }

            return {
                ...state,
                ...enhancer
            }
        }

        case ACTION_OBJECT_REQUEST:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        isInAction: true
                    }
                }
            }
        case ACTION_OBJECT_SUCCESS:
        case ACTION_OBJECT_FAILURE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        isInAction: false
                    }
                }
            }


        case DELETE_OBJECT_REQUEST:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        isDeleting: true
                    }
                }
            }

        case DELETE_OBJECT_SUCCESS: {
            const allIds = state.allIds.filter(id => id !== action.id)

            return {
                ...state,
                allIds,
                byId: allIds.reduce(
                    (acc, id) => ({ ...acc, [id]: state.byId[id] }),
                    {}
                ),
                sortedIds: state.sortedIds.filter(id => id !== action.id),
                filteredSortedIds: state.filteredSortedIds.filter(
                    id => id !== action.id
                )
            }
        }

        case DELETE_OBJECT_FAILURE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        isDeleting: false
                    }
                }
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                errorStatus: null
            }

        default:
            return state
    }
}

export default list
