export const entity = {
    name: 'OrganizationType',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true},
        {path: 'supplier', type: 'boolean'},
        {path: 'internal', type: 'boolean'},
        {path: 'association', type: 'boolean'},
        {path: 'memberAssociation', type: 'boolean'},
    ]
}

export const module_ = {
    object: 'OrganizationType',
    tKey: 'mTitle_organizationType',
    objectIdentifier: 'code',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'code',
            'name',
            {path: 'supplier', initiallyNotVisible: true},
            {path: 'internal', initiallyNotVisible: true},
            {path: 'association', initiallyNotVisible: true},
            {path: 'memberAssociation', initiallyNotVisible: true}
        ],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
            {path: 'supplier', default: false},
            {path: 'internal'},
            {path: 'association'},
            {path: 'memberAssociation'},
        ]
    }
}
