import _ from "lodash";

export const entity = {
    name: 'AnimalType',
    facets: ['codeName'],
    filters: [
        {
            name: 'byShelters',
            isDefault: false,
            query: (context) => {
                const shelters = _.get(context, 'data.shelters')
                return {_id: {$in: _.flatMap(shelters, shelter => shelter.animalTypes.map(animalType => global.ObjectID(animalType.id))) }}
            }
        }
    ]
}

export const module_ = {
    object: 'AnimalType',
    tKey: 'mTitle_animalType',
    objectIdentifier: 'name',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'code', 'name'
        ],
        form: [
            'code', 'name'
        ]
    }
}
