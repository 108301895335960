const path = require("path");
const _ = require("lodash");
const {decrypt} = require("../../../utils/crypto");

const defaultMail = {
    from: '"Défense de l\'Animal" <laconfederation@defensedelanimal.fr>',
    replyTo: 'laconfederation@defensedelanimal.fr',
    verbose: {
        general: true,
    },
    signature: false,
}

export const sendMail = function (token, user, context, done) {
    const language = _.get(user, 'language.id') || 'fr'
    const host = global.isProd
        ? `https://${context.host}`
        : `http://localhost:3000`
    const link = host + `/reset/${token}`



    const mail = _.defaults({
        to: user.mail && decrypt(user.mail),
        context: {
            firstname : user.firstname,
            //civility : context.tl(user.civility.id, language),
            host,
            link
        },
        subject: {template: `${context.tl('passwordDefinition', language)}`},
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/KpMailer'
                : 'src/server/KpMailer',
            'templates/forgotPass'
        ),
        content: `${language || 'fr'}_forgottenPass.html`
    }, defaultMail);
    global.mailer.sendMail(mail, error => done(error, 'emailSent'))
}
