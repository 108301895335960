import React from 'react'
import _ from 'lodash'
import './DonutCard.css'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import Pie from '../../components/Graph/DonutChart/Pie'

const finalObjects = [
    {key: 1, country: "France", value: 400},
    {key: 2, country: "Morocco", value: 200},
    {key: 3, country: "Finland", value: 350},
    {key: 4, country: "Japan", value: 800},
    {key: 5, country: "China", value: 900},
    {key: 6, country: "South Africa", value: 800}
]

const getKey = d => {
    return d.data.key
}

// gets sum for one criteria
const getSum = () => {
    return _.reduce(
        finalObjects,
        (acc, o) => acc + Number(o.value),
        0
    )
}

const DonutCard = ({ title = "Title" }) => {
    return (
        <div className="DonutCard-container col-sm-12 col-md-8 col-lg-8">
            <div className="DonutCard">
                <h4>{title}</h4>
                <div className="DonutCard-content">
                    <AutoSizer>
                        {({ height, width }) => {
                            const radius = 3 * height / 10 - 20
                            const x = width / 2
                            const y = height / 2

                            return (
                                <svg
                                    className="Graph"
                                    width={width}
                                    height={height}
                                >
                                    <Pie
                                        x={x}
                                        y={y}
                                        radius={radius}
                                        data={finalObjects}
                                        axis={"value"}
                                        sum={getSum()}
                                        getKey={getKey}
                                        criteria={"value"}
                                    />
                                </svg>
                            )
                        }}
                    </AutoSizer>
                </div>
            </div>
        </div>
    )
}
export default DonutCard;
