export const entity = {
    name: 'ExpenditureType',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ]
}
export const module_ = {
    object: 'ExpenditureType',
    tKey: 'mTitle_expenditureType',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: ['code','name'],
        form: ['code', 'name']
    }
}
