import _ from "lodash";
const Errors = require("../../../../utils/Errors").default
import {hasDuplicates} from "../../utils/usefulFunctions";

export const entity = {
    name: 'OrganizationalMesh',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields: [
        { path: 'attachments', fieldPath: ['id', 'name'],type:'Organization', link: {type: "MTM", oppositeField: {path: "organizationalMeshes"}}},
        {
            path:'attachmentsNumber',
            f: function () {
                return this.attachments?.length
            }
        },
        {
            path: 'nameWithOrganizationalAxis',
            fieldPath: ['attachments', 'name'],
            $f: function (object, context, callback) {
                if(!context.translateName) return callback(null, `${object.name} : ( ${object.attachments.map(org => ' ' + org.organizationalAxis.name + ' : ' + org.name).toString()} )`)
                callback(null, `${context.translateName(object.name)} : ( ${object.attachments.map(org => ' ' + context.translateName(org.organizationalAxis.name) + ' : ' + context.translateName(org.name))} )`)
            }
        }
    ],
    filters: [
        {  //verified
            name: 'byThisOrganization',
            tKey: 'organisation',
            isDefault: false,
            client: true,
            path: 'organization',
            object: 'Organization',
            display: 'nameWithOrganizationalAxis',
            clearable: true,
            query: function(context) {
                const organizationID = _.get(context, 'data.organization.id')
                return organizationID ? {"attachments": {$elemMatch: {$eq: global.ObjectID(organizationID)}}} : {}
            }
        },
        {
            name: "byOrganizationOrMesh",
            isDefault: false,
            query: function(context) {
                const organizationOrMeshID = _.get(context, 'data.organization')
                return organizationOrMeshID ? {$or:[{"attachments": {$elemMatch: {$eq: global.ObjectID(organizationOrMeshID)}}}, {_id:global.ObjectID(organizationOrMeshID)}]} : {"attachments": "0"}
            }
        },
    ],
    beforeSave: function (newObject, oldObject, context, callback) {
        const IDs = newObject.attachments.map( org => org.organizationalAxis.id.toString())
        if (hasDuplicates(IDs)){
            callback(new Errors.ValidationError('An entity can be attached to one organization from each axis'))
        }
        else {callback(null, newObject, oldObject)}
    }
}

export const module_ = {
    object: 'OrganizationalMesh',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    tKey: 'business unit',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'},
            {path: 'attachments', display: 'nameWithOrganizationalAxis'},
            {path: 'attachmentsNumber', tKey: 'Nb.'}
        ],
        form: [
            {path: 'code', editable: false},
            {path: 'name', type: 'textarea'},
            {path: 'attachments', display: 'nameWithOrganizationalAxis'},
        ],
    },
    filters :['byThisOrganization']
}
