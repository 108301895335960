import _ from 'lodash'
import {payButton, saveButton, returnButton, submitButton, acceptButton, refuseButton, examineButton} from '../utils'

export const getButtonsForModule = (application, context) => {
    const statusId = _.get(application, 'status.id');
    const { moduleId } = context.clientContext;
    let buttons = []

    switch (moduleId) {
        case 'm-C-submission':
            if (statusId === 'draft' || statusId === 'questioned') {
                buttons = [submitButton, saveButton, returnButton]
            }
            break
        case 'm-C-study':
            if (statusId === 'ongoing') {
                buttons = [
                    {
                        ...acceptButton,
                        tKey: 'Transmettre pour décision',
                    },
                    examineButton,
                    returnButton
                ]
            }
            break
        case 'm-C-decision':
            if (statusId === 'controlled') {
                buttons = [acceptButton, refuseButton, examineButton, returnButton]
            }
            break
        case 'm-C-reliefFundPayment':
            if (statusId === 'accepted') {
                buttons = [payButton, returnButton]
            }
            break
    }

    // Adding a return button if not already included, to always allow going back
    if (!buttons.includes(returnButton)) {
        buttons.push(returnButton)
    }

    return buttons
}
