import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Login from '../../../../components/Login'

class LoginPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLogging: props.isLogging,
        }
    }
    componentDidMount() {
        this.props.fetchConfig()
    }
    // new (React v16.3.0) lifecycle
    static getDerivedStateFromProps(props, state) {
        if (props.isLoggedIn && state.isLogging && !props.isLogging) {
            const previousPath = props.previousPath || '/business'
            props.history.push(previousPath)
        }
        return {
            isLogging: props.isLogging
        }
    }

    handleResponse = response => {
        if(!response.error) {
            if(response.data.loginConfirmation) {
                this.props.history.push('/loginConfirmation')
            }
        }
    }

    handleLogin = user => {
        this.props.login(user)
            .then( response => this.handleResponse(response))
    }

    render() {
        const {t, history, serverUrl, authStrategies, client} = this.props
        return <Login
            history={history}
            client={client}
            handleSubmit={this.handleLogin}
            t={t}
            serverUrl={serverUrl}
            authStrategies={authStrategies}
        />
    }
}

LoginPage.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

export default LoginPage
