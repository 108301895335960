export default [
    {
        name: 'HistoryDataType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'imported', name: 'I' },
            { id: 'duplicated', name: 'D' }
        ]
    },
    {
        name: 'DataType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [{ id: 'monthly', name: 'Mensuelle' }]
    },
    {
        name: 'AccountModelType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
                { id: 'header', name: 'Header' },
                { id: 'space', name: 'Space' },
                { id: 'fixed', name: 'Fixed' },
                { id: 'mesh', name: 'Mesh' },
                { id: 'deposit', name: 'deposit' }
            ]
    },
    {
        name: 'ReferentialType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', 'entity', 'byCompany'],
        objects: [
            { id: 'groupSurFamilyCode', name: 'Sur Famille G', entity: 'groupSurFamily', byCompany: 'false' },
            { id: 'surFamilyCode', name: 'Sur Famille', entity: 'surFamily', byCompany: 'true' },
            { id: 'familyCode', name: 'Famille', entity: 'family', byCompany: 'true' },
            { id: 'companyCode', name: 'Société', entity: 'company', byCompany: 'false' },
            { id: 'depositZoneCode', name: 'Zone', entity: 'zone', byCompany: 'false' },
            { id: 'depositCode', name: 'Dépôt', entity: 'deposit', byCompany: 'false' }
        ]
    },
    {
        name: 'MarginReferentialType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', 'entity', 'byCompany'],
        objects: [
            { id: 'groupSurFamilyCode', name: 'Sur Famille G', entity: 'groupSurFamily', byCompany: 'false'  },
            //{ id: 'surFamilyCode', name: 'Sur Famille', entity: 'surFamily', byCompany: 'true'  }
        ]
    },
    {
        name: 'StateModelType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ca', name: "Chiffre d'affaire" },
            { id: 'stock', name: "Stock" },
            { id: 'margin', name: "Marge" },
        ]
    },
]
