const { byPiece } = require('./filters')
const { fieldPathJoinGetter } = require("../../utils/kp3Utils");

export const dependencies = [
    {
        name: 'PieceHistory',
        fields: [
            { path: 'date', type: 'date' },
            'HistoryDataType'
        ],
        filters:[byPiece]
    }
]

export const entity = {
    name: "Piece",
    fields: [
        'Deposit',
        'Company',
        'origin',
        'type',
        'piece',
        {
            type: 'PieceHistory',
            path: 'depositsAndCompanies',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        },
        fieldPathJoinGetter({
            path: 'fullName',
            fieldPath: ['piece', 'type', 'origin', 'company.code', 'deposit.code']
        })
    ]
}

export const modules = [
    {
        object: "PieceHistory",
        tKey: "mTitle_piece",
        newable: false,
        removable: false,
        category: {
            path: "referential",
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'piece.company.fullName',
                    tKey: 'company'
                },
                {path: "piece.piece", tKey: 'piece'},
                'date',
                {
                    path: 'piece.deposit.fullName',
                    tKey: 'deposit'
                },
                {path: 'piece.origin', tKey: 'origin'},
                {path: 'piece.type', tKey: 'type'},
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:["byPiece"]
    }
]
