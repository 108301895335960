const moment = require('moment')
const _ = require("lodash");

async function findData(context, callback) {

    const businessProjectCollectionName = global.app.I.BusinessProject.collectionName
    const countryProjectCollectionName = global.app.I.Country.collectionName
    const zoneProjectCollectionName = global.app.I.Zone.collectionName
    const executedFollowUpCollection = global.app.I.ExecutedFollowUp.collection
    const years = [moment().year()-1, moment().year()]
    const executedFollowUpQuery = executedFollowUpCollection
        .aggregate([
            {
                $match: {year: {$in: years}}
            },
            {
                $lookup: {
                    from: businessProjectCollectionName,
                    localField: 'businessProject',
                    foreignField: '_id',
                    as: 'bp'
                }
            },
            {
                $lookup: {
                    from: countryProjectCollectionName,
                    localField: 'bp.country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            {
                $lookup: {
                    from: zoneProjectCollectionName,
                    localField: 'country.zone',
                    foreignField: '_id',
                    as: 'zone'
                }
            },
            {
                $unwind: '$zone'
            },
            {
                $group: {
                    _id: { zone: '$zone.code', year: '$year' },
                    zone: {$first: '$zone.code'},
                    year: {$first: '$year'},
                    turnover: { $sum: '$executedTurnOver' }
                }
            },
        ])
        .toArray()

    const [executedFollowUps] = await Promise.all([
        executedFollowUpQuery
    ])

    const dataByZone = _.groupBy(executedFollowUps, 'zone')
    const executedFollowUpsData = _.sortBy(Object.keys(dataByZone)).map(zone => {
        const firstYear = dataByZone[zone].find(o => o.year === years[0])
        const secondYear = dataByZone[zone].find(o => o.year === years[1])
        return {
            axis: zone,
            [years[0]]: firstYear ? firstYear.turnover : 0,
            [years[1]]: secondYear ? secondYear.turnover : 0
        }
    })

    const total = executedFollowUpsData.reduce((acc, o) => Object.assign(acc, {
        first: acc.first + o[years[0]],
        second: acc.second + o[years[1]],
    }), {first: 0, second: 0})

    return [
        {
            id: "turnoverPerZone",
            subTitle: {
                text: 'turnoverSubtitle',
                data: {
                    firstYear: years[0],
                    firstTotal: total.first,
                    secondYear: years[1],
                    secondTotal: total.second
                }
            },
            data: executedFollowUpsData
        }
    ]
}

export const entity = {
    name: 'TurnoverPerZone',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
