import React from 'react'
import PropTypes from 'prop-types'
import './TextField.css'
import ValidationUI from '../ValidationUI'

const TextField = (props) => (
    <div>
        <div className="TextField-label">{props.label} {props.required && '*'}</div>
        {props.subtitle && <div className="TextField-subtitle">{props.subtitle}</div>}
        <input
            className="TextField-input"
            {...props}
        />
        { props.error && <ValidationUI error={props.error}/> }
    </div>

)

TextField.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}

export default TextField
