const moment = require('moment')

export const dependencies = [
    {
        name: "DashboardElement",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name",
            "type",
            "url",
            "suffix",
            "link",
            "keys",
            "colors",
            "width",
            "sum"
        ],
        objects: [
            {id: "turnoverPerZone", name: "Chiffre d’affaires / Zone", type: 'doubleBarChart', url: "TurnoverPerZone", keys: [`${moment().year()}`, `${moment().year() -1}`], colors: ['#8e8e8e', '#bbbbbb']},
            {id: "turnoverPerProductLine", name: "Chiffre d’affaires / Ligne produit", type: 'doubleBarChart', url: "TurnoverPerProductLine", keys: [`${moment().year()}`, `${moment().year() -1}`], colors: ['#8e8e8e', '#bbbbbb']},

            {id: "contractsNumberPerZone", name: "Nombre de contrats / Zone", type: 'doubleBarChart', url: "ContractsNumberPerZone", keys: ['signed', 'inProgress'], colors: ['#1568ad', '#8e8e8e']},
            {id: "contractsNumberPerProductLine", name: "Nombre de contrats / Ligne produit", type: 'doubleBarChart', url: "ContractsNumberPerProductLine", keys: ['signed', 'inProgress'], colors: ['#1568ad', '#8e8e8e']},

            {id: "orderTakingPerZone", name: "Prises de commandes / Zone", type: 'barLineChart', url: "OrderTakingPerZone", keys: ['amount', 'number'], colors: ['#1568ad', '#ff632b']},
            {id: "orderTakingPerProductLine", name: "Prises de commandes / Ligne produit", type: 'barLineChart', url: "OrderTakingPerProductLine", keys: ['amount', 'number'], colors: ['#1568ad', '#ff632b']},

            {id: "offerSubmissionPerZone", name: "Offres déposées / Zone", type: 'barLineChart', url: "OfferSubmissionPerZone", keys: ['amount', 'number'], colors: ['#0e9729', '#ff632b']},
            {id: "offerSubmissionPerProductLine", name: "Offres déposées / Ligne produit", type: 'barLineChart', url: "OfferSubmissionPerProductLine", keys: ['amount', 'number'], colors: ['#0e9729', '#ff632b']},

            {id: "orderTakingGlobal", name: "Prises de commandes (Global)", type: 'barLineChart', url: "OrderTakingGlobal", keys: ['amount', 'number'], colors: ['#1568ad', '#ff632b']},
        ]
    }
]

export const entities = [
    {
        name: "DashboardAffectation",
        fields: [
            {type: "User", unique: true},
            {
                path: "elements",
                type: "DashboardElement",
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false
                },
                notEmpty: true
            },
        ]
    }
]
