import React from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection';

const roundNumber = num => Math.round(num)

class Pie extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            colorScale: ['#87cbd4', '#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00', '#ffc08d', '#87d4a0']
        }
        this.renderSlice = this.renderSlice.bind(this)
    }

    renderSlice() {
        const { radius, sum, data, axis, getKey } = this.props

        const pie = d3
            .pie()
            .sort(null)
            .value(d => parseInt(d[axis], 10))

        const arc = d3
            .arc()
            .innerRadius(radius * 0.5)
            .outerRadius(radius)
            .padAngle(0.01)
            .cornerRadius(4)

        const slice = select('.arc')
            .selectAll('path.slice')
            .data(pie(data), getKey)

        const text = select('.labels')
            .selectAll('text')
            .data(pie(data), getKey)

        const legendText = select('.textLegend')
            .selectAll('text')
            .data(pie(data), getKey)

        const legendColor = select('.colorLegend')
            .selectAll('circle')
            .data(pie(data), getKey)

        /* rendering pie slices */

        slice
            .enter()
            .append('path')
            .attr('class', 'slice')
            .style('fill', (d, i) => this.state.colorScale[i])
            .transition()
            .duration(1000)
            .attrTween('d', function(d) {
                const i = d3.interpolate(d.endAngle, d.startAngle)
                return function(t) {
                    d.startAngle = i(t)
                    return arc(d)
                }
            })
        slice
            .style('fill', (d, i) => this.state.colorScale[i])
            .transition()
            .duration(1000)
            .attrTween('d', function(d) {
                const i = d3.interpolate(this.current, d)
                this.current = i(0)
                return function(t) {
                    return arc(i(t))
                }
            })
        slice
            .exit()
            .transition()
            .duration(1000)
            .attrTween('d', function(d) {
                const i = d3.interpolate(d.startAngle, d.endAngle)
                return function(t) {
                    d.startAngle = i(t)
                    return arc(d)
                }
            })
            .remove()

        /* rendering slice percentage labels */

        text
            .enter()
            .append('text')
            .attr('transform', d => `translate(${arc.centroid(d)})`)
            .attr('fill', 'white')
            .attr('dy', '.35em')
            .text(d => {
                return roundNumber(d.value * 100 / sum) + '%'
            })
            .transition()
            .duration(1000)
            .attrTween('transform', function(d) {
                const i = d3.interpolate(d.endAngle, d.startAngle)
                return function(t) {
                    d.startAngle = i(t)
                    const pos = arc.centroid(d)
                    return 'translate(' + [pos[0] - 10, pos[1]] + ')'
                }
            })
        text
            .text(d => roundNumber(d.value * 100 / sum) + '%')
            .transition()
            .duration(1000)
            .attrTween('transform', function(d) {
                this._current = this._current || d
                const i = d3.interpolate(this._current, d)
                this._current = i(0)
                return function(t) {
                    const d2 = i(t)
                    const pos = arc.centroid(d2)
                    return 'translate(' + [pos[0] - 10, pos[1]] + ')'
                }
            })
        text.exit().remove()

        /* rendering legend */

        legendText
            .enter()
            .append('text')
            .attr('transform', function(d, i) {
                return 'translate(0,' + i * 20 + ')'
            })
            .attr('x', 3)
            .attr('dy', '.25em')
            .attr('style', 'font-size : 11')
            .text(
                d => d.value + ' | ' + getKey(d)
            )
        legendText
            .text(
                d => d.value + ' | ' + getKey(d)
            )
            .attr('transform', function(d, i) {
                return 'translate(0,' + i * 20 + ')'
            })
        legendText.exit().remove()

        legendColor
            .enter()
            .append('circle')
            .attr('transform', function(d, i) {
                return 'translate(0,' + i * 20 + ')'
            })
            .attr('cy', '10')
            .attr('cx', '10')
            .attr('r', '4')
            .style('fill', (d, i) => this.state.colorScale[i])
        legendColor
            .attr('transform', function(d, i) {
                return 'translate(0,' + i * 20 + ')'
            })
            .style('fill', (d, i) => this.state.colorScale[i])
        legendColor.exit().remove()
    }

    render() {
        const { x, y } = this.props

        this.renderSlice()
        return (
            <g>
                <g className="arc" transform={`translate(${x}, ${3 * y / 5})`}/>
                <g className="labels" transform={`translate(${x}, ${3 * y / 5})`}/>
                <g className="colorLegend" transform={`translate(10, ${y + 50})`}/>
                <g className="textLegend" transform={`translate(30, ${y + 60})`}/>
            </g>
        )
    }
}

export default Pie
