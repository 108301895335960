export const entity = {
    name: 'Collaborator',
    fields: [
        {path: 'user', type: 'User'},
        {
            path: "email",
            fieldPath: ["user.id"],
            f: function () {
                return this.user.mail
            }
        },
        {
            path: "firstname",
            fieldPath: ["user.id"],
            f: function () {
                return this.user.firstname
            }
        },
        {
            path: "lastname",
            fieldPath: ["user.id"],
            f: function () {
                return this.user.lastname
            }
        },
        {
            path: "fullName",
            fieldPath: ["user.id"],
            f: function () {
                return `${this.user.firstname} ${this.user.lastname}`
            }
        }
    ]
}

export const module_ = {
    object: 'Collaborator',
    tKey: 'mTitle_collaborator',
    category: {
        path: 'referential',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'email'},
            {path: 'firstname'},
            {path: 'lastname'}

        ],
        form: [
            {path: 'user', tKey: 'collaborator', display: 'name', filters: [{
                    path: "userKNotAllowed",
                    match: user => {
                        return !user.k
                    }
                }]},
        ]
    }
}
