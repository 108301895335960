export const entity = {
    name: 'AccountingId',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields:['Project', 'ExpenditureType']
}
export const module_ = {
    object: 'AccountingId',
    tKey: 'mTitle_accountingId',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'project', display: 'fullName'},
            {path: 'expenditureType', display: 'fullName'},
            'code','name'
        ],
        form: [
            {path: 'project', display: 'fullName'},
            {path: 'expenditureType', display: 'fullName'},
            'code', 'name'
        ]
    }
}
