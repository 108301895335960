import {
    GET_OBJECT_SUCCESS
} from "../../../../apps/KpModule/actions/api"

import {
    changeFieldDisabled,
    setFieldVisibility
} from "../../../../apps/KpModule/actions"
import {getDataListList} from "../../../../apps/KpModule/selectors";
import moment from "moment/moment";

function monthRangeToList(startMonth, endMonth){   //MM-YYYY
    if (startMonth && endMonth){
        let monthList= [startMonth]
        while (monthList[monthList.length-1]!==endMonth){
            monthList.push( moment(monthList[monthList.length-1], 'YYYY-MM').add(1, 'months').format('YYYY-MM')
            )
        }
        return monthList
    }
}

export const module_ = {
    object: 'Demand',
    name: 'ProjectCommitment',
    tKey: 'Suivi engagement',
    removable: false,
    newable: false,
    category: {
        path: 'tracking',
    },
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            'demandNumber', 'title', 'organizationAndMesh',
            {path: 'demandNature', tKey: 'category'},
            'status', 'projectCommitmentLastUpdate',
            {path: 'projectCommitmentLastUpdatedBy', initiallyNotVisible: true}
            /*
            {
                path: 'totalBudget',
                tKey: "Budget"
            },
            {
                path: 'totalEstimated',
                tKey: "Estimé"
            },
            {
                path: 'totalValidated',
                tKey: "Validé"
            },
            {
                path: 'totalRevised',
                tKey: "Révisé"
            },
            {
                path: 'totalEngaged',
                tKey: "Engagé"
            },
            {
                path: 'totalOngoing',
                tKey: "encours"
            },

             */
        ],
        form: {
            fields : [
                {path: 'demandNumber', disabled: true},
                /*
                {path: 'attachedDemand', fieldPath: ['id', 'demandNumber', 'imputations', 'startFiscalYear', 'endFiscalYear', "status"], hidden: true},
                {
                    path: 'followTable',
                    tKey: 'suivi par projet',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            {path: "projectQuadruplet", tKey: 'quadruplet du projet', width: 700},    // par defaut le link est celui de dropdown, MTO
                            {path: "budget" },
                            {path: "validated"},
                            {path: "estimated", tKey: 'estimé'},
                            {path: "revised", tKey: 'révisé'},
                            {path: "engaged", tKey: 'engagé'},
                            {path: "ongoing", tKey: 'encours'},
                        ],
                        form: {
                            fields : [
                                {
                                    path: 'followUpByMonth',
                                    tKey: 'suivi par mois',
                                    type: 'dtObjects',
                                    fields: [
                                        {path: 'month'},
                                        {path: "budget" },
                                        {path: "validated"},
                                        {path: "estimated", type: 'editText', tKey: 'estimé'},
                                        {path: "revised", type: 'editText', tKey: 'révisé'},
                                        {path: "engaged", type: 'editText', tKey: 'engagé'},
                                        {path: "ongoing", type: 'editText', tKey: 'encours'},
                                    ],
                                    subscriptions: {
                                        onChange: (newValue, oldValue, {module, formInitialize, getObjectSuccessAction}) => {
                                            if(newValue && !formInitialize && !getObjectSuccessAction) {
                                                const totalValues = newValue.reduce((acc, monthLine) => {
                                                    return {
                                                        estimated: acc.estimated + parseFloat(monthLine.estimated),
                                                        revised: acc.revised + parseFloat(monthLine.revised),
                                                        engaged: acc.engaged + parseFloat(monthLine.engaged),
                                                        ongoing: acc.ongoing + parseFloat(monthLine.ongoing)
                                                    }
                                                }, {estimated: 0, revised: 0, engaged: 0, ongoing: 0})

                                                const followTableField = module.viewMap.form.fields.find(field => field.path === 'followTable')
                                                const paths = ['estimated', 'revised', 'engaged', 'ongoing']
                                                paths.forEach(path => {
                                                    const field = followTableField.viewMap.form.fields.find(field => field.path === path)
                                                    field.setValue(totalValues[path])
                                                })
                                            }

                                        }
                                    },
                                },
                                {path: "estimated", hidden: true},
                                {path: "revised", hidden: true},
                                {path: "engaged", hidden: true},
                                {path: "ongoing", hidden: true},
                            ]
                        }
                    },
                }
                 */
                {
                    path: 'followTable2',
                    tKey: 'suivi par projet',
                    type: 'accordion',
                    newable: true,
                    viewMap: {
                        dt: [
                            {path: "correspondentImputation", display: 'imputationTriplet'},
                        ],
                        form: {
                            fields : [
                                {path: 'correspondentImputation', hidden: true},
                                {
                                    path: 'followUpByMonth2',
                                    tKey: 'suivi par mois',
                                    type: 'dtObjects',
                                    fields: [
                                        {path: 'month'},
                                        {path: "estimated", type: 'editText', tKey: 'estimé'},
                                        {path: "validated"},
                                        {path: "revised", type: 'editText', tKey: 'révisé'},
                                    ],
                                    /*
                                    subscriptions: {
                                        onChange: (newValue, oldValue, {module, formInitialize, getObjectSuccessAction}) => {
                                            if(newValue && !formInitialize && !getObjectSuccessAction) {
                                                const totalValues = newValue.reduce((acc, monthLine) => {
                                                    return {
                                                        estimated: acc.estimated + parseFloat(monthLine.estimated),
                                                        revised: acc.revised + parseFloat(monthLine.revised),
                                                        engaged: acc.engaged + parseFloat(monthLine.engaged),
                                                        ongoing: acc.ongoing + parseFloat(monthLine.ongoing)
                                                    }
                                                }, {estimated: 0, revised: 0, engaged: 0, ongoing: 0})

                                                const followTableField = module.viewMap.form.fields.find(field => field.path === 'followTable')
                                                const paths = ['estimated', 'revised', 'engaged', 'ongoing']
                                                paths.forEach(path => {
                                                    const field = followTableField.viewMap.form.fields.find(field => field.path === path)
                                                    field.setValue(totalValues[path])
                                                })
                                            }

                                        }
                                    },
                                */
                                }
                            ]
                        }
                    },
                }
            ],
            dataLists: [
               "ProjectCommitment-FollowBudget",
                //"ProjectCommitment-ImputationType2"
            ],
        }
    },
    actionSubscriptions: [
        /*
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()

                const followTableField = module.viewMap.form.fields.find(field => field.path === 'followTable2')
                const codeField = module.viewMap.form.fields.find(field => field.path === 'code')
                const imputationsField = module.viewMap.form.fields.find(field => field.path === 'imputations')
                const imputations = !!imputationsField && imputationsField.getValue()

                const budgetsList = getDataListList(state, 'ProjectCommitment-FollowBudget')
                const filteredBudgetsList = budgetsList.filter(budget => budget.analyticalMesh.attachment.some(att => att.code ===  code))
                const result = imputations.map( imp => imp)
                followTableField.setValue(result)
            }
        }

         */
        /*
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()

                const followTableField = module.viewMap.form.fields.find(field => field.path === 'followTable')
                const codeField = module.viewMap.form.fields.find(field => field.path === 'code')
                const analyticalSubAxisId = _.get(state, 'edit.object.data.id')
                const previousFollowTable = _.get(state, 'edit.object.data.followTable')
                const attachedDemandField = module.viewMap.form.fields.find(field => field.path === 'attachedDemand')


                const code = codeField.getValue()
                const attachedDemand = attachedDemandField.getValue()

                const budgetsList = getDataListList(state, 'ProjectCommitment-FollowBudget')
                const filteredBudgetsList = budgetsList.filter(budget => budget.analyticalMesh.attachment.some(att => att.code ===  code))
                const result = _.flatMap(filteredBudgetsList, budget => {

                    const groupedByType = _.groupBy(budget.budgetFollowUp, 'type.id')

                    return Object.keys(groupedByType).map(typeId => {
                        const typeObject = groupedByType[typeId][0].type
                        const projectQuadruplet = `${code} / ${budget.analyticalMesh.code} / ${budget.mesh.code} / ${typeObject.code}`
                        const previousLine = previousFollowTable && previousFollowTable.find(line => line.projectQuadruplet === projectQuadruplet)

                        const correspondingImputation = attachedDemand && attachedDemand.imputations.find(imputation => {
                            const budgetEntityId = _.get(budget, 'mesh.id')
                            const imputationEntityId = _.get(imputation, 'organizationalMesh')
                            const budgetAnalyticalMeshId = _.get(budget, 'analyticalMesh.code')
                            const imputationObjectId = _.get(imputation, 'objet')
                            const imputationType = _.get(imputation, 'imputationType')
                            return budgetEntityId === imputationEntityId && budgetAnalyticalMeshId === imputationObjectId && imputationType === typeId
                        })

                        //{fiscalYear: {code, fiscalYearRange: [startDate, endDate]}}

                        const groupedByFiscalYear = _.groupBy(groupedByType[typeId], 'fiscalYear.code')

                        const firstMonthByFiscalYear = Object.keys(groupedByFiscalYear).reduce((acc, fiscalYearCode) => {
                            const element = groupedByFiscalYear[fiscalYearCode][0]
                            const startDate = element.fiscalYear.fiscalYearRange[0]
                            return {
                                ...acc,
                                [fiscalYearCode]: moment(startDate).format('YYYY-MM')
                            }

                        }, {})

                        const sortedFiscalYears = _(groupedByType[typeId])
                            .map(budgetByFiscalYear => budgetByFiscalYear.fiscalYear)
                            .sortBy(fiscalYear => fiscalYear.fiscalYearRange[0])
                            .value()

                        const firstDate = _.head(sortedFiscalYears).fiscalYearRange[0]
                        const lastDate = _.last(sortedFiscalYears).fiscalYearRange[1]
                        const startMonth = moment(firstDate).format('YYYY-MM')
                        const endMonth = moment(lastDate).format('YYYY-MM')

                        const rangeMonths = monthRangeToList(startMonth, endMonth)

                        const followUpByMonth = rangeMonths.map(month => {
                            const previousMonthLine = previousLine && previousLine.followUpByMonth.find(monthLine => monthLine.month === month)

                            const monthFiscalYear = sortedFiscalYears.find(fiscalYear => {
                                const startMonth = moment(fiscalYear.fiscalYearRange[0]).format('YYYY-MM')
                                const endMonth = moment(fiscalYear.fiscalYearRange[1]).format('YYYY-MM')
                                return month >= startMonth && month <= endMonth
                            })

                            const isFirstMonth = firstMonthByFiscalYear[monthFiscalYear.code] === month
                            const budget = !isFirstMonth
                                ? 0
                                : groupedByFiscalYear[monthFiscalYear.code].reduce((acc, budget) => acc + budget.amount, 0)
                            const amountByMonth = correspondingImputation && correspondingImputation.monthAndAmountList.find( monthAndAmount => monthAndAmount.month === month)
                            const validatedByMonth = !!amountByMonth && !!attachedDemand && ["6", "7", "8"].includes(attachedDemand.status.id) ? amountByMonth.amount : 0
                            return {
                                projectQuadruplet,
                                month: month,
                                budget: budget,
                                validated: validatedByMonth,
                                estimated: previousMonthLine ? previousMonthLine.estimated : 0,
                                revised: previousMonthLine ? previousMonthLine.revised : 0,
                                engaged: previousMonthLine ? previousMonthLine.engaged : 0,
                                ongoing: previousMonthLine ? previousMonthLine.ongoing : 0,
                            }
                        })
                        const keys = ['budget', 'validated', 'estimated', 'revised', 'engaged', 'ongoing']
                        const totalAmounts = followUpByMonth.reduce(
                            (acc, object) => keys.reduce(
                                (acc2, key) => ({...acc2, [key]: acc[key] + object[key] }), {}
                            ),
                            keys.reduce((acc, key) => ({...acc, [key]: 0}), {})
                        )

                        const totalBudget = groupedByType[typeId].reduce((acc, budget) => acc + budget.amount, 0)
                        return {
                            projectQuadruplet,
                            analyticalSubAxis: {id: analyticalSubAxisId},
                            subProject: budget.analyticalMesh,
                            entity: budget.mesh,
                            imputationType: {id: typeId},
                            followUpByMonth: followUpByMonth,
                            budget: totalAmounts.budget,
                            validated: correspondingImputation && !!attachedDemand && ["6", "7", "8"].includes(attachedDemand.status.id) ? totalAmounts.validated : 0,
                            estimated: totalAmounts.estimated,
                            revised: totalAmounts.revised,
                            engaged: totalAmounts.engaged,
                            ongoing: totalAmounts.ongoing
                        }
                    })
                })
                followTableField.setValue(result)
            }
        }
        */
    ],
    accesses: [
        {
            id: "ProjectCommitment-FollowBudget",
            entity: "Budget",
            fieldPath: ['id', 'analyticalMesh', 'mesh', 'budgetFollowUp.id', 'budgetFollowUp.type', 'budgetFollowUp.fiscalYear', 'budgetFollowUp.amount'],
            filters: []
        }
    ],
    filters : [
        'isProject', 'inRealization'
    ]
}
