import React from 'react'
import PropTypes from 'prop-types'
import './HamburgerIcon.css'
import {Menu} from 'react-feather'

const HamburgerIcon = ({ onClick, color }) => {
    return (
        <button
            className="navbar-icon-container"
            onClick={onClick}
        >
            <Menu className="navbar-hamburger-icon" color={color}/>
        </button>
    )
}

HamburgerIcon.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default HamburgerIcon
