import {basicContext} from "../../../utils/contextUtils";

export const returnButton = {
    type: 'return',
    tKey: 'return',
    bsStyle: 'default'
}

export const saveButton = {
    type: 'save',
    tKey: 'save',
    bsStyle: 'success'
}

export const validateButton = {
    action: 'validate',
    bsStyle: 'primary',
    tKey: 'validate',
    type: 'action'
}

export const reviseButton = {
    action: 'revise',
    bsStyle: 'warning',
    tKey: 'réviser',
    type: 'action'
}

export const refuseButton = {
    action: 'refuse',
    bsStyle: 'danger',
    tKey: 'refuse',
    type: 'action'
}

export const delegateButton = {
    action: 'delegate',
    bsStyle: 'warning',
    tKey: 'déléguer',
    type: 'action'
}

export const abandonButton = {
    action: 'abandon',
    bsStyle: 'danger',
    tKey: 'abondonner',
    type: 'action'
}
export const arbitrateButton = {
    action: 'arbitrate',
    bsStyle: 'warning',
    tKey: 'demander arbitrage',
    type: 'action'
}
export const sendButton = {
    action: 'send',
    bsStyle: 'primary',
    tKey: 'envoyer le compte-rendu',
    type: 'action'
}

export const draftButton = {
    action: 'save',
    bsStyle: 'success',
    tKey: 'brouillon',
    type: 'action'
}

export const transmitButton = {
    action: 'transmit',
    bsStyle: 'primary',
    tKey: 'valider',
    type: 'action'
}
export const validateDemandeurButton = {
    action: 'validate',
    bsStyle: 'success',
    tKey: 'enregistrer',
    type: 'action'
}
export const warnAndValidateButton = {
    action: 'arbitrate',
    bsStyle: 'primary',
    tKey: 'valider avec réserve',
    type: 'action'
}

export const buttonsIfArbitrator = [validateButton, refuseButton, abandonButton, returnButton]
export const buttonsIfDraft= [draftButton, validateDemandeurButton, transmitButton, returnButton]
export const buttonsIfFirstDemander= [saveButton, validateButton, abandonButton, returnButton] //buttonsIfFirstContributor
export const steps = [
    {
        name: "preValidation",
        buttons: [saveButton, validateButton, reviseButton, refuseButton, abandonButton, returnButton]
    },
    {
        name: "demand",
        buttons: [saveButton, validateButton, reviseButton, abandonButton, returnButton],
    },
    {
        name: "control",
        buttons: [validateButton, reviseButton, returnButton],
    },
    {
        name: "validation",
        buttons: [validateButton, reviseButton, refuseButton, abandonButton, returnButton],
    },
    {
        name: "realization",
        buttons: [saveButton, validateButton, abandonButton, returnButton],
    }
]

export function userHaveExhaustifScope (userHabs){
    for (let i = 0; i < userHabs.length; i++) {
        if ( !userHabs[i].grantedAccess.length && !userHabs[i].grantedMesh.length ){
            return true
        }
    }
    return false
}

export function generateOrderOfSteps (steps, workFlowConfigs) {
    let orderOfSteps = {}
    let c= 1
    steps.forEach( step => {
        for (let i = 0; i < workFlowConfigs.length; i++) {
            if (workFlowConfigs[i].step===step.name){
                orderOfSteps[step.name] = c
                c+=1
                break
            }
        }
    })
    return orderOfSteps
}

export function generateMaxOrder (workFlowConfigs) {
    let maxOrder = {}
    workFlowConfigs.forEach( config => {
        if (!maxOrder[config.step]) {
            maxOrder[config.step] = config.order
        }
        else if ( config.order > maxOrder[config.step] ){
            maxOrder[config.step] = config.order
        }
    })
    return maxOrder
}

export function getPreviousStepPreviousOrder(currentStep, currentOrder, workFlowConfigs){
    const maxOrderByStep = generateMaxOrder(workFlowConfigs)                  // {"step": order, ...}
    const orderOfSteps = generateOrderOfSteps(steps, workFlowConfigs)   //{"step": classement}

    if (currentStep === null) return [null, null]
    if (orderOfSteps[currentStep] === 1 && currentOrder === 1) return [null, null]
    if ( maxOrderByStep[currentStep] > 1 && currentOrder > 1 ){
        return [currentStep, currentOrder-1]
    }
    if (currentOrder === 1){
        const x = Object.keys(orderOfSteps)
        const previousStep = x[orderOfSteps[currentStep]-2]
        if (previousStep === 'preValidation' ){
            return [previousStep, 1]
        }
        else {
            return [previousStep, maxOrderByStep[previousStep]]
        }
    }
}

export function getAndValidateNextStep(currentStep, currentOrder, workflowConfigs, alreadyTreatedByFunctions){
    const maxOrderByStep = generateMaxOrder(workflowConfigs)                  // {"step":order, ...}
    const orderOfSteps = generateOrderOfSteps(steps, workflowConfigs)   //{"step":classement}
    let [nextStep, nextOrder] = [null, null]

    if (currentStep === 'approved'){return ['approved',1000000]}
    else if ( currentStep === 'closed') {return ['closed',1000000]}
    else if ( currentStep === 'abandoned') {return ['abandoned',1000000]}
    else if ( currentStep === 'draft') {
        const firstStep = Object.keys(orderOfSteps)[0]
        return [firstStep,1]
    }
    if (maxOrderByStep[currentStep]){
        for ( let i = 1; i <= maxOrderByStep[currentStep]; i++){
            const thisOrderConfigs = workflowConfigs.filter(conf => conf.step === currentStep && conf.order === i)
            const thisOrderRolesIDs = thisOrderConfigs.map(conf => global.ObjectID(conf.role.id).toString() )
            if ( !alreadyTreatedByFunctions[currentStep] || !alreadyTreatedByFunctions[currentStep].flat(1).some( roleID => thisOrderRolesIDs.includes(roleID) ) ){
                [nextStep, nextOrder] = [currentStep, i]
                break
            }
        }
    }

    if ( !nextStep || !nextOrder ){
        nextStep = Object.keys(orderOfSteps)[orderOfSteps[currentStep]]
        nextOrder = 1
        if ( !!nextStep && !!nextOrder){
            return [nextStep, nextOrder]
        }
        else if ( currentStep === 'validation') {return ['approved',1000000]}
        else if ( currentStep === 'realization') {return ['closed',1000000]}
    }
    else {
        return [nextStep, nextOrder]
    }
}

export function transmitToNextStep( workflowConfigs ){
    const maxOrderByStep = generateMaxOrder(workflowConfigs)            // {"step":order, ...}
    const orderOfSteps = generateOrderOfSteps(steps, workflowConfigs)   //{"step":classement}
    let [nextStep, nextOrder] = [null, null]
    const firstStep = Object.keys(orderOfSteps)[0]
    if ( maxOrderByStep[firstStep] > 1 ){
        return [firstStep, 2]
    }
    else {
        nextStep = Object.keys(orderOfSteps)[orderOfSteps[firstStep]]
        return [nextStep, 1]
    }
}

export function getNextStepStatus(nextStep) {
    if (nextStep==='demand'){
        return {id:'10'}
    } else if (nextStep==='control'){
        return {id:'2'}
    } else if (nextStep==='validation'){
        return {id:'3'}
    } else if (nextStep==='approved'){
        return {id:'6'}
    } else if (nextStep==='realization'){
        return {id:'7'}
    } else if (nextStep==='closed'){
        return {id:'8'}
    } else if (nextStep==='preValidation'){
        return {id:'12'}
    }
}

export function isItFirstStepFirstOrder(module, step, order){
    const firstStep = order === 1 && ( (module === 'preValidation' && step === 'preValidation') || (module === 'DemandInstruction' && step === 'demand') )
    if ( firstStep ) return true

    return false
}
export function isItDraft(step, order){
    if (step === "draft" && order === 1){
        return true
    }
    return false
}


export function getUserHabsAndGrantedOrgsAndMeshesPerRole(context, callback) {
    global.app.SE.Habilitation.find({
        ...basicContext(context),
        fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
        query: { user: global.ObjectID(context.user.id) }
    }, (e, userHabilitations) => {
        if(e) return callback(e)
        if(!userHabilitations.length) return callback(e, [])

        let userGrantedOrgsAndMeshes = []
        userHabilitations.forEach( hab => {
            userGrantedOrgsAndMeshes.push(hab.grantedAccess)
            userGrantedOrgsAndMeshes.push(hab.grantedMesh)
        })
        const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)

        if (!userGrantedOrgsAndMeshesIDs.length) return callback(null, userHabilitations, {})

        global.app.SE.OrganizationalMesh.find(
            {
                ...basicContext(context),
                fieldPath: [],
                query: {
                    $or: [
                        { "attachments": {$elemMatch : {$in : userGrantedOrgsAndMeshesIDs.map( org => global.ObjectID(org.id)) }} },
                        { _id: {$in : userGrantedOrgsAndMeshesIDs.map( org => global.ObjectID(org.id)) }}
                    ]
                }
            }, (er, meshes) => {
                if(er) return callback(er)
                let userGrantedOrgsAndMeshesPerRole = {}
                userHabilitations.forEach( hab => {
                    let orgsAndMeshes = []
                    orgsAndMeshes.push(hab.grantedAccess)
                    orgsAndMeshes.push(hab.grantedMesh)
                    userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                })
                if ( !!meshes.length ){
                    meshes.forEach( mesh => {
                        Object.keys(userGrantedOrgsAndMeshesPerRole).forEach( role => {
                            if ( userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id) ) {
                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                mesh.attachments.forEach( att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()) )
                            }
                            else if ( mesh.attachments.some( orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString())) ){
                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                mesh.attachments.forEach( att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()) )
                            }
                        })
                    })
                }

                return callback(null, userHabilitations, userGrantedOrgsAndMeshesPerRole)
            })
    })

}
