import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import './Files.css'
import {Upload} from 'react-feather'
import { defaultT } from '../utils/i18n'
import { Table, TrashCell, AutoSizer, Column } from './DataTable'
import { ProgressBar } from './ProgressBar/ProgressBar'
import axios from 'axios'
import moment from "moment";


const ImagesUploader = props => {

    const [uploadState, setUploadState] = useState(0)
    // update the image table by adding a row
    const handleResponse = data => {
        const { push, user } = props
        if (data) {
            push({
                ..._.pick(data, ['name', 'date']),
                type: 'image',
                user
            })
        }
        else {
            toastr.error(t('errorUploading'))
        }
    }

    const handleImageUpload = t => event => {
        const files = event.target.files
        let data = new FormData()
        const fileSize = files[0].size
        const chunkSize = 15 * 1024 * 1024 // 15MB
        let fileChunk = null, chunkNb = 0

        if (fileSize > 500 * 1024 * 1024) {
            toastr.error(t('tooLargeFile'))
        }
        else {
            for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
                if (start + chunkSize > fileSize) {
                    fileChunk = files[0].slice(start, fileSize)
                }
                else {
                    fileChunk = files[0].slice(start, start + chunkSize)
                }
                data.append('imageChunks', fileChunk, files[0].name)
            }
            data.append('user', JSON.stringify(props.user))
            data.append('fileSize', fileSize)
            data.append('chunkNb', chunkNb)

            const options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    let percent = Math.floor( (loaded * 100) / total )
                    setUploadState(percent)
                }
            }
            axios.post('/image', data, options)
            .then(res => {
                handleResponse(res.data)
                toastr.success(t('successImageUploading'))
                setUploadState(0)
            })
            .catch(err => {
                if (err.response.status === 413) {
                    toastr.error(t('tooLargeFile'))
                    setUploadState(0)
                }
                else if (err.response.status === 415) {
                    toastr.error(t('unsupportedImageType'))
                    setUploadState(0)
                }
                else {
                    toastr.error(t('errorUploading'))
                    setUploadState(0)
                }
            })
        }
        // Allow to re-upload the same file in a row
        event.target.value = null
    }

    const handleClickTrash = (rowIndex, fileName, t) => () => {
        toastr.confirm(t('deletionConfirmation'), {
            onOk: () => axios.delete(`/image/${fileName}`)
                .then((res) => {
                    if(res.data.ok || res.data.code === "ENOENT") {
                        toastr.success(t('deletion'), t('successImageDeleting'))
                        props.remove(rowIndex)
                    } else {
                        toastr.error(t('deletion'), res.data.message)
                    }
                })
        })
    }
    const renderDeleteIcon = ({ rowIndex, rowData, t }) => {
        const fullDate = moment(rowData.date).format('YYYY-MM-DD_HH-mm-ss')
        const fileName = `${fullDate}_${rowData.name}`
        return <TrashCell onClick={handleClickTrash(rowIndex, fileName, t)} />
    }

    const { allIds = [], byId = {}, t = defaultT } = props
    const objects = allIds.map(id => byId[id])

    let display = null
    if (props.editable) {
        display = <div className="Images-Files-container">
                    <div className="Files-panel-body">
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <Table
                                    objects={objects}
                                    maxRows={7}
                                    width={width}
                                    noRowsLabel={t('noRows')}
                                >
                                    <Column
                                        flexGrow={1}
                                        width={200}
                                        dataKey="name"
                                        label={t('imageName', { capitalize: true })}
                                    />
                                    <Column
                                        flexGrow={1}
                                        width={200}
                                        dataKey="user.name"
                                        label={t('user', { capitalize: true })}
                                        cellDataGetter={({ dataKey, rowData }) =>
                                            _.get(rowData, dataKey)}
                                    />
                                    <Column
                                        flexGrow={1}
                                        width={200}
                                        dataKey="date"
                                        label={t('date', { capitalize: true })}
                                    />
                                    <Column
                                        flexGrow={0}
                                        width={50}
                                        dataKey="id"
                                        label=""
                                        cellRenderer={(data) => renderDeleteIcon({...data, t})}
                                    />
                                </Table>
                            )}
                        </AutoSizer>
                    </div>
                    <div className="Files-input-group">
                        <button className="btn Files-button">
                            <Upload size={16} className="Files-Icon" />
                            <span className="Files-button-Text">
                                {t(props.placeholder || 'addImages', { capitalize: true })}
                            </span>
                        </button>
                        <input
                            className='Files-input'
                            type='file'
                            accept='image/*'
                            encType='multipart/form-data'
                            onChange={handleImageUpload(t)}
                        />
                    </div>
                    { uploadState > 0 ?
                        <div className="uploadProgress">
                            { t('uploading') }
                            <ProgressBar percentage={uploadState} color='#E94E24'/>
                            {uploadState}%
                        </div>
                        : null
                    }
                </div>
    }
    return display
}

ImagesUploader.propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default ImagesUploader
