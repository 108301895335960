import path from "path"
import _ from "lodash"
import fs from "fs"

function base64Encode(file) {
    return fs.readFileSync(file, {encoding: 'base64'})
}

function getBase64EncodedUrlImage(fileName, style= "") {

    const imagePath = path.join(global.appRoot, global.isProd ? "buildServer/img" : "src/img", fileName);

    return '<img alt="logo" style="'+ style +'" src="data:image/png;base64,' + base64Encode(imagePath) + '"/>'
}

const splitCollections = (collection, maxSize) => {
    const result = [];
    for (let i = 0; i < collection.length; i += maxSize) {
        result.push(collection.slice(i, i + maxSize));
    }
    return result;
}

function getTablesAndColumns(tablePath, object, context) {
    const referenceObject = object[tablePath][0]
    let fiscalYearColumns = [
        {path: 'ongoingFiscalYearBudget', label: 'Bud.', parentColumn: 'Exercice en cours', backgroundColor: '#eee', color: '#505050'},
        {path: 'ongoingFiscalYearEstimated', label: 'Est.', parentColumn: 'Exercice en cours', backgroundColor: '#eee', color: '#505050'},
        {path: 'ongoingFiscalYearValidated', label: 'Prj.', parentColumn: 'Exercice en cours', backgroundColor: '#eee', color: '#505050'},
        {path: 'ongoingFiscalYearRevised', label: 'Rév.', parentColumn: 'Exercice en cours', backgroundColor: '#eee', color: '#505050'}
    ]

    let globalColumns = [
        {path: 'budget', label: 'Bud.', parentColumn: 'Global', backgroundColor: '#505050', color: '#fff'},
        {path: 'validated', label: 'Prj.', parentColumn: 'Global', backgroundColor: '#505050', color: '#fff'},
        {path: 'estimated', label: 'Est.', parentColumn: 'Global', backgroundColor: '#505050', color: '#fff'},
        {path: 'revised', label: 'Rév.', parentColumn: 'Global', backgroundColor: '#505050', color: '#fff'}
    ]

    if(object.displayEngagementData) {
        fiscalYearColumns.push({path: 'ongoingFiscalYearValidatedEngagement', label: 'Dem.', parentColumn: 'Exercice en cours', backgroundColor: '#eee', color: '#505050'})
        globalColumns.push({path: 'validatedEngagement', label: 'Dem.', parentColumn: 'Global', backgroundColor: '#505050', color: '#fff'})

    }

    if(referenceObject && referenceObject.amountByRealizationType) {
        referenceObject.amountByRealizationType.filter(object =>  _.get(object, 'realizationType.status.id') === '1').forEach(
            object => {
                const key = object.realizationType?.code + object.budgetColumnType?.id
                const label = object.realizationType?.code

                if(object.budgetColumnType?.id === '1') {
                    globalColumns.push({
                        path: `amountByRealizationTypeColumn.${key}`,
                        label,
                        order: object.realizationType?.order,
                        parentColumn: object.budgetColumnType?.id === '1' ? 'Global' : 'Exercice en cours'
                    })
                } else {
                    fiscalYearColumns.push({
                        path: `amountByRealizationTypeColumn.${key}`,
                        label,
                        order: object.realizationType?.order,
                        parentColumn: object.budgetColumnType?.id === '1' ? 'Global' : 'Exercice en cours'
                    })
                }
            }
        )
    }

    fiscalYearColumns.sort((a, b) => {
        if(!a.parentColumn) a.parentColumn = ""
        if(!b.parentColumn) b.parentColumn = ""

        if (a.parentColumn < b.parentColumn) return -1
        if (a.parentColumn > b.parentColumn) return 1

        if (a.order < b.order) return -1
        if (a.order > b.order) return 1

        return 0
    })

    globalColumns.sort((a, b) => {
        if(!a.parentColumn) a.parentColumn = ""
        if(!b.parentColumn) b.parentColumn = ""

        if (a.parentColumn < b.parentColumn) return -1
        if (a.parentColumn > b.parentColumn) return 1

        if (a.order < b.order) return -1
        if (a.order > b.order) return 1

        return 0
    })

    const allColumns = [...fiscalYearColumns, ...globalColumns]

    const filteredTable = object[tablePath].filter(
        line => {
            line.amountByRealizationTypeColumn = line.amountByRealizationType.reduce((acc, object) => {
                const key = object.realizationType?.code + object.budgetColumnType?.id
                return {
                    ...acc,
                    [key]: object.amount
                }
            }, {})
            return allColumns.some(column => !!_.get(line, column.path))
        }
    )

    const totalLine = filteredTable.length < 2
        ? null
        : filteredTable.reduce((acc, line) => {

            allColumns.forEach((column) => {
                _.set(acc, column.path, _.get(acc, column.path) + _.get(line, column.path))
            }, {})
            return acc
        }, allColumns.reduce((acc, column) => {
            _.set(acc, column.path, 0)
            return acc
        }, {objet: 'Total'}))

    const splitTables = splitCollections(filteredTable, 11)

    return {fiscalYearColumns, globalColumns, columnGroupSpan: allColumns.length / 2, tables: splitTables, totalLine}
}

export function CrPdfConfig(moduleName, object, context, currency) {

    const [revisedData, revisedHistoryData] = ['revisedTable', 'revisedTableHistory'].map(path => getTablesAndColumns(path, object, context))
    const currentFiscalYearCode = _.get(object, 'currentFiscalYear.code')
    const revueDate = object.revueDate?.toLocaleDateString(context.language.id)
    return {
        content: [
            { path: 'realizationPdf.html' }
        ],
        context: {
            currency: currency.symbol,
            demandNumber: object.demandNumber,
            revueDate,
            recipients: moduleName === 'projectCommittee' ? object.sendTo.map(user => user.name).join(" ; ") : object.sendToP.map(user => user.name).join(" ; "),
            otherRecipients: moduleName === 'projectCommittee' ? object.sendToOthers.split(";").join(" ; "): object.sendToOthersP.split(";").join(" ; "),
            title: moduleName === 'projectCommittee' ? 'Compte rendu comité projet' : 'Compte rendu comité pilotage',
            columnGroupSpan: revisedData.columnGroupSpan,
            fiscalYear: currentFiscalYearCode,
            fiscalYearColumnsR: revisedData.fiscalYearColumns,
            globalColumnsR: revisedData.globalColumns,
            tablesR: revisedData.tables,
            totalR: revisedData.totalLine,
            fiscalYearColumnsRH: revisedHistoryData.fiscalYearColumns,
            globalColumnsRH: revisedHistoryData.globalColumns,
            tablesRH: revisedHistoryData.tables,
            totalRH: revisedHistoryData.totalLine,
            synthesis: moduleName === 'projectCommittee' ? object.synthesis: object.synthesisP,
            news: moduleName === 'projectCommittee' ? object.news: object.newsP,
            deadline: moduleName === 'projectCommittee' ? object.deadline: object.deadlineP,
            cost: moduleName === 'projectCommittee' ? object.cost : object.costP,
            treasury: moduleName === 'projectCommittee' ? object.treasury : object.treasuryP,
            committeeMinutes: moduleName === 'projectCommittee' ? object.committeeMinutes : object.committeeMinutesP,
        },
        configuration: {
            margin: {
                top: '100px',
                bottom: '100px',
                right: '30px',
                left: '30px',
            },
            header: {html: ""},
            footer: {
                html: `<div style="height: 22px; width: 600px; display: flex; justify-content: space-between; align-items: center"">
                    ${getBase64EncodedUrlImage("keenpoint-logo-horizontal.png", "; height: 22px; margin-left: 35px")}
                    <div style="font-size: 9px ;margin-right: 35px; font-family: sans-serif;" >${object.demandNumber} / ${revueDate}</div>
                    </div>`
            },
            pdfName: moduleName === 'projectCommittee' ? 'rapport-comité-projet.pdf' : 'rapport-comité-pilotage.pdf',
            outputInDB: true,
            cssSheet: '',
            waitUntil: 'networkidle0',
            templateDir: path.join(
                global.appRoot,
                global.isProd
                    ? 'buildServer/server/models/suiviEngagement/demandAndProject/pdfTemplates'
                    : 'src/server/models/suiviEngagement/demandAndProject/pdfTemplates'
            )
        }
    }
}
