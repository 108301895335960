const _ = require('lodash')
const moment = require('moment')

const dateToIntFormat = stringDate => {
    const date = moment(stringDate, 'YYYY-MM-DD')
    return 10000 * date.year() + 100 * (date.month()+ 1) + date.date()
}

const numberOfDaysInPeriodForYear = (start, end, year) => {
    let result = 0

    const startPeriodYear = moment(`${year}-01-01`)
    const endPeriodYear = moment(`${year}-12-31`)

    if(start.isAfter(endPeriodYear) || end.isBefore(startPeriodYear)) {
        result = 0
    } else {
        const tmpStart = start.isSameOrAfter(startPeriodYear)
            ? start
            : startPeriodYear

        const tmpEnd = end.isSameOrBefore(endPeriodYear)
            ? end
            : endPeriodYear

        result = _.round(moment.duration(tmpEnd.diff(tmpStart)).asDays() +1)
    }
    return result
}

const getYear = date => moment(date, 'YYYYMMDD').year()
const getMonth = date => moment(date, 'YYYYMMDD').month() +1
const getDayOfYear = date => moment(date, 'YYYYMMDD').dayOfYear()
const totalDaysInYear = year => moment(year+ '1231', 'YYYYMMDD').dayOfYear()
const roundNumber = number => _.round(number, 1)

const min = _.min
const max = _.max
const sum = _.sum
const avg = array => _.sum(array) / array.length
/*
td.informative {
    font-style: italic;
}

td.informative2 {
    font-style: italic;
    color: #4d9900;
}

td.review {
    background-color: #ee5f5b;
}

td.title {
    color: #f46d01;
    font-weight: bold;
}

td.important1 {
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    font-weight: bold;
}

td.important2 {
    font-weight: bold;
}
*/

const rowStyle = type => {
    let result = {}
    switch (type) {
        case 'normal':
            result = {
                minHeight: '30px',
                fontSize: '13px'
            }
            break;
        case 'normal-':
            result = {
                minHeight: '12px',
                fontSize: '8px'
            }
            break;
        case 'important':
            result = {
                fontWeight: 500,
                fontSize: '13px',
                minHeight: '25px',
            }
            break;
        case 'important+':
            result = {
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                fontWeight: 500,
                fontSize: '13px',
                minHeight: '30px',
            }
            break;
        case 'informative':
            result = {
                fontStyle: 'italic',
                fontSize: '13px',
                minHeight: '25px'
            }
            break;
        case 'informative+':
            result = {
                fontStyle: 'italic',
                fontSize: '13px',
                color: '#4d9900',
                minHeight: '25px'
            }
            break;
        case 'title':
            result = {
                height: '35px', backgroundColor: '#ffd9b2', fontSize: '19px', textAlign: 'center', borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', display: 'block', position: 'sticky', top: 41
            }
            break;
        case 'header':
            result = {
                height: '25px', backgroundColor: '#ffd9b2', fontSize: '13px', textAlign: 'center', borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', position: 'sticky', top: 76
            }
            break;
        case 'toCorrect':
            result = {
                color: '#f40416',
                fontWeight: 500
            }
            break;
        default:
            result = {}
            break;
    }
    return result
}

const formatNumber = (value, unit, precision) => {
    if(value === 0 || value === undefined) {
        return ''
    } else {
        let result = value
        switch (unit) {
            case 'percent':
                result = _.round(value * 100, 2).toString() + '%'
                break;
            case 'date':
                result = moment(value, 'YYYYMMDD').format('DD/MM/YYYY')
                break;
            case 'thousand':
                result = _.round(value / 1000, precision)
                break;
            case 'million':
                result = _.round(value / 1000000, precision)
                break;
            case 'normal':
                result = _.round(value, precision)
                break;
            default:
                result = value.toString();
                break;
        }
        return result
    }
}

const irr = (values, guess) => {
    // Credits: algorithm inspired by Apache OpenOffice

    // Calculates the resulting amount
    const irrResult = function (values, dates, rate) {
        const r = rate + 1;
        let result = values[0];
        for (let i = 1; i < values.length; i++) {
            result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365)
        }
        return result
    };

    // Calculates the first derivation
    const irrResultDeriv = function (values, dates, rate) {
        const r = rate + 1;
        let result = 0;
        for (let i = 1; i < values.length; i++) {
            const frac = (dates[i] - dates[0]) / 365;
            result -= frac * values[i] / Math.pow(r, frac + 1)
        }
        return result
    };

    // Initialize dates and check that values contains at least one positive value and one negative value
    const dates = [];
    let positive = false;
    let negative = false;
    for (let i = 0; i < values.length; i++) {
        dates[i] = i === 0 ? 0 : dates[i - 1] + 365
        if (values[i] > 0) positive = true
        if (values[i] < 0) negative = true
    }

    // Return error if values does not contain at least one positive value and one negative value
    if (!positive || !negative) return '#NUM!'

    // Initialize guess and resultRate
    let resultRate = typeof guess === 'undefined' ? 0.1 : guess;

    // Set maximum epsilon for end of iteration
    const epsMax = 1e-10;

    // Set maximum number of iterations
    const iterMax = 50;

    // Implement Newton's method
    let newRate, epsRate, resultValue;
    let iteration = 0;
    let contLoop = true;
    do {
        resultValue = irrResult(values, dates, resultRate)
        newRate =
            resultRate - resultValue / irrResultDeriv(values, dates, resultRate)
        epsRate = Math.abs(newRate - resultRate)
        resultRate = newRate
        contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax
    } while (contLoop && ++iteration < iterMax)

    if (contLoop) return '#NUM!'

    // Return internal rate of return
    return resultRate
}

// Hypothesis Configuration
// { id: '1', name: 'Annuelle – Chaque année' },
// { id: '2', name: 'Annuelle – Première année' },
// { id: '3', name: 'Annuelle – Sauf première année' },
// { id: '4', name: 'Annuelle – Title' },
// { id: '5', name: 'Non Annuelle' },
// { id: '6', name: 'Non Annuelle – Titre' },

const generateYears = (hypothesisType, startYear, numberOfYear) => {
    const endYear = numberOfYear
        ? startYear + numberOfYear + 1
        : startYear + 10
    switch (hypothesisType) {
        case '1':
            return _.range(startYear, endYear)
        case '3':
            return _.range(startYear + 1, endYear)
        case '2':
        case '4':
            return [startYear]
        default:
            return []
    }
}

const generateExercises = (hypothesisType, startYear) => {
    const years = generateYears(hypothesisType, startYear)
    switch (hypothesisType) {
        case '1':
        case '3':
        case '2':
        case '4':
            return years.map(o => ({ exercise: o }))
        default:
            return [{ exercise: '' }]
    }
}

const formatFormula = (formula) => {
    return `(() => { ${formula} })()`
}

export {
    irr, min, max, sum, avg, formatNumber, rowStyle,
    getYear, getMonth, getDayOfYear, totalDaysInYear,
    roundNumber, dateToIntFormat, numberOfDaysInPeriodForYear,
    generateYears, generateExercises, formatFormula
}
