import _ from 'lodash'
import {data, getPreviousExerciseLastMonth, applyOperation} from '../utils/stateComputationUtils'
import {facQuery, lnfQuery, fnlQuery, stoQuery, achatQueryACH, achatQueryFNP, achatQueryTRAAndTRV, achatQueryCCA, achatQueryRFC } from './queries'

export const getData = async month => {

    const currentContext = {
        month: month,
        mesh: ['groupSurFamilyCode'],
        element: {
            byCompany: false,
            meshEntity: 'groupSurFamily',
            meshEntityHeader: 'groupSurFamilyCode'
        }
    }

    const currentMonth = currentContext.month
    const previousExerciseLastMonth = getPreviousExerciseLastMonth(currentMonth)

    const currentMonthQuery = {column: 'fileYearMonth', operation: 'eq', value: currentMonth}
    const previousExerciseLastMonthQuery = {column: 'fileYearMonth', operation: 'eq', value: previousExerciseLastMonth}

    const dataMesh = currentContext.mesh
    const element = currentContext.element

    const turnoverCurrentMonthFAC = await data(dataMesh, [currentMonthQuery], ["value"], facQuery, element)

    const stoCurrentMonthFirstPart = await data(dataMesh, [currentMonthQuery], ["value"], stoQuery, element)
    const stoCurrentMonthSecondPart = await data(dataMesh, [previousExerciseLastMonthQuery], ["value"], stoQuery, element)
    const stoCurrentMonthExercise = applyOperation(stoCurrentMonthFirstPart, stoCurrentMonthSecondPart, dataMesh, 'value', 'substraction')

    const achatCurrentMonthExerciseACH = await data(dataMesh, [currentMonthQuery], ["value"], achatQueryACH, element)
    const fnpCurrentMonthFirstPart = await data(dataMesh, [currentMonthQuery], ["value"], achatQueryFNP, element)
    const fnpCurrentMonthSecondPart = await data(dataMesh, [previousExerciseLastMonthQuery], ["value"], achatQueryFNP, element)
    const achatCurrentMonthExerciseFNP = applyOperation(fnpCurrentMonthFirstPart, fnpCurrentMonthSecondPart, dataMesh, 'value', 'substraction')
    const achatCurrentMonthExerciseTRAAndTRV = await data(dataMesh, [currentMonthQuery], ["value"], achatQueryTRAAndTRV, element)
    const ccaCurrentMonthFirstPart = await data(dataMesh, [currentMonthQuery], ["value"], achatQueryCCA, element)
    const ccaCurrentMonthSecondPart = await data(dataMesh, [previousExerciseLastMonthQuery], ["value"], achatQueryCCA, element)
    const achatCurrentMonthExerciseCCA = applyOperation(ccaCurrentMonthFirstPart, ccaCurrentMonthSecondPart, dataMesh, 'value', 'substraction')
    const achatCurrentMonthExerciseRFC = await data(dataMesh, [currentMonthQuery], ["value"], achatQueryRFC, element)

    const achatCurrentMonthExerciseACHAndFNP = applyOperation(achatCurrentMonthExerciseACH, achatCurrentMonthExerciseFNP, dataMesh, 'value', 'addition')
    const achatCurrentMonthExerciseACHAndFNPAndTRAAndTRV = applyOperation(achatCurrentMonthExerciseACHAndFNP, achatCurrentMonthExerciseTRAAndTRV, dataMesh, 'value', 'addition')
    const achatCurrentMonthExerciseCCAAndRFC = applyOperation(achatCurrentMonthExerciseCCA, achatCurrentMonthExerciseRFC, dataMesh, 'value', 'addition')

    const achatCurrentMonthExercise = applyOperation(achatCurrentMonthExerciseACHAndFNPAndTRAAndTRV, achatCurrentMonthExerciseCCAAndRFC, dataMesh, 'value', 'substraction')

    const turnoverCurrentMonthExercise = turnoverCurrentMonthFAC
    const caMoinsAchatCurrentMonth = applyOperation(turnoverCurrentMonthFAC, achatCurrentMonthExercise, dataMesh, 'value', 'substraction')

    const result = applyOperation(caMoinsAchatCurrentMonth, stoCurrentMonthExercise, dataMesh, 'value', 'addition')

    const rounded = result.map(o => ({key: o.groupSurFamilyCode, value: _.round(o.value/1000), color: '#bf162c'}))

    return _.sortBy(rounded, 'key')
}
