const moment = require("moment");

const todayISO = moment().format("YYYY-MM-DD");

const dataTypes = ["string", "integer", "decimal", "financial", "date", "boolean", "id", "dateRange", "manual", "object", "month", "monthRange", "time"]

const defaultValues = {
    "string": "",
    "boolean": false,
    "integer": null,
    "decimal": null,
    "financial": null,
    "object": null,
    "date": null,
    "month": null,
    "monthRange": [null, null],
    "id": null,
    "time": moment().format("HH:mm"),
    "dateRange": [new Date(), new Date()] //TODO: manage function
}

const clientDefaultValues = {
    "string": "",
    "boolean": false,
    "integer": 0,
    "decimal": 0,
    "financial": 0,
    "object": null,
    "date": null,
    "month": null,
    "monthRange": [null, null],
    "id": "",
    "time": moment().format("HH:mm"),
    "dateRange": [todayISO, todayISO]
}

const allLinkTypes = ["OTM", "MTO", "OTO", "MTM"]

const OT = ["OTO", "OTM"]
const MT = ["MTO", "MTM"]
const TO = ["OTO", "MTO"]
const TM = ["OTM", "MTM"]


const oppositeLinksMap = {
    "OTO": "OTO",
    "MTM": "MTM",
    "OTM": "MTO",
    "MTO": "OTM"
}
export {
    defaultValues,
    dataTypes,
    clientDefaultValues,
    allLinkTypes,
    OT,
    MT,
    TO,
    TM,
    oppositeLinksMap
}
