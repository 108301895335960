import {
    VIEWER_SUCCESS,
    VIEWER_FAILURE,
    VIEWER_REQUEST,
    LOGIN_SUCCESS,
    SET_NOT_FOUND_PAGE,
    BEFORE_REDIRECTION, LOGIN_CONFIRMATION_SUCCESS
} from '../actions/index'

export const VIEWER_INVALIDATE = 'VIEWER_INVALIDATE'

export default (
    state = {
        previousPath: "",
        isFetching: false,
        didInvalidate: true,
        isRouteError: false,
        isNotFoundPage: false,
        error: null,
        errorStatus: null
    },
    action
) => {
    switch (action.type) {
        case VIEWER_FAILURE:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                error: action.error,
                errorStatus: action.status
            }
        case VIEWER_REQUEST:
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            }
        case VIEWER_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case LOGIN_SUCCESS:
        case LOGIN_CONFIRMATION_SUCCESS:
        case VIEWER_INVALIDATE:
            return {
                ...state,
                didInvalidate: true,
                error: null,
                errorStatus: null
            }
        case SET_NOT_FOUND_PAGE:
            return {
                ...state,
                isNotFoundPage: action.value
            }
        case BEFORE_REDIRECTION:
            return {
                ...state,
                previousPath: action.path
            }
        default:
            return state
    }
}
