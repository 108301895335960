import React from 'react'
import './ErrorPage.css'
import PropTypes from 'prop-types'
import logo from "../img/keenpoint-logo-horizontal.png";

const ErrorPage = ({ message, retry, status, t= x => x, applyNavbar = true }) => (
    <div className="ErrorPage-container">
        <div className="Login-form-container">
            {
                applyNavbar && <div className="Login-logo-container">
                    <img
                        alt="Login-logo"
                        src={logo}
                        width={250}
                    />
                </div>
            }
            <div className= "ErrorPage-well">
                <h1 style={{textAlign: 'center'}}>
                    {t('oops')}
                </h1>
                <h3> {t('unexpectedError')}</h3>
                <div>
                    <span><b> {t('errorCode')} :</b> {status}</span>
                    <div>
                        <b>{t('errorMessage')} :</b>
                        <p>
                            {message}
                        </p>
                    </div>
                </div>
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    <button type="button" className="btn remove-button-outline" onClick={retry} style={{width: '200px', background: 'white', border: '1px solid #cccccc'}} >
                        <span>{t('retry')}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
)

ErrorPage.propTypes = {
    message: PropTypes.string.isRequired,
    status: PropTypes.number,
    retry: PropTypes.func,
    t: PropTypes.func
}

export default ErrorPage
