import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoginConfirmation from '../../../../components/LoginConfirmation'

class LoginPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLogging: props.isLogging,
            maskedMail: props.maskedMail,
            maskedPhone: props.maskedPhone
        }
    }
    // new (React v16.3.0) lifecycle
    static getDerivedStateFromProps(props, state) {
        if (props.isLoggedIn && state.isLogging && !props.isLogging) {
            const previousPath = props.previousPath || '/business'
            props.history.push(previousPath)
        }
        return {
            isLogging: props.isLogging
        }
    }

    handleTwoFactorAuth = token => {
        this.props.handleTwoFactorAuth(this.props.mail, token)
    }

    handleTwoFactorAuthReset = () => {
        this.props.handleTwoFactorAuthReset(this.props.mail)
    }

    render() {
        const {t} = this.props
        return <LoginConfirmation
            t={t}
            handleTwoFactorAuth={this.handleTwoFactorAuth}
            handleTwoFactorAuthReset={this.handleTwoFactorAuthReset}
            maskedMail={this.state.maskedMail}
            maskedPhone={this.state.maskedPhone}
        />
    }
}

LoginPage.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
}

export default LoginPage
