const _ = require('lodash')

if (!global.isClient) {
    var async = require('async')
}

const addJoinedEntity = entity => object => (object.joinedEntity = entity.name)
const addJoinedEntityName = entityName => object => (object.joinedEntity = entityName)

export const joinEntities = function(parentEntity, childEntities) {
    //kludge, waiting for integration into enhancers
    const getChildEntities = () =>
        childEntities.map(
            childEntity =>
                _.isString(childEntity)
                    ? _.find(parentEntity.model.entities, { name: childEntity })
                    : childEntity
        )

    return _.defaults(parentEntity, {
        name: parentEntity.name,
        isJoinedEntity: true,
        db: {
            find: function(context, callback) {
                async.parallel({
                        families: callback => async.waterfall([
                            callback => global.app.S.FamilyAxis.find(
                                {
                                    query: {useInScope: true},
                                    group: context.group
                                },
                                callback
                            ),
                            (familyAxis, callback) => {
                                if(familyAxis){
                                    const ids =  familyAxis.map(o => new global.ObjectID(o.id));
                                    global.app.S.Family.db.find(
                                        {
                                            query: {familyAxis: {$in: ids}},
                                            group: context.group
                                        },
                                        (e, children) => {
                                            if (e) return callback(e)
                                            children.forEach(addJoinedEntityName("Family"))
                                            callback(null, children)
                                        }
                                    )
                                }
                                else callback()
                            }
                        ], callback),
                        organizations: callback => async.waterfall([
                            callback => global.app.S.OrganizationAxis.find(
                                {
                                    query: {useInScope: true},
                                    group: context.group
                                },
                                callback
                            ),
                            (organizationAxis, callback) => {
                                if(organizationAxis){
                                    const ids =  organizationAxis.map(o => new global.ObjectID(o.id));
                                    global.app.S.Organization.db.find(
                                        {
                                            query: {organizationAxis: {$in: ids}},
                                            group: context.group
                                        },
                                        (e, children) => {
                                            if (e) return callback(e)
                                            children.forEach(addJoinedEntityName("Organization"))
                                            callback(null, children)
                                        }
                                    )
                                }
                                else callback()
                            }
                        ], callback),
                    },
                    (e, {families, organizations}) => {
                        if (e) return callback(e)
                        const children = [...families, ...organizations]
                        parentEntity.db.fromDBO(children, context, callback)
                    }
                )
            },

            get: function(id, context, callback) {
                let childResult
                async.some(
                    getChildEntities(),
                    (childEntity, callback) => {
                        childEntity.db.get(id, context, (e, child) => {
                            if (child) addJoinedEntity(childEntity)(child)
                            callback(e, (childResult = child))
                        })
                    },
                    e => callback(e, childResult)
                )
            },

            getDBObject: function(id, context, callback) {
                let childResult
                async.some(
                    getChildEntities(),
                    (childEntity, callback) => {
                        childEntity.db.getDBObject(id, context, (e, child) => {
                            if (child) addJoinedEntity(childEntity)(child)
                            callback(e, (childResult = child))
                        })
                    },
                    e => callback(e, childResult)
                )
            },

            createRef: function(object, dbType) {
                return global.DBRef(
                    parentEntity.collectionName,
                    object._id || global.ObjectID(object.id)
                )
            }
        }
    })
}
