const _ = require('lodash')
const { setFieldVisibility, setDataList, setFormButtons} = require("../../../apps/KpModule/actions")
const { validateButton, rectifyButton, refuseButton, returnButton } = require("./utils")

export const module_ = {
    object: 'Reallocation',
    name: 'ReallocationValidation',
    tKey: 'mTitle_reallocationValidation',
    protectedExport: true,
    objectIdentifier: 'reference',
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    removable: false,
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: 'reference', tKey: 'reallocation'},
            {path: 'beneficiary', display: 'fullName'},
            {path: 'registrationNumber', initiallyNotVisible: true},
            {path: 'benefit', initiallyNotVisible: true},
            {path: 'exercise', initiallyNotVisible: true},
            {path: 'requests', display: 'sequence', initiallyNotVisible: true},
            {path: 'reallocationAmount', initiallyNotVisible: true},
            {path: 'amountToRepay', tKey: 'paidAmount'},
            //{path: 'refundSent', width: 100},
            {path: 'refundReceived', width: 100},
            {path: 'status', translate: true},
            {path: 'date', tKey: 'dateAndTime'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields: [
                {path: 'reference', tKey: 'reallocation', disabled: true},
                {
                    path: 'beneficiary',
                    display: 'fullName',
                    fieldPath: [
                        'id', 'firstname', 'lastname', 'fullName','registrationNumber', 'status.id', 'entryDate', 'releaseDate', 'split',
                        'rightHolders'
                        //, 'rightHolders.dependent', 'rightHolders.kinship.id', 'rightHolders.birthDate'
                    ],
                    editable: false
                },
                {path: 'benefit', fieldPath: ['id', 'limit.maximum'], editable: false},
                {
                    path: 'requests',
                    display: 'referenceWithStatus',
                    fieldPath: ['id', 'status', 'ageLimit', 'refund', 'referenceWithStatus', 'benefit.id', 'benefit.requestGroup.id', 'benefit.exercise.id',
                        'benefit.accountNumber', 'benefit.analyticalCode.code', 'benefit.limit.id', 'user.id', 'user.accountNumber'],
                    filters: ['byBenefit'],
                    editable: false,
                    required: true,
                },
                {
                    path: 'amountToRepay',
                    tKey: 'paidAmount',
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if(newValue && parseFloat(newValue) === 0) {
                                store.dispatch(setFieldVisibility('e_refundSent', false))
                                store.dispatch(setFieldVisibility('e_refundReceived', false))
                            }
                        }
                    }
                },
                {path: 'refundSent', editable: false},
                {path: 'refundReceived', default: false},
                {path: 'reallocationAmount', hidden: true},
                {path: 'comments'},
                {path: 'files'},
                {path: 'status', hidden: true}
            ],
            onOpen: ({state, store}) => {

                store.dispatch(setFormButtons([validateButton, rectifyButton, refuseButton, returnButton]))

                const benefit = state.edit.object.data.benefit
                const requests = state.edit.object.data.requests

                if(benefit) {
                    store.dispatch(
                        setDataList(
                            "m-R-reallocationValidation.Reallocation_benefit",
                            [state.edit.object.data.benefit]
                        )
                    )
                }
                if(requests) {
                    store.dispatch(
                        setDataList(
                            "m-R-reallocationValidation.Reallocation_requests",
                            state.edit.object.data.requests
                        )
                    )
                }
            }
        }
    },
    filters: [
        {
            path: 'byStatus',
            query: () => ({status: 'ongoing'})
        }
    ]
}
