export const entity =
    {
        name: 'PointsOfAttention',
        facets: ['codeName']
    }


export const module_ = {
    object: 'PointsOfAttention',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: `point d'attention`,
    viewMap: {
        dt: [
           'code',
           'name'
        ],
        form: [
            'code',
            'name'
        ]
    }
}