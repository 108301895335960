export const module_ = {
    object: 'Invoice',
    name: 'InvoiceControl',
    tKey: 'mTitle_control',
    objectIdentifier: 'invoiceNumber',
    newable: false,
    removable: false,
    defaultSortBy: 'deadline',
    defaultSortDirection: 'ASC',
    category: {
        path: 'invoice',
        icon: 'fileText'
    },
    viewMap: {
        dt: [
            {path: 'wording', initiallyNotVisible: true},
            {path: 'organization'},
            'invoiceNumber',
            {path: 'invoiceDate'},
            'deadline',
            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation'},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            'updated'
        ],
        form: {
            fields: [
                {path: 'wording', disabled: true},
                {path: 'organization', editable: false},
                {path: 'invoiceNumber', editable: false},
                {path: 'invoiceDate', editable: false},
                {path: 'deadline', editable: false},
                {
                    path: 'invoiceLines',
                    defaultSortBy: 'accountsGroup',
                    defaultSortDirection: 'ASC',
                    newable: false,
                    viewMap: {
                        dt: [
                            {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName'},
                            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation'}
                        ],
                        form: [
                            {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName', editable: false},
                            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation', positiveNumber: true, editable: false},
                            {path: 'object', editable: false}
                        ]
                    }
                },
                {path: 'amountInclTax', positiveNumber: true, editable: false},
                {path: 'files', tKey: 'supportingDocuments', required: true, editable: false},
                'comments',
                {path: 'status', hidden: true},
                {path: 'user', hidden: true},
                {path: 'controller', hidden: true},
                {path: 'validator', hidden: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
        }
    },
    filters: [
        {
            path: "controlStep",
            match: function(invoice) {
                return ['ongoing', 'justification'].includes(invoice.status)
            }
        }
    ]
}
