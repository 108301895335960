import React from 'react'
import PropTypes from 'prop-types'
import './LabeledChechboxField.css'

const LabeledCheckboxField = ({ value, onChange, label, subtitle, disabled, t = x => x }) => (
    <div className="LabeledCheckboxField">
        <div className="LabeledCheckboxField-label">{label}</div>
        {subtitle && <div className="LabeledCheckboxField-subtitle">{subtitle}</div>}
        <div className="LabeledCheckboxField-container" style={{display: "flex"}}>
            <input
                type="checkbox"
                className="LabeledCheckboxField-input"
                checked={value}
                onChange={() => undefined}
                disabled={disabled}
            />
            <span
                className="cr"
                onClick={event => {
                    if(onChange) {
                        event.stopPropagation()
                        !disabled && onChange(!value)
                    }
                }}
            >
                    <i className="cr-icon glyphicon glyphicon-ok" onClick={event => {
                        if(!onChange) {
                            event.stopPropagation()
                        }
                    }} />
            </span>
        </div>
    </div>

)

LabeledCheckboxField.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default LabeledCheckboxField
