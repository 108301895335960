const _ = require('lodash')
const moment = require('moment')
const async = require('async')


export const entity = {
    name: 'AccountModel',
    facets: [{ name: 'codeName'}],
    fields: [
        {type: 'User', nullable: true},
        'updated',
        'DataType',
        'AccountCategory',
        'Kind',
        'AccountModelType',
        'AccountModelLoop',
        { path: 'specificS2S1', type: 'boolean' },
        'calledBy',
        {
            path: 'calledBy',
            $f: (accountModel, context, callback) => {
                const regexPattern = `\\b${accountModel.code}\\b`
                global.app.B.AccountModel.collection
                    .find({formula: { $regex: regexPattern}})
                    .toArray((e, models) => {
                        if(e) return callback(e)
                        callback(null, models.map(model => model.code).join(';'))
                    })
            }
        },
        'formula',
        'description',
        {
            path: 'fullInfos',
            fieldPath: [
                'code',
                'name',
                'accountCategory.code',
                'accountCategory.name'
            ],
            f: function() {
                return `(${this.accountCategory.code} - ${this.accountCategory.name}) ${this.code} - ${this.name}`
            }
        }
    ],
    filters: [
        {
            name: 'onlyKindFilialPaysDistribution',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.B.AccountCategory.find(
                    {
                        group: context.group,
                        query: { kind: {$in: ['1', '5', '8']} }
                    },
                    (e, accountCategories) => {
                        const ids = accountCategories.map(
                            ac => new global.ObjectID(ac.id)
                        )
                        callback(e, {
                            accountCategory: { $in: ids }
                        })
                    }
                )
            }
        },
        {
            name: 'onlyKindProjectBpStore',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.B.AccountCategory.find(
                    {
                        group: context.group,
                        query: { kind: '6' }
                    },
                    (e, accountCategories) => {
                        const ids = accountCategories.map(
                            ac => new global.ObjectID(ac.id)
                        )
                        callback(e, {
                            accountCategory: { $in: ids }
                        })
                    }
                )
            }
        },
        {
            name: 'filterAccountModelForHypothesisModel',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const hypothesisKind = _.get(context, 'data.hypothesisKind.id')
                if(hypothesisKind) {
                    global.app.B.AccountCategory.find(
                        {
                            group: context.group,
                            query: {
                                kind: {$in: ['6', '7']},
                            }
                        },
                        (e, accountCategories) => {
                            const ids = accountCategories.map(
                                ac => new global.ObjectID(ac.id)
                            )
                            callback(e, {
                                accountModelType: '5' === hypothesisKind
                                    ? 'value'
                                    : 'year',
                                accountCategory: { $in: ids }
                            })
                        }
                    )
                } else {
                    callback(null, { _id: false })
                }
            }
        },
        {
            name: 'onlyKindProjectBpStoreAndBpStoreDetails',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.B.AccountCategory.find(
                    {
                        group: context.group,
                        query: { kind: {$in: ['6', '7']} }
                    },
                    (e, accountCategories) => {
                        const ids = accountCategories.map(
                            ac => new global.ObjectID(ac.id)
                        )
                        callback(e, {
                            accountCategory: { $in: ids }
                        })
                    }
                )
            }
        },
        {
            name: 'onlyKindStore',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.B.AccountCategory.find(
                    {
                        group: context.group,
                        query: { kind: {$in: ['9']} }
                    },
                    (e, accountCategories) => {
                        const ids = accountCategories.map(
                            ac => new global.ObjectID(ac.id)
                        )
                        callback(e, {
                            accountCategory: { $in: ids }
                        })
                    }
                )
            }
        },
        {
            name: 'byKind',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const kind = _.get(context, 'data.kind.id')
                if (!kind) callback(null, { _id: false })
                else callback(null, { kind })
            }
        },
        {
            name: 'byAccountCategoryKind',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const kind = _.get(context, 'data.kind.id')
                if (!kind) callback(null, { _id: false })
                else global.app.B.AccountCategory.find(
                    {
                        group: context.group,
                        query: { kind }
                    },
                    (e, accountCategories) => {
                        const ids = accountCategories.map(
                            ac => new global.ObjectID(ac.id)
                        )
                        callback(e, {
                            accountCategory: { $in: ids }
                        })
                    }
                )
            }
        },
        {
            name: 'byKindAndYearsType',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const kind = _.get(context, 'data.kind.id')
                if (!kind) callback(null, { _id: false })
                else
                    global.app.B.AccountCategory.find(
                        {
                            group: context.group,
                            query: { kind }
                        },
                        (e, accountCategories) => {
                            const ids = accountCategories.map(
                                ac => new global.ObjectID(ac.id)
                            )
                            callback(e, {
                                accountModelType: 'year',
                                accountCategory: { $in: ids }
                            })
                        }
                    )
            }
        },
        {
            name: 'byKindAndValueType',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const kind = _.get(context, 'data.kind.id')
                if (!kind) callback(null, { _id: false })
                else
                    global.app.B.AccountCategory.find(
                        {
                            group: context.group,
                            query: { kind }
                        },
                        (e, accountCategories) => {
                            const ids = accountCategories.map(
                                ac => new global.ObjectID(ac.id)
                            )
                            callback(e, {
                                accountModelType: 'value',
                                accountCategory: { $in: ids }
                            })
                        }
                    )
            }
        }
    ],
    ps: {
        delete: [
            {
                $$v: function (accountModel, callback) {
                    global.app.B.StateModel.collection
                        .find({stateModelLines: { $elemMatch: {accountModel: { $eq: global.ObjectID(accountModel.id) }}}})
                        .toArray((e, models) => {
                            if(e) return callback(e)
                            if(models && models.length) return callback(new Error('accountModelUsedInStateModels'))
                            return callback()
                        })
                }
            },
            {
                $$v: function (accountModel, callback) {
                    const regexPattern = `\\b${accountModel.code}\\b`
                    global.app.B.AccountModel.collection
                        .find({formula: { $regex: regexPattern}})
                        .toArray((e, models) => {
                            if(e) return callback(e)
                            if(models && models.length) return callback(new Error('accountModelUsedInOtherModels'))
                            return callback()
                        })
                }
            }
        ]
    },
    validateSave: function(newObject, oldObject, context, callback) {
        const categoryCode = _.get(newObject, 'accountCategory.code')
        const modelCategory = newObject.code.split('.')

        if(modelCategory.length !== 2 || !modelCategory[0] || !modelCategory[1]) return callback(new Error('accountModelCodeRules'))

        const regex = new RegExp('\\W')
        const test1 = regex.test(modelCategory[0])
        const test2 = regex.test(modelCategory[1])

        if(test1 || test2) callback(new Error('cannotUseNonWordCharacter'))
        else if(categoryCode !== modelCategory[0]) callback(new Error(context.tc('accountModelCodeValidation', {code: categoryCode})))
        else callback()
    },
    beforeSave: async function (newObject, oldObject, context, callback) {
        newObject.updated = moment().format('YYYY-MM-DD HH:mm:ss')
        newObject.user = context.user
        if(oldObject && oldObject.code !== newObject.code) {
            const regexPattern = `\\b${oldObject.code}\\b`
            newObject.formula = newObject.formula.replace(new RegExp(regexPattern, 'g'), newObject.code)
            const models = await global.app.B.AccountModel.collection
                .find({formula: { $regex: regexPattern}})
                .toArray()
            try {
                if(models) {
                    await async.parallel(models.map(
                        model => callback => {
                            const newFormula = model.formula.replace(new RegExp(regexPattern, 'g'), newObject.code)
                            global.app.B.AccountModel.collection.updateOne(
                                {_id: new global.ObjectID(model._id)},
                                {$set: { formula : newFormula}},
                                callback
                            )
                        }
                    ))
                    return callback(null, newObject, oldObject)
                }

                return callback(null, newObject, oldObject)
            }
            catch (err) {
                return callback(err);
            }

        }
        callback(null, newObject, oldObject)
    }
}

export const module_ = {
    object: 'AccountModel',
    tKey: 'mTitle_accountModel',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'accountCategory', initiallyNotVisible: true },
            //'kind',
            'code',
            'name',
            { path: 'accountModelType', translate: true },
            { path: 'dataType', tKey: 'unit', display: 'name', translateName: true },
            { path: 'specificS2S1', tKey: 'M2M1', initiallyNotVisible: true, width: 100},
            { path: 'updated', tKey: 'date', initiallyNotVisible: true},
            { path: 'user', display: 'name', initiallyNotVisible: true}
        ],
        form: [
            { path: 'accountCategory', fieldPath: ['id', 'code', 'name']},
            //'kind',
            'code',
            'name',
            { path: 'accountModelType', translate: true },
            { path: 'dataType', tKey: 'unit', display: 'name', translateName: true},
            { path: 'accountModelLoop', translate: true },
            { path: 'specificS2S1', tKey: 'specificM2M1'},
            { path: 'calledBy', type: 'creatableTags', editable: false },
            { path: 'formula', tKey: 'model', type: 'textarea', dynamicHeight: true },
            { path: 'description', tKey: 'description', type: 'textarea' }
        ]
    },
    filters: [
        {
            path: 'accountCategory',
            object: 'AccountCategory',
            display: 'fullName',
            placeholder: 'selectAccountsCategory',
            client: true,
            default: { id: null },
            width: 12,
            query: function(context) {
                const accountCategoryId = _.get(
                    context.data,
                    'accountCategory.id'
                )

                return accountCategoryId
                    ? {
                        accountCategory: new global.ObjectID(
                            accountCategoryId
                        )
                    }
                    : { _id: false }
            }
        }
    ]
}
