export const entity = {
    name: 'AccountBPA',
    facets: ['description'],
    fields: [
        { type: 'AccountModel', unique: true },
        { path: 'bp', type: 'boolean' },
        { path: 'adjustable', type: 'boolean' }
    ]
}
export const module_ = {
    kp: true,
    object: 'AccountBPA',
    tKey: 'mTitle_accountBPA',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [{ path: 'accountModel', display: 'fullName' }, 'bp', 'adjustable'],
        form: [
            {
                path: 'accountModel',
                display: 'fullName',
                filters: ['onlyKindProjectBpStore']
            },
            'bp',
            'adjustable',
            'description'
        ]
    }
}
