const _ = require('lodash')
const { forceMap } = require("../../utils/functional");
const dd = require("../../default-engine/engine").default;

export default function(workflowGenerator) {
    const profileConfigEntityName = workflowGenerator.profileConfigEntity.name;
    const profileConfigEntityPath = _.lowerFirst(profileConfigEntityName);

    const entityFields = [
        {
            path: "workflow",
            type: "ObjectWorkflow",
            link: "OTO",
            nullable: true,
            ps: {object: [{$$u: function(workflow, workflowObject, callback) {
                // assign static workflow if needed
                if (this.options.$v && !workflow && workflowGenerator.utils.objectDefinesMesh(workflowObject)) {
                    const context = this.options.context;
                    workflowGenerator.utils.getStaticWorkflow(workflowObject, context, callback);
                } else {
                    callback(null, workflow);
                }
            }}]}
        },

        {path: "status", type: "WorkflowStatus", link: "OTO", nullable: true},

        {
            path: "statusString",
            lazy: true,
            fieldPath: ["status.order", "status.step"],
            $f: (workflowObject, context, callback) => {
                if (!workflowObject.status) return callback(null, "");
                const step = workflowGenerator.utils.getStepByName(workflowObject.status.step);
                const stepTitle = context.tc && context.tc(step.tKey)
                const statusString = workflowObject.status.order ?
                    `${stepTitle} / ${workflowObject.status.order}` :
                    `${stepTitle}`;
                callback(null, statusString);
            }
        },

        {
            path: `step${profileConfigEntityName}s`,
            lazy: true,
            fieldPath: ["status.order", "status.step", "workflow.profileConfigs.step", "workflow.profileConfigs.order", "workflow.profileConfigs.active"],
            f: function () {
                if (!this.workflow || !this.status) return "";
                return _(this.workflow.profileConfigs)
                    .filter({
                        step: this.status.step,
                        order: this.status.order,
                        active: true
                    })
                    .map(`${profileConfigEntityPath}.name`)
                    .value()
                    .join(", ");
            }
        },

        {
            path: "buttons",
            lazy: true,
            fieldPath: ["status.order", "status.step", "workflow.profileConfigs", ...workflowGenerator.formHabilitationFieldPath],
            $f: function(workflowObject, context, callback) {
                if (context.user && workflowObject.workflow && workflowObject.status && context.restAction && context.restAction.type === "R") {
                    callback(null, _.cloneDeep(workflowGenerator.utils.getStatusButtons(
                        workflowObject.status, context, workflowObject
                    )));
                } else {
                    callback(null, _.cloneDeep(workflowGenerator.withoutWorkflowButtons));
                }
            }
        }
    ];

    const extraEntities = model => [
        {
            name: "UsersPerStatus",
            type: "mongoInternal",
            fields: [
                {path: "status"},
                {path: "users"}
            ],
            model
        },
        {
            name: "WorkflowStatus",
            type: "mongoInternal",
            fields: [
                {path: "step"},
                {path: "order", type: "integer"}
            ],
            model
        },
        {
            name: "ObjectWorkflow",
            type: "mongoInternal",
            fields: [
                {path: "profileConfigs", type: "ObjectProfileConfig"}
            ],
            model
        },
        {
            name: "ObjectProfileConfig",
            type: "mongoInternal",
            fields: [
                {type: profileConfigEntityName},
                {path: "profile"},
                {path: "step"},
                {path: "order", type: "integer"},
                {path: "maxOrder", type: "integer"},
                {path: "active", type: "boolean"},
                {path: "multiOrder", type: "boolean", nullable: true},
                {path: "passiveAfter", type: "boolean", nullable: true},
                {path: "passiveBefore", type: "boolean", nullable: true},
            ],
            model
        }
    ];

    return {norm: {fields: [
        {path: "ps", default: {}},
        {path: "ps.object", default: [], $p: {$u: function (processes) {
            return [
                ...processes,
                function (workflowObjectOrObjects) {
                    return forceMap(workflowObjectOrObjects, workflowObject => {
                        if (workflowObject) {
                            let executedObject;
                            let firstStatus;

                            if(this.options.$v) {
                                const context = this.options.context;
                                if(workflowObject.workflow) {

                                    // set status
                                    if(workflowObject.status && workflowObject.status.step) {

                                        const action = context.action && workflowGenerator.utils.getActionByName(workflowObject.status.step, context.action);

                                        // change step and add comments
                                        if(action) executedObject = action.execute(workflowObject, context);

                                    } else {
                                        // if workflow didn't started, initialize with the first step
                                        firstStatus = workflowGenerator.utils.getFirstStatus(workflowObject, context);
                                    }
                                }
                            }

                            return _.defaults({status: firstStatus}, executedObject, workflowObject);
                        }
                    });
                }
            ];
        }}},
        {path: "fields", default: [], $p: {$f: (fields, entity, entities, model) => {

            const commentsFacet = _.find(require("../facets").default, {name: "comments"});
            dd.normalizeInternal(entity, commentsFacet.norm, {facet: commentsFacet}, [entities, model]);

            fields.push(..._.cloneDeep(entityFields));
            entities.push(...extraEntities(model));
        }}}
    ]}};
};
