import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {DownloadCloud} from 'react-feather'
import './DownloadDataTable.css'
import { generateObjectDataGetter } from './DataTable'
import {translate} from "react-i18next"

const DownloadDataTable = ({ objects, filename, columns, t, user, exportTableObject, protectedExport, language }) => {
    const data = objects && objects.map(
        object => columns.reduce((acc, column) => {
            if(!column.dataGetter) return {
                ...acc,
                [column.path]: _.get(object, column.path)
            }
            return {...acc, [column.path]: column.dataGetter(object)}
        } , {})
    )

    const handleCLick = event => {
        exportTableObject(`${moment().format("YYYY-MM-DD_HH-mm-ss")}_${filename || 'data'}`, columns, data, user, protectedExport, language)
        event.preventDefault()
    }

    return (
        <button
            key="downloadDataTable"
            className="DataTable-Board-icon-container"
            onClick={handleCLick}
            title={t('exportDataTable')}
        >
            <DownloadCloud color="#9400d3ed" className="DataTable-Board-icon"/>
        </button>
    )
}

DownloadDataTable.propTypes = {
    filename: PropTypes.string,
    objects: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            cellDataGetter: PropTypes.func
        })
    ).isRequired
}

export default translate('platform')(DownloadDataTable)
