export const module_ = {
    name: "arbitration",
    object: 'Demand',
    category: {
        path: 'Projet',
    },
    tKey: 'arbitrationCommittee',
    newable: false,
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° Projet'},
            {path: 'title'},
            {path: 'relatedProject', tKey: 'projet principal', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'organizationAndMesh', tKey: 'organisation'},
            {path: 'demandNature', tKey: 'category', translateName: true},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields: [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                    //hidden: true,
                    //default: {id:'1'},
                    //display: 'name2'
                },
                {path: 'demandNumber', tKey: 'N° Projet',disabled: true},
                {path: 'relatedProject', tKey: 'projet principal', display: 'demandNumberAndTitle', disabled: true, hidden: true},
                {path: 'title', disabled: true},
                {path: 'description', type: 'richTextEditor', required: true, disabled: true,},
                {path: 'enjeux', type: 'richTextEditor', required: true, disabled: true,},
                {path: 'objectifs', type: 'richTextEditor', required: true, disabled: true,},
                {path: 'organizationAndMesh', display: 'nameWithOrganizationalAxis', tKey: 'organisation',disabled: true},
                {path: 'demandCategory', tKey: 'processType',disabled: true, hidden: true},
                {
                    path: 'demandNature',
                    tKey: 'category',
                    disabled: true,
                    translateName: true,
                },
                {
                    path: 'imputations',
                    hidden: true,
                    type: 'accordion',
                    disabled: true,
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType'},
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant mensuel (k)'},
                        ],
                        form: {
                            fields : [
                                { path: 'objet', required: true, unique: true},
                                {path: 'organizationalMesh', tKey: 'business unit', filters:['byOrganizationOrMesh']},
                                { path: 'imputationType', required: true},
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            if (newValue){
                                                let total = 0
                                                newValue.forEach(object => {
                                                    total+= parseFloat(object.amount)
                                                })
                                                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                                                const totalAmountPerImputationField = imputationsField.viewMap.form.fields.find( field => field.path === 'totalAmountPerImputation')
                                                totalAmountPerImputationField.setValue(total)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea'},
                            ]
                        }
                    },
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.totalAmountPerImputation)
                                })
                                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                                totalAmountField.setValue(total)
                            }
                        }
                    }
                },
                {
                    path: 'arbitrateTextList',
                    tKey: 'justifications demandes arbitrages',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            {path: 'user', display: 'name'
                            },
                            {path: 'step', tKey: "étape"},
                            'order',
                            {path: "arbitrationType", tKey: "type d'arbitrage"},
                            "date",
                        ],
                        form: [
                            {
                                path: 'user',
                                display: 'name',
                                editable: false
                            },
                            {
                                path: 'step',
                                tKey: 'étape',
                                editable: false
                            },
                            {
                                path: 'order',
                                editable: false
                            },
                            {path: "arbitrationType", tKey: "type d'arbitrage",
                                editable: false},
                            {path: 'motif', tKey: 'justifications', type: 'richTextEditor',
                                editable: false},
                        ]
                    },
                },
                {path: 'plafond', hidden: true},
                {path: 'buttons', hidden: true},
                {path: 'arbitratorsFunctions', hidden: true},
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {
                    path: 'workflow',
                    hidden: true
                },
                {path: 'status', hidden: true},
                {path: 'contributorsFunctions', hidden: true},
                {path: 'delegateTo', hidden: true},
                {path: 'arbitrateText', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                'comments',
                'files',
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {path: 'startMonth', tKey: 'début', hidden: true},
                {path: 'endMonth', tKey: 'fin', hidden: true},
                {path: 'totalAmount', tKey: 'plannedTotalAmount', hidden: true },
                {
                    path: 'startFiscalYear',
                    hidden: true,
                    fieldPath: ['id', 'fiscalYearRange']
                },
                {
                    path: 'endFiscalYear',
                    hidden: true,
                    fieldPath: ['id', 'fiscalYearRange']
                },
            ]
        }
    },
    filters : [ 'myArbitration' ]
}
