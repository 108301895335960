import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Fields } from 'redux-form'
import { I18n } from 'react-i18next'
import MonthPickerRange from '../../../../components/Calendar/MonthPickerRange'
import { changeRangeCalendar } from '../../actions'
import {
    getModule,
    getDateRangeEndOpened,
    getDateRangeStartOpened,
    getLanguage
} from '../../selectors'

// binding for redux-form
const VisualComponent = ({
    path,
    names,
    startOpened,
    endOpened,
    onOpenChange,
    module,
    language,
    error,
    touched,
    t,
    ...other
}) => {
    const startInput = _.get(other, names[0]).input
    const endInput = _.get(other, names[1]).input
    const translatedError = t(error)
    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <MonthPickerRange
                    path={path}
                    startDate={startInput.value}
                    endDate={endInput.value}
                    startOpened={startOpened}
                    endOpened={endOpened}
                    onChange={(index, date) => {
                        if (date) {
                            index === 0
                                ? startInput.onChange(date)
                                : endInput.onChange(date)
                        }
                    }}
                    onOpenChange={onOpenChange}
                    language={language}
                    startPlaceholder={t('pickDate')}
                    endPlaceholder={t('pickDate')}
                    error={translatedError}
                    touched={touched}
                    t={t}
                />
            )}
        </I18n>
    )
}

const MonthPickerRangeComponent = ({
    field,
    startOpened,
    endOpened,
    changeRangeCalendar,
    module,
    error,
    touched,
    language,
    t
}) => {
    const fieldName = field.path
    return (
        <Fields
            names={[`${fieldName}[0]`, `${fieldName}[1]`]}
            path={field.tKey || field.path}
            component={VisualComponent}
            startOpened={startOpened}
            endOpened={endOpened}
            language={language}
            onOpenChange={(index, opened) => {
                changeRangeCalendar(field.id, index, opened)
            }}
            error={error}
            touched={touched}
            module={module}
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    startOpened: getDateRangeStartOpened(state, field.id),
    endOpened: getDateRangeEndOpened(state, field.id),
    language: getLanguage(state),
    module: getModule
})

const mapDispatchToProps = {
    changeRangeCalendar: changeRangeCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(
  MonthPickerRangeComponent
)
