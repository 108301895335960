import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {reduxForm, getFormSyncErrors, getFormSubmitErrors, touch, isDirty, getFormValues} from 'redux-form'
import { I18n } from 'react-i18next'
import _ from 'lodash'
import './Form.css'
import { setUiMode, setObjectMode, emptyEditObject } from '../../actions'
import { saveFormObject, createObject } from '../../actions/api'
import { executeLoadSubscriptions, executeAfterSaveSubscriptions } from '../../actions/module'
import { Form, FormField, FormButtons } from '../../../../components/Form'
import ModuleFormField from './Field'
import BsButton from '../../../../components/BsButton'
import { toastr } from 'react-redux-toastr'
import SpinningDots from '../../../../components/Loader/SpinningDots'
import {
    getEditFields,
    getEditIsFetching,
    getEditObjectId,
    getEditObjectData,
    getModule,
    getLanguage,
    getEditButtons,
    getObjectMode,
    getMode,
    getFormUi, getGroupModel, getUser
} from '../../selectors'
import { Prompt } from 'react-router-dom'
import FormWrapper from './FormWrapper'
export const OBJECT_FORM = 'editObject'

class ModuleForm extends PureComponent {
    componentDidMount(){
        this.props.executeLoadSubscriptions()
    }
    renderButtons() {
        const {
            fields,
            // from redux form
            //dirty,
            invalid,
            //pristine,
            //submitting,
            handleSubmit,

            // dispatch
            touch,
            onCancel,
            onSubmitWithContext,

            // connected
            module,
            editButtons,
            formSyncErrors,
            formSubmitErrors,
            isFormDirty,
            formLoading,
            editObjectValues
        } = this.props

        return (
            <I18n ns={[module.model, 'platform']}>
                {t =>
                    <FormButtons>
                        {editButtons.map((button, i) => {
                            switch (button.type) {
                                case "save":
                                    return (
                                        <BsButton
                                            key={i}
                                            type='submit'
                                            bsStyle={button.bsStyle}
                                            disabled={!isFormDirty || formLoading}
                                            onClick={() => {
                                                const key = _.keys(formSyncErrors)[0]
                                                const field = _.find(fields, {'path': key} )

                                                if(invalid && formSyncErrors) {

                                                    _.forEach(fields, function(field) {
                                                        touch(field.path)
                                                    })

                                                    return toastr.error(
                                                        t('validationError'),
                                                        t(field.tKey) + ' : ' + t(formSyncErrors[`${key}`]._error || formSyncErrors[`${key}`])
                                                    )
                                                } else if(invalid && formSubmitErrors) {
                                                    return toastr.error(
                                                        t('validationError'),
                                                        t(formSubmitErrors['serverError'])
                                                    )
                                                }

                                                handleSubmit(object => {
                                                    return onSubmitWithContext({
                                                        object,
                                                        context: { action: 'save' }
                                                    })
                                                })()
                                            }}
                                        >
                                            {t(button.tKey)}
                                        </BsButton>
                                    )
                                case "action":
                                    const disabled = button.pristine
                                        ? isFormDirty || formLoading
                                        : formLoading
                                    return (
                                        <BsButton
                                            key={i}
                                            onClick={() => {
                                                const key = _.keys(formSyncErrors)[0]
                                                const field = _.find(fields, {'path': key} )

                                                if(button.bypassFormValidation) {
                                                    return onSubmitWithContext({
                                                        object: editObjectValues,
                                                        context: {
                                                            action: button.action,
                                                            tKey: t(button.tKey),
                                                            getUserConfirmation: button.getUserConfirmation,
                                                            confirmationMessage: button.confirmationMessage
                                                        }
                                                    })
                                                }

                                                if (invalid && formSyncErrors) {
                                                    _.forEach(fields, function(field) {
                                                        touch(field.path)
                                                    })

                                                    return toastr.error(
                                                        t('validationError'),
                                                        t(field && field.tKey) + ' : ' + t(formSyncErrors[`${key}`]._error || formSyncErrors[`${key}`])

                                                    )
                                                } else if(invalid && formSubmitErrors) {
                                                    return toastr.error(
                                                        t('validationError'),
                                                        t(formSubmitErrors['serverError'])
                                                    )
                                                }
                                                handleSubmit(object => {
                                                    return onSubmitWithContext({
                                                        object,
                                                        context: {
                                                            action: button.action,
                                                            tKey: t(button.tKey),
                                                            getUserConfirmation: button.getUserConfirmation,
                                                            confirmationMessage: button.confirmationMessage
                                                        }
                                                    })
                                                })()
                                            }}
                                            bsStyle={button.bsStyle}
                                            disabled={disabled}
                                        >
                                            {t(button.tKey)}
                                        </BsButton>
                                    )
                                case "return":
                                default:
                                    return (
                                        <BsButton
                                            key={i}
                                            bsStyle={button.bsStyle}
                                            onClick={() => {
                                                onCancel(isFormDirty)
                                            }}
                                        >
                                            {t(button.tKey)}
                                        </BsButton>
                                    )
                            }
                        })}
                    </FormButtons>

                }
            </I18n>
        )
    }

    renderForm() {
        const {
            handleSubmit, fields, user, module, groupModel, objectMode, isFormDirty, initialValues, editObjectValues, language, formLoading
        } = this.props

        const requiredField = fields.some(field => field.required)
        return (
            <I18n key={module.model} ns={[module.model, 'platform']}>
                {t => {
                    return (
                        <FormWrapper isFormDirty={isFormDirty} message={t('quittingFormConfirmation')}>
                            <Form onSubmit={handleSubmit} hideScroll={module.hideScroll}>
                                <Prompt
                                    when={isFormDirty}
                                    message={t('quittingFormConfirmation')}
                                />
                                {fields
                                    .filter(field => !field.hidden)
                                    .filter(field => objectMode === "newObject" || !field.creationOnly)
                                    .map(field => (
                                        <FormField key={field.path} type={field.type} required={field.required} hideLabel={field.hideLabel} width={field.width} label={field.tKey} fullWidth={field.fullWidth} t={t} >
                                            <ModuleFormField field={field} t={t} objectMode={objectMode} initialValues={initialValues} editObjectValues={editObjectValues} language={language} user={user} module={module} groupModel={groupModel}/>
                                        </FormField>
                                    ))
                                }
                                {this.renderButtons()}
                                {requiredField &&  <div className='col-sm-12' style={{fontSize: '11px'}}>(*) {t('requiredFields')}</div>}
                            </Form>
                            {
                                formLoading && <div className="Form-loading-Modal">
                                    <SpinningDots/>
                                </div>
                            }
                        </FormWrapper>
                    )
                }}
            </I18n>
        )
    }

    render() {
        const {objectMode, isFetching} = this.props
        const isNewObject = objectMode === 'newObject'
        return isFetching && !isNewObject ? <SpinningDots /> : this.renderForm()
    }
}

const ReduxForm = reduxForm({
    // a unique name for the form (required by the library)
    form: OBJECT_FORM,
    // loads values when initialValues prop changes
    enableReinitialize: true,
    //keepDirtyOnReinitialize: true
})(ModuleForm)

const mapStateToProps = state => ({
    formSyncErrors: getFormSyncErrors(OBJECT_FORM)(state),
    formSubmitErrors: getFormSubmitErrors(OBJECT_FORM)(state),
    fields: getEditFields(state),
    isFetching: getEditIsFetching(state),
    objectId: getEditObjectId(state),
    initialValues: getEditObjectData(state),
    editButtons: getEditButtons(state),
    user: getUser(state),
    module: getModule(state),
    groupModel: getGroupModel(state),
    formLoading: getFormUi(state).loading,
    language: getLanguage(state),
    objectMode: getObjectMode(state),
    isFormDirty: isDirty(OBJECT_FORM)(state),
    editObjectValues: getFormValues(OBJECT_FORM)(state),
    mode: getMode(state)
})

const mapDispatchToProps = (dispatch, { t }) => ({
    touch: field => dispatch(touch(OBJECT_FORM, field)),
    setObjectMode: objectMode => dispatch(setObjectMode(objectMode)),
    onCancel: (isFormDirty) => {
        if(isFormDirty) {
            toastr.confirm(t('quittingFormConfirmation'), {
                onOk: () => {
                    dispatch(setUiMode('list'))
                    dispatch(emptyEditObject())
                    dispatch(setObjectMode(''))
                }
            })
        } else {
            dispatch(setUiMode('list'))
            dispatch(emptyEditObject())
            dispatch(setObjectMode(''))
        }
    },
    onSubmitWithContext: ({ object, context }) => {
        const newObject = !object.id
        const action = newObject ? createObject : saveFormObject
        console.log('onSubmitWithContext')

        const dispatchAction = () => {
            return dispatch(action(object, context))
                .then(({ error }) => {
                    if (!error) dispatch(executeAfterSaveSubscriptions())
                })
                .catch((error) => console.log(error))
        }
        return context.getUserConfirmation
            ? toastr.confirm( t(context.confirmationMessage || 'formActionConfirmation', {action: context.tKey}), {
                onOk: () => dispatchAction()
            })
            : dispatchAction()
    },
    executeLoadSubscriptions: () => dispatch(executeLoadSubscriptions())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm)
