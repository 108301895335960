const async = require('async');
const _ = require('lodash');
const moment = require('moment');

export const businessProjectFilters = [
    {
        name: "businessProjectInProgress",
        isDefault: false,
        query: () => {
            const filterSteps = ["formX", "buildOffer", "submitOffer", "realisation"];
            return {"workflow.step": {$in: filterSteps, $exists: true}};
        }
    },
    {
        name: "inSteps",
        isDefault: false,
        query: context => {
            const filterSteps = _.get(context, "data.filterSteps");

            if (!filterSteps || !filterSteps.length) return {_id: false};
            return {"workflow.step": {$in: filterSteps, $exists: true}};
        }
    },
    {
        name: "inYearPeriod",
        isDefault: false,
        query: context => {

            const startYear = moment.utc(context.data.startYear, "YYYY").toDate();
            const endYear = moment.utc(context.data.endYear, "YYYY").add(1, "year").toDate();

            if (!startYear || !endYear) return {};
            return {"creationDate": {$gte: startYear, $lte: endYear}};
        }
    },
    {
        name: "inYearCreationPeriod",
        isDefault: false,
        query: context => {
            const startYear = moment.utc(context.data.creationYear, "YYYY").add(-2, "year").toDate();
            const endYear = moment.utc(context.data.creationYear, "YYYY").add(1, "year").toDate();

            if (!startYear || !endYear) return {};
            return {"creationDate": {$gte: startYear, $lt: endYear}};
        }
    },
    {
        name: "isUserInCountryScope",
        isDefault: true,
        async: true,
        query: (context, callback) => {
            async.parallel([
                callback => global.app.I.Country.find(
                    {
                        user: context.user,
                        group: context.group,
                        filters: ["inUserTeam"]
                    },
                    callback
                ),
                callback => global.app.I.TeamMember.get(
                    { user: global.ObjectID(context.user.id) },
                    { group: context.group },
                    (e, teamMember) => callback(null, teamMember)
                )
            ], (error, [countries, teamMember]) => {
                const isProjectMonitoring = _.get(context, "module.id") === "m-I-projectMonitoring"
                const isWorkflowModule = ["m-I-bp1", "m-I-bp2", "m-I-bp4", "m-I-bp5"].includes(_.get(context, "module.id"))

                if( isProjectMonitoring && teamMember ) return callback(null, {monitoringTeamMembers: {$elemMatch: {$eq: global.ObjectID(teamMember.id)}}})
                if( isWorkflowModule || !teamMember  ) return callback(null, {country: {$in: countries.map(global.app.I.Country.db.objectToRefId)}})

                return callback(null, {
                    $or: [
                        {country: {$in: countries.map(global.app.I.Country.db.objectToRefId)}},
                        {monitoringTeamMembers: {$elemMatch: {$eq: global.ObjectID(teamMember.id)}}}
                    ]
                })
            })
        }
    },
    {
        name: "byCountry",
        isDefault: false,
        query: function (context) {
            const countryId = _.get(context, "data.country.id");

            if (!countryId) return {}
            return {country: new global.ObjectID(countryId)};
        }
    },
    {
        name: "byReferentialBusiness",
        match: function (object, context) {

            const countryId = _.get(context.data, "refBusinessCountry.id");

            if (countryId && object.referentialBusinesss) {
                return _.some(
                    object.referentialBusinesss,
                    o => o.country.id === countryId
                )
            }
            return true;
        }
    },
    {
        name: "byZone",
        title: "Zone",
        path: "zone",
        object: "Zone",
        display: "name",
        filters: ["inUserTeam"],
        objectFieldPath: ["country.zone.id"],
        isDefault: false,
        client: true,
        default: {id: null},
        match: function (object, context) {
            const zoneId = _.get(context.data, "zone.id");

            return zoneId ?
                _.get(object, "country.zone.id") === zoneId
                : true;
        }
    },
    {
        name: "byTypeOfOffer",
        title: "TypeOfOffer",
        path: "typeOfOffer",
        object: "TypeOfOffer",
        display: "nameWithRange",
        width: 12,
        objectFieldPath: ["typeOfOffer.id"],
        isDefault: false,
        default: {id: null},
        client: true,
        query: function (context) {
            const typeOfOfferId = _.get(context.data, "typeOfOffer.id");

            return typeOfOfferId ?
                {typeOfOffer: new global.ObjectID(typeOfOfferId)}
                : {}
        }
    },
    {
        name: "byWorkflowStep",
        path: "workflowStep",
        object: "WorkflowStep",
        type: "tags",
        client: true,
        display: "name",
        isDefault: false,
        query: function(context) {
            const steps = _.get(context, "data.workflowStep")
            return steps
                ? {'workflow.step': {$in: steps.map(step => step.id)}}
                : {_id: false}
        }
    },
    {
        name: "byWorkflowStepBR",
        path: "workflowStep",
        object: "WorkflowStep",
        type: "tags",
        client: true,
        display: "name",
        timer: 3,
        isDefault: false,
        default: [{id: 'buildOffer'}, {id: 'submitOffer'}, {id: 'realisation'}],
        query: function(context) {
            const steps = _.get(context, "data.workflowStep")
            return steps
                ? {'workflow.step': {$in: steps.map(step => step.id)}}
                : {_id: false}
        }
    },
    {
        name: "byWorkflowStepPF",
        path: "workflowStep",
        object: "WorkflowStep",
        type: "tags",
        client: true,
        timer: 3,
        display: "name",
        isDefault: false,
        default: [{id: 'formX'}, {id: 'buildOffer'}, {id: 'submitOffer'}, {id: 'realisation'}],
        query: function(context) {
            const steps = _.get(context, "data.workflowStep")
            return steps
                ? {'workflow.step': {$in: steps.map(step => step.id)}}
                : {_id: false}
        }
    }
]
