const _ = require('lodash')

export const modules = [
    {
        object: 'Input',
        category: {
            path: 'activities',
            icon: 'activity'
        },
        defaultSortBy: 'day',
        defaultSortDirection: 'DESC',
        viewMap: {
            dt: [
                { path: 'day', },
                { path: 'user', display: 'firstname' },
                { path: 'actions', initiallyNotVisible: true },
            ],
            form: [
                { path: 'day', required: true },
                {
                    path: 'activities',
                    required: true,
                    defaultSortBy: 'inputDate',
                    defaultSortDirection: 'DESC',
                    viewMap: {
                        dt: [
                            'action',
                            'duration',
                            'note',
                            { path: 'inputDate', tKey: 'creation' }
                        ],
                        form: [
                            { path: 'action', required: true, editable: false },
                            { path: 'duration', required: true, editable: false },
                            { path: 'note', type: 'textarea', editable: false },
                        ],
                    },
                },
            ]
        },
        filters: [
            {
                path: 'user',
                object: 'User',
                display: 'firstname',
                client: true,
                clearable: true,
                placeholder: 'selectUser',
                default: {id: null},
                query: function(context) {
                    const userId = _.get(context.data, 'user.id')
                    if (userId)
                        return { user: global.ObjectID(userId) }
                    return {}
                }
            },
            'byDate',
        ]
    },
]
