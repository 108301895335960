import {generateFetchFieldListAction} from "../../../../apps/KpModule/actions/api";
import {setFieldVisibility} from "../../../../apps/KpModule/actions";
import {basicContext} from "../../../utils/contextUtils";
import async from "async";
import _ from "lodash";

export const entity = {
    name: 'Habilitation',
    fields:[
        {path:'user', type: 'User', link:'MTO', unique: true, uniqueWith:['role']},
        {path:'role', type:'Role'},
        {path:'grantedAccess', type:'Organization', link:'MTM', nullable: true},
        {path:'grantedMesh', type:'OrganizationalMesh', link:'MTM', nullable: true},
        {
            path:'shownGrantedAccess',
            fieldPath: ['grantedAccess', 'grantedAccess.name', "grantedMesh", "grantedMesh.name"],
            f: function () {
                if ( this.grantedAccess.length === 0 && this.grantedMesh.length === 0){
                    return 'Exhaustive scope'
                }
                else if ( this.grantedAccess.length !== 0 ){
                    return this.grantedAccess.map( access => ' ' + access.name )
                }
                else if ( this.grantedMesh.length !== 0 ){
                    return this.grantedMesh.map( access => ' ' + access.name )
                }
            }
        }
    ],
    filters: [
    ]
}

export const module_ = {
    object: 'Habilitation',
    tKey: 'habilitation',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {
                path: 'user',
            },
            {
                path: 'role',
                tKey: 'fonction',
                display: 'profileName',
                translateName: true
            },
            {path: 'shownGrantedAccess', tKey: 'périmètre'},
        ],
        form: [
            {
                path: 'user',
                filters: ['isInGroupModel'],
                display: "name",
                editable: false
            },
            {
                path:'role',
                tKey: 'fonction',
                display: 'profileName',
                translateName: true,
                fieldPath:['id', 'profileName', 'organizationalAxis'],
                subscriptions: {
                    onChange: (newValue, oldValue, {module, store}) => {
                        const grantedAccessField = module.viewMap.form.fields.find( field => field.path === 'grantedAccess')
                        const grantedMeshField = module.viewMap.form.fields.find( field => field.path === 'grantedMesh')
                        if (newValue) {
                            if (newValue.organizationalAxis.id === "000000000000000000000002") {
                                grantedAccessField.setValue(null)
                                grantedMeshField.setValue(null)
                                store.dispatch(setFieldVisibility(grantedAccessField.id, false))
                                store.dispatch(setFieldVisibility(grantedMeshField.id, false))
                            }
                            else if (newValue.organizationalAxis.joinedEntity=== 'Mesh'){
                                store.dispatch(setFieldVisibility(grantedMeshField.id, true))
                                grantedAccessField.setValue(null)
                                store.dispatch(setFieldVisibility(grantedAccessField.id, false))
                            }
                            else {
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-SE-habilitation.Habilitation_grantedAccess",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                organizationalAxis : newValue?.organizationalAxis // newValue? newValue.organizationalAxis : null
                                            }
                                        }
                                    )
                                )
                                store.dispatch(setFieldVisibility(grantedAccessField.id, true))
                                grantedMeshField.setValue(null)
                                store.dispatch(setFieldVisibility(grantedMeshField.id, false))
                            }
                        }
                    }
                },
            },
            {path: 'grantedAccess', tKey: 'organisations', filters:['byOrganizationalAxis']},
            {path: 'grantedMesh', tKey: 'enitités'}
        ],
    }
}
