const stream = require("stream");

export const bulkWriteInBatchs = function(collection, operations, options, batchSize, callback) {
    if (operations.length <= batchSize) {
        collection.bulkWrite(operations, options, callback);
    }
    else {
        collection.bulkWrite(operations.slice(0, batchSize), options, e => {
            if (e) return callback(e);
            bulkWriteInBatchs(collection, operations.slice(batchSize), options, batchSize, callback);
        })
    }
};

export const insertManyInBatchs = function(collection, data, options, batchSize, callback) {
    if (data.length <= batchSize) {
        collection.insertMany(data, options, callback);
    }
    else {
        collection.insertMany(data.slice(0, batchSize), options, e => {
            if (e) return callback(e);
            insertManyInBatchs(collection, data.slice(batchSize), options, batchSize, callback);
        })
    }
};

export class Sink extends stream.Writable {
    constructor(options) {
        super(options);
    }

    _write(line, options, callback) {
        callback(null);
    }
}
