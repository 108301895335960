import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Select } from '../../../../components/Form'
import { getListOptions } from '../../selectors/index'
import getFormValidations from './formValidations'
import {colourStyles} from '../Filter/SelectStyles'
import {translateName} from '../../../../utils/i18n'
import './Tags.css'

// binding for redux-form
const DropDown = ({
    input: { value, onChange, onBlur },
    meta: { touched, error },
    required,
    path,
    display,
    placeholder,
    selectOptions,
    originalOptionsById,
    optionsById,
    disabled,
    editable,
    clearable,
    t
}) => {
    const translatedError = t(error)
    const finalValue = value && value.map(o => {
        return optionsById[o.id] || {value: o.id, label: o[display], notFound: true, optionData: o}
    })
    return (
        <Select
            path={path}
            required={required}
            options={selectOptions}
            noOptionsMessage={() => t('noOptions')}
            className="Form-TagsField"
            isMulti={true}
            styles={colourStyles}
            multi={true}
            onChange={options => {
                const selectAll = options && options.some(option => option.value === 'selectAll')

                if(selectAll) {
                    return onChange(
                        selectOptions.slice(1).map(o => originalOptionsById[o.value])
                    )
                }
                onChange(
                    options && options.map(o => (o.notFound ?
                            o.optionData
                            : o.id ?
                                o
                                : originalOptionsById[o.value]
                    ))//.filter(d => d)
                )
            }}
            value={finalValue}
            touched={touched}
            error={translatedError}
            onBlur={() => onBlur(value)}
            isDisabled={disabled || !editable}
            isClearable={clearable}
            isToto={clearable}
            placeholder={t(placeholder || 'select')}
            t={t}
        />
    )
}

class Tags extends React.Component {
    constructor(props){
        super(props)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const { field, options, t, objectMode, language } = this.props
        const originalOptionsById = options.reduce((acc, option) => {
            return {
                ...acc,
                [option.id]: option
            }
        }, {})
        const optionsById = options.reduce((acc, option) => {
            return {
                ...acc,
                [option.id]: {
                    value: option.id,
                    label: field.translateName
                        ? translateName(option[field.display], language)
                        : field.translate
                            ? t(option[field.display])
                            : option[field.display],
                }
            }},{})

        const selectOptions = [{value: 'selectAll', label: t('selectAll')}, ...options.map(option => optionsById[option.id])]

        const validations = getFormValidations(field, t, [], {}, optionsById)

        return (
            <Field
                name={field.path}
                required={field.required}
                path={field.tKey || field.path}
                display={field.display}
                component={DropDown}
                selectOptions={selectOptions}
                placeholder={field.placeholder}
                originalOptionsById={originalOptionsById}
                optionsById={optionsById}
                disabled={field.disabled || !field.writable}
                editable={!field.editable ? objectMode === 'newObject' : field.editable  }
                validate={validations}
                clearable={field.clearable}
                t={t}
            />
        )
    }
}

const mapStateToProps = (state, { field, t }) => ({
    options: getListOptions(state, field.id, field.sortList, field.translate, t)
})

export default connect(mapStateToProps)(Tags)
