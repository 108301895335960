const _ = require('lodash')
const moment = require('moment')
const path = require('path')
const crypto = require('../../../utils/crypto')

const getTemplateDirPath = language => global.isProd
    ? `buildServer/server/models/fncs/mails/templates/${language}`
    : `src/server/models/fncs/mails/templates/${language}`

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: 'assistance@mycsemanco.com',
    verbose: {
        general: true
    }
}

export async function shareFiles(campaignType, users, file, fileType, context) {

    const logMessage = () => {
        console.log('...Mailing')
    }

    users.forEach(user => {
        const language = user.language || 'fr'

        const groupModelName = (context.groupModel && context.groupModel.name) || "Keenpoint"


        const fileMail = {
            to: user.mail
                ? crypto.decrypt(user.mail)
                : user,
            context: {
                lastname : user.lastname,
                campaign: context.tl(campaignType, language),
                civility : user.civility && context.tl(user.civility, language),
                date: moment().format('YYYY/MM/DD')
            },
            content: `${fileType}.html`,
            subject: {
                template: 'myCSEManCo : ' + context.tl(`${fileType}MailSubject`, language)
            },
            templateDir: getTemplateDirPath(language),
            attachments: [
                {
                    id: file.id,
                    filename: file.filename
                }
            ]
        }

        const passwordMail = {
            to: user.mail
                ? crypto.decrypt(user.mail)
                : user,
            context: {
                lastname : user.lastname,
                filename: file.filename,
                civility : user.civility && context.tl(user.civility, language),
                password: file.password,
                groupModel: groupModelName
            },
            templateDir: path.join(
                global.appRoot,
                global.isProd
                    ? 'buildServer/server/KpMailer/templates/filePassword'
                    : 'src/server/KpMailer/templates/filePassword',
                language
            ),
            content: 'filePassword.html',
            subject: {
                template: context.tl('filePassword', language)
            }
        }


        const mails = fileType === 'simulation'
            ? [fileMail, passwordMail].map(mail => _.defaults(mail, defaultMail))
            : [fileMail].map(mail => _.defaults(mail, defaultMail))

        mails.forEach(mail => global.mailer.sendMail(mail, logMessage))
    })
}
