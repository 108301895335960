import React from 'react'
import './ToggleSwitch.css'
import classnames from "classnames";
import ValidationUI from '../ValidationUI'

const ToggleSwitch = ({options, value, onChange, display,  required,disabled, touched, error, t}) => {
    return (
            <div className={'bigBox'} >
                {
                    options.map(option => {
                        return <input
                            key={option.id}
                            type='button'
                            className={classnames('toggleButton', {
                                selected: option.id === value.id,
                                disabledButton: disabled
                            })}
                            value={t(option[display])}
                            onClick={()=> onChange(option)}
                            disabled={disabled}
                            required={required}
                        />
                    })
                }
                { touched && (error && <ValidationUI error={error}/>) }
            </div>
    )
}


export default ToggleSwitch
