import React from 'react'
import PropTypes from 'prop-types'
import {Trash} from 'react-feather'
import './DownloadDataTable.css'

const DeleteListElements = ({ onClick }) => {
    return (
        <button
            key="deleteListElements"
            className="DataTable-Board-icon-container"
            onClick={event => {
                event.stopPropagation()
                onClick()
            }}
        >
            <Trash className="DataTable-Board-icon" />
        </button>
    )
}

DeleteListElements.prototype = {
    onclick: PropTypes.func.isRequired
}
export default DeleteListElements
