const _ = require('lodash')

const defaultViews = [
    {name: "dt"},
    {name: "form"}
];

export default function(workflowGenerator) {

    const profileConfigEntityName = workflowGenerator.profileConfigEntity.name;
    const profileConfigEntityPath = _.lowerFirst(profileConfigEntityName);

    const profileColumns = _(workflowGenerator.profiles).map(profile => {
        return _.get(profile, "activeStep.multiOrder")
            ? [
                {
                    path: profile.name + "_active",
                    type: "checkbox",
                    tKey: profile.tKey
                },
                {
                    path: profile.name + "_order",
                    type: "editText",
                    tKey: "order",
                }
            ]
            : {
                path: profile.name + "_active",
                type: "checkbox",
                tKey: profile.tKey
            };

    }).flatten().value();

    const viewFields = {
        dt: [
            ...workflowGenerator.meshFields.map(
                meshField => meshField.dtField
            ),
            ...workflowGenerator.profiles.map(
                profile => ({
                    path: `${profile.name}${profileConfigEntityName}s`,
                    tKey: profile.tKey,
                    width: 400
                })
            )
        ],
        form: [
            ...workflowGenerator.meshFields.map(
                meshField => meshField.formField
            ),
            {
                path: "profileConfigByEntitys",
                tKey: workflowGenerator.configModuleDtObjectTKey,
                required: true,
                type: "dtObjects",
                fieldPath: [
                    `${profileConfigEntityPath}.id`,
                    `${profileConfigEntityPath}.name`,
                    ..._.map(workflowGenerator.profiles, profile => `${profile.name}_active`),
                    ..._.map(workflowGenerator.profiles, profile => `${profile.name}_order`)
                ],
                fields: [{
                    path: `${profileConfigEntityPath}.name`,
                    tKey: workflowGenerator.profileConfigEntity.tKey
                }].concat(profileColumns)
            }
        ]
    };

    return {norm: {fields: [
        {
            path: "views",
            default: [],
            $p: [
                {$if: _.isEmpty, $u: _.partial(_.cloneDeep, defaultViews)},
                "fields"
            ],
            fields: [
                {path: "fields", default: [], $p: {$u: function (fields, view) {
                    return _([
                        _.cloneDeep(viewFields[view.name]),
                        fields
                    ])
                        .flatten()
                        .compact()
                        .value();
                }}}
            ]
        }
    ]}};
};
