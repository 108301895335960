import * as ICS from 'ics-js'
const path = require('path')
const moment = require('moment')

export function sendInvitation(recipients, meetingObject, user, expectedGuests) {
    const { code, date, startTime, endTime, object, place, observation, expectedParticipants } = meetingObject

    const files = meetingObject.files.map(file => ({
        id: file._id,
        filename: file.filename
    }))

    date.setHours(startTime.split(':')[0])
    date.setMinutes(startTime.split(':')[1])
    const formatedStartDate = moment(date).format('YYYY-MM-DD HH:mm:ss')

    date.setHours(endTime.split(':')[0])
    date.setMinutes(endTime.split(':')[1])
    const formatedEndDate = moment(date).format('YYYY-MM-DD HH:mm:ss')

    const cal = new ICS.VCALENDAR()

    cal.addProp('VERSION', 2) // Number(2) is converted to '2.0'
    cal.addProp('PRODID', code + ':' + user.mail)

    const event = new ICS.VEVENT()

    event.addProp('UID', code + '' + user.mail)
    event.addProp('DTSTAMP', new Date(), { VALUE: 'DATE-TIME' })
    event.addProp('DTSTART', new Date(formatedStartDate), { VALUE: 'DATE-TIME' })
    event.addProp('DTEND', new Date(formatedEndDate), { VALUE: 'DATE-TIME' })
    event.addProp('ORGANIZER', `CN="${user.firstname} ${user.lastname}":mailto:${user.mail}`)
    event.addProp('LOCATION', place)
    event.addProp('SUMMARY', code)
    event.addProp('DESCRIPTION', object)
    event.addProp('ATTENDEE', null, { PARTSTAT: 'ACCEPTED:mailto:' + user.mail })
    recipients.forEach(mail => {
        if (mail !== user.mail ) {
            event.addProp('ATTENDEE', null, { RSVP: 'TRUE:mailto:' + mail })
        }
    })

    cal.addComponent(event)
    const icalendar = cal.toString()

    recipients.forEach(recipient => {

        const mail = {
            from: `Valodial<support@keenpoint.com>`,
            to: recipient,
            replyTo: `${user.mail}`,
            context: {
                code,
                startTime,
                endTime,
                object,
                place,
                observation,
                expectedParticipants,
                recipients,
                user,
                files: files,
                guests: expectedGuests,
                date: moment(date).format('DD-MM-YYYY')
            },
            content: 'invitationMail.html',
            subject: {
                template: `VALODIAL : Invitation à la réunion : ${code}`
            },
            templateDir: path.join(
                global.appRoot,
                'src/server/models/valodial/templates/mails'
            ),
            attachments: [
                {
                    filename: 'rdv.ics',
                    content: icalendar,
                    contentType: 'text/calendar'
                },
                ...files
            ],
            mock: false,
            verbose: {
                general: true
            }
        }

        global.mailer.sendMail(mail, function(e) {
            if (e) console.log(e)
            else console.log('hooray !!!')
        })
    })
}
