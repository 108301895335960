const {templatesDirPath} = require('./templates/index')
const {decrypt} = require('../../../utils/crypto')

export function sendReport(recipient, object, generatedPdf, context, callback) {
    const exerciseYear = object.exercise.code
    const language = recipient.language ? recipient.language.id : 'fr'
    const mail = {
        from: '"myCSEManCo" <assistance@mycsemanco.com>',
        replyTo: "assistance@mycsemanco.com",
        to: recipient.mail && decrypt(recipient.mail),
        context: {
            civility: context.tl(recipient.civility.id, language),
            lastname: recipient.lastname,
            exerciseYear,
            url: global.isProd
                ? `https://${context.host}/business/${context.groupModel.id}`
                : `http://localhost:3000/business/${context.groupModel.id}`
        },
        content: 'reportMail.html',
        subject: {
            template: `myCSEManCo : ${context.tl('reimbursementCertificate', language)}`
        },
        templateDir: templatesDirPath(language),
        attachments: [],
        verbose: {
            general: true
        }
    }

    global.mailer.sendMail(mail, callback)
}
