import {generateFetchFieldListAction, GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import {
    changeFieldDefault,
    changeFieldDisabled, changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE, processDynamicColumns,
    setFieldVisibility,
    setFormButtons
} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import _ from "lodash";
import {
    arbitrateButton,
    delegateButton,
    draftButton,
    returnButton,
    transmitButton,
    validateDemandeurButton, warnAndValidateButton
} from "../../utils/demandUtils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import {translateName} from "../../../../../utils/i18n";
import {addFloats} from "../../utils/usefulFunctions";
import {handleParentColumnsForTable} from "../../utils/trackingUtils";
import {deepCopy} from "mongodb/lib/utils";

export const module_ = {
    object: 'Demand',
    name: 'Realization',
    category: {
        path: 'Projet',
    },
    tKey: 'Réalisation',
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    newable: false,
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° Projet'},
            {path: 'relatedProject', tKey: 'projet principal', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'largeImputationsTotalAmount', width: 230},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                    //hidden: true,
                    //default: {id:'1'},
                    //display: 'name2'
                },
                {path: 'demandNumber',tKey: 'N° Projet', disabled: true},
                {path: 'relatedProject', tKey: 'projet principal', display: 'demandNumberAndTitle', disabled: true},
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    disabled: true,
                    //editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            if (newValue) {
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-SE-realization.Imputation_organizationalMesh",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                organization : newValue?.id // newValue? newValue.organizationalAxis : null
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    fieldPath: ['id', 'code', 'name'],
                    disabled: true,
                    translateName: true,
                },
                {
                    path: 'title',
                    required: true,
                    disabled: true
                },
                {path: 'description', type: 'richTextEditor', required: true, disabled: true, hidden: true},
                {path: 'enjeux', type: 'richTextEditor', required: true, disabled: true, hidden: true},
                {path: 'objectifs', type: 'richTextEditor', required: true, disabled: true, hidden: true},
                {path: 'pointsOfAttention', tKey: `points d'attention`, disabled: true},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    disabled: true,
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    disabled: true,
                },
                {
                    path: 'largeImputations',
                    disabled: true,
                    type: 'dtObjects',
                    fields: [
                        {path: "organization", tKey: 'direction', display: 'name'},
                        {path: "amount", tKey: 'Montant (k)', type: "editText"}
                    ]

                },
                {
                    path: 'largeImputationsTotalAmount',
                    disabled: true
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    disabled: true,
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant mensuel (k)'},
                        ],
                        form: {
                            fields : [
                                { path: 'objet', required: true, unique: true},
                                {path: 'organizationalMesh', tKey: 'business unit', filters:['byOrganizationOrMesh']},
                                { path: 'imputationType', required: true, translateName: true},
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            if (newValue){
                                                let total = 0
                                                newValue.forEach(object => {
                                                    total+= parseFloat(object.amount)
                                                })
                                                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                                                const totalAmountPerImputationField = imputationsField.viewMap.form.fields.find( field => field.path === 'totalAmountPerImputation')
                                                totalAmountPerImputationField.setValue(total)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea'},
                            ]
                        }
                    },
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.totalAmountPerImputation)
                                })
                                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                                totalAmountField.setValue(total)
                            }
                        }
                    }
                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true,
                },
                {
                    path: 'meetingsPlanning',
                    display: 'code',
                    disabled: true,
                    required: true,
                    fieldPath:['id', 'code', 'revisedReportingPeriod', 'meetingsPlanningRange'],
                },
                {
                    path: 'revueDate',
                    disabled: true
                    //endDate: moment().format('YYYY-MM-DD'),
                },
                {
                    path: 'revisedTable',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        //{path: 'imputationTriplet', width: 800, parentColumn: ''},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', type: 'editText', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50, },
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', type: 'editText', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        //{path: 'dynamicAmounts', hidden: true},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ],
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store, formInitialize, getObjectSuccessAction }) {
                            const state = store.getState()
                            const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                            const previousRevisedTable = _.get(state, 'edit.object.data.revisedTable')
                            const connectedUser = _.get(state, 'user')
                            console.log("newValue?.length", newValue?.length, oldValue?.length)


                            if (!!newValue?.length && !!oldValue && !formInitialize){
                                const filteredOldValue = _.cloneDeep(oldValue).filter( line =>
                                    line.estimated !== 0 ||
                                    line.budget !== 0 ||
                                    line.validated !== 0 ||
                                    line.revised !== 0 ||
                                    line.validatedEngagement !== 0 ||
                                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                                    line.ongoingFiscalYearEstimated !== 0 ||
                                    line.ongoingFiscalYearBudget !== 0 ||
                                    line.ongoingFiscalYearValidated !== 0 ||
                                    line.ongoingFiscalYearRevised !== 0 ||
                                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                                )

                                if (newValue.length === filteredOldValue.length && JSON.stringify(newValue)!==JSON.stringify(filteredOldValue)){
                                    // setting the other lines
                                    const removedTotalLine = newValue.filter(obj => {
                                        return !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null)
                                    })
                                    removedTotalLine.forEach( row => {
                                        const previousRow = !!previousRevisedTable && previousRevisedTable.find( line => line.objet === row.objet && line.entity.id.toString() === row.entity.id.toString() && line.imputationType.id.toString() === row.imputationType.id.toString())
                                        const oldRow = filteredOldValue.find( line => line.objet === row.objet && line.entity.id.toString() === row.entity.id.toString() && line.imputationType.id.toString() === row.imputationType.id.toString())
                                        console.log('oldRow', oldRow)
                                        const { updated: updatedPrevious, user: userPrevious, ...previousValues } = previousRow
                                        const { updated: updatedCurrent, user: userCurrent, ...currentValues } = row;
                                        const currentValuesToBeCompared = {revised: row.revised.toString(), ongoingFiscalYearRevised: row.ongoingFiscalYearRevised.toString()}
                                        let previousValuesToBeCompared = {revised: previousRow.revised.toString(), ongoingFiscalYearRevised: previousRow.ongoingFiscalYearRevised.toString()}
                                        if (!!previousRow && JSON.stringify(currentValuesToBeCompared)===JSON.stringify(previousValuesToBeCompared)){
                                            row.updated = previousRow.updated
                                            row.user = previousRow.user
                                            revisedTableField.setValue(removedTotalLine)
                                        }
                                        else if (JSON.stringify(row)!==JSON.stringify(oldRow)) {
                                            row.updated = moment().format("YYYY-MM-DD HH:mm:ss")
                                            row.user = connectedUser
                                            revisedTableField.setValue(removedTotalLine)
                                        }
                                    })
                                    // setting total line
                                    if (newValue.length>1){
                                        const totalLine = newValue.find( line => line.objet === 'Tot.' && line.entity === null && line.imputationType === null)
                                        totalLine.estimated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                                        totalLine.validated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                                        totalLine.revised = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                                        totalLine.ongoingFiscalYearEstimated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                                        totalLine.ongoingFiscalYearValidated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                                        totalLine.ongoingFiscalYearRevised = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                                        removedTotalLine.push(totalLine)
                                    }


                                    removedTotalLine.forEach(line => {
                                        line.validated = _.round(line.validated)
                                        line.estimated = _.round(line.estimated)
                                        line.revised = _.round(line.revised)
                                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                                    })
                                    revisedTableField.setValue(removedTotalLine)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'revisedTableHistory',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        //{path: 'imputationTriplet', width: 800, parentColumn: ''},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50 },
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'updated', tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', parentColumn: 'Mise à jour', hidden: true}
                    ],
                },
                {
                    path: 'plafond',
                    tKey: "estimatedFinancialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                    disabled: true
                    //filters: ['byAmount']
                },
                {
                    path: 'pilotageCommitteeMembers',
                    tKey: 'comité pilotage',
                    filters: ['isInGroupModel'],
                },
                {
                    path: 'projectCommitteeMembers',
                    tKey: 'comité projet',
                    filters: ['isInGroupModel']
                    //display: 'nameAndRole'
                },
                {
                    path: 'indicatorKeys',
                    type: 'accordion',
                    disabled: true,
                    viewMap: {
                        dt: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            'objectif'
                        ],
                        form: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            {path: 'objectif', required: true}
                        ]
                    }
                },
                {
                    path: 'comments',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                            if ( newValue && oldValue && newValue.length !== oldValue.length ) {
                                let commentsCopy = _.cloneDeep(newValue)
                                const commentsSorted = commentsCopy.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss'))
                                const lastComment = commentsSorted[commentsSorted.length-1]
                                lastComment.date = moment().format("YYYY-MM-DD HH:mm:ss")
                                const comments = commentsSorted.slice(0, newValue.length - 1)
                                comments.push(lastComment)
                                commentsField.setValue(comments)
                            }
                        }
                    }
                },
                'files',
                {
                    path: 'delegateToCheckBox',
                    tKey: 'je souhaite déléguer ma décision',
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const delegateToField = module.viewMap.form.fields.find(field=>field.path==='delegateTo')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(delegateToField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    store.dispatch(setFormButtons([delegateButton, returnButton]))
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(delegateToField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path: 'delegateTo',
                    filters: [ 'isInGroupModel', 'isNotCurrentContributor' ],
                    clearable: false
                },
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {
                    path: 'arbitrateCheckBox',
                    tKey: `je souhaite demander un arbitrage`,
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const arbitrateField = module.viewMap.form.fields.find(field=>field.path==='arbitrateText')
                            const arbitrationTypeField = module.viewMap.form.fields.find(field=>field.path==='arbitrationType')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            const arbitrationType = arbitrationTypeField.getValue()
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(arbitrateField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    if ( arbitrationType && arbitrationType.id === "1" ){
                                        store.dispatch(setFormButtons([arbitrateButton, returnButton]))
                                    }
                                    else if ( arbitrationType && arbitrationType.id === "2"){
                                        store.dispatch(setFormButtons([warnAndValidateButton, returnButton]))
                                    }
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(arbitrateField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path : 'arbitrateText',
                    tKey: `justification`,
                    type: 'richTextEditor'
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },
                {path: 'buttons', hidden: true, default: [draftButton, validateDemandeurButton, transmitButton, returnButton]},
                {
                    path: 'arbitrateTextList',
                    //fieldPath:['step', 'order'],
                    //hidden: true
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            {path: 'user', display: 'name'
                            },
                            'step',
                            'order',
                            "arbitrationType",
                            "date"
                        ],
                        form: [
                            {
                                path: 'user',
                                display: 'name',
                            },
                            'step',
                            'order',
                            {path: 'motif', type: 'richTextEditor'},
                            "arbitrationType",
                            "date"
                        ]
                    },
                    hidden: true
                },
                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'category',
                    hidden: true
                },
                {path: 'displayEngagementData', hidden: true},
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const demandNumberField = module.viewMap.form.fields.find( field => field.path === 'demandNumber')
                const arbitrateCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'arbitrateCheckBox')
                const delegationOptionField = module.viewMap.form.fields.find( field => field.path === 'delegationOption')
                const delegateToCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'delegateToCheckBox')
                const statusField = module.viewMap.form.fields.find( field => field.path === 'status')
                const largeImputationsField = module.viewMap.form.fields.find( field => field.path === 'largeImputations')
                const largeImputationsTotalAmountField = module.viewMap.form.fields.find( field => field.path === 'largeImputationsTotalAmount')

                const hasNoDelegation = delegationOptionField.getValue() && delegationOptionField.getValue().id === "1"

                const organizationAndMeshField = module.viewMap.form.fields.find( field => field.path === 'organizationAndMesh')
                const contributorsFunctionsField = module.viewMap.form.fields.find( field => field.path === 'contributorsFunctions')
                const demandOrganization = organizationAndMeshField.getValue()
                const contributorsFunctions = contributorsFunctionsField.getValue()

                const demandCategoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')
                store.dispatch(setFieldVisibility(demandCategoryField.id, false))
                const plafondField = module.viewMap.form.fields.find( field => field.path === 'plafond')
                store.dispatch(setFieldVisibility(plafondField.id, false))

                store.dispatch(setFieldVisibility(largeImputationsField.id, false))
                store.dispatch(setFieldVisibility(largeImputationsTotalAmountField.id, false))
                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                store.dispatch(setFieldVisibility(totalAmountField.id, false))
                store.dispatch(setFieldVisibility(imputationsField.id, false))
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()
                store.dispatch(setFieldVisibility(revisedTableHistoryField.id, !!revisedTableHistory && !!revisedTableHistory.length))



                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-realization.Demand_delegateTo",
                        store.getState,
                        'form',
                        {
                            data: {
                                demandOrganization : demandOrganization,
                                contributorsFunctions : contributorsFunctions
                            }
                        }
                    )
                )

                delegateToCheckBoxField.setValue(false)
                arbitrateCheckBoxField.setValue(false)

                store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, !hasNoDelegation))
                store.dispatch(setFieldVisibility(arbitrateCheckBoxField.id, false))
                store.dispatch(setFieldVisibility(statusField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(demandNumberField.id, objectMode !== 'newObject'))

                const referenceCurrencies = getDataListList(state, "Realization-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_revisedTable", 'tKey', `${t("revisedTable")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_revisedTableHistory", 'tKey', `${t("revisedTableHistory")} (k${referenceCurrency.symbol})`))
                }
                const revueDateField = module.viewMap.form.fields.find(field => field.path === 'revueDate')
                revueDateField.setValue(moment().format('YYYY-MM-DD'))

                const meetingsPlannings = getDataListList(state, "m-SE-realization.Demand_meetingsPlanning")
                const meetingsPlanningField = module.viewMap.form.fields.find(field => field.path === 'meetingsPlanning')
                const ongoingMeetingPlanning = meetingsPlannings.find(obj => moment().isBetween(obj.meetingsPlanningRange[0], obj.meetingsPlanningRange[1], 'day', '[]'))
                meetingsPlanningField.setValue(ongoingMeetingPlanning)

                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedColumn = revisedTableField.fields.find(field => field.path === 'revised')
                const ongoingFiscalYearRevisedColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearRevised')

                const displayEngagementDataField =  module.viewMap.form.fields.find(field => field.path === 'displayEngagementData')
                const displayEngagementData =  !!displayEngagementDataField && displayEngagementDataField.getValue()
                const validatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')
                const validatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')

                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementHistoryColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementHistoryColumn.id, displayEngagementData))

                const shouldReport = !!ongoingMeetingPlanning ? moment().isBetween(ongoingMeetingPlanning.revisedReportingPeriod[0], ongoingMeetingPlanning.revisedReportingPeriod[1], 'day', '[]') : false
                console.log('ongoingMeetingPlanning', shouldReport)
                store.dispatch(changeFieldDisabled(revisedColumn.id, !shouldReport))
                store.dispatch(changeFieldDisabled(ongoingFiscalYearRevisedColumn.id, !shouldReport))

                store.dispatch(setFieldVisibility(meetingsPlanningField.id, false))
                store.dispatch(setFieldVisibility(revueDateField.id, false))
            },
            dataLists: [
                "Realization-RealizationType",
                "Realization-FiscalYear",
                "Realization-Currency",
            ],
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "Realization-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `engagement (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("l_largeImputationsTotalAmount", 'tKey', `engagement estimé (k${referenceCurrency.symbol})`))
                }
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const language = _.get(state, 'ui.language')

                const realizationTypes = getDataListList(state, "Realization-RealizationType")
                const sortedTypes = _.sortBy(realizationTypes, ['order']);

                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTable = !!revisedTableField && revisedTableField.getValue()
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()


                const filteredRevisedTable = _.cloneDeep(revisedTable).filter( line =>
                        line.estimated !== 0 ||
                        line.budget !== 0 ||
                        line.validated !== 0 ||
                        line.revised !== 0 ||
                        line.validatedEngagement !== 0 ||
                        line.ongoingFiscalYearValidatedEngagement !== 0 ||
                        line.ongoingFiscalYearEstimated !== 0 ||
                        line.ongoingFiscalYearBudget !== 0 ||
                        line.ongoingFiscalYearValidated !== 0 ||
                        line.ongoingFiscalYearRevised !== 0 ||
                        line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const filteredRevisedTableHistory = _.cloneDeep(revisedTableHistory).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )


                const result = []
                const result2 = []
                if (!!filteredRevisedTable && !!filteredRevisedTable.length){

                    filteredRevisedTable.forEach(line => {
                        result.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                console.log('type', type.name, language, translateName(type.name, language))
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })


                    if (filteredRevisedTable.length > 1){
                        const dynamicAmounts = result.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})

                        const validatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            budget,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }

                    result.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    console.log('result', result)
                    revisedTableField.setValue(result)
                }


                if (!!filteredRevisedTableHistory && !!filteredRevisedTableHistory.length){
                    filteredRevisedTableHistory.forEach(line => {
                        result2.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })

                    if (filteredRevisedTableHistory.length > 1){
                        const dynamicAmounts = result2.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})

                        const validatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result2.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            budget,
                            estimated,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }

                    result2.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableHistoryField.setValue(result2)
                }
                store.dispatch(processDynamicColumns({revisedTable: result, revisedTableHistory: result2}))
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const fiscalYearsList = getDataListList(state, 'Realization-FiscalYear')
                const ongoingFiscalYear = fiscalYearsList.find( fiscalYear => moment().isBetween(fiscalYear.fiscalYearRange[0], fiscalYear.fiscalYearRange[1], 'day', '[]'))
                const tableIds = ["e_revisedTable", "e_revisedTableHistory"]
                tableIds.forEach(tableId => handleParentColumnsForTable(store, tableId, ongoingFiscalYear))
            }
        }
    ],
    accesses: [
        {
            id: "Realization-RealizationType",
            entity: "RealizationType",
            fieldPath: ['id', 'code', 'name', 'order'],
            filters: ['onlyActifOnes']
        },
        {
            id: "Realization-FiscalYear",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: []
        },
        {
            id: "Realization-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ["byModuleStep"]
}