export default [
    {
        name: 'Level',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0', name: '0' },
            { id: '1', name: '1' },
            { id: '2', name: '2' },
            { id: '3', name: '3' }
        ]
    },
    {
        name: 'Status',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'abandoned', name: 'abandoned' },
            { id: 'postponed', name: 'postponed' },
            { id: 'realized', name: 'realized' }
        ]
    }
]
