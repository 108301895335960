import _ from 'lodash'
import React from 'react'
import './StyledTable.css'
import classNames from 'classnames'
import { BooleanCell, InputCell } from './DataTable'
import { HoverTooltip } from './HoverTooltip'
import {DownloadCloud} from 'react-feather'
import {translateName} from '../utils/i18n'

const containerStyle = {
    position: 'relative',
    border: '1px solid #d8d8d8bd',
    borderRadius: '4px'
}

const rowStyle = {
    minHeight: '35px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'end',
    color: '#686972'
}

const cellStyle = {
    flex: '1 1 200px',
    width: '100%',
    display: 'grid',
    alignContent: 'center',
    whiteSpace: 'nowrap',
}

const innerCellStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 5px'
}

function cellRenderer(allRows, rowIndex, rowData, cellIndex, cellData, column, onRowChange, language) {
    if(typeof cellData === 'undefined' || cellData === null) return ''

    switch (cellData.type) {
        case 'editText':
            const shallowPath = column.path.split('.')[0]
            const deepPath = column.path.split('.')[1]

            return (
                <InputCell
                    value={cellData.data}
                    onPaste={ event => {
                        event.preventDefault()

                        const paste = (event.clipboardData || window.clipboardData).getData('text');
                        const result = paste.split('\n');
                        const result2 = []
                        result.forEach(row => result2.push(row.split('\t')))

                        const changes = {}
                        let currentRowIndex = rowIndex
                        result2.forEach(pastedRow => {
                            const currentRow = allRows[currentRowIndex]
                            if(!currentRow) return
                            if(!deepPath) {
                                const currentCell = currentRow[column.path]
                                if(currentCell.type || currentCell.type === 'editText') {
                                    changes[currentRowIndex] = {
                                        ...currentRow,
                                        [column.path]: {
                                            ...currentRow[column.path],
                                            data: pastedRow[0]
                                        }
                                    }
                                }
                            } else {
                                const dynamicFieldKeys = Object.keys(currentRow[shallowPath])

                                let currentCellIndex = dynamicFieldKeys.indexOf(deepPath)
                                const newDynamicFieldData = pastedRow.reduce((acc, pastedCell) => {
                                    if(!dynamicFieldKeys[currentCellIndex]) return acc
                                    const currentCell = currentRow[shallowPath][dynamicFieldKeys[currentCellIndex]]
                                    if(!currentCell.type || currentCell.type !== 'editText') return acc
                                    const object = {
                                        ...acc,
                                        [dynamicFieldKeys[currentCellIndex]]: {
                                            ...currentCell,
                                            data: pastedCell
                                        }
                                    }
                                    currentCellIndex++
                                    return object
                                }, {})
                                changes[currentRowIndex] = {
                                    ...currentRow,
                                    [shallowPath]: {
                                        ...currentRow[shallowPath],
                                        ...newDynamicFieldData
                                    }
                                }
                            }
                            currentRowIndex++
                        })
                        onRowChange(changes, true)

                    }}
                    onChange={event => {

                        const newRowData = deepPath
                            ? {
                                ...rowData,
                                [shallowPath]: {
                                    ...rowData[shallowPath],
                                    [deepPath]: {
                                        ...cellData,
                                        data: event.target.value
                                    }
                                }
                            }
                            : {
                                ...rowData,
                                [shallowPath]: {
                                    ...rowData[shallowPath],
                                    data: event.target.value
                                }
                            }
                        onRowChange({ rowIndex, rowData: newRowData })
                    }}
                />
            )
        default:
            const data = cellData.data !== undefined ? cellData.data : cellData

            switch (typeof data) {
                case 'boolean':
                    return column.type === 'boolean'
                        ? <BooleanCell value={data} />
                        : data
                case 'number':
                    return new Intl.NumberFormat(language).format(data)
                case 'string':
                    if(data.slice(-1) === '%') {
                        const percentage = parseFloat(data.slice(0, -1))
                        if(percentage) return Intl.NumberFormat(language, {style: 'percent', maximumFractionDigits: 2}).format(percentage / 100)
                    }
                    return data

                default:
                    return data
            }
    }
}


function handleCLick(event, columns, objects, title, exportState) {
    exportState(title, columns, objects)
    event.preventDefault()
}

const innerCell = (allRows, rowIndex, rowData, cellIndex, cellData, column, onRowChange, language) => (
    <div style={innerCellStyle}>
        {cellRenderer(allRows, rowIndex, rowData, cellIndex, cellData, column, onRowChange, language)}
    </div>
)

export const StyledTableField = ({value, onChange, language, listFields, field: {autoGrow, maxRows}, initialValues, exportState}) => {
    const exportTitle = initialValues.title

    const onRowChange = (newValue, multiple) => {
        if(multiple) {
            onChange(
                value.map( (data, index) => {
                    return newValue[index] ? newValue[index] : data
                })
            )
        } else {
            const { rowIndex, rowData } = newValue
            onChange(
                value.map( (data, index) => {
                    return rowIndex === index ? rowData : data
                })
            )
        }
    }

    return (
        <div style={{position: 'relative'}}>
            <div className='tableBoard' style={{position: 'sticky', top: 0}}>
                <span className='tableTitle'>
                    {translateName(initialValues.title, language)}
                </span>
                <button
                    key='downloadDataTable'
                    className='btn btn-default DownloadDataTable'
                    onClick={event => handleCLick(event, listFields, value, exportTitle, exportState)}
                >
                    <DownloadCloud size={17} />
                </button>
            </div>
            <div style={{...containerStyle}}>
                {
                    value && value.map((o, rowIndex) => (
                        <div key={rowIndex} className={o.className} style={{...rowStyle, backgroundColor: rowIndex % 2 ? '#f9f9f9':'white', ...o.style}}>
                            {
                                listFields.map((column, index) => {

                                    const cellData = _.get(o, column.path)
                                    const cellClassName = cellData ? cellData.className : ''
                                    const cellCustomStyle = cellData && cellData.style

                                    const isNotEmpty = (cellData && cellData.data) || cellData

                                    if(!isNotEmpty && o.isHeader ) {
                                        return null
                                    }
                                    return (
                                        <div key={index} className={classNames(column.className, cellClassName)} style={{...cellStyle, ...column.style, ...cellCustomStyle}}>
                                            {
                                                o.isHeader
                                                    ? (
                                                        <HoverTooltip
                                                            id={`StyledTable-${column.path}-${index}-tooltip`}
                                                            message={cellData && (cellData.data || cellData)}
                                                            placement={'top'}
                                                        >
                                                            {innerCell(rowIndex, o, index, cellData, column, onRowChange, language)}
                                                        </HoverTooltip>
                                                    )
                                                    : innerCell(value, rowIndex, o, index, cellData, column, onRowChange, language)
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
