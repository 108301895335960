async function findData(context, callback) {

    const activeQuery = { active: true }
    const userQuery = {
        user: new global.ObjectID(context.user.id)
    }

    const statusUserQuery = {
        ...activeQuery,
        ...userQuery
    }

    const alertPromise = global.app.S.StatusUser.collection.find(statusUserQuery).toArray()

    const [alerts] = await Promise.all([alertPromise])

    return [
        {
            id: "currentUserAlerts",
            data: alerts.length
        }
    ]
}

export const entity = {
    name: 'CurrentUserAlerts',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
