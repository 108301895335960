import _ from 'lodash'
import createWorkflowManager from '../../utils/workflow'
import { basicContext } from '../../utils/contextUtils'
import { insertCursorInBatches } from '../../../utils/mongoUtils'
import Errors from '../../utils/Errors'

/*
* Setup the workflow using the library
* */

export const workflowParameters = {
    steps: [
        {
            name: 'pending',
            actions: [{ name: 'validate', nextStep: 'inProgress' }]
        },
        {
            name: 'review',
            actions: [{ name: 'validate', nextStep: 'inProgress' }]
        },
        {
            name: 'inProgress',
            multiOrder: true,
            actions: [
                { name: 'validate', nextStep: 'noRisk' },
                { name: 'refuse', nextStep: 'risk', ignoreOrder: true },
                { name: 'question', nextStep: 'review', ignoreOrder: true }
            ]
        },
        { name: 'risk' },
        { name: 'noRisk' }
    ],
    profiles: [
        {
            name: 'manager',
            stepProfiles: [
                { step: 'pending', active: true },
                { step: 'review', active: true },
                { step: 'inProgress', active: false },
                { step: 'risk', active: false },
                { step: 'noRisk', active: false }
            ]
        },
        {
            name: 'validator',
            stepProfiles: [
                { step: 'inProgress', active: true },
                { step: 'risk', active: false },
                { step: 'noRisk', active: false }
            ]
        },
        {
            name: 'controller',
            stepProfiles: [
                { step: 'risk', active: false },
                { step: 'noRisk', active: false }
            ],
            optional: true
        }
    ]
}

export const workflowManager = createWorkflowManager(workflowParameters)

const stepTKeys = {
    pending: 'pending',
    review: 'review',
    inProgress: 'inProgress',
    risk: 'risk',
    noRisk: 'noRisk'
}

export function getStepTkey(step) {
    return stepTKeys[step]
}

/*
* Gets the workflow configs corresponding to the alertConfiguration
* */
export function getWorkflowConfigs(alertConfigurationId, context) {
    if (!_.get(context, 'group')) throw new Errors.ValidationError('context without group')

    // add dormant check here to by-pass workflow config

    const workflowConfigContext = {
        group: context.group,
        fieldPath: [
            'id',
            'habFunction.id',
            'habFunction.habilitations.id',
            'habFunction.habilitations.organizationsJoin.id'
        ],
        query: {
            alertConfiguration: new global.ObjectID(alertConfigurationId),
            dormant: false,
        }
    }

    return global.app.S.WorkflowConfig.find(workflowConfigContext)
}

/*
* Generates the default object for the workflow configs
* */
export function getWorkflowObject(workflowConfigs) {
    if (!workflowConfigs || !workflowConfigs.length) return null

    const initialStatus = workflowManager.getInitialStatus()
    const maxOrders = {
        inProgress: _(workflowConfigs)
            .map('order')
            .max()
    }

    return {
        ...initialStatus,
        maxOrders
    }
}

/*
* Projects the workflow configs creating the static workflow that will be saved.
*
* The object shapes are minimalist, so it's easier to understand and test.
* You may add more properties in the alert generation code (alert engine).
*
* StaticWorkflow = {
*   step: String,
*   order: Number,
*   active: Boolean,
*   habilitation: Habilitation
* }
*
* StatusUser = {
*   step: String,
*   order: Number,
*   active: Boolean,
*   habilitation: Habilitation,
*   user: ObjectID (corresponding to a User)
* }
*
* */
export function initializeWorkflowData(alert, workflowObject, workflowConfigs) {
    const orders = _.range(1, workflowObject.maxOrders.inProgress + 1)

    const staticWorkflows = _(workflowConfigs)
        .map(config => ({
            config,
            habilitations: config.habFunction.habilitations.filter(
                habilitationMatches(alert)
            )
        }))
        .filter('habilitations')
        .flatMap(({ habilitations, config }) => {
            const staticWorkflows = getStaticWorkflowObjectsForProfile(
                config,
                orders
            )
            return _.flatMap(habilitations, habilitation =>
                _.flatten(staticWorkflows).map(staticWorkflow => ({
                    ...staticWorkflow,
                    habilitation
                }))
            )
        })
        .value()

    const statusUsers = projectStatusUsers({
        staticWorkflows,
        step: 'pending',
        order: 0
    })

    return [staticWorkflows, statusUsers]
}

/*
* Generates workflow objects for a given profile
*
* For the multi-order intervention step :
*  - the manger profile is active on pending and review_ and passive on others
*  - the validator profile is active on it's order, and passive on the next orders
*  - the controller profile is passive on risk and noRisk
* */
const getStaticWorkflowObjectsForProfile = (config, orders) => {
    switch (config.profile) {
        case 'manager':
            return [
                { step: 'pending', order: 0, active: true },
                { step: 'review', order: 0, active: true },
                ...orders.map(order => ({
                    step: 'inProgress',
                    order,
                    active: false
                })),
                { step: 'risk', order: 0, active: false },
                { step: 'noRisk', order: 0, active: false }
            ]
        case 'validator':
            return [
                ...orders.reduce((acc, order) => {
                    return order < config.order
                        ? acc
                        : [
                            ...acc,
                            {
                                step: 'inProgress',
                                order,
                                active: order === config.order
                            }
                        ]
                }, []),
                { step: 'risk', order: 0, active: false },
                { step: 'noRisk', order: 0, active: false }
            ]
        case 'controller':
            return [
                { step: 'risk', order: 0, active: false },
                { step: 'noRisk', order: 0, active: false }
            ]
        default:
            return
    }
}

/*
* Matches the habilitation with a given alert
* We have three cases :
*  - no organizationJoin -> central habilitation -> have access
*  - organizationJoin is an instance of Store -> access if matches with the store of the alert
*  - organizationJoin is an instance of Organization -> access if matches with one of the store organizations
* */
const habilitationMatches = alert => habilitation => {
    const checkOrganizations =
        habilitation.organizationsJoin

    return (
        (!checkOrganizations || checkOrganizations.length === 0) ||
        (alert.store && checkOrganizations.some(org => org.id === alert.store.id)) ||
        (alert.organizations &&
            alert.organizations.some(
                organization => checkOrganizations.some(org => organization.id === org.id)
            ))
    )
}

/*
* Status users are generated for only one combination of step and order.
*
* We project all habilitation users, for static workflows that match step and order.
*
* */
function projectStatusUsers({ staticWorkflows, step, order }) {
    return _(staticWorkflows)
        .filter({ step, order })
        .map(staticWorkflow => ({
            ...staticWorkflow,
            user: staticWorkflow.habilitation.user
        }))
        .compact()
        .value()
}

/*
* Get a string describing the current status of the workflow
* */
export function getStatusString({ workflow }, context) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    const stepTitle = context.tc(getStepTkey(workflow.step))
    return _.compact([
        stepTitle,
        workflow.order > 0 ? workflow.order : undefined
    ]).join(' / ')
}

function getCurrentStepOrder({ step, order }) {
    switch (step) {
        case 'pending':
        case 'review':
            return 1
        case 'inProgress':
            return order + 1
        default:
            return 0
    }
}

export function getStepStatus({ workflow }) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    // for the finished steps, we return a special string
    if (['risk', 'noRisk'].includes(workflow.step)) return '-'

    const totalSteps = workflow.maxOrders.inProgress + 1

    return `${getCurrentStepOrder(workflow)}/${totalSteps}`
}

/**
 * Get All users in the static workflow
 * @param alert
 * @param context
 * @returns {Promise<Array>}
 */
export async function getUsersInStaticWorkflow(alert, context) {
    const staticWorkflowContext = {
        ...basicContext(context),
        query: {
            alert: new global.ObjectID(alert.id)
        },
        fieldPath: ['habilitation.user.id']
    }

    const staticWorkflows = await global.app.S.StaticWorkflow.find(
        staticWorkflowContext
    )

    return staticWorkflows.map(staticWF => staticWF.habilitation.user)
}

/**
 * Get list of current users for the alert
 * @param alert
 * @param context
 * @returns {Promise<*>}
 */
export async function getCurrentUsers(alert, context) {
    const statusUserContext = {
        ...basicContext(context),
        query: {
            active: true,
            alert: new global.ObjectID(alert.id)
        },
        fieldPath: ['user.id']
    }

    const statusUsers = await global.app.S.StatusUser.find(statusUserContext)

    return statusUsers.map(o => o.user)
}

/**
 * Get list of next users for the alert
 * @param alert
 * @param context
 * @returns {Promise<ReactWrapper|ShallowWrapper|Array|*|any[]>}
 */
export async function getNextUsers(alert, context) {
    const staticWorkflowContext = {
        ...basicContext(context),
        query: {
            active: true,
            alert: new global.ObjectID(alert.id)
        },
        fieldPath: ['habilitation.user.id']
    }

    const staticWorkflows = await global.app.S.StaticWorkflow.find(
        staticWorkflowContext
    )

    return _(
        staticWorkflows.filter(staticWorkflow => {
            if (['pending', 'review'].includes(alert.workflow.step)) {
                return (
                    staticWorkflow.step === 'inProgress' &&
                    staticWorkflow.active === true
                )
            } else if (alert.workflow.step === 'inProgress') {
                return (
                    staticWorkflow.order > alert.workflow.order &&
                    staticWorkflow.active === true
                )
            }
            return false
        })
    )
        .map(o => o.habilitation.user)
        .value()
}

/*
* Get a string with the names of all the active HabFunctions for the alert
* */
export async function getAlertHabFunctions(alert, context) {
    const statusUserContext = {
        ...basicContext(context),
        query: {
            active: true,
            alert: new global.ObjectID(alert.id)
        },
        fieldPath: [
            'habilitation.habFunction.id',
            'habilitation.habFunction.tName'
        ]
    }

    const statusUsers = await global.app.S.StatusUser.find(statusUserContext)

    return _(statusUsers)
        .map('habilitation.habFunction.tName')
        .uniq()
        .join(', ')
}

/*
* Get a string with the names of all the active HabFunctions for the alert
* */
export async function getActiveStep(alert, context) {
    const statusUserContext = {
        ...basicContext(context),
        query: {
            active: true,
            alert: new global.ObjectID(alert.id)
        },
        fieldPath: [
            'step'
        ]
    }

    const statusUsers = await global.app.S.StatusUser.find(statusUserContext)

    if(!statusUsers.length) return ""

    return statusUsers[0].step
}

/**
 * Get all alerts on user's scope
 * @param user
 * @param period
 * @param context
 * @returns {*}
 */
export function getUserClosedAlerts(user, period, context) {
    const alertCollectionName = global.app.S.Alert.collection
    const habilitationCollectionName = global.app.S.Habilitation.collectionName
    const staticWorkflowCollection = global.app.S.StaticWorkflow.collectionName

    return alertCollectionName
        .aggregate([
            {
                $match: {
                    'workflow.step': {$in: ['risk', 'noRisk']},
                    'date.gte': { $lte: period.end },
                    'date.lt': { $gt: period.start },
                    group: new global.ObjectID(context.group.id),
                }
            },
            {
                $lookup: {
                    from: staticWorkflowCollection,
                    localField: '_id',
                    foreignField: 'alert',
                    as: 'staticWFs'
                }
            },
            {
                $lookup: {
                    from: habilitationCollectionName,
                    localField: 'staticWFs.habilitation',
                    foreignField: '_id',
                    as: 'habilitations'
                }
            },
            {
                $match: {
                    'habilitations.user': new global.ObjectID(user.id)
                }
            },
            {
                $project: {
                    _id: 1,
                    'date': 1,
                    workflow: 1,
                    alertConfiguration: 1,
                    organizations: 1,
                    product: 1,
                    store: 1,
                    group: 1
                }
            }
        ])
        .toArray()
}

/*
* Defines the bootstrap button style for the action
* */
function bsStyleForAction(action) {
    switch (action) {
        case 'refuse':
            return 'danger'
        case 'question':
            return 'warning'
        case 'validate':
        default:
            return 'primary'
    }
}

function getActiveButtonsForStep(step) {
    const stepObject =
        workflowParameters.steps.find(stepObject => stepObject.name === step) ||
        {}
    const actions = stepObject.actions || []
    return actions.map(action => ({
        action: action.name,
        bsStyle: bsStyleForAction(action.name),
        tKey: action.name,
        type: 'action'
    }))
}

/*
* Get workflow buttons for the status user.
*
* Passive status users show only return button.
* Active status users show one button for each step action.
*
* */
export function getAlertButtonsForStatusUser(statusUser = {}) {
    return _([
        statusUser.active && getActiveButtonsForStep(statusUser.step),
        {
            tKey: 'return',
            bsStyle: 'default',
            type: 'return'
        }
    ])
        .flatten()
        .compact()
        .value()
}

/*
* Update status users
*
* This function is called to update the status users to match the next status.
* It will check the workflow config for the alert to compute the new status users.
* */
export async function updateStatusUsers(alert, nextStatus, context) {
    const statusUserCollection = global.app.S.StatusUser.collection
    const alertQuery = { alert: new global.ObjectID(alert.id) }

    const staticWorkflowContext = {
        ...basicContext(context),

        // we clone the alertQuery to avoid object modification by the platform
        query: { ...alertQuery },

        // ask the platform to join the habilitation collection for us
        fieldPath: ['id', 'habilitation.id']
    }

    const staticWorkflows = await global.app.S.StaticWorkflow.find(
        staticWorkflowContext
    )

    const statusUsers = projectStatusUsers({
        staticWorkflows,
        step: nextStatus.step,
        order: nextStatus.order
    }).map(({ id, habilitation, ...other }) => ({
        habilitation: new global.ObjectID(habilitation.id),
        ...other
    }))

    // remove the status users corresponding to the previous status
    await statusUserCollection.deleteMany({
        ...alertQuery,
        group: new global.ObjectID(context.group.id)
    })

    await statusUserCollection.insertMany(statusUsers)
}

/*
* Update status users after a change in habilitation
* */
export async function updateUserHabilitations({
    habilitationId,
    user,
    group
}) {
    const alertCollectionName = global.app.S.Alert.collectionName
    const statusUserCollection = global.app.S.StatusUser.collection
    const staticWorkflowCollection = global.app.S.StaticWorkflow.collection

    await statusUserCollection.removeMany({
        habilitation: new global.ObjectID(habilitationId)
    })

    if (user) {
        const projectionFields = {
            active: 1,
            step: 1,
            order: 1,
            alert: 1,
            habilitation: 1,
            alertConfiguration: 1,
            group: 1,
            'date': 1,
            baseDay: 1
        }

        const aggregationCursor = staticWorkflowCollection.aggregate([
            {
                $match: {
                    group: new global.ObjectID(group.id),
                    habilitation: new global.ObjectID(habilitationId)
                }
            },
            {
                $lookup: {
                    from: alertCollectionName,
                    localField: 'alert',
                    foreignField: '_id',
                    as: 'lookedAlerts'
                }
            },
            {
                $project: {
                    lookedAlert: { $arrayElemAt: ['$lookedAlerts', 0] },
                    ...projectionFields
                }
            },
            {
                $project: {
                    orderEq: { $eq: ['$order', '$lookedAlert.workflow.order'] },
                    stepEq: { $eq: ['$step', '$lookedAlert.workflow.step'] },
                    ...projectionFields
                }
            },
            {
                $match: {
                    orderEq: true,
                    stepEq: true
                }
            },
            {
                $project: {
                    _id: 0,
                    ...projectionFields,
                    user: new global.ObjectID(user.id)
                }
            }
        ])

        await insertCursorInBatches({
            cursor: aggregationCursor,
            collection: statusUserCollection,
            batchSize: 1000
        })
    }
}
