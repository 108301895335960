const _ = require('lodash')

export const module_ = {
    object: 'Refund',
    name: 'RefundFollowUp',
    tKey: 'mTitle_followUp',
    newable: false,
    removable: false,
    protectedExport: true,
    displayLog: false,
    defaultSortBy: 'updated',
    defaultSortDirection: 'ASC',
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: 'folder', width: 225},
            {path: 'reference', tKey: 'request'},
            {path: 'user', tKey: 'beneficiary', display: 'fullName', width: 180},
            {path: 'userRegistrationNumber', tKey: 'registrationNumber', initiallyNotVisible: true},
            {path: 'benefit', width: 225},
            {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true, width: 100},
            {path: 'refund', tKey: 'refundAbbreviated', tooltip: true, width: 100},
            {path: 'beneficiaries', initiallyNotVisible: true, display: 'fullName'},
            {path: 'issued', initiallyNotVisible: true, width: 120},
            {path: 'status', translate: true, width: 100},
            {path: 'updated', width: 120}
        ],
        form: {
            fields: [
                {path: 'folder', disabled: true},
                {path: 'reference', tKey:'request', disabled: true},
                {path: 'user', tKey: 'beneficiary', display: 'fullName', disabled: true},
                {path: 'benefit', editable: false},
                {path: 'currencyAmount', editable: false},
                {path: 'rate', tKey: 'currencyRate', editable: false},
                {path: 'amount', tKey: 'amount(€)', editable: false},
                {path: 'beneficiaries', editable: false, display: 'fullName'},
                {path: 'status', hidden: true},
                {path: 'files', type: 'files2', tKey: 'supportingDocuments', editable: false},
                {path: 'comments', textWidth: 500, type: 'comment2', disabled: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
        }
    },
    filters: [
        'byExercise',
        {
            title: "Status",
            path: "status",
            tKey: "status",
            type: 'tags',
            default: [
                {id: 'validated'}
            ],
            object: "RefundStatus",
            display: "name",
            client: true,
            isDefault: false,
            query: function (context) {
                const statusArray = _.get(context, "data.status")

                return statusArray
                    ? {status: {$in: statusArray.map(o => o.id)}}
                    : {status: false}
            }
        },
        {
            path: 'noDraft',
            match: function(request) {
                return request.status !== 'draft'
            }
        }
    ]
}
