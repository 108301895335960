import React, {Component} from 'react';
import _ from 'lodash'
import * as d3 from 'd3'
import { select } from 'd3-selection';


const defaultColors = ['#de8134', '#f5aa80']
const margin = { top: 20, right: 20, bottom: 60, left: 40 }


class DoubleBarChart extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const {data = [], h ,w, className, dataKeys , defaultKeys, colors = defaultColors, t} = this.props

        const keys = dataKeys || defaultKeys
        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom

        const x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);


        const x1 = d3.scaleBand()
            .padding(0.05);

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const colorByKey = keys.reduce((acc, key, index) => ({
                ...acc,
                [key]: colors[index]
        }), {})

        const xAxis = d3.axisBottom()
            .scale(x0)

        const values = keys.map(key => data.map( d => d[key] ))

        const ticksNumber = Math.max(..._.flatten(values)) < 7 ? Math.max(..._.flatten(values)): 7

        const yAxis = d3.axisLeft()
            .scale(y)
            .ticks(ticksNumber)

        const container = select(`.${className}`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)


        x0.domain(data.map(d => d.axis))

        x1.domain(keys).rangeRound([x0.bandwidth(), 0])

        y.domain([0, d3.max(data, d => d3.max(keys, key =>  d[key] + d[key]/20 ) )]).nice();

        const bars = container
            .select(".bars")
            .selectAll("g")
            .data(data)

        bars
            .attr("transform", d => "translate(" + x0(d.axis) +", 0)")
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .attr("x", function(d) { return x1(d.key); })
            .attr("width", x1.bandwidth())
            .attr("y", function(d) { return y(d.value)})
            .attr("height", function(d) { return height - y(d.value) })
            .attr("fill", function(d) { return colorByKey[d.key] })

        bars
            .enter().append("g")
            .attr("transform", function(d) { return "translate(" + x0(d.axis) +", 0)"; })
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .enter().append("rect")
            .attr("x", function(d) { return x1(d.key); })
            .attr("width", x1.bandwidth())
            .attr("y", height)
            .attr("height", 0)
            .transition()
            .duration(1000)
            .attr("y", function(d) { return y(d.value)})
            .attr("height", function(d) { return height - y(d.value)})
            .attr("fill", function(d) { return colorByKey[d.key] })


        container.select('.axis--x')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0, ${height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("transform", "rotate(-55)")
            .attr("y", 5)
            .attr("x", -6)
            .attr("dy",".35em")
            .style("text-anchor", "end")
            .call(function(k){
                k.each(function(d){ // for each one
                    const self = d3.select(this);
                    const s = self.text().split(';')  // get the text and split it
                    if(s.length !== 1){
                        self.text(''); // clear it out
                        for(let i=0; i< s.length; i++){
                            self.append("tspan") // insert tspan for every bit
                                .attr("y", i*12)
                                .attr("x", -9)
                                .attr("dy",".35em")
                                .text(t(s[i]))
                        }
                    }

                })
            })


        container.select('.axis--y')
            .attr('class', 'axis axis--y')
            .attr('transform', `translate(0,0)`)
            .call(yAxis)



        const legend = container
            .selectAll(".legend")
            .data(keys)

        legend
            .select("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key]})

        legend
            .select("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return t(key)
            });


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i, data) {
                let line = 0
                let previousDataLength = -10

                if(i !== 0) {
                    let j = i
                    while(j > 0){
                        previousDataLength += t(keys[j-1]).length * 12
                        j--
                    }
                }
                /*
                const widthDifference = w - margin.right - keys.slice(0, i).reduce((acc, key) => acc + (t(key).length * 6.5), 0) - 10
                if(widthDifference < 0 ) {
                    line = 1
                    previousDataLength = - widthDifference
                }
                 */
                return `translate(${-previousDataLength},${-20 + (15*line)})`
            })

        enterSelection
            .append("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key]})

        enterSelection
            .append("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return t(key)
            });

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars" />
                        <g className="values" />
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                    </g>
                    <g className="legendContainer"/>
                </svg>
            </div>
        )
    }
}

export default DoubleBarChart;
