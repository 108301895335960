const _ = require('lodash')
const { generateFetchFieldListAction } = require('../../../../apps/KpModule/actions/api')

export const module_ = {
    object: 'Batch',
    name: 'Generation',
    tKey: 'mTitle_batchGeneration',
    objectIdentifier: 'reference',
    removable: false,
    category: {
        path: 'bank',
        icon: 'repeat'
    },
    viewMap: {
        dt: [
            'reference',
            {path: 'process', translate: true},
            {path: 'amount', tKey: 'amount(€)', tooltip: true},
            {path: 'requestsReferences', initiallyNotVisible: true},
            {path: 'orderLinesLength', tKey: 'number'},
            {path: 'status', translate: true},
            {path: 'creationDate', tKey: 'dateAndTime'},
            {path: 'xlsxFile', tKey: 'xlsx', width: 50},
            {path: 'xmlFile', tKey: 'xml', width: 50}
        ],
        form: [
            {path: 'reference', disabled: true},
            {
                path: 'process',
                required: true,
                editable: false,
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        const state = store.getState()
                        const objectMode = state.ui.objectMode
                        if (newValue && newValue.id) {
                            const statutes = objectMode === 'newObject' ? ['validated', 'actualized', 'waiting'] : null
                            const data = {processType: newValue.id, statutes}
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-R-generation.Batch_orderLines",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            ).then(() => {
                                if(objectMode === 'newObject') {
                                    const field = module.viewMap.form.fields.find(
                                        field => field.path === 'orderLines'
                                    )
                                    const newState = store.getState()
                                    const list = newState.dataLists.byId['m-R-generation.Batch_orderLines'].list
                                    field.setValue(list.allIds.map(id => list.byId[id]))
                                }
                            })
                        }
                    }
                }
            },
            {
                path: 'orderLines',
                fieldPath: ['id', 'fullReference', 'amount', 'processType', 'reason', 'IBAN', 'BIC', 'recipient'],
                display: 'fullReference',
                filters: ['byStatus', 'byProcessType'],
                editable: false,
                required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        const amount = module.viewMap.form.fields.find(
                            field => field.path === 'amount'
                        )
                        const number = module.viewMap.form.fields.find(
                            field => field.path === 'orderLinesLength'
                        )

                        if (newValue) {
                            const total = newValue.reduce((acc, line) => acc + parseFloat(line.amount), 0)
                            amount.setValue(_.round(total, 2))
                            number.setValue(newValue.length)
                        }else{
                            amount.setValue(0)
                            number.setValue(0)
                        }
                    }
                }
            },
            {path: 'amount', tKey: 'amount(€)', disabled: true},
            {path: 'orderLinesLength', tKey: 'number', disabled: true},
            {path: "buttons", hidden: true}
        ]
    },
    filters: ['byExercise']
}
