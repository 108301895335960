import moment from 'moment'
import _ from 'lodash'
import { getStoreHabilitationQuery } from '../../utils/habilitations'

async function findData(context) {

    const habQuery = getStoreHabilitationQuery(context)
    const [habilitations] = await Promise.all([habQuery])

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    if (habilitations.length) {
        const datesQuery = {
            'date.gte': { $gte: dates[0].toDate() },
            'date.lt': { $lte: dates[1].toDate() }
        }

        const habilitation = habilitations[0]
        const store = habilitation.store
        const organization = habilitation.organization

        const alertConfigurationCollection =
            global.app.S.AlertConfiguration.collection

        /***** panier moyen *****/

        const panierAlertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'PanierMoyen7jours'
            })
            .toArray()

        /***** articles / ticket *****/

        const articlesSurTicketAlertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'ArticlesSurTicket7Jours'
            })
            .toArray()

        /***** Prix de Vente Moyen *****/

        const prixVenteMoyenAlertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'PrixVenteMoyen7jours'
            })
            .toArray()

        /***** *****/

        const [
            panierAlertConfigurations,
            articlesSurTicketAlertConfigurations,
            prixVenteMoyenAlertConfigurations
        ] = await Promise.all([
            panierAlertConfigurationQuery,
            articlesSurTicketAlertConfigurationQuery,
            prixVenteMoyenAlertConfigurationQuery
        ])
        const panierAlertConfiguration = panierAlertConfigurations[0]
        const articlesSurTicketAlertConfiguration =
            articlesSurTicketAlertConfigurations[0]
        const prixVenteMoyenAlertConfiguration =
            prixVenteMoyenAlertConfigurations[0]

        /***** panier moyen *****/

        const queryPanierMoyen = {
            ...datesQuery,
            alertConfiguration: panierAlertConfiguration._id,
            'theme-CANet': { $exists: true },
            'theme-NbTicket': { $exists: true }
        }

        const alertCollection = global.app.S.Alert.collection
        const panierStoreQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryPanierMoyen,
                        store: store._id
                    }
                },
                {
                    $group: {
                        _id: '$hic-store',
                        ca: { $sum: '$theme-CANet.Mt' },
                        nbTicket: { $sum: '$theme-NbTicket.Nb' }
                    }
                }
            ])
            .toArray()

        const panierOrgnizationQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryPanierMoyen,
                        organizations: organization._id
                    }
                },
                {
                    $group: {
                        _id: 'panierMoyenRegion',
                        ca: { $sum: '$theme-CANet.Mt' },
                        nbTicket: { $sum: '$theme-NbTicket.Nb' },
                        stores: { $push: '$hic-store' }
                    }
                }
            ])
            .toArray()

        /***** articles / ticket *****/

        const queryArticleSurTicket = {
            ...datesQuery,
            alertConfiguration: articlesSurTicketAlertConfiguration._id,
            'theme-CANet': { $exists: true },
            'theme-NbTicket': { $exists: true }
        }

        const articleSurTicketStoreQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryArticleSurTicket,
                        store: store._id
                    }
                },
                {
                    $group: {
                        _id: '$hic-store',
                        nbArticle: { $sum: '$theme-CANet.Nb' },
                        nbTicket: { $sum: '$theme-NbTicket.Nb' }
                    }
                }
            ])
            .toArray()

        const articleSurTicketOrganizationQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryArticleSurTicket,
                        organizations: organization._id
                    }
                },
                {
                    $group: {
                        _id: 'articleSurTicketRegion',
                        nbArticle: { $sum: '$theme-CANet.Nb' },
                        nbTicket: { $sum: '$theme-NbTicket.Nb' },
                        stores: { $push: '$hic-store' }
                    }
                }
            ])
            .toArray()

        /***** Prix de Vente Moyen *****/

        const queryPrixVenteMoyen = {
            ...datesQuery,
            alertConfiguration: prixVenteMoyenAlertConfiguration._id,
            'theme-CANet': { $exists: true }
        }

        const prixVenteMoyenStoreQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryPrixVenteMoyen,
                        store: store._id
                    }
                },
                {
                    $group: {
                        _id: '$hic-store',
                        mtVentes: { $sum: '$theme-CANet.Mt' },
                        nbVentes: { $sum: '$theme-CANet.Nb' }
                    }
                }
            ])
            .toArray()

        const prixVenteMoyenOrganizationQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...queryPrixVenteMoyen,
                        organizations: organization._id
                    }
                },
                {
                    $group: {
                        _id: 'prixVenteMoyenRegion',
                        mtVentes: { $sum: '$theme-CANet.Mt' },
                        nbVentes: { $sum: '$theme-CANet.Nb' }
                    }
                }
            ])
            .toArray()


        ////////////

        const [panierStoreAlerts, panierOrganizationAlerts] = await Promise.all([
            panierStoreQuery,
            panierOrgnizationQuery
        ])

        const [articleSurTicketStoreAlerts, articleSurTicketOrganizationAlerts] = await Promise.all([
            articleSurTicketStoreQuery,
            articleSurTicketOrganizationQuery
        ])

        const [prixVenteMoyenStoreAlerts, prixVenteMoyenOrganizationAlerts] = await Promise.all([
            prixVenteMoyenStoreQuery,
            prixVenteMoyenOrganizationQuery
        ])


        const articleSurTicketStore = articleSurTicketStoreAlerts[0] && _.round(
            articleSurTicketStoreAlerts[0].nbArticle /
                articleSurTicketStoreAlerts[0].nbTicket,
            1
        )

        const panierStore = panierStoreAlerts[0] && _.round(
            panierStoreAlerts[0].ca / panierStoreAlerts[0].nbTicket,
            1
        )

        const panierOrganizationAlert = panierOrganizationAlerts[0]
        const panierRegion = panierOrganizationAlert && _.round(
            panierOrganizationAlert.ca /
            panierOrganizationAlert.nbTicket,
            1
        )

        const articleSurTicketOrganizationAlert =
            articleSurTicketOrganizationAlerts[0]
        const articleSurTicketRegion = articleSurTicketOrganizationAlert && _.round(
            articleSurTicketOrganizationAlert.nbArticle /
                articleSurTicketOrganizationAlert.nbTicket,
            1
        )

        const prixVenteMoyenStore = prixVenteMoyenStoreAlerts[0] && _.round(
            prixVenteMoyenStoreAlerts[0].mtVentes / prixVenteMoyenStoreAlerts[0].nbVentes,
            1
        )

        const prixVenteMoyenOrganizationAlert = prixVenteMoyenOrganizationAlerts[0]
        const prixVenteMoyenRegion = prixVenteMoyenOrganizationAlert && _.round(
            prixVenteMoyenOrganizationAlert.mtVentes /
            prixVenteMoyenOrganizationAlert.nbVentes,
            1
        )


        return [
            {
                id: 'clientsIndicators',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: [
                    {
                        indicateur: 'Prix de vente moyen €',
                        'Mag.': prixVenteMoyenStore,
                        'Moy. Reg.': prixVenteMoyenRegion
                    },
                    {
                        indicateur: 'Articles/ticket (Nb)',
                        'Mag.': articleSurTicketStore,
                        'Moy. Reg.': articleSurTicketRegion
                    },
                    {
                        indicateur: 'Flux moyen €',
                        'Mag.': panierStore,
                        'Moy. Reg.': panierRegion
                    }
                ]
            }
        ]
    } else {
        return [
            {
                id: 'clientsIndicators',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: []
            }
        ]
    }
}

export const entity = {
    name: 'ClientsIndicators',
    dashboard: true,
    fields: ['id', 'subTitle', 'data'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
