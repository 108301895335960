import React from 'react'
import { Field } from 'redux-form'
import {StyledInput} from '../../../../components/Form/StyledInput'
import getFormValidations from './formValidations'

// binding for redux-form
const Input = ({ input, meta:{touched, error}, id, tKey, path, placeholder, editable, disabled, translate, t, required }) => {

    const translatedValue = translate ? t(input.value) : input.value
    const inputProps = Object.assign(input, {value: translatedValue})
    const translatedError = t(error)
    return <StyledInput {...inputProps} id={id} path={tKey || path} touched={touched} error={translatedError} required={required} disabled={disabled || !editable} placeholder={t(placeholder || 'typeSomething')} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094


class FormTextField extends React.Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const { field, t, objectMode, editObjectValues } = this.props
        const validations = getFormValidations(field, t, [], editObjectValues)

        return (
            <Field
                name={field.path}
                id={field.id}
                tKey={field.tKey}
                path={field.path}
                required={field.required}
                component={Input}
                placeholder={field.placeholder}
                validate={validations}
                normalize={(value) => {
                    console.log('field', field)
                    if(field.uppercase) return value.toUpperCase()
                    if(field.lowercase) return value.toLowerCase()

                    return value
                }}
                disabled={field.disabled || !field.writable}
                editable={!field.editable ? objectMode === 'newObject' : field.editable  }
                translate={field.translate}
                t={t}
            />
        )
    }
}
export default FormTextField
