import React from 'react'
import { Field } from 'redux-form'
import {StyledInput} from "../../../../components/Form/StyledInput";

const Input = ({ input, path }) => <StyledInput {...input} path={path} disabled={true} />

const ReadOnlyObject = ({ field }) => {
    return <Field name={`${field.path}.${field.display}`} path={field.tKey || field.path} component={Input} />
}

export default ReadOnlyObject
