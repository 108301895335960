const _ = require('lodash');
const moment = require('moment')

async function findData(context, callback) {

    const countryProjectCollectionName = global.app.I.Country.collectionName
    const zoneProjectCollectionName = global.app.I.Zone.collectionName
    const businessProjectCollection = global.app.I.BusinessProject.collection
    const year = moment().year()
    const signedBusinessProjectQuery = businessProjectCollection
        .aggregate([
            {
                $match: {signatureDate: {$ne: null}}
            },
            {
                $project:{
                    signatureYear: {$year: '$signatureDate'},
                    country: 1
                }
            },
            {
                $match: {signatureYear: year}
            },
            {
                $lookup: {
                    from: countryProjectCollectionName,
                    localField: 'country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            {
                $lookup: {
                    from: zoneProjectCollectionName,
                    localField: 'country.zone',
                    foreignField: '_id',
                    as: 'zone'
                }
            },
            {
                $unwind: '$zone'
            },
            {
                $group: {
                    _id: { zone: '$zone.code' },
                    zone: {$first: '$zone.code'},
                    count: {$sum:1},
                }
            }
        ])
        .toArray()

    const realizationBusinessProjectQuery = businessProjectCollection
        .aggregate([
            {
                $match: {'workflow.step': 'realisation'}
            },
            {
                $lookup: {
                    from: countryProjectCollectionName,
                    localField: 'country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            {
                $lookup: {
                    from: zoneProjectCollectionName,
                    localField: 'country.zone',
                    foreignField: '_id',
                    as: 'zone'
                }
            },
            {
                $unwind: '$zone'
            },
            {
                $group: {
                    _id: { zone: '$zone.code' },
                    zone: {$first: '$zone.code'},
                    count: {$sum:1},
                }
            },
        ])
        .toArray()

    const [
        signedBusinessProjects,
        realizationBusinessProjects
    ] = await Promise.all([
        signedBusinessProjectQuery,
        realizationBusinessProjectQuery
    ])

    const signedByZone = _.groupBy(signedBusinessProjects, 'zone')
    const realizationByZone = _.groupBy(realizationBusinessProjects, 'zone')

    const zones = _.uniq([...Object.keys(signedByZone), ...Object.keys(realizationByZone)])

    const data = _.sortBy(zones).map(zone => {
        const zoneSignedData = signedBusinessProjects.find(o => o.zone === zone)
        const zoneRealizationData = realizationBusinessProjects.find(o => o.zone === zone)
        return {
            axis: zone,
            signed: zoneSignedData ? zoneSignedData.count : 0,
            inProgress: zoneRealizationData ? zoneRealizationData.count : 0,
        }
    })

    const totalRealization = _.reduce(data, (acc, o) =>  acc + o.inProgress, 0)
    const totalSigned = _.reduce(data, (acc, o) =>  acc + o.signed, 0)

    return [
        {
            id: "contractsNumberPerZone",
            subTitle: {
                text: 'contractsNumberSubtitle',
                data: {
                    totalSigned: totalSigned,
                    totalRealization: totalRealization
                }
            },
            data
        }
    ]
}

export const entity = {
    name: 'ContractsNumberPerZone',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
