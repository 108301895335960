const _ = require('lodash')

export default {
    id: 'V',
    name: 'VALODIAL',
    description: '',
    entities: _.concat(
        require('./valodial/referential').entities,
        require('./valodial/question').entities,
        require('./valodial/meeting').entities
    ),
    modules: _.concat(
        [
            require('./valodial/meeting').organization,
            require('./valodial/question').agenda,
            require('./valodial/question').preparation,
            require('./valodial/meeting').presence,
            require('./valodial/question').animation,
            require('./valodial/question').report,
            require('./valodial/meeting').synthesis,
        ],
        require('./valodial/referential').modules
    )
}
