import React from 'react'
import SpinningDots from '../../components/Loader/SpinningDots'
import './LongCard.css'
import {ChevronRight} from 'react-feather'
import { Link } from 'react-router-dom'

class LongCard extends React.Component {

    componentDidMount() {
        const { dataFetcher } = this.props
        dataFetcher && dataFetcher()
    }

    componentDidUpdate(prevProps) {
        const { dataFetcher, path } = this.props

        if ( path !== prevProps.path ) {
            dataFetcher && dataFetcher()
        }
    }

    render() {
        const {title = "", data, isFetching, link } = this.props

        return (<div className="LongCard-container col-sm-12 col-md-8 col-lg-8">
                <div className="LongCard">
                    {
                        isFetching ? <SpinningDots/> : (
                            <div>
                                <h4>{title}</h4>
                                <div className="LongCard-content"> {data} </div>
                                <div className="LongCard-link">
                                    {
                                        link.title && (
                                            <Link
                                                to={link.path}
                                            >
                                                {link.title}
                                                <ChevronRight className="ShortCard-icon"/>
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}
export default LongCard
