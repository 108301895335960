/* eslint-disable no-unused-vars */
import _ from 'lodash'
import moment from 'moment'
import { basicContext } from '../../utils/contextUtils'

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

async function listStateModels(context) {
    const habilitationsPromise = global.app.U.HabilitationState.collection.find({
        user: new global.ObjectID(context.user.id),
        group: new global.ObjectID(context.group.id)
    }).toArray()

    const [habilitations] = await Promise.all([habilitationsPromise])

    const habilitationStateModels = _.flatten(habilitations.map(
        hab => hab.stateModels
    ))
    const ModelsPromise = global.app.U.StateModel.collection
        .find({
            _id: {$in: habilitationStateModels.map(id => global.ObjectID(id))},
            stateModelType: 'stock',
            group: new global.ObjectID(context.group.id)
        })
        .toArray()
    const [models] = await Promise.all([ModelsPromise])

    return models.map(model => ({
        id: model._id.toString(),
        stateModel: model.name
    }))
}

async function getStateModel(stateModelId, context) {
    let parentContext = {}

    const {
        sum,
        merge,
        mergeBy,
        applyOperation,
        getExerciseLastMonth,
        monthExerciseStart,
        getPreviousExerciseLastMonth,
        exerciseFromMonth,
        getRangeMonth,
        getRangeOfMonthsFromExerciseStart,
        getNextMonth,
        getPreviousMonth,
        getPreviousYearMonth,
        getLastMonthOfData,
        getLastMonthOfLastFile,
        calculateValueEvolution,
        calculatePercentEvolution,
        calculatePercentEvolutionByElement,
        addElementToChunk,
        getKeyFromObject,
        formatData,
        getAggregateGroupBy,
        getAggregateColumns,
        getConditions,
        data
    } = require('./utils/stateComputationUtils')

    parentContext['executionMonth'] = moment().format('YYYY-MM')
    parentContext['month'] = _.get(context, 'data.month')
    parentContext['mesh'] = ['reason']
    parentContext['meshName'] = ' '


    const queryWithGroup = query => ({
        ...query,
        group: new global.ObjectID(context.group.id)
    })
    let depositName = ''
    if (_.get(context, 'data.deposit')) {
        depositName = _.get(context, 'data.deposit.fullName')

        const deposit = await global.app.U.Deposit.get(
            {_id: new global.ObjectID(_.get(context, 'data.deposit.id'))},
            {...basicContext(context)}
        )

        if (deposit) {
            parentContext['query'] = {
                depositCode: deposit.code
            }
        }
    }

    const execAML = async stateModelLine => {
        let currentContext = {
            ...parentContext
        }
        const accountModel = stateModelLine.accountModel
        let amlResult
        await eval(accountModel.script)
            .then(response => {
                amlResult = response
            })
            .catch(() => (amlResult = []))
        return amlResult
    }

    const stateModel = await global.app.U.StateModel.get(
        { _id: new global.ObjectID(stateModelId) },
        {
            ...basicContext(context),
            fieldPath: [
                'code',
                'name',
                'stateModelLines',
                'stateModelLines.*',
                'stateModelLines.accountModel.*'
            ]
        }
    )

    const stateModelLines = []
    let history = {}
    await asyncForEach(_.orderBy(stateModel.stateModelLines, 'order'), async sml => {
        if(history[sml.accountModel.id]) {
            stateModelLines.push(...history[sml.accountModel.id])
        }else {
            const result = await execAML(sml)
            history[sml.accountModel.id] = result
            stateModelLines.push(...result)
        }
    })

    return {
        id: stateModelId,
        name: `${stateModel.name} - ${depositName}`,
        stateModelLines
    }
}

export const entity = {
    name: 'StockAdjustmentStateComputation',
    fields: ['stateModel'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                listStateModels(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else
                getStateModel(id, context)
                    .then(object => callback(null, object))
                    .catch(error => callback(error))
        })
    }
}

export const module_ = {
    object: 'StockAdjustmentStateComputation',
    tKey: 'mTitle_stockAdjustmentStateComputation',
    noLeftBoard: true,
    removable: false,
    updatable: false,
    newable: false,
    defaultSortBy: 'stateModel',
    defaultSortDirection: 'ASC',
    category: {
        path: 'Analyse',
        icon: 'grid'
    },
    viewMap: {
        dt: ['stateModel'],
        form: [
            { path: 'name', type: 'readOnly', fullWidth: true },
            {
                path: 'stateModelLines',
                type: 'styledTable',
                autoGrow: true,
                fullWidth: true,
                fields: [
                    { path: 'mesh', style:{flex: '1 1 500px', fontSize: '12px', whiteSpace: 'initial'} },
                    { path: 'firstChunk', dynamic: true },
                    { path: 'secondChunk', dynamic: true },
                    { path: 'style', hidden: true },
                    { path: 'isHeader', hidden: true }
                ]
            }
        ]
    },
    filters: [
        {
            object: 'Deposit',
            path: 'deposit',
            display: 'fullName',
            clearable: true,
            client: true,
            autoFetch: false
        },
        {
            path: 'month',
            type: 'monthPicker',
            client: true,
            autoFetch: false,
            default: moment()
                .subtract(1, 'months')
                .format('YYYY-MM')
        }
    ]
}
/* eslint-enable no-unused-vars */
