import React from 'react'
import PropTypes from 'prop-types'
import HamburgerIcon from './HamburgerIcon'
import LanguageSwitcher from './LanguageSwitcher'
import { Route, Link } from 'react-router-dom'
import './Navbar.css'
import Profile from './Profile'
import Job from './Job'
import Docs from './Docs'
import NotFoundRoute from '../NotFoundRoute/NotFoundRoute'
import coloredHorizontalLogo from '../../../../img/keenpoint-logo-horizontal.png'
import blackHorizontalLogo from '../../../../img/keenpoint-logo-horizontal-preta.png'
import whiteHorizontalLogo from '../../../../img/keenpoint-logo-horizontal-branca.png'
import {Home, MessageSquare} from 'react-feather'
import NotificationBubble from "../../../../components/NotificationBubble";

const Navbar = ({
    toggleSideBar,
    profileOpened,
    setProfileOpened,
    jobOpened,
    docsOpened,
    setJobOpened,
    setDocsOpened,
    documents,
    languagesOpened,
    setLanguagesOpened,
    logout,
    language,
    languagesList,
    user,
    isUserK,
    groupModels,
    notifications,
    iconColor,
    kpLogo
}) => {
    const logoHorizontal = kpLogo === 'white'
        ? whiteHorizontalLogo
        : kpLogo === 'black'
            ? blackHorizontalLogo
            : coloredHorizontalLogo
    return (
        <nav className="Main-navbar">
            <div style={{flex: 1}}>
                <HamburgerIcon onClick={toggleSideBar} color={iconColor} />
            </div>
            <div style={{flex: 1, textAlign: 'center'}}>
                <img alt="kp-logo" style={{cursor: 'pointer'}} width={120} onClick={() => window.open('https://keenpoint.com')} src={logoHorizontal}/>
            </div>
            <div style={{flex: 1}}>
                <div className="navbar-right">
                    {/*<span style={{margin: "7px 15px 0 0"}}>{device === "desktop" && date}</span>*/}
                    <Route
                        path="/business/:groupModelId"
                        render={({ match }) => {
                            return (
                                <NotFoundRoute params={match.params}>
                                    {({ groupModel }) => {
                                        return isUserK && groupModel && groupModel.jobs.length ? (
                                            <Job
                                                color={iconColor}
                                                groupModel={groupModel}
                                                jobOpened={jobOpened}
                                                setJobOpened={setJobOpened}
                                            />
                                        ) : null
                                    }}
                                </NotFoundRoute>
                            )
                        }}
                    />
                    <Route
                        path="/business/:groupModelId"
                        render={({ match }) => (

                            <Link
                                to={`/business/${match.params.groupModelId}`}
                            >
                                <button className="navbar-icon-container">
                                    <Home
                                        color={iconColor}
                                        className="navbar-icon"
                                    />
                                </button>
                            </Link>
                        )}
                    />
                    {
                        documents && documents.length !== 0 && (<Route
                            path="/business/:groupModelId"
                            render={({ match }) => (
                                <NotFoundRoute params={match.params}>
                                    {({ groupModel }) => {
                                        return groupModel ? (
                                            <Docs
                                                color={iconColor}
                                                documents={documents}
                                                docsOpened={docsOpened}
                                                setDocsOpened={setDocsOpened}
                                                language={language}
                                            />
                                        ) : null
                                    }}
                                </NotFoundRoute>
                            )}
                        />)
                    }
                    <Route
                        path="/business/:groupModelId"
                        render={({ match }) => {
                            return (
                                <NotFoundRoute params={match.params}>
                                    {({ groupModel }) => {
                                        const currentGroupModel = groupModels[groupModel.id]
                                        const messageModuleId = `m-${groupModel.model.id}-tickets`
                                        if(currentGroupModel.modules.includes(messageModuleId)) {
                                            const notification = notifications[groupModel.id] && notifications[groupModel.id][messageModuleId]
                                            return <Link
                                                className="navbar-icon-container"
                                                to={`/business/${match.params.groupModelId}/communication/${messageModuleId}`}
                                            >
                                                <MessageSquare
                                                    color={iconColor}
                                                    size={19}
                                                    className="navbar-icon"
                                                />
                                                {
                                                    notification && notification > 0 && <span className="Navbar-notification"/>
                                                }
                                            </Link>
                                        }
                                        return null
                                    }}
                                </NotFoundRoute>
                            )
                        }}
                    />
                    <LanguageSwitcher
                        color={iconColor}
                        languagesOpened={languagesOpened}
                        setLanguagesOpened={setLanguagesOpened}
                        language={language}
                        languagesList={languagesList}
                    />
                    <Profile
                        color={iconColor}
                        profileOpened={profileOpened}
                        setProfileOpened={setProfileOpened}
                        logout={logout}
                        user={user}
                    />
                </div>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    toggleSideBar: PropTypes.func.isRequired,
    profileOpened: PropTypes.bool.isRequired,
    setProfileOpened: PropTypes.func.isRequired,
    jobOpened: PropTypes.bool.isRequired,
    docsOpened: PropTypes.bool.isRequired,
    setJobOpened: PropTypes.func.isRequired,
    setDocsOpened: PropTypes.func.isRequired,
    languagesOpened: PropTypes.bool.isRequired,
    setLanguagesOpened: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isUserK: PropTypes.bool.isRequired
}

export default Navbar
