
/*async function findData(context, callback) {
    console.time("dataSearch");

    const stateModelyears = [2010, 2011, 2013, 2014, 2016, 2018, 2020]


    // les modèles de comptes
    const accountModels = await global.app.F.AccountModel.collection.find().toArray()
    const accountModelsByCode = accountModels.reduce((acc, model) => ({
        ...acc,
        [model.code]: {
            id: model._id,
            formula: model.formula,
        }
    }), {})

    // le modèle d'état
    const stateModel = Object.keys(accountModelsByCode).slice(0,51)


    const types = {
        F: {'subsidiary': 72},
        M: {'shop': 4},
        P: {'country': 5}
    }



    const hermesData = await global.app.F.DataHermes.collection.find().toArray()

    console.timeEnd("dataSearch");



    const data = (type, data, year) => {
        const dataRow = _.find(hermesData, {...types[type], data, year})
        return dataRow ? dataRow.value : 0
    }



    const f_year = 2010
    const project_type = "N"
    const project_real_opening_date = "01/01/2010"

    const irr = () => 0
    const is_eur = () => true
    const getYear = date => 2010
    const min = _.min
    const max = _.max
    const sum = _.sum
    const avg = array => sum(array)/array.length

    const cacheObject = {}

    const formulaM = (code, beginYear, lastYear)  => {

        if (beginYear < 2010) return 0
        const accountModel = accountModelsByCode[code]
        if (!accountModel || (accountModel === Infinity))
            return 0
        if(lastYear){
            const results = []
            for(let year = beginYear; year < lastYear; year++) {
                if (cacheObject[`${code}-${year}`]){
                    results.push(cacheObject[`${code}-${year}`])
                }else {
                    const result = eval(accountModel.formula)
                    cacheObject[`${code}-${year}`] = result
                    results.push(result)
                }
            }
            return results
        }else{
            let year = beginYear

            if (cacheObject[`${code}-${year}`]){
                return cacheObject[`${code}-${year}`]
            }

            const result = eval(accountModel.formula)
            cacheObject[`${code}-${year}`] = result
            return result
        }


    }

    console.time("computeTime");

    const result = stateModel.map(code => {
        const am = accountModelsByCode[code]
        return stateModelyears.reduce((acc, year) => {
                return {
                    ...acc,
                    years: {
                        ...acc.years,
                        [year]: formulaM(code, year)
                    }
                }

            },
            {id: am.id, name: code, years: {}})
    })

    console.timeEnd("computeTime");

    return result
}*/



export const module_ = {
    name: 'StateModel',
    object: 'StateModel',
    tKey: 'mTitle_stateModel',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    category: {
        path: 'hermes',
        icon: 'server'
    },
    viewMap: {
        dt: ['name'],
        form: [
            {path: "code", required: true},
            {path: "name", required: true},
            {
                path: 'yearsStateModelLines',
                tKey: 'Years SML',
                removable: true,
                viewMap: {
                    dt: ['order', {path: 'accountModel', display: 'code'}],
                    form: ['order', {path: 'accountModel', display: 'code'}]
                }
            },
            {
                path: 'valueStateModelLines',
                tKey: 'Value SML',
                removable: true,
                viewMap: {
                    dt: ['order', {path: 'accountModel', display: 'code'}],
                    form: ['order', {path: 'accountModel', display: 'code'}]
                }
            }
        ]
    }
}

export const entity = {
    name: "StateModel",
    facets: ["codeName"],
    fields: [
        {
            path: 'yearsStateModelLines',
            type: 'StateModelLine',
            link: {
                type: 'OTM',
                external: false
            }
        },
        {
            path: 'valueStateModelLines',
            type: 'StateModelLine',
            link: {
                type: 'OTM',
                external: false
            }
        }
    ]
}

export const dependencies = [
    {
        name: 'StateModelLine',
        fields: ['order', 'AccountModel']
    }
]
