const moment = require('moment')
export const returnButton = {
    type: 'return',
    tKey: 'return',
    bsStyle: 'default'
}

export const saveButton = {
    type: 'save',
    tKey: 'save',
    bsStyle: 'success'
}

export const validateButton = {
    action: 'validate',
    bsStyle: 'primary',
    tKey: 'validate',
    type: 'action'
}

export const refuseButton = {
    action: 'refuse',
    bsStyle: 'danger',
    tKey: 'refuse',
    type: 'action'
}

export const rectifyButton = {
    action: 'rectify',
    bsStyle: 'warning',
    tKey: 'toRectify',
    type: 'action'
}

const justifyButton = {
    action: 'justify',
    bsStyle: 'warning',
    tKey: 'toJustify',
    type: 'action'
}


export const distributeButton = {
    action: 'distribute',
    bsStyle: 'primary',
    tKey: 'distribute',
    type: 'action'
}

export const simulateButton = {
    action: 'simulate',
    bsStyle: 'success',
    tKey: 'simulate',
    type: 'action'
}

export const requestButtons = [
    {
        ...validateButton,
        tKey: 'submitMyRequest'
    },
    {
        ...saveButton,
        tKey: 'saveMyRequest'
    },
    returnButton
]

export const invoiceRequestButtons = [
    validateButton,
    saveButton,
    returnButton
]

export const controlButtons = [
    rectifyButton,
    validateButton,
    refuseButton,
    returnButton
]

export const validationButtons= [
    justifyButton,
    validateButton,
    refuseButton,
    returnButton
]

export const saveBeforePayment = {
    action: 'save',
    bsStyle: 'success',
    tKey: 'saveBeforePayment',
    type: 'action'
}

export const saveBeforeRefund = {
    action: 'wait',
    bsStyle: 'success',
    tKey: 'saveBeforeRefund',
    type: 'action'
}

export const requestValidation = {
    action: 'forward',
    bsStyle: 'primary',
    tKey: 'requestReallocationValidation',
    type: 'action'
}

export function validateAge(birthDate, exerciseDate, ageLimit) {
    const birthday = moment(birthDate, 'YYYY-MM-DD')
    const birthDayLimit = moment(exerciseDate, 'YYYY-MM-DD').subtract(ageLimit, 'years').subtract(1, 'days')

    return birthday.isAfter(birthDayLimit)
}

function getFormattedDate(date1, date2, t = x => x) {
    const intervals = ['years','months','days']
    const out = []

    intervals.forEach(function(interval) {
        const diff = date2.diff(date1, interval)
        date1.add(diff, interval)
        out.push((diff > 0 ? diff : '0') + ' ' + t(interval))
    });
    return out.join(' ');
}

export function calculateAge(birthDate, exerciseDate, t) {
    const birthdayMoment = moment(birthDate, 'YYYY-MM-DD')
    const exerciseMoment = moment(exerciseDate, 'YYYY-MM-DD')
    return getFormattedDate(birthdayMoment, exerciseMoment, t)
}
