import React, {Component} from 'react';
import _ from 'lodash'
import * as d3 from 'd3'
import { select } from 'd3-selection';

function chunkString(str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
}

const z = ['#8e8e8e', '#1568ad', '#0e9729', '#dab350', '#da6a2e']

const colorByKey = {
    innerKey1: '#8e8e8e',
    innerKey2: '#1568ad',
    innerKey3: '#0e9729',
    innerKey4: '#da6a2e'
}
const defaultColors = ['#00afff', '#1f80b9', '#215477', '#192c3b', '#000000']
const margin = { top: 40, right: 20, bottom: 50, left: 50 }


class StackedMultipleBarChart extends Component {
    constructor(props) {
        super(props);
        this.transformLegend = this.transformLegend.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) //JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }
    transformLegend(d, i) {
        const {w, t, dataKeys , defaultKeys} = this.props
        const keys = dataKeys || defaultKeys

        let line = 0, previousDataLength = -10, j = 0

        while(j < i){

            previousDataLength += (t(keys[j]).length * 5) + 25
            const currentLength = previousDataLength + (t(keys[j+1]).length * 5) + 25
            if(w - margin.right - 10 < currentLength ) {
                previousDataLength = -10
                line++
            }
            j++
        }
        return `translate(${-previousDataLength},${-20 + (15*line)})`
    }

    render() {
        const {data = [], h ,w, dataKeys , defaultKeys, yAxisUnit, colorRange, colors = defaultColors, t} = this.props
        const isStackedHorizontally = this.props.formValues?.graphType?.id === '2'
        //et par defaut, stackedVertically
        console.log('data', data)
        const keys = _(data).flatMap(item => {
            return _.keys(item.axisData)
            }).uniq().value()

        const innerKeys = _.sortBy(_(data)
            .flatMap(item => {
                const nestedKeys = _.flatMap(item.axisData, _.keys);
                return nestedKeys;
            })
            .uniq()
            .value());

        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom

        const x0 = d3.scaleBand()
            .paddingInner(0.2)
            .domain(data.map(d => d.axis)).rangeRound([0, width])


        const x1 = d3.scaleBand()
            .padding(0.05)
            .domain(keys).rangeRound([0, x0.bandwidth()])

        const x2 = d3.scaleBand()
            .padding(0.05)
            .domain(innerKeys).rangeRound([0, x1.bandwidth()])

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        // Number of colors to generate, based on the length of `innerKeys`
        const numColors = innerKeys.length > 1 ? innerKeys.length : innerKeys.length+1;

        // Generate an array of colors
        const colorScale9 = d3.scaleLinear()
            .domain([0, 1]) // Input domain
            .range(colorRange) // Output range (colors)
            .interpolate(d3.interpolateRgb); // Color interpolation method
        const colors1 = d3.range(numColors).map(d => colorScale9(d / (numColors - 1)));

        const colorsForInnerKeys = d3.scaleOrdinal(d3.schemeCategory10)
            .domain(innerKeys)

        if (innerKeys.length > d3.schemeTableau10.length) {
            colorsForInnerKeys.range(d3.schemePaired);  // Use a larger set if needed
        }

        const colorByInnerKey = innerKeys.reduce((acc, key, index) => ({
            ...acc,
            [key]: colors1[index]
        }), {});

        const xAxis = d3.axisBottom()
            .scale(x0)
        const xSubAxis = d3.axisBottom().scale(x1)



        const yAxis = d3.axisLeft(y)
        //.scale(y)
        //.ticks(5)  // You can adjust the number of ticks as needed
        //.tickFormat(d3.format("d"));  // This will format the ticks as integers

        const container = select(`.StackedMultipleBarChart`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const stackedMax = d3.max(data, d => {
            return d3.max(keys, key => {
                if(!d.axisData[key]) return 0
                return d3.sum(Object.values(d.axisData[key]))
            })
        })

        y.domain([0, stackedMax]).nice()

        container.select(".bars").selectAll(".group")
            .data(data)
            .attr("transform", (d, index) => "translate(" + (x0(d.axis)) +", 0)")

        const groups = container.select(".bars").selectAll(".group")
            .data(data)
            .enter()
            .append("g")
            .attr('class', 'group')
            .attr("transform", function(d, index) { return "translate(" + (x0(d.axis)) +", 0)"; })
        const oldGroups = container.select(".bars").selectAll(".group")
            .data(data)

        if (isStackedHorizontally){
            const outerGroups = groups.selectAll('.outer-group')
                .data(d => keys.map(key => ({key, values: d.axisData[key] || {}})))
                /*
                .data(d => {
                    const outerKeys = Object.keys(d).filter(key => keys.includes(key))
                    //const innerKeys = _.flatMap(outerKeys, key => Object.keys(d[key]))
                    const uniqKeys = _.uniq(innerKeys)
                    const sortedInnerKeys = _.sortBy(uniqKeys);
                    return keys.map(key =>{
                        const zeros = sortedInnerKeys.reduce((acc, innerKey) => {
                            if(d[key][innerKey] == null){
                                return {...acc, innerKey: 0}
                            }
                            return acc
                        } , {})
                        console.log('zeros', zeros)
                        const values = {...d[key], ...zeros}
                        return {key, values}
                    })
                })

                 */
                .enter()
                .append('g')
                .attr('class', 'outer-group')
                .attr('transform', d => `translate(${x1(d.key)}, 0)`);

            outerGroups.selectAll('rect')
                .data(d => Object.entries(d.values).map(([innerKey, value]) => ({innerKey, value})))
                .enter()
                .append('rect')
                .style('fill', d => colorByInnerKey[d.innerKey])
                .attr('x', d => x2(d.innerKey))
                .attr('width', x2.bandwidth())
                .attr("y", height)
                .attr('height', 0)
                .transition()
                .duration(1000)
                .attr('y', d => y(d.value))
                .attr('height', d => height - y(d.value))



            const oldOuterGroups = oldGroups.selectAll('.outer-group')
                .data(d => keys.map(key => ({key, values: d.axisData[key] || {}})))
                /*
                .data(d => {
                    const outerKeys = Object.keys(d).filter(key => keys.includes(key))
                    //const innerKeys = _.flatMap(outerKeys, key => Object.keys(d[key]))
                    const uniqKeys = _.uniq(innerKeys)
                    const sortedInnerKeys = _.sortBy(uniqKeys);
                    return keys.map(key =>{
                        const zeros = sortedInnerKeys.reduce((acc, innerKey) => {
                            if(d[key][innerKey] == null){
                                return {...acc, innerKey: 0}
                            }
                            return acc
                        } , {})
                        const values = {...d[key], ...zeros}
                        return {key, values}
                    })
                })

                 */
                .attr('transform', d => `translate(${x1(d.key)}, 0)`)

            oldOuterGroups.selectAll('rect')
                .data(d => Object.entries(d.values).map(([innerKey, value]) => ({innerKey, value})))
                .attr('x', d => {
                    return x2(d.innerKey)
                })
                .style('fill', d => colorByInnerKey[d.innerKey])
                .attr('width', x2.bandwidth())
                .attr('transform', d => `translate(${x1(d.key)}, 0)`)
                .attr("y", height)
                .attr('height', 0)
                .transition()
                .duration(1000)
                .attr('y', d => y(d.value))
                .attr('height', d => height - y(d.value))
            //.attr('transform', d => `translate(${x2(d.innerKey)}, 0)`);

            const barValues = data.flatMap(d =>
                keys.flatMap(key =>
                    Object.entries(d.axisData[key]).map(([innerKey, value]) => {
                        return {
                            axis: d.axis,
                            outerKey: key,
                            innerKey,
                            value
                        }
                    })
                )
            );

            const values = container
                .select(".values")
                .selectAll("text")
                .data(barValues)
                .join(
                    enter =>
                        enter.append("text")
                        .attr("x", d => {
                            console.log('1')
                            return x0(d.axis) + x1(d.outerKey) + x2(d.innerKey) + x2.bandwidth()/2
                        })
                        .attr("y", height)
                        .attr("dy", ".1em")
                        .style("text-anchor", "middle")
                        .style("font-size", "12px")
                        .text(d => parseFloat(d.value))
                        .call(enter => enter.transition().duration(1000)
                        .attr("y", d => y(d.value) - 5)),
                    update => update.call(update => update.transition().duration(1000)
                        .attr("x", d => {
                            console.log('2')
                            return x0(d.axis) + x1(d.outerKey) + x2(d.innerKey) + x2.bandwidth()/2
                        })
                        .attr("y", d => y(d.value) - 5)
                        .text(d => parseFloat(d.value))),
                    exit => exit.remove()
                );
        }
        else{
            /*
            select('.StackedMultipleBarChart')
                .selectAll('.layer')
                .each(function(d) {
                    select(this).selectAll('rect')
                        .data(d => {
                            d.forEach(table => {
                                table['innerKey'] = d.key;
                            });
                            return d;
                        })
                        .transition()
                        .duration(500)
                        .style('fill', d => {
                            console.log('vertical', d.innerKey, colorByInnerKey[d.innerKey], d);
                            return colorByInnerKey[d.innerKey];
                        });
                })

             */
            groups.selectAll('.layer')
                .data(function(d) { {
                    const outerKeys = Object.keys(d.axisData)
                    const innerKeys = _.flatMap(outerKeys, key => {
                        return Object.keys(d.axisData[key])
                    })
                    const uniqKeys = _.uniq(innerKeys)
                    const sortedInnerKeys = _.sortBy(uniqKeys);
                    const finalData = outerKeys.map(key => ({
                        key,
                        ...d.axisData[key]
                    }))
                    return d3.stack().keys(sortedInnerKeys)(finalData)
                }})
            const layer = groups.selectAll('.layer')
                    .data(function(d) { {
                        const outerKeys = Object.keys(d.axisData)
                        const innerKeys = _.flatMap(outerKeys, key => Object.keys(d.axisData[key]))
                        const uniqKeys = _.uniq(innerKeys)
                        const sortedInnerKeys = _.sortBy(uniqKeys);
                        const finalData = outerKeys.map(key => ({
                            key,
                            ...d.axisData[key]
                        }))
                        return d3.stack().keys(sortedInnerKeys)(finalData)
                    }})
                    .enter()
                    .append("g")
                    .attr("class", "layer")
                //.style('fill', (d, i) => (z[i]))



            layer.selectAll('rect')
                .data((d, index) => {
                    d.forEach(table=>{
                        table['innerKey'] = d.key
                    })
                    return d
                })
                .enter()
                .append('rect')
                .style('fill', (d, index) => {
                    return colorByInnerKey[d.innerKey]
                })
                .attr('x', d => {
                    return x1(d.data.key)
                })
                /*
                    singleDataPoint ? (width - x1.bandwidth()) / 2
                        : singleAxisMultipleOuterKeys ?
                            x1(d.data.key) + w/10
                            : x1(d.data.key))  // Add this line here

                 */
                .attr('width', x1.bandwidth())
                .attr("y", height)
                .attr('height', 0)
                //.on("mouseenter", function(event, d) {
                //    const value = d[1] - d[0];
                //    tooltip
                        //.transition()
                        //.duration(200)
                        //.style("opacity", .9);
                    //tooltip.html(`test`)
                    //    .style("left", (event.clientX) + "px")
                    //    .style("top", (event.clientY - 28) + "px");
                //})
                //.on("mouseleave", function(d) {
                //    tooltip.transition()
                //        .duration(500)
                //        .style("opacity", 0);
                //})
                .transition()
                .duration(1000)
                .attr('y', d => y(d[1]))
                .attr('height', d => {
                    return Math.abs(y(d[0]) - y(d[1]))
                })



            const oldLayers = oldGroups.selectAll('.layer').data(function(d) { {
                const outerKeys = Object.keys(d.axisData)
                const innerKeys = _.flatMap(outerKeys, key => Object.keys(d.axisData[key]))
                const uniqKeys = _.uniq(innerKeys)
                const sortedInnerKeys = _.sortBy(uniqKeys)
                const finalData = outerKeys.map(key => ({
                    key,
                    ...d.axisData[key]
                }))
                return d3.stack().keys(sortedInnerKeys)(finalData)
            }})



            oldLayers.selectAll('rect')
                .data((d, index) => {
                    d.forEach(table=>{
                        table['innerKey'] = d.key
                    })
                    return d
                })
                .style('fill', (d, index) => {
                    return colorByInnerKey[d.innerKey]
                })
                .attr('width', x1.bandwidth())
                .attr('x', function(d) {
                    return x1(d.data.key)
                    /*singleDataPoint ? (width - x1.bandwidth()) / 2
                        : singleAxisMultipleOuterKeys ?
                            x1(d.data.key) + w/10
                            : x1(d.data.key)
                     */
                })  // Add this line here
                .attr('width', x1.bandwidth())
                .attr("y", height)
                .attr('height', 0)

                //.on("mouseenter", function(event, d) {
                //const value = d[1] - d[0];
                //tooltip
                //    .transition()
                //    .duration(200)
                //    .style("opacity", .9);
                //tooltip.html(`test`)
                //    .style("left", event.clientX + "px")
                //    .style("top", (event.clientY - 28) + "px");
                //})
                //.on("mouseleave", function(d) {
                //    console.log("Mouse leave");
                //    tooltip.transition()
                //        .duration(500)
                //        .style("opacity", 0);
                //})
                .transition()
                .duration(1000)
                .attr('y', d => y(d[1]))
                .attr('height', d => {
                    return Math.abs(y(d[0]) - y(d[1]))
                })

            const barValues = keys.map(key => data.map(d => {
                return {axis: d.axis, key, value: _.sum(_.values(d.axisData[key]))}
            }))

            const values = container
                .select(".values")
                .selectAll("text")
                .data(_.flatten(barValues))

            values.exit()
                .remove()

            values.enter().append("text")
                .attr("x", function(d) { return x0(d.axis) + x1(d.key) + x1.bandwidth()/2 })
                .attr("y", height)
                .transition()
                .duration(1000)
                .attr("y", function(d) {
                    return y(d.value) - 5
                })
                .attr("dy", ".1em")
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .text(d => parseFloat(d.value));

            values
                .attr("x", function(d) { return x0(d.axis) + x1(d.key) + x1.bandwidth()/2 })
                .attr("y", height)
                .transition()
                .duration(1000)
                .attr("y", function(d) {
                    return y(d.value) - 5
                })
                .attr("dy", ".1em")
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .text(d => parseFloat(d.value))
        }
        /*

        // Add this before the return statement in your render method
        const tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("position", "absolute")  // Change this from "absolute" to "fixed"
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "0px")
            //.style('width', 40)
            //.style('height', 6);

         */

        //const barValues = keys.map(key => data.map( d => ({axis: d.axis, key, value: d[key]}) ))




        container.select('.axis--x')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0, ${height + 30})`)
            .call(xAxis)
            .selectAll("text")
            //.attr("transform", "rotate(-55)")
            .attr("dy",".50em")
            .style("text-anchor", "middle")
            .style("font-size", "13px") // Set the font size
            .call(function(k) {
                k.each(function(d){ // for each one
                    const self = d3.select(this);
                    const text = t(self.text())
                    const s = chunkString(text, 14)
                    if(s.length !== 1){
                        self.text(''); // clear it out
                        for(let i=0; i< s.length; i++){
                            self.append("tspan") // insert tspan for every bit
                                .attr("y", 10 + i*12)
                                .attr("x", -9)
                                .attr("dy",".50em")
                                .text(s[i])
                            //.style("text-anchor", "end")
                        }
                    }

                })
            })
    /*
        container.select('.axis--x-sub')
            .attr('class', 'axis axis--x-sub')
            .attr('transform', `translate(0, ${height})`)
            .call(xSubAxis)
            .selectAll("text")
            //.attr("transform", "rotate(-55)")
            .attr("dy",".35em")
            .style("text-anchor", "middle")
            .call(function(k) {
                k.each(function(d) {
                    const self = d3.select(this);
                    const text = t(d); // Use 'd' directly instead of self.text()
                    const s = chunkString(text, 14);
                    if (s.length !== 1) {
                        self.text(''); // clear it out
                        for (let i = 0; i < s.length; i++) {
                            self.append("tspan")
                                .attr("y", 10 + i * 12)
                                .attr("x", 0) // Changed from -9 to 0 for center alignment
                                .attr("dy", ".35em")
                                .text(s[i]);
                        }
                    } else {
                        self.text(text);
                    }
                });
            })

     */
        const subAxisGroup = container.selectAll('.axis--x-sub-group')
            .data(data)
            .join('g')
            .attr('class', 'axis--x-sub-group')
            .attr('transform', d => `translate(${x0(d.axis)},${height})`);

        subAxisGroup.each(function(d) {
            const subAxisGroup = d3.select(this);
            const subAxisScale = d3.scaleBand()
                .domain(keys)
                .range([0, x0.bandwidth()]);

            const subAxis = d3.axisBottom(subAxisScale);

            subAxisGroup.call(subAxis)
                .selectAll("text")
                .attr("dy", ".50em")
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .each(function(d) {
                const self = d3.select(this);
                const text = t(d); // Use 'd' directly instead of self.text()
                const lengthPerWidth = Math.floor( x1.bandwidth() * 18 / 100)

                const s = chunkString(text, lengthPerWidth);
                if (s.length !== 1) {
                    self.text(''); // clear it out
                    for (let i = 0; i < s.length; i++) {
                        self.append("tspan")
                            .attr("y", 10 + i * 12)
                            .attr("x", 0) // Changed from -9 to 0 for center alignment
                            .attr("dy", ".50em")
                            .text(s[i]);
                    }
                } else {
                    self.text(text);
                }
            })
        });

        /*
        // Sub x-axis (outer keys)
        const subAxes = container.select('.axis--x-sub')
            .attr('class', 'axis axis--x-sub')
            .attr('transform', `translate(0,${height + 0})`)  // Adjust this value as needed
            .selectAll('g')
            .data(data)
            .enter().append('g')
            .attr('transform', d => `translate(${x0(d.axis)},0)`);

        subAxes.append('g')
            .call(xSubAxis)
            .selectAll("text")
            .attr("dy", ".35em")
            .style("text-anchor", "middle")
            .text(function(d) {
                return t(d); // Apply translation function here
            });


         */
        /*
        container.select('.axis--y')
            .attr('class', 'axis axis--y')
            .attr('transform', `translate(0, 0)`)
            .call(yAxis);

         */

        const yAxisGroup = container.select('.axis--y')
            .attr('class', 'axis axis--y')
            .attr('transform', `translate(0, 0)`);

        yAxisGroup.call(yAxis);

        yAxisGroup.select('.y-axis-unit').remove(); // Remove any existing unit label

        yAxisGroup.append("text")
            .attr("class", "y-axis-unit")
            .attr("x", -20)
            .attr("y", -25)
            .attr("dy", ".71em")
            .style("text-anchor", "start")
            .style("font-size", "13px")
            .style("fill", "black")
            .text(yAxisUnit);



        /*
        const legend = container
            .selectAll(".legend")
            .data(innerKeys)

        legend
            .attr("transform", this.transformLegend)
        legend
            .select("rect")
            .attr("x", width - 100)
            .attr("y", -13)
            .attr("width", 16)
            .attr("height", 16)
            .style("fill", function(key) {return colorByInnerKey[key]})

        legend
            .select("text")
            .attr("x", width - 105)
            .style("text-anchor", "end")
            .style("font-size", "10px")
            .text(function(key) {
                return t(key)
            });

        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", this.transformLegend)

        enterSelection
            .append("rect")
            .attr("x", width - 100)
            .attr("y", -13)
            .attr("width", 16)
            .attr("height", 16)
            .style("fill", function(key) {return colorByInnerKey[key]})


        enterSelection
            .append("text")
            .attr("x", width - 105)
            .style("text-anchor", "end")
            .style("font-size", "15px")
            .text(function(key) {
                return t(key)
            });

         */
        const legendContainer = select('.StackedMultipleBarChart').select('.legend')
            .attr('width', width + margin.left + margin.right)
            .attr('height', 160)
            .attr('transform', `translate(0,10)`)

        const legend = legendContainer
            .selectAll(".legend")
            .data(innerKeys)

        legend.select("rect")
            .attr("x", -50)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(key) {return colorByInnerKey[key]})

        legend.select("text")
            .attr("x", -25)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function(key) {
                return t(key)
            });

        legend
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })

        enterSelection
            .append("rect")
            .attr("x", -50)
            .attr("y", 10)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(key) {return colorByInnerKey[key]})


        enterSelection
            .append("text")
            .attr("x", -25)
            .attr("y", 19)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function(key) {
                return t(key)
            });

        //style={{background: 'linear-gradient(to right, #0076CE, #E9DCC9)'}}
        return (
            <div className="StackedMultipleBarChart">
                <svg>
                    <g className="container">
                        <g className="bars" />
                        <g className="values" />
                        <g className="axis axis--x-sub"/>
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                    </g>
                </svg>
                <svg className="legend"/>
            </div>
        )
    }
}

export default StackedMultipleBarChart;