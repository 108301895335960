import React from 'react'
import shallowCompare from 'react-addons-shallow-compare';
import RichTextEditor from '../../../../components/Form/RichTextEditor'
import MediaViewer from '../../../../components/MediaViewer'
import './KpDashboard.css'
import ChartContainer from '../../../../components/Dashboard/ChartContainer'
import ShortCard from '../../../../components/Dashboard/ShortCard'
import Charts from '../../../../components/Dashboard'
import history from '../../../../history'

function truncateString(str, num) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

class KpDashboard extends React.Component {

    componentDidMount() {
        const { modelId, groupModelId, groupModel, groupId, dashboardElements, fetchDashboardData, fetchPlatformMediaElements } = this.props
        const urls = dashboardElements.map(o => o.url)
        fetchPlatformMediaElements(modelId, groupId, groupModel.profiles)
        dashboardElements && fetchDashboardData(urls, groupModelId, modelId)
    }

    componentDidUpdate(prevProps) {
        const { modelId, groupModelId, groupModel, groupId, dashboardElements, fetchDashboardData, fetchPlatformMediaElements } = this.props
        if(prevProps.groupModelId !== this.props.groupModelId) {
            const urls = dashboardElements.map(o => o.url)
            fetchPlatformMediaElements(modelId, groupId, groupModel.profiles)
            dashboardElements && fetchDashboardData(urls, groupModelId, modelId)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return shallowCompare(this, nextProps, nextState);
    }

    render() {
        const {
            modelId,
            groupModel,
            groupModelId,
            dashboardElements,
            mediaElements,
            elementsAreFetching,
            dashboardDataIsFetching,
            dashboardData,
            t,
            language,
            device
        } = this.props

        console.log('mediaElements', mediaElements)

        const getContainerClass = (width) => {
            switch (width) {
                case 1:
                    return "col-sm-12 col-md-12 col-lg-12"
                case 1/2:
                    return "col-sm-6 col-md-6 col-lg-6"
                default:
                    return "col-sm-6 col-md-4 col-lg-4"
            }
        }

        return ['S', 'I', 'U', 'R', 'C'].includes(modelId) && !elementsAreFetching ? (
            <div className="KpDashboard-container container-fluid">
                <div className="row">
                {
                    dashboardElements.map(element => {
                        return element.type === 'shortCard'
                            ? <ShortCard
                                key={element.id}
                                title={element.name}
                                language={language}
                                subTitle={dashboardData[element.id] && dashboardData[element.id].subTitle}
                                data={dashboardData[element.id] && dashboardData[element.id].data}
                                isFetching={dashboardDataIsFetching[element.id]}
                                suffix={element.suffix}
                                dates={dashboardData[element.id] && dashboardData[element.id].dates}
                                link={{title: element.link.title, path: `/business/${groupModelId}/${element.link.path}`}}
                                t={t}
                            />
                            : element.type === 'video'
                                ? <div className={`videoContainer ${getContainerClass(element.width)}`}>
                                    <video
                                        src={`/video/${dashboardData[element.id] && dashboardData[element.id].link}`}
                                        poster={`/image/${dashboardData[element.id] && dashboardData[element.id].poster}`}
                                        style={{outline: 'none', borderRadius: '4px'}}
                                        width='100%'
                                        controls
                                    />
                                </div>
                                : <ChartContainer
                                    key={element.id}
                                    defaultTitle={element.name}
                                    title={dashboardData[element.id] && dashboardData[element.id].title}
                                    Component={Charts[element.type]}
                                    width={element.width && element.width}
                                    className={element.id}
                                    subTitle={dashboardData[element.id] && dashboardData[element.id].subTitle}
                                    data={dashboardData[element.id] && dashboardData[element.id].data}
                                    dataKeys={dashboardData[element.id] && dashboardData[element.id].dataKeys}
                                    isFetching={dashboardDataIsFetching[element.id]}
                                    keys={element.keys && element.keys}
                                    colors={element.colors[0] && element.colors}
                                    sum={element.sum}
                                    total={dashboardData[element.id] && dashboardData[element.id].total}
                                    sumColor={element.sumColor}
                                    absolute={element.absolute}
                                    axisHeight={element.axisHeight}
                                    useColorsByCashier={element.useColorsByCashier}
                                    useColorsByStore={element.useColorsByStore}
                                    suffix={element.suffix && element.suffix}
                                    t={t}
                                    language={language}
                                />
                    } )
                }
                </div>
                <div className="row" style={{display: 'flex', flexDirection: 'row'}} >
                    {
                        mediaElements && !!mediaElements.length && (
                            <div>
                                {
                                    mediaElements.map((mediaElement, index) => {
                                        const widthParams = mediaElement.fullWidth ? "col-sm-12 col-md-12 col-lg-12" : "col-sm-12 col-md-6 col-lg-6"
                                        const articleTitleStyle = device !== "mobile" ? {whiteSpace: 'nowrap'} : {}
                                        const groupSelectedColor = groupModel.selectedColor ? {color: groupModel.selectedColor} : {}
                                        return (
                                            <div className={widthParams} style={{padding: '0 5px'}}>
                                                <div className="MediaContainer" style={{flex: 2, margin: '5px'}}>
                                                    <div className="Article-container">
                                                        <div className="Article-title"
                                                             onClick={() => {
                                                                 history.push(`/business/${groupModelId}/communication/m-${modelId}-mediaBaseUser/${mediaElement.id}`)
                                                             }}
                                                             style={{fontSize: `${mediaElement.fullWidth ? '25px': '20px'}`, ...articleTitleStyle, ...groupSelectedColor}}>{mediaElement.title}
                                                        </div>
                                                        {
                                                            mediaElement.media?.length > 0 && (
                                                                <div className="Article-image">
                                                                    <MediaViewer key={index} noThumbnail={true} path="mediaElements" data={{mediaElements: mediaElement.media}} mediaHeight={mediaElement.fullWidth ? 'auto' : '250px'} style={{objectFit: 'cover'}} containerStyle={{borderRadius: 0}} />
                                                                    {
                                                                        mediaElement.showTags && (
                                                                            <div>
                                                                                {mediaElement.tags.map(tag => <span style={groupSelectedColor} className="Article-tag">{tag.tName}</span>)}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            mediaElement.showDescription && (
                                                                <div className="Article-text">
                                                                    <RichTextEditor applyMinHeight={false} key={index} value={truncateString(mediaElement.description, mediaElement.fullWidth ? 200 : 120)} t={t} initialValue readOnly={true}/>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {index + ((device === "mobile" || (mediaElements.length % 2 !== 0)) ? 1 : 2) < mediaElements.length && <hr className="Article-separator"/> }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    {
                        device !== "mobile" && mediaElements && !!mediaElements.filter(elem => elem.includedInLatestNewsList).length && (
                            <div className="MediaContainer mediaLinksGroup" style={{flex: 1, margin: '5px', height: 'fit-content'}}>
                                <div className="mediaLinksGroup-title">Fil d'actualités</div>
                                {
                                    mediaElements.filter(elem => elem.includedInLatestNewsList).map(mediaElement => {
                                        return <div
                                            className="mediaLink"
                                            onClick={() => {
                                                history.push(`/business/${groupModelId}/communication/m-${modelId}-mediaBaseUser/${mediaElement.id}`)
                                            }}
                                        >
                                            {mediaElement.title}
                                        </div>
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        ) : (
            <div className="KpDashboard-container container-fluid" />
        )
    }
}

export default KpDashboard
