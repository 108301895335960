const _ = require("lodash")
const moment = require("moment")
import {basicContext} from "../../utils/contextUtils"
import {translateName} from "../../../utils/i18n"
import {importantInputCorrespondences as ic} from "./inputCorrespondence"


const yesterdayISO = moment().subtract(1, "days").format("YYYY-MM-DD")
const todayISO = moment().format("YYYY-MM-DD")

async function findElemData(context){

    const value = _.get(context, 'data.value')
    const typeId = _.get(context, 'data.type.code')
    const dates = _.get(context, 'data.period', [yesterdayISO, yesterdayISO]).map(
        (date, index) => index === 0
            ? moment.utc(date.trim(), "YYYY-MM-DD")
            : moment.utc(date.trim(), "YYYY-MM-DD").add(1, 'day')
    );

    if(!typeId || !value) return []

    const datesQuery = { $and: [
            {[ic.Date.internalIdentifier]: {$gte: dates[0].toDate()}},
            {[ic.Date.internalIdentifier]: {$lt: dates[1].toDate()}}
        ]};

    const inputCorrespondencesQuery = global.app.S.InputCorrespondence.find({
        ...basicContext(context),
        fieldPath: [
            'internalIdentifier',
            'analysisAxis',
            'analysisLabel',
            'inputCorrespondenceByImportType.code',
        ],
        query: {}
    })

    const elemDataQuery = global.app.S.ElemDataLine.collection.find({
        ...datesQuery,
        [typeId]: value,
        groupedLine: {$exists: false},
        group: global.ObjectID(context.group.id)
    }).toArray()

    const [
        inputCorrespondences,
        elemData
    ] = await Promise.all([
        inputCorrespondencesQuery,
        elemDataQuery
    ])

    const columnsByStream = _(inputCorrespondences)
        .filter(ic => !!ic.analysisAxis)
        .groupBy('inputCorrespondenceByImportType.code')
        .value()

    const columns = _(inputCorrespondences)
        .filter(ic => !!ic.analysisAxis)
        .orderBy('order', 'asc')
        .uniqBy('internalIdentifier')


    const columnsStreamMapping = {}

    return _.orderBy(elemData, ['date', 'ticket', 'origin', 'rowId'], ['desc', 'asc', 'asc', 'asc']).map(line => {
        return {
            id: line._id.toString(),
            results: columns.reduce((acc, column) => {
                const translatedLabel = translateName(column.analysisLabel, context.language)

                let showColumn

                if(!columnsStreamMapping.hasOwnProperty(line.origin)) {
                    columnsStreamMapping[line.origin] = {}
                }
                if(columnsStreamMapping[line.origin].hasOwnProperty(column.internalIdentifier)) {
                    showColumn = columnsStreamMapping[line.origin][column.internalIdentifier]
                } else {
                    showColumn = !!columnsByStream[line.origin].find(c => c.internalIdentifier === column.internalIdentifier)
                    columnsStreamMapping[line.origin][column.internalIdentifier]= showColumn
                }
                return {
                    ...acc,
                    [translatedLabel]: showColumn
                        ? column.internalIdentifier === 'date'
                            ? moment(line[column.internalIdentifier]).format('YYYY-MM-DD')
                            : line[column.internalIdentifier]
                        : '---'
                }
            }, {})
        }
    })
}

export const entities = [
    /*
    {
        name: "AnalysisDataType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {id: "ticket", tKey: "Ticket"},
            {id: "cashier", tKey: "Caissier"},
            {id: "ticketCustomer", tKey: "Client"},
            {id: "order", tKey: "Commande"},
            {id: 'productId', tKey: 'Objet'},
            {id: "store", tKey: "Magasin"}
        ]
    },
     */
    {
        name: "ElemDataAnalysis",
        type: null,
        fields: [
            "results"
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error);
                else findElemData(context)
                    .then(objects => global.ioSocket.emit(
                        `fetchDT-success-${context.user.id}${context.clientContext.accessId}`,
                        objects
                    ))
                    .catch(e => global.ioSocket.emit(
                        `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`,
                        {error: e.message}
                    ))
                callback()
            });
        }
    }
]

export const module_ = {
    object: "ElemDataAnalysis",
    tKey: "mTitle_elemDataAnalysis",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    newable: false,
    editable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    manualFilters: true,
    viewMap: {
        dt: [
            {path: "results", dynamic: true, disableColumnsSort : true, tooltip: true},
        ],
        form: []
    },
    filters: [
        {
            path: "period",
            type: 'dateRange',
            client: true,
            clearable: false,
            default: [yesterdayISO, todayISO]
        },
        {
            path: "type",
            object: "GroupAxis",
            display: "tName",
            fieldPath: ["code", "name", "tName"],
            client: true,
            clearable: false,
            sortList: false,
        },
        {
            path: "value",
            tKey: "typeAValue",
            client: true,
            type: "text"
        }
    ]
}
