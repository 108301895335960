export const dependencies = [
    {
        name: 'StateModelLine',
        fields: ['order', 'AccountModel']
    }
]

export const entity = {
    name: "StateModel",
    facets: [
        {name:"codeName"}
    ],
    fields: [
        'StateModelType',
        {
            path: 'stateModelLines',
            type: 'StateModelLine',
            link: {
                type: 'OTM',
                external: false
            }
        }
    ]
}

export const module_ = {
    object: 'StateModel',
    tKey: 'mTitle_stateModel',
    removable: true,
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    category: {
        path: 'keenpoint',
        icon: 'server'
    },
    viewMap: {
        dt: ['name'],
        form: [
            {path: "code", required: true},
            {path: "name", required: true},
            {path: 'stateModelType', clearable: false},
            {
                path: 'stateModelLines',
                tKey: 'State Model Lines',
                removable: true,
                viewMap: {
                    dt: ['order', 'accountModel'],
                    form: ['order', 'accountModel']
                }
            }
        ]
    }
}
