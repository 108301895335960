import { Component } from 'react'
import PropTypes from 'prop-types'

class FormWrapper extends Component {

    handleBeforeUnload = event => {
        const { isFormDirty, message } = this.props
        if (isFormDirty) {
            event.preventDefault()
            event.returnValue = message
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload)
    }

    render() {
        return this.props.children
    }
}

FormWrapper.propTypes = {
    message: PropTypes.string.isRequired,
    isFormDirty: PropTypes.bool.isRequired
}

export default FormWrapper
