import {
    changeFieldProperty,
    FETCH_FORM_DATA_LISTS_DONE,
    processDynamicColumns,
    setFieldVisibility
} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import {generateFetchFieldListAction, GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import _ from "lodash";
import {addFloats} from "../../utils/usefulFunctions";
import {handleParentColumnsForTable} from "../../utils/trackingUtils";
import {translateName} from "../../../../../utils/i18n";

export const module_ = {
    object: 'Demand',
    name: 'Consultation',
    category: {
        path: 'Projet',
    },
    updatable: false,
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    newable: false,
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'step', translate: true},
            {path: 'demandNumber', tKey: 'N° Projet'},
            {path: 'relatedProject', tKey: 'projet principal', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'largeImputationsTotalAmount', width: 230},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                },
                {path: 'demandNumber',tKey: 'N° Projet', disabled: true},
                {path: 'relatedProject', tKey: 'projet principal', display: 'demandNumberAndTitle', disabled: true},
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    disabled: true,
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true,
                    hidden: true
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    fieldPath: ['id', 'code', 'name'],
                    disabled: true,
                    translateName: true
                },
                {path: 'title', required: true, disabled: true},
                {path: 'description', type: 'richTextEditor', required: true, disabled: true},
                {path: 'enjeux', type: 'richTextEditor', required: true, disabled: true},
                {path: 'objectifs', type: 'richTextEditor', required: true, disabled: true},
                {path: 'pointsOfAttention', tKey: `points d'attention`, disabled: true},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    //endDate: ''
                    disabled: true,
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    disabled: true
                },
                {
                    path: 'largeImputations',
                    disabled: true,
                    type: 'dtObjects',
                    fields: [
                        {path: "organization", tKey: 'direction', display: 'name', translateName: true},
                        {path: "amount", tKey: 'Montant (k)', type: "editText"}
                    ]
                },
                {
                    path: 'largeImputationsTotalAmount',
                    disabled: true
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    newable: false,
                    disabled: true,
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant mensuel (k)'},
                        ],
                        form: {
                            fields : [
                                {path: 'objet', required: true, unique: true, disabled: true},
                                {path: 'organizationalMesh', tKey: 'business unit', filters:['byOrganizationOrMesh'], disabled: true},
                                {path: 'imputationType', required: true, disabled: true, translateName: true},
                                {path: 'currency', display: 'symbol', disabled: true},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    disabled: true,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ]
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea', disabled: true},
                            ]
                        }
                    }
                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true
                },
                {
                    path: 'revisedTable',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ]
                },
                {
                    path: 'revisedTableHistory',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.',width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ],
                },
                {
                    path: 'plafond',
                    tKey: "estimatedFinancialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: true,
                    display: 'code',
                    disabled: true
                    //filters: ['byAmount']
                },
                {
                    path: 'indicatorKeys',
                    type: 'accordion',
                    newable: false,
                    disabled: true,
                    viewMap: {
                        dt: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            'objectif'
                        ],
                        form: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur', disabled: true},
                            {path: 'objectif', required: true, disabled: true}
                        ]
                    }
                },
                {path: 'comments', disabled: true},
                {path: 'files', disabled: true},
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },

                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {path: 'displayEngagementData', hidden: true},
            ],
            onOpen: ({ module, state, store, t }) => {

                const organizationAndMesh= _.get(state, 'edit.object.data.organizationAndMesh')

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-consultation.Imputation_organizationalMesh",
                        store.getState,
                        'form',
                        {
                            data: {
                                organization : organizationAndMesh?.id
                            }
                        }
                    )
                )

                const largeImputationsField = module.viewMap.form.fields.find( field => field.path === 'largeImputations')
                const largeImputationsTotalAmountField = module.viewMap.form.fields.find( field => field.path === 'largeImputationsTotalAmount')

                const atLeastOneLargeImputations = !!largeImputationsField && !!largeImputationsField.getValue() && !!largeImputationsField.getValue().length

                store.dispatch(setFieldVisibility(largeImputationsField.id, atLeastOneLargeImputations))
                store.dispatch(setFieldVisibility(largeImputationsTotalAmountField.id, atLeastOneLargeImputations))

                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()

                store.dispatch(setFieldVisibility(revisedTableHistoryField.id, !!revisedTableHistory && !!revisedTableHistory.length))
                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTable = !!revisedTableField && revisedTableField.getValue()
                store.dispatch(setFieldVisibility(revisedTableField.id, !!revisedTable && !!revisedTable.length))

                const displayEngagementDataField =  module.viewMap.form.fields.find(field => field.path === 'displayEngagementData')
                const displayEngagementData =  !!displayEngagementDataField && displayEngagementDataField.getValue()
                const validatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')
                const validatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')

                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementHistoryColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementHistoryColumn.id, displayEngagementData))


                const referenceCurrencies = getDataListList(state, "Consultation-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_largeImputationsTotalAmount", 'tKey', `${t("largeImputationsTotalAmount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_largeImputations.l_amount", 'tKey', `${t("amount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_totalAmount", 'tKey', `${t("plannedTotalAmount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_revisedTable", 'tKey', `${t("revisedTable")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_revisedTableHistory", 'tKey', `${t("revisedTableHistory")} (k${referenceCurrency.symbol})`))
                }

            },
            dataLists: [
                "Consultation-RealizationType",
                "Consultation-FiscalYear",
                "Consultation-Currency",
            ],
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "Consultation-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `engagement (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("l_largeImputationsTotalAmount", 'tKey', `engagement estimé (k${referenceCurrency.symbol})`))
                }
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()

                const realizationTypes = getDataListList(state, "Consultation-RealizationType")
                const sortedTypes = _.sortBy(realizationTypes, ['order'])
                const language = _.get(state, 'ui.language')

                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTable = !!revisedTableField && revisedTableField.getValue()
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()

                const filteredRevisedTable = _.cloneDeep(revisedTable).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const filteredRevisedTableHistory = _.cloneDeep(revisedTableHistory).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const result = []
                const result2 = []
                if (!!filteredRevisedTable && !!filteredRevisedTable.length){

                    filteredRevisedTable.forEach(line => {
                        result.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })

                    if (filteredRevisedTable.length > 1){

                        const dynamicAmounts = result.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})
                        const validatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            validated,
                            revised,
                            budget,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }
                    result.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.budget = _.round(line.budget)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableField.setValue(result)
                }
                if (!!filteredRevisedTableHistory && !!filteredRevisedTableHistory.length){
                    filteredRevisedTableHistory.forEach(line => {
                        result2.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })
                    if (filteredRevisedTableHistory.length > 1){
                        const dynamicAmounts = result2.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})

                        const validatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result2.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            budget,
                            estimated,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }
                    result2.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableHistoryField.setValue(result2)
                }
                store.dispatch(processDynamicColumns({revisedTable: result, revisedTableHistory: result2}))
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const fiscalYearsList = getDataListList(state, 'Consultation-FiscalYear')
                const ongoingFiscalYear = fiscalYearsList.find( fiscalYear => moment().isBetween(fiscalYear.fiscalYearRange[0], fiscalYear.fiscalYearRange[1], 'day', '[]'))
                const tableIds = ["e_revisedTable", "e_revisedTableHistory"]
                tableIds.forEach(tableId => handleParentColumnsForTable(store, tableId, ongoingFiscalYear))
            }
        }
    ],
    accesses: [
        {
            id: "Consultation-RealizationType",
            entity: "RealizationType",
            fieldPath: ['id', 'code', 'name', 'order'],
            filters: ['onlyActifOnes']
        },
        {
            id: "Consultation-FiscalYear",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: []
        },
        {
            id: "Consultation-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        },
    ],
    filters: ["myConsultations", "byFiscalYear"]
}
