import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import _ from "lodash";

const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')

export const realisationOld = {
    name: "bp5",
    object: "BusinessProject",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "name", width: 800}
        ],
        form: [
            {path: "country", writable: false},

            {path: "buttons", hidden: true},
            {path: "workflow", hidden: true},

            {path: "businessProject", hidden: true}
        ]
    },
    filters: [
        "byTypeOfOffer",
        {query: () => ({"workflow.step": "realisation"})}
    ]
}

export const realisation = {
    name: "bp5 bis",
    object: "BusinessProject",
    category: {
        path: "projects",
        icon: 'briefcase'
    },
    step: "realisation",
    newable: false,
    removable: false,
    workflowActions: [
        'validate',
        'question'
    ],
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", display: "codeNameRelation"},
            {path: "typeOfOffer", initiallyNotVisible: true},
            {path: "fullName", tKey: 'name', width: 800},
            {path: "description", type: 'richText', width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},
            "specialFollowUp",
            {path: "beginYear", initiallyNotVisible: true},

            {path: "expectedTurnOver", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualTurnOver", width: 80, tooltip: true},
            {path: "expectedContractCost", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumCommittedExpenses", width: 80, tooltip: true},
            {path: "sumActualContractExpenses", width: 80, tooltip: true},
            {path: "expectedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},

            {path: "sumCommittedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualBilled", width: 80, tooltip: true},
            {path: "sumActualCashed", width: 80, tooltip: true},

            {path: "green", width: 80, tooltip: true},
            {path: "red", width: 80, tooltip: true},
            {path: "blue", width: 80, tooltip: true},

            {path: "commercialTeamMember", display: 'fullName', initiallyNotVisible: true},
            {path: "technicalTeamMember", display: 'fullName', initiallyNotVisible: true},
            {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", initiallyNotVisible: true},
            {path: "projectManager", display: 'fullName'},
            {path: "revueDate"},
            {path: 'risk', display: 'name'},

            {path: "completedService", tKey: "prestation"},
            {path: "allPaymentsReceived", tKey: "payments"},
            {path: "closingDocumentsReceived", tKey: "documents"},

            {path: "currentFunctions", width: 100, initiallyNotVisible: true},
            {path: "file", tKey: "FA"},
            {path: "crrFile", tKey: "CRR"},

            {path: 'orangeStyledRow', hidden : true},
            {path: 'redStyledRow', hidden : true}
        ],
        form: {
            fields: [
                "identifier",
                {path: "country", writable: false, hidden: true},
                // {path: "customer", display: "codeNameRelation", writable: false},
                {path: "typeOfOffer", writable: false, required: false},
                {path: "name", writable: false, required: false},
                {path: "description", type: "richTextEditor", writable: false},

                {path: "expected", type: "readOnly", tKey: "Prévu (CA/Coût/Nbj)"},
                {
                    path: 'executedFollowUps', removable: false, viewMap: {
                        dt: [
                            {path: "year"},
                            {path: "name", tKey: "block / Lot / Livrable"},
                            {path: "executedTurnOver", tKey: "CA réalisé (k€)", tooltip: true},
                            {path: "notedExpenses", tKey: "Coût constaté (k€)", tooltip: true},
                            {path: "notedNumberOfDays", tKey: "Nbj constaté", tooltip: true},
                        ],
                        form: [
                            {path: "year", editable: false},
                            {path: "name", tKey: "block / Lot / Livrable", required: true},
                            {path: "executedTurnOver", tKey: "CA réalisé (k€)", wholePositiveNumber: true},
                            {path: "notedExpenses", tKey: "Coût constaté (k€)", wholePositiveNumber: true},
                            {path: "notedNumberOfDays", tKey: "Nbj constaté", wholePositiveNumber: true},
                        ]
                    }
                },

                {path: "signatureDate", required: true},
                "beginningOfRealisationDate",

                {path: "specialFollowUp", required: true},

                {path: 'projectFunding', required: false, writable: false},

                // Suivi engagé
                {
                    path: "projectComponents",
                    newable: false,
                    removable: false,
                    viewMap: {
                        dt: [
                            {path: "name", tKey: "lot / Livrable"},
                            {path: "expectedNumberOfDays", tKey: "nbj prévu", tooltip: true},
                            {path: "numberOfDays", tKey: "nbj engagé", tooltip: true},
                            {path: "expectedCost", tKey: "coût prévu (k€)", tooltip: true},
                            {path: "cost", tKey: "coût engagé (k€)", tooltip: true},
                            {path: 'expectedTurnover', tKey: "CA prévu (k€)", tooltip: true},
                            {path: "status"},
                            {path: "validationDate", tKey: "validation"}
                        ],
                        form: [
                            {path: "name", tKey: "lot / Livrable", editable: false},
                            {path: "numberOfDays", tKey: "nbj engagé", wholePositiveNumber: true},
                            {path: "cost", tKey: "coût engagé (k€)", wholePositiveNumber: true},
                            {path: "status"},
                            {path: "effectiveSubmissionDate", tKey: "remise"},
                            {path: "validationDate", tKey: "validation"}
                        ]
                    }
                },

                // Suivi facturation
                {
                    path: "billingFollowUps", viewMap: {
                        dt: [
                            {path: "date", tKey: "billDate"},
                            {path: "object", tKey: "object"},
                            {path: "amount", tKey: "amountKE"},
                            "invoicedeadline",
                            "paymentdate"
                        ],
                        form: [
                            {path: "date", tKey: "billDate", required: true},
                            {path: "object", tKey: "object", required: true},
                            {path: "amount", tKey: "amountKE", required: true},
                            "invoicedeadline",
                            "paymentdate"
                        ]
                    }
                },

                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], writable: false},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"], writable: false},
                {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", filters: ["projectMonitor"]},

                {path: "projectManager", writable: false},
                {path: "projectTeam", type: "textarea", writable: false},
                {path: "operationsAndServicesContracts", type: 'creatableTags', disabled: true},

                {path: "revueDate", required: true},
                {path: 'risk', display: 'name'},

                {path: "completedService"},
                {path: "allPaymentsReceived"},
                {path: "closingDocumentsReceived"},

                {path: "comment", type: "richTextEditor", tKey: "synthesis"},
                {path: "actuality", type: "richTextEditor"},
                {path: "deadlinesAndSchedule", type: "richTextEditor"},
                {path: "costs", type: "richTextEditor"},
                {path: "treasury", type: "richTextEditor"},

                {path: "recipients", display: "fullName"},
                {
                    path: "otherRecipients",
                    type: "textarea",
                    tKey: "autres destinataires",
                    placeholder: "eric.durand@enedis.fr, robert.bodet@enedis.fr,..."
                },

                "comments",
                "files",

                {path:"delegatedUsers", filters:["byCountry"], fieldPath: ["id", "userName", "firstName", "lastName", "email"], display: "userName"},
                {path: "sendReport", tKey: "Envoyer compte rendu"},

                {path: "delegatedUsersGetter", hidden: true},
                {path: "buttons", hidden: true},
                {path: "status.step", hidden: true},
                {path: "status.order", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-bp5 bis.BusinessProject_delegatedUsers",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const delegatedUsers = _.get(state, 'edit.object.data.delegatedUsersGetter')
                const delegatedUsersField = module.viewMap.form.fields.find(field => field.id === "e_delegatedUsers")
                delegatedUsersField.setValue(delegatedUsers)
            }
        }
    ],
    filters: [
        "byTypeOfOffer",
        {query: () => ({"workflow.step": "realisation"})}
    ]
}
