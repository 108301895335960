import { createBrowserHistory as createHistory } from 'history'
import {toastr} from "react-redux-toastr";

// this export is useful to push paths to history outside of react-router library, for example on auth middleware
export default createHistory({
    getUserConfirmation(message, callback) {
        // Show some custom dialog to the user and call
        // callback(true) to continue the transiton, or
        // callback(false) to abort it.
        toastr.confirm(message, {
            onOk: () => callback(true)
        })
        callback(false)
    }
})
