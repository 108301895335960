import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ValidationUI from '../ValidationUI'
import './Select.css'

const getWidthClass = width => {
    if(width) return `col-xs-12 col-sm-12 col-md-${width} col-lg-${width}`
    else return 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
}
class ReactSelect extends Component {
    render() {
        const {t, path, touched, error, width, timer, timeLeft, timerIsOn, required, removeLabel } = this.props
        const timerStyle = timerIsOn
            ? {
                marginTop: '2px',
                width: `calc(${(timeLeft - 1) * 100 / (timer)}% - 15px)`,
                transition: 'width 1s linear',
                height: '2px',
                backgroundColor: 'rgb(233 78 36)'
            }
            : {
                width: 'calc(100% - 15px)',
                height: '0px',
                backgroundColor: 'rgb(233 78 36)'
            }

        return (
            <div className={`Select-Container ${getWidthClass(width)}`}>
                {
                    !removeLabel && (
                        <label className='Select-label'>
                            {t(path)} {required && "*"}
                        </label>
                    )
                }
                <Select{...this.props} onBlur={event => event.preventDefault()}/>
                { touched && (error && <ValidationUI error={error}/>) }
                { timer && <div className='Select-Container-timer' style={timerStyle}/> }
            </div>
        )
    }
}

Select.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default ReactSelect
