import _ from 'lodash'
import {data} from '../utils/stateComputationUtils'
import {simpleFacQuery} from './queries'

export const getData = async (companyCode, lastMonthFromLastFile, lastMonthFromLastFilePreviousYear, previousExerciseLastMonth) => {

    const mesh = ['groupSurFamilyCode', 'yearMonth']

    const currentYearQuery = {companyCode: Array.isArray(companyCode) ? {$in: companyCode} : companyCode, yearMonth: lastMonthFromLastFile, fileYearMonth: lastMonthFromLastFile}
    const previousYearQuery = {companyCode: Array.isArray(companyCode) ? {$in: companyCode} : companyCode, yearMonth: lastMonthFromLastFilePreviousYear, fileYearMonth: previousExerciseLastMonth}

    const currentYearTurnoverFAC = await data(mesh, [], ["value"], {...currentYearQuery, ...simpleFacQuery});

    const previousYearTurnoverFAC = await data(mesh, [], ["value"], {...previousYearQuery, ...simpleFacQuery});

    return _.sortBy(
        _.concat(currentYearTurnoverFAC, previousYearTurnoverFAC).map(object => {
            return {
                axis: object.groupSurFamilyCode,
                [object.yearMonth]:  object.value ? (object.value/1000) : 0
            }
        }),
        'axis'
    )
}
