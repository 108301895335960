import { combineReducers } from 'redux'
import user from './user'
import network from './network'
import ui from './ui'
import dashboard from './dashboard'
import { reducer as toastr } from 'react-redux-toastr'
import { VIEWER_SUCCESS, PLATFORM_DOCUMENTS_SUCCESS, PLATFORM_ALERTS_SUCCESS, PLATFORM_MEDIA_SUCCESS } from '../actions'

const entities = (state={ users: {}, groupModels: {}, groups: {}, models:{}, modules:{}, alerts: [], documents: [] }, action) => {
    if (action.type === VIEWER_SUCCESS) {
        return {
            ...action.data.entities,
            documents: [],
            alerts: []
        }
    }
    if (action.type === PLATFORM_DOCUMENTS_SUCCESS) {
        return {
            ...state,
            documents: action.data.documents
        }
    }
    if (action.type === PLATFORM_ALERTS_SUCCESS) {
        return {
            ...state,
            alerts: action.data.alerts
        }
    }
    if (action.type === PLATFORM_MEDIA_SUCCESS) {
        return {
            ...state,
            mediaElements: action.data.mediaElements
        }
    }
    return state
}

/* istanbul ignore next */
export default combineReducers({
    ui,
    user,
    entities,
    network,
    dashboard,
    toastr
})
