import moment from 'moment'
import _ from 'lodash'
import { getStoreHabilitationQuery } from '../../utils/habilitations'

async function findData(context) {

    const habQuery = getStoreHabilitationQuery(context)
    const [habilitations] = await Promise.all([habQuery])

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    if (habilitations.length) {
        const datesQuery = {
            'date.gte': { $gte: dates[0].toDate() },
            'date.lt': { $lte: dates[1].toDate() }
        }

        const habilitation = habilitations[0]
        const store = habilitation.store
        const organization = habilitation.organization

        const alertConfigurationCollection =
            global.app.S.AlertConfiguration.collection
        const alertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'VenteDepartementMagasin7jours'
            })
            .toArray()

        const [alertConfigurations] = await Promise.all([
            alertConfigurationQuery
        ])
        const alertConfiguration = alertConfigurations[0]

        const alertConfQuery = {
            alertConfiguration: alertConfiguration._id
        }

        const alertCollection = global.app.S.Alert.collection
        const storeAlertQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...datesQuery,
                        ...alertConfQuery,
                        store: store._id,
                        'theme-Vente': {$exists: true}
                    }
                },
                { $sort : { 'theme-Vente.Mt' : -1} }
            ])
            .toArray()

        const regionAlertQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...datesQuery,
                        ...alertConfQuery,
                        organizations: organization._id,
                        'theme-Vente': {$exists: true}
                    }
                },
                {
                    $group: {
                        _id: '$DEPARTEMENT',
                        avgVentes: {$avg: '$theme-Vente.Mt'},
                    }
                }
            ])
            .toArray()

        const [storeAlerts, regionAlerts] = await Promise.all([
            storeAlertQuery,
            regionAlertQuery
        ])

        const top5StoreSales = _.slice(storeAlerts, 0, 5)


        const data = top5StoreSales.map( o => ({
            indicateur: o['DEPARTEMENT'],
            'Mag.': _.round(o['theme-Vente'].Mt/1000, 1),
            'Moy. Reg.': _.round(regionAlerts.find(alert => alert._id === o['DEPARTEMENT']).avgVentes/1000, 1)
        }))

        return [
            {
                id: 'topStoreSales',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: data.reverse()
            }
        ]

    } else {
        return [
            {
                id: 'topStoreSales',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: []
            }
        ]
    }
}

export const entity = {
    name: 'TopStoreSales',
    dashboard: true,
    fields: [
        "id",
        "subTitle",
        "data",
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        });

    }
}
