const Errors = require('../../utils/Errors').default
const { FILTER_FORM } = require('../../../apps/KpModule/components/Filter/FilterForm')
const { actionTypes: reduxFormActions } = require('redux-form')
const { setEditDefaultObject } = require('../../../apps/KpModule/actions')
const { getEditDefaultData } = require('../../../apps/KpModule/selectors')
const _ = require('lodash');
const { generateFetchFieldListAction } = require('../../../apps/KpModule/actions/api')
const { setFieldListOptions } = require('../../../apps/KpModule/actions/index')
const {
    expOnContractWithProdValue,
    expOnContractWithoutProdValue,
    travellingExpensesValue,
    turnoverValue,
    otherOperatingCostsValue
} = require("./statesGeneration/formxUtils");
const { forceArray } = require("../../utils/functional") ;

const defaultVolumeUnits = nYears => {
    const yearValues = _.range(1, nYears).reduce(
        (acc, i) => Object.assign({[`N+${i}`]: 0}, acc),
        {N: 0}
    );

    return require("./staticEntities").default.find(
        o => o.name === "ProjectSalesCostVolumeUnit"
    ).objects.map(
        projectSalesCostVolumeUnit => Object.assign({projectSalesCostVolumeUnit}, yearValues)
    )
};

const byBusinessProject = {
    name: "byBusinessProject",
    path: "businessProject",
    object: "BusinessProject",
    display: "countryAndFullName",
    width: 12,
    filters:["developTheBusinessPlan"],
    client: true,
    query: function(context) {
        const businessProjectId = _.get(context.data, "businessProject.id");

        if(businessProjectId) return {businessProject: new global.ObjectID(businessProjectId)};
    }
}

const projectSalesCostVolumeGetters = elements => {
    return elements.map(object => ({
        path: object.key,
        fieldPath: [
            "projectSalesCostVolumes.id",
            "projectSalesCostVolumes.projectSalesCostVolumeUnit.id"
        ],
        f: function () {
            const pscv = this.projectSalesCostVolumes.find(o => o.projectSalesCostVolumeUnit.id === object.id)
            return pscv ? (pscv.N + pscv["N+1"] + pscv["N+2"] + pscv["N+3"] + pscv["N+4"]) : 0;
        }
    }));
}

const otherOperatingCostVolumeGetters = elements => {
    return elements.map(object => ({
        path: object.key,
        fieldPath: [
            "otherOperatingCostVolumes.id",
            "otherOperatingCostVolumes.otherOperatingCostVolumeUnit.id"
        ],
        f: function () {
            const pscv = this.otherOperatingCostVolumes.find(o => o.otherOperatingCostVolumeUnit.id === object.id)
            return pscv ? (pscv.N + pscv["N+1"] + pscv["N+2"] + pscv["N+3"] + pscv["N+4"]) : 0;
        }
    }));
}

const getFieldsModuleForm = (fieldPaths, module) => {
    return fieldPaths.reduce((acc, fieldPath) => {
        return {
            ...acc,
            [fieldPath]: module.viewMap.form.fields.find(
                field => field.path === fieldPath
            )
        }
    }, {})
}

const defaultBusinessProjectSubscriptions = [
    {
        actionType: reduxFormActions.INITIALIZE,
        subscription: ({ store, action }) => {
            if (action.meta.form === FILTER_FORM) {
                const state = store.getState()
                const businessProjectId = _.get(action, 'payload.businessProject.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    businessProject: businessProjectId ? { id: businessProjectId } : null
                }))
            }
        }
    },
    {
        actionType: reduxFormActions.CHANGE,
        subscription: ({ store, action }) => {
            if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'businessProject')) {
                const state = store.getState()
                const businessProjectId = _.get(action, 'payload.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    businessProject: businessProjectId ? { id: businessProjectId } : null
                }))
            }
        }
    }
]

export const entities = [
    {
        name: "ProjectSalesCost",
        fields: [
            "BusinessProject",
            "ProjectComponent",
            "description",
            "ProjectSalesCostUnitOfWork",
            {type: "Provider", nullable: true},
            {type: "TeamMemberProfile", nullable: true},
            {path: "unitaryPrice", type: "decimal", default: 0, ps: {object: ["notNegative"]}},
            {path: "unitaryCost", type: "decimal", default: 0, ps: {object: ["notNegative"]}},
            {path: "marge", type: "decimal", default: 0, ps: {object: ["notNegative"]}},
            {type: "ProjectSalesCostVolume", link: "OTM"},
            // {type: "ProjectSalesCostVolumeR", link: "OTM"},
            ...projectSalesCostVolumeGetters(
                require("./staticEntities").default.find(
                    o => o.name === "ProjectSalesCostVolumeUnit"
                ).objects
            ),
            {path: "pscCa", f: function() { return turnoverValue(this) }},
            {
                path: "pscCharge",
                fieldPath: ["businessProject.perDiem"],
                f: function() { return travellingExpensesValue(this, this.businessProject) }
            },
            {path: "pscCost", f: function() { return expOnContractWithoutProdValue(this) }},
            {path: "pscCostBis", f: function() { return expOnContractWithProdValue(this) }}
        ],
        ps: {
            object: [
                // {
                //     $u: function(projectSalesCosts) {
                //         if(this.options.$v) {
                //             return global.kpf.forceArray(projectSalesCosts).map(
                //                 projectSalesCost => projectSalesCost.projectSalesCostVolumeRs ? projectSalesCost : Object.assign({}, projectSalesCost, {
                //                     projectSalesCostVolumeRs: defaultVolumeUnits(7)
                //                 })
                //             );
                //         }
                //         return projectSalesCosts;
                //     }
                // },
                {
                    $v: function (projectSalesCost) {
                        forceArray(projectSalesCost).map(psc => {
                            if (psc.projectSalesCostUnitOfWork.id === "hr") {
                                if (!psc.TeamMemberProfile) {
                                    return new Errors.ValidationError('profileIsMandatoryWhenChosenUnitIsHR');
                                } else return null;
                            } else {
                                if (psc.TeamMemberProfile) {
                                    return new Errors.ValidationError('profileShouldBeEmptyWhenChosenUnitIsNotHR');
                                } else return null;
                            }
                        });
                    }
                }]
        },
        filters: [byBusinessProject]
    },
    {
        name: "OtherOperatingCost",
        fields: [
            "BusinessProject",
            "component",
            "OtherOperatingCostUnitOfWork",
            {path: "unitaryCost", type: "decimal", default: 0},
            {type: "OtherOperatingCostVolume", link: "OTM"},
            ...otherOperatingCostVolumeGetters(
                require("./staticEntities").default.find(
                    o => o.name === "OtherOperatingCostVolumeUnit"
                ).objects
            ),
            {path: "oocTotal", f: function() { return otherOperatingCostsValue(this) }}

        ],
        filters: [byBusinessProject]
    },
    {
        name: "OtherFiscalCost",
        fields: [
            "BusinessProject",
            "component",
            "OtherFiscalCostBase",
            {path: "rate", type: "decimal", default: 0}
        ],
        filters: [byBusinessProject]
    },
    {
        name: "ProjectSalesCostVolume",
        type: "mongoInternal",
        fields: [
            "ProjectSalesCostVolumeUnit",
            {path: "N", type: "integer"},
            {path: "N+1", type: "integer"},
            {path: "N+2", type: "integer"},
            {path: "N+3", type: "integer"},
            {path: "N+4", type: "integer"}
        ]
    },
    {
        name: "ProjectSalesCostVolumeR",
        type: "mongoInternal",
        fields: [
            "ProjectSalesCostVolumeUnit",
            {path: "N", type: "integer"},
            {path: "N+1", type: "integer"},
            {path: "N+2", type: "integer"},
            {path: "N+3", type: "integer"},
            {path: "N+4", type: "integer"},
            {path: "N+5", type: "integer"},
            {path: "N+6", type: "integer"}
        ]
    },
    {
        name: "OtherOperatingCostVolume",
        type: "mongoInternal",
        fields: [
            "OtherOperatingCostVolumeUnit",
            {path: "N", type: "integer"},
            {path: "N+1", type: "integer"},
            {path: "N+2", type: "integer"},
            {path: "N+3", type: "integer"},
            {path: "N+4", type: "integer"}
        ]
    }
]

export const structure = {
    name: "bp3",
    object: "BusinessProject",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", tKey: "beneficiaryClient", display: "codeNameRelation", width: 300},
            {path: "name", width: 700},
            {path: "beginYear", tKey: "begin", width: 100},
            {path: "perDiem", tKey: "perDiem", width: 80, initiallyNotVisible: true},
            {path: "travelCost", tKey: "travelCost", width: 80, initiallyNotVisible: true},
            {path: "projectComponentsLength", width: 80, initiallyNotVisible: true},
            {path: "currentFunctions", width:  100},
            {path: "excel", tKey: "BP", type: 'fileLink'},
            {path: "file", tKey: "FA"}
        ],
        form: {
            fields: [
                "identifier",
                {path: "name", writable: false},
                {path: "beginYear", tKey: "begin"},
                {path: "perDiem"},
                {path: "travelCost"},
                {path: "projectComponents", tKey: "component_plural", viewMap: {
                        dt: ["name"],
                        form: [
                            {path: "name", tKey: "name *"},
                            {path: "description", type: "textarea"}
                        ]
                    }},

                {path: "comments", tKey: "comment_plural"},
                {path: "files", tKey: "file_plural"},

                {path: "workflow", hidden: true},

                //TODO
                // investiger pourquoi ça ne marche pas sans
                // dans le cas ou il existe un object dans la base
                {path: "businessProject", hidden: true}
            ]
        }
    },
    filters: [{
        query: () => ({"workflow.step": {$in: ["formX", "buildOffer", "submitOffer", "realisation"]}})
    }]
}

export const hypVentes = {
    name: "projectSalesCost",
    object: "ProjectSalesCost",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    viewMap: {
        dt: [
            {path: "projectComponent", tKey: "component", width: 500},
            {path: "description", tKey: "subComponent", width: 400},
            {path: "projectSalesCostUnitOfWork", tKey: "unitOfWork", width: 150},
            {path: "provider", width: 150, initiallyNotVisible: true},
            {path: "teamMemberProfile", display: "nameAndProvider", tKey: "profile", width: 150, initiallyNotVisible: true},
            {path: "unitaryCost", tKey: "cost_short", width: 80},
            {path: "unitaryPrice", tKey: "price_short", width: 80},
            {path: "marge", tKey: "marge_percent", width: 80, initiallyNotVisible: true},
            {path: "franceNb", tooltip: true, width: 80},
            {path: "foreignNb", tooltip: true, width: 80},
            {path: "perdiemNb", tooltip: true, width: 80},
            {path: "transportNb", tooltip: true, width: 80},
            {path: "productivityNb", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "quantityNb", tooltip: true, width: 80},
            {path: "pscCa", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "pscCharge", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "pscCost", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "pscCostBis", tooltip: true, width: 80, initiallyNotVisible: true}
        ],
        form: [
            {
                path: "businessProject",
                display: "name",
                filters:["developTheBusinessPlan"],
                writable: false,
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (newValue && newValue.id) {
                            const data = {businessProject: {id: newValue.id}}
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-I-projectSalesCost.ProjectSalesCost_projectComponent",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            )
                        } else {
                            store.dispatch(setFieldListOptions("e_projectComponent", []))
                        }
                    }
                }

            },
            {path: "projectComponent", tKey: "component *", autoList: false},
            {path: "description", tKey: "subComponent"},
            {path: "projectSalesCostUnitOfWork", tKey: "unitOfWork *"},
            {
                path:"provider",
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (newValue && newValue.id) {
                            const data = {provider: {id: newValue.id}}
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-I-projectSalesCost.ProjectSalesCost_teamMemberProfile",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            )
                        } else {
                            store.dispatch(setFieldListOptions("e_teamMemberProfile", []))
                        }
                    }
                }
            },
            {path: "teamMemberProfile", tKey: "profile", display: "nameAndPriceAndCost"},

            {
                path: "unitaryCost", tKey: "cost *",
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (! formInitialize && _.toNumber(newValue)) {
                            const fields = getFieldsModuleForm(
                                ["unitaryPrice", "marge"],
                                module
                            )
                            if(newValue === 0){
                                fields.marge.setValue(0)
                            }else {
                                const marge = ((fields.unitaryPrice.getValue() - newValue) / newValue) * 100
                                fields.marge.setValue(_.round(marge))
                            }

                        }
                    }
                }
            },
            {
                path: "unitaryPrice", tKey: "price *",
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (! formInitialize && _.toNumber(newValue)) {
                            const fields = getFieldsModuleForm(
                                ["unitaryCost", "marge"],
                                module
                            )
                            const marge = ((newValue-fields.unitaryCost.getValue()) / fields.unitaryCost.getValue()) * 100

                            if(! fields.marge.subscriptions.onChange.arguments) {
                                fields.marge.setValue(_.round(marge))
                            }
                        }
                    }
                }
            },
            {
                path:"marge",
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (! formInitialize && _.toNumber(newValue)) {
                            const fields = getFieldsModuleForm(
                                ["unitaryCost", "unitaryPrice"],
                                module
                            )

                            const unitaryPrice = fields.unitaryCost.getValue() * ((newValue / 100) +1)
                            if(! fields.unitaryPrice.subscriptions.onChange.arguments) {
                                fields.unitaryPrice.setValue(_.round(unitaryPrice))
                            }
                        }
                    }
                }
            },

            {
                path: "projectSalesCostVolumes",
                type: "dtObjects",
                tKey: "volume_plural",
                autoGrow: true,
                fields: [
                    {path: "projectSalesCostVolumeUnit.id", tKey: " ", hidden: true},
                    {path: "projectSalesCostVolumeUnit.name", tKey: "name"},
                    {path: "N", type: "editText", componentClass: "mini-input"},
                    {path: "N+1", type: "editText", componentClass: "mini-input"},
                    {path: "N+2", type: "editText", componentClass: "mini-input"},
                    {path: "N+3", type: "editText", componentClass: "mini-input"},
                    {path: "N+4", type: "editText", componentClass: "mini-input"}
                ],
                default: defaultVolumeUnits(5)
            }
        ],
    },
    filters: ["byBusinessProject"],
    actionSubscriptions: [...defaultBusinessProjectSubscriptions]
}

export const hypHorsVentes = {
    name: "otherOperatingCosts",
    object: "OtherOperatingCost",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    viewMap: {
        dt: [
            {path: "component", tKey: "descriptive", width: 1000},
            {path: "otherOperatingCostUnitOfWork", tKey: "unitOfWork", width: 150},
            {path: "unitaryCost", tKey: "cost_short", width: 80},
            {path:"number", tKey: "quantityNb", width: 80},
            {path: "oocTotal", width: 80}
        ],
        form: [
            {
                path: "businessProject",
                display: "name",
                writable: false,
                filters:["developTheBusinessPlan"]
            },
            {path: "component", tKey: "descriptive"},
            {path: "otherOperatingCostUnitOfWork", tKey: "unitOfWork"},
            "unitaryCost",
            {
                path: "otherOperatingCostVolumes",
                type: "dtObjects",
                autoGrow: true,
                tKey: "volume_plural",
                fields: [
                    {path: "otherOperatingCostVolumeUnit.id", tKey: " ", hidden: true},
                    {path: "otherOperatingCostVolumeUnit.name", tKey: "name"},
                    {path: "N", type: "editText", componentClass: "mini-input"},
                    {path: "N+1", type: "editText", componentClass: "mini-input"},
                    {path: "N+2", type: "editText", componentClass: "mini-input"},
                    {path: "N+3", type: "editText", componentClass: "mini-input"},
                    {path: "N+4", type: "editText", componentClass: "mini-input"}
                ],
                default: require("./staticEntities").default.find(
                    o => o.name === "OtherOperatingCostVolumeUnit"
                ).objects.map(
                    cmp => Object.assign({}, {otherOperatingCostVolumeUnit: cmp}, {
                        N: 0,
                        "N+1": 0,
                        "N+2": 0,
                        "N+3": 0,
                        "N+4": 0
                    })
                )
            }
        ]
    },
    filters: ["byBusinessProject"],
    actionSubscriptions: [...defaultBusinessProjectSubscriptions]
}

export const hypFiscales = {
    name: "otherFiscalCosts",
    object: "OtherFiscalCost",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    viewMap: {
        dt: [
            {path: "component", tKey: "descriptive", width: 1000},
            {path: "otherFiscalCostBase", tKey: "base", width: 500},
            {path: "rate", tKey: "rate", tooltip: true, width: 80},
        ],
        form: [
            {
                path: "businessProject",
                display: "name",
                writable: false,
                filters:["developTheBusinessPlan"]
            },
            {path: "component", tKey: "descriptive"},
            {path: "otherFiscalCostBase", tKey: "base *"},
            {path: "rate", tKey: "rate *"}
        ]
    },
    filters: ["byBusinessProject"],
    actionSubscriptions: [...defaultBusinessProjectSubscriptions]
}
