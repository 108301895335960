const _ = require('lodash')
const moment = require("moment");

export const entities = [
    {
        name: 'Duration',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            'name'
        ],
        objects: [
            { id: '0,25', name: '0.25' },
            { id: '0,50', name: '0.50' },
            { id: '0,75', name: '0.75' },
            { id: '1,00', name: '1.00' },
        ]
    },

    {
        name: "Activity",
        fields: [
            { path: 'action', type: 'Action' },
            { path: 'duration', type: 'Duration' },
            'note',
            { path: 'inputDate', default: '1999-12-31' },
        ]
    },


    {
        name: "Input",
        fields: [
            { path: 'day', type: 'date', nullable: false },
            { path: 'user', type: 'User', nullable: true },
            { path: 'activities', type: 'Activity', link: 'OTM' },
            {
                path: 'actions',
                objectFieldPath: ['activities.action.name'],
                fieldPath: ['activities.action.name'],
                f: function() {
                    let actionsSet = new Set()
                    this.activities.forEach( activity => {
                        actionsSet.add(activity.action.name)
                    })
                    let actionsList = [...actionsSet].join(', ')
                    return actionsList
                }
            },
        ],
        filters: [
            {
                name: "byDate",
                path: "day",
                type: "dateRange",
                client: true,
                clearable: true,
                isDefault: false,
                query: function(context) {
                    const day = context.data && context.data.day
                    if(day)
                        return { day: { $gte : new Date(day[0]), $lte: new Date(day[1]) } }
                    return {}
                }
            }
        ],
        beforeSave: function(newObject, oldObject, context, callback) {
            const action = context.restAction && context.restAction.crudType
            const date = new Date()
            let durationSum = 0, uniqDayUser = false

            newObject.activities.forEach( e => {
                durationSum += Number(e.duration.name)
            })

            if (action === 'C') {
                newObject.activities.forEach( (e, i) => {
                    e.inputDate = `${moment(date).format("YYYY-MM-DD HH:mm")}`
                })
                newObject.user = context.user
                // The day-user pair must be unique
                global.app.K.Input.collection.find({}).toArray(function(e, res) {
                    if (e) return e
                    for (let i = 0; i < res.length; i++) {
                        if (moment(res[i].day).format("YYYY-MM-DD") === moment(newObject.day).format("YYYY-MM-DD")
                            && res[i].user == newObject.user.id) {
                            uniqDayUser = true
                            break
                        }
                    }
                    if (durationSum > 1.00) {
                        callback(new Error(context.t('dayOverload')))
                    }
                    else if (uniqDayUser) {
                        callback(new Error(context.t('uniqueDayUser')))
                    }
                    else {
                        callback(null, newObject, oldObject)
                    }
                })
            }
            else if (action === 'U') {
                if (newObject.activities.length > oldObject.activities.length) {
                    const startId = newObject.activities.length - (newObject.activities.length - oldObject.activities.length)
                    for (let i = startId; i < newObject.activities.length; i++) {
                        newObject.activities[i].inputDate = `${moment(date).format("YYYY-MM-DD HH:mm")}`
                    }
                }
                if (durationSum > 1.00) {
                    callback(new Error(context.t('dayOverload')))
                }
                else {
                    callback(null, newObject, oldObject)
                }
            }
        }
    },
]
