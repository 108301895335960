import {translateName} from "../../../../utils/i18n";

export const entity = require('../../../utils/entityJoin').joinEntities(
    {
        name: 'AmountTypeJoinedEntity',
        fields: [
            //'name',
            'code',
            {
                path: 'name',
                fieldPath: [],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'DisplayedAmount') {
                        return  callback(null, object.name)
                    }
                    if (object.joinedEntity === 'RealizationType') {
                        return callback(null, `Type suivi : ${context.translateName(object.name)} ( ${object.code} )`)
                    }
                }
            },
            {
                path: 'path',
                fieldPath: [],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'DisplayedAmount') {
                        return  callback(null, object.path)
                    }
                    if (object.joinedEntity === 'RealizationType') {
                        return callback(null, object.code)
                    }
                }
            }
        ],
        /*
        beforeSave: function(newObject, oldObject, context){
            if (newObject.joinedEntity === 'Mesh'){
                return newObject
            }
        },
         */
        filters: [
            /*£
            {
                name: 'byIncludeInFunctionScope',
                isDefault: true,
                match: object => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return object.includeInFunctionScope
                    }
                    return true
                }
            }

             */
        ],
    },
    ['DisplayedAmount', 'RealizationType']
)
