import React from 'react'
import { Field } from 'redux-form'
import CheckBoxField from '../../../../components/Form/CheckboxField'

// binding for redux-form
const Input = ({ input: {value, onChange}, path, editable, disabled, t}) =>  {
    return <CheckBoxField label={path} value={value} onChange={onChange} readOnly={!editable} disabled={disabled || !editable} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormCheckboxField = ({ field, t, objectMode }) => {
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            component={Input}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            disabled={field.disabled}
            t={t}
        />
    )
}

export default FormCheckboxField
