import React from 'react'
import ImagesUploaderField from '../../../../components/ImagesUploaderField'
import { getModule } from '../../selectors'
import { getUser } from '../../../MainPage/selectors'
import { getFormValues } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { connect } from 'react-redux'
import _ from "lodash";
import moment from "moment";


const LogoPicker = props => {
    const images = _.get(props, `moduleData.${props.field.path}`) || []
    const logo = images[0]
    const fullDate = logo && moment(logo.date).format('YYYY-MM-DD_HH-mm-ss')
    return (
        <div style={{padding: '5px'}}>
            {
                logo && (
                    <div className="SidebarContent-logo-container" style={{border: '1px dashed #838383'}}>
                        <img
                            alt={'fsdgfsd'}
                            className="SidebarContent-logo"
                            src={`/image/${fullDate}_${logo.name}`}
                        />
                    </div>
                )
            }
            <ImagesUploaderField
                {...props}
                module={props.module}
                user={props.user}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    module: getModule(state),
    user: getUser(state),
    moduleData: getFormValues(OBJECT_FORM)(state)
})

export default connect(mapStateToProps)(LogoPicker)
