const { basicContext } = require('../../utils/contextUtils')
const _ = require('lodash')
const moment = require('moment')
const { translateName } = require('../../../utils/i18n')
const todayISO = moment().format('YYYY-MM-DD')
const lastWeekISO = moment().subtract(7, 'days').format('YYYY-MM-DD')

export const entity = {
    name: 'ScoreAnalysis',
    fields: [
        'order',
        'scoringName',
        'scoringPeriodicity',
        'perimeterName',
        'comparisonName',
        'dataPeriod',
        'scoreInValue',
        'elementsName'
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findScoreLineData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    indexes: [
        {key: {incomplete: 1, baseDay: 1, scoringConfig: 1}}
    ]
}

async function findScoreLineData(context) {

    const scoreConfigId = _.get(context, 'data.scoringConfig.id')

    const filterDates = _.get(context, 'data.period', [lastWeekISO, todayISO])
        .map(date => moment.utc(date.trim(), 'YYYY-MM-DD'));

    if (!scoreConfigId || !filterDates) return []

    const scorings = await global.app.S.Scoring.find({
        ...basicContext(context),
        fieldPath: ['id'],
        query: {
            scoringConfig: new global.ObjectID(scoreConfigId),
            'date.gte': {$gte: filterDates[0].toDate()},
            'date.lt': {$lte: filterDates[1].add(1, 'days').toDate()}
        }
    })

    if(scorings.length){

        const scoreLines = await global.app.S.ScoreLine.find({
            ...basicContext(context),
            fieldPath: [
                'id', 'elementsName',
                'scoringName', 'scoringPeriodicity', 'order', 'perimeterName',
                'comparisonName', 'dataPeriod', 'dataPeriodColumn', 'scoreInValue',
            ],
            query: {
                scoring: {$in: scorings.map(o => new global.ObjectID(o.id))},
            }
        })

        const scoreLineGroupedByElement = _.groupBy(scoreLines, o => o.elementsName)

        const result = Object.keys(scoreLineGroupedByElement).reduce((acc, elementsName) => {
            return [
                ...acc,
                {
                    id: elementsName,
                    elementsName,
                    ...scoreLineGroupedByElement[elementsName].reduce((acc, scoreLine) => {
                        return {
                            ..._.pick(scoreLine, [
                                'scoringName',
                                'perimeterName',
                                'scoringPeriodicity',
                                'comparisonName'
                            ]),
                            score: {
                                ...acc.score,
                                [scoreLine.dataPeriodColumn] : scoreLine.scoreInValue
                            },
                            order: {
                                ...acc.order,
                                [scoreLine.dataPeriodColumn] : scoreLine.order
                            }
                        }
                    }, {})
                }]
        }, [])

        return result

    } else {
        return []
    }
}
export const module_ = {
    object: 'ScoreAnalysis',
    tKey: 'mTitle_scoreAnalysis',
    category: {
        path: 'tracking',
        icon: 'briefcase'
    },
    newable: false,
    updatable: false,
    removable: false,
    defaultSortBy: 'elementsName',
    defaultSortDirection: 'ASC',
    parentHeader: true,
    memorizeColumns: false,
    headerClassName: 'breakHeaderLine',
    headerRowClassName: 'KpDataTableOverFlowHidden',
    headerHeight: 45,
    viewMap:{
        dt: [
            {path: 'scoringName', parentColumn: '', tKey: 'scoringType', initiallyNotVisible: true},
            {path: 'perimeterName', parentColumn: '', tKey: 'perimeter', initiallyNotVisible: true},
            {path: 'scoringPeriodicity', parentColumn: '', tKey: 'periodicity', translate: true, initiallyNotVisible: true},
            {path: 'comparisonName', parentColumn: '', tKey: 'comparisonGroup', initiallyNotVisible: true},
            {path: 'elementsName', parentColumn: '', tKey: 'element'},
            { path: 'order', parentColumn: 'Order', dynamic: true},
            { path: 'score', parentColumn: 'Score', dynamic: true},
        ]
    },
    filters: [
        {
            path: 'scoringConfig',
            object: 'ScoringConfig',
            display: 'tName',
            filters: ['scoringConfigurationsByHabFunctions'],
            clearable: false,
            client: true
        },
        {
            path: 'period',
            type: 'dateRange',
            default: [lastWeekISO, todayISO],
            client: true
        }
    ]
}
