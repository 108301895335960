import React from 'react'
import PropTypes from 'prop-types'
import './TrashCell.css'
import {Trash2} from 'react-feather'

export const TrashCell = ({ onClick, style = {}, size = 16 }) => (
    <Trash2 size={size} style={style} className="DataTable-TrashCell" onClick={event => {
        event.stopPropagation()
        onClick()
    }} />
)

TrashCell.propTypes = {
    onClick: PropTypes.func
}

export default TrashCell
