import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import user from './user'
import randomList from './randomList'
import groupModel from './groupModel'
import ui from './ui'
import file from './file'
import kpModule from './module'
import list from './list'
import chart from './chart'
import filter from './filter'
import edit from './edit'
import fields from './fields'
import dataLists from './dataLists'

/* istanbul ignore next */
export default combineReducers({
    user,
    groupModel,
    module: kpModule,
    fields,
    dataLists,
    list,
    chart,
    edit,
    filter,
    randomList,
    form,
    ui,
    file
})
