const _ = require('lodash')
async function findData(context) {
    const courseId = _.get(context, 'data.course.id')

    if(!courseId) return []
    const course = await global.app.FT.Course.get({_id: new global.ObjectID(courseId)},
        {
            fieldPath: ['formation', 'participants', 'formationFullTitle'],
            group: context.group
        })
    const synthesisCollection = global.app.FT.Synthesis.collection

    const fetchPromise = synthesisCollection
        .aggregate([
            {
                $match: {
                    participant: {$in: course.participants.map(participant => new global.ObjectID(participant.id))},
                    course: new global.ObjectID(context.data.course.id)
                }
            }
        ])
        .toArray()

    const participantsSyntheses = await fetchPromise

    const groupedByParticipantId = _.groupBy(participantsSyntheses, participantsSynthesis =>  participantsSynthesis.participant.toString())
    return _.flatMap(course.participants, (participant) => {
        const groupedByObjectiveId = groupedByParticipantId[participant.id]
            && _.groupBy(groupedByParticipantId[participant.id], participantsSynthesis =>  participantsSynthesis.objective.toString())
        return  course.formation.formationObjectives.map( formationObjective => {

            const oldObject = groupedByObjectiveId
                && groupedByObjectiveId[formationObjective.objective.id]
                && groupedByObjectiveId[formationObjective.objective.id][0]

            const object = {
                participant,
                course: {
                    id: course.id,
                    formationFullTitle: course.formationFullTitle,
                },
                objective: {
                    id: formationObjective.objective.id,
                    fullName: formationObjective.objective.fullName
                },
                target: formationObjective.level.name
            }
            if(oldObject) {
                return {
                    ...object,
                    id: oldObject._id.toString(),
                    present: oldObject.present,
                    acquired: {
                        id: oldObject.acquired,
                        name: oldObject.acquired
                    },
                    synthesis: oldObject.synthesis,
                }
            } else {
                return {
                    ...object,
                    id: `${participant.id}-${course.id}-${formationObjective.objective.id}`,
                    present: false,
                    acquired: null,
                    synthesis: "",
                }
            }
        })
    })
}

async function getData(id, context) {
    const course = await global.app.FT.Course.get({_id: new global.ObjectID(context.data.course.id)},
        {
            fieldPath: ['formation', 'participants', 'formationFullTitle'],
            group: context.group
        })

    const [participantId, courseId, objectiveId] = id.split('-')
    const query = courseId
        ? {
            participant: new global.ObjectID(participantId),
            course: new global.ObjectID(courseId),
            objective: new global.ObjectID(objectiveId),
        } :
        {
            _id: new global.ObjectID(id)
        }
    const participantSynthesis = await global.app.FT.Synthesis.collection.findOne(query)


    if(participantSynthesis) {

        const participant = course.participants.find(participant => participant.id ===  participantSynthesis.participant.toString())

        const formationObjective = course.formation.formationObjectives.find(formationObjective => formationObjective.objective.id === participantSynthesis.objective.toString())

        return {
            participant,
            course: {
                id: course.id,
                formationFullTitle: course.formationFullTitle,
            },
            objective: {
                id: formationObjective.objective.id,
                fullName: formationObjective.objective.fullName
            },
            target: formationObjective.level.name,
            id,
            present: participantSynthesis.present,
            acquired: {
                id: participantSynthesis.acquired,
                name: participantSynthesis.acquired
            },
            synthesis: participantSynthesis.synthesis,
        }
    }
    else {
        const participant = course.participants.find(participant => participant.id ===  participantId)

        const formationObjective = course.formation.formationObjectives.find(formationObjective => formationObjective.objective.id === objectiveId)

        return {
            participant,
            course: {
                id: course.id,
                formationFullTitle: course.formationFullTitle,
            },
            objective: {
                id: formationObjective.objective.id,
                fullName: formationObjective.objective.fullName
            },
            target: formationObjective.level.name,
            id,
            present: false,
            acquired: null,
            synthesis: "",
        }
    }
}

export const entity = {
    name: 'Synthesis',
    fields: [
        {path: 'participant', type: 'User'},
        {type: 'Course'},
        {type: 'Objective'},
        {
            path: 'target',
            fieldPath: ['course.formation.formationObjectives', 'objective'],
            f: function () {
                const formationObjective = this.course.formation.formationObjectives.find(formationObjective => formationObjective.objective.id === this.objective.id)
                 return formationObjective && formationObjective.level.name
            }
        },
        //{path: 'target', type: 'Level'},
        {path: 'present', type: 'boolean'},
        {path: 'acquired', type: 'Level'},
        'synthesis'
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else
                getData(id, context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    save: async (newObject, context, callback) => {

        const course = await global.app.FT.Course.get({_id: new global.ObjectID(context.data.course.id)},
            {
                fieldPath: ['formation', 'participants', 'formationFullTitle'],
                group: context.group
            })

        const [participantId, courseId, objectiveId] = newObject.id.split('-')
        const query = courseId
            ? {
                participant: new global.ObjectID(participantId),
                course: new global.ObjectID(courseId),
                objective: new global.ObjectID(objectiveId),
            } :
            {
                _id: new global.ObjectID(newObject.id)
            }
        global.app.FT.Synthesis.collection.update(
            query,
            {
                $set: {
                    present: newObject.present,
                    acquired: newObject.acquired.id,
                    synthesis: newObject.synthesis
                }
            },
            {upsert: true},
            (e) => {
                const participant = course.participants.find(participant => participant.id ===  newObject.participant.id)

                const formationObjective = course.formation.formationObjectives.find(formationObjective => formationObjective.objective.id === newObject.objective.id)

                const object = {
                    ...newObject,
                    id: newObject.id,
                    participant,
                    course: {
                        id: course.id,
                        formationFullTitle: course.formationFullTitle,
                    },
                    objective: {
                        id: formationObjective.objective.id,
                        fullName: formationObjective.objective.fullName
                    },
                    target: formationObjective.level.name
                }
                callback(e, object)
            }
        )
    }
}
export const module_ = {
    object: 'Synthesis',
    tKey: 'mTitle_synthesis',
    category: {
        path: 'sessions',
        icon: 'book'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'participant', display: 'name'},
            {path: 'course', display: 'formationFullTitle', tKey: 'formation'},
            {path: 'objective', display: 'fullName'},
            {path: 'target', width : 100},
            {path: 'present', width : 100},
            {path: 'acquired', width: 100},
            'synthesis'
        ],
        form: [
            {path: 'participant', display: 'name', editable: false},
            {path: 'course', display: 'formationFullTitle', editable: false, tKey: 'formation'},
            {path: 'objective', display: 'fullName', editable: false},
            {path: 'target', editable: false},
            {path: 'present'},
            {path: 'acquired'},
            {path: 'synthesis', type: 'textarea'}
        ]
    },
    filters: [{
        path: 'course',
        object: 'Course',
        client: true,
        width: 12,
        display: 'fullName',
        //fieldPath: ['formationFullTitle'],
        filters: ['ongoing']
    }]
}
