const _ = require('lodash')

export default {
    id: "E",
    name: "Engagement",
    description: "",
    entities: _.concat(
        [
            //referential
            require('./commitment/referential/organizationAxis').entity,
            require('./commitment/referential/organization').entity,
            require('./commitment/referential/organizationalWorkflowMesh').entity,
            require('./commitment/referential/project').entity,
            require('./commitment/referential/imputationEntity').entity,
            require('./commitment/referential/lot').entity,
            require('./commitment/referential/sublot').entity,
            require('./commitment/referential/nature').entity,
            require('./commitment/referential/provider').entity,
            require('./commitment/referential/deliverable').entity,
            require('./commitment/referential/expenditureType').entity,
            require('./commitment/referential/accountingId').entity,
            require('./commitment/referential/function').entity,
        ],
        require('./commitment/referential/currency').entities,
        require('./commitment/settings/budget').entities
    ),
    modules: [
        //referential
        require('./commitment/referential/currency').module_,
        require('./commitment/referential/organizationAxis').module_,
        require('./commitment/referential/organization').module_,
        require('./commitment/referential/organizationalWorkflowMesh').module_,
        require('./commitment/referential/project').module_,
        require('./commitment/referential/imputationEntity').module_,
        require('./commitment/referential/lot').module_,
        require('./commitment/referential/sublot').module_,
        require('./commitment/referential/nature').module_,
        require('./commitment/referential/provider').module_,
        require('./commitment/referential/deliverable').module_,
        require('./commitment/referential/expenditureType').module_,
        require('./commitment/referential/accountingId').module_,
        require('./commitment/referential/function').module_,
        require('./commitment/settings/budget').module_
    ]
}
