const _ = require("lodash");

const defaultViews = [
    {name: "dt"},
    {name: "form"}
];

export default function(workflowGenerator) {
    const profileConfigEntityName = workflowGenerator.profileConfigEntity.name;

    const entityFields = {
        dt: [
            {path: "statusString"},
            {path: `step${profileConfigEntityName}s`}
        ],
        form: [
            {path: "statusString", type: "readOnly"},
            "comments",

            // attending fieldPath definition on the view
            {path: "buttons", hidden: true},
            {path: "status.step", hidden: true},
            {path: "status.order", hidden: true},
        ]
    };

    return {norm: {fields: [
        {
            path: "views",
            default: [],
            $p: [
                {$if: _.isEmpty, $u: _.partial(_.cloneDeep, defaultViews)},
                "fields"
            ],
            fields: [
                {path: "fields", default: [], $p: {
                    $if: (fields, view) => entityFields[view.name],
                    $u: (fields, view) => [...fields, ..._.cloneDeep(entityFields[view.name])]
                }}
            ]
        }
    ]}};
};
