const _ = require('lodash')
const moment = require("moment");
const {fieldPathJoinGetter} = require("../../utils/kp3Utils");

export const entities = [
    {
        name: 'Project',
        facets: ['comments'],
        fields: [
            {path: 'code', unique: true, nullable: true},
            'name',
            fieldPathJoinGetter({path: 'fullName', fieldPath: ['code', 'name']}),
            'Company',
            'Subject',
            { type: 'Contact', link: 'MTM', nullable: true },
            { path: 'team', type: 'User', link: 'MTM' },
            { path: 'creation', type: 'date', nullable: true },
            { path: 'update', type: 'date', nullable: true },
            { type: 'User', nullable: true },
            'description',
            { path: 'status', type: 'ProjectStatus' }
        ],
        filters: [
            {
                name: 'onlyOngoingProspectiveProject',
                isDefault: false,
                query: function() {
                    return { status: {$in: ['ongoing', 'prospective']} }
                }
            },
            {
                name: 'onlyOwner',
                isDefault: false,
                query: function(context) {
                    const userId = _.get(context, 'user.id')
                    if (userId)
                        return { user: global.ObjectID(userId) }
                    else
                        return {_id: false}
                }
            },
            {
                name: 'onlyUserInTeam',
                isDefault: false,
                query: function(context) {
                    const userId = _.get(context, 'user.id')
                    if (userId)
                        return { team: global.ObjectID(userId) }
                    else
                        return {_id: false}
                }
            }
        ],
        beforeSave: function(newObject, oldObject, context, callback) {
            const action = context.restAction && context.restAction.crudType
            const date = new Date()

            if (action === 'C') {
                newObject.code = `${newObject.company.code} - ${newObject.subject.code}`
                newObject.creation = date
                newObject.user = context.user
            }
            newObject.update = date

            callback(null, newObject, oldObject)
        }
    },



    {
        name: "Deadline",
        type: "mongoInternal",
        fields: [
            { path: 'date', type: 'date' },
            { path: 'nbDays', type: 'integer', notEmpty: true },
            'note',
            { path: 'inputDate', default: '1999-12-31' },
        ]
    },


    {
        name: 'Action',
        facets: ['files', 'comments'],
        fields: [
            { path: 'name', unique: true },
            'Project',
            { path: 'type', type: 'ActionType' },
            { path: 'creation', type: 'date', nullable: true },
            { path: 'update', type: 'date', nullable: true },
            { type: 'User', nullable: true },
            { path: 'deadlines', type: 'Deadline', link: 'OTM' },
            { path: 'responsible', type: 'User' },
            'description',
            { path: 'status', type: 'ActionStatus' },
            {
                path: 'lastDeadlineDate',
                f: function() {
                    const lastDeadline = this.deadlines[this.deadlines.length - 1]
                    return `${moment(lastDeadline.date).format("YYYY-MM-DD")}`
                }
            },
            {
                path: 'lastDeadlineNbDays',
                f: function() {
                    const lastDeadline = this.deadlines[this.deadlines.length - 1]
                    return `${lastDeadline.nbDays}`
                }
            },
        ],
        beforeSave: function(newObject, oldObject, context, callback) {
            const action = context.restAction && context.restAction.crudType
            const date = new Date()

            newObject.user = context.user
            newObject.update = date

            if (action === 'C') {
                newObject.creation = date
                newObject.deadlines.forEach( (e, i) => {
                    e.inputDate = `${moment(date).format("YYYY-MM-DD HH:mm")}`
                })
            }
            else if (action === 'U') {
                if (newObject.deadlines.length > oldObject.deadlines.length) {
                    const startId = newObject.deadlines.length - (newObject.deadlines.length - oldObject.deadlines.length)
                    for (let i = startId; i < newObject.deadlines.length; i++) {
                        newObject.deadlines[i].inputDate = `${moment(date).format("YYYY-MM-DD HH:mm")}`
                    }
                }
            }

            callback(null, newObject, oldObject)
        }
    },
]
