import _ from 'lodash'

export const capitalizer = {
    type: 'postProcessor',
    name: 'capitalizer',
    process: (value, key, options) => options.disableCapitalize ? _.lowerFirst(value) : _.upperFirst(value)
}

/*
* translateName extracts translations from a multi line string with shape (fr:: nom en::name)
* */
export function translateName(rawName = '', language = 'en') {
    const names = rawName.split('\n')
    const nameForLgn = lng => names.find(t => t.match(new RegExp(`^${lng}::`)))

    const name = nameForLgn(language) || names[0]
    return name.replace(/^\w+::\s*/, '')
}

export function translateName2(rawName = '', language = 'en') {
    const names = rawName.split('\n')
    const nameForLgn = lng => names.find(t => t.match(new RegExp(`^${lng}::`)))

    const name = nameForLgn(language) || names[0]
    const translated = name.replace(/^\w+::\s*/, '')

    return translated.split('\n').join('<br/>')
}

/*
* This is the default translation function to be used when library function is not reached
* */
export function defaultT(path, { capitalize }={}) {
    return capitalize ? _.capitalize(path) : path
}
