const moment = require('moment');
const _ = require('lodash')
const { sendInvitation } = require('./utils/sendInvitation')
const { sendReport } = require('./utils/sendReport')
const { prepareDocument } = require('./utils/prepareDocument')

export const entities = [
    {
        name: 'MeetingStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'tKey'],
        objects: [
            { id: 'inProgress', tKey: 'meetingInProgress' },
            { id: 'closed', tKey: 'meetingClosed' },
            { id: 'notHeld', tKey: 'meetingNotHeld' },
        ]
    },
    {
        name: 'Meeting',
        facets: ['comments', 'files'],
        fields: [
            {
                path: "sequence", unique: true, ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "v.meetingSeq",
                        formatResult: result => `${result}`
                    }]
                }
            },
            { path: 'code', unique: true },
            'Entity',
            'TypeOfMeeting',
            'object',
            { path: 'date', type: 'date' },
            {path: 'startTime', type: "time"},
            {path: 'endTime', type: "time"},
            'place',
            'expectedGuests',
            'presentGuests',
            {
                path: 'expectedParticipants',
                type: 'User',
                link: 'MTM',
                nullable: true
            },
            {
                path: 'presentParticipants',
                type: 'User',
                link: 'MTM'
            },
            'observation',
            'synthesis',
            { type: 'MeetingStatus', nullable: true},
            { path: 'sendInvitation', type: 'boolean' },
            { path: 'sendReport', type: 'boolean' },
            {
                path:"entityCode",
                fieldPath:["entity.id"],
                f: function () {
                    return `${this.entity.name} - ${this.code}`
                }
            },
            {
                path:"totalNumberOfQuestions",
                fieldPath:["questions.id"],
                f: function () {
                    return this.questions.length
                }
            },
            {
                path:"totalNumberOfQuestionsInProgress",
                fieldPath:["questions.questionStatus.id"],
                f: function () {
                    return this.questions.filter( question => question.questionStatus && question.questionStatus.id === "inProgress" ).length
                }
            },
            {
                path:"totalNumberOfQuestionsTreated",
                fieldPath:["questions.questionStatus.id"],
                f: function () {
                    return this.questions.filter( question => question.questionStatus && question.questionStatus.id === "closed" ).length
                }
            },
            {
                path:"totalNumberOfQuestionsAbandoned",
                fieldPath:["questions.questionStatus.id"],
                f: function () {
                    return this.questions.filter( question => question.questionStatus && question.questionStatus.id === "abandoned" ).length
                }
            },
            {
                path:"totalNumberOfQuestionsPostponed",
                fieldPath:["questions.questionStatus.id"],
                f: function () {
                    return this.questions.filter( question => question.questionStatus && question.questionStatus.id === "delayed" ).length
                }
            },
            {
                path:"totalNumberOfQuestionsNotTreated",
                fieldPath:["questions.questionStatus"],
                f: function () {
                    return this.questions.filter( question => question.questionStatus === null ).length
                }
            }
        ],
        filters: [
            {
                name: "shouldRespectDaysUpstream",
                async: true,
                isDefault: false,
                match: function (object, context, callback) {

                    const todayMoment = moment().format("YYYY-MM-DD")

                    const meetingTypeContext = {
                        user: context.user,
                        group: context.group,
                        query: {
                            _id: new global.ObjectID(object.typeOfMeeting.id)
                        }
                    }

                    global.app.V.TypeOfMeeting.find(meetingTypeContext, (e, objects) => {
                        if(e) callback(e)

                        const newDate = moment.utc(todayMoment, "YYYY-MM-DD").add(objects[0].daysUpstream, 'days').toDate();
                        callback(null, object.date >= newDate)
                    })
                }
            },
            {
                name: "upToDate",
                isDefault: false,
                query: () => {
                    const today = moment().format("YYYY-MM-DD")
                    const filterDate = moment.utc(today, "YYYY-MM-DD").toDate();
                    return {"date": { $gte: filterDate }}
                }
            },
            {
                name: "isToday",
                isDefault: false,
                query: () => {
                    const today = moment().format("YYYY-MM-DD")
                    const filterDate = moment.utc(today, "YYYY-MM-DD").toDate();

                    return {"date": filterDate }
                }
            },
            {
                name: "pastDated",
                isDefault: false,
                query: () => {
                    const today = moment().format("YYYY-MM-DD")
                    const filterDate = moment.utc(today, "YYYY-MM-DD").toDate();

                    return {"date": { $lte: filterDate }}
                }
            },
            {
                name: "userHasHabilitationForEntity",
                async: true,
                isDefault: false,
                query: function (context, callback) {
                    global.app.V.Habilitation.find(
                        {
                            query: { 'users': new global.ObjectID(context.user.id) },
                            group: context.group
                        },
                        (e, habilitations) => {
                            if (e) return callback(e);

                            callback(null, {
                                "entity": {$in: habilitations.map(hab => new global.ObjectID(hab.entity.id))}
                            })
                        }
                    );
                }
            },
            {
                name: "meetingInProgress",
                fieldPath:["meetingStatus.id"],
                client: false,
                isDefault: false,
                match: function(object) {

                    return !object.meetingStatus || object.meetingStatus === 'inProgress'
                }
            },
            {
                name: "byMeetingStatus",
                path: 'meetingStatus',
                object: 'MeetingStatus',
                display: 'tKey',
                translate: true,
                client: true,
                clearable: true,
                match: function(object, context) {

                    const meetingStatus = _.get(context, 'data.meetingStatus.id')

                    if(!meetingStatus) return true
                    return object.meetingStatus && object.meetingStatus.id === meetingStatus
                }
            },
        ],
        sorters: [
            {
                name: 'byCode',
                query: () => ({ code: 1 })
            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        const internalFielPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "entity.code",
                                "sequence"
                            ])
                        ];
                        context.internalFieldPath = internalFielPath;
                    }
                    else if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
                        const internalFielPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "code",
                                "expectedParticipants.id",
                                "expectedParticipants.firstname",
                                "expectedParticipants.lastname",
                                "questions.lastUser.id",
                                "questions.owner.id",
                                "questions.subject.name",
                                "typeOfMeeting.id",
                                "typeOfMeeting.numberOfGuests"
                            ])
                        ];
                        context.internalFieldPath = internalFielPath;
                    }
                    callback(null, context)
                }
            }]
        },
        validateSave: function(object, oldObject, context, callback) {
            const minNumberOfParticipants = object.typeOfMeeting.numberOfGuests

            const todayMoment = moment().format("YYYY-MM-DD")
            const filterDate = moment.utc(todayMoment, "YYYY-MM-DD").toDate();

            if ( object.date < filterDate ) {
                return callback(new Error("chooseLaterDate"));
            }
            if ( object.expectedParticipants.length < minNumberOfParticipants ) {
                return callback(new Error(context.tc("notEnoughParticipants", { np: minNumberOfParticipants })));
            }
            callback(null)

        },
        beforeSave: (object, oldObject, context, callback) => {

            const date = moment(object.date).format('YYYYMMDD')

            if (object.code) {
                callback(null, object, oldObject)
            } else {
                object.code = ( object.entity.code + "-" + date + "-" + object.sequence ).replace(/\s/g, '')
                object.meetingStatus = {id:"inProgress"}
                callback(null, object, oldObject)
            }

        },
        afterSave: function (newObject, oldObject, context, callback) {
            if (newObject.sendInvitation === true) {

                const expectedParticipants = newObject.expectedParticipants.map(o => o.mail)
                const expectedGuests = newObject.expectedGuests ? _.split(newObject.expectedGuests, ',') : []
                const recipients = _.concat(expectedParticipants, expectedGuests)
                const user = context.user
                sendInvitation(recipients, newObject, user, expectedGuests)
                callback()
            }
            else if(newObject.sendReport === true) {

                const presentParticipants = newObject.presentParticipants.map(o => o.mail)
                const presentGuests = newObject.presentGuests ? _.split(newObject.presentGuests, ',') : []
                const recipients = _.concat(presentParticipants, presentGuests)
                const user = context.user

                const document = prepareDocument(newObject, context)

                global.pdf.generatePdf(document, (e, result) => {
                    if(e) {
                        console.log(e)
                        sendReport(recipients, newObject, user, presentGuests)
                    }
                    sendReport(recipients, newObject, user, presentGuests, result)
                })
                callback()
            } else {
                callback()
            }
        }
    }
]

export const organization = {
    name: 'organization',
    object: 'Meeting',
    category: 'manageTheMeeting',
    tKey: 'mTitle_organization',
    defaultSortBy: 'date',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            { path: 'entity', width: 150},
            {
                path: 'typeOfMeeting',
                display: 'code', tKey: 'type',
                width: 150, initiallyNotVisible: true
            },
            { path: "object", width: 400 },
            { path: 'date', width: 150 }
        ],
        form: [
            { path: 'entity', filters: ["active", "userHasHabilitationForEntity"]},
            { path: 'typeOfMeeting', filters:["active"], display: 'code' },
            { path: 'object', required: true },
            { path: 'date', required: true },
            { path: 'startTime', required: true },
            { path: 'endTime', required: true },
            { path: 'place', required: true },
            'expectedParticipants',
            {
                path: 'expectedGuests',
                placeholder: 'alexia.dupont@gmail.com,jdoe@gmail.com,...'
            },
            {path: 'sendInvitation', tKey:"sendMail"},
            {path: 'observation', type: "richTextEditor"},
            'comments',
            'files'
        ]
    },
    filters:["upToDate", "userHasHabilitationForEntity", "meetingInProgress"]
}

export const presence = {
    name: 'presence',
    object: 'Meeting',
    category: 'manageTheMeeting',
    tKey: 'mTitle_presence',
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            { path: 'entity', width: 150 },
            { path: 'object', width: 500 }
        ],
        form: [
            { path: "code", writable: false},
            { path: 'object', writable: false },
            { path: 'date', writable: false },
            'startTime',
            'endTime',
            'place',
            { path: 'expectedParticipants', writable: false },
            'presentParticipants',
            { path: 'expectedGuests', writable: false },
            {
                path: 'presentGuests',
                placeholder: 'alexia.dupont@gmail.com,jdoe@gmail.com,...'
            },
            { path: 'observation', type: "richTextEditor"},
            'comments'
        ]
    },
    filters:['userHasHabilitationForEntity', 'isToday', 'meetingInProgress']
}

export const synthesis = {
    name: 'synthesis',
    object: 'Meeting',
    category: 'manageTheMeeting',
    tKey: 'mTitle_synthesis',
    removable: false,
    newable: false,
    defaultSortBy: "date",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            { path: 'entity', width: 150 },
            { path: "object", width: 500 },
            { path: 'date', width: 150 },
            { path: 'meetingStatus', display: "tKey", translate: true, width: 150 },
            "totalNumberOfQuestions",
            "totalNumberOfQuestionsInProgress",
            "totalNumberOfQuestionsTreated",
            "totalNumberOfQuestionsNotTreated",
            { path: "totalNumberOfQuestionsAbandoned", initiallyNotVisible: true },
            { path: "totalNumberOfQuestionsPostponed", initiallyNotVisible: true }

        ],
        form: [
            { path: 'code', writable: false},
            { path: 'object', type: 'textarea', writable: false},
            { path: 'date', required: true , writable: false},

            { path: 'startTime', writable: false },
            { path: 'endTime', writable: false },
            { path: 'place', writable: false },

            { path: 'presentParticipants', writable: false, required: false },
            {
                path: 'presentGuests',
                type: "textarea",
                writable: false
            },
            { path: 'synthesis', type: "richTextEditor"},
            { path: 'meetingStatus', display: "tKey", translate: true, required: true },
            {path: 'sendReport', tKey:"sendMail"},
            'comments',
            'files'

        ]
    },
    filters:["userHasHabilitationForEntity", "pastDated", "byMeetingStatus"]
}
