import React from 'react'
import { Field } from 'redux-form'
import LabeledCheckboxField from "../../../../components/Form/LabeledCheckboxField";
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input: {value, onChange}, language, label, subtitle, editable, disabled, t}) =>  {
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)
    return <LabeledCheckboxField label={translatedLabel} subtitle={translatedSubtitle} value={value} onChange={onChange} disabled={disabled || !editable} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormLabeledCheckboxField = ({ field, t, language, objectMode }) => {
    return (
        <Field
            name={field.path}
            label={field.label}
            subtitle={field.subtitle}
            language={language}
            component={Input}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            disabled={field.disabled}
            t={t}
        />
    )
}

export default FormLabeledCheckboxField
