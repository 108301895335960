import { kpFetch } from '../restClient'
import { batchActions } from 'redux-batched-actions'
import i18n from "../utils/i18nClient";

export const CALL_API = '@@api/CALL_API'
export const CALL_APIS_PARALLEL = '@@api/CALL_APIS_PARALLEL'

const actionWith = (action, data) => {
    const finalAction = { ...action, ...data }
    delete finalAction[CALL_API]
    return finalAction
}

const requestAction = action => {
    const { types } = action[CALL_API]
    const [requestType, ,] = types

    return actionWith(action, { type: requestType })
}

const successAction = t => (action, response) => {
    const { types, transpose } = action[CALL_API]
    const [, successType] = types

    if (transpose && typeof transpose !== 'function') {
        throw new Error('Expected transpose to be a function')
    }

    return actionWith(action, {
        meta: {t},
        data: transpose ? transpose(response.json) : response.json,
        type: successType
    })
}

const errorAction = t => (action, response) => {
    const { types } = action[CALL_API]
    const [, , failureType] = types

    const errorProps = {
        type: failureType,
        error: response.message || 'Something bad happened',
        status: response.status
    }
    errorProps.meta = { ...action.meta, t }

    if (response.status === 401) {
        errorProps.meta = { ...action.meta, unauthorized: true }
    }

    return actionWith(action, errorProps)
}

const callApi = (store, action, instructions) => {
    const { method, url, responseType, params } = instructions

    const state = store.getState()

    const t = i18n.getFixedT(state.ui.language, "platform")

    return kpFetch(method, url, responseType, params).then(
        response => {
            const actionGenerator = response.ok ? successAction(t) : errorAction(t)
            return actionGenerator(action, response)
        },
        error =>
            Promise.reject({
                data: t("thisDoesNotWork"),
                error: error
            })
    )
}

function one(store, action, next) {
    next(requestAction(action))

    const instructions = action[CALL_API]

    return callApi(store, action, instructions).then(nextAction => {
        next(nextAction)
        return nextAction
    })
}

export const BATCHED_API_REQUEST = 'BATCHED_API_REQUEST'
export const BATCHED_API_SUCCESS = 'BATCHED_API_SUCCESS'
export const BATCHED_API_ERROR = 'BATCHED_API_ERROR'

const parallel = (store, actions, next) => {
    if (!actions.every(action => action[CALL_API])) {
        throw new Error('Parallel action should contain only api actions')
    }

    const requestActions = actions.map(requestAction)

    next(batchActions(requestActions, BATCHED_API_REQUEST))

    return Promise.all(
        actions.map(action =>
            callApi(store, action, action[CALL_API]).then(nextAction => {
                nextAction.error
                    ? next({
                          ...batchActions([nextAction], BATCHED_API_ERROR),
                          meta: { unauthorized: nextAction.status === 401 }
                      })
                    : next(batchActions([nextAction], BATCHED_API_SUCCESS))

                return nextAction
            })
        )
    ).then(nextActions => nextActions)
}

export const apiMiddleware = store => next => action => {
    const callAPI = action[CALL_API]
    const callParallel = action[CALL_APIS_PARALLEL]

    if (typeof callParallel !== 'undefined') {
        return parallel(store, callParallel, next)
    } else if (typeof callAPI !== 'undefined') {
        return one(store, action, next)
    } else {
        return next(action)
    }
}

export default apiMiddleware
