import { createStore, applyMiddleware, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import api from '../../middleware/api'
import errorMiddleware from '../../middleware/error'
import loginRedirect from '../../middleware/loginRedirect'
import notificationsMiddleware from "../../middleware/notifications";

export default function(name, reducer) {

    // if (process.env.NODE_ENV !== "production") {
    //     middlewares.push(createLogger())
    // }

    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name,
                actionsBlacklist: ['REDUX_STORAGE_SAVE']
            })
            : compose

    return createStore(
        enableBatching(reducer),
        composeEnhancers(
            applyMiddleware(...[thunk, api, loginRedirect, errorMiddleware, notificationsMiddleware])
        )
    )
}
