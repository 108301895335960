import { createSelector } from 'reselect'

export const chainChildSelectors = (childStateSelector, childSelectors) =>
    Object.keys(childSelectors).reduce(
        (acc, selectorName) =>
            Object.assign(acc, {
                [selectorName]: createSelector(
                    childStateSelector,
                    childSelectors[selectorName]
                )
            }),
        {}
    )
