const _ = require('lodash')

export default [
    {
        name: 'CompensationMode',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'fixed', name: 'Fixe' },
            { id: 'variable', name: 'Variable' },
            { id: 'actions', name: 'Actions' }
        ]
    },
    {
        name: 'ResourceType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'backOffice', name: 'Back office' },
            { id: 'shortMission', name: 'Missions courtes durées' },
            { id: 'expatriates', name: 'Expatriés' },
            {
                id: 'localInternalResources',
                name: 'Ressources internes locales'
            },
            { id: 'subcontractors', name: 'Sous-traitants' }
        ]
    },
    {
        name: 'SecurityLevel',
        type: 'static',
        fields: [{ path: 'id', type: 'integer' }, 'name'],
        objects: [
            { id: 1, name: 'Vert' },
            { id: 2, name: 'Jaune' },
            { id: 3, name: 'Rouge' },
            { id: 4, name: 'Marron' }
        ]
    },
    {
        name: 'Risk',
        type: 'static',
        fields: [{ path: 'id', type: 'integer' }, 'name'],
        objects: [
            { id: 1, name: 'Financier' },
            { id: 2, name: 'Planning' },
            { id: 3, name: 'Qualité' }
        ]
    },
    {
        name: 'Status',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'green', name: 'Vert' },
            { id: 'blue', name: 'Bleu' },
            { id: 'red', name: 'Rouge' }
        ]
    },
    {
        name: 'CustomerRelation',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'prospect', name: 'Prospect' },
            { id: 'customer', name: 'Client' },
            { id: 'loyalCustomer', name: 'Client fidèle' }
        ]
    },
    {
        name: 'CompetitorQualification',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'high', name: 'Forte' },
            { id: 'medium', name: 'Moyenne' },
            { id: 'low', name: 'Faible' }
        ]
    },
    {
        name: 'Opportunity',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'finantialOpportunity', name: 'Financière' },
            { id: 'imageOpportunity', name: 'Image' },
            { id: 'technologicalOpportunity', name: 'Technologique' }
        ]
    },
    {
        name: 'ProjectFunding',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ownSupply', name: 'Fonds propres' },
            { id: 'afd', name: 'AFD' },
            { id: 'wb', name: 'WB' },
            { id: 'bid', name: 'BID' },
            { id: 'adb', name: 'ADB' },
            { id: 'others', name: 'Autres' }
        ]
    },
    {
        name: 'ProjectNature',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'sale', name: '1 - Contrat' },
            { id: 'offSale', name: '2 - Services partagés' },
            { id: 'fiscal', name: '3 - Fiscal' }
        ]
    },
    {
        name: 'SummariesUnit',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', key: 'turnover', name: 'CA (k€)' },
            { id: '2', key: 'expenses', name: 'Coût (k€)' },
            { id: '3', key: 'numberOfDays', name: 'Nbj' }
        ]
    },
    {
        name: 'MarginOverContract',
        type: 'static',
        fields: [
            { path: 'id', type: 'integer' },
            {
                path: 'name',
                f: function() {
                    return `≥${this.id}%`
                }
            }
        ],
        objects: [
            { id: 10, name: '≥10%' },
            { id: 20, name: '≥20%' },
            { id: 30, name: '≥30%' },
            { id: 40, name: '≥40%' }
        ]
    },
    {
        name: 'Year',
        type: 'static',
        fields: [
            { path: 'id', type: 'integer' },
            {
                path: 'name',
                f: function() {
                    return `${this.id}`
                }
            }
        ],
        objects: _.range(2000, 2026).map(year => ({
            id: year,
            name: `${year}`
        })),
        sorters: [
            {
                name: 'byDescYear',
                query: () => ({ _id: -1 })
            }
        ]
    },
    {
        name: 'InterventionMode',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'consultancy', name: 'Consultance' },
            { id: 'projectManagement', name: 'Gestion de projet' },
            { id: 'performanceContract', name: 'Contrat de performance' }
        ]
    },
    {
        name: 'ResponseMode',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ace', name: 'Avenant contrat' },
            { id: 'ami', name: 'Appel à manifestation d’intérêt' },
            { id: 'apd', name: 'Appel d’offre' },
            { id: 'grg', name: 'Gré à gré' }
        ]
    },
    {
        name: 'ContractualSchema',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            {
                id: 'directWithoutSubcontractor',
                name: 'Direct sans sous-traitant'
            },
            {
                id: 'directWithSubcontractor',
                name: 'Direct avec sous-traitant'
            },
            { id: 'subcontractor', name: 'Sous-traitant' },
            { id: 'consortium', name: 'Consortium' }
        ]
    },

    {
        name: 'BusinessActivity',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'prospection', name: 'Prospection' },
            { id: 'buildOffer', name: 'Montage d’offre' },
            { id: 'realisation', name: 'Réalisation' },
            { id: 'others', name: 'Autres' },
            { id: 'corporate', name: 'Corporate' }
        ]
    },
    {
        name: 'ProjectSalesCostVolumeUnit',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', key: 'franceNb', name: 'France' },
            { id: '2', key: 'foreignNb', name: 'Extérieur' },
            { id: '3', key: 'perdiemNb', name: 'Perdiem' },
            { id: '4', key: 'transportNb', name: 'A/R' },
            { id: '5', key: 'productivityNb', name: 'Productivité' },
            { id: '6', key: 'quantityNb', name: 'Unité' }
        ]
    },
    {
        name: 'OtherOperatingCostVolumeUnit',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [{ id: '1', key: 'number', name: 'Unité' }]
    },
    {
        name: 'ProjectSalesCostUnitOfWork',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'hr', name: 'Ressources humaines' },
            { id: 'licences', name: 'Licences' },
            { id: 'equipment', name: 'Matériels - Equipement' },
            { id: 'image', name: 'Image' },
            { id: 'orthes', name: 'Autres' }
        ]
    },
    {
        name: 'OtherOperatingCostUnitOfWork',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'local', name: 'Locaux' },
            { id: 'administrative', name: 'Administratif' },
            { id: 'juridical', name: 'Juridique' },
            { id: 'comercial', name: 'Commercial' },
            { id: 'localhr', name: 'RH local' },
            { id: 'orthes', name: 'Autres' }
        ]
    },
    {
        name: 'OtherFiscalCostBase',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'revenues', name: 'Chiffre d’affaires' },
            { id: 'contractExpenses', name: 'Charges sur contrat' },
            { id: 'contractMargin', name: 'Marge sur contrat' },
            { id: 'chargesStructure', name: 'Charges Structure & Dvp' },
            { id: 'operatingIncome', name: 'Résultat Opérationnel' }
        ]
    },
    {
        name: 'ProviderRelation',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'competitor', name: 'Concurrent' },
            { id: 'partner', name: 'Partenaire' },
            { id: 'subcontractor', name: 'Sous-traitant' },
            { id: 'contractsOperator', name: 'Intervenant Contrat Op/Pr' }
        ]
    },
    {
        name: 'OriginOfTheCase',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'client', name: 'Client' },
            { id: 'prospection', name: 'Prospection' },
            { id: 'edfdi', name: 'EDF DI' },
            { id: 'partner', name: 'Partenaire' },
            { id: 'lessor', name: 'Bailleur' },
            { id: 'others', name: 'Autres' }
        ]
    },
    {
        name: 'BusinessProjectResult',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [{ id: 'won', name: 'Gagné' }, { id: 'lost', name: 'Perdu' }]
    },
    {
        name: 'ProfileType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'operator', name: 'Opérateur' },
            { id: 'technician', name: 'Technicien' },
            { id: 'engineer', name: 'Ingénieur' },
            { id: 'director', name: 'Directeur' }
        ]
    },
    {
        name: 'ContractType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'VTE', name: 'VTE - Contrat de vente' },
            { id: 'ACH', name: "ACH - Contrat d'achat" },
            { id: 'FRN', name: 'FRN - Contrat fournisseur' },
            { id: 'DVP', name: 'DVP - Contrat de développement' }
        ]
    },
    {
        name: 'CountryType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'target', name: 'Cible' },
            { id: 'core', name: 'Coeur' },
            { id: 'interest', name: 'Intérêt' },
            { id: 'historical', name: 'Historique' },
            { id: 'opportunity', name: 'Opportunité' },
            { id: 'priority', name: 'Prioritaire' }
        ]
    },
    {
        name: 'ContractState',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0000001', name: 'Signé par toutes les parties' },
            { id: '0000002', name: 'Signé par la partie prenante' },
            { id: '0000003', name: 'Signé par EDF IN' },
            { id: '0000004', name: 'Non signé' }
        ]
    },
    {
        name: 'ContractStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'abandoned', name: 'Abandonné' },
            { id: 'active', name: 'Actif' },
            { id: 'finished', name: 'Terminé' }
        ]
    },
    {
        name: 'ContractVersion',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'draft', name: 'DRAFT' },
            { id: 'original', name: 'ORIGINAL' },
            { id: 'scan', name: 'SCAN' }
        ]
    },
    {
        name: 'ProviderOrigin',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'enedis', name: 'ENEDIS' },
            { id: 'retirement', name: 'RETRAITE' },
            { id: 'other', name: 'AUTRE' }
        ]
    },
    {
        name: 'TeamReferent',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'technicalReferent', name: 'Référent technique' },
            { id: 'commercialReferent', name: 'Référent commercial' }
        ]
    },
    {
        name: 'ProjectStep',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'formX', name: 'Détection' },
            { id: 'buildOffer', name: 'Montage' },
            { id: 'submitOffer', name: 'Contractualisation' },
            { id: 'realisation', name: 'Réalisation' },
            { id: 'finished', name: 'terminé' },
            { id: 'acceptedAmi', name: 'AMI accepté' },
            { id: 'noGo', name: 'no go' },
            { id: 'notSubmitted', name: 'offre non remise' },
            { id: 'abandoned', name: 'refused' },
            { id: 'lost', name: 'perdu' }
        ]
    },
    {
        name: 'ActiveProjectStep',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'formX', name: 'Détection' },
            { id: 'buildOffer', name: 'Montage' },
            { id: 'submitOffer', name: 'Contractualisation' },
            { id: 'realisation', name: 'Réalisation' }
        ]
    }
]
