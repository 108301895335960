const _ = require('lodash');
const moment = require('moment')

async function findData(context, callback) {

    const typeOfOfferProjectCollectionName = global.app.I.TypeOfOffer.collectionName
    const rangeOfOfferProjectCollectionName = global.app.I.RangeOfOffer.collectionName
    const businessProjectCollection = global.app.I.BusinessProject.collection
    const year = moment().year()
    const businessProjectQuery = businessProjectCollection
        .aggregate([
            {
                $match: {effectiveOfferSubmissionDate: {$ne: null}}
            },
            {
                $project:{
                    offerSubmissionYear: {$year: '$effectiveOfferSubmissionDate'},
                    typeOfOffer: 1,
                    'financialStatement.turnOver.total': 1,
                    estimatedTurnover:1
                }
            },
            {
                $match: {offerSubmissionYear: year}
            },
            {
                $lookup: {
                    from: typeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $unwind: '$rangeOfOffer'
            }
        ])
        .toArray()

    const [businessProjects] = await Promise.all([
        businessProjectQuery
    ])


    const formatedBusinessProjects = businessProjects.map(o => {
        const fn = _.get(o, 'financialStatement.turnOver.total')
        return {
            rangeOfOffer: o.rangeOfOffer.code,
            estimatedTurnover: fn ? fn : o.estimatedTurnover
        }
    })
    const dataByRangeOfOffer = _.groupBy(formatedBusinessProjects, 'rangeOfOffer')

    const data = _.sortBy(Object.keys(dataByRangeOfOffer)).map(rangeOfOffer => ({
        axis: rangeOfOffer,
        amount: _.round(_.sumBy(dataByRangeOfOffer[rangeOfOffer], 'estimatedTurnover'), 1),
        number: dataByRangeOfOffer[rangeOfOffer].length
    }))

    const total = _.reduce(data, (acc, o) => {
        return {
            amount: acc.amount + o.amount,
            number: acc.number + o.number
        }
    }, {amount: 0, number: 0})

    return [
        {
            id: "offerSubmissionPerProductLine",
            subTitle: {
                text: 'offerSubmissionSubtitle',
                data: {
                    year: year,
                    amount: _.round(total.amount, 1),
                    number: total.number
                }
            },
            data
        }
    ]
}

export const entity = {
    name: 'OfferSubmissionPerProductLine',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
