import React from 'react'
import classnames from 'classnames'
import './NotificationBubble.css'

const NotificationBubble = ({count, active}) => {
    return <span className={classnames("NotificationBubble", {active})}> {count} </span>

}

export default NotificationBubble
