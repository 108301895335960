import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {
    changeFieldDefault,
    changeFieldDisabled, changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE,
    setFieldVisibility,
    setFormButtons
} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import _ from "lodash";
import {
    arbitrateButton,
    delegateButton,
    draftButton,
    returnButton,
    transmitButton,
    validateDemandeurButton, warnAndValidateButton
} from "../../utils/demandUtils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    object: 'Demand',
    name: 'DemandControl',
    category: {
        path: 'demande',
    },
    tKey: 'Contrôle',
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    newable: false,
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° Demande'},
            {path: 'relatedDemand', tKey: 'demande principale', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'relatedProject', tKey: 'projet', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                    //hidden: true,
                    //default: {id:'1'},
                    //display: 'name2'
                },
                {path: 'demandNumber',tKey: 'N° Demande', disabled: true},
                {path: 'relatedProject', tKey: 'projet', display: 'demandNumberAndTitle', disabled: true, filters: ['isProject', 'approvedOrInRealization']},
                {path: 'relatedDemand', tKey: 'demande principale', display: 'demandNumberAndTitle', disabled: true},
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    disabled: true,
                    //editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            if (newValue) {
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-SE-demandControl.Imputation_organizationalMesh",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                organization : newValue?.id // newValue? newValue.organizationalAxis : null
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    fieldPath: ['id', 'code', 'name'],
                    disabled: true,
                    translateName: true,
                },
                {
                    path: 'title',
                    required: true,
                    disabled: true
                },
                {path: 'description', type: 'richTextEditor', required: true, disabled: true,},
                {path: 'enjeux', type: 'richTextEditor', required: true, disabled: true,},
                {path: 'objectifs', type: 'richTextEditor', required: true, disabled: true,},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    disabled: true,
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    disabled: true,
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    disabled: true,
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            //'budget'
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant (k)'},
                        ],
                        form: {
                            fields : [
                                { path: 'objet', required: true, unique: true},
                                {path: 'budget', display: 'budgetDisplayName'},
                                {path: 'organizationalMesh', tKey: 'business unit', filters:['byOrganizationOrMesh']},
                                { path: 'imputationType', required: true, translateName: true},
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            if (newValue){
                                                let total = 0
                                                newValue.forEach(object => {
                                                    total+= parseFloat(object.amount)
                                                })
                                                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                                                const totalAmountPerImputationField = imputationsField.viewMap.form.fields.find( field => field.path === 'totalAmountPerImputation')
                                                totalAmountPerImputationField.setValue(total)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea'},
                            ]
                        }
                    },
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.totalAmountPerImputation)
                                })
                                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                                totalAmountField.setValue(total)
                            }
                        }
                    }
                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true,
                },
                {
                    path: 'plafond',
                    tKey: "financialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                    disabled: true
                    //filters: ['byAmount']
                },
                {
                    path: 'comments',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                            if ( newValue && oldValue && newValue.length !== oldValue.length ) {
                                let commentsCopy = _.cloneDeep(newValue)
                                const commentsSorted = commentsCopy.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss'))
                                const lastComment = commentsSorted[commentsSorted.length-1]
                                lastComment.date = moment().format("YYYY-MM-DD HH:mm:ss")
                                const comments = commentsSorted.slice(0, newValue.length - 1)
                                comments.push(lastComment)
                                commentsField.setValue(comments)
                            }
                        }
                    }
                },
                'files',
                {
                    path: 'delegateToCheckBox',
                    tKey: 'je souhaite déléguer ma décision',
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const delegateToField = module.viewMap.form.fields.find(field=>field.path==='delegateTo')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(delegateToField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    store.dispatch(setFormButtons([delegateButton, returnButton]))
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(delegateToField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path: 'delegateTo',
                    filters: [ 'isInGroupModel', 'isNotCurrentContributor' ],
                    clearable: false
                },
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {
                    path: 'arbitrateCheckBox',
                    tKey: `je souhaite demander un arbitrage`,
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const arbitrateField = module.viewMap.form.fields.find(field=>field.path==='arbitrateText')
                            const arbitrationTypeField = module.viewMap.form.fields.find(field=>field.path==='arbitrationType')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            const arbitrationType = arbitrationTypeField.getValue()
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(arbitrateField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    if ( arbitrationType && arbitrationType.id === "1" ){
                                        store.dispatch(setFormButtons([arbitrateButton, returnButton]))
                                    }
                                    else if ( arbitrationType && arbitrationType.id === "2"){
                                        store.dispatch(setFormButtons([warnAndValidateButton, returnButton]))
                                    }
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(arbitrateField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path : 'arbitrateText',
                    tKey: `justification`,
                    type: 'richTextEditor'
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },
                {path: 'buttons', hidden: true, default: [draftButton, validateDemandeurButton, transmitButton, returnButton]},
                {
                    path: 'arbitrateTextList',
                    //fieldPath:['step', 'order'],
                    //hidden: true
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            {path: 'user', display: 'name'
                            },
                            'step',
                            'order',
                            "arbitrationType",
                            "date"
                        ],
                        form: [
                            {
                                path: 'user',
                                display: 'name',
                            },
                            'step',
                            'order',
                            {path: 'motif', type: 'richTextEditor'},
                            "arbitrationType",
                            "date"
                        ]
                    },
                    hidden: true
                },
                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'category',
                    hidden: true
                }
            ],
            dataLists: [
                "DemandControl-Currency",
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const demandNumberField = module.viewMap.form.fields.find( field => field.path === 'demandNumber')
                const arbitrateCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'arbitrateCheckBox')
                const arbitrationTypeField = module.viewMap.form.fields.find( field => field.path === 'arbitrationType')
                const delegationOptionField = module.viewMap.form.fields.find( field => field.path === 'delegationOption')
                const delegateToCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'delegateToCheckBox')
                const statusField = module.viewMap.form.fields.find( field => field.path === 'status')

                const arbitrationType = arbitrationTypeField.getValue()
                const hasNoDelegation = delegationOptionField.getValue() && delegationOptionField.getValue().id === "1"

                const organizationAndMeshField = module.viewMap.form.fields.find( field => field.path === 'organizationAndMesh')
                const contributorsFunctionsField = module.viewMap.form.fields.find( field => field.path === 'contributorsFunctions')
                const demandOrganization = organizationAndMeshField.getValue()
                const contributorsFunctions = contributorsFunctionsField.getValue()

                const demandCategoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')
                store.dispatch(setFieldVisibility(demandCategoryField.id, false))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-demandControl.Demand_delegateTo",
                        store.getState,
                        'form',
                        {
                            data: {
                                demandOrganization : demandOrganization,
                                contributorsFunctions : contributorsFunctions
                            }
                        }
                    )
                )

                delegateToCheckBoxField.setValue(false)
                arbitrateCheckBoxField.setValue(false)

                store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, !hasNoDelegation))
                store.dispatch(setFieldVisibility(arbitrateCheckBoxField.id, arbitrationType))
                store.dispatch(setFieldVisibility(statusField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(demandNumberField.id, objectMode !== 'newObject'))

                const referenceCurrencies = getDataListList(state, "DemandControl-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_totalAmount", 'tKey', `${t("plannedTotalAmount")} (k${referenceCurrency.symbol})`))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "DemandControl-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `total (k${referenceCurrency.symbol})`))
                }
            }
        },
    ],
    accesses: [
        {
            id: "DemandControl-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ["byModuleStep"]
}
