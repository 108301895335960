import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {EyeOff, Eye, Info} from 'react-feather'
import logoKP from '../img/keenpoint-logo-horizontal.png'
import logoCNDA from '../img/logo_cnda.svg'
/*
import logoRTE from '../img/logo_RTE.png'
import logoHermes from '../img/logo_Hermes.png'
import logoEDF from '../img/logo_EDF.png'
import logoCELIO from '../img/logo_CELIO.png'
import logoUAPL from '../img/logo_uapl.png'
import logoCSE from '../img/logo_mycsemanco.png'
 */
import { toastr } from 'react-redux-toastr'
import './login.css'
import {ProgressBar} from './ProgressBar/ProgressBar'
import {WarningBanner} from './Banner'
import HoverPopover from './HoverPopover'
import { STRONG_PASS, MEDIUM_PASS, WEAK_PASS } from '../utils/regExps'
import SpinningDots from "./Loader/SpinningDots";
import DynamicStyledInput from "./Form/DynamicStyledInput";

const passwordStrengthColors = {
    1: '#fd3333c7',
    2: 'orange',
    3: '#53a552'
}

const getLogo = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'rte':
        case 'hermes':
        case 'thom':
        case 'preprod':
            return logoKP
        case 'cnda':
            return logoCNDA
        default:
            return
    }
}

const getLogoStyle = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'thom':
        case 'rte':
        case 'hermes':
            return {width: '200px'}
        case 'cnda':
            return {height: '200px'}
        default:
            return {width: '200px'}
    }
}

class Reset extends Component {
    constructor() {
        super()

        this.state = {
            password: '',
            passwordConfirmation: '',
            showPass: false,
            showPassConf: false,
            score: 0
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.passwordStrength = this.passwordStrength.bind(this)
    }

    handleChange(field, event) {
        const newValue = event.target.value
        this.setState({
            [field]: newValue
        })
        if(field === 'password') {
            this.passwordStrength(event)
        }
    }

    passwordStrength(e){
        const pwd = e.target.value

        const strongRegex = new RegExp(STRONG_PASS, "g")
        const mediumRegex = new RegExp(MEDIUM_PASS, "g")
        const enoughRegex = new RegExp(WEAK_PASS, "g")

        if(strongRegex.test(pwd)) {
            this.setState({score: 3})
        } else if(mediumRegex.test(pwd)){
            this.setState({score: 2})
        } else if(enoughRegex.test(pwd)) {
            this.setState({score: 1})
        } else this.setState({score: 0})
    }

    handleSubmit(event) {
        event.preventDefault()
        const { handleSubmit, t } = this.props
        if (!this.state.password) {
            toastr.error(
                t('error'),
                t("notEmptyPasswordField")
            )
        } else if (this.state.score < 3) {
            toastr.error(
                t('error'),
                t("weakPassword")
            )
        } else if (this.state.password === this.state.passwordConfirmation) {
            handleSubmit && handleSubmit(this.state.password)
        } else {
            toastr.error(
                t('error'),
                t("passwordConfirmationDoesNotMatchPassword")
            )
        }
    }

    render() {
        const {error, t, loading, client} = this.props
        console.log('client', client)
        return (
            <div className="Login-container">
                <div className="Login-form-container">
                    <div className="Login-logo-container">
                        <img
                            alt="Login-logo"
                            src={getLogo(client)}
                            style={getLogoStyle(client)}
                        />
                    </div>
                    <div className= "Login-well">
                        {
                            loading
                                ? <SpinningDots/>
                                : error
                                    ? <WarningBanner message={t(error)}/>
                                    : (
                                        <form onSubmit={this.handleSubmit}>
                                            <div style={{textAlign: 'left', margin: "20px 0"}}>
                                                {t('passwordResetIntro')}
                                            </div>
                                            <div style={{position: 'relative'}}>
                                                <DynamicStyledInput
                                                    id="ResetForm-password"
                                                    path="newPassword"
                                                    type={this.state.showPass ? "text" : "password"}
                                                    value={this.state.password}
                                                    onChange={event => this.handleChange("password", event)}
                                                    t={t}
                                                />
                                                <span onClick={() => this.setState({showPass: !this.state.showPass})}
                                                      className="Login-eye-icon">
                                                {
                                                    this.state.showPass
                                                        ? <EyeOff size={17}/>
                                                        : <Eye size={17}/>
                                                }
                                            </span>
                                            </div>
                                            <div style={{margin: '0 5px', display: 'flex', alignItems: 'center'}}>
                                                <ProgressBar percentage={(1000 * this.state.score) / 30}
                                                             color={passwordStrengthColors[this.state.score]}/>
                                                <HoverPopover id={'passwordStrength'} message={this.props.t("weakPassword")}
                                                              placement={"right"}>
                                                    <Info size={14}
                                                          style={{margin: '1px', color: '#525252', cursor: 'pointer'}}/>
                                                </HoverPopover>
                                            </div>
                                            <br/>
                                            <div style={{position: 'relative'}}>
                                                <DynamicStyledInput
                                                    id="ResetForm-passwordConfirmation"
                                                    path="newPasswordConfirmation"
                                                    type={this.state.showPassConf ? "text" : "password"}
                                                    value={this.state.passwordConfirmation}
                                                    onChange={event => this.handleChange("passwordConfirmation", event)}
                                                    t={t}
                                                />
                                                <span
                                                    onClick={() => this.setState({showPassConf: !this.state.showPassConf})}
                                                    className="Login-eye-icon">
                                                {
                                                    this.state.showPassConf
                                                        ? <EyeOff size={17}/>
                                                        : <Eye size={17}/>
                                                }
                                            </span>
                                            </div>
                                            <br/>
                                            <div className='Login-buttons'>
                                                <button type="submit" className="btn Reset-pass-submit-button"
                                                        value="submit">
                                                    {t("initializePassword")}
                                                </button>
                                            </div>
                                        </form>
                                    )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Reset.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Reset

