const _ = require('lodash')

export default {
    id: "FT",
    name: "training",
    description: "",
    languages: ['fr'],
    entities: _.concat(
        require('./rte/staticEntities').default,
        require('./rte/referential/formation').entities,
        [
            require('./rte/referential/category').entity,
            require('./rte/referential/objective').entity,
            require('./rte/referential/collaborator').entity,
            require('./rte/sessions/organize').entity,
            require('./rte/sessions/synthesis').entity,
            require('./rte/analysis/scoring').entity,
        ]
    ),
    modules: [
        require('./rte/referential/category').module_,
        require('./rte/referential/objective').module_,
        require('./rte/referential/formation').module_,
        require('./rte/referential/collaborator').module_,
        require('./rte/sessions/organize').module_,
        require('./rte/sessions/synthesis').module_,
        require('./rte/sessions/close').module_,
        require('./rte/analysis/scoring').module_
    ]
};
