const moment = require('moment')
const _ = require('lodash')
const { parse } = require('json2csv')
const { basicContext } = require("../../utils/contextUtils")

const todayISO = moment().format("YYYY-MM-DD");
const startOfYearISO = moment().startOf('year').format("YYYY-MM-DD")

export const entity = {
    name: 'EntriesExtraction',
    facets: [{name: 'files', linkType: 'OTO'}],
    fields: [
        {path: 'processes', type: 'Process', link: 'MTM'},
        {
            path: 'processesNames',
            fieldPath: ['processes.id'],
            f: function() {
                return (this.processes || []).map(process => process.id).join(' , ')
            }
        },
        {path: 'period', type: 'dateRange'},
        'creationDate'
    ],
    beforeSave: (object, oldObject, context, callback) => {

        object.creationDate = moment().format("YYYY-MM-DD HH:mm")

        const firstDate = moment(object.period[0]).format('YYYY-MM-DD')
        const secondDate = moment(object.period[1]).format('YYYY-MM-DD')

        const fileName = `Ecritures-${firstDate}_${secondDate}`

        const query = {
            recordType: {$in: object.processes.map(process => process.id) },
            date: {
                $gte: firstDate,
                $lte: secondDate
            },
            transmissionDate: { $exists: false }
        }

        global.app.C.AccountingEntries.find({
                ...basicContext(context),
                query
            },
            (error, entries) => {
                if(error) callback(error)
                else {
                    const fields = [
                        'type',
                        'date',
                        'journal',
                        'generalAccount',
                        'auxiliaryAccount',
                        'direction',
                        'amount',
                        'wording',
                        'reference',
                        'analyticSection'
                    ];
                    const opts = { fields, header: false, quote: '', delimiter: ';' }
                    const data = entries.map(o => ({
                        ...o,
                        amount: parseFloat(o.amount),
                        date:  moment(o.date).format('DDMMYYYY')
                    }))
                    const csvData = parse(data, opts)

                    global.csv.generateCsv(fileName, csvData, (err, file) => {
                        if(err) callback(err)
                        else {

                            global.app.C.AccountingEntries.collection.updateMany(
                                query,
                                {
                                    $set: {
                                        transmissionDate: moment().format('YYYY-MM-DD'),
                                        fileName: file.filename
                                    }
                                },
                                err => {
                                    if(err) callback(err)
                                    else {
                                        object.file = {
                                            ...file,
                                            user: _.get(context, "user.name", "unknown user"),
                                            date: moment().format("YYYY-MM-DD HH:mm")
                                        }
                                        callback(null, object, oldObject)
                                    }
                                }
                            )
                        }
                    })
                }
            }
        )
    }
}

export const module_ = {
    object: 'EntriesExtraction',
    tKey: 'mTitle_entriesExtraction',
    removable: false,
    updatable: false,
    objectIdentifier: 'processesNames',
    defaultSortBy: 'creationDate',
    defaultSortDirection: 'DESC',
    category: {
        path: 'accounting',
        icon: 'book'
    },
    viewMap: {
        dt: [
            {path: 'processes', translate: true},
            'period',
            {path: 'creationDate', tKey: 'dateAndTime'},
            {path: 'file', width: 100},
        ],
        form: {
            fields: [
                {path: 'processes'},
                {
                    path: 'period',
                    editable: false,
                    default: [startOfYearISO, todayISO]
                },
                {path: 'processesNames', hidden: true},
            ],
            onOpen: ({module}) => {
                const processes = module.viewMap.form.fields.find(
                    field => field.path === 'processes'
                )
                processes.setValue([{id: 'refund'}, {id: 'invoice'}])
            }
        }
    }
}
