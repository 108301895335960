export const entities = [
    {
        name: 'LeaseData',
        fields: [
            'LeaseType',
            { path: 'withProject', type: 'boolean' },
            { path: 'openingDate', type: 'date', nullable: true },
            {
                type: 'GeneralCharacteristic',
                link: 'OTM'
            },
            {
                type: 'LeaseDataRenewal',
                link: {type: 'OTM', deleteType: 'cascade'}
            },
            {
                type: 'LeaseDataPeriod',
                link: {type: 'OTM', deleteType: 'cascade'}
            }
        ]
    },
    {
        name: 'GeneralCharacteristic',
        fields: [
            'GeneralCharacteristicType',
            { path: 'value', type: 'integer' }
        ]
    },
    {
        name: 'LeaseDataRenewal',
        fields: [
            { path: 'start', type: 'date', nullable: true},
            { path: 'end', type: 'date', nullable: true},
            'LeaseRenewal',
            { path: 'convenience', type: 'boolean' },
            { path: 'fixed', type: 'boolean' },
            { path: 'variable', type: 'boolean' },
            { path: 'minimumGuaranteed', type: 'boolean' },
            { path: 'capped', type: 'boolean' },
            { path: 'rentDeductible', type: 'integer' }
        ]
    },
    {
        name: 'LeaseDataPeriod',
        fields: [
            { path: 'start', type: 'date' },
            { path: 'end', type: 'date' },
            'LeaseCondition',
            'LeaseRenewal',
            { path: 'value', type: 'integer' }
        ]
    }
]
