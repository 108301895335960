const moment = require('moment')
const {setFieldVisibility} = require("../../../apps/KpModule/actions");
const {isUserK} = require("../../../apps/KpModule/selectors");

export const entities = [
    {
        name: 'AlertTypeModelPlatform',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            { path: 'name', type: 'string' }
        ],
        objects: [
            { id: 'warning', name: 'warning' },
            { id: 'info', name: 'information' },
            { id: 'custom', name: 'special' }
        ]
    },
    {
        name: 'AlertModelPlatform',
        fields: [
            { path: 'type', type: 'AlertTypeModelPlatform' },
            { type: "Profile", link: "MTM" },
            { path: 'active', type: 'boolean' },
            { path: 'blockAccess', type: 'boolean' },
            'message',
            { tKey: 'displayTime', path: 'length', type: 'integer' },
            'dateAndTime'
        ],
        beforeSave: (object, oldObject, context, callback) => {
            object.dateAndTime = moment().format('YYYY-MM-DD HH:mm')
            callback(null, object, oldObject)
        }
    }
]

export const modules = [
    {
        tKey: "m-T-Alert",
        object: 'AlertModelPlatform',
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'message',
        defaultSortBy: 'dateAndTime',
        defaultSortDirection: 'DESC',
        viewMap: {
            dt: [
                { path: 'message', type: 'translatedText' },
                { path: 'type', translate: true },
                { path: 'length', tKey: 'displayTime' },
                'profiles',
                'dateAndTime',
                { path: 'active', tKey: 'visible', width: 100 },
                { path: 'blockAccess', width: 100, initiallyNotVisible: true }
            ],
            form: {
                fields: [
                    { path: 'type', translate: true },
                    {path: 'profiles', type: 'tags', filters: ['thisModel']},
                    {
                        path: 'message',
                        type: 'textarea',
                        placeholder: 'fr:: message en français\nen:: message en Anglais\nnl:: message en Anglais'
                    },
                    { path: 'length', tKey: 'displayTime', default: 20 },
                    {path: 'active', tKey: 'visible'},
                    'blockAccess'
                ],
                onOpen: ({state, store}) => {
                    const userK = isUserK(state)
                    store.dispatch(setFieldVisibility(`e_blockAccess`, userK))
                }
            }
        }
    }
]
