import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import {getListOptions} from '../../selectors/index'
import ToggleSwitch from "../../../../components/Form/ToggleSwitch";
import getFormValidations from "./formValidations";

const Component = ({ input: {value, onChange}, meta:{touched, error}, selectOptions, display, required,disabled, t}) => {
    return (
        <ToggleSwitch
            options={selectOptions}
            value= {value}
            onChange={(option) => onChange(option)}
            display={display}
            required={required}
            disabled={disabled}
            touched={touched}
            error={error}
            t={t}
        />
    )
}

const Toggle = ({field, objectMode, options, t}) => {
    const validations = getFormValidations(field)
    return (
        <Field
            name={field.path}
            required={field.required}
            component={Component}
            selectOptions={options}
            display={field.display}
            disabled={field.disabled || ( !field.editable && objectMode !== 'newObject') }
            validate={validations}
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    options: getListOptions(state, field.id, field.sortList)
})

export default connect(mapStateToProps)(Toggle)
