import React from 'react'
import { Field } from 'redux-form'
import TextField from '../../../../components/Form/TextField'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input, meta, language, placeholder, label, subtitle, editable, disabled, minValue, maxValue, t, required }) => {

    const translatedError = t(meta.error)
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)

    return <TextField {...input} style={{margin: '10px 0 0 20px'}} type="number" min={minValue} max={maxValue} label={translatedLabel} subtitle={translatedSubtitle} touched={meta.touched} error={translatedError} required={required} disabled={disabled || !editable} placeholder={t(placeholder || 'typeSomething')} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormLabeledNumberField = ({ field, t, language, objectMode }) => {
    return (
        <Field
            name={field.path}
            field={field}
            label={field.label}
            required={field.required}
            subtitle={field.subtitle}
            minValue={field.minValue}
            maxValue={field.maxValue}
            component={Input}
            placeholder={field.placeholder}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            translate={field.translate}
            t={t}
            language={language}
        />
    )
}

export default FormLabeledNumberField
