import {translateName} from "../../../../utils/i18n";
import _ from "lodash";

export const entities = [
    {
        name: 'Imputation',
        fields:[
            // unique by objet-bu-imputationType for 1 project
            // when a project (principale) is validated, we create a analytical subAxis, we create codes with different objets
            // when a project (complementaire) is validated, we create codes with new and different objets
            {path: 'objet'},
            'OrganizationalMesh',
            'ImputationType',
            { path: 'description'},
            {type: 'Budget', nullable: true},
            'Currency',
            {path: 'totalAmountPerImputation', type: 'decimal'},
            //{path: 'exchangeRate', type: 'decimal'},
            {
                path: 'monthAndAmountList',
                type:'MonthAndAmountList',
                lazy: true,
                fieldPath: [
                    'id',
                    'month',
                    'amount',
                    'fiscalYear'
                ],
                link: {
                    type: "OTM",
                    onParent: true,     //parent est Imputation
                    onChild: false,     //child est MonthAndAmount
                }
            },
            {
                path:'imputationTriplet',
                fieldPath: ['objet', 'organizationalMesh.name', 'imputationType.name'],
                $f: (object, context, callback) => {
                    callback(null, object.objet + ' - ' + translateName(object.organizationalMesh.name,  _.get(context, "language.id")) + ' - ' + translateName(object.imputationType.name,  _.get(context, "language.id")) )
                },
            }
        ],
    },
    {
        name: 'MonthAndAmountList',
        list : true,
        fields: [
            {path: 'month'},
            {path: 'amount', type: 'decimal'},
            'FiscalYear'
        ],
    }
]


