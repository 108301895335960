import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import reducer from './reducers'
import createStore from './createStore'
import ModuleAccordion from './components/ModuleAccordion'
import { initializeModule } from './actions/module'
import {fetchBasicData, fetchFormObject} from './actions/api'
import { setLanguage, setDefaultEditObject, setObjectMode, setFormObject } from './actions'
const {getEditDefaultData, getModule, getObjectMode} = require('./selectors')
function changeModule(store, props) {
    /*
    * setups the store for a new module
    * it should be done here and not in the render function because
    * it changes the state and can lead to stability issues in React
    * */

    const { moduleId, user, groupModel, language } = props

    store.dispatch(initializeModule(moduleId, groupModel, user, language))
    store.dispatch(fetchBasicData())
    if(props.objectId) {
        store.dispatch(setObjectMode('editObject'))
        store.dispatch(fetchFormObject(props.objectId))
    } else if(props.defaultObject) {
        store.dispatch(setDefaultEditObject(props.defaultObject))

        const state = store.getState()
        store.dispatch(setObjectMode('newObject'))
        store.dispatch(setFormObject({...getEditDefaultData(state)}, getModule(state), getObjectMode(state)))
    }
}

class KpModule extends PureComponent {
    constructor(props) {
        super(props)

        this.store = createStore('KpModule', reducer, props)

        changeModule(this.store, props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.moduleId !== prevProps.moduleId) {
            changeModule(this.store, this.props)
        } else if (this.props.language !== prevProps.language) {
            this.store.dispatch(setLanguage(this.props.language))
        }
    }

    render() {
        return (
            <Provider store={this.store}>
                <ModuleAccordion {...this.props} />
            </Provider>
        )
    }
}

KpModule.propTypes = {
    user: PropTypes.object.isRequired,
    groupModel: PropTypes.object,
    moduleId: PropTypes.string.isRequired,
}

export default KpModule
