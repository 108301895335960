const _ = require('lodash');
const moment = require('moment')

async function findData(context, callback) {

    const businessProjectCollection = global.app.I.BusinessProject.collection
    const year = moment().year()
    const month = moment().month() +1
    const businessProjectYearQuery = businessProjectCollection
        .aggregate([
            {
                $match: {signatureDate: {$ne: null}}
            },
            {
                $project:{
                    signatureYear: {$year: '$signatureDate'},
                    country: 1,
                    'financialStatement.turnOver.total': 1,
                    estimatedTurnover:1
                }
            },
            {
                $match: {signatureYear: year}
            }
        ])
        .toArray()

    const businessProjectMonthQuery = businessProjectCollection
        .aggregate([
            {
                $match: {signatureDate: {$ne: null}}
            },
            {
                $project:{
                    signatureYear: {$year: '$signatureDate'},
                    signatureMonth: {$month: '$signatureDate'},
                    country: 1,
                    'financialStatement.turnOver.total': 1,
                    estimatedTurnover:1
                }
            },
            {
                $match: {
                    signatureYear: year,
                    signatureMonth: month
                }
            }
        ])
        .toArray()

    const [
        yearBusinessProjects,
        monthBusinessProjects
    ] = await Promise.all([
        businessProjectYearQuery,
        businessProjectMonthQuery
    ])


    const formatedBusinessProjectsYear = yearBusinessProjects.reduce((acc, o) => {
        const fn = _.get(o, 'financialStatement.turnOver.total')
        return {
            number: acc.number +1,
            amount: acc.amount + (fn ? fn : o.estimatedTurnover)
        }
    }, {amount: 0, number: 0})

    const formatedBusinessProjectsMonth = monthBusinessProjects.reduce((acc, o) => {
        const fn = _.get(o, 'financialStatement.turnOver.total')
        return {
            number: acc.number +1,
            amount: acc.amount + (fn ? fn : o.estimatedTurnover)
        }
    }, {amount: 0, number: 0})



    const data = [
        {
            axis: 'Cumulées',
            ...formatedBusinessProjectsYear
        },
        {
            axis: 'Mois en cours',
            ...formatedBusinessProjectsMonth
        }
    ]

    return [
        {
            id: "orderTakingGlobal",
            subTitle: {
                text: 'orderTakingGlobalSubtitle',
                data: {
                    year: year,
                    amount: formatedBusinessProjectsYear.amount,
                    number: formatedBusinessProjectsYear.number
                }
            },
            data
        }
    ]
}

export const entity = {
    name: 'OrderTakingGlobal',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
