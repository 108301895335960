export const entity = {
    name: 'SubsidiaryAssignment',
    fields: [
        { type: 'User', unique: true },
        {
            path: 'subsidiaries',
            type: 'Subsidiary',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'numberOfAssignedSubsidiary',
            fieldPath: ['subsidiaries.id'],
            f: function() {
                return this.subsidiaries ? this.subsidiaries.length : 0
            }
        }
    ]
}

export const module_ = {
    name: 'SubsidiaryAssignment',
    object: 'SubsidiaryAssignment',
    tKey: 'mTitle_subsidiaryAssignment',
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    category: {
        path: 'assignment',
        icon: 'user-check'
    },
    viewMap: {
        dt: ['user', 'numberOfAssignedSubsidiary'],
        form: [
            { path: 'user', editable: false },
            { path: 'subsidiaries',  tKey: 'subsidiary(s)', display: 'fullName', required: true }
        ]
    }
}
