import {
    INITIALIZE_MODULE,
    SET_EDIT_DEFAULT_OBJECT,
    SET_FORM_OBJECT,
    SET_DEFAULT_EDIT_OBJECT,
    EMPTY_EDIT_OBJECT,
    SET_FORM_BUTTONS,
    UPDATE_OBJECT,
    SET_CELL_START_DATE,
    SET_CELL_END_DATE
} from '../actions'
import {
    CREATE_OBJECT_REQUEST, CREATE_OBJECT_SUCCESS, GET_OBJECT_FAILURE, GET_OBJECT_REQUEST, GET_OBJECT_SUCCESS,
    SAVE_OBJECT_REQUEST, SAVE_OBJECT_SUCCESS
} from '../actions/api'
import { combineReducers } from 'redux'
import _ from 'lodash'

const defaultFormObjectState = {
    id: null,
    data: {},
    isFetching: false,
    isSaving: false
}

const formObject = (state=defaultFormObjectState, action) => {
    const isFormPanel = _.get(action, 'meta.module.defaultPanel') === 'form'
    switch (action.type) {
        case INITIALIZE_MODULE:
        case EMPTY_EDIT_OBJECT:
            return defaultFormObjectState
        case GET_OBJECT_FAILURE:
            return {
                id: null,
                data: {},
                isFetching: false
            }
        case GET_OBJECT_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case SET_FORM_OBJECT:
            return {
                ...state,
                data: action.object,
                isFetching: false
            }
        case GET_OBJECT_SUCCESS:
            return {
                ...state,
                id: action.data.id,
                data: action.data,
                isFetching: false
            }
        case UPDATE_OBJECT:
            return {
                ...state,
                data: action.data,
            }
        case SAVE_OBJECT_REQUEST:
        case CREATE_OBJECT_REQUEST:
            return {
                ...state,
                isSaving: true
            }
        case CREATE_OBJECT_SUCCESS:
            const data = action.data
            return isFormPanel
                ? {id: data.id, data, isSaving: false, isFetching: false}
                : {...state, isSaving: false}
        case SAVE_OBJECT_SUCCESS:
            return isFormPanel
                ? {...state, data: action.data, isSaving: false}
                : {...state, isSaving: false}
        default:
            return state
    }
}

const createDefaultObject = (module={}) => {
    const { edit, fields } = module

    const fieldIds = (edit && edit.fields) || []

    return fieldIds.reduce((acc, fieldId) => {
        const field = fields.byId[fieldId]
        return {
            ...acc,
            [field.path]: field.default
        }
    }, {})
}

const defaultObject = (state={}, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return createDefaultObject(action.module)
        case SET_EDIT_DEFAULT_OBJECT:
            return action.object
        case SET_DEFAULT_EDIT_OBJECT:
            return {
                ...state,
                ...action.defaultObject
            }
        default:
            return state
    }
}

const saveAndReturnButtons = [
    { type: 'save', bsStyle: 'success', tKey: 'save' },
    { type: 'return', bsStyle: 'default', tKey: 'return' }
]

const saveButton = [
    { type: 'save', bsStyle: 'success', tKey: 'save' },
]

const returnButton = [
    { type: 'return', bsStyle: 'default', tKey: 'return' }
]

const buttons = (state=saveAndReturnButtons, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
        case SET_FORM_OBJECT:
            return !action.module.updatable && action.objectMode !== 'newObject'
                ? returnButton
                : action.module.defaultFormButtons || state
        case GET_OBJECT_SUCCESS:
            return _.get(action, 'meta.module.updatable')
                ? _.get(action, 'meta.module.defaultPanel') === 'form' ? saveButton : _.get(action, 'data.buttons', state)
                : returnButton
        case SET_FORM_BUTTONS:
            return action.buttons
        default:
            return state
    }
}

const accessId = (state=null, action) => {
    if (action.type === INITIALIZE_MODULE) {
        return _.get(action, 'module.edit.accessId', state)
    } else {
        return state
    }
}

const formIcon = (state=false, action) => {
    if (action.type === INITIALIZE_MODULE) {
        return action.module.edit.formIcon
    } else {
        return state
    }
}

const deleteAccessId = (state=null, action) => {
    if (action.type === INITIALIZE_MODULE) {
        return _.get(action, 'module.edit.deleteAccessId', state)
    } else {
        return state
    }
}

const fields = (state=[], action) => {
    if (action.type === INITIALIZE_MODULE) {
        return _.get(action, 'module.edit.fields', state)
    } else {
        return state
    }
}

const dataLists = (state=[], action) => {
    if (action.type === INITIALIZE_MODULE) {
        return _.get(action, 'module.edit.dataLists', state)
    } else {
        return state
    }
}

const startDateById = (state={}, action) => {
    if (action.type === SET_CELL_START_DATE) {
        return {
            ...state,
            [action.id]: action.startDate
        }
    } else {
        return state
    }
}

const endDateById = (state={}, action) => {
    if (action.type === SET_CELL_END_DATE) {
        return {
            ...state,
            [action.id]: action.endDate
        }
    } else {
        return state
    }
}

const edit = combineReducers({
    buttons,
    defaultObject,
    accessId,
    deleteAccessId,
    fields,
    dataLists,
    formIcon,
    startDateById,
    endDateById,
    object: formObject
})

export default edit
