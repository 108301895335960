import history from '../history'
import { beforeRedirection } from '../apps/MainPage/actions'
// redirects to the login page if the user is not authenticated

export default store => next => action => {
    if (
        action.meta &&
        action.meta.unauthorized &&
        history.location.pathname !== '/login'
    ) {
        store.dispatch(beforeRedirection(history.location.pathname + history.location.search))
        history.push('/login')
    }
    next(action)
}
