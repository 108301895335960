const _ = require('lodash')
const moment = require('moment')
const workflow = require('../workflow')

const RISK = 'risk'
const NO_RISK = 'noRisk'

export async function generateReportTreatedAlert(object, context) {
    try {
        const period = object.period

        const dates = {
            start: moment.utc(moment(period[0]).format('YYYY-MM-DD')).toDate(),
            end: moment.utc(moment(period[1]).format('YYYY-MM-DD')).toDate()
        }

        console.log('--> Period :')
        console.log(dates)

        const loadReasons = global.app.S.Reason.find({
            fieldPath: ['code', 'tName'],
            translateName: context.translateName,
            group: context.group
        })

        const loadFamilyAxis = global.app.S.FamilyAxis.find({
            translateName: context.translateName,
            group: context.group
        })

        const loadOrganizationAxis = global.app.S.OrganizationAxis.find({
            translateName: context.translateName,
            group: context.group
        })

        const [alert, reason, organizationAxis, familyAxis] = await Promise.all([
            workflow.getUserClosedAlerts(context.user, dates, context),
            loadReasons,
            loadOrganizationAxis,
            loadFamilyAxis
        ])
        const dataAlerts = await structureData(
            period,
            { alert, reason, organizationAxis, familyAxis },
            context
        )

        const generatedFile = await generateExcel(`${context.tc('treatedAlert')}.xlsx`, dataAlerts, context)
        const fileObject = {
            ...generatedFile,
            id: new global.ObjectID(generatedFile.id),
            user: _.get(context, 'user.name', 'unknown user'),
            date: moment().format('YYYY-MM-DD HH:mm')
        }
        const alertReportCollection = global.app.S.AlertReport.collection
        await alertReportCollection.updateOne(
            { _id: new global.ObjectID(object.id)},
            { $set: { file: fileObject}
            }
        )

        return {
            ...object,
            date: moment(object.date).format('YYYY-MM-DD HH:mm:ss'),
            period: object.period.map(o => moment(o).format('YYYY-MM-DD')),
            file: fileObject
        }
    } catch (e) {
        throw(e)
    }
}

async function generateExcel(filename, data, context){
    return new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(data, context),
            filename,
            (err, file) => {
                if (err) reject(err)
                else resolve(file)
            })
    })
}

// const generateExcel = (dataAlerts, callback) => {
//     global.excel.generateExcel(
//         configureWorkbook(dataAlerts),
//         'TreatedAlert.xlsx',
//         callback
//     )
// }

const getSubStoreAxisElements = (groupAxis, alert) => {
    let refs = {
        ticket: '',
        cashier: '',
        checkout: ''
    }
    if (groupAxis.joinedEntity === 'SubStoreAxis') {
        switch (groupAxis.code) {
            case 'ticketNo':
                return Object.assign({}, refs, {
                    ticket: alert[groupAxis.code]
                })
            case 'cashier':
                return Object.assign({}, refs, {
                    cashier: alert[groupAxis.code]
                })
            case 'checkout':
                return Object.assign({}, refs, {
                    checkout: alert[groupAxis.code]
                })
            default:
                return refs
        }
    }
    return refs
}

const getGroupElementCode = (groupAxis, alert) => {
    switch (groupAxis.joinedEntity) {
        case 'StoreAxis':
            return alert.store.code
        case 'SubStoreAxis':
            if (groupAxis.code === 'ticketNo') {
                return alert.store.code
            } else return `${alert.store.code}-${alert[groupAxis.code]}`
        case 'OrganizationAxis':
            return alert.organizations[0].code
        case 'FamilyAxis':
            return alert.familys[0].code
        case 'ProductAxis':
            return alert.product ? alert.product.code : ''
        default:
            return ''
    }
}

async function structureData(period, data, context){
    console.log('--> Structure the Data')

    const userAlerts = await new Promise((resolve, reject) => {

        global.app.S.Alert.find(
            {
                group: context.group,
                t: context.t,
                tc: context.tc,
                translateName: context.translateName,
                fieldPath: [
                    "id", "code", "dataPeriod", "product.id", "store.id", "reason.id",
                    "alertConfiguration.id", "alertConfiguration.tName",
                    "alertConfiguration.groupAxes.id", "alertConfiguration.groupAxes.joinedEntity",
                    "familys.tName", "familyAxis.id", "familys.familyAxis.id", "familys.familyAxis.tName",
                    "organizations.tName", "organizations.organizationAxis.id", "organizations.organizationAxis.tName"
                ],
                query: {
                    _id: {$in: _.map(data.alert, "_id")}
                }
            },
            (error, alerts) => {
                if (error) reject(error)
                else resolve(alerts)
            }
        )
    })

    const result = userAlerts.reduce((o, alert) => {
        const alertkey =
            alert.alertConfiguration.groupAxes
                .map(ga => getGroupElementCode(ga, alert))
                .join('-') +
            '-' +
            alert.alertConfiguration.name

        const refs = alert.alertConfiguration.groupAxes.reduce((acc, o) => {
            return Object.assign(acc, getSubStoreAxisElements(o, alert))
        }, {})

        if (!o[alertkey]) {
            o[alertkey] = Object.assign(
                {
                    alertName: alert.alertConfiguration.tName,

                    familyAxis: alert.familys.map(family => ({
                        code: family.familyAxis.code,
                        tName: family.familyAxis.tName,
                        family: family.code,
                        familyTName: family.tName
                    })),

                    organizationAxis: alert.organizations.map(org => ({
                        code: org.organizationAxis.code,
                        tName: org.organizationAxis.tName,
                        organization: org.code,
                        organizationTName: org.tName
                    })),

                    product: alert.product
                        ? `${alert.product.code} - ${alert.product.name}`
                        : '',

                    store: alert.store
                        ? `${alert.store.code} - ${alert.store.name}`
                        : '',

                    values: {
                        [RISK]: data.reason.reduce(
                            (o, reason) => {
                                return Object.assign({}, o, {
                                    [reason.code]: 0
                                })
                            },
                            { total: 0 }
                        ),
                        [NO_RISK]: data.reason.reduce(
                            (o, reason) => {
                                return Object.assign({}, o, {
                                    [reason.code]: 0
                                })
                            },
                            { total: 0 }
                        )
                    }
                },
                refs
            )
        }

        if (alert.workflow.step === RISK && alert.reason) {
            o[alertkey].values[RISK][alert.reason.code]++
            o[alertkey].values[RISK]['total']++
        } else if (alert.workflow.step === NO_RISK && alert.reason) {
            o[alertkey].values[NO_RISK][alert.reason.code]++
            o[alertkey].values[NO_RISK]['total']++
        }

        return o
    }, {})

    const alerts = _.keys(result).map(key =>
        Object.assign(
            { values: result[key].values },
            _.omit(result[key], ['values'])
        )
    )

    const totaux = alerts.reduce(
        (acc, alert) => {
            data.reason.forEach(reason => {
                if (alert.values[RISK]) {
                    acc[RISK][reason.code] += alert.values[RISK][reason.code]
                }

                if (alert.values[NO_RISK]) {
                    acc[NO_RISK][reason.code] +=
                        alert.values[NO_RISK][reason.code]
                }
            })
            acc[RISK].total += alert.values[RISK].total
            acc[NO_RISK].total += alert.values[NO_RISK].total
            return acc
        },
        {
            [RISK]: data.reason.reduce(
                (o, reason) => {
                    return Object.assign({}, o, { [reason.code]: 0 })
                },
                { total: 0 }
            ),
            [NO_RISK]: data.reason.reduce(
                (o, reason) => {
                    return Object.assign({}, o, { [reason.code]: 0 })
                },
                { total: 0 }
            )
        }
    )

    console.log(alerts)
    console.log(totaux)

    return {
        alerts,
        totaux,
        reasons: data.reason,
        userName: context.user.name,
        organizationAxis: data.organizationAxis,
        familyAxis: data.familyAxis
    }
}

const configureWorkbook = ({
    alerts,
    totaux,
    reasons,
    organizationAxis,
    familyAxis
}) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)
    //workbook.properties.date1904 = true

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Alertes')

    let row = 2
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const refNumber = organizationAxis.length + familyAxis.length

    dataSheet.getRow(row).getCell(col).value = 'Axe organisationelle'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    if (organizationAxis.length) {
        dataSheet.mergeCells(row, col, row, col + organizationAxis.length - 1)
    }
    col += organizationAxis.length

    dataSheet.getRow(row).getCell(col).value = 'Magasin'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row + 1, col)
    col++

    dataSheet.getRow(row).getCell(col).value = 'Caissier'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row + 1, col)
    col++

    dataSheet.getRow(row).getCell(col).value = 'Caisse'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row + 1, col)
    col++

    dataSheet.getRow(row).getCell(col).value = 'Axe famille'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    if (familyAxis.length) {
        dataSheet.mergeCells(row, col, row, col + familyAxis.length - 1)
    }
    col += familyAxis.length

    dataSheet.getRow(row).getCell(col).value = 'Produit'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row + 1, col)
    col++

    dataSheet.getRow(row).getCell(col).value = 'Alerte'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row + 1, col)
    col++

    dataSheet.getRow(row).getCell(col).value = 'Risquées'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row, col + reasons.length)
    col += reasons.length
    col++

    dataSheet.getRow(row).getCell(col).value = 'Non risquées'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row, col + reasons.length)

    row++
    col = 1

    organizationAxis.forEach(org => {
        dataSheet.getRow(row).getCell(col).value = org.tName
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    col += 3

    familyAxis.forEach(family => {
        dataSheet.getRow(row).getCell(col).value = family.tName
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    col += 2

    reasons.forEach(reason => {
        dataSheet.getRow(row).getCell(col).value = reason.tName
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })
    dataSheet.getRow(row).getCell(col).value = 'Total'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    col++

    reasons.forEach(reason => {
        dataSheet.getRow(row).getCell(col).value = reason.tName
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })
    dataSheet.getRow(row).getCell(col).value = 'Total'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG

    alerts.forEach(alert => {
        row++
        col = 1
        //col = refNumber +5;

        organizationAxis.forEach(org => {
            const orgAxis = alert.organizationAxis.find(
                o => o.code === org.code
            )
            dataSheet.getRow(row).getCell(col).value = orgAxis
                ? orgAxis.organizationTName
                : ''
            col++
        })

        dataSheet.getRow(row).getCell(col).value = alert.store
        col++

        dataSheet.getRow(row).getCell(col).value = alert.cashier
        col++

        dataSheet.getRow(row).getCell(col).value = alert.checkout
        col++

        familyAxis.forEach(family => {
            const familyAxis = alert.familyAxis.find(
                o => o.code === family.code
            )
            dataSheet.getRow(row).getCell(col).value = familyAxis
                ? familyAxis.familyTName
                : ''
            col++
        })

        dataSheet.getRow(row).getCell(col).value = alert.product
        col++

        dataSheet.getRow(row).getCell(col).value = alert.alertName
        col++

        reasons.forEach(reason => {
            dataSheet.getRow(row).getCell(col).value =
                alert.values[RISK][reason.code]
            col++
        })

        dataSheet.getRow(row).getCell(col).value = alert.values[RISK].total
        col++

        reasons.forEach(reason => {
            dataSheet.getRow(row).getCell(col).value =
                alert.values[NO_RISK][reason.code]
            col++
        })

        dataSheet.getRow(row).getCell(col).value = alert.values[NO_RISK].total
        col++
    })

    row++
    col = 1

    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    dataSheet.mergeCells(row, col, row, refNumber + 4)

    col = refNumber + 5

    dataSheet.getRow(row).getCell(col).value = 'Total'
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    col++

    reasons.forEach(reason => {
        dataSheet.getRow(row).getCell(col).value = totaux[RISK][reason.code]
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    dataSheet.getRow(row).getCell(col).value = totaux[RISK].total
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    col++

    reasons.forEach(reason => {
        dataSheet.getRow(row).getCell(col).value = totaux[NO_RISK][reason.code]
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    dataSheet.getRow(row).getCell(col).value = totaux[NO_RISK].total
    dataSheet.getRow(row).getCell(col).fill = darkGrayBG
    col++

    return workbook
}
