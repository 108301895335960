import React from 'react'
import PropTypes from 'prop-types'
import {Check, X} from 'react-feather'
import './BooleanCell.css'

const svgProps = {
    className: "DataTable-BooleanCell",
    size: 18
}

export const BooleanCell = ({ value }) => value ? <Check {...svgProps} style={{color: '#09b309'}} /> : <X {...svgProps} />

BooleanCell.propTypes = {
    value: PropTypes.bool.isRequired
}
