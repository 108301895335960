const _ = require('lodash')

export default {
    id: "U",
    name: "Pilotage",
    description: "",
    languages: ['fr'],
    entities: _.concat(
        require("./uapl/staticEntities").default,
        require("./uapl/article").entities,
        require("./uapl/deposit").dependencies,
        require("./uapl/family").dependencies,
        require("./uapl/subFamily").dependencies,
        require("./uapl/elementaryData").entities,
        require("./uapl/provider").dependencies,
        require("./uapl/client").dependencies,
        require("./uapl/piece").dependencies,
        require("./uapl/stateModel").dependencies,
        require("./uapl/importConf").dependencies,
        require("./uapl/importLog").dependencies,
        require("./uapl/importAnalysis").dependencies,
        require("./uapl/dashboardAffectation").dependencies,
        [
            require("./uapl/company").entity,
            require("./uapl/deposit").entity,
            require("./uapl/family").entity,
            require("./uapl/subFamily").entity,
            require("./uapl/surFamily").entity,
            require("./uapl/groupSurFamily").entity,


            require("./uapl/provider").entity,
            require("./uapl/client").entity,
            require("./uapl/piece").entity,
            require("./uapl/reason").entity,
            require("./uapl/habilitationPerimeter").entity,
            require("./uapl/habilitationState").entity,
            require("./uapl/accountModel").entity,
            require("./uapl/analyseStateComputation").entity,
            require("./uapl/stockAdjustmentStateComputation").entity,
            require("./uapl/marginStateComputation").entity,
            require("./uapl/stateModel").entity,

            require("./uapl/zone").entity,
            require("./uapl/importConf").entity,
            require("./uapl/importLog").entity,
            require("./uapl/importAnalysis").entity,
            require("./uapl/referentialJoin").entity,
            require("./uapl/dashboardCharts/importAnalysisGraph").entity,
            require("./uapl/dashboardCharts/agrTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/caplTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/accAgrTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/accCaplTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/totalTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/totalAccTurnoverBySurFamily").entity,
            require("./uapl/dashboardCharts/marginBySurFamilyGroup").entity,
            require("./uapl/dashboardCharts/marginCommercialBySurFamilyGroup").entity,
            require("./uapl/dashboardCharts/accStockAdjustment").entity

        ]
    ),
    modules: _.concat(
        [
            require("./uapl/accountModel").module_,
            require("./uapl/stateModel").module_,
            require("./uapl/analyseStateComputation").module_,
            require("./uapl/stockAdjustmentStateComputation").module_,
            require("./uapl/marginStateComputation").module_,

            require("./uapl/company").module_
        ],
        require("./uapl/article").modules,
        require("./uapl/subFamily").modules,
        require("./uapl/family").modules,
        [
            require("./uapl/surFamily").module_,
            require("./uapl/groupSurFamily").module_,
            require("./uapl/zone").module_
        ],
        require("./uapl/deposit").modules,
        require("./uapl/provider").modules,
        require("./uapl/client").modules,
        require("./uapl/piece").modules,
        [
            require("./uapl/reason").module_,
            require("./uapl/habilitationPerimeter").module_,
            require("./uapl/habilitationState").module_,

            require("./uapl/importConf").module_,
            require("./uapl/importLog").module_,
            require("./uapl/importAnalysis").module_,
            require("./uapl/elementaryData").module_,
        ]
    )
};
