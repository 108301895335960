import React from 'react'
import { Field } from 'redux-form'
import {StyledInput} from "../../../../components/Form/StyledInput";

const Input = ({ t, input, path }) => <StyledInput t={t} {...input} path={path} disabled={true} />

const ReadOnly = ({ t, field }) => {
    return <Field t={t} name={field.path} path={field.tKey || field.path} component={Input} />
}

export default ReadOnly
