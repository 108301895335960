import React from 'react'
import { connect } from 'react-redux'
import {reduxForm, change as reduxFormChange, isDirty, getFormValues, initialize} from 'redux-form'
import {
    getFilterFields,
    getFilterObject,
    getChartFiltersOpened
} from '../../selectors/index'
import FilterForm from './FilterForm'
import {fetchChartData, fetchDtData, fetchFilterFieldsLists} from '../../actions/api'
import {FILTER_FORM} from "./ListFilterForm";

export const CHART_FILTER_FORM = 'chartFilterObject'

const ChartFilterForm = ({
    module,
    fields,
    filtersOpened,
    onChange,
    fetchFilterData,
    manuallyFetchFilterData,
    fetchFilterFieldsLists,
    t,
    isFormDirty,
    filterData
}) => (
    <FilterForm
        module={module}
        fields={fields}
        filtersOpened={filtersOpened}
        onChange={onChange}
        fetchFilterData={fetchFilterData}
        manuallyFetchFilterData={manuallyFetchFilterData}
        fetchFilterFieldsLists={fetchFilterFieldsLists}
        t={t}
        isFormDirty={isFormDirty}
        filterData={filterData}
    />
)

const ReduxForm = reduxForm({
    // a unique name for the form (required by the library)
    form: CHART_FILTER_FORM,
    enableReinitialize: false
})(ChartFilterForm)

const mapStateToProps = state => ({
    isFormDirty: isDirty(CHART_FILTER_FORM)(state),
    filterData:  getFormValues(CHART_FILTER_FORM)(state),
    filtersOpened: getChartFiltersOpened(state),
    fields: getFilterFields(state),
    initialValues: getFilterObject(state)
})

const mapDispatchToProps = (dispatch, {module}) => ({
    fetchFilterFieldsLists: () => dispatch(fetchFilterFieldsLists('chart')),
    fetchFilterData: () => {
        if(module.manualFilters) return
        dispatch(fetchChartData())
    },
    manuallyFetchFilterData: data => {
        dispatch(fetchChartData())
        dispatch(initialize(CHART_FILTER_FORM, data))
    },
    onChange: (field, value) =>
        dispatch(reduxFormChange(CHART_FILTER_FORM, field, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm)
