const _ = require('lodash')
export default {
    id: "A",
    name: "Track",
    description: "",
    entities: _.concat(
        [
            require("./authentication/warehouse").entity,
            require("./authentication/location").entity,
            require("./authentication/locationTag").entity,
            require("./authentication/productAxisType").entity,
            require("./authentication/productAxis").entity,
            require("./authentication/tag").entity,
            require("./authentication/sku").entity,
            require("./authentication/historyTag").entity,
            require("./authentication/product").entity
        ],
        require("./authentication/sku").dependencies,
        require("./authentication/media/entities").entities,
        require("./authentication/product").dependencies,
    ),
    modules: _.concat(
        [
            require("./authentication/warehouse").module_,
            require("./authentication/location").module_,
            require("./authentication/locationTag").module_,
            require("./authentication/productAxisType").module_,
            require("./authentication/productAxis").module_,
            require("./authentication/product").module_,
            require("./authentication/sku").module_,
            require("./authentication/tag").module_,
            require("./authentication/historyTag").module_,

        ],
        require("./authentication/media/modules").modules
    )
};
