import async from "async";

const _ = require('lodash')
const { setFieldListOptions, changeFieldDisabled } = require('../../../../apps/KpModule/actions')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')

export const entities = [
    {
        name: 'BPEndorsed',
        fields: [
            'code',
            'Subsidiary',
            {
                type: 'ProjectConfiguration', nullable: true,
                link: { oppositeField: { link: { deleteType: 'cascade' } } }
            },
            { path: 'endorsedOpening', type: 'date' },
            'BPProjectType',
            'yearStartBP'
        ],
        filters: [
            {
                name: 'bySubsidiary',
                isDefault: false,
                query: function(context) {
                    const subsidiaryId = _.get(context.data, 'subsidiary.id')
                    return subsidiaryId
                        ? { subsidiary: new global.ObjectID(subsidiaryId) }
                        : {_id: null}
                }
            },
        ],
        validateSave: function(newObject, oldObject, context, callback) {
            try {
                if(Number.parseInt(newObject.yearStartBP, 10) < 1995) {
                    return callback(new Error(context.tc('yearStartBPShouldBeBiggerThan1995')))
                } else {
                    callback(null, newObject, oldObject)
                }
            } catch (e) {
                callback(e)
            }
        }
    }
]

export const modules = [
    {
        kp: true,
        object: 'BPEndorsed',
        name: 'bPEndorsed',
        tKey: 'mTitle_bPEndorsed',
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        category: {
            path: 'empiric',
            icon: 'briefcase'
        },
        updatable: false,
        viewMap: {
            dt: [
                {
                    path: 'subsidiary',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                'code',
                {
                    path: 'projectConfiguration',
                    display: 'projectId',
                    initiallyNotVisible: true
                },
                'endorsedOpening',
                'bPProjectType',
                'yearStartBP'
            ],
            form: [
                {
                    path: 'subsidiary',
                    display: 'fullName',
                    filters: ['inUserPerimeter'],
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if (newValue && newValue.id) {
                                const projectConfigurationQuery = {
                                    subsidiary: _.pick(newValue, 'id')
                                }
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-B-bPEndorsed.BPEndorsed_projectConfiguration',
                                        store.getState,
                                        'form',
                                        { data: projectConfigurationQuery }
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions('e_projectConfiguration', []))
                            }
                        }
                    }
                },
                {
                    path: 'projectConfiguration',
                    display: 'projectId',
                    fieldPath: ['id', 'code', 'projectId', 'yearStartBP', 'openingDate', 'bPProjectType.id'],
                    filters: ['onlyEndorsedVersions', 'bySubsidiary'],
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            const bPProjectTypeField = module.viewMap.form.fields.find(
                                field => field.path === 'bPProjectType'
                            )
                            const yearStartBPField = module.viewMap.form.fields.find(
                                field => field.path === 'yearStartBP'
                            )
                            const endorsedOpeningField = module.viewMap.form.fields.find(
                                field => field.path === 'endorsedOpening'
                            )
                            const codeField = module.viewMap.form.fields.find(
                                field => field.path === 'code'
                            )
                            let disabled = false
                            if (newValue && newValue.id) {
                                disabled = true
                                bPProjectTypeField.setValue(newValue.bPProjectType)
                                yearStartBPField.setValue(newValue.yearStartBP)
                                endorsedOpeningField.setValue(newValue.openingDate)
                                codeField.setValue(newValue.code)
                            }
                            store.dispatch(changeFieldDisabled('e_bPProjectType', disabled))
                            store.dispatch(changeFieldDisabled('e_yearStartBP', disabled))
                            store.dispatch(changeFieldDisabled('e_endorsedOpening', disabled))
                            store.dispatch(changeFieldDisabled('e_code', disabled))
                        }
                    }
                },
                {path: 'code', editable: false},
                {path: 'endorsedOpening', editable: false},
                {path: 'bPProjectType', editable: false},
                {path: 'yearStartBP', editable: false}
            ]
        },
        filters: [
            {
                title: 'Subsidiary',
                path: 'subsidiary',
                object: 'Subsidiary',
                display: 'fullName',
                width: 12,
                client: true,
                filters: ['inUserPerimeter'],
                async: true,
                query: function(context, callback) {
                    const subsidiaryId = _.get(context.data, 'subsidiary.id')
                    if (subsidiaryId) {
                        callback(null, {subsidiary: new global.ObjectID(subsidiaryId)})
                    } else {
                        callback(null, {})
                    }
                }
            }
        ]
    }
]
