import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

class LogoutPage extends Component {
    componentDidMount() {
        this.props.logout()
    }

    render() {
        return <Redirect to='/login'/>

    }
}

LogoutPage.propTypes = {
    logout: PropTypes.func.isRequired
}

export default LogoutPage