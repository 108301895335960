import React from 'react'
import MediaViewer from '../../../../components/MediaViewer'
import { getFormValues } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { connect } from 'react-redux'


const MediaContainer = props => {
    return <MediaViewer noThumbnail={props.field.noThumbnail} path={props.field.path} data={props.moduleData} style={props.field.style} />
}


const mapStateToProps = (state) => ({
    moduleData: getFormValues(OBJECT_FORM)(state)
})

export default connect(mapStateToProps)(MediaContainer)
