import React from 'react'
import PropTypes from 'prop-types'
import './MultiCheckboxField.css'
import {translateName, translateName2} from "../../utils/i18n";
import HTMLRenderer from "react-html-renderer";

const MultiCheckboxField = props => {
    return (
        <div>
            <div className="MultiCheckboxField-label">{props.label} {props.required && '*'}</div>
            {props.subtitle && <div className="MultiCheckboxField-subtitle">{props.subtitle}</div>}
            <div className="MultiCheckboxField-wrapper">
                {props.options.map(option => (
                    <div className="MultiCheckboxField-checkbox" key={option.id}>
                        <input
                            type="checkbox"
                            className="MultiCheckboxField-input"
                            value={option.id}
                            disabled={props.disabled}
                            checked={props.value && props.value.map(value => value.id).includes(option.id)}
                            onChange={() => undefined}
                        />
                        <span
                            className="cr"
                            onClick={() => {
                                if(props.onChange && !props.disabled) {
                                    const checked = props.value.map(object => object.id).includes(option.id)
                                    if(!checked) {
                                        props.onChange([...props.value, {id: option.id, detailedResponse: ""}])
                                    }
                                    else props.onChange(props.value.filter(value => value.id !== option.id ))
                                }
                            }}
                        >
                                <i className="cr-icon glyphicon glyphicon-ok" onClick={event => {
                                    if(!props.onChange) {
                                        event.stopPropagation()
                                    }
                                }} />
                        </span>
                        <label className="MultiCheckboxField-option">
                            <HTMLRenderer
                                html={translateName2(option.name, props.language)}
                            />
                        </label>
                    </div>
                ))}
            </div>
            <div className="MultiCheckboxField-textarea-container">
                {
                    props.hasDetailedResponse && <textarea
                        className="RadioButtonField-textarea"
                        value={props.detailedResponse}
                        disabled={props.disabled}
                        placeholder={props.detailedResponsePlaceholder}
                        onChange={props.onResponseDetailsChange}
                        rows={3}
                    />
                }
            </div>
        </div>
    )
}

MultiCheckboxField.propTypes = {
    value: PropTypes.any.isRequired,
    options: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default MultiCheckboxField
