export const entity = {
    name: 'Currency',
    fields: [
        {path: 'symbol', unique: true},
        {path: 'abbreviation', unique: true},
        {path: 'nature', type: 'Nature'},
    ],
    filters: [
        {
            name: 'isReferenceCurrency',
            isDefault: false,
            query: function() {
                return {"nature": "1"}
            }
        },
    ]
}


export const module_ = {
    object: 'Currency',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    //tKey: '',
    defaultSortBy: 'abbreviation',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'symbol'},
            {path: 'abbreviation'},
            {path: 'nature'}
        ],
        form: [
            {path: 'symbol'},
            {path: 'abbreviation'},
            {path: 'nature', type: 'toggle'},
        ],
    }
}

