import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import WrappedMenu from '../../../../components/Menu'
import './Navbar.css'
import i18n from '../../../../utils/i18nClient'
import {Flag} from 'react-feather'
import EngFlag from '../../../../img/en.png'
import FrFlag from '../../../../img/fr.png'
import ItgFlag from '../../../../img/it.png'
import NlFlag from '../../../../img/nl.png'
import EsFlag from '../../../../img/es.png'

const flagsByCode = {
    'en': EngFlag,
    'fr': FrFlag,
    'es': EsFlag,
    'it': ItgFlag,
    'nl': NlFlag
}

const lngNamesBycode = {
    'en': 'English',
    'fr': 'Français',
    'es': 'Española',
    'it': 'Italiano',
    'nl': 'Nederlands'
}

const LanguageSwitcher = ({
    languagesOpened,
    setLanguagesOpened,
    language,
    languagesList,
    color
}) => {
    return (
        <div
            className={classnames('dropdown', {
                open: languagesOpened
            })}
        >
            <button
                className="navbar-icon-container"
                onClick={() => setLanguagesOpened(!languagesOpened)}
            >
                <Flag className="navbar-icon language-icon" color={color}/>
            </button>
            <WrappedMenu
                opened={languagesOpened}
                onClickOutside={() => setLanguagesOpened(false)}
                outsideClickIgnoreClass={'language-icon'}
                position="left"
            >
                <ul
                    className="Navbar-Menu-list"
                    aria-labelledby="dropdownMenuFlag"
                >
                    {
                        languagesList.map((ln, index) => {
                            return (
                                <li
                                    key={index}
                                    className="Navbar-Menu-item"
                                    onClick={() => {
                                        i18n.changeLanguage(ln)
                                        setLanguagesOpened(false)
                                    }}
                                >
                                    <img height={20} width={20} style={{marginRight: '10px'}} alt={`Navbar-Menu-${ln}`} src={flagsByCode[ln]}/>
                                    <a
                                        className={classnames({
                                            selectedLanguage: language === ln
                                        })}
                                    >
                                        {lngNamesBycode[ln]}
                                    </a>
                                    { index !== languagesList.length - 1  && <p className='divider'/>}
                                </li>
                            )
                        })
                    }
                </ul>
            </WrappedMenu>
        </div>
    )
}

LanguageSwitcher.propTypes = {
    languagesOpened: PropTypes.bool.isRequired,
    setLanguagesOpened: PropTypes.func.isRequired
}

export default LanguageSwitcher
