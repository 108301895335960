/* eslint-disable no-unused-vars */
import _ from 'lodash'
import moment from 'moment'
import { basicContext } from '../../utils/contextUtils'
import {getExerciseRangOfMonthsFromMonth} from "./utils/stateComputationUtils";

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

async function listStateModels(context) {
    const habilitationsPromise = global.app.U.HabilitationState.collection.find({
        user: new global.ObjectID(context.user.id),
        group: new global.ObjectID(context.group.id)
    }).toArray()

    const [habilitations] = await Promise.all([habilitationsPromise])

    const habilitationStateModels = _.flatten(habilitations.map(
        hab => hab.stateModels
    ))
    const ModelsPromise = global.app.U.StateModel.collection.find({
        _id: {$in: habilitationStateModels.map(id => global.ObjectID(id))},
        stateModelType: 'margin'
    }).toArray()
    const [models] = await Promise.all([ModelsPromise])

    return models.map(model => ({
        id: model._id.toString(),
        stateModel: model.name
    }))
}

async function getStateModel(stateModelId, context) {
    let parentContext = {}

    const {
        sum,
        merge,
        mergeBy,
        monthExerciseStart,
        applyOperation,
        getExerciseRangOfMonthsFromMonth,
        getExerciseLastMonth,
        exerciseFromMonth,
        getPreviousExerciseLastMonth,
        getRangeMonth,
        getRangeOfMonthsFromExerciseStart,
        getNextMonth,
        getPreviousMonth,
        getPreviousYearMonth,
        getLastMonthOfData,
        getLastMonthOfLastFile,
        calculateValueEvolution,
        calculatePercentEvolution,
        calculatePercentEvolutionByElement,
        calculatePercentOverChunks,
        getKeyFromObject,
        formatData,
        getAggregateGroupBy,
        getAggregateColumns,
        getConditions,
        data
    } = require('./utils/stateComputationUtils')

    const meshEntity = _.get(context, 'data.marginReferentialType.entity')
    const meshEntityHeader = _.get(context, 'data.marginReferentialType.id')

    parentContext['month'] = _.get(context, 'data.month')
    parentContext['mesh'] = [meshEntityHeader]
    parentContext['meshName'] = _.get(
        context,
        'data.marginReferentialType.name'
    )

    parentContext['element'] = {
        byCompany: 'true' === _.get(context, 'data.marginReferentialType.byCompany'),
        meshEntity: meshEntity,
        meshEntityHeader: meshEntityHeader
    }

    parentContext['query'] = {}

    let referentialJoinName = 'Groupe'

    if (_.get(context, 'data.company')) {
        referentialJoinName = _.get(context, 'data.company.fullName')

        const referentialJoin = await global.app.U.Company.get(
            { _id: new global.ObjectID(_.get(context, 'data.company.id')) },
            { ...basicContext(context) }
        )
        if (referentialJoin) {
            parentContext['query'] = { companyCode: referentialJoin.code }
        }
    }

    const meshData = await global.app.U[_.upperFirst(meshEntity)].find(
        {
            ...basicContext(context),
            query: {},
            fieldPath: [
                'code',
                'name',
                'company',
                'company.code'
            ]
        }
    )

    const meshDataByCode = meshData.reduce((acc, data) => {
            return data.company
                ? {...acc, [`${data.company.code}-${data.code}`]: `${data.company.code} - ${data.code} - ${data.name}`}
                : {...acc, [`${data.code}`]: `${data.code} - ${data.name}`}
        }
        , {})

    const execAML = async stateModelLine => {
        let currentContext = {
            ...parentContext
        }
        const accountModel = stateModelLine.accountModel
        let amlResult
        await eval(accountModel.script)
            .then(response => {
                amlResult = response
            })
            .catch(error => (amlResult = []))
        return amlResult
    }

    const stateModel = await global.app.U.StateModel.get(
        { _id: new global.ObjectID(stateModelId) },
        {
            ...basicContext(context),
            fieldPath: [
                'code',
                'name',
                'stateModelLines',
                'stateModelLines.*',
                'stateModelLines.accountModel.*'
            ]
        }
    )

    const stateModelLines = []
    let history = {}
    await asyncForEach(_.orderBy(stateModel.stateModelLines, 'order'), async sml => {
        if(history[sml.accountModel.id]) {
            stateModelLines.push(...history[sml.accountModel.id])
        }else {
            let result = await execAML(sml)

            const accountModelType = _.get(sml, 'accountModel.accountModelType.id')
            if(accountModelType === 'mesh') {

                result = result.map(o => ({
                    ...o,
                    mesh: _.isObject(o.mesh)
                        ? {
                            ...o.mesh,
                            data: meshDataByCode[o.mesh.data] ? meshDataByCode[o.mesh.data] : o.mesh.data
                        }
                        : meshDataByCode[o.mesh] ? meshDataByCode[o.mesh] : o.mesh
                }))
            }

            const orderedResult = _.orderBy(result, 'mesh.data')
            history[sml.accountModel.id] = orderedResult
            stateModelLines.push(...result)
        }
    })

    return {
        id: stateModelId,
        name: `${stateModel.name} - ${referentialJoinName}`,
        stateModelLines
    }
}

export const entity = {
    name: 'MarginStateComputation',
    fields: ['stateModel'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                listStateModels(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else {
                getStateModel(id, context)
                    .then( object => global.ioSocket.emit(
                        `fetchFormObject-success-${context.user.id}${context.clientContext.accessId}`,
                        object
                    ))
                    .catch(error => {
                        global.ioSocket.emit(
                            `fetchFormObject-failure-${context.user.id}${context.clientContext.accessId}`,
                            {error: error.message}
                        )
                    })
                callback(null, {})
            }
        })
    }
}

export const module_ = {
    object: 'MarginStateComputation',
    tKey: 'mTitle_marginStateComputation',
    noLeftBoard: true,
    removable: false,
    updatable: false,
    newable: false,
    useSocketsOnGet: true,
    defaultSortBy: 'stateModel',
    defaultSortDirection: 'ASC',
    category: {
        path: 'Analyse',
        icon: 'grid'
    },
    viewMap: {
        dt: [{ path: 'stateModel', tKey: 'stateModels' }],
        form: [
            { path: 'name', type: 'readOnly', fullWidth: true },
            {
                path: 'stateModelLines',
                type: 'styledTable',
                autoGrow: true,
                fullWidth: true,
                fields: [
                    {path: 'mesh', style:{flex: '1 1 500px', fontSize: '12px', whiteSpace: 'initial'}},
                    { path: 'firstChunk', dynamic: true },
                    { path: 'secondChunk', dynamic: true },
                    { path: 'thirdChunk', dynamic: true },
                    { path: 'style', hidden: true },
                    { path: 'isHeader', hidden: true }
                ]
            }
        ]
    },
    filters: [
        {
            object: 'Company',
            path: 'company',
            display: 'fullName',
            clearable: true,
            client: true,
            autoFetch: false
        },
        {
            object: 'MarginReferentialType',
            path: 'marginReferentialType',
            fieldPath: ['name', 'entity', 'byCompany'],
            display: 'name',
            clearable: false,
            client: true,
            autoFetch: false,
            sortList: false
        },
        {
            path: 'month',
            type: 'monthPicker',
            client: true,
            autoFetch: false,
            default: moment()
                .subtract(1, 'months')
                .format('YYYY-MM')
        }
    ]
}
/* eslint-enable no-unused-vars */
