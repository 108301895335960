import moment from 'moment'
import {getData} from './marginRequest'
import {getLastMonthOfLastFile} from '../utils/stateComputationUtils'

async function findData() {

    const previousMonthISO = moment().subtract(1, "months")
    const previousMonth = previousMonthISO.format('YYYY-MM')
    const lastMonthFromLastFile = await getLastMonthOfLastFile(previousMonth)

    const formatedPreviousMonth = moment(lastMonthFromLastFile, 'YYYY-MM').format('MMM YYYY')

    const finalResult = await getData(lastMonthFromLastFile)

    return [
        {
            id: "marginBySurFamilyGroup",
            subTitle: {
                text: `marginBySurFamilyGroupSubtitle`,
                data: {
                    previousMonth: formatedPreviousMonth
                }
            },
            data: finalResult
        }
    ]
}
export const entity = {
    name: 'MarginBySurFamilyGroup',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
