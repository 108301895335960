import React from "react";
import PropTypes from "prop-types";
import MonthCalendar from "rc-calendar/lib/MonthCalendar";
import Picker from "rc-calendar/lib/Picker";
import enUS from "rc-calendar/lib/locale/en_US";
import frFR from "rc-calendar/lib/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import "rc-calendar/assets/index.css";
import ValidationUI from "../ValidationUI";
import "./DatePicker.css";
import {StyledInput} from "../Form/StyledInput";

const MonthPicker = ({
  value,
  path,
  required,
  onChange,
  open,
  onOpenChange,
  language = "en",
  placeholder = language === "fr" ? "Choisir un mois" : "Pick a month...",
  error,
  touched,
  disabled,
  disabledDate,
  t
}) => {

  language === "fr" ? moment.locale("fr") : moment.locale("en-gb");

  const calendar = (
    <MonthCalendar
      locale={language === "fr" ? frFR : enUS}
      disabledDate={disabledDate}
      className="DatePicker-calendar"
    />
  );
  return (
      <Picker
          animation="slide-up"
          calendar={calendar}
          value={value ? moment(value) : null}
          onChange={date => onChange(date && date.format("YYYY-MM"))}
          open={open}
          onOpenChange={onOpenChange}
          disabled={disabled}
      >
        {
          ({value}) => (
              <div className="DatePicker-container">
                <StyledInput
                    path={path}
                    required={required}
                    placeholder={placeholder}
                    value={value ? value.format('MMM YYYY') : ""}
                    t={t}
                />
                {touched && error && <ValidationUI error={error} />}
              </div>
          )
        }
      </Picker>
  );
};

MonthPicker.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
  language: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

export default MonthPicker;
