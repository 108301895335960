const _ = require('lodash')
const moment = require('moment')
const {getHabilitations} = require('./habilitations')

const darkGrayBG = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'E0E0E0' },
    bgColor: { argb: 'E0E0E0' }
}

const colorsByGroupAxis= {
    store: 'ffe0e6',
    cashier: 'cdd4ff',
    ticketCustomer: 'e4ffc5'
}

export async function generateIndicatorReport(object, context) {
    try {
        const period = object.period

        const dates = {
            start: moment.utc(moment(period[0]).format('YYYY-MM-DD')).toDate(),
            end: moment.utc(moment(period[1]).format('YYYY-MM-DD')).toDate()
        }

        const formattedPeriod = `${moment.utc(period[0]).format('YYMMDD')} - ${moment.utc(period[1]).format('YYMMDD')}`

        const organizationAxes = await global.app.S.OrganizationAxis.find({
            query: {},
            fieldPath: ["code", "name"],
            group: context.group,
        })


        const sortedOrganizationsAxes = _.sortBy(organizationAxes, 'name')

        const elemDataCollection = global.app.S.ElemDataLine.collection

        const elemData = await elemDataCollection
            .find({
                date: {
                    $gte: dates.start,
                    $lte: dates.end
                },
                group: new global.ObjectID(context.group.id),
                $or: object.indicators.map(
                    indicator => ({[indicator.mtKeyName]: {$exists: true}})
                )
            }).toArray()

        const groupedByStore = _.groupBy(elemData, 'store')

        const dateColumns = getDateColumns(dates, object.periodicity)
        const axesColumns = sortedOrganizationsAxes.map(orgAxis => orgAxis.name)

        const storeColumns = [...axesColumns, 'Magasin', 'Indicateur', ...dateColumns]
        const cashierColumns = [...axesColumns, 'Magasin', 'Caissier', 'Indicateur', ...dateColumns]

        const storeData = _.flatMap(Object.keys(groupedByStore), storeCode => {
            const firstLine = groupedByStore[storeCode][0]
            return object.indicators.map( indicator => {
                const indicatorElemDataLines = groupedByStore[storeCode].filter(elemDataLine => !!_.get(elemDataLine, indicator.mtKeyName))
                const groupedByDate = _.groupBy(indicatorElemDataLines, elemDataLine => {
                    if(object.periodicity.id === 'day') {
                        return moment(elemDataLine.date).format('DD/MM/YYYY')
                    }

                    const start = moment(elemDataLine.date).startOf(object.periodicity.id).format('DD/MM/YYYY')
                    const end = moment(elemDataLine.date).endOf(object.periodicity.id).format('DD/MM/YYYY')

                    return `${start} -> ${end}`
                })
                return dateColumns.reduce((acc, date) => {
                    const currentSum = groupedByDate[date]
                        ? groupedByDate[date].reduce((acc, elemDataLine) => {
                            return acc + _.get(elemDataLine, indicator.mtKeyName)
                        },0)
                        : 0
                    return {
                        ...acc,
                        [date]: object.cumulative ? acc.Total + currentSum : currentSum,
                        Total: acc.Total + currentSum
                    }
                }, sortedOrganizationsAxes.reduce((acc, orgAxis) => ({
                    ...acc,
                    [orgAxis.name]: firstLine ? firstLine[orgAxis.code] : ""
                }), {Magasin: storeCode, Indicateur: context.translateName(indicator.completeName), Total: 0}))
            })
        })

        const cashierData = _.flatMap(Object.keys(groupedByStore), storeCode => {
            const firstLine = groupedByStore[storeCode][0]
            const groupedByCashier = _.groupBy(groupedByStore[storeCode], 'cashier')

            return _.flatMap(Object.keys(groupedByCashier), cashierCode => {
                return object.indicators.map( indicator => {
                    const indicatorElemDataLines = groupedByCashier[cashierCode].filter(elemDataLine => !!_.get(elemDataLine, indicator.mtKeyName))
                    const groupedByDate = _.groupBy(indicatorElemDataLines, elemDataLine => {
                        if(object.periodicity.id === 'day') {
                            return moment(elemDataLine.date).format('DD/MM/YYYY')
                        }

                        const start = moment(elemDataLine.date).startOf(object.periodicity.id).format('DD/MM/YYYY')
                        const end = moment(elemDataLine.date).endOf(object.periodicity.id).format('DD/MM/YYYY')

                        return `${start} -> ${end}`
                    })
                    return dateColumns.reduce((acc, date) => {
                        const currentSum = groupedByDate[date]
                            ? groupedByDate[date].reduce((acc, elemDataLine) => {
                                return acc + _.get(elemDataLine, indicator.mtKeyName)
                            },0)
                            : 0
                        return {
                            ...acc,
                            [date]: object.cumulative ? acc.Total + currentSum : currentSum,
                            Total: acc.Total + currentSum
                        }
                    }, sortedOrganizationsAxes.reduce((acc, orgAxis) => ({
                        ...acc,
                        [orgAxis.name]: firstLine ? firstLine[orgAxis.code] : ""
                    }), {Magasin: storeCode, Caissier: cashierCode, Indicateur: context.translateName(indicator.completeName), Total: 0}))
                })
            })
        })

        if(!object.cumulative) {
            storeColumns.push('Total')
            cashierColumns.push('Total')
        }

        const generatedFile = await generateIndicatorExcel({keys: ['store', 'cashier'], data : {store: storeData, cashier: cashierData}, columns: {store: storeColumns, cashier: cashierColumns}, period: formattedPeriod})

        const fileObject = {
            ...generatedFile,
            _id: new global.ObjectID(generatedFile.id)
        }
        const reportCollection = global.app.S.Report.collection
        await reportCollection.updateOne(
            { _id: new global.ObjectID(object.id)},
            { $set: { file: fileObject}}
        )

        return {
            ..._.pick(object, ["id", "title", "reportType", "periodicity", "date"]),
            period: period.map(date => moment(date).format('YYYY-MM-DD')),
            file: generatedFile
        }
    } catch (e) {
        throw e
    }
}

export async function generateAlertReport(object, context) {
    try {
        const period = object.period

        const dates = {
            start: moment.utc(moment(period[0]).format('YYYY-MM-DD')).toDate(),
            end: moment.utc(moment(period[1]).format('YYYY-MM-DD')).toDate(),
            endPlusOne: moment.utc(moment(period[1]).add(1, 'days').format('YYYY-MM-DD')).toDate(),
        }

        const formattedPeriod = `${moment(dates.start).format('YYMMDD')} - ${moment(dates.end).format('YYMMDD')}`


        const [storeHabilitations, orgHabilitations] = await getHabilitations(context)

        const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
        const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

        const habQuery = stores.length || organizations.length
            ? {
                $or: [
                    {store: {$in : stores}},
                    {organizations: {$in: organizations}}
                ],
            }
            : {}

        const alertCollection = global.app.S.Alert.collection
        const storeCollection = global.app.S.Store.collection
        const alertConfCollection = global.app.S.AlertConfiguration.collection
        const organizationCollection = global.app.S.Organization.collection
        const organizationAxisCollection = global.app.S.OrganizationAxis.collection

        const organisations = await organizationCollection.find({group: new global.ObjectID(context.group.id)}).toArray()
        const organisationsById = organisations.reduce((acc, org) => ({...acc, [org._id.toString()]: org}), {})
        const organisationAxes = await organizationAxisCollection.find({group: new global.ObjectID(context.group.id)}).toArray()
        const organisationAxesById = organisationAxes.reduce((acc, orgAxis) => ({...acc, [orgAxis._id.toString()]: orgAxis}), {})
        const alertConfs = await alertConfCollection.find({_id: {$in: object.alertConfigurations.map(alertConf => global.ObjectID(alertConf.id))}, group: new global.ObjectID(context.group.id)}).toArray()
        const storesData = await storeCollection.find({group: new global.ObjectID(context.group.id)}).toArray()
        const groupedStoresData = storesData.reduce((acc, storeData) => ({...acc, [storeData._id.toString()]: storeData}), {})
        const groupAxes = await global.app.S.GroupAxis.find({
            group: global.ObjectID(context.group.id),
            query: {}
        })

        const dateColumns = getDateColumns(dates, object.periodicity)

        const alerts = await alertCollection
            .find({
                alertConfiguration: {$in: object.alertConfigurations.map(alertConf => global.ObjectID(alertConf.id))},
                "date.gte": { $gte: dates.start },
                "date.lt": { $lte: dates.endPlusOne },
                ...habQuery,
                group: new global.ObjectID(context.group.id),
            })
            .toArray()

        const alertsByAlertConf = _.groupBy(alerts, alert => {
            return alert.alertConfiguration.toString()
        });

        const generalReport = structureData(dateColumns, {alertsByAlertConf, alertConfs, object}, context)


        const reportsByAlert = _.sortBy(alertConfs, alertConf => context.translateName(alertConf.name, 'fr')).map(alertConf => {
            const groups = alertConf.groupAxes
                .map(groupAxis => groupAxes.find(elem => elem.id === groupAxis.toString()))
                .filter(groupAxis => groupAxis.includeInReportAnalysis)
                .map(groupAxis => ({...groupAxis, color: colorsByGroupAxis[groupAxis.code]}))

            return _.flatMap(groups.map(group => {
                const groupedAlertsByAxis = _.groupBy(alertsByAlertConf[alertConf._id.toString()], alert => alert[group.code])
                return Object.keys(groupedAlertsByAxis).map(axis => {
                    const firstElement = groupedAlertsByAxis[axis][0]
                    const organisations = firstElement.organizations && ['store'].includes(group.code)
                        ? firstElement.organizations.reduce((acc, orgId) => {
                            const organisation = organisationsById[orgId.toString()]
                            const organisationAxis = organisationAxesById[organisation.organizationAxis.toString()]

                            return {
                                ...acc,
                                [organisationAxis.code]: organisation.code
                            }
                        },{})
                        : {}
                    const groupedByDate = _.groupBy(groupedAlertsByAxis[axis], alert => {
                        if(object.periodicity.id === 'day') {
                            return moment(alert.baseDay).format('DD/MM/YYYY')
                        }

                        const start = moment(alert.baseDay).startOf(object.periodicity.id).format('DD/MM/YYYY')
                        const end = moment(alert.baseDay).endOf(object.periodicity.id).format('DD/MM/YYYY')

                        return `${start} -> ${end}`
                    })

                    return dateColumns.reduce((acc, date) => {
                        const currentValue = groupedByDate[date]?.length || 0
                        return {
                            ...acc,
                            [date]: object.cumulative ? acc.Total + currentValue : currentValue,
                            Total: acc.Total + currentValue
                        }
                    }, {
                        Axe: context.tl(group.code, 'fr'),
                        Element: group.code === 'store'
                            ? `${groupedStoresData[axis]?.code} - ${groupedStoresData[axis]?.name}`
                            : axis,
                        ...organisations,
                        Total: 0,
                        color: group.color
                    })

                })
            }))
        })



        const generatedFile = await generateAlertExcel({dateColumns, organisationAxes, generalReport, reportsByAlert, period: formattedPeriod, cumulative: object.cumulative})

        const fileObject = {
            ...generatedFile,
            _id: new global.ObjectID(generatedFile.id)
        }
        const reportCollection = global.app.S.Report.collection
        await reportCollection.updateOne(
            { _id: new global.ObjectID(object.id)},
            { $set: { file: fileObject}}
        )

        return {
            ..._.pick(object, ["id", "title", "reportType", "periodicity", "date"]),
            period: period.map(date => moment(date).format('YYYY-MM-DD')),
            file: fileObject
        }

    } catch (e) {
        throw(e)
    }
}


function getDateColumns(period, periodicity) {
    const columns = []
    const startDate = moment(period.start)
    const endDate = moment(period.end)

    let currentDate = startDate

    while (currentDate.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD")) {
        if(periodicity.id === 'day') {
            columns.push(moment(currentDate).format('DD/MM/YYYY'))
            currentDate.add(1, 'day');
        } else {
            const start = moment(currentDate).startOf(periodicity.id)
            const end = moment(currentDate).endOf(periodicity.id)
            columns.push(`${moment(start).format('DD/MM/YYYY')} -> ${moment(end).format('DD/MM/YYYY')}`)
            currentDate = end.add(1, 'day')
        }
    }
    return columns
}


function structureData(dateColumns, data, context){
    const periodicity = data.object.periodicity
    const cumulative = data.object.cumulative


    return _.sortBy(data.alertConfs, alertConf => context.translateName(alertConf.name, 'fr')).map((alertConf, index) => {
        const groupedByDate = _.groupBy(data.alertsByAlertConf[alertConf._id.toString()], alert => {
            if(periodicity.id === 'day') {
                return moment(alert.baseDay).format('DD/MM/YYYY')
            }

            const start = moment(alert.baseDay).startOf(periodicity.id).format('DD/MM/YYYY')
            const end = moment(alert.baseDay).endOf(periodicity.id).format('DD/MM/YYYY')

            return `${start} -> ${end}`
        })

        return dateColumns.reduce((acc, date) => {
            const currentValue = groupedByDate[date]?.length || 0
            return {
                ...acc,
                [date]: cumulative ? acc.Total + currentValue : currentValue,
                Total: acc.Total + currentValue
            }
        }, {Alerte: context.translateName(alertConf.name, 'fr'), number: index + 1, Total: 0})
    })
}

const configureAlertWorkbook = ({dateColumns, organisationAxes, generalReport, reportsByAlert, period, cumulative}) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)
    workbook.properties.defaultColWidth = 40

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet(`Alertes ${period}`)
    let row = 1
    let col = 1

    const columns = ['number', 'Alerte', ...dateColumns]

    if(!cumulative) {
        columns.push('Total')
    }

    columns.forEach(column => {
        const cell = dataSheet.getRow(row).getCell(col)
        cell.fill = darkGrayBG
        cell.border = {
            bottom: { style: 'thin' }
        }
        cell.value = column === 'number' ? 'N°' : column
        col++
    })

    row = 2
    generalReport.forEach(line => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = line[column]
            col++
        })
        row++
    })

    const organisationAxesColumns = organisationAxes.map(orgAxis => orgAxis.code)

    reportsByAlert.forEach((report, index) => {
        let dataSheet = workbook.addWorksheet(`Alerte ${index+1}`)
        let row = 1
        let col = 1

        const columns = [...organisationAxesColumns, 'Axe', 'Element', ...dateColumns]

        if(!cumulative) {
            columns.push('Total')
        }

        columns.forEach(column => {
            const cell = dataSheet.getRow(row).getCell(col)
            cell.value = column
            cell.fill = darkGrayBG
            cell.border = {
                bottom: { style: 'thin' }
            }
            col++
        })

        row = 2
        report.forEach(line => {
            col = 1
            columns.forEach(column => {
                dataSheet.getRow(row).getCell(col).value = line[column]
                dataSheet.getRow(row).getCell(col).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: line.color },
                    bgColor: { argb: line.color }
                }
                col++
            })
            row++
        })
    })



    return workbook
}

export async function generateAlertExcel(data) {
    return await new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureAlertWorkbook(data),
            `Rapport des alertes.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            }
        );
    });
}

export async function generateIndicatorExcel(data) {
    return await new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureIndicatorWorkbook(data),
            `Rapport des indicateurs.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            }
        );
    });
}

const configureIndicatorWorkbook = ({keys, data, columns, period}) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)
    workbook.properties.defaultColWidth = 40

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    keys.forEach(key => {
        let dataSheet = workbook.addWorksheet(`${period} ${key}`)
        let row = 1
        let col = 1

        columns[key].forEach(column => {
            const cell = dataSheet.getRow(row).getCell(col)
            cell.fill = darkGrayBG
            cell.border = {
                bottom: { style: 'thin' }
            }
            cell.value = column
            col++
        })

        row = 2
        data[key].forEach(line => {
            col = 1
            columns[key].forEach(column => {
                dataSheet.getRow(row).getCell(col).value = line[column]
                col++
            })
            row++
        })
    })
    return workbook
}

