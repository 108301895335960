import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Search} from 'react-feather'
import './DataTableFilter.css'
//import { HoverTooltip } from '../HoverTooltip'
import HoverPopover from '../HoverPopover'

class DataTableFilter extends Component {
    renderInput = () => (
        <input
            type="text"
            className="DataTableFilter-input"
            placeholder={this.props.placeholder || 'Search...'}
            onChange={event => this.props.onChange(event.target.value)}
            value={this.props.value}
        />
    )

    renderTooltip = () => (
        <HoverPopover
            id="DataTableFilter-tooltip"
            message={this.props.tooltip || "Tooltip"}
            placement="left"
        >
            {this.renderInput()}
        </HoverPopover>
    )

    render() {
        return (
            <div className={'DataTableFilter'}>
                <Search size={18} className="DataTableFilter-icon" />
                {this.props.tooltip ? this.renderTooltip() : this.renderInput()}
            </div>
        )
    }
}

DataTableFilter.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string
}

export default DataTableFilter
