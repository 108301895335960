import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import WrappedMenu from '../../../../components/Menu'
import { translate } from 'react-i18next'
import './Navbar.css'
import {FileText} from 'react-feather'
import { toastr } from 'react-redux-toastr'
import {getFile} from "../../../../utils/network";

class Docs extends Component {
    GetDoc = doc => () => {
        const {setDocsOpened, t } = this.props
        const file = doc.files[0]
        if(file) {
            setDocsOpened(false)
            toastr.info(t('downloading'))
            getFile({
                fileId: file._id,
                filename: file.filename
            })

        } else {
         toastr.error(t('cannotFindDocument'))
        }
    }

    render() {
        const { docsOpened, setDocsOpened, documents, color } = this.props

        return (
            <div
                className={classnames('dropdown', {
                    open: docsOpened
                })}
            >
                <button
                    className="navbar-icon-container"
                    onClick={() => setDocsOpened(!docsOpened)}
                >
                    <FileText className="navbar-icon documents-icon" color={color}/>
                </button>
                <WrappedMenu
                    opened={docsOpened}
                    onClickOutside={() => setDocsOpened(false)}
                    outsideClickIgnoreClass={'documents-icon'}
                    position="left"
                >
                    <ul
                        className="Navbar-Menu-list"
                        aria-labelledby="dropDownMenuDocs"
                    >
                        {documents.map((doc, index) => (
                            <li key={doc._id} className="Navbar-Menu-item" onClick={this.GetDoc(doc)}>
                                <a>
                                    {doc.name}
                                </a>
                                { index !== documents.length - 1  && <p className='divider'/>}
                            </li>
                        ))}
                    </ul>
                </WrappedMenu>
            </div>
        )
    }
}

Docs.propTypes = {
    language: PropTypes.string.isRequired,
    docsOpened: PropTypes.bool.isRequired,
    setDocsOpened: PropTypes.func.isRequired
}

export default translate('platform')(Docs)
