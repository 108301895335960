/* eslint-disable no-unused-vars */
const _ = require('lodash')
const moment = require('moment')
const {dateToIntFormat} = require("../engines/functions");

const { basicContext } = require('../../../utils/contextUtils')
const {
    irr, getMonth, getYear, min, max, sum,
    avg, formatNumber, rowStyle, formatFormula
} = require('../engines/functions')

export async function calculateEmpiricAccountModels(shopBPE, calculationYears, accountModelsToCalculate, context) {

    console.time('dataSearch')

    const shopBPEndorsed = await global.app.B.ShopBPEndorsed.get(
        { _id: new global.ObjectID(shopBPE.id) },
        {
            ...basicContext(context),
            fieldPath: [
                'realOpening',
                'fullInformation',
                'shop.id',
                'shop.openingDate',
                'shop.subsidiary.id',
                'shop.subsidiary.country.id',
                'shop.subsidiary.country.currency.id',
                'shop.subsidiary.country.currency.code',
                'bPEndorsed.id',
                'bPEndorsed.bPProjectType.id',
                'bPEndorsed.endorsedOpening'
            ]
        }
    )

    const shopBPEndorsedFullInformation = _.get(shopBPEndorsed, 'fullInformation')

    const realDate = moment(_.get(shopBPEndorsed, 'realOpening'), 'YYYY-MM-DD')

    const is_eur = () => {
        return _.get(shopBPEndorsed, 'shop.subsidiary.country.currency.code') === 'EUR'
    }

    const project_type = _.get(shopBPEndorsed, 'bPEndorsed.bPProjectType.id')
    const project_real_opening_date = dateToIntFormat(_.get(shopBPEndorsed, 'realOpening'))
    const project_opening_date = dateToIntFormat(_.get(shopBPEndorsed, 'bPEndorsed.endorsedOpening'))

    const first_opening_date = dateToIntFormat(_.get(shopBPEndorsed, 'shop.openingDate') || moment('1995-01-01', 'YYYY-MM-DD'))

    const accountModels = await global.app.B.AccountModel.collection
        .find()
        .toArray()
    const accountModelsByCode = accountModels.reduce(
        (acc, model) => ({
            ...acc,
            [model.code]: {
                id: model._id,
                formula: model.formula
            }
        }),
        {}
    )

    // const beginYear = realDate.year() - 1 + _.get(selectedStateModel, 'yearOfStart.value')
    // const stateYears = _.range(
    //     beginYear,
    //     realDate.year() + _.get(selectedStateModel, 'yearOfEnd.value')
    // )

    const f_year = _.head(calculationYears)

    const countries = await global.app.B.Country.collection
        .find()
        .toArray()
    const countriesTypes = countries.reduce((acc, country) => ({
        ...acc,
        [`P-${country.code}`]: {mesh: 3, country: country._id}
    }), {})

    const types = {
        F: { mesh: '1', subsidiary: new global.ObjectID(_.get(shopBPEndorsed, 'shop.subsidiary.id')) },
        H: { mesh: '2' },
        P: { mesh: '3', country: new global.ObjectID(_.get(shopBPEndorsed, 'shop.subsidiary.country.id')) },
        M: { mesh: '4', shop: new global.ObjectID(_.get(shopBPEndorsed, 'shop.id')) },
        B: { mesh: '5', bp: new global.ObjectID(_.get(shopBPEndorsed, 'bPEndorsed.id')) },
        ...countriesTypes
    }

    const dataInContext = await global.app.B.Data.collection
        .find({
            $or: _.values(types)
        })
        .toArray()

    const lowerCaseData = dataInContext.map(o => ({
        ...o,
        name: _.get(o, 'name', '').toLowerCase()
    }))

    console.timeEnd('dataSearch')

    const data = (type, name, year) => {
        const dataRows = _.filter(lowerCaseData, { name: name.toLowerCase(), year, ...types[type] })
        return dataRows.reduce((acc, o) => acc + o.value, 0)
    }

    const cacheObject = {}

    const formula = (...args) => formulaM(...args)

    const formulaM = (code, beginYear, lastYear) => {
        const accountModel = accountModelsByCode[code]
        if (!accountModel || accountModel === Infinity) return 0
        if (lastYear) {
            const results = []
            for (let year = beginYear; year <= lastYear; year++) {
                if (cacheObject[`${code}-${year}`]) {
                    results.push(cacheObject[`${code}-${year}`])
                } else {
                    const result = beginYear > 1995
                        ? eval(formatFormula(accountModel.formula))
                        : 0
                    cacheObject[`${code}-${year}`] = result
                    results.push(result)
                }
            }
            return results
        } else if (beginYear) {
            let year = beginYear

            if (cacheObject[`${code}-${year}`]) {
                return cacheObject[`${code}-${year}`]
            }

            const result = beginYear > 1995
                ? eval(formatFormula(accountModel.formula))
                : 0
            cacheObject[`${code}-${year}`] = result
            return result
        } else {
            const result = eval(formatFormula(accountModel.formula))
            cacheObject[`${code}`] = result
            return result
        }
    }

    console.time('computeTime')

    const result = accountModelsToCalculate.reduce((acc, am) => {
        console.log('Account Model Code', am.accountModelCode)
        return {
            ...acc,
            [am.accountModelCode]: am.accountModelType === 'year'
                ? calculationYears.reduce(
                    (acc, year) => ({
                        ...acc,
                        [year]: formulaM(am.accountModelCode, year)
                    }), {})
                : formulaM(am.accountModelCode)
        }
    }, {})

    console.timeEnd('computeTime')

    return result
}


/* eslint-enable no-unused-vars */
