import {
    DASHBOARD_DATA_REQUEST,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_FAILURE
} from '../actions/index'

const user = (
    state = {
        elements: [],
        elementsAreFetching: false,
        dataByElement: {},
        dataIsFetching: {},
        dashboardFetchingError: null,
        dataFetchingErrors: []
    },
    action
) => {
    switch (action.type) {
        case DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                dataIsFetching: {
                    ...state.dataIsFetching,
                    [action.id]: true
                }
            }
        case DASHBOARD_DATA_SUCCESS:
            const dataObject = action.data[0]
            return {
                ...state,
                dataIsFetching: {
                    ...state.dataIsFetching,
                    [action.id]: false
                },
                dataByElement:{
                    ...state.dataByElement,
                    [dataObject.id]: {
                        ...dataObject
                    }
                }
            }
        case DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                dataFetchingErrors: [
                    ...state.dataFetchingErrors,
                    action.error
                ]
            }

        default:
            return state
    }
}

export default user
