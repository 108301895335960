import _ from "lodash";
import moment from "moment";
import {basicContext} from "../../../utils/contextUtils";
import {decrypt} from "../../../utils/crypto";

const darkGrayBG = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'E0E0E0' },
    bgColor: { argb: 'E0E0E0' }
}

export async function generateAlertExcel(data) {
    return await new Promise((resolve, reject) => {
        const formatedDate = moment(data.date).format('YYYY-MM-DD_HH-mm-ss')
        global.excel.generateExcel(
            configureAlertWorkbook(data),
            `${formatedDate}_${data.title}.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            },
            true
        );
    });
}

const configureAlertWorkbook = ({columns, objects}) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)
    workbook.properties.defaultColWidth = 40

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet(`Survey report`)
    let row = 1
    let col = 1

    let currentParentColumn
    let nbSubColumns = 0

    columns.forEach(column => {
        const cell = dataSheet.getRow(row).getCell(col)
        cell.fill = darkGrayBG
        cell.border = {
            bottom: { style: 'thin' }
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.value = column.display
        if(column.subColumn) {

            const subCell = dataSheet.getRow(row + 1).getCell(col)
            subCell.fill = darkGrayBG
            subCell.border = {
                bottom: { style: 'thin' }
            }
            subCell.alignment = { vertical: 'middle', horizontal: 'center' };
            subCell.value = column.subColumn

            if(column.id === currentParentColumn) {
                nbSubColumns++
            } else {
                if(currentParentColumn) {
                    dataSheet.mergeCells(row, col - nbSubColumns, row, col - 1)
                }
                currentParentColumn = column.id
                nbSubColumns = 1
            }
            col++
        } else {
            if(currentParentColumn) {
                dataSheet.mergeCells(row, col - nbSubColumns, row, col - 1)
                currentParentColumn = null
                nbSubColumns = 0
            }
            dataSheet.mergeCells(row, col,row + 1, col)
            col++
        }
    })

    row = 3
    objects.forEach(line => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = column.getter(line)
            col++
        })
        row++
    })
    return workbook
}

export async function generateSurveyReport(object, context) {

    const surveyConf = await global.app[context.model.id].SurveyConf.get(
        _.get(object, 'survey.surveyConf.id'),
        {
            fieldPath: ['questions'],
            group: context.group
        }
    )

    const surveyResults = await global.app[context.model.id].SurveyResult.find({
        ...basicContext(context),
        fieldPath: [
            'user.firstname',
            'user.lastname',
            'user.mail',
            'user.phone',
            'responses'
        ],
        query: {
            status: 'validated',
            survey: {$eq: new global.ObjectID(object.survey.id)}
        }
    })

    const personalDataColumns = object.anonymize
        ? [{display: 'IDbase', getter: object => object.user.id}]
        : [
            {display: 'Adresse mail', getter: object => object.user.mail && decrypt(object.user.mail)},
            {display: 'Prénom', getter: object => object.user.firstname},
            {display: 'Nom', getter: object => object.user.lastname},
            {display: 'Téléphone', getter: object => object.user.phone && decrypt(object.user.phone)}
        ]

    const columns = [
        ...personalDataColumns,
        ..._.flatMap(surveyConf.questions,
                question => {
                    const questionType = _.get(question, 'surveyType.id')
                    let questions = []
                    switch(questionType){
                        case 'labeledText':
                        case 'boundedInteger':
                        case 'boundedNumber':
                        case 'boundedIntegerInDropdown':
                        case 'labeledCheckbox':
                        case 'cursor':
                            questions.push({id: question.id ,display: context.translateName(question.wording), getter: object => object[question.id]})
                            break
                        case 'radioButton':
                            questions.push({id: question.id ,display: context.translateName(question.wording), getter: object => object[question.id]})
                            question.responseSet.responseElements.some(el => {
                                if(el.hasDetailedResponse) {
                                    questions.push({id: question.id ,display: 'réponse détaillée', getter: object => object[`${question.id}.details`]})
                                }
                                return !!el.hasDetailedResponse
                            })
                            break
                        case 'multiCheckbox':
                            question.responseSet.responseElements.forEach((el, index) => {
                                questions.push({id: question.id ,display: context.translateName(question.wording), subColumn: `${index + 1}`, getter: object => object[`${question.id}.${el.id}`]})
                            })
                            question.responseSet.responseElements.some(el => {
                                if(el.hasDetailedResponse) {
                                    questions.push({id: question.id ,display: 'réponse détaillée', getter: object => object[`${question.id}.details`]})
                                }
                                return !!el.hasDetailedResponse
                            })
                            break
                        case 'rankOptions':
                            question.responseSet.responseElements.forEach((el, index) => {
                                questions.push({id: question.id ,display: context.translateName(question.wording), subColumn: `${index + 1}`, getter: object => object[`${question.id}.${index}`]})
                            })
                            break
                        default:
                            questions.push({id: question.id ,display: context.translateName(question.wording), getter: () => ''})
                            break;

                    }
                    return questions
        })
    ]

    const resultsByUser = surveyResults.map(surveyResult => {
        return surveyConf.questions.reduce((acc, question) => {
            const questionType = _.get(question, 'surveyType.id')
            const response = surveyResult.responses.find(response => {
                return _.get(response, 'surveyQuestion.id') === question.id
            })

            let responseId
            let responseName
            let hasDetailedResponse
            let questionResponses = {}

            let value = null
            switch(questionType){
                case 'labeledText':
                case 'boundedInteger':
                case 'boundedNumber':
                case 'boundedIntegerInDropdown':
                    questionResponses = {[question.id]: response.response}
                    break
                case 'labeledCheckbox':
                    questionResponses = {[question.id]: !!response.responseBoolean ? 'OUI' : 'NON'}
                    break
                case 'cursor':
                case 'radioButton':
                    responseId = _.get(response, 'responseElement.id')
                    responseName = _.get(response, 'responseElement.tName')
                    questionResponses = {[question.id]: responseName}

                    hasDetailedResponse = _.get(response, 'responseElement.hasDetailedResponse')
                    if(hasDetailedResponse) {
                        questionResponses[`${question.id}.details`] = response.detailedResponse
                    }
                    break
                case 'multiCheckbox':
                    value = response.responseElements.forEach(el => {
                        responseId = _.get(el, 'id')
                        responseName = _.get(el, 'tName')
                        questionResponses[`${question.id}.${responseId}`] = responseName

                        hasDetailedResponse = _.get(el, 'hasDetailedResponse')
                        if(hasDetailedResponse) {
                            questionResponses[`${question.id}.details`] = response.detailedResponse
                        }
                    })
                    break
                case 'rankOptions':
                    value = response.responseElements.forEach((el, index) => {
                        responseId = _.get(el, 'id')
                        responseName = _.get(el, 'tName')
                        questionResponses[`${question.id}.${index}`] = responseName
                    })
                    break
            }

            return {
                ...acc,
                ...questionResponses
            }
        }, {user: surveyResult.user})
    })

    return await generateAlertExcel({objects: resultsByUser, columns, title: object.title, date: object.date})
}

export async function generateAnalysis(context) {
    const surveyConf = _.get(context, 'data.surveyConf')
    if(surveyConf && surveyConf.id) {
        const surveyQuestionId = _.get(context, 'data.surveyQuestion.id')

        const surveys = await global.app[context.model.id].Survey.find({
            ...basicContext(context),
            fieldPath: ['id'],
            query: {
                surveyConf: new global.ObjectID(surveyConf.id)
            }
        })

        const surveyResults = await global.app[context.model.id].SurveyResult.find({
            ...basicContext(context),
            fieldPath: [
                'title',
                'survey.code',
                'survey.start',
                'user.name',
                'responses'
            ],
            query: {
                status: 'validated',
                survey: {$in: surveys.map(survey  => new global.ObjectID(survey.id))}
            }
        })

        const groupedByUser = _.groupBy(surveyResults, 'user.name')

        return Object.keys(groupedByUser)
            .map(userName => ({
                id: userName,
                user: userName,
                survey:  surveyConf.tTitle,
                results: groupedByUser[userName].reduce((acc, surveyResult) => {
                    const formattedStartDate = moment(surveyResult.survey.start).format('YYYY-MM-DD')
                    return {
                        ...acc,
                        [formattedStartDate]: surveyConf.questions
                            .filter(question => {
                                if(surveyQuestionId) return surveyQuestionId === question.id
                                return true
                            })
                            .filter(question => ['radioButton', 'cursor', 'multiCheckbox'].includes(_.get(question, 'surveyType.id')))
                            .reduce((acc, question) => {
                                const response = surveyResult.responses.find(response => {
                                    return _.get(response, 'surveyQuestion.id') === question.id
                                })
                                let responseResult = 0

                                switch (_.get(question, 'surveyType.id')){
                                    case 'radioButton':
                                    case 'cursor':
                                        responseResult = response.responseElement.coefficient
                                        break
                                    case 'multiCheckbox':
                                        response.responseElements.forEach(element => {
                                            responseResult += element.coefficient++
                                        })
                                        break;
                                }
                                return acc + (question.coefficient * responseResult)
                            }, 0)
                    }
                }, {})
            }))
    }

    return []
}
