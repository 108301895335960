import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import MonthPicker from "../../../../components/Calendar/MonthPicker";
import getFormValidations from "./formValidations";
import { toggleCalendar } from "../../actions";
import {getModule, getLanguage, getDatePickerOpened} from "../../selectors";
import moment from "moment";

const DATE_FORMAT = 'YYYY-MM'

function dateComparator(startDate, endDate) {
  if (!startDate && !endDate) return

  const referenceStartDate = startDate && moment(startDate, DATE_FORMAT).startOf('month')
  const referenceEndDate = endDate && moment(endDate, DATE_FORMAT).startOf('month')

  return function compareDate(date) {
    return date
        ? startDate && endDate
            ? moment(referenceStartDate).diff(date.startOf('month'), 'months')  > 0 || moment(referenceEndDate).diff(date.startOf('month'), 'months')  <= 0
            : startDate
                ? moment(referenceStartDate).diff(date.startOf('month'), 'months')  > 0
                : moment(referenceEndDate).diff(date.startOf('month'), 'months')  < 0
        : false
  }
}

// binding for redux-form
const VisualComponent = ({
  input: { value, onChange },
  meta: { touched, error },
  open,
  path,
  required,
  onOpenChange,
  startDate,
  endDate,
  disabled,
  editable,
  t
}) => {
  const comparator = dateComparator(startDate, endDate)

  const translatedError = t(error)
  return (
    <MonthPicker
      value={value}
      path={path}
      require={required}
      onChange={value => onChange(value)}
      open={open}
      onOpenChange={onOpenChange}
      disabledDate={comparator}
      touched={touched ? "true" : "false"}
      error={translatedError}
      language={"fr"}
      disabled={!editable || disabled}
      t={t}
    />
  );
};

const MonthPickerComponent = ({ field, language, module, open, toggleCalendar, t, objectMode }) => {
  const validations = getFormValidations(field);
  return (
    <Field
      name={field.path}
      path={field.tKey || field.tKey}
      require={field.required}
      component={VisualComponent}
      open={open}
      onOpenChange={() => toggleCalendar(field.id)}
      validate={validations}
      startDate={field.startDate}
      endDate={field.endDate}
      disabled={field.disabled || !field.writable}
      editable={!field.editable ? objectMode === 'newObject' : field.editable}
      language={language}
      module={module}
      t={t}
    />
  );
};

const mapStateToProps = (state, { field }) => ({
  open: getDatePickerOpened(state, field.id),
  language: getLanguage(state),
  module: getModule
});

const mapDispatchToProps = {
  toggleCalendar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthPickerComponent);
