export const job = {
    name: 'fixDataInconsistency',
    title: 'Fix data inconsistency',
    //cron: "5 1 * * *",
    single: true,
    execute: function(context, callback) {
        console.log('Fix data inconsistency')

        global.app.B.HypothesisModelLine.collection
            .find({ active: false })
            .forEach(function(o) {
                global.app.B.HypothesisData.collection.updateMany(
                    { hypothesisModelLineCode: o.code },
                    {
                        $set: { hypothesisModelLine: o._id }
                    }
                )
            })

        callback(null, { message: 'Job ended !' })
    }
}
