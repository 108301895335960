const _ = require('lodash')
const moment = require('moment')
const async = require('async')
const { basicContext } = require('../../../utils/contextUtils')
const {
    calculateBPStoreAccountModels
} = require('../engines/bPStoreAccountModelEngine')
const { generateExercises } = require('../engines/functions')

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

async function calculateHypotheses(projectConfiguration, context) {
    const onlyWithProject = ['N', 'R'].includes(
        _.get(projectConfiguration, 'bPProjectType.id')
    )

    // { id: '1', name: 'Annuelle – Chaque année' },
    // { id: '2', name: 'Annuelle – Première année' },
    // { id: '3', name: 'Annuelle – Sauf première année' },
    // { id: '4', name: 'Annuelle – Title' },
    // { id: '5', name: 'Non Annuelle' },
    // { id: '6', name: 'Non Annuelle – Titre' },
    const hypothesisModelLines = await global.app.B.HypothesisModelLine.find({
        ...basicContext(context),
        fieldPath: [
            'id', 'code', 'initialize.code', 'initialize.accountModelType.id', 'accountModelCode', 'hypothesisModel.id',
            'dataTypeM1.id', 'dataTypeM1.code', 'dataTypeM2.id', 'dataTypeM2.code', 'hypothesisKind.id', 'hypothesisScope.id'
        ],

        query: {
            hypothesisKind: { $in: ['1', '2', '3', '5'] },
            hypothesisType: 'hypothesis',
            poles: new global.ObjectID(projectConfiguration.pole.id),
            active: true
        }
    })

    let result = []

    const initHypothesisModelLines = hypothesisModelLines
        .filter(hml => !!hml.initialize)
        .map(hml => ({
            accountModelCode: _.get(hml, 'initialize.code'),
            accountModelType: _.get(hml, 'initialize.accountModelType.id')
        }))


    const startYear = _.toNumber(projectConfiguration.yearStartBP)
    const dataYears = _.range(startYear, startYear + 10)

    const initAccountModelResults = initHypothesisModelLines.length
        ? await calculateBPStoreAccountModels(
            projectConfiguration,
            _.uniqBy(initHypothesisModelLines, 'accountModelCode'),
            undefined,
            context,
            dataYears,
            true
        )
        : []


    await asyncForEach(hypothesisModelLines, async hypothesisModelLine => {
        const defaultHypothesis = {
            value: 0,
            hypothesisModel: new global.ObjectID(
                hypothesisModelLine.hypothesisModel.id
            ),
            hypothesisModelLine: new global.ObjectID(hypothesisModelLine.id),
            hypothesisModelLineCode: hypothesisModelLine.code,
            projectConfiguration: new global.ObjectID(projectConfiguration.id),
            group: new global.ObjectID(context.group.id)
        }
        const initialize = !!hypothesisModelLine.initialize

        let dataTypesM1 = []
        let dataTypesM2 = []

        if(hypothesisModelLine.dataTypeM1 && hypothesisModelLine.dataTypeM1.length) {
            dataTypesM1 = initialize
                ? [
                      {
                          ...defaultHypothesis,
                          dataType: new global.ObjectID(
                              _.first(hypothesisModelLine.dataTypeM1).id
                          ),
                          dataTypeCode: _.first(hypothesisModelLine.dataTypeM1).code
                      }
                  ]
                : hypothesisModelLine.dataTypeM1.map(o => ({
                        ...defaultHypothesis,
                        dataType: new global.ObjectID(o.id),
                        dataTypeCode: o.code
                  }))
        }

        if(hypothesisModelLine.dataTypeM2 && hypothesisModelLine.dataTypeM2.length) {
            dataTypesM2 = initialize
                ? [
                      {
                          ...defaultHypothesis,
                          dataType: new global.ObjectID(
                              _.first(hypothesisModelLine.dataTypeM2).id
                          ),
                          dataTypeCode: _.first(hypothesisModelLine.dataTypeM2).code
                      }
                  ]
                : hypothesisModelLine.dataTypeM2.map(o => ({
                        ...defaultHypothesis,
                        dataType: new global.ObjectID(o.id),
                        dataTypeCode: o.code
                  }))
        }

        const exercisesValues = generateExercises(
            _.get(hypothesisModelLine, 'hypothesisKind.id'),
            _.get(projectConfiguration, 'yearStartBP')
        )

        const transformValueDataInit = (resultAccountModel, type, dataType) => {
            result.push({
                withProject: type,
                ...dataType,
                exercise: '',
                value: resultAccountModel
            })
        }

        const transformYearDataInit = (resultAccountModel, type, dataType) => {
            Object.entries(
                resultAccountModel
            ).forEach(([exercise, value]) => {
                result.push({
                    withProject: type,
                    ...dataType,
                    exercise,
                    value
                })
            })
        }

        const transformDataInit = (hypothesisKind, resultAccountModel, type, dataType) => {
            return ['1', '2', '3'].includes(hypothesisKind)
                ? transformYearDataInit(resultAccountModel, type, dataType)
                : transformValueDataInit(resultAccountModel, type, dataType)
        }

        const transformData = (exercisesValues, type, dataType) => {
            exercisesValues.forEach(yearObject => {
                result.push({
                    withProject: type,
                    ...dataType,
                    ...yearObject
                })
            })
        }
        // Generation
        // { id: '1', name: 'M2 et M1' },
        // { id: '2', name: 'M2 uniquement' },
        // { id: '3', name: 'SM1 uniquement' }

        if(['1', '2'].includes(hypothesisModelLine.hypothesisScope.id)) {
            dataTypesM2.forEach(dataType => {
                initialize
                    ? transformDataInit(
                        hypothesisModelLine.hypothesisKind.id,
                        initAccountModelResults[hypothesisModelLine.initialize.code] ? initAccountModelResults[hypothesisModelLine.initialize.code] : 0,
                    1,
                        dataType
                    )
                    : transformData(exercisesValues, 1, dataType)
            })
        }

        if(
            ['1', '3'].includes(hypothesisModelLine.hypothesisScope.id) &&
            !onlyWithProject
        ) {
            dataTypesM1.forEach(dataType => {
                initialize
                    ? transformDataInit(
                        hypothesisModelLine.hypothesisKind.id,
                        initAccountModelResults[hypothesisModelLine.initialize.code] ? initAccountModelResults[hypothesisModelLine.initialize.code] : 0,
                    0,
                        dataType
                    )
                    : transformData(exercisesValues, 0, dataType)
            })
        }
    })

    const hypothesisDataCollection = global.app.B.HypothesisData.collection

    await hypothesisDataCollection.deleteMany({
        projectConfiguration: new global.ObjectID(projectConfiguration.id),
        group: new global.ObjectID(context.group.id)
    })
    if (result.length) {
        await hypothesisDataCollection.insertMany(result)
    }
    return projectConfiguration
}

async function updateInitHypotheses(projectConfiguration, context) {

    const hypothesisModelLines = await global.app.B.HypothesisModelLine.find({
        ...basicContext(context),
        fieldPath: [
            'id', 'code', 'initialize.code', 'initialize.accountModelType.id', 'accountModelCode', 'hypothesisModel.id',
            'dataTypeM1.id', 'dataTypeM1.code', 'dataTypeM2.id', 'dataTypeM2.code', 'hypothesisKind.id', 'hypothesisScope.id'
        ],

        query: {
            poles: new global.ObjectID(projectConfiguration.pole.id),
            hypothesisKind: { $in: ['1', '2', '3', '5'] },
            hypothesisType: 'hypothesis',
            initialize: {$ne: null}
        }
    })

    const hypothesisModelLinesById = _.keyBy(hypothesisModelLines, 'id')


    const initHypothesisModelLines = hypothesisModelLines
        .map(hml => ({
            accountModelCode: _.get(hml, 'initialize.code'),
            accountModelType: _.get(hml, 'initialize.accountModelType.id')
        }))


    const startYear = _.toNumber(projectConfiguration.yearStartBP)
    const dataYears = _.range(startYear, startYear + 10)

    const initAccountModelResults = initHypothesisModelLines.length
        ? await calculateBPStoreAccountModels(
            projectConfiguration,
            _.uniqBy(initHypothesisModelLines, 'accountModelCode'),
            undefined,
            context,
            dataYears,
            true
        )
        : []

    const hypothesisDataCollection = global.app.B.HypothesisData.collection

    const hypothesisDatas = await hypothesisDataCollection.find({
            projectConfiguration: new global.ObjectID(projectConfiguration.id),
            group: new global.ObjectID(context.group.id)
        }).toArray()

    await asyncForEach(hypothesisDatas, async hypothesisData => {
        const hypothesisModelLine = hypothesisModelLinesById[hypothesisData.hypothesisModelLine.toString()]
        if(hypothesisModelLine) {
            const resultAccountModel = ['1', '2', '3'].includes(hypothesisModelLine.hypothesisKind.id)
                ? initAccountModelResults[hypothesisModelLine.initialize.code][hypothesisData.year]
                : initAccountModelResults[hypothesisModelLine.initialize.code]

            await hypothesisDataCollection.updateOne(
                { _id: hypothesisData._id },
                { $set: { value: resultAccountModel }})
        }
    })

    return projectConfiguration
}

async function generateLeaseData(projectConf, context) {

    const generalCharacteristicTypes = await global.app.B.GeneralCharacteristicType.find(
        basicContext(context)
    )

    const leaseDatas = projectConf.leaseConfigurations.map(leaseConf => ({
            leaseType: leaseConf.leaseType,
            withProject: leaseConf.leaseElement.id === 'm1' ? 0 : 1,
            openingDate: leaseConf.startDate,
            generalCharacteristics: generalCharacteristicTypes.map(gct => ({
                generalCharacteristicType: {id: gct.id},
                value: 0,
                group: context.group
            })),
            leaseDataRenewals: _.range((_.get(leaseConf, 'leaseRenewalsNumber.value') || 0) + 1).map(renewalNumber => ({
                start: null,
                end: null,
                leaseRenewal: {id: renewalNumber +100},
                convenience: false,
                fixed: false,
                variable: false,
                minimumGuaranteed: false,
                capped: false,
                rentDeductible: 0,
                group: context.group
            })),
            group: context.group
    }))
    await global.app.B.ProjectConfiguration.save(
        { ...projectConf, leaseDatas},
        basicContext(context)
    )
    return projectConf
}

async function updateLeaseData(projectConf, oldProjectConf, context) {

    const fieldPath = [
        'country.id',
        'subsidiary.id',
        'status.id',
        'pole.id',
        'bPProjectType.id',
        'leaseDatas.id',
        'leaseDatas.leaseType.id',
        'leaseDatas.generalCharacteristics.id',
        'leaseDatas.generalCharacteristics.generalCharacteristicType.id',
        'leaseDatas.leaseDataRenewals.id',
        'leaseDatas.leaseDataRenewals.leaseRenewal.id',
        'leaseDatas.leaseDataPeriods.id',
        'leaseDatas.leaseDataPeriods.leaseRenewal.id',
        'leaseDatas.leaseDataPeriods.leaseCondition.id',
        'leaseConfigurations.id',
        'leaseConfigurations.leaseType.id',
        'leaseConfigurations.leaseElement.id',
        'leaseConfigurations.leaseRenewalsNumber.id',
        'leaseConfigurations.leaseDuration.id',
        'group.id'
    ]
    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConf.id) },
        {
            ...basicContext(context),
            fieldPath
        }
    )

    const generalCharacteristicTypes = await global.app.B.GeneralCharacteristicType.find(
        basicContext(context)
    )

    // const leaseDatas = await async.map(projectConfiguration.leaseConfigurations, leaseConf => {
    const leaseDatas = projectConfiguration.leaseConfigurations.map(leaseConf => {

        const oldLeaseConf = oldProjectConf.leaseConfigurations.find(oldLeaseConf =>
            oldLeaseConf.leaseType.id === leaseConf.leaseType.id &&
            oldLeaseConf.leaseElement.id === leaseConf.leaseElement.id
        )

        if(!!oldLeaseConf && (oldLeaseConf.id === leaseConf.id)) {
            const withProject = leaseConf.leaseElement.id === 'm1' ? 0 : 1
            const exitingLeaseData =  projectConfiguration.leaseDatas.find(o =>
                o.leaseType.id === leaseConf.leaseType.id &&
                o.withProject === withProject
            )

            if(leaseConf.leaseType.id !== 'temporaryStore') {

                const renewalsNumberDiff = _.get(leaseConf, 'leaseRenewalsNumber.value') - _.get(oldLeaseConf, 'leaseRenewalsNumber.value')

                if(renewalsNumberDiff === 0) {
                    return exitingLeaseData
                } else if( renewalsNumberDiff < 0) {
                    const deletedElement = _.range(1,(renewalsNumberDiff * -1)  +1).map(o =>
                        _.get(oldLeaseConf, 'leaseRenewalsNumber.value') + 1 - o + 100
                    )
                    // const leaseDataPeriodCollection = global.app.B.LeaseDataPeriod.collection
                    // await leaseDataPeriodCollection.deleteMany({
                    //     leaseRenewal: { $in: deletedElement},
                    //     leaseData: new global.ObjectID(projectConfiguration.id),
                    //     group: new global.ObjectID(context.group.id)
                    // })
                    return {
                        ...exitingLeaseData,
                        leaseDataRenewals: exitingLeaseData.leaseDataRenewals.filter(leaseDataRenewal => {
                            return deletedElement.every(renewalsMissing => {
                                return leaseDataRenewal.leaseRenewal.id !== renewalsMissing
                            })
                        }),
                        leaseDataPeriods: exitingLeaseData.leaseDataPeriods.filter(leaseDataPeriod =>
                            !deletedElement.includes(leaseDataPeriod.leaseRenewal.id)
                        )
                    }
                } else {
                    return {
                        ...exitingLeaseData,
                        leaseDataRenewals: [
                            ...exitingLeaseData.leaseDataRenewals,
                            ..._.range(1,renewalsNumberDiff +1).map(renewalsMissing => ({
                                start: null,
                                end: null,
                                leaseRenewal: {id: _.get(leaseConf, 'leaseRenewalsNumber.value') + 1 - renewalsMissing + 100},
                                convenience: false,
                                fixed: false,
                                variable: false,
                                minimumGuaranteed: false,
                                capped: false,
                                rentDeductible: 0,
                                group: context.group
                            }))
                        ]
                    }
                }
            }
            return exitingLeaseData
        } else {
            return {
                leaseType: leaseConf.leaseType,
                withProject: leaseConf.leaseElement.id === 'm1' ? 0 : 1,
                openingDate: leaseConf.startDate,
                generalCharacteristics: generalCharacteristicTypes.map(gct => ({
                    generalCharacteristicType: {id: gct.id},
                    value: 0,
                    group: context.group
                })),
                leaseDataRenewals: _.range((_.get(leaseConf, 'leaseRenewalsNumber.value') || 0) + 1).map(renewalNumber => ({
                    start: null,
                    end: null,
                    leaseRenewal: {id: renewalNumber + 100},
                    convenience: false,
                    fixed: false,
                    variable: false,
                    minimumGuaranteed: false,
                    capped: false,
                    rentDeductible: 0,
                    group: context.group
                })),
                group: context.group
            }
        }

    })

    await global.app.B.ProjectConfiguration.save(
        { ...projectConf, leaseDatas},
        {
            ...basicContext(context),
            action: 'updatingLease'
        }
    )
    return projectConf
}

const isYearHiDataChanged = (projectConf, oldProjectConf) => {
    return projectConf.yearHIDATA !== oldProjectConf.yearHIDATA
}

const isLeasesConfChanged = (projectConf, oldProjectConf) => {
    if(projectConf.leaseConfigurations.length !== oldProjectConf.leaseConfigurations.length) {
        return true
    }
    return projectConf.leaseConfigurations.some(leaseConf => {
        const oldLeaseConf = oldProjectConf.leaseConfigurations.find(oldLeaseConf =>
            oldLeaseConf.leaseType.id === leaseConf.leaseType.id &&
            oldLeaseConf.leaseElement.id === leaseConf.leaseElement.id &&
            (
                oldLeaseConf.leaseDuration.id === leaseConf.leaseDuration.id &&
                (_.get(oldLeaseConf, 'leaseRenewalsNumber.id') === _.get(leaseConf, 'leaseRenewalsNumber.id'))
            )
        )
        return !oldLeaseConf || (oldLeaseConf && oldLeaseConf.id !== leaseConf.id)
    })
}

async function generateData(projectConf, oldProjectConf,context) {

    if(!oldProjectConf) {
        await calculateHypotheses(projectConf, context)
        await generateLeaseData(projectConf, context)
        await updateMaxVersionOrder(projectConf, context)
    } else {
        if(isYearHiDataChanged(projectConf, oldProjectConf)) {
            await updateInitHypotheses(projectConf, context)
        }
        if(isLeasesConfChanged(projectConf, oldProjectConf)){
            await updateLeaseData(projectConf, oldProjectConf, context)
        }
    }
    return projectConf
}

async function duplicateProjectConf(projectConf, context) {
    const fieldPath = [
        'country.id',
        'subsidiary.id',
        'status.id',
        'pole.id',
        'bPProjectType.id',
        'leaseDatas.id',
        'leaseDatas.leaseType.id',
        'leaseDatas.generalCharacteristics.id',
        'leaseDatas.generalCharacteristics.generalCharacteristicType.id',
        'leaseDatas.leaseDataRenewals.id',
        'leaseDatas.leaseDataRenewals.leaseRenewal.id',
        'leaseDatas.leaseDataPeriods.id',
        'leaseDatas.leaseDataPeriods.leaseRenewal.id',
        'leaseDatas.leaseDataPeriods.leaseCondition.id',
        'leaseConfigurations.id',
        'leaseConfigurations.leaseType.id',
        'leaseConfigurations.leaseElement.id',
        'leaseConfigurations.leaseRenewalsNumber.id',
        'leaseConfigurations.leaseDuration.id',
        'group.id'
    ]
    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConf.id) },
        {
            ...basicContext(context),
            fieldPath
        }
    )

    const hypothesisDatas = await global.db.collection("b.hypothesisData")
        .find({
            projectConfiguration: new global.ObjectID(projectConf.id),
            group: new global.ObjectID(context.group.id)
        }).toArray()

    const clonedProjectConfiguration = _.cloneDeep(projectConfiguration)

    const newProjectConfiguration = await global.app.B.ProjectConfiguration.save(
        {
            ..._.omit(clonedProjectConfiguration, ['id', 'leaseDatas', 'leaseConfigurations', 'hypothesisDatas']),
            leaseConfigurations: clonedProjectConfiguration.leaseConfigurations.map(o => _.omit(o, ['id', 'projectConfiguration'])),
            leaseDatas: clonedProjectConfiguration.leaseDatas.map(leaseData => ({
                ..._.omit(leaseData, ['id', 'generalCharacteristics', 'leaseDataRenewals', 'leaseDataPeriods', 'projectConfiguration']),
                generalCharacteristics: leaseData.generalCharacteristics.map(o => _.omit(o, ['id', 'leaseData'])),
                leaseDataRenewals: leaseData.leaseDataRenewals.map(o => _.omit(o, ['id', 'leaseData'])),
                leaseDataPeriods: leaseData.leaseDataPeriods.map(o => _.omit(o, ['id', 'leaseData']))
            }))
        },
        {
            ...basicContext(context),
            action: 'duplication'
        }
    )

    const hypothesisDatasToInsert = hypothesisDatas.map(hd => ({
        ..._.omit(hd, '_id'),
        projectConfiguration: new global.ObjectID(newProjectConfiguration.id)
    }))

    if(hypothesisDatasToInsert.length) {
        await global.db.collection("b.hypothesisData").insertMany(hypothesisDatasToInsert)
    }

    return newProjectConfiguration
}

async function updateMaxVersionOrder(object, context) {
    await global.db.collection("b.projectConfiguration").updateMany(
        {
            ..._.pick(object, ['city', 'localization', 'yearStartBP']),
            pole: new global.ObjectID(object.pole.id),
            country: new global.ObjectID(object.country.id),
            subsidiary: new global.ObjectID(object.subsidiary.id),
            bPProjectType: object.bPProjectType.id,
            group: new global.ObjectID(context.group.id)
        },
        {
            $set: {'versionOrder.max': Number.parseInt(object.versionOrder.max, 10)}
        }
    )
}

export { generateData, duplicateProjectConf }