const moment = require("moment");
const _ = require('lodash');

const getElementFullName = object => {
    return object.code ? `${object.code} - ${object.name}` : object.name
}

export const entities = [
    {
        name: "ProjectGroupAxis",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "zone",            name: "Zone",           groupper: "country.zone.name",              fieldPath: ["country.zone.name"]},
            {id: "country",         name: "Pays",           groupper: "country.name",                   fieldPath: ["country.name"]},
            {id: "rangeOfOffer",    name: "Ligne produits",  groupper: "typeOfOffer.rangeOfOffer.name",  fieldPath: ["typeOfOffer.rangeOfOffer.name"]},
            {id: "typeOfOffer",     name: "Type produits",   groupper: "typeOfOffer.name",               fieldPath: ["typeOfOffer.name"]}
        ],
        byId: function(id) { return _.find(this.objects, {id}); }
    },
    {
        name: "OrderTakingGroupAxis",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "zone",              name: "Zone",           groupper: () => "zone.name", sorter: () => "zone.name",              fieldPath: ["zone.name"]},
            {id: "country",           name: "Pays",           groupper: () => "country.name", sorter: () => "country.name",                   fieldPath: ["country.name"]},
            {id: "rangeOfOffer",      name: "Ligne produits",  groupper: () => "rangeOfOffer.name", sorter: t => elem => t(_.get(elem, "rangeOfOffer.name")),  fieldPath: ["rangeOfOffer.name"]},
            {id: "typeOfOffer",       name: "Type produits",   groupper: () => "typeOfOffer.name", sorter: t => elem => t(_.get(elem, "typeOfOffer.name")),               fieldPath: ["typeOfOffer.name"]},
            {id: "responseMode",      name: "Mod. réponse",   groupper: () => "responseMode", sorter: t => elem => t(_.get(elem, "responseMode")),               fieldPath: ["responseMode"]},
            {id: "projectFunding",    name: "Financement",   groupper: () => "projectFunding", sorter: t => elem => t(_.get(elem, "projectFunding")),               fieldPath: ["projectFunding"]},
            {
                id: "compensationModes",
                name: "Mod. rémunération",
                groupper: t => elem => elem.compensationModes.sort().map(t).join(', '),
                sorter: t => elem => elem.compensationModes.sort().map(t).join(', '),
                fieldPath: ["compensationModes"]
            },
            {id: "interventionMode",  name: "Mod. d'intervention",   groupper: () => "interventionMode", sorter: t => elem => t(_.get(elem, "interventionMode")),               fieldPath: ["interventionMode"]},
            {id: "contractualSchema", name: "Sch. Contractuel",   groupper: () => "contractualSchema", sorter: t => elem => t(_.get(elem, "contractualSchema")),               fieldPath: ["contractualSchema"]},
            {
                id: "project",
                name: "Projet",
                groupper: () => project => `${project.code} - ${project.name}` ,
                sorter: () => project => `${project.code} - ${project.name}` ,
                fieldPath: ["code", "name"]
            }
        ],
        byId: function(id) { return _.find(this.objects, {id}); }
    },
    {
        name: "OrderTakingScopeAxis",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "zone",              name: "Zone"},
            {id: "country",           name: "Pays"},
            {id: "rangeOfOffer",      name: "Ligne produits"},
            {id: "typeOfOffer",       name: "Type produits"},
            {id: "responseMode",      name: "Mod. réponse"},
            {id: "projectFunding",    name: "Financement"},
            {id: "compensationModes", name: "Mod. rémunération"},
            {id: "interventionMode",  name: "Mod. d'intervention"},
            {id: "contractualSchema", name: "Sch. Contractuel"}
        ],
    },
    {
        name: "BrAndCommercialGroupAxis",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "zone",              name: "Zone",           groupper: () => "zone.name",                fieldPath: ["zone.name"]},
            {id: "country",           name: "Pays",           groupper: () => "country.name",             fieldPath: ["country.name"]},
            {id: "countryType",       name: "Type pays",      groupper: () => "country.countryType",      fieldPath: ["country.countryType"]},
            {id: "rangeOfOffer",      name: "Ligne produits", groupper: () => "rangeOfOffer.name",        fieldPath: ["rangeOfOffer.name"]},
            {id: "typeOfOffer",       name: "Type produits",  groupper: () => "typeOfOffer.name",         fieldPath: ["typeOfOffer.name"]},
            {id: "signatureYear",     name: "Année de signature", groupper: () => "signatureYear",        fieldPath: ["signatureYear"]},
            {id: "creationYear",      name: "Année de détection", groupper: () => "creationYear",         fieldPath: ["creationYear"]},
            {id: "offerSubmissionYear", name: "Année du dépôt d'offre", groupper: () => "offerSubmissionYear", fieldPath: ["offerSubmissionYear"]},
            {id: "responseMode",      name: "Mod. réponse",   groupper: () => "responseMode",             fieldPath: ["responseMode"]},
            {id: "projectFunding",    name: "Financement",    groupper: () => "projectFunding",           fieldPath: ["projectFunding"]},
            {
                id: "compensationModes",
                name: "Mod. rémunération",
                groupper: (t) => (elem) => elem.compensationModes.sort().map(t).join(', ') ,
                fieldPath: ["compensationModes"]
            },
            {id: "interventionMode",  name: "Mod. d'intervention", groupper: () => "interventionMode",    fieldPath: ["interventionMode"]},
            {id: "contractualSchema", name: "Sch. Contractuel",    groupper: () => "contractualSchema",   fieldPath: ["contractualSchema"]},
            {
                id: "project",
                name: "Projet",
                groupper: () => project => `${project.code} - ${project.name}` ,
                fieldPath: ["code", "name"]
            }
        ],
        byId: function(id) { return _.find(this.objects, {id}); }
    },
    {
        name: "AnalysisYear",
        type: "static",
        fields: [
            {path: "id", type: "integer"},
            {path: "name", f: function() { return `${this.id}`; }}
        ],
        objects: _.range(moment().year()-3, moment().year()+1).map(
            y => ({id: y})
        )
    },
    require('../../../utils/entityJoin').joinEntities(
        {
            name: 'Scope',
            fields: [
                {
                    path: 'fullName',
                    fieldPath: ['code', 'name'],
                    $f: (object, context, callback) => {
                        callback(null, getElementFullName(object))
                    }
                }
            ],
        },
        [
            'Zone', 'Country', 'RangeOfOffer', 'TypeOfOffer', 'CompensationMode',
            'ResponseMode', 'ProjectFunding', 'InterventionMode', 'ContractualSchema'
        ]
    )
]
