import {setFieldVisibility} from "../../../../apps/KpModule/actions";
import _ from "lodash";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import {translateName} from "../../../../utils/i18n";

export const entity = {
    name: 'DemandCategory',
    facets: ['codeName'],
    fields: [
        'Category',
        {path: 'display', type: 'boolean'}
    ],
    filters: [
        {
            name: "engagementProcessType",
            isDefault: false,
            query: function () {
                return {category: "2"}
            }
        },
    ]
}

export const module_ = {
    object: 'DemandCategory',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    tKey: 'processType',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {
                path: 'name',
                type: 'translatedText'
            },
            {path: 'category', tKey: 'process'}
        ],
        form: {
            fields: [
                {path: 'code', editable: false},
                {
                    path: 'name',
                    type: 'textarea',
                    unique : true
                },
                {
                    path: 'category',
                    tKey: 'process',
                    default: {id:'1'},
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction}) => {
                            const categoryField = module.viewMap.form.fields.find(field => field.path === 'category')
                            const displayField = module.viewMap.form.fields.find(field => field.path === 'display')
                            const category = categoryField && categoryField.getValue()
                            console.log('category', category)
                            store.dispatch(setFieldVisibility(displayField.id, category?.id === '2'))
                        }
                    }
                },
                {path: 'display', tKey: 'displayEngagementData'}
            ],
            onOpen: ({ store, module, t }) => {
                const categoryField = module.viewMap.form.fields.find(field => field.path === 'category')
                const displayField = module.viewMap.form.fields.find(field => field.path === 'display')
                const category = categoryField && categoryField.getValue()
                console.log('category', category)
                store.dispatch(setFieldVisibility(displayField.id, category?.id === '2'))
            }
        }
    }
}
