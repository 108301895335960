const _ = require('lodash')
const exceljs = require('exceljs')
const { basicContext } = require('../../../utils/contextUtils')

const minimalKeys = [
    'MAILLE',
    'BP',
    'MAGASIN',
    'FILIALE',
    'PAYS',
    'ANNEE',
    'TYPE',
    'SOURCE'
]

const headersCorrespondences = {
    MAILLE: 'mesh',
    BP: 'bp',
    MAGASIN: 'shop',
    FILIALE: 'subsidiary',
    PAYS: 'country',
    ANNEE: 'year',
    TYPE: 'type',
    EXERCICE: 'exercise',
    ECHEANCE: 'deadline',
    SOURCE: 'source'
}

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

export async function dataImportEngineXls(object, file, query, context) {
    const groupQuery = { group: new global.ObjectID(context.group.id) }

    const [bPsEndorsed, subsidiaries, countries, shops, meshDataTypes, dataSources, dataTypeImports] = await Promise.all([
        global.app.B.BPEndorsed.find({...basicContext(context)}),
        global.app.B.Subsidiary.find({...basicContext(context)}),
        global.app.B.Country.find({...basicContext(context)}),
        global.app.B.Shop.find({...basicContext(context)}),
        global.app.B.MeshDataType.find({...basicContext(context)}),
        global.app.B.DataSource.find({...basicContext(context)}),
        global.app.B.DataTypeImport.find({...basicContext(context)})
    ])

    const bPsEndorsedByCode = _.keyBy(bPsEndorsed, 'code')
    const subsidiariesByCode = _.keyBy(subsidiaries, 'code')
    const countriesByCode = _.keyBy(countries, 'code')
    const chopsByCode = _.keyBy(shops, 'code')
    const meshDataTypesByCode = _.keyBy(meshDataTypes, 'code')
    const dataSourcesByCode = _.keyBy(dataSources, 'code')
    const dataTypeImportsByCode = _.keyBy(dataTypeImports, 'code')

    const workbook = new exceljs.Workbook()
    await workbook.xlsx.readFile(file.path)
    const worksheet = workbook.getWorksheet('dpei_data')
    // bpa_data
    let parsedData = []
    const columns = worksheet.getRow(1).values
    worksheet.eachRow(function(row, rowNumber) {
        if (rowNumber !== 1) {
            parsedData.push(
                worksheet
                    .getRow(rowNumber)
                    .values.reduce((acc, value, index) => {
                        return { ...acc, [columns[index]]: value }
                    }, {lineIndex: rowNumber})
            )
        }
    })

    const importErrors = []

    const filteredParsedData = parsedData.filter(o => o.value !== '')

    const selectedMesh = meshDataTypes.find(o => o.id === object.meshDataType.id)

    await asyncForEach(filteredParsedData, async line => {

        const lineErrors = []

        const lineKeys = Object.keys(line)
        minimalKeys.forEach(key => {
            if(!lineKeys.includes(key)) {
                lineErrors.push({
                    type: "dataMissing",
                    column: key,
                    lineIndex: line.lineIndex,
                    line: line,
                    columnValue: undefined
                })
            }
        })

        let referentialPart = _.mapKeys(
            _.pick(line, Object.keys(headersCorrespondences)),
            (value, key) =>
                headersCorrespondences[key]
                    ? headersCorrespondences[key]
                    : key
        )

        if(referentialPart.mesh && selectedMesh.code !== referentialPart.mesh) {
            lineErrors.push({
                type: 'dataDoNotMatchWithFilter',
                column: 'MAILLE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.mesh
            })
        }

        if(referentialPart.type && !dataTypeImportsByCode[referentialPart.type]) {
            lineErrors.push({
                type: 'dataInconsistency',
                column: 'TYPE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.type
            })
        }
        if(referentialPart.source && !dataSourcesByCode[referentialPart.source]) {
            lineErrors.push({
                type: 'dataInconsistency',
                column: 'SOURCE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.source
            })
        }

        if(referentialPart.deadline && referentialPart.deadline !== 'C12') {
            lineErrors.push({
                type: 'dataInconsistency',
                column: 'ECHEANCE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.deadline
            })
        }

        const parsedYear = _.toNumber(referentialPart.year)
        const parsedExercise = _.toNumber(referentialPart.exercise)

        if(
            referentialPart.exercise && referentialPart.exercise !== '' &&
            (!Number.isInteger(parsedExercise) ||
            ( parsedExercise < 1995 || parsedExercise > 2040 ))
        ) {
            lineErrors.push({
                type: 'dataInconsistency',
                column: 'EXERCICE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.exercise
            })
        }

        if(
            !Number.isInteger(parsedYear) ||
            ( parsedYear < 1995 || parsedYear > 2040 )
        ) {
            lineErrors.push({
                type: 'dataInconsistency',
                column: 'ANNEE',
                lineIndex: line.lineIndex,
                line: line,
                columnValue: referentialPart.year
            })
        }

        // some refs tests
        if (referentialPart.mesh) {

            switch (referentialPart.mesh) {
                case 'F':
                    if(referentialPart.subsidiary && !subsidiariesByCode[referentialPart.subsidiary]) {
                        lineErrors.push({
                            type: 'dataInconsistency',
                            column: 'SUBSIDIARY',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: referentialPart.subsidiary
                        })
                    }
                    break
                case 'P':
                    if(referentialPart.country && !countriesByCode[referentialPart.country]) {
                        lineErrors.push({
                            type: 'dataInconsistency',
                            column: 'COUNTRY',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: referentialPart.country
                        })
                    }
                    break
                case 'M':
                    if(referentialPart.shop && !chopsByCode[referentialPart.shop]) {
                        lineErrors.push({
                            type: 'dataInconsistency',
                            column: 'MAGASIN',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: referentialPart.shop
                        })
                    }
                    break
                case 'B':
                    if(referentialPart.bp && !bPsEndorsedByCode[referentialPart.bp]) {
                        lineErrors.push({
                            type: 'dataInconsistency',
                            column: 'BP',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: referentialPart.bp
                        })
                    }
                    break
                default:
                    lineErrors.push({
                        type: 'dataInconsistency',
                        column: 'MAILLE',
                        lineIndex: line.lineIndex,
                        line: line,
                        columnValue: referentialPart.mesh
                    })
                    break
            }
        }

        const dataPart = _.omit(line, [...minimalKeys, 'EXERCICE', 'lineIndex', 'ECHEANCE'])

        Object.keys(dataPart).forEach(key => {
            if(!_.isNumber(dataPart[key])) {
                lineErrors.push({
                    type: 'dataFormat',
                    column: key,
                    lineIndex: line.lineIndex,
                    line: line,
                    columnValue: dataPart[key]
                })
            }
        })

        importErrors.push(...lineErrors)
    })

    if(!importErrors.length) {
        await asyncForEach(filteredParsedData, async line => {
            const dataPart = _.omit(line, [...minimalKeys, 'EXERCICE', 'lineIndex', 'ECHEANCE'])

            let referentialPart = _.mapKeys(
                _.pick(line, Object.keys(headersCorrespondences)),
                (value, key) =>
                    headersCorrespondences[key]
                        ? headersCorrespondences[key]
                        : key
            )

            referentialPart = {
                ..._.pick(referentialPart, ['deadline']),
                type: dataTypeImportsByCode[referentialPart.type].id,
                mesh: meshDataTypesByCode[referentialPart.mesh].id,
                source: dataSourcesByCode[referentialPart.source].id,
                bp: referentialPart.bp !== ''
                    ? new global.ObjectID(bPsEndorsedByCode[referentialPart.bp].id)
                    : '',
                subsidiary: referentialPart.subsidiary !== ''
                    ? new global.ObjectID(subsidiariesByCode[referentialPart.subsidiary].id)
                    : '',
                country: referentialPart.country !== ''
                    ? new global.ObjectID(countriesByCode[referentialPart.country].id)
                    : '',
                shop: referentialPart.shop !== ''
                    ? new global.ObjectID(chopsByCode[referentialPart.shop].id)
                    : '',
                year: referentialPart.year !== '' ? _.toNumber(referentialPart.year) : null,
                exercise: referentialPart.exercise !== '' ? _.toNumber(referentialPart.exercise) : null
            }

            const dataToStore = Object.keys(dataPart).reduce((acc, key) => {
                return [
                    ...acc,
                    {
                        ...referentialPart,
                        name: key,
                        value: _.toNumber(dataPart[key]),
                        ...groupQuery
                    }
                ]
            }, [])

            await asyncForEach(dataToStore, async data => {
                const enhancedQuery = {
                    ..._.omit(data, ['value', 'deadline', 'exercise'])
                }
                await global.db.collection('b.data').updateOne(
                    {...enhancedQuery},
                    {
                        $set: _.pick(data, ['value', 'deadline', 'exercise']),
                        $setOnInsert: _.omit(data, ['value', 'deadline', 'exercise'])
                    },
                    {upsert: true})
            })
        })
    }

    return {
        userErrors: importErrors,
        processedLines: filteredParsedData.length
    }
}
