const async = require("async");
const _ = require("lodash");
const engineCommons = require("../engines/common");
const fs = require("fs");
const transforms = require("./transforms");

const { generateLogPdf } = require("../utils/importLogPdf")


const readableEngine = function(readableStream, csvFileName, query, context, callback) {
    console.log('start reading file for validation...')

    let systemError = undefined

    const errorArray = []
    const warningArray = []

    // const minimalKeys = ['mesh', 'bp', 'shop', 'subsidiary', 'country', 'year', 'type', 'source'];

    const minimalKeys = ['Maille', 'BP', 'Magasin', 'Filiale', 'Pays', 'Annee', 'Type', 'Exercice', 'Echeance', 'Source'];

    const headersCorrespondences = {
        Maille: 'mesh',
        BP: 'bp',
        Magasin: 'shop',
        Filiale: 'subsidiary',
        Pays: 'country',
        Annee: 'year',
        Type: 'type',
        Exercice: 'exercise',
        Echeance: 'deadline',
        Source: 'source'
    }

    const options = { objectMode: true, context: context }
    const sink = new engineCommons.Sink(options)

    const errorHandler = function(e) {
        if (!systemError) systemError = e
        sink.end()
    }

    const dataCollection = "b.data"

    const deleteFromDataCollections = (query, callback) => global.db.collection(dataCollection).deleteMany(query, callback);

    const unsetIncompleteFromDataCollections =  (warningArray, callback) => global.db.collection(dataCollection).updateMany(
        {incomplete: true},
        {$unset: {incomplete: ""}},
        {},
        (e, result) => callback(e, {processedLines: _.get(result, "matchedCount"), userWarnings: warningArray})
    )

    sink.on("finish", () => {
        console.log("Processing file data finished.");

        if (errorArray.length || systemError) {
            console.log('Some errors occurred on validation.')
            // Do something here to revers after data import errors
            const engineError = {};
            if (systemError) engineError.systemError = systemError;
            if (errorArray.length) engineError.userError = errorArray;

            deleteFromDataCollections({incomplete: true}, e => {
                if (e) return callback(e);
                callback(engineError)
            })
        }
        else {
            console.log("Successful!");

            const filteredWarningArray = _.uniqBy(warningArray, o => `${o.column}-${o.columnValue}`)

            async.series([
                done => deleteFromDataCollections({dataToDeleteAfterImport: true}, done),
                done => unsetIncompleteFromDataCollections(filteredWarningArray, done),
            ], (e, results) => {
                if (e) return callback(e);
                console.log('Done insertions')
                callback(null, results[1]);

            })
        }
    });

    sink.on("close", () => {
        if (context.logEngines) console.log("The stream has been closed.");
    });

    readableStream
        .pipe(new transforms.AddIndex(options)).on("error", errorHandler)
        .pipe(new transforms.MinimalColumnsInFileValidator(options, minimalKeys, errorArray)).on('error', errorHandler)
        .pipe(new transforms.RenameHeaders(options, headersCorrespondences)).on("error", errorHandler)
        .pipe(new transforms.SetDeleteTagOnOldData(options, dataCollection, query)).on('error', errorHandler)
        .pipe(new transforms.ValuesTypeCasting(options)).on("error", errorHandler)
        .pipe(new transforms.AddSourceToLine(options, errorArray, warningArray)).on('error', errorHandler)
        .pipe(new transforms.AddTypeToLine(options, errorArray, warningArray)).on('error', errorHandler)
        .pipe(new transforms.AddMeshElementsToLine(options, errorArray, warningArray)).on('error', errorHandler)
        .pipe(new transforms.AddMeshToLine(options, errorArray, warningArray)).on('error', errorHandler)
        .pipe(new transforms.WriteDataToDb(options, errorArray, dataCollection, minimalKeys, headersCorrespondences)).on("error", errorHandler)
        .pipe(sink).on("error", errorHandler);
};

export const dataImportEngineCsv = function(file, query, context, callback) {
    const input = fs.createReadStream(file.path, {emitClose: true})
    const csvParser = global.csvParse({ delimiter: ';', columns: true, rtrim: true, ltrim: true, relax: true, bom: true})

    csvParser.on( 'error', function(e) {
        console.log(e.message)
        fs.unlink(file.path, (err) => {
            if (err) return callback({systemError: e})
            return callback({systemError: e})
        })
    })

    const parsedStream = input.pipe(csvParser)

    readableEngine(parsedStream, file.path, query, context, (e, result) => {
        const userErrors = _.get(e, 'userError', []);
        const systemError = _.get(e, 'systemError.message');
        const success = !e && _.isEmpty(userErrors) && ! systemError;
        const params = {
            success, systemError, userErrors, engineOutput: result,
            file
        }

        generateLogPdf(params, (e, logFilePDF) => {
            if(e) return callback(e)
            fs.unlink(file.path, err => {
                if (err) return callback({systemError: e})
                return callback(e, {success, logFilePDF})
            })
        });


    })
}
