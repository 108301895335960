import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popover from 'react-bootstrap/lib/Popover'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'


export class HoverPopover extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        message: PropTypes.node.isRequired,
        placement: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired
    }

    renderTooltip = () => (
        <Popover id={this.props.id}>
            {this.props.message}
        </Popover>
    )

    render() {
        return (
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement={this.props.placement}
                overlay={this.renderTooltip()}
            >
                {this.props.children}
            </OverlayTrigger>
        )
    }
}

export default HoverPopover