export const refundApplicationStatusObjects = [
    { id: 'draft', name: 'Brouillon' },
    { id: 'ongoing', name: "En cours d'étude" },
    { id: 'questioned', name: 'En attente de réponse' },
    { id: 'controlled', name: 'En attente de décision' },
    { id: 'accepted', name: 'Accepté' },
    { id: 'refused', name: 'Refusé' },
    { id: 'paid', name: 'Payé'},
]

export const adhesionApplicationStatusObjects = [
    { id: 'filed', name: 'Volet Administratif : Enregistré', style: 'primary'},
    { id: 'incomplete', name: 'Volet Administratif : Compléments  demandés', style: 'warning' },
    { id: 'updated', name: 'Volet Administratif : Mis à jour', style: 'primary' },
    { id: 'preValidated', name: 'Volet Administratif : Validé', style: 'warning' },
    { id: 'adjourned', name: 'Volet Technique : Compléments  demandés', style: 'warning' },
    { id: 'adjournedBD', name: 'Volet Technique : Compléments  demandés', style: 'warning' },
    { id: 'updated2', name: 'Volet Technique : Mis à jour', style: 'primary' },
    { id: 'filed2', name: 'Volet Technique : Enregistré', style: 'primary' },
    { id: 'completed', name: 'Volet Technique : Validé', style: 'primary' },
    { id: 'visitInProgress', name: 'Visite : Demandée', style: 'warning' },
    { id: 'visitCompleted', name: 'Visite : Effectuée', style: 'primary' },
    { id: 'accepted', name: 'Adhésion : Acceptée', style: 'primary' },
    { id: 'rejected', name: 'Adhésion : Refusée', style: 'danger' },
    { id: 'validated', name: 'Adhésion : Validée', style: 'success' },
]

export const liberalityApplicationStatusObjects = [
    { id: 'draft', name: 'Brouillon', style: 'warning'},
    { id: 'incomplete', name: 'Compléments  demandés', style: 'warning'},
    { id: 'readyToBePresented', name: 'Prêt à être présenté', style: 'primary'},
    { id: 'updated', name: 'Mis à jour', style: 'primary'},
    { id: 'awaitingDecision', name: 'En attente de décision', style: 'primary'},
    { id: 'validated', name: 'Validé', style: 'success'},
    { id: 'rejected', name: 'Refusé', style: 'danger'},
]


export const organizationActivities = [
    { id: 'refuge', name: 'Accueil' },
    { id: 'hostFamily', name: "Famille d'accueil" },
    { id: 'pound', name: 'Fourrière' },
    { id: 'pension', name: 'Pension' },
    { id: 'retirement', name: 'Retraite' },
    { id: 'adoption', name: 'Adoption N-1' },
]

export const dependencies =[
    {
        name: 'ExerciseStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'provisionalClosure', name: 'provisionalClosure' },
            { id: 'finalClosure', name: 'finalClosure' }
        ]
    },
    {
      name: 'OrganizationActivity',
      type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: organizationActivities
    },
    {
      name: 'ShelterType',
      type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'pound', name: 'pound' },
            { id: 'retirementHome', name: 'retirementHome' },
            { id: 'pension', name: 'pension' },
            { id: 'hostFamily', name: 'hostFamily' },
            { id: 'shelter', name: 'shelter' },
            { id: 'guarding', name: 'guarding' },
            { id: 'requisition', name: 'requisition' },
        ]
    },
    {
        name: 'Civility',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'm', name: 'monsieur' },
            { id: 'mme', name: 'madame' },
            { id: 'mlle', name: 'mademoiselle' },
            { id: 'o', name: 'other' }
        ]
    },
    {
        name: 'Member',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', name: 'P' },
            { id: '2', name: 'M' },
            { id: '3', name: 'G' },
            { id: '4', name: 'R' }
        ]
    },
    {
        name: 'OrganizationSize',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', name: 'P' },
            { id: '2', name: 'M' },
            { id: '3', name: 'G' }
        ]
    },
    {
        name: 'Action',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'validate', name: 'valider' },
            { id: 'refuse', name: 'refuser' },
            { id: 'rectify', name: 'à corriger' },
            { id: 'justify', name: 'à justifier' }
        ],
        filters: [
            {
                name: 'controlActions',
                isDefault: false,
                match: object => {
                    return ['validate', 'refuse', 'rectify'].includes(object.id)
                }
            },
            {
                name: 'validationActions',
                isDefault: false,
                match: object => {
                    return ['validate', 'refuse', 'justify'].includes(object.id)
                }
            }
        ]
    },
    {
        name: 'RefundStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'validated', name: 'validated' },
            { id: 'refused', name: 'refused'},
            { id: 'controlled', name: 'controlled' },
            { id: 'rectification', name: 'rectification' },
            { id: 'justification', name: 'justification' },
            { id: 'paid', name: 'paid' },
            { id: 'waiting', name: 'waiting' },
            { id: 'actualized', name: 'actualized' },
            { id: 'reallocated', name: 'reallocated' },

        ]
    },
    {
        name: 'InvoiceStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'controlled', name: 'controlled' },
            { id: 'validated', name: 'validated' },
            { id: 'refused', name: 'refused'},
            { id: 'justification', name: 'justification' },
            { id: 'paid', name: 'paid' },
            { id: 'waiting', name: 'waiting' },
            { id: 'actualized', name: 'actualized' }
        ]
    },
    {
        name: 'ReliedFundApplicationStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: refundApplicationStatusObjects
    },
    {
        name: 'AdhesionApplicationStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', 'style'],
        objects: adhesionApplicationStatusObjects
    },
    {
        name: 'LiberalityApplicationStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', 'style'],
        objects: liberalityApplicationStatusObjects
    },
    {
        name: 'BatchStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'waiting', name: 'waiting' },
            { id: 'validated', name: 'validated' },
            { id: 'paid', name: 'paid' },
            { id: 'toPay', name: 'toPay' },
            { id: 'actualized', name: 'actualized' }
        ]
    },
    {
        name: 'PaymentStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'controlled', name: 'controlled' },
            { id: 'paid', name: 'paid' }
        ]
    },
    {
        name: 'BenefitRecipient',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'salaried', name: 'salaried' },
            { id: 'retired', name: 'retired' },
            { id: 'rightHolder', name: 'rightHolder' }
        ]
    },
    {
        name: 'Journal',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0000001', name: 'refund' },
            { id: '0000002', name: 'purchase' },
            { id: '0000003', name: 'sale' },
            { id: '0000004', name: 'variousOperation' }
        ]
    },
    {
        name: 'SupplierJournal',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0000001', name: 'bank' },
            { id: '0000002', name: 'sale' },
            { id: '0000003', name: 'purchase' }
        ]
    },
    {
        name: 'Process',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'adhesion', name: 'Adhésion' },
            { id: 'emergencyFund', name: 'Fonds de Secours Exceptionnel' },
            /*
            { id: 'refund', name: 'refundRequest' },
            { id: 'invoice', name: 'supplierInvoice' },
             */
        ]
    },
    {
        name: 'Global',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'exercise', 'code', 'name'],
        objects: [
            { id: 'global', code: 'global',  name: 'global', exercise: ''},
        ]
    },
    {
        name: 'AccountStatementReason',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'submitted', name: 'submitted' },
            { id: 'refused', name: 'refused' },
            { id: 'toRectify ', name: 'toRectify' },
        ]
    },
    {
        name: 'AccountStatementOperation',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'debit', name: 'debit' },
            { id: 'credit', name: 'credit' },
        ]
    },
    {
        name: 'Form',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'exceptionalHelp', name: 'exceptionalHelp' },
            { id: 'advanceOnLegacy', name: 'advanceOnLegacy' },
        ]
    },
    {
        name: 'LandType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', 'name2'],
        objects: [
            { id: 'propertyOfTheAssociation', name: 'propertyOfTheAssociation', name2: 'propertyOfTheAssociation_submission' },
            { id: 'propertyOfTheCommunityOrMetropolis', name: 'propertyOfTheCommunityOrMetropolis', name2: 'propertyOfTheCommunityOrMetropolis_submission' },
            { id: 'rentedByTheAssociation', name: 'rentedByTheAssociation', name2: 'rentedByTheAssociation_submission' },
            { id: 'provisionOfTheAssociation', name: 'provisionOfTheAssociation', name2: 'provisionOfTheAssociation_submission' },
        ]
    }
]
