import _ from 'lodash'

export const entity = {
    name: "AlertFields",
    fields: ["id", "name", "delay", "value", "alertEngineOrder", "themeFieldType", "themeJoin", "denominatorTheme", "denominatorThemeFieldType", "aggOperator", "condOperator"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                global.app.S.AlertConfiguration.find(
                    {
                        group: context.group,
                        fieldPath: [
                            "_id", "name", "delay",
                            "alertFields._id", "alertFields.value", "alertFields.alertEngineOrder.tKey",
                            "alertFields.themeJoin.completeName", "alertFields.themeFieldType.code", "alertFields.denominatorTheme.completeName", "alertFields.denominatorThemeFieldType.code", "alertFields.aggOperator.tKey", "alertFields.condOperator.name"
                        ],
                        query: {}
                    },
                    (e, alertConfs) => {
                        if (e) callback(e)

                        const alertFields = alertConfs.map(alertConf => alertConf.alertFields.map(alertField => {
                            return alertField && {
                                id: `${alertConf.id}_${alertField.id}`,
                                name: alertConf.name,
                                delay: alertConf.delay,
                                themeJoin: alertField.themeJoin && alertField.themeJoin.completeName,
                                themeFieldType: alertField.themeFieldType && alertField.themeFieldType.code,
                                denominatorTheme: alertField.denominatorTheme && alertField.denominatorTheme.completeName,
                                denominatorThemeFieldType: alertField.denominatorThemeFieldType && alertField.denominatorThemeFieldType.code,
                                aggOperator: alertField.aggOperator && alertField.aggOperator.tKey,
                                condOperator: alertField.condOperator && alertField.condOperator.name,
                                value: alertField.value,
                                alertEngineOrder: alertField.alertEngineOrder && alertField.alertEngineOrder.tKey,
                            }
                        }))

                        const objects = _.flatten(alertFields)

                        return callback(null, objects)

                    }
                )
        })
    }
}

export const module_ = {
    object: "AlertFields",
    tKey: "AlertFields Module",
    name: "AlertFieldsModule",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "name",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            "name",
            {path: "delay", tKey: "timeLagInDays"},
            {path: "themeJoin", tKey: "numerator"},
            {path: "themeFieldType", tKey: "amountOrNumber"},
            {path: "denominatorTheme", tKey: "denominator"},
            {path: "denominatorThemeFieldType", tKey: "amountOrNumber"},
            {path: "aggOperator", tKey: "operation", translate: true},
            {path: "condOperator", tKey: "condition", translate: true},
            {path: "value", tKey: "conditionValue"},
            {path: "alertEngineOrder", tKey: "computationOrder"},
        ],
        form: []
    }
}
