import React from 'react'
import {AlertCircle, AlertTriangle} from 'react-feather'
import './Banner.css'

export const InfoBanner = ({ message }) => {
    return (
        <div className="Banner-container" style={{backgroundColor: "rgb(229, 246, 253)", color: "rgb(1, 67, 97)"}}>
            <div className="Banner-icon-container">
                <AlertCircle size={18} style={{color: 'rgb(3, 169, 244)'}}/>
            </div>
            <div className="Banner-message">
                {message}
            </div>
        </div>
    )
}

export const WarningBanner =  ({ message }) => {
    return (
        <div className="Banner-container" style={{backgroundColor: "rgb(255, 244, 229)", color: "rgb(102, 60, 0)"}}>
            <div className="Banner-icon-container">
                <AlertTriangle size={18} style={{color: "rgb(255, 152, 0)"}}/>
            </div>
            <div className="Banner-message">
                {message}
            </div>
        </div>
    )
}
