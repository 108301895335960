import React from 'react'
import { connect } from 'react-redux'
import moment from "moment"
import classnames from 'classnames'
import { Route, withRouter, Link, Switch, Redirect } from 'react-router-dom'
import * as selectors from '../../selectors'
import {
    setSidebarOpen,
    setCategoryCollapse,
    fetchPlatformAlerts,
    fetchPlatformDocuments,
    setTheme
} from '../../actions/index'
import logoKP from '../../../../img/logo_KP.png'
import logoRTE from '../../../../img/logo_RTE.png'
import logoHermes from '../../../../img/logo_Hermes.png'
import logoCNDA from '../../../../img/logo_cnda.svg'
import logoEDF from '../../../../img/logo_EDF.png'
import logoCELIO from '../../../../img/logo_CELIO.png'
import logoUAPL from '../../../../img/logo_uapl.png'
import logoCSE from '../../../../img/logo_mycsemanco.png'
import GroupModel from './GroupModel'
import './SidebarContent.css'
import './GroupModel.css'
import NotFoundRoute from '../NotFoundRoute/NotFoundRoute'
import { translate, I18n } from 'react-i18next'
import { Select } from '../../../../components/Form'
import _ from 'lodash'
import {iconSelector} from '../../../../utils/iconSelector'
import {colourStyles} from '../../../KpModule/components/Filter/SelectStyles'

const GroupModelDropDown = (groupModels, t, fetchPlatformAlerts, fetchPlatformDocuments, setTheme, user, selectedColor) => ({ match, history }) => {

    const pushToHistory = object => {
        history.push(`/business/${!_.isNull(object) ? object.value : ''}`)

        const groupModel = groupModels.find(groupModel => groupModel.id === object.value)

        fetchPlatformAlerts(object.modelId)
        fetchPlatformDocuments(object.modelId, groupModel.profiles, _.get(user, 'language.id') || 'fr')
        setTheme({
            ..._.pick(groupModel, ['backgroundColor', 'menuButtonsColor', 'selectedColor', 'kpLogo']),
            logo: groupModel.group.logo
        })
    }

    let isMultiGroup = _.uniq(groupModels.map(gm => gm.group.name)).length !== 1

    const optionsById = groupModels.reduce((acc, gm) => {
        return {
            ...acc,
            [gm.id]: {
                label: isMultiGroup
                    ? `${gm.name || t(gm.model.name)} (${gm.group.name})`
                    : gm.name || t(gm.model.name),
                value: gm.id,
                modelId: gm.model.id
            }
        }},{})

    const selectOptions = groupModels.map(gm => optionsById[gm.id])

    return (
        <Select
            value={match.params.groupModelId && optionsById[match.params.groupModelId]}
            options={selectOptions}
            styles={colourStyles}
            selectedColor={selectedColor}
            width={12}
            onChange={pushToHistory}
            multi={false}
            isClearable={false}
            placeholder={'selectGroupModel'}
            t={t}
        />
    )
}

const returnButton = groupModel => ({match, history}) => {
    return groupModel ? (
        <div className="SidebarContent-returnButton" >
            <a onClick={() => history.push(`/business/${groupModel.id}`)}>
                {groupModel && groupModel.model.name}
            </a>
        </div>
    ) : null
}

const getLogo = (client) => {
    switch (client) {
        case 'slp-celio':
            return logoCELIO
        case 'edfin':
            return logoEDF
        case 'uapl':
            return logoUAPL
        case 'cse':
            return logoCSE
        case 'rte':
            return logoRTE
        case 'hermes':
            return logoHermes
        case 'cnda':
            return logoCNDA
        default:
            return logoKP
    }
}

export const SidebarContent = ({
    client,
    isUserK,
    isAccountManager,
    groupModels = [],
    collapse,
    setCategoryCollapse,
    setSidebarOpen,
    setTheme,
    fetchPlatformAlerts,
    fetchPlatformDocuments,
    theme,
    device,
    user,
    notifications,
    setNotifications,
    t
}) => {
    let logoSRC = getLogo(client)
    if(!!theme.logo?.name) {
        const fullDate = moment(theme.logo.date).format('YYYY-MM-DD_HH-mm-ss')
        logoSRC = `/image/${fullDate}_${theme.logo.name}`
    }
    return (
        <div className="SidebarContent">
            <div>
                <div className="SidebarContent-logo-container">
                    <img
                        className="SidebarContent-logo"
                        //style={getStyle(client)}
                        id="keenpoint_logo"
                        src={logoSRC}
                        alt="Keenpoint"
                    />
                </div>{' '}
                {groupModels.length > 1 ? (
                    <div className="SidebarContent-dropDown">
                        <Switch>
                            <Route
                                exact
                                path={`/`}
                                render={() => <Redirect to="/business" />}
                            />
                            <Route
                                path="/private"
                                render={GroupModelDropDown(groupModels, t, fetchPlatformAlerts, fetchPlatformDocuments, setTheme, user, theme.selectedColor)}
                            />
                            <Route
                                path="/setting"
                                render={GroupModelDropDown(groupModels, t, fetchPlatformAlerts, fetchPlatformDocuments, setTheme, user, theme.selectedColor)}
                            />
                            <Route
                                path="/business/:groupModelId?"
                                render={GroupModelDropDown(groupModels, t, fetchPlatformAlerts, fetchPlatformDocuments, setTheme, user, theme.selectedColor)}
                            />
                            <Route component={NotFoundRoute} />
                        </Switch>
                    </div>
                ) : (
                    <Switch>
                        <Route
                            path="/setting"
                            render={returnButton(groupModels[0])}
                        />
                        <Route
                            path="/private"
                            render={returnButton(groupModels[0])}
                        />
                    </Switch>
                )}
            </div>
            <div className={
                classnames(
                    'SidebarContent-modules',
                    {
                        fullLength: groupModels.length <= 1,
                        isAccountManager,
                        isUserK,
                    }
                )
            }>
                <div className="SidebarContent-groupModules">
                    <Route
                        path="/business/:groupModelId/:category?/:moduleId?"
                        render={({ match }) => (
                            <NotFoundRoute params={match.params}>
                                {({ groupModel }) => (
                                    // add key in I18n to make it properly reload the namespaces
                                    groupModel
                                        ? (
                                            <I18n
                                                key={groupModel.model.id}
                                                ns={[groupModel.model.id, 'platform']}
                                            >
                                                {t => (
                                                    <GroupModel
                                                        device={device}
                                                        setSidebarOpen={setSidebarOpen}
                                                        groupModel={groupModel}
                                                        group={groupModel.group}
                                                        model={groupModel.model}
                                                        selectedCategory={
                                                            match.params.category
                                                        }
                                                        selectedModuleId={
                                                            match.params.moduleId
                                                        }
                                                        collapse={collapse}
                                                        setCategoryCollapse={
                                                            setCategoryCollapse
                                                        }
                                                        selectedColor={theme.selectedColor}
                                                        user={user}
                                                        isUserK={isUserK}
                                                        notifications={notifications}
                                                        setNotifications={setNotifications}
                                                        t={t}
                                                    />
                                                )}
                                            </I18n>
                                        )
                                        : null
                                )}
                            </NotFoundRoute>
                        )}
                    />
                </div>
                <div className="technicalMenu">
                    <Route
                        path="/business/:groupModelId"
                        render={({ match }) => {
                            return (
                                <NotFoundRoute params={match.params}>
                                    {({ groupModel }) => {
                                        if (groupModel) {
                                            if (groupModel.super_ || isAccountManager || isUserK) {
                                                return (
                                                    <div>
                                                        <Route
                                                            path="/business/:groupModelId"
                                                            render={({ match }) => {
                                                                return (
                                                                    <NotFoundRoute params={match.params}>
                                                                        {({ groupModel }) => {
                                                                            if (groupModel) {
                                                                                if (groupModel.super_ ||  isUserK) {
                                                                                    return (
                                                                                        <Link
                                                                                            to={`/business/${groupModel.id}/m-T-profiles`}
                                                                                            className={classnames(
                                                                                                'technicalMenu-link',
                                                                                                {active: match.url === `/business/${groupModel.id}/m-T-profiles`})
                                                                                            }
                                                                                            onClick={() =>
                                                                                                device !== 'desktop' &&
                                                                                                setSidebarOpen(false)}
                                                                                        >
                                                                                            {iconSelector( 'clipboard', 'technicalMenu-icon')}
                                                                                            {t('profiles')}
                                                                                        </Link>
                                                                                    )
                                                                                }
                                                                                return null
                                                                            }
                                                                            return null
                                                                        }}
                                                                    </NotFoundRoute>
                                                                )
                                                            }}
                                                        />
                                                        <Route
                                                            path="/business/:groupModelId"
                                                            render={({ match }) => {
                                                                return (
                                                                    <NotFoundRoute params={match.params}>
                                                                        {({ groupModel }) => {
                                                                            if (groupModel) {
                                                                                if (groupModel.super_ || isUserK) {
                                                                                    return (
                                                                                        <Link
                                                                                            to={`/business/${groupModel.id}/m-T-groupmodel-team`}
                                                                                            className={classnames(
                                                                                                'technicalMenu-link',
                                                                                                {active: match.url === `/business/${groupModel.id}/m-T-groupmodel-team`})
                                                                                            }
                                                                                            onClick={() =>
                                                                                                device !== 'desktop' &&
                                                                                                setSidebarOpen(false)}
                                                                                        >
                                                                                            {iconSelector( 'user-check', 'technicalMenu-icon')}
                                                                                            {t('groupmodel-team')}
                                                                                        </Link>
                                                                                    )
                                                                                }
                                                                                return null
                                                                            }
                                                                            return null
                                                                        }}
                                                                    </NotFoundRoute>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return null
                                        }
                                        return null
                                    }}
                                </NotFoundRoute>
                            )
                        }}
                    />
                    {(isAccountManager || isUserK) && (
                        <Link
                            to={`/setting/m-T-users`}
                            onClick={() =>
                                device !== 'desktop' && setSidebarOpen(false)}
                        >
                            {iconSelector( 'users', 'technicalMenu-icon')}
                            {t('users')}
                        </Link>
                    )}
                    {isUserK && (
                        <Link
                            to={`/setting/m-T-group`}
                            onClick={() =>
                                device !== 'desktop' && setSidebarOpen(false)}
                        >
                            {t('group_plural')}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    groupModels: selectors.getUserGroupModels(state),
    isAccountManager: selectors.isAccountManager(state),
    isUserK: selectors.isUserK(state),
    user: selectors.getUserData(state),
    collapse: state.ui.categoryCollapse,
    device: state.ui.device,
    client: state.ui.client.client,
    theme: state.ui.theme
})

const mapDispatchToProps = {
    setTheme,
    setCategoryCollapse,
    setSidebarOpen,
    fetchPlatformAlerts,
    fetchPlatformDocuments
}

export default withRouter(
    translate('platform')(
        connect(mapStateToProps, mapDispatchToProps)(SidebarContent)
    )
)
