import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import BsCollapse from 'react-bootstrap/lib/Collapse'
import PropTypes from 'prop-types'

class Collapse extends Component {
    // Required by onClickOutSide
    handleClickOutside = (...args) => {
        this.props.opened &&
            this.props.onClickOutside &&
            this.props.onClickOutside(args)
    }

    render() {
        return (
            <BsCollapse in={this.props.opened}>
                {this.props.children}
            </BsCollapse>
        )
    }
}

const WrappedCollapse = onClickOutside(Collapse)

WrappedCollapse.propTypes = {
    opened: PropTypes.bool.isRequired,
    handleClickOutside: PropTypes.func
}

export default WrappedCollapse
