import Errors from "../../../../utils/Errors";
import {basicContext} from "../../../../utils/contextUtils";
import {changeFieldDisabled, setFieldEdition, setFieldVisibility} from "../../../../../apps/KpModule/actions";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const entity = {
    name: 'AnalyticalAxis',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: false, uniqueCode: true}
    ],
    fields: [
        {path: 'isProjectAxis', type:'boolean'},
        {path: 'isBudgetAxis', type:'boolean'},
    ],
    uniqueProcess: function ( ID, isProjectAxis, context, callback) {
        if ( isProjectAxis ){
            return global.app.SE.AnalyticalAxis.find({
                ...basicContext(context),
                fieldPath: [],
                query: {
                    "_id": {$ne: global.ObjectID(ID)},
                    "isProjectAxis": true
                }
            }, (e, axis) => {
                if (!!axis && !!axis.length){
                    return callback(new Errors.ValidationError("Un seul axe doit avoir la case « Catégorie 1 : projet » cochée"))
                }
                return callback()
            })
        }
        callback()
    },
    validateSave: function (newObject, oldObject, context, callback) {
        this.uniqueProcess( newObject.id, newObject.isProjectAxis, context, callback)
    },
}

export const module_ = {
    object: 'AnalyticalAxis',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'}
        ],
        form: {
            fields : [
                {path: 'code', editable: false},
                {path: 'name', type: 'textarea', unique: true},
                {path: 'isProjectAxis', tKey: 'projectProcess', unique: true},
                {path: 'isBudgetAxis',  tKey: `budgetAxis`},
            ],
            dataLists: [
                "AnalyticalAxis-Budget"
            ],
            onOpen: ({ module, state, store }) => {
                const objectMode = state.ui.objectMode
                const objectID = state.edit.object.id
                const isBudgetAxisField = module.viewMap.form.fields.find(field => field.path === 'isBudgetAxis')
                const budgets = getDataListList(state, 'AnalyticalAxis-Budget')
                const relatedAnalyticalAxes = budgets.map( budget => budget.analyticalMesh?.attachment.map(att => att.analyticalAxis.id)).flat(1)
                const axisIsEnteredInBudget = relatedAnalyticalAxes.includes(objectID)
                store.dispatch(changeFieldDisabled(isBudgetAxisField.id, objectMode !== 'newObject' && isBudgetAxisField.getValue() && axisIsEnteredInBudget))
            },
        },
    },
    accesses: [
        {
            id: "AnalyticalAxis-Budget",
            entity: "Budget",
            fieldPath: ['id', 'analyticalMesh'],
        },
    ]
}