import _ from "lodash";
import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {hasDuplicates} from "../../utils/usefulFunctions";
import Errors from "../../../../utils/Errors";
import {translateName} from "../../../../../utils/i18n";

export const entities = [
    {
        name: 'Entity',
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: true}
        ],
        fields: [
            {path: 'entityType', type:'EntityConfiguration'},
            {
                path: 'attachments',
                type: 'StructureElement',
                link: 'MTM'
            },
            /*
            {path: 'attachmentsCombination', type: 'EntityAttachmentsRules'},
            {
                path: 'entityAttachments',
                type: 'EntityAttachment',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            }

             */
        ],
        filters: [
            {
                name: 'byEntityType',
                tKey: 'entityType',
                isDefault: false,
                client: true,
                path: 'entityType',
                object: 'EntityConfiguration',
                display: "name",    //This fucking line is obligatory apparently
                translateName: true,
                clearable: false,
                query: function(context) {
                    const entityTypeID = _.get(context, 'data.entityType.id')
                    return entityTypeID ? {"entityType": {$eq: global.ObjectID(entityTypeID)}} : {_id: null}
                }
            }
        ],
        beforeSave: function (newObject, oldObject, context, callback) {
            const attachedToThisAxesIds = newObject.attachments.map(obj => global.ObjectID(obj.structureAxis.id).toString())
            const respectAttachmentsRules = newObject.entityType.entityAttachmentsRules.length === 0
                || newObject.entityType.entityAttachmentsRules.some(rule => {
                        const axesPossibleCombinationIds = rule.axesPossibleCombination.map(obj => global.ObjectID(obj.id).toString())
                        return axesPossibleCombinationIds.every(obligatoryAxesId => attachedToThisAxesIds.includes(obligatoryAxesId))
                   })
            if (!respectAttachmentsRules){
                callback(new Errors.ValidationError('This entity does not respect attachments rules'))
            }
            else {callback(null, newObject, oldObject)}
        }
    },
    /*
    {
        name: 'EntityAttachment',
        type: 'mongoInternal',
        fields: [
            {path: 'axis', type: 'StructureAxes'},
            {path: 'elementOfThisAxis', type: 'StructureElement'},
        ]
    }

     */
]

export const module_ = {
    object: 'Entity',
    tKey: 'elementaryEntity',
    category: {
        path: 'structure',
        icon: 'layers'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name', type: 'translatedText'},
            {path: 'entityType', translateName: true},
            {path: 'attachments', translateName: true}
        ],
        form: {
            fields: [
                {
                    path: 'entityType',
                    translateName: true,
                    fieldPath: ['id', 'name',
                        //'structures',
                        'entityAttachmentsRules'],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const attachmentsField = module.viewMap.form.fields.find( field => field.path === 'attachments')
                            attachmentsField.setValue(null)

                            const axesPossibleCombinationIds = (newValue?.entityAttachmentsRules || []).flatMap(rule => {
                                return rule.axesPossibleCombination.map(obj => obj.id)
                            })

                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entity.Entity_attachments",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            axesPossibleCombinationIds: axesPossibleCombinationIds
                                        }
                                    }
                                )
                            )
                        }
                    }
                },
                {path: 'code'},
                {path: 'name', type: 'textarea', unique: true, placeholder: 'fr:: nom en français\nen:: name in english\n...'},
                {
                    path: 'attachments',
                    display: 'nameWithAxis',
                    filters: ['eliminateSelectedAndIncoherentOptions'],
                    fieldPath: [ 'id', 'nameWithAxis', 'structureAxis'],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const entityTypeField = module.viewMap.form.fields.find( field => field.path === 'entityType')
                            const entityType = entityTypeField && entityTypeField.getValue()
                            console.log('newValue', newValue, entityType?.entityAttachmentsRules)
                            const axesPossibleCombinationIds = (entityType?.entityAttachmentsRules || [])
                                .filter(rule => !newValue || newValue.every(obj => rule.axesPossibleCombination.map(axis => axis.id).includes(obj.structureAxis.id)))
                                .flatMap(rule => rule.axesPossibleCombination.map(obj => obj.id))
                            console.log('axesPossibleCombinationIds', axesPossibleCombinationIds)
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entity.Entity_attachments",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            //structures: entityType?.structures,
                                            axesPossibleCombinationIds: axesPossibleCombinationIds,
                                            selectedElements: newValue
                                        }
                                    }
                                )
                            )
                        }
                    }
                }
            ],
        }
    },
    filters: ['byEntityType']
}
