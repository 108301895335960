import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './BsButton.css'

const BsButton = ({
    bsStyle = 'default',
    type = 'button',
    children,
    className,
    ...props
}) => (
    <button
        type={type}
        className={classnames(
            `btn btn-${bsStyle} BsButton`,
            className
        )}
        {...props}
    >
        {children}
    </button>
)

BsButton.propTypes = {
    bsStyle: PropTypes.string,
    buttonClass: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string
}

export default BsButton
