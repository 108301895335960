if (!Math.sign) {
    Math.sign = function(x) {
        // Si x vaut NaN, le résultat vaudra NaN.
        // Si x vaut -0, le résultat vaudra -0.
        // Si x vaut +0, le résultat vaudra +0.
        // Si x est négatif et différent de -0, le résultat vaudra -1.
        // Si x est positif et différent de +0, le résultat vaudra +1.
        x = +x; // on convertit la valeur en un nombre
        if (x === 0 || isNaN(x)) {
            return Number(x);
        }
        return x > 0 ? 1 : -1;
    };
}