import _ from 'lodash'
import {changeFieldDisabled, changeFieldRequired, changeFieldDefault} from "../../../../../apps/KpModule/actions";

export const businessRevue = {
    name: "businessRevue",
    tKey: "Business revue (k€)",
    object: "BusinessProject",
    category: {
        path: "reporting",
        icon: 'layers'
    },
    removable: false,
    newable: false,
    alerts:[
        {
            alertType: 'redAlert',
            conditions: [
                {rule: 'eq', path: 'status', value: 'formX'},
                {rule: 'ne', path: 'sumActualTurnOver', value: 0}
            ]
        },
        {
            alertType: 'redAlert',
            conditions: [
                {rule: 'eq', path: 'status', value: 'buildOffer'},
                {rule: 'ne', path: 'sumActualTurnOver', value: 0}
            ]
        },
        {
            alertType: 'blueAlert',
            conditions: [
                {rule: 'eq', path: 'status', value: 'submitOffer'},
                {rule: 'ne', path: 'sumActualTurnOver', value: 0}
            ]
        }
    ],
    viewMap: {
        dt: [
            "country",
            {path: "customer", width: 200},
            {path: "typeOfOffer", display: 'fullName', width: 300},
            {path: "fullName", tKey: 'name', width: 400},
            {path: "description", width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},

            {path: "partners", width: 150, initiallyNotVisible: true},
            {path: "subcontractors", width: 150, initiallyNotVisible: true},
            {path: "competitors", width: 150, initiallyNotVisible: true},

            {path: "beginYear", width: 90},
            {path: "orderTakingMonth", width: 90, initiallyNotVisible: true},

            {path: "detectionTurnOver", tooltip: true, width: 150, initiallyNotVisible: true},
            {path: "buildOfferTurnOver", tooltip: true, width: 150, initiallyNotVisible: true},
            {path: "contractualizationTurnOver", tooltip: true, width: 150, initiallyNotVisible: true},

            {path: "expectedTurnOver", tKey: "totalExpectedTurnOver", tooltip: true, width: 150, initiallyNotVisible: true},
            {path: "expectedContractMarginInPercent", width: 150, initiallyNotVisible: true},

            {path: "businessRevueCurrentTurnOver", tooltip: true, width: 130},
            {path: "businessRevueCurrentTurnOverP", tooltip: true, width: 130},
            {path: "businessRevueExecutedTurnOver", tooltip: true, width: 130},
            {path: "businessRevueCurrentYearProbability", tooltip: true, width: 130},
            {path: "businessRevueCurrentProbability", tooltip: true, width: 130, initiallyNotVisible: true},
            {path: "businessRevueNextYearTurnover", tooltip: true, width: 130, initiallyNotVisible: true},


            {path: "sumActualTurnOver", tKey: "totalSumActualTurnover", tooltip: true, width: 130},
            {path: "expectedContractCost", tKey: "totalExpectedContractCost", tooltip: true, width: 130, initiallyNotVisible: true},
            {path: "sumActualContractExpenses", tKey: "totalSumActualContractExpenses", tooltip: true, width: 130, initiallyNotVisible: true},
            {path: "sumCommittedExpenses", tKey: "totalSumCommittedExpenses", tooltip: true, width: 130, initiallyNotVisible: true},


            {path: "sumActualBilled", tKey: "totalSumActualBilled", tooltip: true, width: 150, initiallyNotVisible: true},
            {path: "sumActualCashed", tKey: "totalSumActualCashed", tooltip: true, width: 160, initiallyNotVisible: true},

            {path: "billedMinusCommitted", tooltip: true, width: 130, initiallyNotVisible: true},

            {path: 'risk', display: 'name', initiallyNotVisible: true},
            {path: "commercialTeamMember", display: "fullName", width: 150, initiallyNotVisible: true},
            {
                path: "technicalTeamMember",
                display: "fullName",
                width: 150,
                initiallyNotVisible: true
            },

            {path: "offerSubmissionDate", width: 150, initiallyNotVisible: true},
            {path: "reportedSubmissionDate", width: 150, initiallyNotVisible: true},

            {path: "effectiveOfferSubmissionDate", width: 150, initiallyNotVisible: true},
            {path: "estimatedResponseDate", width: 150, initiallyNotVisible: true},
            {path: "offerAcceptedDate", width: 150, initiallyNotVisible: true},
            {path: "signatureDate", width: 150, initiallyNotVisible: true},

            {path: "currentFunctions", width: 100, initiallyNotVisible: true},
            {path: "completedService"},
            {path: "allPaymentsReceived"},
            {path: "closingDocumentsReceived"},
            {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", initiallyNotVisible: true},
            {path: "statusAndOrder", width: 100},
            {path: "workflow", hidden: true},
            {path: "status", hidden: true},

            {path: "businessRevueCurrentComment", tKey: "comment", initiallyNotVisible: true},

            {path: "file", tKey: "FA"}
        ],
        form: {
            fields: [
                "identifier",
                {path: "name", writable: false},
                {path: "description", type: "richTextEditor", writable: false},
                {path: "developerPitches", tKey: "developerPitch", type: "richTextEditor", writable: false},
                {path: "commercialTeamMember", display: "fullName", writable: false},
                {path: "technicalTeamMember", display: "fullName", writable: false},
                {path: "turnoverDifference", type: "readOnly", tKey: "T.CA (Prévu / Réalisé)"},
                {
                    path: "businessRevues",
                    tKey: "Business revue (k€)",
                    removable: false,
                    sortable: false,
                    viewMap: {
                        dt: [
                            {path: "date", type: "date"},
                            {path: "executedTurnOver", tKey: "CA Réalisé (N)"},
                            {path: "revised", tKey: "T.CA Révisé"},
                            {path: "yearN", tKey: "CA Prévu (N)"},
                            {path: "yearNPlusOne", tKey: "CA Prévu (N+1)"},
                            {path: "comment", width: 400},
                            'user'
                        ],
                        form: [
                            {path: "date", hidden: true},
                            {path: "revised", tKey: "T.CA Révisé", wholePositiveNumber: true, editable: false},
                            {path: "yearN", tKey: "CA Prévu (N)", wholePositiveNumber: true, editable: false},
                            {path: "orderMonth", tKey: 'orderTakingMonth', type: "monthPicker", editable: false},
                            {path: "probability", tKey: "Probabilité (%)", wholePositiveNumber: true, editable: false},
                            {path: "yearNPlusOne", tKey: "CA Prévu (N+1)", wholePositiveNumber: true, editable: false},
                            {path: "comment", type: "textarea", editable: false},
                            {path: "executedTurnOver", hidden: true},
                            {path: "projectStep", hidden: true},
                            {path: 'user', hidden: true}
                        ]
                    }
                },
                {path: "operationsAndServicesContracts", type: 'creatableTags', disabled: true},
                {path: "comments"},

                {path: "files", hidden: true},

                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const {workflow, signatureDate, businessRevues} = state.edit.object.data
                const disabled = signatureDate || (workflow && workflow.step === 'realisation')

                store.dispatch(changeFieldDisabled('e_businessRevues.e_orderMonth', disabled))
                store.dispatch(changeFieldRequired('e_businessRevues.e_orderMonth', !disabled))
                const lastBusinessRevue = _.sortBy(businessRevues, 'date' ).reverse()[0]
                const fieldPaths = ['revised', 'yearN', 'orderMonth', 'probability', 'yearNPlusOne']
                fieldPaths.forEach(path => store.dispatch(changeFieldDefault(
                    `e_businessRevues.e_${path}`,
                    lastBusinessRevue
                        ? lastBusinessRevue[path]
                        : null
                    ))
                )

                /*
                if(beginYear) {
                    const yearMoment = beginYear && moment(beginYear.id, 'YYYY').format('YYYY-MM')
                    const yearPlusOneMoment = beginYear && moment(beginYear.id, 'YYYY').add(1, 'years').format('YYYY-MM')
                    store.dispatch(setFieldStartDate('e_businessRevues.e_orderMonth', yearMoment))
                    store.dispatch(setFieldEndDate('e_businessRevues.e_orderMonth', yearPlusOneMoment))
                }
                 */
            }
        }
    },
    filters: [
        "byZone",
        "byWorkflowStepBR"
    ]
}
