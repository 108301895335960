const { textSearchQuery } = require("../../utils/mongoUtils")
const _ = require("lodash")
const Errors = require('../../utils/Errors').default

export const entity = {
    name: "Product",
    facets: ["translatedField"],
    fields: [
        {path: "code", index: true, unique: true},
        {path: "name", index: true, notEmpty: true},
        {
            type: "Family",
            link: "MTM",
            ps: {
                object: [
                    {
                        $v: function (familys) {
                            if(!familys.length) {
                                return new Errors.ValidationError(this.options.context.tc("theProjectShouldBeLinkedAtLeastOneFamily"));
                            }
                            const familyAxes = familys.map(family => family.familyAxis.id);
                            const countedAxes = _.countBy(familyAxes);
                            if (_.some(countedAxes, value => value > 1)) {
                                return new Errors.ValidationError(this.options.context.tc("eachProjectShouldBeLinkedToDistinctFamilyAxis"));
                            }
                        }
                    }
                ]
            }
        },
        {
            path: "numberOfFamilies",
            fieldPath: ["familys.id"],
            f: function() {
                return this.familys ? this.familys.length : 0;
            }
        },
        {
            path: "familiesNames",
            fieldPath: ["familys.id", "familys.completeName"],
            f: function() {
                return this.familys ? this.familys.map(o => o.completeName).join(', ') : '';
            }
        }
    ],
}

export const module_ = {
    object: "Product",
    tKey: "mTitle_product",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: "code",
    defaultSortDirection: "ASC",
    maxResults: 2000,
    viewMap: {
        dt: [
            "code",
            {path: "name", type: "translatedText"},
            {path: "numberOfFamilies", tKey: "attachment_number"}
        ],
        form: [
            {path: "code", editable: false, required: true},
            {path: "name", type: "textarea"},
            {path: "familiesNames", tKey: "attachment_number", type: 'readOnly', writable: false}
        ]
    },
    filters: [
        {
            title: "search",
            path: "search",
            tKey: "fillReference",
            client: true,
            type: "text",
            timer: 3,
            query: context => {
                const search = context.data && context.data.search;
                const keys = ["code", "name"];

                return textSearchQuery(search, keys);
            }
        }
    ],
    removable: false
}
