export const entities = [
    {
        name: "ExchangeRate",
        fields: [
            {path: 'year', type: 'integer', notEmpty: true},
            {path: 'txVsEur', type: 'integer'}
        ]
    },
    {
        name: 'Currency',
        fields: [
            {path: 'code', unique: true, notEmpty: true, index: true},
            {path: 'name', unique: true, notEmpty: true},
            {path: 'reference', type: 'boolean'},
            {type: 'ExchangeRate', link: "OTM"}
        ]
    }
]

export const module_ = {
    object: 'Currency',
    tKey: 'mTitle_currency',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'code','name'
        ],
        form: [
            {path: 'code', editable: false, required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (!formInitialize && newValue && newValue.length > 0) {
                            const field = module.viewMap.form.fields.find(
                                field => field.path === 'reference'
                            )
                            if(newValue === 'EUR') {
                                field.setValue(true)
                            } else {
                                field.setValue(false)
                            }
                        }
                    }
                }
            },
            {path: "name", required: true},
            {path: 'reference', disabled: true},
            {
                path: 'exchangeRates',
                defaultSortBy: 'year',
                defaultSortDirection: 'ASC',
                viewMap: {
                    dt: [
                        'year', 'txVsEur'
                    ],
                    form: [
                        {path: 'year', editable: false, conversionRateYear: true},
                        {path: 'txVsEur', default: 1, positiveNumber: true}
                    ]
                }
            }
        ]
    }
}
