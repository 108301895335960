export const entity = {
    name: "Tag",
    fields: [
        {path: "code", unique: true},
        {type: "Sku", unique: true}
    ],
}

export const module_ = {
    object: "Tag",
    tKey: "Tag",
    category: {
        path: "referential",
        icon: 'server'
    },
    maxResults: 2000,
    viewMap: {
        dt: [
            "code",
            {path: "sku", display: "code"}
        ],
        form: [
            {path: "code", editable: false, required: true},
            {path: "sku", display: "code", required: true}
        ]
    }
}
