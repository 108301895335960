import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import _ from "lodash";

const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')
const { setFieldVisibility, setDataList } = require('../../../../../apps/KpModule/actions')

export const montage = {
    name: "bp2",
    object: "BusinessProject",
    category: {
        path: "projects",
        icon: 'briefcase'
    },
    step: "buildOffer",
    newable: false,
    removable: false,
    workflowActions: [
        'validate',
        'question',
        'notSubmitted'
    ],
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", width: 200, initiallyNotVisible: true},
            {path: "typeOfOffer", width: 300, initiallyNotVisible: true},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "description", type: 'richText', width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},
            {path: "beginYear", width: 100, initiallyNotVisible: true},

            {path: "expectedTurnOver", tooltip: true, width: 80},
            {path: "expectedContractCost", tooltip: true, width: 80},
            {path: "expectedContractMarginInPercent", width: 80},
            {path: "expectedNumberOfDays", tooltip: true, width: 80, initiallyNotVisible: true},

            {path: "offerSubmissionDate", width: 150},
            {path: "reportedSubmissionDate", width: 150, initiallyNotVisible: true},

            {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", initiallyNotVisible: true},
            {path: "currentFunctions", width: 100},
            {path: "file", tKey: "FA"},

            {path: 'orangeStyledRow', hidden : true},
            {path: 'redStyledRow', hidden : true}
        ],
        form: {
            fields: [
                "identifier",
                {path: "country", writable: false, required: false, hidden: true},
                /*
                {
                    path: "country", tKey: "country", display: "fullInformations", writable: false,
                    filters: ["inUserTeam"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            if (newValue && newValue.id) {
                                const dataCountry = {country: {id: newValue.id}}
                                store.dispatch(
                                    (
                                        "m-I-bp2.BusinessProject_customer",
                                        store.getState,
                                        {data: dataCountry}
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions("e_customer", []))
                            }
                        }
                    }
                },
                 */
                {path: "typeOfOffer", display: "nameWithRange"},
                {path: "name", writable: false, required: false},
                {path: "description", type: "richTextEditor", required: false, editable: false},
                {path: "developerPitch", writable: false, tKey: 'detectionDeveloperPitch', type: "richTextEditor"},
                {path: "developerPitch2",tKey: "buildOfferDeveloperPitch", type: "richTextEditor", required: true},
                {path: "beginYear", editable: false},
                {path: "customer", display: "codeNameRelation", required: true},
                {path: "estimatedTurnover", editable: false},
                "globalTurnover",
                {path: "marginOverContract", required: true},
                {path: "resourceTypes", required: true},
                {path: "estimatedNumberOfDays", tKey: "estimatedNumberOfDaysForRealisation", required: true},

                {path: "responseMode", required: true},
                {path: "offerSubmissionDate", writable: false, required: false},
                "reportedSubmissionDate",
                {
                    path: "businessProject",
                    tKey: "relatedToProject",
                    autoList: false,
                    display: "name",
                    filters: ["byReferentialBusiness"],
                    writable: false,
                    fieldPath: [
                        "id",
                        "name",
                        "referentialBusinesss.country.id",
                        "referentialBusinesss.businessProject.id"
                    ]
                },
                {path: "projectFunding", required: true},
                {path: "compensationModes", required: true},
                {path: "interventionMode", required: true},
                {path: "contractualSchema", required: true},
                {path: "needLocalStructure"},
                {path: "partners", filters: ["partners"], required: false},
                {path: "subcontractors", filters: ["subcontractors"], required: false},
                {path: "competitors", filters: ["competitors"], required: false},
                {path: "buildOfferNumberOfDays", tKey: 'buildOfferNumberOfDaysBuildOffer', required: true},
                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], required: true},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"], disabled: true},
                {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", filters: ["projectMonitor"]},
                {path: "projectManager", disabled: true},
                {path: "projectTeam", type: "textarea", disabled: true},

                {path: "comments", tKey: "comment_plural"},
                {path: "files", tKey: "file_plural"},

                {path:"delegatedUsers", filters:["byCountry"], fieldPath: ["id", "userName", "firstName", "lastName", "email"], display: "userName"},

                {path: "delegatedUsersGetter", hidden: true},
                {path: "buttons", hidden: true},
                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}
                const businessProject = state.edit.object.data.businessProject

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-bp2.BusinessProject_delegatedUsers",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
                store.dispatch(setFieldVisibility(`e_businessProject`, !!businessProject))
                if(!!businessProject) {
                    store.dispatch(
                        setDataList(
                            "m-I-bp2.BusinessProject_businessProject",
                            [businessProject]
                        )
                    )
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const delegatedUsers = _.get(state, 'edit.object.data.delegatedUsersGetter')
                const delegatedUsersField = module.viewMap.form.fields.find(field => field.id === "e_delegatedUsers")
                delegatedUsersField.setValue(delegatedUsers)
            }
        }
    ],
    filters: [
        "byTypeOfOffer",
        {query: () => ({"workflow.step": "buildOffer"})}
    ]
}
