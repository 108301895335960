import {setFieldStartDate} from "../../../../../apps/KpModule/actions";

export const ContractMonitoring = {
    object: "ContractMonitoring",
    name: 'contractMonitoring',
    category: {
        path: "technical",
        icon: "layers"
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            'contractFullId',
            {path: 'identifier', initiallyNotVisible: true},
            {path: 'type', initiallyNotVisible: true},
            {path: 'number', initiallyNotVisible: true},
            {path: 'year', initiallyNotVisible: true},
            'wording',
            {path: 'analyticalCode', initiallyNotVisible: true},
            {path: 'origin', initiallyNotVisible: true},
            {path: 'providerAndCustomer'},
            {path: 'businessProjectsFN', tKey: 'project', initiallyNotVisible: true},
            {path: 'technicalTeamMember', initiallyNotVisible: true},
            {path: 'zone', initiallyNotVisible: true},
            {path: 'country', initiallyNotVisible: true},
            {path: 'state'},
            {path: 'version', initiallyNotVisible: true},
            {path: 'kickOff', initiallyNotVisible: true},
            {path: 'begin', initiallyNotVisible: true},
            {path: 'end', initiallyNotVisible: true},
            {path: 'status'},
            {path: 'amount', tKey: 'amountKE_abbreviation'}
        ],
        form: [
            {path: 'contractFullId', disabled: true},
            {path: 'wording', required: true},
            {path: 'type', editable: false},
            {path: 'year', required: true, editable: false},
            {path: 'analyticalCode', editable: false},
            {path: 'provider', display: 'fullName', required: false},
            {path: 'customer', display: 'fullName', required: false},
            {
                path: 'businessProjects',
                tKey: 'project',
                translate: false,
                display: 'fullName',
                filters: [{
                    name: 'isUserInCountryScope',
                    query: () => ({})
                }]
            },
            {path: 'state'},
            /*
            {path: 'version', editable: false},
            {
                path: 'kickOff',
                subscriptions: {
                    onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                        store.dispatch(setFieldStartDate('e_end', newValue))
                    }
                }
            },

             */
            {path: 'begin'},
            {path: 'end'},
            {path: 'status'},
            {path: 'amount', positiveNumber: true, default: 0, tKey: 'amountKE_abbreviation'},
            'comments',
            'files'
        ]
    },
    filters: ["byProvider", "byCustomer", "byStatus"]
}
