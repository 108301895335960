import moment from 'moment'
import {getData} from './accRequests'
import {
    data,
    getLastMonthOfLastFile,
    getPreviousExerciseLastMonth,
    getPreviousYearMonth
} from '../utils/stateComputationUtils'
import _ from 'lodash'

async function findData() {

    const previousMonthISO = moment().subtract(1, "months")
    const previousMonth = previousMonthISO.format('YYYY-MM')

    const lastMonthFromLastFile = await getLastMonthOfLastFile(previousMonth)
    const previousExerciseLastMonth = getPreviousExerciseLastMonth(moment().format('YYYY-MM'))
    const lastMonthFromLastFilePreviousYear = getPreviousYearMonth(lastMonthFromLastFile)

    const formatedPreviousMonth = moment(lastMonthFromLastFile, 'YYYY-MM').format('MMM YYYY')
    const formatedLastYearPreviousMonth = moment(lastMonthFromLastFilePreviousYear, 'YYYY-MM').format('MMM YYYY')

    const finalResult = await getData('AGR', lastMonthFromLastFile, lastMonthFromLastFilePreviousYear, previousExerciseLastMonth)

    const totalCurrentYear = _.round(
        finalResult.reduce((acc,o) => acc + (o[lastMonthFromLastFile] || 0), 0),
        2
    )
    const totalPreviousYear = _.round(
        finalResult.reduce((acc,o) => acc + (o[lastMonthFromLastFilePreviousYear] || 0), 0),
        2
    )

    return [
        {
            id: "accAgrTurnoverBySurFamily",
            subTitle: {
                text: 'accTurnoverBySurFamilySubtitle',
                data: {
                    lastYear: formatedLastYearPreviousMonth,
                    lastYearTotal: totalPreviousYear,
                    currentYear: formatedPreviousMonth,
                    currentYearTotal: totalCurrentYear
                }
            },
            dataKeys:[lastMonthFromLastFile, lastMonthFromLastFilePreviousYear],
            data: finalResult
        }
    ]
}
export const entity = {
    name: 'AccAgrTurnoverBySurFamily',
    dashboard: true,
    fields: ["id", "subTitle", "dataKeys", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
