import { combineReducers } from 'redux'
import {
    INITIALIZE_MODULE,
    SET_FORM_OBJECT,
    SET_UI_MODE,
    SET_OBJECT_MODE
} from '../actions/index'
import {
    GET_OBJECT_FAILURE,
    GET_OBJECT_REQUEST,
    CREATE_OBJECT_REQUEST,
    CREATE_OBJECT_SUCCESS,
    CREATE_OBJECT_FAILURE,
    SAVE_OBJECT_REQUEST,
    SAVE_OBJECT_SUCCESS,
    SAVE_OBJECT_FAILURE
} from '../actions/api'
import { SET_LANGUAGE } from '../actions'
import _ from 'lodash'

const language = (state = 'en', action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
        case SET_LANGUAGE:
            return action.language
        default:
            return state
    }
}

const form = (state = {loading: false}, action) => {
    switch (action.type) {
        case CREATE_OBJECT_REQUEST:
        case SAVE_OBJECT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_OBJECT_SUCCESS:
        case SAVE_OBJECT_SUCCESS:
        case CREATE_OBJECT_FAILURE:
        case SAVE_OBJECT_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

const mode = (state = 'list', action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return action.module.defaultPanel
        case CREATE_OBJECT_SUCCESS:
        case SAVE_OBJECT_SUCCESS:
            return _.get(action, 'meta.module.defaultPanel') || 'list'
        case GET_OBJECT_FAILURE:
            return 'list'
        case SET_FORM_OBJECT:
        case GET_OBJECT_REQUEST:
            return 'form'
        case SET_UI_MODE:
            return action.mode
        default:
            return state
    }
}

const objectMode = (state = '', action) => {
    switch (action.type) {
        case SET_OBJECT_MODE:
            return action.objectMode
        case SAVE_OBJECT_SUCCESS:
        case CREATE_OBJECT_SUCCESS:
            const isFormPanel = _.get(action, 'meta.module.defaultPanel') === 'form'
            return isFormPanel ? 'editObject' : ''
        default:
            return state
    }
}

const ui = combineReducers({
    form,
    language,
    mode,
    objectMode
})

export default ui
