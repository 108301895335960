import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import WrappedMenu from '../../../../components/Menu'
import { translate } from 'react-i18next'
import './Navbar.css'
import {User, LogIn} from 'react-feather'

const Profile = ({ profileOpened, setProfileOpened, logout, user, t, color }) => {
    return (
        <div
            className={classnames('dropdown', {
                open: profileOpened
            })}
        >
            <button
                className="navbar-icon-container"
                onClick={() => setProfileOpened(!profileOpened)}
            >
                <User className="navbar-icon profile-icon" color={color}/>
            </button>
            <WrappedMenu
                opened={profileOpened}
                onClickOutside={() => setProfileOpened(false)}
                outsideClickIgnoreClass={'profile-icon'}
                position="left"
            >
                <ul
                    className="Navbar-Menu-list"
                    aria-labelledby="dropDownMenuUser"
                >
                    <li className="loggedUser" style={{marginLeft: '30px', color: 'rgb(52, 71, 103)'}}>{user && user.name}</li>
                    <li className="divider" />
                    <li>
                        <Link
                            to={`/private/m-T-my-account`}
                            className="Navbar-Menu-item"
                            onClick={() => {
                                setProfileOpened(false)
                            }}
                        >
                            <User
                                className="navbar-icon"
                            />
                            {t('my-account')}
                        </Link>
                    </li>
                    <li
                        className="Navbar-Menu-item"
                        onClick={() => {
                            setProfileOpened(false)
                            logout()
                        }}
                    >
                        <LogIn className="navbar-icon"/>
                        <a>{t('logout')}</a>
                    </li>
                </ul>
            </WrappedMenu>
        </div>
    )
}

Profile.propTypes = {
    profileOpened: PropTypes.bool.isRequired,
    setProfileOpened: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

export default translate('platform')(Profile)
