export const entity = {
    name: "ManualReferenceDate",
    fields: [
        "code",
        {path: "period", type: "dateRange"},
        {path: "active", type: "boolean"},
        {path: "alertConfigurations", type: "AlertConfiguration", link: "MTM", nullable: true},
        {path: "scoringConfigs", type: "ScoringConfig", link: "MTM", nullable: true}
    ]
}

export const module_ = {
    object: "ManualReferenceDate",
    tKey: "mTitle_referenceDate",
    category: {
        path: "setting",
        icon: "settings"
    },
    viewMap: {
        dt: [
            "code",
            {path: "period", type: "dateRange"},
            {path: "active"}
        ],
        form: [
            "code",
            {path: "period", type: "dateRange"},
            {path: "alertConfigurations", tKey: "alertConfiguration", display: "tName"},
            {path: "scoringConfigs", tKey: "scoringConfig", display: "tName"},
            {path: "active"}
        ]
    }
}
