import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './InputCell.css'

export class InputCell extends Component {
    render() {
        const { className, ...otherProps } = this.props

        return (
            <input
                className={classnames("DataTable-InputCell", className)}
                {...otherProps}
            />
        )
    }
}

InputCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired
}

export default InputCell