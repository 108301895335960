const { fieldPathJoinGetter } = require("../../../utils/kp3Utils");

export const entity = {
    name: 'Sublot',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields:[
        'Lot',
        {
            path: 'lotCode',
            fieldPath: ['lot.id', 'lot.code'],
            f: function () {
                return this.lot && this.lot.code
            }
        },
        {
            path: 'lotProjectCode',
            fieldPath: ['lot.id', 'lot.project', 'lot.project.code'],
            f: function () {
                return this.lot && this.lot.project && this.lot.project.code
            }
        },
        {
            path: 'lotProject',
            fieldPath: ['lot.id', 'lot.project', 'lot.project.fullName'],
            f: function () {
                return this.lot && this.lot.project && this.lot.project.fullName
            }
        },
        fieldPathJoinGetter({
            path: "fullNameWithLotAndProject",
            fieldPath: ["lotProjectCode", "lotCode", "fullName"],
            joinString: " - "
        })
    ]
}
export const module_ = {
    object: 'Sublot',
    tKey: 'mTitle_sublot',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'lotProject', tKey: 'project'},
            {path: 'lot', display: 'fullName'},
            'code','name'
        ],
        form: [
            {path: 'lot', display: 'fullName'},
            'code', 'name'
        ]
    }
}
