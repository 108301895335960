const Errors = require("../../utils/Errors").default

export const entity = {
    name: 'HabilitationPerimeter',
    fields:[
        {type: "User", unique: true},
        {type: 'Company', link:'MTM'},
        {type: 'SurFamily', link:'MTM'},
        {type: 'Zone', link:'MTM'},
        {type: 'Deposit', link:'MTM'},
    ],
    validateSave: function(object, oldObject, context, callback) {
        const {companys, surFamilys, zones, deposits} = object
        const isCompany = companys && (companys.length !== 0)
        const isSurFamily = surFamilys && (surFamilys.length !== 0)
        const isZone = zones && (zones.length !== 0)
        const isDeposit = deposits && (deposits.length !== 0)

        const habilitations = [isCompany, isSurFamily, isZone, isDeposit]

        const userHabilitations = habilitations.filter(hab => hab)

        if(userHabilitations.length < 2) return callback()
        else return callback(new Errors.ValidationError("choseOnlyOneMesh"))
    },
}

export const module_ = {
    object: 'HabilitationPerimeter',
    tKey: 'mTitle_habilitationPerimeter',
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    category: {
        path: 'keenpoint',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path:'user', display: 'name'}
        ],
        form: [
            {path: 'user', display: 'name', required: true, editable: false, clearable: false},
            {path: 'companys', tKey:'company_plural', display: 'fullName', required: false},
            {path: 'surFamilys', tKey:'surFamily_plural', display: 'fullNameWithCompanyCode', required: false},
            {path: 'zones', display: 'fullName', required: false},
            {path: 'deposits', tKey:'deposit_plural', display: 'fullName', required: false}
        ]
    }
}
