import React from 'react'
import { Field } from 'redux-form'
import { TextField } from '../../../../components/Form'
import getFormValidations from './formValidations'
import {translateName} from '../../../../utils/i18n'

// binding for redux-form
const Input = ({ input, meta:{error}, language, t }) => {

    const translatedValue = translateName(input.value, language)
    const translatedError = t(error)

    return <TextField type="text" onChange={() => console.log('cannot change this value')} value={translatedValue} error={translatedError} disabled={true}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormTranslatedTextField = ({ field, t, objectMode, language }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            component={Input}
            translate={field.translate}
            language={language}
            t={t}
        />
    )
}

export default FormTranslatedTextField
