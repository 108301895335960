const moment = require('moment/moment')
const async = require('async')
const _ = require('lodash')
const Errors = require("../../../utils/Errors").default
const { basicContext } = require("../../../utils/contextUtils");
const { getRefundsByExercise, getInvoicesByExercise } = require("../utils/refundAndInvoiceUtils");
const {sendUserMail} = require('../mails/userInfoUpdate')
const {sendNotificationMail} = require('../mails/userRefund')
const {returnButton} = require('../utils')
const {workflowMailing} = require('../mails/reallocationWorkflowMails')
const {generateExcel} = require('../utils/generateExcel')
const {writeXml} = require('../utils/writeXml')
const {validateForXmlGeneration} = require('../utils/validateXml')

const updateMany = function(entity, query, set, push, callback) {
    const pushQuery = push ? {$push: push} : {}
    const collection = global.app.R[entity].collection
    collection.updateMany(
        query,
        {$set: set, ...pushQuery},
        (e) => {
            if (e) {
                console.warn(e)
                callback(e)
            } else {
                callback()
            }
        }
    )
}

export const entity = {
    name: 'Batch',
    facets: [
        {name: 'files', linkType: 'OTO', path: 'xmlFile'},
        {name: 'files', linkType: 'OTO', path: 'xlsxFile'}
    ],
    fields: [
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "r.batchSeq",
                    formatResult: result => `${result}`
                }]
            }
        },
        'reference',
        'Process',
        {path:'orderLines', type: 'BankFlow', link: 'MTM', nullable: true},
        {
            path: "requestsReferences",
            fieldPath: ['process', 'orderLines.reason'],
            $f: function (lot, context, callback) {
                if (lot.process === 'invoice') return callback(null)
                console.log('lot', lot)
                global.app.R.Refund.find(
                    {
                        query: {reference: {$in: (lot.orderLines || []).map(orderLine => orderLine.reason)}},
                        fieldPath: ['sequence'],
                        group: new global.ObjectID(context.group.id)
                    },
                    (e, requests) => {
                        if (e) callback(e)

                        callback(
                            null,
                            requests.map(request => request.sequence).join(', ')
                        )
                    }
                )
            }
        },
        {path: 'amount', type: 'financial'},
        'orderLinesLength',
        'status',
        'creationDate',
        'transmissionDate',
        'paymentDate',
        'fileName',
        {path: 'correctionEmail', type: 'boolean'},
        {
            path: "buttons",
            $f: function (lot, context, callback) {
                if('toPay' === lot.status  && context.clientContext.moduleId === 'm-R-control') {
                    callback(null, [{type: 'action', tKey: 'controlled', bsStyle: 'warning', action: 'control'}, returnButton])
                } else if('controlled' === lot.status && context.clientContext.moduleId === 'm-R-payment') {
                    callback(null, [{type: 'action', tKey: 'paid', bsStyle: 'primary', action: 'pay'}, returnButton])
                } else {
                    callback(null, [returnButton])
                }
            }
        }
    ],
    filters: [
        {
            name: 'byExercise',
            path: 'exercise',
            object: 'Exercise',
            filters: ['notProvisionallyClosed'],
            fieldPath: ['startDate', 'endDate'],
            sortList: false,
            display: 'code',
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            query: async (context, callback) => {
                try {
                    const [refunds, invoices] = await Promise.all([getRefundsByExercise(context), getInvoicesByExercise(context)])
                    const refundPaymentOrders = await global.app.R.BankFlow.find({
                        fieldPath: ['id'],
                        ...basicContext(context),
                        query: {
                            reason: {
                                $in: _.concat(
                                    refunds.map(request => request.reference),
                                    invoices.map(invoice => invoice.wording)
                                )
                            }
                        }
                    })

                    return callback(null, { orderLines: { $elemMatch: { $in: refundPaymentOrders.map(paymentOrder => global.ObjectID(paymentOrder.id)) } } })

                } catch (error) {
                    callback(error)
                }
            }
        },
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "sequence"
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    /*
    refundIsUsedInReallocation: function(newObject, oldObject, context, callback) {
        if(context.action === 'save') {
            async.parallel(newObject.orderLines.map(orderLine => callback => {
                global.app.R.Refund.collection.findOne(
                    { reference: orderLine.reason },
                    (e, refund) => {
                        if(e) callback(e)
                        global.app.R.Reallocation.collection.findOne({
                            status: {$nin: ['refused', 'validated']},
                            requests: { $elemMatch: { $eq : new global.ObjectID(refund._id)} }
                        }, (e, reallocation) => {
                            if(e) return callback(e)
                            else if(reallocation) return callback(new Errors.ValidationError(context.tc('refundUndergoingReallocation', {reference: orderLine.reason})))
                            callback(null)
                        })
                    }
                )
            }),err => {
                if(err) return callback(err)
                callback()
            })
        } else callback()
    },
     */
    lotMustNotContainDoubles: (newObject, oldObject, context, callback) => {
        const reasons = newObject.orderLines.map(ol => ol.reason)

        const hasDoubles = reasons.some((reason, index) => reasons.indexOf(reason) !== index)

        if(hasDoubles) {
            callback(new Errors.ValidationError("Des doublants d'ordres de virements ont été détecté veuillez contacter le support"))
        } else callback()
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.lotMustNotContainDoubles(newObject, oldObject, context, callback)
            //callback => this.refundIsUsedInReallocation(newObject, oldObject, context, callback)
        ], callback)
    },
    beforeSave: function (newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType

        if(action === 'C') {
            newObject.status = 'toPay'
            newObject.reference = `Lot-${newObject.process.id === 'invoice' ? 'F' : 'D'}-${newObject.sequence}`
            newObject.creationDate = moment().format("YYYY-MM-DDTHH:mm:ss")
            const fileName = `LotDeVirement_${newObject.reference}`

            global.app.R.Bank.find({
                ...basicContext(context),
                fieldPath: ['active', 'process', 'iban', 'bic'],
                query: {
                    active: true,
                    process: { $elemMatch: {$eq : newObject.process.id} }
                }
            }, (e, accounts) => {
                if(accounts.length === 0) {
                    callback(new Errors.ValidationError('noActiveBankForProcess'))
                }
                else {
                    const bank = accounts[0]
                    async.parallel([
                            function(callback) {
                                const validatedObject = validateForXmlGeneration(newObject, callback, context.tc)
                                const xmlData = writeXml(validatedObject, bank)
                                global.xml.generateXml(fileName, xmlData, (err, file) => {
                                    if(err) callback(err)
                                    else callback(null, file)
                                })
                            },
                            function(callback) {
                                generateExcel(newObject, bank)
                                    .then(file => callback(null, file))
                                    .catch(err => callback(err))
                            }
                        ],
                        function(err, results) {
                            if(err) callback(err)
                            else {
                                newObject.xmlFile = {
                                    ...results[0],
                                    user: _.get(context, "user.name", "unknown user"),
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                                newObject.xlsxFile = {
                                    ...results[1],
                                    user: _.get(context, "user.name", "unknown user"),
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                                callback(null, newObject, oldObject)
                            }
                        })
                }
            })
        }else if(context.action && context.action !== 'save') {
            switch (context.action) {
                case 'control':
                    newObject.status = 'controlled'
                    newObject.transmissionDate = null
                    newObject.fileName = null
                    break
                case 'pay':
                    newObject.status = 'paid'
                    newObject.transmissionDate = null
                    newObject.fileName = null
                    break
                default:
                    console.log('no action !!!')
            }
            callback(null, newObject, oldObject)

        } else callback(null, newObject, oldObject)
    },
    afterSave: function (newObject, oldObject, context, callback) {

        const action  = context.restAction && context.restAction.crudType

        if(action === 'C' && newObject.status === 'toPay') {
            updateMany(
                'BankFlow',
                {_id: {$in: newObject.orderLines.map(line => global.ObjectID(line.id)) }},
                {
                    status: 'toPay',
                    reference: newObject.reference,
                    transmissionDate: moment().format('YYYY-MM-DD'),
                    fileName: newObject.xmlFile.filename
                },
                null,
                callback
            )
        } else if( newObject.status === 'controlled' && oldObject.status === 'toPay' ) {
            const removedLines = oldObject.orderLines.filter(oldLine => {
                return !newObject.orderLines.some(newLine => oldLine.id === newLine.id )
            })

            if (removedLines.length !== 0) {
                global.app.R.BankFlow.collection.updateMany(
                    {_id: {$in: removedLines.map(line => global.ObjectID(line.id)) }},
                    {$set: {
                            status: 'waiting',
                            reference: null,
                            transmissionDate: null,
                            fileName: null
                        }},
                    (e) => {
                        if (e) {
                            console.warn(e)
                            callback(e)
                        } else {
                            const newComment = {
                                user: _.pick(context.user, ['id', 'name']),
                                text: `${context.tc('waiting')}`,
                                date: moment().format("YYYY-MM-DD HH:mm")
                            }

                            if( newObject.process.id === 'refund') {
                                updateMany(
                                    'Refund',
                                    {reference: {$in: removedLines.map(line => line.reason) }},
                                    {status: 'waiting'},
                                    {comments: newComment},
                                    e => {
                                        if(e) callback(e)
                                        else {
                                            if(newObject.correctionEmail) {
                                                sendUserMail(removedLines.map(line => line.userId), context)
                                                    .then(() => console.log('sending user info-update request'))
                                                    .catch(e => console.warn(e))
                                            }
                                            callback()
                                        }
                                    }
                                )
                            } else {
                                updateMany(
                                    'Invoice',
                                    {wording: {$in: removedLines.map(line => line.reason) }},
                                    {
                                        status: 'waiting',
                                        reference: null,
                                        transmissionDate: null,
                                        fileName: null
                                    },
                                    {comments: newComment},
                                    e => {
                                        if(e) callback(e)
                                        else callback()
                                    }
                                )
                            }
                        }
                    }
                )
            } else {
                callback()
            }

        } else if( newObject.status === 'paid' && oldObject.status === 'controlled') {
            const defaultObject = {
                type: 'G',
                generated: moment().format("YYYY-MM-DD"),
                date: moment(newObject.paymentDate).format("YYYY-MM-DD"),
                journal: 'VIR',
                recordType:newObject.process.id,
                reference: oldObject.reference,
                group: new global.ObjectID(context.group.id)
            }

            async.series([
                callback => global.db.collection('r.bank').findOne({
                    active: true,
                    process: { $elemMatch: {$eq : newObject.process.id} }
                }, (e, bank) => {
                    if(e) callback(e)
                    else if(!bank) callback('Vous devez renseigner un compte actif pour le processus choisi')
                    else {
                        const specificLines = newObject.orderLines.map(line => {
                            return [
                                {
                                    ...defaultObject,
                                    generalAccount: newObject.process.id === 'refund' ? '411000000' : '401000000',
                                    auxiliaryAccount: line.accountNumber,
                                    amount: line.amount,
                                    direction: 'D',
                                    wording: line.reason
                                },
                                {
                                    ...defaultObject,
                                    generalAccount: bank.temporaryAccount,
                                    amount: line.amount,
                                    direction: 'C',
                                    wording: line.reason
                                }
                            ]

                        })

                        const commonObject = {
                            wording: `Ordre de virement num ${newObject.reference}, Nombre virements ${newObject.orderLinesLength}`,
                            amount: newObject.amount
                        }

                        const commonLines = [
                            {...defaultObject, ...commonObject, generalAccount: bank.temporaryAccount, direction: 'D'},
                            {...defaultObject, ...commonObject, generalAccount: bank.provisionalAccount, direction: 'C'}
                        ]

                        async.series([
                            callback => {
                                const removedLines = oldObject.orderLines.filter(oldLine => {
                                    return !newObject.orderLines.some(newLine => oldLine.id === newLine.id )
                                })

                                if(removedLines.length !== 0) {
                                    global.app.R.BankFlow.collection.updateMany(
                                        {_id: {$in: removedLines.map(line => global.ObjectID(line.id)) }},
                                        {$set: {
                                                status: 'waiting',
                                                reference: null,
                                                transmissionDate: null,
                                                fileName: null
                                            }},
                                        (e) => {
                                            if (e) {
                                                console.warn(e)
                                                callback(e)
                                            } else {
                                                const newComment = {
                                                    user: _.pick(context.user, ['id', 'name']),
                                                    text: `${context.tc('waiting')}`,
                                                    date: moment().format("YYYY-MM-DD HH:mm")
                                                }
                                                if( newObject.process.id === 'refund') {
                                                    updateMany(
                                                        'Refund',
                                                        {reference: {$in: removedLines.map(line => line.reason) }},
                                                        { status: 'waiting'},
                                                        {comments: newComment},
                                                        (e) => {
                                                            if(e) callback(e)
                                                            else {
                                                                sendUserMail(removedLines.map(line => line.userId), context)
                                                                    .then(() => console.log('sending user info-update request'))
                                                                    .catch(e => console.warn(e))
                                                                callback()
                                                            }
                                                        }
                                                    )
                                                } else {
                                                    updateMany(
                                                        'Invoice',
                                                        {wording: {$in: removedLines.map(line => line.reason) }},
                                                        { status: 'waiting'},
                                                        {comments: newComment},
                                                        callback
                                                    )
                                                }
                                            }
                                        })
                                } else {
                                    callback()
                                }
                            },
                            callback => {
                                if(newObject.orderLines.length !== 0) {
                                    global.app.R.BankFlow.collection.updateMany(
                                        {_id: {$in: newObject.orderLines.map(line => global.ObjectID(line.id)) }},
                                        {
                                            $set: {
                                                status: 'paid'
                                            }},
                                        (e) => {
                                            if (e) {
                                                console.warn(e)
                                                callback(e)
                                            } else {
                                                global.app.R.AccountingEntries.collection.insertMany( _.flatten([...specificLines, ...commonLines]), (e) => {
                                                    if (e) {
                                                        console.warn(e)
                                                        callback(e)
                                                    }else {
                                                        const newComment = {
                                                            user: _.pick(context.user, ['id', 'name']),
                                                            text: `${context.tc('paid')}`,
                                                            date: moment().format("YYYY-MM-DD HH:mm")
                                                        }

                                                        if( newObject.process.id === 'refund') {
                                                            updateMany(
                                                                'Refund',
                                                                {reference: {$in: newObject.orderLines.map(line => line.reason) }},
                                                                { status: 'paid'},
                                                                {comments: newComment},
                                                                (e) => {
                                                                    if(e) callback()
                                                                    else {
                                                                        sendNotificationMail(newObject.orderLines, context)
                                                                            .then(() => console.log('sending user refund notification'))
                                                                            .catch(e => console.warn(e))
                                                                        callback()
                                                                    }
                                                                }
                                                            )
                                                        } else {
                                                            updateMany(
                                                                'Invoice',
                                                                {wording: {$in: newObject.orderLines.map(line => line.reason) }},
                                                                { status: 'paid'},
                                                                {comments: newComment},
                                                                callback
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        })
                                }else {
                                    callback()
                                }
                            }
                        ], (err) => callback(err))
                    }
                }),
                callback => {
                    global.app.R.Refund.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {
                            reference: {$in: newObject.orderLines.map(line => line.reason) }
                        }
                    }, (err, requests) => {
                        global.app.R.Reallocation.find({
                            ...basicContext(context),
                            fieldPath: ['ongoingPayment', 'beneficiary', 'amountToRepay', 'status', 'refund', 'date', 'benefit', 'benefit.id', 'benefit.name'],
                            query: {
                                requests: { $elemMatch: { $in: requests.map(request => new global.ObjectID(request.id)) } },
                                status: 'waitingRefund'
                            }
                        }, (err, reallocationRequests) => {
                            if(!reallocationRequests || reallocationRequests.length === 0) return callback()
                            else {
                                const fullyRefundedRequests = reallocationRequests.filter(req => req.ongoingPayment === '0.00')

                                global.app.R.Reallocation.collection.updateMany(
                                    {
                                        _id: {
                                            $in: fullyRefundedRequests.map(req => new global.ObjectID(req.id))
                                        }
                                    },
                                    {
                                        $set: {status: 'waitingPayment'},
                                        $push: {
                                            comments: {
                                                user: _.pick(context.user, ['id', 'name']),
                                                text: `${context.tc('waitingPayment')}`,
                                                date: moment().format("YYYY-MM-DD HH:mm")
                                            }
                                        }
                                    },
                                    {},
                                    (err, result) => {
                                        if(err) callback(err)
                                        else {
                                            fullyRefundedRequests.forEach(
                                                req => workflowMailing({...req, status: 'refundCompleted'}, [], context).then(() => console.log('sending mails done'))
                                            )
                                            return callback()
                                        }
                                    }
                                )
                            }
                        })
                    })
                }
            ], err => callback(err))

        } else {
            callback()
        }
    }
}
