import {basicContext} from "../../../../utils/contextUtils";
import _ from "lodash";
import {processDynamicColumns, setFieldVisibility} from "../../../../../apps/KpModule/actions";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import moment from "moment";

export const entity = {
    name: 'AnalyticalMesh',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: false, uniqueCode: true}
    ],
    fields: [
        {path: 'attachment', type:'AnalyticalSubAxis', link:'MTM', fieldPath:['nameWithAnalyticalAxis']},
        {path: "isCreatedAutomatically", type: 'boolean'},
        {
            path: 'nameWithAttachment',
            fieldPath: ['attachment.id', 'attachment.name', 'attachment.analyticalAxis', 'name'],
            $f: function (object, context, callback) {
                if(!context.translateName) return callback(null, `${object.name} : ( ${object.attachment.map(subAxis => ' ' + subAxis?.analyticalAxis?.name + ' : ' + subAxis?.name).toString()} )`)
                callback(null, `${context.translateName(object.name)} : ( ${object.attachment.map(subAxis => ' ' + context.translateName(subAxis?.analyticalAxis?.name) + ' : ' + context.translateName(subAxis?.name))} )`)
            }
        },
        {
            path: 'attachmentDetails',
            fieldPath: ['attachment.id', 'attachment.name', 'attachment.analyticalAxis', 'name'],
            $f: function (object, context, callback) {
                if(!context.translateName) return callback(null, `${object.attachment.map(subAxis => ' ' + subAxis?.analyticalAxis?.name + ' : ' + subAxis?.name).toString()}`)
                callback(null, `${object.attachment.map(subAxis => ' ' + context.translateName(subAxis?.analyticalAxis?.name) + ' : ' + context.translateName(subAxis?.name))}`)
            }
        }
    ],
    filters: [
        {
            name: 'byThisSubAxis',
            isDefault: false,
            query: function(context) {
                const organizationID = _.get(context.data, 'subAxis.id')
                return organizationID? {"attachment": {$elemMatch : {$eq : global.ObjectID(organizationID)}}} : {"attachment": "0"}
            }
        },
        {
            name: "byBudgetAxis",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.SE.AnalyticalSubAxis.find(
                    {
                        ...basicContext(context),
                        fieldPath: ['analyticalAxis'],
                        query: {
                        }
                    },(e, subAxes) => {
                        if (e) return callback(e)
                        const subAxesInBudgetAxes = subAxes.filter( subAxis => subAxis.analyticalAxis.isBudgetAxis).map( subAxisInBudget => global.ObjectID(subAxisInBudget.id))
                        const query = !!subAxesInBudgetAxes.length ? {"attachment": {$elemMatch: {$in: subAxesInBudgetAxes}} } : {_id: null}
                        callback(e, query)
                    }
                )
            }
        },
        {
            name: 'createdManually',
            isDefault: false,
            query: function(context) {
                return {"isCreatedAutomatically": false}
            }
        }
    ]
}

export const module_ = {
    object: 'AnalyticalMesh',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [ 'code',
            {path: 'name', type: 'translatedText'},
            {path: 'attachment'},
        ],
        form: {
            fields: [
                {path: 'code', editable: false},
                {path: 'name', type: 'textarea'},
                {path: 'attachment', display: 'nameWithAnalyticalAxis'},
                {path: 'isCreatedAutomatically'}
            ],
            onOpen: ({ store, module, t }) => {
                const isCreatedAutomaticallyField = module.viewMap.form.fields.find(field => field.path === 'isCreatedAutomatically')
                store.dispatch(setFieldVisibility(isCreatedAutomaticallyField.id, false))
            }
        },
    },
}
