export const entity = {
    name: "FiscalYear",
    fields: [
        "Month",
        "MonthNumber",
        {
            path: 'fiscalYearStart',
            fieldPath: ['month.id', 'monthNumber.id'],
            $f: (object, context, callback) => {
                return callback(null, object.month?.id + '-' + object.monthNumber?.id)
            }
        }
    ]
}

export const module_ = {
    object: "FiscalYear",
    tKey: "mTitle_fiscalYear",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultPanel: 'form',
    newable: false,
    removable: false,
    listable: false,
    viewMap: {
        dt: [
            {path: 'month'},
            {path: "monthNumber"}
        ],
        form: [
            {path: 'month', sortList: false},
            {path: "monthNumber", display: "dayNumber", tKey: "day", sortList: false}
        ]
    }
}
