const _ = require('lodash')

export const module_ = {
    object: 'Invoice',
    name: 'InvoiceFollowUp',
    tKey: 'mTitle_followUp',
    newable: false,
    removable: false,
    displayLog: false,
    defaultSortBy: 'updated',
    defaultSortDirection: 'ASC',
    category: {
        path: 'invoice',
        icon: 'fileText'
    },
    viewMap: {
        dt: [
            {path: 'wording', initiallyNotVisible: true},
            {path: 'organization', display: 'name'},
            'invoiceNumber',
            {path: 'invoiceDate'},
            'deadline',
            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation', tooltip: true},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            'updated'
        ],
        form: {
            fields: [
                {path: 'wording', disabled: true},
                {path: 'organization', editable: false},
                {path: 'invoiceNumber', editable: false},
                {path: 'invoiceDate', editable: false},
                {path: 'deadline', editable: false},
                {
                    path: 'invoiceLines',
                    defaultSortBy: 'accountsGroup',
                    defaultSortDirection: 'ASC',
                    newable: false,
                    viewMap: {
                        dt: [
                            {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName'},
                            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation'}
                        ],
                        form: [
                            {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName', editable: false},
                            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation', positiveNumber: true, editable: false},
                            {path: 'object', editable: false}
                        ]
                    }
                },
                {path: 'amountInclTax', positiveNumber: true, editable: false},
                {path: 'files', tKey: 'supportingDocuments', required: true},
                'comments',
                {path: 'status', hidden: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
        }
    },
    filters: [
        'byExercise',
        {
            title: "Status",
            path: "status",
            tKey: "status",
            type: 'tags',
            default: [
                {id: 'validated'}
            ],
            object: "InvoiceStatus",
            display: "name",
            client: true,
            isDefault: false,
            query: function (context) {
                const statusArray = _.get(context, "data.status")

                return statusArray
                    ? {status: {$in: statusArray.map(o => o.id)}}
                    : {status: false}
            }
        },
        {
            path: "noDraft",
            match: function(request) {
                return request.status !== 'draft'
            }
        }
    ]
}
