import '../clientGlobals'
import './i18nClient'
import '../server/utils/client'
import _ from 'lodash'
import common2 from '../server/enhancers/common2'
import client from '../server/enhancers/client'


class ClientEnhancer {
    constructor(models) {
        this.models = common2(_.cloneDeep(models))
        this.modulesById = {}
        this.currentId = null
    }

    setCurrentModule(id, store) {
        this.currentId = id

        if (!this.modulesById[id]) {
            const modelId = id.split('-')[1]
            const model = _.find(this.models, { id: modelId })
            const module = _.find(model.modules, { id })

            this.modulesById[id] = client(module, store)
        }

        // console.log(this.getCurrentModule())

        return this.getCurrentModule()
    }

    getCurrentModule() {
        return this.modulesById[this.currentId]
    }
}

export default ClientEnhancer
