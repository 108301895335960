import {changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE, setFieldVisibility} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    object: 'Demand',
    name: 'DemandConsultation',
    category: {
        path: 'demande',
    },
    tKey: 'consultation',
    updatable: false,
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    newable: false,
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'step', translate: true},
            {path: 'demandNumber', tKey: 'N° Demande'},
            {path: 'relatedDemand', tKey: 'demande principale', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'relatedProject', tKey: 'projet', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                },
                {path: 'demandNumber',tKey: 'N° Demande', disabled: true},
                {path: 'relatedProject', tKey: 'projet', display: 'demandNumberAndTitle', disabled: true, filters: ['isProject', 'approvedOrInRealization']},
                {path: 'relatedDemand', tKey: 'demande principale', display: 'demandNumberAndTitle', disabled: true},
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    disabled: true,
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    fieldPath: ['id', 'code', 'name'],
                    disabled: true,
                    translateName: true,
                },
                {path: 'title', required: true, disabled: true},
                {path: 'description', type: 'richTextEditor', required: true, disabled: true},
                {path: 'enjeux', type: 'richTextEditor', required: true, disabled: true},
                {path: 'objectifs', type: 'richTextEditor', required: true, disabled: true},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    //endDate: ''
                    disabled: true,
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    disabled: true
                },
                {
                    path: 'largeImputations',
                    disabled: true,
                    type: 'dtObjects',
                    fields: [
                        {path: "organization", tKey: 'direction', display: 'name'},
                        {path: "amount", tKey: 'Montant (k)', type: "editText"}
                    ]
                },
                {
                    path: 'largeImputationsTotalAmount',
                    disabled: true
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    newable: false,
                    disabled: true,
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant (k)'},
                        ],
                        form: {
                            fields : [
                                {path: 'objet', required: true, unique: true, disabled: true},
                                {path: 'budget', display: 'budgetDisplayName'},
                                {path: 'organizationalMesh', tKey: 'business unit', filters:['byOrganizationOrMesh'], disabled: true},
                                {path: 'imputationType', required: true, disabled: true, translateName: true},
                                {path: 'currency', display: 'symbol', disabled: true},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    disabled: true,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ]
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea', disabled: true},
                            ]
                        }
                    }
                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true
                },
                {
                    path: 'plafond',
                    tKey: "financialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                    disabled: true
                    //filters: ['byAmount']
                },
                {path: 'comments', disabled: true},
                {path: 'files', disabled: true},
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },

                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                }
            ],
            dataLists: [
                "DemandConsultation-Currency",
            ],
            onOpen: ({ module, state, store, t }) => {

                const x = module.viewMap.form.fields.find( field => field.path === 'imputations')
                const organizationAndMesh= _.get(state, 'edit.object.data.organizationAndMesh')

                const demandCategoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')
                store.dispatch(setFieldVisibility(demandCategoryField.id, false))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-demandConsultation.Imputation_organizationalMesh",
                        store.getState,
                        'form',
                        {
                            data: {
                                organization : organizationAndMesh?.id
                            }
                        }
                    )
                )

                const largeImputationsField = module.viewMap.form.fields.find( field => field.path === 'largeImputations')
                const largeImputationsTotalAmountField = module.viewMap.form.fields.find( field => field.path === 'largeImputationsTotalAmount')

                const atLeastOneLargeImputations = !!largeImputationsField && !!largeImputationsField.getValue() && !!largeImputationsField.getValue().length

                store.dispatch(setFieldVisibility(largeImputationsField.id, atLeastOneLargeImputations))
                store.dispatch(setFieldVisibility(largeImputationsTotalAmountField.id, atLeastOneLargeImputations))

                const referenceCurrencies = getDataListList(state, "DemandConsultation-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_totalAmount", 'tKey', `${t("plannedTotalAmount")} (k${referenceCurrency.symbol})`))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "DemandConsultation-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `total (k${referenceCurrency.symbol})`))
                }
            }
        },
    ],
    accesses: [
        {
            id: "DemandConsultation-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ["myConsultations", "byFiscalYear"]
}
