import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Select } from '../../../../components/Form/index'
import { colourStyles } from './SelectStyles'
import { getListOptions, getFilterListsAreFetching, getClearable } from '../../selectors/index'
import './FilterDropdownField.css'
import {translateName} from "../../../../utils/i18n";

class Tags extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            value: this.props.input.value,
            timeLeft: this.props.timer,
            timerIsOn: false,
            tempoValue: null,
        }
        this.timer = 0
        this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.input.value){
            this.setState({value: this.props.input.value})
        }

    }

    startTimer() {
        if(!this.props.timer) {
            this.props.input.onChange(this.state.tempoValue)
            this.setState({tempoValue: null})
        } else {
            clearInterval(this.timer)
            this.setState({
                timerIsOn: false
            }, () => {
                this.setState({timeLeft: this.props.timer, timerIsOn: true}, () => {
                    this.timer = setInterval(this.countDown, 1000);
                })
            })
        }
    }

    countDown() {
        this.setState({timeLeft: this.state.timeLeft - 1}, () => {
            if(this.state.timeLeft === 0) {
                clearInterval(this.timer)
                this.setState(
                    {timerIsOn: false},
                    () => {
                        this.props.input.onChange(this.state.tempoValue)
                        this.setState({tempoValue: null})
                    }
                )
            }
        })
    }

    render() {
        const {
            input: {onChange},
            id,
            path,
            selectOptions,
            optionsById,
            originalOptionsById,
            isFetching,
            clearable,
            width,
            timer,
            placeholder = 'select',
            t
        } = this.props

        const {tempoValue, value, timerIsOn, timeLeft} = this.state

        const undefinedValue = value && value.some(o => !optionsById[o.id])
        if(undefinedValue) {
            onChange(
                value.filter(o => o.id && optionsById[o.id])
            )
        }

        return (
            <Select
                id={id}
                path={path}
                className="FilterDropdownField-Select"
                options={selectOptions}
                noOptionsMessage={() => t('noOptions')}
                placeholder={t(placeholder)}
                styles={colourStyles}
                isMulti={true}
                multi={true}
                width={width}
                timer={timer}
                timerIsOn={timerIsOn}
                timeLeft={timeLeft}
                closeMenuOnSelect={false}
                onChange={options => {
                    this.setState({
                        tempoValue: options && options.map(
                            option => option.value && originalOptionsById[option.value]
                        )
                    }, () => this.startTimer())
                }}
                value={
                    tempoValue
                        ? tempoValue.map(o => optionsById[o.id])
                        : value && value.map(o => optionsById[o.id])
                }
                isClearable={clearable}
                isLoading={isFetching}
                backspaceRemovesValue={true}
                t={t}
            />
        )
    }
}

const FilterField = ({
    filter,
    options,
    isFetchingFilters,
    clearable,
    fetchFilterData,
    fetchFilterFieldsLists,
    onChange,
    t,
    language
}) => {
    const originalOptionsById = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: option
        }
    }, {})
    const optionsById = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: {
                label: filter.translateName
                    ? translateName(option[filter.display], language)
                    : filter.translate
                        ? t(option[filter.display])
                        : option[filter.display],
                value: option.id
            }
        }},{})

    const selectOptions = options.map(option => optionsById[option.id])
    return (
        <Field
            name={filter.path}
            id={filter.id}
            path={filter.tKey || filter.path}
            display={filter.display}
            placeholder={filter.placeholder}
            component={Tags}
            clearable={clearable}
            width={filter.width}
            isFetching={isFetchingFilters}
            selectOptions={selectOptions}
            optionsById={optionsById}
            originalOptionsById={originalOptionsById}
            timer={filter.timer}
            onChange={(event, value) => {
                // we need to call fetchDtData after reduxFormChange
                event.preventDefault()
                onChange(filter.path, value)
                if(filter.filter) {
                    fetchFilterFieldsLists()
                }
                if(!!filter.autoFetch) {
                    fetchFilterData()
                }
            }}
            t={t}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    // initialValues: state.filterObject,
    isFetching: getFilterListsAreFetching(state),
    options: getListOptions(state, filter.id, filter.sortList),
    clearable: getClearable(state, filter.id)
})

export default connect(mapStateToProps)(FilterField)
