import {translateName} from "../../../../utils/i18n";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";

export const entity = require('../../../utils/entityJoin').joinEntities(
    {
        name: 'perimeterJoinedEntity',
        fields: [
            //'name',
            {
                path: 'name',
                fieldPath: ['attachments.name', 'attachments.organizationalAxis.name', 'organizationalAxis.name'],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'FiscalYear') {
                        return  callback(null, `Exercice : ${object.code}`)
                    }
                    if (object.joinedEntity === 'DemandNature'){
                        return  callback(null, `Catégorie : ${translateName(object.name, _.get(context, "language.id"))}`)
                    }
                    if (object.joinedEntity === 'Demand'){
                        return  callback(null, `Projet : ${object.title}`)
                    }
                    if (object.joinedEntity === 'Organization'){
                        return  callback(null, `Organisation : ${translateName(object.name, _.get(context, "language.id"))} ( Axe : ${context.translateName(object.organizationalAxis.name)} )`)
                    }
                    if (object.joinedEntity === 'OrganizationalMesh'){
                        const fullName = !context.translateName ?
                            `${object.name} ( ${object.attachments.map(org => ' ' + org.organizationalAxis.name + ' : ' + org.name).toString()} )`
                            : `${context.translateName(object.name)} ( ${object.attachments.map(org => ' ' + context.translateName(org.organizationalAxis.name) + ' : ' + context.translateName(org.name))} )`
                        return  callback(null, `BU : ${fullName}`)
                    }
                }
            },
            {
                path: 'fiscalYearRange',
                fieldPath: [],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'FiscalYear') {
                        return  callback(null, object.fiscalYearRange)
                    }
                    return  callback(null, null)
                }
            }
        ],
        /*
        beforeSave: function(newObject, oldObject, context){
            if (newObject.joinedEntity === 'Mesh'){
                return newObject
            }
        },
         */
        filters: [
            { //verified
                name: "organizationAndMeshesRule",
                isDefault: true,
                //async: true,
                match: (object, context) => {
                    const perimeterOptions = _.get(context.data, 'perimeterOptions', []) || []
                    const organizationIsSelected = perimeterOptions.some(option => option.joinedEntity === 'Organization')
                    const meshIsSelected = perimeterOptions.some(option => option.joinedEntity === 'OrganizationalMesh')
                    if(organizationIsSelected){
                        return object.joinedEntity !== 'OrganizationalMesh'
                    }
                    if (meshIsSelected){
                        return object.joinedEntity !== 'Organization'
                    }
                    return true
                },
            },
            { //verified
                name: "onlyCoherentOptions",
                isDefault: true,
                async: true,
                query: async function(context, callback) {
                    const projectsToTrack = await global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: [
                            'imputations', 'imputations.organizationalMesh', 'imputations.monthAndAmountList'
                        ],
                        query: {
                            category : '1',
                            status:{$nin: ['1', '5', '9']},
                            $or: [{imputations: {$exists: true}}, {revisedTable: {$exists: true}}],
                        }
                    })
                    const demandNaturesIds = projectsToTrack.map(obj => global.ObjectID(obj.demandNature.id))
                    const projectsToTrackIds = projectsToTrack
                        .filter(project => !project.relatedProject)
                        .map(obj => global.ObjectID(obj.id))
                    //const organizationsIds = projectsToTrack.map(obj=obj)
                    const organizationsIds = _(projectsToTrack).flatMap(item =>{
                        return _(item.imputations).flatMap(imputation =>
                            imputation.organizationalMesh.attachments.map(att => global.ObjectID(att.id))
                        ).uniq().value()
                    }).uniq().value()
                    const meshesIds = _(projectsToTrack).flatMap(item =>{
                        return item.imputations.map(imputation => global.ObjectID(imputation.organizationalMesh.id))
                    }).uniq().value()
                    const fiscalYearsIds = _(projectsToTrack).flatMap(item =>{
                        return _(item.imputations).flatMap(imputation =>
                            imputation.monthAndAmountList.map(obj => global.ObjectID(obj.fiscalYear.id))
                        ).uniq().value()
                    }).uniq().value()

                    const idsToLookFor = [...demandNaturesIds, ...projectsToTrackIds, ...organizationsIds, ...meshesIds, ...fiscalYearsIds]

                    console.log('onlyCoherentOptions', projectsToTrack.length)
                    callback(null, {_id: {$in: idsToLookFor}})
                },
            }
        ]
    },
    ['FiscalYear', 'DemandNature', 'Demand', 'Organization', 'OrganizationalMesh']
)
