import {basicContext} from "../../../utils/contextUtils";
import _ from "lodash";

export const entity = {
    name: 'RealizationType',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true},
    ],
    fields: [
        {path: 'order', type: 'integer', unique: true},
        'Status',
        {
            path: "noDeleteButtonAccess",
            $f: function (object, context, callback) {
                callback(null, true)
            }
        },
    ],
    filters: [
        {
            name: "onlyActifOnes",
            isDefault: false,
            query: function () {
                return {status: "1"}
            }
        },
    ],
    beforeSave: async function processCsv(newObject, oldObject, context, callback) {
        const action = context.restAction && context.restAction.crudType
        try {
            if (action === 'C'){
                const bulkWritesForRevisedTable = [{ deleteOne: {filter: { _id: null }} }]
                //const bulkWritesForDemand = [{ deleteOne: {filter: { _id: null }} }]

                const demandsToUpdate = await global.app.SE.Demand.find({
                    ...basicContext(context),
                    fieldPath: ["revisedTable"],
                    query: {
                        "title": "test1000",
                        "revisedTable": { $exists: true, $ne: [] },
                        //"workflow.0": "realization"
                    }
                })
                const addedAmountByNewRealizationType = [
                    {
                        _id: new global.ObjectID(),
                        realizationType: newObject.id ,
                        budgetColumnType: '1',  //global
                        amount: 0
                    },
                    {
                        _id: new global.ObjectID(),
                        realizationType: newObject.id,
                        budgetColumnType: '2', //yearly
                        amount: 0
                    }
                ]
                demandsToUpdate.forEach(demand => {
                    const revisedTable = demand.revisedTable
                    revisedTable.forEach(revisedLine => {
                        const newAmountsByRealizationType = {...revisedLine.amountByRealizationType, ...addedAmountByNewRealizationType}
                        bulkWritesForRevisedTable.push({
                            updateOne: {
                                filter: { _id: global.ObjectID(revisedLine.id) },
                                update: {
                                    $push: {
                                        "amountByRealizationType": {$each: addedAmountByNewRealizationType}
                                    }
                                }
                            }
                        })
                    })
                })
                await global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                    if (e) return callback(e)})

                callback(null, newObject, oldObject);
            }
            else{
                callback(null, newObject, oldObject);
            }
        } catch (error) {
            callback(error)
        }
    }
}

export const module_ = {
    object: 'RealizationType',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: 'type suivi',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'},
            'order',
            {path: 'status'},
            {path: 'noDeleteButtonAccess', hidden: true},
        ],
        form: [
            'code',
            {path: 'name', type: 'textarea', placeholder: 'fr:: nom en français\nen:: nom en Anglais'},
            {path: 'order', wholePositiveNumber: true, default: 1},
            {path: 'status', type : 'toggle', default: {id: "1"}},
        ],
    }
}
