import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../img/kplogo_horizontal_white_30px.png'
import PropTypes from 'prop-types'
import { defaultT } from '../utils/i18n'

class NotFoundPage extends Component {
    render() {
        const { t = defaultT } = this.props
        return (
            <div>
                <nav className="Login-navbar">
                    <div className="Login-kpLogo">
                        <img
                            className="Login-kpLogo-img"
                            src={logo}
                            alt="Keenpoint"
                        />
                    </div>
                </nav>
                <div className="">
                    <div className="well Login-well col-sm-offset-4 col-sm-4">
                        <h2>{t('404error', { capitalize: true })}</h2>
                        <Link
                            to={`/business`}
                            onClick={() => {
                                this.props.setNotFoundPage(false)
                            }}
                        >
                            {t('homelink', { capitalize: true })}
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

NotFoundPage.propTypes = {
    t: PropTypes.func,
    setNotFoundPage: PropTypes.func.isRequired
}

export default NotFoundPage
