import moment from 'moment'
import _ from 'lodash'
import {
    data,
    getLastMonthOfLastFile,
    getPreviousExerciseLastMonth,
    getPreviousYearMonth
} from '../utils/stateComputationUtils'
import {getData} from './requests'

async function findData() {
    const previousMonthISO = moment().subtract(1, "months")
    const previousMonth = previousMonthISO.format('YYYY-MM')

    const lastMonthFromLastFile = await getLastMonthOfLastFile(previousMonth)
    const previousExerciseLastMonth = getPreviousExerciseLastMonth(moment().format('YYYY-MM'));

    const lastMonthFromLastFilePreviousYear = getPreviousYearMonth(lastMonthFromLastFile)

    const formatedPreviousMonth = moment(lastMonthFromLastFile, 'YYYY-MM').format('MMM YYYY')
    const formatedLastYearPreviousMonth = moment(lastMonthFromLastFilePreviousYear, 'YYYY-MM').format('MMM YYYY')

    const finalResult = await getData('CAP', lastMonthFromLastFile, lastMonthFromLastFilePreviousYear, previousExerciseLastMonth)

    const total = finalResult.reduce((acc, o) => Object.assign(acc, {
        [lastMonthFromLastFilePreviousYear]: acc[lastMonthFromLastFilePreviousYear] + (o[lastMonthFromLastFilePreviousYear] || 0),
        [lastMonthFromLastFile]: acc[lastMonthFromLastFile] + (o[lastMonthFromLastFile] || 0),
    }), {[lastMonthFromLastFilePreviousYear]: 0, [lastMonthFromLastFile]: 0})

    return [
        {
            id: "caplTurnoverBySurFamily",
            subTitle: {
                text: 'turnoverBySurFamilySubtitle',
                data: {
                    lastYear: formatedLastYearPreviousMonth,
                    lastYearTotal: _.round(total[lastMonthFromLastFilePreviousYear], 2),
                    currentYear: formatedPreviousMonth,
                    currentYearTotal: _.round(total[lastMonthFromLastFile], 2)
                }
            },
            dataKeys:[lastMonthFromLastFile, lastMonthFromLastFilePreviousYear],
            data: finalResult
        }
    ]
}
export const entity = {
    name: 'CaplTurnoverBySurFamily',
    dashboard: true,
    fields: ["id", "subTitle", "dataKeys", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
