export const SET_UI_MODE = 'SET_UI_MODE'

export const setUiMode = mode => ({
    type: SET_UI_MODE,
    mode
})

export const TOGGLE_CALENDAR = 'TOGGLE_CALENDAR'

export const toggleCalendar = id => ({
    type: TOGGLE_CALENDAR,
    id
})

export const CHANGE_RANGE_CALENDAR = 'CHANGE_RANGE_CALENDAR'

export const changeRangeCalendar = (id, index, opened) => ({
    type: CHANGE_RANGE_CALENDAR,
    id,
    index,
    opened
})

export const SET_FORM_OBJECT = 'SET_FORM_OBJECT'

export const setFormObject = (object, module, objectMode) => ({
    type: SET_FORM_OBJECT,
    object,
    module,
    objectMode
})

export const SET_EDIT_DEFAULT_OBJECT = 'SET_EDIT_DEFAULT_OBJECT'

export const setEditDefaultObject = object => ({
    type: SET_EDIT_DEFAULT_OBJECT,
    object
})

export const SET_FIELD_LIST_OPTIONS = 'SET_FIELD_LIST_OPTIONS'

export const setFieldListOptions = (id, ids) => ({
    type: SET_FIELD_LIST_OPTIONS,
    id,
    ids
})

export const SET_FIELD_VISIBILITY = 'SET_FIELD_VISIBILITY'

export const setFieldVisibility = (id, visible) => ({
    type: SET_FIELD_VISIBILITY,
    id,
    visible
})

export const SET_FIELD_EDITION = 'SET_FIELD_EDITION'

export const setFieldEdition = (id, editable) => ({
    type: SET_FIELD_EDITION,
    id,
    editable
})

export const SET_FIELD_ACTIVE = 'SET_FIELD_ACTIVE'

export const setFieldActive = (id, active) => ({
    type: SET_FIELD_ACTIVE,
    id,
    active
})

export const SET_FIELD_REMOVABLE = 'SET_FIELD_REMOVABLE'

export const setFieldRemovable = (id, removable) => ({
    type: SET_FIELD_REMOVABLE,
    id,
    removable
})

export const SET_FIELD_NEWABLE = 'SET_FIELD_NEWABLE'

export const setFieldNewable = (id, newable) => ({
    type: SET_FIELD_NEWABLE,
    id,
    newable
})

export const SET_FIELD_START_DATE = 'SET_FIELD_START_DATE'

export const setFieldStartDate = (id, startDate) => ({
    type: SET_FIELD_START_DATE,
    id,
    startDate
})

export const SET_FIELD_END_DATE = 'SET_FIELD_END_DATE'

export const setFieldEndDate = (id, endDate) => ({
    type: SET_FIELD_END_DATE,
    id,
    endDate
})


export const SET_FIELD_WIDTH = 'SET_FIELD_WIDTH'

export const setFieldWidth = (id, width) => ({
    type: SET_FIELD_WIDTH,
    id,
    width
})

export const SET_CELL_START_DATE = 'SET_CELL_START_DATE'

export const setCellStartDate = (id, startDate) => ({
    type: SET_CELL_START_DATE,
    id,
    startDate
})

export const SET_CELL_END_DATE = 'SET_CELL_END_DATE'

export const setCellEndDate = (id, endDate) => ({
    type: SET_CELL_END_DATE,
    id,
    endDate
})

export const SET_LIST_ELEMENT_SELECTION = 'SET_LIST_ELEMENT_SELECTION'

export const setListElementSelection = (id, isChecked) => ({
    type: SET_LIST_ELEMENT_SELECTION,
    id,
    isChecked
})

export const FILTER_DATA_TABLE = 'FILTER_DATA_TABLE'

export const filterDataTable = value => {
    return {
        type: FILTER_DATA_TABLE,
        value
    }
}

export const SORT_DATA_TABLE = 'SORT_DATA_TABLE'

export const sortDataTable = (sortBy, sortDirection) => {
    return {
        type: SORT_DATA_TABLE,
        sortBy,
        sortDirection
    }
}

export const SET_COLUMN_SELECTOR_OPENED = 'SET_COLUMN_SELECTOR_OPENED'

export const setColumnSelectorOpened = (opened, groupModel) => {
    return {
        type: SET_COLUMN_SELECTOR_OPENED,
        opened
    }
}

export const SET_ACTION_SELECTOR_OPENED = 'SET_ACTION_SELECTOR_OPENED'

export const setActionSelectorOpened = opened => {
    return {
        type: SET_ACTION_SELECTOR_OPENED,
        opened
    }
}

export const SET_FILTERS_OPENED = 'SET_FILTERS_OPENED'

export const setFiltersOpened = opened => {
    return {
        type: SET_FILTERS_OPENED,
        opened
    }
}

export const SET_CHART_FILTERS_OPENED = 'SET_CHART_FILTERS_OPENED'

export const setChartFiltersOpened = opened => {
    return {
        type: SET_CHART_FILTERS_OPENED,
        opened
    }
}

export const INITIALIZE_MODULE = 'INITIALIZE_MODULE'

export const initializeModule = (module, groupModel, user, language) => {
    return {
        type: INITIALIZE_MODULE,
        module,
        groupModel,
        user,
        language
    }
}

export const TOGGLE_COLUMN_VISIBILITY = 'TOGGLE_COLUMN_VISIBILITY'

export const toggleColumnVisibility = columnId => {
    return {
        type: TOGGLE_COLUMN_VISIBILITY,
        columnId
    }
}

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const clearErrors = () => ({
    type: CLEAR_ERRORS
})

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const setLanguage = language => ({
    type: SET_LANGUAGE,
    language
})

export const SET_DEFAULT_EDIT_OBJECT = 'SET_DEFAULT_EDIT_OBJECT'

export const setDefaultEditObject = defaultObject => ({
    type: SET_DEFAULT_EDIT_OBJECT,
    defaultObject
})

export const SET_OBJECT_MODE = 'SET_OBJECT_MODE'

export const setObjectMode = objectMode => ({
    type: SET_OBJECT_MODE,
    objectMode
})

export const CHANGE_FIELD_DISABLED = 'CHANGE_FIELD_DISABLED'

export const changeFieldDisabled = (id, disabled) => ({
    type: CHANGE_FIELD_DISABLED,
    id,
    disabled
})

export const CHANGE_FIELD_DEFAULT = 'CHANGE_FIELD_DEFAULT'

export const changeFieldDefault = (id, value) => ({
    type: CHANGE_FIELD_DEFAULT,
    id,
    value
})

export const CHANGE_FIELD_REQUIRED = 'CHANGE_FIELD_REQUIRED'

export const changeFieldRequired = (id, required) => ({
    type: CHANGE_FIELD_REQUIRED,
    id,
    required
})

export const CHANGE_FIELD_PROPERTY = 'CHANGE_FIELD_PROPERTY'
export const changeFieldProperty = (id, property, value) => ({
    type: CHANGE_FIELD_PROPERTY,
    id,
    property,
    value
})

export const EMPTY_EDIT_OBJECT = 'EMPTY_EDIT_OBJECT'

export const emptyEditObject = () => ({
    type: EMPTY_EDIT_OBJECT
})

export const SET_FORM_BUTTONS = 'SET_FORM_BUTTONS'

export const setFormButtons = buttons => ({
    type: SET_FORM_BUTTONS,
    buttons
})
export const SET_DATA_LIST = 'SET_DATA_LIST'

export const setDataList = (id, data) => ({
    type: SET_DATA_LIST,
    id,
    data
})

export const UPDATE_OBJECT = 'UPDATE_OBJECT'

export const updateObject = data => ({
    type: UPDATE_OBJECT,
    data
})

export const SET_FILTER_MEMORY = 'SET_FILTER_MEMORY'

export const setFilterMemory = query => ({
    type: SET_FILTER_MEMORY,
    query
})

export const PROCESS_DYNAMIC_COLUMNS = 'PROCESS_DYNAMIC_COLUMNS'

export const processDynamicColumns = data => ({
    type: PROCESS_DYNAMIC_COLUMNS,
    data
})

export const FETCH_FORM_DATA_LISTS_DONE = 'FETCH_FORM_DATA_LISTS_DONE'

export const doneFetchingFormDataLists = () => ({
    type: FETCH_FORM_DATA_LISTS_DONE
})

export const UPDATE_CHART_PARAMS = 'UPDATE_CHART_PARAMS'

export const updateChartParams = (data) => ({
    type: UPDATE_CHART_PARAMS,
    data
})
