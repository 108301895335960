import React from 'react'
import PropTypes from 'prop-types'
import './CheckBox.css'

const Checkbox = ({ selected, handleCheckboxChange, label, disabled, t = x => x }) => {

        return (
            <div className="Checkbox">
                <label
                    style={{display: "flex"}}
                    onClick={ event => {
                        if(handleCheckboxChange) {
                            event.stopPropagation()
                            !disabled && handleCheckboxChange(!selected)
                        }
                    }}
                >
                    <input
                        type="checkbox"
                        className="Checkbox-input"
                        checked={selected}
                        onChange={() => undefined}
                        disabled={disabled}
                        onClick={event => event.stopPropagation()}
                    />
                    <span className="cr">
                        <i className="cr-icon glyphicon glyphicon-ok"/>
                    </span>
                    {label && <div className="Checkbox-label"> {t(label)} </div>}
                </label>
            </div>
        )
}

Checkbox.propTypes = {
    selected: PropTypes.bool.isRequired
}

export default Checkbox
