import moment from 'moment'

const translation = {
    'years': 'an(s)',
    'months': 'moi(s)',
    'days': 'jour(s)'
}
function validateAge(birthDate, exerciseDate, ageLimit) {
    const birthday = moment(birthDate, 'YYYY-MM-DD')
    const birthDayLimit = moment(exerciseDate, 'YYYY-MM-DD').subtract(ageLimit, 'years').subtract(1, 'days')

    return birthday.isAfter(birthDayLimit)
}
function getFormattedDate(date1, date2) {
    const intervals = ['years','months','days']
    const out = []

    intervals.forEach(function(interval) {
        const diff = date2.diff(date1, interval)
        date1.add(diff, interval)
        out.push((diff > 0 ? diff : '0') + ' ' + translation[interval])
    });
    return out.join(' ');
}

export function calculateAge(birthDate, exerciseDate) {
    const birthdayMoment = moment(birthDate, 'YYYY-MM-DD')
    const exerciseMoment = moment(exerciseDate, 'YYYY-MM-DD')
    return getFormattedDate(birthdayMoment, exerciseMoment)
}
