import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {unregister} from "./registerServiceWorker";

const rootEl = document.getElementById('root')

ReactDOM.render(
    <App />,
    rootEl
)
unregister()
