import {generateLogPdf} from "../utils/importLogPdf";

const _ = require('lodash')
const { generateFetchFieldListAction } = require('../../../../apps/KpModule/actions/api')
const {setFieldListOptions} = require('../../../../apps/KpModule/actions')
const fs = require('fs')
const path = require('path');
const moment = require('moment')
const { setFieldVisibility } = require("../../../../apps/KpModule/actions");
const { generateXlsx } = require('../dataImpExp/generateExcel')
const { dataImportEngineXls } = require('../dataImpExp/importExcel')
const { dataImportEngineCsv } = require('../dataImpExp/importCsv')
export const entities = [
    {
        name: 'ImportExportData',
        facets: [
            'files',
            {name: 'files', linkType: 'OTO'},
            {name: 'files', linkType: 'OTO', path: 'log'}
        ],
        fields: [
            'ImportExportType',
            'MeshDataType',
            {type: 'ElementDataType', nullable: true},
            {type: 'DataYear', nullable: true},
            'date',
            {path: "success", type: "boolean", nullable: true}
        ],
        beforeSave: function (newObject, oldObject, context, callback) {

            let query = {mesh: newObject.meshDataType.id}
            newObject.date = moment().format('YYYY-MM-DD HH:mm:ss')

            if(newObject.importExportType.id === 'imp') {
                const gfs = new global.GridFSBucket(global.db);
                const downloadStream = gfs
                    .openDownloadStream(global.ObjectID(newObject.files[0].id))

                const fileName = `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${newObject.files[0].filename}`
                const file = {
                    name: fileName,
                    path: path.join(process.cwd(), `./tempo/${fileName}`)
                }
                const fileType = fileName.split('.').pop()
                let writeStream = fs.createWriteStream(file.path)
                downloadStream
                    .pipe(writeStream)
                    .on("error", e => {
                        console.log('Error', e);
                        callback(e)
                    })
                    .on("finish", () => {
                        console.log('file downloaded')
                        if(['xls', 'xlsx'].includes(fileType)) {
                            dataImportEngineXls(newObject, file, query, context)
                                .then( result => {
                                    const success = !result.userErrors.length
                                    const engineOutput = {processedLines: result.processedLines}
                                    const systemError = []
                                    const userErrors = result.userErrors
                                    const params = {
                                        success, systemError, userErrors,
                                        engineOutput, file
                                    }
                                    generateLogPdf(params, (e, logFilePDF) => {
                                        if(e) return callback(e)
                                        fs.unlink(file.path, err => {
                                            if (err) return callback({systemError: e})
                                            console.log(`${file.path} was deleted`);
                                            newObject.success = success
                                            newObject.log = logFilePDF
                                            newObject.file = newObject.files[0]
                                            callback(null, newObject, oldObject)
                                        })
                                    })
                                })
                                .catch(error => {
                                    fs.unlink(file.path, e => {
                                        if (e) return callback(e);
                                        console.log(`${file.path} was deleted`);
                                        callback(error)
                                    })
                                })
                        } else {
                            callback(new Error(context.tc('badFileFormat')))
                        }

                        // if(fileType === 'csv') {
                        //     dataImportEngineCsv(file, query, context, (e, result) => {
                        //         if (e) return callback(e)
                        //         newObject.success = result.success
                        //         newObject.file = newObject.files[0]
                        //         newObject.log = result.logFilePDF
                        //         callback(null, newObject, oldObject)
                        //     })
                        // }

                    });
            } else {
                if(newObject.dataYear) {
                    query.year = _.toNumber(newObject.dataYear.id)
                }
                if(newObject.elementDataType) {
                    switch (newObject.meshDataType.id) {
                        case '1': // Subsidiary
                            query = {...query, subsidiary: global.ObjectID(newObject.elementDataType.id)}

                            break
                        case '3': // Country
                            query =  {...query, country: global.ObjectID(newObject.elementDataType.id)}
                            break
                        case '4': //Shop
                            query =  {...query, shop: global.ObjectID(newObject.elementDataType.id)}
                            break
                        case '5': // BPEndorsed
                            query =  {...query, bp: global.ObjectID(newObject.elementDataType.id)}
                            break
                        case '2': // HI
                            break
                        default:
                            break
                    }
                }
                const userName = _.get(context, 'user.name', 'unknown user')
                generateXlsx(query, newObject, context, (e, file) => {
                    if(e) callback(e)

                    newObject.file = newObject.lastExtraction = {
                        ...file,
                        user: userName,
                        date: moment().format('YYYY-MM-DD HH:mm')
                    }
                    newObject.success = true
                    callback(null, newObject, oldObject)
                })
            }
        },
        afterSave: function (object, oldObject, context, callback) {
            callback()
        }
    }
]

export const modules = [
    {
        object: 'ImportExportData',
        tKey: 'mTitle_ImportExportData',
        category: {
            path: 'empiric',
            icon: 'briefcase'
        },
        defaultSortBy: 'date',
        defaultSortDirection: 'DESC',
        //updatable: false,
        viewMap: {
            dt: [{path: 'importExportType', tKey: 'flow'}, 'date', 'meshDataType', 'dataYear', {path: 'success', width: 50}, {path: 'file', width: 50}, {path: 'log', width: 50}],
            form: [
                {
                    path: 'importExportType',
                    tKey: 'flow',
                    default: {id: 'exp'},
                    editable: false,
                    clearable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            const visible = newValue && newValue.id === 'imp'
                            store.dispatch(setFieldVisibility(`e_elementDataType`, !visible))
                            store.dispatch(setFieldVisibility(`e_dataYear`, !visible))
                            store.dispatch(setFieldVisibility(`e_files`, visible))
                        }
                    }
                },
                {
                    path: 'meshDataType',
                    editable: false,
                    clearable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            if (newValue && newValue.id) {
                                let data = {}
                                switch (newValue.id) {
                                    case '1':
                                        data =  {dataType: 'Subsidiary'}
                                        break
                                    case '3':
                                        data =  {dataType: 'Country'}
                                        break
                                    case '4':
                                        data =  {dataType: 'Shop'}
                                        break
                                    case '5':
                                        data =  {dataType: 'BPEndorsed'}
                                        break
                                    default:
                                        data =  {dataType: newValue.id}
                                }

                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-B-importExportData.ImportExportData_elementDataType',
                                        store.getState,
                                        'form',
                                        {data}
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions("e_elementDataType", []))
                            }
                        }
                    }
                },
                {
                    path: 'elementDataType',
                    display: 'fullName',
                    editable: false
                },
                {path: 'dataYear', editable: false},
                {path: 'files', maxLength: 1, required: true},
            ]
        }
    }
]
