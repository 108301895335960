export const entities = [
    {
        name: 'Funding',
        fields:[
            //'year',
            'Mode',
            'object',
            'amount',
            'financialCost',
            'echeanceAmount',
            'description'
        ],
    },
    {
        name: 'Mode',
        facets: ['codeName']
    }
]


export const module_ = {
    object: 'Mode',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    tKey: 'mode financement',
    viewMap: {
        dt: [
            'code',
            'name'
        ],
        form: [
            'code',
            'name'
        ]
    }
}