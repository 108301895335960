import {setFieldVisibility, changeFieldDisabled} from "../../../../apps/KpModule/actions";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";


export const entity = {
    name: 'Role',
    fields: [
        {path: 'profile', type: 'Profile', link: 'MTO', unique: true},
        {path: 'organizationalAxis', type:'AxisAndMesh', link: 'MTO'},
        {
            path:'profileName',
            fieldPath: ['profile.name'],
            f: function () {
                    return this.profile?.name
            }
        },
    ],
    filters:[
        { //not yet
            name: "byOrganizationalAxis",
            isDefault: false,
            query: function(context) {
                const organizationalAxisID = _.get(context.data, 'organizationalAxis.id')
                return organizationalAxisID ? { $or: [ {"organizationalAxis": global.ObjectID(organizationalAxisID)},{"exhaustiveScope": true} ]} : {"organizationalAxis": "0"}
            }
        },
        { // verified
            name: "byOrganizationAndMesh",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                let axesIDs = ['000000000000000000000002']
                const organizations = _.get(context.data, 'organizations') || []
                if(!organizations.length) return callback(null, {_id: null})
                if (organizations[0].joinedEntity === "OrganizationalMesh"){
                    axesIDs.push('000000000000000000000001')
                }
                const organizationsIDs = organizations.map( organization=> global.ObjectID(organization.id))
                global.app.SE.OrganizationalMesh.find(
                    {
                        ...basicContext(context),
                        fieldPath: ["attachments.id"],
                        query: {
                            $or: [ { "attachments": { $elemMatch: {$in : organizationsIDs} }},{_id: {$in : organizationsIDs}}]                                }
                    }, (e, meshes) => {
                        if(e) return callback(e)
                        let augmentedOrgs = organizations.map( org => global.ObjectID(org.id))
                        if ( !!meshes.length ){
                            axesIDs.push('000000000000000000000001')
                            const relatedOrgsIDsList = meshes.map(mesh => mesh.attachments)
                            const relatedOrgsIDs = relatedOrgsIDsList.flat(1).map( org => global.ObjectID(org.id))
                            augmentedOrgs.push(relatedOrgsIDs)
                            augmentedOrgs = augmentedOrgs.flat(1)
                            let uniqueOrgsIDsString = []
                            augmentedOrgs.forEach(function (roleID){
                                if (!uniqueOrgsIDsString.includes(JSON.stringify(roleID))) {uniqueOrgsIDsString.push(JSON.stringify(roleID))}
                            })
                            augmentedOrgs = uniqueOrgsIDsString.map(stringID => global.ObjectID(JSON.parse(stringID)))
                        }
                        global.app.SE.Organization.find(
                            {
                                ...basicContext(context),
                                fieldPath: ["attachment.id"],
                                query: {
                                    _id: {$in: augmentedOrgs}
                                }
                            }, (e, orgs) => {
                                if (e) return callback(e)
                                orgs.forEach( org => axesIDs.push(global.ObjectID(org.organizationalAxis.id)))
                                let uniqueAxesIDsString = []
                                axesIDs.forEach(function (id){
                                    if (!uniqueAxesIDsString.includes(JSON.stringify(id))) {uniqueAxesIDsString.push(JSON.stringify(id))}
                                })
                                const uniqueAxesIDs = uniqueAxesIDsString.map(stringID => global.ObjectID(JSON.parse(stringID)))
                                callback(e, {"organizationalAxis": { $in: uniqueAxesIDs }})
                            }
                        )
                    })
            }
        }
    ]
}

export const module_ = {
    object: 'Role',
    tKey: 'fonction',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    defaultSortBy: 'profile',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'profile', tKey: "profil", translateName: true},
            {path: 'organizationalAxis', tKey: 'Périmètre', translateName: true},
        ],
        form: [
            {
                path: 'profile',
                tKey: 'profil',
                editable: false,
                translateName: true
                //unique: true
            },
            {
                path: 'organizationalAxis',
                tKey: 'Périmètre',
                editable: false,
                //display: 'translatedName',
                translateName: true
            },
        ],
    }
}