const _ = require('lodash')
const { setFieldListOptions, setFieldVisibility, changeFieldRequired} = require('../../../../apps/KpModule/actions')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')

export const entities = [
    {
        name: 'StateModelLine',
        type: 'mongoInternal',
        fields: [
            'AccountModel',
            'AccountModelType',
            { path: 'order', type: 'integer' },
            'wording',
            'ModelStyle',
            'ModelFormat'
        ]
    },
    {
        name: 'StateModel',
        facets: ['translatedField', { name: 'codeName', uniqueName: true }],
        fields: [
            {path: 'category', type: 'AccountCategory', index: true},
            {
                path: 'yearOfStart',
                type: 'StateModelYear',
                sorters: ['byOrder']
            },
            {
                path: 'yearOfEnd',
                type: 'StateModelYear',
                sorters: ['byOrder']
            },
            {
                path: 'stateModelLines',
                type: 'StateModelLine',
                link: {
                    type: 'OTM',
                    external: false
                }
            },
            {
                path: 'scenario',
                type: 'ProjectContextM1M2',
                link: 'MTM'
            },
            {
                path: 'translatedFullName',
                $f:(object, context, callback) => {
                    const translatedName = context.translateName
                        ? context.translateName(object.name)
                        : object.name
                    callback(null, `${object.code}-${translatedName}`)
                }
            }
        ],
        filters: [{
            name: 'byKindProject',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const kinds = _.get(context, 'data.kind')
                if(kinds){
                    global.app.B.AccountCategory.find(
                        {
                            group: context.group,
                            query: { kind: {$in: kinds} }
                        },
                        (e, accountCategories) => {
                            const ids = accountCategories
                                ? accountCategories.map(ac => new global.ObjectID(ac.id))
                                : []
                            callback(e, {
                                category: { $in: ids }
                            })
                        }
                    )
                } else {
                    callback(null, {_id: null})
                }
            }
        }],
        ps: {
            delete: [
                {
                    $$v: function (stateModel, callback) {
                        global.app.B.StateModelAssignment.find({
                            group: this.options.context.group,
                            query: {stateModels: { $elemMatch: {$eq: global.ObjectID(stateModel.id)} }}
                        }, (e, stateModelAssignment) => {
                            if (e) return callback(e)

                            if (stateModelAssignment && stateModelAssignment.length) callback(new Error('stateModelIsAssignedToAUser'))
                            else callback()
                        });
                    }
                }
            ]
        },
    }
]

export const module_ = {
    name: 'StateModel',
    object: 'StateModel',
    tKey: 'mTitle_stateModel',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            { path: 'category', display: 'fullName'},
            'code',
            { path: 'name', type: 'translatedText' },
            'yearOfStart',
            'yearOfEnd'
        ],
        form: [
            {
                path: 'category',
                display: 'fullName',
                editable: false,
                fieldPath: ['id', 'fullName', 'kind.id'],
                subscriptions: {
                    onChange: (newValue, oldValue, { module, store }) => {
                        if (newValue && newValue.id) {
                            const kindQuery = {
                                kind: { id: newValue.kind.id }
                            }
                            store.dispatch(
                                generateFetchFieldListAction(
                                    'm-B-stateModel.StateModelLine_accountModel',
                                    store.getState,
                                    'form',
                                    { data: kindQuery }
                                )
                            )
                            // only Kind Project BpStore And BpStore Details
                            let kindIsProjectBpStoreOrBpStoreDetails = ['6', '7'].includes(_.get(newValue, 'kind.id'))
                            if(!kindIsProjectBpStoreOrBpStoreDetails) {
                                const scenario = module.viewMap.form.fields.find(
                                    field => field.path === 'scenario'
                                )
                                scenario.setValue(null)
                            }
                            store.dispatch(setFieldVisibility('e_scenario',kindIsProjectBpStoreOrBpStoreDetails))
                            store.dispatch(changeFieldRequired('e_scenario', kindIsProjectBpStoreOrBpStoreDetails))

                        } else {
                            store.dispatch(
                                setFieldListOptions(
                                    'e_stateModelLines.l_accountModel',
                                    []
                                )
                            )
                        }
                    }
                }
            },
            'code',
            { path: 'name', type: 'textarea' },
            { path: 'scenario' },
            { path: 'yearOfStart', sortList: false },
            { path: 'yearOfEnd', sortList: false },
            {
                path: 'stateModelLines',
                defaultSortBy: 'order',
                defaultSortDirection: 'ASC',
                removable: true,
                viewMap: {
                    dt: [
                        { path: 'accountModel', display: 'name' },
                        'order',
                        { path: 'wording', tKey: 'name', type: 'translatedText' },
                        {
                            path: 'modelStyle',
                            translate: true
                        }
                    ],
                    form: [
                        {
                            path: 'accountModel',
                            fieldPath: ['id', 'name', 'fullInfos'],
                            display: 'fullInfos',
                            filters: ['byAccountCategoryKind']
                        },
                        { path: 'order', required: true },
                        { path: 'wording', tKey: 'name', type: 'textarea' },
                        {
                            path: 'modelStyle',
                            translate: true
                        }
                    ]
                }
            }
        ]
    },
    filters: [
        /*
        {
            path: 'category',
            object: 'AccountCategory',
            display: 'fullName',
            client: true,
            default: { id: null },
            query: function(context) {
                const category = _.get(context.data, 'category.id')

                return category
                    ? { category }
                    : { _id: false }
            }
        }

         */
    ]
}
