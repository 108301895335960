const {fieldPathJoinGetter} = require("../../utils/kp3Utils");

export const entity = {
    name: 'ProductAxisType',
    facets: ['codeName', 'translatedField'],
    fields: [
        fieldPathJoinGetter({path: "tFullName", fieldPath: ["code", "tName"]})
    ]
}

export const module_ = {
    object: 'ProductAxisType',
    tKey: 'mTitle_productAxisType',
    category: {
        path: 'product',
        icon: 'server'
    },
    viewMap: {
        dt: ['code', { path: 'name', type: 'translatedText' }],
        form: ['code', { path: 'name', type: 'textarea' }]
    }
}
