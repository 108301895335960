import React from 'react'
import './CursorField.css'
import classnames from "classnames";
import ValidationUI from '../ValidationUI'

const CursorField = ({options, value, onChange, display,  disabled, touched, error}) => {
    return (
        <div className='Cursor-container'>
            {
                options.map(option => {
                    return <input
                        key={option.id}
                        type='button'
                        className={classnames('Cursor-Button', {
                            selected: option.id === value,
                            disabledButton: disabled
                        })}
                        value={option[display]}
                        onClick={()=> onChange(option.id)}
                        disabled={disabled}
                    />
                })
            }
            { touched && (error && <ValidationUI error={error}/>) }
        </div>
    )
}


export default CursorField
