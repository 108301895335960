import Errors from "../../../../utils/Errors";
import {setFieldStartDate} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import {basicContext} from "../../../../utils/contextUtils";

export const entity = {
    name: 'OrganizationalAxis',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields: [
        {path: 'includeInFunctionScope', type: 'boolean'},
        {path: 'projectBreakdownAxe', type: 'boolean'},
        {path: 'workflowEnabledAxe', type: 'boolean'},
        /*
        {
            path:  'translatedName',
            fieldPath: ['name'],
            $f: function(object, context, callback) {
                callback(null, `${context.translateName(object.name)}`)
            }
        }
         */
    ],
    uniqueProcess: function ( ID, workflowEnabledAxe, includeInFunctionScope, projectBreakdownAxe, context, callback) {
        if (workflowEnabledAxe && !includeInFunctionScope){
            return callback(new Errors.ValidationError("L'organisation du Workflow doit correspondre à l'organisation des Fonctions. Merci de cocher les deux cases"))
        }
        if ( projectBreakdownAxe ){
            return global.app.SE.OrganizationalAxis.find({
                ...basicContext(context),
                fieldPath: [],
                query: {
                    "_id": {$ne: global.ObjectID(ID)},
                    "projectBreakdownAxe": true
                }
            }, (e, axis) => {
                if (!!axis && !!axis.length){
                    return callback(new Errors.ValidationError("Un seul axe doit avoir la case « Ventilation projets par direction » cochée"))
                }
                return callback()
            })
        }
        callback()
    },
    validateSave: function (newObject, oldObject, context, callback) {
        this.uniqueProcess( newObject.id, newObject.workflowEnabledAxe, newObject.includeInFunctionScope, newObject.projectBreakdownAxe, context, callback)
    },
}

export const module_ = {
    object: 'OrganizationalAxis',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',  //DESC
    viewMap: {
        dt: [ 'code',
            {path: 'name', type: 'translatedText'},
            //{path:  'translatedName',}
        ],
        form: [
            {path: 'code', editable: false},
            {path: 'name', type: 'textarea'},
            {path: 'includeInFunctionScope',tKey: 'functionAxis'},
            {path: 'projectBreakdownAxe', tKey: 'projectVentilationPreValidation'},
            {
                path: 'workflowEnabledAxe',
                tKey: 'workflowOrganizationalAxis',
                subscriptions: {
                    onChange(newValue, oldValue, {module, store}) {
                        const state = store.getState()
                        const includeInFunctionScopeField = module.viewMap.form.fields.find(field => field.path === 'includeInFunctionScope')
                        includeInFunctionScopeField.setValue(!!newValue)
                    }
                }
            },
        ],
    },
}
