import React, { Component } from "react"
import PropTypes from "prop-types"
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import Collapse from 'react-collapse'
import classnames from 'classnames'
import "./Accordion.css"
import {I18n} from "react-i18next";

class Accordion extends Component {
    renderHeading() {
        return this.props.renderHeading && this.props.renderHeading()
    }

    renderPanel({ panelKey, height }) {
        return (
            <Collapse
                key={panelKey}
                isOpened={this.props.openedPanel === panelKey}
                fixedHeight={height}
                theme={{
                    //collapse: 'Accordion-Collapse-collapse',
                    collapse: classnames(
                        'Accordion-Collapse-collapse',
                        `${panelKey === 'innerList' && this.props.openedPanel === 'innerList'  ? 'Accordion-innerAccordion': ''}`,
                    ),
                    content: classnames(
                        this.props.panelClassName,
                        { fillAllHeight: height }
                    )
                }}
            >
                <I18n ns={[this.props.model, 'platform']}>
                    {t => this.props.renderPanel(panelKey, t)}
                </I18n>
            </Collapse>
        )
    }

    renderFillingPanels() {
        return (
            <div className={`Accordion-panels ${this.props.mainAccordion ? 'Accordion-borderless-panels' : ''}`}>
                <AutoSizer>
                    {({ height, width }) => (
                        <div
                            style={{ height: `${height}px`, width: `${width}px`}}
                        >
                            {this.props.panelKeys.map(
                                panelKey => this.renderPanel({ panelKey, height })
                            )}
                        </div>
                    )}
                </AutoSizer>
            </div>
        )
    }

    renderPanels() {
        return (
            <div className="Accordion-panels">
                {this.props.panelKeys.map(
                    panelKey => this.renderPanel({ panelKey })
                )}
            </div>
        )
    }

    render() {
        return (
            <div className="Accordion panel-group">
                {this.renderHeading()}
                {this.props.fillAllHeight
                    ? this.renderFillingPanels()
                    : this.renderPanels()
                }
            </div>
        )
    }
}

Accordion.propTypes = {
    openedPanel: PropTypes.string.isRequired,
    panelKeys: PropTypes.arrayOf(PropTypes.string),
    renderHeading: PropTypes.func,
    renderPanel: PropTypes.func.isRequired,
    fillAllHeight: PropTypes.bool.isRequired,
    panelClassName: PropTypes.string
}

export default Accordion;
