function arrayHasDuplicates(array) {
    const valuesSoFar = Object.create(null);
    for (let i = 0; i < array.length; ++i) {
        const value = array[i];
        if (value in valuesSoFar) {
            return true;
        }
        valuesSoFar[value] = true;
    }
    return false;
}

export const entity = {
    name: 'OrganizationalWorkflowMesh',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields:[
        {type: 'Organization', link: 'MTM'}
    ],
    validateSave: function(object, oldObject, context, callback) {
        const axes = object.organizations.map(o => o.organizationAxis.code)
        const hasDuplicates = arrayHasDuplicates(axes)
        if(hasDuplicates) callback(new Error(context.t("MustNotHaveDuplicateOrganizationAxes")))
        else callback()
    },
}
export const module_ = {
    object: 'OrganizationalWorkflowMesh',
    tKey: 'mTitle_organizationalWorkflowMesh',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'code',
            'name',
            {path: 'organizations', tKey: 'attachments', display: 'fullNameWithAxisCode'}
        ],
        form: [
            {path: 'code'},
            {path: "name"},
            {path: 'organizations', tKey: 'attachments', display: 'fullNameWithAxisCode', required: true}
        ]
    }
}
