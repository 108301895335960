export const entity = {
    name: 'DataType',
    facets: ['translatedField', { name: 'codeName', uniqueName: true }],
    fields: ['Format', 'Precision'],
    ps: {
        delete: [
            {
                $$v: function (dataType, callback) {
                    global.app.B.HypothesisModelLine.find({
                        group: this.options.context.group,
                        query: { $or : [
                                {dataTypeM1: { $elemMatch: {$eq: global.ObjectID(dataType.id)} }},
                                {dataTypeM2: { $elemMatch: {$eq: global.ObjectID(dataType.id)} }}
                            ]}
                    }, (e, hModelLines) => {
                        if (e) return callback(e)

                        if (hModelLines && hModelLines.length) callback(new Error('dataTypeIsUsedInHypothesisModelLine'))
                        else callback()
                    });
                }
            }
        ]
    }
}
export const module_ = {
    object: 'DataType',
    tKey: 'mTitle_unit',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'code',
            { path: 'name', type: 'translatedText' },
            { path: 'format', tKey: 'unit', translate: true },
            { path: 'precision', tKey: 'decimal' }
        ],
        form: [
            'code',
            { path: 'name', type: 'textarea' },
            { path: 'format', tKey: 'unit', translate: true },
            { path: 'precision', tKey: 'decimal'}
        ]
    }
}
