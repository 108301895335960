import React from 'react'
import {AlertCircle} from 'react-feather'

const ValidationUI = (props) => {
    const translate = props.t
        ? props.t
        : x => x
    return <span style={{color: 'rgb(212 2 2 / 90%)', display: 'flex', alignSelf: 'start' }}><AlertCircle size={14} style={{margin: 'auto 5px auto 5px'}}/> {translate(props.error)}</span>
}

export default ValidationUI
