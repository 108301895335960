import {
    FETCH_FORM_DATA_LISTS_DONE,
    setFieldVisibility, updateChartParams
} from "../../../../apps/KpModule/actions";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";
import {translateName} from "../../../../utils/i18n";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import {actionTypes as reduxFormActions} from "redux-form";
import React from 'react'



async function getAmountsByMonth(context) {
    const perimeterOptions = _.get(context, 'data.perimeterOptions', []) || []
    const analysisOption = _.get(context, 'data.analysisOption')

    const allRealizationTypes = await global.app.SE.RealizationType.find({
        ...basicContext(context),
        fieldPath: [],
        query: {}
    })

    const dynamicDisplayedAmounts = allRealizationTypes.map(realizationType => {
        return {
            id: realizationType.id,
            name: `Type suivi : ${context.translateName(realizationType.name)} ( ${realizationType.code} )`,
            code: realizationType.code,
            path: realizationType.code,
        }
    })
    const staticDisplayedAmounts = [
        {id:'000000000000000000000003', name: "projectValidatedAndOngoingValidatedPValAndOngoing", path: 'validatedAndOngoingValidated', code: "pValAndOngoing"},
        {id:'000000000000000000000001', name: "projectValidatedPVal", path: 'validated', code: "pVal"},
        {id:'000000000000000000000002', name: "projectOngoingValidatedPOngoing", path: 'ongoingValidated', code: "pOngoing"},

        {id:'000000000000000000000006', name: "demandValidatedAndOngoingValidatedDOngoingAndVal", path: 'demandValidatedAndOngoingValidated', code: "dOngoingAndVal"},
        {id:'000000000000000000000004', name: "demandValidatedDVal", path: 'demandValidated', code: "dVal"},
        {id:'000000000000000000000005', name: "demandOngoingValidatedDOngoing", path: 'demandOngoingValidated', code: "dOngoing"},

        {id:'000000000000000000000007', name: "projectBudgetBud", path: 'budget', code: "bud"},
        {id:'000000000000000000000008', name: "projectEstimatedEst", path: 'estimated', code: "est"},
        {id:'000000000000000000000009', name: "projectRevisedRev", path: 'revised', code: "rev"},
        //{id:'6', name: "realizationTypes", path: 'realizationTypes'},
    ]

    const allDisplayedAmounts = [...staticDisplayedAmounts, ...dynamicDisplayedAmounts]
    const displayedAmounts = _.get(context, 'data.displayedAmounts') || allDisplayedAmounts


    const stackOption = _.get(context, 'data.stackOption')
    console.log('stackOption', stackOption)
    const selectedFiscalYears = perimeterOptions.filter(object => object.joinedEntity === 'FiscalYear')
    const selectedCategories = perimeterOptions.filter(object => object.joinedEntity === 'DemandNature')
    const selectedProjects = perimeterOptions.filter(object => object.joinedEntity === 'Demand')
    const selectedOrganizations = perimeterOptions.filter(object => object.joinedEntity === 'Organization')
    const selectedOrganizationalMeshes = perimeterOptions.filter(object => object.joinedEntity === 'OrganizationalMesh')

    let perimeterQuery = {}
    if(!!selectedCategories.length){
        perimeterQuery['demandNature'] = {$in :selectedCategories.map(obj=>global.ObjectID(obj.id))}
    }
    //if(!!selectedProjects.length){
    //    perimeterQuery['_id'] = {$in :selectedProjects.map(obj=>obj.id)}
    //}

    const allBudgets = await global.app.SE.Budget.find({
        ...basicContext(context),
        fieldPath: ['analyticalMesh', 'budgetFollowUp', 'estimatedBudgetFollowUp'],
        query: {}
    })

    const allProjects = await global.app.SE.Demand.find({
        ...basicContext(context),
        fieldPath: [
            'demandNature', 'relatedProject.demandNature',
            'imputations',
            'imputations.demand.title', 'imputations.demand.organizationAndMesh.name',
            'imputations.demandNature.name', 'imputations.monthAndAmountList',
            'revisedTable', 'completeRevisedTableHistory',
            'relatedProject.imputations',
            'relatedProject.imputations.demand.title', 'relatedProject.imputations.demand.organizationAndMesh.name',
            'relatedProject.demandNature.name', 'relatedProject.imputations.monthAndAmountList',
            'relatedProject.revisedTable', 'relatedProject.completeRevisedTableHistory'
        ],
        query: {
            category : '1',
            status:{$nin: ['1', '5', '9']},  //draft, refused, abandonned
            $or: [{imputations: {$exists: true}}, {revisedTable: {$exists: true}}], //projects in instruction or prevalidation without any imputations yet
            ...perimeterQuery
        }
    })
    const allDemands = await global.app.SE.Demand.find({
        ...basicContext(context),
        fieldPath: [
            'demandNature', 'relatedProject.demandNature',
            'imputations',
            'imputations.demand.title', 'imputations.demand.organizationAndMesh.name',
            'imputations.demandNature.name', 'imputations.monthAndAmountList',
            'relatedProject.imputations',
            'relatedProject.imputations.demand.title', 'relatedProject.imputations.demand.organizationAndMesh.name',
            'relatedProject.demandNature.name', 'relatedProject.imputations.monthAndAmountList'
        ],
        query: {
            category : '2',
            status:{$nin: ['1', '5', '9']},  //draft, refused, abandonned
            $or: [{imputations: {$exists: true}}], //demands in instruction without any imputations yet
            //relatedProject: {$exists: true},  //only demands related to a project
            ...perimeterQuery
        }
    })

    const demandsAndProjects = [...allProjects, ...allDemands]

    const selectedProjectsIds = selectedProjects.map(obj=>obj.id)
    const filteredBySelectedProjects = !!selectedProjects.length ?
        demandsAndProjects
        .filter(project =>
            selectedProjectsIds.includes(project.id)
            || selectedProjectsIds.includes(project.relatedProject?.id)
        )
        : demandsAndProjects


    const filteredBudgetsBySelectedProjects = !!selectedProjects.length ?
        allBudgets.filter(budget =>
            _.some(filteredBySelectedProjects, project =>
                budget.analyticalMesh.attachment.map(att => att.code).includes(project.demandNumber)
            )
        )
        : allBudgets

    let correctedImputationsInArrays = []
    filteredBySelectedProjects.forEach(projectOrDemand => {
        const isProject = projectOrDemand.category === '1'
        const isDemand = projectOrDemand.category === '2'
        if (isProject){
            const isPrincipaleProject = !projectOrDemand.relatedProject
            if (isPrincipaleProject){
                const isOngoingValidation = !projectOrDemand.revisedTable
                const isOngoingValidaction = !projectOrDemand.completeRevisedTableHistory
                const revisedLinesFiltered = (projectOrDemand.revisedTable || []).filter(line=>{
                    const imputationsFiltered = projectOrDemand.imputations.filter(imputation => line.objet === imputation.objet && line.entity.id === imputation.organizationalMesh.id)
                    const imputationsFilteredIds = imputationsFiltered.map(imputation => imputation.imputationType.id)
                    return !imputationsFilteredIds.includes(line.imputationType.id)
                })
                const fakeImputations = revisedLinesFiltered.map(revisedLine =>{
                    const anImputation = projectOrDemand.imputations.find(imputation => revisedLine.objet === imputation.objet && revisedLine.entity.id === imputation.organizationalMesh.id)
                    const fakeOne = {
                        ...anImputation,
                        //same objet
                        //same organizational mesh
                        imputationType: revisedLine.imputationType,
                        totalAmountPerImputation: 0,
                        monthAndAmountList: [],
                        id: new global.ObjectID()
                    }
                    return fakeOne
                })
                projectOrDemand.imputations = [ ...projectOrDemand.imputations, ...fakeImputations]
                const budgetsForThisProject = filteredBudgetsBySelectedProjects.filter(budget => {
                    const budgetProject = budget.analyticalMesh.attachment.find(att => att.analyticalAxis.isProjectAxis)
                    return projectOrDemand.demandNumber === budgetProject?.code
                })
                const budgetsFakeImputations = budgetsForThisProject.reduce((acc,budget) => {
                    const imputationsFiltered = projectOrDemand.imputations.filter(imputation => budget.analyticalMesh.code === imputation.objet && global.ObjectID(budget.mesh.id).toString() === imputation.organizationalMesh.id)
                    const imputationsFilteredIds = imputationsFiltered.map(imputation => imputation.imputationType.id)
                    const budgetImputationTypes = budget.budgetFollowUp.filter(bfu => !imputationsFilteredIds.includes(bfu.type.id)).map(obj => obj.type)
                    const uniqBudgetImputationTypes =  _.uniqBy(budgetImputationTypes, 'id')
                    const fakeImputations = uniqBudgetImputationTypes.map(type =>{
                        const anImputation = imputationsFiltered[0]
                        return {
                            ...anImputation,
                            imputationType: type,
                            totalAmountPerImputation: 0,
                            monthAndAmountList: [],
                            id: new global.ObjectID()
                        }

                    })
                    acc = [...acc, ...fakeImputations]
                    return acc
                }, [])

                projectOrDemand.imputations = [ ...projectOrDemand.imputations, ...budgetsFakeImputations]

                const modifiedImputations = projectOrDemand.imputations
                    .map(imputation => {
                        const budget = filteredBudgetsBySelectedProjects.find(budget => {
                            const budgetProject = budget.analyticalMesh.attachment.find(att => att.analyticalAxis.isProjectAxis)
                            return projectOrDemand.demandNumber === budgetProject?.code
                                && budget.analyticalMesh.code === imputation.objet
                                && global.ObjectID(budget.mesh.id).toString() === imputation.organizationalMesh.id
                        })
                        const clonedBudget = _.cloneDeep(budget)
                        if(!!budget){
                            clonedBudget.budgetFollowUp = budget.budgetFollowUp.filter(bfu => bfu.type.id === imputation.imputationType.id)
                            clonedBudget.estimatedBudgetFollowUp = budget.estimatedBudgetFollowUp.filter(ebfu => ebfu.type.id === imputation.imputationType.id)
                        }
                        const revisedLines = (projectOrDemand.completeRevisedTableHistory || []).filter(line =>
                            line.objet === imputation.objet
                            && line.entity.id === imputation.organizationalMesh.id
                            && line.imputationType.id === imputation.imputationType.id
                        )
                        return {
                            ...imputation,
                            isOngoingValidation,
                            category: '1',
                            budget: clonedBudget || [],
                            revisedLines: revisedLines
                        }
                    })
                correctedImputationsInArrays.push(modifiedImputations)
            }
            else{
                if (projectOrDemand.status !== '6'){ //approved
                    const modifiedImputations = projectOrDemand.imputations
                        .map(imputation => {
                            return {
                                ...imputation,
                                demand: projectOrDemand.relatedProject,
                                isOngoingValidation: true,
                                category: '1',
                                budget: [],
                                revisedLines: []
                            }
                        })
                    correctedImputationsInArrays.push(modifiedImputations)
                }
            }
        }
        if (isDemand){
            const isPrincipaleDemand = !projectOrDemand.relatedDemand
            if (isPrincipaleDemand){
                const isOngoingValidation = projectOrDemand.status !== '6'
                const modifiedImputations = projectOrDemand.imputations
                    .map(imputation => {
                        return {
                            ...imputation,
                            isOngoingValidation,
                            category: '2',
                            demand: projectOrDemand.relatedProject,
                            budget: [],
                            revisedLines: []
                        }
                    })
                correctedImputationsInArrays.push(modifiedImputations)
            }
            else{
                if (projectOrDemand.status !== '6'){ //approved
                    const modifiedImputations = projectOrDemand.imputations
                        .map(imputation => {
                            return {
                                ...imputation,
                                demand: projectOrDemand.relatedProject,
                                isOngoingValidation: true,
                                category: '2',
                                budget: [],
                                revisedLines: []
                            }
                        })
                    correctedImputationsInArrays.push(modifiedImputations)
                }
            }
        }
    })
    const correctedImputations = correctedImputationsInArrays.flat()

    const selectedOrganizationsIds = selectedOrganizations.map(org => org.id)
    const filteredByOrganizations = !!selectedOrganizations.length ?
        correctedImputations.filter(imputation =>
            imputation.organizationalMesh.attachments.some(att => selectedOrganizationsIds.includes(att.id))
        )
        : correctedImputations

    const selectedOrganizationalMeshesIds = selectedOrganizationalMeshes.map(mesh => mesh.id)
    const filteredByMeshes = !!selectedOrganizationalMeshes.length ?
        filteredByOrganizations.filter(imputation =>
            selectedOrganizationalMeshesIds.includes(imputation.organizationalMesh.id)
        )
        : filteredByOrganizations

    const staticIds = ['000000000000000000000001', '000000000000000000000002', '000000000000000000000003', '000000000000000000000004']
    if ( !staticIds.includes(analysisOption.id) ){
        filteredByMeshes.forEach(imputation => {
            const organization = imputation.organizationalMesh.attachments.find(att => att.organizationalAxis.id === analysisOption.id)
            imputation['organization'] = !!organization? organization : {name: 'Non renseigné'}
        })
    }

    const splitedImputationsInArrrays = filteredByMeshes.map(imputation => {
        let splitedImputation = []
        const fiscalYearsOfThisImputation = _.map(imputation.monthAndAmountList, 'fiscalYear');
        const fiscalYearsOfBudget = _.map(imputation.budget.budgetFollowUp, 'fiscalYear');
        const fiscalYearsOfEstimatedBudget = _.map(imputation.budget.estimatedBudgetFollowUp, 'fiscalYear');
        const fiscalYearsOfRevisedTable = _.map(imputation.revisedLines, 'meetingsPlanning.fiscalYear');
        const allPossibleFiscalYears = [...fiscalYearsOfThisImputation, ...fiscalYearsOfBudget, ...fiscalYearsOfEstimatedBudget, ...fiscalYearsOfRevisedTable]
        const uniqueFiscalYears = _.uniqBy(allPossibleFiscalYears, 'id');
        uniqueFiscalYears.forEach(fiscalYear => {
            const monthAndAmountListOFThisFiscalYear = imputation.monthAndAmountList.filter(obj => obj.fiscalYear.id === fiscalYear.id)
            const totalValidated = _.sumBy(monthAndAmountListOFThisFiscalYear, 'amount'); // Sum the 'price' for each month within the product group

            const budgetFollowUpOfThisFiscalYear = (imputation.budget.budgetFollowUp || []).filter(obj => obj.fiscalYear.id === fiscalYear.id)
            const totalBudget = _.sumBy(budgetFollowUpOfThisFiscalYear, 'amount')

            const estimatedBudgetFollowUpOfThisFiscalYear = (imputation.budget.estimatedBudgetFollowUp || []).filter(obj =>
                obj.fiscalYear.id === fiscalYear.id && obj.budgetColumnType.id === "2")
            const onlyTheLastEcheance = estimatedBudgetFollowUpOfThisFiscalYear.reduce((latest, current) => {
                const latestEndDate = new Date(latest.meetingsPlanning.meetingsPlanningRange[1]);
                const currentEndDate = new Date(current.meetingsPlanning.meetingsPlanningRange[1]);
                return currentEndDate > latestEndDate ? current : latest;
            }, !!estimatedBudgetFollowUpOfThisFiscalYear.length ? estimatedBudgetFollowUpOfThisFiscalYear[0] : null);
            const totalEstimatedBudget = !!onlyTheLastEcheance ? onlyTheLastEcheance.amount : 0

            const revisedLinesForThisFiscalYear = (imputation.revisedLines || []).filter(obj => obj.meetingsPlanning.fiscalYear.id === fiscalYear.id)
            const revisedLineOfTheLastEcheance = revisedLinesForThisFiscalYear.reduce((latest, current) => {
                const latestEndDate = new Date(latest.revueDate);
                const currentEndDate = new Date(current.revueDate);

                return currentEndDate > latestEndDate ? current : latest
            }, !!revisedLinesForThisFiscalYear.length ? revisedLinesForThisFiscalYear[0] : null);
            const totalRevised = !!revisedLineOfTheLastEcheance ? revisedLineOfTheLastEcheance.ongoingFiscalYearRevised : 0
            const amountByRealizationType = !!revisedLineOfTheLastEcheance ?
                revisedLineOfTheLastEcheance.amountByRealizationType
                    .filter(object => object.budgetColumnType.id === "2")
                    .reduce((acc, object) => {
                        return {
                            ...acc,
                            [object.realizationType.code]: object.amount
                        }
                }, {})
                : {}

            splitedImputation.push({
                ...imputation,
                monthAndAmountList: monthAndAmountListOFThisFiscalYear,
                fiscalYear: fiscalYear,
                validatedForThisFiscalYear: totalValidated,
                budgetForThisFiscalYear: totalBudget,
                estimatedBudgetForThisFiscalYear: totalEstimatedBudget,
                revisedForThisFiscalYear: totalRevised,
                ...amountByRealizationType
            })
        })
        return splitedImputation
    })

    const splitedImputations = splitedImputationsInArrrays.flat()
    const selectedFiscalYearIds = selectedFiscalYears.map(FY => FY.id)

    const filteredByFiscalYears = !!selectedFiscalYears.length?
        splitedImputations.filter(imputation => selectedFiscalYearIds.includes(imputation.fiscalYear.id))
        : splitedImputations

    const imputationsGroupedByAnalysisOption = _.groupBy(filteredByFiscalYears, item => {
        return _.get(item, analysisOption.path, 'notSpecified');
    });

    const result = _.map(imputationsGroupedByAnalysisOption, (items, option) => {

        //const groupedByStackOption = _.groupBy(items, stackOption.path)  //stackOption.path
        const groupedByStackOption = !!stackOption.path ? _.groupBy(items, stackOption.path) : {noStackingOption: items}


        const validatedByProject = _.map(groupedByStackOption, (projectOrDemandItems, project) => {
            const projectItems = projectOrDemandItems.filter(imputation => imputation.category === '1')
            const validatedImputations = projectItems.filter(imputation => !imputation.isOngoingValidation)
            const ongoingValidationImputations = projectItems.filter(imputation => imputation.isOngoingValidation)
            const totalValidated = _.sumBy(validatedImputations, 'validatedForThisFiscalYear'); // Sum the 'price' for each month within the product group
            const totalOngoingValidated = _.sumBy(ongoingValidationImputations, 'validatedForThisFiscalYear');
            const validatedAndOngoingValidated = totalValidated + totalOngoingValidated

            const demandItems = projectOrDemandItems.filter(imputation => imputation.category === '2')
            const demandValidatedImputations = demandItems.filter(imputation => !imputation.isOngoingValidation)
            const demandOngoingValidationImputations = demandItems.filter(imputation => imputation.isOngoingValidation)
            const totalDemandValidated = _.sumBy(demandValidatedImputations, 'validatedForThisFiscalYear'); // Sum the 'price' for each month within the product group
            const totalDemandOngoingValidated = _.sumBy(demandOngoingValidationImputations, 'validatedForThisFiscalYear');
            const demandValidatedAndOngoingValidated = totalDemandValidated + totalDemandOngoingValidated

            const budget = _.sumBy(projectOrDemandItems, 'budgetForThisFiscalYear');
            const estimatedBudget = _.sumBy(projectOrDemandItems, 'estimatedBudgetForThisFiscalYear');
            const revised = _.sumBy(projectOrDemandItems, 'revisedForThisFiscalYear');

            const amountPerRealizationType = allRealizationTypes.reduce((acc, current) => {
                const x = _.sumBy(projectOrDemandItems, current.code)
                return {
                    ...acc,
                    [current.code]: x  || 0
                }
            }, {})
            return {
                stackAxis: stackOption.translateName ? translateName(project, _.get(context, "language.id")) : project,
                validated: totalValidated,
                ongoingValidated: totalOngoingValidated,
                validatedAndOngoingValidated: validatedAndOngoingValidated,
                demandValidated: totalDemandValidated,
                demandOngoingValidated: totalDemandOngoingValidated,
                demandValidatedAndOngoingValidated: demandValidatedAndOngoingValidated,
                budget: budget,
                estimated: estimatedBudget,
                revised: revised,
                ...amountPerRealizationType
            };
        });

        const result1 = displayedAmounts.reduce((acc, displayedAmount) =>{
            const x = validatedByProject.reduce((acc, object) => {
                return {
                    ...acc,
                    [object.stackAxis]: object[displayedAmount.path]   //displayedAmount.path
                }
            },{})
            return {
                ...acc,
                [displayedAmount.code]: x
            }
        }, {})

        return {
            id: new global.ObjectID(),
            axis: analysisOption.translateName === 'true' ? translateName(option, _.get(context, "language.id")) : context.tc(option),
            axisData: result1,
            //noDeleteButtonAccess: true,
            //type: 'multipleBarChart',
        }
    })

    //const resultWithoutZeros = result.filter(obj => _.sum(_.values(obj.validatedByProject)) !== 0)
    const amountsToCheck = displayedAmounts.map(obj => obj.code)
    const filteredResult = result.filter(item =>
        _.some(
            _.pick(item.axisData, amountsToCheck), // Pick only the properties we care about
            amounts => _.some(_.values(amounts), value => value !== 0) // Check if any value is non-zero
        )
    );
    console.log('filteredResult', filteredResult)
    return filteredResult
}
export const entities = [
    {
        name: 'AnalyzeTrackingByMonth',
        fields: [
            {path: 'axis'},
            {path: 'axisData', type: 'object'},
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else getAmountsByMonth(context)
                    .then(objects => callback(null, objects))
                    .catch(e => callback(e))
            })
        },
        filters: [
            {  //verified
                name: 'stackedBy',
                tKey: 'stackOption',
                isDefault: false,
                client: true,
                path: 'stackOption',
                object: 'StackOptions',
                translate: true,
                //type: 'toggle',
                display: 'name',
                fieldPath: ['path', 'translateName'],
                clearable: true,
                default: {id: null},
            },
            {  //verified
                name: 'byAnalysisOption',
                tKey: 'analysisCriteriaForExAxis',
                isDefault: false,
                client: true,
                path: 'analysisOption',
                object: 'analysisOptionsJoinedEntity',
                translate: true,
                fieldPath: ['path', 'translateName'],
                display: 'name',
                //translateName: true,
                default: {id:'000000000000000000000001', name: "fiscalYear", path: 'fiscalYear.code', translateName: false},
                clearable: false,
            },
            {  //verified
                name: 'byPerimeter',
                tKey: 'perimeter',
                isDefault: false,
                client: true,
                path: 'perimeterOptions',
                object: 'perimeterJoinedEntity',
                //translate: true,
                fieldPath: ['joinedEntity', 'fiscalYearRange'],
                display: 'name',
                type: 'tags',
                //translateName: true,
                default: [],
                filter: true,
                placeholder: 'allIsSelected',
                //clearable: false,
            },
            {  //verified
                name: 'graphType',
                tKey: 'stacking',
                isDefault: false,
                client: true,
                path: 'graphType',
                object: 'GraphType',
                translate: true,
                hidden: true,
                width: 2,
                //type: 'toggle',
                display: 'tKey',
                //fieldPath: ['path', 'translateName'],
                clearable: false,
                default: {id:'1', name: "stackedVertically", tKey: 'yes'},
            },
            {
                name: "displayedAmounts",
                tKey: 'amountsTypesToAnalyze',
                type: 'tags',
                isDefault: false,
                client: true,
                translate: true,
                path: 'displayedAmounts',
                object: 'AmountTypeJoinedEntity',
                display: 'name',
                clearable: true,
                default: [],
                filters: [],
                sorters: ['byId'],
                placeholder: 'allIsSelected',
                fieldPath: ['path', 'name', 'code'],
            },
            {
                name: "colorRange",
                tKey: ' ',
                //type: 'tags',
                isDefault: false,
                client: true,
                translate: true,
                path: 'colorRange',
                object: 'ColorRange',
                display: 'name',
                clearable: false,
                width: '2',
                default: {id:'1', name: "skyBlue", colorRange: ['#0076CE', '#E9DCC9']},
                //filters: [],
                //placeholder: 'allIsSelected',
                fieldPath: ['colorRange'],
            }
        ]
    }
]


export const module_ = {
    object: 'AnalyzeTrackingByMonth',
    category: {
        path: `tracking`,
    },
    newable: false,
    //manualFilters: true,
    defaultPanel: 'chart',
    chart: {
        keys: [
            'projectValidated', 'projectOngoingValidated', 'projectValidatedAndOngoingValidated',
            'demandValidated', 'demandOngoingValidated', 'demandValidatedAndOngoingValidated',
            'budget', 'estimated', 'revised', 'E',
            'axisData'
        ],
        type: "stackedMultipleBarChart",
        title: 'Périmètre complet',
        yAxisUnit: 'k',
        colorRange: ['#0076CE', '#E9DCC9']
    },
    viewMap: {
        dt: [
            {path: 'axis', tKey: 'month', width: 30},
            //{path: 'projectValidated'},
            //{path: 'projectOngoingValidated'},
            //{path: 'projectValidatedAndOngoingValidated'},
            //{path: 'validatedByProject', tKey: 'project(s)', display: 'stackAxis', type: 'objectArray', width: 700},
            //{path: 'noDeleteButtonAccess', hidden: true},
        ],
        form: {
            dataLists: [
                "TrackingByMonth-Currency",
            ],
        },
        chart: [
            'axis',
            //'projectValidatedAndOngoingValidated', 'projectValidated', 'projectOngoingValidated',
            //'demandValidatedAndOngoingValidated', 'demandValidated', 'demandOngoingValidated',
            //'budget', 'estimated', 'revised', 'E',
            'axisData'
        ]
    },

    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({store, action, module }) => {
                console.log('moduleee', module.viewMap.chart.fields)
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "TrackingByMonth-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(updateChartParams({yAxisUnit: `k${referenceCurrency.symbol}`}))
                }
            }
        },
        {
            actionType: reduxFormActions.CHANGE,
            subscription: ({ store, action, module, form }) => {
                const state = store.getState()
                console.log('test', 'test', )
                //module.chart.title.setValue('test')
                //store.dispatch(changeFieldProperty("f_graphType", 'clearable', true))
                //store.dispatch(updateChartParams({title: 'test2'}))
                const selectedColorRange = state.form.chartFilterObject.values.colorRange.colorRange
                const selectedPerimeter = state.form.chartFilterObject.values.perimeterOptions
                let title = 'Périmètre complet'
                if (!!selectedPerimeter && !!selectedPerimeter.length){
                    const stringsArray = selectedPerimeter.map(option => option.name)
                    const grouped = stringsArray.reduce((acc, item) => {
                        // Split the string into category and value
                        const category = item.split(' : ')[0];
                        const value = item.slice(category.length+2, item.length)
                        // If the category is not in the accumulator, add it with the value as the first element in an array
                        if (!acc[category]) {
                            acc[category] = [value];
                        } else {
                            // If the category exists, push the new value into the array
                            acc[category].push(value);
                        }

                        return acc;
                    }, {});
                    const joined = Object.entries(grouped)
                        .map(([category, values]) => `${category}(s) : ${values.join(', ')}`)
                        .join('\n');
                    title = joined.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ));
                }
                console.log('selectedPerimeter', selectedPerimeter)
                console.log('title', title)
                store.dispatch(updateChartParams({title: title, colorRange: selectedColorRange}))
                store.dispatch(setFieldVisibility("f_graphType", !!state.form.chartFilterObject.values.stackOption?.id))
                /*
                if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'businessProject')) {
                    const state = store.getState()
                    const businessProjectId = _.get(action, 'payload.id')
                    const defaultData = getEditDefaultData(state)

                    store.dispatch(setEditDefaultObject({
                        ...defaultData,
                        businessProject: businessProjectId ? { id: businessProjectId } : null
                    }))
                }

                 */
            }
        }
    ],
    accesses: [
        {
            id: "TrackingByMonth-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: [
        //'byProjectType', 'byFiscalYear', 'byOrganizationAndMesh', 'byProjectStatus',
        //'byProject', 'byImputationType',
        'byPerimeter',
        'byAnalysisOption', 'displayedAmounts', 'stackedBy',
        'graphType', 'colorRange'
    ]
}