import React from 'react'
import { Field } from 'redux-form'
import RankOptionsField from '../../../../components/Form/RankOptionsField'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input: {value, onChange}, language, label, required, subtitle, options, optionsById, editable, disabled}) =>  {
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)
    if(!value || value.length === 0) {
        onChange(options.map(option => option.id))
    }

    return <RankOptionsField
        options={options}
        optionsById={optionsById}
        value={value}
        label={translatedLabel}
        required={required}
        subtitle={translatedSubtitle}
        onChange={onChange}
        disabled={disabled || !editable}
        language={language}
    />
}

const FormRankOptionsField = ({ field, language}) => {
    const optionsById = field.options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: option
        }},{})
    return (
        <Field
            name={field.path}
            label={field.label}
            required={field.required}
            subtitle={field.subtitle}
            language={language}
            options={field.options}
            optionsById={optionsById}
            component={Input}
            editable={field.editable}
            disabled={field.disabled}
        />
    )
}

export default FormRankOptionsField
