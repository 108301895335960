import React from 'react'
import './ShortCard.css'
import {List as ListIcon} from 'react-feather'
import CountUp from 'react-countup';
import SpinningDots from '../../components/Loader/SpinningDots'
import { Link } from 'react-router-dom'
import {translateName} from "../../utils/i18n"

class ShortCard extends React.Component {

    componentDidMount() {
        const { dataFetcher } = this.props
        dataFetcher && dataFetcher()
    }

    componentDidUpdate(prevProps) {
        const { dataFetcher, path } = this.props

        if ( path !== prevProps.path ) {
            dataFetcher && dataFetcher()
        }
    }

    render() {
        const { title = "Title", language, data = 0 , link, suffix=" (Nb)", isFetching, subTitle, t} = this.props

        return (
            <div className="ShortCard-container col-sm-6 col-md-4 col-lg-4">
                <div>
                    <div className="ShortCard">
                        <div>
                            <h4 style={{marginBottom: "5px"}}>{translateName(title, language)}</h4>
                            {
                                subTitle && <div className="ChartCard-subTitle" style={{color: `${subTitle.color}`, font: `${subTitle.font}`}}>{t(subTitle.text, subTitle.data)}</div>
                            }
                        </div>
                        <div className="ShortCard-content">
                            {
                                isFetching ? (
                                        <SpinningDots/>
                                ) : <CountUp delay={0.2} start={0} end={data} suffix={suffix} separator=","/>
                            }
                        </div>
                       <div className="ShortCard-link">
                           {
                               link.title && <Link to={link.path}>
                                   {/*{link.title}*/}
                                   <ListIcon className="ShortCard-icon"/>
                               </Link>
                           }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortCard
