import React from 'react'
import PropTypes from 'prop-types'
import Picker from './DatePicker'
import './DateRangePicker.css'
import moment from 'moment'
import _ from 'lodash'

const DATE_FORMAT = 'YYYY-MM-DD'

function dateComparator(referenceDateString, compare) {
    if (
        !referenceDateString ||
        !_.isString(referenceDateString) ||
        referenceDateString === ''
    )
        return

    const referenceDate =
        referenceDateString &&
        moment(referenceDateString, DATE_FORMAT).startOf('day')

    return function compareDate(date) {
        return date
            ? compare(moment(referenceDate).diff(date.startOf('day'), 'days'))
            : false
    }
}

const DateRangePicker = ({
    path,
    startDate,
    endDate,
    startOpened,
    endOpened,
    onChange,
    onOpenChange,
    language,
    startPlaceholder,
    endPlaceholder,
    disabled,
    t
}) => (
    <div className="DateRangePicker">
        <Picker
            // disabledDate={dateComparator(endDate, daysDiff => daysDiff < 0)}
            path={`${t(path)}: ${t('start')}`}
            value={startDate}
            open={startOpened}
            onOpenChange={() => onOpenChange('startOpened', !startOpened)}
            onChange={value => {
                onChange(0, value)
                onOpenChange('endOpened', !endOpened)
            }}
            placeholder={startPlaceholder}
            disabled={disabled}
            language={language}
            t={t}
        />
        <Picker
            path={`${t(path)}: ${t('end')}`}
            disabledDate={dateComparator(startDate, daysDiff => daysDiff > 0)}
            value={endDate}
            open={endOpened}
            onOpenChange={() => onOpenChange('endOpened', !endOpened)}
            onChange={value => onChange(1, value)}
            placeholder={endPlaceholder}
            disabled={disabled}
            language={language}
            t={t}
        />
    </div>
)

DateRangePicker.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startOpened: PropTypes.bool.isRequired,
    endOpened: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    language: PropTypes.string,
    startPlaceholder: PropTypes.string,
    endPlaceholder: PropTypes.string
}

export default DateRangePicker
