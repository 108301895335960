import {
    EXECUTE_AFTER_SAVE_SUBSCRIPTIONS, EXECUTE_LOAD_SUBSCRIPTIONS, FIELD_CLIENT_EVENT, INITIALIZE_MODULE,
    LOAD_DEFAULT_EDIT, EXECUTE_DT_LOAD_SUBSCRIPTIONS
} from '../../../middleware/module'

/*
* This actions are intercepted by the module middleware
* */

export const fieldClientEvent = (key, path, payload) => ({
    [FIELD_CLIENT_EVENT]: {
        key,
        path,
        payload
    }
})

export const loadDefaultEdit = () => ({
    [LOAD_DEFAULT_EDIT]: {}
})

export const executeDtLoadSubscriptions = () => ({
    [EXECUTE_DT_LOAD_SUBSCRIPTIONS]: {}
})

export const executeLoadSubscriptions = () => ({
    [EXECUTE_LOAD_SUBSCRIPTIONS]: {}
})

export const executeAfterSaveSubscriptions = () => ({
    [EXECUTE_AFTER_SAVE_SUBSCRIPTIONS]: {}
})

export const initializeModule = (moduleId, groupModel, user, language) => ({
    [INITIALIZE_MODULE]: {
        moduleId,
        groupModel,
        user,
        language
    }
})
