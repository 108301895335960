const { validateAge } = require('./../utils')

export const getProratedValue = (exercise, user, value, cancelSplit, removeRetiredSpecialRule) => {
    const exerciseStartDate = exercise.startDate
    const exerciseEndDate = exercise.endDate

    const entryDate = user.entryDate
    const releaseDate = user.releaseDate

    if(releaseDate) {
        releaseDate.setDate( releaseDate.getDate() - 1)
    }

    const start = entryDate
        ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
        : exerciseStartDate

    const end = !releaseDate || (user.status.id === 'retired' && !removeRetiredSpecialRule)
        ? exerciseEndDate
        : releaseDate < exerciseEndDate
            ? releaseDate
            : exerciseEndDate

    const monthsDiff = (date1, date2) => {
        const years = date2.getFullYear() - date1.getFullYear()
        return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
    }

    const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
        ? 0
        : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

    const userSplit = user['split'] || 0

    return cancelSplit
        ? value * presenceRatio
        : ((value * (100 - userSplit)) / 100) * presenceRatio
}

export const calculateExerciseEndowment = (exercise, user) => {

    const childrenNumber = user.rightHolders
        .filter(rh => rh.kinship.id === 'child' && rh.dependent)
        .filter( rh => validateAge(rh.birthDate, exercise.startDate, 25))
        .length

    const limitForTheExerciseBeforeProrating = childrenNumber * exercise.variableDot + exercise.fixedDot

    return user.status.id === 'external'
        ? 0
        : getProratedValue(exercise, user, limitForTheExerciseBeforeProrating)
}

export const calculateBenefitEndowment = (benefit, user) => {

    const variableLimit = benefit.limit.variable
    const fixedLimit = benefit.limit.fixed
    const maximumLimit = benefit.limit.maximum

    const childrenNumber = user.rightHolders
        .filter(rh => rh.kinship.id === 'child' && rh.dependent)
        .filter( rh => validateAge(rh.birthDate, benefit.exercise.startDate, benefit.ageLimit))
        .length

    const fixedAndVariable = benefit.childrenPresenceCondition && !childrenNumber
        ?  0
        : childrenNumber *  variableLimit + fixedLimit

    const fixAndValueAfterProrating = getProratedValue(benefit.exercise, user, fixedAndVariable)

    return user.status.id === 'external'
        ? 0
        : maximumLimit && fixAndValueAfterProrating >= maximumLimit
            ? maximumLimit
            : fixAndValueAfterProrating
}
