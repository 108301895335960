const moment = require('moment')
const _ = require('lodash')

export const generateLogPdf = function(params, callback) {
    const { success, file, systemError, userErrors, engineOutput } = params

    const fileName = file.name
    const outputName = fileName.substr(0, fileName.lastIndexOf(".")) + ".pdf"



    const userWarnings = _.get(engineOutput, 'userWarnings', [])

    const configuration = {
        content: [{ path: 'ImportLogTemplate.html' }],
        context: {
            titreDemande: 'Import LOGS',
            systemError,
            haveUserErrors: userErrors.length > 0,
            userErrors: _.take(userErrors, 100),
            userWarnings,
            haveUserWarnings: userWarnings.length > 0,
            processedLines: _.get(engineOutput, 'processedLines'),
            success,
            fileName
        },
        configuration: {
            header: {
                height: '1cm',
                html:
                    '<header style="font-family: Helvetica"><div style="float: left">Keenpoint Consulting, ' +
                    moment().format('DD/MM/YYYY HH:mm') +
                    '</div></header>'
            },
            footer: {
                height: '1cm',
                html:
                    '<footer style="font-family: Helvetica"><div style="float: left">{{pageNum}}/{{numPages}}</div></footer>'
            },
            pdfName: outputName,
            outputInDB: true,
            cssSheet: ''
        }
    }

    global.pdf.generatePdf(configuration, callback)
}
