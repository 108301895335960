const _ = require("lodash");
const { setFieldListOptions, setFieldVisibility } = require('../../../apps/KpModule/actions');
const { getDataListList } = require('../../../apps/KpModule/selectors');
const { propertyChangeComparator } = require("../../utils/functional");
const { updateUserHabilitations } = require("./workflow");
const {generateFetchFieldListAction} = require("../../../apps/KpModule/actions/api");
const Errors = require('../../utils/Errors').default

const getFieldByPath = (module, path) => _.find(module.viewMap.form.fields, { path });

function updateOrganizationJoinOptions({habFunction, store, module}) {
    const state = store.getState();

    const organizationsJoinField = getFieldByPath(module, "organizationsJoin");

    if (habFunction) {

        const options = getDataListList(
            state,
            "m-S-habilitation.Habilitation_organizationsJoin"
        );

        const filteredOptions = options.filter(
            option => option.habFunctionCategoryCode === habFunction.categoryCode
        )

        const currentValue = organizationsJoinField.getValue()
        const filteredCurrentValue = currentValue.filter(value => filteredOptions.some(option => option.id === value.id))

        organizationsJoinField.setValue(filteredCurrentValue)

        store.dispatch(
            setFieldListOptions("e_organizationsJoin", _.map(filteredOptions, "id"))
        );

        const centralHabFunction = habFunction.categoryCode === "f00000000000000000000000"
        store.dispatch(setFieldVisibility("e_organizationsJoin", !centralHabFunction))
    }
}

export const entity = {
    name: "Habilitation",
    fields: [
        {
            type: "HabFunction",
            tKey: "function",
            unique: true,
            uniqueWith: "user",
            link: {type: "MTO", oppositeField: {path: "habilitations"}}
        },
        {
            path: 'organizationsJoin',
            type: "OrganizationJoin",
            link: "MTM",
            nullable: true
        },
        { type: "User" },
        {
            path: "isCentral",
            fieldPath: ["habFunction.organizationAxisJoin.id"],
            f: function() {
                if (!this.habFunction || !this.habFunction.organizationAxisJoin) return false; // the organization join is not recovered when creating a new incident kludge
                return this.habFunction.organizationAxisJoin.id === global.app.S.CentralAxis.objects[0].id ;
            }
        }
    ],

    ps: {
        context: [
            {
                $u: function(context) {
                    let newContext;

                    // enhance save fieldPath, for usage in validation
                    if (context && (_.get(context, "access.type") === "S") && _.isArray(context.internalFieldPath)) {
                        newContext = {
                            ...context,
                            internalFieldPath: [...context.internalFieldPath, "habFunction.organizationAxisJoin.id"]
                        }
                    }

                    return newContext || context;
                }
            }
        ],
        object: [

            // validates that the habilitation is linked to an organization or a store
            // if the habilitation linked to a central habFunction, this is not necessary
            {
                $v: function (habilitation) {
                    const organizationAxisJoinId = _.get(habilitation, "habFunction.organizationAxisJoin.id");

                    if (organizationAxisJoinId) {
                        if (
                            (organizationAxisJoinId !== global.app.S.CentralAxis.objects[0].id) &&
                            (_.isUndefined(habilitation.organizationsJoin) || habilitation.organizationsJoin.length === 0)
                        ) {
                            return new Errors.ValidationError(this.options.context.tc("habilitationMustBeLinkedToOrganization"));
                        }
                    } else {
                        console.warn("" +
                            "missing recovery of habFunction.organizationAxisJoin.id," +
                            "include it in the internalFieldPath !"
                        );
                    }
                }
            },

            // validates that we don't modify the structure of the habilitation,
            // to avoid breaking the objects generated by the workflow
            {
                $v: function(habilitation) {
                    const context = this.options.context;
                    const oldHabilitation = context.businessOldObject;

                    const propertyChanged = propertyChangeComparator(habilitation, oldHabilitation);

                    if (oldHabilitation && propertyChanged("habFunction.id")) {
                        return new Errors.ValidationError(context.tc("cannotChangeHabFunctionOfSavedHabilitation"));
                    }

                    /*
                    if (oldHabilitation && propertyChanged("organizationsJoin")) {
                        return new Errors.ValidationError(context.tc("cannotChangeOrganizationJoinOfSavedHabilitation"));
                    }
                     */
                }
            },

            {
                $$f: function(habilitation, callback) {
                    const accessType = _.get(this, "options.context.access.type");
                    const group = _.get(this, "options.context.group");

                    // modifying an existing habilitation
                    if (habilitation && habilitation.id && (accessType === "S")) {

                        // this will update the StatusUser collection
                        updateUserHabilitations({
                            habilitationId: habilitation.id,
                            user: _.get(habilitation, "user", null),
                            group
                        })
                            .then(() => callback(null))
                            .catch(error => callback(error))
                    } else {
                        callback(null);
                    }
                }
            }
        ]
    }
}

export const module_ = {
    object: "Habilitation",
    tKey: "mTitle_habilitation",
    category: {
        path: "setting",
        icon: "settings"
    },
    removable: false,
    defaultSortBy: "user",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            {path: "user"},
            {path: "habFunction", tKey: "function", translateName: true},
            {path: "organizationsJoin", display: "codeName", tKey: "perimeter"}
        ],
        form: {
            fields: [
                {
                    path: "user",
                    fieldPath: ["id", "name", "groups"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            if(newValue) {
                                const state = store.getState()
                                const groupModel = state.groupModel
                                const currentGroup = newValue.groups.find(group => _.get(group, 'group.id') === _.get(groupModel, 'group.id'))
                                const currentGroupModel = currentGroup && currentGroup.groupModels.find(gm => _.get(gm, 'groupModel.id') === groupModel.id)
                                const profiles = currentGroupModel && currentGroupModel.profiles

                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-S-habilitation.Habilitation_habFunction",
                                        store.getState,
                                        'form',
                                        {data: {profiles} }
                                    )
                                )
                            }
                        }
                    }
                },
                {
                    path: "habFunction",
                    tKey: "function",
                    display: "name",
                    translateName: true,
                    fieldPath: ["id", "name", "categoryCode"],
                    filters:["byProfiles"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            updateOrganizationJoinOptions({
                                habFunction: newValue,
                                module,
                                store
                            })
                        }
                    }
                },
                {
                    path: "organizationsJoin",
                    tKey: "perimeter",
                    display: "codeName",
                    required: true,
                    fieldPath: ["id", "codeName", "habFunctionCategoryCode"]
                }
            ]
        }
    },
    filters: [{
        path: "organizationAxisJoin",
        object: "OrganizationAxisJoin",
        tKey: "perimeter",
        client: true,
        async: true,
        filters: ["usedInScope"],
        display: "tName",
        clearable: false,
        query: function (context, callback) {
            const organizationAxisJoinId = _.get(context , "data.organizationAxisJoin.id");

            global.app.S.HabFunction.find(
                {
                    query: {"organizationAxisJoin": new global.ObjectID(organizationAxisJoinId)},
                    group: context.group
                },
                (e, habFunctions) => {
                    if (e) return callback(e);

                    callback(null, {"habFunction": {$in: habFunctions.map(hf => new global.ObjectID(hf.id))}});
                }
            );
        }
    }]
}
