import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ModuleFormField from '../../apps/KpModule/components/Form/Field'
import SurveyFormField from "./SurveyFormField";


class SurveyField extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const {fields, initialize} = this.props
        const defaultObject = Object
            .keys(fields)
            .reduce((acc, keyField) => {
                acc[keyField] = fields[keyField].default
                return acc
        }, {})
        initialize(defaultObject)
    }

    render() {
        const {fields, filteredAndOrderedFieldIds, t, language, disabled} = this.props
        return (
            <div className="SurveyField-wrapper">
                {filteredAndOrderedFieldIds.map(keyField => (
                    <SurveyFormField
                        key={fields[keyField].path}
                        required={fields[keyField].required}
                        hideLabel={fields[keyField].hideLabel}
                        label={fields[keyField].label}
                        width={fields[keyField].width}
                        minWidth={fields[keyField].minWidth}
                        subtitle={fields[keyField].subtitle}
                        language={language}
                        t={t}
                    >
                        <ModuleFormField
                            field={
                                {
                                    ...fields[keyField],
                                    disabled
                                }
                            }
                            objectMode={'editObject'}
                            t={t}
                            language={language}
                        />
                    </SurveyFormField>
                ))}
            </div>
        )
    }
}


SurveyField.propTypes = {
    fields: PropTypes.any.isRequired,
    language: PropTypes.any.isRequired,
    isPristine: PropTypes.bool.isRequired,
    t: PropTypes.func
}

export default SurveyField
