import React from 'react'
import { Field } from 'redux-form'
import TextField from '../../../../components/Form/TextField'
import getFormValidations from './formValidations'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input, meta:{touched, error}, language, placeholder, label, subtitle, editable, disabled, translate, t, required }) => {

    const translatedError = t(error)
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)

    return <TextField {...input} style={{margin: '10px 0 0 20px'}} type="text" label={translatedLabel} subtitle={translatedSubtitle} touched={touched} error={translatedError} required={required} disabled={disabled || !editable} placeholder={t(placeholder || 'typeSomething')} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormTextField = ({ field, t, language, objectMode }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            label={field.label}
            subtitle={field.subtitle}
            component={Input}
            placeholder={field.placeholder}
            validate={validations}
            normalize={(value) => field.uppercase ? value.toUpperCase() : value}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            translate={field.translate}
            t={t}
            language={language}
        />
    )
}

export default FormTextField
