const moment = require("moment");
const _ = require('lodash')
const dd = require("../../default-engine/engine").default;

const repeatedStepProfile = function(profiles, elementPath) {
    return _(profiles)
        .flatMap("stepProfiles")
        .filter("active")
        .filter("step.multiOrder")
        .countBy(elementPath)
        .some(value => value > 1);
};

const stringToObject = fields => element => _.isString(element) ? fields.reduce((acc, field) => Object.assign(acc, {[field]: element}), {}) : element;
const updateAsObject = fields => ({$u: element => _.isArray(element) ? element.map(stringToObject(fields)) : stringToObject(fields)(element)});

export default workflowGenerator => dd.normalize(
    workflowGenerator,
    {
        name: "workflowCommonGenerator",
        fields: [
            {path: "meshFields", $p: ["required", "notEmpty", updateAsObject(["entity"]), "fields"], fields: [
                {path: "entity", $p: ["string"]},
                {path: "dtField", default: {}, fields: [
                    {path: "path", $default: (dtField, meshField) => _.lowerFirst(meshField.entity)},
                    {path: "display", default: "name"},
                    {path: "tKey", $default: "path"}
                ]},
                {path: "formField", default: {}, fields: [
                    {path: "path", $default: (dtField, meshField) => _.lowerFirst(meshField.entity)},
                    {path: "display", default: "name"},
                    {path: "tKey", $default: "path"},
                    {path: "editable", default: false},
                    {path: "fieldPath", $default: formField => ['id', formField.display, "groupAxes.id", "groupAxes.joinedEntity"]}
                ]}
            ]},
            {path: "modelId", $p: ["required", "string"]},
            {path: "objectEntity", $p: ["required", "string", "notEmpty"]},
            {path: "configurationEntity", $p: ["required", "string", "notEmpty"]},
            {
                path: "profileConfigEntity",
                $p: ["stringOrObject", updateAsObject(["name"]), "fields"],
                fields: [
                    {path: "name", $p: ["string"]},
                    {path: "tKey", $default: profileConfigEntity => _.lowerFirst(profileConfigEntity.name)}
                ]
            },
            // {path: "profileConfigEntityTitle", default: "Position", $p: ["default", "string", "notEmpty"]},
            {path: "profileConfigEntityFiters", default: []},
            {path: "translate", $default: workflowGenerator => (path, fallback) => tc => {
                const p = `M_${workflowGenerator.modelId}.wf.${path}`;
                return _.isFunction(tc) ? tc(p, {defaultValue: fallback}) : fallback;
            }},

            {path: "formHabilitationFieldPath", default: []},
            {path: "workflowConfigFieldPath", default: []},
            {path: "enhanceComments", default: comments => comments},
            {path: "extractUsersWithHabilitation", $p: ["required", "function"]},
            {path: "configModuleDtObjectTKey", default: "workflow"},

            {path: "defaultButtons", default: [{type: "return", bsStyle: "default", tKey: "return"}]},
            {path: "withoutWorkflowButtons", default: [{type: "return", bsStyle: "default", tKey: "return"}]},

            {path: "steps", $p: ["required", "array", "notEmpty", {type: "keyToObject", key: "name"}, "fields"], fields: [
                {path: "name", $p: ["required", "string", "unique"]},
                {path: "tKey", $default: step => _.lowerFirst(step.name), $p: ["string"]},
                {path: "multiOrder", default: false},
                {path: "blocking", default: false},
                {path: "type", default: "inProgress", $p: ["required", {type: "enum", list: ["inProgress", "validated", "refused"]}]},
                {path: "nextSteps", default: []},
                {path: "previousSteps", default: []},
                {path: "actions", default: [], fields: [
                    {path: "name", $p: ["required", "string", "unique"]},
                    {path: "tKey", $default: action => _.lowerFirst(action.name), $p: ["string"]},
                    {path: "ignoreOrder", default: false},
                    {
                        path: "button",
                        default: {},
                        fields: [
                            {path: "type", default: "action", $p:
                                {$v: type => (type !== "action") && new Error("Action button should be of type action")}
                            },
                            {path: "bsStyle", default: "primary", $p: ["string"]},
                            {path: "action", $default: (button, action) => action.name, $p:
                                {$v: (btnAction, btn, action) => (btnAction !== action.name) && new Error("Button action should equal action name")}
                            },
                            {path: "tKey", $default: "action", $p: ["string"]}
                        ],
                        $p: ["object"]
                    },
                    {path: "nextStatus", $if: nextStatus => _.isString(nextStatus), $p: {$u: function(nextStatus, action, actions, step, steps) {
                        // if nextStatus configured with a String meaning the step of the status after we pass all orders
                        return function(workflowObject) {
                            const next = _.find(steps, {name: nextStatus}).firstStatus;
                            const order = parseInt(workflowObject.status.order, 10);
                            const maxOrder = workflowObject.workflow.profileConfigs.find(o => o.step === step.name).maxOrder;
                            return step.multiOrder && !action.ignoreOrder && maxOrder > order ? _.defaults({order: order + 1}, workflowObject.status) : next;
                        };
                    }}},
                    {path: "execute", $default: function(action, actions, step, steps, wf) {
                        return function(workflowObject, context) {

                            const status = action.nextStatus(workflowObject);

                            const comments = wf.enhanceComments([
                                ...workflowObject.comments,
                                {
                                    text: `${context.tc(action.name)} ${context.tc(step.tKey)}`,
                                    date: moment().format("YYYY-MM-DD HH:mm"),
                                    user: context.user && {id: context.user.id, name: context.user.name}
                                }
                            ], {workflowObject, context});

                            return _.defaults({status, comments}, workflowObject);
                        };
                    }}
                ], $p: ["array"]},
                {path: "active", $default: function(step) { return step.actions.length > 0; }, $p: ["default", "boolean"]},
                {path: "firstStatus", $default: function(step) {
                    return {
                        step: step.name,
                        order: step.multiOrder ? 1 : 0
                    };
                }},
                {path: "buttons", $default: function(step) {
                    return step.actions.map(action => action.button);
                }, $p: {$u: (buttons, step, steps, workflowGenerator) => buttons.concat(_.cloneDeep(workflowGenerator.defaultButtons))}},
            ]},

            {path: "profiles", $p: ["required", "array", "notEmpty"], fields: [
                {path: "name", $p: ["required", "string", "unique"]},
                {path: "tKey", $default: profile => _.lowerFirst(profile.name), $p: ["string"]},
                {path: "stepProfiles", default: [], $p: ["default", {type: "keyToObject", key: "step"}, "fields"], fields: [
                    {path: "step", $p: {$u: function (step, stepProfile, stepProfiles, profile, profiles, workflowGenerator) {
                        return _.isString(step) ? _.find(workflowGenerator.steps, {name: step}) : step;
                    }}},
                    {path: "profile", $p: {$u: function(stepProfileProfile, stepProfile, stepProfiles, profile) {
                        return profile;
                    } }},
                    {path: "active", default: false},
                    {path: "passiveBefore", $default: function(stepProfile) {
                        return stepProfile.active ? false : undefined;
                    }},
                    {path: "passiveAfter", $default: function(stepProfile) {
                        return stepProfile.active ? true : undefined;
                    }},
                ]},
                {path: "activeStep", $default: function(profile) {
                    const activeStepProfile = _.find(profile.stepProfiles, {active: true});
                    return activeStepProfile && activeStepProfile.step;
                }},
                {path: "optional", default: false, $p: ["boolean"]},
                {path: "getSteps", $default: function(profile) {
                    return function () {
                        return profile.stepProfiles.map(stepProfile => stepProfile.step);
                    };
                }}
            ]},

            {path: "profiles", $p: [
                {$v: function(profiles) { if (repeatedStepProfile(profiles, "step.name")) return Error("Workflow configuration: some profiles have double active steps"); }},
                {$v: function(profiles) { if (repeatedStepProfile(profiles, "profile.name")) return Error("Workflow configuration: some profiles have double active profiles"); }}
            ]},

            // set the opposite field of stepProfiles on each step
            {path: "steps.stepProfiles", $p: {$u: function(stepProfiles, step, steps, workflowGenerator) {
                return _(workflowGenerator.profiles)
                    .flatMap(profile => profile.stepProfiles)
                    .filter(stepProfile => stepProfile.step && stepProfile.step.name === step.name)
                    .value();
            }}},
            {path: "steps.activeProfiles", $default: function(step) {
                return _(step.stepProfiles)
                    .filter({active: true})
                    .map("profile")
                    .value();
            }, $p: {$v: function(activeProfiles, step) {
                if (step.actions.length && !activeProfiles.length) return Error(`Workflow configuration: no active profile found for step ${step.name}`);
            }}},
            {path: "steps.activeProfile", $default: function(step) { return step.activeProfiles[0]; }},

            // common utils
            {path: "utils", default: {}, fields: [
                {path: "groupProfileConfigByEntity", $default: function(utils, workflowGenerator) {
                    return function (profileConfigObjects, profileConfigs=[]) {
                        const defaultProfileConfigByObject = workflowGenerator.profiles.reduce(
                            (acc, profile) => Object.assign(acc, {
                                [profile.name + "_active"]: false,
                                [profile.name + "_order"]: 0
                            }),
                            {}
                        );

                        return profileConfigObjects.map(profileConfigObject => {
                            const profileConfigByEntity = _(profileConfigs)
                                .filter(
                                    {[_.lowerFirst(workflowGenerator.profileConfigEntity.name)]: {id: profileConfigObject.id}}
                                )
                                .reduce(
                                    (acc, profileConfig) => Object.assign({
                                        [profileConfig.profile + "_active"]: true,
                                        [profileConfig.profile + "_order"]: profileConfig.order || 0
                                    }, acc),
                                    {}
                                );
                            return Object.assign(
                                {},
                                defaultProfileConfigByObject,
                                profileConfigByEntity,
                                {
                                    id: profileConfigObject.id,
                                    [_.lowerFirst(workflowGenerator.profileConfigEntity.name)]: profileConfigObject
                                }
                            );
                        });
                    };
                }}
            ]},

            // object facets
            {path: "configFacet", $default: require("./configFacet").default},
            {path: "configModuleFacet", $default: require("./configModuleFacet").default},
            {path: "objectFacet", $default: require("./objectFacet").default},
            {path: "objectModuleFacet", $default: require("./objectModuleFacet").default}
        ]
    },
    {forceEdit: true, throw: true}
);
