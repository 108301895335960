import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RecoverPass from '../../../../components/RecoverPass'

class RecoverPassPage extends Component {
    componentDidMount() {
        this.props.fetchConfig()
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.isLoggedIn &&
            prevProps.isLogging &&
            !this.props.isLogging
        ) {
            this.props.history.push('/business')
        }
    }

    handleForgotten = user => {
        const {language} = this.props
        this.props.sendMail(user, language)
            .then(
                response => {
                    if (!response.error) {
                        setTimeout(() => this.props.history.push('/login'), 3000);
                    }
                }
            )
    }
    render() {
        const {history, client, t} = this.props
        return <RecoverPass client={client} handleSubmit={this.handleForgotten} t={t} history={history} />
    }
}

RecoverPassPage.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    sendMail: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

export default RecoverPassPage
