import _ from "lodash";
import {translateName} from "../../../../../utils/i18n";
import {basicContext} from "../../../../utils/contextUtils";
import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {setFieldVisibility} from "../../../../../apps/KpModule/actions";

export const entities = [
    {
        name: 'EntityConfiguration',
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: true}
        ],
        fields: [
            {path: 'structures', type:'Structure', link: 'MTM'},
            {
                path: 'entityAttachmentsRules',
                type: 'EntityAttachmentsRules',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: true,
                }
            }
        ]
    },
    {
        name: 'EntityAttachmentsRules',
        //type: 'mongoInternal',
        fields: [
            {path: 'axesPossibleCombination', type: 'StructureAxes', link: 'MTM'},
            {
                path: 'name',
                fieldPath: ['axesPossibleCombination.id'],
                $f: function (object, context, callback) {
                    const language = _.get(context, "language.id")
                    const fullName = object.axesPossibleCombination.map(obj => translateName(obj.name, language)).join(' - ')
                    return callback(null, fullName)
                }
            },
        ],
        filters: [
            {
                name: 'byEntityType',
                isDefault: false,
                query: function(context) {
                    const entityTypeID = _.get(context, 'data.entityTypeID')
                    return entityTypeID ? {entityConfiguration: global.ObjectID(entityTypeID)} : {_id: null}
                }
            }
        ]
    }
]

export const module_ = {
    object: 'EntityConfiguration',
    category: {
        path: 'structure',
        icon: 'layers'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name', type: 'translatedText'},
            {path: 'structures', translateName: true}
        ],
        form: {
            fields: [
                {path: 'code'},
                {path: 'name', type: 'textarea', unique: true, placeholder: 'fr:: nom en français\nen:: name in english\n...'},
                {
                    path: 'structures',
                    translateName: true,
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const entityAttachmentsRulesField = module.viewMap.form.fields.find( field => field.path === 'entityAttachmentsRules')
                            const axesPossibleCombinationField = entityAttachmentsRulesField.viewMap.form.fields.find( field => field.path === 'axesPossibleCombination')
                            const axesPossibleCombination = axesPossibleCombinationField && axesPossibleCombinationField.getValue()
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entityConfiguration.EntityAttachmentsRules_axesPossibleCombination",
                                    store.getState,
                                    'form',
                                    {
                                        data:{
                                            structuresIds: (newValue || []).map(value => value.id),
                                            alreadyAttachedToThisAxes: axesPossibleCombination
                                        }
                                    }
                                )
                            )
                        }
                    }
                },
                {
                    path: 'entityAttachmentsRules',
                    type: 'accordion',
                    //removable: true,
                    viewMap: {
                        dt: [
                            {path: 'axesPossibleCombination', tKey: 'possibleCombinations', translateName: true,}
                        ],
                        form: {
                            fields: [
                                {
                                    path: 'axesPossibleCombination',
                                    tKey: 'possibleCombination',
                                    translateName: true,
                                    filters: ['byStructures', 'eliminateIncoherentAxes'],
                                    fieldPath: ['id', 'structure', 'name'],
                                    //display: 'nameWithStructure',
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){

                                            const structuresField = module.viewMap.form.fields.find( field => field.path === 'structures')
                                            const structures = structuresField && structuresField.getValue()
                                            console.log('structures', structures)
                                            store.dispatch(
                                                generateFetchFieldListAction(
                                                    "m-SE-entityConfiguration.EntityAttachmentsRules_axesPossibleCombination",
                                                    store.getState,
                                                    'form',
                                                    {
                                                        data:{
                                                            structuresIds: (structures || []).map(value => value.id),
                                                            alreadyAttachedToThisAxes: newValue
                                                        }
                                                    }
                                                )
                                            )
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }
            ],
        }
    },
}
