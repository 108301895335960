import React, { Component } from 'react'
import ErrorPage from '../components/ErrorPage'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        console.log(error)
        this.setState({
            hasError: true,
            error: error.toString(),
            stack: info.componentStack
        })
    }

    handleReload = () => {
        this.setState({hasError: false})
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPage
                    message={this.state.error.toString()}
                    retry={this.handleReload}
                    t={this.props.t}
                    applyNavbar={false}
                />
            )
        }
        return this.props.children
    }
}
