const _ = require("lodash")
const moment = require("moment")

const monthNumbers = Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MM')})

const getYears = years => years.reduce((acc, year) => {
    return [
        ...acc,
        `${year}`
    ]
}, [])
const getMonths = years => years.reduce((acc, year) => {
    return [
        ...acc,
        ...monthNumbers.map(month => `${year}-${month}`)
    ]
}, [])

const compute = async context => {
    const axis1 = _.get(context, "data.axis1.id")
    const axis2 = _.get(context, "data.axis2.id")
    const indicator = _.get(context, "data.indicator")
    const scope = _.get(context, "data.scopeType.id")
    const scopeName = _.get(context, "data.scopeType.name")
    const scopeValue = _.get(context, "data.scope")

    const groupBpsByAxis = (bps, axisId) => _.groupBy(bps, axisId
        ? global.app.I.OrderTakingGroupAxis.byId(axisId).groupper(context.tc)
        : ''
    )

    const sortBpsByAxis = (bps, axisId) => _.sortBy(bps, axisId
        ? global.app.I.OrderTakingGroupAxis.byId(axisId).sorter(context.tc)
        : ''
    )

    const businessProjectCollection = global.app.I.BusinessProject.collection
    const businessRevueCollection = global.app.I.BusinessRevue.collectionName
    const countryCollectionName = global.app.I.Country.collectionName
    const zoneCollectionName = global.app.I.Zone.collectionName
    const typeOfOfferCollectionName = global.app.I.TypeOfOffer.collectionName
    const rangeOfOfferCollectionName = global.app.I.RangeOfOffer.collectionName

    const year = moment().year()

    const years = getYears([year, year + 1, year + 2, year + 3])
    const currentAndNextYear = [`${year}`, `${year + 1}`]
    const months = getMonths(currentAndNextYear)

    const indicators = indicator.id
        ? [indicator]
        : [
            {id: "revised", name: "CA (k€)"},
            {id: "revisedP", name: "CA.P (k€)"},
            {id: "number", name: "Projet (Nb)"}
        ]
    const query = () => {
        switch (scope) {
            case 'zone':
            case 'country':
            case 'typeOfOffer':
            case 'rangeOfOffer':
                return{[`${scope}._id`]: {$in: scopeValue.map(o => new global.ObjectID(o.id))}}
            case 'projectFunding':
            case 'interventionMode':
            case 'responseMode':
            case 'contractualSchema':
                return {[scope]: {$in: scopeValue.map(o => o.id)}}
            case 'compensationModes':
                return {[scope]: {$elemMatch: {$in: scopeValue.map(o => o.id)}}}

        }
    }
    const matchObject = scope && scopeValue
        ? query()
        : {}



    const fetchPromise = businessProjectCollection
        .aggregate([
            {
                $match: {
                    'workflow.step': {$in: ['formX', 'buildOffer', 'submitOffer', 'realisation', 'finished']}
                }
            },
            {
                $lookup: {
                    from: businessRevueCollection,
                    let: { bp: "$_id", signatureDate: '$signatureDate', step: '$workflow.step' },
                    pipeline: [
                        { $match: { $expr: { $eq: ['$businessProject', '$$bp'] }}},
                        { $sort: { date: 1 }},
                        { $project: {
                            date: 1,
                            orderYear: { $substr: [ "$orderMonth", 0, 4 ] },
                            orderMonth: { $substr: [ "$orderMonth", 5, -1 ] },
                            revised: 1,
                            probability: 1,
                            //revisedP: { $multiply: [ "$revised", "$probability", 1/100 ] },
                                revisedP: {
                                    $cond: [
                                        {
                                            $or: [
                                                {
                                                    $and: [
                                                        {$eq: ['$$step', 'finished']},
                                                        {$ne: ['$$signatureDate', null]},
                                                    ],
                                                },
                                                {$eq: ['$$step', 'realisation']}
                                            ]
                                        },
                                        '$revised',
                                        { $multiply: [ "$revised", "$probability", 1/100 ] }
                                    ]
                                }
                        }}
                    ],
                    as: 'businessRevues'
                }
            },
            {
                $lookup: {
                    from: countryCollectionName,
                    localField: 'country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            {
                $lookup: {
                    from: typeOfOfferCollectionName,
                    localField: 'typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: zoneCollectionName,
                    localField: 'country.zone',
                    foreignField: '_id',
                    as: 'zone'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $project: {
                    lastBusinessRevue: {$arrayElemAt: ['$businessRevues', -1]},
                    country: {$arrayElemAt: ['$country', 0]},
                    zone: {$arrayElemAt: ['$zone', 0]},
                    typeOfOffer: {$arrayElemAt: ['$typeOfOffer', 0]},
                    rangeOfOffer: {$arrayElemAt: ['$rangeOfOffer', 0]},
                    code: 1,
                    name: 1,
                    responseMode: 1,
                    projectFunding: 1,
                    interventionMode: 1,
                    contractualSchema: 1,
                    compensationModes: 1
                }
            },
            {
                $match: {
                    ...matchObject,
                    'lastBusinessRevue.orderYear': {$in: years}
                }
            }

        ])
        .toArray()

    const businessProjects = await fetchPromise

    const sumYearValues = (brs, indicator) => brs.reduce(
        (acc, br) => {
            const value = br[indicator] || 0
            if(currentAndNextYear.includes(br.orderYear)) {
                const fullMonth =  `${br.orderYear}-${br.orderMonth}`
                return {
                    ...acc,
                    [fullMonth]: acc[fullMonth] + value,
                    [br.orderYear]: acc[br.orderYear] + value
                }
            }
            return {
                ...acc,
                [br.orderYear]: acc[br.orderYear] + value
            }
        },
        [...years, ...months].reduce((acc, key) => ({...acc, [key]: 0}) , {})
    )

    const accumulateKeysValues = (keys, values) => keys.reduce((acc, key) => {
        const value = values[key]
        return {
            ...acc,
            [key]: acc.total + value,
            total: acc.total + value
        }}, {total: 0})

    return indicators.reduce((acc, indicator) => {
        const total = sumYearValues(businessProjects.map(bp => ({...bp.lastBusinessRevue, number: 1})), indicator.id)
        const totalLine = {
            id: new global.ObjectID().toString(),
            element1: 'Total',
            element2: '---',
            scopeAxis: scopeName ? scopeName : '---',
            scope: scopeValue ? scopeValue.map(o => o.fullName).join(', ') : '---',
            indicator: indicator.name,
            ...total
        }

        const monthsAccumulation = accumulateKeysValues(months, total)
        const yearsAccumulation = accumulateKeysValues(years, total)

        const accumulationLine = {
            id: new global.ObjectID().toString(),
            element1: 'Total cumulé',
            element2: '---',
            scopeAxis: scopeName ? scopeName : '---',
            scope: scopeValue ? scopeValue.map(o => o.fullName).join(', ') : '---',
            indicator: indicator.name,
            ...monthsAccumulation,
            ...yearsAccumulation
        }
        return [
            ...acc,
            ..._.flatMap(groupBpsByAxis(sortBpsByAxis(businessProjects, axis1), axis1), (groupBps, element1) => {
                const subTotal = sumYearValues(groupBps.map(bp => ({...bp.lastBusinessRevue, number: 1})), indicator.id)
                const subTotalLine = {
                    id: new global.ObjectID().toString(),
                    element1: 'Sous-total',
                    element2: '---',
                    scopeAxis: scopeName ? scopeName : '---',
                    scope: scopeValue ? scopeValue.map(o => o.fullName).join(', ') : '---',
                    indicator: indicator.name,
                    ...subTotal
                }

                const monthsSubAccumulation = accumulateKeysValues(months, subTotal)
                const yearsSubAccumulation = accumulateKeysValues(years, subTotal)

                const subAccumulationLine = {
                    id: new global.ObjectID().toString(),
                    element1: 'Sous-total cumulé',
                    element2: '---',
                    scopeAxis: scopeName ? scopeName : '---',
                    scope: scopeValue ? scopeValue.map(o => o.fullName).join(', ') : '---',
                    indicator: indicator.name,
                    ...monthsSubAccumulation,
                    ...yearsSubAccumulation
                }
                const elements = _.flatMap(groupBpsByAxis(sortBpsByAxis(groupBps, axis2), axis2), (groupBps, element2) => {
                    return {
                        id: new global.ObjectID().toString(),
                        element1: element1 === 'undefined' ? '---': element1,
                        element2: element2 === 'undefined' ? '---': element2,
                        scopeAxis: scopeName ? scopeName : '---',
                        scope: scopeValue ? scopeValue.map(o => o.fullName).join(', ') : '---',
                        indicator: indicator.name,
                        ...sumYearValues(groupBps.map(bp => ({...bp.lastBusinessRevue, number: 1})), indicator.id)
                    }
                })
                return axis1 && axis2
                    ? [
                        ...elements,
                        subTotalLine,
                        subAccumulationLine
                    ] :  elements
            }),
            totalLine,
            accumulationLine
        ]
    }, [])
}

export const entities = [
    {
        name: "Indicator",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "revised", name: "CA (k€)"},
            {id: "revisedP", name: "CA.P (k€)"},
            {id: "number", name: "Projet (Nb)"}
        ]
    },
    {
        name: "OrderTaking",
        fields: [
            "element1",
            "element2",
            "scopeAxis",
            "scope",
            "indicator",
            ...getMonths([moment().year()]),
            ...getYears([moment().year()]),
            ...getMonths([moment().year() + 1]),
            ...getYears([moment().year() + 1, moment().year() + 2, moment().year() + 3]),
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else {
                    compute(context)
                        .then(objects => callback(null, objects))
                        .catch(e => callback(e))
                }
            })
        }
    }
]

export const module_ = {
    object: "OrderTaking",
    tKey: "mTitle_orderTaking",
    category: {
        path: "analysis",
        icon: 'sliders'
    },
    removable: false,
    sortable: false,
    chart: {
        keys: [
            ...getMonths([moment().year()]),
            ...getYears([moment().year(), moment().year() + 1, moment().year() + 2, moment().year() + 3])
        ],
        type: "dynamicBarChart",
        title: `Prise de commande (k€) - ${moment().year()}`
    },
    viewMap: {
        dt: [
            {path: "element1", tKey: "Axe principal", width: 500, translate: true},
            {path: "element2", tKey: "Axe secondaire", width: 500, translate: true},
            {path: "scopeAxis", tKey: "Axe périmètre", width: 300, initiallyNotVisible: true},
            {path: "scope", tKey: "Périmètre", width: 500, initiallyNotVisible: true},
            {path: "indicator", tKey:"indicator", width: 150},
            ...getMonths([moment().year()]).map(month => ({path: month})),
            ...getYears([moment().year()]),
            ...getMonths([moment().year() + 1]).map(month => ({path: month, initiallyNotVisible: true})),
            ...getYears([moment().year() + 1, moment().year() + 2, moment().year() + 3]),
        ],
        chart: ["element1", "element2", "indicator",
            ...getMonths([moment().year()]).map(month => ({path: month})),
            ...getYears([moment().year(), moment().year() + 1, moment().year() + 2, moment().year() + 3])
        ]
    },
    filters: [
        {
            object: "OrderTakingGroupAxis",
            title: "Axe principal",
            placeholder: "Sélectionner l'axe principal",
            path: "axis1",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "OrderTakingGroupAxis",
            title: "Axe secondaire",
            placeholder: "Sélectionner l'axe secondaire",
            path: "axis2",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "Indicator",
            path: "indicator",
            placeholder: "Sélectionner l'indicateur",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "Scope",
            path: "scope",
            placeholder: "Sélectionner le périmètre",
            client: true,
            type: 'tags',
            default: [],
            display: "fullName",
            clearable: true,
            filters: [
                {
                    path: 'scopeType',
                    object: 'OrderTakingScopeAxis',
                    display: "name",
                    placeholder: "Sélectionner l'axe périmètre",
                    default: {id: null},
                    client: true,
                    autoFetch: false,
                    match: (object, context) => {
                        const scopeTypeId = _.get(context, 'data.scopeType.id')
                        if(scopeTypeId) {
                            return scopeTypeId === 'compensationModes'
                                ? object.joinedEntity === 'CompensationMode'
                                : object.joinedEntity === _.upperFirst(scopeTypeId)
                        }
                        return true
                    }
                }
            ]
        },

    ]
}
