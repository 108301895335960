const getReference = object => {
    switch (object.joinedEntity) {
        case 'Refund':
            return object.reference
        case 'Invoice':
            return object.wording
        default:
            return 'referenceNull'

    }
}
const getProcessType = object => {
    switch (object.joinedEntity) {
        case 'Refund':
            return 'request'
        case 'Invoice':
            return 'invoice'
        default:
            return 'processTypeNull'

    }
}

export const entity = require('./../../utils/entityJoin').joinEntities(
    {
        name: 'ProcessJoin',
        fields: [
            {
                path: 'processReference',
                fieldPath: ['reference', 'wording'],
                $f: (object, context, callback) => {
                    callback(null, getReference(object))
                }
            },
            {
                path: 'processType',
                $f: (object, context, callback) => {
                    callback(null, getProcessType(object))
                }
            }
        ],
        filters: [
            {
                name: 'byProcessType',
                isDefault: false,
                match: (object, context) => {
                    const processType = context.data && context.data.processType
                    return ['validated', 'toPay', 'paid', 'waiting'].includes(object.status) &&
                        object.processType === processType
                }
            }
        ]
    },
    ['Refund', 'Invoice']
)
