const _ = require('lodash')
const moment = require('moment')
const { basicContext } = require('../../../utils/contextUtils')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')
const { setDataList } = require('../../../../apps/KpModule/actions')
const {
    calculateBPStoreAccountModels
} = require('../engines/bPStoreAccountModelEngine')
const { formatNumber, rowStyle } = require('../engines/functions')

async function findData(context) {
    const projectConfigId = _.get(context, 'data.projectConfiguration.id')
    const projectContextM1M2 = _.get(context, 'data.projectContextM1M2.id')
    if(!projectConfigId || !projectContextM1M2) return []

    const categories = await global.app.B.AccountCategory.find({
        ...basicContext(context),
        fieldPath: ['id'],
        // filter only kind "Projet BpStore" and "Projet BpStore détails"
        query: {kind: {$in: ['6', '7']}},
    })
    const userStateModelAssignment = await global.app.B.StateModelAssignment.find({
        ...basicContext(context),
        fieldPath: ['stateModels.id'],
        query: {
            user: new global.ObjectID(_.get(context, 'user.id'))
        }
    })
    const userStateModelAssignmentQuery = {
        $in: userStateModelAssignment[0]
            ? userStateModelAssignment[0].stateModels.map(model => new global.ObjectID(model.id))
            : []
    }
    const stateModels = await global.app.B.StateModel.find({
        ...basicContext(context),
        fieldPath: ['id', 'code', 'tName'],
        query: {
            _id: userStateModelAssignmentQuery,
            category: {$in: categories.map(category => new global.ObjectID(category.id))},
            scenario: projectContextM1M2
        },

    })
    return stateModels.map(o => _.pick(o, ['id', 'code', 'tName']))
}

async function calculateState(idStateModel, context) {

    const projectConfigurationId = _.get(
        context,
        'data.projectConfiguration.id'
    )

    const projectConfigurationProjectId = _.get(
        context,
        'data.projectConfiguration.projectId'
    )

    const projectContextName = _.get(context, 'data.projectContextM1M2.name')
    const projectContextM1M2 = _.get(context, 'data.projectContextM1M2.id')

    if(!projectConfigurationId || !projectContextM1M2) {
        return await new Promise((resolve, reject) => {
            reject(new Error(context.tc("boothProjectAndContextMustBeSelected")))
        })
    }

    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConfigurationId) },
        {
            ...basicContext(context),
            fieldPath: ['id', 'yearStartBP']
        }
    )

    const { yearStartBP } = projectConfiguration
    const beginYear = _.toNumber(yearStartBP)

    // Get state model
    const selectedStateModel = await global.app.B.StateModel.get(idStateModel, {
        ...basicContext(context),
        fieldPath: [
            'id',
            'code',
            'tName',
            'name',
            'yearOfStart.value',
            'yearOfEnd.value',

            'stateModelLines.order',
            'stateModelLines.modelFormat.id',
            'stateModelLines.modelStyle.id',
            'stateModelLines.accountModel',
            'stateModelLines.accountModel.id',
            'stateModelLines.accountModel.code',
            'stateModelLines.accountModel.formula',
            'stateModelLines.accountModel.specificS2S1',
            'stateModelLines.accountModel.accountModelType.id',
            'stateModelLines.accountModel.dataType.id',
            'stateModelLines.accountModel.dataType.format',
            'stateModelLines.accountModel.dataType.precision',
        ]
    })

    const stateYears = _.range(beginYear, beginYear + selectedStateModel.yearOfEnd.value + 1)

    const resultAccountModels = await calculateBPStoreAccountModels(
        projectConfiguration,
        selectedStateModel.stateModelLines
            .map(sml => ({
                accountModelCode: _.get(sml, 'accountModel.code'),
                accountModelType: _.get(sml, 'accountModel.accountModelType.id')
            })),
        projectContextM1M2,
        context,
        stateYears,
        false
    )

    const resultAccountModelLines = selectedStateModel.stateModelLines.map(stateModelLine => {

        const accountModelUnit = _.get(stateModelLine, 'accountModel.dataType.format.id')
        const accountModelPrecision = _.get(stateModelLine, 'accountModel.dataType.precision.id')
        const accountModelType = _.get(stateModelLine, 'accountModel.accountModelType.id')

        return {

            id: stateModelLine.id,
            readOnly: true,
            name: {
                data: context.translateName(stateModelLine.wording),
                style: { textAlign: 'left' }
            },
            order: stateModelLine.order,
            style: rowStyle(stateModelLine.modelStyle.id),
            value: accountModelType === 'value'
                ? formatNumber(
                    resultAccountModels[stateModelLine.accountModel.code],
                    accountModelUnit,
                    accountModelPrecision
                )
                : '',
            years: accountModelType === 'year'
                ? stateYears.reduce((acc, year) => {
                    return {
                        ...acc,
                        [year]: {
                            data: formatNumber(
                                resultAccountModels[
                                    stateModelLine.accountModel.code
                                    ][year],
                                accountModelUnit,
                                accountModelPrecision
                            ),
                            style: { textAlign: 'center' }
                        }
                    }
                }, {})
                : {},
        }
    })

    const commentsObjects = await global.app.B.DetailAnalysisComments.find(
        {
            ...basicContext(context),
            query: {
                projectConfiguration: new global.ObjectID(projectConfigurationId),
                projectContextM1M2: projectContextM1M2,
                stateModel: new global.ObjectID(selectedStateModel.id),
            },
            fieldPath: ['id', 'user', 'date', 'text']
        })

    return {
        ..._.pick(selectedStateModel, ['id', 'code', 'tName', 'name']),
        title: projectConfigurationProjectId + ' / ' + context.tc(projectContextName),
        stateLines: _.sortBy(resultAccountModelLines, 'order'),
        comments: commentsObjects && commentsObjects.map(commentObject => ({
            ..._.pick(commentObject, ['id', 'user', 'text']),
            date: moment(commentObject.date).format('YYYY-MM-DD HH:mm:ss')
        })),
    }
}

async function saveComments(newObject, context) {
    const projectConfigId = _.get(context, 'data.projectConfiguration.id')
    const projectContextId = _.get(
        context,
        'data.projectContextM1M2.id'
    )

    const oldComments = await global.app.B.DetailAnalysisComments.find(
        {
            ...basicContext(context),
            query: {
                projectConfiguration: new global.ObjectID(projectConfigId),
                projectContextM1M2: projectContextId,
                stateModel: new global.ObjectID(newObject.id)
            },
            fieldPath: ['id']
        })

    const newComments = newObject.comments.filter(comment => !oldComments.some(oldComment => comment.id === oldComment.id))

    if(newComments.length) {
        await global.app.B.DetailAnalysisComments.collection.insertMany(newComments.map(newComment => ({
            text: newComment.text,
            user: new global.ObjectID(newComment.user.id),
            date: moment(newComment.date).toDate(),
            projectConfiguration: new global.ObjectID(projectConfigId),
            projectContextM1M2: projectContextId,
            stateModel: new global.ObjectID(newObject.id),
            group: new global.ObjectID(_.get(context, 'group.id'))
        })))
    }

    return _.pick(newObject, ['id', 'code', 'tName'])
}

export const entity = {
    name: 'AnalyzeDetails',
    fields: ['name', 'title'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else {
                calculateState(id, context)
                    .then( object => {
                        global.ioSocket.emit(
                            `fetchFormObject-success-${context.user.id}${context.clientContext.accessId}`,
                            object
                        )
                    })
                    .catch(error => {
                        global.ioSocket.emit(
                            `fetchFormObject-failure-${context.user.id}${context.clientContext.accessId}`,
                            {error: error.message}
                        )
                    })
                callback(null, {})
            }
        })
    },
    save: function(newObject, context, callback) {
        this.prepareContext(context, 'S', (error, context) => {
            if (error) callback(error)
            else
                saveComments(newObject, context)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    }
}

export const module_ = {
    name: 'AnalyzeDetails',
    object: 'AnalyzeDetails',
    tKey: 'mTitle_analyzeDetails',
    removable: false,
    //updatable: false,
    newable: false,
    hideScroll:true,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    useSocketsOnGet: true,
    category: {
        path: 'bpStore',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            { path: 'code', initiallyNotVisible: true },
            { path: 'tName' , type: 'translatedText' }
        ],
        form: [
            { path: 'title', hidden: true },
            { path: 'code', hidden: true },
            { path: 'tName' , hidden: true },
            {
                path: 'stateLines',
                type: 'styledTable',
                autoGrow: true,
                fullWidth: true,
                fields: [
                    {
                        path: 'name',
                        style: {
                            flex: '1 1 500px',
                            whiteSpace: 'initial'
                        }
                    },
                    { path: 'value' },
                    { path: 'years', dynamic: true },
                    { path: 'style', hidden: true },
                    // { path: 'isHeader', hidden: true }
                ]
            },
            {path: 'comments', type: 'comment2', fullWidth: true}
        ]
    },
    filters: [
        {
            title: 'byProjectConfiguration',
            path: 'projectConfiguration',
            object: 'ProjectConfiguration',
            display: 'projectId',
            client: true,
            width: 9,
            placeholder: 'selectProjectVersion',
            fieldPath: [
                'bPProjectType',
                'projectName',
                'yearStartBP',
                'status.id'
            ],
            clearable: false,
            filters: ['onlyAffectedSubsidiary']
        },
        {
            title: 'byProjectContextM1M2',
            path: 'projectContextM1M2',
            object: 'ProjectContextM1M2',
            display: 'name',
            client: true,
            width: 3,
            default: { id: '1', code: 'S2', name: 'M2NewStore' },
            placeholder: 'selectScenario',
            clearable: false,
            sortList: false,
            filters: ['byProjectType']
        }
    ],
    stateSubscriptions: [{
        statePath: 'form.filterObject.values.projectConfiguration',
        subscription: (newValue, previousValue, { store }) => {
            const projectIsNOrR = ['N', 'R'].includes(_.get(newValue, 'bPProjectType.id'))

            store.dispatch(
                generateFetchFieldListAction(
                    'm-B-analyzeDetails_filter_ProjectContextM1M2',
                    store.getState,
                    'form',
                    { data: {projectContextM1M2: projectIsNOrR} }
                )
            )

            // store.dispatch(setDataList(
            //     'm-B-analyzeDetails_filter_ProjectContextM1M2',
            //     projectIsNOrR
            //         ? [{ id: '1', code: 'S2', name: 'M2NewStore' }]
            //         : [
            //             { id: '1', code: 'S2', name: 'M2NewStore' },
            //             { id: '0', code: 'S1', name: 'M1CurrentStoreContinuity'},
            //             { id: '2', code: 'S2S1', name: 'M2M1Difference' }
            //         ]
            // ))

        }
    }]
}
