export const entity = {
    name: 'NatureOfHelp',
    facets: ['codeName'],
    fields: [
        {path: 'eligibleToEmergencyFund', type: 'boolean'}
    ]
}


export const module_ = {
    object: 'NatureOfHelp',
    tKey: 'mTitle_natureOfHelp',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'},
            'eligibleToEmergencyFund'
        ],
        form: [
            {path: 'code'},
            {path: 'name'},
            'eligibleToEmergencyFund'
        ]
    }
}
