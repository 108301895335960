import {basicContext} from "../../../utils/contextUtils";
import _ from "lodash";
import {changeFieldDisabled} from "../../../../apps/KpModule/actions";
import async from "async";
const Errors = require("../../../utils/Errors").default

export const entity = {
    name: 'MinMaxAmount',
    fields: [
        {path: 'code', unique: true},
        {path: 'min', type: 'integer'},
        {path: 'max', type: 'integer'}
    ],
    filters: [
        {
            name: "byAmount",
            isDefault: false,
            query: function(context) {
                const amount = _.get(context.data, 'amount')
                return amount ?
                    {"min": {$lte:parseFloat(amount)}, "max": {$gte:parseFloat(amount)}} : {_id: "0"}
            }
        }
    ],
    uniqueProcess: function (minMaxID, _min, _max, context, callback) {
        async.parallel([
            callback => global.app.SE.MinMaxAmount.find(
                {
                    ...basicContext(context),
                    fieldPath: ['role.id'],
                    query: {}
                },(e, objects) => {
                    callback(e, !objects.length)
                }),
            callback => global.app.SE.MinMaxAmount.find(
                {
                    ...basicContext(context),
                    fieldPath: ['role.id'],
                    query: {
                        _id: {$ne: global.ObjectID(minMaxID)},
                        $or: [{"min": {$lte:_max}, "max": {$gte:_min}}, {"max": {$eq:_min}}]
                    }
                },callback),
        ],(error, results) => {
            if (error) return callback(error)
            if (_min>=_max){
                return callback(new Errors.ValidationError('the max should be greater than the min amount'))
            }
            const [isItFirstPlage, combinations] = results
            if (isItFirstPlage){
                if (_min===0){
                    callback()
                } else callback(new Errors.ValidationError('the min amount should be 0 for the first range'))
            }
            else{
                if(combinations && combinations.length !== 0) {
                    if ( combinations.length === 1 && combinations[0]["max"] === _min ){
                        //meaning that {"max": {$eq:_min}} returned a result
                        callback()
                    }
                    else {
                        //meaning that the query {"min": {$lte:_max}, "max": {$gte:_min}} returned a result
                        callback(new Errors.ValidationError('this min/max interval coincides with another interval that already exists'))
                    }
                }
                else {
                    callback(new Errors.ValidationError('the ranges of the amounts must not be discontinuous'))
                }
            }
        })
    },
    validateSave: function (newObject, oldObject, context, callback) {
        this.uniqueProcess(newObject.id, newObject.min, newObject.max, context, callback)
    }
}

export const module_ = {
    object: 'MinMaxAmount',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    tKey: 'financialBracket',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'min', tKey: 'min. (K)'},
            {path: 'max', tKey: 'max. (K)'},
        ],
        form: [
            {
                path: 'code',
                disabled: true,
                editable: false,
                default: 'Min. (k) < Plage (k) ≤ Max. (k)'
            },
            {
                path: 'min',
                tKey: 'min. (K)',
                default: 0,
                editable: false,
                wholePositiveNumber: true,
                required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {module, store}) => {
                        const codeField = module.viewMap.form.fields.find( field => field.path === 'code')
                        const minField = module.viewMap.form.fields.find( field => field.path === 'min')
                        const maxField = module.viewMap.form.fields.find( field => field.path === 'max')
                        const min = minField.getValue()
                        const max = maxField.getValue()
                        if (newValue) {
                            codeField.setValue(`${min} k < Plage (k) ≤ ${max} k`)
                        }
                    }
                }
            },
            {
                path: 'max',
                tKey: 'max. (K)',
                default: 0,
                editable: false,
                wholePositiveNumber: true,
                required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {module, store}) => {
                        const codeField = module.viewMap.form.fields.find( field => field.path === 'code')
                        const minField = module.viewMap.form.fields.find( field => field.path === 'min')
                        const maxField = module.viewMap.form.fields.find( field => field.path === 'max')
                        const min = minField.getValue()
                        const max = maxField.getValue()
                        if (newValue) {
                            codeField.setValue(`${min} k < Plage (k) ≤ ${max} k`)
                        }
                    }
                }
            }
        ],
    }
}