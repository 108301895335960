import _ from 'lodash'
import {
    data,
    getRangeOfMonthsFromExerciseStart
} from '../utils/stateComputationUtils'
import {simpleFacQuery} from './queries'

export const getData = async (companyCode, lastMonthFromLastFile, lastMonthFromLastFilePreviousYear, previousExerciseLastMonth) => {


    const mesh = ['groupSurFamilyCode', 'fileYearMonth']

    const currentYearRangeOfMonths = getRangeOfMonthsFromExerciseStart(lastMonthFromLastFile);
    const currentYearQuery = {companyCode: Array.isArray(companyCode) ? {$in: companyCode} : companyCode, fileYearMonth: lastMonthFromLastFile, yearMonth: {$in : currentYearRangeOfMonths}}

    const previousYearRangeOfMonths = getRangeOfMonthsFromExerciseStart(lastMonthFromLastFilePreviousYear);
    const previousYearQuery = {companyCode: Array.isArray(companyCode) ? {$in: companyCode} : companyCode, fileYearMonth: previousExerciseLastMonth, yearMonth: {$in : previousYearRangeOfMonths}};

    const currentYearTurnoverFAC = await data(mesh, [], ["value"], {...currentYearQuery, ...simpleFacQuery})
    const previousYearTurnoverFAC = await data(mesh, [], ["value"], {...previousYearQuery, ...simpleFacQuery})

    const keys = {
        [lastMonthFromLastFile]: lastMonthFromLastFile,
        [previousExerciseLastMonth]: lastMonthFromLastFilePreviousYear
    }


    return _.sortBy(
        _.concat(currentYearTurnoverFAC, previousYearTurnoverFAC).map(object => {
            return {
                axis: object.groupSurFamilyCode,
                [keys[object.fileYearMonth]]:  object.value ? (object.value/1000) : 0
            }
        }),
        'axis'
    )
}
