const _ = require("lodash");
const dd = require("./../default-engine/engine").default;
const facets = require("./facets").default;
const {accordionNorm} = require("./accordionNorm")

const dtu = require("./dataTypeUtils");
const { addDefaultFilters, addDefaultEntities, addDefaultFields } = require("./defaultObjects");

const defaultPath = field => {
    if(! _.includes(dtu.dataTypes, field.type)) return _.lowerFirst(field.type) + (_.includes(dtu.TM, field.link && field.link.type) ? "s" : "");
};

const createOppositeLink = (link, field, fields, entity) => {
    const linkId = entity.name + "_" + field.path; // todo: think about an unique linkId v
    const oppositeEntity = link.entity;

    if(! (
        (oppositeEntity.type === "static" && entity.type !== "static")
        || field.f || field.$f
    )) {
        const oppositeLink = dtu.oppositeLinksMap[link.type];

        const oppositeField = link.oppositeField = dd.normalize(link.oppositeField, {default: {}, fields: [
            {path: "type", default: entity.name},
            {path: "userDefined", default: false},
            {path: "writable", default: true},
            {path: "entity", $default: _.constant(oppositeEntity)},
            {path: "nullable", default: _.includes(["OTO", "MTO"], oppositeLink)},
            {path: "link", default: {}, fields: [
                {path: "external", default: true},
                {path: "type", default: oppositeLink},
                {path: "entity", $default: _.constant(entity)},
                {path: "onParent", default: link.onChild},
                {path: "onChild", default: link.onParent},
                {path: "id", default: linkId}
            ]},
            {path: "path", $default: oppositeField => defaultPath(oppositeField)}
        ]});

        oppositeEntity.fields.push(oppositeField);
    }
    return linkId;
};

const safeFind = (list, query) => {
    const result = _.find(list, query);
    if(! result) throw new Error(`${_.keys(query)[0]} ${_.values(query)[0]} could not be found`);
    return result;
};


export default function(modelOrModels) {

    const execute = function(model) {
        dd.normalize(model, {
            name: "model",
            fields: [
                {path: "enhanced", default: true},
                {path: "name", $p: ["required", "string", "notEmpty"]},
                {path: "id", $default: model => model.name.slice(0, 1), $p: ["required", "notEmpty", "unique"]},
                {path: 'languages', default: ['en', 'fr']},
                {path: "technical", default: false},
                {path: "noGroup", $default: "technical"},
                {path: "entities", default: [], $p: [addDefaultEntities, "fields"], fields: [
                    {path: "model", $default: (entity, entities, model) => model},

                    {path: "name", $p: ["required", "string", "notEmpty"]},
                    {
                        path: "url",
                        $default: entity => (entity.model.technical ? "" : (entity.model.id.toLowerCase() + ".")) + entity.name.toLowerCase(),
                        $p: ["required", "string", "notEmpty"]
                    },
                    {path: "type", default: "mongo", $p: [{type: "enum", list: ["mongo", "mongoInternal", "static", "sync"]}]},

                    {path: "technical", $default: "../../technical"},
                    {path: "noGroup", $default: (entity, entities, model) => {
                        return entity.type !== "mongo" || entity.technical || model.technical || model.noGroup
                    }},

                    {path: "fields", default: [], $p: [
                        {$u: (fields, entity, entities) => fields.map(field => {
                            if (_.isString(field)) {
                                const isEntity = _(entities).map("name").includes(field);
                                field = isEntity ? {type: field} : {path: field};
                            }
                            return field;
                        })}
                    ]},

                    {path: "facets", default: [], $p: ["forceArray", "keyToObject", {type: "fusion", list: facets, id: "name"}]},

                    {path: "facets.norm", $p: (norm, facet, facets, entity, entities, model) => {
                        dd.normalizeInternal(entity, norm, {facet}, [entities, model])
                        }},

                    {path: "fields", $p: {$u: addDefaultFields}},

                    {
                        path: "fields",
                        fields: [
                            {path: "entity", $default: (field, fields, entity) => entity},

                            {path: "type", default: "string", $p: ["string"]},
                            {path: "long", $if: (long, field) => _.includes(["string"], field.type), default: false},

                            {path: "writable", $default: field => !(field.f || field.$f)},

                            {path: "link", $if: (link, field) => !_.includes(dtu.dataTypes, field.type), default: {}, $p: [{type: "keyToObject", key: "type"}, "fields"], fields: [
                                {path: "field", $default: (link, field) => field},

                                {path: "entity", $default: (link, field, fields, entity, entities) => safeFind(entities, {name: field.type}), $p: ["required", "object", "notEmpty"]},
                                {path: "external", $default: link => link.entity.type !== "mongoInternal", $p: ["boolean"]},
                                {path: "type", $default: link => link.external ? "MTO" : "OTM", $p: ["required", {type: "enum", list: dtu.allLinkTypes}]},

                                {path: "userDefined", default: true}
                            ]},
                            {path: "path", $default: defaultPath, $p: ["required", "string", "notEmpty"]},
                            {path: "tKey", $default: "path", $p: ["required", "string", "notEmpty"]}
                        ]
                    },

                    {path: "fields.link", fields: [
                        {path: "id", $default: createOppositeLink}
                    ]},

                    {
                        path: "fields",
                        fields: [
                            {
                                path: "list",
                                $default: field => !!field.link && _.includes(["OTM", "MTM"], field.link.type),
                                $p: ["boolean"]
                            },
                            {path: "encrypt", default: false, $p: ["boolean"]},
                            {path: "notEmpty", default: false, $p: ["boolean"]},
                            {path: "nullable", default: false, $p: ["boolean"]},
                            //{path: "nullable", $default: function(field) { return field.object && ! field.list; }, $p: ["boolean"]},

                            {
                                path: "$default",
                                $if: (default_, field) => field.link && field.link.type === "OTO" && field.link.updateType === "cascade" && !field.nullable,
                                $default: field => field.link.entity.new
                            },
                            {
                                path: "default",
                                $if: (default_, field) => field.list && !field.$default && !(field.f || field.$f),
                                default: []
                            },
                            {
                                path: "default",
                                $if: (default_, field) => (!field.$default) && !(field.f || field.$f),
                                $default: field => field.link ? dtu.defaultValues.object : dtu.defaultValues[field.type]
                            },
                            {
                                path: "$default",
                                $default: field => () => _.cloneDeep(field.default),
                                $p: ["function", "bind"]
                            }
                        ]
                    },


                    {path: "filters", default: [], $p: [{$u: addDefaultFilters}]},
                ]},

                {path: "modules", fields: [
                    {path: "main", default: true},
                    {path: "model", $default: "../../id"},
                    {path: "model_", $default: "../../"},
                    {path: "object", $p: ["required", "string", "notEmpty", "technicalName"]},

                    {path: "name", $default: "object", $p: ["required", "string", "notEmpty"]},
                    {path: "tKey", $default: module => module.name && _.lowerFirst(module.name)},
                    {path: "id", $default: (module, modules, model) => `m-${model.id}-${_.lowerFirst(module.name)}`, $p: ["required", "notEmpty"]},
                    {path: "step", default: ""},
                    // maxResults limits the number of results on the db query
                    {path: "maxResults", default: false},
                    {path: "noLeftBoard", default: false},
                    {path: "useSocketsOnGet", default: false},
                    {path: "useSocketsOnFind", default: false},
                    {path: "useSocketsOnSave", default: false},
                    {path: "removable", default: true},
                    {path: "sortable", default: true},
                    {path: "memorizeColumns", default: true},
                    {path: "removableGroup", default: false},
                    {path: "impExp", default: false},
                    {path: "chart", default: false},
                    {path: "defaultPanel", default: "list"},
                    {path: "newable", default: true},
                    {path: "listable", default: true},
                    {path: "updatable", default: true},
                    {path: "protectedExport", default: false},
                    {path: "displayLog", default: true},
                    {path: "workflowActions", default: []},
                    {path: "defaultFormButtons", default: [
                            { type: 'save', bsStyle: 'success', tKey: 'save' },
                            { type: 'return', bsStyle: 'default', tKey: 'return' }
                        ]
                    }

                ]},

                {path: "modules", $p: {type: "norm", object: accordionNorm}},

                {path: "modules", fields: [
                    {path: "accesses", $p: [{$u: (accesses, module) =>{
                        const filters = module.filters.reduce((acc,filter) => {
                            return filter.filters ? _.concat(acc, filter.filters) : acc
                        }, module.filters.slice())
                        const filterAccesses = _(filters).map("access").compact().value();
                        return accesses.concat(filterAccesses);
                    }
                    }]}
                ]},

                {path: "accesses", $default: model => _(model.modules).map("accesses").flatten().compact().value()},

                {path: "modules", fields: [
                    {path: "accesses", fields: [
                        {path: "module", $default: "../../"}
                    ]}
                ]},

                {path: "accesses", fields: [
                    {path: "model", $default: "../../"}
                ]}

            ]
        }, {throw: true});
    };

    _.isArray(modelOrModels) ? modelOrModels.forEach(execute) : execute(modelOrModels);

    // common(modelOrModels);

    return modelOrModels;
};
