const configureWorkbook = (object, data, dataTotal, columns, context) => workbook => {
    workbook.creator = 'Keenpoint'
    workbook.lastModifiedBy = 'Keenpoint'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Consommations', {defaultColWidth: 300})

    let row = 1
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const header = dataSheet.getRow(row)
    columns.forEach(column => {
        header.getCell(col).value = context.tc(column, {current: object.exercise.code, previous: object.comparisonExercise && object.comparisonExercise.code})
        header.getCell(col).fill = darkGrayBG
        header.getCell(col).font = {
            size: 12,
            bold: true
        }
        header.getCell(col).border = {
            bottom: {style:'thin'}
        }
        col++
    })
    header.getCell(col).border = {left: {style:'thin'}}

    row = 2
    data.forEach(line => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = line[column]
            dataSheet.getRow(row).getCell(col).font = {
                size: 12,
            }
            col++
        })
        dataSheet.getRow(row).getCell(col).border = {
            left: {style:'thin'},
        }
        row++
    })

    col = 1
    columns.forEach(column => {
        dataSheet.getRow(row).getCell(col).value = dataTotal[column]
        dataSheet.getRow(row).getCell(col).font = {
            size: 12,
            bold: true
        }
        dataSheet.getRow(row).getCell(col).border = {
            top: {style:'thin'},
            bottom: {style:'thin'}
        }
        col++
        dataSheet.getRow(row).getCell(col).border = {
            left: {style:'thin'}
        }
    })

    columns.forEach((column, index) => {
        dataSheet.getColumn(index + 1).width = 15
    })
}

export async function generateExcel(fileName, object, data, dataTotal, columns, context) {
    return await new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(object, data, dataTotal, columns, context),
            fileName,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            },
            true
        );
    });
}
