export const entity = require('../../../../utils/entityJoin').joinEntities(
    {
        name: 'AxisAndMesh',
        fields: [
            'name',
            /*
            {
                path: 'name',
                fieldPath: ['name'],
                f: function(){
                    if(this.joinedEntity === 'OrganizationalAxis') {
                        return  `${this.name}`
                    }
                    if (this.joinedEntity === 'Mesh') {
                        return `${this.name}`
                    }
                }
            }
             */
        ],
        /*
        beforeSave: function(newObject, oldObject, context){
            if (newObject.joinedEntity === 'Mesh'){
                return newObject
            }
        },
         */
        filters: [
            {
                name: 'byIncludeInFunctionScope',
                isDefault: true,
                match: object => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return object.includeInFunctionScope
                    }
                    return true
                }
            }
        ]
    },
    ['OrganizationalAxis', 'Mesh']
)
