export const entity = require('./../../utils/entityJoin').joinEntities({
    name: 'GlobalBenefitJoin',
    fields: [
        'code',
        'name',
        {
            path: 'exercise',
            type: 'Exercise',
            $f: (object, context, callback) => {
                callback(null, object.exercise)
            }
        }
    ]
}, ['Global', 'Benefit'])
