export const dependencies = [
    {
        name: "DashboardElement",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name", "type", "url", "suffix", "link", "keys", "colors", "width", "sum"
        ],
        objects: [

            // Common Elements
            {
                id: "currentUserAlertsInProgress",
                name: "fr::Alertes déclenchées\nen::Alerts triggered\nit::Alerts ricevuti",
                type: 'shortCard',
                url: "CurrentUserAlertsInProgress",
                suffix: " (Nb)",
                link: {
                    title: "Traiter les alertes",
                    path: "tracking/m-S-alertProcessing"
                }
            },
            {
                id: "currentUserAlerts",
                name: "fr::Alertes à traiter\nen::Alerts to process\nit::Alerts da gestire",
                type: 'shortCard',
                url: "CurrentUserAlerts",
                suffix: " (Nb)",
                link: {
                    title: "Traiter les alertes",
                    path: "tracking/m-S-alertProcessing"
                }
            },

            {id: "topStore100Disc", name: "fr::Magasin : Remise ln. = 100%\nen::Store : Disc. ln. = 100%\nit::Negozio: Sconto netto per articolo 100%", type: 'simpleBarChart', url: "TopStore100Disc", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "top90To100Disc", name: "fr::Magasin : Remise ln. 90 ≤ % < 100\nen::Store : Disc. ln. 90 ≤ % < 100\nit::Negozio: Sconto netto per articolo 90 ≤ % < 100", type: 'simpleBarChart', url: "Top90To100Disc", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "top80To90Disc", name: "fr::Magasin : Remise ln. 80 ≤ % < 90\nen::Store : Disc. ln. 80 ≤ % < 90\nit::Negozio: Sconto netto per articolo 80 ≤ % < 90", type: 'simpleBarChart', url: "Top80To90Disc", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "top70To80Disc", name: "fr::Magasin : Remise ln. 70 ≤ % < 80\nen::Store : Disc. ln. 70 ≤ % < 80\nit::Negozio: Sconto netto per articolo 70 ≤ % < 80", type: 'simpleBarChart', url: "Top70To80Disc", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "topNegativePmts", name: "fr::Magasin : Remboursement > 0€\nen::Store : Refund > 0€\nit::Negozio: Rimborsi > 0€", type: 'simpleBarChart', url: "TopNegativePmts", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "cashierCustomerWithSameEmail", name: "fr::Magasin : Caissier ≠ Client, même adresse mail\nen::Store : Cashier ≠ Customer, Same email\nit::Negozio: Venditore ≠ cliente, ma stessa email", type: 'simpleBarChart', url: "CashierCustomerWithSameEmail", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "cashierIsCustomer", name: "fr::Magasin : Caissier = Client\nen::Store : Cashier = Customer\nit::Negozio: Venditore = Cliente", type: 'simpleBarChart', url: "CashierIsCustomer", suffix: ' (Nb)', width: 1/2, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "employeePurchaseH1000", name: "fr::Employee : Gross purch. > 1000€\nen::Employee : Gross purch. > 1000€\nit::Dipendenti : Acquisti lordi mensili > 1000€", type: 'simpleBarChart', url: "EmployeePurchaseH1000", suffix: ' (Nb)', width: 1/2, keys: ['customerIsEmployee', 'number'], sum: true, useColorsByStore: true},
            {id: "customerGrossPurchaseH2500", name: "fr::Customer : Gross Purch. > 2.5k€\nen::Customer : Gross Purch. > 2.5k€\nit::Clienti : Acquisti lordi settimanali > 2500€", type: 'simpleBarChart', url: "CustomerGrossPurchaseH2500", suffix: ' (Nb)', width: 1/2, keys: ['ticketCustomer', 'number'], sum: true, useColorsByStore: true, axisHeight: 90},
            {id: "customerTicketEH4", name: "fr::Customer : Tkt. ≥ 4\nen::Customer : Tkt. ≥ 4\nit::Clienti : ≥ 4 Ticket giornalieri", type: 'simpleBarChart', url: "CustomerTicketEH4", suffix: ' (Nb)', width: 1/2, keys: ['ticketCustomer', 'number'], sum: true, useColorsByStore: true, axisHeight: 90},

            // Stores Elements
            /*
            {id: "currentUserExpenses", name: "Dépenses caisse", type: 'shortCard', url: "CurrentUserExpenses", suffix:"€"},
            {id: "abandonmentReceipts", name: "Top 10: Abandon / C", type: 'simpleBarChart', url: "AbandonmentReceipts", suffix: ' (Nb)', width: 1/2, keys: ['vendeur', 'abandon'], sum: true, useColorsByCashier: true},
            {id: "canceledReceipts", name: "Top 10: Annulation ticket / C", type: 'simpleBarChart', url: "CanceledReceipts", suffix: ' (Nb)', width: 1/2, keys: ['vendeur', 'canceled'], sum: true, useColorsByCashier: true},
            {id: "refundsWithoutReceipt", name: "Top 10: Remb. ss tk origine / C", type: 'simpleBarChart', url: "RefundsWithoutReceipt", suffix: ' (Nb)', width: 1/2, keys: ['vendeur', 'refund'], sum: true, useColorsByCashier: true},
            {id: "overSeventyPriceChange", name: "Top 10: Modification Prix > 70% / C", type: 'simpleBarChart', url: "OverSeventyPriceChange", suffix: ' (Nb)', width: 1/2, keys: ['vendeur', 'modified'], sum: true, useColorsByCashier: true},
            {id: "clientsIndicators", name: "Indicateurs clients", type: 'horizontalDoubleBarChart', url: "ClientsIndicators", width: 1/2, colors: ['#003f5c', '#ffa600'], keys: ['Mag.', 'Moy. Reg.']},
            {id: "attachmentRate", name: "Taux de rattachement (%)", type: 'horizontalDoubleBarChart', url: "AttachmentRate", width: 1/2, colors: ['#003f5c', '#ffa600'], keys: ['Mag.', 'Moy. Reg.']},
            {id: "turnoverPerDay", name: "Flux quotidien (K€)", type: 'lineChart', url: "TurnoverPerDay", width: 1/2, colors: ['#58508d', '#ffa600'], keys: ['Mag.', 'Moy. Reg.']},
            {id: "turnoverPerSeller", name: "Flux / Vendeur (K€)", type: 'simpleBarChart', url: "TurnoverPerSeller", width: 1/2, keys: ['vendeur', 'CA'], useColorsByCashier: true},
            {id: "topStoreSales", name: "Top 5 flux magasin (K€)", type: 'horizontalDoubleBarChart', url: "TopStoreSales", width: 1/2, colors: ['#003f5c', '#bc5090'], keys: ['Mag.', 'Moy. Reg.']},
            {id: "topRegionSales", name: "Top 5 flux région (K€)", type: 'horizontalDoubleBarChart', url: "TopRegionSales", width: 1/2, colors: ['#003f5c', '#bc5090'], keys: ['Mag.', 'Moy. Reg.']},

            // Regions Elements
            {id: "drAbandonPerStore", name: "Top 10: Abandon / M", type: 'simpleBarChart', url: "DrAbandonPerStore", suffix: ' (Nb)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drCanceledPerStore", name: "Top 10: Annulation ticket / M", type: 'simpleBarChart', url: "DrCanceledPerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drExpensePerStore", name: "Top 10: Dépenses caisse / M", type: 'simpleBarChart', url: "DrExpensePerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drRefundCashPerStore", name: "Top 10: Remb. espèce / M", type: 'simpleBarChart', url: "DrRefundCashPerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, absolute: true , sum: true},
            {id: "drRefundWithoutTicketPerStore", name: "Top 10: Remb. ss tk origine / M", type: 'simpleBarChart', url: "DrRefundWithoutTicketPerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, absolute: true , sum: true},
            {id: "drRefundCashWithoutTicketPerStore", name: "Top 10: Remb. esp. ss tk origine / M", type: 'simpleBarChart', url: "DrRefundCashWithoutTicketPerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, absolute: true , sum: true},
            {id: "drManualDiscountReturnPerStore", name: "Top 10: Remise man. retour / M", type: 'simpleBarChart', url: "DrManualDiscountReturnPerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drManualDiscountSalePerStore", name: "Top 10: Remise man. vente / M", type: 'simpleBarChart', url: "DrManualDiscountSalePerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drDiscountModificationPricePerStore", name: "Top 10: Remise modif. prix / M", type: 'simpleBarChart', url: "DrDiscountModificationPricePerStore", suffix: ' (€)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "tillDifferencesAndCancellations", name: "Top 10 : Ecart caisse avec annul. ticket / M", type: 'simpleBarChart', url: "TillDifferencesAndCancellations", suffix: ' (Nb)', width: 1/2, keys: ['store', 'value'], useColorsByStore: true, sum: true},
            {id: "drTurnoverPerStore", name: "Flux / Magasin", type: 'simpleBarChart', url: "DrTurnoverPerStore", suffix: ' (€)', width: 1, keys: ['store', 'value'], useColorsByStore: true, sum: true}
             */
        ]
    }
]
