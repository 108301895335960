import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'
import {PlusCircle} from 'react-feather'
import { Table, TrashCell, Column, AutoSizer, BooleanCell } from './DataTable'
import _ from 'lodash'
import moment from 'moment'
import { ObjectID } from 'bson'
import { FormField, FormButtons } from './Form'
import ModuleFormField from '../apps/KpModule/components/Form/Field'
import BsButton from './BsButton'
import classnames from 'classnames'
import { translateName } from '../utils/i18n'
import './AccordionComponent.css'
import PropTypes from 'prop-types'
import Accordion from './Accordion'
import {defaultHeaderRenderer, SortDirection} from 'react-virtualized/dist/commonjs/Table'
import HoverPopover from "./HoverPopover";
import Draggable from "react-draggable";

const findRowIndex = (objects, sortedObjects, rowIndex) => objects.findIndex(o => {
    if (sortedObjects[rowIndex].id) return sortedObjects[rowIndex].id === o.id
    return Object.keys(sortedObjects[rowIndex]).reduce(
        (acc, key) =>
            acc &&
            sortedObjects[rowIndex][key] === o[key]
    )
})

const sort = (ids, getValueForId, sortDirection) =>
    _.orderBy(
        ids,
        getValueForId,
        sortDirection === SortDirection.ASC ? 'asc' : 'desc'
    )

const translatorGetter = (field, t, language) => (data, path) => {
    const object = _.get(data, path)
    return field.translateName
        ? translateName(object, language)
        : field.translate
            ? t(object, { capitalize: true })
            : object
}

function cellDataGetter({ field, t, language }) {
    const getAndTranslate = translatorGetter(field, t, language)

    switch (field.type) {
        case 'object':
            return ({ rowData, dataKey }) =>
                getAndTranslate(rowData, `${dataKey}.${field.display}`)
        case 'date':
            return ({ rowData, dataKey }) => {
                const value = _.get(rowData, dataKey)
                const m = moment(value)
                return m.isValid() ? m.format('YYYY-MM-DD') : value
            }
        case 'objectFromList':
            return ({ rowData, dataKey }) => {
                const object = _.get(rowData, dataKey)

                if (!object) return

                return getAndTranslate(
                    field,
                    `list.${object.id}.${field.display}`
                )
            }
        case 'objectArray':
            return ({ rowData, dataKey }) => {
                return _.get(rowData, dataKey) &&
                    _.get(rowData, dataKey).map(element => {
                        const value = _.get(element, `${field.display}`)
                        return field.translateName
                            ? translateName(value, language)
                            : field.translate ? t(value) : value
                    }).join(', ')
            }
        case 'tags':
            return ({ rowData, dataKey }) => {
                return _.get(rowData, dataKey) &&
                    _.get(rowData, dataKey).map(element => {
                        const value = _.get(element, `${field.display}`)
                        return field.translate ? t(value) : value
                    }).join(', ')
            }
        case 'translatedText':
            return ({ rowData, dataKey }) =>
                translateName(_.get(rowData, dataKey), language)
        default:
            return ({ rowData, dataKey }) => getAndTranslate(rowData, dataKey)
    }
}

function cellRenderer(field) {
    switch (field.type) {
        case 'boolean':
            return ({ cellData }) => <BooleanCell value={cellData} />
        default:
            return undefined
    }
}

class AccordionComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mode: 'innerList',
            newFormObject: null,
            index: null,
            sortBy: props.defaultSortBy,
            sortDirection: props.defaultSortDirection || 'ASC'
        }
    }

    enhanceColumns = columns => {
        const { t, language } = this.props
        const filteredColumns = columns.filter(column => !column.hidden)
        return filteredColumns.map((column, index) => {
            const nextColumn = filteredColumns[index + 1]
            return {
                ...column,
                dataKey: column.path,
                label: t(column.tKey, { capitalize: true }),
                cellDataGetter: cellDataGetter({ field: column, language, t }),
                cellRenderer: cellRenderer(column),
                headerRenderer: this.renderColumnHeader(column, nextColumn)
            }
        })
    }

    renderColumnHeader = (column, nextColumn) => {
        const {objects = [], setFieldWidth, t} = this.props

        return headerParams => {
            const getNumber = o => {
                const numberCandidate = Number(_.get(o, column.path))
                return _.isNaN(numberCandidate) ? 0 : numberCandidate
            }
            const total = _.round(
                objects.reduce((acc, o) => getNumber(o) + acc, 0)
            )

            const headerRenderer = defaultHeaderRenderer(headerParams)

            let children = []

            if (column.tooltip) {
                children.push(
                    <HoverPopover
                        key="overlay"
                        id={'KpDataTable-tooltip'}
                        message={`${t('total')} : ${total}`}
                        placement={'top'}
                    >
                        {headerRenderer[0]}
                    </HoverPopover>
                )
            } else {
                children.push(headerRenderer[0])
            }


            // the second element is the sort indicator
            if (headerRenderer[1]) {
                children.push(headerRenderer[1])
            }

            if(nextColumn) {
                children.push(
                    <div
                        onClick={event => event.stopPropagation()}
                    >
                        <Draggable
                            axis="x"
                            defaultClassName="DragHandle"
                            defaultClassNameDragging="DragHandleActive"
                            onDrag={(event, data) => {
                                const delta = data.deltaX;
                                const columnWidth = column.width + delta
                                const nextColumnWidth = nextColumn.width - delta

                                setFieldWidth(column.id, columnWidth)
                                setFieldWidth(nextColumn.id, nextColumnWidth)
                            }}
                            position={{ x: 0 }}
                            zIndex={999}
                        >
                            <span className="DragHandleIcon">⋮</span>
                        </Draggable>
                    </div>
                )
            }
            return children
        }
    }

    getOrderColumn = () => {
        return {
            dataKey: 'order',
            label: 'order',
            width: 20,
            flexGrow: 0,
            flexShrink: 1,
            disableSort: true,
            disableFilter: true,
            disableExport: true,
            disableHide: true,
            cellRenderer: ({ rowIndex }) => {
                return <span style={{fontWeight: 500, color: '#525252'}}>{rowIndex + 1}</span>
            }
        }
    }

    getDeleteColumn = (sortedObjects, t) => {
        const remove = rowIndex => () =>
            toastr.confirm(t('deletionConfirmation'), {
                onOk: () => this.props.remove(rowIndex)
            })

        return {
            dataKey: 'id',
            label: '',
            width: 20,
            flexGrow: 0,
            flexShrink: 0,
            disableSort: true,
            disableFilter: true,
            disableExport: true,
            disableHide: true,
            cellRenderer: ({ rowIndex, rowData }) => {
                if(!rowData.noDeleteButtonAccess) {
                    return (
                        <TrashCell
                            onClick={() => {
                                // takes index of object in sorted list and finds its index in unsorted list
                                const index = findRowIndex(this.props.objects, sortedObjects, rowIndex)
                                remove(index)()
                            }}
                        />
                    )
                }
            }
        }
    }

    sortTable = ({ sortBy, sortDirection }) => {
        this.setState({
            sortBy,
            sortDirection
        })
    }

    renderList() {
        const {
            initialize,
            listFields,
            maxRows,
            t,
            editFields,
            module,
            noRowsMessage,
            formField,
            newable,
            removable,
            sortable,
            disabled,
            objects,
            byId,
            allIds,
            sortableTable,
            move
        } = this.props
        const defaultObject = editFields.reduce((acc, field) => {
            acc[field.path] = field.default
            return acc
        }, {})

        const dataGetter = (fieldById, sortBy, listFields) => {
            const sortedField = listFields.find(field => field.path === sortBy)

            switch (sortedField.type) {
                case 'objectFromList':
                    return fieldById[sortedField.path][sortedField.display]
                case 'boolean':
                    return fieldById[sortedField.path] ? 'true' : 'false'
                default:
                    return fieldById[sortedField.path]
            }
        }

        const sortedIds = this.state.sortBy
            ? sort(allIds, id => dataGetter(byId[id], this.state.sortBy, listFields), this.state.sortDirection)
            : allIds

        const sortedObjects = ( sortedIds && sortedIds.map(id => byId[id]) )|| []

        const tableColumns = _.compact([
            sortableTable && this.getOrderColumn(),
            ...this.enhanceColumns(listFields),
            formField
                ? removable && !disabled ? this.getDeleteColumn(sortedObjects, t) : undefined
                : module.removable ? this.getDeleteColumn(sortedObjects, t) : undefined
        ])

        return (
            <div>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            objects={sortedObjects}
                            width={width}
                            maxRows={maxRows}
                            headerClassName="AccordionHeader"
                            headerRowClassName="AccordionHeaderRow"
                            onRowClick={({ index }) => {
                                if(!sortedObjects[index].noFormAccess) {
                                    initialize(sortedObjects[index])
                                    const rowIndex = findRowIndex(objects, sortedObjects, index )
                                    this.setState({
                                        mode: 'innerForm',
                                        newFormObject: false,
                                        index: rowIndex
                                    })
                                }
                            }}
                            sortableTable={sortableTable}
                            onSortEnd={({ newIndex, oldIndex }) => move(oldIndex, newIndex)}
                            noRowsLabel={t(noRowsMessage || 'noRows')}
                            sort={sortable && !sortableTable && this.sortTable}
                            sortBy={this.state.sortBy}
                            sortDirection={this.state.sortDirection}
                        >
                            {tableColumns.map((column, i) => (
                                <Column key={i} {...column} />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
                {this.state.mode === 'innerList' && newable && !disabled && (
                    <span
                        className={classnames(
                            // 'glyphicon',
                            // 'glyphicon-plus-sign',
                            'AccordionComponent-listNewButton'
                        )}
                        onClick={() => {
                            initialize(defaultObject)
                            this.setState({
                                newFormObject: true,
                                mode: 'innerForm'
                            })
                        }}
                    >
                        <PlusCircle size={22} />
                    </span>
                )}
            </div>
        )
    }

    renderForm() {
        const {
            editable,
            disabled,
            failSubmit,
            succeedSubmit,
            isSubmitting,
            isPristine,
            isValid,
            editFields,
            formValues,
            push,
            onFieldChange,
            t,
            user,
            language,
            editObjectValues
        } = this.props
        const fields = editFields.map(o => o.path)
        return (
            <div className="AccordionComponent-form">
                {editFields.map(field => !field.hidden && (
                    <FormField key={field.path} required={field.required} type={field.type} label={field.tKey} t={t}>
                        <ModuleFormField
                            field={{
                                ...field,
                                editable: editable ? field.editable : editable,
                                disabled: disabled ? disabled : field.disabled
                            }}
                            editObjectValues={editObjectValues}
                            t={t}
                            language={language}
                            objectMode={this.state.newFormObject ? 'newObject' : 'editObject'}
                            user={user}
                        />
                    </FormField>
                ))}
                <FormButtons>
                    {
                        !disabled && (this.state.newFormObject || editable) && (
                            <BsButton
                                type="button"
                                bsStyle="success"
                                disabled={ (!this.state.newFormObject && isPristine) || isSubmitting}
                                onClick={() => {
                                    if (!isValid) {
                                        failSubmit(fields)
                                    } else {
                                        succeedSubmit()

                                        if(this.state.newFormObject) {
                                            const mongoId = new ObjectID()
                                            push({id: mongoId.toString(), ...formValues})
                                        }
                                        else {
                                            onFieldChange(this.state.index, formValues)
                                        }
                                        this.setState({ mode: 'innerList' })
                                    }
                                }}
                            >
                                Ok
                            </BsButton>
                        )
                    }
                    <BsButton
                        type="button"
                        bsStyle="default"
                        onClick={() => this.setState({ mode: 'innerList' })}
                    >
                        Cancel
                    </BsButton>
                </FormButtons>
            </div>
        )
    }

    renderPanel(panelKey) {
        return panelKey === 'innerList' ? this.renderList() : this.renderForm()
    }

    render() {
        const {path, t, required} =this.props
        return (
            <div style={{padding: '5px'}}>
                <label className="AccordionComponent-label" >{t(path)} {required && '*'}</label>
                <Accordion
                    openedPanel={this.state.mode}
                    panelKeys={['innerList', 'innerForm']}
                    renderPanel={this.renderPanel.bind(this)}
                    fillAllHeight={false}
                />
            </div>
        )
    }
}

AccordionComponent.propTypes = {
    initialize: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    listFields: PropTypes.array.isRequired,
    editFields: PropTypes.array.isRequired,
    maxRows: PropTypes.number,
    formValues: PropTypes.object,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    failSubmit: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default AccordionComponent
