const _ = require('lodash')
const path = require('path')
const moment = require('moment')
const {decrypt} = require("../../../utils/crypto")

async function businessRevueRecovery(context) {

    const businessProjects = await global.app.I.BusinessProject.collection.find({
        'workflow.step': {$in: ["formX", "buildOffer", "submitOffer", "realisation", "finished"]}
    }).toArray();

    for (const project of businessProjects) {
        const sortedBusinessRevues = await global.app.I.BusinessRevue.collection
            .aggregate([
                {
                    $match: {businessProject: new global.ObjectID(project._id)}
                },
                {
                    $sort : {date : 1}
                }
            ])
            .toArray()

        const lastBR = _.last(sortedBusinessRevues)

        const  firstDayOfMonth = moment().startOf('month').toDate()

        const  firstDayOfYear = moment().startOf('year').toDate()

        const isBrDone =  sortedBusinessRevues.some(BR => {
            return BR.date >= firstDayOfMonth
        })

        const automaticRecovery = project.workflow.step !== 'finished'
            || new Date(project.workflow.lastUpdate) > firstDayOfYear

        if(automaticRecovery && !isBrDone  && lastBR) {

            const executedFollowUp =  await global.app.I.ExecutedFollowUp.collection.findOne(
                {
                    businessProject: new global.ObjectID(project._id),
                    year: moment().year()
                }
            )

            await global.app.I.BusinessRevue.collection.insertOne(
                {
                    ..._.pick(lastBR, ["revised", "yearN", "probability", "yearNPlusOne", "projectStep", "orderMonth"]),
                    comment: "Reprise automatique du mois précédent",
                    date: new Date(),
                    executedTurnOver: executedFollowUp ? executedFollowUp.executedTurnOver : 0,
                    businessProject: new global.ObjectID(project._id),
                    group: new global.ObjectID(context.group.id)
                }
            )
        }
    }

}

async function businessRevueReminder(context) {

    const businessProjects = await global.app.I.BusinessProject.collection.find({
        'workflow.step': {$in: ["formX", "buildOffer", "submitOffer", "realisation"]},
        monitoringTeamMembers: { $exists: true, $not: {$size: 0}},
        group: global.ObjectID(context.group.id)
    }).toArray();

    if(!businessProjects.length) return "no project with monitors found"

    const date = new Date();
    const firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)

    const businessRevues = await global.app.I.BusinessRevue.collection.find({
        businessProject: {$in: businessProjects.map(bp => bp._id)},
        date: { $gte: firstOfMonth},
        group: global.ObjectID(context.group.id)
    }).toArray();

    const filteredProjects = businessProjects.filter(bp => {
        return !businessRevues.find(br => br.businessProject.toString() === bp._id.toString())
    })

    if(!filteredProjects.length) return "no missing business revues found"

    const teamMembersIds = _(filteredProjects).flatMap(bp => bp.monitoringTeamMembers.map(elem => elem.toString())).uniq().value()

    const teamMembers = await global.app.I.TeamMember.collection.find({
        _id: {$in: teamMembersIds.map(id => global.ObjectID(id))},
        group: global.ObjectID(context.group.id)
    }).toArray();

    const data = teamMembers.map(teamMember => {
        return {
            teamMember,
            projects: filteredProjects.filter(bp => bp.monitoringTeamMembers.map(elem => elem.toString()).includes(teamMember._id.toString()))
        }
    })


    const defaultMailElements = {
        from: '"EDFIN" <edfin@keenpoint.com>',
        replyTo: 'support@keenpoint.com',
        content: `businessRevuesReminder.html`,
        subject: { template: "Rappel : BR mensuel en attente de ta contribution"},
        signature: false,
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? "buildServer/server/models/edf-in/templates/mails"
                : "src/server/models/edf-in/templates/mails"
        ),
        verbose: {
            general: true,
        }
    }

    const host = context.host || global.config.host

    const mails = data.map(object => {
        return _.defaults({
            to: decrypt(object.teamMember.email),
            context: {
                firstname: object.teamMember.firstName,
                projects: object.projects,
                link: global.isProd
                    ? `https://${host}/business/${context.groupModel.id}/reporting/m-I-businessRevue`
                    : `http://localhost:3000/business/${context.groupModel.id}/reporting/m-I-businessRevue`,
            }
        }, defaultMailElements)
    })

    return global.mailer.sendMail(mails, error => {
        if (error) {
            console.log("error sending emails")
            return
        }
        console.log(`A total of ${filteredProjects.length} projects missing business revues for current month`)
    })

}

async function applyJob(title, context, job) {
    const message = `${title} successful`

    if (context.autoJob) {
        console.log(`starting automatic ${title} job`)

        // if the job is automatic we have to execute the job for all groups that have this model.

        const edfinGroupModels = await global.GroupModel.find({
            fieldPath: ["id", "group.id"],
            query: {model: "I"}
        })

        for (const groupModel of edfinGroupModels) {
            await job({ ...context, groupModel, group: groupModel.group })
        }

    } else {
        console.log(`starting manual ${title} job`)
        await job(context);
    }


    console.log(message);
    return { message };
}

export const jobs = [
    {
        name: "automaticRecovery",
        title: "Automatic Recovery",
        cron: "0 1 12 * *",
        execute: function(context, callback) {
            applyJob(this.title, context, businessRevueRecovery)
                .then(result => callback(null, result))
                .catch(callback)
        }
    },
    {
        name: "businessRevueReminder",
        title: "Business Revue Reminder",
        cron: "0 7 6-11 * *",
        execute: function(context, callback) {
            applyJob(this.title, context, businessRevueReminder)
                .then(result => callback(null, result))
                .catch(callback)
        }
    }
]
