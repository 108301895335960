export const submitButton = {
    type: 'save',
    tKey: 'submit',
    bsStyle: 'primary'
}

export const endorseButton = {
    type: 'save',
    tKey: 'endorse',
    bsStyle: 'warning'
}

export const returnButton = {
    type: 'return',
    tKey: 'return',
    bsStyle: 'default'
}
