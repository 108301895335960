const moment = require('moment')

export const dependencies = [
    {
        name: "DashboardElement",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name",
            "type",
            "url",
            "suffix",
            "link",
            "keys",
            "colors",
            "width",
            "sum",
            "sumColor",
            "absolute"
        ],
        objects: [
            {id: "importAnalysisGraph", name: "Analyse d'import", type: 'simpleBarChart', url: "ImportAnalysisGraph", width: 1/2, suffix: ' (Nb)', keys: ['key', 'number'], sum: true},
            {
                id: "agrTurnoverBySurFamily",
                name: "agrTurnoverBySurFamily",
                type: 'doubleBarChart',
                width: 1/2,
                url: "AgrTurnoverBySurFamily",
                keys: [
                    'currentYear',
                    'previousYear'
                ],
                colors: ['#1b638e', '#bbb']
            },
            {
                id: "accAgrTurnoverBySurFamily",
                name: "accAgrTurnoverBySurFamily",
                width: 1/2,
                type: 'doubleBarChart',
                url: "AccAgrTurnoverBySurFamily",
                keys: [
                    `${moment().subtract(1, "months").format('YYYY')}`,
                    `${moment().subtract(1, "months").subtract(1, "years").format('YYYY')}`],
                colors: ['#1b638e', '#bbb']},
            {
                id: "caplTurnoverBySurFamily",
                name: "caplTurnoverBySurFamily",
                type: 'doubleBarChart', url: "CaplTurnoverBySurFamily",
                width: 1/2,
                keys: [
                    `${moment().subtract(1, "months").format('YYYY-MM')}`,
                    `${moment().subtract(1, "years").subtract(1, "months").format('YYYY-MM')}`
                ],
                colors: ['#1b638e', '#bbb']
            },
            {
                id: "accCaplTurnoverBySurFamily",
                name: "accCaplTurnoverBySurFamily",
                width: 1/2, type: 'doubleBarChart',
                url: "AccCaplTurnoverBySurFamily",
                keys: [
                    `${moment().subtract(1, "months").format('YYYY')}`,
                    `${moment().subtract(1, "months").subtract(1, "years").format('YYYY')}`
                ],
                colors: ['#1b638e', '#bbb']
            },
            {
                id: "totalTurnoverBySurFamily",
                name: "totalTurnoverBySurFamily",
                width: 1/2, type: 'doubleBarChart',
                url: "TotalTurnoverBySurFamily",
                keys: [
                    `${moment().subtract(1, "months").format('YYYY-MM')}`,
                    `${moment().subtract(1, "months").subtract(1, "years").format('YYYY-MM')}`
                ],
                colors: ['#1b638e', '#bbb']
            },
            {
                id: "totalAccTurnoverBySurFamily",
                name: "totalAccTurnoverBySurFamily",
                width: 1/2, type: 'doubleBarChart',
                url: "TotalAccTurnoverBySurFamily",
                keys: [
                    `${moment().subtract(1, "months").format('YYYY')}`,
                    `${moment().subtract(1, "months").subtract(1, "years").format('YYYY')}`
                ],
                colors: ['#1b638e', '#bbb']
            },
            {
                id: "marginBySurFamilyGroup",
                name: "marginBySurFamilyGroup",
                width: 1/2,
                type: 'simpleBarChart',
                url: "MarginBySurFamilyGroup",
                suffix: ' (k€)',
                keys: ['key', 'value'],
                sum: true,
                sumColor: '#bf162c'
            },
            {
                id: "marginCommercialBySurFamilyGroup",
                name: "marginCommercialBySurFamilyGroup",
                width: 1/2,
                type: 'simpleBarChart',
                url: "MarginCommercialBySurFamilyGroup",
                suffix: ' (k€)',
                keys: ['key', 'value'],
                sum: true,
                sumColor: '#bf162c'
            },
            {
                id: "accStockAdjustment",
                name: "accStockAdjustment",
                width: 1/2,
                type: 'simpleBarChart',
                url: "AccStockAdjustment",
                keys: ['key', 'value'],
                absolute: true
            }
        ]
    }
]
