/* eslint-disable no-unused-vars */
import {retry} from "async/index";

const _ = require('lodash')
const moment = require('moment')
const Errors = require('../../../utils/Errors').default
const { basicContext } = require('../../../utils/contextUtils')
const {
    irr, min, max, sum, avg, formatNumber, rowStyle, dateToIntFormat,
    getYear, getMonth, getDayOfYear, totalDaysInYear,
    roundNumber, generateYears, numberOfDaysInPeriodForYear,
    formatFormula
} = require('./functions')


export async function calculateBPStoreAccountModels(
    projectConf,
    accountModelsToCalculate,
    projectContextM1M2,
    context,
    yearsCalculation,
    useYearHiData
) {
    console.time('dataSearch')

    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConf.id) },
        {
            ...basicContext(context),
            fieldPath: [
                'pole.id', 'pole.code',
                'subsidiary.country.id',
                'subsidiary.country.code',
                'subsidiary.country.currency.code',
                'country.id', 'country.code', 'country.currency.code',
                'leaseDatas.id', 'leaseDatas.leaseType.id', 'leaseDatas.leaseType.code',
                'leaseDatas.generalCharacteristics.id',
                'leaseDatas.generalCharacteristics.generalCharacteristicType.id',
                'leaseDatas.leaseDataRenewals.id',
                'leaseDatas.leaseDataRenewals.leaseRenewal.id',
                'leaseDatas.leaseDataRenewals.leaseRenewal.code',
                'leaseDatas.leaseDataPeriods.id',
                'leaseDatas.leaseDataPeriods.leaseCondition.id',
                'leaseDatas.leaseDataPeriods.leaseCondition.code',
                'leaseConfigurations.leaseElement.id',
                'leaseConfigurations.leaseType.id',
                'leaseConfigurations.startDate',

            ]
        }
    )

    const { yearStartBP, openingDate, yearHIDATA } = projectConfiguration

    const project_real_opening_date = dateToIntFormat(yearStartBP + '0101')

    const project_opening_date = dateToIntFormat(openingDate)
    const hi_data_year = _.toNumber(yearHIDATA)

    const beginYear = _.toNumber(yearStartBP)

    const f_year = !!useYearHiData ? hi_data_year : beginYear
    const min_year = beginYear
    const max_year = beginYear+ 9

    const s2ConfTemporaryStore = projectConfiguration.leaseConfigurations.find(o => o.leaseElement.id === 'm2' && o.leaseType.id === 'temporaryStore')

    const s2_has_temporary_shop = () => !!s2ConfTemporaryStore
    const s2_temporary_shop_opening_date = !!s2ConfTemporaryStore
        ? dateToIntFormat(moment(s2ConfTemporaryStore.startDate, 'YYYY-MM-DD').format('YYYYMMDD'))
        : dateToIntFormat(moment().format('YYYYMMDD'))

    const project_subsidiary_code = _.get(projectConfiguration, 'subsidiary.code')
    const project_country_code = _.get(projectConfiguration, 'subsidiary.country.code')
    const project_country_currency_code = _.get(projectConfiguration, 'subsidiary.country.currency.code')
    const project_pole_code = _.get(projectConfiguration, 'pole.code')
    const project_operating_country_code = _.get(projectConfiguration, 'country.code')
    const project_operating_country_currency_code = _.get(projectConfiguration, 'country.currency.code')

    const project_type = _.get(projectConfiguration, 'bPProjectType')

    let withProject = projectContextM1M2 ? _.toNumber(projectContextM1M2) : _.get(context, 'data.projectContextM1M2.id')

    const is_s1 = () => withProject === 0
    const is_s2 = () => withProject === 1
    const is_s2s1 = () => withProject === 2

    const is_dev = () => project_country_currency_code !== 'EUR'
    const is_eur = () => project_country_currency_code === 'EUR'

    let leasePeriod = 0

    const surface = element => {
        if(is_s2s1()) {
            return 0
        }
        const scope = is_s1() ? 'm1' : 'm2'
        switch (element) {
            case 'MainSales':
                const mainSales = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'principal'
                )
                return _.get(mainSales, 'main', '0')
            case 'MainAnnex':
                const mainAnnex = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'principal'
                )
                return _.get(mainAnnex, 'annex', '0')
            case 'MainFacade':
                const mainFacade = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'principal'
                )
                return _.get(mainFacade, 'facade', '0')
            case 'Other1':
                const otherLocal1 = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'otherLocal1'
                )
                return _.get(otherLocal1, 'main', '0')
            case 'Other2':
                const otherLocal2 = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'otherLocal2'
                )
                return _.get(otherLocal2, 'main', '0')
            case 'Temporary':
                const temporaryStore = projectConfiguration.leaseConfigurations.find(o =>
                    o.leaseElement.id === scope && o.leaseType.id === 'temporaryStore'
                )
                return _.get(temporaryStore, 'main', '0')
            default:
                return 0
        }
    }

    const periodNumber = type => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        return leaseData ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease').length : 0
    }

    const periodDays = (type, order) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataPeriods = leaseData
            ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease')
            : []
        if(filteredLeaseDataPeriods.length > order) {
            const orderedLeaseDataPeriods = _.sortBy(filteredLeaseDataPeriods, 'start')
            const leaseDataPeriod = orderedLeaseDataPeriods[order]
            const start = leaseDataPeriod.start
            const end = leaseDataPeriod.end
            result = _.round(moment.duration(end.diff(start)).asDays() +1)
        }
        return result
    }

    const periodDate = (type, order, option) => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataPeriods = leaseData
            ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease')
            : []
        if(filteredLeaseDataPeriods.length > order) {
            const orderedLeaseDataPeriods = _.sortBy(filteredLeaseDataPeriods, 'start')
            return dateToIntFormat(orderedLeaseDataPeriods[order][option])
        } else {
            return new Errors.ValidationError(`Period lease order : ${order} do not exist`)
        }
    }

    const periodBeginDate = (type, order) => periodDate(type, order, 'start')
    const periodEndDate = (type, order) => periodDate(type, order, 'end')

    const periodDaysInYear = (type, order, year) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataPeriods = leaseData
            ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease')
            : []
        if(filteredLeaseDataPeriods.length > order) {
            const orderedLeaseDataPeriods = _.sortBy(filteredLeaseDataPeriods, 'start')
            const leaseDataPeriod = orderedLeaseDataPeriods[order]
            const start = moment(leaseDataPeriod.start, 'YYYY-MM-DD');
            const end = moment(leaseDataPeriod.end, 'YYYY-MM-DD');
            result = numberOfDaysInPeriodForYear(start, end, year)
        }
        return result
    }

    const periodValue = (type, condition, order) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const leaseDataPeriods = leaseData.leaseDataPeriods.filter(o => o.leaseCondition.code === condition)
        const orderedLeaseDataPeriods = _.sortBy(leaseDataPeriods, 'start')
        return orderedLeaseDataPeriods.length > order
            ? orderedLeaseDataPeriods[order].value
            : 0
    }

    const periodValueInYear = (type, condition, order, year) => {
        const value = periodValue(type, condition, order)
        const daysInYear = periodDaysInYear(type, order, year)
        return value > 0 && daysInYear > 0
            ? ( value * daysInYear ) / totalDaysInYear(year)
            : 0
    }

    const periodRenewalOrder = (type, order) => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataPeriods = leaseData
            ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease')
            : []
        if(filteredLeaseDataPeriods.length > order) {
            const orderedLeaseDataPeriods = _.sortBy(filteredLeaseDataPeriods, 'start')
            return orderedLeaseDataPeriods[order].leaseRenewal - 100
        } else {
            return new Errors.ValidationError(`Period lease number : ${order} do not exist`)
        }
    }

    const renewalNumber = type => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        return leaseData
            ? leaseData.leaseDataRenewals.length
            : new Error(`lease type : ${type} do not exist`)
    }

    const renewalDate = (type, order, option) => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataRenewal = leaseData && leaseData.leaseDataRenewals.find(o => o.leaseRenewal.id === order + 100)
        return filteredLeaseDataRenewal
            ? dateToIntFormat(filteredLeaseDataRenewal[option])
            : new Error(`renewal order : ${order} do not exist`)
    }

    const renewalBeginDate = (type, order) => renewalDate(type, order, 'start')
    const renewalEndDate = (type, order) => renewalDate(type, order, 'end')

    const renewalDays = (type, order, option) => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataRenewal = leaseData && leaseData.leaseDataRenewals.find(o => o.leaseRenewal.id === order + 100)
        if(filteredLeaseDataRenewal) {
            const start = moment(filteredLeaseDataRenewal.start, 'YYYY-MM-DD')
            const end = moment(filteredLeaseDataRenewal.end, 'YYYY-MM-DD')
            return moment.duration(end.diff(start)).asDays() +1
        } else {
            return new Errors.ValidationError(`renewal order : ${order} do not exist`)
        }
    }

    const renewalDaysInYear = (type, order, year) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataRenewal = leaseData && leaseData.leaseDataRenewals.find(o => o.leaseRenewal.id === order + 100)
        if(filteredLeaseDataRenewal) {
            const start = moment(filteredLeaseDataRenewal.start, 'YYYY-MM-DD');
            const end = moment(filteredLeaseDataRenewal.end, 'YYYY-MM-DD');
            result = numberOfDaysInPeriodForYear(start, end, year)
        }
        return result
    }

    const generalCharacteristicData = (type, option) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        if(leaseData) {
            const generalCharacteristic = leaseData.generalCharacteristics
                .find(o => o.generalCharacteristicType.id === option)
            result = generalCharacteristic && generalCharacteristic.value !== ''
                ? _.toInteger(generalCharacteristic.value)
                : 0
        }
        return result
    }

    const leaseholdAcquisition = type => generalCharacteristicData(type, 'rightToLease')
    const deposit = type => generalCharacteristicData(type, 'guaranteeDeposit')
    const bankGuarantee = type => generalCharacteristicData(type, 'bankGuarantee')
    const renovationOfThePremises = type => generalCharacteristicData(type, 'restorationOfPremises')

    const getLeaseDataRenewals = (type, order, option) => {
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const leaseDataRenewal = leaseData && leaseData.leaseDataRenewals.find(o => o.leaseRenewal.id === order + 100)
        return leaseDataRenewal
            ? leaseDataRenewal[option]
            : new Error(`renewal order : ${order} do not exist`)

    }

    const isAmortizedDuringInitialPeriod = type => getLeaseDataRenewals(type,0, 'convenience')
    const isFixed = (type, order) => getLeaseDataRenewals(type, order, 'fixed')
    const isVariable = (type, order) => getLeaseDataRenewals(type, order, 'variable')
    const isGaranteed = (type, order) => getLeaseDataRenewals(type, order, 'minimumGuaranteed')
    const isCapped = (type, order) => getLeaseDataRenewals(type, order, 'capped')
    const rentFreeMonths = (type, order) => getLeaseDataRenewals(type, order, 'rentDeductible')

    const rentFreeDaysInYear = (type, order, year) => {
        const freeMonths = rentFreeMonths(type, order)
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const leaseDataRenewal = leaseData && leaseData.leaseDataRenewals
            .find(o => o.leaseRenewal.id === order + 100)
        if(leaseDataRenewal) {
            const start = moment(leaseDataRenewal.start, 'YYYY-MM-DD');
            const end = moment(leaseDataRenewal.end, 'YYYY-MM-DD');
            const intMonths = _.floor(leaseDataRenewal.rentDeductible)
            const intDays = _.floor((leaseDataRenewal.rentDeductible - intMonths) * 30)
            const freeMonthsEndDate = moment(start).add({days: intDays,months: intMonths})
            return leaseDataRenewal.rentDeductible > 0
                ? numberOfDaysInPeriodForYear(start, freeMonthsEndDate, year)
                : 0
        }else {
            return new Errors.ValidationError(`renewal order : ${order} do not exist`)
        }
    }

    const periodRentFreeDaysInYear = (type, order, year) => {
        let result = 0
        const leaseData = projectConfiguration.leaseDatas.find(o => o.leaseType.code === type && o.withProject === withProject)
        const filteredLeaseDataPeriods = leaseData
            ? leaseData.leaseDataPeriods.filter(o => o.leaseCondition.id === 'fixLease')
            : []

        if(filteredLeaseDataPeriods.length > order) {
            const orderedLeaseDataPeriods = _.sortBy(filteredLeaseDataPeriods, 'start')
            const leaseDataPeriod = orderedLeaseDataPeriods[order]

            const leaseDataRenewal = leaseData.leaseDataRenewals
                .find(o => o.leaseRenewal.id === leaseDataPeriod.leaseRenewal)

            const start = moment(leaseDataPeriod.start, 'YYYY-MM-DD');
            const end = moment(leaseDataPeriod.end, 'YYYY-MM-DD');

            const intMonths = _.floor(leaseDataRenewal.rentDeductible)
            const intDays = _.floor((leaseDataRenewal.rentDeductible - intMonths) * 30)
            const freeMonthsEndDate = moment(leaseDataRenewal.start).add({days: intDays, months: intMonths})

            if(leaseDataRenewal.rentDeductible > 0 && start.isSameOrBefore(freeMonthsEndDate)) {
                result = end.isSameOrAfter(freeMonthsEndDate)
                    ? numberOfDaysInPeriodForYear(start, freeMonthsEndDate, year)
                    : numberOfDaysInPeriodForYear(start, end, year)
            }
        }
        return result
    }

    // les modèles de comptes
    const accountModels = await global.app.B.AccountModel.collection
        .find()
        .toArray()
    const accountModelsByCode = accountModels.reduce(
        (acc, am) => ({
            ...acc,
            [_.toLower(am.code)]: _.pick(am, ['_id', 'code', 'formula', 'specificS2S1'])
        }),
        {}
    )

    const countries = await global.app.B.Country.collection
        .find()
        .toArray()
    const countriesTypes = countries.reduce((acc, country) => ({
        ...acc,
        [`P-${country.code}`]: {mesh: '3', country: country._id}
    }), {})

    const types = {
        F: { mesh: '1', subsidiary: new global.ObjectID(_.get(projectConfiguration, 'subsidiary.id')) },
        H: { mesh: '2' },
        P: { mesh: '3', country: new global.ObjectID(_.get(projectConfiguration, 'subsidiary.country.id')) },
        'P-OPERATION': { mesh: '3', country: new global.ObjectID(_.get(projectConfiguration, 'country.id')) },
        ...countriesTypes
        //M: { mesh: '4', shop: new global.ObjectID(shop.id) },
        //B: { mesh: '5', bp: new global.ObjectID(shopBPEndorsed.id) }
    }

    const dataInContext = await global.app.B.Data.collection
        .find({
            $or: _.values(types)
        })
        .toArray()

    const lowerCaseData = dataInContext.map(o => ({
        ...o,
        name: _.get(o, 'name', '').toLowerCase()
    }))

    const hypothesisData = await global.app.B.HypothesisData.find({
        ...basicContext(context),
        query: {projectConfiguration: new global.ObjectID(projectConfiguration.id)},
        fieldPath: ['hypothesisModelLine.code', 'dataType.code']
    })

    console.timeEnd('dataSearch')

    const data = (type, name, year) => {
        const dataRows = _.filter(lowerCaseData, { name: name.toLowerCase(), year, ...types[type] })
        return dataRows.reduce((acc, o) => acc + o.value, 0)
    }

    const hypothesis = (code, type, firstYear, lastYear) => {

        const exercise = firstYear ? {exercise: firstYear} : {}
        const filter = {
            'hypothesisModelLineCode': code,
            'dataTypeCode': type,
            ...exercise,
            withProject
        }
        if(lastYear){
            const results = []
            for (let year = firstYear; year <= lastYear; year++) {
                results.push(hypothesis(code, type, year))
            }
            return results
        } else {
            const dataRows = _.filter(hypothesisData, filter)
            return dataRows.reduce((acc, o) => acc + o.value, 0)
        }
    }

    const getWithProjectFromSituation = (situation) => {
        switch (situation) {
            case 'S1':
                return 0
            case 'S2':
                return 1
            case 'S2S1':
            default:
                return 2
        }
    }

    const accountModelCache = {}
    const leaseAccountModelCache = {}

    const formulaM = (...args) => formula(...args)
    const formulaH = (...args) => formula(...args)
    const formulaP = (...args) => formula(...args)
    const formulaH_ = (...args) => formulaP_(...args)

    const formulaByLeasePeriod = (code, firstElement, secondElement) => {
        const accountModel = accountModelsByCode[_.toLower(code)]
        if (!accountModel || accountModel === Infinity) return 0
        let result = 0
        const oldLeasePeriodValue = leasePeriod
        if(secondElement !== undefined){
            let year = firstElement
            leasePeriod = secondElement
            result = setWithProjectBeforeEvaluationLease(accountModel, year)
        } else {
            leasePeriod = firstElement
            result = setWithProjectBeforeEvaluationLease(accountModel)
        }
        leasePeriod = oldLeasePeriodValue
        return result
    }

    const formulaP_ = (code, situation, firstYear, lastYear) => {
        let result = 0
        const oldValue = withProject
        withProject = getWithProjectFromSituation(situation)
        result = formulaP(_.toLower(code), firstYear, lastYear)
        withProject = oldValue
        return result
    }

    const evaluateFormula =  (accountModel, year) => {
        const cacheKey = year !== undefined
            ? `${accountModel.code}-${year}-${withProject}`
            : `${accountModel.code}-${withProject}`

        if (accountModelCache[cacheKey]) {
            return accountModelCache[cacheKey]
        }
        const result = eval(formatFormula(accountModel.formula))
        accountModelCache[cacheKey] = result
        return result
    }

    const evaluateFormulaLease =  (accountModel, year) => {
        const cacheKey = year !== undefined
            ? `${accountModel.code}-${year}-${withProject}-${leasePeriod}`
            : `${accountModel.code}-${withProject}-${leasePeriod}`

        if (leaseAccountModelCache[cacheKey]) {
            return leaseAccountModelCache[cacheKey]
        }
        const result = eval(formatFormula(accountModel.formula))
        leaseAccountModelCache[cacheKey] = result
        return result
    }

    const setWithProjectBeforeEvaluationLease = (accountModel, year) => {
        let result = 0
        if(is_s2s1() && !accountModel.specificS2S1 ){
            // set withProject to M2
            withProject = 1
            result = evaluateFormulaLease(accountModel, year)
            // set withProject to M1
            withProject = 0
            result -= evaluateFormulaLease(accountModel, year)
            // back to M21
            withProject = 2
        } else {
            result = evaluateFormulaLease(accountModel, year)
        }
        return result
    }

    const setWithProjectBeforeEvaluation = (accountModel, year) => {
        let result = 0
        if(is_s2s1() && !accountModel.specificS2S1 ){
            // set withProject to M2
            withProject = 1
            result = evaluateFormula(accountModel, year)
            // set withProject to M1
            withProject = 0
            result -= evaluateFormula(accountModel, year)
            // back to M21
            withProject = 2
        } else {
            result = evaluateFormula(accountModel, year)
        }
        return result
    }

    const formula = (code, firstYear, lastYear) => {
        const accountModel = accountModelsByCode[_.toLower(code)]
        if (!accountModel || accountModel === Infinity) return 0
        let result = []
        if (lastYear) {
            for (let year = firstYear; year <= lastYear; year++) {
                const tmpResult = year > 1995
                    ? setWithProjectBeforeEvaluation(accountModel, year)
                    : 0
                result.push(tmpResult)
            }
        } else if (firstYear !== undefined) {
            let year = firstYear === 0 ? f_year : firstYear
            result = year > 1995
                ? setWithProjectBeforeEvaluation(accountModel, year)
                : 0
        } else {
            result = setWithProjectBeforeEvaluation(accountModel)
        }
        return result
    }

    console.time('computeTime')

    const result = accountModelsToCalculate.reduce((acc, am) => {
        console.log('Account Model Code', am.accountModelCode)
        return {
            ...acc,
            [am.accountModelCode]: am.accountModelType === 'year'
                ? yearsCalculation.reduce(
                    (acc, year) => ({
                        ...acc,
                        [year]: formulaM(am.accountModelCode, year)
                    }), {})
                : formulaM(am.accountModelCode)
        }
    }, {})

    console.timeEnd('computeTime')

    return result
}
/* eslint-enable no-unused-vars */
