import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { setUiMode, setObjectMode, emptyEditObject } from '../../actions'
import List from '../List'
import Form from '../Form'
import { I18n, translate } from 'react-i18next'
import { isDirty } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import GlyphiconButton from './GlyphiconButton'
import Accordion from '../../../../components/Accordion'
import Chart from '../Graph/index'
import './ModuleAccordion.css'
import { iconSelector } from '../../../../utils/iconSelector'
import { loadDefaultEdit } from '../../actions/module'
import { getModule, getListIcon, getChartIcon, getFormIcon, getEditFieldsLength } from '../../selectors/index'
import {Plus, BarChart2, Shuffle, Table as TableIcon} from 'react-feather'
import { fetchInitialChartData, fetchInitialDtData } from '../../actions/api'
import { OBJECT_FORM } from '../Form'

class ModuleAccordion extends PureComponent {
    renderHeading = () => {
        const {
            module,
            mode,
            setUiMode,
            listIcon,
            formIcon,
            chartIcon,
            confirmAndSetUiMode,
            setObjectMode,
            openFormWithDefaultData,
            fetchInitialDtData,
            fetchInitialChartData,
            emptyEditObject,
            isFormDirty,
            menuButtonsColor,
            selectedColor
        } = this.props

        const category = module.category
        const path = category.path ? category.path : category
        const icon = category.icon

        return (
            <div className="Module-header">
                <I18n ns={[module.model, 'platform']}>
                    {t => (
                        <div className="Module-title-container">
                            {iconSelector(
                                icon || path,
                                'Module-menu-icon',
                                menuButtonsColor
                            )}
                            <p className="Module-menu-title" style={{color: menuButtonsColor}}>
                                {t(path)}
                            </p>
                            {iconSelector(
                                'rightChevron',
                                'Module-title-icon',
                                selectedColor
                            )}
                            <p className="Module-title" style={{color: selectedColor}}>
                                {t(module.tKey)}
                            </p>
                        </div>
                    )}
                </I18n>
                <div className="ModuleAccordion-panel-buttons-div">
                    <I18n ns={[module.model, 'platform']}>
                        {t => (
                            <div className="btn-group ModuleAccordion-buttons">
                                {module.impExp &&
                                mode !== 'impExp' && (
                                    <GlyphiconButton
                                        backgroundColor="rgb(250 252 254)"
                                    >
                                        <Shuffle style={{color: '#fefefe'}} size={15} />
                                    </GlyphiconButton>
                                )}
                                {chartIcon &&
                                mode !== 'chart' && (
                                    <GlyphiconButton
                                        backgroundColor="hsl(23deg 90% 59%)"
                                        onClick={() => {
                                            fetchInitialChartData()
                                            setUiMode('chart')
                                        }}
                                    >
                                        <BarChart2 style={{color: 'rgb(63 64 71)'}} size={15} />
                                    </GlyphiconButton>
                                )}
                                { listIcon && module.listable &&
                                mode !== 'list' && (
                                    <GlyphiconButton
                                        backgroundColor="hsl(198deg 99% 37%)"
                                        onClick={() => {
                                            //fetchInitialDtData()
                                            confirmAndSetUiMode('list', isFormDirty)
                                        }}
                                    >
                                        <TableIcon style={{color: 'rgb(234 234 234)'}} size={15} />
                                    </GlyphiconButton>
                                )}
                                {formIcon &&
                                module.newable &&
                                mode !== 'form' && (
                                    <GlyphiconButton
                                        backgroundColor="hsl(70deg 59% 51%)"
                                        onClick={() => {
                                            setObjectMode('newObject')
                                            openFormWithDefaultData()
                                        }}
                                    >
                                        <Plus style={{color: 'rgb(63 64 71)'}} size={15} />
                                    </GlyphiconButton>
                                )}
                            </div>
                        )}
                    </I18n>
                </div>
            </div>
        )
    }

    static renderPanel(key, t) {
        switch (key) {
            case 'list':
                return <List t={t} />
            case 'form':
                return <Form t={t} />
            case 'chart':
                return <Chart t={t} />
            default:
                return <List t={t} />
        }
    }

    render() {
        const { mode, module } = this.props

        return (
            <div className="Module-container">
                <Accordion
                    renderHeading={this.renderHeading}
                    renderPanel={ModuleAccordion.renderPanel}
                    openedPanel={mode}
                    panelKeys={['list', 'form', 'chart']}
                    fillAllHeight={true}
                    panelClassName="panel-body"
                    mainAccordion={true}
                    model={module.model}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    mode: state.ui.mode,
    module: getModule(state),
    listIcon: getListIcon(state),
    formIcon: getFormIcon(state),
    chartIcon: getChartIcon(state),
    editFieldsLength: getEditFieldsLength(state),
    isFormDirty: isDirty(OBJECT_FORM)(state)
})

const mapDispatchToProps = (dispatch, { t }) => ({
    setUiMode: panel => dispatch(setUiMode(panel)),
    emptyEditObject: () => dispatch(emptyEditObject()),
    confirmAndSetUiMode: (panel, isFormDirty) => {
        if(isFormDirty) {
            toastr.confirm(t('quittingFormConfirmation'), {
                onOk: () => dispatch(setUiMode(panel))
            })
        } else {
            dispatch(setUiMode(panel))
            dispatch(emptyEditObject())
            dispatch(setObjectMode(''))
        }
    },
    setObjectMode: mode => dispatch(setObjectMode(mode)),
    openFormWithDefaultData: () => dispatch(loadDefaultEdit()),
    fetchInitialChartData: () => dispatch(fetchInitialChartData()),
    fetchInitialDtData: () => dispatch(fetchInitialDtData()),
})

export default translate('platform')(
    connect(mapStateToProps, mapDispatchToProps)(ModuleAccordion)
)
