import { Component } from 'react'
import _ from 'lodash'
import * as selectors from '../../selectors'
import { setNotFoundPage } from '../../actions'
import { connect } from 'react-redux'

const processData = (props) => {
    const {
        groupModels,
        params,
        technical,
        isUserK,
        isAccountManager
    } = props

    if(! params) return {isMissingData: true}

    const groupModel = _.find(groupModels, {
        id: params.groupModelId
    })


    const module = params.moduleId
        ? _.find(groupModel ? groupModel.modules : [], {
            id: params.moduleId
        })
        : undefined

    const groupModule = technical && 'm-T-group' === params.moduleId
    const usersModule = technical && 'm-T-users' === params.moduleId
    const profileModule = technical && 'm-T-my-account' === params.moduleId
    const profileTicket = technical && 'm-T-my-tickets' === params.moduleId
    const subscriptionModule =  params.moduleId?.split('-')[2] === 'userSubscription'

    const userHaveAccessToTechnicalModules =
        (usersModule && (isUserK || isAccountManager)) ||
        (groupModule && isUserK) ||
        profileModule ||
        profileTicket

    const moduleMissing = groupModels.length !== 0 &&  params.moduleId
        ? (!technical && !module && !['m-T-groupmodel-team', 'm-T-alert', 'm-T-documents', 'm-T-broadcastList', 'm-T-messaging', 'm-T-profiles'].includes(params.moduleId) && !subscriptionModule) ||
        (technical && !userHaveAccessToTechnicalModules)
        : false
    return {
        isMissingData: (groupModels.length !== 0  && !groupModel && !technical) || moduleMissing,
        groupModel,
        technical
    }
}

class NotFoundRoute extends Component {
    constructor(props) {
        super(props)
        this.state = processData(props)
    }
    static getDerivedStateFromProps(nextProps){
        return processData(nextProps)
    }
    redirectIfNotFoundPage() {
        if (this.state.isMissingData) {
            this.props.setNotFoundPage(true)
        }
    }

    componentDidMount() {
        this.redirectIfNotFoundPage()
    }

    componentDidUpdate() {
        this.redirectIfNotFoundPage()
    }

    render() {
        const {groupModel, technical} = this.state
        return groupModel || technical
            ? this.props.children({groupModel})
            :  null

    }
}

const mapStateToProps = state => ({
    groupModels: selectors.getUserGroupModels(state),
    isAccountManager: selectors.isAccountManager(state),
    isUserK: selectors.isUserK(state),
    //userId: selectors.getUser().id
})

const mapDispatchToProps = {
    setNotFoundPage
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundRoute)
