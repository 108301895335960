export const refundTemplatesDirectoryPath = ln => global.isProd
    ? `buildServer/server/models/fncs/mails/templates/${ln}/refund`
    : `src/server/models/fncs/mails/templates/${ln}/refund`
export const invoiceTemplatesDirectoryPath = ln => global.isProd
    ? `buildServer/server/models/fncs/mails/templates/${ln}/invoice`
    : `src/server/models/fncs/mails/templates/${ln}/invoice`

export const reallocationTemplatesDirectoryPath = ln => global.isProd
    ? `buildServer/server/models/fncs/mails/templates/${ln}/reallocation`
    : `src/server/models/fncs/mails/templates/${ln}/reallocation`
