import React from 'react'
import PropTypes from 'prop-types'
import {Check, X} from 'react-feather'
import './BooleanCell.css'

const svgProps = {
    className: "DataTable-BooleanCell",
    size: 18
}

export const FailureIndicator = ({ value }) => value ? <X {...svgProps} style={{color: 'red'}} /> : ''

FailureIndicator.propTypes = {
    value: PropTypes.bool.isRequired
}
