import dd from './../default-engine/engine'
import _ from 'lodash'
import {OBJECT_FORM} from '../../apps/KpModule/components/Form'
import { change as reduxFormChange } from 'redux-form'
import {BATCHED_API_SUCCESS} from '../../middleware/api'

function stateSubscriptionFromField(field, formName) {
    return field.subscriptions && field.subscriptions.onChange && {
        statePath: `form.${formName}.values.${field.path}`,
        subscription: field.subscriptions.onChange
    }
}

function extractModuleStateSubscriptions(module) {
    const formFields = _.get(module, 'viewMap.form.fields', [])

    return _(formFields)
        .flatMap(field => _.flatten([
            stateSubscriptionFromField(field, OBJECT_FORM),
            field.stateSubscriptions
        ]))
        .compact()
        .value()
}

function extractAccordionStateSubscriptions(accordionField) {
    return _.get(accordionField, 'viewMap.form.fields', [])
        .flatMap(field => {
            if(field.type === 'accordion') {
                return extractAccordionStateSubscriptions(field)
            }
            return stateSubscriptionFromField(field, `${accordionField.path}${_.upperFirst(OBJECT_FORM)}`)
        })
        .filter(stateSubscription => stateSubscription)
}

function extractModuleActionSubscriptions(module) {
    const onLoad = _.get(module, 'viewMap.form.onLoad')

    return onLoad ? [{
        actionType: BATCHED_API_SUCCESS,
        subscription: onLoad
    }] : []
}

function fieldWithList(field) {
    return _.includes(['dropdownObject', 'tags'], field.type)
}

const getFieldValue = (formName, store) => field => {
    const state = store.getState()
    return _.get(state, `form.${formName}.values.${field.path}`)
}

const setFieldValue = (formName, store) => (value, field) =>
    store.dispatch(reduxFormChange(formName, field.path, value))

function normalizeClientModule(module, store) {
    const moduleNorm = {
        name: 'clientNorm',
        fields: [
            { path: 'clientEnhanced', default: true },
            {
                path: 'viewMap',
                fields: [
                    {path: 'dt', $if: dt => dt, fields: [
                        {path: 'fields', fields: [
                            {path: 'id', $p: {$u: (id, field) => `l_${field.path}`}}
                        ]},
                        {path: 'onLoad', default: () => {}}
                    ]},
                    {path: 'form', $if: form => form, fields: [
                        {path: 'reduxFormName', default: OBJECT_FORM},
                        {path: 'getFieldValue', $default: form => getFieldValue(form.reduxFormName, store)},
                        {path: 'setFieldValue', $default: form => setFieldValue(form.reduxFormName, store)},
                        {path: 'fields', fields: [
                            {path: 'id', $p: {$u: (id, field) => `e_${field.path}`}},
                            {path: 'setValue', $default: (field, fields, form) => value => form.setFieldValue(value, field)},
                            {path: 'getValue', $default: (field, fields, form) => () => form.getFieldValue(field)},
                            {path: 'getList', $if: (getList, field) => fieldWithList(field), $default: () => () => {
                                console.warn('getList not yet implemented!')
                                // const state = store.getState()
                                // return getField(state, field.id).list.allIds
                            }},
                            {path: 'setList', $if: (getList, field) => fieldWithList(field), $default: () => () => {
                                console.warn('setList not yet implemented!')
                                // store.dispatch(setEditListOptions(field.path, ids))
                            }},
                            {path: 'viewMap', fields: [
                                {path: 'dt', $if: dt => dt, fields: [
                                    {path: 'fields', fields: [
                                        {path: 'id', $p: {$u:
                                            (id, field, fields, dt, viewMap, parentField) => `${parentField.id}.l_${field.path}`
                                        }}
                                    ]}
                                ]},
                                {path: 'form', $if: form => form, fields: [
                                    {path: 'reduxFormName', $default: (form, viewMap, accordionField) => accordionField.path + _.upperFirst(OBJECT_FORM)},
                                    {path: 'getFieldValue', $default: form => getFieldValue(form.reduxFormName, store)},
                                    {path: 'setFieldValue', $default: form => setFieldValue(form.reduxFormName, store)},
                                    {path: 'fields', fields: [
                                        {path: 'id', $p: {$u:
                                            (id, field, fields, dt, viewMap, parentField) => `${parentField.id}.e_${field.path}`
                                        }},
                                        {path: 'setValue', $default: (field, fields, form) => value => form.setFieldValue(value, field)},
                                        {path: 'getValue', $default: (field, fields, form) => () => form.getFieldValue(field)},
                                        {path: 'viewMap', fields: [
                                                {path: 'dt', $if: dt => dt, fields: [
                                                        {path: 'fields', fields: [
                                                                {path: 'id', $p: {$u:
                                                                            (id, field, fields, dt, viewMap, parentField) => `${parentField.id}.l_${field.path}`
                                                                    }}
                                                            ]}
                                                    ]},
                                                {path: 'form', $if: form => form, fields: [
                                                        {path: 'reduxFormName', $default: (form, viewMap, accordionField) => accordionField.path + _.upperFirst(OBJECT_FORM)},
                                                        {path: 'getFieldValue', $default: form => getFieldValue(form.reduxFormName, store)},
                                                        {path: 'setFieldValue', $default: form => setFieldValue(form.reduxFormName, store)},
                                                        {path: 'fields', fields: [
                                                                {path: 'id', $p: {$u:
                                                                            (id, field, fields, dt, viewMap, parentField) => `${parentField.id}.e_${field.path}`
                                                                    }},
                                                                {path: 'fields', fields: [
                                                                        {path: 'id', $p: {$u:
                                                                                    (id, field, fields, parentField) => `${parentField.id}.l_${field.path}`
                                                                            }}
                                                                    ]},
                                                                {path: 'setValue', $default: (field, fields, form) => value => form.setFieldValue(value, field)},
                                                                {path: 'getValue', $default: (field, fields, form) => () => form.getFieldValue(field)}
                                                            ]}
                                                    ]},
                                            ]},
                                        {path: 'fields', fields: [
                                            {path: 'id', $p: {$u:
                                                (id, field, fields, parentField) => `${parentField.id}.l_${field.path}`
                                            }}
                                        ]},
                                    ]}
                                ]},
                            ]},
                            {path: 'fields', fields: [
                                {path: 'id', $p: {$u:
                                    (id, field, fields, parentField) => `${parentField.id}.l_${field.path}`
                                }}
                            ]}
                        ]},
                        {path: 'onOpen', default: () => {}}
                    ]},
                    {path: 'chart', $if: chart => chart, fields: [
                        {path: 'fields', fields: [
                                {path: 'id', $p: {$u: (id, field) => `c_${field.path}`}}
                            ]}
                    ]}
                ]
            },
            {
                path: 'filters',
                fields: [{ path: 'id', $default: filter => `f_${filter.path}` }]
            },
            {
                path: 'filters.filters',
                fields: [{ path: 'id', $default: filter => `f_${filter.path}` }]
            },
            {
                path: 'viewMap.form.fields.stateSubscriptions',
                if: field => field.type === 'accordion',
                $default: extractAccordionStateSubscriptions
            },
            {
                path: 'stateSubscriptions',
                default: [],
                $p: {$u: (stateSubscriptions, module) => [
                    ...stateSubscriptions,
                    ...extractModuleStateSubscriptions(module)
                ]}},
            {
                path: 'actionSubscriptions',
                default: [],
                $p: {$u: (actionSubscriptions, module) => [
                    ...actionSubscriptions,
                    ...extractModuleActionSubscriptions(module)
                ]}
            }
        ]
    }

    dd.normalize(module, moduleNorm, { throw: true })

    return module
}

export default normalizeClientModule
