import {setFieldVisibility} from "../../../../../apps/KpModule/actions";

export const module_ = {
    name: 'Decision',
    tKey: 'Décision CA',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            {path: 'status', translate: true}
        ],
        form: {
            fields: [
                {path: 'object', editable: false},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', editable: false},
                {
                    path: 'needs',
                    tKey: 'Besoins',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount'},
                            { path: 'requestedAmount'},
                            { path: 'awardedAmount'},
                            { path: 'form', translate: true }
                        ],
                        form: [
                            { path: 'natureOfHelp', editable: false },
                            { path: 'totalAmount', editable: false },
                            { path: 'requestedAmount', editable: false },
                            { path: 'awardedAmount', required: true, positiveWholeNumber: true },
                            { path: 'form', required: true, translate: true },
                        ]
                    }
                },
                {
                    path: 'currentLiberalities',
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            store.dispatch(setFieldVisibility('e_currentSuccessionsName', !!newValue))
                            store.dispatch(setFieldVisibility('e_estimatedAmount', !!newValue))
                        }
                    }
                },
                {path: 'currentSuccessionsName', editable: false,},
                {path: 'estimatedAmount', editable: false,},
                {path: 'files', tKey: 'Fichiers', editable: false},
                {path: 'comments', tKey: 'Commentaires'},
                {path: 'studyCommitteeComment', type: 'richTextEditor', editable: false},
                {path: 'decision', tKey: 'boardOfDirectorsDecision', type: 'richTextEditor'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'submitterHasShelters', hidden: true},
                {path: 'buttons', hidden: true}
            ]
        }
    },
    filters: [
        'userHasAuthorization',
        {
            name: 'byStatusForDecision',
            query: () => {
                return { status: 'controlled'}
            }
        }
    ]
}
