import _ from 'lodash'
import {
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS, LOGIN_CONFIRMATION_REQUEST,
    LOGIN_CONFIRMATION_SUCCESS,
    VIEWER_SUCCESS
} from '../actions/index'

const user = (
    state = {
        id: null,
        isLogging: false
    },
    action
) => {
    switch (action.type) {
        case VIEWER_SUCCESS:
            return {
                ...state,
                id: action.data.result
            }
        case LOGIN_REQUEST:
        case LOGIN_CONFIRMATION_REQUEST:
            return {
                ...state,
                id: null,
                isLogging: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLogging: false,
                ...action.data.user
            }
        case LOGIN_CONFIRMATION_SUCCESS:
            localStorage.setItem('browserId', _.get(action, 'data.browserId'))
            return {
                ...state,
                isLogging: false,
                id: _.get(action, 'data.user.id')
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                id: null,
                isLogging: false
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                id: null,
                isLogging: false
            }
        default:
            return state
    }
}

export default user
