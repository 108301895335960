const _ = require('lodash')

export const entities = [
    {
        name: 'LeaseCondition',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'code', 'name'],
        objects: [
            { id: 'fixLease', code: 'FIXE', name: 'fixedRentOnAnnualBasis' },
            { id: 'leaseThreshold1', code: 'SEUIL_1', name: 'turnoverThreshold1' },
            { id: 'leaseRate1', code: 'TAUX_1', name: 'turnoverRate1' },
            { id: 'leaseThreshold2', code: 'SEUIL_2', name: 'turnoverThreshold2' },
            { id: 'leaseRate2', code: 'TAUX_2', name: 'turnoverRate2' },
            { id: 'leaseThreshold3', code: 'SEUIL_3', name: 'turnoverThreshold3' },
            { id: 'leaseRate3', code: 'TAUX_3', name: 'turnoverRate3' },
            { id: 'leaseMinimum', code: 'MINI', name: 'guaranteedRent' },
            { id: 'leaseMaximum', code: 'MAXI', name: 'rentLimit' }
        ]
    },
    {
        name: 'LeaseRenewal',
        type: 'static',
        fields: [
            { path: 'id', type: 'integer' },
            'code',
            'name',
            'convenience'
        ],
        objects: [
            {
                id: 100,
                code: 'initial',
                name: 'initial',
                convenience:
                    'assetsDepreciationsOverInitialPeriod'
            },
            {
                id: 101,
                code: 'renewal1',
                name: 'Renouvellement 1',
                convenience:
                    "renewal1Question"
            },
            {
                id: 102,
                code: 'renewal2',
                name: 'Renouvellement 2',
                convenience:
                    "renewal2Question"
            },
            {
                id: 103,
                code: 'renewal3',
                name: 'Renouvellement 3',
                convenience:
                    "renewal3Question"
            }
        ]
    },
    {
        name: 'GeneralCharacteristicType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'rightToLease', name: 'rightToLease' },
            { id: 'guaranteeDeposit', name: 'guaranteeDeposit' },
            { id: 'bankGuarantee', name: 'bankGuarantee' },
            {
                id: 'restorationOfPremises',
                name: 'restorationOfPremises'
            }
        ]
    },
    {
        name: 'LeaseElement',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'm1', name: 'M1CurrentStoreContinuity' },
            { id: 'm2', name: 'M2NewStore' }
        ]
    },
    {
        name: 'LeaseType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'code', 'name'],
        objects: [
            { id: 'principal', code: 'MAIN', name: 'mainStore'},
            { id: 'otherLocal1', code: 'OTHER_1', name: 'otherLocal1' },
            { id: 'otherLocal2', code: 'OTHER_2', name: 'otherLocal2' },
            { id: 'temporaryStore', code: 'TEMPORARY', name: 'temporaryStore' },
            { id: 'leaseImpact', code: 'IMPACT_M1_M2', name: 'impactM1M2' }
        ]
    },
    {
        name: 'LeaseRenewalsNumber',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name', {path: 'value', type: 'integer'}],
        objects: [
            { id: '0', name: '0', value: 0},
            { id: '1', name: '1', value: 1 },
            { id: '2', name: '2', value: 2 },
            { id: '3', name: '3', value: 3 }
        ]
    },
    {
        name: 'LeaseDuration',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'gtOrEq10Y', name: 'gtOrEq10Y' },
            { id: 'lt10Y', name: 'lt10Y' }
        ]
    },
    {
        name: 'ImportExportType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'imp', name: 'import' },
            { id: 'exp', name: 'export' }
        ]
    },
    {
        name: 'ProjectConfigurationStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'ongoing' },
            { id: 'submitted', name: 'submitted' },
            { id: 'endorsed', name: 'endorsed' }
        ]
    },
    {
        name: 'StateModelYear',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            { path: 'order', type: 'integer' },
            { path: 'name', type: 'string' },
            { path: 'value', type: 'integer' }
        ],
        objects: [
            { id: '-5', order: 1, name: 'year-5', value: -5 },
            { id: '-4', order: 2, name: 'year-4', value: -4 },
            { id: '-3', order: 3, name: 'year-3', value: -3 },
            { id: '-2', order: 4, name: 'year-2', value: -2 },
            { id: '-1', order: 5, name: 'year-1', value: -1 },
            { id: '0', order: 6, name: 'year', value: 0 },
            { id: '1', order: 7, name: 'year+1', value: 1 },
            { id: '2', order: 8, name: 'year+2', value: 2 },
            { id: '3', order: 9, name: 'year+3', value: 3 },
            { id: '4', order: 10, name: 'year+4', value: 4 },
            { id: '5', order: 11, name: 'year+5', value: 5 },
            { id: '6', order: 12, name: 'year+6', value: 6 },
            { id: '7', order: 13, name: 'year+7', value: 7 },
            { id: '8', order: 14, name: 'year+8', value: 8 },
            { id: '9', order: 15, name: 'year+9', value: 9 },
            { id: '10', order: 16, name: 'year+10', value: 10 }
        ],
        sorters: [
            {
                name: 'byOrder',
                query: () => ({ order: 1 })
            }
        ]
    },
    {
        name: 'Format',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'normal', name: 'unitary' },
            { id: 'percent', name: 'percent' },
            { id: 'thousand', name: 'thousand' },
            { id: 'million', name: 'million' },
            { id: 'date', name: 'date' },
            { id: 'text', name: 'text' }
        ]
    },
    {
        name: 'ModelFormat',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'kdev', name: 'kdev' },
            { id: 'integer', name: 'integer' },
            { id: 'percent2', name: 'percent2' },
            { id: 'real2', name: 'real2' },
            { id: 'real3', name: 'real3' },
            { id: 'real4', name: 'real4' },
            { id: 'text', name: 'text' }
        ]
    },
    {
        name: 'Precision',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '0', name: '0' },
            { id: '1', name: '1' },
            { id: '2', name: '2' },
            { id: '3', name: '3' },
            { id: '4', name: '4' },
            { id: '5', name: '5' },
            { id: '6', name: '6' }
        ]
    },
    {
        name: 'Kind',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            // { id: 'shop', name: 'shop' },
            // { id: 'project', name: 'project' },
            // { id: 'projectDetail', name: 'projectDetail' }

            { id: '1', name: 'subsidiary' },
            { id: '5', name: 'distribution' },
            { id: '9', name: 'store' },
            { id: '3', name: 'bpValProjectDetails' },
            { id: '7', name: 'bpStoreProjectDetails' },
            { id: '2', name: 'bpValProject' },
            { id: '6', name: 'bpStoreProject' },
            { id: '4', name: 'plan+bpValEndorsedProjects' },
            { id: '8', name: 'country' }
        ]
    },
    {
        name: 'AccountModelType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'year', name: 'year' },
            { id: 'value', name: 'value' }
        ]
    },
    {
        name: 'AccountModelLoop',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            // { id: 'mainStore', name: 'Période du bail Magasin principal' },
            // { id: 'otherLocal1', name: 'Période du bail Autre local 1' },
            // { id: 'otherLocal2', name: 'Période du bail Autre local 2' },
            // {
            //     id: 'temporaryShop',
            //     name: 'Période du bail Magasin temporaire'
            // }

            { id: '1', name: 'Pas de boucle' },
            { id: '5', name: 'mainLeasePeriod' },
            { id: '6', name: 'otherLocal1LeasePeriod' },
            { id: '7', name: 'otherLocal2LeasePeriod' },
            { id: '8', name: 'Période de bail magasin temporaire' }
            /*
            { id: '2', name: 'product' },
            { id: '3', name: 'product-workshop' },
            { id: '4', name: 'product-matter' },

             */
        ]
    },
    {
        name: 'ModelStyle',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'normal', name: 'normal' },
            { id: 'normal-', name: 'normal-' },
            { id: 'important', name: 'important' },
            { id: 'important+', name: 'important+' },
            { id: 'informative', name: 'informative' },
            { id: 'informative+', name: 'informative+' },
            { id: 'title', name: 'title' },
            { id: 'header', name: 'header' },
            { id: 'toCorrect', name: 'toCorrect' }
        ]
    },
    {
        name: 'BPProjectType',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            'name'
        ],
        objects: [
            { id: 'N', name: 'NNew' },
            { id: 'R', name: 'RRenovation' },
            { id: 'A', name: 'AEnlargement' },
            { id: 'D', name: 'DRelocation' },
            { id: 'S', name: 'SConcessionTakeover' },
            { id: 'N+C', name: 'NCNewWithCannibalization' }
        ]
    },
    {
        name: 'HypothesisType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'hypothesis', name: 'Hypothèse' },
            { id: 'account', name: 'Compte' }
        ]
    },
    {
        name: 'HypothesisKind',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', name: 'everyYear' },
            { id: '2', name: 'firstYear' },
            { id: '3', name: 'exceptFirstYear' },
            { id: '5', name: 'value' }
        ]
        //
        // Y_ALL               ("1"),
        // Y_FIRST             ("2"),
        // Y_ALL_BUT_FIRST     ("3"),
        // VALUE               ("4"),
        // Y_TITLE             ("5"),
        // V_TITLE             ("6");
    },
    {
        name: 'HypothesisScope',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: '1', name: 'M2&M1' },
            { id: '2', name: 'M2' },
            { id: '3', name: 'M1' }
        ]
    },
    {
        name: 'ProjectContextM1M2',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            'code',
            'name',
            {path: 'order', type: 'integer'}
        ],
        objects: [
            { id: '1', code: 'S2', name: 'M2NewStore', order: 1},
            {
                id: '0',
                code: 'S1',
                name: 'M1CurrentStoreContinuity',
                order: 2
            },
            { id: '2', code: 'S2S1', name: 'M2M1Difference', order: 3}
        ],
        filters: [
            {
                name: 'withoutDiff',
                isDefault: false,
                match: o => o.id !== '2'
            },
            {
                name: 'onlyForBPStore',
                isDefault: false,
                query: context => {
                    const modelFolderType = _.get(context , "data.modelFolderType");

                    return modelFolderType === 'bpstore'
                        ? {}
                        : {_id: null}
                }
            },
            {
                name: 'byProjectType',
                isDefault: false,
                query: context => {
                    const projectIsNOrR = _.get(context , "data.projectContextM1M2");

                    return projectIsNOrR === 'true'
                        ? {code: 'S2'}
                        : {}
                }
            }
        ],
        sorters: [
            {
                name: 'byOrder',
                isDefault: true,
                query: () => ({ order: 1 })
            }
        ]
    },
    {
        name: 'OuterLoop',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: []
    },
    {
        name: 'InnerLoop',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: []
    },
    {
        name: 'Impact',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: []
    },
    {
        name: 'ModelFolderType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'bpstore', name: 'bpstore' },
            { id: 'empiric', name: 'empiric' }
        ]
    }
]
