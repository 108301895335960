const _ = require('lodash')

export const module_ = {
    object: 'Refund',
    name: 'refundKpModule',
    kp: true,
    tKey: 'KP-Remboursement',
    newable: false,
    removable: false,
    displayLog: false,
    defaultSortBy: 'updated',
    defaultSortDirection: 'ASC',
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: 'reference', tKey:'number2'},
            {path: 'user', tKey: 'beneficiary', display: 'fullName'},
            {path: 'userRegistrationNumber', tKey: 'registrationNumber', initiallyNotVisible: true},
            'benefit',
            {path: 'date', tKey: 'dateOfInvoice'},
            {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true},
            {path: 'refund', tKey: 'refundAbbreviated', tooltip: true},
            {path: 'beneficiaries', initiallyNotVisible: true, display: 'fullName'},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            'updated'
        ],
        form: {
            fields: [
                {path: 'reference', tKey:'number2', disabled: true},
                {path: 'user', tKey: 'beneficiary', display: 'fullName', disabled: true},
                {path: 'benefit', editable: false},
                {path: 'date', type: 'datePicker', tKey: 'dateOfInvoice', editable: false},
                {path: 'currencyAmount', editable: false},
                {path: 'rate', tKey: 'currencyRate', editable: false},
                {path: 'amount', tKey: 'amount(€)', editable: false},
                {path: 'beneficiaries', editable: false, display: 'fullName'},
                {path: 'files', tKey: 'supportingDocuments', editable: false},
                'comments',
                {path: 'status', hidden: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
        }
    },
    filters: [
        {
            title: "Status",
            path: "status",
            tKey: "status",
            type: 'tags',
            default: [
                {id: 'validated'}
            ],
            object: "RefundStatus",
            display: "name",
            client: true,
            width: 12,
            isDefault: false,
            query: function (context) {
                const statusArray = _.get(context, "data.status")

                return statusArray
                    ? {status: {$in: statusArray.map(o => o.id)}}
                    : {}
            }
        }
    ]
}
