import {translateName} from "../../../../../utils/i18n";
import {basicContext} from "../../../../utils/contextUtils";

const _ = require('lodash')

const getElementFullName = (object, context) => {
    if (object.joinedEntity === 'Organization') {
        return `${translateName(object.organizationalAxis.name, _.get(context, "language.id"))} : ${object.name}`
    } else {
        return ` Business unit : ${object.name}  ( ${(object.attachments || []).map( att => att.name).join(', ')} )`
    }
}

export const entity = require('../../../../utils/entityJoin').joinEntities(
    {
        name: 'OrganizationAndMesh',
        fields: [
            'name',
            {
                path: 'fullName',
                fieldPath: ['organizationalAxis.name', 'attachments.name'],
                $f: (object, context, callback) => {
                    callback(null, getElementFullName(object, context))
                }
            }
        ],
        filters: [
            {
                name: 'inWorkflowEnabledAxe',
                isDefault: false,
                fieldPath: ['organizationalAxis.workflowEnabledAxe'],
                match: function(object) {
                    const isInWorkflowEnabledAxe = !!_.get(object, 'organizationalAxis.workflowEnabledAxe')
                    return isInWorkflowEnabledAxe && (object.joinedEntity === 'Organization')
                }
            },
            { //verified
                name: "byProjectType",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    const projectTypes = _.get(context.data, 'projectTypes')
                    console.log('projectTypeID', projectTypes)
                    const ongoingValidationStatusesIds = ['1', '2', '3', '4', '5', '10', '11', '12']
                    const postValidationStatusesIds = ['6', '7', '8']
                    //we gonna treat 9 (abandoned differently)
                    const abandonedStatusQuery1 = {
                        category: "1",
                        status: '9',
                        revisedTable: {$exists: true},
                    }
                    const abandonedStatusQuery2 = {
                        category: "1",
                        status: '9',
                        revisedTable: {$exists: false}
                    }
                    const query = !!projectTypes && !!projectTypes.length && projectTypes.length === 1 ?
                        projectTypes[0].id === '2' ?
                            {$or: [{category: "1", status: {$in: ongoingValidationStatusesIds}}, abandonedStatusQuery2]}
                            : {$or: [{category: "1", status: {$in: postValidationStatusesIds}}, abandonedStatusQuery1]}
                        : {}

                    /*
                    const projects = await global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {
                            category: '1',
                            ...query
                        }
                    })


                     */
                    global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: ['imputations'],
                        query
                    }, (e, projects) => {
                        if (e) return callback(e)
                        let orgnizationsAndMeshesIDs = []
                        projects.forEach(project =>{
                            orgnizationsAndMeshesIDs.push(global.ObjectID(project.organizationAndMesh.id))
                            project.imputations.forEach(imputation => {
                                orgnizationsAndMeshesIDs.push(global.ObjectID(imputation.organizationalMesh.id))
                                imputation.organizationalMesh.attachments.forEach(organization =>{
                                    orgnizationsAndMeshesIDs.push(global.ObjectID(organization.id))
                                })
                            })
                        })
                        callback (null, {_id: {$in: orgnizationsAndMeshesIDs}})
                    })

                }
            },
        ]
    },
    ['Organization', 'OrganizationalMesh']
)
