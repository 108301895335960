const { setFieldVisibility, setFieldListOptions } = require('../../../../apps/KpModule/actions')
const {generateFetchFieldListAction} = require("../../../../apps/KpModule/actions/api");

export const entities = [
    {
        name: 'HypothesisModel',
        facets: ['translatedField', { name: 'codeName' }],
        fields: [
            { type: 'LeaseType', nullable: true },
            {
                path: 'hypothesisModelLine',
                type: 'HypothesisModelLine',
                link: 'OTM'
            },
            {
                path: 'translatedFullName',
                $f:(object, context, callback) => {
                    const translatedName = context.translateName
                        ? context.translateName(object.name)
                        : object.name
                    callback(null, `${object.code}-${translatedName}`)
                }
            }
        ],
        validateSave: function(newObject, oldObject, context, callback) {
            if(
                newObject.hypothesisModelLine &&
                newObject.hypothesisModelLine.some(hml => {
                    let haveWrongDataType = false
                    switch (hml.hypothesisScope.id) {
                        case '3':
                            haveWrongDataType = !(hml.dataTypeM1.length > 0 && hml.dataTypeM2.length === 0)
                            break
                        case '2':
                            haveWrongDataType = !(hml.dataTypeM2.length > 0 && hml.dataTypeM1.length === 0)
                            break
                        case '1':
                            haveWrongDataType = !(hml.dataTypeM2.length > 0 && hml.dataTypeM1.length > 0)
                            break
                    }

                    return hml.hypothesisType.id === 'hypothesis' && haveWrongDataType
                })) {
                return callback(new Error('shouldHaveAtLeastOneDataType'))
            }
            callback()
        }
    },
    {
        name: 'HypothesisModelLine',
        facets: ['translatedField'],
        fields: [
            'code',
            'name',
            { type: 'HypothesisType', default: { id: 'hypothesis' }, nullable: true},
            { type: 'AccountModel', nullable: true },
            { path: 'order', type: 'integer' },
            { type: 'HypothesisKind', nullable: true},
            'ModelStyle',
            'HypothesisScope',
            { path: 'dataTypeM2', type: 'DataType', link: 'MTM', nullable: true},
            { path: 'dataTypeM1', type: 'DataType', link: 'MTM', nullable: true },
            { path: 'poles', type: 'Pole', link: 'MTM', nullable: true },
            { path: 'initialize', type: 'AccountModel', nullable: true},
            { path: 'active', type: 'boolean', default: true}
        ]
    }
]

export const module_ = {
    name: 'HypothesisModel',
    object: 'HypothesisModel',
    tKey: 'mTitle_hypothesisModel',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: ['code', { path: 'name', type: 'translatedText' }],
        form: [
            'code',
            { path: 'name', type: 'textarea' },
            {
                path: 'leaseType',
                subscriptions: {
                    onChange: (
                        newValue,
                        oldValue,
                        {formInitialize, module, store}
                    ) => {
                        store.dispatch(
                            setFieldVisibility(
                                'e_hypothesisModelLine',
                                !(newValue && newValue.id)
                            )
                        )
                    }
                }
            },
            {
                path: 'hypothesisModelLine',
                tKey: 'model',
                removable: true,
                defaultSortBy: 'order',
                defaultSortDirection: 'ASC',
                viewMap: {
                    dt: [
                        'hypothesisType',
                        'code',
                        { path: 'name', type: "translatedText" },
                        'order',
                        'hypothesisKind',
                        'active'
                    ],
                    form: [
                        {
                            path: 'hypothesisType',
                            clearable: false,
                            subscriptions: {
                                onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                                    if (
                                        newValue &&
                                        newValue.id
                                    ) {

                                        if(newValue.id === 'hypothesis') {
                                            const hypothesisKindField = module.viewMap.form.fields.find(
                                                field => field.path === 'hypothesisModelLine'
                                            ).viewMap.form.fields.find(
                                                field => field.path === 'hypothesisKind'
                                            )

                                            store.dispatch(
                                                generateFetchFieldListAction(
                                                    'm-B-hypothesisModel.HypothesisModelLine_initialize',
                                                    store.getState,
                                                    'form',
                                                    {data : {hypothesisKind: hypothesisKindField.getValue()}}
                                                )
                                            )
                                        } else if(newValue.id === 'account') {
                                            store.dispatch(setFieldListOptions('e_hypothesisModelLine.e_initialize', []))
                                        }

                                        const fields = [
                                            'e_hypothesisModelLine.e_code',
                                            'e_hypothesisModelLine.e_hypothesisKind',
                                            'e_hypothesisModelLine.e_dataTypeM2',
                                            'e_hypothesisModelLine.e_dataTypeM1',
                                            'e_hypothesisModelLine.e_initialize'
                                        ]

                                        fields.forEach(fieldId => store.dispatch(
                                            setFieldVisibility(
                                                fieldId,
                                                newValue.id === 'hypothesis'
                                            )
                                        ))
                                        store.dispatch(
                                            setFieldVisibility(
                                                'e_hypothesisModelLine.e_accountModel',
                                                newValue.id === 'account'
                                            )
                                        )

                                    }
                                }
                            }
                        },
                        // required: true
                        { path: 'code', required: true },
                        {
                            path: 'accountModel',
                            display: 'fullName',
                            filters: ['onlyKindProjectBpStoreAndBpStoreDetails'],
                            required: true
                        },
                        { path: 'name', type: 'textarea', required: true },
                        { path: 'order', required: true, wholeNumber: true},
                        { path: 'hypothesisScope' },
                        { path: 'modelStyle' },
                        { path: 'poles' },
                        {
                            path: 'hypothesisKind',
                            required: true,
                            subscriptions: {
                                onChange: (newValue, oldValue, { module, store }) => {
                                    if (newValue && newValue.id) {
                                        store.dispatch(
                                            generateFetchFieldListAction(
                                                'm-B-hypothesisModel.HypothesisModelLine_initialize',
                                                store.getState,
                                                'form',
                                                {data: {hypothesisKind: {id: newValue.id}}}
                                            )
                                        )
                                    } else {
                                        store.dispatch(setFieldListOptions('e_hypothesisModelLine.e_initialize', []))
                                    }
                                }
                            }
                        },
                        {
                            path: 'dataTypeM2', display: 'name', translateName: true,
                            subscriptions: {
                                onChange: (newValue, oldValue, { module, store }) => {
                                    if (newValue && newValue.length) {
                                        const initializeField = module.viewMap.form.fields.find(
                                            field => field.path === 'hypothesisModelLine'
                                        ).viewMap.form.fields.find(
                                            field => field.path === 'initialize'
                                        )
                                        const dataTypeM1Field = module.viewMap.form.fields.find(
                                            field => field.path === 'hypothesisModelLine'
                                        ).viewMap.form.fields.find(
                                            field => field.path === 'dataTypeM1'
                                        )

                                        const dataTypeM1Values = dataTypeM1Field.getValue()

                                        let visible = true
                                        if(
                                            newValue.length > 1 ||
                                            (
                                                newValue.length === 1 &&
                                                dataTypeM1Values.length &&
                                                (
                                                    !dataTypeM1Values.map(o => o.id).includes(newValue[0].id) ||
                                                    dataTypeM1Values.length > 1
                                                )
                                            )
                                        ) {
                                            initializeField.setValue({id: null})
                                            visible = false
                                        }

                                        store.dispatch(
                                            setFieldVisibility(
                                                'e_hypothesisModelLine.e_initialize',
                                                visible
                                            )
                                        )
                                    }
                                }
                            }
                        },
                        {
                            path: 'dataTypeM1', display: 'name', translateName: true,
                            subscriptions: {
                                onChange: (newValue, oldValue, { module, store }) => {
                                    if (newValue && newValue.length) {
                                        const initializeField = module.viewMap.form.fields.find(
                                            field => field.path === 'hypothesisModelLine'
                                        ).viewMap.form.fields.find(
                                            field => field.path === 'initialize'
                                        )
                                        const dataTypeM2Field = module.viewMap.form.fields.find(
                                            field => field.path === 'hypothesisModelLine'
                                        ).viewMap.form.fields.find(
                                            field => field.path === 'dataTypeM2'
                                        )

                                        const dataTypeM2Values = dataTypeM2Field.getValue()

                                        let visible = true
                                        if(
                                            newValue.length > 1 ||
                                            (
                                                newValue.length === 1 &&
                                                dataTypeM2Values.length &&
                                                (
                                                    !dataTypeM2Values.map(o => o.id).includes(newValue[0].id) ||
                                                    dataTypeM2Values.length > 1
                                                )
                                            )
                                        ) {
                                            initializeField.setValue({id: null})
                                            visible = false
                                        }
                                        store.dispatch(
                                            setFieldVisibility(
                                                'e_hypothesisModelLine.e_initialize',
                                                visible
                                            )
                                        )
                                    }
                                }
                            }
                        },
                        {
                            path: 'initialize',
                            display: 'fullName'
                            //filters: ['filterAccountModelForHypothesisModel']
                        },
                        'active'
                        //{ path: 'active', default: true }
                    ]
                }
            }
        ]
    }
}
