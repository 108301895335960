import {setFieldVisibility} from "../../../../apps/KpModule/actions";
import {addFloats, monthRangeToList} from "../utils/usefulFunctions";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";
import {generateBPStoreFolder} from "../../bpshop/utils/bPStoreExportXlsx";
import moment from "moment/moment";
import Errors from "../../../utils/Errors";

export const entities = [
    {
        name: 'ExtractedFiles',
        facets: ['files'],
        fields: [
            //N demand - Objet - Budget - Type imputation - date - type : e, r, ou f - montant
            //Demand = N demand
            //imputation = Objet - Budget - Type imputation
            //date : 'YYYY-MM-DD'
            //type : e, r, ou f
            //montant
            {path: 'fileName', unique: true},
            {
                path: 'fileLines',
                type: 'FileLines',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            },
            {
                path: 'numberOfExtractedLines',
                f: function(){
                    return this.fileLines.length
                }
            }
            ],
        beforeSave: async function processCsv(newObject, oldObject, context, callback) {
            const action = context.restAction && context.restAction.crudType
                try {
                    if (action === 'C'){
                        const fileLines = []
                        const input = global.gfs.openDownloadStream(global.ObjectID(newObject.files[0].id));
                        const csvParser = global.csvParse({ delimiter: ';', columns: true, rtrim: true, ltrim: true });
                        const parsedStream = input.pipe(csvParser)
                        const completeData = []
                        const filledDemands = new Set()
                        const filledBUCodes = new Set()
                        const filledAnalyticalCodes = new Set()
                        //const filledBudgetsIdentifiers = new Set()
                        const filledImputationTypes = new Set()
                        const filledRealizationTypes = new Set()
                        const filledCurrencies = new Set()

                        parsedStream.on('data', (data)=>{
                            completeData.push(data)
                            const dataKeys = Object.keys(data)
                            const demandNumberKey = dataKeys[0]
                            const codeBUKey = dataKeys[2]
                            const analyticalMeshKey = dataKeys[3]
                            const imputationTypeKey = dataKeys[4]
                            const typeKey = dataKeys[6]
                            const currencyKey = dataKeys[7]

                            filledDemands.add(data[demandNumberKey])
                            filledBUCodes.add(data[codeBUKey])
                            filledAnalyticalCodes.add(data[analyticalMeshKey])
                            //filledBudgetsIdentifiers.add(data['Budget'])
                            filledImputationTypes.add(data[imputationTypeKey])
                            filledRealizationTypes.add(data[typeKey])
                            filledCurrencies.add(data[currencyKey])
                            console.log("completeData", completeData.length)
                        })
                        parsedStream.on('end', async ()=>{
                            const correspondentDemands = await global.app.SE.Demand.find({
                                ...basicContext(context),
                                fieldPath: ['demandNumber', 'imputations', 'relatedProject.id', 'relatedProject.demandNumber'],
                                query: {demandNumber: {$in: Array.from(filledDemands)}}
                            })

                            const budgetBUs = await global.app.SE.OrganizationalMesh.find({
                                ...basicContext(context),
                                fieldPath: [],
                                query: {code: { $in: Array.from(filledBUCodes) }}
                            })

                            const budgetAnalyticalMeshes = await global.app.SE.AnalyticalMesh.find({
                                ...basicContext(context),
                                fieldPath: [],
                                query: {code: { $in: Array.from(filledAnalyticalCodes) }}
                            })

                            const correspondentBudgets = await global.app.SE.Budget.find({
                                ...basicContext(context),
                                fieldPath: ['mesh', 'analyticalMesh'],
                                query: {
                                    mesh: { $in: budgetBUs.map(BU => global.ObjectID(BU.id)) },
                                    analyticalMesh: { $in: budgetAnalyticalMeshes.map(mesh => global.ObjectID(mesh.id)) }
                                }
                            })


                            const correspondentImputationTypes = await global.app.SE.ImputationType.find({
                                ...basicContext(context),
                                fieldPath: ['code'],
                                query: {
                                    code: { $in: Array.from(filledImputationTypes) }
                                }
                            })

                            const correspondentRealizationTypes = await global.app.SE.RealizationType.find({
                                ...basicContext(context),
                                fieldPath: ['code'],
                                query: {
                                    code: { $in: Array.from(filledRealizationTypes) }
                                }
                            })
                            const correspondentCurrencies = await global.app.SE.Currency.find({
                                ...basicContext(context),
                                fieldPath: ['symbol'],
                                query: {
                                    symbol: { $in: Array.from(filledCurrencies) }
                                }
                            })
                            const verification = !!correspondentDemands.length && !!budgetBUs.length && !!budgetAnalyticalMeshes.length && !!correspondentBudgets.length && !!correspondentImputationTypes.length && !!correspondentRealizationTypes.length && !!correspondentCurrencies.length
                            console.log("completeData", completeData.length, filledImputationTypes, filledCurrencies, filledRealizationTypes, correspondentImputationTypes.length, correspondentRealizationTypes.length, correspondentCurrencies.length)
                            if (verification){
                                for (const data of completeData) {
                                //completeData.forEach( data => {
                                    const dataKeys = Object.keys(data)
                                    const demandNumberKey = dataKeys[0]
                                    const objetKey = dataKeys[1]
                                    const codeBUKey = dataKeys[2]
                                    const analyticalMeshKey = dataKeys[3]
                                    const imputationTypeKey = dataKeys[4]
                                    const dateKey = dataKeys[5]
                                    const typeKey = dataKeys[6]
                                    const currencyKey = dataKeys[7]
                                    const amountKey = dataKeys[8]

                                    const demandNumber = data[demandNumberKey]
                                    const objet = data[objetKey]
                                    //const budgetIdentifiers = data['Budget'].split("*")
                                    const budgetBUCode = data[codeBUKey]
                                    const budgetAnalyticalMeshCode = data[analyticalMeshKey]
                                    const imputationType = data[imputationTypeKey]
                                    const date = data[dateKey]
                                    const realizationType = data[typeKey]
                                    const currency = data[currencyKey]
                                    const amount = data[amountKey]
                                    const verification1 = !!demandNumber && !!objet && !!budgetBUCode && !!budgetAnalyticalMeshCode && !!imputationType && !!date && !!realizationType &&!!currency && !!amount
                                    if (!verification1) return callback(new Errors.ValidationError('error 2'))

                                    const correspondentDemand = correspondentDemands.find(demand => demand.demandNumber === demandNumber)
                                    const correspondentBudget = correspondentBudgets.find(obj => obj.mesh.code === budgetBUCode && obj.analyticalMesh.code === budgetAnalyticalMeshCode && obj.analyticalMesh.attachment.some(subAxis => subAxis.code === correspondentDemand.relatedProject.demandNumber && subAxis.analyticalAxis.isProjectAxis))
                                    const correspondentImputationType = correspondentImputationTypes.find(obj => obj.code === imputationType)
                                    const correspondentRealizationType = correspondentRealizationTypes.find(obj => obj.code === realizationType)
                                    const correspondentCurrency = correspondentCurrencies.find(obj => obj.symbol === currency)

                                    const verification2 = !!correspondentDemand && !!correspondentBudget && !!correspondentImputationType && !!correspondentRealizationType && !!correspondentCurrency
                                    if (!verification2) return callback(new Errors.ValidationError('error 3'))

                                    const correspondentImputation = correspondentDemand.imputations.find(imp => imp.objet === objet && imp.budget.id.toString() === correspondentBudget.id.toString() && imp.imputationType.id.toString() === correspondentImputationType.id.toString())
                                    const verification3 = !!correspondentImputation
                                    if (!verification3) return callback(new Errors.ValidationError('error 4'))
                                    fileLines.push({
                                        engagement: {id: correspondentDemand.id},
                                        imputation: {id: correspondentImputation.id, budget: correspondentImputation.budget, imputationType: correspondentImputation.imputationType},
                                        date: date,
                                        realizationType: {id: correspondentRealizationType.id, code: correspondentRealizationType.code},
                                        currency: correspondentCurrency,
                                        amount: amount
                                    })
                                }
                                newObject.fileLines = fileLines
                                const values = []  //[{id: revisedTableLine.id, incrementBy: {engaged:..., }
                                const valuesByMonth = []  //[{id: imputation.id, incrementBy: {engaged:..., }
                                const filledMonths = fileLines.map( line => moment(line.date).format('YYYY-MM-DD'))

                                const currentFiscalYear = await global.app.SE.FiscalYear.find({
                                    ...basicContext(context),
                                    fieldPath: [],
                                    query: {
                                        "fiscalYearRange.0": {$lte: moment().toDate()},
                                        "fiscalYearRange.1": {$gte: moment().toDate()},
                                    }
                                })


                                for (const line of fileLines) {
                                    const correspondentDemands = await global.app.SE.Demand.find({
                                        ...basicContext(context),
                                        fieldPath: ['relatedProject.id', 'relatedProject.revisedTable'],
                                        query: {
                                            _id: global.ObjectID(line.engagement.id)
                                        }
                                    })
                                    //console.log("correspondentDemand.relatedProject", correspondentDemand.relatedProject)
                                    const revisedTableLine = correspondentDemands[0].relatedProject.revisedTable.find(row => row.objet === line.imputation.budget.analyticalMesh.code && row.entity.id.toString() === line.imputation.budget.mesh.id.toString() && row.imputationType.id.toString() === line.imputation.imputationType.id.toString())
                                    const alreadyExistingIds = values.map(value => value.revisedTableLine.id.toString())
                                    const dateIsOk = moment(line.date).isBetween(currentFiscalYear[0].fiscalYearRange[0], currentFiscalYear[0].fiscalYearRange[1], 'day', '[]')
                                    if (!alreadyExistingIds.includes(revisedTableLine.id.toString())){
                                        const x = {
                                            revisedTableLine: {id: revisedTableLine.id},
                                            incrementBy: {
                                                [line.realizationType.id]: {
                                                    globalAddedAmount: parseFloat(line.amount),
                                                    ongoingFiscalYearAddedAmount: dateIsOk ? parseFloat(line.amount) : 0
                                                },
                                                //[line.realizationType.code]: parseFloat(line.amount),
                                                //['ongoingFiscalYear'+_.upperFirst(line.realizationType.code)]: dateIsOk ? parseFloat(line.amount) : 0
                                            }
                                        }
                                        values.push(x)
                                    }
                                    else{
                                        const alreadyExistingValue = values.find( value => value.revisedTableLine.id.toString() === revisedTableLine.id.toString())
                                        const shouldIncrementAmount = !!alreadyExistingValue.incrementBy[line.realizationType.id]
                                        if (shouldIncrementAmount){
                                            alreadyExistingValue.incrementBy[line.realizationType.id].globalAddedAmount = addFloats(alreadyExistingValue.incrementBy[line.realizationType.id].globalAddedAmount, parseFloat(line.amount))
                                            if (dateIsOk) alreadyExistingValue.incrementBy[line.realizationType.id].ongoingFiscalYearAddedAmount = addFloats(alreadyExistingValue.incrementBy[line.realizationType.id].ongoingFiscalYearAddedAmount, parseFloat(line.amount))
                                            //alreadyExistingValue.incrementBy[line.realizationType.code] = addFloats(alreadyExistingValue.incrementBy[line.realizationType.code], parseFloat(line.amount))
                                            //if (dateIsOk) {alreadyExistingValue.incrementBy['ongoingFiscalYear'+_.upperFirst(line.realizationType.code)] = addFloats(alreadyExistingValue.incrementBy['ongoingFiscalYear'+_.upperFirst(line.realizationType.code)], parseFloat(line.amount)) }
                                        }
                                        else{
                                            alreadyExistingValue.incrementBy[line.realizationType.id] = {
                                                globalAddedAmount: parseFloat(line.amount),
                                                ongoingFiscalYearAddedAmount: dateIsOk ? parseFloat(line.amount) : 0
                                            }
                                            //alreadyExistingValue.incrementBy[line.realizationType.code] = parseFloat(line.amount)
                                            //alreadyExistingValue.incrementBy['ongoingFiscalYear'+_.upperFirst(line.realizationType.code)] = dateIsOk ? parseFloat(line.amount) : 0
                                        }
                                    }
                                    //line.imputation.budget.
                                }
                                const bulkWrites = []
                                values.forEach(value => {
                                    const typesToIncrement = Object.keys(value.incrementBy)
                                    typesToIncrement.forEach( type => {
                                        bulkWrites.push({
                                            updateOne: {
                                                filter: {
                                                    _id: global.ObjectID(value.revisedTableLine.id) ,
                                                },
                                                update: {
                                                    $inc: { "amountByRealizationType.$[elem].amount": value.incrementBy[type].globalAddedAmount}
                                                },
                                                arrayFilters: [
                                                    { "elem.realizationType": global.ObjectID(type), "elem.budgetColumnType": "1" }
                                                ]
                                            }
                                        })
                                        bulkWrites.push({
                                            updateOne: {
                                                filter: {
                                                    _id: global.ObjectID(value.revisedTableLine.id) ,
                                                },
                                                update: {
                                                    $inc: { "amountByRealizationType.$[elem].amount": value.incrementBy[type].ongoingFiscalYearAddedAmount}
                                                },
                                                arrayFilters: [
                                                    { "elem.realizationType": global.ObjectID(type), "elem.budgetColumnType": "2" }
                                                ]
                                            }
                                        })
                                    })
                                })
                                await global.app.SE.RevisedTable.collection.bulkWrite(bulkWrites, {})
                                //await global.app.SE.trackingByMonth.collection.bulkWrite(bulkWritesForTrackingByMonth, {})
                                // bulkWrites : filter : _id:, increment: dynamic...

                                callback(null, newObject, oldObject);


                            }
                            else{
                                return callback(new Errors.ValidationError('error 1'))
                            }
                        })
                    }
                    else{
                        callback(null, newObject, oldObject);
                    }
                } catch (error) {
                    callback(error)
                }
        }
    },
    {
        name: 'FileLines',
        type: "mongoInternal",
        fields: [
            // file : N demand - Objet - Budget - Type imputation - date - type : e, r, ou f - montant
            //Demand = N demand
            //imputation = Objet - Budget - Type imputation
            //date : 'YYYY-MM-DD'
            //type : e, r, ou f
            //Currency
            //montant
            {
                path: 'engagement',
                type: 'Demand'
            },
            'Imputation',
            {path: 'date', type: 'date', format: 'YYYY-MM-DD'},
            'RealizationType',
            'Currency',
            {path: 'amount', type: 'decimal'}
        ]
    }
]

export const module_ = {
    object: 'ExtractedFiles',
    category: {
        path: `tracking`,
    },
    viewMap: {
        dt: [
            'fileName', 'numberOfExtractedLines'
        ],
        form: {
            fields: [
                'fileName',
                'numberOfExtractedLines',
                {
                    path: 'fileLines',
                    type: 'dtObjects',
                    fields: [
                        {path: 'engagement', display: 'demandNumber', width: 200},
                        {path: 'imputation', display: 'imputationTriplet', width: 350},
                        {path: 'date', width: 140},
                        {path: 'realizationType', translateName: true, width: 130},
                        {path: 'currency', display: 'symbol', width: 80},
                        {path:  'amount', width: 100},
                    ],
                },
                'files'
            ],
            onOpen: ({ module, state, store }) => {
                const objectMode = state.ui.objectMode
                const fileLinesField = module.viewMap.form.fields.find( field => field.path === 'fileLines')
                const numberOfExtractedLinesField = module.viewMap.form.fields.find( field => field.path === 'numberOfExtractedLines')
                store.dispatch(setFieldVisibility(numberOfExtractedLinesField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(fileLinesField.id, objectMode !== 'newObject'))
            }
        }
    },
}
