const path = require('path')
const _ = require('lodash')

const defaultMail = (noReply, groupModelName, language, message) => ({
    from: `"${groupModelName}" <${noReply}>`,
    replyTo: noReply,
    content: `${language || 'en'}_${message}.html`,
    verbose: {
        general: true
    },
    service: 'mailjet'
})

const subjects = {
    fr: {
        creation: (numTicket, groupModelName) => `${groupModelName}: Accusé de réception - Demande N° ${numTicket}`,
        response: (numTicket, groupModelName) => `${groupModelName}: Nouveau message - Demande N° ${numTicket}`,
        update: (numTicket, groupModelName) => `${groupModelName}: Accusé de réception - Demande N° ${numTicket}`,
        adminNotification: (numTicket, groupModelName) => `${groupModelName}: Nouveau message - Demande N° ${numTicket}`,

    },
    en: {
        creation: (numTicket, groupModelName) => `${groupModelName}: Acknowledgment of receipt - Request No. ${numTicket}`,
        response: (numTicket, groupModelName) => `${groupModelName}: New message - Request No. ${numTicket}`,
        update: (numTicket, groupModelName) => `${groupModelName}: Accusé de réception - Demande N° ${numTicket}`,
        adminNotification: (numTicket, groupModelName) => `${groupModelName}: Nouveau message - Demande N° ${numTicket}`,
    },
    nl: {
        creation: (numTicket, groupModelName) => `${groupModelName}: Ontvangstbevestiging - Ticketnr. ${numTicket}`,
        response: (numTicket, groupModelName) => `${groupModelName}: Nieuw bericht  - Ticketnr. ${numTicket}`,
        update: (numTicket, groupModelName) => `${groupModelName}: Accusé de réception - Demande N° ${numTicket}`,
        adminNotification: (numTicket, groupModelName) => `${groupModelName}: Nouveau message - Demande N° ${numTicket}`,
    }
}

const translateSubject = (language, type) => {
    switch (language) {
        case 'fr':
        case 'en':
            return _.get(subjects, `${language}.${type}`)
        default:
            return _.get(subjects, `en.${type}`)
    }
}

export const sendMail = function({recipient, sender, theme, type, numTicket, groupModelName, link, noReply}, done) {
    const language = typeof(_.get(recipient, 'language')) !== 'string'
        ? _.get(recipient, 'language.id')
        : _.get(recipient, 'language')

    const mail = _.defaults(
        {
            to: recipient.mail,
            subject: { template: translateSubject(language, type)(numTicket, groupModelName) },
            context: {
                fullName: recipient.name,
                fullNameSender: sender.name,
                groupModelName,
                numTicket,
                link,
                theme
            },
            templateDir: path.join(
                global.appRoot,
                global.isProd
                    ? 'buildServer/server/KpMailer'
                    : 'src/server/KpMailer',
                'templates/ticket'
            )
        },
        defaultMail(noReply, groupModelName, language, type)
    )

    global.mailer.sendMail(mail, error => done(error, 'emailSent'))
}
