/* eslint-disable no-unused-vars */
const _ = require('lodash')
const moment = require('moment')
const { dateToIntFormat } = require("../engines/functions");
const { setFormButtons } = require('../../../../apps/KpModule/actions')
const { basicContext } = require('../../../utils/contextUtils')
const { formatNumber, rowStyle } = require('../engines/functions')

const {
    calculateEmpiricAccountModels
} = require('../engines/empiricAccountModelEngine')

async function calculateState(idStateModel, context) {

    // Get state model
    const selectedStateModel = await global.app.B.StateModel.get(idStateModel, {
        ...basicContext(context),
        fieldPath: [
            'id',
            'code',
            'name',
            'yearOfStart.value',
            'yearOfEnd.value',

            'stateModelLines.order',
            'stateModelLines.modelFormat.id',
            'stateModelLines.modelStyle.id',
            'stateModelLines.accountModel',
            'stateModelLines.accountModel.id',
            'stateModelLines.accountModel.code',
            'stateModelLines.accountModel.formula',
            'stateModelLines.accountModel.accountModelType.id',
            'stateModelLines.accountModel.dataType.format.id',
            'stateModelLines.accountModel.dataType.precision.id'
        ]
    })

    const realDate = moment(_.get(context, 'data.shopBPEndorsed.realOpening'), 'YYYY-MM-DD')

    const beginYear = realDate.year() - 1 + _.get(selectedStateModel, 'yearOfStart.value')
    const calculationYears = _.range(
        beginYear,
        realDate.year() + _.get(selectedStateModel, 'yearOfEnd.value')
    )

    const resultAccountModels = await calculateEmpiricAccountModels(
        _.get(context, 'data.shopBPEndorsed'),
        calculationYears,
        selectedStateModel.stateModelLines.map(sml => ({
            accountModelCode: _.get(sml, 'accountModel.code'),
            accountModelType: _.get(sml, 'accountModel.accountModelType.id')
        })),
        context
    )

    const stateModelLines = [
        ..._.get(selectedStateModel, 'stateModelLines', []).map(sml => {
            const am = sml.accountModel
            const unit = _.get(am, 'dataType.format.id')
            const precision = _.get(am, 'dataType.precision.id')
            return {
                id: am.id,
                name: {data: context.translateName(sml.wording), style: {textAlign: 'left'}},
                order: sml.order,
                style: rowStyle(sml.modelStyle.id),
                value: am.accountModelType.id === 'value'
                    ? formatNumber(resultAccountModels[am.code], unit, precision)
                    : '',
                years: calculationYears.reduce((acc, year) => {
                    return {
                        ...acc,
                        [year]: am.accountModelType.id === 'year'
                            ? formatNumber(resultAccountModels[am.code][year], unit, precision)
                            : ''
                    }
                }, {})
            }
        })
    ]

    return {
        ..._.pick(selectedStateModel, ['id', 'name']),
        title: _.get(context, 'data.shopBPEndorsed.fullInformation'),
        stateLines: _.sortBy(stateModelLines, 'order')
    }
}

async function findData(context) {
    const stateModels = await global.app.B.StateModel.find({
        // filter only kind "Shop"
        ...basicContext(context),
        fieldPath: ['id', 'code', 'tName'],
        query: {kind: '9'},
    })
    return stateModels.map(o => ({
        ..._.pick(o, ['id', 'code']),
        name: o.tName
    }))
}

export const entity = {
    name: 'AnalyseBrBpa',
    fields: ['name', 'title'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else {
                calculateState(id, context)
                    .then( object => {
                        global.ioSocket.emit(
                            `fetchFormObject-success-${context.user.id}${context.clientContext.accessId}`,
                            object
                        )
                    })
                    .catch(error => {
                        global.ioSocket.emit(
                            `fetchFormObject-failure-${context.user.id}${context.clientContext.accessId}`,
                            {error: error.message}
                        )
                    })
                callback(null, {})
            }
        })
    }
}

export const module_ = {
    name: 'AnalyseBR_BPA',
    object: 'AnalyseBrBpa',
    tKey: 'mTitle_analyseBR_BPA',
    newable: false,
    removable: false,
    useSocketsOnGet: true,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'empiric',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            { path: 'code', initiallyNotVisible: true },
            { path: 'name', type: 'translatedText' }
        ],
        form: {
            fields: [
                { path: 'title', hidden: true },
                {
                    path: 'stateLines',
                    type: 'styledTable',
                    autoGrow: true,
                    fullWidth: true,
                    fields: [
                        {
                            path: 'name',
                            style: {
                                flex: '1 1 500px',
                                whiteSpace: 'initial'
                            }
                        },
                        { path: 'value' },
                        { path: 'years', dynamic: true },
                        { path: 'style', hidden: true },
                        // { path: 'isHeader', hidden: true }
                    ]
                }
            ],
            onOpen: ({ store }) => {

                store.dispatch(
                    setFormButtons(
                        [{type: 'return', tKey: 'return', bsStyle: 'default'}]
                    )
                )
            }
        }
    },
    filters: [
        {
            title: 'Shop - Bp',
            path: 'shopBPEndorsed',
            object: 'ShopBPEndorsed',
            display: 'fullInformation',
            fieldPath: ['realOpening', 'fullInformation'],
            filters: ['onlyAffectedSubsidiary'],
            clearable: false,
            client: true,
            autoFetch: false,
            width: 12
        }
    ]
}
/* eslint-enable no-unused-vars */
