import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { I18n } from 'react-i18next'
import DatePicker from '../../../../components/Calendar/DatePicker'
import getFormValidations from './formValidations'
import { toggleCalendar } from '../../actions'
import { getModule, getLanguage, getDatePickerOpened } from '../../selectors'

const DATE_FORMAT = 'YYYY-MM-DD'

function dateComparator(startDate, endDate) {
    if (!startDate && !endDate) return

    const referenceStartDate = startDate && moment(startDate, DATE_FORMAT).startOf('day')
    const referenceEndDate = endDate && moment(endDate, DATE_FORMAT).startOf('day')

    return function compareDate(date) {
        return date
            ? startDate && endDate
                ? moment(referenceStartDate).diff(date.startOf('day'), 'days')  > 0 || moment(referenceEndDate).diff(date.startOf('day'), 'days')  < 0
                : startDate
                    ? moment(referenceStartDate).diff(date.startOf('day'), 'days')  > 0
                    : endDate ?
                    moment(referenceEndDate).diff(date.startOf('day'), 'days')  < 0
                        : false
            : false
    }
}


// binding for redux-form
const VisualComponent = ({
    input: { value, onChange },
    meta: {touched, error},
    path,
    required,
    open,
    onOpenChange,
    language,
    module,
    disabled,
    editable,
    showTime,
    startDate,
    endDate,
    t
}) => {
    const comparator = dateComparator(startDate, endDate)

    const translatedError = t(error)
    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <DatePicker
                    value={value}
                    required={required}
                    path={path}
                    open={open}
                    touched={touched}
                    showTime={showTime}
                    error={translatedError}
                    onChange={value => onChange(value)}
                    onOpenChange={onOpenChange}
                    disabledDate={comparator}
                    language={language}
                    placeholder={t('pickDate')}
                    disabled={!editable || disabled}
                    t={t}
                />
            )}
        </I18n>
    )
}

const DatePickerComponent = ({
    field,
    t,
    objectMode,
    open,
    toggleCalendar,
    language,
    module,
}) => {
    const validations = getFormValidations(field)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.tKey}
            required={field.required}
            component={VisualComponent}
            validate={validations}
            // onChange={(event, newValue) => {
            //     field.onChange && field.onChange(newValue)
            // }}
            open={open}
            language={language}
            module={module}
            onOpenChange={() => toggleCalendar(field.id)}
            showTime={field.showTime}
            startDate={field.startDate}
            endDate={field.endDate}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    open: getDatePickerOpened(state, field.id),
    language: getLanguage(state),
    module: getModule(state)
})

const mapDispatchToProps = {
    toggleCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent)
