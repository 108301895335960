const moment = require('moment')

export const OperationsAndServicesContracts = {
    object: "ContractMonitoring",
    name: 'operationsAndServicesContracts',
    category: {
        path: "management",
        icon: 'trendingUp'
    },
    removable: false,
    viewMap: {
        dt: [
            'contractFullId',
            {path: 'identifier', initiallyNotVisible: true},
            {path: 'type', initiallyNotVisible: true},
            {path: 'number', initiallyNotVisible: true},
            {path: 'year', initiallyNotVisible: true},
            'wording',
            {path: 'analyticalCode', initiallyNotVisible: true},
            {path: 'origin', initiallyNotVisible: true},
            {path: 'providerAndCustomer'},
            {path: 'businessProjectsFN', tKey: 'project', initiallyNotVisible: true},
            {path: 'technicalTeamMember', initiallyNotVisible: true},
            {path: 'zone', initiallyNotVisible: true},
            {path: 'country', initiallyNotVisible: true},
            {path: 'state'},
            {path: 'version', initiallyNotVisible: true},
            //{path: 'kickOff', initiallyNotVisible: true},
            {path: 'begin', initiallyNotVisible: true},
            {path: 'end', initiallyNotVisible: true},
            {path: 'status'},
            {path: 'amount', tKey: 'amountE_abbreviation'}
        ],
        form: [
            {path: 'contractFullId', required: true},
            {path: 'wording', required: true},
            {path: 'type'},
            {path: 'year', required: true, default: {id: moment().format('YYYY')}, sortList: false},
            {path: 'analyticalCode', placeholder: 'BU...'},
            {path: 'provider', display: 'fullName', required: false},
            {path: 'customer', display: 'fullName', required: false},
            {
                path: 'businessProjects',
                tKey: 'project',
                display: 'fullName',
                translate: false,
                filters: [{
                    name: 'isUserInCountryScope',
                    query: () => ({})
                }]
            },
            {path: 'state'},
            {path: 'version'},
            /*
            {
                path: 'kickOff',
                subscriptions: {
                    onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                        store.dispatch(setFieldStartDate('e_end', newValue))
                    }
                }
            },
             */
            {path: 'begin'},
            {path: 'end'},
            {path: 'status', default: {id: 'active'}},
            {path: 'amount', positiveNumber: true, default: 0, tKey: 'amountE_abbreviation'},
            'comments',
            'files'
        ]
    },
    filters: ["byProvider", "byCustomer", "byStatus"]
}
