const _ = require("lodash");
const { forceMap } = require("./toDeprect");

const fieldPathJoinGetter = ({path, fieldPath, joinString=" - "}) => ({
    path,
    fieldPath,
    f: function() { return fieldPath
        .map(p => _.get(this, p))
        .join(joinString);
    }
});

const getAccordionField = panelName => (accordion, value) => accordion.fields().find(
    field => field[panelName] && field[panelName].active() && field[panelName].value() === value
);
const getAccordionFormField = getAccordionField("form");
const getAccordionDtField = getAccordionField("dt");

const getModuleUrl = module => {
    if (!module.entity && !module.entity.url) throw new Error("missing entity information to generate url");
    return `/rest3/${module.entity.url}`;
};

const addParentLinkOnChildren = function(value, { parentValue, parentPathOnChild, multipleParents }) {
    if(value !== undefined && value !== null && value !== []) {
        const addParent = value => {
            if (multipleParents) {
                value[parentPathOnChild] = _.remove(
                    value[parentPathOnChild],
                    obj => obj.id !== parentValue.id
                );
                value[parentPathOnChild].push(parentValue);
            }
            else value[parentPathOnChild] = parentValue;
        };
        forceMap(value, addParent);
    }
    return value;
}

export {
    fieldPathJoinGetter,
    getAccordionField,
    getAccordionFormField,
    getAccordionDtField,
    getModuleUrl,
    addParentLinkOnChildren
};
