const _ = require("lodash");
const { getUser } = require("./../../enhancers/defaultObjects");

export const entity = _.defaults({
    getUserHabFunctions: function(user, context, callback) {
        this.get(user.id, {group: context.group, fieldPath: ["habilitations"]}, (e, user) => {
            if(e) return callback(e);
            const habFunctions = _(user.habilitations)
                .filter(habilitation => _.get(habilitation, 'group.id') === _.get(context, 'group.id'))
                .groupBy(habilitation => habilitation.habFunction.id)
                .map(habilitations => ({habilitations, ...habilitations[0].habFunction}))
                .value();
            callback(null, habFunctions);
        });
    }
}, getUser())
