const path = require('path')
const moment = require('moment')

export function sendReport(recipients, questionObject, user, presentGuests, generatedPdf) {
    const {
        code,
        date,
        startTime,
        endTime,
        object,
        place,
        synthesis,
        presentParticipants
    } = questionObject

    const files = questionObject.files.map(
        file => ({
            id: file._id,
            filename: file.filename
        })
    )

    console.log(files)

    recipients.forEach(recipient => {
        const mail = {
            from: `Valodial<support@keenpoint.com>`,
            to: recipient,
            replyTo: `${user.mail}`,
            context: {
                code,
                startTime,
                endTime,
                object,
                place,
                synthesis,
                presentParticipants,
                recipients,
                user,
                files: files,
                guests: presentGuests,
                date: moment(date).format('DD-MM-YYYY')
            },
            content: 'reportMail.html',
            subject: {
                template: `VALODIAL : Compte rendu de réunion : ${code}`
            },
            templateDir: path.join(
                global.appRoot,
                'src/server/models/valodial/templates/mails'
            ),
            attachments: [
                generatedPdf,
                ...files
            ],
            mock: false,
            verbose: {
                general: true
            }
        }

        global.mailer.sendMail(mail, function(e) {
            if (e) console.log(e)
            else console.log('mail sent...')
        })
    })
}
