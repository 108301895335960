const moment = require('moment')
const crypto = require('../../../utils/crypto')

const configureWorkbook = (object, bank, context) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Batch')

    let row = 1
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const columns = [
        {key: 'Banque CNDA', getter  : () => bank.bank},
        {key: 'IBAN CNDA', getter  : () => crypto.decrypt(bank.iban)},
        {key: 'BIC CNDA', getter  : () => crypto.decrypt(bank.bic)},
        {key: 'Destinataire', getter  : (orderLine) => orderLine.recipient},
        {key: 'IBAN', getter  : orderLine => crypto.decrypt(orderLine.IBAN)},
        {key: 'BIC', getter  : orderLine => crypto.decrypt(orderLine.BIC)},
        {key: 'Référence', getter  : orderLine => orderLine.reason},
        {key: 'Date', getter  : () => moment().format("YYYY-MM-DD")},
        {key: 'Montant', getter  : orderLine => orderLine.amount}
    ]

    columns.forEach(column => {
        dataSheet.getRow(row).getCell(col).value = column.key
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    row = 2
    object.orderLines.forEach(line => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = column.getter(line)
            col++
        })
        row++
    })

    dataSheet.getRow(row).getCell(1).value = 'Montant total'
    dataSheet.getRow(row).getCell(9).value = object.amount
    dataSheet.mergeCells(row, 1, row, 8)

    return workbook
}

export async function generateExcel(object, bank, context) {
    //const wording = object.process.id === 'invoice'? 'Facture ' : 'Dossier'
    return await new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(object, bank, context),
            `LotDeVirement_${object.reference}.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            }
        );
    });
}
