import React from 'react'
import {
    Briefcase, TrendingUp, Search, PieChart, Book, Folder, Layers,
    Sliders, Server, Settings, Database, Users, User, UserCheck, Grid,
    Clipboard, FileText, Repeat, Video, Activity, Gift, Mail, ArrowRight,
    ChevronRight, ChevronDown, ChevronUp, MessageCircle, List, BarChart2
} from 'react-feather'


const components = {
    'clipboard': Clipboard,
    'trendingUp' : TrendingUp,
    'briefcase': Briefcase,
    'pieChart': PieChart,
    'search': Search,
    'book': Book,
    'layers': Layers,
    'sliders': Sliders,
    'server': Server,
    'settings': Settings,
    'database': Database,
    'users': Users,
    'user-check': UserCheck,
    'user': User,
    'grid': Grid,
    'fileText': FileText,
    'repeat': Repeat,
    'media': Video,
    'activity': Activity,
    'gift': Gift,
    'mail': Mail,
    'rightArrow': ArrowRight,
    'rightChevron': ChevronRight,
    'upChevron': ChevronUp,
    'downChevron': ChevronDown,
    'messageCircle': MessageCircle,
    'list': List,
    'bar-chart-2': BarChart2
}


export const iconSelector = (icon, className, color) =>
    React.createElement(components[icon] || Folder, {className, color});
