const { byDeposit } = require('./filters')

export const dependencies = [
    {
        name: 'DepositZoneHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith: 'zone', index: true},
            'Zone',
            'HistoryDataType'
        ],
        filters:[byDeposit]
    }
]
export const entity = {
    name: "Deposit",
    facets: [
        {name:"codeName", uniqueCode: true}
    ],
    fields: [
        {
            type: 'DepositZoneHistory',
            path: 'zones',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        }
    ]
}

export const modules = [
    {
        object: 'DepositZoneHistory',
        tKey: 'mTitle_deposit',
        newable: false,
        removable: false,
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: [
                {path: "deposit.code", tKey: 'code', width: 100},
                {path: "deposit.name", tKey: 'name'},
                {path: 'date', width: 100},
                {
                    path: 'zone',
                    display: 'fullName'
                },
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:['byDeposit']
    }
]
