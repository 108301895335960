const {fieldPathJoinGetter} = require('../../../utils/kp3Utils')

export const entities = [
    {
        name: 'FormationObjective',
        fields: [
            'Objective',
            'Level'
        ]
    },
    {
        name: 'Formation',
        fields: [
            'Category',
            {path: 'code', unique: true},
            'title',
            'description',
            'Level',
            {path: 'length', type: 'integer'},
            'method',
            {path: 'formationObjectives', type: 'FormationObjective', link: 'OTM'},
            fieldPathJoinGetter({
                path: "fullTitle",
                fieldPath: ["code", "title"],
                joinString: " - "
            }),
            {
                path: "formationObjectivesNb",
                fieldPath: ["formationObjectives.id"],
                f: function () {
                    return this.formationObjectives ? this.formationObjectives.length : 0
                }
            }
        ]
    }
]

export const module_ = {
    object: 'Formation',
    tKey: 'mTitle_mediaTraining',
    category: {
        path: 'referential',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'category', display: 'fullName'},
            {path: 'code'},
            {path: 'title'},
            {path: 'level'},
            {path: 'length', tKey: 'nbHours'},
            {path: 'formationObjectivesNb', tKey: 'nbObjectives'}

        ],
        form: [
            {path: 'category', display: 'fullName', required: true},
            {path: 'code', required: true},
            {path: 'title', required: true},
            {path: 'description', type: 'textarea'},
            {path: 'level', default: {id: '0'}},
            {path: 'length', tKey: 'nbHours', wholePositiveNumber: true},
            {path: 'method', type: 'textarea'},
            {
                path: 'formationObjectives',
                tKey: 'objectives',
                viewMap: {
                    dt: [
                        {path: 'objective', display: 'fullName'},
                        'level'
                    ],
                    form: [
                        {path: 'objective', display: 'fullName'},
                        {path: 'level', default: {id: '0'}}
                    ]
                }
            }
        ]
    }
}
