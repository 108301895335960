import download from "downloadjs"
import _ from "lodash"
import withQuery from "with-query"

export function getFile({fileId, filename, password, language, groupModel}){
    fetch(withQuery(window.location.origin + `/file`, {id: fileId, password, language, groupModelId: groupModel && groupModel.id}), {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true
        },
        method: 'GET',
        credentials: 'same-origin'
    })
    .then(resp => {
        return resp.blob()
    })
    .then(blob => download(blob, filename))
}
