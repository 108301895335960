export default store => next => action => {
    try {
        next(action)
    } catch(e) {
        // this error handling is really poor, just to avoid blind errors on async middlewares
        // ideally we should define actions to be dispatched in case of error

        console.warn('reducer error:', e)
        throw e
    }
}