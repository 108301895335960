import openSocket from 'socket.io-client'
const socket = openSocket()

function subscribeUser(userId, groupId, modelId, groupModelId) {
    socket.emit('userSubscription', userId, groupId, modelId, groupModelId)
}

function addListeners(userId, callback) {
    socket.on( 'afterSave', data => callback(data) )
    socket.on( `initialData-${userId}`, data => callback(data) )
}

function addToastrListener(userId, callback) {
    socket.on( `toastrMessage-${userId}`, data => callback(data))
}

function removeListeners() {
    socket.removeListener('initialData')
    socket.removeListener('afterSave')
}
function fetchFormObjectListeners(callback, listenerId) {
    socket.on( `fetchFormObject-success-${listenerId}`, data => {
        socket.removeListener(`fetchFormObject-success-${listenerId}`)
        socket.removeListener(`fetchFormObject-failure-${listenerId}`)
        callback('success', data)
    } )
    socket.on( `fetchFormObject-failure-${listenerId}`, data => {
        socket.removeListener(`fetchFormObject-success-${listenerId}`)
        socket.removeListener(`fetchFormObject-failure-${listenerId}`)
        callback('failure', data)
    } )
}

function fetchDTListeners(callback, listenerId) {
    socket.on( `fetchDT-success-${listenerId}`, data => {
        socket.removeListener(`fetchDT-success-${listenerId}`)
        socket.removeListener(`fetchDT-failure-${listenerId}`)
        callback('success', data)
    } )
    socket.on( `fetchDT-failure-${listenerId}`, data => {
        socket.removeListener(`fetchDT-success-${listenerId}`)
        socket.removeListener(`fetchDT-failure-${listenerId}`)
        callback('failure', data)
    } )
}

function fetchChartListeners(callback, listenerId) {
    socket.on( `fetchChart-success-${listenerId}`, data => {
        socket.removeListener(`fetchChart-success-${listenerId}`)
        socket.removeListener(`fetchChart-failure-${listenerId}`)
        callback('success', data)
    } )
    socket.on( `fetchDT-failure-${listenerId}`, data => {
        socket.removeListener(`fetchDT-success-${listenerId}`)
        socket.removeListener(`fetchDT-failure-${listenerId}`)
        callback('failure', data)
    } )
}

function fetchEditObjectListeners(callback, listenerId) {
    socket.on( `fetchEditObject-success-${listenerId}`, data => {
        socket.removeListener(`fetchEditObject-success-${listenerId}`)
        socket.removeListener(`fetchEditObject-failure-${listenerId}`)
        callback('success', data)
    } )

    socket.on( `fetchEditObject-failure-${listenerId}`, data => {
        socket.removeListener(`fetchEditObject-success-${listenerId}`)
        socket.removeListener(`fetchEditObject-failure-${listenerId}`)
        callback('failure', data)
    } )
}

export {
    subscribeUser,
    addListeners,
    addToastrListener,
    removeListeners,
    fetchFormObjectListeners,
    fetchEditObjectListeners,
    fetchChartListeners,
    fetchDTListeners
};
