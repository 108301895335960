import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reset from '../../../../components/Reset'
import { toastr } from 'react-redux-toastr'

class ResetPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: null,
            error: null,
            isLogging: props.isLogging,
            isCheckingToken: false,
        }
    }

    // new (React v16.3.0) lifecycle
    static getDerivedStateFromProps(props, state) {
        if (props.isLoggedIn && state.isLogging && !props.isLogging) {
            props.history.push('/business')
        }
        return {
            isLogging: props.isLogging
        }
    }

    componentDidMount() {
        const { resetGet, token } = this.props
        this.props.fetchConfig()
        this.setState({isCheckingToken: true})
        resetGet(token).then(
            response => {
                this.setState({isCheckingToken: false})

                if (response.error) {
                    this.setState({ error: response.error })
                } else {
                    this.setState({user: response.data})
                }
            }
        )
    }

    handleReset = password => {
        const {t} = this.props
        this.props.reset(this.props.token, {...this.state.user, password})
            .then( response => {
                if (response.error) {
                    this.setState({ error: response.error })
                    toastr.error(
                        t('error'),
                        response.error === "Unauthorized" ? this.props.t('wrongUserOrPassword') : response.error
                    )
                } else {
                    toastr.success(
                        t('success'),
                        t(response.data.message)
                    )
                    setTimeout(() => this.props.history.push('/login'), 3000);
                }
            })
    }

    render() {
        const {history, client, t} = this.props
        return <Reset client={client}  history={history} handleSubmit={this.handleReset} error={this.state.error} t={t} loading={this.state.isCheckingToken} />
    }
}

ResetPage.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    resetGet: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

export default ResetPage
