import _ from "lodash";

export const kpBusinessRevue = {
    name: "kpBusinessRevue",
    object: "BusinessRevue",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    removable: false,
    newable: false,
    viewMap: {
        dt: [
            "date",
            {path: "businessProjectCountryAndFullName", tKey: "projet", width: 400},
            {path: "executedTurnOver", tKey: "CA Réalisé (k€)"},
            {path: "projectStep", tKey: "étape"},
            {path: "revised", tKey: "CA Révisé (k€)"},
            {path: "yearN", tKey: "Année (k€)"},
            {path: "probability", tKey: "Probabilité (%)"},
            {path: "yearP", tKey: "AnnéeP (k€)"},
            {path: "yearNPlusOne", tKey: "Année+1 (k€)"},
            "comment"
        ],
        form: [
            "date",
            {path: "businessProjectCountryAndFullName", tKey: "projet", writable: false},
            {path: "executedTurnOver", tKey: "CA Réalisé (k€)"},
            {path: "projectStep", tKey: "étape"},
            {path: "revised", tKey: "CA Révisé (k€)"},
            {path: "yearN", tKey: "Année (k€)"},
            {path: "probability", tKey: "Probabilité (%)"},
            {path: "yearP", tKey: "AnnéeP (k€)"},
            {path: "yearNPlusOne", tKey: "Année+1 (k€)"},
            {path: "comment", type: "textarea"}
        ]
    },
    filters: [{
        name: "byBusinessProject",
        path: "businessProject",
        object: "BusinessProject",
        display: "countryAndFullName",
        width: 12,
        clearable: true,
        filters: ["businessProjectInProgress", {name: "isUserInCountryScope", query: () => ({})}],
        sorters: ["byCountry"],
        client: true,
        query: function (context) {
            const businessProjectId = _.get(context.data, "businessProject.id");

            return businessProjectId
                ? {businessProject: new global.ObjectID(businessProjectId)}
                : {}
        }
    }],
}
