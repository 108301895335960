import {translateName} from "../../../../utils/i18n";
import _ from "lodash";

export const entity = require('../../../utils/entityJoin').joinEntities(
    {
        name: 'analysisOptionsJoinedEntity',
        fields: [
            //'name',
            {
                path: 'name',
                fieldPath: [],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return  callback(null, `Axe organisationnel : ${translateName(object.name, _.get(context, "language.id"))}`)
                    }
                    if (object.joinedEntity === 'AnalysisOption') {
                        return callback(null, object.name)
                    }
                }
            },
            {
                path: 'path',
                fieldPath: [],
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return  callback(null, 'organization.name')
                    }
                    if (object.joinedEntity === 'AnalysisOption') {
                        return callback(null, object.path)
                    }
                }
            },
            {
                path: 'translateName',
                fieldPath: [],
                type: 'boolean',
                $f: (object, context, callback) => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return  callback(null, true)
                    }
                    if (object.joinedEntity === 'AnalysisOption') {
                        return callback(null, object.translateName)
                    }
                }
                /*
                f: function(){
                    console.log('this', this)
                    if(this.joinedEntity === 'OrganizationalAxis') {
                        return  false
                    }
                    if (this.joinedEntity === 'AnalysisOption') {
                        return `${this.translateName}`
                    }
                }

                 */
            }
        ],
        /*
        beforeSave: function(newObject, oldObject, context){
            if (newObject.joinedEntity === 'Mesh'){
                return newObject
            }
        },
         */
        filters: [
            /*£
            {
                name: 'byIncludeInFunctionScope',
                isDefault: true,
                match: object => {
                    if(object.joinedEntity === 'OrganizationalAxis') {
                        return object.includeInFunctionScope
                    }
                    return true
                }
            }

             */
        ]
    },
    ['OrganizationalAxis', 'AnalysisOption']
)
