const _ = require('lodash');

const expOnContractWithProdValue = (psc) => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "5", "6"], pscv.projectSalesCostVolumeUnit.id) })
    const result = ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) => acc + (-psc.unitaryCost * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000
        , 0)
    return _.round(result, 1);
};

const expOnContractWithProdValues = (psc) => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "5", "6"], pscv.projectSalesCostVolumeUnit.id) })
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: _.round((-psc.unitaryCost * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000, 1)})
        , {})
};

const expOnContractWithoutProdValue = (psc) => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "6"], pscv.projectSalesCostVolumeUnit.id) })
    const result = ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  acc + (-psc.unitaryCost * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000
        , 0)
    return _.round(result, 1);
};

const expOnContractWithoutProdValues = (psc) => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "6"], pscv.projectSalesCostVolumeUnit.id) })
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: _.round((-psc.unitaryCost * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000, 1)})
        , {})
};

const travellingExpensesValue = (psc, businessProject) => {
    const perdiemPscv = _.find(psc.projectSalesCostVolumes, pscv => { return _.includes(["3"], pscv.projectSalesCostVolumeUnit.id) });
    const travelCostPscv = _.find(psc.projectSalesCostVolumes, pscv => { return _.includes(["4"], pscv.projectSalesCostVolumeUnit.id) });
    const result = ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  acc + -(perdiemPscv[n] * businessProject.perDiem + travelCostPscv[n] * businessProject.travelCost) / 1000
        , 0)
    return _.round(result, 1);
};

const travellingExpensesValues = (psc, businessProject) => {
    const perdiemPscv = _.find(psc.projectSalesCostVolumes, pscv => { return _.includes(["3"], pscv.projectSalesCostVolumeUnit.id) });
    const travelCostPscv = _.find(psc.projectSalesCostVolumes, pscv => { return _.includes(["4"], pscv.projectSalesCostVolumeUnit.id) });
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: _.round(-(perdiemPscv[n] * businessProject.perDiem + travelCostPscv[n] * businessProject.travelCost) / 1000, 1)})
        , {})
};

const turnoverValue = psc => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "6"], pscv.projectSalesCostVolumeUnit.id) });
    const result = ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) => acc + (psc.unitaryPrice * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000
        , 0)
    return _.round(result, 1);
};

const turnoverValues = psc => {
    const pscvFiltred = _.filter(psc.projectSalesCostVolumes, pscv => { return _.includes(["1", "2", "6"], pscv.projectSalesCostVolumeUnit.id) });
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: _.round((psc.unitaryPrice * _.reduce(pscvFiltred, (sum, pscv) => { return sum + pscv[n]; }, 0)) / 1000, 1)})
        , {})
};

const otherOperatingCostsValue = oop => {
    const oopFiltred = _.filter(oop.otherOperatingCostVolumes, oocv => { return _.includes(["1"], oocv.otherOperatingCostVolumeUnit.id) });
    const result = _(["N", "N+1", "N+2", "N+3", "N+4"]).reduce(
        (acc, n) =>  acc + (-oop.unitaryCost * _.reduce(oopFiltred, (sum, oocv) => { return sum + oocv[n]; }, 0)) / 1000
        , 0);
    return _.round(result, 1);
};

const otherOperatingCostsValues = oop => {
    const oopFiltred = _.filter(oop.otherOperatingCostVolumes, oocv => { return _.includes(["1"], oocv.otherOperatingCostVolumeUnit.id) });
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: _.round((-oop.unitaryCost * _.reduce(oopFiltred, (sum, oocv) => { return sum + oocv[n]; }, 0)) / 1000, 1)})
        , {})
};

const otherFiscalCostsValuesNegative = (ofc, base) => {
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: base.values["N"] > 0 ? _.round((-base.values[n] * ofc.rate) / 100, 1) : 0})
        , {})
};

const otherFiscalCostsValuesPositive = (ofc, base) => {
    return ["N", "N+1", "N+2", "N+3", "N+4"].reduce(
        (acc, n) =>  Object.assign(acc, {[n]: base.values["N"] < 0 ? _.round((base.values[n] * ofc.rate) / 100, 1) : 0})
        , {})
};

const reduceValuesAndTotal = (object) => {
    return _.reduce(object, (o, n) => {
        o.total = _.round(o.total + n.total, 1);
        o.values["N"] = _.round(o.values["N"] + n.values["N"], 1);
        o.values["N+1"] = _.round(o.values["N+1"] + n.values["N+1"], 1);
        o.values["N+2"] = _.round(o.values["N+2"] + n.values["N+2"], 1);
        o.values["N+3"] = _.round(o.values["N+3"] + n.values["N+3"], 1);
        o.values["N+4"] = _.round(o.values["N+4"] + n.values["N+4"], 1);
        return o;
    }, {total: 0, values:{"N": 0, "N+1": 0, "N+2": 0, "N+3": 0, "N+4": 0}})
};

const reduceValues = (object) => {
    return _.pick(
        _.reduce(object, (o, n) => {
            o.component = n.component;
            o.total = _.round(o.total + n.total, 1);
            o.values["N"] = _.round(o.values["N"] + n.values["N"], 1);
            o.values["N+1"] = _.round(o.values["N+1"] + n.values["N+1"], 1);
            o.values["N+2"] = _.round(o.values["N+2"] + n.values["N+2"], 1);
            o.values["N+3"] = _.round(o.values["N+3"] + n.values["N+3"], 1);
            o.values["N+4"] = _.round(o.values["N+4"] + n.values["N+4"], 1);
            return o;
        }, {total: 0, values:{"N": 0, "N+1": 0, "N+2": 0, "N+3": 0, "N+4": 0}})
        , ["total", "values", "component"]);
};

const groupByAndReceValuesAndTotal = (object) => {
    return _(object)
        .map(o => {return _.pick(o, ["component", "values"])})
        .groupBy(o =>  o.component.id)
        .map(o => { return reduceValues(o) })
        .map(o => {
            o.total = _.reduce(o.values, (o, n) => o + n, 0);
            return o;
        })
        .value();
};

const ratioValues = (numerator, denominator) => {
    return {
        total: (denominator.total !== 0 ? _.round((numerator.total / denominator.total)*100, 2) : 0) + "%",
        values: _(["N", "N+1", "N+2", "N+3", "N+4"]).reduce(
            (acc, n) =>  Object.assign(acc, {[n]: (denominator.values[n] !== 0 ? _.round((numerator.values[n] / denominator.values[n])*100 , 2) : 0) + "%"})
            , {})
    }
};


const businessPlanSummary = (businessProject, turnover, travellingExpenses, expOnContract) => {

    const otherOperatingCosts = [];
    const otherFiscalCosts = [];

    const componentTurnover = groupByAndReceValuesAndTotal(turnover).map( o => {
        o.title = o.component.name;
        o.type = "1";
        return o;
    });

    const componentTurnoverTotal = Object.assign(
        reduceValuesAndTotal(componentTurnover),
        {title: "Chiffre d'affaire", type: "2"}
    );

    const componentTurnoverTotalWithYear = _(["N", "N+1", "N+2", "N+3", "N+4"]).reduce(
        (acc, n, i) => Object.assign(acc, {[businessProject.beginYear.id + i]: componentTurnoverTotal.values[n]})
    , {});

    const chargeOnContract = _.concat(travellingExpenses, expOnContract);
    const componentChargeOnContract = groupByAndReceValuesAndTotal(chargeOnContract).map(o=>{
        o.title = o.component.name;
        o.type = "1";
        return o;
    });

    const componentChargeOnContractTotal = Object.assign(
        reduceValuesAndTotal(componentChargeOnContract),
        {title: "Charges sur contrat", type: "2"}
    );


    const turnoverAndchargeOnContract = _.concat(componentTurnoverTotal, componentChargeOnContractTotal);
    const marginOnContract = Object.assign(
        reduceValuesAndTotal(turnoverAndchargeOnContract),
        {title: "Marge sur contrat", type: "3"}
    );

    const marginOncontractInPercent = Object.assign(
        ratioValues(marginOnContract, componentTurnoverTotal),
        {title: "en %", type: "3"}
    );

    ///////////////////////////
    // Other Operating Costs //
    ///////////////////////////

    businessProject.otherOperatingCosts.forEach( oop => {
        const keys = {
            component: oop.component,
            uo: oop.otherOperatingCostUnitOfWork
        };
        otherOperatingCosts.push(Object.assign({}, keys, {values: otherOperatingCostsValues(oop)}));
    });

    otherOperatingCosts.map(o => {
        o.total = _.reduce(o.values, (o, n) => o + n, 0);
        o.title = o.component;
        o.type = "1";
        return o;
    });

    const otherOperatingCostsTotal = Object.assign(
        reduceValuesAndTotal(otherOperatingCosts),
        {title: "Charges structure & dvp", type: "2"}
    );


    const otherOperatingCostsTotalAndmarginOnContract = _.concat(otherOperatingCostsTotal, marginOnContract);

    const operationalResult = Object.assign(
        reduceValuesAndTotal(otherOperatingCostsTotalAndmarginOnContract),
        {title: "Résultat opérationnel", type: "3"}
    );

    const operationalResultInPercent = Object.assign(
        ratioValues(operationalResult, componentTurnoverTotal),
        {title: "en %", type: "3"}
    );

    ////////////////////////
    // Other Fiscal Costs //
    ////////////////////////

    businessProject.otherFiscalCosts.forEach( ofc => {
        const keys = {
            component: ofc.component,
            base: ofc.otherFiscalCostBase
        };
        switch (ofc.otherFiscalCostBase.id) {
            case "revenues":
                otherFiscalCosts.push(Object.assign({}, keys, {values: otherFiscalCostsValuesNegative(ofc, componentTurnoverTotal)}));
                break;
            case "contractExpenses":
                otherFiscalCosts.push(Object.assign({}, keys, {values: otherFiscalCostsValuesPositive(ofc, componentChargeOnContractTotal)}));
                break;
            case "contractMargin":
                otherFiscalCosts.push(Object.assign({}, keys, {values: otherFiscalCostsValuesNegative(ofc, marginOnContract)}));
                break;
            case "chargesStructure":
                otherFiscalCosts.push(Object.assign({}, keys, {values: otherFiscalCostsValuesPositive(ofc, otherOperatingCostsTotal)}));
                break;
            case "operatingIncome":
                otherFiscalCosts.push(Object.assign({}, keys, {values: otherFiscalCostsValuesNegative(ofc, operationalResult)}));
                break;
            default:
                console.log("formUtils.js")
        }
    });

    otherFiscalCosts.map(o => {
        o.title = o.component;
        o.type = "1";
        o.total = _.reduce(o.values, (o, n) => o + n, 0);
        return o;
    });

    const otherFiscalCostsTotal = Object.assign(
        reduceValuesAndTotal(otherFiscalCosts),
        {title: "Charges fiscales", type: "2"}
    );

    const operationalResultAndotherFiscalCostsTotal = _.concat(operationalResult, otherFiscalCostsTotal);

    const netProfit = Object.assign(
        reduceValuesAndTotal(operationalResultAndotherFiscalCostsTotal),
        {title: "Résultat net", type: "3"}
    );

    const netProfitInPercent = Object.assign(
        ratioValues(netProfit, componentTurnoverTotal),
        {title: "en %", type: "3"}
    );

    return {
        turnover,
        componentTurnover,
        componentTurnoverTotal,
        componentTurnoverTotalWithYear,
        componentChargeOnContract,
        componentChargeOnContractTotal,
        marginOnContract,
        marginOncontractInPercent,
        otherOperatingCosts,
        otherOperatingCostsTotal,

        operationalResult,
        operationalResultInPercent,
        otherFiscalCosts,
        otherFiscalCostsTotal,
        netProfit,
        netProfitInPercent
    };
};

export {
    businessPlanSummary,

    expOnContractWithProdValue,
    expOnContractWithoutProdValue,
    travellingExpensesValue,
    turnoverValue,
    otherOperatingCostsValue,

    expOnContractWithProdValues,
    expOnContractWithoutProdValues,
    travellingExpensesValues,
    turnoverValues,

    otherOperatingCostsValues,
    reduceValuesAndTotal,
    groupByAndReceValuesAndTotal,
    ratioValues
};
