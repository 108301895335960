import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logoKP from '../img/keenpoint-logo-horizontal.png'
import logoCNDA from '../img/logo_cnda.svg'
/*
import logoRTE from '../img/logo_RTE.png'
import logoHermes from '../img/logo_Hermes.png'
import logoEDF from '../img/logo_EDF.png'
import logoCELIO from '../img/logo_CELIO.png'
import logoUAPL from '../img/logo_uapl.png'
import logoCSE from '../img/logo_mycsemanco.png'
 */
import './login.css'
import DynamicStyledInput from "./Form/DynamicStyledInput";

const getLogo = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'rte':
        case 'hermes':
        case 'thom':
        case 'preprod':
            return logoKP
        case 'cnda':
            return logoCNDA
        default:
            return
    }
}

const getLogoStyle = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'rte':
        case 'hermes':
        case 'thom':
        case 'preprod':
            return {width: '200px'}
        case 'cnda':
            return {height: '200px'}
        default:
            return {width: '200px'}
    }
}

class Login extends Component {
    constructor() {
        super()

        this.state = {
            mail: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(field, event) {
        const newValue = event.target.value
        this.setState({
            [field]: newValue
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state)

    }

    render() {
        const {t, history, client} = this.props
        return (
            <div className="Login-container">
                <div className="Login-form-container">
                    <div className="Login-logo-container">
                        <img
                            alt="Login-logo"
                            src={getLogo(client)}
                            style={getLogoStyle(client)}
                        />
                    </div>
                    <div className= "Login-well">
                        <h2 className="Login-form-subtitle">
                            {t('typeEmailToChangePassword')}
                        </h2>
                        <form onSubmit={this.handleSubmit}>
                            <DynamicStyledInput
                                id="RecoverPass-emailAddress"
                                path="emailAddress"
                                type="text"
                                value={this.state.mail}
                                onChange={event => this.handleChange('mail', event)}
                                t={t}
                            />
                            <br />
                            <div className='Reset-pass-buttons'>
                                <button type="submit" className="btn Reset-pass-submit-button remove-button-outline" value="submit">
                                    {t('send')}
                                </button>
                                <button type="button" className="btn btn-default Reset-pass-cancel-button remove-button-outline" onClick={() => history.push('/login')}>
                                    {t('cancel')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Login

