export const entity = {
    name: 'Objective',
    facets: ['codeName'],
    fields: ['description']
}

export const module_ = {
    object: 'Objective',
    tKey: 'mTitle_objective',
    category: {
        path: 'referential',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'}

        ],
        form: [
            {path: 'code'},
            {path: 'name'},
            {path: 'description', type: 'textarea'}
        ]
    }
}
