const _ = require('lodash')

async function findData() {
    return [
        {
            id: 'holidaysVideo',
            link: `dashboard02_holidaysVideo.mp4`,
            poster: `dashboard02_holidaysVideo.png`
        }
    ]
}
export const entity = {
    name: 'HolidaysVideo',
    dashboard: true,
    fields: ['id', 'link', 'poster'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData()
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
