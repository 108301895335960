import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Field} from 'redux-form'
import { I18n } from 'react-i18next'
import DateRangePicker from '../../../../components/Calendar/DateRangePicker'
import { changeRangeCalendar } from '../../actions'
import {
    getModule,
    getDateRangeEndOpened,
    getDateRangeStartOpened,
    getLanguage
} from '../../selectors'

// binding for redux-form
const VisualComponent = ({
    input,
    path,
    names,
    startOpened,
    endOpened,
    language,
    filter,
    changeRangeCalendar,
    fetchFilterData,
    module,
}) => {

    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <DateRangePicker
                    path={path}
                    startDate={input.value[0]}
                    endDate={input.value[1]}
                    startOpened={startOpened}
                    endOpened={endOpened}
                    onChange={(index, date) => {
                        if (date) {
                            const newValue = input.value.slice()
                            newValue.splice(index, 1, date);

                            if(index === 0 && date > input.value[1]) {
                                newValue.splice(index, 1, null)
                            }
                            input.onChange(newValue)
                        }
                    }}
                    onOpenChange={(calendar, opened) => {
                        changeRangeCalendar(filter.id, calendar, opened)

                        // if we closed the calendar, launch the query after value is updated in state
                        if (calendar === 'endOpened' && !opened)
                            setTimeout(fetchFilterData, 50)
                    }}
                    language={language}
                    startPlaceholder={t('pickDate')}
                    endPlaceholder={t('pickDate')}
                    t={t}
                />
            )}
        </I18n>
    )
}

const DateRangePickerComponent = ({
    filter,
    startOpened,
    endOpened,
    module,
    language,
    changeRangeCalendar,
    fetchFilterData
}) => {
    const fieldName = filter.path

    return (
        <Field
            name={filter.path}
            //names={[`${fieldName}[0]`, `${fieldName}[1]`]}
            path={filter.tKey || filter.path}
            component={VisualComponent}
            startOpened={startOpened}
            endOpened={endOpened}
            language={language}
            filter={filter}
            changeRangeCalendar={changeRangeCalendar}
            fetchFilterData={fetchFilterData}
            module={module}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    startOpened: getDateRangeStartOpened(state, filter.id),
    endOpened: getDateRangeEndOpened(state, filter.id),
    language: getLanguage(state),
    module: getModule
})

const mapDispatchToProps = dispatch => ({
    changeRangeCalendar: (filterId, index, opened) =>
        dispatch(changeRangeCalendar(filterId, index, opened))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    DateRangePickerComponent
)
