import React from 'react'
import {Field, FieldArray} from 'redux-form'
import Comment from '../../../../components/Comment'
import { I18n } from 'react-i18next'
import {getModule, getUserIdAndName} from '../../selectors'
import {connect} from 'react-redux'
import {TextField} from "../../../../components/Form";
// binding for redux-form

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const VisualComponent = ({
    fields: { push, getAll }, editable, disabled,
    placeholder, noRowsMessage, tKeyText, tKeyUser, tKeyDate, validationMessage,
    textWith, columns, module, user
}) => {
    const objects = getAll()

    const allIds = objects && objects.map(o => o.id)
    const byId = arrayToObject(objects)

    return (
        <I18n ns={[module.model, 'platform']}>
            {t =>
                <Comment
                    user={user}
                    byId={byId}
                    allIds={allIds}
                    columns={columns}
                    editable={editable}
                    disabled={disabled}
                    textWith={textWith}
                    push={push}
                    placeholder={placeholder}
                    noRowsMessage={noRowsMessage}
                    tKeyText={tKeyText}
                    tKeyUser={tKeyUser}
                    tKeyDate={tKeyDate}
                    validationMessage={validationMessage}
                    t={t}
                />
            }
        </I18n>
    )
}

const CommentField = ({ field, module, user, objectMode }) => {
    return (
        <FieldArray
            name={field.path}
            placeholder={field.placeholder}
            noRowsMessage={field.noRowsMessage}
            tKeyText={field.tKeyText}
            tKeyUser={field.tKeyUser}
            tKeyDate={field.tKeyDate}
            validationMessage={field.validationMessage}
            component={VisualComponent}
            disabled={field.disabled || !field.writable}
            textWith={field.textWidth}
            editable={!field.editable ? objectMode === 'newObject' : field.editable}
            columns={field.fields}
            module={module}
            user={user}
            rerenderOnEveryChange={true}
        />
    )
}
const mapStateToProps = (state) => ({
    module: getModule(state),
    user: getUserIdAndName(state),
})


export default connect(mapStateToProps)(CommentField)
