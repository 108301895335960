const _ = require('lodash')

export default {
    id: 'K',
    name: 'Actions',
    description: '',
    entities: _.concat(
        require('./keenpointActions/staticEntities').entities,
        require('./keenpointActions/entitiesReferential').entities,
        require('./keenpointActions/entitiesActions').entities,
        require('./keenpointActions/entitiesActivities').entities
    ),
    modules:
        _.concat(
            require('./keenpointActions/modulesReferential').modules,
            require('./keenpointActions/modulesActions').modules,
            require('./keenpointActions/modulesActivities').modules
        ),

}
