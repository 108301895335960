import async from "async"
import Errors from "../../utils/Errors"
const { basicContext } = require('../../utils/contextUtils')
import {
    changeFieldDisabled,
    setFieldEdition,
    setFieldListOptions,
    setFieldVisibility
} from "../../../apps/KpModule/actions"
import {getDataListList} from "../../../apps/KpModule/selectors"
import {getFieldModuleForm} from './themeConfig'

const _ = require("lodash");

function updateFieldsOptions({themeConfig, store}) {
    const state = store.getState()

    const fieldsByDatalist = {
        'm-S-subTheme.ThemeCondition_source': 'e_conditions.e_source',
        'm-S-subTheme.ThemeCondition_source2': 'e_conditions.e_source2',
        'm-S-subTheme.SubThemeElement_codeSource': 'e_subThemeElements.e_codeSource'
    }

    Object.keys(fieldsByDatalist).forEach(dataList => {
        const options = getDataListList(state, dataList)

        const filteredOptions = options.filter(
            option => _.get(option, 'inputCorrespondenceByImportType.id') === _.get(themeConfig, 'stream.id')
        )

        store.dispatch(
            setFieldListOptions(fieldsByDatalist[dataList], _.map(filteredOptions, "id"))
        );
    })
}

export const dependencies = [
    {
        name: "SubThemeElement",
        facets: ["translatedField"],
        fields: [
            {
                path: "code",
                ps: {object: ["mongoFieldName"]},
                notEmpty: true,
                index: true
            },
            "name",
            {
                path: "codeSource",
                type: "InputCorrespondence",
                link: "MTO"
            },
            "value",
            {
                path: "completeCode",
                fieldPath: ["code", "subTheme.completeCode"],
                f: function() {
                    return `${this.subTheme.completeCode}.${this.code}`;
                }
            }
        ],
        ps: {
            delete: [
                {
                    $$v: function (object, callback) {
                        async.parallel({
                            alertConfiguration: callback =>  global.app.S.AlertConfiguration.collection.findOne({$or: [
                                    {'alertFields.themeJoin': new global.ObjectID(object.id)},
                                    {'alertFields.denominatorTheme': new global.ObjectID(object.id)},
                                ]}, callback),
                            scoringConfig: callback =>  global.app.S.ScoringConfig.collection.findOne({$or: [
                                    {'scoringFields.numeratorTheme': new global.ObjectID(object.id)},
                                    {'scoringFields.denominatorTheme': new global.ObjectID(object.id)},
                                ]}, callback),
                        }, (e, results) => {
                            if (e) return callback(e)
                            if(results.alertConfiguration) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInAlertConfiguration')))
                            if(results.scoringConfig) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInScoringConfiguration')))

                            callback()
                            global.db.collection('slp.elemData').updateMany(
                                {[`theme-${object.code}`]:{$exists: true}},
                                {$unset: {[`theme-${object.code}`]: ''}}
                            )

                        })
                    }
                }
            ]
        }
    }
]

export const entity = {
    name: "SubTheme",
    facets: ["translatedField"],
    fields: [
        {
            path: "code",
            ps: {object: ["mongoFieldName"]},
            notEmpty: true,
            index: true,
            unique: true,
            uniqueWith: "themeConfig"
        },
        "name",
        {
            type: "ThemeConfig",
            link: {
                type: "MTO",
                oppositeField: {
                    path: "subThemes"
                }
            }
        },
        {
            type: "SubThemeElement",
            link: {
                type: "OTM",
                /*
                oppositeField: {
                    path: "subTheme"
                }
                 */
            }
        },
        {path: "conditions", type: "ThemeCondition", link: "OTM"},
        {
            path: "completeCode",
            fieldPath: ["themeConfig.completeCode"],
            f: function() {
                return `${this.themeConfig.completeCode}.${this.code}`;
            }
        },
        {
            path: "subThemeElementsNumber",
            fieldPath: ["subThemeElements.id"],
            f: function() {
                return this.subThemeElements.length
            }
        },
        {
            path: 'hasCalculatedData',
            fieldPath: ["themeConfig.code"],
            $f: (subTheme, context, callback) => {
                global.app.S.ElemDataLine.collection
                    .findOne(
                        {[`theme-${subTheme.themeConfig.code}.${subTheme.code}`]: { $exists: true}},
                        (e, theme) => {
                            if (e) return callback(e)
                            callback(null, !!theme)
                        })
            }
        }
    ],
    nameCannotContainHyphens: (newObject, oldObject, context, callback) => {
        const containsHyphens = newObject.name.indexOf('-') !== -1
        if(containsHyphens) return callback(new Errors.ValidationError('nameCannotContainHyphens'))
        const subThemeElementContainsHyphen = newObject.subThemeElements.some(subThemeElem => subThemeElem.name.indexOf('-') !== -1)
        if(subThemeElementContainsHyphen) return callback(new Errors.ValidationError('subThemeElementNameCannotContainHyphens'))
        return callback()
    },
    uniqueSubThemeElements: (newObject, oldObject, context, callback) => {
        const codes = newObject.subThemeElements
            .map(elem => elem.code)
        const duplicated = codes.some((code, index) => codes.indexOf(code) !== index)
        if(duplicated) return callback(new Errors.ValidationError('subThemeElementsShouldBeUnique'))
        return callback()
    },
    canNotDeleteUsedSubThemeElement: (object, oldObject, context, callback) => {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C') return callback()
        const deletedSubThemeElements = oldObject.subThemeElements.filter(e => !object.subThemeElements.some(o => o.id === e.id))
        async.each(
            deletedSubThemeElements,
            (subThemeElement, callback) => {
                async.parallel({
                    alertConfiguration: callback =>  global.app.S.AlertConfiguration.collection.findOne({$or: [
                            {'alertFields.themeJoin': new global.ObjectID(subThemeElement.id)},
                            {'alertFields.denominatorTheme': new global.ObjectID(subThemeElement.id)},
                        ]}, callback),
                    scoringConfig: callback =>  global.app.S.ScoringConfig.collection.findOne({$or: [
                            {'scoringFields.numeratorTheme': new global.ObjectID(subThemeElement.id)},
                            {'scoringFields.denominatorTheme': new global.ObjectID(subThemeElement.id)},
                        ]}, callback),
                }, (e, results) => {
                    if (e) return callback(e)
                    if(results.alertConfiguration) {
                        return callback(new Errors.ValidationError(context.tc('cannotDeleteSubThemeElementUsedInAlertConfiguration', {subThemeElementCode: subThemeElement.code})))
                    }
                    if(results.scoringConfig) {
                        return callback(new Errors.ValidationError(context.tc('cannotDeleteSubThemeElementUsedInScoringConfiguration', {subThemeElementCode: subThemeElement.code})))
                    }
                })
            },
            callback
        )
    },
    cannotModifyUsedSubTheme: (object, oldObject, context, callback) => {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C' || object.code === oldObject.code) return callback()
        global.app.S.ElemDataLine.collection
            .findOne(
                {[`theme-${oldObject.themeConfig.code}.${oldObject.code}`]: { $exists: true}},
                (e, theme) => {
                    if (e) return callback(e)
                    if (!!theme) return callback(new Errors.ValidationError('cannotChangeUsedSubThemeCode'))
                    return callback()
                })
    },
    cannotModifyUsedSubThemeElements: (object, oldObject, context, callback) => {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C') return callback()
        const deletedSubThemeElements = oldObject.subThemeElements.filter(e => !object.subThemeElements.some(o => o.code === e.code))

        async.parallel(deletedSubThemeElements.map(subThemeElement => callback => {
            global.app.S.ElemDataLine.collection
                .findOne(
                    {[`theme-${oldObject.themeConfig.code}.${oldObject.code}.${subThemeElement.code}`]: { $exists: true}},
                    (e, theme) => {
                        if (e) return callback(e)
                        if (!!theme) return callback(new Errors.ValidationError('cannotChangeUsedSubThemeElementCode'))
                        return callback()
                    })
        }), callback)
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.nameCannotContainHyphens(newObject, oldObject, context, callback),
            callback => this.uniqueSubThemeElements(newObject, oldObject, context, callback),
            callback => this.canNotDeleteUsedSubThemeElement(newObject, oldObject, context, callback),
            callback => this.cannotModifyUsedSubTheme(newObject, oldObject, context, callback),
            callback => this.cannotModifyUsedSubThemeElements(newObject, oldObject, context, callback)
        ], callback)
    },
    afterSave: function(object, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C') return callback()

        const deletedSubThemeElements = oldObject.subThemeElements.filter(e => !object.subThemeElements.some(o => o.code === e.code))
        async.each(
            deletedSubThemeElements,
            (subThemeElement, callback) => {
                global.db.collection('slp.elemData').updateMany(
                    {[`theme-${object.themeConfig.code}.${object.code}.${subThemeElement.code}`]:{$exists: true}},
                    {$unset: {[`theme-${object.themeConfig.code}.${object.code}.${subThemeElement.code}`]: ''}},
                    callback
                )
            },
            callback
        )
    },
    ps: {
        delete: [
            {
                $$v: function (object, callback) {
                    async.parallel({
                        themeConfig: callback => global.app.S.ThemeConfig.collection.findOne({_id: object.themeConfig}, callback),
                        alertConfiguration: callback =>  global.app.S.AlertConfiguration.collection.findOne({$or: [
                                {'alertFields.themeJoin': new global.ObjectID(object.id)},
                                {'alertFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                        scoringConfig: callback =>  global.app.S.ScoringConfig.collection.findOne({$or: [
                                {'scoringFields.numeratorTheme': new global.ObjectID(object.id)},
                                {'scoringFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                    }, (e, results) => {
                        if (e) return callback(e)
                        if(results.alertConfiguration) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteSubThemeUsedInAlertConfiguration', {subThemeCode: object.code})))
                        if(results.scoringConfig) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteSubThemeUsedInScoringConfiguration', {subThemeCode: object.code})))

                        global.db.collection('slp.elemData').updateMany(
                            {[`theme-${results.themeConfig.code}.${object.code}`]:{$exists: true}},
                            {$unset: {[`theme-${results.themeConfig.code}.${object.code}`]: ''}},
                            callback
                        )

                    })
                }
            }
        ]
    }
}

export const module_ = {
    object: "SubTheme",
    tKey: "mTitle_subTheme",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "code",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            {
                path: "themeConfig",
                tKey: "themeConfig",
                display: "tName",
                initiallyNotVisible: true
            },
            "code",
            {path: "name", type: "translatedText"},
            {path: 'subThemeElementsNumber', tKey: 'subThemeElements'}
        ],
        form: {
            fields: [
                {
                    path: "themeConfig",
                    tKey: "themeConfig",
                    display: "tName",
                    fieldPath: ["id", "tName", "stream.id"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            updateFieldsOptions({themeConfig: newValue, store})
                        }
                    }
                },
                {
                    path: "code",
                    //editable: false
                },
                {path: "name", type: "textarea", required: true},
                {
                    path: "conditions",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "operator", translate: true},
                            {path: "source2", tKey: "compareTo"},
                            {path: "condValue", tKey: "value"},
                            {path: "condValues", tKey: "values"}
                        ],
                        form: [
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id']},
                            {
                                path: "source2",
                                tKey: "compareTo",
                                fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {

                                        const condValueField = getFieldModuleForm('conditions', 'condValue', module)
                                        const condValuesField = getFieldModuleForm('conditions', 'condValues', module)

                                        if(!newValue) {
                                            condValueField.setValue(null)
                                        }

                                        store.dispatch(setFieldVisibility(condValueField.id, !newValue))
                                        store.dispatch(setFieldVisibility(condValuesField.id, !newValue))
                                    }
                                }
                            },
                            {
                                path: "operator",
                                translate: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store}) => {
                                        const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                        store.dispatch(
                                            setFieldVisibility(
                                                "e_conditions.e_condValues",
                                                showValuesField
                                            )
                                        )
                                        store.dispatch(
                                            setFieldVisibility(
                                                "e_conditions.e_condValue",
                                                !showValuesField
                                            )
                                        )
                                    }
                                }
                            },
                            {path: "condValue", tKey: "value"},
                            {path: "condValues", tKey: "values", type: 'creatableTags'}
                        ]
                    }
                },
                {
                    path: "subThemeElements",
                    tKey: "subThemeElements",
                    removable: true,
                    viewMap: {
                        dt: [
                            "code",
                            {path:"name", type: "translatedText"},
                            "codeSource",
                            {path:"value", tKey: "sourceValue"}
                        ],
                        form: [
                            {
                                path: "code",
                                editable: false
                            },
                            {path: "name", type: "textarea", required: true},
                            {path: "codeSource", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id']},
                            {path:"value", tKey: "sourceValue", required: true}
                        ]
                    }
                },
                //{ path: 'hasCalculatedData', hidden: true }
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                /*
                const hasCalculatedData = _.get(state, 'edit.object.data.hasCalculatedData')
                const fields = ['e_code', 'e_themeConfig']
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, hasCalculatedData)))
                store.dispatch(setFieldEdition('e_subThemeElements', !hasCalculatedData))
                 */
                const themeConfig = _.get(state, 'edit.object.data.themeConfig')

                updateFieldsOptions({themeConfig, store})
            }
        }
    },
    filters: [
        {
            path: "themeConfig",
            object: "ThemeConfig",
            client: true,
            display: "tName",
            clearable: false,
            query: function (context) {
                const themeConfigId = _.get(context, "data.themeConfig.id");
                if (themeConfigId) return {
                    "themeConfig": global.ObjectID(themeConfigId)
                };
            }
        }
    ]
}
