const {fieldPathJoinGetter} = require("../../utils/kp3Utils");

export const dependencies = [
    {
        name: 'ProductUnit',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' },
            { id: 'C', name: 'C' },
            { id: 'D', name: 'D' }
        ]
    },
    {
        name: 'ProductStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'active', name: 'Actif' },
            { id: 'inactive', name: 'Inactif' }
        ]
    }
]

export const entity = {
    name: 'Product',
    facets: [
        { name: 'codeName', nameOptional: false, uniqueName: true },
        'translatedField'
    ],
    fields: [
        'ProductUnit',
        { path: 'barCode', type: 'integer' },
        { type: 'ProductStatus', default: { id: 'active' } },
        { path: 'attachments', type: 'ProductAxis', link: 'MTM' },
        fieldPathJoinGetter({path: "tFullName", fieldPath: ["code", "tName"]})
    ]
}

export const module_ = {
    object: 'Product',
    tKey: 'mTitle_product',
    category: {
        path: 'product',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'code',
            'name',
            'productUnit',
            'barCode',
            'productStatus',
            'attachments'
        ],
        form: [
            'code',
            'name',
            'productUnit',
            'barCode',
            'productStatus',
            { path: 'attachments', type: 'tags' }
        ]
    }
}
