import _ from "lodash";
import {basicContext} from "../../../../utils/contextUtils";

export const module_ = {
    name: 'FollowUp',
    object: 'ReliefFund',
    tKey: 'Mes dossiers',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    updatable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            {path: 'status', tKey: 'Statut', translate: true}
        ],
        form: {
            fields: [
                {path: 'object', editable: false},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', editable: false},
                {
                    path: 'needs',
                    tKey: 'Besoins',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount', tKey: 'Mt total (€)' },
                            { path: 'requestedAmount', tKey: 'Mt demandé (€)' },
                            { path: 'awardedAmount', tKey: 'Mt Accordé (€)'}
                        ],
                        form: [
                            { path: 'natureOfHelp', editable: false},
                            { path: 'totalAmount', tKey: 'Mt total (€)', editable: false },
                            { path: 'requestedAmount', tKey: 'Mt demandé (€)', editable: false },
                            { path: 'awardedAmount', tKey: 'Mt Accordé (€)', editable: false}
                        ]
                    }
                },
                {path: 'files', tKey: 'Fichiers', editable: false},
                {path: 'comments', tKey: 'Commentaires', editable: false},
                {path: 'decision', tKey: 'Décision du Conseil d’Administration', type: 'richTextEditor', editable: false},
                {path: 'status', translate: true},
                {path: 'buttons', hidden: true}
            ]
        }
    },
    filters: [
        'userHasAuthorization',
        {
            name: 'excludeDraft',
            query: () => {
                return { status: { $ne: 'draft' } }
            }
        },
        {
            title: "Status",
            path: "status",
            tKey: "Statut",
            translate: true,
            type: 'tags',
            default: [{id: 'accepted'}, {id: 'paid'}],
            filters: [
                {
                    name: 'excludeDraft',
                    match: object => {
                        return object.id !== 'draft'
                    }
                }
            ],
            object: "ReliedFundApplicationStatus",
            display: "name",
            client: true,
            isDefault: false,
            query: function (context) {
                const statusArray = _.get(context, "data.status")

                return statusArray
                    ? {status: {$in: statusArray.map(o => o.id)}}
                    : {status: false}
            }
        },
        {
            path: "authorizedFiles",
            isDefault: false,
            async: true,
            query: async (context, callback) => {
                try {
                    const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                        ...basicContext(context),
                        fieldPath: [
                            'id',
                            'functions.id',
                            'functions.submissionAuthorization',
                            'functions.studyAuthorization',
                            'functions.decisionAuthorization',
                            'functions.paymentAuthorization',
                            'organization.id',
                            'shelters.id'
                        ]
                    })
                    const userIsAdmin = ['studyAuthorization', 'decisionAuthorization', 'paymentAuthorization'].some(
                        key => cUser.functions.some(
                            userFunction => !!userFunction[key]
                        )
                    )
                    const userIsSubmitter = cUser.functions.some(
                        userFunction => !!userFunction['submissionAuthorization']
                    )

                    callback(null, userIsAdmin
                        ? {}
                        : userIsSubmitter
                            ? {organization: global.ObjectID(cUser.organization.id)}
                            : {_id: null}
                    )
                } catch (e) {
                    callback(e)
                }

            }
        },
    ]
}
