import _ from 'lodash'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import ValidationUI from "../ValidationUI"
import './PhoneNumberInputField.css'

export default function PhoneNumberInputField({path, value, customOnChange, required, t, language, disabled, touched, error}){
    const localization = require(`../../utils/localization/${language}.json`)
    return(
        <div className="PhoneNumberInput-group">
            <label className="PhoneNumberInput-label">
                {t(path)} {required && "*"}
            </label>
            <PhoneInput
                country="fr"
                localization={localization}
                value={value}
                onChange={customOnChange}
                isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                onlyCountries={['fr', 'gb', 'es', 'it', 'nl']}
                specialLabel=""
                inputClass="PhoneNumberInput-input"
                disabled={disabled}
            />
            { touched && (error && <ValidationUI error={error}/>) }
        </div>

    );
}

