import {entities} from "./suiviEngagement/tracking/trackingByMonth";

const _ = require('lodash')

export default {
    id: 'SE',
    name: 'SuiviEngagement',
    description: '',
    entities: _.concat(
        [
            require('./suiviEngagement/demandAndProject/projectAndDemandEntity').entity,
            require('./suiviEngagement/demandAndProject/largeImputation').entity,
            require('./suiviEngagement/workFlow/demandCategory').entity,
            require('./suiviEngagement/workFlow/demandNature').entity,
            require('./suiviEngagement/settings/imputationType').entity,
            require('./suiviEngagement/workFlow/minMaxAmount').entity,
            require('./suiviEngagement/workFlow/role').entity,
            require('./suiviEngagement/workFlow/habilitation').entity,
            require('./suiviEngagement/referencial/newOrgnizationalStructure/structureElement').entity,
            require('./suiviEngagement/referencial/organizationalStructure/organizationalAxis').entity,
            require('./suiviEngagement/referencial/organizationalStructure/axisAndMesh').entity,
            require('./suiviEngagement/referencial/organizationalStructure/organization').entity,
            require('./suiviEngagement/referencial/organizationalStructure/organizationalMesh').entity,
            require('./suiviEngagement/referencial/organizationalStructure/organizationAndMesh').entity,
            require('./suiviEngagement/referencial/analyticalStructure/analyticalAxis').entity,
            require('./suiviEngagement/referencial/analyticalStructure/analyticalMesh').entity,
            require('./suiviEngagement/settings/fiscalYear').entity,
            require('./suiviEngagement/settings/meetingsPlanning').entity,
            require('./suiviEngagement/settings/currency').entity,
            require('./suiviEngagement/settings/realizationTypes').entity,
            require('./suiviEngagement/referencial/supplier').entity,
            require('./suiviEngagement/settings/budget').entity,
            require('./suiviEngagement/settings/budget').entity2,
            require('./suiviEngagement/settings/budget').entity3,
            require('./suiviEngagement/settings/pointsOfAttention').entity,
            require('./suiviEngagement/tracking/analysisOptionsJoinedEntity').entity,
            require('./suiviEngagement/tracking/perimeterJoinedEntity').entity,
            require('./suiviEngagement/tracking/amountTypeJoinedEntity').entity,
        ],
        require('./suiviEngagement/referencial/newOrgnizationalStructure/structure').entities,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/entityConfiguration').entities,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/entity').entities,
        require('./suiviEngagement/referencial/analyticalStructure/analyticalSubAxis').entities,
        require('./suiviEngagement/workFlow/workFlow').entities,
        require('./suiviEngagement/staticEntities').entities,
        require('./suiviEngagement/demandAndProject/imputations').entities,
        require('./suiviEngagement/settings/indicatorKey').entities,
        require('./suiviEngagement/referencial/funding').entities,
        require('./suiviEngagement/tracking/projectCommittee').entities,
        require('./suiviEngagement/tracking/extractedFiles').entities,
        require('./suiviEngagement/tracking/trackingByMonth').entities,
    ),


    modules: [
        require('./suiviEngagement/demandAndProject/project/preValidation').module_,
        require('./suiviEngagement/demandAndProject/project/instruction').module_,
        require('./suiviEngagement/demandAndProject/project/control').module_,
        require('./suiviEngagement/demandAndProject/project/validation').module_,
        require('./suiviEngagement/demandAndProject/project/realization').module_,
        require('./suiviEngagement/demandAndProject/project/arbitration').module_,
        require('./suiviEngagement/demandAndProject/project/consultation').module_,
        require('./suiviEngagement/demandAndProject/demand/demandInstruction').module_,
        require('./suiviEngagement/demandAndProject/demand/demandControl').module_,
        require('./suiviEngagement/demandAndProject/demand/demandValidation').module_,
        require('./suiviEngagement/demandAndProject/demand/demandArbitration').module_,
        require('./suiviEngagement/demandAndProject/demand/demandConsultation').module_,
        require('./suiviEngagement/tracking/projectCommittee').module_,
        require('./suiviEngagement/tracking/pilotageCommittee').module_,
        require('./suiviEngagement/tracking/projectCommitment').module_,
        require('./suiviEngagement/tracking/extractedFiles').module_,
        require('./suiviEngagement/tracking/trackingByMonth').module_,
        require('./suiviEngagement/workFlow/demandCategory').module_,
        require('./suiviEngagement/workFlow/demandNature').module_,
        require('./suiviEngagement/workFlow/minMaxAmount').module_,
        require('./suiviEngagement/workFlow/role').module_,
        require('./suiviEngagement/workFlow/habilitation').module_,
        require('./suiviEngagement/workFlow/workFlow').module_,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/structure').module_,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/entityConfiguration').module_,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/structureElement').module_,
        require('./suiviEngagement/referencial/newOrgnizationalStructure/entity').module_,
        require('./suiviEngagement/referencial/organizationalStructure/organizationalAxis').module_,
        require('./suiviEngagement/referencial/organizationalStructure/organization').module_,
        require('./suiviEngagement/referencial/organizationalStructure/organizationalMesh').module_,
        require('./suiviEngagement/referencial/analyticalStructure/analyticalAxis').module_,
        require('./suiviEngagement/referencial/analyticalStructure/analyticalSubAxis').module_,
        require('./suiviEngagement/referencial/analyticalStructure/analyticalMesh').module_,
        require('./suiviEngagement/settings/fiscalYear').module_,
        require('./suiviEngagement/settings/meetingsPlanning').module_,
        require('./suiviEngagement/settings/currency').module_,
        require('./suiviEngagement/settings/pointsOfAttention').module_,
        require('./suiviEngagement/settings/indicatorKey').module_,
        require('./suiviEngagement/referencial/funding').module_,
        require('./suiviEngagement/settings/realizationTypes').module_,
        require('./suiviEngagement/referencial/supplier').module_,
        require('./suiviEngagement/settings/budget').module_,
        require('./suiviEngagement/settings/imputationType').module_,
    ],
    jobs: [
        require('./suiviEngagement/jobs/purgeDemands').purgeProjectsAndDemandsJob,
        require('./suiviEngagement/jobs/purgeDemands').purgeAllJob,
        require('./suiviEngagement/jobs/purgeDemands').backupJob,
        require('./suiviEngagement/jobs/purgeDemands').resetJob,
        require('./suiviEngagement/jobs/purgeDemands').job
    ]
}
