const _ = require("lodash");

/*
* Check if both queries define an OR condition.
* If this is true, creates a new query with all OR conditions merged inside an AND
* */
function mergeOrQueries(query1, query2) {
    if (query1.$or && query2.$or) {
        const {
            $or: $or1,
            $and: $and1,
            ...rest1
        } = {  $and: [], ...query1 };

        const {
            $or: $or2,
            $and: $and2,
            ...rest2
        } = {  $and: [], ...query2 };

        return {
            ...rest1,
            ...rest2,
            $and: [...$and1, ...$and2, $or1, $or2]
        }
    } else {
        return {...query1, ...query2};
    }
}

/*
* Generate a query object to check if a given search text is present in any ot the keys.
*
* The search is case insensitive.
*
* Don't forget to add indexes for all keys in your Mongo collection!
*
* Returns a function that adds the query to the previous query, in case it comes with an $or query
* */
function textSearchQuery(search, keys) {
    if (!search || (search === "")) {
        // force to fastly find nothing
        return {_id: {$exists: false}};
    }

    const regExp = new RegExp(search, "i");

    const searchQuery = {$or: keys.map(key => ({
        [key]: regExp
    }))};

    return query => mergeOrQueries(query, searchQuery)
}

const isMongoField = fieldName => ! _.includes(["_id", "null"], fieldName) && ! _.startsWith(fieldName, "$") && ! _.includes(fieldName, ".");

const mongoIdToStringId = object => object.id || (object._id && object._id.toString()) || (object.oid && object.oid.toString());

function getNextSequence(collectionName, callback) {
    return new Promise((resolve, reject) => {
        global.db.collection("counters").findAndModify(
            {_id: collectionName},
            [],
            {$inc: {seq: 1}},
            {new: true, upsert: true},
            (error, result) => {
                if (error) {
                    reject(error);
                    return callback && callback(error);
                } else {
                    const counter = _.get(result, "value.seq");
                    if (isNaN(Number(counter))) {
                        reject(error);
                        return callback && callback(new Error(`Invalid sequence number ${counter}`))
                    } else {
                        resolve(counter);
                        return callback && callback(null, counter);
                    }
                }
            }
        );
    });
};

export {
    textSearchQuery,
    isMongoField,
    mongoIdToStringId,
    getNextSequence,
    mergeOrQueries
};
