export const entity = {
    name: 'AccountCategory',
    facets: [{ name: 'codeName', uniqueName: true }],
    fields: [
        { type: 'Kind', index: true },
        {
            path: 'nameWithContext',
            fieldPath: ['name', 'kind.name'],
            f: function () {
                return `${this.name} (${this.kind.name})`
            }
        }
    ]
}
export const module_ = {
    object: 'AccountCategory',
    tKey: 'mTitle_accountCategory',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: ['code', 'name', { path: 'kind', tKey: 'context', translate: true }],
        form: [
            { path: 'code', editable: false },
            'name',
            { path: 'kind', tKey: 'context', translate: true}
        ]
    }
}
