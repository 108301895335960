const _ = require('lodash')
const moment = require('moment')
const async = require("async")
const { basicContext } = require("../../../utils/contextUtils")
const { calculateFinancialSummaries } = require('../statesGeneration/financialSummaries')
const {getWorkflowModel} = require("../workflow/workflow")

const { businessPlanPdfConfig } = require("../pdfGeneration/faConf")
const { crrPdfConfig } = require("../pdfGeneration/crrConf")
const { delegationMailing } = require("../mails/workflowMails")

const fieldPathBPForBusinessSummary = [
    "beginYear.id",
    "workflow.step", "workflow.order", "workflow.maxOrders",
    "financialSummarys", "financialSummarys.id", "financialSummarys.nature.id",
    "financialSummarys.summaryTableHeaders.id",
    "financialSummarys.financialTurnover", "financialSummarys.financialExpenses",
    "financialSummarys.financialNumberOfDays"
];

export const createBPForAMI = (businessProject, context, callback) => {

    if (businessProject.workflow.step === "acceptedAmi") {
        const newBP = _.omit(businessProject, [
            'id',
            'code',
            'workflow',
            'comments',
            'files',
            'file'
        ])

        global.app.I.BusinessProject.save(
            {
                ...newBP,
                businessProject
            },
            {...basicContext(context), action: 'save'},
            callback
        )
    }
    else callback()

}

export const enhanceProjectComponents = (businessProject, context, callback) => {
    if (businessProject.workflow && businessProject.workflow.step === "realisation" && businessProject.workflow.order === 1) {
        const financialSummaryContext = {
            ...basicContext(context),
            fieldPath: ['id', 'lot', 'financialTurnover', 'financialExpenses', 'financialNumberOfDays'],
            query: {
                businessProject: new global.ObjectID(businessProject.id),
                projectNature: 'sale'
            }
        }

        async.waterfall([
            callback => global.app.I.FinancialSummary.find(financialSummaryContext, callback),
            (fss, callback) => {
                const projectComponents = fss.map(fs => ({
                    name: fs.lot,
                    expectedNumberOfDays: fs.financialNumberOfDays,
                    expectedCost: fs.financialExpenses,
                    expectedTurnover: fs.financialTurnover,
                    businessProject: new global.ObjectID(businessProject.id),
                }))

                if(projectComponents.length) {
                    global.db.collection("i.projectComponent").insertMany(
                        projectComponents,
                        callback
                    );
                }
                else {
                    callback()
                }
            }
        ], callback);
    }
    else callback()
}
export const initializeReferentialBusiness = (businessProject, context, callback) => {
    if (businessProject.workflow && context.action === "validate" && businessProject.workflow.step === "buildOffer" && businessProject.workflow.order === 1) {
        async.waterfall([
            callback => global.app.I.ReferentialBusiness.get(
                businessProject.id,
                {ignoreNotFound: true, group: context.group, user: context.user},
                callback
            ),
            (refBusiness, callback) => {
                if (!refBusiness) {
                    global.app.I.ReferentialBusiness.save(
                        {
                            code: businessProject.code,
                            name: businessProject.name,
                            businessProject: {id: businessProject.id},
                            country: {id: businessProject.country.id}
                        },
                        {
                            group: context.group,
                            user: context.user,
                            fieldPath: ["code", "name", "country.id", "businessProject.id", "businessProject.country.id"]
                        },
                        callback
                    )
                }
                else callback();
            }
        ], callback);
    }
    else callback()
}

export const financialStatementCalculation = (businessProjectId, context, callback) => {
    global.app.I.BusinessProject.get(
        businessProjectId,
        {...basicContext(context), fieldPath: fieldPathBPForBusinessSummary},
        (e, businessProject) => {
            if (e) return callback(e)

            const financialSummary = calculateFinancialSummaries(businessProject)
            let query = {financialStatement: financialSummary}
            switch (businessProject.workflow.step) {
                case 'formX':
                    query.financialStatementDetection = financialSummary
                    break
                case 'buildOffer':
                    query.financialStatementBuildOffer = financialSummary
                    break
                case 'submitOffer':
                    query.financialStatementContractualization = financialSummary
                    break
                case 'realisation':
                    query.financialStatementOperation = financialSummary
                    break
                default:
                    return callback()
            }

            global.db.collection("i.businessProject").updateOne(
                {_id: new global.ObjectID(businessProject.id)},
                {$set: query},
                callback
            );
        }
    )
}

export const businessProjectFilesGeneration = (businessProjectId, context, callback) => {
    global.app.I.BusinessProject.get(
        businessProjectId,
        context,
        (e, businessProject) => {
            if (e) return callback(e)

            const refBusiness = {
                code: _.head(businessProject.referentialBusinesss) ?
                    _.head(businessProject.referentialBusinesss).code :
                    businessProject.code ? businessProject.code : "tmp-code",
                name: _.head(businessProject.referentialBusinesss) ?
                    _.head(businessProject.referentialBusinesss).name :
                    businessProject.name ? businessProject.name : "tmp-name",
            };

            console.log("businessProjectFilesGeneration start")
            async.series({
                businessPlan: callback => global.pdf.generatePdf(businessPlanPdfConfig(
                    businessProject,
                    refBusiness,
                    `BP_${_.snakeCase(businessProject.name.replace(new RegExp("'", 'g'), ""))}.pdf`,
                    context
                ), callback),
                crr: callback => {
                    if(
                        businessProject.workflow &&
                        businessProject.workflow.step === "realisation" &&
                        context.module && ["bp5 bis", "projectMonitoring"].includes(context.module.name)
                    ){
                        global.pdf.generatePdf(crrPdfConfig(
                            businessProject,
                            refBusiness,
                            `CRR_${_.snakeCase(businessProject.name.replace(new RegExp("'", 'g'), ""))}.pdf`,
                            context
                        ), callback)
                    }else {
                        callback(null, false)
                    }
                }
            }, (e, results) => {
                console.log("businessProjectFilesGeneration finish", e, results)
                if (e) return callback(e)
                const {businessPlan, crr} = results
                let setQuery = {
                    file: businessPlan
                }
                if(crr) {
                    Object.assign(setQuery, {crrFile: crr})
                }
                let query = {$set: setQuery}

                if(crr && businessProject.sendReport){
                    let pushQuery = {
                        files: Object.assign({
                            date: moment().format("YYYY-MM-DD HH:mm"),
                            user: _.pick(context.user, ["id", "name"])
                        }, crr),
                        comments: {
                            _id: new global.ObjectID(),
                            user: _.pick(context.user, ["id", "name"]),
                            date: moment().format("YYYY-MM-DD HH:mm"),
                            text: "Compte rendu envoyé par mail"
                        }
                    }
                    Object.assign(query, {$push: pushQuery})
                }

                global.db.collection("i.businessProject").updateOne(
                    {_id: new global.ObjectID(businessProject.id)},
                    query,
                    callback
                );
            })
        }
    )
}


export const applyWorkflowDelegation = (businessProject, oldUser, teamMember, context, callback) => {


}

export const createBRLine = async (businessProject, oldBusinessProject, context, callback) => {
    const oldSignatureDate =  oldBusinessProject.signatureDate && oldBusinessProject.signatureDate.getTime()
    const newSignatureDate =  businessProject.signatureDate && businessProject.signatureDate.getTime()

    if(["submitOffer", "realisation"].includes(oldBusinessProject.workflow.step) && newSignatureDate && newSignatureDate !== oldSignatureDate) {
        const executedFollowUp =  await global.app.I.ExecutedFollowUp.collection.findOne(
            {
                businessProject: new global.ObjectID(businessProject._id),
                year: moment().year()
            }
        )
        const lastBR = _.last(_.orderBy(businessProject.businessRevues, 'date'))
        const brObject = {
            ..._.omit(lastBR, ['id']),
            comment: `Mise à jour en fonction de la date de signature : ${moment(businessProject.signatureDate).format('DD-MM-YYYY')}`,
            date: new Date(),
            executedTurnOver: executedFollowUp ? executedFollowUp.executedTurnOver : 0,
            user: new global.ObjectID(context.user.id),
            projectStep: businessProject.workflow.step,
            orderMonth: moment(businessProject.signatureDate).format('YYYY-MM'),
            businessProject: new global.ObjectID(businessProject.id),
            group: new global.ObjectID(context.group.id)
        }
        global.db.collection("i.businessRevue").insertOne(brObject, callback)
    } else if(oldBusinessProject.workflow.step !== businessProject.workflow.step) {
        const executedFollowUp =  await global.app.I.ExecutedFollowUp.collection.findOne(
            {
                businessProject: new global.ObjectID(businessProject._id),
                year: moment().year()
            }
        )
        const lastBR = _.last(_.orderBy(businessProject.businessRevues, 'date'))

        const brObject = {
            ..._.omit(lastBR, ['id']),
            comment: 'Reprise automatique suite au changement de statut',
            date: new Date(),
            executedTurnOver: executedFollowUp ? executedFollowUp.executedTurnOver : 0,
            user: new global.ObjectID(context.user.id),
            projectStep: businessProject.workflow.step,
            businessProject: new global.ObjectID(businessProject.id),
            group: new global.ObjectID(context.group.id)
        }
        global.db.collection("i.businessRevue").insertOne(brObject, callback)

    } else callback()
}

export const createFirstBR = (businessProject, context, callback) => {
    const brObject = {
        date: new Date(),
        user: new global.ObjectID(context.user.id),
        projectStep: 'formX',
        revised: businessProject.revisedTCA,
        yearN: businessProject.plannedCA,
        orderMonth: businessProject.brOrderTakingMonth,
        probability: businessProject.probability,
        yearNPlusOne: businessProject.plannedCAPlusOne,
        comment: 'Création automatique, suite à la détection d’un nouveau projet',
        businessProject: new global.ObjectID(businessProject.id),
        group: new global.ObjectID(context.group.id)
    }
    global.db.collection("i.businessRevue").insertOne(brObject, err => {
        if(err) return callback(err)
        global.db.collection("i.businessProject").updateOne(
            {_id: new global.ObjectID(businessProject.id)},
            {
                $set: {
                    revisedTCA: null,
                    plannedCA: null,
                    brOrderTakingMonth: null,
                    probability: null,
                    plannedCAPlusOne: null,
                }
            }, callback
        )
    })
}

export const updateBeginYear = (businessProject, oldBusinessProject, context, callback) => {

    const newSignatureYear =  businessProject.signatureDate && businessProject.signatureDate.getFullYear()

    if(["submitOffer", "realisation"].includes(oldBusinessProject.workflow.step) && newSignatureYear && newSignatureYear !== businessProject.beginYear?.id) {

        global.db.collection("i.businessProject").updateOne(
            {_id: new global.ObjectID(businessProject.id)},
            {$set: {beginYear: newSignatureYear}}
            , callback
        )
    } else callback()
}

export const updateBeginYearToMatchBr = async (businessProject, oldBusinessProject, context, callback) => {
    if(_.get(context, 'module.name') !== 'businessRevue' || businessProject.signatureDate) return callback()
    const newBusinessRevue = businessProject.businessRevues.find(br => {
        return !oldBusinessProject.businessRevues.some(oldBr => oldBr.id === br.id)
    })
    if(!newBusinessRevue) return callback()
    const newValues = {}
    const extractedYear = newBusinessRevue.orderMonth && parseInt(newBusinessRevue.orderMonth.split('-')[0])

    if(extractedYear && !businessProject.signatureDate) newValues.beginYear = extractedYear
    if(newBusinessRevue.revised) newValues.estimatedTurnover = newBusinessRevue.revised

    if(businessProject.estimatedTurnover !== newValues.estimatedTurnover) {
        const bpWorkflowModel = await getWorkflowModel(businessProject, context)
        const nextBpWorkflowModel = await getWorkflowModel({...businessProject, estimatedTurnover: newValues.estimatedTurnover}, context)

        if(bpWorkflowModel?.id !== nextBpWorkflowModel?.id) {
            newValues.workflow = {
                ...businessProject.workflow,
                order: 1
            }
        }
    }

    global.db.collection("i.businessProject").updateOne(
        {_id: new global.ObjectID(businessProject.id)},
        {$set: newValues},
        callback
    )
}

export const addNewActiveUserToCurrentWorkflow = (businessProject, teamMember, callback) => {
    async.waterfall([
        callback => global.app.I.TeamMember.collection.findOne({_id: new global.ObjectID(teamMember.id)}, callback),
        (teamMember, callback) => global.app.I.StatusUser.collection.findOne({
                businessProject: new global.ObjectID(businessProject.id),
                active: true
            },
            (e, statusUser) => {
                if(e) return callback(e)
                callback(null, statusUser, teamMember)
            }
        ),
        (statusUser, teamMember, callback) => global.app.I.StatusUser.collection.insertOne({
            ..._.omit(statusUser, ['_id', 'user', 'teamMember']),
            user: teamMember.user,
            teamMember: teamMember._id
        }, callback),
    ], callback)
}

export const fieldPathBusinessProject = [
    "fullName",
    "beginYear.id",
    "country.id", "country.name", "country.securityLevel.name", "country.nameAndSecurity",
    "customer.id", "customer.name", "customer.codeNameRelation",
    "typeOfOffer.id", "typeOfOffer.name",
    "code", "estimatedTurnover", "globalTurnover", "estimatedNumberOfDays", "offerSubmissionDate",
    "opportunitys.id", "opportunitys.name", "securityLevel.id", "securityLevel.name", "risk.id.","risk.name",
    "projectFunding.id", "projectFunding.name", "responseMode.id", "responseMode.name",
    "interventionMode.id", "interventionMode.name", "contractualSchema.id", "contractualSchema.name",
    "competitors.id", "competitors.name", "partners.id", "partners.name",
    "originOfTheCase.id", "originOfTheCase.name", "descriptionOfOrigin",
    "referentialBusinesss.id", "referentialBusinesss.code", "referentialBusinesss.name",
    "marginOverContract.id", "marginOverContract.name", "sumMarginOverContract", "sumTurnover",
    "commercialTeamMember.id", "commercialTeamMember.fullName",
    "technicalTeamMember.id", "technicalTeamMember.fullName",
    "monitoringTeamMembers.id", "monitoringTeamMembers.firstName", "monitoringTeamMembers.lastName", "monitoringTeamMembers.email", "monitoringTeamMembers.fullName",
    "compensationModes.id", "compensationModes.name",
    "resourceTypes.id", "resourceTypes.name",

    "executedFollowUps.id", "executedFollowUps.year",
    "projectComponents.id",
    "billingFollowUps.id",
    "businessRevues.id",

    "actualMarginInPercent",
    "committedMarginInPercent",

    "expectedTurnOver",
    "sumActualTurnOver",
    "sumActualBilled",
    "sumActualContractExpenses",
    "sumActualCashed",
    "expectedContractCost",
    "sumCommittedExpenses",
    "expectedContractMarginInPercent",
    "expectedNumberOfDays",
    "sumActualNumberOfDays",
    "sumCommittedNumberOfDays",

    "perDiem", "travelCost", "businessProject.identifier",
    "workflow.step", "workflow.order", "workflow.maxOrders",
    "file.id",
    "crrFile.id",
    "excel.id",

    "partnersName", "subcontractorsName", "competitorsName",
    "partners.name", "subcontractors.name", "competitors.name",
    "financialStatement.*",
    "financialStatementDetection.*",

    "workflow.step",

    "recipients.email", "recipients.firstName"

];
