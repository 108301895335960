const _ = require("lodash");

function newContext(oldContext, newContext) {
    return _.defaults(newContext, _.omit(oldContext, ["prepared", "client", "entity", "id", "access", "action", "fields", "query", "filters", "fieldPath", "internalFieldPath", "ignoreNotFound",
        "newObject", "businessOldObject", "businessNewObject", "objects", "e"]));
}

/*
* Replicates the basic data of a context, to be used to execute something (find, get...) in another entity
* */
function basicContext(context={}) {
    return _.pick(context, ["group", "groupModel", "user", "t", "tc", "tl", "translateName", "host"])
}

export {
    newContext,
    basicContext
};
