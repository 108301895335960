import React, {Component} from 'react'
import {Field} from 'redux-form'
import {getModuleIsFetching} from '../../selectors'
import {connect} from 'react-redux'
import './FilterTextField.css'
import {StyledInput} from "../../../../components/Form/StyledInput";

const getWidthClass = width => {
    if(width) return `col-xs-12 col-sm-12 col-md-${width} col-lg-${width}`
    else return 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
}

class Input extends Component {
    constructor(props){
        super(props)
        this.state = {
            value: this.props.input.value,
            timeLeft: this.props.timer,
            timerIsOn: false
        }
        this.timer = 0
        this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.input.value !== this.props.input.value) {
            this.setState({ value: nextProps.input.value });
        }
    }

    startTimer() {
        if(!this.props.timer) return this.props.input.onChange(this.state.value)
        clearInterval(this.timer)
        this.setState({
            timerIsOn: false
        }, () => {
            setTimeout(() => {
                this.setState({timeLeft: this.props.timer, timerIsOn: true}, () => {
                    this.timer = setInterval(this.countDown, 1000);
                })
            }, 1)
        })
    }

    countDown() {
        this.setState({timeLeft: this.state.timeLeft - 1}, () => {
            if(this.state.timeLeft === 0) {
                clearInterval(this.timer)
                this.setState(
                    {timerIsOn: false},
                    () => this.props.input.onChange(this.state.value)
                )
            }
        })
    }
    render() {
        const {path, isFetching, placeholder, timer, t, width} = this.props
        const {value, timerIsOn, timeLeft} = this.state

        return <div className={`${getWidthClass(width)}`}>
            <StyledInput
                value={value}
                onChange={event => {
                    this.setState({
                        value: event.target.value
                    }, () => this.startTimer())
                }}
                path={path}
                disabled={isFetching}
                width={width}
                placeholder={t(placeholder || 'typeSomething')}
                timer={timer}
                timerIsOn={timerIsOn}
                timeLeft={timeLeft}
                t={t}
            />
        </div>
    }

}

const FilterField = ({ filter, isFetching, onChange, fetchFilterData, t }) => (
    <Field
        name={filter.path}
        path={filter.tKey || filter.path}
        placeholder={filter.placeholder}
        timer={filter.timer}
        type={filter.type}
        width={filter.width}
        component={Input}
        isFetching={isFetching}
        onChange={(event, value) => {
            // we need to call fetchDtData after reduxFormChange
            event.preventDefault()
            onChange(filter.path, value)
            if(!!filter.autoFetch) {
                fetchFilterData()
            }
        }}
        t={t}
    />
)

const mapStateToProps = (state) => ({
    isFetching: getModuleIsFetching(state)
})

export default connect(mapStateToProps)(FilterField)
