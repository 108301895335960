import React from 'react'
import { FieldArray } from 'redux-form'
import VideosUploader from './VideosUploader'
import { I18n } from 'react-i18next'


const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const VisualComponent = ({fields:{ push, remove, getAll }, module, user, editable}) => {
    const objects = getAll()
    const allIds = objects && objects.map(o => o.id)
    const byId = arrayToObject(objects)

    return (
        <I18n ns={[module.model, 'platform']}>
            {t =>
                <VideosUploader
                    byId={byId}
                    allIds={allIds}
                    push={push}
                    remove={remove}
                    t={t}
                    user={user}
                    editable={editable}
                />
            }
        </I18n>

    )
}

// binding for redux-form
const VideosUploaderField = ({ field, module, user }) => {
    return (
        <FieldArray
            name={field.path}
            component={VisualComponent}
            module={module}
            rerenderOnEveryChange={true}
            user={user}
            editable={field.editable}
        />
    )
}


export default VideosUploaderField
