import React from 'react'
import DataTableFilter from '../DataTable/DataTableFilter'
import {defaultTableRowRenderer} from "react-virtualized";
import {Table, AutoSizer} from '../DataTable'
import { HoverTooltip } from '../HoverTooltip'
import {Column} from 'react-virtualized/dist/commonjs/Table'
import ChevronsLeft from "react-feather/dist/icons/chevrons-left";
import ChevronsRight from "react-feather/dist/icons/chevrons-right";
import "./DualBox.css";

export const BoxList = (props) => {
    const { columnLabel, position, objects, handleRowClick, handleButtonCLick, disabled, filterValue, handleFilterChange, height, tooltip, t} = props
    return (
        <AutoSizer disableHeight>
            {({ width }) => (
                <div
                    className={"DualBox-Scrollbox"}
                    style={{ height, width }}
                >
                    <div className={"DualBoxButtonDiv"} style={{ width }}>
                        <HoverTooltip
                            id="DataTableFilter-tooltip"
                            message={t(position === 'left' ? 'addAllElements' : 'removeAllElements')}
                            placement="bottom"
                        >
                            <button
                                className={"btn btn-default DualBoxBoardButton"}
                                type="button"
                                onClick={handleButtonCLick}
                            >
                                {position === 'left' ? <ChevronsRight size={17} /> : <ChevronsLeft size={17} />}
                            </button>
                        </HoverTooltip>
                        <DataTableFilter
                            value={filterValue}
                            onChange={handleFilterChange}
                            placeholder={"Rechercher"}
                            tooltip={t('elementTooltip', {
                                count: objects.length
                            })}
                        />
                    </div>
                    <Table
                        objects={objects}
                        width={width}
                        style={{border: '1px solid #e5e5e5', borderRadius: '4px'}}
                        rowClassName={disabled ? 'disabled': ''}
                        rowRenderer={(object) => {
                            return (
                                <HoverTooltip
                                    id="DataTableFilter-tooltip"
                                    message={t(tooltip)}
                                    placement="bottom"
                                >
                                    {defaultTableRowRenderer(object)}
                                </HoverTooltip>
                            )
                        }}
                        maxRows={4}
                        onRowClick={handleRowClick}
                        noRowsLabel={"Aucun élément disponible dans cette liste"}
                    >
                        <Column label={columnLabel} dataKey={"label"}/>
                    </Table>
                </div>
            )}
        </AutoSizer>
    )
}
