import { INITIALIZE_MODULE } from '../actions/index'

const user = (
    state=null,
    action
) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return action.user;
        default:
            return state;
    }
};

export default user;
