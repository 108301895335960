const _ = require("lodash")
const util = require("util")

const Errors = {
    ValidationError: function(message, status) {
        Error.captureStackTrace(this, this.constructor)
        this.name = "ValidationError"
        this.message = message || "Validation error"
        this.status = status || 400
        this.generated = true
    },
    NotFoundError: function(message) {
        Error.captureStackTrace(this, this.constructor)
        this.name = "NotFoundError"
        this.message = message || "Object not found"
        this.status = 404
        this.generated = true
    },
    AuthError: function(message) {
        Error.captureStackTrace(this, this.constructor)
        this.name = "AuthError"
        this.message = message || "Not authorized"
        this.status = 403
        this.generated = true
    },
    NotAllowed: function(message) {
        Error.captureStackTrace(this, this.constructor)
        this.name = "NotAllowed"
        this.message = message || "Not allowed"
        this.status = 500
        this.generated = true
    },
    UnknownError: function(message, status) {
        Error.captureStackTrace(this, this.constructor)
        this.name = "UnknownError"
        this.message = message || "Something went wrong"
        this.status = status || 500
    }

};

_.forEach(Errors, CustomError => util.inherits(CustomError, Error))

export const manageNotFound = (ignoreNotFound, {entity, id}) => (object, callback) => {
    if(ignoreNotFound !== true && ! object) {
        const message = `${entity.name} with id ${id || "[No id]"} not found`

        if(! ignoreNotFound) return callback(new Errors.NotFoundError(message))
        else if(ignoreNotFound === "warn") console.warn(message)
        else console.warn(`Unknown ignoreNotFound value : ${ignoreNotFound}`)
    }
    callback(null, object)
}

export const manageEmptyInArray = ({entity, objectType}) => (objects) => {
    const emptyObjects = _.isArray(objects) && _.reject(objects)

    if(! _.isEmpty(emptyObjects)) {
        console.warn(`${emptyObjects.length} Empty element(s) in list of ${objectType} (${entity.name})`)

        objects = _.compact(objects)
    }
    return objects
}

export default Errors;
