import moment from 'moment'
import {getHabilitations} from "../../utils/habilitations";
const _ = require("lodash");

async function findData(context, callback) {

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
    const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habQuery = stores.length || organizations.length
        ? {
            $or: [
                {store: {$in : stores}},
                {organizations: {$in: organizations}}
            ],
        }
        : {}

    const datesQuery = {
        'date.gte': { $gte: dates[0].toDate() },
        'date.lt': { $lte: dates[1].toDate() }
    }

    const alertConfigurationCollection =
        global.app.S.AlertConfiguration.collection
    const alertConfigurationQuery = alertConfigurationCollection
        .find({
            code: 'RemiseModificationPrixSup70'
        })
        .toArray()

    const [alertConfigurations] = await Promise.all([
        alertConfigurationQuery
    ])

    const alertConfiguration = alertConfigurations[0]

    const alertConfQuery = {
        alertConfiguration: alertConfiguration._id
    }

    const alertCollection = global.app.S.Alert.collection
    const alertRemiseQuery = alertCollection
        .aggregate([
            {
                $match: {
                    ...datesQuery,
                    ...alertConfQuery,
                    ...habQuery,
                    'theme-Remise.Manuelle.12.Nb': { $exists: true }
                }
            }
        ])
        .toArray()

    const [alertRemiseCashier] = await Promise.all([
        alertRemiseQuery
    ])

    const canceledAlertsByCashier = alertRemiseCashier.reduce((acc, alert) => {
        if(!acc[alert['cashier']]) {
            acc[alert['cashier']] = _.get(alert, 'theme-Remise.Manuelle.12.Nb')
        }
        else acc[alert['cashier']] += _.get(alert, 'theme-Remise.Manuelle.12.Nb')
        return acc
    }, {})



    const alertRemise =Object.keys(canceledAlertsByCashier)
        .map( key => ({
            vendeur: key,
            modified: canceledAlertsByCashier[key]
        }))

    return [
        {
            id: "overSeventyPriceChange",
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: _(alertRemise).orderBy('modified', 'desc').slice(0, 10)
        }
    ]
}

export const entity = {
    name: 'OverSeventyPriceChange',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
