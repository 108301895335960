const _ = require('lodash')

export const modules = [
    {
        object: 'Unit',
        name: 'Group',
        category: {
            path: 'referential',
            icon: 'server'
        },
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: ['code', 'name'],
            form: ['code', 'name']
        }
    },

    {
        object: 'Company',
        category: {
            path: 'referential',
            icon: 'server'
        },
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                { path: 'unit', tKey: 'group', initiallyNotVisible: true },
                'code',
                'name',
                'status'
            ],
            form: [
                { path: 'unit', tKey: 'group' },
                'code',
                'name',
                { path: 'description', type: 'richTextEditor' },
                'status'
            ]
        },
        filters: [
            {
                path: 'unit',
                object: 'Unit',
                display: 'fullName',
                client: true,
                clearable: true,
                placeholder: 'selectGroup',
                default: {id: null},
                query: function(context) {
                    const unitId = _.get(context.data, 'unit.id')
                    if (unitId)
                        return { unit: global.ObjectID(unitId) }
                    return {}
                }
            },
            {
                path: 'statusCompany',
                object: 'CompanyStatus',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectCompanyStatus',
                default: {id: 'prospect'},
                query: function(context) {
                    const companyStatusId = _.get(
                        context.data,
                        'statusCompany.id'
                    )
                    if (companyStatusId)
                        return { status: companyStatusId }
                    return {}
                }
            }
        ]
    },

    {
        object: 'Contact',
        category: {
            path: 'referential',
            icon: 'server'
        },
        defaultSortBy: 'firstName',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                { path: 'company', initiallyNotVisible: true },
                {
                    path: 'civility',
                    display: 'name',
                    initiallyNotVisible: true
                },
                'firstName',
                'lastName',
                'email',
                'phone',
                'position',
                { path: 'profession', initiallyNotVisible: true },
                { path: 'linkedin', initiallyNotVisible: true },
                'status'
            ],
            form: [
                'company',
                'civility',
                { path: 'firstName', required: true },
                { path: 'lastName', required: true },
                'email',
                'phone',
                'position',
                'profession',
                'linkedin',
                { path: 'description', type: 'richTextEditor' },
                'status',
                { path: 'comments', tKey: 'note' }
            ]
        },
        filters: [
            {
                path: 'company',
                object: 'Company',
                display: 'fullName',
                client: true,
                clearable: true,
                placeholder: 'selectCompany',
                default: {id: null},
                query: function(context) {
                    const companyId = _.get(context.data, 'company.id')
                    if (companyId)
                        return { company: global.ObjectID(companyId) }
                    return {}
                }
            },
            {
                path: 'statusContact',
                object: 'ContactStatus',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectContactStatus',
                default: {id: 'prospect'},
                query: function(context) {
                    const contactStatusId = _.get(
                        context.data,
                        'statusContact.id'
                    )
                    if (contactStatusId)
                        return { status: contactStatusId }
                    return {}
                }
            }
        ]
    },

    /*
    {
        object: 'Project',
        category: {
            path: 'referential',
            icon: 'server'
        },
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                { path: 'company', initiallyNotVisible: true },
                'code',
                'name',
                'status'
            ],
            form: [
                'company',
                'code',
                'name',
                { path: 'description', type: 'richTextEditor' },
                'status',
                { path: 'comments', tKey: 'note' }
            ]
        },
        filters: [
            {
                path: 'company',
                object: 'Company',
                display: 'fullName',
                client: true,
                clearable: true,
                placeholder: 'selectCompany',
                default: {id: null},
                query: function(context) {
                    const companyId = _.get(context.data, 'company.id')
                    if (companyId)
                        return { company: global.ObjectID(companyId) }
                    return {}
                }
            },
            {
                path: 'statusProject',
                object: 'ProjectStatus',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectProjectStatus',
                default: {id: 'prospective'},
                query: function(context) {
                    const projectStatusId = _.get(
                        context.data,
                        'statusProject.id'
                    )
                    if (projectStatusId)
                        return { status: projectStatusId }
                    return {}
                }
            }
        ]
    },
     */

    {
        object: 'ActionType',
        category: {
            path: 'referential',
            icon: 'server'
        },
        tKey: 'actionType',
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: ['code', 'name'],
            form: ['code', 'name']
        }
    },

    {
        object: 'Subject',
        category: {
            path: 'referential',
            icon: 'server'
        },
        tKey: 'subject',
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: ['code', 'name'],
            form: ['code', 'name', 'actionType']
        }
    },
]
