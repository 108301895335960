import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import SingleFile from '../../../../components/SingleFile'
import getFormValidations from './formValidations'
import './DropDown.css'
import ValidationUI from "../../../../components/ValidationUI"

// binding for redux-form
const Component = ({ input: { value, onChange }, meta: { touched, error }, disabled, editable, placeholder, t }) => {
    return (
        <>
            <SingleFile
                url="file"
                value={value}
                onChange={onChange}
                t={t}
                placeholder={placeholder}
                editable={editable && !disabled}
            />
            { touched && (error && <ValidationUI t={t} error={error}/>) }
        </>
    )
}

class SingleFileComponent extends React.Component {
    constructor(props){
        super(props)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const { field, t, objectMode, groupModel, editObjectValues } = this.props

        const validations = getFormValidations(field, t, [], editObjectValues)
        return (
            <Field
                name={field.path}
                required={field.required}
                path={field.tKey || field.path}
                selectedColor={groupModel && groupModel.selectedColor}
                component={Component}
                disabled={field.disabled || !field.writable}
                validate={validations}
                editable={!field.editable ? objectMode === 'newObject' : field.editable  }
                clearable={field.clearable}
                placeholder={field.placeholder}
                t={t}
            />
        )
    }
}

const mapStateToProps = (state, { field, t }) => ({})

export default connect(mapStateToProps)(SingleFileComponent)
