import React from 'react'
import PropTypes from 'prop-types'
import './FileCell.css'
import {FileText} from 'react-feather'
import { getFile } from '../../utils/network'

const onClick = (fileId, filename, password, language, groupModel) => event => {
    event.stopPropagation()
    getFile({ fileId, filename, password, language, groupModel })
}

export const FileCell = ({ fileId, filename, password, language, groupModel }) => (
    <FileText size={16} className="DataTable-FileCell" onClick={onClick(fileId, filename, password, language, groupModel)} />
)

FileCell.propTypes = {
    fileId: PropTypes.any.isRequired,
    fileName: PropTypes.string
}

export default FileCell
