import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Column,
    defaultCellDataGetter
} from 'react-virtualized/dist/commonjs/Table'
import _ from 'lodash'
import Board from './Board'
import { Table, ROW_HEIGHT } from './Table'

export function generateObjectDataGetter(column) {
    return column.dataGetter
        ? object => column.dataGetter(object)
        : object => defaultCellDataGetter({
            rowData: object,
            dataKey: column.dataKey
        })
}

function valueMatchesTarget(value, target) {
    const matchAsString = _.isString(value) && value.toLowerCase().search(target.toLowerCase()) !== -1
    const matchAsNumber = _.isNumber(value) && value === Number(target)
    return matchAsString || matchAsNumber
}

export function applyFilter({ objects, filter, columns }) {
    // Save some computation time if :
    // no filter or
    // no columns or
    // no objects

    if (!columns.length || !objects) return []
    if (filter === '') return objects

    const dataGetters = columns.map(generateObjectDataGetter)

    return objects.filter(object =>
        dataGetters.some(dataGetter =>
            valueMatchesTarget(dataGetter(object), filter)
        )
    )
}

export function applySort({ objects, sortBy, sortDirection, columns }) {
    if (!sortBy) return objects

    const columnToSort = _.find(columns, { dataKey: sortBy })
    if (!columnToSort) return objects

    const dataGetter = generateObjectDataGetter(columnToSort)

    return _.orderBy(
        objects,
        dataGetter,
        sortDirection === 'ASC' ? 'asc' : 'desc'
    )
}

class DataTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sortedObjects: props.objects,
            filteredSortedObjects: props.objects,
            filter: '',
            sortBy: null,
            sortDirection: null,
            columnSelectorOpened: false,
            selectorOptions: props.columns
                .filter(column => !column.disableSort)
                .map(column => ({
                    key: column.dataKey,
                    label: column.label,
                    selected: true
                })),
            visibleMap: props.columns.reduce(
                (acc, column) => Object.assign(acc, { [column.dataKey]: true }),
                {}
            ),
            visibleColumns: this.props.columns
        }
    }

    setColumnSelectorOpen(value) {
        this.setState({
            columnSelectorOpen: value
        })
    }

    /*

    filterDataTable(target) {
        const { allIds } = this.props

        const filteredIds = allIds.filter(id => {
            const object = this.state.objects[id]
            return this.state.keysToSearch.some(key =>
                valueMatchesTarget(object[key], target)
            )
        })
        this.setState({
            dataTableFilter: target,
            filteredIds: filteredIds,
            filteredSortedIds: filteredIds
        })
    }

     */

    renderColumns = () => {
        return this.state.visibleColumns.map((column, i) => (
            <Column key={i} flexGrow={1} width={200} {...column} />
        ))
    }

    getTableHeight = autoSizerHeight => {
        const { flexible, fixedHeight = 195 } = this.props

        return flexible
            ? autoSizerHeight
            : Math.min(
                this.state.filteredSortedIds.length * ROW_HEIGHT + ROW_HEIGHT,
                fixedHeight || 60
            )
    }

    handleSort = ({ sortBy, sortDirection }) => {
        const visibleColumns = this.state.visibleColumns

        const sortedObjects = applySort({
            objects: this.state.sortedObjects,
            sortBy,
            sortDirection,
            columns: visibleColumns
        })

        const filteredSortedObjects = applyFilter({
            filter: this.state.filter,
            objects: sortedObjects,
            columns: visibleColumns
        })

        this.setState({
            sortBy,
            sortDirection,
            sortedObjects,
            filteredSortedObjects
        })
    }

    handleFilterChange = filter => {
        const filteredSortedObjects = applyFilter({
            filter,
            objects: this.state.sortedObjects,
            columns: this.state.visibleColumns
        })

        this.setState({ filter, filteredSortedObjects })
    }

    handleOpenColumnSelector = ({ opened }) => {
        this.setState({
            columnSelectorOpened: opened
        })
    }

    handleChangeColumnSelection = ({ key, selected }) => {
        const visibleMap = {
            ...this.state.visibleMap,
            [key]: selected
        }

        const visibleColumns = this.props.columns.filter(
            column => visibleMap[column.dataKey]
        )

        const selectorOptions = this.state.selectorOptions.map(option => {
            return option.key === key ? { ...option, selected } : option
        })

        const sortedColumnIsVisible = visibleMap[this.state.sortBy]
        const sortBy = sortedColumnIsVisible ? this.state.sortBy : null
        const sortDirection = sortedColumnIsVisible
            ? this.state.sortDirection
            : null

        const sortedObjects = applySort({
            objects: this.props.objects,
            sortBy,
            sortDirection,
            columns: visibleColumns
        })
        const filteredSortedObjects = applyFilter({
            filter: this.state.filter,
            objects: sortedObjects,
            columns: visibleColumns
        })

        this.setState({
            visibleMap,
            visibleColumns,
            selectorOptions,
            sortedObjects,
            filteredSortedObjects
        })
    }

    renderBoard = () => {
        const {
            filteredSortedObjects,
            filter,
            columnSelectorOpened,
            selectorOptions,
            visibleColumns
        } = this.state

        const { width } = this.props

        return (
            <Board
                width={width}
                selectorOptions={selectorOptions}
                columnSelectorOpened={columnSelectorOpened}
                openColumnSelector={this.handleOpenColumnSelector}
                changeColumnSelection={this.handleChangeColumnSelection}
                objects={filteredSortedObjects}
                columns={visibleColumns}
                filter={filter}
                changeFilter={this.handleFilterChange}
            />
        )
    }

    renderTable = () => {
        const { sortBy, sortDirection, filteredSortedObjects } = this.state
        const { noRowsLabel } = this.props

        return (
            <Table
                {...this.props}
                objects={filteredSortedObjects}
                sort={this.handleSort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                noRowsLabel={noRowsLabel}
            >
                {this.renderColumns()}
            </Table>
        )
    }

    render() {
        return (
            <div>
                {this.renderBoard()}
                {this.renderTable()}
            </div>
        )
    }
}

DataTable.propTypes = {
    objects: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            cellDataGetter: PropTypes.func,
            disableSort: PropTypes.bool,
            disableHide: PropTypes.bool,
            disableExport: PropTypes.bool
        })
    ).isRequired,
    noRowsLabel: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
}

export default DataTable
