import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import _ from 'lodash'
import DualBoxList from '../../../../components/DualBoxList/dualBoxList'
import getFormValidations from './formValidations'
import { isEmpty } from 'lodash'
import {
    sortDataTable,
    setFiltersOpened,
    filterDataTable,
} from '../../actions'
import {
    getFiltersExist,
    getSortBy,
    getSortDirection,
    getDataTableFilter,
    getFiltersOpened,
    getListOptions,
    getDataTableObjects
} from '../../selectors'

// binding for redux-form
const DualBoxListRedux = ({
    input: { value, onChange, onBlur },
    meta: { touched, error },
    selectOptions,
    optionsById,
    originalOptionsById,
    path,
    editable,
    disabled,
    required,
    t
}) => {
    //const selectedValue = !isEmpty(value) ? value.filter(o => !!o).map(o => o.id) : [];
    const selectedValue = !isEmpty(value) ? value.map(o => o.id) : [];

    const nonSelectedOptions = _.difference(selectOptions, selectedValue)
    return (
        <DualBoxList
            path={path}
            disabled={disabled || !editable}
            required={required}
            selected={selectedValue}
            nonSelected={nonSelectedOptions}
            optionsById={optionsById}
            height={240}
            onChange={options => {
                onChange(
                    options && options.filter(id => !!originalOptionsById[id]).map(id => originalOptionsById[id])
                )
            }}
            t={t}
        />
    )
}

const DualBoxListLogic = ({ field, options, t, objectMode }) => {
    const selectOptions = options.map(option => option.id)
    const originalOptionsById = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: option
        }
    }, {})
    const optionsById = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: {
                label: field.translate
                    ? t(option[field.display])
                    : option[field.display],
                value: option.id
            }
        }},{})
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            required={field.required}
            path={field.tKey || field.path}
            component={DualBoxListRedux}
            selectOptions={selectOptions}
            originalOptionsById={originalOptionsById}
            optionsById={optionsById}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            validate={validations}
            clearable={field.clearable}
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    options: getListOptions(state, field.id),
    dataTableFilter: getDataTableFilter(state),
    filtersExist: getFiltersExist(state),
    filtersOpened: getFiltersOpened(state),
    objects: getDataTableObjects(state),
    sortBy: getSortBy(state),
    sortDirection: getSortDirection(state),
})

const mapDispatchToProps = (dispatch) => {
    return {
        filterDataTable: value => dispatch(filterDataTable(value)),
        setFiltersOpened: opened => dispatch(setFiltersOpened(opened)),
        sortDataTable: ({ sortBy, sortDirection }) =>
            dispatch(sortDataTable(sortBy, sortDirection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DualBoxListLogic)
