const AUTHORIZED_CHARACTERS = [
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    '0','1','2','3','4','5','6','7','8','9',
    '/','-','?',':','(',')','.',',',"'",'+'
]

const regex = /\/{2,}/gi;

function replaceAt(element, index, replacement) {
    if (index >= element.length) {
        return element
    }

    return element.substring(0, index) + replacement + element.substring(index + 1);
}

function findReplacement(char) {
    if(['Ä','Â','À','Á'].includes(char)) return 'A'
    if(['Ç','Č','Ć'].includes(char)) return 'C'
    if(['Đ'].includes(char)) return 'D'
    if(['Ë','Ê','È','É'].includes(char)) return 'E'
    if(['Ï','Î','Ì','Í'].includes(char)) return 'I'
    if(['Ñ'].includes(char)) return 'N'
    if(['Ö','Ô','Ò','Ó'].includes(char)) return 'O'
    if(['Š'].includes(char)) return 'S'
    if(['Ü','Û','Ù','Ú'].includes(char)) return 'U'
    if(['Ÿ', 'Ý'].includes(char)) return 'Y'
    if(['Ž'].includes(char)) return 'Z'

    if(['ä','â','à','á'].includes(char)) return 'a'
    if(['ç','č','ć'].includes(char)) return 'c'
    if(['đ'].includes(char)) return 'd'
    if(['ë','ê','è','é'].includes(char)) return 'e'
    if(['ï','î','ì','í'].includes(char)) return 'i'
    if(['ñ'].includes(char)) return 'n'
    if(['ö','ô','ò','ó'].includes(char)) return 'o'
    if(['š'].includes(char)) return 's'
    if(['ü','û','ù','ú'].includes(char)) return 'u'
    if(['ÿ','ý'].includes(char)) return 'y'
    if(['ž'].includes(char)) return 'z'

    return ' '


}

const validate = (element, length) => {
    const element2 = element.replace(regex, ' ')
    let validatedElement = element2
    for (let i=0; i<element2.length; i++) {

        if(!AUTHORIZED_CHARACTERS.includes(element2[i])) {
            const replacement = findReplacement(element2[i])
            validatedElement = replaceAt(validatedElement, i, replacement)
        } else if(element2[i] === '/' && (i === 0 || i === element2.length - 1)) {
            validatedElement = replaceAt(validatedElement, i, ' ')
        }
    }

    return validatedElement.substring(0, length)
}


export const validateForXmlGeneration = (object, callback, translate) => {
    const xmlData = {...object}

    xmlData.reference = validate(object.reference, 26)
    xmlData.orderLines = object.orderLines.map(orderLine => {
        const unstructured = (orderLine.processType === 'invoice'? 'Reglt FACT. ' : 'Vers. ')  + orderLine.reason
        return {
            ...orderLine,
            reason: validate(orderLine.reason, 35),
            recipient: validate(orderLine.recipient, 70),
            unstructured: validate(unstructured, 140),
            IBAN: orderLine.IBAN,
            BIC: orderLine.BIC
        }
    })
    return xmlData
}
