export const module_ = {
    name: 'Close',
    object: 'Course',
    tKey: 'mTitle_consultSynthesis',
    newable: false,
    removable: false,
    category: {
        path: 'sessions',
        icon: 'book'
    },
    viewMap: {
        dt: [
            'code',
            {path: 'formationCategory', tKey: 'category'},
            {path: 'formation', display: 'fullTitle'},
            {path: 'start'},
            {path: 'end'},
            {path: 'collaborator', display: 'fullName'},
            {path: 'nbParticipants'},
            {path: 'status'}
        ],
        form: [
            {path: 'code', editable: false},
            {path: 'formationCategory', tKey: 'category', editable: false},
            {path: 'formation', display: 'fullTitle', editable: false},
            {path: 'formationDescription', tKey: 'description', type: 'textarea', editable: false},
            {path: 'formationMethod', tKey: 'method', type: 'textarea', editable: false},
            {path: 'start', required: true, editable: false},
            {path: 'end', required: true, editable: false},
            {path: 'synthesis', type: 'textarea', required: true},
            {path: 'status'},
            {
                path: 'details',
                tKey: 'details',
                viewMap: {
                    dt: [
                        {path: 'participant', display: 'name'},
                        //{path: 'course', display: 'formationFullTitle', tKey: 'formation'},
                        {path: 'objective', display: 'fullName'},
                        {path: 'target', width : 100},
                        {path: 'present', width : 100},
                        {path: 'acquired', width: 100},
                        'synthesis'
                    ],
                    form: [
                        {path: 'participant', display: 'name', editable: false},
                        //{path: 'course', display: 'formationFullTitle', editable: false, tKey: 'formation'},
                        {path: 'objective', display: 'fullName', editable: false},
                        {path: 'target', editable: false},
                        {path: 'present', editable: false},
                        {path: 'acquired', editable: false},
                        {path: 'synthesis', type: 'textarea', editable: false}
                    ]
                }
            }
        ]
    },
    filters: ['byCategory']
}
