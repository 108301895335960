
export const simpleFacQuery = {dataNature: 'FAC'};

export const facQuery = {
    dataNature: 'FAC',
    $or : [
        { depositCode: { $ne: '39' } },
        {$and: [
                { depositCode: '39' },
                { familyCode: { $ne: '230' } }
            ]}
    ],
    $and: [ { articleCode: { $ne: '24735' } }, { articleCode: { $ne: '25440' } } ]
};

export const lnfQuery = {
    dataNature: 'LNF',
    dataOrigin: 'GEST',
    $or : [
        { depositCode: { $ne: '39' } },
        {$and: [
                { depositCode: '39' },
                { familyCode: { $ne: '230' } }
            ]}
    ],
    $and: [ { articleCode: { $ne: '24735' } }, { articleCode: { $ne: '25440' } } ]
};

export const fnlQuery = {
    dataNature: 'FNL',
    dataOrigin: 'GEST',
    $or : [
        { depositCode: { $ne: '39' } },
        {$and: [
                { depositCode: '39' },
                { familyCode: { $ne: '230' } }
            ]}
    ],
    $and: [ { articleCode: { $ne: '24735' } }, { articleCode: { $ne: '25440' } } ]
};

export const stoQuery = {dataNature: 'STO', dataOrigin: 'GEST'};

export const achatQueryACH = {dataNature: 'ACH'};
export const achatQueryFNP = {dataNature: 'FNP', dataOrigin: 'GEST'};
export const achatQueryTRAAndTRV = {dataNature: {$in: ['TRA', 'TRV']}, dataOrigin: 'COMP'};
export const achatQueryCCA = {dataNature: 'CCA', dataOrigin: 'GEST'};
export const achatQueryRFC = {dataNature: 'RFC'};
