/*
* Creates a Writable stream object to batch execution of a function.
* */

export function createBatchedWriter({ batchSize, transform, execute }) {
    class BatchedExecution extends global.Writable {
        constructor() {
            super({ objectMode: true })

            this.data = []
            this.batchSize = batchSize || 10
            this._execute = execute
            this._transform = transform
        }

        _write(chunk, encoding, callback) {
            this.data.push(this._transform(chunk))
            if (this.data.length < this.batchSize) {
                callback()
            } else {
                this._execute(this.data, callback)
                this.data = []
            }
        }

        _final(callback) {
            if (this.data.length) {
                this._execute(this.data, callback)
            } else {
                callback()
            }
        }
    }

    return new BatchedExecution()
}

/*
* Insert the whole content of the cursor in the collection, by batch.
* */
export function insertCursorInBatches({ cursor, collection, batchSize }) {
    return new Promise((resolve, reject) => {
        const writeable = createBatchedWriter({
            batchSize,
            transform: object => object,
            execute: (data, callback) => collection.insertMany(data, callback)
        })

        const stream = cursor.pipe(writeable)

        stream.on('error', reject)
        stream.on('finish', resolve)
    })
}
