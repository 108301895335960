const _ = require('lodash')

const forceFunction = f => (_.isFunction(f) ? f : () => f)

const forceArray = o =>
    _([o])
        .flatten()
        .compact()
        .value()

const forceMap = (value, execute = _.identity) =>
    _.isArray(value) ? _.map(value, execute) : execute(value)

const propertyChangeComparator = (o1, o2) => propertyOrProperties =>
    _.some(
        forceArray(propertyOrProperties),
        property => _.get(o1, property) !== _.get(o2, property)
    )

/*
* Apply function to object or elements in array
* */
const apply = (o, func) => (_.isArray(o) ? o.map(func) : func(o))

const isEmpty = value =>
    (!_.isUndefined(value) && value !== false && value !== 0 && !value) ||
    (_.isArray(value) && _.isEmpty(value))

/*
* Compose functions with single parameters.
*
* Ex: compose([f, g]) creates a function like x => f(g(x))
*
* */
const compose = (functions=[]) => x =>
    functions.reduceRight(
        (x, f) => f(x),
        x
    )

const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export {
    forceFunction,
    forceArray,
    forceMap,
    propertyChangeComparator,
    apply,
    isEmpty,
    compose,
    arrayEquals,
}
