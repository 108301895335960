import React from "react";
const _ = require('lodash')

export function getData(cellData){
    const data = cellData && cellData.data !== undefined ? cellData.data : cellData

    switch (typeof data) {
        case 'string':
            if(data.slice(-1) === '%') {
                const percentage = parseFloat(data.slice(0, -1))
                if(percentage === 0) return percentage
                if(percentage) return percentage / 100
                return data
            }
            return data
        default:
            return data
    }
}

export function getCellType(cellData) {
    const data = cellData && cellData.data !== undefined ? cellData.data : cellData

    if (typeof(data) === 'string') {
        if(data.slice(-1) === '%') {
            const percentage = parseFloat(data.slice(0, -1))
            if(percentage || percentage === 0) return 'percentage'
            return 'string'
        }
        return 'string'
    }
    return typeof(data)
}

const configureWorkbook = (title, columns, objects) => workbook => {
    workbook.creator = 'Keenpoint'
    workbook.lastModifiedBy = 'Keenpoint'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Feuille 1')

    let row = 3
    let col = 1

    const titleCell = dataSheet.getRow(1).getCell(1)
    titleCell.value = title
    titleCell.font = {bold: true}

    dataSheet.mergeCells(1,1,1, columns.length)

    objects.forEach(line => {
        col = 1
        columns.forEach(column => {
            const cell = dataSheet.getRow(row).getCell(col)
            const cellData = _.get(line, column.path)
            cell.value = getData(cellData)
            const cellType = getCellType(cellData)
            if(cellType === 'percentage') {
                cell.numFmt = '0.00%'
            }
            if(line.style.backgroundColor) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    bgColor: { argb: line.style.backgroundColor.slice(-6)},
                    fgColor: { argb: line.style.backgroundColor.slice(-6)}
                }
            }

            if(line.style.borderTop || line.style.borderBottom) {
                const bottomBorderStyle = line.style.borderBottom ? {bottom: {style:'thin'}} : {}
                const topBorderStyle = line.style.borderTop ? {top: {style:'thin'}} : {}
                cell.border = {
                    ...topBorderStyle,
                    ...bottomBorderStyle
                }
            }
            const fontStyle = {}
            if(line.style) {
                if(line.style.fontWeight === 'bold') fontStyle.bold = true
                if(line.style.fontStyle === 'italic') fontStyle.italic = true
                if(line.style.fontSize) fontStyle.size = parseInt(line.style.fontSize.slice(0, -2))
                if(line.style.color) fontStyle.color = { argb: line.style.color.slice(-6) }
            }
            if(cellData && cellData.style) {
                if(cellData.style.color) fontStyle.color = { argb: cellData.style.color.slice(-6) }
                if(cellData.style.fontWeight === 'bold') fontStyle.bold = true
                if(cellData.style.fontStyle === 'italic') fontStyle.italic = true
                if(cellData.style.fontSize) fontStyle.size = parseInt(cellData.style.fontSize.slice(0, -2))

                if(cellData.style.backgroundColor) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: cellData.style.backgroundColor.slice(-6)},
                        fgColor: { argb: cellData.style.backgroundColor.slice(-6)}
                    }
                }
            }

            cell.font = fontStyle
            col++
        })
        row++
    })

    columns.forEach((column, index) => {
        dataSheet.getColumn(index + 1).width = column.width / 10
    })

    return workbook
}

export async function generateExcel({title, columns, objects, passwordProtected}) {

    return await new Promise((resolve, reject) => {
        global.excel.generateExcelStream(
            configureWorkbook(title, columns, objects),
            passwordProtected,
            `${title}.xlsx`,
            (err, stream) => {
                if (err) reject(err)
                else resolve(stream)
            }
        );
    });
}
