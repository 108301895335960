import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import ColorPicker from "../../../../components/ColorPicker";
import getFormValidations from "./formValidations"
import {setFieldActive} from "../../actions";
import {getFieldActive} from "../../selectors";
import './ColorPicker.css'
import classnames from "classnames";

class Component extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    handleInputActive = hovered => {
        this.setState({hovered})
    }

    handleClickOutside = () => {
        this.props.setFieldActive(false)
    }

    handleChange = (background) => {
        return this.props.input.onChange(background)
    }

    render() {

        return (
            <div style={{position: 'relative', width: 'fit-content'}}>
                <div>
                    <div
                        style={{background: this.props.input.value}}
                        onClick={() => this.props.setFieldActive(true)}
                        className="colorPicker-display"
                        onMouseEnter={() => this.handleInputActive(true)}
                        onMouseLeave={() => this.handleInputActive(false)}
                    />
                    <div className="StyledInput-group">
                        <label className="StyledInput-label">
                            {this.props.t(this.props.path)} {this.props.required && "*"}
                        </label>
                        <div>
                            <input
                                //style={disabledStyle}
                                style={{height: '35px'}}
                                name={this.props.path}
                                className={classnames('StyledInput-input', {
                                    hovered: this.state.hovered
                                })}
                                value={this.props.input.value}
                                onChange={event => this.handleChange(event.target.value)}
                                readOnly={this.props.readOnly}
                                disabled={this.props.disabled}
                            />
                        </div>
                    </div>
                </div>
                <ColorPicker
                    color={this.props.input.value}
                    opened={this.props.active}
                    handleClickOutside={() => this.props.setFieldActive(false)}
                    handleColorChange={this.handleChange}
                />
            </div>
        );
    }
}

const ColorPickerComponent = ({field, objectMode, active, setFieldActive, t}) => {
    const validations = getFormValidations(field)
    return (
        <Field
            name={field.path}
            path={field.path}
            required={field.required}
            component={Component}
            active={active}
            setFieldActive={setFieldActive}
            disabled={field.disabled || ( !field.editable && objectMode !== 'newObject') }
            validate={validations}
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    active: getFieldActive(state, field.id),
})

const mapDispatchToProps = (dispatch, { field }) => ({
    setFieldActive: active => dispatch(setFieldActive(field.id, active))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColorPickerComponent)
