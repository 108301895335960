import {data, getRangeOfMonthsFromExerciseStart, getLastMonthOfData, getPreviousYearMonth} from '../utils/stateComputationUtils'
import _ from 'lodash'

export const getData = async month => {

    const parentContext = {}

    const currentMonth = month;
    const previousYearMonth = getPreviousYearMonth(month);

    const currentMonthData = await getLastMonthOfData(currentMonth);
    const previousYearMonthData = await getLastMonthOfData(previousYearMonth);

    const monthsCurrentExercise = getRangeOfMonthsFromExerciseStart(currentMonth);
    const monthsPreviousExercise = getRangeOfMonthsFromExerciseStart(previousYearMonth);

    const currentMonthFileQuery = {column: 'fileYearMonth', operation: 'eq', value: currentMonthData};
    const previousYearMonthFileQuery = {column: 'fileYearMonth', operation: 'eq', value: previousYearMonthData};


    const currentExerciseQuery = {...parentContext.query, yearMonth: {$in: monthsCurrentExercise}, dataNature: 'STJ'};
    const previousExerciseQuery = {...parentContext.query, yearMonth: {$in: monthsPreviousExercise}, dataNature: 'STJ'};

    const turnoverCurrentExercise = await data([], [currentMonthFileQuery], ["value"], currentExerciseQuery);
    const turnoverPreviousExercise = await data([], [previousYearMonthFileQuery], ["value"], previousExerciseQuery);

    const keys = [previousYearMonth, currentMonth]
    const colors = ['#9E9E9E', '#ec6258']

    return [turnoverPreviousExercise, turnoverCurrentExercise].map((o, index) => ({key: keys[index], value: o[0] ? _.round(o[0].value/1000, 1) : 0, color: colors[index]}) )
}
