const async = require('async')
const _ = require('lodash')
const moment = require('moment')
const Errors = require("../../utils/Errors").default
const { basicContext } = require('../../utils/contextUtils')
const { validateAge } = require('./utils')
const { setFieldStartDate } = require('../../../apps/KpModule/actions')
const { fieldPathJoinGetter } = require('../../utils/kp3Utils')
const {setFieldEdition} =  require('../../../apps/KpModule/actions')

export const entity = {
    name: 'Benefit',
    facets: [{name: 'codeName', nameOptional: false, uniqueName: true}],
    fields: [
        { path: 'accountNumber'},
        'Category',
        'BenefitStatus',
        { type: 'Exercise', index: true},
        'AnalyticalCode',
        'Journal',
        'Limit',
        { path: 'ageLimit', type: 'integer'},
        { path: 'benefitRecipients', type: 'BenefitRecipient', link: 'MTM'},
        { path: 'startDate'},
        { path: 'endDate'},
        { path: 'limitDate'},
        { path: 'refundStartDate'},
        { path: 'refundEndDate'},
        { path: 'participation', type: 'integer'} ,
        { path: 'childrenPresenceCondition', type: 'boolean'} ,
        { path: 'reallocated', type: 'boolean'} ,
        { path: 'taxReturnDocument', type: 'boolean'},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"],
            joinString: " - "
        })
    ],
    filters: [
        {
            name: 'reallocationBenefits',
            path: 'exercise',
            object: 'Exercise',
            display: "code",
            sortList: false,
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            query: (context, callback) => {
                global.app.R.Exercise.find({
                    ...basicContext(context),
                    query: {
                        exerciseStatus: {$in: ['ongoing', 'provisionalClosure']}
                    },
                }, (e, exercises) => {
                    if(e) callback(e)
                    else {
                        callback(
                            null,
                            {
                                exercise: {$in: exercises.map(exercise => new global.ObjectID(exercise.id))},
                                reallocated: true
                            }
                        )
                    }
                })
            }
        },
        {
            name: 'onGoingExercise',
            async: true,
            isDefault: false,
            query: (context, callback) => {
                global.app.R.Exercise.collection.findOne(
                    { exerciseStatus: 'ongoing' },
                    (e, exercise) => {
                        if(e) callback(e)
                        const exerciseId = exercise && exercise._id

                        callback(
                            null,
                            exerciseId
                                ? {exercise: exerciseId}
                                : {exercise: false}
                        )
                    }
                )
            }
        },
        {
            name: 'accessibleForRefund',
            isDefault: false,
            query: () => {
                const date = moment().format('YYYY-MM-DD')
                return {
                    $and: [
                        {'refundStartDate': {$lte: date}},
                        {'refundEndDate': {$gte: date}}
                    ]
                }
            }
        },
        {
            name: 'notClosedExercise',
            isDefault: false,
            match: object => {
                return _.get(object, 'exercise.exerciseStatus.id') !== 'finalClosure'
            }
        },
        {
            name: 'userHasBalance',
            async: true,
            isDefault: false,
            query: (context, callback) => {
                async.waterfall([
                    (callback) => {
                        global.app.R.CUsers.get(
                            {kpUser: new global.ObjectID(context.user.id)},
                            {
                                fieldPath: ['rightHolders.id', 'rightHolders.kinship', 'rightHolders.dependent', 'status.id'],
                                group: context.group
                            },
                            (e, user) => {
                                if(e) callback(e)
                                else callback(null, user)
                            }
                        )
                    },
                    (user, callback) => {
                        global.app.R.Exercise.collection.findOne(
                            { exerciseStatus: 'ongoing' },
                            (e, exercise) => {
                                if(e) callback(e)
                                else callback(null, user, exercise)
                            }
                        )
                    },
                    (user, exercise, callback) => {
                        if(exercise) {
                            global.app.R.Benefit.find(
                                {
                                    ...basicContext(context),
                                    query: {exercise: exercise._id}

                                },
                                (error, benefits) => {
                                    if(error) callback(error)
                                    else callback(null, user, exercise, benefits)
                                }
                            )
                        }
                    },
                    (user, exercise, benefits, callback) => {
                        global.app.R.Refund.find({
                                ...basicContext(context),
                                fieldPath: ['refund', 'status'],
                                query: {
                                    user: new global.ObjectID(user.id),
                                    benefit: {$in: benefits.map(o => new global.ObjectID(o.id))},
                                    status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }
                                }},
                            (error, refunds) => {
                                if(error) callback(error)
                                else callback(null, user, exercise, benefits, refunds)
                            }
                        )

                    },
                    (user, exercise, benefits, refunds, callback) => {

                        const exerciseStartDate = exercise.startDate
                        const exerciseEndDate = exercise.endDate
                        const entryDate = user.entryDate
                        const releaseDate = user.releaseDate

                        const totalExerciseRefund = refunds
                            .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)

                        const limitForTheExerciseBeforeSplit = user.status.id === 'external'
                            ? 0
                            : user.rightHolders
                                .filter(rh => rh.kinship.id === 'child' && rh.dependent)
                                .filter( rh => validateAge(rh.birthDate, exerciseStartDate, 25))
                                .length
                            * exercise.variableDot + exercise.fixedDot

                        if(releaseDate) {
                            releaseDate.setDate( releaseDate.getDate() - 1)
                        }

                        const start = entryDate
                            ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
                            : exerciseStartDate

                        const end = !releaseDate || user.status.id === 'retired'
                            ? exerciseEndDate
                            : releaseDate < exerciseEndDate
                                ? releaseDate
                                : exerciseEndDate

                        const monthsDiff = (date1, date2) => {
                            const years = date2.getFullYear() - date1.getFullYear()
                            return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
                        }

                        const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
                            ? 0
                            : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

                        const userSplit = user['split'] || 0
                        const getProratedValue = value => ((value * (100 - userSplit)) / 100) * presenceRatio

                        const limitForTheExercise = getProratedValue(limitForTheExerciseBeforeSplit)

                        const balanceByBenefit = benefits.map(benefit => {

                            const childrenNumber = user.rightHolders
                                .filter(rh => rh.kinship.id === 'child' && rh.dependent)
                                .filter( rh => validateAge(rh.birthDate, exerciseStartDate, benefit.ageLimit))
                                .length

                            const fixedAndVariable = benefit.childrenPresenceCondition && !childrenNumber
                                ?  0
                                : (childrenNumber * benefit.limit.variable ) + benefit.limit.fixed

                            const fixAndValueAfterProrating = getProratedValue(fixedAndVariable)

                            const limitForTheBenefit = user.status.id === 'external'
                                ? 0
                                : benefit.limit.maximum && fixAndValueAfterProrating >= benefit.limit.maximum
                                    ? benefit.limit.maximum
                                    : fixAndValueAfterProrating
                            const benefitRefundRequests = refunds.filter(refund => refund.benefit.toString() === benefit.id)

                            const totalRefund = benefitRefundRequests
                                .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)

                            return {
                                id: benefit.id,
                                exerciseBalance: _.round(limitForTheExercise - totalExerciseRefund, 2),
                                balance: _.round(limitForTheBenefit - totalRefund, 2),
                            }
                        })
                        callback(null, balanceByBenefit, exercise)
                    }
                ], (error, balanceByBenefit, exercise) => {
                    if(error) callback(error)
                    else callback(
                        null,
                        {
                            $or: [
                                {_id: {$in:  balanceByBenefit.filter(o => o.exerciseBalance > 0 && o.balance > 0).map(o => new global.ObjectID(o.id))}},
                                {exercise: {$ne: exercise._id}}
                            ]
                        }
                    )
                })
            }
        }
    ],
    validateSave: function (newObject, oldObject, context, callback) {
        if(!newObject.childrenPresenceCondition) return callback()
        global.app.R.Benefit.find({
            ...basicContext(context),
            fieldPath: ['iban', 'process', 'bank', 'active'],
            query: {
                _id: {$ne : global.ObjectID(newObject.id)},
                exercise: global.ObjectID(newObject.exercise.id),
                childrenPresenceCondition: true
            }
        }, (e, benefits) => {
            if(benefits && benefits.length !== 0) {
                callback(new Errors.ValidationError('uniqueBenefitWithChildrenPresenceCondition'))
            }
            else callback()
        })
    },
}

export const module_ = {
    object: 'Benefit',
    tKey: 'mTitle_benefit',
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            'category', 'code', 'name',
            {path: 'refundStartDate', tKey: 'start'}, {path: 'refundEndDate', tKey: 'end'},
            {path: 'benefitRecipients', initiallyNotVisible: true},
            {path: 'participation', tKey: 'participation(%)', initiallyNotVisible: true},
            {path: 'limit', initiallyNotVisible: true},
            {path: 'exercise', display: 'code', initiallyNotVisible: true},
            {path: 'accountNumber', initiallyNotVisible: true},
            {path: 'analyticalCode', display: 'fullName', initiallyNotVisible: true},
        ],
        form: {
            fields: [
                'category', 'code', 'name',
                {
                    path: 'exercise',
                    display: 'code',
                    fieldPath: ['id', 'code', 'startDate', 'endDate', 'exerciseStatus'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, getObjectSuccessAction, module}) => {
                            if(!formInitialize && !getObjectSuccessAction && newValue) {
                                const startDate = newValue.startDate
                                const endDate = newValue.endDate
                                module.viewMap.form.fields.forEach(
                                    field => {
                                        if(['refundStartDate'].includes(field.path)) {
                                            field.setValue(startDate)
                                        } else if('refundEndDate' === field.path) {
                                            const plusOneMonth = moment(endDate).add(1,'months').format('YYYY-MM-DD')
                                            field.setValue(plusOneMonth)
                                        }
                                    }
                                )
                            }
                        }
                    }
                },
                {
                    path: 'benefitRecipients', required: true,
                    default: [
                        { id: 'salaried'},
                        { id: 'retired'},
                        { id: 'rightHolder'}
                    ]
                },
                {path: 'ageLimit', default: 25, required: true, wholeNumber: true},
                {path: 'participation', tKey: 'participation(%)', default: 50, required: true},
                {path: 'limit', required: true},
                {path: 'accountNumber', default: '604000000', required: true},
                {path: 'analyticalCode', display: 'fullName', required: true},
                {path: 'childrenPresenceCondition', default: false},
                {path: 'reallocated',tKey: 'reallocate', default: false},
                {path: 'taxReturnDocument', tKey: 'reimbursementCertificate', default: false},
                {path: 'refundRequested', type: 'separator'},
                {
                    path: 'refundStartDate',
                    type: 'datePicker',
                    tKey: 'start',
                    required: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            store.dispatch(setFieldStartDate('e_refundEndDate', newValue))
                        }
                    }
                },
                {path: 'refundEndDate', type: 'datePicker', tKey: 'end', required: false},
            ],
            onOpen: ({module, store}) => {
                const exerciseField = module.viewMap.form.fields.find(
                    field => field.path === 'exercise'
                )
                const exercise = exerciseField.getValue()
                const exerciseId = _.get(exercise, 'exerciseStatus.id')
                const exerciseClosed = exerciseId === 'finalClosure'

                if(exerciseId) {
                    const editFields = ['category', 'exercise', 'code', 'name', 'benefitRecipients', 'ageLimit', 'participation', 'limit', 'accountNumber', 'analyticalCode', 'childrenPresenceCondition', 'refundRequested', 'refundStartDate', 'refundEndDate']
                    editFields.forEach(field => {
                        store.dispatch(setFieldEdition(`e_${field}`, !exerciseClosed))
                    })
                }
            }
        }
    }
}
