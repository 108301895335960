import React from 'react'
import { connect } from 'react-redux'
import {change as reduxFormChange, isDirty, reduxForm, initialize, getFormValues} from 'redux-form'
import {
    getFilterFields,
    getFilterObject,
    getFiltersOpened
} from '../../selectors/index'
import FilterForm from './FilterForm'
import {fetchDtData, fetchFilterFieldsLists} from '../../actions/api'

export const FILTER_FORM = 'filterObject'

const DtFilterForm = ({
    module,
    fields,
    filtersOpened,
    onChange,
    fetchFilterData,
    manuallyFetchFilterData,
    fetchFilterFieldsLists,
    t,
    language,
    isFormDirty,
    filterData,
    groupModel
}) => (
    <FilterForm
        module={module}
        fields={fields}
        filtersOpened={filtersOpened}
        onChange={onChange}
        fetchFilterData={fetchFilterData}
        manuallyFetchFilterData={manuallyFetchFilterData}
        fetchFilterFieldsLists={fetchFilterFieldsLists}
        t={t}
        language={language}
        isFormDirty={isFormDirty}
        filterData={filterData}
        groupModel={groupModel}
    />
)

const ReduxForm = reduxForm({
    // a unique name for the form (required by the library)
    form: FILTER_FORM,
    enableReinitialize: false
})(DtFilterForm)

const mapStateToProps = state => ({
    isFormDirty: isDirty(FILTER_FORM)(state),
    filtersOpened: getFiltersOpened(state),
    fields: getFilterFields(state),
    initialValues: getFilterObject(state),
    filterData:  getFormValues(FILTER_FORM)(state)
})

const mapDispatchToProps = (dispatch, {module}) => ({
    fetchFilterFieldsLists: () => dispatch(fetchFilterFieldsLists()),
    fetchFilterData: () => {
        if(module.manualFilters) return
        dispatch(fetchDtData())
    },
    manuallyFetchFilterData: data => {
        dispatch(fetchDtData())
        dispatch(initialize(FILTER_FORM, data))
    },
    onChange: (field, value) =>
        dispatch(reduxFormChange(FILTER_FORM, field, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm)
