import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { I18n } from 'react-i18next'
import TimePicker from '../../../../components/Calendar/TimePicker'
import getFormValidations from './formValidations'
import { toggleCalendar } from '../../actions'
import { getModule, getLanguage } from '../../selectors'

// binding for redux-form
const VisualComponent = ({ input: { value, onChange }, meta: {touched, error}, module, disabled, t }) => {
    const translatedError = t(error)
    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <TimePicker
                    showSecond={false}
                    onChange={value =>
                        onChange(value && value.format("HH:mm"))
                    }
                    placeholder={t('pickDate')}
                    touched={touched ? 'true' : 'false'}
                    error={translatedError}
                    value={value}
                    disabled={disabled}
                />
            )}
        </I18n>
    )
}

const DatePickerComponent = ({
                                 field,
                                 language,
                                 module,
                                 t
                             }) => {
    const validations = getFormValidations(field)
    return (
        <Field
            name={field.path}
            component={VisualComponent}
            validate={validations}
            // onChange={(event, newValue) => {
            //     field.onChange && field.onChange(newValue)
            // }}
            disabled={field.disabled || !field.writable}
            language={language}
            module={module}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    language: getLanguage(state),
    module: getModule
})

const mapDispatchToProps = {
    toggleCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent)
