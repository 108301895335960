export const htmlDecode = input => {
    const element = document.createElement('textarea');
    element.innerHTML = input;
    return element.innerHTML
}

export const insertAtIndex = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
]

export const keyInObject = (object, key) => key in object
