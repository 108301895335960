const _ = require('lodash')
const { basicContext } = require('../../../utils/contextUtils')

export const entity =  {
    name: 'BankFlow',
    fields: [
        'bank',
        'recipient',
        {path: 'reason', unique: true},
        {path: 'IBAN', encrypted: true},
        {path: 'BIC', encrypted: true},
        {path: 'amount', type: 'decimal'},
        'generated',
        'status',
        'transmissionDate',
        'fileName',
        'reference',
        {
            path: "fullReference",
            fieldPath: ["recipient", "reason"],
            f: function () {
                return `${this.recipient} - ${this.reason}`
            }
        }
    ],
    filters: [
        {
            name: 'byExercise',
            path: 'exercise',
            object: 'Exercise',
            filters: ['notProvisionallyClosed'],
            fieldPath: ['startDate', 'endDate'],
            display: 'code',
            sortList: false,
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            match: (object, context, callback) => {
                const exerciseStartDate =  _.get(context, 'data.exercise.startDate')
                const exerciseEndDate =  _.get(context, 'data.exercise.endDate')

                global.app.C.Invoice.find({
                    ...basicContext(context),
                    fieldPath: ['benefit.id', 'benefit.exercise.id'],
                    query: {wording: object.reason},
                }, (e, invoices) => {
                    if(e) return callback(e)
                    const invoice = invoices[0]

                    if(!invoice) return callback(null, false)

                    return callback(null, invoice.invoiceDate >= exerciseStartDate && invoice.invoiceDate <= exerciseEndDate)
                })
            }
        },
        {
            name: 'byStatus',
            isDefault: false,
            query: function (context) {
                const statutes = _.get(context , "data.statutes")
                return statutes
                    ? {status: {$in: statutes}}
                    : {}
            },
        },
        {
            name: "selectedOnly",
            isDefault: false,
            query: function (context) {
                const orderLines = _.get(context , "data.orderLines");
                return orderLines
                    ? {_id: {$in: orderLines.map( line => global.ObjectID(line.id))}}
                    : {_id: null}
            }
        },
    ]
}

export const module_ = {
    object: 'BankFlow',
    tKey: 'mTitle_transferOrders',
    removable: false,
    protectedExport: true,
    displayLog: false,
    category: {
        path: 'bank',
        icon: 'repeat'
    },
    viewMap: {
        dt: [
            {path: 'bank'},
            {path: 'recipient'},
            {path: 'reason'},
            {path: 'IBAN'},
            {path: 'BIC'},
            {path: 'amount', tKey: 'amount(€)'},
            {path: 'generated'},
            {path: 'reference'},
            {path: 'status', translate: true},
            {path: 'transmissionDate', initiallyNotVisible: true},
            {path: 'fileName', initiallyNotVisible: true},
        ]
    },
    filters: ['byExercise']
}
