const _ = require('lodash')

const years = ['N', 'N+1', 'N+2', 'N+3', 'N+4', 'N+5', 'N+6', 'N+7', 'N+8', 'N+9']

const initializeYearsValues = years => {
    return years.reduce(
        (acc, n) => ({
            [n]: 0,
            ...acc
        }),
        {}
    )
}

const reduceValuesAndTotal = objects => {
    return _.reduce(
        objects,
        (o, n) => {
            o.total = _.round(o.total + n.total, 1)
            years.forEach(year => {
                o.values[year] = _.round(o.values[year] + n.values[year], 1)
            })
            return o
        },
        { total: 0, values: initializeYearsValues(years) }
    )
}

const substractValues = (a, b) => ({
    total: a.total - b.total,
    values: years.reduce((acc, n) => ({
        [n]: a.values[n] - b.values[n],
        ...acc
    }), {})
})

const ratioValues = (num, den) => {
    return {
        total: den.total !== 0 ? _.round(num.total / den.total * 100, 1) : 0,
        values: years.reduce(
            (acc, n) =>
                Object.assign(acc, {
                    [n]:
                        den.values[n] !== 0
                            ? _.round(num.values[n] / den.values[n] * 100, 1)
                            : 0
                }),
            {}
        )
    }
}

export function calculateFinancialSummaries(businessProject) {

    const turnOverComponents = businessProject.financialSummarys
        .filter(fs => fs.projectNature.id === 'sale')
        .map(fs => ({
            component: fs.lot,
            total: fs.financialTurnover,
            values: _.pick(
                fs.summaryTableHeaders.find(
                    sth => sth.summariesUnit.id === '1'
                ),
                years
            )
        }))

    const turnOver = reduceValuesAndTotal(turnOverComponents)

    const chargeOnContractComponents = businessProject.financialSummarys
        .filter(fs => fs.projectNature.id === 'sale')
        .map(fs => ({
            component: fs.lot,
            total: fs.financialExpenses,
            values: _.pick(
                fs.summaryTableHeaders.find(
                    sth => sth.summariesUnit.id === '2'
                ),
                years
            )
        }))

    const chargeOnContract = reduceValuesAndTotal(chargeOnContractComponents)

    const margeOnContract = substractValues(turnOver, chargeOnContract)

    const margeOnContractInPercent = ratioValues(margeOnContract, turnOver)

    const chargeStructureAndDevComponents = businessProject.financialSummarys
        .filter(fs => fs.projectNature.id === 'offSale')
        .map(fs => ({
            component: fs.lot,
            total: fs.financialExpenses,
            values: _.pick(
                fs.summaryTableHeaders.find(
                    sth => sth.summariesUnit.id === '2'
                ),
                years
            )
        }))

    const chargeStructureAndDev = reduceValuesAndTotal(
        chargeStructureAndDevComponents
    )

    const operationalResult = substractValues(margeOnContract, chargeStructureAndDev)

    const operationalResultInPercent = ratioValues(operationalResult, turnOver)

    const fiscalChargeComponents = businessProject.financialSummarys
        .filter(fs => fs.projectNature.id === 'fiscal')
        .map(fs => ({
            component: fs.lot,
            total: fs.financialExpenses,
            values: _.pick(
                fs.summaryTableHeaders.find(
                    sth => sth.summariesUnit.id === '2'
                ),
                years
            )
        }))

    const fiscalCharge = reduceValuesAndTotal(
        fiscalChargeComponents
    )

    const netResult = substractValues(operationalResult, fiscalCharge)

    const netResultInPercent = ratioValues(netResult, turnOver)

    const numberOfDaysComponents = businessProject.financialSummarys
        .filter(fs => fs.projectNature.id === 'sale')
        .map(fs => ({
            component: fs.lot,
            total: fs.financialNumberOfDays,
            values: _.pick(
                fs.summaryTableHeaders.find(
                    sth => sth.summariesUnit.id === '3'
                ),
                years
            )
        }))

    const numberOfDays = reduceValuesAndTotal(numberOfDaysComponents)


    return {
        globalTurnover: businessProject.globalTurnover,

        turnOver,
        turnOverComponents,

        chargeOnContract,
        chargeOnContractComponents,

        margeOnContract,
        margeOnContractInPercent,

        chargeStructureAndDev,
        chargeStructureAndDevComponents,

        operationalResult,
        operationalResultInPercent,

        fiscalCharge,
        fiscalChargeComponents,

        netResult,
        netResultInPercent,

        numberOfDaysComponents,
        numberOfDays
    }
}
