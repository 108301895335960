import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import WrappedMenu from '../../../../components/Menu'
import { translate } from 'react-i18next'
import './Navbar.css'
import {Settings} from 'react-feather'
import { kpFetch } from '../../../../restClient'
import { toastr } from 'react-redux-toastr'

class Job extends Component {
    launchJob = job => () => {
        const { setJobOpened, groupModel } = this.props

        setJobOpened(false)

        kpFetch(
            'POST',
            '/job',
            "",
            {context: {
                job,
                groupModelId: groupModel.id
            }}
        )
            .then(({ ok, json, message }) =>
                toastr.success('JOB', ok ? json.message : message, { timeOut: 0 })
            )
            .catch(error =>
                toastr.error('JOB', error.message, { timeOut: 0 })
            )
    }

    render() {
        const { jobOpened, setJobOpened, groupModel, t, color } = this.props

        return (
            <div
                className={classnames('dropdown', {
                    open: jobOpened
                })}
            >
                <button
                    className="navbar-icon-container"
                    onClick={() => setJobOpened(!jobOpened)}
                >
                    <Settings className="navbar-icon setting-icon" color={color}/>
                </button>
                <WrappedMenu
                    opened={jobOpened}
                    onClickOutside={() => setJobOpened(false)}
                    outsideClickIgnoreClass={'setting-icon'}
                    position="left"
                >
                    <ul
                        className="Navbar-Menu-list"
                        aria-labelledby="dropDownMenuSetting"
                    >
                        {groupModel.jobs.map(job => (
                            <li key={job.id} className="Navbar-Menu-item" onClick={this.launchJob(job)}>
                                <a>{t(job.title || job.name)}</a>
                            </li>
                        ))}
                    </ul>
                </WrappedMenu>
            </div>
        )
    }
}

Job.propTypes = {
    jobOpened: PropTypes.bool.isRequired,
    setJobOpened: PropTypes.func.isRequired,
    groupModel: PropTypes.object.isRequired
}

export default translate('platform')(Job)
