export const entities = [
    {
        name: 'CompanyStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'prospect', name: 'Prospect' },
            { id: 'client', name: 'Client' },
            { id: 'abandoned', name: 'Abandoned' }
        ]
    },
    {
        name: 'ContactStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'prospect', name: 'Prospect' },
            { id: 'confirmed', name: 'Confirmed' },
            { id: 'inTouch', name: 'In Touch' }
        ]
    },

    {
        name: 'Civility',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'M', name: 'Monsieur' },
            { id: 'Mme', name: 'Madame' }
        ]
    },
    {
        name: 'ProjectStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'prospective', name: 'Prospective' },
            { id: 'ongoing', name: 'Ongoing' },
            { id: 'closed', name: 'Closed' },
            { id: 'abandoned', name: 'Abandoned' }
        ]
    },
    {
        name: 'SubjectStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'Ongoing' },
            { id: 'done', name: 'Done' },
            { id: 'abandoned', name: 'Abandoned' }
        ]
    },
    {
        name: 'ActionStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'ongoing', name: 'Ongoing' },
            { id: 'done', name: 'Done' },
            { id: 'abandoned', name: 'Abandoned' }
        ]
    }
]
