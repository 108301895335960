import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ValidationUI from '../ValidationUI'
import './Select.css'

const ReactSelect = (props) => {
    return (
        <div className={props.fullWidth ? "Wide-Select-Container":"Select-Container"}>
            <div className="Select-separate-label">{props.label} {props.required && '*'}</div>
            {props.subtitle && <div className="Select-subtitle">{props.subtitle}</div>}
            <Select {...props} placeholder={props.placeholder || 'select'} onBlur={event => event.preventDefault()}  />
            { props.touched && (props.error && <ValidationUI error={props.error}/>) }
        </div>
    )
}


Select.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default ReactSelect
