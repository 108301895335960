import _ from 'lodash'
import createWorkflowManager from '../../../utils/workflow'
import {basicContext} from "../../../utils/contextUtils"

/**
 * Setup the workflow using the library
 */
export const workflowParameters = {
    steps: [
        {
            name: 'formX',
            multiOrder: true,
            previousSteps:[],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'buildOffer', getUserConfirmation: true },
                { name: 'question', nextStep: 'formX', ignoreOrder: true, getUserConfirmation: true },
                { name: 'noGo', nextStep: 'noGo', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'buildOffer',
            multiOrder: true,
            previousSteps:['formX'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'submitOffer', getUserConfirmation: true },
                { name: 'question', nextStep: 'buildOffer', ignoreOrder: true, getUserConfirmation: true },
                { name: 'notSubmitted', nextStep: 'notSubmitted', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'submitOffer',
            multiOrder: true,
            previousSteps:['formX', 'buildOffer'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'realisation', getUserConfirmation: true },
                { name: 'validateAmi', nextStep: 'acceptedAmi', ignoreOrder: true, getUserConfirmation: true },
                { name: 'question', nextStep: 'submitOffer', ignoreOrder: true, getUserConfirmation: true },
                { name: 'lost', nextStep: 'lost', ignoreOrder: true, getUserConfirmation: true },
                { name: 'abandoned', nextStep: 'abandoned', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'realisation',
            multiOrder: true,
            previousSteps:['formX', 'buildOffer', 'submitOffer'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'finished', getUserConfirmation: true },
                { name: 'question', nextStep: 'realisation', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'finished',
            type: 'validated',
            previousSteps:['formX', 'buildOffer', 'submitOffer', 'realisation']
        },
        {
            name: 'acceptedAmi',
            type: 'validated',
            previousSteps:['formX', 'buildOffer', 'submitOffer']
        },
        { name: 'noGo', type: 'refused' },
        { name: 'notSubmitted', type: 'refused' },
        { name: 'lost', type: 'refused' },
        { name: 'abandoned', type: 'refused' }
    ],
    profiles: [
        {
            name: 'formX',
            stepProfiles: [
                { step: 'formX' }
            ]
        },
        {
            name: 'buildOffer',
            stepProfiles: [
                { step: 'buildOffer'}
            ]
        },
        {
            name: 'submitOffer',
            stepProfiles: [
                { step: 'submitOffer'}
            ]
        },
        {
            name: 'realisation',
            stepProfiles: [
                { step: 'realisation'}
            ]
        }
    ]
}
export const workflowManager = createWorkflowManager(workflowParameters)

/**
 * Gets the workflow configs corresponding to the county
 * @param businessProject
 * @param context
 * @returns {*}
 */
export async function getWorkflowModel(businessProject, context){
    if(context.data?.workflowModels && context.data.workflowModels[businessProject.id]) {
        return context.data.workflowModels[businessProject.id]
    }
    const countryId = businessProject.country.id.toString()
    const typeOfOfferId = businessProject.typeOfOffer.id.toString()
    const estimatedT = businessProject.estimatedTurnover

    const pipeline = [
        // Step 1: Match based on countryId
        {
            $match: {
                countries: new global.ObjectID(countryId)
            }
        },
        // Step 2: Project only necessary fields early
        {
            $project: {
                id: { $toString: '$_id' },
                typesOfOffer: 1,
                minTurnover: 1,
                maxTurnover: 1,
                workflowConfigs: 1
            }
        },
        // Step 3: Filter based on typesOfOffer and turnover
        {
            $addFields: {
                typeOfOfferTest: {
                    $or: [
                        { $eq: [{ $size: "$typesOfOffer" }, 0] }, // No typesOfOffer
                        { $in: [new global.ObjectID(typeOfOfferId), "$typesOfOffer"] } // Type matches
                    ]
                },
                turnoverTest: {
                    $or: [
                        { $and: [{ $eq: ["$minTurnover", 0] }, { $eq: ["$maxTurnover", 0] }] }, // No turnover
                        { $and: [{ $lt: ["$minTurnover", estimatedT] }, { $gte: ["$maxTurnover", estimatedT] }] } // Turnover in range
                    ]
                }
            }
        },
        // Step 4: Filter documents that pass tests
        {
            $match: {
                typeOfOfferTest: true,
                turnoverTest: true
            }
        },
        // Step 5: Sorting logic
        {
            $addFields: { typesOfOfferSize: { $size: "$typesOfOffer" } }
        },
        {
            $sort: {
                typesOfOfferSize: -1,
                maxTurnover: -1
            }
        },
        { $limit: 1 }, // Limit to one workflowModel
        // Step 6: Lookup workflowConfigs from 'i.workflowConfig'
        {
            $lookup: {
                from: 'i.workflowConfig',
                let: { workflowConfigIds: '$workflowConfigs' }, // Define variable for workflowConfigs
                pipeline: [
                    {
                        $match: {
                            $expr: {
                                $in: ['$_id', '$$workflowConfigIds'] // Match workflowConfig IDs
                            }
                        }
                    },
                    {
                        $project: {
                            _id: 1,
                            profile: 1,
                            order: 1,
                            teamMemberProfile: 1
                        }
                    }
                ],
                as: 'workflowConfigs' // Output array of matched workflowConfigs
            }
        },
        { $unwind: '$workflowConfigs' }, // Unwind workflowConfigs if necessary
        // Step 7: Lookup teamMemberProfile from 'i.teamMemberProfile'
        {
            $lookup: {
                from: 'i.teamMemberProfile',
                let: { teamMemberProfileId: '$workflowConfigs.teamMemberProfile' },
                pipeline: [
                    {
                        $match: {
                            $expr: {
                                $eq: ['$_id', '$$teamMemberProfileId']
                            }
                        }
                    },
                    {
                        $project: {
                            id: { $toString: '$_id' }, // Convert _id to string
                            abreviation: 1,
                            teamMembers: 1
                        }
                    }
                ],
                as: 'workflowConfigs.teamMemberProfile'
            }
        },
        { $unwind: '$workflowConfigs.teamMemberProfile' },
        // Step 8: Lookup teamMembers and join user + countrys data in one stage
        {
            $lookup: {
                from: 'i.teamMember',
                let: { teamMemberProfileId: '$workflowConfigs.teamMemberProfile._id' },
                pipeline: [
                    {
                        $match: {
                            $expr: { $eq: ['$teamMemberProfile', '$$teamMemberProfileId'] }
                        }
                    },
                    {
                        $lookup: {
                            from: 'user',
                            let: { userId: '$user' },  // Define userId variable
                            pipeline: [
                                {
                                    $match: {
                                        $expr: { $eq: ['$_id', '$$userId'] } // Use $$userId for matching
                                    }
                                },
                                {
                                    $project: {
                                        id: { $toString: '$_id' }, // Transform to string
                                        active: 1
                                    }
                                }
                            ],
                            as: 'user'
                        }
                    },
                    { $unwind: {path: '$user', preserveNullAndEmptyArrays: true}},
                    {
                        $lookup: {
                            from: 'i.country',
                            let: { countryIds: '$countrys' }, // Define countryIds variable
                            pipeline: [
                                {
                                    $match: {
                                        $expr: { $in: ['$_id', '$$countryIds'] } // Use $$countryIds for matching
                                    }
                                },
                                {
                                    $project: {
                                        id: { $toString: '$_id' } // Transform to string
                                    }
                                }
                            ],
                            as: 'countrys'
                        }
                    },
                    {
                        $project: {
                            id: { $toString: '$_id' },
                            email: 1,
                            firstName: 1,
                            lastName: 1,
                            user: {
                                id: { $toString: '$user._id' },
                                active: 1
                            },
                            countrys: {
                                $map: {
                                    input: '$countrys',
                                    as: 'country',
                                    in: {
                                        id: { $toString: '$$country._id' }
                                    }
                                }
                            }
                        }
                    }
                ],
                as: 'workflowConfigs.teamMemberProfile.teamMembers'
            }
        },
        // Step 9: Avoid duplicate workflowConfigs and finalize structure
        {
            $group: {
                _id: '$_id',
                id: { $first: '$id' },
                typesOfOffer: { $first: '$typesOfOffer' },
                minTurnover: { $first: '$minTurnover' },
                maxTurnover: { $first: '$maxTurnover' },
                workflowConfigs: { $push: '$workflowConfigs' }
            }
        }
    ];

    const workflowModels = await global.app.I.WorkflowModel.collection.aggregate(pipeline).toArray()

    if(!workflowModels.length) return null

    const workflowModel = workflowModels[0]

    if(workflowModel) {
        if(!context.data) context.data = {}
        if(!context.data.workflowModels) context.data.workflowModels = {}
        context.data.workflowModels[businessProject.id] = workflowModel
    }
    return workflowModel
}

/**
 * Generates the default object for the workflow configs
 * @param workflowConfigs
 * @returns {*}
 */
export function getMaxOrders(workflowConfigs) {
    if (!workflowConfigs || !workflowConfigs.length) return null

    return _(workflowConfigs)
        .groupBy('profile')
        .mapValues(
            o => _(o).map('order').max()
        )
        .omitBy(o => o === 0)
        .value()
}

/**
* WorkflowUnit = {
    *   step: String,
    *   order: Number,
    *   type: String,
    *   teamMemberProfile: TeamMemberProfile
* }
*
**/
export function generateWorkflowUnits(
    workflowConfigs,
    maxOrders
) {
    //[{step, order, active, teamMemberProfile}]
    return _.flatMap(workflowConfigs, config => {
            //[{step, order}]
            const workflowUnits = getWorkFlowUnitsForConfig(
                config,
                maxOrders
            )
            return _.flatten(workflowUnits).map(workflowUnit => ({
                ...workflowUnit,
                teamMemberProfile: config.teamMemberProfile
            }))
        })
}

/**
* Generates workflow objects for a given config
**/

export function getWorkFlowUnitsForConfig(config, maxOrders) {
    let result = []
    const profile = workflowParameters.profiles.find(
        profile => profile.name === config.profile
    )
    if (profile) {
        result = _.flatMap(profile.stepProfiles, stepProfile => {
            const step = workflowParameters.steps.find(
                step => step.name === stepProfile.step
            )
            const orders = maxOrders[step.name]
                ? _.range(1, maxOrders[step.name] + 1)
                : []
            if (step.multiOrder) {
                return orders.reduce((results, order) => {
                    if (order === config.order) {
                        results.push({
                            step: stepProfile.step,
                            order,
                            type: step.type
                        })
                    }
                    return results
                }, [])
            } else {
                return [{
                    step: stepProfile.step,
                    order: 0,
                    type: step.type
                }]
            }
        })
    }

    return result
}

/**
* Get a string describing the current status of the workflow
**/
export function getStatusAndOrder({ workflow }, context) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    const stepTitle = context.tc ? context.tc(workflow.step) : workflow.step;
    return _.compact([
        stepTitle,
        workflow.order > 0 ? workflow.order : undefined
    ]).join(' / ')
}

export function getStatus({ workflow }) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    return workflow.step
}

function getCurrentStepOrder({ step, order }) {
    switch (step) {
        case 'formX':
        case 'buildOffer':
        case 'submitOffer':
        case 'realisation':
            return order + 1
        case 'finished':
        case 'noGo':
        case 'notSubmitted':
        case 'lost':
        case 'abandoned':
            return 1
        default:
            return 0

    }
}

export function getStepStatus({ workflow }) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    // for the finished steps, we return a special string
    if (['finished', 'noGo', 'notSubmitted', 'lost', 'abandoned'].includes(workflow.step)) return '-'

    const totalSteps = workflow.maxOrders.inProgress + 1

    return `${getCurrentStepOrder(workflow)}/${totalSteps}`
}

/**
 * Get list of current users for the businessProject
 * @param businessProject
 * @param context
 * @returns {Promise<*>}
 */
export async function getCurrentUsers(businessProject, context) {
    if(!businessProject.workflow) return []
    const currentStepAndOrder = _.pick(businessProject.workflow, ['step', 'order'])

    const currentFunctions = await getFunctionsForStepAndOrder(businessProject, currentStepAndOrder, context)
    const projectDelegations = await getProjectDelegations(businessProject, currentStepAndOrder, context)

    const userIsActiveWithCountryHabilitation = teamMember =>  teamMember.user.active === true && teamMember.countrys.some(
        country => country.id === businessProject.country.id
    )

    const usersWithCurrentFunctions = _.flatMap(currentFunctions, teamMemberProfile => {
        return teamMemberProfile.teamMembers.filter(userIsActiveWithCountryHabilitation)
    })
    const usersWithCurrentDelegations = projectDelegations
        .map(delegation => delegation.teamMember)
        .filter(userIsActiveWithCountryHabilitation)

    return _(usersWithCurrentFunctions)
        .concat(usersWithCurrentDelegations)
        .uniqBy(teamMember => teamMember.id)
        .value()
}

/**
* Get a string with the names of all the active HabFunctions for the project
**/
export async function getBusinessProjectTeamMemberProfile(businessProject, context) {
    if(!businessProject.workflow) return ''
    const currentStepAndOrder = _.pick(businessProject.workflow, ['step', 'order'])
    const currentFunctions = await getFunctionsForStepAndOrder(businessProject, currentStepAndOrder, context)

    return currentFunctions
        .map(teamMemberProfile => teamMemberProfile.abreviation)
        .join(', ')
}

export const getWorkflowUnits = async function(workflowModel) {
    const maxOrders = getMaxOrders(workflowModel.workflowConfigs)
    return generateWorkflowUnits(workflowModel.workflowConfigs, maxOrders)
}


export async function getFunctionsForStepAndOrder(businessProject, workflowObject, context) {
    const workflowModel = await getWorkflowModel(businessProject, context)

    if(!workflowModel) return []

    const workflowUnits = await getWorkflowUnits(workflowModel)

    return _(workflowUnits)
        .filter(workflowObject)
        .map(workflowUnit => workflowUnit.teamMemberProfile)
        .uniqBy(teamMemberProfile => teamMemberProfile.id)
        .value()
}

export async function getProjectDelegations(businessProject, workflowObject, context) {
    if(!workflowObject) return []
    return await global.app.I.StaticWorkflow.find({
        ...basicContext(context),
        query: {
            businessProject: new global.ObjectID(businessProject.id),
            step: workflowObject.step,
            order: workflowObject.order
        },
        fieldPath: ['id', 'teamMember.id', 'teamMember.user.id', 'teamMember.countrys.id']
    })
}

async function userHasActiveDelegation(teamMember, businessProject, workflowObject, context) {
    const delegations =  await global.app.I.StaticWorkflow.collection.find({
        businessProject: new global.ObjectID(businessProject.id),
        step: workflowObject.step,
        order: workflowObject.order,
        teamMember: new global.ObjectID(teamMember.id),
        group: new global.ObjectID(context.group.id)
    }).toArray()
    return !!delegations.length
}

/*
* Get workflow buttons for business project.
*
*
* */
export async function getBusinessProjectButtons(businessProject, context){

    if(!businessProject.workflow) return [{
        tKey: 'return',
        bsStyle: 'default',
        type: 'return'
    }]

    const teamMembers = await global.app.I.TeamMember.find({
        ...basicContext(context),
        fieldPath: [
            'user.active',
            'countrys.id',
            'teamMemberProfile.id'
        ],
        query: {
            user: new global.ObjectID(_.get(context, 'user.id'))
        }
    })

    if(!teamMembers.length) return [{
        tKey: 'return',
        bsStyle: 'default',
        type: 'return'
    }]

    const teamMember = teamMembers[0]

    const currentStepAndOrder = _.pick(businessProject.workflow, ['step', 'order'])

    const currentFunctions = await getFunctionsForStepAndOrder(businessProject, currentStepAndOrder, context)
    const userHasDelegation = await userHasActiveDelegation(teamMember, businessProject, currentStepAndOrder, context)

    const userIsActive = teamMember.user.active
    const userHasCountryHabilitation = teamMember.countrys.map(country => country.id).includes(businessProject.country.id)
    const userHasCurrentFunction = currentFunctions
        .map(teamMemberProfile => teamMemberProfile.id)
        .includes(_.get(teamMember, 'teamMemberProfile.id'))

    const userIsAllowedActions = userIsActive && userHasCountryHabilitation && (userHasCurrentFunction || userHasDelegation)

    return _([
        userIsAllowedActions && getWorkflowButtonsForStep(businessProject),
        {
            tKey: 'return',
            bsStyle: 'default',
            type: 'return'
        }
    ]).flatten().compact().value()
}

function getWorkflowButtonsForStep(businessProject) {
    const isAmiBP = _.get(businessProject, 'responseMode.id') === 'ami'
    const {step, order} = businessProject.workflow
    const stepObject =
        workflowParameters.steps.find(stepObject => stepObject.name === step) ||
        {}

    const filteredActions = order === 1
        ? stepObject.actions.filter(o => o.name !== 'question')
        : stepObject.actions

    const actions = stepObject.actions
        ? [
            { name: 'save' },
            ...filteredActions
        ]
        : []

    const reformObject = action => ({
        action: action.name,
        bsStyle: bsStyleForAction(action.name),
        getUserConfirmation: !!action.getUserConfirmation,
        tKey: action.tKey || action.name,
        type: 'action'
    })

    if(step === 'submitOffer') {
        return isAmiBP
            ? actions.filter(o => o.name !== 'validate').map(reformObject)
            : actions.filter(o => o.name !== 'validateAmi').map(reformObject)
    }else {
        return actions.map(reformObject)
    }
}

/*
* Defines the bootstrap button style for the action
* */
function bsStyleForAction(action) {
    switch (action) {
        case 'save':
            return 'success'
        case 'question':
            return 'warning'
        case 'noGo':
        case 'notSubmitted':
        case 'lost':
        case 'abandoned':
            return 'danger'
        case 'validate':
        case 'validateAmi':
        default:
            return 'primary'
    }
}
