const moment = require('moment')
const crypto = require('../../../utils/crypto')

export const writeXml = (lot, bank) =>
    `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<Document xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:iso:std:iso:20022:tech:xsd:pain.001.001.03">
<CstmrCdtTrfInitn>
<GrpHdr>
<MsgId>Bord num ${lot.reference}</MsgId>
<CreDtTm>${lot.creationDate}</CreDtTm>
<NbOfTxs>${lot.orderLines.length}</NbOfTxs>
<CtrlSum>${lot.amount}</CtrlSum>
<InitgPty>
<Nm>CE ENGIE MANAGEMENT COMPANY</Nm>
</InitgPty>
</GrpHdr>
<PmtInf>
<PmtInfId>Bord num ${lot.reference}</PmtInfId>
<PmtMtd>TRF</PmtMtd>
<NbOfTxs>${lot.orderLines.length}</NbOfTxs>
<CtrlSum>${lot.amount}</CtrlSum>
<PmtTpInf>
<SvcLvl>
<Cd>SEPA</Cd>
</SvcLvl>
</PmtTpInf>
<ReqdExctnDt>${moment().format('YYYY-MM-DD')}</ReqdExctnDt>
<Dbtr>
<Nm>CE ENGIE MANAGEMENT COMPANY</Nm>
</Dbtr>
<DbtrAcct>
<Id>
<IBAN>${crypto.decrypt(bank.iban)}</IBAN>
</Id>
</DbtrAcct>
<DbtrAgt>
<FinInstnId>
<BIC>${crypto.decrypt(bank.bic)}</BIC>
</FinInstnId>
</DbtrAgt>
<ChrgBr>SLEV</ChrgBr>
${lot.orderLines.reduce((acc, line, index) => {
    return acc + `<CdtTrfTxInf>
<PmtId>
<InstrId>${index}-${line.processType === 'invoice'? 'Reglt-FACT.' : 'Vers.' }</InstrId>
<EndToEndId>${line.reason}</EndToEndId>
</PmtId>
<Amt>
<InstdAmt Ccy="EUR">${line.amount}</InstdAmt>
</Amt>
<CdtrAgt>
<FinInstnId>
<BIC>${crypto.decrypt(line.BIC)}</BIC>
</FinInstnId>
</CdtrAgt>
<Cdtr>
<Nm>${line.recipient}</Nm>
</Cdtr>
<CdtrAcct>
<Id>
<IBAN>${crypto.decrypt(line.IBAN)}</IBAN>
</Id>
</CdtrAcct>
<RmtInf>
<Ustrd>${line.unstructured}</Ustrd>
</RmtInf>
</CdtTrfTxInf>`
    }, '')
}
</PmtInf>
</CstmrCdtTrfInitn>
</Document>`
