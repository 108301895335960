import { createSelector } from 'reselect'
import * as entitiesSelectors from './entities'
import * as dashboardSelectors from './dashboard'
import * as networkSelectors from './network'
import * as userSelectors from './user'
import { chainChildSelectors } from '../../../utils/selector'
import _ from 'lodash'

// root selectors
export const getEntities = state => state.entities
export const getUser = state => state.user
export const getNetwork = state => state.network
export const getDashboard = state => state.dashboard
export const getUi = state => state.ui

// children chaining
export const entities = chainChildSelectors(getEntities, entitiesSelectors)
export const network = chainChildSelectors(getNetwork, networkSelectors)
export const user = chainChildSelectors(getUser, userSelectors)
export const dashboard = chainChildSelectors(getDashboard, dashboardSelectors)

export const getClient = state => getUi(state).client
export const getLanguage = state => getUi(state).language

export const isRouteError = state => getNetwork(state).isRouteError
export const isNotFoundPage = state => getNetwork(state).isNotFoundPage

export const getGroupModels = state => getEntities(state).groupModels
export const getGroups = state => getEntities(state).groups
export const getDashboardElements = state => getEntities(state).dashboardElements
export const getAlerts = state => getEntities(state).alerts
export const getDocuments = state => getEntities(state).documents
export const getMediaElements = state => getEntities(state).mediaElements

export const getDashboardData = state => getDashboard(state).dataByElement
export const dashboardDataIsFetching = state => getDashboard(state).dataIsFetching
export const elementsAreFetching = state => getDashboard(state).elementsAreFetching

// custom compositions

export const getUserData = createSelector(
    getEntities,
    user.getId,
    (entities, userId) => {
        const { users } = entities
        return users && users[userId]
    }
)

export const getUserGroupModels = createSelector(
    getEntities,
    getUserData,
    (entities, user) => {
        try {
            // recover the hole tree of data
            const { models, groups, modules, groupModels, jobs } = entities

            return user.groupModels.map(id => {
                const groupModel = groupModels[id]
                return {
                    ...groupModel,
                    model: models[groupModel.model],
                    group: groups[groupModel.group],
                    modules: groupModel.modules.map(
                        module => modules[module]
                    ),
                    jobs: groupModel.jobs.map(job => jobs[job])
                }
            })
        } catch (error) {
            // if state data is not consistent, we send the default value
            return []
        }
    }
)

export const getIsLogged = createSelector(user.getId, id => !!id)

export const isUserK = createSelector(getUserData, user => {
    return user ? user.k : false
})

export const isAccountManager = createSelector(
    getUserGroupModels,
    groupModels =>
        groupModels.some(groupModel => _.get(groupModel, 'group.super_'))
)
