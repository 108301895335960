import _ from "lodash";
import {basicContext} from "../../../../utils/contextUtils";

export const entity = {
    // axe de ventilation étape prévalidation
    // status actif inactif pour echéance
    // echeance date à date
    // comité projet : date de revue (plage entre debut de l'echeance en cours et aujourdh'ui)
    // une ligne des totaux
    // budget table estimé (pour chaque type il faut avoir une colonne de global et une autre par echeance)
    //
    name: 'Organization',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields: [
        { path: 'organizationalAxis', type:'OrganizationalAxis', link:'MTO'},
        {
            path:  'nameWithOrganizationalAxis',
            fieldPath: ['organizationalAxis.name', 'name'],
            $f: function(object, context, callback) {
                if(!context.translateName) return callback(null, `${object.organizationalAxis.name} : ${object.name}`)
                callback(null, `${context.translateName(object.organizationalAxis.name)} : ${context.translateName(object.name)}`)
            }
        }
    ],
    filters: [
        {
            name: 'inWorkflow',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.SE.WorkFlow.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {}
                }, (e, workflows) => {
                    if (e) return callback(e)
                    if (!workflows.length) {
                        return callback(null, {_id: null})
                    }
                    else {
                        let organizationsInWorkflows = workflows.map( wf => wf.organizationAndMesh)
                        console.log('organizationsInWorkflows', organizationsInWorkflows)
                        organizationsInWorkflows = organizationsInWorkflows.flat(1)
                        console.log('organizationsInWorkflows', organizationsInWorkflows)
                        callback(null, { _id : {$in: organizationsInWorkflows.map(org => global.ObjectID(org.id))} })
                    }
                })
            }
        },
        {
            name: 'byHabilitation',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.SE.Habilitation.find({
                    ...basicContext(context),
                    fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                    query: {
                        user: global.ObjectID(context.user.id),
                    }
                }, (e, userHabilitations) => {
                    if (e) return callback(e)
                    if (!userHabilitations.length) {
                        return callback(null, {_id: null})
                    }
                    else {
                        let userGrantedOrgsAndMeshes = []   //array of arrays
                        for (let i = 0; i < userHabilitations.length; i++) {
                            const hab = userHabilitations[i]
                            if ( !hab.grantedAccess.length && !hab.grantedMesh.length) {
                                return callback(null, {})
                            }
                            else {
                                userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                            }
                        }
                        const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                        global.app.SE.OrganizationalMesh.find(
                            {
                                ...basicContext(context),
                                fieldPath: [],
                                query: {
                                    $or: [
                                        {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                        {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                    ]
                                }
                            }, (er, meshes) => {
                                if (er) return callback(er)
                                let userGrantedOrgs = []
                                userHabilitations.forEach( hab => {
                                    userGrantedOrgs.push(hab.grantedAccess)
                                })
                                if (!!meshes.length) {
                                    meshes.forEach(mesh => {
                                        userGrantedOrgs.push(mesh.attachments)
                                    })
                                }
                                userGrantedOrgs = userGrantedOrgs.flat(1)
                                callback(er, { _id : {$in: userGrantedOrgs.map(org => global.ObjectID(org.id))} })
                            }
                        )
                    }
                })
            }
        },
        {
            name: 'inWorkflowEnabledAxe',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.SE.OrganizationalAxis.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {
                        workflowEnabledAxe: true
                    }
                }, (e, axes) => {
                    if (e) return callback(e)
                    const axesIds = axes.map(axis => global.ObjectID(axis.id))
                    callback(null, {organizationalAxis: {$in: axesIds}})
                })
            }
        },
        {   //verified
            name: 'byThisOrganizationalAxis',
            tKey: 'organizationalAxis',
            isDefault: false,
            client: true,
            path: 'organizationalAxis',        //on va stocker les valeurs choisies dans le filtre par l'utilisateur dans ce path
            object: 'OrganizationalAxis',      //l'entité des élements qu'on va filter with
            display: 'name',
            translateName: true,
            clearable: true,
            query: function(context) {
                const organizationalAxisID = context.data && context.data.organizationalAxis && context.data.organizationalAxis.id
                return organizationalAxisID ? {"organizationalAxis": global.ObjectID(organizationalAxisID)} : {}
            }
        },
        { //verified
            name: "byOrganizationalAxis",
            isDefault: false,
            query: function(context) {
                const organizationalAxisID = _.get(context.data, 'organizationalAxis.id')
                return organizationalAxisID ? {"organizationalAxis": global.ObjectID(organizationalAxisID)} : {"organizationalAxis": "0"}
            }
        },
        {
            name: "attachedToOrganization",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const organizationId = _.get(context, 'data.organizationId')

                if(!organizationId) return callback(null, {_id: null})

                global.app.SE.OrganizationalMesh.find({
                    ...basicContext(context),
                    fieldPath: ['attachments.organizationalAxis.projectBreakdownAxe'],
                    query: {
                        attachments: {$elemMatch: {$eq: global.ObjectID(organizationId)}}
                    }
                }, (e, meshes) => {
                    if(e) return callback(e)
                    const organizationsIds = _.flatMap(meshes, mesh => {
                        return mesh.attachments
                            .filter(org => !!_.get(org, 'organizationalAxis.projectBreakdownAxe'))
                            .map(org => org.id)
                    })

                    if(!organizationsIds.length) return callback(null, {_id: null})
                    callback(null, {_id: {$in: organizationsIds.map(id => global.ObjectID(id))}})
                })
            }
        }
    ]
}

export const module_ = {
    object: 'Organization',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    tKey: 'Organisation',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',  //DESC
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'}
        ],
        form: [
            {path: 'organizationalAxis', translateName: true},
            {path: 'code', editable: false},
            {path: 'name', type: 'textarea', unique: true}
        ],
    },
    filters: ['byThisOrganizationalAxis']
}
