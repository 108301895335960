import moment from 'moment'
import _ from 'lodash'
import {getHabilitations} from '../../utils/habilitations'

async function findData(context){

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')


    const dashboardDates = [lastWeekISO, yesterdayISO]

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
    const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habQuery = stores.length || organizations.length
        ? {
            $or: [
                {store: {$in : stores}},
                {organizations: {$in: organizations}}
            ],
        }
        : {}

    const alertConfigurationCollection = global.app.S.AlertConfiguration.collection
    const alertConfigurationQuery = alertConfigurationCollection
        .find({
            code: "DashboardDRCAMagasin7jours"
        })
        .toArray()

    const [alertConfigurations] = await Promise.all([alertConfigurationQuery])
    const alertConfiguration =  alertConfigurations[0]

    const dates = [lastWeekISO, todayISO].map(date => moment.utc(date.trim(), 'DD-MM-YYYY'))

    const organizationAlertsPromise = global.app.S.Alert.collection
        .aggregate([
            {
                $match: {
                    'date.gte': { $gte: dates[0].toDate() },
                    'date.lt': { $lte: dates[1].toDate() },
                    'alertConfiguration': alertConfiguration._id,
                    'theme-CANet.Mt': {$exists: true},
                    ...habQuery
                }
            },
            { $sort : { 'theme-CANet.Mt' : 1} }
        ])
        .toArray()

    const [organizationAlerts] = await Promise.all([organizationAlertsPromise])

    const formatedAlerts = organizationAlerts.map( o => ({
        store: _.get(o, 'store'),
        value: _.round(_.get(o, 'theme-CANet.Mt'), 1),
        //color: '#4fd4ee'
    }))

    return [{
        id: "drTurnoverPerStore",
        subTitle: {
            text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
            font: '11px',
            color: '#888383'
        },
        data: formatedAlerts
    }]
}

export const entity = {
    name: 'DrTurnoverPerStore',
    dashboard: true,
    fields: [
        "id",
        "subTitle",
        "data",
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error);
            else findData(context)
                .then(objects => callback(null, objects))
                .catch(error => callback(error))
        });
    }
}
