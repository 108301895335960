const configureWorkbook = (projects, fieldGetters, context) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Projets')

    let row = 1
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const columns = Object.keys(fieldGetters).map(key => ({
        key, getter: fieldGetters[key]
    }))

    columns.forEach(column => {
        dataSheet.getRow(row).getCell(col).value = context.tc(column.key)
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    row = 2
    projects.forEach(project => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = column.getter(project)
            col++
        })
        row++
    })

    return workbook
}

export async function generateExcel(fileName, projects, fieldGetters, context) {
    return await new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(projects, fieldGetters, context),
            `${fileName}.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            }
        );
    });
}
