const _ = require('lodash');
const moment = require('moment')

async function findData(context) {

    const typeOfOfferProjectCollectionName = global.app.I.TypeOfOffer.collectionName
    const rangeOfOfferProjectCollectionName = global.app.I.RangeOfOffer.collectionName
    const businessProjectCollection = global.app.I.BusinessProject.collection
    const year = moment().year()
    const signedBusinessProjectQuery = businessProjectCollection
        .aggregate([
            {
                $match: {signatureDate: {$ne: null}}
            },
            {
                $project:{
                    signatureYear: {$year: '$signatureDate'},
                    typeOfOffer: 1
                }
            },
            {
                $match: {signatureYear: year}
            },
            {
                $lookup: {
                    from: typeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $unwind: '$rangeOfOffer'
            },
            {
                $group: {
                    _id: { rangeOfOffer: '$rangeOfOffer.code' },
                    rangeOfOffer: {$first: '$rangeOfOffer.code'},
                    count: {$sum:1},
                }
            }
        ])
        .toArray()

    const realizationBusinessProjectQuery = businessProjectCollection
        .aggregate([
            {
                $match: {'workflow.step': 'realisation'}
            },
            {
                $lookup: {
                    from: typeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $unwind: '$rangeOfOffer'
            },
            {
                $group: {
                    _id: { rangeOfOffer: '$rangeOfOffer.code' },
                    rangeOfOffer: {$first: '$rangeOfOffer.code'},
                    count: {$sum:1},
                }
            }
        ])
        .toArray()

    const [
        signedBusinessProjects,
        realizationBusinessProjects
    ] = await Promise.all([
        signedBusinessProjectQuery,
        realizationBusinessProjectQuery
    ])

    const signedByProductLine = _.groupBy(signedBusinessProjects, 'rangeOfOffer')
    const realizationByProductLine = _.groupBy(realizationBusinessProjects, 'rangeOfOffer')

    const rangeOfOffers = _.uniq([...Object.keys(signedByProductLine), ...Object.keys(realizationByProductLine)])

    const data = _.sortBy(rangeOfOffers).map(rangeOfOffer => {
        const poductLineSignedData = signedBusinessProjects.find(o => o.rangeOfOffer === rangeOfOffer)
        const productLineRealizationData = realizationBusinessProjects.find(o => o.rangeOfOffer === rangeOfOffer)
        return {
            axis: rangeOfOffer,
            signed: poductLineSignedData ? poductLineSignedData.count : 0,
            inProgress: productLineRealizationData ? productLineRealizationData.count : 0,
        }
    })

    const totalRealization = _.reduce(data, (acc, o) =>  acc + o.inProgress, 0)
    const totalSigned = _.reduce(data, (acc, o) =>  acc + o.signed, 0)

    return [
        {
            id: "contractsNumberPerProductLine",
            subTitle: {
                text: 'contractsNumberSubtitle',
                data: {
                    totalSigned: totalSigned,
                    totalRealization: totalRealization
                }
            },
            data
        }
    ]
}

export const entity = {
    name: 'ContractsNumberPerProductLine',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
