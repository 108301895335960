const _ = require('lodash')
const moment = require('moment')
const async = require('async')
const Errors = require('../../../utils/Errors').default
const { setFieldListOptions, setFieldVisibility, setDataList } = require('../../../../apps/KpModule/actions')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')
const { basicContext } = require('../../../utils/contextUtils')
const {
    generateData,
    duplicateProjectConf,
} = require('./hypothesisDataEngine')


export const entities = [
    {
        name: 'LeaseConfiguration',
        fields: [
            'LeaseElement',
            'LeaseType',
            { type: 'LeaseRenewalsNumber', nullable: true },
            { type: 'LeaseDuration', nullable: true},
            { path: 'main', type: 'integer', nullable: true },
            { path: 'facade', type: 'integer', nullable: true },
            { path: 'annex', type: 'integer', nullable: true },
            { path: 'startDate', type: 'date', nullable: true}
        ]
    },
    {
        name: 'ProjectConfiguration',
        facets: ['description'],
        fields: [
            'Subsidiary',
            'Country',
            'Pole',
            'city',
            'localization',
            'version',
            { path: 'status', type: 'ProjectConfigurationStatus', nullable: true },
            'versionOrder',
            'BPProjectType',
            { path: 'openingDate', type: 'date', $default: () => moment().format('YYYY-MM-DD') },
            { path: 'yearStartBP', type: 'integer', $default: () => moment().add(2, 'years').year() },
            { path: 'yearHIDATA', type: 'integer', $default: () => moment().year() },
            {
                type: 'LeaseConfiguration',
                link:'OTM'
            },
            {
                type: 'LeaseData',
                link:'OTM'
            },
            {
                type: 'HypothesisData',
                link:'OTM'
            },
            {
                path: 'code',
                fieldPath: ['country.code', 'city', 'localization', 'bPProjectType.id', 'openingDate'],
                f: function() {
                    return `BA ${this.country.code} ${this.city} ${this.localization} - ${this.bPProjectType.id}${moment(this.openingDate).year()}`
                }
            },
            {
                path: 'versionAndOrder',
                fieldPath: ['versionOrder.id', 'version'],
                f: function() {
                    return `${this.versionOrder.order} - ${this.version}`
                }
            },
            {
                path: 'projectName',
                fieldPath: ['pole.name', 'country.code', 'city', 'localization'],
                f: function() {
                    return `${this.pole.name} - ${this.country.code} - ${this
                        .city} / ${this.localization}`
                }
            },
            {
                path: 'projectId',
                fieldPath: ['pole.name', 'country.code', 'city', 'localization', 'version', 'versionAndOrder'],
                f: function() {
                    return `${this.pole.name} - ${this.country.code} - ${this
                        .city} / ${this.localization} (ver. ${this.versionOrder.order} - ${this.version})`
                }
            },
            {
                path: 'buttons',
                fieldPath: ['id', 'status.id'],
                f: function() {
                    const returnButton = {
                        tKey: 'return',
                        bsStyle: 'default',
                        type: 'return'
                    }
                    const saveButton = {
                        tKey: 'save',
                        bsStyle: 'success',
                        type: 'save'
                    }
                    const duplicateButton = {
                        tKey: 'duplicate',
                        action: 'duplicate',
                        bsStyle: 'primary',
                        getUserConfirmation: true,
                        pristine: true,
                        type: 'action'
                    }

                    if(this.id) {
                        return this.status.id === 'ongoing'
                            ? [saveButton, duplicateButton, returnButton]
                            : [duplicateButton, returnButton]
                    } else {
                        return [saveButton, returnButton]
                    }
                }
            }
        ],
        filters: [
            {
                name: 'onlyAffectedSubsidiary',
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.B.SubsidiaryAssignment.collection
                        .find({
                            user: new global.ObjectID(context.user.id),
                            group: new global.ObjectID(context.group.id)
                        })
                        .toArray((error, assignments) => {
                            const ids = _.flatMap(
                                assignments,
                                assignement => assignement.subsidiaries
                            )
                            callback(error, {
                                subsidiary: { $in: ids }
                            })
                        })
                }
            },
            {
                name: 'bySubsidiary',
                isDefault: false,
                query: function(context) {
                    const subsidiaryId = _.get(context.data, 'subsidiary.id')
                    return subsidiaryId
                        ? { subsidiary: new global.ObjectID(subsidiaryId) }
                        : {_id: null}
                }
            },
            {
              name: 'onlyEndorsedVersions',
              isDefault: false,
              query: () => ({status: 'endorsed'})
            },
            {
                name: 'projectConfForSubmittedAndEndorsedModules',
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    const projectConfigurationId = _.get(context.data, 'projectConfiguration')
                    if(!!projectConfigurationId) {
                        const targetStatus = _.get(context, 'module.name') === 'Submission'
                            ? ['ongoing', 'submitted']
                            : ['submitted', 'endorsed']
                        global.app.B.ProjectConfiguration.get(
                            projectConfigurationId,
                            {
                                ...basicContext(context),
                                fieldPath: [
                                    'subsidiary.id', 'country.id', 'pole.id',
                                    'version', 'city', 'localization', 'group.id', 'yearStartBP'
                                ]
                            },
                            (e, projectConfiguration) => {
                                if(projectConfiguration) {
                                    callback(e, {
                                        ..._.pick(projectConfiguration, ['city', 'localization', 'yearStartBP']),
                                        subsidiary: new global.ObjectID(projectConfiguration.subsidiary.id),
                                        country: new global.ObjectID(projectConfiguration.country.id),
                                        pole: new global.ObjectID(projectConfiguration.pole.id),
                                        bPProjectType: projectConfiguration.bPProjectType,
                                        group: new global.ObjectID(context.group.id),
                                        status: {$in: targetStatus}
                                    })
                                } else {
                                    callback(null, {_id: null})
                                }
                            }
                        )
                    } else {
                        callback(null, {})
                    }
                }
            }
        ],
        // afterDelete: function (object, context, callback) {
        //     const projectConfiguration = new global.ObjectID(object.id)
        //     global.db.collection("b.hypothesisData").deleteMany(
        //         {projectConfiguration, group: new global.ObjectID(context.group.id)},
        //         callback
        //     )
        // },
        shouldHaveMainLeaseConfigForM2: function(newObject, oldObject, context, callback) {
            if(['N', 'R'].includes(_.get(newObject, 'bPProjectType.id'))) {
                const hasMainLeaseConfigForM2 = newObject.leaseConfigurations.some(
                    leaseConfig => {
                        return _.get(leaseConfig, 'leaseElement.id') === 'm2'
                            && _.get(leaseConfig, 'leaseType.id') === 'principal'
                    }
                )

                if(hasMainLeaseConfigForM2) return callback()

                return callback(new Error('shouldHaveMainLeaseConfigForM2'))
            }
            callback()
        },
        shouldHaveMainLeaseConfigForM1M2: function(newObject, oldObject, context, callback) {
            if(!['N', 'R'].includes(_.get(newObject, 'bPProjectType.id'))) {
                const hasMainLeaseConfigForM1 = newObject.leaseConfigurations.some(
                    leaseConfig => {
                        return _.get(leaseConfig, 'leaseElement.id') === 'm1'
                            && _.get(leaseConfig, 'leaseType.id') === 'principal'
                    }
                )

                const hasMainLeaseConfigForM2 = newObject.leaseConfigurations.some(
                    leaseConfig => {
                        return _.get(leaseConfig, 'leaseElement.id') === 'm2'
                            && _.get(leaseConfig, 'leaseType.id') === 'principal'
                    }
                )

                if(hasMainLeaseConfigForM1 && hasMainLeaseConfigForM2) return callback()

                return callback(new Error(context.tc('shouldHaveMainLeaseConfigForM1M2', {
                    scenario: hasMainLeaseConfigForM1 ? 'M2' : 'M1'
                })))
            }
            callback()
        },
        TheCoupleScenarioLeaseShouldBeUnique: function(newObject, oldObject, context, callback) {
            try {
                const couplesArray = []
                newObject.leaseConfigurations.forEach(leaseConfig => {
                    const uniqueIdentifier = `${_.get(leaseConfig, 'leaseElement.id')}-${_.get(leaseConfig, 'leaseType.id')}`

                    if(couplesArray.indexOf(uniqueIdentifier) !== -1) {
                        throw(new Error(context.tc('theCoupleScenarioLeaseShouldBeUnique')))
                    }
                    else couplesArray.push(uniqueIdentifier)
                })
                callback()
            } catch (e) {
                callback(e)
            }
        },
        shouldNotHaveLeaseImpactForM1: function(newObject, oldObject, context, callback) {
                const hasLeaseImpactForM1 = newObject.leaseConfigurations.some(
                    leaseConfig => {
                        return _.get(leaseConfig, 'leaseElement.id') === 'm1'
                            && _.get(leaseConfig, 'leaseType.id') === 'leaseImpact'
                    }
                )
                if(!hasLeaseImpactForM1) return callback()
                return callback(new Error('shouldNotHaveLeaseImpactForM1'))
        },
        validateSave: function(newObject, oldObject, context, callback) {
            if(!['duplicate', 'duplication'].includes(_.get(context, 'action'))) {
                async.series([
                    callback => this.shouldHaveMainLeaseConfigForM2(newObject, oldObject, context, callback),
                    callback => this.shouldHaveMainLeaseConfigForM1M2(newObject, oldObject, context, callback),
                    callback => this.TheCoupleScenarioLeaseShouldBeUnique(newObject, oldObject, context, callback),
                    callback => this.shouldNotHaveLeaseImpactForM1(newObject, oldObject, context, callback),
                ], callback)
            } else {
                callback(null, newObject, oldObject)
            }
        },
        beforeSave: function(object, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            if (action === 'C' || _.get(context, 'action') === 'duplication') {
                object.status = {id: 'ongoing'}
                global.db.collection("b.projectConfiguration").findOne(
                    {
                        ..._.pick(object, ['city', 'localization', 'yearStartBP']),
                        pole: new global.ObjectID(object.pole.id),
                        country: new global.ObjectID(object.country.id),
                        subsidiary: new global.ObjectID(object.subsidiary.id),
                        bPProjectType: object.bPProjectType.id,
                        group: new global.ObjectID(context.group.id)

                    },
                    (e, projectConf) => {
                        if(projectConf) {
                            const projectConfMaxVersionOrder = Number.parseInt(projectConf.versionOrder.max, 10) +1

                            object.versionOrder = {
                                order: projectConfMaxVersionOrder,
                                max: projectConfMaxVersionOrder
                            }
                            global.db.collection("b.projectConfiguration").updateMany(
                                {
                                    ..._.pick(object, ['city', 'localization', 'yearStartBP']),
                                    pole: new global.ObjectID(object.pole.id),
                                    country: new global.ObjectID(object.country.id),
                                    subsidiary: new global.ObjectID(object.subsidiary.id),
                                    bPProjectType: object.bPProjectType.id,
                                    group: new global.ObjectID(context.group.id)
                                },
                                {
                                    $set: {'versionOrder.max': projectConfMaxVersionOrder}
                                },
                                e => {
                                    if(e) return callback(e)
                                    callback(null, object, oldObject)
                                }
                            )
                        } else {
                            object.versionOrder = {
                                order: 1,
                                max: 1
                            }
                            callback(null, object, oldObject)
                        }
                    }
                )

            } else {
                callback(null, object, oldObject)
            }
        },
        afterSave: function(object, oldObject, context, callback) {
            if(_.get(context, 'action') === 'duplicate') {
                duplicateProjectConf(object, oldObject, context)
                    .then(o => callback(null, o))
                    .catch(e => callback(e))
            } else if ( ! ['updatingLease', 'duplication'].includes(_.get(context, 'action')) ) {
                generateData(object, oldObject, context)
                    .then(o => callback(null, o))
                    .catch(e => callback(e))
            } else {
                callback(null, object)
            }
        },
        ps: {
            delete: [
                {
                    $v: function (projectConfiguration) {
                        if(['submitted', 'endorsed'].includes(_.get(projectConfiguration, 'status'))) {
                            return new Errors.ValidationError(this.options.context.tc('onlyOngoingProjectConfigurationCanBeDeleted'))
                        }
                    }
                }
            ]
        }
    }
]

export const module_ = {
    object: 'ProjectConfiguration',
    tKey: 'mTitle_projectConfiguration',
    defaultSortBy: 'projectName',
    defaultSortDirection: 'ASC',
    category: {
        path: 'bpStore',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            { path: 'country', display: 'fullName', initiallyNotVisible: true, width: 120 },
            { path: 'subsidiary', display: 'fullName', initiallyNotVisible: true, width: 120 },
            { path: 'projectName', tKey: 'name' },
            { path: 'versionAndOrder', width: 120 },
            { path: 'pole', display: 'fullName', width: 50 },
            { path: 'bPProjectType', tKey: 'type', width: 50 },
            { path: 'openingDate', width: 50 },
            { path: 'yearStartBP', initiallyNotVisible: true, width: 50 },
            { path: 'yearHIDATA', width: 40 },
            { path: 'status', width: 40, translate: true }
        ],
        form: {
            fields: [
                {
                    path: 'subsidiary',
                    display: 'fullName',
                    fieldPath: ['id', 'fullName', 'country'],
                    filters: ['inUserPerimeter'],
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if (newValue && newValue.id) {
                                const subsidiaryQuery = {
                                    subsidiary: { id: newValue.id }
                                }
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-B-projectConfiguration.ProjectConfiguration_country',
                                        store.getState,
                                        'form',
                                        { data: subsidiaryQuery }
                                    )
                                )

                                const countryField = module.viewMap.form.fields.find(
                                    field => field.path === 'country'
                                )
                                countryField.setValue(_.pick(newValue.country, ['id', 'fullName']))
                            } else {
                                store.dispatch(setFieldListOptions('e_country', []))
                            }
                        }
                    }
                },
                {path: 'country', editable: false, display: 'fullName'},
                {path: 'pole', editable: false, display: 'fullName'},
                {path: 'city', required: true},
                {path: 'localization', required: true},
                'projectName',
                {path: 'version', required: true},
                {path: 'bPProjectType', editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { store }) => {
                            if (newValue && newValue.id) {
                                const defaultObjects= [
                                    { id: 'principal', code: 'MAIN', name: 'mainStore'},
                                    { id: 'otherLocal1', code: 'OTHER_1', name: 'otherLocal1' },
                                    { id: 'otherLocal2', code: 'OTHER_2', name: 'otherLocal2' },
                                    { id: 'temporaryStore', code: 'TEMPORARY', name: 'temporaryStore' }
                                ]
                                if(['N', 'R'].includes(newValue.id)) {
                                    store.dispatch(setDataList(
                                        'm-B-projectConfiguration.LeaseConfiguration_leaseElement',
                                        [{ id: 'm2', name: 'M2NewStore' }]
                                        )
                                    )
                                    store.dispatch(setDataList(
                                        'm-B-projectConfiguration.LeaseConfiguration_leaseType',
                                        defaultObjects
                                        )
                                    )

                                } else {
                                    store.dispatch(setDataList(
                                        'm-B-projectConfiguration.LeaseConfiguration_leaseElement',
                                        [
                                            { id: 'm2', name: 'M2NewStore' },
                                            { id: 'm1', name: 'M1CurrentStoreContinuity' }
                                            ]
                                        )
                                    )
                                    store.dispatch(setDataList(
                                        'm-B-projectConfiguration.LeaseConfiguration_leaseType',
                                        [
                                            ...defaultObjects,
                                            { id: 'leaseImpact', code: 'IMPACT_M1_M2', name: 'impactM1M2' }
                                        ])
                                    )
                                }
                            }
                        }
                    }
                },
                {path: 'openingDate', required: true},
                {path: 'yearStartBP', wholePositiveNumber: true, required: true, editable: false},
                {path: 'yearHIDATA', wholePositiveNumber: true, required: true},
                {
                    path: 'leaseConfigurations',
                    tKey: 'lease',
                    defaultSortBy: 'leaseElement',
                    defaultSortDirection: 'ASC',
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: 'leaseElement', tKey: 'scenario'},
                            {path: 'leaseType', tKey: 'lease'},
                            {path: 'leaseDuration'},
                            {path: 'leaseRenewalsNumber'},
                            {path: 'main', tKey: 'surface(SqM)'},
                            {path: 'facade', tKey: 'facade(SqM)'},
                            {path: 'annex', tKey: 'annex(SqM)'},
                            {path: 'startDate', tKey: 'openingDate'}
                        ],
                        form: [
                            {
                                path: 'leaseElement', required: true,
                                editable: false, tKey: 'scenario',
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store }) => {
                                        if (newValue && newValue.id) {
                                            const leaseConfigurationsField = module.viewMap.form.fields.find(
                                                field => field.path === 'leaseConfigurations'
                                            )

                                            const leaseTypeField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseType'
                                            )
                                            const leaseType = leaseTypeField.getValue()

                                            const isTemporaryStore = leaseType && leaseType.id === 'temporaryStore'
                                            const isM2 = newValue && newValue.id === 'm2'

                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_startDate',
                                                isM2 && isTemporaryStore
                                            ))
                                        }
                                    }
                                }
                            },
                            {
                                path: 'leaseType', required: true,
                                editable: false, tKey: 'lease',
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store }) => {
                                        if (newValue && newValue.id) {
                                            const leaseConfigurationsField = module.viewMap.form.fields.find(
                                                field => field.path === 'leaseConfigurations'
                                            )

                                            const leaseElementField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseElement'
                                            )
                                            const leaseDurationField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseDuration'
                                            )
                                            const leaseRenewalsNumberField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseRenewalsNumber'
                                            )

                                            const isTemporaryStore = newValue && newValue.id === 'temporaryStore'

                                            const isMain = newValue && newValue.id === 'principal'

                                            const isLeaseImpact = newValue && newValue.id === 'leaseImpact'

                                            if (isTemporaryStore || isLeaseImpact) {
                                                leaseDurationField.setValue({id: 'lt10Y'})
                                                leaseRenewalsNumberField.setValue({id: 0})
                                            }

                                            const leaseDuration = leaseDurationField.getValue()

                                            const leaseElement = leaseElementField.getValue()

                                            const isM2 = leaseElement && leaseElement.id === 'm2'

                                            const isGtThanOrEq10Y = leaseDuration && leaseDuration.id === 'gtOrEq10Y'


                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_leaseDuration',
                                                !isTemporaryStore && !isLeaseImpact
                                            ))
                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_leaseRenewalsNumber',
                                                !isTemporaryStore && !isLeaseImpact && !isGtThanOrEq10Y
                                            ))
                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_main',
                                                !isLeaseImpact
                                            ))
                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_facade',
                                                isMain
                                            ))
                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_annex',
                                                isMain
                                            ))
                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_startDate',
                                                isM2 && isTemporaryStore
                                            ))
                                        }
                                    }
                                }
                            },
                            {path: 'leaseDuration', required: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store }) => {
                                        if (newValue && newValue.id) {
                                            const leaseConfigurationsField = module.viewMap.form.fields.find(
                                                field => field.path === 'leaseConfigurations'
                                            )

                                            const leasTypeField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseType'
                                            )
                                            const leasType = leasTypeField && leasTypeField.getValue()

                                            const isTemporaryStore = leasType && leasType.id === 'temporaryStore'

                                            const isLThan10Y = newValue && newValue.id === 'lt10Y'

                                            const leaseRenewalsNumberField = leaseConfigurationsField.viewMap.form.fields.find(
                                                field => field.path === 'leaseRenewalsNumber'
                                            )
                                            if (!isLThan10Y) {
                                                leaseRenewalsNumberField.setValue({id: 0})
                                            }

                                            store.dispatch(setFieldVisibility(
                                                'e_leaseConfigurations.e_leaseRenewalsNumber',
                                                !isTemporaryStore && isLThan10Y
                                            ))
                                        }
                                    }
                                }
                            },
                            {path: 'leaseRenewalsNumber', fieldPath: ['id', 'name', 'value'], required: true},
                            {path: 'main', tKey: 'surface(SqM)', required: true, wholePositiveNumber: true, default: 0},
                            {path: 'facade', tKey: 'facade(SqM)', required: true, wholePositiveNumber: true, default: 0},
                            {path: 'annex', tKey: 'annex(SqM)', required: true, wholePositiveNumber: true, default: 0},
                            {path: 'startDate', tKey: 'openingDate', required: true}
                        ]
                    }
                },
                {path: 'leaseDatas', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const objectMode = state.ui.objectMode

                if(objectMode === 'newObject') {
                    store.dispatch(setFieldVisibility(`e_projectName`, false))
                }
            }

        }

    },
    filters: [
        {
            title: 'Subsidiary',
            path: 'subsidiary',
            object: 'Subsidiary',
            display: 'fullName',
            placeholder: 'selectASubsidiary',
            client: true,
            filters: ['inUserPerimeter'],
            width: 12,
            async: true,
            query: function(context, callback) {
                const subsidiaryId = _.get(context.data, 'subsidiary.id')

                if (subsidiaryId) {
                    callback(null, {
                        subsidiary: new global.ObjectID(subsidiaryId)
                    })
                } else {
                    global.app.B.SubsidiaryAssignment.collection
                        .find({
                            user: new global.ObjectID(context.user.id),
                            group: new global.ObjectID(context.group.id)
                        })
                        .toArray((error, assignments) => {
                            const ids = _.flatMap(
                                assignments,
                                assignement => assignement.subsidiaries
                            )

                            callback(error, {
                                subsidiary: { $in: ids }
                            })
                        })
                }
            }
        }
    ]
}
