import React from 'react'
import SpinningDots from '../../components/Loader/SpinningDots'
import CircularProgressbar from 'react-circular-progressbar';
import './RadialProgress.css'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import * as d3 from 'd3'
import {ChevronRight} from 'react-feather'

class RadialProgressComponent extends React.Component {

    componentDidMount() {
        const { dataFetcher } = this.props
        const text = d3.select('.CircularProgressbar').select('text')
        text
            .attr('x', '25')
            .attr('y', '58')

        dataFetcher()
    }

    componentDidUpdate(prevProps) {
        const { dataFetcher, path } = this.props

        const text = d3.select('.CircularProgressbar').select('text')
        text
            .attr('x', '25')
            .attr('y', '58')

        if ( path !== prevProps.path ) {
            dataFetcher()
        }
    }

    render() {
        const {title, data, isFetching, link={} } = this.props

        return (
            <div className="RadialProgress-container col-sm-6 col-md-4 col-lg-4">
                {
                    isFetching ? (
                        <div className="RadialProgress">
                            <SpinningDots/>
                        </div>
                            ) : (
                        <div className="RadialProgress">
                            <h4>{title}</h4>
                            <div className="RadialProgress-content">
                                <AutoSizer>
                                    {({ height, width }) => {

                                        return (
                                            <CircularProgressbar
                                                percentage={data}
                                                text={`${data}%`}
                                                // Path width must be customized with strokeWidth,
                                                // since it informs dimension calculations.
                                                strokeWidth={7}
                                                // You can override styles either by specifying this "styles" prop,
                                                // or by overriding the default CSS here:
                                                // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                                                styles={{
                                                    // Customize the root svg element
                                                    root: {
                                                        height: height,
                                                        width: width
                                                    },
                                                    // Customize the path, i.e. the part that's "complete"
                                                    path: {
                                                        // Tweak path color:
                                                        stroke: '#f88',
                                                        // Tweak path to use flat or rounded ends:
                                                        strokeLinecap: 'butt',
                                                        // Tweak transition animation:
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                    },
                                                    // Customize the circle behind the path
                                                    trail: {
                                                        // Tweak the trail color:
                                                        stroke: '#d6d6d6',
                                                    },
                                                    // Customize the text
                                                    text: {
                                                        // Tweak text color:
                                                        fill: '#f88',
                                                        // Tweak text size:
                                                        fontSize: '30px',
                                                    },
                                                }}
                                            />
                                        )
                                    }}
                                </AutoSizer>
                            </div>
                            <div className="RadialProgress-link">
                                {link.title && <a href={link.path}> {link.title} <ChevronRight className="ShortCard-icon"/> </a> }
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}
export default RadialProgressComponent;
