export const job = {
    name: 'initHypothesisModelLineStatus',
    title: 'Init Hypothesis Model Line Status',
    //cron: "5 1 * * *",
    single: true,
    execute: function(context, callback) {
        console.log('Init Hypothesis Model Line Status')

        global.app.B.HypothesisModelLine.collection.updateMany(
            {},
            { $set: { active: true } }
        )
        callback(null, { message: 'Job ended !' })
    }
}
