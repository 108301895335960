import React, {Component} from 'react'
import {Field} from 'redux-form'
import {getModuleIsFetching} from '../../selectors'
import {connect} from 'react-redux'
import './FilterNumberField.css'
import {StyledInput} from "../../../../components/Form/StyledInput";

let typingTimer
const doneTypingInterval = 700

class Input extends Component {
    constructor(props) {
        super(props)
        const { input: { value } } = this.props
        this.state = {value}
    }

    handleOnChange = event => {
        this.setState({
            value: event.target.value
        })
    }


    render() {
        const {input: {onChange}, type, isFetching, placeholder, path, t} = this.props

        const translatedPlaceholder = t(
            placeholder || 'search',
            { capitalize: true }
        ) + '...'

        return (
            <div className='FilterNumberField-wrapper'>
                <StyledInput
                    path={path}
                    placeholder={placeholder}
                    value={this.state.value}
                    type={type}
                    disabled={isFetching}
                    onChange={this.handleOnChange}
                    onKeyUp={ () => {
                        clearTimeout(typingTimer)
                        typingTimer = setTimeout(() => onChange(this.state.value), doneTypingInterval);
                    }}
                    t={t}
                />
            </div>
        )
    }
}

const FilterField = ({ filter, isFetching, onChange, fetchFilterData, t }) => (
    <Field
        name={filter.path}
        path={filter.tKey || filter.path}
        placeholder={filter.placeholder}
        type={filter.type}
        component={Input}
        isFetching={isFetching}
        onChange={(event, value) => {
            // we need to call fetchDtData after reduxFormChange
            event.preventDefault()
            onChange(filter.path, value)
            fetchFilterData()
        }}
        t={t}
    />
)

const mapStateToProps = (state) => ({
    isFetching: getModuleIsFetching(state)
})

export default connect(mapStateToProps)(FilterField)
