import React from 'react'
import { Field } from 'redux-form'
import RadioButtonField from '../../../../components/Form/RadioButtonField'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input: {value, onChange}, language, label, required, subtitle, options, editable, disabled}) =>  {
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)
    const hasDetailedResponse = options.some(option => {
        return option.hasDetailedResponse && (option.id === value.id)
    })
    const detailedOption = options.find(option => {
        return option.hasDetailedResponse && (option.id === value.id)
    })
    return <RadioButtonField
        options={options}
        valueId={value.id}
        detailedResponse={value.detailedResponse}
        hasDetailedResponse={hasDetailedResponse}
        detailedResponsePlaceholder={detailedOption && detailedOption.detailedResponsePlaceholder}
        label={translatedLabel}
        required={required}
        subtitle={translatedSubtitle}
        onChange={id => onChange({id, detailedResponse: ""})}
        onResponseDetailsChange={event => onChange({id: value.id, detailedResponse: event.target.value})}
        disabled={disabled || !editable}
        language={language}
    />
}

const FormRadioButtonField = ({ field, language}) => {

    return (
        <Field
            name={field.path}
            label={field.label}
            required={field.required}
            subtitle={field.subtitle}
            language={language}
            options={field.options}
            component={Input}
            editable={field.editable}
            disabled={field.disabled}
        />
    )
}

export default FormRadioButtonField
