import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import {SketchPicker} from "react-color"

class ColorPicker extends Component {
    // Required by onClickOutSide
    handleClickOutside = (...args) => {
        this.props.opened &&
        this.props.onClickOutside &&
        this.props.onClickOutside(args)
    }

    render() {
        return this.props.opened && (
            <SketchPicker
                disableAlpha
                color={this.props.color}
                styles={this.props.style}
                onChangeComplete={ (color) => this.props.handleColorChange(color.hex) }
            />
        )
    }
}

const WrappedColorPicker = onClickOutside(ColorPicker)

WrappedColorPicker.propTypes = {
    opened: PropTypes.bool.isRequired,
    handleClickOutside: PropTypes.func
}

export default WrappedColorPicker
