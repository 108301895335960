const _ = require('lodash')
const Errors = require("../../../utils/Errors").default
const { basicContext } = require('../../../utils/contextUtils')
const {
    submitButton,
    endorseButton,
    returnButton
} = require('../utils/buttons')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')

const groupBy = (array, elements) => {
    return array.reduce(function(acc, o) {
        const group = _.join(elements.map(e => _.get(o, e)), '-')
        return acc[group]
            ? { ...acc, [group]: [...acc[group], o] }
            : { ...acc, [group]: [o] }
    }, {})
}

async function findData(context) {
    const subsidiaryId = _.get(context, 'data.subsidiary.id')
    if (!subsidiaryId) return []

    const projectConfigurations = await global.app.B.ProjectConfiguration.find({
        ...basicContext(context),
        fieldPath: [
            'versionAndOrder',
            'projectName',
            'subsidiary.code',
            'country.code',
            'pole.code',
            'city',
            'localization',
            'status.id'
        ],
        query: {
            subsidiary: new global.ObjectID(subsidiaryId)
        }
    })

    const paths = [
        'subsidiary.code',
        'country.code',
        'pole.code',
        'city',
        'localization'
    ]

    const groups = groupBy(projectConfigurations, paths)

    const targetStatus =
        _.get(context, 'module.name') === 'Submission'
            ? 'submitted'
            : 'endorsed'

    return Object.keys(groups).map(key => {
        const elements = groups[key]
        return {
            id: elements[0].id,
            name: elements[0].projectName,
            version: elements.find(o => _.get(o, 'status.id') === targetStatus)
        }
    })
}

const getButtons = context => {
    const module = _.get(context, 'clientContext.moduleId')
    if (module === 'm-B-submission') {
        return [submitButton, returnButton]
    } else if (module === 'm-B-endorsement') {
        return [endorseButton, returnButton]
    } else {
        return [returnButton]
    }
}

async function getElement(id, context) {
    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        id,
        {
            ...basicContext(context),
            fieldPath: ['projectName', 'versionAndOrder']
        }
    )

    const validatedProjects = await global.app.B.ProjectConfiguration.find({
        ...basicContext(context),
        fieldPath: ['id', 'versionAndOrder'],
        query: {
            status:
                _.get(context, 'module.name') === 'Submission'
                    ? 'submitted'
                    : 'endorsed',
            ..._.pick(projectConfiguration, [
                'city',
                'localization',
                'yearStartBP'
            ]),
            subsidiary: new global.ObjectID(projectConfiguration.subsidiary.id),
            country: new global.ObjectID(projectConfiguration.country.id),
            pole: new global.ObjectID(projectConfiguration.pole.id),
            bPProjectType: projectConfiguration.bPProjectType,
            group: new global.ObjectID(projectConfiguration.group.id)
        }
    })

    return {
        id: projectConfiguration.id,
        name: projectConfiguration.projectName,
        version: validatedProjects.length
            ? _.pick(validatedProjects[0], ['id', 'versionAndOrder'])
            : null,
        buttons: getButtons(context)
    }
}

async function saveElement(object, context) {
    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        object.id,
        {
            ...basicContext(context),
            fieldPath: [
                'subsidiary.id',
                'country.id',
                'pole.id',
                'version',
                'city',
                'localization',
                'group.id'
            ]
        }
    )

    const targetStatus =
        _.get(context, 'module.name') === 'Submission'
            ? 'submitted'
            : 'endorsed'

    if (targetStatus === 'endorsed') {
        const projectConf = await global.db
            .collection('b.projectConfiguration')
            .findOne({
                ..._.pick(projectConfiguration, [
                    'city',
                    'localization',
                    'yearStartBP'
                ]),
                subsidiary: new global.ObjectID(
                    projectConfiguration.subsidiary.id
                ),
                country: new global.ObjectID(
                    projectConfiguration.country.id
                ),
                pole: new global.ObjectID(projectConfiguration.pole.id),
                bPProjectType: projectConfiguration.bPProjectType,
                group: new global.ObjectID(projectConfiguration.group.id),
                status: targetStatus
            })

        if (projectConf) {
            const bpEndorsed = await global.db
                .collection('b.bpEndorsed')
                .findOne({
                    projectConfiguration: projectConf._id
                })
            if (bpEndorsed) {
                const shopBpEndorsed = await global.db
                    .collection('b.shopBpEndorsed')
                    .findOne({
                        bPEndorsed: bpEndorsed._id
                    })
                if (shopBpEndorsed) {
                    throw new Errors.ValidationError(
                        context.tc(
                            'clearShopBpEndorsedBeforeChangingProjectConfVersionStatus'
                        )
                    )
                }
            }

            await global.db.collection('b.bpEndorsed').deleteMany({
                projectConfiguration: projectConf._id
            })
        }
    }

    await global.db.collection('b.projectConfiguration').updateMany(
        {
            ..._.pick(projectConfiguration, [
                'city',
                'localization',
                'yearStartBP'
            ]),
            subsidiary: new global.ObjectID(projectConfiguration.subsidiary.id),
            country: new global.ObjectID(projectConfiguration.country.id),
            pole: new global.ObjectID(projectConfiguration.pole.id),
            bPProjectType: projectConfiguration.bPProjectType,
            group: new global.ObjectID(projectConfiguration.group.id),
            status: targetStatus
        },
        {
            $set: { status: 'ongoing' }
        }
    )

    if (object.version) {
        const affectedProjectConf = await global.app.B.ProjectConfiguration.get(
            _.get(object, 'version.id'),
            {
                ...basicContext(context),
                fieldPath: [
                    'code',
                    'versionAndOrder',
                    'bPProjectType',
                    'yearStartBP',
                    'openingDate'
                ]
            }
        )
        await global.db.collection('b.projectConfiguration').updateOne(
            { _id: new global.ObjectID(_.get(object, 'version.id')) },
            { $set: { status: targetStatus } }
        )

        if (targetStatus === 'endorsed') {
            await global.db.collection('b.bpEndorsed').insertOne({
                code: affectedProjectConf.code,
                yearStartBP: affectedProjectConf.yearStartBP,
                endorsedOpening: affectedProjectConf.openingDate,
                bPProjectType: _.get(affectedProjectConf, 'bPProjectType.id'),
                subsidiary: new global.ObjectID(_.get(affectedProjectConf, 'subsidiary.id')),
                projectConfiguration: new global.ObjectID(_.get(affectedProjectConf, 'id')),
                group: new global.ObjectID(_.get(context, 'group.id'))
            })
        }

        return {
            ...object,
            version: { versionAndOrder: affectedProjectConf.versionAndOrder }
        }
    } else {
        return object
    }
}

export const entity = {
    name: 'SubmissionAndEndorsement',
    fields: [
        'name',
        { path: 'version', type: 'ProjectConfiguration', nullable: true },
        { path: 'buttons' }
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else {
                getElement(id, context)
                    .then(object => callback(null, object))
                    .catch(error => callback(error))
            }
        })
    },
    save: function(object, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else
                saveElement(object, context)
                    .then(object => callback(null, object))
                    .catch(error => callback(error))
        })
    }
}

export const modules = [
    {
        name: 'Submission',
        object: 'SubmissionAndEndorsement',
        tKey: 'mTitle_submission',
        removable: false,
        newable: false,
        category: {
            path: 'bpStore',
            icon: 'briefcase'
        },
        viewMap: {
            dt: [
                'name',
                {
                    path: 'version',
                    display: 'versionAndOrder',
                    tKey: 'submittedVersion'
                }
            ],
            form: {
                fields: [
                    { path: 'name', type: 'readOnly' },
                    {
                        path: 'version',
                        display: 'versionAndOrder',
                        tKey: 'submittedVersion',
                        filters: ['projectConfForSubmittedAndEndorsedModules'],
                        fieldPath: [
                            'id',
                            'versionOrder',
                            'versionOrder.order',
                            'version',
                            'versionAndOrder'
                        ]
                    },
                    { path: 'buttons', hidden: true }
                ],
                onOpen: ({ state, store }) => {
                    const id = state.edit.object.data.id
                    if (id) {
                        store.dispatch(
                            generateFetchFieldListAction(
                                'm-B-submission.SubmissionAndEndorsement_version',
                                store.getState,
                                'form',
                                { data: { projectConfiguration: id } }
                            )
                        )
                    }
                }
            }
        },
        filters: [
            {
                title: 'Subsidiary',
                path: 'subsidiary',
                object: 'Subsidiary',
                display: 'fullName',
                placeholder: 'selectASubsidiary',
                client: true,
                width: 12,
                filters: ['inUserPerimeter'],
                clearable: false
            }
        ]
    },
    {
        name: 'Endorsement',
        object: 'SubmissionAndEndorsement',
        tKey: 'mTitle_endorsement',
        removable: false,
        newable: false,
        category: {
            path: 'bpStore',
            icon: 'briefcase'
        },
        viewMap: {
            dt: [
                'name',
                {
                    path: 'version',
                    display: 'versionAndOrder',
                    tKey: 'endorsedVersion'
                }
            ],
            form: {
                fields: [
                    { path: 'name', type: 'readOnly' },
                    {
                        path: 'version',
                        display: 'versionAndOrder',
                        tKey: 'endorsedVersion',
                        filters: ['projectConfForSubmittedAndEndorsedModules'],
                        fieldPath: [
                            'id',
                            'versionOrder',
                            'versionOrder.order',
                            'version',
                            'versionAndOrder'
                        ]
                    },
                    { path: 'buttons', hidden: true }
                ],
                onOpen: ({ state, store }) => {
                    const id = state.edit.object.data.id
                    if (id) {
                        store.dispatch(
                            generateFetchFieldListAction(
                                'm-B-endorsement.SubmissionAndEndorsement_version',
                                store.getState,
                                'form',
                                { data: { projectConfiguration: id } }
                            )
                        )
                    }
                }
            }
        },
        filters: [
            {
                title: 'Subsidiary',
                path: 'subsidiary',
                object: 'Subsidiary',
                display: 'fullName',
                placeholder: 'selectASubsidiary',
                client: true,
                width: 12,
                filters: ['inUserPerimeter'],
                clearable: false
            }
        ]
    }
]
