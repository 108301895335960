const { bySubFamily, byCompany } = require('./filters')
const { fieldPathJoinGetter } = require("../../utils/kp3Utils");

export const dependencies = [
    {
        name: 'SubFamilyFamilyHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith: ['family', 'subFamily'], index: true},
            'Family',
            {type: 'Company', index: true},
            { path: 'date', type: 'date' },
            'HistoryDataType'
        ],
        filters: [bySubFamily]
    }
]

export const entity = {
    name: 'SubFamily',
    facets: [{name: 'codeName', codeUniqueWith: "company"}],
    fields: [
        'Company',
        {
            type: 'SubFamilyFamilyHistory',
            path: 'families',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        },
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"]
        })
    ],
    filters: [{
        ...byCompany,
        autoFetch: true
    }]
}

export const modules = [
    {
        object: 'SubFamilyFamilyHistory',
        tKey: 'mTitle_subFamily',
        newable: false,
        removable: false,
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'company',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                {path: 'subFamily.code', tKey: 'code', width: 100},
                {path: 'subFamily.name', tKey: 'name'},
                {path: 'date', width: 100},
                {
                    path: 'family',
                    display: 'fullName'
                },
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:['bySubFamily']
    }
]
