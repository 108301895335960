import React from 'react'
import logo from '../img/keenpoint-logo-horizontal.png'
import './login.css'
import {WarningBanner} from "./Banner";


const AuthFailure = ({strategy, t, history}) => {
    return (
        <div className="Login-container">
            <div className="Login-form-container">
                <div className="Login-logo-container">
                    <img
                        alt="Login-logo"
                        src={logo}
                        width={250}
                    />
                </div>
                <div className= "Login-well">
                    <WarningBanner message={t('failedAuthentication', {strategy})}/>
                    <div style={{textAlign: 'center', marginTop: '10px'}}>
                        <button type="button" className="btn remove-button-outline" onClick={() => history.push('/login')} style={{background: 'white', border: '1px solid #cccccc'}} >
                            <span>{t('retry')}</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AuthFailure

