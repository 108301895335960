import React from 'react'
import { Field } from 'redux-form'
import ReactSelect from '../../../../components/Form/Select.example'
import getFormValidations from './formValidations'
import {colourStyles} from '../Filter/SelectStyles'
import './DropDown.css'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const DropDown = ({ input: { value, onChange, onBlur }, meta: { touched, error }, selectOptions, subtitle, optionsById, disabled, editable, label, required, placeholder, t, language }) => {
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)
    const translatedError = t(error)
    return (
        <ReactSelect
            className="Form-DropDownField"
            label={translatedLabel}
            subtitle={translatedSubtitle}
            required={required}
            options={selectOptions}
            placeholder={t(placeholder || 'select')}
            multi={false}
            styles={colourStyles}
            onChange={option => {
                onChange(option && option.value)
            }}
            noOptionsMessage={() => t('noOptions')}
            value={value && optionsById[value]}
            isClearable={true}
            isDisabled={disabled || !editable}
            touched={touched}
            error={translatedError}
            onBlur={() => onBlur(value)}
            t={t}
        />
    )
}

const DropdownObject = ({ field, t, language, objectMode }) => {
    const optionsById = field.options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: {
                value: option.id,
                label: option.name
            }
        }},{})

    const selectOptions = field.options.map(option => optionsById[option.id])
    const validations = getFormValidations(field)
    return (
        <Field
            name={field.path}
            label={field.label}
            subtitle={field.subtitle}
            required={field.required}
            component={DropDown}
            selectOptions={selectOptions}
            optionsById={optionsById}
            disabled={field.disabled || !field.writable}
            validate={validations}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            clearable={field.clearable}
            placeholder={field.placeholder}
            t={t}
            language={language}
        />
    )
}


export default DropdownObject
