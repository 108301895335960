const moment = require("moment");
const _ = require("lodash");
const { generateReportTreatedAlert } = require("./utils/reportTreatedAlert");
const { generateThemeExport } = require("./utils/themeExport");
const { setFieldVisibility } = require("../../../apps/KpModule/actions");
const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

export const dependencies = [
    {
        name: "AlertReportType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "alertAnalysis", name: "alertAnalysis"},
            {id: "themeExport", name: "themeExport"}
        ]
    }
]

export const entity = {
    name: "AlertReport",
    facets: [{name: "files", linkType: "OTO"}],
    fields: [
        {type: "AlertReportType", link: "MTO"},
        {type: "ThemeJoin", link: "MTM"},
        {path: "user", type: "User", nullable: true},
        {path: "date", type: "date", dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
        {path: "period", type: "dateRange"}
    ],
    beforeSave: function(object, oldObject, context, callback) {
        object.user = _.pick(context.user, ['id'])
        object.date = new Date()

        callback(null, object, oldObject)
    },
    afterSave: (object, oldObject, context, callback) => {
        const generate = object.alertReportType.id === "alertAnalysis"
            ? generateReportTreatedAlert
            : generateThemeExport

        generate(object, context)
            .then(object => {
                global.ioSocket.emit(
                    `fetchEditObject-success-${context.user.id}${context.clientContext.accessId}`,
                    _.pick(object, ['id', 'date', 'alertReportType', 'period', 'file'])
                )
            })
            .catch(error => {
                global.ioSocket.emit(
                    `fetchEditObject-failure-${context.user.id}${context.clientContext.accessId}`,
                    {error: error.message}
                )
            })
        callback()
    }
}

export const module_ = {
    object: "AlertReport",
    tKey: "mTitle_alertReport",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    updatable: false,
    editable: false,
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    useSocketsOnSave: true,
    viewMap: {
        dt: [
            {path: 'date', tKey: 'dateAndTime'},
            {path: "alertReportType", tKey: "reportType", translate: true},
            "period",
            "file",
        ],
        form: [
            {
                path: "period",
                type: "dateRange",
                mvField: {ranges: "C", default: `${lastWeekISO} - ${todayISO}`},
                editable: false
            },
            {
                path: "alertReportType",
                tKey: "reportType",
                translate: true,
                default: {id: 'alertAnalysis'},
                subscriptions: {
                    onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                        if (!formInitialize || true) {
                            const state = store.getState();
                            const reportType = _.get(state, "form.editObject.values.alertReportType.id");

                            // theme joins is only visible for theme exports
                            store.dispatch(setFieldVisibility("e_themeJoins", reportType === "themeExport"))
                        }
                    }
                },
                editable: false
            },
            {path: "themeJoins", tKey: "indicator_plural", display: "completeName", editable: false}
        ]
    },
    filters: [
        {
            path: "filterUser",
            object: "User",
            client: false,
            display: "name",
            query: function (context) {
                const userId = _.get(context, "user.id");
                if (userId) return {
                    "user": global.ObjectID(userId)
                };
            }
        },
        {
            path: "period",
            type: "dateRange",
            client: true,
            default: [lastWeekISO, todayISO],
            query: function (context) {
                const period = _.get(context, 'data.period', [lastWeekISO, todayISO]).map(
                    date => moment.utc(date.trim(), "YYYY-MM-DD")
                )
                return { $and: [
                    {date: { $gte: period[0].toDate() }},
                    {date: { $lt: period[1].add(1, 'days').toDate() }}
                ]}
            }
        }
    ]
}
