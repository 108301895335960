export const entities = [
    {
        name: 'Contacts',
        fields: [
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'mail', encrypted: true, unique: true},
            {type: 'Function'},
            {path: 'phone', encrypted: true},
            {path: 'images', type: 'Image', link: {type: 'OTM', deleteType: 'cascade'}},
            {path: 'comment'},
        ]
    },
    {
        name: 'Image',
        fields: [
            {path: 'name', notEmpty: true},
            'User',
            {path: 'date', notEmpty: true}
        ]
    }
]

export const administratorModule = {
    object: 'Contacts',
    name: 'administratorContactsModule',
    tKey: 'mTitle_contacts',
    defaultSortBy: 'lastname',
    objectIdentifier: 'lastname',
    protectedExport: true,
    defaultSortDirection: 'ASC',
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'lastname'},
            {path: 'firstname',},
            {path: 'function'},
            {path: 'phone'},
            {path: 'mail'}
        ],
        form: [
            {path: 'lastname', required: true},
            {path: 'firstname', required: true},
            {path: 'function', required: true},
            {path: 'phone', required: true},
            {path: 'mail', required: true, emailAddress: true},
            {path: 'comment', type: 'richTextEditor'},
            {path: 'images', tKey: 'photo', placeholder: 'addPhoto', type: 'image', fieldPath: ['id', 'name', 'user.id', 'user.name', 'date'], required: true, singleImage: true}
        ]
    }
}

export const userModule = {
    object: 'Contacts',
    name: 'userContactsModule',
    tKey: 'mTitle_contacts',
    defaultSortBy: 'lastname',
    protectedExport: true,
    displayLog: false,
    defaultSortDirection: 'ASC',
    newable: false,
    removable: false,
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'lastname'},
            {path: 'firstname'},
            {path: 'function'},
            {path: 'phone'},
            {path: 'mail'},
        ],
        form: [
            {tKey: 'photo', path: 'images', type: 'image', fieldPath: ['id', 'name', 'user.id', 'user.name', 'date'], editable: false, width: 200, hideLabel: true, noThumbnail: true},
            {path: 'lastname', editable: false, disabled: true},
            {path: 'firstname', editable: false},
            {path: 'function', editable: false},
            {path: 'phone', editable: false},
            {path: 'mail', editable: false}
        ]
    }
}
