import async from 'async'
import moment from 'moment'
import Errors from '../../../utils/Errors'
import {generatePresentationTable} from './presentationDocument'
import {prepareListButton} from "./utils";
import {returnButton} from "../utils";

export const entities = [
    {
        name: 'LiberalityPresentation',
        facets: [
            {name: 'files', linkType: 'OTO', path: 'table'},
        ],
        fields: [
            {type: 'LiberalityFile', link: "MTM"},
            {
                path: 'filesNumber',
                fieldPath: ['liberalityFiles.id'],
                f: function() {
                    return this.liberalityFiles?.length
                }
            },
            {path: 'presentationDate'},
            {path: 'updatedAt', type: 'date', nullable: true},
            {path: 'updatedBy', type: 'User', nullable: true},
            {
                path: "buttons",
                f: function () {
                    return [prepareListButton, returnButton]
                }
            }
        ],
        emptyFilesField: (object, callback) => {
            if(!!object.liberalityFiles.length) return callback()

            return callback(new Errors.ValidationError("Une présentation doit contenir au moins un dossier"))
        },
        updateExistingPresentation: async (object, oldObject, callback) => {
            let addedFiles = oldObject
                ? object.liberalityFiles.filter(newFile => !object.liberalityFiles.some(oldFile => oldFile.id === newFile.id ))
                : object.liberalityFiles

            if(!addedFiles.length) return callback()
            const currentDate = moment().format('YYYY-MM-DD')
            const pendingPresentation = await global.app.C.LiberalityPresentation.collection.findOne({
                _id: {$ne: global.ObjectID(object.id)},
                presentationDate: {$gt: currentDate}
            })

            if(pendingPresentation) return callback(new Errors.ValidationError('Veuillez mettre à jour la liste existante de la prochaine présentation'))
            return callback()
        },
        cannotRemoveProcessedFiles: async (object, oldObject, callback) => {
            if(!oldObject) return callback()
            const removedFiles = oldObject.liberalityFiles
                .filter(file => ['validated', 'refused', 'incomplete'].includes(file.status.id))
                .filter(oldFile => !object.liberalityFiles.some(newFile => newFile.id === oldFile.id ))

            if(!removedFiles?.length) return callback()

            return callback(new Errors.ValidationError("Vous ne pouvez pas retirer les dossier validés ou refusés"))

        },
        validateSave: async function(object, oldObject, context, callback){
            async.series([
                callback => this.emptyFilesField(object, callback),
                callback => this.cannotRemoveProcessedFiles(object, oldObject, callback),
                callback => this.updateExistingPresentation(object, oldObject, callback),
            ], callback)
        },
        beforeSave: async function(object, oldObject, context, callback){
            try {
                object.updatedAt = new Date()
                object.updatedBy = context.user
                object.table = await generatePresentationTable(object, context)
                await this.updateFilesStatus(object, oldObject, context)
                return callback(null, object, oldObject)
            } catch (e) {
                return callback(e)
            }

        },
        validateDelete: async function(object, context, callback){
            const files = await global.app.C.LiberalityFile.collection.find({ _id: { $in: object.liberalityFiles.map(id => global.ObjectID(id))}}).toArray()
            const hasProcessedFile = files.some(file => ['validated', 'refused'].includes(file.status))

            if(hasProcessedFile) return callback(new Errors.ValidationError("Vous ne pouvez pas supprimé une présentation qui contient des dossiers traités"))

            return callback(null, object)
        },
        afterDelete: async function(object, context, callback){
            const files = await global.app.C.LiberalityFile.collection.find({ _id: { $in: object.liberalityFiles.map(id => global.ObjectID(id))}}).toArray()
            const awaitDecisionFiles = files.filter(file => file.status === 'awaitingDecision')

            if(!awaitDecisionFiles.length) return callback()

            global.app.C.LiberalityFile.collection.updateMany(
                { _id: { $in: awaitDecisionFiles.map(file => file._id) } },
                {$set: {status: 'readyToBePresented'}},
                callback
            )
        },
        updateFilesStatus: async (object, oldObject) => {
            await async.parallel(
                [
                    callback => global.app.C.LiberalityFile.collection.updateMany(
                        {
                            _id: {
                                $in: object.liberalityFiles
                                    .filter(file => ['readyToBePresented', 'updated'].includes(file.status.id))
                                    .map(file => global.ObjectID(file.id))
                            }
                        },
                        {$set: {status: 'awaitingDecision'}},
                        callback
                    ),
                    callback => {
                        if(!oldObject) return callback()
                        const removedFiles = oldObject.liberalityFiles
                            .filter(file => file.status.id === 'awaitingDecision')
                            .filter(oldFile => !object.liberalityFiles.some(newFile => newFile.id === oldFile.id ))
                        if(removedFiles.length === 0) return callback()
                        global.app.C.LiberalityFile.collection.updateMany(
                            { _id: { $in: removedFiles.map(file => global.ObjectID(file.id)) } },
                            {$set: {status: 'readyToBePresented'}},
                            callback
                        )
                    }
                ]
            )
        }
    }
]
