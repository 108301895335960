const _ = require('lodash');
const moment = require('moment')
const { directoryPath } = require('../templates/index')

export const crrPdfConfig = function(
    businessProject,
    refBusiness,
    outputName,
    context
) {
    const bpCode = refBusiness.code ? refBusiness.code : " ";
    const bpName = refBusiness.name ? refBusiness.name : " ";

    const bpCountryNameAndSecurity = (businessProject.country && businessProject.country.nameAndSecurity)  ? businessProject.country.nameAndSecurity : " ";
    const revueDate = moment(businessProject.revueDate).format("DD/MM/YYYY")

    const header = "<header style=\"font-size: 8px; margin-left: 10px; font-family: Verdana, Arial, sans-serif\"><p>" +
        "<strong>" + bpCountryNameAndSecurity + "</strong><br>" +
        bpName + " / " + bpCode + "<br>" +
        "<small> Revue le " + revueDate + "</small><br>" +
        "</p></header>"


    const footer = "<footer style=\"font-size: 8px; margin-left: 10px; font-family: Verdana, Arial, sans-serif\"><div class=\"row\">" +
        "<div class=\"col-md-8\" style=\"float: left\">EDF IN - Compte rendu réalisation</div>" +
        "</div></footer>"


    return {
        content: [
            { path: 'CRRTemplate.html' }
        ],
        context: {
            bpObject: businessProject,
            years: _.range(businessProject.beginYear.id, businessProject.beginYear.id +10)

        },
        configuration: {
            landscape: true,
            header: {
                height: '3cm',
                html: header.replace(new RegExp("'", 'g'), "")
            },
            footer: {
                height: '1cm',
                html: footer.replace(new RegExp("'", 'g'), "")
            },
            pdfName: outputName.replace(new RegExp("'", 'g'), ""),
            outputInDB: true,
            cssSheet: '',
            templateDir: directoryPath.pdfs
        }
    }
}
