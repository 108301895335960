const _ = require('lodash')
const { generateFetchFieldListAction } = require('../../../apps/KpModule/actions/api')
const { setFieldVisibility, setDataList, setFormButtons, setFieldEdition} = require("../../../apps/KpModule/actions")
const { saveBeforePayment, saveBeforeRefund, requestValidation, returnButton } = require("./utils")

export const module_ = {
    object: 'Reallocation',
    name: 'ReallocationRequest',
    tKey: 'mTitle_reallocationRequest',
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    objectIdentifier: 'reference',
    removable: true,
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'reference', tKey: 'reallocation'},
            {path: 'benefit'},
            {path: 'exercise', initiallyNotVisible: true},
            {path: 'requests', display: 'sequence'},
            {path: 'reallocationAmount'},
            {path: 'amountToRepay'},
            {path: 'ongoingPayment', initiallyNotVisible: true},
            {path: 'refundSent', width: 150},
            {path: 'status', translate: true},
            {path: 'date', tKey: 'dateAndTime'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields: [
                {path: 'reference', tKey: 'reallocation', disabled: true},
                {
                    path: 'benefit',
                    filters: ['reallocationBenefits'],
                    fieldPath: ['id', 'code', 'name'],
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            const state = store.getState()
                            const objectMode = state.ui.objectMode

                            const field = module.viewMap.form.fields.find(
                                field => field.path === 'requests'
                            )

                            if (objectMode === 'newObject') {
                                if (newValue && newValue.id) {

                                    const data = {benefit: newValue}

                                    store.dispatch(
                                        generateFetchFieldListAction(
                                            "m-R-reallocationRequest.Reallocation_requests",
                                            store.getState,
                                            'form',
                                            {data}
                                        )
                                    ).then(() => {
                                        const newState = store.getState()
                                        const list = newState.dataLists.byId['m-R-reallocationRequest.Reallocation_requests'].list
                                        field.setValue(list.allIds.map(id => list.byId[id]))
                                    })
                                } else field.setValue([])
                            }
                        }
                    }

                },
                {
                    path: 'requests',
                    display: 'referenceWithStatus',
                    fieldPath: ['id', 'status', 'ageLimit', 'refund', 'referenceWithStatus', 'benefit.id', 'benefit.requestGroup.id', 'benefit.exercise.id',
                        'benefit.accountNumber', 'benefit.analyticalCode.code', 'benefit.limit.id', 'user.id', 'user.accountNumber'],
                    filters: ['byUser', 'notDraftReallocatedNorRefused', 'byBenefit'],
                    disabled: true,
                    required: true,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            const state = store.getState()
                            const objectMode = state.ui.objectMode

                            const paidRefundField = module.viewMap.form.fields.find(
                                field => field.path === 'amountToRepay'
                            )

                            const refundField = module.viewMap.form.fields.find(
                                field => field.path === 'reallocationAmount'
                            )

                            const ongoingPaymentField = module.viewMap.form.fields.find(
                                field => field.path === 'ongoingPayment'
                            )

                            if(objectMode === 'newObject') {
                                if(newValue) {
                                    const requestPaid = newValue.some(request => request.status === 'paid')
                                    const validatedRequests = newValue.some(request => ['waiting', 'actualized', 'validated'].includes(request.status))
                                    store.dispatch(setFieldVisibility('e_ribCSE', requestPaid))
                                    const buttons = requestPaid || validatedRequests
                                        ? validatedRequests
                                            ? [saveBeforeRefund, returnButton]
                                            : [saveBeforePayment, returnButton]
                                        : [requestValidation, returnButton]
                                    store.dispatch(setFormButtons(buttons))

                                    const totalRefund = newValue.reduce((acc, request) => {
                                        const refund = parseFloat(request.refund)
                                        return isNaN(refund)
                                            ? acc
                                            : acc + refund
                                    }, 0)
                                    const totalPaid = newValue.filter(request => ['waiting', 'actualized', 'validated', 'paid'].includes(request.status)).reduce((acc, request) => {
                                        const refund = parseFloat(request.refund)
                                        return isNaN(refund)
                                            ? acc
                                            : acc + refund
                                    }, 0)
                                    const ongoingPayment = newValue
                                        .filter(request => ['validated', 'waiting', 'actualized'].includes(request.status))
                                        .reduce((acc, request) => acc + parseFloat(request.refund), 0)

                                    refundField.setValue(_.round(totalRefund, 2).toFixed(2))
                                    paidRefundField.setValue(_.round(totalPaid, 2).toFixed(2))
                                    ongoingPaymentField.setValue(_.round(ongoingPayment, 2).toFixed(2))
                                }else{
                                    refundField.setValue('0.00')
                                    paidRefundField.setValue('0.00')
                                    ongoingPaymentField.setValue('0.00')

                                    store.dispatch(setFieldVisibility('e_refundSent', false))
                                    store.dispatch(setFieldVisibility('e_ribCSE', false))
                                    store.dispatch(setFormButtons([returnButton]))
                                }
                            } else {
                                const requestPaid = newValue && newValue.some(request => request.status === 'paid')
                                store.dispatch(setFieldVisibility('e_refundSent', requestPaid))
                                store.dispatch(setFieldVisibility('e_ribCSE', requestPaid))
                            }
                        }
                    }
                },
                {path: 'amountToRepay', disabled: true},
                {path: 'ongoingPayment', disabled: true},
                {path: 'refundSent', default: false},
                {path: 'ribCSE', type: 'textarea', tKey: 'RIB CSE', default: 'BIC : BREDFRPPXXX\nIBAN : FR76 1010 7002 0300 9260 3522 935', disabled: true},
                {path: 'reallocationAmount', hidden: true},
                {path: 'comments'},
                {path: 'files'},
                {path: 'beneficiary', hidden: true},
                {path: 'status', hidden: true}
            ],
            onOpen: ({state, store}) => {

                const objectMode = state.ui.objectMode

                if(objectMode === 'newObject') {
                    store.dispatch(setFieldVisibility('e_reference', false))
                    store.dispatch(setFieldVisibility('e_refundSent', false))
                    store.dispatch(setFieldEdition('e_ribCSE', false))
                    store.dispatch(setFormButtons([returnButton]))
                }

                if(objectMode !== 'newObject') {
                    const status = state.edit.object.data.status
                    const buttons = ['ongoing', 'validated', 'refused', 'waitingRefund'].includes(status)
                        ? [returnButton]
                        : [requestValidation, returnButton]

                    store.dispatch(setFormButtons(buttons))

                    const editable = ['waitingPayment', 'rectification'].includes(state.edit.object.data.status)
                    const fields = ['refundSent', 'comments', 'files']
                    fields.forEach(field => store.dispatch(setFieldEdition(`e_${field}`, editable)))

                    const benefit = state.edit.object.data.benefit
                    const requests = state.edit.object.data.requests

                    if(benefit) {
                        store.dispatch(
                            setDataList(
                                "m-R-reallocationRequest.Reallocation_benefit",
                                [state.edit.object.data.benefit]
                            )
                        )
                    }
                    if(requests) {
                        store.dispatch(
                            setDataList(
                                "m-R-reallocationRequest.Reallocation_requests",
                                state.edit.object.data.requests
                            )
                        )
                    }
                }

            }
        }
    },
    filters: ['byUser']
}
