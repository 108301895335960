import React from 'react'
import _ from 'lodash'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import './ChartContainer.css'
import SpinningDots from '../../components/Loader/SpinningDots'
import {translateName} from "../../utils/i18n";

const ChartContainer = ({width, defaultTitle, total, title, Component, data, subTitle, dataKeys, isFetching, keys, colors, className, sum, sumColor, absolute, useColorsByCashier, useColorsByStore, axisHeight, suffix, t, language}) => {
    const actualTotal = sum ? (
        <div className="ChartCard-total" style={{color: sumColor || '#E94E24'}}>
            {total || (data && Intl.NumberFormat(language).format(_.round(data.reduce((acc, o) => {
                return absolute
                    ? acc + Math.abs(o[keys[1]])
                    : acc + o[keys[1]]
            }, 0)))) }{suffix}
        </div>
    ) : null

    const getContainerClass = () => {
        switch (width) {
            case 1:
                return "col-sm-12 col-md-12 col-lg-12"
            case 1/2:
                return "col-sm-6 col-md-6 col-lg-6"
            case 2/3:
                return "col-sm-8 col-md-8 col-lg-8"
            default:
                return "col-sm-6 col-md-4 col-lg-4"
        }
    }
    console.log("title", title)
    console.log("defaultTitle", defaultTitle)

    return (
        <div className={`ChartContainer ${getContainerClass()}`}>
            <div className="ChartCard">
                <div>
                    <h4>{ title ?  t(title.text, title.data) : translateName(defaultTitle, language)}</h4>
                    {
                        subTitle && <div className="ChartCard-subTitle" style={{color: `${subTitle.color}`, font: `${subTitle.font}`}}>{t(subTitle.text, subTitle.data)}</div>
                    }
                    {actualTotal}
                </div>
                <div className="ChartCard-content">
                    {
                        isFetching
                            ? <SpinningDots/>
                            : (
                                <AutoSizer>
                                    { ({ height, width }) => <Component
                                        h={height}
                                        w={width}
                                        data={data}
                                        axisHeight={axisHeight}
                                        dataKeys={dataKeys}
                                        defaultKeys={keys}
                                        colors={colors}
                                        className={className}
                                        t={t}
                                        language={language}
                                        absolute={absolute}
                                        useColorsByCashier={useColorsByCashier}
                                        useColorsByShop={useColorsByStore}
                                    /> }
                                </AutoSizer>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default ChartContainer
