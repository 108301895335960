const moment = require('moment')

export const entity = {
    name: 'AccountingEntries',
    fields: [
        'type',
        {path: 'date', type: 'date'},
        'journal',
        'generalAccount',
        'auxiliaryAccount',
        'direction',
        'amount',
        'wording',
        'reference',
        'analyticSection',
        {path: 'issued', type: 'date'},
        {path: 'generated', type: 'date'},
        {path: 'transmissionDate', type: 'date'},
        'status',
        'fileName',
        'recordType'
    ],
    filters: [
        {
            name: "byDate",
            path: "date",
            type: "dateRange",
            client: true,
            default: [moment().startOf('year').format('YYYY-MM-DD'), moment().format("YYYY-MM-DD")],
            clearable: true,
            isDefault: false,
            query: function(context) {
                const date = context.data && context.data.date
                if(date)
                    return { date: { $gte : date[0], $lte: date[1] } }
                return {}
            }
        }
    ]
}

export const module_ = {
    object: 'AccountingEntries',
    tKey: 'mTitle_accountingEntries',
    removable: false,
    protectedExport: true,
    displayLog: false,
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    category: {
        path: 'accounting',
        icon: 'book'
    },
    viewMap: {
        dt: [
            {path: 'recordType', width: 300,  tKey: 'process', translate: true},
            {path: 'type'},
            {path: 'date', tKey: 'pieceDate', width: 300},
            {path: 'journal'},
            {path: 'generalAccount', width: 300},
            {path: 'auxiliaryAccount', width: 300},
            {path: 'direction'},
            {path: 'amount'},
            {path: 'wording', width: 400},
            {path: 'reference'},
            {path: 'analyticSection'},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'generated', initiallyNotVisible: true},
            {path: 'status', translate: true, initiallyNotVisible: true},
            {path: 'transmissionDate', initiallyNotVisible: true},
            {path: 'fileName', initiallyNotVisible: true}
        ]
    },
    filters: ['byDate']
}
