const _ = require("lodash");
const moment = require("moment");
const { translateName } = require("../../../utils/i18n");
const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

const getAndTranslateForContext = context => (object, path) => translateName(
    _.get(object, path),
    _.get(context, "language.id")
)

function getElementsName(object, context) {
    const getAndTranslate = getAndTranslateForContext(context);
    return object.scoringConfig.scoredElements.map(scoredElement => {
        switch (scoredElement.joinedEntity) {
            case "StoreAxis":
                return `${_.get(object, "store.code")} - ${getAndTranslate(object, "store.name")}`;
            case "FamilyAxis":
                return getAndTranslate(object, "family.name");
            case "OrganizationAxis":
                return getAndTranslate(object, "organization.name");
            case "ProductAxis":
                return `${_.get(object, "product.code")} - ${_.get(object, "product.name")}`;
            case "SubStoreAxis":
                return `${object[scoredElement.code]}`;
            default:
                return "";
        }
    }).join(' | ')

}

function getComparisonName(object, context) {
    const getAndTranslate = getAndTranslateForContext(context)

    const joinedEntity = _.get(object, "scoringConfig.comparisonAxis.joinedEntity");

    switch (joinedEntity) {
        case "StoreAxis":
            return `${_.get(object, "store.code")} - ${getAndTranslate(object, "store.name")}`;
        case "FamilyAxis":
            return getAndTranslate(object, "family.name");
        case "OrganizationAxis":
            return getAndTranslate(object, "organization.name");
        default:
            return "";
    }
}

// build a flat array of { habilitation, habFunction } linked to user
// const getUserAffectations = (habFunctions, userId) => _(habFunctions)
//     .flatMap(
//         habFunction => habFunction.habilitations.map(
//             habilitation => ({habFunction, habilitation})
//         )
//     )
//     .filter(
//         ({habilitation}) => _.some(habilitation.users, user => user.id === userId)
//     )
//     .value();

// extract habilitations for a given axis
// const axisHabilitationGetter = affectations => axisName => _(affectations)
//     .filter(
//         ({habFunction}) => habFunction.organizationAxisJoin.joinedEntity === axisName
//     )
//     .map("habilitation")
//     .value();

export const entity = {
    name: "ScoreLine",
    fields: [
        "habilitation",
        "element",
        "comparison",
        "score",
        "Scoring",
        {
            type: "ScoringConfig",
            link: {oppositeField: {link: {deleteType: "cascade"}}}
        },
        {type: "Store", nullable: true},
        {path: "storeOrganizations", type: "Organization", nullable: true, link: "MTM"},

        {type: "Product", nullable: true},
        {path: "productFamilys", type: "Family", nullable: true, link: "MTM", index: true},

        {type: "Organization", nullable: true, link: "MTO"},
        {type: "Family", nullable: true, link: "MTO"},
        {
            path: "elementValue",
            fieldPath:["scoringConfig.scoringFields.denominatorTheme.code"],
            f: function() {
                return this.fractionForFields.length === 1
                    ? (
                        _.get(this, 'scoringConfig.scoringFields.0.denominatorTheme')
                            ? _.round(this.fractionForFields[0] * 100, 2)
                            : _.round(this.fractionForFields[0], 2)
                    )
                    : '-'
            }
        },
        {
            path: "groupComparisonMean",
            fieldPath:["scoringConfig.scoringFields.denominatorTheme.code"],
            f: function() {
                return this.scoringFieldsIntermediateCalculations.length === 1
                    ? (
                        _.get(this, 'scoringConfig.scoringFields.0.denominatorTheme')
                            ? _.round((_.get(this, 'scoringFieldsIntermediateCalculations.0.mean') || 0) * 100, 2)
                            : _.round((_.get(this,'scoringFieldsIntermediateCalculations.0.mean') || 0), 2)
                    )
                    : '-'
            }
        },
        {
            path: "scoringName",
            lazy: true,
            fieldPath:["scoringConfig.tName"],
            $f: (object, context, callback) => {
                callback(null, _.get(object, "scoringConfig.tName"))
            }
        },
        {
            path: "scoringPeriodicity",
            lazy: true,
            fieldPath:["scoringConfig.timeWindow.tKey"],
            $f: (object, context, callback) => {
                callback(null, _.get(object, "scoringConfig.timeWindow.tKey"))
            }
        },
        {
            path: "perimeterName",
            lazy: true,
            fieldPath:["scoringConfig.filterFields.completeName"],
            $f: (object, context, callback) => {
                callback(null, _.get(object, "scoringConfig.filterFields.completeName"))
            }
        },
        {
            path: "elementsName",
            lazy: true,
            fieldPath: [
                "scoringConfig.scoredElements.joinedEntity",
                "store.code", "store.name", "organization.name", "product.name", "family.name"
            ],
            $f: (object, context, callback) => {
                callback(null, getElementsName(object, context))
            }
        },

        {
            path: "comparisonName",
            lazy: true,
            fieldPath: [
                "scoringConfig.comparisonAxis.joinedEntity",
                "store.code", "store.name", "organization.name", "family.name"
            ],
            $f: (object, context, callback) => {
                callback(null, getComparisonName(object, context))
            }
        },

        {
            path: "scoreInPercentage",
            f: function() { return Math.round(this.score * 100).toString() + " %" }
        },
        {
            path: "scoreInValue",
            f: function() {
                const result = Math.round(this.score, 1)
                return result !== 0 ? result : "0"
            }
        },
        {
            path: "dataPeriod",
            fieldPath: ["scoring.date"],
            f: function () {
                const dates = this.scoring && this.scoring["date"];
                if (dates) {
                    const bDate = moment.utc(this.scoring["date"].gte).format("YYYY-MM-DD");
                    const eDate = moment.utc(this.scoring["date"].lt).subtract(1, "days").format("YYYY-MM-DD");
                    return `${bDate} -> ${eDate}`;
                }
            }
        },
        {
            path: "dataPeriodColumn",
            fieldPath: ["scoring.date"],
            f: function () {
                const dates = this.scoring && this.scoring["date"];
                if (dates) {
                    const bDate = moment.utc(this.scoring["date"].gte).format("YYYY-MM-DD");
                    const eDate = moment.utc(this.scoring["date"].lt).subtract(1, "days").format("YYYY-MM-DD");
                    return bDate === eDate
                        ? bDate
                        :`${bDate} ${eDate}`
                }
            }
        }
    ],
    indexes: [
        {key: {incomplete: 1, baseDay: 1, scoringConfig: 1}}
    ]
}

export const module_ = {
    object: "ScoreLine",
    tKey: "mTitle_scoreLine",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    removable: false,
    updatable: false,
    defaultSortBy: 'order',
    defaultSortDirection: 'ASC',
    newable: false,
    viewMap:{
        dt: [
            {path: "scoringName", tKey: "scoring", initiallyNotVisible: true},
            {path: "scoringPeriodicity", tKey: "periodicity", initiallyNotVisible: true},
            {path: "order", tKey: "rank", width: 50},
            {path: "perimeterName", tKey: "perimeter", initiallyNotVisible: true},
            {path: "elementName", tKey: "element"},
            {path: "comparisonName", tKey: "comparisonGroup", initiallyNotVisible: true},
            {path: "dataPeriod", tKey: "period"},
            {path: "scoreInValue", tKey: "scoreInValue", width: 50},
        ]
    },
    filters: [
        {
            path: "scoringConfig",
            object: "ScoringConfig",
            display: "tName",
            client: true,
            filters: ["scoringConfigurationsByHabFunctions"],
            clearable: false,
            query: function(context) {
                const scoringConfigId = _.get(context, 'data.scoringConfig.id');

                if(scoringConfigId && scoringConfigId !== "null") return {"scoringConfig": global.ObjectID(scoringConfigId)};
            }
        },
        // {
        //     path: "scoringConfig",
        //     object: "ScoringConfig",
        //     display: "tName",
        //     client: true,
        //     async: true,
        //     query: (context, callback) => {
        //
        //         let query = {}
        //
        //         const scoringConfId = _.get(context, 'data.scoringConfig.id');
        //         if(scoringConfId && scoringConfId !== "null") {
        //             _.assign(query, {"scoringConfig": new global.ObjectID(scoringConfId)})
        //
        //
        //             global.app.S.ScoringConfig.get(
        //                 scoringConfId,
        //                 {
        //                     ...basicContext(context),
        //                     fieldPath: [
        //                         "habFunctions.habilitations.users.id",
        //                         "habFunctions.organizationAxisJoin.id",
        //                         "habFunctions.organizationAxisJoin.joinedEntity"
        //                     ]
        //                 },
        //                 (e, scoringConfig) => {
        //                     if (scoringConfig.habFunctions.length) {
        //
        //                         const getAxisHabilitations = axisHabilitationGetter(
        //                             getUserAffectations(scoringConfig.habFunctions, context.user.id)
        //                         );
        //                         const centralHabilitations = getAxisHabilitations("CentralAxis");
        //
        //                         if (! centralHabilitations.length) {
        //                             const storeHabilitations = getAxisHabilitations("StoreAxis");
        //                             const organizationHabilitations = getAxisHabilitations("OrganizationAxis");
        //
        //                             let orElements = [];
        //                             if (storeHabilitations.length) orElements.push({"store": {$in: storeHabilitations.map( o => new global.ObjectID(o.organizationJoin))}});
        //                             if (organizationHabilitations.length) {
        //                                 orElements.push({"storeOrganizations": {$in: organizationHabilitations.map( o => new global.ObjectID(o.organizationJoin))}});
        //                                 orElements.push({"organization": {$in: organizationHabilitations.map( o => new global.ObjectID(o.organizationJoin))}});
        //                             }
        //
        //                             if (orElements.length) {
        //                                 _.assign(query, {$or: orElements});
        //                             }
        //                         }
        //                     }
        //                     callback(null, query)
        //                 }
        //             )
        //         }
        //         else callback(null, {"_id": {$exists: false}})
        //     }
        // },
        {
            type: "dateRange",
            default: [lastWeekISO, todayISO],
            path: "dates",
            client: true,
            async: true,
            query: (context, callback) => {

                const filterDates = _.get(context, 'data.dates', [lastWeekISO, todayISO])
                    .map(date => moment.utc(date.trim(), "YYYY-MM-DD"));

                const scoringContext = {
                    user: context.user,
                    group: context.group,
                    query: {
                        "date.gte": {$gte: filterDates[0].toDate()},
                        "date.lt": {$lte: filterDates[1].add(1, "days").toDate()}
                    },
                    fieldPath: ["id"]
                }

                global.app.S.Scoring.find(scoringContext, (e, objects) => {
                    if(e) return callback(e)
                    callback(e, {
                        "scoring": {$in: objects.map(o => new global.ObjectID(o.id))}
                    });
                })
            }
        }
    ]
}
