import _ from 'lodash'

const uniqueById = (arr, mapFn) =>
    arr.reduce((acc, o) => {
        const mapped = mapFn(o)
        return Object.assign(acc, { [mapped.id]: mapped })
    }, {})

export default viewer => ({
    result: viewer.user.id,
    client: viewer.client,
    languages: viewer.languages,
    entities: {
        users: {
            [viewer.user.id]: {
                ...viewer.user,
                groupModels: _.map(viewer.groupModels, 'id')
            }
        },
        groupModels: uniqueById(viewer.groupModels, groupModel => ({
            ...groupModel,
            group: groupModel.group.id,
            model: groupModel.model.id,
            languages: groupModel.model.languages,
            profiles: _.map(groupModel.profiles, 'id'),
            modules: _.map(groupModel.modules, 'id'),
            dashboardElements: _.map(groupModel.dashboardElements, 'id'),
            jobs: _.map(groupModel.jobs, 'id'),
        })),
        groups: uniqueById(viewer.groupModels, groupModel => groupModel.group),
        models: uniqueById(viewer.groupModels, groupModel =>
            _.omit(groupModel.model, ['modules', 'jobs'])
        ),
        modules: uniqueById(
            _.flatMap(viewer.groupModels, 'modules'),
            module => module
        ),
        dashboardElements:_.flatMap(viewer.groupModels, groupModel => {
            return groupModel.dashboardElements.map(dashboardElement => ({
                ...dashboardElement,
                groupModel: _.pick(groupModel, ['id', 'group'])
            }))
        }),
        jobs: uniqueById(
            _.flatMap(viewer.groupModels, 'jobs'),
            job => job
        )
    }
})
