import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fetchBasicData } from '../../actions/api'
import {
    getListIsFetching,
    getModuleFetchError,
    getFiltersExist,
    getMode, getLanguage,
} from '../../selectors/index'
import SpinningDots from '../../../../components/Loader/SpinningDots'
import {WarningBanner} from '../../../../components/Banner'
import { getModule, getGroupModel } from '../../selectors/index'
import Filter from '../Filter'
import DataTable from './KpDataTable'
import './List.css'

class List extends PureComponent {
    render() {
        const { isFetching, module, groupModel, filtersExist, fetchError, mode, t, language } = this.props
        const isListMode = mode === 'list'
        return (
            <div className='List-ListPanel'>
                {filtersExist && <Filter module={module} t={t} language={language} groupModel={groupModel} />}
                {isListMode && (isFetching ? <SpinningDots/> : !fetchError ? <DataTable groupModel={groupModel} module={module} t={t}/> : this.renderError())}
            </div>
        )
    }

    renderError() {
        const { fetchError, clearErrors, t } = this.props

        return (
            <div className="">
                <WarningBanner message={fetchError}/>
                <button className="btn btn-light remove-button-outline" onClick={clearErrors}>{t('retry')}</button>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    module: getModule(state),
    language: getLanguage(state),
    groupModel: getGroupModel(state),
    isFetching: getListIsFetching(state),
    fetchError: getModuleFetchError(state),
    filtersExist: getFiltersExist(state),
    mode: getMode(state)
})

const mapDispatchToProps = {
    clearErrors: fetchBasicData
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
