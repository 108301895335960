import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from "redux-form"
import { fetchBasicData } from '../../actions/api'
import { getChartIsFetching, getChartFetchError } from '../../selectors/index'
import SpinningDots from '../../../../components/Loader/SpinningDots'
import { getModule } from '../../selectors/index'
import ChartFilterForm, {CHART_FILTER_FORM} from '../Filter/ChartFilterForm'
import ChartContainer from '../../../../components/Graph/ChartContainer'
import '../List/List.css'
import { getChartFiltersOpened, getChart } from '../../selectors'
import { setChartFiltersOpened } from '../../actions'

class ConnectedGraph extends Component {
    renderListPanel() {
        const {
            isFetching,
            module,
            filtersOpened,
            objects,
            formValues,
            setFiltersOpened,
            t
        } = this.props

        return (
            <div className="List-ListPanel">
                <ChartFilterForm module={module} t={t} />
                {isFetching
                    ? <SpinningDots />
                    : (
                        <ChartContainer
                            objects={objects.byId}
                            allIds={objects.allIds}
                            setFiltersOpened={setFiltersOpened}
                            filtersOpened={filtersOpened}
                            axis={module.chartParams.data}
                            filters={module.chartParams.filters}
                            formValues={formValues}
                            keys={module.chartParams.keys}
                            type={module.chartParams.type}
                            title={module.chartParams.title}
                            yAxisUnit={module.chartParams.yAxisUnit}
                            colorRange={module.chartParams.colorRange}
                            t={t}
                        />
                    )
                }
            </div>
        )
    }

    renderError() {
        const { fetchError, clearErrors } = this.props

        return (
            <div>
                {fetchError}
                <button onClick={clearErrors}>Retry</button>
            </div>
        )
    }

    render() {
        const { fetchError } = this.props

        return fetchError ? this.renderError() : this.renderListPanel()
    }
}

const mapStateToProps = state => ({
    objects: getChart(state),
    module: getModule(state),
    isFetching: getChartIsFetching(state),
    fetchError: getChartFetchError(state),
    filtersOpened: getChartFiltersOpened(state),
    formValues: getFormValues(CHART_FILTER_FORM)(state)
})

const mapDispatchToProps = dispatch => {
    return {
        clearErrors: fetchBasicData,
        setFiltersOpened: opened => dispatch(setChartFiltersOpened(opened))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedGraph)
