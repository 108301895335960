const _ = require('lodash')

export const entities = [
    {
        name: 'HermesProjectType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            {id: 'N', name: 'NNew'},
            {id: 'R', name: 'RRenovation'},
            {id: 'A', name: 'AEnlargement'},
            {id: 'D', name: 'DRelocation'},
            {id: 'S', name: 'SConcessionTakeover'},
            {id: 'N+C', name: 'NCNewWithCannibalization'}
        ]
    },
    {
        name: "Year",
        type: "static",
        fields: [
            {path: "id", type: "integer"},
            {path: "name", f: function() { return `${this.id}`; }}
        ],
        objects: _.range(2015, 2022).map(
            year => ({id: year})
        )
    }
]
