const moment = require('moment')
const _ = require('lodash')


export const entity = {
    name: 'Course',
    fields: [
        {path: 'details', type: 'Synthesis', link: 'OTM'},
        {path: 'code', unique: true},
        'Formation',
        {path: 'start', type: 'date'},
        {path: 'end', type: 'date'},
        {path: 'collaborator', type: 'Collaborator'},
        {path: 'participants', type: 'User', link: 'MTM'},
        'Status',
        'synthesis',
        {
            path: 'nbParticipants',
            fieldPath: ['participants.id'],
            f: function () {
                return this.participants && this.participants.length
            }
        },
        {
            path: 'formationFullTitle',
            fieldPath: ['formation.id', 'formation.fullTitle'],
            f: function () {
                return this.formation && this.formation.fullTitle
            }
        },
        {
            path: 'formationLength',
            fieldPath: ['formation.id', 'formation.length'],
            f: function () {
                return this.formation && this.formation.length
            }
        },
        {
            path: 'formationDescription',
            fieldPath: ['formation.id', 'formation.description'],
            f: function () {
                return this.formation && this.formation.description
            }
        },
        {
            path: 'formationMethod',
            fieldPath: ['formation.id', 'formation.method'],
            f: function () {
                return this.formation && this.formation.method
            }
        },
        {
            path: 'formationLevel',
            fieldPath: ['formation.id', 'formation.level'],
            f: function () {
                return this.formation && this.formation.level && this.formation.level.name
            }
        },
        {
            path: 'formationCategory',
            fieldPath: ['formation.id', 'formation.category.fullName'],
            f: function () {
                return this.formation && this.formation.category && this.formation.category.fullName
            }
        },
        {
            path: "fullName",
            fieldPath: ['code', 'start', 'end', 'formation.id', 'formation.fullTitle'],
            f: function () {
                const begin = this.start &&  moment(this.start).format('YYYY-MM-DD')
                const end = this.end && moment(this.end).format('YYYY-MM-DD')
                const title = this.formation && this.formation.fullTitle
                return `${this.code} - ${title} - ${begin} - ${end}`
            }
        }
    ],
    filters: [
        {
            name: 'yetToStart',
            isDefault: false,
            query: () => {
                const today = new Date()
                return {start: {$gt: today}}
            }
        },
        {
            name: 'ongoing',
            isDefault: false,
            query: () => {
                const today = new Date()
                return {
                    start: {$lte: today},
                    status: 'ongoing'
                }
            }
        },
        {
            name: 'byCategory',
            path: 'category',
            object: 'Category',
            display: 'fullName',
            client: true,
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const categoryId = _.get(context, 'data.category.id')
                global.app.FT.Formation.collection.find({
                    category: categoryId && new global.ObjectID(categoryId),
                }).toArray(
                    (error, formations) => {
                        callback(error, { formation: { $in: formations.map(formation => formation._id) } });
                    });
            }
        }
    ]
}

export const module_ = {
    name: 'Organize',
    object: 'Course',
    tKey: 'mTitle_organize',
    removable: false,
    category: {
        path: 'sessions',
        icon: 'book'
    },
    viewMap: {
        dt: [
            'code',
            {path: 'formationCategory', tKey: 'category'},
            {path: 'formationFullTitle', tKey: 'formation'},
            {path: 'formationLevel', tKey: 'level'},
            {path: 'formationLength', tKey: 'nbHours'},
            {path: 'start'},
            {path: 'end'},
            {path: 'collaborator', display: 'fullName'},
            {path: 'nbParticipants'}
        ],
        form: [
            {path: 'code', required: true},
            {path: 'formation', display: 'fullTitle'},
            {path: 'start', required: true},
            {path: 'end', required: true},
            {path: 'collaborator', display: 'fullName'},
            {path: 'participants', display: 'name', filters: [{
                    path: "userKNotAllowed",
                    match: user => {
                        return !user.k
                    }
                }]},
            {path: 'status', default: {id: 'ongoing'}}
        ]
    },
    filters: ['yetToStart']
}
