import {INITIALIZE_MODULE, SET_CHART_FILTERS_OPENED} from '../actions'
import {CHART_REQUEST, CHART_SUCCESS, CHART_FAILURE} from '../actions/api'

const getDataIds = data => data.map(o => o.id)

const defaultState = {
    allIds: [],
    byId: {},
    filtersOpened: true,
    isFetching: false,
    error: null,
    errorStatus: null
}

const chart = (state = defaultState, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE: {
            return {
                ...defaultState,
                ...action.module.chart
            }
        }
        case SET_CHART_FILTERS_OPENED:
            return {
                ...state,
                filtersOpened: action.opened
            }
        case CHART_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
                errorStatus: null
            }

        case CHART_SUCCESS:
            return {
                ...state,
                isFetching: false,
                allIds: getDataIds(action.data),
                byId: action.data.reduce(
                    (acc, o) => ({ ...acc, [o.id]: o }),
                    {}
                )
            }

        case CHART_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                errorStatus: action.status
            }

        default:
            return state
    }
}

export default chart
