import _ from "lodash";
import async from "async";
import {basicContext} from "../../utils/contextUtils";
import {GET_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api";
import {setFieldEdition} from "../../../apps/KpModule/actions";

async function findShelters(context, callback) {
    try {
        const exercise = await global.app.C.Exercise.get({exerciseStatus: 'ongoing'}, {
            ...basicContext(context),
            fieldPath: ['id', 'code'],
        })
        if(!exercise) return []

        const user = context.user
        const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(user.id)}, {
            ...basicContext(context),
            fieldPath: ['id', 'organization.id', 'organization.organizationType.memberAssociation', 'shelters.id']
        })

        const isAdministratorModule = context.clientContext.moduleId === 'm-C-capacityAdministratorModule'

        if(!isAdministratorModule && !cUser.organization?.organizationType?.memberAssociation) return []

        const shelters = await global.app.C.Shelter.find({
            ...basicContext(context),
            fieldPath: ['id', 'name', 'organization.id', 'organization.name', 'animalTypes.id', 'animalTypes.name'],
            query: isAdministratorModule
                ? {}
                : !!cUser.shelters.length
                    ? {_id: {$in: cUser.shelters.map(shelter => global.ObjectID(shelter.id))}}
                    : {organization: global.ObjectID(cUser.organization.id)}
        })

        async.map(shelters, (shelter, callback) => {
            const animalTypeQuery = !!context.data.animalTypes?.length
                ? {animalType: {$in: context.data.animalTypes.map(animalType => global.ObjectID(animalType.id))}}
                : {}
            global.app.C.Capacity.find({
                ...basicContext(context),
                fieldPath: ['id', 'animalType.id', 'animalType.name', 'totalCapacity', 'availableCapacity'],
                query: {
                    shelter: global.ObjectID(shelter.id),
                    exercise: global.ObjectID(exercise.id),
                    ...animalTypeQuery
                }
            }, (e, capacities) => {
                return callback(e, capacities.reduce((acc, capacityDetails) => {
                    return {
                        ...acc,
                        ddpp: acc.ddpp + capacityDetails.ddpp,
                        totalCapacity: acc.totalCapacity + capacityDetails.totalCapacity,
                        availableCapacity: acc.availableCapacity + capacityDetails.availableCapacity,
                        adoption: acc.adoption + capacityDetails.adoption,
                        pound: acc.pound + capacityDetails.pound,
                        hostFamily: acc.hostFamily + capacityDetails.hostFamily
                    }
                },{
                    id: shelter.id,
                    shelter: _.pick(shelter, ['id', 'name']),
                    organization: shelter.organization,
                    ddpp: 0,
                    totalCapacity: 0,
                    availableCapacity: 0,
                    adoption: 0,
                    pound: 0,
                    hostFamily: 0
                }))
            })
        }, callback)
    } catch (e) {
        throw(e)
    }

}
async function getShelterDetails(id, context) {
    const exercise = await global.app.C.Exercise.get({exerciseStatus: 'ongoing'}, {
        ...basicContext(context),
        fieldPath: ['id', 'code'],
    })

    const shelter = await global.app.C.Shelter.get(id, {
        ...basicContext(context),
        fieldPath: ['id', 'name', 'organization.id', 'organization.name', 'animalTypes.id', 'animalTypes.name'],
    })
    const capacities = await global.app.C.Capacity.find({
        ...basicContext(context),
        fieldPath: ['id', 'animalType.id', 'animalType.name', 'totalCapacity', 'availableCapacity'],
        query: {
            shelter: global.ObjectID(shelter.id),
            exercise: global.ObjectID(exercise.id)
        }
    })

    return {
        organization: shelter.organization,
        exercise,
        shelter: _.pick(shelter, ['id', 'name']),
        capacityByAnimalType: shelter.animalTypes.map(animalType => {
            const capacityDetails = capacities.find(capacity => capacity.animalType.id === animalType.id )
            return {
                animalType: animalType,
                ddpp: capacityDetails ? capacityDetails.ddpp : 0,
                totalCapacity: capacityDetails ? capacityDetails.totalCapacity : 0,
                availableCapacity: capacityDetails ? capacityDetails.availableCapacity : 0,
                adoption: capacityDetails ? capacityDetails.adoption : 0,
                pound: capacityDetails ? capacityDetails.pound : 0,
                hostFamily: capacityDetails ? capacityDetails.hostFamily : 0
            }
        })
    }
}

function saveShelterDetails(newObject, context, callback) {
    const options = { upsert: true }
    const queries = newObject.capacityByAnimalType.map(animalTypeCapacity => {
        return {
            query: {
                exercise: global.ObjectID(newObject.exercise.id),
                shelter: global.ObjectID(newObject.shelter.id),
                animalType: global.ObjectID(animalTypeCapacity.animalType.id),
                group: global.ObjectID(context.group.id)
            },
            update: {
                $set : {
                    exercise: global.ObjectID(newObject.exercise.id),
                    shelter: global.ObjectID(newObject.shelter.id),
                    animalType: global.ObjectID(animalTypeCapacity.animalType.id),
                    ddpp: animalTypeCapacity.ddpp,
                    totalCapacity: animalTypeCapacity.totalCapacity,
                    availableCapacity: animalTypeCapacity.availableCapacity,
                    adoption: animalTypeCapacity.adoption,
                    pound: animalTypeCapacity.pound,
                    hostFamily: animalTypeCapacity.hostFamily,
                    group: global.ObjectID(context.group.id)
                }
            }
        }
    })
    async.parallel(
        queries.map(query => callback => global.db.collection("c.capacity").updateOne(query.query, query.update, options, callback)),
            error => {
                callback(error, _.pick(newObject, ['id', 'organization', 'shelter']))
            }
    )

}

export const entities = [
    {
        name: 'Capacity',
        fields: [
            'Exercise',
            'Shelter',
            'AnimalType',
            {path: 'ddpp', type: 'integer'},
            {path: 'totalCapacity', type: 'integer'},
            {path: 'availableCapacity', type: 'integer'},
            {path: 'adoption', type: 'integer'},
            {path: 'pound', type: 'integer'},
            {path: 'hostFamily', type: 'integer'}
        ]
    },
    {
        name: "CapacityByAnimalType",
        type: "mongoInternal",
        fields: [
            "AnimalType",
            {path: 'ddpp', type: 'integer'},
            {path: 'totalCapacity', type: 'integer'},
            {path: 'availableCapacity', type: 'integer'},
            {path: 'adoption', type: 'integer'},
            {path: 'pound', type: 'integer'},
            {path: 'hostFamily', type: 'integer'}
        ]
    },
    {
        name: 'CapacityByShelter',
        fields: [
            "Organization",
            "Shelter",
            "Exercise",
            {path: "capacityByAnimalType", type: "CapacityByAnimalType", link: "MTO"}
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) return callback(error);

                else {
                    try {
                        findShelters(context, callback)
                    } catch (e) {
                        callback(e)
                    }
                }
            })
        },
        get: function(id, context, callback) {
            this.prepareContext(context, 'R', (error, context) => {
                if (error) callback(error);
                else getShelterDetails(id, context, callback)
                    .then(object => callback(null, object))
                    .catch(e => callback(e))
            })
        },
        save: function(newObject, context, callback) {
            this.prepareContext(context, 'S', (error, context) => {
                if (error) callback(error)
                else saveShelterDetails(newObject, context, callback)
            })
        }
    }
]


export const administratorModule = {
    name: 'CapacityAdministratorModule',
    object: 'CapacityByShelter',
    tKey: 'mTitle_capacity',
    objectIdentifier: 'name',
    newable: false,
    removable: false,
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            'organization', 'shelter',
            {path: 'ddpp'},
            {path: 'totalCapacity'},
            {path: 'availableCapacity'},
            {path: 'adoption'},
            {path: 'pound'},
            {path: 'hostFamily'}
        ],
        form: [
            {path: 'organization', editable: false},
            {path: 'exercise', display: 'code', fieldPath: ['id', 'code', 'exerciseStatus.id'], editable: false, hidden: true},
            {path: 'shelter', editable: false},
            {
                path: 'capacityByAnimalType',
                type: 'dtObjects',
                fields: [
                    'animalType',
                    {path: 'ddpp', type: 'editText', width: 100},
                    {path: 'totalCapacity', type: 'editText', width: 100},
                    {path: 'availableCapacity', type: 'editText', width: 100},
                    {path: 'adoption', type: 'editText', width: 100},
                    {path: 'pound', type: 'editText', width: 100},
                    {path: 'hostFamily', type: 'editText', width: 100}
                ]
            }
        ]
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()

                const exercise = _.get(state, 'edit.object.data.exercise')

                store.dispatch(setFieldEdition('e_capacityByAnimalType', _.get(exercise, 'exerciseStatus.id') !== 'finalClosure'))
            }
        }
    ],
    filters: [
        {
            path: 'animalTypes',
            object: 'AnimalType',
            type: 'tags',
            display: 'name',
            client: true
        }
    ],
}

export const userModule = {
    name: 'CapacityUserModule',
    object: 'CapacityByShelter',
    tKey: 'mTitle_capacity',
    objectIdentifier: 'name',
    newable: false,
    removable: false,
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            'organization', 'shelter',
            {path: 'ddpp'},
            {path: 'totalCapacity'},
            {path: 'availableCapacity'},
            {path: 'adoption'},
            {path: 'pound'},
            {path: 'hostFamily'}
        ],
        form: [
            {path: 'organization', editable: false},
            {path: 'exercise', display: 'code', fieldPath: ['id', 'code', 'exerciseStatus.id'], editable: false, hidden: true},
            {path: 'shelter', editable: false},
            {
                path: 'capacityByAnimalType',
                type: 'dtObjects',
                fields: [
                    'animalType',
                    {path: 'ddpp', type: 'editText', width: 100},
                    {path: 'totalCapacity', type: 'editText', width: 100},
                    {path: 'availableCapacity', type: 'editText', width: 100},
                    {path: 'adoption', type: 'editText', width: 100},
                    {path: 'pound', type: 'editText', width: 100},
                    {path: 'hostFamily', type: 'editText', width: 100}
                ]
            }
        ]
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()

                const exercise = _.get(state, 'edit.object.data.exercise')

                store.dispatch(setFieldEdition('e_capacityByAnimalType', _.get(exercise, 'exerciseStatus.id') !== 'finalClosure'))
            }
        }
    ],
    filters: [
        {
            path: 'animalTypes',
            object: 'AnimalType',
            type: 'tags',
            display: 'name',
            client: true
        }
    ],
}
