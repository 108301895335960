import moment from 'moment'
import _ from 'lodash'
import {getStoreHabilitationQuery} from '../../utils/habilitations'

const enumerateDaysBetweenDates = function(startDate, endDate) {
    const dates = []

    while(startDate.diff(endDate) <= 0) {
        dates.push(startDate.format('DD-MM-YYYY'))
        startDate.add(1, 'days')
    }
    return dates
};

async function findData(context){

    const habQuery = getStoreHabilitationQuery(context)
    const [habilitations] = await Promise.all([habQuery])

    const yesterdayMoment = moment().subtract(1, 'days')
    const lastWeekMoment = moment().subtract(7, 'days')

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')


    const datesRange = enumerateDaysBetweenDates(lastWeekMoment, yesterdayMoment)

    const dashboardDates = [lastWeekISO, yesterdayISO]

    if(habilitations.length) {
        const habilitation = habilitations[0];
        const store = habilitation.store
        const organization = habilitation.organization

        const alertConfigurationCollection = global.app.S.AlertConfiguration.collection
        const alertConfigurationQuery = alertConfigurationCollection
            .find({
                code: "CAMagasinJour"
            })
            .toArray()

        const [alertConfigurations] = await Promise.all([alertConfigurationQuery])
        const alertConfiguration =  alertConfigurations[0]

        const dates = [lastWeekISO, todayISO].map(date => moment.utc(date.trim(), 'DD-MM-YYYY'))

        const datesQuery = {
            'date.gte': { $lte: dates[1].toDate() },
            'date.lt': { $gte: dates[0].toDate() },
        }

        const alertConfQuery = {
            'alertConfiguration': alertConfiguration._id
        }

        const commonAlertsQuery = {
            ...datesQuery,
            ...alertConfQuery,
            'theme-CANet.Mt': {$exists: true}
        }

        const storeAlertsPromise = global.app.S.Alert.collection.aggregate([
            {
                $match: {
                    ...commonAlertsQuery,
                    'store': store._id,
                }
            },
            {
                $group:{
                    _id: '$hic-date.gte',
                    ca: {$sum: '$theme-CANet.Mt'},
                }
            },
            {
                $project: {
                    date: { $dateToString: { format: "%d-%m-%Y", date: "$_id" } },
                    ca: {$divide: ['$ca', 1000]},

                }
            }
        ]).toArray()

        const organizationAlertsPromise = global.app.S.Alert.collection.aggregate([
            {
                $match: {
                    ...commonAlertsQuery,
                    'organizations': organization._id
                }
            },
            {
                $group:{
                    _id: '$hic-date.gte',
                    avg: {$avg: '$theme-CANet.Mt'},
                }
            },
            {
                $project: {
                    date: { $dateToString: { format: "%d-%m-%Y", date: "$_id" } },
                    avg: {$divide: ['$avg', 1000]},

                }
            }
        ]).toArray()

        const [
            storesAlerts,
            organizationAlerts
        ] = await Promise.all([
            storeAlertsPromise,
            organizationAlertsPromise
        ])

        const finalValues = datesRange.map(
            date => {
                const storeData = storesAlerts.find(o => o.date === date);
                const regionData = organizationAlerts.find(o => o.date === date);
                return {
                    date,
                    'Mag.': storeData ? _.round(storeData.ca, 1) : 0,
                    'Moy. Reg.': regionData ? _.round(regionData.avg, 1) : 0
                }
            }
        )

        return [{
            id: "turnoverPerDay",
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: finalValues
        }]
    }
    else {
        return [{
            id: "turnoverPerDay",
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: []
        }]

    }
}

export const entity = {
    name: 'TurnoverPerDay',
    dashboard: true,
    fields: [
        "id",
        "subTitle",
        "data",
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error);
            else findData(context)
                .then(objects => callback(null, objects))
                .catch(error => callback(error))
        });
    }
}
