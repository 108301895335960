const _ = require('lodash')
const { textSearchQuery } = require("../../utils/mongoUtils")

export const byCompany = {
    name: 'byCompany',
    title: "Company",
    path: "company",
    tKey: "company",
    default: {id: null},
    object: "Company",
    objectFieldPath: ["company", "company.id"],
    display: "fullName",
    client: true,
    isDefault: false,
    clearable: true,
    autoFetch: false,
    query: function (context) {
        const companyId = _.get(context, "data.company.id");

        return companyId
            ? {company: new global.ObjectID(companyId)}
            : {_id: false}
    }
}

export const byArticle = {
    name: 'byArticle',
    title: 'Article',
    path: 'article',
    object: 'Article',
    type: 'asyncDropdownObject',
    objectFieldPath: ["article.id"],
    display: 'fullName',
    filters:[
        {
            title: "byCode",
            path: "byCode",
            tKey: "byCode",
            type: "text",
            query: context => {
                const params = context.clientContext.params
                const search = params && params.search

                const keys = ["code", "name"]

                return textSearchQuery(search, keys);
            }
        },
        byCompany
    ],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const articleId = _.get(context.data, 'article.id')
        return articleId
            ? {article: new global.ObjectID(articleId)}
            : {_id: false}
    }
}

export const bySubFamily = {
    name: 'bySubFamily',
    title: 'SubFamily',
    path: 'subFamily',
    object: 'SubFamily',
    objectFieldPath: ["subFamily.id"],
    display: 'fullName',
    filters:[byCompany],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const subFamilyId = _.get(context.data, 'subFamily.id')
        return subFamilyId
            ? {subFamily: new global.ObjectID(subFamilyId)}
            : {_id: false}
    }
}
export const byFamily = {
    name: 'byFamily',
    title: 'Family',
    path: 'family',
    object: 'Family',
    objectFieldPath: ["family.id"],
    display: 'fullName',
    filters:[byCompany],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const familyId = _.get(context.data, 'family.id')
        return familyId
            ? {family: new global.ObjectID(familyId)}
            : {_id: false}
    }
}

export const byDeposit = {
    name: 'byDeposit',
    title: 'Deposit',
    path: 'deposit',
    object: 'Deposit',
    objectFieldPath: ["deposit.id"],
    display: 'fullName',
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const depositId = _.get(context.data, 'deposit.id')
        return depositId
            ? {deposit: new global.ObjectID(depositId)}
            : {_id: false}
    }
}

export const byProvider = {
    name: 'byProvider',
    title: 'Provider',
    path: 'provider',
    object: 'Provider',
    objectFieldPath: ["provider.id"],
    type: 'asyncDropdownObject',
    display: 'fullName',
    filters:[
        {
            title: "byProviderCode",
            path: "providerCode",
            tKey: "providerCode",
            type: "text",
            query: context => {
                const params = context.clientContext.params
                const search = params && params.search

                const keys = ["code", "name"]

                return textSearchQuery(search, keys);
            }
        },
        byCompany
    ],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const providerId = _.get(context.data, 'provider.id')
        return providerId
            ? {provider: new global.ObjectID(providerId)}
            : {_id: false}
    }
}

export const byClient = {
    name: 'byClient',
    title: 'Client',
    path: 'client',
    object: 'Client',
    objectFieldPath: ["client.id"],
    type: 'asyncDropdownObject',
    display: 'fullName',
    filters:[
        {
            title: "byClientCode",
            path: "clientCode",
            tKey: "clientCode",
            type: "text",
            query: context => {
                const params = context.clientContext.params
                const search = params && params.search

                const keys = ["code", "name"]

                return textSearchQuery(search, keys);
            }
        },
        byCompany
    ],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const clientId = _.get(context.data, 'client.id')
        return clientId
            ? {client: new global.ObjectID(clientId)}
            : {_id: false}
    }
}

export const byPiece = {
    name: 'byPiece',
    title: 'Piece',
    path: 'piece',
    object: 'Piece',
    objectFieldPath: ["piece.id"],
    type: 'asyncDropdownObject',
    display: 'fullName',
    filters:[
        {
            title: "byPiece",
            path: "byPiece",
            tKey: "byPiece",
            type: "text",
            query: context => {
                const params = context.clientContext.params
                const search = params && params.search

                const keys = ["piece"]

                return textSearchQuery(search, keys);
            }
        },
        byCompany
    ],
    clearable: true,
    isDefault: false,
    client: true,
    query: function(context) {
        const pieceId = _.get(context.data, 'piece.id')
        return pieceId
            ? {piece: new global.ObjectID(pieceId)}
            : {_id: false}
    }
}
