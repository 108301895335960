const moment = require("moment");
const _ = require("lodash");
const async = require("async");
const { translateName } = require("../../../utils/i18n");

const yesterdayISO = moment().subtract(1, "days").format("YYYY-MM-DD");

const groupByJoinedEntity = (alertAnalyseGroupElement, alerts) => {
    switch (alertAnalyseGroupElement.joinedEntity) {
        case "StoreAxis":
            return _.groupBy(alerts, alert => alert.store
                ? `${alert.store.code}_${alert["dataPeriod"]}`
                : `undefined_store_${alert["dataPeriod"]}`
            )
        case "ProductAxis":
            return _.groupBy(alerts, alert => alert.product
                ? `${alert.product.code}_${alert["dataPeriod"]}`
                : `undefined_product_${alert["dataPeriod"]}`
            )
        case "OrganizationAxis":
            return _.groupBy(alerts, alert => {
                const organization = alert.organizations.find(o => o.organizationAxis.code === alertAnalyseGroupElement.code)
                return organization ? `${organization.code}_${alert["dataPeriod"]}` : `undefined_org_${alert["dataPeriod"]}`
            })
        case "FamilyAxis":
            return _.groupBy(alerts, alert => {
                const family = alert.familys.find(o => o.familyAxis.code === alertAnalyseGroupElement.code)
                return family ? `${family.code}_${alert["dataPeriod"]}` : `undefined_family_${alert["dataPeriod"]}`
            })
        default:
            return []
    }
}

const getMesh = (alertAnalyseGroupElement, alert) => {
    switch (alertAnalyseGroupElement.joinedEntity) {
        case "StoreAxis":
            return `${alert.store.code} - ${alert.store.name}`
        case "ProductAxis":
            return alert.product.name
        case "OrganizationAxis":
            return alert.organizations.find(o => o.organizationAxis.code === alertAnalyseGroupElement.code).name
        case "FamilyAxis":
            return alert.familys.find(o => o.familyAxis.code === alertAnalyseGroupElement.code).name
        default:
            return "No value for joined entity"
    }
}

const findAlertsAnalyse = (context, callback) => {

    const scopeId = _.get(context, 'data.scope.id');
    const alertConfId = _.get(context, 'data.alertConfiguration.id');
    const alertGroupElemId = _.get(context, 'data.alertAnalyseGroupElement.id');

    // in case both mandadory filters are empty, we return an empty list
    if (!(alertConfId && alertGroupElemId)) return callback(null, []);

    const scopeQuery = (scopeId && scopeId !== "null") ? {$or: [
        {"familys": new global.ObjectID(scopeId)},
        {"organizations": new global.ObjectID(scopeId)}
    ]} : {};

    const dates = _.get(context, 'data.dates', [yesterdayISO, yesterdayISO]).map(
        date => moment.utc(date.trim(), "YYYY-MM-DD")
    );
    const datesQuery = {
        "date.gte": { $gte: dates[0].toDate() },
        "date.lt": { $lte: dates[1].add(1, "days").toDate() },
    };

    const alertConfQuery = (alertConfId && alertConfId !== "null") ? {"alertConfiguration": new global.ObjectID(alertConfId)} : {};

    const alertContext = {
        user: context.user,
        group: context.group,
        t: context.t,
        tc: context.tc,
        query: {
            ...scopeQuery,
            ...datesQuery,
            ...alertConfQuery
        },
        fieldPath: [
            "id",
            "store.id",
            "product.id",
            "alertConfiguration.id",
            "groupAxes.id",
            "dataPeriod",
            "lineCount",
            "organizations.organizationAxis.id",
            "organizations.organizationAxis.name",
            "familys.familyAxis.id",
            "familys.familyAxis.name"
        ]
    }

    const alertConfContext = {
        user: context.user,
        group: context.group,
        fieldPath: [
            "id",
            "groupAxes.id",
            "tName"
        ]
    }

    async.parallel({
        alertConf: callback => {
            global.app.S.AlertConfiguration.get(
                alertConfId,
                {
                    fieldPath: [...global.app.S.AlertConfiguration.deleteFieldPath, ...alertConfContext.fieldPath],
                    group: context.group
                },
                callback
            )
        },
        alertAnalyseGroupElement: callback => {
            global.app.S.AlertAnalyseGroupElement.get(
                alertGroupElemId,
                {
                    fieldPath: global.app.S.AlertAnalyseGroupElement.deleteFieldPath,
                    group: context.group
                },
                callback
            )
        },
        alerts: callback => global.app.S.Alert.find(alertContext, callback),
        family: callback => global.app.S.Family.get(scopeId, {
            fieldPath: global.app.S.Family.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        },callback),
        organization: callback => global.app.S.Organization.get(scopeId, {
            fieldPath: global.app.S.Organization.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        },callback)
    }, (e, results) => {
        if (e) return callback(e);

        const {
            alertConf,
            alertAnalyseGroupElement,
            alerts,
            family,
            organization
        } = results

        const authorisedGroupElements = alertConf.groupAxes.reduce((acc, groupAxis) => {
            switch(groupAxis.joinedEntity){
                case "StoreAxis":
                case "SubStoreAxis":
                    return [...acc, "StoreAxis", "OrganizationAxis"]
                case "ProductAxis":
                    return [...acc, "ProductAxis", "FamilyAxis"]
                case "FamilyAxis":
                    return [...acc, "FamilyAxis"]
                case "OrganizationAxis":
                    return [...acc, "OrganizationAxis"]
                default:
                    return []
            }
        }, []);

        const alertsGrouped = authorisedGroupElements.includes(alertAnalyseGroupElement.joinedEntity)
            ? groupByJoinedEntity(alertAnalyseGroupElement, alerts)
            : []

        let perimeter = '';
        if(organization) {
            perimeter = organization.name
        }else if(family) {
            perimeter = family.name
        }

        callback(
            null,
            Object.keys(alertsGrouped).map(
                key => {
                    const objects = alertsGrouped[key]
                    const object = objects[0]
                    return {
                        id: objects.map(o => o.id).join("_"),
                        dataPeriod: _.get(object, "dataPeriod"),
                        mesh: getMesh(alertAnalyseGroupElement, object),
                        lineCount:  _.sum(objects.map(o => o.lineCount)),
                        alert: translateName(object.alertConfiguration.name),
                        perimeter
                    }
                }
            )
        )
    });
}

const getAlertAnalyse = (ids, context, callback) => {
    global.app.S.Alert.find(
        {
            group: context.group,
            tc: context.tc,
            fieldPath: [
                "id", "code", "dataPeriod", "lineCount", "values", "meshWithoutGroupAxis",
                "alertConfiguration.themesName",
                "alertConfiguration.groupAxes.id",
                "alertConfiguration.groupAxisName"
            ],

            query: {
                _id: {$in: ids.split("_").map(id => global.ObjectID(id))}
            }
        },
        (e, alerts) => {
            const result = {
                id: new global.ObjectID(),
                tName: translateName(alerts[0].alertConfiguration.name),
                themesName: alerts[0].alertConfiguration.themesName,
                groupAxisName: alerts[0].alertConfiguration.groupAxisName,
                alerts : alerts.map(alert => _.pick(alert, ["id", "code", "dataPeriod", "lineCount", "values", "meshWithoutGroupAxis"]))
            }
            callback(null, result);
        }
    )
}

export const entity = {
    name: "AlertAnalyse",
    type: null,
    fields: [
        "id",
        "dataPeriod",
        "mesh",
        "lineCount",
        "alerts",
        "tName",
        "groupAxisName",
        "themesName",
        "alert",
        "perimeter"
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) return callback(error);
            else findAlertsAnalyse(context, (e, objects) => {
                if (e) {
                    global.ioSocket.emit(
                        `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`,
                        {error: e.message}
                    )
                } else {
                    global.ioSocket.emit(
                        `fetchDT-success-${context.user.id}${context.clientContext.accessId}`,
                        objects
                    )
                }
            })
            callback()
        })
    },
    get: function(ids, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error);
            else getAlertAnalyse(ids, context, callback);
        })
    }
}

export const module_ = {
    object: "AlertAnalyse",
    tKey: "mTitle_alertAnalyse",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    defaultSortBy: "dataPeriod",
    defaultSortDirection: "ASC",
    newable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    manualFilters: true,
    viewMap: {
        dt: [
            {path: "alert", initiallyNotVisible: true},
            {path: "perimeter", initiallyNotVisible: true},
            {path: "dataPeriod", tKey: "period", width: 40},
            {path: "mesh", tKey: "by", width: 380},
            {path: "lineCount", tKey: "number", width: 35, tooltip: true},
        ],
        form: [
            {path: "tName", tKey: "alertType", writable: false},
            {path: "groupAxisName", tKey: "meshs", writable: false},
            {path: "themesName", tKey: "indicator_plural", writable: false},
            {
                path: "alerts",
                tKey: "alert_plural",
                type: "dtObjects",
                maxRows: 20,
                fields: [
                    {path: "dataPeriod", tKey: "period", width: 40},
                    {path: "meshWithoutGroupAxis", tKey: "for", width: 420},
                    {path: "lineCount", tKey: "number", width: 10},
                    {path: "values", tKey: "values", width: 50}
                ]
            }

        ]
    },
    filters: [
        {
            path: "alertConfiguration",
            object: "AlertConfiguration",
            display: "tName",
            client: true,
            filters: ["alertConfigurationsByHabFunctions"],
            clearable: false
        },
        {
            path: "scope",
            object: "Scope",
            display: "completeName",
            client: true

        },
        {
            path: "alertAnalyseGroupElement",
            object: "AlertAnalyseGroupElement",
            display: "tName",
            client: true,
            clearable: false
        },
        {
            path: "dates",
            type: "dateRange",
            client: true,
            default: [yesterdayISO, yesterdayISO]
        }
    ]
}
