import React, {Component} from 'react';
import * as d3 from 'd3'
import { select } from 'd3-selection';


const defaultColors = ['#ffa600', '#eb4d11']
const margin = { top: 20, right: 25, bottom: 60, left: 40 }


class LineChart extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const {h ,w, data = [], className, dataKeys , defaultKeys, colors = defaultColors, t} = this.props

        const keys = dataKeys || defaultKeys
        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom


        const x = d3.scalePoint()
            .rangeRound([0, width])
            .padding(0.4)

        const x2 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingOuter(0.05)
            .paddingInner(0.3)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const y2 = d3.scaleLinear()
            .rangeRound([height, 0])

        const colorByKey = {
            [keys[0]]: colors[0],
            [keys[1]]: colors[1]
        }

        const xAxis = d3.axisBottom()
            .scale(x)

        const maxTick = Math.max(...data.map(d => d[keys[1]] ))

        const yAxis = d3.axisRight()
            .scale(y)
            .ticks(maxTick < 6 ? maxTick : 7 )

        const y2Axis = d3.axisLeft()
            .scale(y2)
            .ticks(6)

        const giveMeEmpty = d3.line()
            .x(function(d) { return x(d.axis) })
            .y(height);

        const valueLine = d3.line()
            .x(function(d) { return x(d.axis) })
            .y(function(d) { return y(d[keys[1]]) })


        const container = select(`.${className}`)
            .select("svg")
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        x.domain([...data.map(d =>d['axis'])])

        x2.domain([...data.map(d =>d['axis'])])

        y.domain([0, d3.max(data, d => d[keys[1]] + 1 )]);

        y2.domain([0, d3.max(data, d => d[keys[0]] + d[keys[0]]/20 )]).nice();

        const bars = container
            .select(".bars")
            .selectAll("rect")
            .data(data)

        bars.exit()
            .remove()

        bars.enter().append("rect")
            .attr("x", function(d) { return x2(d['axis']); })
            .attr("width", x2.bandwidth())
            .attr('height', 0)
            .attr('y', height)
            .transition()
            .duration(1000)
            .attr("y", function(d) { return y2(d[keys[0]]); })
            .attr("height", function(d) { return height - y2(d[keys[0]]) })
            .attr("fill", d => (d.color || colorByKey[keys[0]]))

        bars
            .attr("width", x2.bandwidth())
            .attr("height", function(d) { return height - y2(d[keys[0]]) })
            .attr("x", function(d) { return x2(d['axis']); })
            .attr("y", function(d) { return y2(d[keys[0]]); })
            .attr("fill", d => (d.color || colorByKey[keys[0]]))



        const path = container
            .selectAll('.path1')
            .data([data])


        path
            .style("stroke", colorByKey[keys[1]])
            .style("stroke-width", "2")
            .style("fill", "none")
            .attr("d", valueLine)

        path.enter().append('path')
            .attr('class', 'path1')
            .style("stroke", colorByKey[keys[1]])
            .style("stroke-width", "2")
            .style("fill", "none")
            .attr('d', giveMeEmpty)
            .transition()
            .duration(1000)
            .attr("d", valueLine)


        container.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("transform", "rotate(-55)")
            .attr("y", 5)
            .attr("x", -6)
            .attr("dy", ".35em")
            .style("text-anchor", "end");

        container.select('.axis--y')
            .attr('transform', `translate(${width},0)`)
            .call(yAxis)

        container.select('.axis--y2')
            .attr('transform', `translate(0,0)`)
            .call(y2Axis)


        const legend = container
            .selectAll(".legend")
            .data(keys)

        legend
            .select("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key];})

        legend
            .select("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return t(key)
            });


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i) { return "translate("+ i * -70 + ", -15)"; })

        enterSelection
            .append("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key];})

        enterSelection
            .append("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return t(key)
            })

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars"/>
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                        <g className="axis axis--y2"/>
                    </g>
                    <g className="legendContainer"/>
                </svg>
            </div>
        )
    }
}

export default LineChart;
