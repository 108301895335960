import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash"
import { HashLink as Link } from 'react-router-hash-link'
import PhoneInput from "react-phone-input-2"
import './Registration.css'
import DynamicStyledInput from "./Form/DynamicStyledInput"
import Files from "./Files"
import {colourStyles} from "../apps/KpModule/components/Filter/SelectStyles"
import {Select} from "./Form"
import CheckBoxField from "./Form/CheckboxField"
import DatePicker from "./Calendar/DatePicker"
import SpinningDots from "./Loader/SpinningDots"
import logoCNDA from  '../img/logo_cnda.svg'
import ValidationUI from "./ValidationUI"
import SingleFile from "./SingleFile";

const removeElemAtIndex = (array, index) => {
    if(array.length <= index ) return array
    const arrayOfLetters = ['a', 'b', 'c', 'd', 'e', 'f'];

    const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

    const halfAfterTheUnwantedElement = array(index + 1);

    return halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);

}

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const FormContainer = (props) => {
    return (
        <div className="Registration-container">
            <div className="Registration-form-container">
                <div className= "Registration-well">
                    {props.children}
                </div>
            </div>
        </div>
    )
}


class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                civility: null,
                firstname: '',
                lastname: '',
                mail: '',
                phone: '',
                organizationPresident: false,
                organizationName: '',
                organizationAddress: '',
                organizationAdditionalAddress: '',
                organizationZipCode: '',
                organizationCity: '',
                organizationCountry: 'FRANCE',
                rna: '',
                sheltersNumber: 0,
                generalInterestRecognition: false,
                publicUtilityRecognition: false,
                hasEndowmentFund: false,
                hasDUERP: false,
                DUERPUpdateDate: '',
                contactID: null,
                situationNotice: null,
                updatedStatus: null,
                balanceSheetsAndIncomeStatements: null,
                officialStatement: null,
                CERFA: null,
                DDPVisitReports: null,
                sheltersPhotos: null,
                lastGeneralMeeting: null
            },
            open: {
                DUERPUpdateDateOpen: false
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleFileDeletion = this.handleFileDeletion.bind(this)
    }

    handleChange(field, value) {
        const result = {
            [field]: value
        }
        this.setState({
            data: {...this.state.data, ...result}
        })

        this.props.validateField(result)
    }
    handleOnOpenChange(path) {
        this.setState({
            open: {
                ...this.state.open,
                [path]: !this.state.open[path]
            }
        })
    }

    handleFileUpload(path,  filesObject) {
        const result = {
            [path]: filesObject
        }
        this.setState({
            data: {
                ...this.state.data,
                ...result
            }
        })
        this.props.validateField(result)
    }

    handleFileDeletion(index) {
        this.setState({
            files: removeElemAtIndex(this.state.files, index)
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state.data)
    }

    getSelectComponent(path, optionsPath, isMulti, removeLabel) {
        const {t} = this.props
        const options = this.props[optionsPath]
        const optionsById = this.props[`${optionsPath}ById`]

        return <Select
            path={t(path)}
            options={options}
            noOptionsMessage={() => t('noOptions')}
            //className="Form-TagsField"
            isMulti={isMulti}
            styles={colourStyles}
            multi={true}
            onChange={optionOrOptions => {
                let selectedValue
                if(isMulti) {
                    selectedValue = optionOrOptions ? optionOrOptions.map(o => ({id: o.value})) : []
                } else {
                    selectedValue = optionOrOptions ? {id: optionOrOptions.value} : null
                }
                this.handleChange(path, selectedValue)
            }}
            value={
                isMulti
                    ? this.state[path].map(o => optionsById[o.id])
                    : optionsById[this.state[path]?.id]
            }
            isDisabled={false}
            isClearable={true}
            removeLabel={removeLabel}
            placeholder={t('select')}
            t={t}
        />
    }

    render() {
        const {t, success, loading, validationErrors} = this.props
        const {
            files, firstname, lastname, mail, phone, organizationPresident,
            organizationName, organizationAddress, organizationAdditionalAddress, organizationZipCode,
            organizationCity, rna, sheltersNumber,
            generalInterestRecognition, publicUtilityRecognition, hasEndowmentFund, hasDUERP, DUERPUpdateDate,
            contactID, situationNotice, updatedStatus, balanceSheetsAndIncomeStatements, officialStatement,
            CERFA, DDPVisitReports, sheltersPhotos, lastGeneralMeeting
        } = this.state.data

        const {DUERPUpdateDateOpen} = this.state.open

        const filesIds = files && files.map(o => o.id)
        const filesById = arrayToObject(files)

        if(success) {
            return (
                <FormContainer>
                    Vous serez redirigé sous peu ...
                </FormContainer>
            )
        }

        if(loading) {
            return (
                <FormContainer>
                    <SpinningDots/>
                </FormContainer>
            )
        }

        return (
            <FormContainer>
                <img
                    id="logoCNDAo"
                    src={logoCNDA}
                    alt="logoCNDA"
                    style={{height: '150px'}}
                />
                <form onSubmit={this.handleSubmit} className="Registration-Form">
                    <div style={{marginBottom: '20px'}}>
                        <p>Le processus d’adhésion à la CNDA se déroule en cinq étapes :</p>
                        <ol>
                            <li>Volet Administratif</li>
                            <li>Volet Technique</li>
                            <li>Volet Visite des lieux</li>
                            <li>Volet Décision du Conseil d’Administration de la CNDA</li>
                            <li>Volet Paiement des cotisations annuelles</li>
                        </ol>
                        <p>En remplissant le formulaire suivant, vous allez démarrer le processus d’adhésion.</p>
                    </div>
                    <div className="Registration-from-title">Processus d’adhésion - Volet administratif</div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div id="contact" className="section-title">Contact</div>

                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('civility', 'civilities', false, false)}
                        </div>
                        {!!validationErrors['civility'] && <ValidationUI t={t} error={validationErrors['civility']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-firstname"
                            path="firstname"
                            type="text"
                            value={firstname}
                            onChange={event => this.handleChange("firstname", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['firstname'] && <ValidationUI t={t} error={validationErrors['firstname']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-lastname"
                            path="lastname"
                            type="text"
                            value={lastname}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("lastname", uppercased)
                            }}
                            t={t}
                        />
                        {!!validationErrors['lastname'] && <ValidationUI t={t} error={validationErrors['lastname']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-mail"
                            path="Courriel"
                            type="text"
                            value={mail}
                            onChange={event => this.handleChange("mail", event.target.value.toLowerCase())}
                            t={t}
                        />
                        {!!validationErrors['mail'] && <ValidationUI t={t} error={validationErrors['mail']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Téléphone</div>
                        <PhoneInput
                            country="fr"
                            localization="fr"
                            value={phone}
                            onChange={value => this.handleChange("phone", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        {!!validationErrors['phone'] && <ValidationUI t={t} error={validationErrors['phone']}/>}
                        <br/>
                        <CheckBoxField
                            label={"Je suis Président de l’association"}
                            value={organizationPresident}
                            onChange={value => this.handleChange("organizationPresident", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <div id="organization" className="section-title">Association</div>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Raison sociale"
                            type="text"
                            value={organizationName}
                            onChange={event => this.handleChange("organizationName", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['organizationName'] &&
                            <ValidationUI t={t} error={validationErrors['organizationName']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="address"
                            type="text"
                            value={organizationAddress}
                            onChange={event => this.handleChange("organizationAddress", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['organizationAddress'] &&
                            <ValidationUI t={t} error={validationErrors['organizationAddress']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="additionalAddress"
                            type="text"
                            value={organizationAdditionalAddress}
                            onChange={event => this.handleChange("organizationAdditionalAddress", event.target.value)}
                            t={t}
                        />
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="zipCode"
                            type="text"
                            value={organizationZipCode}
                            onChange={event => this.handleChange("organizationZipCode", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['organizationZipCode'] &&
                            <ValidationUI t={t} error={validationErrors['organizationZipCode']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="city"
                            type="text"
                            value={organizationCity}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("organizationCity", uppercased)
                            }}
                            t={t}
                        />
                        {!!validationErrors['organizationCity'] &&
                            <ValidationUI t={t} error={validationErrors['organizationCity']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="N° Registre National des Associations"
                            type="text"
                            value={rna}
                            onChange={event => this.handleChange("rna", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['rna'] && <ValidationUI t={t} error={validationErrors['rna']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Nombre de refuges"
                            type="number"
                            value={sheltersNumber}
                            onChange={event => this.handleChange("sheltersNumber", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['sheltersNumber'] &&
                            <ValidationUI t={t} error={validationErrors['sheltersNumber']}/>}
                        <br/>
                        <CheckBoxField
                            label={"Notre association est reconnue d’intérêt général"}
                            value={generalInterestRecognition}
                            onChange={value => this.handleChange("generalInterestRecognition", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"Notre association est reconnue d’utilité publique"}
                            value={publicUtilityRecognition}
                            onChange={value => this.handleChange("publicUtilityRecognition", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"Notre association dispose d’un fonds de dotations"}
                            value={hasEndowmentFund}
                            onChange={value => this.handleChange("hasEndowmentFund", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"Notre association dispose d’un document unique des risques professionnels (DUERP)"}
                            value={hasDUERP}
                            onChange={value => this.handleChange("hasDUERP", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Date de mise à jour DUERP</div>
                        <DatePicker
                            open={DUERPUpdateDateOpen}
                            onOpenChange={() => this.handleOnOpenChange("DUERPUpdateDateOpen")}
                            value={DUERPUpdateDate}
                            onChange={value => this.handleChange("DUERPUpdateDate", value)}
                            placeholder="Choisissez une date"
                            t={t}
                        />
                        <br/>
                        <div id="documents" className="section-title">Documents</div>
                        <div style={{margin: '0px 5px 10px'}}>Pièce d’identité déposant(e)</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={contactID}
                            onChange={file => this.handleFileUpload('contactID', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['contactID'] && <ValidationUI t={t} error={validationErrors['contactID']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Avis de situation de moins de trois mois</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={situationNotice}
                            onChange={file => this.handleFileUpload('situationNotice', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['situationNotice'] && <ValidationUI t={t} error={validationErrors['situationNotice']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Status à jour</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={updatedStatus}
                            onChange={file => this.handleFileUpload('updatedStatus', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['updatedStatus'] && <ValidationUI t={t} error={validationErrors['updatedStatus']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Bilans et comptes de résultats des trois derniers exercices</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={balanceSheetsAndIncomeStatements}
                            onChange={file => this.handleFileUpload('balanceSheetsAndIncomeStatements', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['balanceSheetsAndIncomeStatements'] && <ValidationUI t={t} error={validationErrors['balanceSheetsAndIncomeStatements']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>
                            Déclaration officielle en Préfecture (au Tribunal Administratif pour Alsace et Moselle)
                        </div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={officialStatement}
                            onChange={file => this.handleFileUpload('officialStatement', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['officialStatement'] && <ValidationUI t={t} error={validationErrors['officialStatement']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>
                            CERFA et le récépissé d’enregistrement de la dernière
                            liste des dirigeants déposée en Préfecture (au Tribunal Administratif pour Alsace et
                            Moselle)
                        </div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={CERFA}
                            onChange={file => this.handleFileUpload('CERFA', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['CERFA'] && <ValidationUI t={t} error={validationErrors['CERFA']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Deux derniers rapports de visite de la DDP pour les refuges (Si Applicable)</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={DDPVisitReports}
                            onChange={file => this.handleFileUpload('DDPVisitReports', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['DDPVisitReports'] && <ValidationUI t={t} error={validationErrors['DDPVisitReports']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Photos de nos refuges (Si Applicable)</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={sheltersPhotos}
                            onChange={file => this.handleFileUpload('sheltersPhotos', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['sheltersPhotos'] && <ValidationUI t={t} error={validationErrors['sheltersPhotos']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Convocation et le procès-verbal de la dernière Assemblée Générale</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={lastGeneralMeeting}
                            onChange={file => this.handleFileUpload('lastGeneralMeeting', file)}
                            t={t}
                            placeholder={'Clickez ici ou glissez un fichier'}
                            editable={true}
                        />
                        {!!validationErrors['lastGeneralMeeting'] && <ValidationUI t={t} error={validationErrors['lastGeneralMeeting']}/>}
                        <br/>
                        <br/>
                        <button type="submit" className="btn Registration-mainButton remove-button-outline" value="submit">
                            JE DÉMARRE LE PROCESSUS D’ADHÉSION
                        </button>
                    </div>
                </form>
            </FormContainer>
        )
    }
}

Registration.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Registration

