export const entity = {
    name: 'Reason',
    fields:[
        {path: "code", unique: true},
        {path: "name", unique: true}
    ]
}

export const module_ = {
    object: 'Reason',
    tKey: 'mTitle_reason',
    removable: false,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'code', width: 100},
            'name'
        ],
        form: [
            { path: 'code', required: true, editable: false},
            { path: 'name', required: true},
        ]
    }
}
