import React, {Component} from 'react';
import _ from 'lodash'
import * as d3 from 'd3'
import { select } from 'd3-selection';

function chunkString(str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
}


const defaultColors = ['#de8134', '#f5aa80']
const margin = { top: 40, right: 20, bottom: 40, left: 20 }


class MultipleBarChart extends Component {
    constructor(props) {
        super(props);
        this.transformLegend = this.transformLegend.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    transformLegend(d, i) {
        const {w, t, dataKeys , defaultKeys} = this.props
        const keys = dataKeys || defaultKeys

        let line = 0, previousDataLength = -10, j = 0

        while(j < i){

            previousDataLength += (t(keys[j]).length * 5) + 25
            const currentLength = previousDataLength + (t(keys[j+1]).length * 5) + 25
            if(w - margin.right - 10 < currentLength ) {
                previousDataLength = -10
                line++
            }
            j++
        }
        return `translate(${-previousDataLength},${-20 + (15*line)})`
    }

    render() {
        const {data = [], h ,w, className, dataKeys , defaultKeys, colors = defaultColors, t} = this.props

        const keys = dataKeys || defaultKeys
        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom

        const x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.2);


        const x1 = d3.scaleBand()
            .padding(0.05);

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const colorByKey = keys.reduce((acc, key, index) => ({
            ...acc,
            [key]: colors[index]
        }), {})

        const xAxis = d3.axisBottom()
            .scale(x0)

        const container = select(`.${className}`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)


        x0.domain(data.map(d => d.axis))

        x1.domain(keys).rangeRound([x0.bandwidth(), 0])

        y.domain([0, d3.max(data, d => d3.max(keys, key =>  d[key] + d[key]/20 ) )]).nice();

        const bars = container
            .select(".bars")
            .selectAll("g")
            .data(data)

        bars
            .attr("transform", d => "translate(" + x0(d.axis) +", 0)")
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .attr("x", function(d) { return x1(d.key); })
            .attr("width", x1.bandwidth())
            .attr("y", function(d) { return y(d.value)})
            .attr("height", function(d) { return height - y(d.value) })
            .attr("fill", function(d) { return colorByKey[d.key] })

        bars
            .enter().append("g")
            .attr("transform", function(d) { return "translate(" + x0(d.axis) +", 0)"; })
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .enter().append("rect")
            .attr("x", function(d) { return x1(d.key); })
            .attr("width", x1.bandwidth())
            .attr("y", height)
            .attr("height", 0)
            .transition()
            .duration(1000)
            .attr("y", function(d) { return y(d.value)})
            .attr("height", function(d) { return height - y(d.value)})
            .attr("fill", function(d) { return colorByKey[d.key] })


        const barValues = keys.map(key => data.map( d => ({axis: d.axis, key, value: d[key]}) ))

        const values = container
            .select(".values")
            .selectAll("text")
            .data(_.flatten(barValues))

        values.exit()
            .remove()

        values.enter().append("text")
            .attr("x", function(d) { return x0(d.axis) + x1(d.key) + x1.bandwidth()/2 })
            .attr("y", height)
            .transition()
            .duration(1000)
            .attr("y", function(d) {
                return y(d.value) - 5
            })
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "10px")
            .text(d => parseFloat(d.value));

        values
            .attr("x", function(d) { return x0(d.axis) + x1(d.key) + x1.bandwidth()/2 })
            .attr("y", function(d) {
                return y(d.value) - 5
            })
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "10px")
            .text(d => parseFloat(d.value))


        container.select('.axis--x')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0, ${height})`)
            .call(xAxis)
            .selectAll("text")
            //.attr("transform", "rotate(-55)")
            .attr("dy",".35em")
            .style("text-anchor", "middle")
            .call(function(k) {
                k.each(function(d){ // for each one
                    const self = d3.select(this);
                    const text = t(self.text())
                    const s = chunkString(text, 14)
                    if(s.length !== 1){
                        self.text(''); // clear it out
                        for(let i=0; i< s.length; i++){
                            self.append("tspan") // insert tspan for every bit
                                .attr("y", 10 + i*12)
                                .attr("x", -9)
                                .attr("dy",".35em")
                                .text(s[i])
                                //.style("text-anchor", "end")
                        }
                    }

                })
            })

        const legend = container
            .selectAll(".legend")
            .data(keys)

        legend
            .attr("transform", this.transformLegend)
        legend
            .select("rect")
            .attr("x", width - 25)
            .attr("y", -8)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key]})

        legend
            .select("text")
            .attr("x", width - 30)
            .style("text-anchor", "end")
            .style("font-size", "10px")
            .text(function(key) {
                console.log('tkey', key, t(key))
                return t(key)
            });

        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", this.transformLegend)

        enterSelection
            .append("rect")
            .attr("x", width - 25)
            .attr("y", -8)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return colorByKey[key]})

        enterSelection
            .append("text")
            .attr("x", width - 30)
            .style("text-anchor", "end")
            .style("font-size", "10px")
            .text(function(key) {
                return t(key)
            });

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars" />
                        <g className="values" />
                        <g className="axis axis--x"/>
                    </g>
                    <g className="legendContainer"/>
                </svg>
            </div>
        )
    }
}

export default MultipleBarChart;
