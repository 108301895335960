import moment from 'moment'

async function findData(context, callback) {

    const yesterdayISO = moment().subtract(1, "days").format("YYYY-MM-DD");
    const dateQuery = {
        date: yesterdayISO
    };

    const match = {
        $match: {...dateQuery}
    }

    const changesPromise = global.db.collection('u.interfacesChanges').aggregate([
        match,
        {
            $group: {
                _id: {interface: '$interface', nature: '$nature'},
                interface: {$first: '$interface'},
                nature: {$first: '$nature'},
                number: {$sum: 1}
            }
        }
    ]).toArray()

    const [changes] = await Promise.all([changesPromise])

    return [
        {
            id: "importAnalysisGraph",
            subTitle: {
                text: `référentiels du ${yesterdayISO}`,
                font: '11px',
                color: '#888383'
            },
            data: changes.map(o => ({
                key: `${o.interface};${o.nature}`,
                number: o.number
            }))
        }
        ]
}
export const entity = {
    name: 'ImportAnalysisGraph',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
