const _ = require("lodash");
const async = require("async");

const positiveAndSequentialArray = arr => arr[0] === 1 && arr[arr.length-1] === arr.length;

export default function(workflowGenerator) {

    const profileConfigEntityName = workflowGenerator.profileConfigEntity.name;
    const profileConfigEntityPath = _.lowerFirst(profileConfigEntityName);

    const entityFields = [
        ...workflowGenerator.meshFields.map(meshField => ({type: meshField.entity, unique: true})),
        {
            type: "ProfileConfigByEntity",
            fieldPath: [`profileConfigs.${profileConfigEntityPath}.id`],

            $f: function (workflowConfig, context, callback) {
                // Getter : ProfileConfig -> ProfileConfigByEntity

                global.app[workflowGenerator.modelId][profileConfigEntityName].find(
                    {
                        group: context.group,
                        filters: workflowGenerator.profileConfigEntityFilters
                    },
                    (e, profileConfigObjects) => {
                        if (e) return callback(e);

                        callback(
                            null,
                            workflowGenerator.utils.groupProfileConfigByEntity(profileConfigObjects, workflowConfig.profileConfigs)
                        );
                    }
                );
            },

            $s: function(profileConfigByEntitys, workflowConfig, context, callback) {
                // Setter : ProfileConfigByEntity -> ProfileConfig

                workflowConfig.profileConfigs = _(profileConfigByEntitys)
                    .map(profileConfigByEntity => _(workflowGenerator.profiles)
                        .filter(profile => profileConfigByEntity[profile.name + "_active"])
                        .map(profile => ({
                            profile: profile.name,
                            [profileConfigEntityPath]: profileConfigByEntity[profileConfigEntityPath],
                            order: parseInt(profileConfigByEntity[profile.name + "_order"], 10)
                        })).value()
                    )
                    .flatten()
                    .compact()
                    .value();
                callback();
            }
        },
        {type: "ProfileConfig", link: "OTM"},
        ...workflowGenerator.profiles.map(
            profile => ({
                path: `${profile.name}${profileConfigEntityName}s`,
                fieldPath: ["profileConfigs.profile", "profileConfigs.order", `profileConfigs.${profileConfigEntityPath}.name`],
                f: function() {
                    const profileConfigs = _.filter(this.profileConfigs, {profile: profile.name});
                    return _(profileConfigs)
                        .filter({profile: profile.name})
                        .map("order")
                        .uniq()
                        .orderBy()
                        .map(
                            order => _(profileConfigs)
                                .filter({order})
                                .map(o => o[profileConfigEntityPath].name)
                                .value()
                                .join(", ")
                        )
                        .value()
                        .join(" → ");
                }
            })
        )
    ];

    const extraEntities = model => [
        {
            name: "ProfileConfigByEntity",
            type: "mongoInternal",
            fields: [
                {type: profileConfigEntityName},
                ...workflowGenerator.profiles.map(profile => ({
                    path: profile.name + "_active",
                    type: "boolean"
                })),
                ...workflowGenerator.profiles.map(profile => ({
                    path: profile.name + "_order",
                    type: "integer"
                }))
            ],
            model
        },
        {
            name: "ProfileConfig",
            type: "mongoInternal",
            fields: [
                {type: profileConfigEntityName},
                {path: "profile"},
                {path: "order", type: "integer", nullable: true}
            ],
            model
        }
    ];

    return {norm: {fields: [
        {path: "fields", default: [], $p: {
            $f: function(fields, entity, entities, model) {
                fields.push(..._.cloneDeep(entityFields));

                //TODO avoid duplication of entities
                entities.push(...extraEntities(model));
            }
        }},
        {path: "validateSave", default: function(object, oldObject, context, callback) {
            async.series([
                callback => {
                    const missingMandatoryProfiles = _(workflowGenerator.profiles)
                        .filter({optional: false})
                        .map(profile => ({
                            profile,
                            found: _.find(object.profileConfigByEntitys, {
                                [profile.name + "_active"]: true
                            })
                        }))
                        .filter(o => !o.found)
                        .map(o => {
                            const profileName = context.tc(o.profile.tKey);
                            return new Error(context.tc("profileIsMandatory", {profileName}));
                        })
                        .value();

                    callback(missingMandatoryProfiles[0]);
                },
                callback => {
                    const invalidOrderProfiles = _(workflowGenerator.steps)
                        .filter("multiOrder")
                        .map("activeProfile")
                        .map(
                            profile => {
                                const orders = _(object.profileConfigByEntitys)
                                    .filter({
                                        [profile.name + "_active"]: true
                                    })
                                    .map(profile.name + "_order")
                                    .uniq()
                                    .orderBy()
                                    .value();

                                if (!positiveAndSequentialArray(orders)) {
                                    const profileName = context.tc(profile.tKey);
                                    return new Error(context.tc("profileOrderSequential", {profileName}));
                                } else return null;
                            }
                        )
                        .flatten()
                        .value();

                    callback(invalidOrderProfiles[0]);
                }
            ], callback);

        }}
    ]}};
};
