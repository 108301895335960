export const HypothesisDataUpdateCommentsEntity = {
    name: 'HypothesisDataUpdateComments',
    fields: [
        {
            type: 'ProjectConfiguration',
            link: { oppositeField: { link: { deleteType: 'cascade' } } }
        },
        'ProjectContextM1M2',
        'HypothesisModel',
        'User',
        { path: 'date', type: 'date' },
        'text'
    ]
}

export const DetailAnalysisCommentsEntity = {
    name: 'DetailAnalysisComments',
    fields: [
        {
            type: 'ProjectConfiguration',
            link: { oppositeField: { link: { deleteType: 'cascade' } } }
        },
        'ProjectContextM1M2',
        'StateModel',
        'User',
        { path: 'date', type: 'date' },
        'text'
    ]
}
