export const entities = [
    {
        name: 'IndicatorKey',
        fields:[
            'IndicatorKeyList',
            'objectif'
        ],
    },
    {
        name: 'IndicatorKeyList',
        facets: ['codeName']
    }
]


export const module_ = {
    object: 'IndicatorKeyList',
    tKey: 'Objective',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'code',
            'name'
        ],
        form: [
            'code',
            'name'
        ]
    }
}