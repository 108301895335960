const _ = require('lodash')

export const entities = [
    {
        name: 'Unit',
        facets: ['codeName'],
    },

    {
        name: 'Company',
        facets: ['codeName'],
        fields: [
            { type: 'Unit', nullable: true },
            'description',
            { path: 'status', type: 'CompanyStatus' }
        ]
    },

    {
        name: 'Contact',
        facets: ['firstLastName', 'comments'],
        fields: [
            { type: 'Company', nullable: true },
            'Civility',
            'email',
            'phone',
            'position',
            'profession',
            'linkedin',
            'description',
            { path: 'status', type: 'ContactStatus' }
        ],
        filters: [
            {
                name: 'byCompany',
                isDefault: false,
                query: function(context) {
                    const companyId = _.get(context.data, 'company.id')
                    if (companyId)
                        return { company: global.ObjectID(companyId)}
                    return {}
                }
            }
        ],
    },
    /*
            {
                name: 'Project',
                facets: ['codeName', 'comments'],
                fields: [
                    { type: 'Company', nullable: true },
                    'description',
                    { path: 'status', type: 'ProjectStatus' }
                ],
                filters: [
                    {
                        name: 'onlyOngoingProspectiveProject',
                        isDefault: false,
                        query: function() {
                            return { status: {$in: ['ongoing', 'prospective']} }
                        }
                    }
                ],
            },
     */

    {
        name: 'ActionType',
        facets: ['codeName'],
    },

    {
        name: 'Subject',
        facets: ['codeName'],
        fields: [
            { path: 'actionType', type: 'ActionType', link: 'MTM' },
        ],
    },

]
