const _ = require('lodash')
const moment = require('moment')
const { textSearchQuery } = require("../../../utils/mongoUtils")
const {generateFetchFieldListAction} = require("../../../../apps/KpModule/actions/api")
const { setDataList } = require('../../../../apps/KpModule/actions')

export const entities = [
    {
        name: 'DataYear',
        type: 'static',
        fields: [
            { path: 'id', type: 'integer' },
            {
                path: 'name',
                f: function() {
                    return `${this.id}`
                }
            }
        ],
        objects: _.range(
            1995,
            moment().year() + 15
        ).map(year => ({ id: year }))
    },
    {
        name: 'DataTypeImport',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'code', 'name'],
        objects: [
            { id: '1', code: 'R', name: 'reporting'},
            { id: '2', code: 'C', name: 'correction'}
        ]
    },
    {
        name: 'DataSource',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'code', 'name'],
        objects: [
            { id: '1', code: 'H', name: 'HI'},
            { id: '2', code: 'M', name: 'Magnitude'},
            { id: '3', code: 'HI-DATA' , name: 'HI-DATA'}
        ]
    },
    {
        name: 'MeshDataType',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'code', 'name'],
        objects: [
            { id: '1', code: 'F', name: 'subsidiary'},
            { id: '3', code: 'P', name: 'country'},
            { id: '4', code: 'M', name: 'store'},
            { id: '5', code: 'B', name: 'BP'}
        ]
    },
    {
        name: 'Data',
        fields: [
            'mysqlId',
            { type: 'Country', nullable: true },
            { type: 'Subsidiary', nullable: true },
            { type: 'Shop', nullable: true, link: { oppositeField: { link: { deleteType: 'cascade' } } } },
            { type: 'BPEndorsed', path: 'bp', nullable: true, link: { oppositeField: { link: { deleteType: 'cascade' } } } },
            { path: 'year',type: 'integer'},
            { type: 'DataTypeImport', path: 'type'},
            { type: 'MeshDataType', path: 'mesh'},
            { type: 'DataSource', path: 'source'},
            'exercise',
            'deadline',
            'name',
            { path: 'value',type: 'integer'}
        ],
        indexes: [
            { key: { mesh: 1 } },
            { key: { mesh: 1, shop: 1 } },
            { key: { mesh: 1, subsidiary: 1 } },
            { key: { mesh: 1, country: 1 } },
            { key: { mesh: 1, bp: 1 } }
        ]
    }
]

export const modules = [
    {
        name: 'Data',
        object: 'Data',
        tKey: 'mTitle_data',
        newable: false,
        defaultSortBy: 'year',
        defaultSortDirection: 'ASC',
        category: {
            path: 'empiric',
            icon: 'briefcase'
        },
        viewMap: {
            dt: [
                'country', 'subsidiary', 'shop', {path: 'bp', display: 'code'}, 'name', 'type', 'mesh', 'year', 'value', 'exercise', 'deadline', 'source'
            ]
        },
        filters: [
            {
                title: 'DataYear',
                path: 'dataYear',
                object: 'DataYear',
                display: 'name',
                client: true,
                filters:[
                    {
                        title: "byName",
                        path: "byName",
                        tKey: "byName",
                        type: "text",
                        match: (year, context) => {
                            const params = context.clientContext.params
                            const search = params && params.search

                            const regExp = new RegExp(search, "i");



                            return regExp.test(year.name)
                        }
                    }
                ],
                type: 'asyncDropdownObject',
                placeholder: 'enterAYear',
                query: function(context) {
                    const year = _.get(context.data, 'dataYear.id')
                    if (year) {
                        return { year: Number.parseInt(year, 10) }
                    } else {
                        return {}
                    }
                }
            },
            {
                title: 'MeshDataType',
                path: 'meshDataType',
                object: 'MeshDataType',
                clearable: false,
                display: 'name',
                client: true,
                autoFetch: false,
                default: {id: null},
                placeholder: 'selectAMesh',
                query: function(context) {
                    const mesh = _.get(
                        context.data,
                        'meshDataType.id'
                    )
                    return { mesh }
                }
            },
            {
                title: 'Country',
                path: 'country',
                object: 'Country',
                display: 'fullName',
                client: true,
                placeholder: 'Sélectionner un pays',
                query: function(context) {
                    const countryId = _.get(context.data, 'country.id')
                    if (countryId) {
                        return {
                            mesh: '3',
                            country: new global.ObjectID(countryId)
                        }
                    } else {
                        return {}
                    }
                }
            },
            {
                title: 'Subsidiary',
                path: 'subsidiary',
                object: 'Subsidiary',
                display: 'fullName',
                client: true,
                placeholder: 'Sélectionner une filiale',
                query: function(context) {
                    const subsidiaryId = _.get(context.data, 'subsidiary.id')
                    if (subsidiaryId) {
                        return {
                            mesh: '1',
                            subsidiary: new global.ObjectID(subsidiaryId)
                        }
                    } else {
                        return {}
                    }
                }
            },
            {
                title: 'Shop',
                path: 'shop',
                object: 'Shop',
                display: 'fullName',
                client: true,
                placeholder: 'Sélectionner un magasin',
                query: function(context) {
                    const shopId = _.get(context.data, 'shop.id')
                    if (shopId) {
                        return {
                            mesh: '4',
                            shop: new global.ObjectID(shopId)
                        }
                    } else {
                        return {}
                    }
                }
            },
            {
                title: 'BPEndorsed',
                path: 'bPEndorsed',
                object: 'BPEndorsed',
                display: 'code',
                client: true,
                placeholder: 'Sélectionner un BP',
                query: function(context) {
                    const bPEndorsedId = _.get(context.data, 'bPEndorsed.id')
                    if (bPEndorsedId) {
                        return {
                            mesh: '5',
                            bp: new global.ObjectID(bPEndorsedId)
                        }
                    } else {
                        return {}
                    }
                }
            }
        ],
        stateSubscriptions: [{
            statePath: 'form.filterObject.values.meshDataType',
            subscription: (newValue, previousValue, { store }) => {
                const typeId = newValue.id
                let entity = 'Country'
                switch (typeId) {
                    case '3':
                        entity = 'Country'
                        break
                    case '1':
                        entity = 'Subsidiary'
                        break
                    case '4':
                        entity = 'Shop'
                        break
                    case '5':
                        entity = 'BPEndorsed'
                        break
                    default:
                        entity = 'Country'
                        break
                }

                store.dispatch(setDataList('m-B-data_filter_Country', []))
                store.dispatch(setDataList('m-B-data_filter_Subsidiary', []))
                store.dispatch(setDataList('m-B-data_filter_Shop', []))
                store.dispatch(setDataList('m-B-data_filter_BPEndorsed', []))

                store.dispatch(
                    generateFetchFieldListAction(
                        'm-B-data_filter_' + entity,
                        store.getState,
                        'form',
                        {}
                    )
                )
            }
        }]
    }
]
