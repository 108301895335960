const _ = require("lodash")
const moment = require("moment")
const { workflowParameters } = require("../workflow/workflow");

const projectSteps = _.map(workflowParameters.steps, "name")

const compute = async (axis1, axis2, threshold, indicator, context) => {

    if(!indicator.id) return []

    const groupBpsByAxis = (bps, axisId) => _.groupBy(bps, axisId
        ? global.app.I.BrAndCommercialGroupAxis.byId(axisId).groupper(context.tc)
        : ''
    )

    const groupBpsByThreshold = (bps, indicator, threshold) =>  {
        const getBR = bp => ({
            ...bp.lastBusinessRevue,
            number: 1
        })
        return _.groupBy(bps, bp => getBR(bp)[indicator.id] >= threshold)
    }

    const businessProjectCollection = global.app.I.BusinessProject.collection
    const businessRevueCollection = global.app.I.BusinessRevue.collectionName
    const countryCollectionName = global.app.I.Country.collectionName
    const zoneCollectionName = global.app.I.Zone.collectionName
    const typeOfOfferCollectionName = global.app.I.TypeOfOffer.collectionName
    const rangeOfOfferCollectionName = global.app.I.RangeOfOffer.collectionName

    const currentYear = moment().year()

    const fetchPromise = businessProjectCollection
        .aggregate([
            {
                $lookup: {
                    from: businessRevueCollection,
                    let: { bp: "$_id", signatureDate: '$signatureDate', step: '$workflow.step' },
                    pipeline: [
                        { $match: { $expr: { $eq: ['$businessProject', '$$bp'] }}},
                        { $sort: { date: 1 }},
                        { $project: {
                                date: 1,
                                year: { $year: "$date" },
                                executedTurnOver: 1,
                                yearN: 1,
                                yearNPlusOne: 1,
                                probability: 1,
                                yearP: { $multiply: [ "$yearN", "$probability", 1/100 ] },
                            }}
                    ],
                    as: 'businessRevues'
                }
            },
            {
                $lookup: {
                    from: countryCollectionName,
                    localField: 'country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            {
                $lookup: {
                    from: typeOfOfferCollectionName,
                    localField: 'typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: zoneCollectionName,
                    localField: 'country.zone',
                    foreignField: '_id',
                    as: 'zone'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $project: {
                    lastBusinessRevue: {$arrayElemAt: ['$businessRevues', -1]},
                    country: {$arrayElemAt: ['$country', 0]},
                    zone: {$arrayElemAt: ['$zone', 0]},
                    typeOfOffer: {$arrayElemAt: ['$typeOfOffer', 0]},
                    rangeOfOffer: {$arrayElemAt: ['$rangeOfOffer', 0]},
                    code: 1,
                    name: 1,
                    responseMode: 1,
                    projectFunding: 1,
                    interventionMode: 1,
                    contractualSchema: 1,
                    compensationModes: 1,
                    signatureYear: { $year: "$signatureDate" },
                    creationYear: { $year: "$creationDate" },
                    offerSubmissionYear: { $year: "$offerSubmissionDate" },
                    step: '$workflow.step'
                }
            },
            {
                $match: {'lastBusinessRevue.year': currentYear}
            }

        ])
        .toArray()

    const businessProjects = await fetchPromise

    const sumStepValues = (brs, indicator) => brs.reduce(
        (acc, br) => {
            return {
                ...acc,
                [br.step]: acc[br.step] + br[indicator]
            }
        },
        projectSteps.reduce((acc, step) => ({...acc, [step]: 0}) , {})
    )

    const sumValues = sumStepValues(businessProjects.map(bp => ({...bp.lastBusinessRevue, step: bp.step, number: 1})), indicator.id)

    const totalLine = [
        {
            id: new global.ObjectID().toString(),
            element1: 'Total',
            element2: '---',
            indicator: indicator.name,
            ...projectSteps.reduce((acc, step) => ({...acc, [step]: _.round(sumValues[step])}), {})
        },
        {
            id: new global.ObjectID().toString(),
            element1: 'Total',
            element2: '---',
            indicator: "Projet (Nb)",
            ...sumStepValues(businessProjects.map(bp => ({...bp.lastBusinessRevue, step: bp.step, number: 1})), 'number')
        }
    ]

    return [
        ..._.flatMap(groupBpsByThreshold(businessProjects, indicator, threshold), (groupBps, element0) =>
            _.flatMap(groupBpsByAxis(groupBps, axis1), (groupBps, element1) =>
                _.flatMap(groupBpsByAxis(groupBps, axis2), (groupBps, element2) => {

                    const sumValues = sumStepValues(groupBps.map(bp => ({...bp.lastBusinessRevue, step: bp.step, number: 1})), indicator.id)
                    return [
                        {
                            id: new global.ObjectID().toString(),
                            element0: element0 === "true" ? `>= ${threshold} (k€)` : `< ${threshold} (k€)`,
                            element1: element1 === 'undefined' ? '---': element1,
                            element2: element2 === 'undefined' ? '---': element2,
                            indicator: indicator.name,
                            ...projectSteps.reduce((acc, step) => ({...acc, [step]: _.round(sumValues[step])}), {})
                        },
                        {
                            id: new global.ObjectID().toString(),
                            element0: element0 === "true" ? `>= ${threshold} (k€)` : `< ${threshold} (k€)`,
                            element1: element1 === 'undefined' ? '---': element1,
                            element2: element2 === 'undefined' ? '---': element2,
                            indicator: "Projet (Nb)",
                            ...sumStepValues(groupBps.map(bp => ({...bp.lastBusinessRevue, step: bp.step, number: 1})), 'number')
                        }
                    ]
                }))),
        ...totalLine
    ]
}

export const entities = [
    {
        name: "BrIndicator",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "executedTurnOver", name: `CA Réalisé (k€) - ${moment().year()}`},
            {id: "yearN", name: `CA Prévu (k€) - ${moment().year()}`},
            {id: "yearP", name: `CA PrévuP (k€) - ${moment().year()}`},
            {id: "yearNPlusOne", name: `CA Prévu (k€) - ${moment().year() + 1}`}
        ]
    },
    {
        name: "BrAndCommercial",
        fields: [
            "element0",
            "element1",
            "element2",
            "indicator",
            ...projectSteps
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else {
                    const axis1 = _.get(context, "data.axis1.id")
                    const axis2 = _.get(context, "data.axis2.id")
                    const threshold = _.parseInt(_.get(context, "data.threshold") || 0)
                    const indicator = _.get(context, "data.indicator")
                    compute(axis1, axis2, threshold, indicator, context)
                        .then(objects => callback(null, objects))
                        .catch(e => callback(e))
                }
            })
        }
    }
]

export const module_ = {
    object: "BrAndCommercial",
    tKey: "mTitle_brAndCommercial",
    category: {
        path: "analysis",
        icon: 'sliders'
    },
    removable: false,
    sortable: false,
    chart: {
        keys: projectSteps,
        type: "dynamicBarChart",
        title: `BR & Commercial (k€) - ${moment().year()}`
    },
    viewMap: {
        dt: [
            { path: "element1", tKey: "Axe principal", width: 500, translate: true},
            { path: "element2", tKey: "Axe secondaire", width: 500, translate: true},
            { path: "indicator", tKey:"indicator", width: 300},
            { path: "element0", tKey: "Seuil", width: 200},
            { path: 'formX' },
            { path: 'buildOffer' },
            { path: 'submitOffer' },
            { path: 'realisation' },
            { path: 'finished' },
            { path: 'acceptedAmi', initiallyNotVisible: true },
            { path: 'noGo', initiallyNotVisible: true },
            { path: 'notSubmitted', initiallyNotVisible: true },
            { path: 'lost', initiallyNotVisible: true },
            { path: 'abandoned', initiallyNotVisible: true }
        ],
        chart: ["element1", "element2", "element0", "indicator", ...projectSteps]
    },
    filters: [
        {
            object: "BrAndCommercialGroupAxis",
            title: "Axe principal",
            placeholder: "Sélectionner l'axe principal",
            path: "axis1",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "BrAndCommercialGroupAxis",
            title: "Axe secondaire",
            placeholder: "Sélectionner l'axe secondaire",
            path: "axis2",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "BrIndicator",
            path: "indicator",
            placeholder: "Sélectionner l'indicateur",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            path: "threshold",
            placeholder: "Saisir le seuil",
            type: "number",
            client: true,
            default: 0
        }
    ]
}
