import React from 'react'
import PropTypes from 'prop-types'
import MonthPicker from './MonthPicker'
import './DateRangePicker.css'
import moment from 'moment'
import _ from 'lodash'

const DATE_FORMAT = 'YYYY-MM'

function dateComparator(referenceDateString, compare) {
    if (
        !referenceDateString ||
        !_.isString(referenceDateString) ||
        referenceDateString === ''
    )
        return

    const referenceDate =
        referenceDateString &&
        moment(referenceDateString, DATE_FORMAT).startOf('month')

    return function compareDate(date) {
        return date
            ? compare(moment(referenceDate).diff(date.startOf('month'), 'months'))
            : false
    }
}

const MonthPickerRange = ({
  path,
  startDate,
  endDate,
  startOpened,
  endOpened,
  onChange,
  onOpenChange,
  language,
  startPlaceholder,
  endPlaceholder,
  error,
  touched,
  t
}) => {
   return (
       <div className="DateRangePicker">
           <MonthPicker
               path={`${t(path)}: ${t('start')}`}
               value={startDate}
               open={startOpened}
               onOpenChange={() => onOpenChange('startOpened', !startOpened)}
               onChange={value => {
                   onChange(0, value)
                   onOpenChange('endOpened', !endOpened)
               }}
               placeholder={startPlaceholder}
               language={language}
               error={error}
               touched={touched}
               t={t}
           />
           <MonthPicker
               path={`${t(path)}: ${t('end')}`}
               disabledDate={dateComparator(startDate, daysDiff => daysDiff > 0)}
               value={endDate}
               open={endOpened}
               onOpenChange={() => onOpenChange('endOpened', !endOpened)}
               onChange={value => onChange(1, value)}
               placeholder={endPlaceholder}
               language={language}
               error={error}
               touched={touched}
               t={t}
           />
       </div>
   );
};

MonthPickerRange.propTypes = {
  startOpened: PropTypes.bool.isRequired,
  endOpened: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string
};

export default MonthPickerRange;
