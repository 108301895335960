const { fieldPathJoinGetter } = require('../../../utils/kp3Utils');

export const entity = {
    name: 'Organization',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields:[
        'OrganizationAxis',
        {
            path: 'axisCode',
            fieldPath: ['organizationAxis.id', 'organizationAxis.code'],
            f: function () {
                return this.organizationAxis && this.organizationAxis.code
            }
        },
        fieldPathJoinGetter({
            path: 'fullNameWithAxisCode',
            fieldPath: ['axisCode', 'fullName'],
            joinString: ' - '
        }),

    ]
}
export const module_ = {
    object: 'Organization',
    tKey: 'mTitle_organization',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'fullName','code','name'
        ],
        form: [
            {path: 'organizationAxis', display: 'fullName', required: true},
            {path: 'code'},
            {path: 'name'}
        ]
    }
}
