import React from 'react'
import PropTypes from 'prop-types'
import './CheckboxField.css'

const CheckboxField = ({ value, onChange, label, disabled, t = x => x }) => (
    <div className="CheckboxField">
        <label style={{display: "flex"}}>
            <input
                type="checkbox"
                className="CheckboxField-input"
                checked={value}
                onChange={() => undefined}
                disabled={disabled}
            />
            <span className="cr" onClick={event => {
                if(onChange) {
                    event.stopPropagation()
                    !disabled && onChange(!value)
                }
            }}>
                        <i className="cr-icon glyphicon glyphicon-ok" onClick={event => {
                            if(!onChange) {
                                event.stopPropagation()
                            }
                        }} />
                    </span>
            {label && <div className="CheckboxField-label" onClick={event => event.stopPropagation()}> {t(label)} </div>}
        </label>
    </div>

)

CheckboxField.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default CheckboxField
