import React, {Component} from 'react'
import _ from 'lodash'
import './StackedBarChart.css'
import * as d3 from 'd3'
import { select } from 'd3-selection';

const margin = { top: 20, right: 30, bottom: 60, left: 50 }
const z = ['#8e8e8e', '#1568ad', '#0e9729', '#dab350', '#da6a2e']


class StackedBarChart extends Component {
    constructor(props) {
        super(props);

        this.drawChart = this.drawChart.bind(this)
        this.timer = setTimeout(this.drawChart, 700)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }


    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    drawAxisAndLegend() {
        const {h ,w, keys, data = [], t} = this.props

        const finalKeys = _(keys).flatMap(key => {
            if (typeof key === 'string') return key
            if (key.path && !key.dynamic) return key.path
            if (key.dynamic){
                return _(data).flatMap(object => {
                    return Object.keys(object[key.path])
                }).uniq().value()
            }
        }).uniq().value()

        const finalData = data.map(object => {
            return keys.reduce((acc, key) => {
                return key.dynamic ? {
                    ...acc,
                    ...object[key.path]
                } : {
                    ...acc,
                    [key]: object[key]
                }
            },{date: object.date})
        })

        const width = (w - 20) - margin.left - margin.right
        const height = h/2 - margin.top - margin.bottom

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const xAxis = d3.axisBottom()
            .scale(x)

        const yAxis = d3.axisLeft()
            .scale(y)
            .ticks(7)

        const layers = d3.stack()
            .keys(finalKeys)
            .value((d, key) => d[key] || 0)(finalData)

        x.domain((layers[0] || []).map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1] || [], d => d[1] )]).nice()

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const legendContainer = select('.BarChart').select('.legend')
            .attr('width', width + margin.left + margin.right)
            .attr('height', 160)
            .attr('transform', `translate(0,10)`)

        const legend = legendContainer
            .selectAll(".legend")
            .data(finalKeys)

        legend.select("rect")
            .attr("x", -50)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(d, i) {return z[i];});

        legend.select("text")
            .attr("x", -25)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function(d, i) {
                return t(finalKeys[i])
            });

        legend
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })

        enterSelection
            .append("rect")
            .attr("x", -50)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(d, i) {return z[i];});


        enterSelection
            .append("text")
            .attr("x", -25)
            .attr("y", 9)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function(d, i) {
                return t(finalKeys[i])
            });

        container.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)

        container.select('.axis--y')
            .attr('transform', `translate(0,0)`)
            .call(yAxis)

    }

    drawChart() {

        const {h ,w, keys, data = []} = this.props

        const finalKeys = _(keys).flatMap(key => {
            if (typeof key === 'string') return key
            if (key.path && !key.dynamic) return key.path
            if (key.dynamic){
                return _(data).flatMap(object => {
                    return Object.keys(object[key.path])
                }).uniq().value()
            }
        }).uniq().value()

        const finalData = data.map(object => {
            return keys.reduce((acc, key) => {
                return key.dynamic ? {
                    ...acc,
                    ...object[key.path]
                } : {
                    ...acc,
                    [key]: object[key]
                }
            },{date: object.date})
        })

        const width = (w - 20) - margin.left -margin.right
        const height = h/2 - margin.top - margin.bottom

        const totalData = finalData.map(object => {
            return {
                date: object.date,
                total: finalKeys.reduce((acc, key) => acc + (object[key] || 0), 0)
            }
        })

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)


        const layers = d3.stack()
            .keys(finalKeys)
            .value((d, key) => d[key] || 0)(finalData)


        x.domain((layers[0] || []).map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1] || [], d => d[1] )]).nice()

        const values = container
            .select(".values")
            .selectAll("text")
            .data(totalData)

        const layer = container.selectAll('.layer')
            .data(layers)
            .enter()
            .append('g')
            .attr('class', 'layer')
            .style('fill', (d, i) => (z[i]))


        layer.selectAll('rect')
            .data(d => d)
            .enter()
            .append('rect')
            .attr('x', d => x(d.data.date))
            .attr('width', x.bandwidth())
            .attr('height', 0)
            .attr("y", height)
            .transition()
            .duration(1000)
            .attr('height', d => Math.abs(y(d[0]) - y(d[1])))
            .attr('y', d => y(d[1]))

        values.enter().append("text")
            .attr("x", function(d) {
                return x(d.date) + x.bandwidth()/2; })
            .attr("y", height - 10)
            .transition()
            .duration(1000)
            .attr("y", function(d) {
                return y(d.total) - 10
            })
            .attr("dy", ".1em")
            .text(d => _.round(d.total, 2))
            .style("text-anchor", "middle")
            .style("font-size", "12px")

    }

    updateChart(){

        const {h ,w, keys, data = []} = this.props

        const width = (w - 20) - margin.left -margin.right
        const height = h/2 - margin.top - margin.bottom

        const finalKeys = _(keys).flatMap(key => {
            if (typeof key === 'string') return key
            if (key.path && !key.dynamic) return key.path
            if (key.dynamic){
                return _(data).flatMap(object => {
                    return Object.keys(object[key.path])
                }).uniq().value()
            }
        }).uniq().value()

        const finalData = data.map(object => {
            return keys.reduce((acc, key) => {
                return key.dynamic ? {
                    ...acc,
                    ...object[key.path]
                } : {
                    ...acc,
                    [key]: object[key]
                }
            },{date: object.date})
        })

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const layers = d3.stack()
            .keys(finalKeys)
            .value((d, key) => d[key] || 0)(finalData)

        x.domain((layers[0] || []).map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1] || [], d => d[1] )]).nice()


        container.selectAll('.layer')
            .data(layers)
            .exit()
            .remove()

        container.selectAll('.layer')
            .data(layers)
            .style('fill', (d, i) => (z[i]))
            .selectAll('rect')
            .data(d => d)
            .attr('width', x.bandwidth())
            .attr('x', d => x(d.data.date))
            .attr('height', d => Math.abs(y(d[0]) - y(d[1])))
            .attr('y', d => y(d[1]))

        container
            .select(".values")
            .exit()
            .remove()

        container
            .select(".values")
            .selectAll("text")
            .attr("x", function(d) {
                return x(d.date) + x.bandwidth()/2; })
            .attr("y", function(d) {
                return y(d.total) - 10
            })
            .text(d => _.round(d.total, 2))
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")

    }

    render() {

        this.drawAxisAndLegend()
        this.updateChart()

        return (
            <div className="BarChart">
                <svg className="svgChart" style={{marginLeft: "10px"}}>
                    <g className="container">
                        <g className="values"/>
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                    </g>
                </svg>
                <svg className="legend"/>
            </div>
        )
    }
}

export default StackedBarChart;
