const { fieldPathJoinGetter } = require('../../utils/kp3Utils')

export const entity = {
    name: 'Location',
    fields: [
        {
            type: 'Warehouse',
            unique: true,
            uniqueWith: ['aisle', 'row', 'level']
        },
        'aisle',
        'row',
        'level',
        fieldPathJoinGetter({
            path: 'fullLocation',
            fieldPath: ['warehouse.name', 'aisle', 'row', 'level'],
            joinString: '-'
        })
    ]
}

export const module_ = {
    object: 'Location',
    tKey: 'mTitle_location',
    category: {
        path: 'organisation',
        icon: 'server'
    },
    viewMap: {
        dt: [
            { path: 'warehouse', display: 'fullName' },
            'aisle',
            'row',
            'level'
        ],
        form: [
            { path: 'warehouse', display: 'fullName' },
            'aisle',
            'row',
            'level'
        ]
    }
}
