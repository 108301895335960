const moment = require('moment')
const _ = require('lodash')
const {
    calculateBPStoreAccountModels
} = require('../engines/bPStoreAccountModelEngine')
const { basicContext } = require('../../../utils/contextUtils')

const { setFieldListOptions } = require('../../../../apps/KpModule/actions')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')

async function generateAccountBPAData(shopBPEndorsed, context) {

    const accountBPAs = await global.app.B.AccountBPA.find({
        ...basicContext(context),
        fieldPath:[
            'accountModel.id',
            'accountModel.code',
            'accountModel.accountModelType.id'
        ]
    })

    const projectConfigurationId = _.get(
        shopBPEndorsed,
        'bPEndorsed.projectConfiguration.id'
    )

    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConfigurationId) }, basicContext(context)
    )

    const projectContextM1M2 = '1'

    const realOpeningDate = moment(shopBPEndorsed.realOpening)
    const endorsedOpeningDate = moment(shopBPEndorsed.endorsedOpening, 'YYYY-MM-DD')

    const dataYears = _.range(endorsedOpeningDate.year() -1, endorsedOpeningDate.year() +10)

    const resultAccountModels = await calculateBPStoreAccountModels(
        projectConfiguration,
        accountBPAs.map(o => ({
                accountModelCode: _.get(o, 'accountModel.code'),
                accountModelType: _.get(o, 'accountModel.accountModelType.id')
            })),
        projectContextM1M2,
        context,
        dataYears
    )

    const yearGap = realOpeningDate.year() - endorsedOpeningDate.year()

    const defaultObject = {
        'bp' : new global.ObjectID(_.get(shopBPEndorsed, 'bPEndorsed.id')),
        'mesh' : '5',
        'source' : '1',
        'type' : '1',
        'deadline' : 'C12',
        'group' : new global.ObjectID(context.group.id)
    }
    const yearAccountResults = accountBPAs.filter(o => _.get(o, 'accountModel.accountModelType.id') === 'year').reduce((acc, accountModelBPA) => {
        const accountModelCode = accountModelBPA.accountModel.code
        Object.keys(resultAccountModels[accountModelCode]).forEach(year => {
            acc.push({
                ...defaultObject,
                'name' : accountModelCode.replace('dprj.', 'bpi.'),
                'year' : _.toNumber(year) + yearGap,
                'exercise' : _.toNumber(year) + yearGap,
                'value' : resultAccountModels[accountModelCode][year]
            })
        })
        return acc
    }, [])

    const yearAccountBPResults = accountBPAs.filter(o => _.get(o, 'accountModel.accountModelType.id') === 'year' && o.bp).reduce((acc, accountModelBPA) => {
        const accountModelCode = accountModelBPA.accountModel.code
        Object.keys(resultAccountModels[accountModelCode]).forEach(year => {

            const isProjectConfInScope = ['N', 'S', 'N+C'].includes(projectConfiguration.bPProjectType.id)

            const isAdjustable = isProjectConfInScope
                && (_.toNumber(year) + yearGap) === realOpeningDate.year()
                && accountModelBPA.adjustable
                && realOpeningDate.year() >= endorsedOpeningDate.year()

            acc.push({
                ...defaultObject,
                'name' : accountModelCode.replace('dprj.', 'bp.'),
                'year' : _.toNumber(year) + yearGap,
                'exercise' : _.toNumber(year) + yearGap,
                'value' : isAdjustable
                    ? (resultAccountModels[accountModelCode][year] / (13 - (endorsedOpeningDate.month() +1))) * (13 - (realOpeningDate.month() +1))
                    : resultAccountModels[accountModelCode][year]
            })
        })
        return acc
    }, [])

    const valueResults = accountBPAs.filter(o => _.get(o, 'accountModel.accountModelType.id') === 'value').reduce((acc, accountModelBPA) => {
        const accountModelCode = accountModelBPA.accountModel.code

        dataYears.forEach(year => {
            acc.push({
                ...defaultObject,
                'name': accountModelCode.replace('dprj.', 'bpi.'),
                'year': year,
                'exercise': year,
                'group': new global.ObjectID(context.group.id),
                'value': resultAccountModels[accountModelCode]
            })
        })
        return acc
    }, [])

    if(valueResults.length || yearAccountResults.length || yearAccountBPResults.length) {
        await global.app.B.Data.collection.insertMany([...valueResults, ...yearAccountResults, ...yearAccountBPResults])
    }

    return shopBPEndorsed
}

export const entities = [
    {
        name: 'ShopBPEndorsed',
        facets: ['description'],
        fields: [
            'Shop',
            { path: 'bPEndorsed', type: 'BPEndorsed', unique: true},
            { path: 'realOpening', type: 'date' },
            {
                path: 'type',
                fieldPath: [
                    'bPEndorsed.bPProjectType.name',
                ],
                $f: (object, context, callback) => {
                    callback(null, context.tc(_.get(object, 'bPEndorsed.bPProjectType.name')))
                }
            },
            {
                path: 'currency',
                fieldPath: [
                    'shop.subsidiary.country.currency.code',
                    'shop.subsidiary.country.currency.name'
                ],
                f: function() {
                    return _.get(this.shop, 'subsidiary.country.currency.code')
                }
            },
            {
                path: 'subsidiary',
                fieldPath: ['shop.subsidiary.code', 'shop.subsidiary.name'],
                f: function() {
                    return `${this.shop.subsidiary.code} - ${this.shop.subsidiary.name}`
                }
            },
            {
                path: 'endorsedOpening',
                fieldPath: ['bPEndorsed.endorsedOpening'],
                f: function() {
                    return this.bPEndorsed && moment(this.bPEndorsed.endorsedOpening).format('YYYY-MM-DD')
                }
            },
            {
                path: 'fullInformation',
                fieldPath: [
                    'shop.code',
                    'bPEndorsed.code',
                    'bPEndorsed.endorsedOpening',
                    'realOpening'
                ],
                f: function() {
                    return `${this.shop.code} / ${this.bPEndorsed.code} / ${moment(
                        this.bPEndorsed.endorsedOpening
                    ).year()} / ${moment(this.realOpening).year()}`
                }
            }
        ],
        filters: [{
            name: 'onlyAffectedSubsidiary',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                global.app.B.SubsidiaryAssignment.find({
                    ...basicContext(context),
                    fieldPath: ['id', 'subsidiaries.id'],
                    query: {
                        user: new global.ObjectID(context.user.id),
                        group: new global.ObjectID(context.group.id)
                    }}, (error, assignments) => {
                        const subsidiariesIds = _.flatMap(
                            assignments,
                            assignment => assignment.subsidiaries.map(
                                subsidiary => new global.ObjectID(subsidiary.id)
                            )
                        )
                        if(subsidiariesIds.length) {
                            global.app.B.Shop.find({
                                ...basicContext(context),
                                fieldPath: ['id'],
                                query: {
                                    subsidiary: { $in: subsidiariesIds },
                                    group: new global.ObjectID(context.group.id)
                                }}, (error, shops) => {
                                    const shopsIds = shops.map(
                                        shop => new global.ObjectID(shop.id)
                                    )

                                    if(shopsIds.length) {
                                        callback(error, {
                                            shop: { $in: shopsIds }
                                        })
                                    } else {
                                        callback(null, {_id: null})
                                    }
                            })
                        } else {
                            callback(null, {_id: null})
                        }

                    })
            }
        }],
        afterSave: function(object, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C' && !oldObject) {
                generateAccountBPAData(object, context)
                    .then(o => callback(null, o))
                    .catch(e => callback(e))
            } else {
                callback(null, object)
            }
        },
        afterDelete: function (object, context, callback) {
            global.db.collection("b.data")
                .deleteMany({
                    bp: new global.ObjectID(_.get(object, 'bPEndorsed.id'))
                }, callback)
        }
    }
]

export const modules = [
    {
        object: 'ShopBPEndorsed',
        name: 'shopBPEndorsed',
        tKey: 'mTitle_shopBPEndorsed',
        defaultSortBy: 'shop',
        defaultSortDirection: 'ASC',
        category: {
            path: 'empiric',
            icon: 'briefcase'
        },
        viewMap: {
            dt: [
                { path: 'subsidiary', initiallyNotVisible: true, width: 400 },
                { path: 'shop', display: 'fullName', width: 400, index: true},
                { path: 'bPEndorsed', display: 'code', width: 400 },
                { path: 'currency', initiallyNotVisible: true, width: 100 },
                { path: 'type', initiallyNotVisible: true },
                { path: 'endorsedOpening', width: 150 },
                { path: 'realOpening', width: 150 }
            ],
            form: [
                {
                    path: 'shop', display: 'fullName', editable: false,
                    fieldPath: ['id', 'fullName', 'subsidiary.id', 'subsidiary.country.currency.code', 'subsidiary.country.currency.name'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            const currencyField = module.viewMap.form.fields.find(
                                field => field.path === 'currency'
                            )
                            if (newValue && newValue.id) {
                                currencyField.setValue(`${newValue.subsidiary.country.currency.code} - ${newValue.subsidiary.country.currency.name}`)

                                const bpEndorsedQuery = {
                                    subsidiary: newValue.subsidiary
                                }
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-B-shopBPEndorsed.ShopBPEndorsed_bPEndorsed',
                                        store.getState,
                                        'form',
                                        { data: bpEndorsedQuery }
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions('e_bPEndorsed', []))
                            }
                        }
                    }
                },
                {
                    path: 'bPEndorsed', display: 'code', filters: ['bySubsidiary'], editable: false,
                    fieldPath: ['id', 'code', 'endorsedOpening', 'bPProjectType.id', 'bPProjectType.name', 'projectConfiguration.id'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store, t }) => {
                            if (newValue && newValue.id) {
                                const endorsedOpeningField = module.viewMap.form.fields.find(
                                    field => field.path === 'endorsedOpening'
                                )
                                endorsedOpeningField.setValue(newValue.endorsedOpening)

                                const bPProjectTypeField = module.viewMap.form.fields.find(
                                    field => field.path === 'bPProjectType'
                                )
                                bPProjectTypeField.setValue(t(newValue.bPProjectType.name))
                            }
                        }
                    }
                },
                { path: 'currency', disabled: true },
                { path: 'bPProjectType', disabled: true },
                { path: 'endorsedOpening', disabled: true },
                'realOpening',
                { path: 'description', type: 'textarea'}
            ]
        },
        filters: [
            {
                title: 'Subsidiary',
                path: 'subsidiary',
                object: 'Subsidiary',
                display: 'fullName',
                client: true,
                width: 12,
                filters: ['inUserPerimeter'],
                placeholder: 'selectASubsidiary',
                async: true,
                query: function(context, callback) {
                    const subsidiaryId = _.get(context.data, 'subsidiary.id')
                    if (subsidiaryId) {
                        global.app.B.Shop.find(
                            {
                                group: context.group,
                                query: {
                                    subsidiary: new global.ObjectID(
                                        subsidiaryId
                                    )
                                }
                            },
                            (e, shops) => {
                                const shopIds = shops.map(
                                    shop => new global.ObjectID(shop.id)
                                )
                                callback(e, { shop: { $in: shopIds } })
                            }
                        )
                    } else {
                        callback(null, {})
                    }
                }
            }
        ]
    }
]
