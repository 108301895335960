export const entities = [
    {
        name: "ElemDataLine",
        collectionName: "slp.elemData",
        fields: [
            {path: "date", type: "date"},
            {path: "store", type: "string"},
            {path: "incomplete", type: "boolean"},
            "Product",
            "Store",
            {type: "Organization", link: "MTM"},
            {type: "Family", link: "MTM"},
            {path: "ticket"},
            {path: "ticketCustomer"},
            {path: "order"},
            {path: "cashier"}
        ],
        indexes:[
            {key: {"origin": 1, "date": 1, "incomplete": 1, "group": 1}},
            {key: {"incomplete": 1, "group": 1}},
            {key: {"date": 1, "group": 1}},
            {key: {"date": 1, "organizations": 1, "group": 1}},
            {key: {"date": 1, "familys": 1, "group": 1}},
            {key: {"ticket": 1, "groupedLine": 1, "group": 1}},
            {key: {"ticketCustomer": 1, "groupedLine": 1, "group": 1}},
            {key: {"order": 1, "groupedLine": 1, "group": 1}},
            {key: {"cashier": 1, "groupedLine": 1, "group": 1}},

        ]
    },
    {
        name: "ClientDataLine",
        collectionName: "slp.clientData",
        fields: [
            {path: "date", type: "date", index: true},
            {path: "store", type: "string", index: true},
            {path: "incomplete", type: "boolean", index: true}
        ],
        indexes:[
            {key: {"store": 1, "date": 1, incomplete: 1}}
        ]
    },
    {
        name: "ScoringIntermediate",
        collectionName: "slp.scoringIntermediate",
        fields: [
            {path: "infiniteFraction", type: "boolean", index: true},
            {path: "comparison", type: "string", index: true}
        ]
    }
]
