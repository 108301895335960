import React from 'react'
import VideosViewer from '../../../../components/VideosViewer'
import VideosUploaderField from '../../../../components/VideosUploaderField'
import { getModule } from '../../selectors'
import { getUser } from '../../../MainPage/selectors'
import { getFormValues } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { connect } from 'react-redux'


const VideosContainer = props => {

    return (
        <div>
            <VideosViewer data={props.moduleData} />
            <VideosUploaderField 
                {...props}
                module={props.module}
                user={props.user}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    module: getModule(state),
    user: getUser(state),
    moduleData: getFormValues(OBJECT_FORM)(state)
})

export default connect(mapStateToProps)(VideosContainer)
