const moment = require('moment')

export const pointDeSortie = {
    name: "pointDeSortie",
    object: "PointDeSortie",
    category: {
        path: "reporting",
        icon: 'layers'
    },
    defaultPanel: 'chart',
    chart: {
        keys: ['Réalisé', 'A réaliser', 'En contractualisation', 'En Montage', 'Détecté'],
        type: "barChart",
        title: `Point de Sortie ${moment().year()} (k€)`
    },
    viewMap: {
        chart: ['date', 'Réalisé', 'A réaliser', 'En contractualisation', 'En Montage', 'Détecté']
    },
    filters: ["byZone"]
}
