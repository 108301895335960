import React from 'react'
import { Field } from 'redux-form'
import { MultiCheckboxField  } from '../../../../components/Form'
import getFormValidations from './formValidations'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const MultipleCheckboxField = ({
    input: { value, onChange },
    disabled,
    editable,
    label,
    required,
    subtitle,
    language,
    options
}) => {
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)

    const detailedOption = options.find(option => {
        return option.hasDetailedResponse && value && value.some(value => value.id === option.id)
    })

    const detailedValue = detailedOption && value.find(value => value.id === detailedOption.id)

    return (
        <MultiCheckboxField
            label={translatedLabel}
            required={required}
            subtitle={translatedSubtitle}
            detailedResponse={detailedValue && detailedValue.detailedResponse}
            hasDetailedResponse={!!detailedOption}
            detailedResponsePlaceholder={detailedOption && detailedOption.detailedResponsePlaceholder}
            onResponseDetailsChange={event => {
                onChange(value && value.map(value => {
                    if(value.id === detailedOption.id) return {id: value.id, detailedResponse: event.target.value}
                    return value
                }))
            }}
            options={options}
            onChange={onChange}
            value={value}
            disabled={disabled || !editable}
            language={language}
        />
    )
}

const MultipleCheckbox = ({ field, t, language }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            label={field.label}
            required={field.required}
            subtitle={field.subtitle}
            language={language}
            component={MultipleCheckboxField}
            options={field.options}
            disabled={field.disabled}
            editable={field.editable}
            validate={validations}
            t={t}
        />
    )
}

export default MultipleCheckbox
