const {fieldPathJoinGetter} = require("../../utils/kp3Utils");

export const entity = {
    name: "OrganizationAxis",
    facets: [
        {name:"codeName", codeValidator: "mongoFieldName"},
        "translatedField"
    ],
    fields: [
        {path: "useInScope", type: "boolean", default: false},
        fieldPathJoinGetter({path: "tFullName", fieldPath: ["code", "tName"]})
    ]
}

export const module_ = {
    object: "OrganizationAxis",
    tKey: "mTitle_organizationAxis",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: "name",
    defaultSortDirection: "ASC",
    // facets: [{name: "codeName", editableCode: false}],
    viewMap: {
        dt: [
            "code",
            {path: "name", type: "translatedText"},
            "useInScope"
        ],
        form: [
            {path: "code", editable: false},
            {path: "name", type: "textarea"},
            "useInScope"
        ]
    },
    removable: false
}
