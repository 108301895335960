import React from 'react'
import ImagesViewer from '../../../../components/ImagesViewer'
import ImagesUploaderField from '../../../../components/ImagesUploaderField'
import { getModule } from '../../selectors'
import { getUser } from '../../../MainPage/selectors'
import { getFormValues } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { connect } from 'react-redux'


const ImagesContainer = props => {

    return (
        <div style={{padding: '5px'}}>
            <ImagesViewer noThumbnail={props.field.noThumbnail} path={props.field.path} data={props.moduleData} style={props.field.style} />
            <ImagesUploaderField
                {...props}
                module={props.module}
                user={props.user}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    module: getModule(state),
    user: getUser(state),
    moduleData: getFormValues(OBJECT_FORM)(state)
})

export default connect(mapStateToProps)(ImagesContainer)
