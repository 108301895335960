const _ = require('lodash')
const moment = require('moment')

const configureSimulationWorkbook = (formObject, objects, context) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet1 = workbook.addWorksheet('Paramètres simulation')

    dataSheet1.protect('123456', {formatColumns: true})
        .then( () => {})
        .catch(err => console.err(err))

    const params = [
        {key: 'Titre :', getter: () => formObject.title},
        {key: 'Type :', getter: () => context.tl(_.get(formObject, 'campaignType.id'), 'fr')},
        {key: 'Date campagne :', getter: () => moment(formObject.campaignDate).format('DD/MM/YYYY')},
        {key: 'Mt./Bénéficiaire :', getter: () => formObject.beneficiaryAmount.toFixed(2)},
        {key: 'Mt./Enfant :', getter: () => formObject.childAmount.toFixed(2)},
        {key: 'Age inf à :', getter: () => formObject.maxChildAge},
        {key: 'Mt. Min/Foyer :', getter: () => formObject.minPerHousehold && formObject.minPerHousehold.toFixed(2)},
        {key: 'Mt. Max/Foyer :', getter: () => formObject.maxPerHousehold && formObject.maxPerHousehold.toFixed(2)},
        {key: 'Bénéficiaires :', getter: () => formObject.statuses.map(status => status.name).map(status => context.tl(status, 'fr')).join(', ')},
        {key: 'Exclusion/Date campagne :', getter: () => formObject.useCampaignDate ? 'OUI' : 'NON'},
        {key: 'Appliquer prorata :', getter: () => formObject.applyProrating ? 'OUI' : 'NON'},
        {key: 'Appliquer split :', getter: () => formObject.applySplit ? 'OUI' : 'NON'},

    ]

    let row1 = 1

    params.forEach(param => {
        dataSheet1.getRow(row1).getCell(1).value = param.key
        dataSheet1.getRow(row1).getCell(1).font = {bold: true}
        dataSheet1.getRow(row1).getCell(2).value = param.getter()
        row1++
    })

    let dataSheet2 = workbook.addWorksheet('Résultats')

    dataSheet2.protect('123456', {formatColumns: true})
        .then( () => {})
        .catch(err => console.err(err))

    let row2 = 1
    let col2 = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const columns = [
        {key: 'Civilité', getter: object => object.civility},
        {key: 'Prénom', getter: object => object.firstname},
        {key: 'Nom', getter: object => object.lastname},
        {key: 'Matricule', getter: object => object.registrationNumber},
        {key: 'Statut', getter: object => object.status},
        {key: "Date d'entrée", getter: object => object.entryDate},
        {key: 'Date de sortie', getter: object => object.releaseDate},
        {key: 'Enfants retenus', getter: object => object.childrenInCharge},
        {key: 'Date naissance enfants retenus', getter: object => object.childrenInChargeBirthDates},
        {key: 'Age enfants retenus', getter: object => object.childrenInChargeAges},
        {key: 'Nb enfants retenus', getter: object => object.childrenInChargeNb},
        {key: 'Enfants exclus', getter: object => object.childrenNotInCharge},
        {key: 'Date naissance enfants exclus', getter: object => object.childrenNotInChargeBirthDates},
        {key: 'Age enfants exclus', getter: object => object.childrenNotInChargeAges},
        {key: 'Nb enfants exclus', getter: object => object.childrenNotInChargeNb},
        {key: 'Chèque cadeau', getter: object => object.giftVoucher.toFixed(2)},
        {key: 'Chèque culture', getter: object => object.cultureVoucher.toFixed(2)},
        {key: 'Total', getter: object => object.total.toFixed(2)}
    ]



    columns.forEach(column => {
        dataSheet2.getRow(row2).getCell(col2).value = column.key
        dataSheet2.getRow(row2).getCell(col2).fill = darkGrayBG
        col2++
    })

    row2 = 2
    const totals = objects.reduce((acc, line) => {
        col2 = 1
        columns.forEach(column => {
            dataSheet2.getRow(row2).getCell(col2).value = column.getter(line)
            col2++
        })
        row2++

        acc.giftVoucher += line.giftVoucher
        acc.cultureVoucher += line.cultureVoucher
        acc.total += line.total
        return acc

    }, {giftVoucher: 0, cultureVoucher: 0, total: 0})


    dataSheet2.getRow(row2).getCell(1).value = 'Total'
    dataSheet2.getRow(row2).getCell(16).value = totals.giftVoucher.toFixed(2)
    dataSheet2.getRow(row2).getCell(17).value = totals.cultureVoucher.toFixed(2)
    dataSheet2.getRow(row2).getCell(18).value = totals.total.toFixed(2)
    //dataSheet2.mergeCells(row2, 1, row2, 15)

    return workbook
}

const configureDistributionWorkbook = (formObject, objects, context) => workbook => {
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date(1985, 8, 30)
    workbook.modified = new Date()
    workbook.lastPrinted = new Date(2016, 9, 27)

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Résultats')

    dataSheet.protect('123456', {formatColumns: true})
        .then( result => {
            console.log(result)
        })
        .catch(err => console.err(err))


    let row = 1
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const columns = [
        {key: 'Identifiant', getter: object => `eng${object.registrationNumber}`},
        {key: 'Chèque cadeau', getter: object => object.giftVoucher.toFixed(2)},
        {key: 'Chèque culture', getter: object => object.cultureVoucher.toFixed(2)},
        {key: 'Total', getter: object => object.total.toFixed(2)}
    ]

    columns.forEach(column => {
        dataSheet.getRow(row).getCell(col).value = column.key
        dataSheet.getRow(row).getCell(col).fill = darkGrayBG
        col++
    })

    row = 2
    const totals = objects.reduce((acc, line) => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = column.getter(line)
            col++
        })
        row++

        acc.giftVoucher += line.giftVoucher
        acc.cultureVoucher += line.cultureVoucher
        acc.total += line.total
        return acc

    }, {giftVoucher: 0, cultureVoucher: 0, total: 0})

    dataSheet.getRow(row).getCell(1).value = 'Total'
    dataSheet.getRow(row).getCell(2).value = totals.giftVoucher.toFixed(2)
    dataSheet.getRow(row).getCell(3).value = totals.cultureVoucher.toFixed(2)
    dataSheet.getRow(row).getCell(4).value = totals.total.toFixed(2)

    return workbook
}

export async function generateExcel(type, filename, formObject, objects, context) {
    return await new Promise((resolve, reject) => {
        const configureWorkbook = type === 'simulation'
            ? configureSimulationWorkbook
            : configureDistributionWorkbook

        const passwordProtected = type === 'simulation'

        global.excel.generateExcel(
            configureWorkbook(formObject, objects, context),
            `${filename}.xlsx`,
            (err, file) => {
                if (err) reject(err);
                else resolve(file);
            },
            passwordProtected
        )
    });
}
