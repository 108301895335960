const _ = require('lodash')
const moment = require('moment')
const async = require('async')
const { change } = require('redux-form')
const {FILTER_FORM} = require('../../../../apps/KpModule/components/Filter/ListFilterForm')
const { updateObject, setCellStartDate, setCellEndDate, setDataList, setFormButtons } = require('../../../../apps/KpModule/actions')
const { getFormValues } = require('../../../../apps/KpModule/selectors')
const { basicContext } = require('../../../utils/contextUtils')
const { rowStyle } = require('../engines/functions')
const { setFieldVisibility, changeFieldDisabled} = require("../../../../apps/KpModule/actions")
const {
    calculateBPStoreAccountModels
} = require('../engines/bPStoreAccountModelEngine')
const { formatNumber } = require('../engines/functions')

const getPlusOneYear = date => moment(date).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD')
const getNextDay = date => moment(date).add(1, 'days')

const generateYears = (type, year) => {
    switch (type) {
        case '1':
            return _.range(year, year + 10)
        case '3':
            return _.range(year + 1, year + 10)
        case '2':
        case '4':
            return [year]
        default:
            return []
    }
}

async function findHypothesisModel(context) {

    const projectConfigId = _.get(context, 'data.projectConfiguration.id')

    if(!projectConfigId) return []

    const leaseDatas = await global.app.B.LeaseData.find({
        ...basicContext(context),
        fieldPath: ['leaseType.id'],
        query: {
            projectConfiguration: new global.ObjectID(projectConfigId),
            withProject: _.toNumber(_(context).get('data.projectContextM1M2.id'))
        }
    })

    const userHypothesisModelAssignment = await global.app.B.HypothesisModelAssignment.find({
        ...basicContext(context),
        fieldPath: ['hypothesisModels.id'],
        query: {
            user: new global.ObjectID(_.get(context, 'user.id'))
        }
    })

    const userHypothesisModelAssignmentQuery = {
        $in: userHypothesisModelAssignment[0]
            ? userHypothesisModelAssignment[0].hypothesisModels.map(model => new global.ObjectID(model.id))
            : []
    }

    const hypothesisModel = await global.app.B.HypothesisModel.find({
        ...basicContext(context),
        fieldPath: ['code', 'name'],
        query: {
            _id: userHypothesisModelAssignmentQuery,
            leaseType: {$in: leaseDatas.map(o => o.leaseType.id)}
        }
    })
    const leaseModelHypothesis = hypothesisModel.map(o => _.pick(o, ['id', 'code', 'name']))

    const dataHypothesis = await global.app.B.HypothesisData.find({
        ...basicContext(context),
        fieldPath: [
            'hypothesisModel.id',
            'hypothesisModel.code',
            'hypothesisModel.name'
        ],
        query: {
            hypothesisModel: userHypothesisModelAssignmentQuery,
            projectConfiguration: new global.ObjectID(projectConfigId),
            withProject: _.toNumber(_(context).get('data.projectContextM1M2.id'))
        }
    })

    const dataModelHypothesis = _(dataHypothesis)
        .map(o => ({
            id: o.hypothesisModel.id,
            code: o.hypothesisModel.code,
            name: o.hypothesisModel.name
        }))
        .uniqBy('code')
        .value()

    return _.orderBy(
        [...leaseModelHypothesis, ...dataModelHypothesis],
        'code'
    )
}

async function saveHypothesisData(newObject, context) {

    const projectConfigId = _.get(context, 'data.projectConfiguration.id')
    const projectContextId = _.get(
        context,
        'data.projectContextM1M2.id'
    )
    const projectStatusId = _.get(context, 'data.projectConfiguration.status.id')

    if(projectStatusId === 'ongoing') {

        const queryHypothesisData = {
            projectConfiguration: new global.ObjectID(projectConfigId),
            withProject: _.toNumber(_.get(context, 'data.projectContextM1M2.id')),
            hypothesisModel: new global.ObjectID(newObject.id),
            group: new global.ObjectID(_.get(context, 'group.id'))
        }
        const hypothesisData = _.drop(newObject.hypothesisData)
            .filter(o => !o.readOnly)
            .reduce((acc, element) => {
                const defaultObject = {
                    dataTypeCode: element.dataTypeCode,
                    hypothesisModelLineCode: element.hypothesisModelLineCode,
                    dataType: new global.ObjectID(element.dataType),
                    hypothesisModelLine: new global.ObjectID(
                        element.hypothesisModelLine
                    ),
                    ...queryHypothesisData
                }
                switch (element.hypothesisModelType) {
                    case '1':
                    case '2':
                    case '3':
                        return [
                            ...acc,
                            ...Object.keys(element.years).map(key => ({
                                value: applyDataTypeFormat(_.toNumber(element.years[key].data) || 0, element.dataTypeFormat, element.dataTypePrecision),
                                hypothesisModelLine: new global.ObjectID(
                                    element.hypothesisModelLine
                                ),
                                dataType: new global.ObjectID(element.dataType),
                                exercise: _.toNumber(key),
                                ...defaultObject
                            }))
                        ]
                    case '5':
                        return [
                            ...acc,
                            {
                                value: applyDataTypeFormat(_.toNumber(element.value.data) || 0, element.dataTypeFormat, element.dataTypePrecision),
                                exercise: '',
                                ...defaultObject
                            }
                        ]
                    default:
                        return acc
                }
            }, [])

        if (hypothesisData.length) {
            const hypDataCollection = global.app.B.HypothesisData.collection
            await hypDataCollection.deleteMany(queryHypothesisData)
            await hypDataCollection.insertMany(hypothesisData)
        }

        async.each(newObject.leaseDataRenewals1, async ldr => {
            await global.db.collection("b.leaseDataRenewal").updateOne(
                {_id: new global.ObjectID(ldr.id)},
                {$set: {start: ldr.start, end: ldr.end}}
            )
        })

        async.each(newObject.leaseDataRenewals1, async ldr => {
            await global.db.collection("b.leaseDataRenewal").updateOne(
                {_id: new global.ObjectID(ldr.id)},
                {$set: {start: ldr.start, end: ldr.end}}
            )
        })

        async.each(newObject.leaseDataRenewals2, async ldr => {
            await global.db.collection("b.leaseDataRenewal").updateOne(
                {_id: new global.ObjectID(ldr.id)},
                {$set: {convenience: ldr.convenience}}
            )
        })

        async.each(newObject.leaseDataRenewals3, async ldr => {
            await global.db.collection("b.leaseDataRenewal").updateOne(
                {_id: new global.ObjectID(ldr.id)},
                {
                    $set: {
                        fixed: ldr.fixed,
                        variable: ldr.variable,
                        minimumGuaranteed: ldr.minimumGuaranteed,
                        capped: ldr.capped,
                        rentDeductible: Number.parseInt(ldr.rentDeductible) || 0
                    }
                }
            )
        })

        async.each(newObject.generalCharacteristics, async ldr => {
            await global.db.collection("b.generalCharacteristic").updateOne(
                {_id: new global.ObjectID(ldr.id)},
                {$set: {value: _.toNumber(ldr.value) || 0}}
            )
        })

        if (newObject.leaseDataPeriods.length && newObject.leaseDataId) {
            const LeaseDataPeriodCollection = global.app.B.LeaseDataPeriod.collection
            await LeaseDataPeriodCollection.deleteMany({
                leaseData: global.ObjectID(newObject.leaseDataId)
            })

            const leaseRenewals = await global.app.B.LeaseRenewal.find(basicContext(context))
            const leaseDataPeriods = newObject.leaseDataPeriods.reduce((acc, leaseData) => {
                leaseRenewals.forEach(leaseRenewal => {
                    const leaseDataForLeaseRenewal = leaseData[leaseRenewal.code]
                    if(leaseDataForLeaseRenewal){
                        Object.keys(leaseDataForLeaseRenewal).forEach(periods => {
                            acc.push({
                                leaseData: global.ObjectID(newObject.leaseDataId),
                                leaseCondition: leaseData.id,
                                leaseRenewal: leaseRenewal.id,
                                start: periods.split(' ')[0],
                                end: periods.split(' ')[1],
                                value: _.toNumber(leaseDataForLeaseRenewal[periods].data) || 0,
                                group: new global.ObjectID(_.get(context, 'group.id'))
                            })
                        })
                    }
                })
                return acc
            }, [])

            await LeaseDataPeriodCollection.insertMany(leaseDataPeriods)
        }
    }


    const oldComments = await global.app.B.HypothesisDataUpdateComments.find(
        {
            ...basicContext(context),
            query: {
                projectConfiguration: new global.ObjectID(projectConfigId),
                projectContextM1M2: projectContextId
            },
            fieldPath: ['id']
        })

    const newComments = newObject.comments.filter(comment => !oldComments.some(oldComment => comment.id === oldComment.id))

    if(newComments.length) {
        await global.app.B.HypothesisDataUpdateComments.collection.insertMany(newComments.map(newComment => ({
            text: newComment.text,
            user: new global.ObjectID(newComment.user.id),
            date: moment(newComment.date).toDate(),
            projectConfiguration: new global.ObjectID(projectConfigId),
            projectContextM1M2: projectContextId,
            hypothesisModel: new global.ObjectID(newObject.id),
            group: new global.ObjectID(_.get(context, 'group.id'))
        })))
    }

    return newObject
}

const applyDataTypeFormat = (value, format, precision) => {
    switch (format) {
        case 'percent':
            return _.round(value / 100, 10)
        case 'thousand':
            return _.round(value * 1000, 10)
        case 'million':
            return _.round(value * 1000000, 10)
        case 'normal':
        case 'date':
            return value
        default:
            return value
    }
}
const removeDataTypeFormat = (value, format, precision) => {
    switch (format) {
        case 'percent':
            return _.round(value * 100, 10)
        case 'thousand':
            return _.round(value / 1000, 10)
        case 'million':
            return _.round(value / 1000000, 10)
        case 'normal':
        case 'date':
            return value
        default:
            return value
    }
}

const getDataFromHypothesisDataByHypothesisModelTypeValue = (
    projectConfigStatusId,
    hypModelLine,
    hypothesisData
) => {
    const identifier = `${hypModelLine.code}--${hypModelLine.dataType.code}`
    const data = {
        data: _.get(hypothesisData, identifier)
            ? removeDataTypeFormat(_.get(hypothesisData, identifier).value, _.get(hypModelLine, 'dataType.format.id'), _.get(hypModelLine, 'dataType.precision.id'))
            : '',
        type: projectConfigStatusId !== 'ongoing' ? '' : 'editText',
        style: { color: '#dd3c1b' }
    }
    return hypModelLine.hypothesisKind.id === '5' ? data : ''
}

const getDataFromHypothesisDataByHypothesisModelTypeYears = (
    projectConfigStatusId,
    startYear,
    year,
    hypModelLine,
    hypothesisData
) => {
    const identifier = `${hypModelLine.code}-${year}-${hypModelLine.dataType
        .code}`
    const data = {
        [year]: {
            data: _.get(hypothesisData, identifier)
                ? removeDataTypeFormat(_.get(hypothesisData, identifier).value, _.get(hypModelLine, 'dataType.format.id'), _.get(hypModelLine, 'dataType.precision.id'))
                : '',
            type: projectConfigStatusId !== 'ongoing' ? '' : 'editText',
            style: { color: '#dd3c1b' }
        }
    }
    switch (hypModelLine.hypothesisKind.id) {
        case '1':
            return data
        case '2':
            return startYear === year ? data : {}
        case '3':
            return startYear !== year ? data : {}
        default:
            return {}
    }
    // { id: '1', name: 'Annuelle – Chaque année' },
    // { id: '2', name: 'Annuelle – Première année' },
    // { id: '3', name: 'Annuelle – Sauf première année' },
    // { id: '4', name: 'Annuelle – Title' },
    // { id: '5', name: 'Non Annuelle' },
    // { id: '6', name: 'Non Annuelle – Titre' },
}

async function getHypothesisData(hypothesisModelId, context) {
    const hypothesisModel = await global.app.B.HypothesisModel.get(
        hypothesisModelId,
        {
            ...basicContext(context),
            fieldPath: [
                'leaseType.id',
                'hypothesisModelLine.id',
                'hypothesisModelLine.hypothesisType.id',
                'hypothesisModelLine.accountModel.id',
                'hypothesisModelLine.accountModel.accountModelType.id',
                'hypothesisModelLine.accountModel.dataType.format.id',
                'hypothesisModelLine.accountModel.dataType.precision.id',
                'hypothesisModelLine.modelStyle.id',
            ]
        }
    )

    const projectConfigurationId = _.get(
        context,
        'data.projectConfiguration.id'
    )
    const projectConfigStatusId = _.get(
        context,
        'data.projectConfiguration.status.id'
    )

    const projectConfigurationProjectId = _.get(
        context,
        'data.projectConfiguration.projectId'
    )

    const projectContextId = _.get(
        context,
        'data.projectContextM1M2.id'
    )

    const projectContextName = _.get(
        context,
        'data.projectContextM1M2.name'
    )

    const commentsObjects = await global.app.B.HypothesisDataUpdateComments.find(
        {
            ...basicContext(context),
            query: {
                projectConfiguration: new global.ObjectID(projectConfigurationId),
                projectContextM1M2: projectContextId,
                hypothesisModel: new global.ObjectID(hypothesisModelId),
            },
            fieldPath: ['id', 'user', 'date', 'text']
        })

    const projectConfiguration = await global.app.B.ProjectConfiguration.get(
        { _id: new global.ObjectID(projectConfigurationId) },
        {
            ...basicContext(context),
            fieldPath: [
                'subsidiary.country.id',
                'subsidiary.country.code',
                'subsidiary.country.currency.code',
                'country.id', 'country.code', 'country.currency.code'
            ]
        }
    )

    const yearStartBP = _.get(context, 'data.projectConfiguration.yearStartBP')

    const projectContextM1M2 = _.get(context, 'data.projectContextM1M2.id')

    const withProject = _.toNumber(projectContextM1M2)

    const hypothesisData = await global.app.B.HypothesisData.find({
        ...basicContext(context),
        fieldPath: [
            'hypothesisModelLine.code', 'hypothesisModelLine.name', 'hypothesisModelLine.hypothesisType.id', 'hypothesisModelLine.hypothesisKind.id', 'hypothesisModelLine.modelStyle.id', 'hypothesisModelLine.order',
            'hypothesisModelLine.accountModel.dataType.format.id', 'hypothesisModelLine.accountModel.dataType.precision.id', 'hypothesisModelLine.accountModel.accountModelType.id',
            'hypothesisModelLine.dataTypeM1.id', 'hypothesisModelLine.dataTypeM1.code', 'hypothesisModelLine.dataTypeM1.format.id', 'hypothesisModelLine.dataTypeM1.precision',
            'hypothesisModelLine.dataTypeM2', 'hypothesisModelLine.dataTypeM2.code', 'hypothesisModelLine.dataTypeM2.format.id', 'hypothesisModelLine.dataTypeM2.precision',
            'exercise', 'dataType.code'
        ],
        query: {
            projectConfiguration: new global.ObjectID(projectConfigurationId),
            hypothesisModel: new global.ObjectID(hypothesisModelId),
            withProject: withProject
        }
    })

    const hypothesisDataByHypothesisAndExercice = hypothesisData.reduce(
        (acc, hypoData) => {
            const identifier = `${hypoData.hypothesisModelLine
                .code}-${hypoData.exercise}-${hypoData.dataType.code}`
            return {
                ...acc,
                [identifier]: hypoData
            }
        },
        []
    )

    const dataModelLines = _(hypothesisData)
        .map(hd => hd.hypothesisModelLine)
        .uniqBy('code')
        .value()

    const hypothesisModelLines = dataModelLines.filter(
        o => o.hypothesisType.id === 'hypothesis'
    )

    const hypothesisModelLinesByDataType = hypothesisModelLines.reduce(
        (acc, hypoModelLine) => {
            return withProject === 0
                ? [
                      ...acc,
                      ...hypoModelLine.dataTypeM1.map(dt => ({
                          ...hypoModelLine,
                          dataType: dt
                      }))
                  ]
                : [
                      ...acc,
                      ...hypoModelLine.dataTypeM2.map(dt => ({
                          ...hypoModelLine,
                          dataType: dt
                      }))
                  ]
        },
        []
    )

    const startYear = _.toNumber(yearStartBP)

    const dataYears = _.range(startYear, startYear + 10)

    const hypothesisModelLinesTypeAM = hypothesisModel.hypothesisModelLine.filter(
        o => o.hypothesisType.id === 'account'
    )

    const resultAccountModels = await calculateBPStoreAccountModels(
        projectConfiguration,
        hypothesisModelLinesTypeAM
            .map(hml => ({
                accountModelCode: _.get(hml, 'accountModel.code'),
                accountModelType: _.get(hml, 'accountModel.accountModelType.id')
            })),
        projectContextM1M2,
        context,
        dataYears
    )

    const resultHypothesisModelLines = hypothesisModelLinesByDataType.map(
        hypothesisModelLine => {
            const defaultObject = {
                id: hypothesisModelLine.id + hypothesisModelLine.dataType.id,
                hypothesisModel: hypothesisModelId,
                hypothesisModelLine: hypothesisModelLine.id,
                hypothesisModelLineCode: hypothesisModelLine.code,
                dataType: hypothesisModelLine.dataType.id,
                dataTypeCode: hypothesisModelLine.dataType.code,
                dataTypeFormat: hypothesisModelLine.dataType.format.id,
                dataTypePrecision: hypothesisModelLine.dataType.precision,
                hypothesisModelType: hypothesisModelLine.hypothesisKind.id,
                name: {
                    data: context.translateName(hypothesisModelLine.name),
                    style: { textAlign: 'left' }
                },
                type: {
                    data: context.translateName(
                        hypothesisModelLine.dataType.name
                    ),
                    style: { textAlign: 'left' }
                },
                value: getDataFromHypothesisDataByHypothesisModelTypeValue(
                    projectConfigStatusId,
                    hypothesisModelLine,
                    hypothesisDataByHypothesisAndExercice
                ),
                years: {},
                order: hypothesisModelLine.order,
                style: rowStyle(hypothesisModelLine.modelStyle.id)
            }

            return ['1', '2', '3'].includes(
                hypothesisModelLine.hypothesisKind.id
            )
                ? dataYears.reduce((acc, year) => {
                      return {
                          ...acc,
                          years: {
                              ...acc.years,
                              ...getDataFromHypothesisDataByHypothesisModelTypeYears(
                                  projectConfigStatusId,
                                  startYear,
                                  year,
                                  hypothesisModelLine,
                                  hypothesisDataByHypothesisAndExercice
                              )
                          }
                      }
                  }, defaultObject)
                : defaultObject
        }
    )

    const resultAccountModelLines = hypothesisModelLinesTypeAM.map(hypothesisModelLine => {

        const accountModelUnit = _.get(hypothesisModelLine, 'accountModel.dataType.format.id')
        const accountModelPrecision = _.get(hypothesisModelLine, 'accountModel.dataType.precision.id')
        const accountModelType = _.get(hypothesisModelLine, 'accountModel.accountModelType.id')
        const years = generateYears(
            accountModelType === 'year' ? '1' : undefined,
            startYear
        )

        return {
            id: hypothesisModelLine.id,
            hypothesisModel: hypothesisModelId,
            hypothesisModelLine: hypothesisModelLine.id,
            readOnly: true,
            name: {
                data: context.translateName(hypothesisModelLine.name),
                style: { textAlign: 'left' }
            },
            type: '',
            value: accountModelType === 'value'
                ? formatNumber(
                    resultAccountModels[hypothesisModelLine.accountModel.code],
                    accountModelUnit,
                    accountModelPrecision
                )
                : '',
            years: accountModelType === 'year'
                ? years.reduce((acc, year) => {
                    return {
                        ...acc,
                        [year]: {
                            data: formatNumber(
                                resultAccountModels[
                                    hypothesisModelLine.accountModel.code
                                    ][year],
                                accountModelUnit,
                                accountModelPrecision
                            ),
                            style: { textAlign: 'center' }
                        }
                    }
                }, {})
                : {},
            order: hypothesisModelLine.order,
            style: rowStyle(hypothesisModelLine.modelStyle.id)
        }
    })

    const resultModelLines = [
        ...resultHypothesisModelLines,
        ...resultAccountModelLines
    ]

    const leaseData = hypothesisModel.leaseType
        ? await global.app.B.LeaseData.get({
            withProject: withProject,
            projectConfiguration: new global.ObjectID(projectConfigurationId),
            leaseType: hypothesisModel.leaseType.id
        },
        {
            ...basicContext(context),
            ignoreNotFound: true,
            fieldPath: [
            'generalCharacteristics.id',
            'generalCharacteristics.name',
            'generalCharacteristics.generalCharacteristicType.id',
            'generalCharacteristics.generalCharacteristicType.name',

            'leaseDataRenewals.id',
            'leaseDataRenewals.leaseRenewal.id',
            'leaseDataRenewals.leaseRenewal.name',
            'leaseDataRenewals.leaseRenewal.convenience',

            'leaseDataPeriods.id',
            'leaseDataPeriods.leaseCondition.id',
            'leaseDataPeriods.leaseRenewal.id'
        ]})
        : undefined

    const leaseConditions = await global.app.B.LeaseCondition.find({
        fieldPath: ['id', 'name'],
        ...basicContext(context)
    })
    const leaseRenewals = await global.app.B.LeaseRenewal.find({
        fieldPath: ['id', 'code'],
        ...basicContext(context)
    })

    const leaseDataByLRandLC =  _.get(leaseData, 'leaseDataPeriods')
        ? _.groupBy(
            _.get(leaseData, 'leaseDataPeriods'),
            o => o.leaseCondition.id+'-'+o.leaseRenewal.id
        )
        : undefined

    const leaseDataRenewals = _.get(leaseData, 'leaseDataRenewals')
    const orderedLeaseDataRenewals = _.orderBy(leaseDataRenewals, 'leaseRenewal.id')
    const leasDataResult = leaseData
        ? {
            leaseDataId: _.get(leaseData, 'id'),
            leaseDataRenewals1: orderedLeaseDataRenewals,
            leaseDataRenewals2: orderedLeaseDataRenewals,
            leaseDataRenewals3: orderedLeaseDataRenewals,
            generalCharacteristics: _.get(leaseData, 'generalCharacteristics'),
            leaseDataPeriods: leaseConditions.map(lc => ({
                    id: lc.id,
                    name: lc.name,
                    ...leaseRenewals.reduce((acc, lr) => {
                        acc[lr.code] = {
                            ...(leaseDataByLRandLC && leaseDataByLRandLC[lc.id+'-'+lr.id])
                                ? leaseDataByLRandLC[lc.id+'-'+lr.id].reduce((acc, data) => {
                                        acc[`${data.start} ${data.end}`] = {
                                            data: data.value,
                                            type: 'editText',
                                            style: {color: 'rgb(221, 60, 27)'}
                                        }
                                        return acc
                                    }, {})
                                : {}
                        }
                        return acc
                    }, {})
            }))
        }
        : {
            leaseDataRenewals1: [],
            leaseDataRenewals2: [],
            leaseDataRenewals3: [],
            generalCharacteristics: [],
            leaseDataPeriods: []
        }

    return {
        id: hypothesisModelId,
        code: hypothesisModel.code,
        name: hypothesisModel.name,
        title: projectConfigurationProjectId + ' / ' + context.tc(projectContextName),
        hypothesisData: _.sortBy(resultModelLines, 'order'),
        comments: commentsObjects && commentsObjects.map(commentObject => ({
            ..._.pick(commentObject, ['id', 'user', 'text']),
            date: moment(commentObject.date).format('YYYY-MM-DD HH:mm:ss')
        })),
        ...leasDataResult
    }
}

export const entity = {
    name: 'UpdatingHypothesisData',
    fields: ['name'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findHypothesisModel(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else
                getHypothesisData(id, context)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    save: function(newObject, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else {
                // Only for Turnover
                if(newObject.code === 'd14') {
                    const productsMix = newObject.hypothesisData.filter(o => o.hypothesisModelLineCode && o.hypothesisModelLineCode.startsWith('product.mix'))
                    if(productsMix) {
                        const years = Object.keys(productsMix[0].years)
                        const year = years.find(year => {
                            const result = _.round(productsMix.reduce((acc, pm) =>
                                    acc + Number.parseFloat(pm.years[year].data)
                                , 0), 3)
                            return  result < 99.999 || 100.001 < result
                        })
                        if(year){
                            return callback(new Error(context.tc('productMixShouldBe100', {year})))
                        }
                    }
                }
                saveHypothesisData(newObject, context)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
            }
        })
    }
}

export const module_ = {
    object: 'UpdatingHypothesisData',
    tKey: 'mTitle_updatingHypothesisData',
    newable: false,
    removable: false,
    hideScroll:true,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'bpStore',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: 'code', initiallyNotVisible: true},
            {path: 'name', type: 'translatedText'}
        ],
        form: {
            fields: [
                { path: 'title', type: 'readOnly', fullWidth: true},
                { path: 'leaseDataId', hidden: true },
                //{ path: 'name', type: 'translatedText', editable: false },
                { path: 'title', hidden: true },
                {
                    path: 'leaseDataRenewals1',
                    type: 'dtObjects',
                    sortable: false,
                    autoGrow: true,
                    fullWidth: true,
                    fields: [
                        { path: 'id', tKey: '', hidden: true },
                        { path: 'leaseRenewal.name', tKey: 'period(s)', width: 400 },
                        { path: 'start', type: 'datePicker', style: {color: 'rgb(221, 60, 27)'} },
                        { path: 'end', type: 'datePicker', style: {color: 'rgb(221, 60, 27)'} }
                    ],
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {

                            if (newValue) {

                                let previousEndDate

                                newValue.forEach(row => {
                                    if(row.start) {
                                        store.dispatch(setCellStartDate(`e_leaseDataRenewals1.l_end-${row.id}`, getNextDay(row.start)))
                                    }

                                    if(previousEndDate) {
                                        store.dispatch(setCellStartDate(`e_leaseDataRenewals1.l_start-${row.id}`, getNextDay(previousEndDate)))
                                        store.dispatch(setCellEndDate(`e_leaseDataRenewals1.l_start-${row.id}`, getNextDay(previousEndDate)))
                                    }
                                    previousEndDate = row.end
                                })

                                const pathByIndex = ['initial', 'renewal1', 'renewal2', 'renewal3']
                                const state = store.getState()
                                const formValues = getFormValues(state)
                                store.dispatch(updateObject({
                                    ...formValues,
                                    leaseDataPeriods: formValues.leaseDataPeriods.map(row => {
                                        return newValue.reduce((acc, value, index) => {
                                            if(!value.start || !value.end || value.end < value.start) return {...acc, [pathByIndex[index]]: {}}
                                            const periods = {}
                                            let intermediate = value.start
                                            while(getPlusOneYear(intermediate) < value.end ) {
                                                const period = `${intermediate} ${getPlusOneYear(intermediate)}`
                                                periods[period] = {
                                                    data: row[pathByIndex[index]][period]
                                                        ? row[pathByIndex[index]][period].data
                                                        : 0,
                                                    type: 'editText',
                                                    style: {color: 'rgb(221, 60, 27)'}
                                                }
                                                intermediate = getNextDay(getPlusOneYear(intermediate)).format('YYYY-MM-DD')
                                            }
                                            periods[`${intermediate} ${value.end}`] = {
                                                data: row[pathByIndex[index]][`${intermediate} ${value.end}`]
                                                    ? row[pathByIndex[index]][`${intermediate} ${value.end}`].data
                                                    : 0,
                                                type: 'editText',
                                                style: {color: 'rgb(221, 60, 27)'}
                                            }

                                            return {...acc, [pathByIndex[index]]: periods}
                                        }, row)
                                    })
                                }))
                            }
                        }

                    }
                },
                {
                    path: 'leaseDataRenewals2',
                    type: 'dtObjects',
                    autoGrow: true,
                    fullWidth: true,
                    required: true,
                    fields: [
                        { path: 'id', tKey: '', hidden: true },
                        { path: 'leaseRenewal.convenience', tKey: 'Question', translate: true },
                        { path: 'convenience', type: 'checkbox', width: 30 }
                    ]
                },
                {
                    path: 'generalCharacteristics',
                    type: 'dtObjects',
                    autoGrow: true,
                    fullWidth: true,
                    required: true,
                    fields: [
                        { path: 'id', tKey: '', hidden: true },
                        {
                            path: 'generalCharacteristicType.name',
                            tKey: 'mainFeatures',
                            translate: true
                        },
                        {
                            path: 'value',
                            type: 'editText',
                            style: {color: 'rgb(221, 60, 27)'},
                            componentClass: 'mini-input',
                            width: 150
                        }
                    ]
                },
                {
                    path: 'leaseDataRenewals3',
                    type: 'dtObjects',
                    autoGrow: true,
                    fullWidth: true,
                    required: true,
                    fields: [
                        { path: 'id', tKey: '', hidden: true },
                        { path: 'leaseRenewal.name', tKey: 'period(s)' },
                        {
                            path: 'fixed',
                            type: 'checkbox'
                        },
                        {
                            path: 'variable',
                            type: 'checkbox'
                        },
                        {
                            path: 'minimumGuaranteed',
                            type: 'checkbox'
                        },
                        {
                            path: 'capped',
                            type: 'checkbox'
                        },
                        {
                            path: 'rentDeductible',
                            type: 'editText',
                            componentClass: 'mini-input'
                        }
                    ]
                },
                {
                    path: 'leaseDataPeriods',
                    required: true,
                    type: 'dtObjects',
                    fullWidth: true,
                    autoGrow: true,
                    parentHeader: true,
                    headerClassName: 'breakHeaderLine',
                    headerHeight: 45,
                    fields: [
                        { path: 'name', parentColumn: '', translate: true },
                        { path: 'initial', parentColumn: 'Initial', dynamic: true, type: 'customized' },
                        { path: 'renewal1', parentColumn: 'Renouvellement 1', dynamic: true, type: 'customized' },
                        { path: 'renewal2', parentColumn: 'Renouvellement 2', dynamic: true, type: 'customized' },
                        { path: 'renewal3', parentColumn: 'Renouvellement 3', dynamic: true, type: 'customized' }
                    ]
                },
                {
                    path: 'hypothesisData',
                    type: 'styledTable',
                    fullWidth: true,
                    autoGrow: true,
                    required: true,
                    fields: [
                        {
                            path: 'name',
                            style: {
                                flex: '1 1 500px',
                                whiteSpace: 'initial'
                                // textAlign: 'left',
                                // paddingLeft: '10px'
                            }
                        },
                        'type',
                        'value',
                        { path: 'years', dynamic: true },
                        { path: 'hypothesisModelType', hidden: true },
                        { path: 'hypothesisModelLine', hidden: true },
                        { path: 'hypothesisModelLineCode', hidden: true },
                        { path: 'dataType', hidden: true },
                        { path: 'dataTypeCode', hidden: true },
                        { path: 'dataTypeFormat', hidden: true },
                        { path: 'dataTypePrecision', hidden: true },
                        { path: 'readOnly', hidden: true },
                        { path: 'style', hidden: true }
                    ]
                },
                {path: 'comments', type: 'comment2', fullWidth: true}
            ],
            onOpen: ({state, store}) => {

                const projectStatusId = _.get(state, 'filter.object.projectConfiguration.status.id')
                const fields = ['e_leaseDataRenewals1', 'e_leaseDataRenewals2', 'e_leaseDataRenewals3', 'e_generalCharacteristics', 'e_leaseDataPeriods']
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, projectStatusId !== 'ongoing')))

                const hypothesisData = _.get(state, 'edit.object.data.hypothesisData')
                const isLease = hypothesisData && hypothesisData.length < 2

                store.dispatch(setFieldVisibility(`e_title`, isLease))
                store.dispatch(setFieldVisibility(`e_leaseDataRenewals1`, isLease))
                store.dispatch(setFieldVisibility(`e_leaseDataRenewals2`, isLease))
                store.dispatch(setFieldVisibility(`e_leaseDataRenewals3`, isLease))
                store.dispatch(setFieldVisibility(`e_generalCharacteristics`, isLease))
                store.dispatch(setFieldVisibility(`e_leaseDataPeriods`, isLease))
                store.dispatch(setFieldVisibility(`e_hypothesisData`, !isLease))
            }
        }
    },
    filters: [
        {
            title: 'byProjectConfiguration',
            path: 'projectConfiguration',
            object: 'ProjectConfiguration',
            display: 'projectId',
            client: true,
            width: 9,
            placeholder: 'selectProjectVersion',
            clearable: false,
            fieldPath: [
                'bPProjectType',
                'projectName',
                'yearStartBP',
                'status.id'
            ],
            filters: ['onlyAffectedSubsidiary']
        },
        {
            title: 'byProjectContextM1M2',
            path: 'projectContextM1M2',
            object: 'ProjectContextM1M2',
            display: 'name',
            client: true,
            width: 3,
            placeholder: 'selectScenario',
            clearable: false,
            autoFetch: true,
            sortList: false,
            filters: ['withoutDiff']
        }
    ],
    stateSubscriptions: [{
        statePath: 'form.filterObject.values.projectConfiguration',
        subscription: (newValue, previousValue, { store }) => {
            const projectIsNOrR = ['N', 'R'].includes(_.get(newValue, 'bPProjectType.id'))

            store.dispatch(setDataList(
                'm-B-updatingHypothesisData_filter_ProjectContextM1M2',
                projectIsNOrR
                    ? [{ id: '1', code: 'S2', name: 'M2NewStore' }]
                    : [
                        { id: '1', code: 'S2', name: 'M2NewStore' },
                        { id: '0', code: 'S1', name: 'M1CurrentStoreContinuity'},
                    ]
            ))
        }
    }]
}
