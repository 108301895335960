
export function getStoreHabilitationQuery(context) {

    const storeCollectionName = global.app.S.Store.collectionName
    //const organizationAxisCollectionName = global.app.S.OrganizationAxis.collectionName
    //const organizationCollectionName = global.app.S.Organization.collectionName
    const habFunctionCollectionName = global.app.S.HabFunction.collectionName

    const habilitationCollection = global.app.S.Habilitation.collection
    return  habilitationCollection
        .aggregate([
            {
                $match: {
                    user: new global.ObjectID(context.user.id)
                }
            },
            {
                $lookup: {
                    from: habFunctionCollectionName,
                    localField: 'habFunction',
                    foreignField: '_id',
                    as: 'habFunc'
                }
            },
            {
                $match: {
                    'habFunc.organizationAxisJoin': new global.ObjectID(
                        'a00000000000000000000000'
                    ) // Store axis
                }
            },
            {
                $unwind: '$organizationsJoin'
            },
            {
                $lookup: {
                    from: storeCollectionName,
                    localField: 'organizationsJoin',
                    foreignField: '_id',
                    as: 'store'
                }
            },
            {
                $unwind: '$store'
            },
            /*
            {
                $lookup: {
                    from: organizationCollectionName,
                    localField: 'store.organizations',
                    foreignField: '_id',
                    as: 'organization'
                }
            },
            {
                $unwind: '$organization'
            },
            {
                $lookup: {
                    from: organizationAxisCollectionName,
                    localField: 'organization.organizationAxis',
                    foreignField: '_id',
                    as: 'orgAxis'
                }
            },
            {
                $unwind: '$orgAxis'
            },
            {
                $match: {
                    'orgAxis.code': 'REGION'
                }
            }
             */
        ])
        .toArray()
}

export function getOrganizationHabilitationQuery(context) {
    const organizationCollectionName = global.app.S.Organization.collectionName

    const habilitationCollection = global.app.S.Habilitation.collection
    return  habilitationCollection
        .aggregate([
            {
                $match: {
                    user: new global.ObjectID(context.user.id)
                }
            },
            {
                $unwind: '$organizationsJoin'
            },
            {
                $lookup: {
                    from: organizationCollectionName,
                    localField: 'organizationsJoin',
                    foreignField: '_id',
                    as: 'organization'
                }
            },
            {
                $unwind: '$organization'
            }
        ])
        .toArray()
}

export const getHabilitations = async (context) => {
    const storeHabQuery = getStoreHabilitationQuery(context)
    const orgHabQuery = getOrganizationHabilitationQuery(context)
    return await Promise.all([storeHabQuery, orgHabQuery])
}
