import React, {Component} from 'react';
import * as d3 from 'd3'
import { select } from 'd3-selection';


const margin = { top: 20, right: 30, bottom: 20, left: 110 }

const defaultColors = ['#ffa600', '#58508d']

class HorizontalDoubleBarChart extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const {data = [], h ,w, className, colors = defaultColors, dataKeys, defaultKeys} = this.props

        const keys = dataKeys || defaultKeys
        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom


        const x0 = d3.scaleBand()
            .rangeRound([height, 0])
            .paddingInner(0.1);


        const x1 = d3.scaleBand()
            .padding(0.05);

        const y = d3.scaleLinear()
            .rangeRound([0, width])

        const colorsByKey = {
            [keys[0]]: colors[0],
            [keys[1]]: colors[1]
        }

        const xAxis = d3.axisLeft()
            .scale(x0)

        const container = select(`.${className}`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const lengendContainer = select(`.${className}`)
            .select('svg')
            .select('.legendContainer')
            .attr('transform', `translate(${margin.left},${margin.top + height + margin.top})`)


        x0.domain(data.map(d => d['indicateur']))

        x1.domain(keys).rangeRound([x0.bandwidth(), 0])

        y.domain([0, d3.max(data, d => d3.max(keys, key =>  d[key] + d[key]/20 ) )]).nice();

        const bars = container
            .select(".bars")
            .selectAll("g")
            .data(data)

        const values = container
            .select(".values")
            .selectAll("g")
            .data(data)

        bars
            .attr("transform", function(d) { return "translate(0, " + x0(d.indicateur) +")"; })
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .attr("y", function(d) { return x1(d.key); })
            .attr("height", x1.bandwidth())
            .attr("x", 0)
            .attr("width", function(d) { return y(d.value) })
            .attr("fill", function(d) { return colorsByKey[d.key] })

        bars
            .enter().append("g")
            .attr("transform", function(d) { return "translate(0, " + x0(d.indicateur) +")"; })
            .selectAll("rect")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .enter().append("rect")
            .attr("y", function(d) { return x1(d.key); })
            .attr("height", x1.bandwidth())
            .attr("x", 0)
            .attr("width", 0)
            .transition()
            .duration(1000)
            .attr("width", function(d) { return y(d.value) })
            .attr("fill", function(d) { return colorsByKey[d.key] })

        values
            .attr("transform", function(d) { return "translate(0, " + x0(d.indicateur) +")"; })
            .selectAll("text")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .attr("y", d => x1(d.key) + x0.bandwidth()/4 + 2)
            .attr("x", d => y(d.value) + 10 )
            .attr("dx", ".1em")
            .style("text-anchor", "start")
            .style("font-size", "12px")
            .text(d => d.value)

        values
            .enter().append("g")
            .attr("transform", function(d) { return "translate(0, " + x0(d.indicateur) +")"; })
            .selectAll("text")
            .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
            .enter().append("text")
            .attr("y", d => x1(d.key) + x0.bandwidth()/4 + 2)
            .attr("x", 0)
            .transition()
            .duration(1000)
            .attr("x", d => y(d.value) + 10 )
            .attr("dx", ".1em")
            .style("text-anchor", "start")
            .style("font-size", "12px")
            .text(d => d.value)


        const axis = container.select('.axis--x')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0, 0)`)
            .call(xAxis)


        if (data.length === 1) {
            axis.select("text").remove()
        } else if (data.length !== 1) {
            axis.selectAll("text")
                .attr("x", -9)
                .attr("y", 0)
                .attr("dx", ".35em")
                .style("text-anchor", "end");
        }


        const legend = lengendContainer
            .selectAll("g")
            .data(colors)

        legend
            .select("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(d, i) {return colors[i];})

        legend
            .select("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(d, i) {
                return keys[i]
            });


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i) { return "translate("+ i * -50 + ", -15)"; })

        enterSelection
            .append("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(d, i) {return colors[i];})

        enterSelection
            .append("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(d, i) {
                return keys[i]
            });

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars" />
                        <g className="values" />
                        <g className="axis axis--x"/>
                    </g>
                    <g className="legendContainer"/>
                </svg>
            </div>
        )
    }
}

export default HorizontalDoubleBarChart;
