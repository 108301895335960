import React from 'react'
import Collapse from '../Collapse'
import WrappedMenu from '../../components/Menu'
import classNames from 'classnames'
import './ActionSelector.css'
import DataTableBoardButton from './DataTableBoardButton'
import {MoreVertical as More} from 'react-feather'
import PropTypes from 'prop-types'
import {translate} from "react-i18next";

const ActionSelector = ({ opened, open, actions, onClick, moduleId, t }) => {
    return (
        <div
            className={classNames('dropdown', {
                open: opened
            })}
        >
            <DataTableBoardButton
                onClick={() => open({ opened: !opened })}
            >
                <More className="DataTable-Board-icon" />
            </DataTableBoardButton>
            <WrappedMenu
                opened={opened}
                position="right"
                onClickOutside={() => open({ opened: false })}
                outsideClickIgnoreClass={'ActionSelector-Button'}
            >
                <ul
                    className="Navbar-Menu-list"
                    aria-labelledby="ShowActionsDropdown"
                >
                    {actions.map(action => (
                        <li
                            key={action}
                            className="Navbar-Menu-item"
                            onClick={event => {
                                event.stopPropagation()
                                onClick(action, moduleId)
                            }}
                        >
                            {t(action)}
                        </li>
                    ))}
                </ul>
            </WrappedMenu>
        </div>
    )
}

ActionSelector.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.string).isRequired,
    opened: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    moduleId: PropTypes.string.isRequired
}

export default translate('platform')(ActionSelector)
