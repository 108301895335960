const sorterByCode = {
    name: 'byCode',
    query: () => ({ code: 1 })
}

export const entities = [
    {
        name: 'Treatment',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'tKey'],
        objects: [
            { id: 'allvalidatedAnswers', tKey: 'allvalidatedAnswers' },
            { id: 'allQuestionsAnswered', tKey: 'allQuestionsAnswered' },
            {
                id: 'noRestrictionsOnAnswers',
                tKey: 'noRestrictionsOnAnswers'
            }
        ]
    },
    {
        name: 'Theme',
        facets: ['codeName'],
        fields: [{ path: 'active', type: 'boolean', default: true }],
        filters: [
            {
                name: "active",
                isDefault: false,
                query: () =>  ({active: true})
            }
        ],
        sorters: [sorterByCode]
    },
    {
        name: 'Subject',
        facets: ['codeName'],
        fields: [
            'Theme',
            { path: 'active', type: 'boolean', default: true }
        ],
        sorters: [sorterByCode]
    },
    {
        name: 'Entity',
        facets: ['codeName'],
        fields: [{ path: 'active', type: 'boolean', default: true }],
        filters: [
            {
                name: "active",
                isDefault: false,
                query: () =>  ({active: true})
            },
            {
                name: "userHasHabilitationForEntity",
                async: true,
                isDefault: false,
                query: function (context, callback) {

                    global.app.V.Habilitation.find(
                        {
                            query: {'users': new global.ObjectID(context.user.id) },
                            group: context.group
                        },
                        (e, habilitations) => {
                            if (e) return callback(e);

                            callback(null, {"_id": {$in: habilitations.map(hab => new global.ObjectID(hab.entity.id))}});
                        }
                    );
                }
            }
        ],
        sorters: [sorterByCode]
    },
    {
        name: 'Function',
        facets: ['codeName'],
        fields: [{ path: 'active', type: 'boolean', default: true }],
        filters: [
            {
                name: "active",
                isDefault: false,
                query: () =>  ({active: true})
            }
        ],
        sorters: [sorterByCode]
    },
    {
        name: 'Habilitation',
        fields: [
            {
                type: 'Entity',
                unique: true,
                uniqueWith: 'function',
                link: {
                    type: 'MTO',
                    oppositeField: { path: 'habilitations' }
                }
            },
            {
                type: 'Function',
                link: 'MTO',
                nullable: true
            },
            {
                type: 'User',
                link: 'MTM',
                nullable: true
            }
        ],
        filters:[
            {
                name: "userInHasHabilitation",
                isDefault: false,
                query: (context) =>  {
                    const userId  = context.user.id
                    return {'users': new global.ObjectID(userId) }
                }
            }
        ],
        sorters: [sorterByCode]
    },
    {
        name: 'TypeOfMeeting',
        fields: [
            { path: 'code', unique: true },
            { path: 'daysUpstream', type: 'integer', default: 0 },
            { path: 'daysDownstream', type: 'integer', default: 0 },
            { path: 'numberOfGuests', type: 'integer', default: 0 },
            'Treatment',
            { path: 'active', type: 'boolean', default: true }
        ],
        filters: [
            {
                name: "active",
                isDefault: false,
                query: () =>  ({active: true})
            }
        ],
        sorters: [sorterByCode]
    }
]

export const modules = [
    {
        object: 'Theme',
        category: 'referencing',
        tKey: 'mTitle_theme',
        removable: false,
        viewMap: {
            dt: ['code', 'name', 'active'],
            form: ['code', 'name', 'active']
        }
    },
    {
        object: 'Subject',
        category: 'referencing',
        tKey: 'mTitle_subject',
        removable: false,
        viewMap: {
            dt: [
                { path: 'code', width: 100 },
                { path: 'name', width: 400},
                { path: 'theme', display: 'name', width: 300 },
                { path: 'active', width: 100}
            ],
            form: [
                'code',
                'name',
                { path: 'theme', display: 'name', filters:["active"] },
                'active'
            ]
        }
    },
    {
        object: 'Entity',
        category: 'configure',
        tKey: 'mTitle_entity',
        removable: false,
        viewMap: {
            dt: ['code', 'name', 'active'],
            form: ['code', 'name', 'active']
        }
    },
    {
        object: 'Function',
        category: 'configure',
        tKey: 'mTitle_function',
        removable: false,
        viewMap: {
            dt: ['code', 'name', 'active'],
            form: ['code', 'name', 'active']
        }
    },
    {
        object: 'Habilitation',
        category: 'configure',
        tKey: 'mTitle_habilitation',
        viewMap: {
            dt: [
                { path: 'entity', width: 200 },
                { path: 'function', width: 300, required: false },
                { path: 'users', width: 800, required: false },
            ],
            form: [
                { path: 'entity', filters:["active"] },
                { path: 'function', filters:["active"], required: true },
                { path: 'users' },
            ]
        }
    },
    {
        object: 'TypeOfMeeting',
        category: 'configure',
        tKey: 'mTitle_typeOfMeeting',
        removable: false,
        viewMap: {
            dt: [
                { path: 'code', width: 100},
                { path: "treatment", display: "tKey", translate: true, width: 300},
                { path: 'daysUpstream', width: 100},
                { path: 'daysDownstream', width: 100},
                { path: 'numberOfGuests', width: 100},
                { path: 'active', width: 100}
            ],
            form: [
                { path: 'code', required: true },
                { path: "treatment", display: "tKey", translate: true },
                { path: 'daysUpstream', wholePositiveNumber: true },
                { path: 'daysDownstream', wholePositiveNumber: true },
                { path: 'numberOfGuests', wholePositiveNumber: true },
                'active'
            ]
        }
    }
]
