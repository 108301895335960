import _ from 'lodash'
const moment = require("moment");

const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

async function findData(context) {
    const dates = _.get(context, 'data.dates', [lastWeekISO, todayISO])
    const datesQuery = {
        'date': {
            $gte: dates[0],
            $lte: dates[1]
        },
    };
    const interfaceId = _.get(context, "data.interface.id")

    if (!interfaceId) return [];

    const match = { $match: {
        'interface': interfaceId || null,
            ...datesQuery
    } }

    const changesPromise = global.db.collection('u.interfacesChanges').aggregate([
        match,
        {
            $group: {
                _id: {date: '$date', interface: '$interface', nature: '$nature', fileName: '$fileName', importDate: '$importDate', hour: '$hour'},
                company: {$first: { $concat: [ '$companyCode', " - ", '$companyName' ] }},
                date:{$first: '$date'},
                hour: {$first: '$hour'},
                interface: {$first: '$interface'},
                nature: {$first: '$nature'},
                fileName: {$first: '$fileName'},
                importDate: {$first: { $concat: [ '$importDate', " - ", '$hour' ] }},
                number: {$sum: 1}
            }
        }
    ]).toArray()

    const [changes] = await Promise.all([changesPromise])

    return changes.map(o => ({
        ...o,
        id: Object.keys(o._id).reduce((acc, key) => acc ? acc + ';' + o._id[key] : o._id[key], '')
    }))
}

async function getData(query) {

    const queryKeys = ['date', 'interface', 'nature', 'fileName', 'importDate', 'hour']
    const queryElements = query.split(';')

    if (queryElements.length === 0) return {}
    let finalQuery = {}

    for(let i = 0 ; i < queryKeys.length ; i++ ) {
        Object.assign(finalQuery, {[queryKeys[i]]: queryElements[i]})
    }

    const match = { $match: {...finalQuery} }


    const changesPromise = global.db.collection('u.interfacesChanges').aggregate([
        match
    ]).toArray()

    const [changes] = await Promise.all([changesPromise])

    const company = changes[0] && `${changes[0].companyCode} - ${changes[0].companyName}`

    return {
        ...finalQuery,
        id: new global.ObjectID(),
        number: changes.length,
        company,
        importDate: `${finalQuery.importDate} - ${finalQuery.hour}`,
        details: changes

    }

}

export const dependencies = [
    {
        name: "Interface",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey",
        ],
        objects: [
            {id: "family", tKey: "Famille"},
            {id: "subFamily", tKey: "Sous Famille"},
            {id: "article", tKey: "Article"},
            {id: "client", tKey: "Client"},
            {id: "provider", tKey: "Fournisseur"},
            {id: 'deposit', tKey: 'Dépot'}
        ]
    }
]


    export const entity = {
    name: "ImportAnalysis",
    fields:['date', 'interface', 'nature', 'fileName', 'importDate', 'number', 'details'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    },
    get: function(query, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error);
            else getData(query, context, callback)
                    .then(object => callback(null, object))
                    .catch(error => callback(error))
        })
    }
}

export const module_ = {
    object: "ImportAnalysis",
    tKey: "mTitle_analyse",
    category: {
        path: "dataflow",
        icon: 'sliders'
    },
    newable: false,
    updatable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'company'},
            {path: 'date', width: 150},
            {path: 'interface', translate: true},
            {path: 'nature',translate: true, tKey: 'type'},
            {path: 'number', tKey: 'Nb.', width: 50},
            {path: 'fileName', width: 300, initiallyNotVisible: true},
            {path: 'importDate', tKey: 'treatedOn', width: 150, initiallyNotVisible: true},
        ],
        form: {
            fields : [
                {path: 'date', editable: false},
                {path: 'company', editable: false},
                {path: 'interface', translate: true, editable: false},
                {path: 'nature', tKey: 'type',translate: true, editable: false},
                {path: 'number', tKey: 'Nb.', editable: false},
                {path: 'fileName', editable: false},
                {path: 'importDate', tKey: 'treatedOn', editable: false},
                {
                    path: "details",
                    type: "dtObjects",
                    applyBoard: true,
                    maxRows: 20,
                    fields: [
                        'element',
                        {path: 'oldValue', tKey: 'before'},
                        {path: 'newValue', tKey: 'after'}
                    ]
                }
            ]
        }
    },
    filters:[
        {
            path: "dates",
            type: "dateRange",
            client: true,
            default: [lastWeekISO, todayISO]
        },
        {
            title: "Interface",
            path: "interface",
            tKey: "interface",
            default: {id: null},
            object: "Interface",
            objectFieldPath: ["interface", "interface.id"],
            display: "tKey",
            client: true,
            isDefault: false,
            clearable: true,
        }
    ]
}
