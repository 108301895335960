import {GET_OBJECT_SUCCESS} from "../../../../apps/KpModule/actions/api";
import {changeFieldDisabled, setFieldListOptions, setFieldVisibility, processDynamicColumns} from "../../../../apps/KpModule/actions";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import async from "async";
import {basicContext} from "../../../utils/contextUtils";
import {hasDuplicates} from "../utils/usefulFunctions";
import Errors from "../../../utils/Errors";
import moment, {lang} from "moment/moment";
import {translateName} from "../../../../utils/i18n";
const _ = require('lodash')


function filterUniqueByName(array) {
    const uniqueFiscalYearsIDs = new Set();
    return array.filter(obj => {
        if (!uniqueFiscalYearsIDs.has(obj.id.toString())) {
            uniqueFiscalYearsIDs.add(obj.id.toString());
            return true;
        }
        return false;
    });
}

export const entity2 = {
    name: 'BudgetFollowUp',
    fields: [
        'FiscalYear',
        { path: "type", type: 'ImputationType' },
        { path: 'amount', type: 'decimal' },
    ],
}

export const entity3 = {
    name: 'EstimatedBudgetFollowUp',
    fields: [
        'MeetingsPlanning',
        'FiscalYear',
        { path: "type", type: 'ImputationType' },
        { path: 'amount', type: 'decimal' },
        'BudgetColumnType'
    ],
}



export const entity = {
    name: 'Budget',
    fields: [
        {path: 'mesh', type: 'OrganizationalMesh', unique: true, uniqueWith:['analyticalMesh']},
        {path: 'analyticalMesh', type: 'AnalyticalMesh'},
        {path: 'currency', type: 'Currency'},
        {
            path: 'budgetTable',
            lazy: true,
            fieldPath: [
                'BudgetFollowUp.id', 'BudgetFollowUp.fiscalYear', 'BudgetFollowUp.imputationType'
            ],
            list: true,
            $f: (workFlow,context,callback) => callback(null, [])
        },
        {
            path: 'estimatedBudgetTable',
            lazy: true,
            fieldPath: [
                'EstimatedBudgetFollowUp.id', 'EstimatedBudgetFollowUp.meetingsPlanning', 'EstimatedBudgetFollowUp.type', 'EstimatedBudgetFollowUp.fiscalYear'
            ],
            list: true,
            $f: (workFlow,context,callback) => callback(null, [])
        },
        {
            path: 'budgetFollowUp',
            type:'BudgetFollowUp',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false
            },
            nullable: true
        },
        {
            path: 'estimatedBudgetFollowUp',
            type:'EstimatedBudgetFollowUp',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false
            },
            nullable: true
        },
        'Status',
        {
            path: 'budgetDisplayName',
            fieldPath: ['mesh.name', 'analyticalMesh.name', 'analyticalMesh.attachment'],
            $f: function (object, context, callback) {
                return callback(null, `${object?.analyticalMesh?.name} - ${object?.mesh?.name} (${object?.analyticalMesh?.attachment?.map( att => ' ' + att.code + ' / ' +att.name)})`)
            }
        },
        {
            path: 'meshAttachments',
            fieldPath: ['mesh.nameWithOrganizationalAxis'],
            f: function(){
                const match = this.mesh.nameWithOrganizationalAxis.match(/\(([^)]+)\)/);
                let string2 = null
                if (match) {
                    string2 = match[1]; // This is the content inside the parentheses
                }
                return string2
            }
        },
        {
            path: 'analyticalMeshAttachments',
            fieldPath: ['analyticalMesh.attachmentDetails'],
            f: function(){
                return this.analyticalMesh.attachmentDetails
            }
        },
        {
            path: 'totalsPerImputationTypes',
            fieldPath: ['budgetFollowUp.id', 'budgetFollowUp.amount', 'budgetFollowUp.type.name'],
            $f: function(object, context, callback){
                global.app.SE.ImputationType.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {}
                },(e, imputationTypes) =>{
                    if (e) return callback(e)
                    const budgetsGroupedByImputationType = _.groupBy(object.budgetFollowUp, 'type.name')
                    const alreadyFilledTypes = Object.keys(budgetsGroupedByImputationType)
                    const result = imputationTypes.reduce((acc, type) => {
                        const cdt = !!alreadyFilledTypes.includes(type.name)
                        const amount = cdt ? budgetsGroupedByImputationType[type.name].reduce((acc, obj) => acc+obj.amount, 0) : 0
                        return {
                            ...acc,
                            [translateName(type.name, _.get(context, "language.id"))]: amount
                        }
                    }, {})
                    callback(null, result)

                })
            }
            //format: {typeName: amount, ...}
        }
    ],
    filters: [
        {
            name: "byOrganization",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                const organizationID = _.get(context, 'data.organizationID')
                if (!!organizationID) {
                    global.app.SE.OrganizationalMesh.find(
                        {
                            ...basicContext(context),
                            fieldPath: ['analyticalAxis'],
                            query: { attachments : {$elemMatch: {$eq: global.ObjectID(organizationID)}} }
                        },(e, meshes) => {
                            if (e) return callback(e)
                            if (!!meshes.length){
                                const meshesIDs = meshes.map( mesh => global.ObjectID(mesh.id) )
                                return callback(null, {mesh: {$in: meshesIDs}})
                            }
                            else{
                                callback(null, {_id: null})
                            }
                        }
                    )
                }
                else{
                    return callback(null, {_id: null})
                }
            }
        },
        {
            name: "byOrganizationalMesh",
            isDefault: false,
            query: function (context) {
                const organizationalMeshID = _.get(context, 'data.organizationalMeshID')
                return !!organizationalMeshID ? {mesh: {$eq: global.ObjectID(organizationalMeshID)}} : {}
            }
        },
        {
            name: "budgetForDemand",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                const projectNumber = _.get(context, 'data.projectNumber')
                if (!projectNumber) {
                    global.app.SE.AnalyticalSubAxis.find(
                        {
                            ...basicContext(context),
                            fieldPath: ['analyticalAxis'],
                            query: {}
                        },(e, subAxes) => {
                            if (e) return callback(e)
                            const budgetAxesWithNoProject = subAxes.filter( subAxis => subAxis.analyticalAxis.isBudgetAxis && !subAxis.analyticalAxis.isProjectAxis).map(subAxisInBudget => global.ObjectID(subAxisInBudget.id))
                            if (!!budgetAxesWithNoProject.length){
                                global.app.SE.AnalyticalMesh.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: [],
                                        query: {"attachment": {$elemMatch: {$in: budgetAxesWithNoProject}} }
                                    }, (e, meshes) => {
                                        if (e) return callback(e)
                                        if (!!meshes && !!meshes.length){
                                            const meshesIDs = meshes.map( mesh => global.ObjectID(mesh.id))
                                            return callback(null, {analyticalMesh: {$in: meshesIDs}} )
                                        }
                                        else{
                                            callback(null, {_id : null})
                                        }
                                    }
                                )
                            }
                            else{
                                callback(null, {_id: null})
                            }
                        }
                    )
                }
                else{
                    global.app.SE.AnalyticalSubAxis.find(
                        {
                            ...basicContext(context),
                            fieldPath: [],
                            query: {
                                code: {$eq: projectNumber},
                            }
                        }, (e, result) => {
                            if (e) return callback(e)
                            if (!!result && !!result.length && result.length === 1){
                                global.app.SE.AnalyticalMesh.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: [],
                                        query: {
                                            attachment: {$elemMatch: {$eq: global.ObjectID(result[0].id)}},
                                        }
                                    }, (e, meshes) => {
                                        if (e) return callback(e)
                                        if (!!meshes && !!meshes.length){
                                            const meshesIDs = meshes.map( mesh => global.ObjectID(mesh.id))
                                            return callback(null, {analyticalMesh: {$in: meshesIDs}} )
                                        }
                                        callback(null, {_id : null})
                                    }
                                )
                            }
                            else{
                                callback(null, {_id : null})
                            }
                        }
                    )
                }
            }
        }
    ],
    beforeSave: async function (newObject, oldObject, context, callback) {
        try {
            const action = context.restAction && context.restAction.crudType
            const budgetProjects = newObject.analyticalMesh.attachment.filter( att => att.analyticalAxis.isProjectAxis === true)
            if (!!budgetProjects && !!budgetProjects.length && budgetProjects.length === 1){
                if (action === 'C'){
                    const bulkWritesForRevisedTable = []
                    const revisedTableNewIds = []

                    const availableImputationTypes = await global.app.SE.ImputationType.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {}
                    })
                    const availableRealizationTypes = await global.app.SE.RealizationType.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {}
                    })

                    availableImputationTypes.forEach(imputationType =>{
                        const newID = new global.ObjectID()
                        revisedTableNewIds.push(newID)
                        let list = []
                        availableRealizationTypes.forEach(type =>{
                            list.push({
                                _id: new global.ObjectID(),
                                realizationType: global.ObjectID(type.id), //zid
                                budgetColumnType: '1',  //global
                                amount: 0
                            })
                            list.push({
                                _id: new global.ObjectID(),
                                realizationType: global.ObjectID(type.id),
                                budgetColumnType: '2', //yearly
                                amount: 0
                            })
                        })
                        const estimatedBudgetsForThisLine = newObject.estimatedBudgetFollowUp.filter( obj => obj.type.id.toString() === imputationType.id.toString() )//&& obj.amount !== 0 )
                        const estimatedBudgetForCurrentMeetingPlanning = estimatedBudgetsForThisLine.filter(obj => moment().isBetween(obj.meetingsPlanning.meetingsPlanningRange[0], obj.meetingsPlanning.meetingsPlanningRange[1], 'day', '[]'))
                        const globalEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '1')
                        const estimatedAmount = !!globalEstimatedBudget ? globalEstimatedBudget.amount : 0
                        const yearlyEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '2')
                        const ongoingEstimated = !!yearlyEstimatedBudget ? yearlyEstimatedBudget.amount : 0

                        const budgetsForThisLine = newObject.budgetFollowUp.filter( obj => obj.type.id.toString() === imputationType.id.toString() )//&& obj.amount !== 0 )
                        const globalBudget = budgetsForThisLine.reduce( (acc, obj) => acc+obj.amount, 0)
                        const budgetForCurrentFiscalYear = budgetsForThisLine.find(obj => moment().isBetween(obj.fiscalYear.fiscalYearRange[0], obj.fiscalYear.fiscalYearRange[1], 'day', '[]'))
                        const yearlyBudget = !!budgetForCurrentFiscalYear ? budgetForCurrentFiscalYear.amount : 0
                        bulkWritesForRevisedTable.push(
                            {
                                insertOne: {
                                    document: {
                                        _id: newID,
                                        objet: newObject.analyticalMesh.code,
                                        entity: global.ObjectID(newObject.mesh.id),
                                        imputationType: global.ObjectID(imputationType.id),
                                        estimated: estimatedAmount, //
                                        validated: 0,
                                        budget: globalBudget, //
                                        revised: 0,
                                        validatedEngagement: 0,
                                        ongoingFiscalYearValidatedEngagement: 0,
                                        ongoingFiscalYearEstimated: ongoingEstimated, //
                                        ongoingFiscalYearValidated: 0,
                                        ongoingFiscalYearBudget: yearlyBudget, //
                                        ongoingFiscalYearRevised: 0,
                                        amountByRealizationType: list,
                                        updated: null,
                                        user: null,
                                        //demand: global.ObjectID(newObject.relatedProject.id),
                                        group: new global.ObjectID(_.get(context, 'group.id'))
                                    }
                                }
                            }
                        )
                    })

                    await global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                        if (e) return callback(e)})

                    await global.app.SE.Demand.collection.updateOne(
                        { demandNumber: budgetProjects[0].code },
                        {
                            $push: {
                                revisedTable: { $each: revisedTableNewIds }
                            }
                        },
                        (e)=>{
                            if (e) return callback(e)}
                    )
                    return callback(null, newObject, oldObject);
                }
                else {
                    const demandNumber = budgetProjects[0].code
                    const project = await global.app.SE.Demand.get(
                        {
                            demandNumber: demandNumber
                        },
                        {
                            ...basicContext(context),
                            fieldPath: ["revueDate", "revisedTable"],
                        })
                    if (!!project){
                        const revisedTable = project.revisedTable
                        let bulkWritesForRevisedTable = [{ deleteOne: {filter: { _id: null }} }]
                        revisedTable.forEach(line => {
                            const shouldUpdate = line.entity.id.toString() === newObject.mesh.id.toString() && line.objet === newObject.analyticalMesh.code
                            if (shouldUpdate){
                                const estimatedBudgetsForThisLine = newObject.estimatedBudgetFollowUp.filter( obj => obj.type.id.toString() === line.imputationType.id.toString() )//&& obj.amount !== 0 )
                                const budgetsForThisLine = newObject.budgetFollowUp.filter( obj => obj.type.id.toString() === line.imputationType.id.toString() )//&& obj.amount !== 0 )
                                // filter by fiscalYear
                                const estimatedBudgetForCurrentMeetingPlanning = estimatedBudgetsForThisLine.filter(obj => moment().isBetween(obj.meetingsPlanning.meetingsPlanningRange[0], obj.meetingsPlanning.meetingsPlanningRange[1], 'day', '[]'))
                                const globalEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '1')
                                const yearlyEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '2')

                                const budgetForCurrentFiscalYear = budgetsForThisLine.find(obj => moment().isBetween(obj.fiscalYear.fiscalYearRange[0], obj.fiscalYear.fiscalYearRange[1], 'day', '[]'))


                                const infosToBeUpdatedWithExist = !!globalEstimatedBudget && !!yearlyEstimatedBudget && !!budgetForCurrentFiscalYear

                                const estimatedAmount = !!globalEstimatedBudget ? globalEstimatedBudget.amount : 0
                                const ongoingEstimated = !!yearlyEstimatedBudget ? yearlyEstimatedBudget.amount : 0

                                const globalBudget = budgetsForThisLine.reduce( (acc, obj) => acc+obj.amount, 0)
                                const yearlyBudget = !!budgetForCurrentFiscalYear ? budgetForCurrentFiscalYear.amount : 0

                                bulkWritesForRevisedTable.push({
                                    updateOne: {
                                        filter: { _id: global.ObjectID(line.id) },
                                        update: {
                                            $set: {
                                                estimated: estimatedAmount,
                                                ongoingFiscalYearEstimated: ongoingEstimated,
                                                budget: globalBudget,
                                                ongoingFiscalYearBudget: yearlyBudget
                                            }
                                        }
                                    }
                                })

                            }
                        })

                        await global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                            if (e) return callback(e)
                        })
                    }
                    else{
                        return callback(new Errors.ValidationError('the principale project cannot be updated'))
                    }
                }
            }
            callback(null, newObject, oldObject)
        } catch (error) {
            callback(error)
        }
    }
}

export const module_ = {
    object: 'Budget',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: 'budget',
    defaultSortBy: 'analyticalMesh',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'mesh', tKey: 'business unit', display: 'name', width: 100},
            {path: 'meshAttachments', tKey: 'organizations', width: 350},
            {path: 'analyticalMesh', tKey: 'analytique', width: 100},
            {path: 'analyticalMeshAttachments', tKey: 'analyticalMeshes', width: 350},
            {path: 'totalsPerImputationTypes', dynamic: true, width: 100}
        ],
        form:{
            fields:[
                {path: 'mesh',tKey: 'business unit', editable: false, display: 'nameWithOrganizationalAxis'},
                {path: 'analyticalMesh', editable: false, tKey: 'analytique', display: 'nameWithAttachment'},// filters: ['byBudgetAxis', 'createdManually'], display: "nameWithAttachment"},
                {path: 'currency', editable: false, display: 'symbol'},
                {
                    path: 'budgetTable',
                    tKey: 'budget',
                    type: 'dtObjects',
                    fields: [
                        {path: "fiscalYear", tKey: 'exercice', display: 'code'},
                        {path: "types", dynamic: true, type: 'editText'},
                    ],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction}) => {
                            if(!formInitialize && !getObjectSuccessAction) {
                                const budgetTableField = module.viewMap.form.fields.find(field => field.path === 'budgetFollowUp')

                                const state = store.getState()
                                const language = _.get(state, 'ui.language')
                                const fiscalYears = getDataListList(state, 'Budget-FiscalYear')
                                const budgetTypes = getDataListList(state, 'Budget-ImputationType')
                                const groupId = state.groupModel.group.id

                                const result = _.flatMap(newValue, row => {
                                    const fiscalYear = fiscalYears.find(fiscalYear => fiscalYear.code === row.fiscalYear)
                                    const typesNames = Object.keys(row.types)
                                    return typesNames.map(typeName => {
                                        const budgetType = budgetTypes.find(budgetType => translateName(budgetType.name, language) === typeName)
                                        return {
                                            fiscalYear,
                                            type: budgetType,
                                            amount: row.types[typeName],
                                        }
                                    })
                                })
                                console.log('length', result.length, result)
                                budgetTableField.setValue(result)
                            }
                        }
                    }
                },
                {
                    path: 'estimatedBudgetTable',
                    type: 'dtObjects',
                    fields: [
                        {path: "fiscalYear", tKey: 'exercice', display: 'code'},
                        {path: "meetingsPlanning", display: 'code'},
                        {path: "types", type: 'editText', dynamic: true},
                    ],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction, t}) => {
                            if(!formInitialize && !getObjectSuccessAction) {
                                const estimatedBudgetFollowUpField = module.viewMap.form.fields.find(field => field.path === 'estimatedBudgetFollowUp')
                                const state = store.getState()
                                const language = _.get(state, 'ui.language')

                                const ongoingFiscalYears = getDataListList(state, 'Budget-FiscalYear')
                                const budgetTypes = getDataListList(state, 'Budget-ImputationType')
                                const meetingsPlannings = getDataListList(state, 'Budget-MeetingsPlanning')
                                const budgetColumnsTypes = getDataListList(state, 'Budget-BudgetColumnType')

                                const result = _.flatMap(newValue, row => {
                                    const fiscalYear = ongoingFiscalYears.find(fiscalYear => fiscalYear.code === row.fiscalYear)
                                    const meetingsPlanning = meetingsPlannings.find(object => object.code === row.meetingsPlanning)
                                    const typesNames = Object.keys(row.types)
                                    return typesNames.map(typeName => {
                                        const words = typeName.split(' ');
                                        const imputationTypeName = words[0]
                                        const columnTypeName = words[1]
                                        const budgetType = budgetTypes.find(budgetType => translateName(budgetType.name, language) === imputationTypeName)
                                        const columnType = budgetColumnsTypes.find(columnType => {
                                            return t(columnType.name) === columnTypeName
                                        })
                                        return {
                                            fiscalYear,
                                            meetingsPlanning,
                                            type: budgetType,
                                            amount: row.types[typeName],
                                            budgetColumnType: columnType
                                        }
                                    })
                                })
                                console.log('wach', result)
                                estimatedBudgetFollowUpField.setValue(result)
                            }
                        }
                    }
                },
                {path: 'status', type: 'toggle', default: {id: '1'}},
                {
                    path: 'budgetFollowUp',
                    type: 'dtObjects',
                    fields: [
                        {path: "fiscalYear", display: 'code'},
                        {path: "type", display: "name"},
                        {path: "amount"}
                    ]
                },
                {
                    path: 'estimatedBudgetFollowUp',
                    type: 'dtObjects',
                    fields: [
                        {path: "fiscalYear", display: 'code'},
                        {path: "meetingsPlanning", display: 'code'},
                        {path: "type", display: "name"},
                        {path: "amount"},
                        'budgetColumnType'
                    ]
                }
            ],
            onOpen: ({ store, module, t }) => {
                console.log('sync', t('yearly'), t('global'))

                const state = store.getState()
                const language = _.get(state, 'ui.language')

                const budgetFollowUpField = module.viewMap.form.fields.find(field => field.path === 'budgetFollowUp')
                store.dispatch(setFieldVisibility(budgetFollowUpField.id, false))

                const estimatedBudgetFollowUpField = module.viewMap.form.fields.find(field => field.path === 'estimatedBudgetFollowUp')
                store.dispatch(setFieldVisibility(estimatedBudgetFollowUpField.id, false))

                const objectMode = state.ui.objectMode

                if(objectMode === 'newObject') {

                    const budgetTableField = module.viewMap.form.fields.find(field => field.path === 'budgetTable')
                    const estimatedBudgetTableField = module.viewMap.form.fields.find(field => field.path === 'estimatedBudgetTable')

                    const fiscalYears = getDataListList(state, 'Budget-FiscalYear')
                    const ongoingFiscalYears = fiscalYears.filter( fiscalYear => ['1', '3'].includes(fiscalYear.fiscalYearStatus.id))

                    const budgetTypes = getDataListList(state, 'Budget-ImputationType')
                    const meetingsPlannings = getDataListList(state, 'Budget-MeetingsPlanning')
                    const ongoingMeetingsPlannings = meetingsPlannings.filter( obj => moment().isBetween(obj.meetingsPlanningRange[0], obj.meetingsPlanningRange[1], 'day', '[]'))
                    const canReportMeetingsPlannings = meetingsPlannings.filter( obj => moment().isBetween(obj.budgetReportingPeriod[0], obj.budgetReportingPeriod[1], 'day', '[]'))

                    const budgetColumnsTypes = getDataListList(state, 'Budget-BudgetColumnType')

                    const result = ongoingFiscalYears.map(fiscalYear => {
                        return {
                            fiscalYear: fiscalYear.code,
                            types: budgetTypes.reduce((acc, type) => {
                                return {
                                    ...acc,
                                    [translateName(type.name, language)]: 0
                                }
                            }, {})
                        }
                    })

                    budgetTableField.setValue(result)

                    const result2 = ongoingMeetingsPlannings.map(object => {
                        const canReport = canReportMeetingsPlannings.some(obj => obj.id.toString() === object.id.toString())
                        const defaultObject = {
                            fiscalYear: object.fiscalYear.code,
                            meetingsPlanning: object.code,
                            types: budgetTypes.reduce((acc, type) => {
                                const twoColumns = budgetColumnsTypes.reduce( (acc, columnType) => {
                                    return {
                                        ...acc,
                                        [translateName(type.name, language)+' '+t(columnType.name)]: 0,
                                    }
                                }, {})
                                return {
                                    ...acc,
                                    ...twoColumns
                                }
                            }, {})
                        }
                        if ( !canReport ) defaultObject['_disabled'] = true
                        return defaultObject
                    })
                    estimatedBudgetTableField.setValue(result2)
                    store.dispatch(processDynamicColumns({budgetTable: result, estimatedBudgetTable: result2}))
                }
            },
            dataLists: [
                "Budget-FiscalYear",
                "Budget-ImputationType",
                "Budget-MeetingsPlanning",
                "Budget-BudgetColumnType"
            ],
        }
    },
    actionSubscriptions: [
        // todo les budgets closed if nnewObject manjibhomch, if already existing object ghankhlihom w ndir _disabled: true
        // todo budgets open ghanjibhom
        // todo budgets ouverture provisoire manjibhomch
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store, t }) => {
                const state = store.getState()
                const language = _.get(state, 'ui.language')

                const budgetTableField = module.viewMap.form.fields.find(field => field.path === 'budgetTable')
                const estimatedBudgetTableField = module.viewMap.form.fields.find(field => field.path === 'estimatedBudgetTable')

                const fiscalYears = getDataListList(state, 'Budget-FiscalYear')

                const ongoingFiscalYears = fiscalYears.filter( fiscalYear => ['1', '3'].includes(fiscalYear.fiscalYearStatus.id))
                console.log('ongoingFiscalYears', ongoingFiscalYears.map(obj=>obj.code))

                const budgetTypes = getDataListList(state, 'Budget-ImputationType')

                const budgetColumnsTypes = getDataListList(state, 'Budget-BudgetColumnType')

                const budgetFollowUp = _.get(state, 'edit.object.data.budgetFollowUp', [])

                const estimatedBudgetFollowUp = _.get(state, 'edit.object.data.estimatedBudgetFollowUp', [])

                //ongoing li machi already existing
                const alreadyFilledFiscalYears = budgetFollowUp.map( data => data.fiscalYear )
                const desiredFiscalYears = [...ongoingFiscalYears, ...alreadyFilledFiscalYears]
                const uniqueDesiredFiscalYears = _.uniqBy(desiredFiscalYears, 'id');

                const meetingsPlannings = getDataListList(state, 'Budget-MeetingsPlanning')
                const ongoingMeetingsPlannings = meetingsPlannings.filter( obj => moment().isBetween(obj.meetingsPlanningRange[0], obj.meetingsPlanningRange[1], 'day', '[]'))
                const canReportMeetingsPlannings = meetingsPlannings.filter( obj => moment().isBetween(obj.budgetReportingPeriod[0], obj.budgetReportingPeriod[1], 'day', '[]'))
                const alreadyFilledMeetingsPlanning = estimatedBudgetFollowUp.map( data => data.meetingsPlanning )
                const desiredMeetingsPlannings = [...ongoingMeetingsPlannings, ...alreadyFilledMeetingsPlanning]
                const uniqueDesiredMeetingsPlannings = _.uniqBy(desiredMeetingsPlannings, 'id');

                const result = uniqueDesiredFiscalYears.map(fiscalYear => {
                    const isOngoingFiscalYear = ongoingFiscalYears.some(obj => obj.id.toString() === fiscalYear.id.toString());
                    const defaultObject = {
                        fiscalYear: fiscalYear.code,
                        types: budgetTypes.reduce((acc, type) => {
                            const defaultBudget =  budgetFollowUp.find(budget => {
                                const budgetFiscalYearId = _.get(budget, 'fiscalYear.id')
                                const budgetTypeId = _.get(budget, 'type.id')
                                return budgetFiscalYearId ===  fiscalYear.id && budgetTypeId ===  type.id
                            })
                            return {
                                ...acc,
                                [translateName(type.name, language)]: defaultBudget ? defaultBudget.amount : 0
                            }
                        }, {})
                    }
                    //if ( !isOngoingFiscalYear ) defaultObject['_disabled'] = true
                    return defaultObject
                })

                budgetTableField.setValue(result)
                console.log('uniqueDesiredMeetingsPlannings', uniqueDesiredMeetingsPlannings)
                const result2 = uniqueDesiredMeetingsPlannings.map(object => {
                    const canReport = canReportMeetingsPlannings.some(obj => obj.id.toString() === object.id.toString())
                    const defaultObject = {
                        fiscalYear: object.fiscalYear.code,
                        meetingsPlanning: object.code,
                        types: budgetTypes.reduce((acc, type) => {
                            const twoColumns = budgetColumnsTypes.reduce( (acc, columnType) => {
                                const defaultEstimatedBudget =  estimatedBudgetFollowUp.find(estimatedBudget => {
                                    const meetingsPlanningId = _.get(estimatedBudget, 'meetingsPlanning.id')
                                    const budgetTypeId = _.get(estimatedBudget, 'type.id')
                                    const columnTypeId = _.get(estimatedBudget, 'budgetColumnType.id')
                                    return meetingsPlanningId ===  object.id && budgetTypeId ===  type.id && columnTypeId ===  columnType.id
                                })
                                return {
                                    ...acc,
                                    [translateName(type.name, language)+' '+t(columnType.name)]: defaultEstimatedBudget ? defaultEstimatedBudget.amount : 0,
                                }
                            }, {})
                            return {
                                ...acc,
                                ...twoColumns
                            }
                        }, {})
                    }
                    if ( !canReport ) defaultObject['_disabled'] = true
                    return defaultObject
                })
                console.log('result2', result2)
                estimatedBudgetTableField.setValue(result2)
                store.dispatch(processDynamicColumns({budgetTable: result, estimatedBudgetTable: result2}))
            }
        }
    ],
    accesses: [
        {
            id: "Budget-FiscalYear",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearStatus.id'],
            filters: []
        },
        {
            id: "Budget-ImputationType",
            entity: "ImputationType",
            fieldPath: ['id', 'name'],
            filters: []
        },
        {
            id: "Budget-MeetingsPlanning",
            entity: "MeetingsPlanning",
            fieldPath: ['id', 'code', 'fiscalYear.id', 'fiscalYear.code', 'meetingsPlanningRange', 'budgetReportingPeriod'],
            filters: []
        },
        {
            id: "Budget-BudgetColumnType",
            entity: "BudgetColumnType",
            fieldPath: ['id', 'name'],
            filters: []
        }
    ],
    filters: []
}
