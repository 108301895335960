const _ = require("lodash");
const stream = require("stream");
const Transform = stream.Transform;
const async = require("async");

const MAX_ERROR_PER_FILE = 100;

class ContextTransform extends Transform {
    constructor(options) {
        super(options);
        this.group = options.context.group;
        this.queryWithGroup = query => ({ ...query, group: new global.ObjectID(options.context.group.id) });
    }
}

/**
 * Add index to the line: {lineIndex: i}
 */
class AddIndex extends Transform {
    constructor(options) {
        super(options);
        this.currentIndex = 1;
    }

    _transform(line, encoding, callback) {
        this.currentIndex++;
        line.lineIndex = this.currentIndex;
        callback(null, line);
    }
}

/**
 * Verify if requiredInFile columns are in the file
 */
class MinimalColumnsInFileValidator extends Transform {
    constructor(options, minimalKeys, errorArray) {
        super(options);
        this.errorArray = errorArray;
        this.minimalKeys = minimalKeys;
        this.firstLine = true;
    }

    _transform(line, encoding, callback) {
        if (this.firstLine) {
            this.firstLine = false;
            this.minimalKeys.forEach(key => {
                const lineError = (line[key] === undefined) ? {
                    type: "columnMissing",
                    column: key,
                    lineIndex: 1,
                    line: line,
                    columnValue: undefined
                } : undefined;
                if (lineError) this.errorArray.push(lineError);
            });
            if(this.errorArray.length) {
                callback(new Error("Columns are BAD."), line);
            } else{
                callback(null, line);
            }
        } else {
            callback(null, line);
        }
    }
}

class SetDeleteTagOnOldData extends Transform {
    constructor(options, dataCollection, query) {
        super(options);
        this.dataCollection = dataCollection;
        this.query = query;
        this.firstLine = true;
    }

    _transform(line, encoding, callback) {
        if (this.firstLine) {
            this.firstLine = false;
            global.db.collection(this.dataCollection).updateMany(
                this.query,
                {$set: {dataToDeleteAfterImport: true}},
                {},
                (e, result) => {
                    if(e) callback(new Error("There are errors when putting tag on old data"), line)
                    callback(null, line);
                })
        } else {
            callback(null, line);
        }
    }
}


/**
 * Verify if requiredInLine columns are not empty
 */
class MinimalValidator extends Transform {
    constructor(options, errorArray, minimalKeys) {
        super(options);
        this.errorArray = errorArray;
        this.minimalKeys = minimalKeys;
    }

    _transform(line, encoding, callback) {
        const validationErrors = _.map(this.minimalKeys, keyObject => {
            const key = keyObject.header;
            const lineError = (line[key] !== undefined && line[key] === "") ? {
                type: "columnDataMissing",
                column: key,
                lineIndex: line.lineIndex,
                line: line,
                columnValue: line[key]
            } : undefined;
            if (lineError) this.errorArray.push(lineError);
            return lineError;
        });

        if (_.compact(validationErrors).length > 0) callback();
        else callback(null, line);
    }
}


/**
 * Assign some data to the lines
 */
class WriteDataOnLines extends Transform {
    constructor(options, key, dataOrFunction) {
        super(options);
        this.key = key;
        this.dataOrFunction = dataOrFunction;
    }

    _transform(line, encoding, callback) {
        line[this.key] = _.isFunction(this.dataOrFunction) ? this.dataOrFunction(line) : this.dataOrFunction;
        callback(null, line);
    }
}

/**
 * Rename headers according to inputCorrespondences
 */
class RenameHeaders extends ContextTransform {
    constructor(options, headersCorrespondences) {
        super(options);
        this.headerDict = headersCorrespondences;
    }

    _transform(line, encoding, callback) {
        const newLine = _.mapKeys(line,
            (value, oldHeader) => (oldHeader !== "lineIndex" && this.headerDict[oldHeader]) ?
                this.headerDict[oldHeader] :
                oldHeader
        );
        callback(null, newLine);
    }
}

/**
 * Add Mesh elements data
 */
class AddMeshElementsToLine extends ContextTransform {
    constructor(options, errorArray, warningArray) {
        super(options);
        this.errorArray = errorArray;
        this.warningArray = warningArray;
        this.firstLine = true;
        this.elements = [];
        this.mesh = '';
    }

    _transform(line, options, callback) {
        async.series(
            [
                callback => {
                    if(this.firstLine) {
                        console.log('firstLine Mesh Element')
                        this.firstLine = false;
                        this.mesh = line.mesh;
                        switch (line.mesh) {
                            case 'F': // Subsidiary
                                global.db.collection("b.subsidiary")
                                    .find(this.queryWithGroup({}))
                                    .toArray((e, result) => {
                                        if(e) return callback(e);
                                        this.elements = result;
                                        callback()
                                    })
                                break
                            case 'P': // Country
                                global.db.collection("b.country")
                                    .find(this.queryWithGroup({}))
                                    .toArray((e, result) => {
                                        if(e) return callback(e);
                                        this.elements = result;
                                        callback()
                                    })
                                break
                            case 'M': //Shop
                                global.db.collection("b.shop")
                                    .find(this.queryWithGroup({}))
                                    .toArray((e, result) => {
                                        if(e) return callback(e);
                                        this.elements = result;
                                        callback()
                                    })
                                break
                            case 'B': // BPEndorsed
                                global.db.collection("b.bPEndorsed")
                                    .find(this.queryWithGroup({}))
                                    .toArray((e, result) => {
                                        if(e) return callback(e);
                                        this.elements = result;
                                        callback()
                                    })
                                break
                            case 'H': // HI
                                callback()
                                break
                            default:
                                callback()
                                break
                        }

                    }
                    else callback()

                },
                callback => {
                    if(this.mesh !== line.mesh) {
                        this.warningArray.push({
                            type: 'tooManyMesh',
                            column: 'Mesh',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: line.mesh
                        });
                    } else {
                        switch (line.mesh) {
                            case 'F': // Subsidiary
                                const subsidiary = this.elements.find(
                                    o => o.code === line.subsidiary
                                )
                                if(subsidiary) {
                                    line.subsidiary = subsidiary._id;
                                } else {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Subsidiary',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.subsidiary
                                    });
                                }

                                if(['bp', 'shop', 'country'].some(o => line[o] !== '')) {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Mesh',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.subsidiary
                                    });
                                }
                                break
                            case 'P': // Country
                                const country = this.elements.find(
                                    o => o.code === line.country
                                )
                                if(country) {
                                    line.country = country._id;
                                } else {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Country',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.country
                                    });
                                }

                                if(['bp', 'shop', 'subsidiary'].some(o => line[o] !== '')) {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Mesh',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.country
                                    });
                                }
                                break
                            case 'M': //Shop
                                const shop = this.elements.find(
                                    o => o.code === line.shop
                                )
                                if(shop) {
                                    line.shop = shop._id;
                                } else {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Shop',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.shop
                                    });
                                }

                                if(['bp', 'subsidiary', 'country'].some(o => line[o] !== '')) {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Mesh',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.shop
                                    });
                                }
                                break
                            case 'B': // BPEndorsed
                                const bp = this.elements.find(
                                    o => o.code === line.bp
                                )
                                if(bp) {
                                    line.bp = bp._id;
                                } else {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'bp',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.bp
                                    });
                                }

                                if(['shop', 'subsidiary', 'country'].some(o => line[o] !== '')) {
                                    this.warningArray.push({
                                        type: 'dataInconsistency',
                                        column: 'Mesh',
                                        lineIndex: line.lineIndex,
                                        line: line,
                                        columnValue: line.bp
                                    });
                                }
                                break
                            default:
                                break
                        }
                    }
                    callback()
                }
            ],
            e => {
                if(e) return callback(e)
                callback(null, line)
            }
        )
    }
}


/**
 * Add Mesh data
 */
class AddMeshToLine extends ContextTransform {
    constructor(options, errorArray, warningArray) {
        super(options);
        this.errorArray = errorArray;
        this.warningArray = warningArray;
        this.firstLine = true;
        this.meshs = [];
    }

    _transform(line, options, callback) {
        async.series(
            [
                callback => {
                    if(this.firstLine) {
                        console.log('firstLine MeshDataType')
                        this.firstLine = false;
                        global.app.B.MeshDataType.find(
                            this.queryWithGroup({}),
                            (e, result) => {
                                this.meshs = result;
                                callback()
                            }
                        )
                    }
                    else callback()
                },
                callback => {
                    const mesh = this.meshs.find(
                        o => o.code === line.mesh
                    )
                    if(mesh) {
                        line.mesh = mesh.id;
                    } else {
                        this.warningArray.push({
                            type: 'dataInconsistency',
                            column: 'Mesh',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: line.mesh
                        });
                    }
                    callback()
                }
            ],
            e => {
                if(e) return callback(e)
                callback(null, line)
            }
        )
    }
}

/**
 * Add Type data
 */
class AddTypeToLine extends ContextTransform {
    constructor(options, errorArray, warningArray) {
        super(options);
        this.errorArray = errorArray;
        this.warningArray = warningArray;
        this.firstLine = true;
        this.types = [];
    }

    _transform(line, options, callback) {
        async.series(
            [
                callback => {
                    if(this.firstLine) {
                        console.log('firstLine DataTypeImport')
                        this.firstLine = false;
                        global.app.B.DataTypeImport.find(
                            this.queryWithGroup({}),
                            (e, result) => {
                                this.types = result;
                                callback()
                            }
                        )
                    }
                    else callback()
                },
                callback => {
                    const type = this.types.find(
                        o => o.code === line.type
                    )
                    if(type) {
                        line.type = type.id;
                    } else {
                        this.warningArray.push({
                            type: 'dataInconsistency',
                            column: 'Type',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: line.type
                        });
                    }
                    callback()
                }
            ],
            e => {
                if(e) return callback(e)
                callback(null, line)
            }
        )
    }
}

/**
 * Add Source data
 */
class AddSourceToLine extends ContextTransform {
    constructor(options, errorArray, warningArray) {
        super(options);
        this.errorArray = errorArray;
        this.warningArray = warningArray;
        this.firstLine = true;
        this.sources = [];
    }

    _transform(line, options, callback) {
        async.series(
            [
                callback => {
                    if(this.firstLine) {
                        console.log('firstLine DataSource')
                        this.firstLine = false;
                        global.app.B.DataSource.find(
                            this.queryWithGroup({}),
                            (e, result) => {
                                this.sources = result;
                                callback()
                            }
                        )
                    }
                    else callback()
                },
                callback => {
                    const source = this.sources.find(
                        o => o.code === line.source
                    )
                    if(source) {
                        line.source = source.id;
                    } else {
                        this.warningArray.push({
                            type: 'dataInconsistency',
                            column: 'Source',
                            lineIndex: line.lineIndex,
                            line: line,
                            columnValue: line.source
                        });
                    }
                    callback()
                }
            ],
            e => {
                if(e) return callback(e)
                callback(null, line)
            }
        )
    }
}

/**
 * Cast values of the columns to its appropriate types
 */
class ValuesTypeCasting extends Transform {
    _transform(line, options, callback) {
        line.year = line.year !== '' ? _.toNumber(line.year) : null
        line.exercise = line.exercise !== '' ? _.toNumber(line.exercise) : null
        callback(null, line);
    }
}


/**
 * Write lines to the DB (and link them to the group in the context)
 */
class WriteDataToDb extends ContextTransform {
    constructor(options, errorArray, collectionName, minimalKeys, headersCorrespondences) {
        super(options);
        this.errorArray = errorArray;
        this.collectionName = collectionName;
        this.minimalKeys = minimalKeys;
        this.headersCorrespondences = headersCorrespondences;
        this.bufferToWrite = [];
    }

    _transform(line, encoding, callback) {
        if(this.errorArray.length > MAX_ERROR_PER_FILE) {
            console.log("There are too many errors in this file");
            this.end();
        }

        if (line.lineIndex % 100 === 0) console.log(line.lineIndex);

        const refHeaders = this.minimalKeys.map(o => this.headersCorrespondences[o])
        const referentialPart = _.pick(line, refHeaders)
        const dataPart = _.omit(line, [...refHeaders, '_id', 'lineIndex'])

        const dataToStore = Object.keys(dataPart).map(element => (
            {
                incomplete: true,
                ...referentialPart,
                name: element,
                value: _.toNumber(line[element]),
                ...this.queryWithGroup({})
            }
        ))

        this.bufferToWrite.push(...dataToStore);
        if (this.bufferToWrite.length >= 100) {
            global.db.collection(this.collectionName).insertMany(this.bufferToWrite, {}, (e) => {
                if (e) return callback(e);
                this.bufferToWrite = [];
                callback(null, _.cloneDeep(line));
            });
        }
        else {
            callback(null, _.cloneDeep(line));
        }
    }

    _flush(callback) {
        if (this.bufferToWrite.length > 0) {
            global.db.collection(this.collectionName).insertMany(this.bufferToWrite, {}, e => {
                if (e) return callback(e);
                this.bufferToWrite = [];
                callback(null);
            });
        }
        else {
            callback(null);
        }
    }
}


export {
    AddIndex, MinimalColumnsInFileValidator, MinimalValidator, SetDeleteTagOnOldData, WriteDataOnLines, RenameHeaders, AddMeshElementsToLine, AddMeshToLine, AddTypeToLine, AddSourceToLine, WriteDataToDb, ValuesTypeCasting
};
