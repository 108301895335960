import React, { Component } from 'react'
import ReactDOM from "react-dom"
import PropTypes from 'prop-types'
import VirtualizedTable from 'react-virtualized/dist/commonjs/Table'
import {defaultTableRowRenderer} from 'react-virtualized';
import {
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";
import _ from 'lodash'
import './Table.css'
import classnames from 'classnames'

export const HEADER_HEIGHT = 35
export const ROW_HEIGHT = 40

const tableHeightForRows = nRows => ROW_HEIGHT * nRows

export class Table extends Component {
    static propTypes = {
        children: PropTypes.array.isRequired,
        objects: PropTypes.array.isRequired,
        noRowsLabel: PropTypes.string,
        width: PropTypes.number.isRequired,
        onRowClick: PropTypes.func,

        // the table will have this height
        height: PropTypes.number,

        // the table will grow up to a number of rows
        maxRows: PropTypes.number,

        // the table will be as big as the number of rows
        autoGrow: function(props, propName, componentName) {
            const value = props[propName]
            if (!_.isUndefined(value) && !_.isBoolean(value)) {
                return new Error(
                    `Invalid props supplied to ${componentName}. ` +
                    `Should have a boolean prop ${propName}`
                );
            }

            if (!value && _.isUndefined(props.height || props.maxRows)) {
                return new Error(
                    `Invalid props supplied to ${componentName}. ` +
                    `Should have one of the props: height, maxRows or autoGrow.`
                );
            }
        }
    }

    getRowClass = ({ index }) => {
        const { objects } = this.props
        return classnames(
            'DataTable-Table-row',
            this.props.rowClassName,
            this.props.headerRowClassName,
            {
                editable: this.props.onRowClick,
                header: index === -1,
                first: index === 0,
                hover: !this.props.noHover,
                greenStyledRow: objects[index] && objects[index].orangeStyledRow
                    ? false
                    : objects[index] && objects[index].greenStyledRow,
                orangeStyledRow: objects[index] && objects[index].orangeStyledRow,
                redStyledRow: objects[index] && objects[index].redStyledRow,
            }
        )
    }

    renderNoRows = () => {
        const { noRowsLabel } = this.props
        return (
            <div className="DataTable-Table-noRows">
                {noRowsLabel || 'No rows...'}
            </div>
        )
    }

    rowRenderer = (object) => {

        const SortableRow = SortableElement(({i, ...props}) => {
            props.index = i;
            return defaultTableRowRenderer(props);
        })

        const {alerts} = this.props
        if(!alerts || alerts.length === 0) return this.props.sortableTable ? <SortableRow {...object} i={object.index}/> : defaultTableRowRenderer(object)

        for(let i=0; i < alerts.length; i++) {
            const alert = alerts[i].conditions.every((cond) => {
                const value = object.rowData[cond.path]
                switch (cond.rule) {
                    case 'eq':
                        return value === cond.value
                    case 'gt':
                        return value > cond.value
                    case 'gte':
                        return value >= cond.value
                    case 'lt':
                        return value < cond.value
                    case 'lte':
                        return value <= cond.value
                    case 'ne':
                        return value !== cond.value
                    default:
                        return false
                }
            })
            if(alert) return this.props.sortableTable ? <SortableRow {...object} className={classnames(object.className, alerts[i].alertType)} i={object.index}/> : defaultTableRowRenderer(object)
        }
        return this.props.sortableTable ? <SortableRow {...object} i={object.index}/> : defaultTableRowRenderer(object)
    }

    computeHeight = () => {
        //TODO
        // it's work arround, look why the objects is undefined
        const totalHeight = tableHeightForRows(this.props.objects ? this.props.objects.length : 0)
        if (this.props.autoGrow) return totalHeight

        const maxHeight = this.props.maxRows && tableHeightForRows(this.props.maxRows)

        const height = this.props.maxRows ? Math.min(totalHeight, maxHeight) : totalHeight

        const headerHeight = this.props.disableHeader ? 0 : HEADER_HEIGHT

        // the max is to ensure we see at least one line to show the no row message
        return Math.max(
            height + headerHeight,
            HEADER_HEIGHT + ROW_HEIGHT
        )
    }

    getHeight = () => this.props.height || this.computeHeight()

    render() {

        const Table = this.props.sortableTable
            ? SortableContainer(VirtualizedTable, {
                withRef: true
            })
            : VirtualizedTable

        return (
            <Table
                {...this.props}
                getContainer={wrappedInstance =>
                    ReactDOM.findDOMNode(wrappedInstance.Grid)
                }
                distance={5}
                height={this.getHeight()}
                headerHeight={this.props.headerHeight || HEADER_HEIGHT}
                gridClassName={`DataTable-Table-grid ${this.props.gridClassName}`}
                headerClassName={`DataTable-Table-header ${this.props.headerClassName}` }
                rowClassName={({index}) => this.getRowClass({index})}
                rowHeight={ROW_HEIGHT}
                rowCount={this.props.objects ? this.props.objects.length : 0}
                rowRenderer={this.props.rowRenderer || this.rowRenderer}
                rowGetter={({ index }) => this.props.objects[index]}
                noRowsRenderer={this.renderNoRows}
            >
                {this.props.children}
            </Table>
        )
    }
}

export default Table
