export const job = {
    name: 'initAccountModelUser',
    title: 'Init Account Model User',
    //cron: "5 1 * * *",
    single: true,
    execute: function(context, callback) {
        console.log('Init Account Model User')

        global.app.B.AccountModel.collection.updateMany(
            {},
            {
                $set: {
                    user: new global.ObjectID('61bc9933f568680012e0daf3')
                }
            }
        )

        callback(null, { message: 'Job ended !' })
    }
}
