export const module_ = {
    name: 'ReliefFundPayment',
    tKey: 'Paiement dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            {path: 'status', translate: true}
        ],
        form: [
            {path: 'object', editable: false},
            {path: 'totalAwardedAmount', tKey: 'awardedAmount'},
            {path: 'paymentDate', required: true},
            {path: 'bank', display: 'bank'},
            {path: 'note', type: 'richTextEditor'},
            {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
            {path: 'comments', hidden: true},
            {path: 'buttons', hidden: true}
        ]
    },
    filters: [
        'userHasAuthorization',
        {
            name: 'byStatusForDecision',
            query: () => {
                return { status: 'accepted'}
            }
        }
    ]
}
