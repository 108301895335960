import {EXPORT_TABLE_SUCCESS, EXPORT_STATE_SUCCESS} from '../actions/api'
const defaultState = {}

const file = (state = defaultState, action) => {
    switch (action.type) {
        case EXPORT_TABLE_SUCCESS:
            console.log('table export success')
            return state
        case EXPORT_STATE_SUCCESS: {
            console.log('state export success')
            return state
        }
        default:
            return state
    }
}

export default file
