const _ = require("lodash");
const moment = require("moment/moment")
const {basicContext} = require("../../../utils/contextUtils")

const DATE_FORMAT = 'YYYY-MM-DD'

export async function getRefundsByExercise(context) {
    const exerciseId =  _.get(context, 'data.exercise.id')
    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        fieldPath: ['id'],
        query: {exercise: global.ObjectID(exerciseId)}
    })

    return await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['id', 'reference'],
        query: {benefit: {$in: benefits.map(benefit => global.ObjectID(benefit.id))}}

    })
}

export async function getInvoicesByExercise(context) {

    const exerciseStartDate =  _.get(context, 'data.exercise.startDate')
    const exerciseEndDate =  _.get(context, 'data.exercise.endDate')

    const formattedStartDate =  moment(exerciseStartDate).format(DATE_FORMAT)
    const formattedEndDate =  moment(exerciseEndDate).format(DATE_FORMAT)

    return await global.app.R.Invoice.find({
        ...basicContext(context),
        fieldPath: ['id', 'wording'],
        query: {
            invoiceDate: {
                $gte: formattedStartDate,
                $lte: formattedEndDate
            }
        }
    })
}
