import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataTableBoardButton from './DataTableBoardButton'
import {Sliders} from 'react-feather'
import './Board.css'
import DataTableFilter from './DataTableFilter'
import ColumnSelector from './ColumnSelector'
import DownloadDataTable from './DownloadDataTable'
import {translate} from "react-i18next";
import DeleteListElements from "./DeleteListElements";
import ActionSelector from "./ActionSelector";

export class Board extends Component {
    render() {
        return (
            <div
                className="DataTable-Board"
            >
                <div className="DataTable-Board-left">
                    {this.props.changeFiltersVisibility && (
                        <DataTableBoardButton
                            onClick={this.props.changeFiltersVisibility}
                        >
                            <Sliders className="DataTable-Board-icon" color="#3f51b5e6" size={16} />
                        </DataTableBoardButton>
                    )}
                    {
                        !this.props.noLeftBoard &&
                        <ColumnSelector
                            options={this.props.selectorOptions}
                            opened={this.props.columnSelectorOpened}
                            open={this.props.openColumnSelector}
                            changeColumnSelection={
                                this.props.changeColumnSelection
                            }
                            t={this.props.t}
                        />
                    }
                    {
                        !this.props.noLeftBoard &&
                        <DownloadDataTable
                            objects={this.props.objects}
                            columns={this.props.columns.filter(
                                column => !column.disableExport
                            )}
                            exportTableObject={this.props.exportTableObject}
                            protectedExport={this.props.protectedExport}
                            user={this.props.user}
                            filename={this.props.moduleName}
                            language={this.props.language}
                        />
                    }
                    {
                        !!this.props.actions.length &&
                        <ActionSelector
                            actions={this.props.actions}
                            opened={this.props.actionSelectorOpened}
                            open={this.props.openActionSelector}
                            onClick={this.props.onActionClick}
                            moduleId={this.props.moduleId}
                        />
                    }
                    {
                        this.props.deleteListElements &&
                        (<DeleteListElements onClick={this.props.deleteListElements}/>)
                    }
                </div>
                <div className="DataTable-Board-right">
                    <DataTableFilter
                        value={this.props.filter}
                        onChange={this.props.changeFilter}
                        placeholder={this.props.searchPlaceholder}
                        tooltip={this.props.searchTooltip}
                    />
                </div>
            </div>
        )
    }
}

Board.propTypes = {
    actions: PropTypes.array,
    actionSelectorOpened: PropTypes.bool.isRequired,
    openActionSelector: PropTypes.func.isRequired,
    selectorOptions: PropTypes.array.isRequired,
    columnSelectorOpened: PropTypes.bool.isRequired,
    openColumnSelector: PropTypes.func.isRequired,
    changeColumnSelection: PropTypes.func.isRequired,
    objects: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    filter: PropTypes.string.isRequired,
    changeFilter: PropTypes.func.isRequired,
    changeFiltersVisibility: PropTypes.func,
    searchTooltip: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    moduleName: PropTypes.string,
    moduleId: PropTypes.string
}

export default translate('platform')(Board)
