import React from 'react'
import {FieldArray} from 'redux-form'
import Files from '../../../../components/Files'
import { I18n } from 'react-i18next'
import {getGroupModel, getModule, getUser} from '../../selectors'
import {connect} from 'react-redux'
import getFormValidations from './formValidations'
import ValidationUI from '../../../../components/ValidationUI'

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

// binding for redux-form
const VisualComponent = ({fields:{ push, remove, getAll }, meta: {submitFailed,  error}, placeholder, noRowsMessage, readOnly, editable, disabled, removable, displayEmpty, module, groupModel, user, t, language, style}) => {
    const objects = getAll()

    const allIds = objects && objects.map(o => o.id)
    const byId = arrayToObject(objects)
    const translatedError = t(error)

    return (
        <I18n ns={[module.model, 'platform']}>
            {t => {
                const files = getAll()
                return (!!files?.length || displayEmpty) && (
                    <div>
                        <Files
                            url="file"
                            groupModel={groupModel}
                            user={user}
                            byId={byId}
                            allIds={allIds}
                            push={push}
                            remove={remove}
                            t={t}
                            language={language}
                            placeholder={placeholder}
                            noRowsMessage={noRowsMessage}
                            editable={editable && !disabled}
                            removable={removable}
                            readOnly={readOnly}
                            style={style}
                        />
                        {submitFailed && error && <ValidationUI error={translatedError} />}
                    </div>
                )
            }}
        </I18n>

    )
}

const FilesField = ({ field, groupModel, module, user, objectMode, t, language }) => {
    const validations = getFormValidations(field, t)
    return (
        <FieldArray
            name={field.path}
            placeholder={field.placeholder}
            disabled={field.disabled}
            displayEmpty={field.displayEmpty}
            editable={!field.editable ? objectMode === 'newObject' : field.editable}
            readOnly={field.readOnly}
            removable={field.removable}
            noRowsMessage={field.noRowsMessage}
            style={field.style}
            component={VisualComponent}
            validate={validations}
            groupModel={groupModel}
            module={module}
            user={user}
            rerenderOnEveryChange={true}
            t={t}
            language={language}
        />
    )
}

const mapStateToProps = (state) => ({
    groupModel: getGroupModel(state),
    module: getModule(state),
    user: getUser(state),
})

export default connect(mapStateToProps)(FilesField)
