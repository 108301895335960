import _ from "lodash"
import {decrypt} from "../../../utils/crypto"
import path from "path";

export async function notifyMonitors (businessProject, oldObject, context) {
    if(!businessProject.monitoringTeamMembers?.length) {
        console.log("no monitors found")
        return
    }

    const addedMonitors = businessProject.monitoringTeamMembers.filter(
        teamMember => {
            if(!oldObject?.monitoringTeamMembers?.length) return true
            return !oldObject.monitoringTeamMembers.some(
                oldTeamMember => oldTeamMember.id === teamMember.id
            )
        }
    )

    if(!addedMonitors.length) {
        console.log("no added monitors found")
        return
    }

    const projectRef = businessProject.fullName

    const defaultMailElements = {
        from: '"EDFIN" <edfin@keenpoint.com>',
        replyTo: 'support@keenpoint.com',
        content: `monitorNotification.html`,
        subject: { template: `Affectation en tant que Responsable Suivi du Projet ${projectRef}`},
        signature: false,
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? "buildServer/server/models/edf-in/templates/mails"
                : "src/server/models/edf-in/templates/mails"
        ),
        verbose: {
            general: true,
        }
    }

    const userName = _.get(context, 'user.name')


    const mails = addedMonitors.map(teamMember => {
        return _.defaults({
            to: decrypt(teamMember.email),
            context: {
                firstName: teamMember.firstName,
                userName,
                projectRef
            }
        }, defaultMailElements)
    })

    return global.mailer.sendMail(mails, error => {
        if (error) {
            console.log("error sending emails")
            return
        }
        console.log(`A total of ${addedMonitors.length} monitors were notified`)
    })

}
