export const entity = {
    name: 'Function',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ]
}
export const module_ = {
        object: 'Function',
        tKey: 'mTitle_function',
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: ['code','name'],
            form: ['code','name']
        }
    }
