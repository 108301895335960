const _ = require('lodash')
const { setDataList } = require('../../../../apps/KpModule/actions')

export const module_ = {
    object: 'Batch',
    name: 'Control',
    tKey: 'mTitle_batchControl',
    objectIdentifier: 'reference',
    newable: false,
    removable: false,
    category: {
        path: 'bank',
        icon: 'repeat'
    },
    viewMap: {
        dt: [
            'reference',
            {path: 'process', translate: true},
            {path: 'amount', tKey: 'amount(€)'},
            {path: 'requestsReferences', initiallyNotVisible: true},
            {path: 'orderLinesLength', tKey: 'number'},
            {path: 'status', translate: true},
            {path: 'creationDate', tKey: 'dateAndTime'},
            {path: 'xlsxFile', tKey: 'xlsx', width: 50},
            {path: 'xmlFile', tKey: 'xml', width: 50}
        ],
        form: {
            fields: [
                {path: 'reference', disabled: true},
                {path: 'process', required: true, editable: false},
                {
                    path: 'orderLines',
                    fieldPath: ['id', 'fullReference', 'amount', 'processType'],
                    display: 'fullReference',
                    filters: ['selectedOnly'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            const amount = module.viewMap.form.fields.find(
                                field => field.path === 'amount'
                            )
                            const number = module.viewMap.form.fields.find(
                                field => field.path === 'orderLinesLength'
                            )

                            if (newValue) {
                                const total = newValue.reduce((acc, line) => acc + parseFloat(line.amount), 0)
                                amount.setValue(_.round(total, 2))
                                number.setValue(newValue.length)
                            }else{
                                amount.setValue(0)
                                number.setValue(0)
                            }
                        }
                    }
                },
                {path: 'amount', tKey: 'amount(€)', disabled: true},
                {path: 'orderLinesLength', tKey: 'number', disabled: true},
                'correctionEmail',
                {path: "buttons", hidden: true}
            ],
            onOpen: ({module, state, store}) => {
                const correctionEmail = module.viewMap.form.fields.find(
                    field => field.path === 'correctionEmail'
                )
                correctionEmail.setValue(true)

                const orderLines = state.edit.object.data.orderLines
                if(orderLines) {
                    store.dispatch(
                        setDataList(
                            "m-R-control.Batch_orderLines",
                            orderLines
                        )
                    )
                }

            }
        }
    },
    filters: [
        'byExercise',
        {
            path: "controlStep",
            match: function(request) {
                return 'toPay' === request.status
            }
        }
    ]
}
