const { forceFunction} = require("../../../utils/functional")
const Errors = require("../../../utils/Errors").default

const errorIfResult = (message, callback) => (e, result) => {
    const getMessage = forceFunction(message);
    if (e) return callback(e);
    if (result) return callback(new Errors.ValidationError(getMessage(result)));
    callback();
};

export const entities = [
    {
        name: 'Account',
        fields: [
            {path: 'accountNumber', unique: true},
            'accountWording',
            {path: 'key', type: 'integer'},
            'AnalyticalCode'
        ]
    },
    {
        name: 'AccountsTemplate',
        fields: [
            'AccountsGroup',
            {path: 'accounts', type: 'Account', link: 'OTM'},
            {
                path: 'accountsGroupFullName',
                fieldPath: ['accountsGroup.fullName'],
                f: function() {
                    return this.accountsGroup?.fullName
                }
            }
        ],
        isLinkedToInvoiceLine: function(accountsTemplate, context, callback) {
            console.log('template acc group', accountsTemplate.accountsGroup)
            global.app.C.InvoiceLine.get(
                {accountsGroup: new global.ObjectID(accountsTemplate.accountsGroup)},
                {ignoreNotFound: true, group: context.group},
                callback
            )
        },
        validateSave: function(newObject, oldObject, context, callback) {
            const keysTotal = newObject.accounts.reduce((acc, account) => acc + parseInt(account.key), 0)
            if(keysTotal !== 100) {
                callback(new Errors.ValidationError('keysValuesSumEquals100'))
            }else{
                callback()
            }
        },
        ps: {
            delete: [
                {
                    $$v: function (accountsTemplate, callback) {
                        global.app.C.AccountsTemplate.isLinkedToInvoiceLine(
                            accountsTemplate,
                            this.options.context,
                            errorIfResult("modelNotLinkedToInvoice", callback)
                        )
                    }
                }
            ]
        }
    }
]

export const module_ = {
    object: 'AccountsTemplate',
    tKey: 'mTitle_accountsTemplate',
    defaultSortBy: 'accountsGroup',
    objectIdentifier: 'accountsGroupFullName',
    defaultSortDirection: 'ASC',
    category: {
        path: 'accounting',
        icon: 'book'
    },
    viewMap: {
        dt: [
            {path: 'accountsGroup', display: 'fullName', required: true}
        ],
        form: [
            {path: 'accountsGroup', display: 'fullName'},
            {
                path: 'accounts',
                removable: true,
                viewMap: {
                    dt: [
                        {path: 'accountNumber', tKey: 'n°'},
                        'accountWording',
                        'key',
                        {path: 'analyticalCode', display: 'fullName'}
                    ],
                    form: [
                        {path: 'accountNumber', tKey: 'n°', required: true},
                        {path: 'accountWording', required: true},
                        {path: 'key', positiveNumber: true, required: true},
                        {path: 'analyticalCode', display: 'fullName'},
                    ]
                }
            },
            {path: 'accountsGroupFullName', hidden: true}
        ]
    }
}
