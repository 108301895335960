export const dependencies = [
    {
        name: 'SKUStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'name'],
        objects: [
            { id: 'disponible', name: 'disponible' },
            { id: 'expédié', name: 'expédié' },
            { id: 'réceptionné', name: 'réceptionné' },
        ]
    },
]

export const entity = {
    name: 'Sku',
    fields: [
        { path: 'code', index: true, unique: true },
        'Product',
        'Location',
        {path: 'status', type: 'SKUStatus'}
    ]
}

export const module_ = {
    object: 'Sku',
    tKey: 'mTitle_sku',
    category: {
        path: 'referential',
        icon: 'server'
    },
    maxResults: 2000,
    viewMap: {
        dt: ['code', { path: 'product', display: 'tFullName' }, {path: 'location', display: 'fullLocation'}, 'status'],
        form: [
            { path: 'code', editable: false, required: true },
            { path: 'product', display: 'tFullName', required: true },
            { path: 'location', display: 'fullLocation'},
            'status'
        ]
    }
}
