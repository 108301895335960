const _ = require('lodash')
const moment = require('moment');
const { directoryPath } = require('../templates/index')
const { basicContext } = require("../../../utils/contextUtils")
const { decrypt } = require("../../../utils/crypto")

export async function crrMail (businessProjectId, context) {

    const businessProject = await global.app.I.BusinessProject.get(businessProjectId, {...basicContext(context)})
    if (
        _.get(businessProject, "workflow.step") === "realisation" &&
        businessProject.sendReport &&
        context.module && ["bp5 bis", "projectMonitoring"].includes(context.module.name)
    ) {

        const project = {
            name: businessProject.name,
            country: businessProject.country.name,
            typeOfOffer: businessProject.typeOfOffer.name,
            customer: businessProject.customer.name,
            revueDate: moment(businessProject.revueDate).format("YYYY-MM-DD"),
            commercialTeamMember: businessProject.commercialTeamMember ? businessProject.commercialTeamMember.fullName : "",
            technicalTeamMember: businessProject.technicalTeamMember ? businessProject.technicalTeamMember.fullName : "",
            risk: businessProject.risk ? businessProject.risk.name : "",
            ..._.pick(businessProject, ["comment", "actuality", "deadlinesAndSchedule", "costs", "treasury"])
        }

        let attachments = []
        if (businessProject.sendReport && businessProject.crrFile){
            attachments.push({
                id: new global.ObjectID(businessProject.crrFile.id),
                filename: businessProject.crrFile.filename
            })
        }
        let users = businessProject.recipients.map(recipient => ({
            mail: decrypt(recipient.email),
            firstName: recipient.firstName
        }))

        businessProject.otherRecipients.trim().split(',').forEach(mail => {
            users.push({
                mail: mail,
                firstName: '',
            })
        })

        sendMail({
            users,
            subject: `Compte rendu Projet "${project.name} / ${project.country}"`,
            project,
            contextUser: context.user,
            template: 'crrMail.html',
            attachments
        })
    }
}

const sendMail = ({ users, project, subject, contextUser, template, attachments }) => {
    const logMessage = () => {
        console.log('...workflow Mailing')
    }
    users.forEach(user => {
        const mail = _.defaults(
            {
                to: user.mail,
                context: {
                    user,
                    contextUser,
                    project
                },
                content: template,
                subject: {
                    template: subject
                },
                templateDir: directoryPath.mails,
                attachments
            },
            defaultMail
        )

        global.mailer.sendMail(mail, logMessage)
    })
}

const defaultMail = {
    from: '"EDFIN" <edfin@keenpoint.com>',
    replyTo: 'edfin@keenpoint.com',
    verbose: {
        general: true
    }
}
