import React from "react";
import PropTypes from "prop-types";
import Calendar from "rc-calendar";
import Picker from "rc-calendar/lib/Picker";
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import enUS from "rc-calendar/lib/locale/en_US";
import frFR from "rc-calendar/lib/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import "rc-calendar/assets/index.css";
import "./DatePicker.css";
import {Calendar as CalendarIcon} from "react-feather";
import {StyledInput} from "../Form/StyledInput";
import ValidationUI from "../ValidationUI";
const timePickerElement = <TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} />

const DatePicker = props => {
  const {
    path,
    required,
    value,
    style,
    open,
    showTime,
    onChange,
    onOpenChange,
    disabledDate,
    language = "en",
    disabled,
    placeholder = "Pick a date...",
    dtObject,
    t
  } = props;

  if (language === "fr") {
    moment.locale("fr");
  } else {
    moment.locale("en-gb");
  }

  console.log('showTime', showTime)
  const FORMAT = showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"

  const now = moment();

  return (
    <Picker
      animation="slide-up"
      calendar={
        <Calendar
          locale={language === "fr" ? frFR : enUS}
          className="DatePicker-calendar"
          defaultValue={now}
          disabledDate={disabledDate}
          timePicker={showTime ? timePickerElement : null}
          formatter
        />
      }
      value={value ? moment(value) : null}
      onChange={date => onChange(date && date.format(FORMAT))}
      open={open}
      onOpenChange={onOpenChange}
      disabled={disabled}
    >
      {({ value }) => {
        return dtObject
            ? (
                <div className='input-group'>
                  <input
                      className='form-control'
                      style={{height: '29px', border: '1px solid #939bb4', ...style}}
                      placeholder={placeholder}
                      value={value ? value.format(FORMAT) : ""}
                  />
                  <span className="input-group-addon" style={{padding: '0px 5px', backgroundColor: 'bisque', border: '1px solid rgb(147, 155, 180)', borderLeft: 'none'}}>
                    <CalendarIcon size={18} />
                </span>
                </div>
            )
            : (
                <div className="DatePicker-container">
                  <StyledInput
                      path={path}
                      required={required}
                      placeholder={placeholder}
                      value={value ? value.format(FORMAT) : ""}
                      t={t}
                  />
                  {props.touched &&
                  props.error && <ValidationUI error={props.error} />}
                </div>
            )
      }}
    </Picker>
  );
};

DatePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabledDate: PropTypes.func
};

export default DatePicker;
