import React, {Component} from 'react'
const { toastr } = require('react-redux-toastr')
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { iconSelector } from '../../../../utils/iconSelector'
import Collapse from '../../../../components/Collapse'
import ExternalLink from './ExternalLink'
import classnames from 'classnames'
import _ from 'lodash'
import './GroupModel.css'
import {defaultT} from '../../../../utils/i18n'
import {addListeners, addToastrListener, subscribeUser, removeListeners} from '../../../../utils/socketAPI'
import NotificationBubble from '../../../../components/NotificationBubble'

export class GroupModel extends Component{
    constructor(props){
        super(props)
    }

    componentDidMount() {
        const userId = this.props.user.id
        const groupId = this.props.group.id
        const modelId = this.props.model.id
        const groupModelId = this.props.groupModel.id

        subscribeUser(userId, groupId, modelId, groupModelId)
        addListeners(userId, data => this.props.setNotifications(data[userId], groupModelId))
        addToastrListener(userId, message => {
            toastr.info(message, {
                timeOut: 60000
            })
        })
    }

    componentWillUnmount() {
        removeListeners()
    }

    /*
    * We need to respect the order that the modules were sent.
    * This is done by reducing the list of modules to an object containing all information needed
    * in the module.
    *
    * Magic object shape = {
    *   categories: [ String ] --> unique categories by order of appearance
    *   categoryData: {
    *       ...[category: String]: {
    *           modules: [Object] --> in order of appearance
    *           selected: Boolean
    *       }
    *   }
    * }
    * */

    render() {
        const {
            device,
            collapse,
            groupModel,
            setCategoryCollapse,
            selectedModuleId,
            selectedCategory,
            setSidebarOpen,
            notifications,
            user,
            selectedColor,
            t = defaultT
        } = this.props

        const magicObject = groupModel.modules.reduce(
            ({ categories, categoryData }, module) => {
                const category = module.category
                const path = category.path || category
                const categoryModules = _.get(categoryData, `${path}.modules`, [])

                return {
                    categories: categoryData[path] ? categories : [...categories, category],
                    categoryData: {
                        ...categoryData,
                        [path]: {
                            modules: [...categoryModules, module],
                            selected: (path === selectedCategory) || (module.id === selectedModuleId)
                        }
                    }
                }
            },
            { categories: [], categoryData: {} }
        )

        return (
            <div className="GroupModel">
                {magicObject.categories.map(category => {
                    const path = category.path || category
                    const categoryOpened = collapse[path]
                    const { selected, modules } = magicObject.categoryData[path]

                    const groupNotifications = modules.reduce((acc, module) => {
                        const notificationData = notifications[groupModel.id]
                        return acc + ( (notificationData && notificationData[module.id]) ? notificationData[module.id] : 0 )
                    }, 0)

                    return (
                        <div key={path} className={classnames({ active: selected || categoryOpened })}>
                            <div
                                className="GroupModel-category"
                                onClick={() => !selected && setCategoryCollapse(path, !categoryOpened)}
                            >
                                <div>
                                    {iconSelector(category.icon || category, 'GroupModel-category-icon')}
                                    {t(path)}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {
                                        groupNotifications && !categoryOpened && !selected ? <NotificationBubble count={groupNotifications}/> : null
                                    }
                                    {
                                        iconSelector(
                                            categoryOpened ? 'upChevron': 'downChevron',
                                            categoryOpened ? 'GroupModel-category-arrow-icon-active' : 'GroupModel-category-arrow-icon'
                                        )
                                    }
                                </div>
                            </div>

                            <Collapse opened={categoryOpened || selected}>
                                <ul className="GroupModel-moduleList">
                                    {modules.map(module => {
                                        const active =  selectedModuleId === module.id
                                        const linkColor = active ? (selectedColor || '#e94d24') : 'rgb(33 33 33 / 71%)'
                                        return module.externalLink
                                            ? <ExternalLink key={module.externalLink.link} externalLink={module.externalLink} user={user} groupModel={groupModel} t={t}/>
                                            : (
                                                <li
                                                    className={classnames(
                                                    "GroupModel-moduleList-li",
                                                        {
                                                            active: selectedModuleId === module.id
                                                        })
                                                    }
                                                    key={module.id} >
                                                    <Link
                                                        to={`/business/${groupModel.id}/${path}/${module.id}`}
                                                        onClick={() => device !== "desktop" && setSidebarOpen(false)}
                                                        style={{position: "relative", display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', color: linkColor}}
                                                    >
                                                        {t(module.tKey)}
                                                        {
                                                            notifications[groupModel.id] &&
                                                            !!notifications[groupModel.id][module.id] &&
                                                            <NotificationBubble active={selectedModuleId === module.id} count={notifications[groupModel.id][module.id]}/>
                                                        }
                                                    </Link>

                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </Collapse>
                        </div>
                    )
                })}
            </div>
        )
    }
}

GroupModel.propTypes = {
    device: PropTypes.string.isRequired,
    collapse: PropTypes.object.isRequired,
    groupModel: PropTypes.object.isRequired,
    selectedModuleId: PropTypes.string,
    selectedCategory: PropTypes.string,
    setCategoryCollapse: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default GroupModel
