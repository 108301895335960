const _ = require('lodash')
const moment = require('moment')

export const sum = arr => _.sum(arr.map(o => o.mt))
export const merge = (a, b) => _.merge(a, b)
export const mergeBy = (values, mesh) => {
    return Object.values(
        _.reduce(
            values,
            (acc, value) => {
                if (value[mesh]) {
                    const keys = _.without(Object.keys(value), mesh)
                    const object = keys.reduce((ac, key) => {
                        if (_.isObject(value[key])) {
                            const tmp =
                                acc[value[mesh]] && acc[value[mesh]][key]
                                    ? acc[value[mesh]][key]
                                    : {}
                            ac[key] = { ...value[key], ...tmp }
                        } else {
                            ac[key] = value[key]
                        }
                        return ac
                    }, {})
                    acc[value[mesh]] = {
                        ...acc[value[mesh]],
                        ...value,
                        ...object
                    }
                }
                return acc
            },
            []
        )
    )
}

export const fromArrayObjectToMapByCode = (array, mesh, element) =>
    array.reduce((acc, object) => {
        const key = _.isArray(mesh)
            ? mesh.reduce((acc, mesh) => {
                  return `${acc}${object[mesh]}`
              }, '')
            : object.mesh
        return {
            ...acc,
            [key]: object[element]
        }
    }, {})

export const applyOperation = (
    firstData,
    secondData,
    mesh,
    element,
    operation
) => {
    if (mesh) {
        const firstDataAsObject = fromArrayObjectToMapByCode(
            firstData,
            mesh,
            element
        )
        const secondDataAsObject = fromArrayObjectToMapByCode(
            secondData,
            mesh,
            element
        )

        const keys = [...firstData, ...secondData].map(o => {
            return _.pick(o, mesh)
        })
        const uniqKeys = _.uniqWith(keys, _.isEqual)

        return uniqKeys.map(o => {
            const key = mesh.reduce((acc, mesh) => {
                return `${acc}${o[mesh]}`
            }, '')
            const firstValue = firstDataAsObject[key]
                ? firstDataAsObject[key]
                : 0
            const secondValue = secondDataAsObject[key]
                ? secondDataAsObject[key]
                : 0
            let result = 0
            if (operation === 'addition') {
                result = firstValue + secondValue
            } else if (operation === 'substraction') {
                result = firstValue - secondValue
            }

            return {
                ...o,
                [element]: result
            }
        })
    } else {
        let result = []
        const firstValue = firstData[0] ? firstData[0].value : 0
        const secondValue = secondData[0] ? secondData[0].value : 0
        if (operation === 'addition') {
            result.push({ [element]: firstValue + secondValue })
        } else if (operation === 'substraction') {
            result.push({ [element]: firstValue - secondValue })
        }
        return result
    }
}

export const monthExerciseStart = '07'

export const getExerciseLastMonth = currentMonth => {
    const exercise = exerciseFromMonth(currentMonth)
    return moment(`${exercise}-${monthExerciseStart}-01`, 'YYYY-MM-DD')
        .subtract(1, 'month')
        .add(1, 'years')
        .format('YYYY-MM')
}

export const getExerciseFirstMonth = currentMonth => {
    const exercise = exerciseFromMonth(currentMonth)
    return`${exercise}-${monthExerciseStart}`
}

export const getExerciseRangOfMonths = currentMonth => {
    const firstMonth = getExerciseFirstMonth(currentMonth)
    const lastMonth = getExerciseLastMonth(currentMonth)
    return getRangeMonth(firstMonth, lastMonth)
}

export const getPreviousExerciseLastMonth = currentMonth => {
    const exercise = exerciseFromMonth(currentMonth)
    return moment(`${exercise}-${monthExerciseStart}-01`, 'YYYY-MM-DD')
        .subtract(1, 'month')
        .format('YYYY-MM')
}
export const exerciseFromMonth = exerciseMonth => {
    const date = moment(`${exerciseMonth}-01`, 'YYYY-MM-DD')
    const month = date.month() + 1
    const year = date.year()
    const result = month < monthExerciseStart ? year - 1 : year

    return result.toString()
}

export const getRangeMonth = (startMonth, endMonth) => {
    let startDate = moment(`${startMonth}-01`, 'YYYY-MM-DD')
    const endDate = moment(`${endMonth}-01`, 'YYYY-MM-DD')
    let out = [startMonth]

    while (startDate.isBefore(endDate)) {
        startDate = startDate.add(1, 'month')
        out.push(startDate.format('YYYY-MM'))
    }
    return out
}

export const getExerciseRangOfMonthsFromMonth = currentMonth => {
    const lastMonth = getExerciseLastMonth(currentMonth)
    return getRangeMonth(currentMonth, lastMonth)
}

export const getRangeOfMonthsFromExerciseStart = endMonth => {
    const exercise = exerciseFromMonth(endMonth)
    const startMonth = `${exercise}-${monthExerciseStart}`
    return getRangeMonth(startMonth, endMonth)
}

export const getPreviousMonth = month => {
    return moment(`${month}-01`, 'YYYY-MM-DD')
        .subtract(1, 'month')
        .format('YYYY-MM')
}

export const getNextMonth = month => {
    return moment(`${month}-01`, 'YYYY-MM-DD')
        .add(1, 'month')
        .format('YYYY-MM')
}

export const getPreviousYearMonth = month => {
    return moment(`${month}-01`, 'YYYY-MM-DD')
        .subtract(1, 'years')
        .format('YYYY-MM')
}

export const addElementToChunk = (data, chunk, source, title) => {
    return data.map(o => ({
        ...o,
        [chunk]: {
            ...o[chunk],
            [title]: source[o.mesh]
        }
    }))
}

export const calculatePercentEvolutionByElement = (
    data,
    chunk,
    target,
    element,
    title
) => {
    return data.map(o => ({
        ...o,
        [chunk]: {
            ...o[chunk],
            [title]:
                o[chunk][target] && element
                    ? Number.parseFloat(
                          o[chunk][target] / element * 100
                      ).toFixed(0) + '%'
                    : '-'
        }
    }))
}

export const calculatePercentEvolution = (data, chunk, start, end, title) => {
    return data.map(o => ({
        ...o,
        [chunk]: {
            ...o[chunk],
            [title]:
                o[chunk][end] && o[chunk][start]
                    ? Number.parseFloat(
                          (o[chunk][end] - o[chunk][start]) /
                              Math.abs(o[chunk][start]) *
                              100
                      ).toFixed(0) + '%'
                    : '-'
        }
    }))
}

export const calculatePercentEvolutionGlobal = (data, chunk, start, end, title) => {
    return data.reduce((acc, object) => {
        if(object[chunk][end] && object[chunk][start]) {
            acc.push({
                ...object,
                [chunk]: {
                    ...object[chunk],
                    [title]:
                        object[chunk][end] && object[chunk][start]
                            ? Number.parseFloat(
                            (object[chunk][end] - object[chunk][start]) /
                            Math.abs(object[chunk][start]) *
                            100
                        ).toFixed(0) + '%'
                            : '-'
                }
            })
        }
        return acc
    }, [])
}

export const calculatePercentOverChunks = (
    data,
    targetChunk,
    title,
    mainChunk,
    mainChunkElement,
    secondaryChunk,
    secondaryChunkElement
) => {
    return data.map(o => ({
        ...o,
        [targetChunk]: {
            ...o[targetChunk],
            [title]:
                _.get(o, `${mainChunk}.${mainChunkElement}`) &&
                _.get(o, `${secondaryChunk}.${secondaryChunkElement}`)
                    ? Number.parseFloat(
                          o[mainChunk][mainChunkElement] /
                              o[secondaryChunk][secondaryChunkElement] *
                              100
                      ).toFixed(0) + '%'
                    : '-'
        }
    }))
}

export const calculateValueEvolution = (data, chunk, start, end, title) => {
    return data.map(o => ({
        ...o,
        [chunk]: {
            ...o[chunk],
            [title]:
                o[chunk][end] && o[chunk][start]
                    ? Math.floor(o[chunk][end] - o[chunk][start])
                    : '-'
        }
    }))
}

export const getKeyFromObject = (object, headers) => {
    return Array.isArray(headers)
        ? headers.reduce((acc, value) => {
              return acc === '' ? object[value] : `${acc}-${object[value]}`
          }, '')
        : headers
}

export const formatData = (
    data,
    mesh,
    columnHeaders,
    cellValue,
    dataType
) => {
    // const mesh = 'family'
    // const columnHeader = 'month'
    // const cellValue = 'quantity'
    // const dataType = 'firstChunk'

    return Object.values(
        data.reduce((acc, object) => {
            const customMesh = mesh ? object[mesh] : 'Total'
            const values = acc[customMesh]
                ? {
                      ...acc[mesh ? object[mesh] : 'Total'],
                      [dataType]: {
                          ...acc[mesh ? object[mesh] : 'Total'][dataType],
                          [getKeyFromObject(object, columnHeaders)]: object[
                              cellValue
                          ]
                              ? Math.floor(object[cellValue])
                              : 0
                      }
                  }
                : {
                      mesh: mesh ? object[mesh] : 'Total',
                      [dataType]: {
                          [getKeyFromObject(object, columnHeaders)]: object[
                              cellValue
                          ]
                              ? Math.floor(object[cellValue])
                              : 0
                      }
                  }

            return {
                ...acc,
                [mesh ? object[mesh] : 'Total']: values
            }
        }, {})
    )
}

export const getAggregateGroupBy = mailles => {
    return mailles.reduce((acc, o) => ({ ...acc, [o]: '$' + o }), {})
}


export const getAggregateColumns = columns => {
    return columns.reduce((acc, o) => ({ ...acc, [o]: { $sum: '$' + o } }), {})
}

export const getConditions = (conditions, resultAsArray) =>
    conditions.reduce((acc, o) => {
        if (Array.isArray(o)) {
            return { ...acc, $or: getConditions(o, true) }
        } else {
            return resultAsArray
                ? [...acc, { [o.column]: { ['$' + o.operation]: o.value } }]
                : { ...acc, [o.column]: { ['$' + o.operation]: o.value } }
        }
    }, resultAsArray ? [] : {})

export const getElementProjection = (element, byCompany) => {
    return element
        ? {
              [element]: {
                  $concat: byCompany
                      ? [
                          '$_id.company',
                          '-',
                          `$_id.${element}`
                      ]
                      : [
                          `$_id.${element}`
                      ]

              }
          }
        : {}
}

export const getElementForMeshEntity = (
    meshEntity,
    meshEntityHeader
) => {
    let query = []

    if (meshEntity) {
        query = [
            {
                $lookup: {
                    from: `u.${meshEntity}`,
                    localField: meshEntityHeader,
                    foreignField: 'code',
                    as: meshEntity
                }
            },
            {
                $addFields: {
                    [meshEntity]: { $arrayElemAt: [`$${meshEntity}`, 0] }
                }
            }
        ]
    }

    return query
}

export const getCompanyAggregation = byCompany => {
    return byCompany ? {company: "$articleCompanyCode"} : {}
}

export const getLastMonthOfData = async currentMonth => {
    const lastMonth = getExerciseLastMonth(currentMonth)
    const months = getRangeMonth(currentMonth, lastMonth).reverse()

    let result = currentMonth
    let i = 0;
    while ((i < months.length)) {
        const month = months[i]
        const data = await global.db.collection('u.elementaryData').findOne({fileYearMonth: month})
        if(!!data) {
            result = month
            break;
        }
        i++;
    }
    return result
}

export const getLastMonthOfLastFile = async currentMonth => {

    const firstMonth = getExerciseFirstMonth(currentMonth)
    const months = getRangeMonth(firstMonth, currentMonth).reverse()

    let result = currentMonth
    let i = 0;
    while ((i < months.length)) {
        const month = months[i]
        const data = await global.db.collection('u.elementaryData').findOne({fileYearMonth: month})
        if(!!data) {
            result = month
            break;
        }
        i++;
    }
    return result
}

export const data = async (
    mesh,
    conditions,
    aggregation,
    currentContext,
    element
) => {
    const {meshEntityHeader, byCompany} = element
        ? element
        : {meshEntityHeader: undefined, byCompany: undefined}
    const query = [
        {
            $match: {
                ...currentContext,
                ...getConditions(conditions)
            }
        },
        {
            $group: {
                _id: {
                    ...getAggregateGroupBy(mesh),
                    ...getCompanyAggregation(byCompany)
                },
                ...getAggregateColumns(
                    Array.isArray(aggregation)
                        ? aggregation
                        : aggregation.columns
                )
            }
        },
        {
            $project: {
                ...getAggregateGroupBy(mesh),
                ...getElementProjection(meshEntityHeader, byCompany),
                ...(Array.isArray(aggregation)
                    ? getAggregateGroupBy(aggregation)
                    : {
                          [aggregation.header]: {
                              [aggregation.operator]: aggregation.columns.map(
                                  col => '$' + col
                              )
                          }
                      })
            }
        }
    ]

    const elemDatas = await global.db
        .collection('u.elementaryData')
        .aggregate(_.compact(query))
        .toArray()

    return elemDatas.map(o => ({
        ...o._id,
        ..._.pick(o, meshEntityHeader),
        ..._.pick(
            o,
            Array.isArray(aggregation) ? aggregation : aggregation.header
        )
    }))
}
