const _ = require('lodash')
const {getRefundsByExercise, getInvoicesByExercise} = require('../utils/refundAndInvoiceUtils')

export const entity =  {
    name: 'BankFlow',
    fields: [
        'bank',
        'process',
        'recipient',
        {path: 'reason', unique: true},
        {path: 'IBAN', encrypted: true},
        {path: 'BIC', encrypted: true},
        {path: 'amount', type: 'decimal'},
        'generated',
        'status',
        'transmissionDate',
        'fileName',
        'reference',
        {
            path: "fullReference",
            fieldPath: ["recipient", "reason"],
            f: function () {
                return `${this.recipient} - ${this.reason}`
            }
        }
    ],
    filters: [
        {
            name: 'byExercise',
            path: 'exercise',
            object: 'Exercise',
            filters: ['notProvisionallyClosed'],
            fieldPath: ['startDate', 'endDate'],
            display: 'code',
            sortList: false,
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            query: async (context, callback) => {
                try {
                    const [refunds, invoices] = await Promise.all([getRefundsByExercise(context), getInvoicesByExercise(context)])

                    return callback(
                        null,
                        {
                            reason: {
                                $in: _.concat(
                                    refunds.map(request => request.reference),
                                    invoices.map(invoice => invoice.wording)
                                )
                            }
                        }
                    )

                } catch (error) {
                    callback(error)
                }
            }
        },
        {
            name: 'byProcessType',
            isDefault: false,
            query: function (context) {
                const processType = _.get(context , "data.processType")
                return {processType}
            },
        },
        {
            name: 'byStatus',
            isDefault: false,
            query: function (context) {
                const statutes = _.get(context , "data.statutes")
                return statutes
                    ? {status: {$in: statutes}}
                    : {}
            },
        },
        {
            name: "selectedOnly",
            isDefault: false,
            query: function (context) {
                const orderLines = _.get(context , "data.orderLines");
                return orderLines
                    ? {_id: {$in: orderLines.map( line => global.ObjectID(line.id))}}
                    : {_id: null}
            }
        },
    ]
}

export const module_ = {
    object: 'BankFlow',
    tKey: 'mTitle_transferOrders',
    removable: false,
    protectedExport: true,
    displayLog: false,
    category: {
        path: 'bank',
        icon: 'repeat'
    },
    viewMap: {
        dt: [
            {path: 'process'},
            {path: 'bank'},
            {path: 'recipient'},
            {path: 'reason'},
            {path: 'IBAN'},
            {path: 'BIC'},
            {path: 'amount', tKey: 'amount(€)'},
            {path: 'generated'},
            {path: 'reference'},
            {path: 'status', translate: true},
            {path: 'transmissionDate', initiallyNotVisible: true},
            {path: 'fileName', initiallyNotVisible: true},
        ]
    },
    filters: ['byExercise']
}
