const _ = require('lodash')

async function findData(context) {
    const language = _.get(context, 'language.id')
    return [
        {
            id: 'animatedVideo',
            link: `dashboard01_${language}_animatedVideo.mp4`,
            poster: `dashboard01_${language}_videoPoster.png`
        }
    ]
}
export const entity = {
    name: 'AnimatedVideo',
    dashboard: true,
    fields: ['id', 'link', 'poster'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
