const steps = [ "preValidation", "demand", "control", "validation", "realization"]

export function getRoleWorkflowConfigs(configs= [], role) {
    return configs.filter( config => config.role.id === role.id)
}
export function getStepWorkflowConfig(configs, step) {
    return configs.find(config => config.step === step)
}

export function thereIsAtLeastOneInDemand (configs) {
    for (let i = 0; i < configs.length; i++) {
        if (configs[i]['step'] === 'demand'){
            return true
        }
    }
    return false
}
export function thereIsAtLeastOneInPreValidation (configs) {
    for (let i = 0; i < configs.length; i++) {
        if (configs[i]['step'] === 'preValidation'){
            return true
        }
    }
    return false
}

export function thereIsAtLeastOneInValidation (configs) {
    for (let i = 0; i < configs.length; i++) {
        if (configs[i]['step'] === 'validation'){
            return true
        }
    }
    return false
}

export function generateMaxOrder (configs) {
    let maxOrder = {}
    configs.forEach( config => {
        if (!maxOrder[config.step]) {
            maxOrder[config.step] = config.order
        }
        else if ( config.order > maxOrder[config.step] ){
            maxOrder[config.step] = config.order
        }
    })
    return maxOrder
}
export function generateOrderOfSteps (steps, workFlowConfigs) {
    let orderOfSteps = {}
    let c= 1
    steps.forEach( step => {
        for (let i = 0; i < workFlowConfigs.length; i++) {
            if (workFlowConfigs[i].step===step){
                orderOfSteps[step] = c
                c+=1
                break
            }
        }
    })
    return orderOfSteps
}

export function correctStepAndOrder(currentStep, currentOrder, workflowConfigs, alreadyTreatedByFunctions){
    const maxOrderByStep = generateMaxOrder(workflowConfigs)                  // {"step":order, ...}
    const orderOfSteps = generateOrderOfSteps(steps, workflowConfigs)   //{"step":classement}
    let [nextStep, nextOrder] = [null, null]
    if ( maxOrderByStep[currentStep] ){
        for ( let i = 1; i <= maxOrderByStep[currentStep]; i++){
            const thisOrderConfigs = workflowConfigs.filter(conf => conf.step === currentStep && conf.order === i)
            const thisOrderRolesIDs = thisOrderConfigs.map(conf => global.ObjectID(conf.role.id).toString() )
            if ( !alreadyTreatedByFunctions[currentStep] || !alreadyTreatedByFunctions[currentStep].flat(1).some( roleID => thisOrderRolesIDs.includes(roleID) ) ){
                [nextStep, nextOrder] = [currentStep, i]
                break
            }
        }
    }
    if ( !nextStep || !nextOrder ){ //si on supprime l'étape du workflow
        if ( currentStep === "control" && !orderOfSteps[currentStep]) {
            return ["validation", 1]
        }
        else if ( currentStep === "preValidation" && !orderOfSteps[currentStep]) {
            return [currentStep, currentOrder]
        }
        else if ( currentStep === "realization" && !orderOfSteps[currentStep]){
            return [currentStep, currentOrder]
        }
        else {
            nextStep = Object.keys(orderOfSteps)[orderOfSteps[currentStep]]
            nextOrder = 1
            if ( !!nextStep && !!nextOrder){
                return [nextStep, nextOrder]
            }
            else if ( currentStep === 'validation') {return ['approved',1000000]}
            else if ( currentStep === 'realization') {return ['closed',1000000]}
        }
    }
    else {
        return [nextStep, nextOrder]
    }
}
export function getPreviousStepPreviousOrder(currentStep, currentOrder, workFlowConfigs){
    const maxOrderByStep = generateMaxOrder(workFlowConfigs)                  // {"step":order, ...}
    const orderOfSteps = generateOrderOfSteps(steps, workFlowConfigs)   //{"step":classement}
    if (currentStep === null) return [null, null]
    if ( currentOrder > 1){
        return [currentStep, currentOrder-1]
    }
    else {
        if (orderOfSteps[currentStep]){
            return [currentStep, currentOrder]
        }
        else {
            let previousStep = currentStep
            while ( !orderOfSteps[previousStep] ){
                previousStep = steps[steps.indexOf(previousStep)-1]
            }
            return [previousStep, maxOrderByStep[previousStep]]
        }
    }
}
export function ordersAreStrictPositive (configs) {
    for (let j = 0; j < configs.length; j++) {
        if (configs[j]['order'] < 1){
            return false
        }
    }
    return true
}

export function ordersVerification (configs, step, order) {
    for (let j = 0; j < configs.length; j++) {
        if (configs[j]['step'] === step && configs[j]['order'] === order){
            return true
        }
    }
    return false
}

export function areOrdersContinuous (configs) {
    const maxOrder = generateMaxOrder (configs)
    for (let l= 0; l < Object.keys(maxOrder).length; l++){
        for (let i = 1; i < maxOrder[Object.keys(maxOrder)[l]]; i++){
            if ( !ordersVerification(configs, Object.keys(maxOrder)[l], i) ) {
                return false
            }
        }
    }
    return true
}

export function organizationsHaveSameAxis(orgs){
    let joinedEntities = []
    orgs.forEach( org => {
        if ( !joinedEntities.includes(org.joinedEntity) ){
            joinedEntities.push(org.joinedEntity)
        }
    })
    if (joinedEntities.length >= 2){
        return false
    }
    else if ( joinedEntities[0] === 'OrganizationalMesh'){
        return true
    }
    else if ( joinedEntities[0] === 'Organization'){
        let axes = []
        orgs.forEach( org => {
            if ( org.organizationalAxis._id && !axes.includes(org.organizationalAxis._id.toString()) ){
                axes.push(org.organizationalAxis._id.toString())
            }
            else if ( org.organizationalAxis.id && !axes.includes(org.organizationalAxis.id.toString()) ){
                axes.push(org.organizationalAxis.id.toString())
            }
        })
        if (axes.length === 1){
            return true
        }
        else {return false}
    }
}

export function substractedItems(contributorsFunctions, correctRolesIDs) {
    let result = []
    if ( !!contributorsFunctions.length ){
        const contributorsFunctionsStrings = contributorsFunctions.map( id => id.toString() )
        const correctRolesIDsStrings = correctRolesIDs.map( id => id.toString() )
        contributorsFunctionsStrings.forEach( id => {
            if ( !correctRolesIDsStrings.includes(id) ){
                result.push( global.ObjectID(id) )
            }
        })
        return result
    }
    else {
        return result
    }
}
export function getNextStepStatus(nextStep) {
    if (nextStep==='demand'){
        return '10'
    } else if (nextStep==='control'){
        return '2'
    } else if (nextStep==='validation'){
        return '3'
    } else if (nextStep==='approved'){
        return '6'
    } else if (nextStep==='realization'){
        return '7'
    } else if (nextStep==='closed'){
        return '8'
    } else if (nextStep==='preValidation'){
        return '12'
    }
}
export function getStatusName2(StatusID) {
    if (StatusID==='10'){
        return 'demande'
    } else if (StatusID==='2'){
        return 'contôle'
    } else if (StatusID==='3'){
        return 'validation'
    } else if (StatusID==='7'){
        return 'réalisation'
    } else if (StatusID==='4'){
        return "demande d'information"
    } else if (StatusID==='11'){
        return "arbitrage"
    } else if (StatusID==='12'){
        return "pré-validation"
    }
}
