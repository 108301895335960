const moment = require("moment");
const _ = require("lodash");

const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

export const dependencies = [
    {
        name: "TreatmentType",
        type: "static",
        // facets: ["translatedField"],
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {id: "imp", tKey: "fileImport"}
        ]
    }
]

export const entity =  {
    name: "ImportLog",
    facets: [{name: "files", linkType: "OTO"}],
    fields: [
        {
            path: "treatmentDate",
            type: "date",
            index: true,
            dateFormat: "YYYY-MM-DD HH-mm-ss"
        },
        {type: "ImportConf", nullable: true},
        "TreatmentType",
        {path: "success", type: "boolean"},
        {type: "ImportType", nullable: true},
        "fileName"
    ],

    successLogsForDate: function(date, callback) {
        const beginDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        const endDate = moment(beginDate).add(1, "days").toDate();
        this.collection.find({treatmentDate: {$gte: beginDate, $lt: endDate}, success: true}).sort({treatmentDate: -1}).limit(1).toArray(callback);
    }
}

export const module_ = {
    object: "ImportLog",
    category: {
        path: "dataflow",
        icon: 'sliders'
    },
    removable: false,
    defaultSortBy: "treatmentDate",
    defaultSortDirection: "DESC",
    viewMap: {
        dt: [
            {path: "treatmentDate", tKey: "date"},
            {path: "importType", tKey: "fileType", display: "tKey", translate: true, width: 150},
            {path: "fileName", tKey: "fileName", width: 250},
            "success",
            "file"
        ]
    },
    filters: [
        {
            path: "dates",
            type: "dateRange",
            ranges: "C",
            client: true,
            default: [lastWeekISO, todayISO],
            query: function(context) {
                if (!_.get(context, 'data.dates[0]') && !_.get(context, 'data.dates[1]')) {
                    console.warn('skipping date filter on import logs')
                    return {}
                }

                const dates = context.data.dates.map(
                    date => moment.utc(date.trim(), "YYYY-MM-DD")
                );

                return {
                    treatmentDate: {
                        $gte: dates[0].toDate(),
                        $lte: dates[1].add(1, "days").toDate()
                    }
                };
            }
        },
    ],
    newable: false
}
