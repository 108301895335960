const moment = require('moment/moment')
const async = require('async')
const _ = require('lodash')
const Errors = require("../../../utils/Errors").default
const { basicContext } = require("../../../utils/contextUtils");
const {returnButton} = require('../utils')
const {generateExcel} = require('../utils/generateExcel')
const {writeXml} = require('../utils/writeXml')
const {validateForXmlGeneration} = require('../utils/validateXml')

const updateMany = function(entity, query, set, push, callback) {
    const pushQuery = push ? {$push: push} : {}
    const collection = global.app.C[entity].collection
    collection.updateMany(
        query,
        {$set: set, ...pushQuery},
        (e) => {
            if (e) {
                console.warn(e)
                callback(e)
            } else {
                callback()
            }
        }
    )
}
export const entity = {
    name: 'Batch',
    facets: [
        {name: 'files', linkType: 'OTO', path: 'xmlFile'},
        {name: 'files', linkType: 'OTO', path: 'xlsxFile'}
    ],
    fields: [
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "c.batchSeq",
                    formatResult: result => `${result}`
                }]
            }
        },
        'reference',
        {path:'orderLines', type: 'BankFlow', link: 'MTM', nullable: true},
        {path: 'amount', type: 'financial'},
        'orderLinesLength',
        'status',
        'creationDate',
        'transmissionDate',
        'paymentDate',
        'fileName',
        {path: 'correctionEmail', type: 'boolean'},
        {
            path: "buttons",
            $f: function (lot, context, callback) {
                if('toPay' === lot.status  && context.clientContext.moduleId === 'm-C-control') {
                    callback(null, [{type: 'action', tKey: 'controlled', bsStyle: 'warning', action: 'control'}, returnButton])
                } else if('controlled' === lot.status && context.clientContext.moduleId === 'm-C-payment') {
                    callback(null, [{type: 'action', tKey: 'paid', bsStyle: 'primary', action: 'pay'}, returnButton])
                } else {
                    callback(null, [returnButton])
                }
            }
        }
    ],
    filters: [
        {
            name: 'byExercise',
            path: 'exercise',
            object: 'Exercise',
            filters: ['notProvisionallyClosed'],
            fieldPath: ['startDate', 'endDate'],
            objectFieldPath: ['orderLines.id', 'orderLines.reason'],
            sortList: false,
            display: 'code',
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            match: (object, context, callback) => {
                const exerciseStartDate =  _.get(context, 'data.exercise.startDate')
                const exerciseEndDate =  _.get(context, 'data.exercise.endDate')

                const objectProcessRefs = object.orderLines.map(orderLine => orderLine.reason)

                global.app.C.Invoice.find({
                    ...basicContext(context),
                    fieldPath: ['invoiceDate'],
                    query: { wording: { $in: objectProcessRefs } },
                }, (e, invoices) => {
                    if(e) return callback(e)
                    callback(null, invoices.some(invoice => {
                        if(!invoice) return false

                        return invoice.invoiceDate >= exerciseStartDate && invoice.invoiceDate <= exerciseEndDate
                    }))

                })
            }
        },
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "sequence"
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    lotMustNotContainDoubles: (newObject, oldObject, context, callback) => {
        const reasons = newObject.orderLines.map(ol => ol.reason)

        const hasDoubles = reasons.some((reason, index) => reasons.indexOf(reason) !== index)

        if(hasDoubles) {
            callback(new Errors.ValidationError("Des doublants d'ordres de virements ont été détecté veuillez contacter le support"))
        } else callback()
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.lotMustNotContainDoubles(newObject, oldObject, context, callback)
        ], callback)
    },
    beforeSave: function (newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType

        if(action === 'C') {
            newObject.status = 'toPay'
            newObject.reference = `Lot-F-${newObject.sequence}`
            newObject.creationDate = moment().format("YYYY-MM-DDTHH:mm:ss")
            const fileName = `LotDeVirement_${newObject.reference}`

            global.app.C.Bank.find({
                ...basicContext(context),
                fieldPath: ['active', 'iban', 'bic'],
                query: {active: true}
            }, (e, banks) => {
                if(banks.length === 0) {
                    callback(new Errors.ValidationError('activeBankNotFound'))
                }
                else {
                    const bank = banks[0]
                    async.parallel([
                            function(callback) {
                                const validatedObject = validateForXmlGeneration(newObject, callback, context.tc)
                                const xmlData = writeXml(validatedObject, bank)
                                global.xml.generateXml(fileName, xmlData, (err, file) => {
                                    if(err) callback(err)
                                    else callback(null, file)
                                })
                            },
                            function(callback) {
                                generateExcel(newObject, bank)
                                    .then(file => callback(null, file))
                                    .catch(err => callback(err))
                            }
                        ],
                        function(err, results) {
                            if(err) callback(err)
                            else {
                                newObject.xmlFile = {
                                    ...results[0],
                                    user: _.get(context, "user.name", "unknown user"),
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                                newObject.xlsxFile = {
                                    ...results[1],
                                    user: _.get(context, "user.name", "unknown user"),
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                                callback(null, newObject, oldObject)
                            }
                        })
                }
            })
        }else if(context.action && context.action !== 'save') {
            switch (context.action) {
                case 'control':
                    newObject.status = 'controlled'
                    newObject.transmissionDate = null
                    newObject.fileName = null
                    break
                case 'pay':
                    newObject.status = 'paid'
                    newObject.transmissionDate = null
                    newObject.fileName = null
                    break
                default:
                    console.log('no action !!!')
            }
            callback(null, newObject, oldObject)

        } else callback(null, newObject, oldObject)
    },
    afterSave: function (newObject, oldObject, context, callback) {

        const action  = context.restAction && context.restAction.crudType

        if(action === 'C' && newObject.status === 'toPay') {
            updateMany(
                'BankFlow',
                {_id: {$in: newObject.orderLines.map(line => global.ObjectID(line.id)) }},
                {
                    status: 'toPay',
                    reference: newObject.reference,
                    transmissionDate: moment().format('YYYY-MM-DD'),
                    fileName: newObject.xmlFile.filename
                },
                null,
                callback
            )
        } else if( newObject.status === 'controlled' && oldObject.status === 'toPay' ) {
            const removedLines = oldObject.orderLines.filter(oldLine => {
                return !newObject.orderLines.some(newLine => oldLine.id === newLine.id )
            })

            if (removedLines.length !== 0) {
                global.app.C.BankFlow.collection.updateMany(
                    {_id: {$in: removedLines.map(line => global.ObjectID(line.id)) }},
                    {$set: {
                            status: 'waiting',
                            reference: null,
                            transmissionDate: null,
                            fileName: null
                        }},
                    (e) => {
                        if (e) {
                            console.warn(e)
                            callback(e)
                        } else {
                            const newComment = {
                                user: _.pick(context.user, ['id', 'name']),
                                text: `${context.tc('waiting')}`,
                                date: moment().format("YYYY-MM-DD HH:mm")
                            }

                            updateMany(
                                'Invoice',
                                {wording: {$in: removedLines.map(line => line.reason) }},
                                {
                                    status: 'waiting',
                                    reference: null,
                                    transmissionDate: null,
                                    fileName: null
                                },
                                {comments: newComment},
                                e => {
                                    if(e) callback(e)
                                    else callback()
                                }
                            )
                        }
                    }
                )
            } else {
                callback()
            }

        } else if( newObject.status === 'paid' && oldObject.status === 'controlled') {
            const defaultObject = {
                type: 'G',
                generated: moment().format("YYYY-MM-DD"),
                date: moment(newObject.paymentDate).format("YYYY-MM-DD"),
                journal: 'VIR',
                reference: oldObject.reference,
                group: new global.ObjectID(context.group.id)
            }

            global.db.collection('c.bank').findOne({active: true},
                (e, bank) => {
                    if(e) callback(e)
                    else if(!bank) callback('Vous devez renseigner un compte banquaire actif')
                    else {
                        const specificLines = newObject.orderLines.map(line => {
                            return [
                                {
                                    ...defaultObject,
                                    generalAccount: '401000000',
                                    auxiliaryAccount: line.accountNumber,
                                    amount: line.amount,
                                    direction: 'D',
                                    wording: line.reason
                                },
                                {
                                    ...defaultObject,
                                    generalAccount: bank.temporaryAccount,
                                    amount: line.amount,
                                    direction: 'C',
                                    wording: line.reason
                                }
                            ]

                        })

                        const commonObject = {
                            wording: `Ordre de virement num ${newObject.reference}, Nombre virements ${newObject.orderLinesLength}`,
                            amount: newObject.amount
                        }

                        const commonLines = [
                            {...defaultObject, ...commonObject, generalAccount: bank.temporaryAccount, direction: 'D'},
                            {...defaultObject, ...commonObject, generalAccount: bank.provisionalAccount, direction: 'C'}
                        ]

                        async.series([
                            callback => {
                                const removedLines = oldObject.orderLines.filter(oldLine => {
                                    return !newObject.orderLines.some(newLine => oldLine.id === newLine.id )
                                })

                                if(removedLines.length !== 0) {
                                    global.app.C.BankFlow.collection.updateMany(
                                        {_id: {$in: removedLines.map(line => global.ObjectID(line.id)) }},
                                        {$set: {
                                                status: 'waiting',
                                                reference: null,
                                                transmissionDate: null,
                                                fileName: null
                                            }},
                                        (e) => {
                                            if (e) {
                                                console.warn(e)
                                                callback(e)
                                            } else {
                                                const newComment = {
                                                    user: _.pick(context.user, ['id', 'name']),
                                                    text: `${context.tc('waiting')}`,
                                                    date: moment().format("YYYY-MM-DD HH:mm")
                                                }
                                                updateMany(
                                                    'Invoice',
                                                    {wording: {$in: removedLines.map(line => line.reason) }},
                                                    { status: 'waiting'},
                                                    {comments: newComment},
                                                    callback
                                                )
                                            }
                                        })
                                } else {
                                    callback()
                                }
                            },
                            callback => {
                                if(newObject.orderLines.length !== 0) {
                                    global.app.C.BankFlow.collection.updateMany(
                                        {_id: {$in: newObject.orderLines.map(line => global.ObjectID(line.id)) }},
                                        {
                                            $set: {
                                                status: 'paid'
                                            }},
                                        (e) => {
                                            if (e) {
                                                console.warn(e)
                                                callback(e)
                                            } else {
                                                global.app.C.AccountingEntries.collection.insertMany( _.flatten([...specificLines, ...commonLines]), (e) => {
                                                    if (e) {
                                                        console.warn(e)
                                                        callback(e)
                                                    }else {
                                                        const newComment = {
                                                            user: _.pick(context.user, ['id', 'name']),
                                                            text: `${context.tc('paid')}`,
                                                            date: moment().format("YYYY-MM-DD HH:mm")
                                                        }

                                                        updateMany(
                                                            'Invoice',
                                                            {wording: {$in: newObject.orderLines.map(line => line.reason) }},
                                                            { status: 'paid'},
                                                            {comments: newComment},
                                                            callback
                                                        )
                                                    }
                                                })
                                            }
                                        })
                                }else {
                                    callback()
                                }
                            }
                        ], (err) => callback(err))
                    }
                })

        } else {
            callback()
        }
    }
}
