import moment from 'moment'
import {
    changeFieldDefault, changeFieldDisabled,
    setFieldEndDate,
    setFieldStartDate, setFieldVisibility, setFormButtons,
} from "../../../../apps/KpModule/actions";
import {basicContext} from "../../../utils/contextUtils";
import Errors from "../../../utils/Errors";
import async from "async";
import {returnButton, saveButton, sendButton} from "../utils/demandUtils";
import {getDataListList} from "../../../../apps/KpModule/selectors";

export const entity = {
    name: 'MeetingsPlanning',
    fields: [
        {path: 'code', unique: false},
        {path: 'fiscalYear', type: 'FiscalYear'},
        {path: 'meetingsPlanningRange', type: 'dateRange'},
        {path: 'budgetReportingPeriod', type: 'dateRange'},
        {path: 'revisedReportingPeriod', type: 'dateRange'},
        //month
        //startDay
        //endDay
    ],
    filters: [
        {
            name: "onlyOngoing",
            isDefault: false,
            query: function() {
                const currentDay = moment(moment().format('YYYY-MM-DD')).toDate()
                console.log("currentDay", currentDay)
                return {startDay: {$lte: currentDay}, endDay: {$gte: currentDay}}
            }
        },
    ],
    uniqueProcess: function ( ID, meetingsPlanningRange, budgetReportingPeriod, revisedReportingPeriod, context, callback) {
        const condition1 = moment(revisedReportingPeriod[0]).isBetween(meetingsPlanningRange[0], meetingsPlanningRange[1], 'day', '[]')
        const condition2 = moment(revisedReportingPeriod[1]).isBetween(meetingsPlanningRange[0], meetingsPlanningRange[1], 'day', '[]')
        const revisedReportingPeriodIsIncludedInMeetingsPlanningRange = condition1 && condition2
        if (!revisedReportingPeriodIsIncludedInMeetingsPlanningRange){
            return callback(new Errors.ValidationError("revisedReportingPeriod must be included in meetingsPlanningRange"))
        }
        async.parallel([
                callback => global.app.SE.MeetingsPlanning.collection.findOne({
                    _id: {$ne: global.ObjectID(ID)}
                    },
                    (e, object) => {
                    callback(e, !object)
                }),
                callback => global.app.SE.MeetingsPlanning.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {
                        _id: {$ne: global.ObjectID(ID)},
                        "meetingsPlanningRange.1": {$eq: moment(meetingsPlanningRange[0]).subtract(1, 'days').toDate()}
                    }
                }, (e, objects) => {
                    callback(e, !!objects.length && objects.length === 1)
                }),
                callback => global.app.SE.MeetingsPlanning.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {
                            _id: {$ne: global.ObjectID(ID)},
                            $or: [{"meetingsPlanningRange.0": {$lte: meetingsPlanningRange[1]}, "meetingsPlanningRange.1": {$gte: meetingsPlanningRange[0]}}]
                        }
                    }, callback),
                callback => global.app.SE.MeetingsPlanning.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {
                        _id: {$ne: global.ObjectID(ID)},
                        $or: [{"budgetReportingPeriod.0": {$lte: budgetReportingPeriod[1]}, "budgetReportingPeriod.1": {$gte: budgetReportingPeriod[0]}}]
                    }
                }, callback),
                callback => global.app.SE.MeetingsPlanning.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {
                        _id: {$ne: global.ObjectID(ID)},
                        $or: [{"revisedReportingPeriod.0": {$lte: revisedReportingPeriod[1]}, "revisedReportingPeriod.1": {$gte: revisedReportingPeriod[0]}}]
                    }
                }, callback),
            ],(error, results) => {
                if (error) return callback(error)
            const [isFirstMeetingPlanning, noDiscontinuityProblem, meetingsPlanningRanges, budgetReportingPeriod, revisedReportingPeriod] = results

            if (!isFirstMeetingPlanning && !noDiscontinuityProblem){
                return callback(new Errors.ValidationError("meetingPlanningRanges discontinuity problem"))
            }
            if (!!meetingsPlanningRanges.length){
                return callback(new Errors.ValidationError("meetingPlanningRanges overlapping problem"))
            }
            if (!!budgetReportingPeriod.length){
                return callback(new Errors.ValidationError("budgetReportingPeriod overlapping problem"))
            }
            if (!!revisedReportingPeriod.length){
                return callback(new Errors.ValidationError("revisedReportingPeriod overlapping problem"))
            }
            return callback()
        })
    },
    validateSave: function (newObject, oldObject, context, callback) {
        console.log('newObject[0]', newObject, newObject.revisedReportingPeriod)
        this.uniqueProcess( newObject.id, newObject.meetingsPlanningRange, newObject.budgetReportingPeriod, newObject.revisedReportingPeriod, context, callback)
    },
}
export const module_ = {
    object: 'MeetingsPlanning',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: 'echéance',
    viewMap: {
        dt: [
            {path: 'code', width: 100},
            {path: 'fiscalYear', tKey: 'exercice', display: 'code', width: 100},
            'meetingsPlanningRange',
            {path: 'budgetReportingPeriod', width: 300},
            'revisedReportingPeriod',
        ],
        form: {
            fields : [
                {path: 'code', required: true},
                {
                    path: 'fiscalYear',
                    tKey: 'exercice',
                    display: 'code',
                    filters:['nonClosed'],
                    fieldPath: ['id', 'fiscalYearRange', 'code'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            const meetingsPlanningRangeField = module.viewMap.form.fields.find( field => field.path === 'meetingsPlanningRange')
                            const objectMode = store.getState().ui.objectMode

                            if(objectMode === 'newObject') {
                                if (newValue) {
                                    meetingsPlanningRangeField.setValue([newValue.fiscalYearRange[0], newValue.fiscalYearRange[0]])
                                }
                                else{
                                    meetingsPlanningRangeField.setValue(null)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'meetingsPlanningRange',
                    tKey: 'Période échéance',
                    required: true,
                    default: null,
                },
                {
                    path: 'budgetReportingPeriod',
                    tKey: 'Renseignement Ré-estimé Budget',
                    default: null,
                    required: true
                },
                {
                    path: 'revisedReportingPeriod',
                    tKey: 'Renseignement Révisé',
                    default: null,
                    required: true
                },
            ],
            onOpen: ({ module, state, store }) => {
                const fiscalYearField = module.viewMap.form.fields.find(field => field.path === 'fiscalYear')
                const objectMode = state.ui.objectMode
                store.dispatch(changeFieldDisabled(fiscalYearField.id, objectMode !== 'newObject'))
            },
        }
    }
}