import XHR from 'i18next-xhr-backend'
import LngDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import { capitalizer } from './i18n'

const backEndOptions = {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    loadPath: '/locales/{{lng}}/{{ns}}.json',

    // path to post missing resources
    addPath: '/locales/add/{{lng}}/{{ns}}.missing.json',

    // your backend server supports multiloading
    // /locales/resources.json?lng=de+en&ns=ns1+ns2
    allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

    // allow cross domain requests
    crossDomain: false,

    // allow credentials on cross domain requests
    withCredentials: false
}

const LngDetectorOptions = {
    // order and from where user language should be detected
    order: ['localStorage', 'cookie', 'navigator', 'htmlTag'],

    // keys or params to lookup language from
    //lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain'

    // optional htmlTag with lang attribute, the default is:
    //htmlTag: document.documentElement
}

const i18n = i18next
    .use(XHR)
    .use(LngDetector)
    .use(capitalizer)
    .init({
        detection: LngDetectorOptions,
        backend: backEndOptions,
        postProcess: 'capitalizer',

        whitelist: ['en', 'fr', 'es', 'it', 'nl'],

        nonExplicitWhitelist: true,

        load : 'languageOnly',

        fallbackLng: false,

        // have a common namespace used around the full app
        ns: ['platform'],
        defaultNS: 'platform',

        debug: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: true, // set to true if you like to wait for loaded in every translated hoc
            nsMode: 'fallback'
        },
        // added to translate strings that contain ":"
        nsSeparator: false,
        keySeparator: false,
    })

export const LANGUAGES = ['en', 'fr', 'es', 'it', 'nl']

export default i18n

