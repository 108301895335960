const _ = require('lodash')

export const module_ = {
    object: 'Refund',
    name: 'Validator',
    tKey: 'mTitle_validation',
    newable: false,
    removable: false,
    displayLog: false,
    defaultSortBy: 'updated',
    defaultSortDirection: 'ASC',
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: 'reference', tKey:'number2'},
            {path: 'user', tKey: 'beneficiary', display: 'fullName'},
            {path: 'userRegistrationNumber', tKey: 'registrationNumber', initiallyNotVisible: true},
            'benefit',
            {path: 'date', tKey: 'dateOfInvoice'},
            {path: 'amount', tKey: 'amount(€)_abbreviation'},
            {path: 'refund', tKey: 'refundAbbreviated'},
            {path: 'beneficiaries', initiallyNotVisible: true, display: 'fullName'},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            'updated'
        ],
        form: {
            fields: [
                {path: 'reference', tKey:'number2', disabled: true},
                {path: 'user', tKey: 'beneficiary', display: 'fullName', disabled: true},
                {path: 'benefit', editable: false},
                {path: 'date', type: 'datePicker', tKey: 'dateOfInvoice', editable: false},
                {path: 'currencyAmount', editable: false},
                {path: 'rate', tKey: 'currencyRate', editable: false},
                {path: 'amount', tKey: 'amount(€)', editable: false},
                {path: 'beneficiaries', editable: false, display: 'fullName'},
                {path: 'files', tKey: 'supportingDocuments', editable: false},
                'comments',
                {path: 'controller', hidden: true},
                {path: 'userFullName', hidden: true},
                {path: 'status', hidden: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
        }
    },
    filters: [
        {
            path: "validationStep",
            match: function(request) {
                return ['controlled'].includes(request.status)
            }
        }
    ]
}
