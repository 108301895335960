export const entity = {
    name: 'AccountModel',
    fields: ['name', 'script', 'AccountModelType']
}

export const module_ = {
    object: 'AccountModel',
    tKey: 'mTitle_accountModel',
    removable: true,
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    category: {
        path: 'keenpoint',
        icon: 'server'
    },
    viewMap: {
        dt: ['name'],
        form: [
            { path: 'name', required: true },
            'accountModelType',
            {
                path: 'script',
                type: 'textarea'
            }
        ]
    }
}
