import {basicContext} from "../../utils/contextUtils";
import _ from "lodash";
import moment from "moment";

export const entity = {
    name: 'Limit',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true},
        {path: 'fixed', type: 'integer'},
        {path: 'variable', type: 'integer'},
        {path: 'maximum', type: 'integer', nullable: true}
    ],
    afterSave: async (object, oldObject, context, callback) => {

        const action  = context.restAction && context.restAction.crudType

        if(action === 'C' || object.maximum === oldObject.maximum) {
            return callback()
        }

        const benefit = await global.app.R.Benefit.get(
            {
                limit: global.ObjectID(object.id),
                childrenPresenceCondition: true
            },
            {
                ...basicContext(context),
                fieldPath: ['limit.maximum']
            }
        )

        if(!benefit) return callback()

        await global.app.R.CUsers.collection.updateMany({declaration2: true}, {
            $set: {
                declaration2 : false,
            },
            $push: {
                comments: {
                    user: _.pick(context.user, ['id', 'name']),
                    text: `La case « Acceptation du Plafond ${oldObject.maximum}€ pour Soutien scolaire / garde d’enfants» a été réinitialisée suite à la modification du montant « Max (€) » du plafond retenu pour cette prestation: de ${oldObject.maximum}€ à ${object.maximum}€`,
                    date: moment().format("YYYY-MM-DD HH:mm")
                }
            }
        })

        return callback()

    }
}
export const module_ = {
    object: 'Limit',
    tKey: 'mTitle_limit',
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: ['code', 'name', 'fixed', 'variable', 'maximum'],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
            {path: 'fixed', wholeNumber: true, required: true},
            {path: 'variable', wholeNumber: true, required: true},
            {path: 'maximum'},
        ]
    }
}
