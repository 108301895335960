import React from 'react'
import PropTypes from 'prop-types'
import './GlyphiconButton.css'

const GlyphiconButton = ({ onClick, backgroundColor, children, title }) => (
    <button
        className='btn btn-default GlyphiconButton'
        type='button'
        title={title}
        style={{ backgroundColor }}
        onClick={onClick}
    >
        {children}
        {title && <span style={{marginTop: '1px'}}>{title}</span>}
    </button>
)

GlyphiconButton.propTypes = {
    onClick: PropTypes.func
}

export default GlyphiconButton
