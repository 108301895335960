const _ = require('lodash')
const Errors = require("../../utils/Errors").default

const getElementFullName = object => {
    if (object.joinedEntity === 'BPEndorsed') {
        return `${object.code}`
    } else {
        // 'Subsidiary', 'Country', 'Shop'
        return `${object.code} - ${object.name}`
    }
}

export const entities = [
    {
        name: 'Currency',
        facets: [{ name: 'codeName', uniqueName: true }]
    },
    {
        name: 'Zone',
        facets: [{ name: 'codeName', uniqueName: true }]
    },
    {
        name: 'Pole',
        facets: [{ name: 'codeName', uniqueName: true }],
        ps: {
            delete: [
                {
                    $$v: function (pole, callback) {
                        global.app.B.HypothesisModelLine.find({
                            group: this.options.context.group,
                            query: {poles: { $elemMatch: {$eq: global.ObjectID(pole.id)} }}
                        }, (e, hModelLines) => {
                            if (e) return callback(e)

                            if (hModelLines && hModelLines.length) callback(new Errors.ValidationError('poleIsUsedInHypothesisModelLine'))
                            else callback()
                        });
                    }
                }
            ]
        }
    },
    {
        name: 'Country',
        facets: [{ name: 'codeName', uniqueName: true }],
        fields: ['Currency', 'Zone']
    },
    {
        name: 'Subsidiary',
        facets: [{ name: 'codeName', uniqueName: true }],
        fields: ['Country'],
        filters: [
            {
                name: "inUserPerimeter",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.B.SubsidiaryAssignment.collection.find({
                        user: new global.ObjectID(context.user.id),
                        group: new global.ObjectID(context.group.id)
                    }).toArray(
                        (error, assignments) => {
                            const ids = _.flatMap(
                                assignments,
                                assignement => assignement.subsidiaries
                            )

                            callback(error, { _id: { $in: ids } });
                        });
                }
            }
        ]
    },
    {
        name: 'Shop',
        facets: [{ name: 'codeName', uniqueName: true }],
        fields: [
            { type: 'Subsidiary', index: true},
            'Pole',
            { path: 'openingDate', type: 'date', nullable: true },
            { path: 'renovationDate', type: 'date', nullable: true },
            'monthsAfter'
        ]
    },
    require('./../../utils/entityJoin').joinEntities(
        {
            name: 'ElementDataType',
            fields: [
                {
                    path: 'name',
                    fieldPath: ['shop.name', 'subsidiary.code'],
                    $f: (object, context, callback) => {

                        callback(null, getElementFullName(object))
                    }
                }
            ],
            filters: [
                {
                    name: 'byDataType',
                    isDefault: true,
                    match: (object, context) => {
                        const dataType = context.data && context.data.dataType
                        if(object.joinedEntity === 'orgA') {
                            return !!object.case
                        }
                        return true
                    }
                }
            ]
        },
        ['Subsidiary', 'Country', 'Shop', 'BPEndorsed']
    )

]

export const modules = _.concat(
    ['Currency', 'Zone', 'Pole'].map(object => ({
        object: object,
        tKey: `mTitle_${object.toLowerCase()}`,
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: ['code', 'name'],
            form: ['code', 'name']
        }
    })),
    [
        {
            object: 'Country',
            tKey: 'mTitle_country',
            defaultSortBy: 'code',
            defaultSortDirection: 'ASC',
            category: {
                path: 'referential',
                icon: 'server'
            },
            viewMap: {
                dt: [
                    'code',
                    'name',
                    { path: 'currency', display: 'fullName' },
                    { path: 'zone', display: 'fullName' }
                ],
                form: [
                    'code',
                    'name',
                    { path: 'currency', display: 'fullName' },
                    { path: 'zone', display: 'fullName' }
                ]
            }
        },
        {
            object: 'Subsidiary',
            tKey: 'mTitle_subsidiary',
            defaultSortBy: 'code',
            defaultSortDirection: 'ASC',
            category: {
                path: 'referential',
                icon: 'server'
            },
            viewMap: {
                dt: ['code', 'name', { path: 'country', display: 'fullName' }],
                form: ['code', 'name', { path: 'country', display: 'fullName' }]
            }
        },
        {
            object: 'Shop',
            tKey: 'mTitle_shop',
            defaultSortBy: 'code',
            defaultSortDirection: 'ASC',
            category: {
                path: 'referential',
                icon: 'server'
            },
            viewMap: {
                dt: [
                    { path: 'subsidiary', display: 'fullName', editable: false },
                    'code',
                    'name',
                    { path: 'pole', display: 'fullName', editable: false },
                    'openingDate',
                    'renovationDate',
                    'monthsAfter'
                ],
                form: [
                    { path: 'subsidiary', display: 'fullName', editable: false },
                    'code',
                    'name',
                    { path: 'pole', display: 'fullName', editable: false },
                    'openingDate',
                    'renovationDate',
                    'monthsAfter'
                ]
            }
        }
    ]
)
