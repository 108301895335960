const moment = require('moment');
const path = require('path')

const translation = (context={}) => ({
    t: context.t || function(path){ return path }
})

const getQuestionsReport = questions => {
    return questions.map( question => ({
        subject : question.subject.name,
        context : question.context,
        response : question.response,
        draftReply: question.draftReply,
        lastUser : question.lastUser,
        owner : question.owner,
        lastModificationDate: moment(question.lastModificationDate).format('DD/MM/YYYY'),
        creationDate: moment(question.creationDate).format('DD/MM/YYYY')

    }))
}

const prepareDocument = (object, context) => {

    return {
        content: [{ path: 'meetingSynthesis.html' }, { path: 'questionResponse.html' }],
        context: {
            schedule: [object.startTime, object.endTime],
            place: object.place,
            object: object.object,
            expectedParticipants: object.expectedParticipants.map(p => `${p.firstname} ${p.lastname}`).join(', '),
            presentParticipants: object.presentParticipants.map(p => `${p.firstname} ${p.lastname}`).join(', '),
            presentGuests: object.presentGuests,
            synthesis: object.synthesis,
            questions: getQuestionsReport(object.questions),
            now : moment(Date.now()).format('DD/MM/YYYY'),
            user: context.user
        },
        configuration: {
            landscape: true,
            header: {
                height: "2cm",
                html: `<div style="font-family: Verdana, Arial, sans-serif;text-align:center;"><h6 style="color: #2a5da8">${object.code}</h6><p style="font-size: 8px;">Situation au <span>${moment(object.date).format('DD/MM/YYYY')}</span> : <span>${translation(context).t(object.meetingStatus.id)}</span></p></div>`
            },
            footer: {
                height: "1cm",
                html: `<div style="font-size: 8px; font-family: Verdana, Arial, sans-serif"><div style="float: left">${ context.group.name } - Compte rendu de réunion</div><div style="float: right">{{pageNum}}/{{numPages}}</div></div>`

            },
            pdfName: "generatedPDF.pdf",
            outputInDB: true,
            templateDir: path.join(global.appRoot, "src/server/models/valodial/templates/pdfs")
        }
    }
}

export { prepareDocument }
