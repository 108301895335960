import {importantInputCorrespondences as ic} from "./inputCorrespondence";
import {getHabilitations} from "./utils/habilitations";

const moment = require("moment");
const _ = require("lodash");

const yesterdayISO = moment().subtract(1, "days").format("YYYY-MM-DD");
const todayISO = moment().format("YYYY-MM-DD");

const getElemDataProjection = (themeAnalyseGroupElement, byDate) => {
    const dateProjection =  byDate ? {date: 1} : {}
    switch (themeAnalyseGroupElement.joinedEntity) {
        case "SubStoreAxis":
            return {[themeAnalyseGroupElement.code]: 1, ...dateProjection}
        case "StoreAxis":
            return {store: 1, ...dateProjection}
        case "OrganizationAxis":
            return {organizations: 1, ...dateProjection}
        case "FamilyAxis":
            return {familys: 1, ...dateProjection}
        default:
            return dateProjection
    }
}

const getThemesProjection = themes => {
    return themes.reduce((acc, theme) => {
        return {
            ...acc,
            [theme.completeCode]: 1
        }
    }, {})
}

const groupByJoinedEntity = (themeAnalyseGroupElement, elemData, byDate, organizationsById, familysById) => {
    switch (themeAnalyseGroupElement.joinedEntity) {
        case "SubStoreAxis":
            return _.groupBy(elemData, elem => {
                const date = byDate ? `_${moment.utc(elem["date"]).format("YYYY-MM-DD")}` : ""
                return elem[themeAnalyseGroupElement.code]
                        ? `${elem["store"]}${elem[themeAnalyseGroupElement.code]}${date}`
                        : `undefined_sub-store-${date}`
            })
        case "StoreAxis":
            return _.groupBy(elemData, elem => {
                const date = byDate ? `_${moment.utc(elem["date"]).format("YYYY-MM-DD")}` : ""
                return elem["store"]
                        ? `${elem.store}${date}`
                        : `undefined_store-${date}`
            })
        case "OrganizationAxis":
            return _.groupBy(elemData, elem => {
                const orgId = elem.organizations.find(orgId => {
                    const organization = organizationsById[orgId.toString()]
                    return organization?.organizationAxis.code === themeAnalyseGroupElement.code
                })
                const organization = organizationsById[orgId]
                const date = byDate ? `_${moment.utc(elem["date"]).format("YYYY-MM-DD")}` : ""
                return organization
                    ? `${organization.code}${date}`
                    : `undefined_org-${date}`
            })
        case "FamilyAxis":
            return _.groupBy(elemData, elem => {
                const famId = elem.familys.find(famId => {
                    const family = familysById[famId.toString()]
                    return family?.familyAxis.code === themeAnalyseGroupElement.code
                })
                const family = familysById[famId]
                const date = byDate ? `_${moment.utc(elem["date"]).format("YYYY-MM-DD")}` : ""
                return family
                    ? `${family.code}${date}`
                    : `undefined_family-${date}`
            })
        default:
            return []
    }
}

const getMesh = (themeAnalyseGroupElement, storesByCode, organizationsById, familysById, elem) => {
    switch (themeAnalyseGroupElement.joinedEntity) {
        case "StoreAxis":
            const store = storesByCode[elem.store]
            return store
                ? `${store.code} - ${store.name}`
                : 'undefined store'
        case "OrganizationAxis":
            const orgId = elem.organizations.find(orgId => {
                const organization = organizationsById[orgId.toString()]
                return organization?.organizationAxis.code === themeAnalyseGroupElement.code
            })
            return organizationsById[orgId]?.name
        case "FamilyAxis":
            const famId = elem.familys.find(famId => {
                const family = familysById[famId.toString()]
                return family?.familyAxis.code === themeAnalyseGroupElement.code
            })
            return familysById[famId]?.name
        case "SubStoreAxis":
            return elem[themeAnalyseGroupElement.code]
        default:
            return "No value for joined entity"
    }
}

async function findThemeAnalyse(context){

    const scopeId = _.get(context, 'data.scope.id');
    const themeGroupElemId = _.get(context, 'data.themeAnalyseGroupElement.id');
    const cumulateTheme = _.get(context, 'data.cumulate.id');
    const themes = _.get(context, 'data.themes', []);

    const byDate = cumulateTheme === "notCumulate"

    const themeAnalyseGroupElement = await global.app.S.ThemeAnalyseGroupElement.get(
        themeGroupElemId,
        {
            fieldPath: global.app.S.ThemeAnalyseGroupElement.deleteFieldPath,
            group: context.group
        }
    )

    const storesQuery = global.app.S.Store.find({
        query: {},
        fieldPath: ["code", "name"],
        group: context.group,
    })

    const themeJoinIds = _.get(context, 'data.themes', []).map(o => new global.ObjectID(o.id));

    const themeJoinsQuery = global.app.S.ThemeJoin.find({
        query: {_id: {$in : themeJoinIds}},
        fieldPath: ["completeCode", "completeName", "mtKeyName", "nbKeyName"],
        group: context.group
    })

    const familyQuery = global.app.S.Family.get(
        scopeId,
        {
            fieldPath: global.app.S.Family.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        }
    )

    const familysQuery = global.app.S.Family.find({
        query: {},
        fieldPath: ["name", "familyAxis.code"],
        group: context.group,
    })



    const organizationQuery = global.app.S.Organization.get(
        scopeId,
        {
            fieldPath: global.app.S.Organization.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        }
    )

    const organizationsQuery = global.app.S.Organization.find({
        query: {},
        fieldPath: ["name", "organizationAxis.code"],
        group: context.group,
    })

    const elemDataProjection = {
        ...getElemDataProjection(themeAnalyseGroupElement, byDate),
        ...getThemesProjection(themes)
    }

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const userStores = storeHabilitations.map(hab => _.get(hab, 'store.code'))
    const userOrganizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habilitationsQuery = userStores.length || userOrganizations.length
        ? {
            $or: [
                {store: {$in : userStores}},
                {organizations: {$in: userOrganizations}}
            ]
        }
        : {}

    const scopeQuery = (scopeId && scopeId !== "null")
        ? _.get(context, 'data.scope.ancestorEntityName') === 'Organization'
            ? {organizations: new global.ObjectID(scopeId)}
            : {familys: new global.ObjectID(scopeId)}
        : {}

    const dates = _.get(context, 'data.period', [yesterdayISO, yesterdayISO]).map(
        date => moment.utc(date.trim(), "YYYY-MM-DD")
    );
    const datesQuery = { $and: [
            {[ic.Date.internalIdentifier]: {$gte: dates[0].toDate()}},
            {[ic.Date.internalIdentifier]: {$lte: dates[1].toDate()}}
        ]};

    const groupQuery = {
        group: global.ObjectID(context.group.id)
    }

    const themesQuery = themes.reduce((query, theme) => {
        return {
            $or: [
                ...query.$or,
                {[theme.completeCode]: {$exists: true}}
            ],
        }
    }, {$or: []})


    const elemDataQuery = {
        ...groupQuery,
        ...datesQuery,
        ...scopeQuery,
        ...habilitationsQuery,
        ...themesQuery
    }

    const elemDatasQuery = global.app.S.ElemDataLine.collection.aggregate([
        { $match: elemDataQuery },
        { $project: elemDataProjection }
    ]).toArray()

    console.time("Fetching data")

    const [
        themeJoins,
        stores,
        organization,
        organizations,
        family,
        familys,
        elemDatas
    ] = await Promise.all([
        themeJoinsQuery,
        storesQuery,
        organizationQuery,
        organizationsQuery,
        familyQuery,
        familysQuery,
        elemDatasQuery
    ])

    const storesByCode = stores.reduce((acc, store) => ({...acc, [store.code]: store}), {})
    const organizationsById = organizations.reduce((acc, org) => ({...acc, [org.id]: org}), {})
    const familysById = familys.reduce((acc, fam) => ({...acc, [fam.id]: fam}), {})

    console.timeEnd("Fetching data")

    console.time("Processing data")
    const elementsGrouped = groupByJoinedEntity(themeAnalyseGroupElement, elemDatas, byDate, organizationsById, familysById)

    let perimeter = '';
    if(organization) {
        perimeter = organization.name
    }else if(family) {
        perimeter = family.name
    }

    const processedResult = _.flatten(Object.keys(elementsGrouped).map(
        key => {
            const objects = elementsGrouped[key]
            const object = objects[0]
            const group = getMesh(themeAnalyseGroupElement, storesByCode, organizationsById, familysById, object)
            return themeJoins.map(themeJoin => {
                const period = byDate
                    ? moment.utc(object["date"]).format("YYYY-MM-DD")
                    : dates.map(d => d.format("YYYY-MM-DD")).join(" -> ");
                return {
                    id: [period, themeJoin.code, perimeter, group].join("_"),
                    period,
                    group,
                    indicator: context.translateName(themeJoin.name),
                    amount: _.round(
                        _.sum(objects.map(o =>
                            _.get(o, themeJoin.mtKeyName) ? _.get(o, themeJoin.mtKeyName) : 0
                        )),
                        2
                    ),
                    number:  _.sum(objects.map(o =>
                        _.get(o, themeJoin.nbKeyName) ? _.get(o, themeJoin.nbKeyName) : 0)
                    ),
                    perimeter
                }
            })
        }
    ))

    console.timeEnd("Processing data")

    return processedResult

}

export const entity = {
    name: "ThemeAnalyse",
    type: null,
    fields: [
        "name",
        "id",
        "perimeter",
        "period",
        "group",
        "indicator",
        "amount",
        "number"
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error);
            else findThemeAnalyse(context)
                .then(objects => global.ioSocket.emit(
                    `fetchDT-success-${context.user.id}${context.clientContext.accessId}`,
                    objects
                ))
                .catch(e => global.ioSocket.emit(
                    `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`,
                    {error: e.message}
                ))
            callback()
        });
    }
}

export const module_ = {
    object: "ThemeAnalyse",
    tKey: "mTitle_themeAnalyse",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    defaultSortBy: "period",
    defaultSortDirection: "ASC",
    newable: false,
    editable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    manualFilters: true,
    viewMap: {
        dt: [
            {path: "indicator", width: 250},
            {path: "perimeter", initiallyNotVisible: true},
            {path: "group", tKey: 'groupedBy'},
            {path: "period", width: 200},
            {path: "amount", width: 80, tooltip: true},
            {path: "number", width: 80, tooltip: true},
        ],
        form: []
    },
    filters: [
        {
            path: "themes",
            tKey: 'indicator_plural',
            object: "ThemeJoin",
            fieldPath: ["completeCode", "completeName"],
            display: "completeName",
            client: true,
            type: "tags"
        },
        {
            path: "scope",
            object: "Scope",
            display: "completeName",
            fieldPath: ["completeName", "ancestorEntityName"],
            default: {id: 'a00000000000000000000000'}, // fake id
            clearable: true,
            client: true

        },
        {
            path: "themeAnalyseGroupElement",
            object: "ThemeAnalyseGroupElement",
            display: "tName",
            tKey: 'groupedBy',
            default: {id: 'a00000000000000000000000'}, // store
            client: true,
            clearable: false
        },
        {
            path: "period",
            type: "dateRange",
            client: true,
            default: [yesterdayISO, todayISO]
        },
        {
            path: "cumulate",
            tKey: 'typeOfAnalysis',
            object: "ThemeAnalyseCumulate",
            display: "tKey",
            translate: true,
            default: {id: 'cumulate'},
            client: true,
            clearable: false
        }
    ]
}
