import { combineReducers } from 'redux'
import { LANGUAGES } from '../../../utils/i18nClient'
import {
    SET_LANGUAGE,
    SET_THEME,
    SET_CATEGORY_COLLAPSE,
    SET_LANGUAGES_OPENED,
    SET_PROFILE_OPENED,
    SET_JOB_OPENED,
    SET_DOCS_OPENED,
    SET_UI_DEVICE,
    SET_SIDEBAR_DOCKED,
    SET_SIDEBAR_OPEN,
    VIEWER_SUCCESS,
    CONFIG_SUCCESS
} from '../actions/index'

export const client = (state = {client: '', auth: {}}, action) => {
    switch (action.type) {
        case CONFIG_SUCCESS:
            return {
                ...state,
                ...action.data
            }
        case VIEWER_SUCCESS:
            return {
                ...state,
                client: action.data.client
            }
        default:
            return state
    }
}

const defaultTheme = {
    backgroundColor: '#f6f9fc',
    menuButtonsColor: '#62626e',
    selectedColor: '#e94d24',
    kpLogo: 'colored'
}

export const theme = (state = defaultTheme, action) => {
    switch (action.type) {
        case SET_THEME:
            const keys = Object.keys(action.theme)
            const values = keys.reduce((acc, key) => {
                if(action.theme[key]) {
                    acc[key] = action.theme[key]
                }
                return acc
            }, {})
            return {
                ...state,
                ...values
            }
        default:
            return state
    }
}

export const language = (state = 'fr', action) => {
    if (action.type === SET_LANGUAGE) {
        return action.language
    } else {
        return state
    }
}

export const languagesList = (state = LANGUAGES, action) => {
    if (action.type === VIEWER_SUCCESS) {
        return action.data.languages
    } else {
        return state
    }
}

export const device = (state = 'mobile', action) => {
    if (action.type === SET_UI_DEVICE) {
        return action.device
    } else {
        return state
    }
}

export const sidebar = (state = { docked: true, open: false }, action) => {
    switch (action.type) {
        case SET_SIDEBAR_DOCKED:
            return {
                ...state,
                docked: action.value,
                open: false
            }
        case SET_SIDEBAR_OPEN:
            return {
                ...state,
                open: action.value
            }
        default:
            return state
    }
}

export const categoryCollapse = (state = {}, action) => {
    if (action.type === SET_CATEGORY_COLLAPSE) {
        return {
            ...state,
            [action.category]: action.collapse
        }
    } else {
        return state
    }
}

export const navbar = (
    state = { jobOpened: false, docsOpened: false, profileOpened: false, languagesOpened: false },
    action
) => {
    switch (action.type) {
        case SET_JOB_OPENED:
            return {
                ...state,
                jobOpened: action.value
            }
        case SET_DOCS_OPENED:
            return {
                ...state,
                docsOpened: action.value
            }
        case SET_PROFILE_OPENED:
            return {
                ...state,
                profileOpened: action.value
            }
        case SET_LANGUAGES_OPENED:
            return {
                ...state,
                languagesOpened: action.value
            }
        default:
            return state
    }
}

const ui = combineReducers({
    client,
    language,
    languagesList,
    device,
    sidebar,
    categoryCollapse,
    navbar,
    theme
})

export default ui
