import React from 'react'
import {Field} from 'redux-form'
import {AutoSizer, Column, Table} from "../../../../components/DataTable";
import {getLanguage} from "../../selectors";
import {OBJECT_FORM} from "./Form";
import {connect} from "react-redux";
import _ from "lodash";
import {translateName} from "../../../../utils/i18n";


const getFormName = field => `${field.path}${_.upperFirst(OBJECT_FORM)}`


// binding for redux-form
const VisualComponent = ({input: {value}, t, language}) => {
    return (
        <div>
            {
                value && value.map(element => {
                    const objects = Object.keys(element.responses).map(key => ({
                        responseElement: translateName(element.responses[key].name, language),
                        response: element.responses[key].result
                    }))
                    return (
                        <div style={{marginTop: '20px', marginBottom: '20px'}}>
                            <p style={{fontSize:'15px', marginBottom: '20px'}}>{translateName(element.question, language)}</p>
                            <div style={{border: '1px solid #d2d2d2', borderRadius: '4px'}}>
                                <AutoSizer disableHeight>
                                    {({ width }) => (
                                        <Table
                                            objects={objects}
                                            width={width}
                                            noRowsLabel={t('noRows')}
                                        >
                                            <Column
                                                key={0}
                                                dataKey={'responseElement'}
                                                label={t('responseElement')}
                                                width={800}
                                                flexGrow={1}
                                                flexShrink={1}
                                            />
                                            <Column
                                                key={1}
                                                dataKey={'response'}
                                                label={t('response')}
                                                width={200}
                                                flexGrow={1}
                                                flexShrink={1}
                                            />
                                        </Table>
                                    )}
                                </AutoSizer>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const FormSurveyResponseField = ({ field, t, language}) => {
    return (
        <Field
            name={field.path}
            formName={getFormName(field)}
            component={VisualComponent}
            t={t}
            language={language}
        />
    )
}

const mapStateToProps = state => ({
    language: getLanguage(state)
})

export default connect(mapStateToProps)(FormSurveyResponseField)
