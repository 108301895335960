import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { I18n } from 'react-i18next'
import MonthPicker from '../../../../components/Calendar/MonthPicker'
import { toggleCalendar } from '../../actions'
import { getModule, getLanguage, getDatePickerOpened } from '../../selectors'

// binding for redux-form
const VisualComponent = ({
     input: { value, onChange },
    path,
     open,
     onOpenChange,
     language,
     module
}) => {
    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <MonthPicker
                    path={path}
                    value={value}
                    open={open}
                    onChange={value => onChange(value)}
                    onOpenChange={onOpenChange}
                    language={language}
                    placeholder={t('pickDate')}
                />
            )}
        </I18n>
    )
}

const MonthPickerComponent = ({
     filter,
     open,
     language,
     module,
     toggleCalendar,
     onChange,
     fetchFilterData,
     fetchFilterFieldsLists
}) => {
    const fieldName = filter.path
    return (
        <Field
            name={fieldName}
            path={filter.tKey || filter.path}
            component={VisualComponent}
            onChange={(event, value) => {
                // we need to call fetchDtData after reduxFormChange
                event.preventDefault()
                onChange(fieldName, value)

                if(filter.filter) {
                    fetchFilterFieldsLists()
                }
                if(!!filter.autoFetch) {
                    fetchFilterData()
                }
            }}
            open={open}
            language={language}
            module={module}
            onOpenChange={() => toggleCalendar(filter.id)}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    open: getDatePickerOpened(state, filter.id),
    language: getLanguage(state),
    module: getModule
})

const mapDispatchToProps = dispatch => ({
    toggleCalendar: filterId => dispatch(toggleCalendar(filterId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MonthPickerComponent)
