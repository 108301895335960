/* eslint-disable no-unused-vars */
import {GET_OBJECT_SUCCESS} from "../../../../apps/KpModule/actions/api";

const _ = require('lodash')
const moment = require('moment')
const { dateToIntFormat } = require("../engines/functions");
const { setFormButtons } = require('../../../../apps/KpModule/actions')
const { basicContext } = require('../../../utils/contextUtils')
const { formatNumber, rowStyle } = require('../engines/functions')

const {
    calculateEmpiricAccountModels
} = require('../engines/empiricAccountModelEngine')

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

async function calculateState(context) {

    if(
        _.get(context, 'data.accountModels') &&
        _.get(context, 'data.dataYear.id')
    ) {

        const subsidiaryAssignments = await global.app.B.SubsidiaryAssignment.find({
            ...basicContext(context),
            fieldPath: ['id', 'subsidiaries.id'],
            query: {
                user: new global.ObjectID(context.user.id),
                group: new global.ObjectID(context.group.id)
            }
        })
        const subsidiariesIds = _.flatMap(
            subsidiaryAssignments,
            assignment => assignment.subsidiaries.map(
                subsidiary => new global.ObjectID(subsidiary.id)
            )
        )

        const shops = await global.app.B.Shop.find({
            ...basicContext(context),
            fieldPath: ['id'],
            query: {
                subsidiary: {$in: subsidiariesIds},
                group: new global.ObjectID(context.group.id)
            }
        })

        const shopsIds = shops.map(
            shop => new global.ObjectID(shop.id)
        )

        const refYear = Number.parseInt(_.get(context, 'data.dataYear.id', '1995'))
        const refDate = moment.utc(`${refYear}-01-01`, "YYYY-MM-DD").toDate();
        const shopBPEndorsedList = await global.app.B.ShopBPEndorsed.find({
            ...basicContext(context),
            fieldPath: [
                'id',
                'shop.fullName',
                'shop.pole.name',
                'shop.subsidiary.country.name',
                'shop.subsidiary.country.zone.name',
                'type', 'realOpening', 'endorsedOpening',
                'bPEndorsed.code'
            ],
            query: {
                shop: {$in: shopsIds},
                realOpening: {$gte: refDate},
                group: new global.ObjectID(context.group.id)
            }
        })


        const accountModels = _.get(context, 'data.accountModels').map(o => ({
            ...o,
            accountModelCode: o.code,
            accountModelType: o.accountModelType.id
        }))

        const realOpeningYears = shopBPEndorsedList.map(o => {
            return moment(_.get(o, 'realOpening'), 'YYYY-MM-DD').year()
        })
        const endYear = _.max(realOpeningYears)
        const realOpeningYearsBiggerThanRefYear = realOpeningYears.filter(o => o >= refYear)

        const beginYear = _.min(realOpeningYearsBiggerThanRefYear) - 1
        const calculationYears = _.range(beginYear, endYear + 10)

        const shopBPEndorsedResults = []
        await asyncForEach(shopBPEndorsedList, async shopBPEndorsed => {
            const realOpeningYear = moment(_.get(shopBPEndorsed, 'realOpening'), 'YYYY-MM-DD').year()
            const shopBPEndorsedCalculationYears = _.range(realOpeningYear -1, realOpeningYear + 10)
            const shopBPEndorsedResult = await calculateEmpiricAccountModels(
                shopBPEndorsed,
                shopBPEndorsedCalculationYears,
                accountModels,
                context
            )

            const formatedResult = accountModels.map(am => {
                const format = _.get(am, 'dataType.format.id')
                const precision = _.get(am, 'dataType.precision.id')
                return {
                    id: shopBPEndorsed.id + am.id,
                    zone: _.get(shopBPEndorsed, 'shop.subsidiary.country.zone.name'),
                    country: _.get(shopBPEndorsed, 'shop.subsidiary.country.name'),
                    subsidiary: _.get(shopBPEndorsed, 'shop.subsidiary.name'),
                    pole: _.get(shopBPEndorsed, 'shop.pole.name'),
                    shop: _.get(shopBPEndorsed, 'shop.fullName'),
                    bPEndorsed: _.get(shopBPEndorsed, 'bPEndorsed.code'),
                    type: _.get(shopBPEndorsed, 'type'),
                    realOpening: moment(_.get(shopBPEndorsed, 'realOpening'), 'YYYY-MM-DD')
                        .format('YYYY-MM-DD'),
                    endorsedOpening: moment(_.get(shopBPEndorsed, 'endorsedOpening'), 'YYYY-MM-DD')
                        .format('YYYY-MM-DD'),
                    name: am.name,
                    value: am.accountModelType === 'value'
                        ? formatNumber(shopBPEndorsedResult[am.code], format, precision)
                        : '',
                    years: calculationYears.reduce((acc, year) => {
                        return {
                            ...acc,
                            [year]: am.accountModelType === 'year'
                                ? formatNumber(shopBPEndorsedResult[am.code][year], format, precision)
                                : ''
                        }
                    }, {})
                }
            })

            shopBPEndorsedResults.push(...formatedResult)
        })
        return shopBPEndorsedResults
    } else {
        return []
    }
}

export const entity = {
    name: 'AccountModelExtraction',
    fields: [
        'shop', 'subsidiary', 'type', 'realOpening', 'endorsedOpening',
        'name', 'value', 'years'
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else {
                calculateState(context)
                    .then( objects => {
                        global.ioSocket.emit(
                            `fetchDT-success-${context.user.id}${context.clientContext.accessId}`,
                            objects
                        )
                    })
                    .catch(error => {
                        global.ioSocket.emit(
                            `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`,
                            {error: error.message}
                        )
                    })
                callback(null, [])
            }
        })
    }
}

export const module_ = {
    name: 'AccountModelExtraction',
    object: 'AccountModelExtraction',
    tKey: 'mTitle_accountModelExtraction',
    newable: false,
    removable: false,
    updatable: false,
    editable: false,
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    useSocketsOnFind: true,
    category: {
        path: 'empiric',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            'zone', 'country',
            'subsidiary', 'pole',
            'shop', 'bPEndorsed',
            'type',
            'realOpening',
            'endorsedOpening',
            { path: 'name', tKey: 'indicator' }, 'value',
            { path: 'years', dynamic: true }
        ]
    },
    filters: [
        {
            title: "AccountModel",
            path: "accountModels",
            type: 'tags',
            object: "AccountModel",
            display: "fullInfos",
            client: true,
            timer: 5,
            width: 12,
            placeholder: 'selectAccountModels',
            filters: ['onlyKindStore'],
            fieldPath: [
                'code',
                'name',
                'accountModelType.id',
                'dataType.precision.id',
                'dataType.format.id'
            ]
        },
        {
            title: 'DataYear',
            path: 'dataYear',
            object: 'DataYear',
            display: 'name',
            client: true,
            filters:[
                {
                    title: "byName",
                    path: "byName",
                    tKey: "byName",
                    type: "text",
                    match: (year, context) => {
                        const params = context.clientContext.params
                        const search = params && params.search
                        const regExp = new RegExp(search, "i");
                        return regExp.test(year.name)
                    }
                }
            ],
            type: 'asyncDropdownObject',
            placeholder: 'enterAYear'
        }
    ]
}
/* eslint-enable no-unused-vars */
