import {decrypt} from "../../../utils/crypto";

const _ = require('lodash')
const path = require("path")
const moment = require('moment')
const mediaDirPath = path.join(global.appRoot, global.isProd ? "buildServer/server/models/fncs/mails/media" : "src/server/models/fncs/mails/media")
const { reallocationTemplatesDirectoryPath} = require('./utils')
const { basicContext } = require("../../../utils/contextUtils");

const getQueryFromStatus = object => {
    switch (object.status) {
        case 'ongoing':
            return {'authorizations.code': '000002'}
        case 'rectification':
        case 'validated':
        case 'refused':
        case 'waitingPayment':
        case 'refundCompleted':
        case 'waitingRefund':
            return {_id: new global.ObjectID(object.beneficiary.id)}
        default:
            return {_id: null}
    }
}

export async function workflowMailing(reallocation, files, context) {

    const cUsers = await global.app.R.CUsers.collection
        .aggregate([
            { '$lookup': {
                    'from': 'profile',
                    'let': { 'authId': '$authorizations' },
                    'pipeline': [
                        { '$match': { '$expr': { '$in': ['$_id', '$$authId'] } } }
                    ],
                    'as':'authorizations'
                }
            },
            {
                $match: getQueryFromStatus(reallocation)
            },
        ])
        .toArray()

    const subjectUser = ['validated', 'refused', 'rectification', 'waitingPayment'].includes(reallocation.status)
        ? 'myCSEManCo'
        : `${reallocation.beneficiary.firstname} ${reallocation.beneficiary.lastname}`

    sendMails({
        users: cUsers,
        translate: context.tl,
        subjectUser: subjectUser,
        status: reallocation.status,
        refund: reallocation.refund,
        object: reallocation,
        contextUser: context.user,
        template: `${reallocation.status}Reallocation.html`,
        url: global.isProd
            ? `https://${context.host}/business/${context.groupModel.id}`
            : `http://localhost:3000/business/${context.groupModel.id}`
    })
}

const sendMails = ({ users, translate, subjectUser, object, refund, template, url, attachments, contextUser }) => {
    const logMessage = () => {
        console.log('...Mailing')
    }
    const {reference, status, date} = object

    const RIB = ['refundCompleted', 'waitingPayment'].includes(status)
        ? [{path: mediaDirPath + '/RIB_CSE.png'}]
        : []

    users.forEach(user => {
        const language = user.language || 'fr'
        const mail = _.defaults(
            {
                to: user.mail && decrypt(user.mail),
                context: {
                    user: user,
                    contextUser,
                    object,
                    date: moment(date.split(' ')[0]).format('DD/MM/YYYY'),
                    comment: 'fake',
                    refund,
                    url
                },
                content: template,
                subject: {
                    template: ` ${subjectUser} : ${translate('reallocationRequest', language)} ${reference} (${translate(status, language)})`
                },
                templateDir: reallocationTemplatesDirectoryPath(language),
                attachments: [...RIB]
            },
            defaultMail
        )

        global.mailer.sendMail(mail, logMessage)
    })
}

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: 'assistance@mycsemanco.com',
    verbose: {
        general: true
    }
}
