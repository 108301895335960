import {getDataListList} from "../../../../../apps/KpModule/selectors";
import moment from "moment/moment";
import {fiscalYearsMonth2} from "../../utils/usefulFunctions";
import {changeFieldDefault, setFieldVisibility} from "../../../../../apps/KpModule/actions";
import {translateName} from "../../../../../utils/i18n";
import _ from "lodash";
import {basicContext} from "../../../../utils/contextUtils";

export const entities = [
    {
        name: 'Structure',
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: true}
        ],
        fields:[
            {path: 'hierarchyType', type: 'HierarchyType'},
            {
                path: 'structureAxes',
                type:'StructureAxes',
                lazy: true,
                fieldPath: [
                    'id',
                    'code',
                    'name',
                    'hierarchyLevel',
                ],
                link: {
                    type: "OTM",
                    onParent: true,     //parent est Imputation
                    onChild: true,     //child est MonthAndAmount
                }
            },
        ],
    },
    {
        name: 'StructureAxes',
        //list : true,
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: true}
        ],
        fields: [
            {path: 'hierarchyLevel', type: 'integer', nullable: true},
            {
                path: 'nameWithStructure',
                fieldPath: ['structure.id'],
                $f: function (object, context, callback) {
                    const language = _.get(context, "language.id")
                    const fullName = translateName(object.structure.name, language) + ' : ' + translateName(object.name, language)
                    return callback(null, fullName)
                },

            },
            {path: 'axisUtilities', type: 'AxisUtility', link: 'MTM', nullable: true}
            /*
            {
                path: 'hierarchyLevelGetter',
                $f: (object, context, callback) => {
                    console.log('letsee', object)
                    callback(null, object.hierarchyLevel ? object.hierarchyLevel : 'Non renseigné')
                },

            },
             */
        ],
        filters: [
            {
                name: "axesToShowInStructureElement",
                isDefault: false,
                async: true,
                query: async function (context, callback) {
                    const structureElements = await global.app.SE.StructureElement.find({
                        ...basicContext(context),
                        fieldPath: ['structure.id', 'structure.structureAxes', 'structureAxis'],
                        query: {}
                    })
                    const lowerAxesOfUsedAxesIDs = structureElements
                        .filter(elem => !!elem.structureAxis.hierarchyLevel)
                        .flatMap(elem => {
                            const structure = elem.structureAxis.structure
                            const lowestAxis = structure.structureAxes.find(axis => axis.hierarchyLevel === elem.structureAxis.hierarchyLevel + 1)
                            return !!lowestAxis ? global.ObjectID(lowestAxis.id).toString() : []
                        })
                    const uniqIds = _.uniq(lowerAxesOfUsedAxesIDs)

                    const query = {
                            $or: [
                                {hierarchyLevel: {$in: [null, 1]}},
                                {_id: {$in: uniqIds.map(id => global.ObjectID(id))}}
                            ]
                        }
                    return callback(null, query)
                }
            },
            {
                name: 'byStructures',
                isDefault: false,
                path: 'structuresIds',
                query: function(context) {
                    console.log('data1', context.data)
                    const structuresIds = _.get(context, 'data.structuresIds') || []
                    return {"structure": {$in: structuresIds.map(id => global.ObjectID(id))}}
                }
            },
            {
                name: 'eliminateIncoherentAxes',
                isDefault: false,
                path: 'structuresIds',
                query: function(context) {
                    // if the structure have a vertical hierarchy, we should only choose one obligatory axe of this structure
                    const alreadyAttachedToThisAxes = _.get(context, 'data.alreadyAttachedToThisAxes') || []
                    console.log('data2', context.data)
                    //query = axes in already selected axes +
                    const incoherentAxes = alreadyAttachedToThisAxes.flatMap(axe => {
                        return axe.structure.hierarchyType.id === '1' ? //is vertical
                            axe.structure.structureAxes.filter(id => id !== axe.id)
                            : []
                    })
                    return  {_id: {$nin: incoherentAxes.map(id => global.ObjectID(id))}}
                }
            }
        ]
    }
]


export const module_ = {
    object: 'Structure',
    category: {
        path: 'structure',
        icon: 'layers'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name', type: 'translatedText'},
            {path: 'hierarchyType'}
        ],
        form: {
            fields: [
                {path: 'code'},
                {path: 'name', type: 'textarea', placeholder: 'fr:: nom en français\nen:: name in english\n...'},
                {
                    path: "hierarchyType",
                    type: 'toggle',
                    translate: true,
                    default: {id:'1'},
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const structureAxesField = module.viewMap.form.fields.find( field => field.path === 'structureAxes')
                            const hierarchyLevelField = structureAxesField.viewMap.form.fields.find( field => field.path === 'hierarchyLevel')
                            const isVerticalHierarchy = newValue?.id === '1'
                            console.log('isVerticalHierarchy', isVerticalHierarchy, newValue?.id, newValue)
                            store.dispatch(changeFieldDefault(hierarchyLevelField.id, isVerticalHierarchy ? 1 : null))
                            store.dispatch(setFieldVisibility(hierarchyLevelField.id, isVerticalHierarchy))
                        }
                    }
                },
                {
                    path: 'structureAxes',
                    tKey: 'axes',
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            'code',
                            {path: 'name', type: 'translatedText'},
                            {path: 'hierarchyLevel', tKey: 'level'},
                        ],
                        form: {
                            fields: [
                                'code',
                                {path: 'name', type: 'textarea', placeholder: 'fr:: nom en français\nen:: name in english\n...'},
                                {
                                    path: 'hierarchyLevel',
                                    tKey: 'level',
                                    default: 1
                                },
                                {path: 'axisUtilities'}
                            ]
                        }
                    }
                }
            ]
        },
    }
}


