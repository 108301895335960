import { PureComponent } from 'react'
import PropTypes from 'prop-types'

const defineDevice = mql => (mql.matches ? 'desktop' : 'mobile')

class DeviceDetector extends PureComponent {
    constructor(props) {
        super(props)

        const mql = window.matchMedia('(min-width: 850px)')

        this.state = {
            mql
        }

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    }

    componentDidMount() {
        this.state.mql.addListener(this.mediaQueryChanged)

        this.setState({
            device: defineDevice(this.state.mql)
        })

        this.props.handleChange(defineDevice(this.state.mql))
        this.props.dockSidebar(defineDevice(this.state.mql) === 'desktop')
    }

    componentWillUnmount() {
        this.state.mql.removeListener(this.mediaQueryChanged)
    }

    mediaQueryChanged() {
        this.setState({
            device: defineDevice(this.state.mql)
        })

        this.props.handleChange(defineDevice(this.state.mql))
        this.props.dockSidebar(defineDevice(this.state.mql) === 'desktop')
    }

    render() {
        return null
    }
}

DeviceDetector.propTypes = {
    handleChange: PropTypes.func.isRequired,
    dockSidebar: PropTypes.func.isRequired
}

export default DeviceDetector
