import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { touch } from 'redux-form'
import {StyledTableField} from '../../../../components/StyledTable'
import { exportState } from '../../actions/api'
import {getFieldListFields, getLanguage, getModule} from '../../selectors'
import getFormValidations from './formValidations'
import ValidationUI from "../../../../components/ValidationUI";

const VisualComponent = ({
     input: { value, onChange },
     meta:{ touched, error },
     field,
     listFields,
     language,
     initialValues,
     exportState,
    t
}) => {

    const translatedError = t(error)
    return (
        <div style={{marginTop: '5px'}}>
            <StyledTableField
                value={value}
                onChange={onChange}
                field={field}
                listFields={listFields}
                language={language}
                initialValues={initialValues}
                exportState={exportState}
            />
            {touched && error && <ValidationUI error={translatedError}/>}
        </div>
    )
}

class StyledTable extends React.Component {
    constructor(props){
        super(props)
        this.validations = getFormValidations(props.field, props.listFields)
    }
    render() {
        const {field, listFields, module, language, t, touch, exportState, initialValues} = this.props

        return (
            <Field
                name={field.path}
                component={VisualComponent}
                validate={this.validations}
                listFields={listFields}
                field={field}
                module={module}
                t={t}
                touch={touch}
                language={language}
                initialValues={initialValues}
                exportState={exportState}
            />
        )
    }
}


const mapStateToProps = (state, { field }) => ({
    module: getModule(state),
    listFields: getFieldListFields(state, field.id),
    language: getLanguage(state)
})

const mapDispatchToProps = dispatch => ({
    touch: field => dispatch(touch(OBJECT_FORM, field)),
    exportState: (title, columns, objects) => dispatch(exportState(title, columns, objects))
})


export default connect(mapStateToProps, mapDispatchToProps)(StyledTable)
