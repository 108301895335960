import { INITIALIZE_MODULE } from '../actions/index'

const groupModel = (state = null, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return action.groupModel ? action.groupModel : null
        default:
            return state
    }
}

export default groupModel
