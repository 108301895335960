const _ = require('lodash')

async function findData() {
    return [
        {
            id: 'newYearVideo',
            link: 'voeux_2023.mp4',
            poster: 'voeux_2023.png'
        }
    ]
}
export const entity = {
    name: 'NewYearVideo',
    dashboard: true,
    fields: ['id', 'link', 'poster'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData()
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
