import moment from 'moment'
import _ from 'lodash'
import {getStoreHabilitationQuery} from '../../utils/habilitations'

async function findData(context) {

    const habQuery = getStoreHabilitationQuery(context)
    const [habilitations] = await Promise.all([habQuery])

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    if (habilitations.length) {
        const datesQuery = {
            'date.gte': { $gte: dates[0].toDate() },
            'date.lt': { $lte: dates[1].toDate() }
        }

        const habilitation = habilitations[0]
        const store = habilitation.store
        const organization = habilitation.organization

        const alertConfigurationCollection =
            global.app.S.AlertConfiguration.collection
        const alertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'CAVendeur7jours'
            })
            .toArray()

        const [alertConfigurations] = await Promise.all([
            alertConfigurationQuery
        ])
        const alertConfiguration = alertConfigurations[0]

        const alertConfQuery = {
            alertConfiguration: alertConfiguration._id
        }

        const alertCollection = global.app.S.Alert.collection
        const alertCashierQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...datesQuery,
                        ...alertConfQuery,
                        store: store._id,
                        'theme-CANet': { $exists: true }
                    }
                },
                {
                    $group: {
                        _id: '$hic-cashier',
                        CA: { $sum: '$theme-CANet.Mt' }
                    }
                },
                { $sort : { 'CA' : 1} }
            ])
            .toArray()

        const alertRegionQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...datesQuery,
                        ...alertConfQuery,
                        organizations: organization._id,
                        'theme-CANet': { $exists: true }
                    }
                },
                {
                    $group: {
                        _id: { cashier: '$hic-cashier', store: '$hic-store' },
                        CA: { $sum: '$theme-CANet.Mt' }
                    }
                }
            ])
            .toArray()

        const [cashierAlerts, organizationAlerts] = await Promise.all([
            alertCashierQuery,
            alertRegionQuery
        ])

        const caByCashier = cashierAlerts.map(o => ({
            vendeur: o._id,
            CA: _.round(o.CA / 1000, 1),
            //color: '#4fd4ee'
        }))

        const caMoyMagasin = _.round(
            _.reduce(
                cashierAlerts,
                (sum, o) => {
                    return sum + o.CA
                },
                0
            ) / (cashierAlerts.filter(o => o.CA !== 0).length * 1000),
            1
        )

        const caMoyRegion = _.round(
            _.reduce(
                organizationAlerts,
                (sum, o) => {
                    return sum + o.CA
                },
                0
            ) / (organizationAlerts.filter(o => o.CA !== 0).length * 1000),
            1
        )

        return [
            {
                id: 'turnoverPerSeller',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: [
                    ...caByCashier,
                    {
                        vendeur: 'Moy. Mag.',
                        CA: caMoyMagasin,
                        color: '#5964c7'
                    },
                    {
                        vendeur: 'Moy. Rég.',
                        CA: caMoyRegion,
                        color: '#58508d'
                    }
                ]
            }
        ]
    }
    else {
        return [
            {
                id: 'turnoverPerSeller',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: []
            }
        ]
    }
}

export const entity = {
    name: 'TurnoverPerSeller',
    dashboard: true,
    fields: ['id', 'subTitle', 'data'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
