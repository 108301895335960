const _ = require("lodash");
const moment = require("moment");

const todayISO = moment().format("YYYY-MM-DD");

// build a flat array of { habilitation, habFunction } linked to user
const getUserAffectations = (habFunctions, userId) => _(habFunctions)
    .flatMap(
        habFunction => habFunction.habilitations.map(
            habilitation => ({habFunction, habilitation})
        )
    )
    .filter(
        ({habilitation}) => habilitation.user === userId
    )
    .value();

// extract habilitations for a given axis
const axisHabilitationGetter = affectations => axisName => _(affectations)
    .filter(
        ({habFunction}) => habFunction.organizationAxisJoin.joinedEntity === axisName
    )
    .map("habilitation")
    .value();


export const entity = {
    name: "Scoring",
    collectionName: "s.scoring",
    fields: [
        { path: 'baseDay', type: 'date'},
        {
            type: "ScoreLine",
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        },

        {path: "date.gte", type: "date", index: true},
        {path: "date.lt", type: "date", index: true},

        {
            type: "ScoringConfig",
            link: {oppositeField: {link: {deleteType: "cascade"}}}
        },

        {
            path: "dataPeriod",
            f: function () {
                let bDate = moment.utc(this["date"].gte).format("YYYY-MM-DD");
                let eDate = moment.utc(this["date"].lt).subtract(1, "days").format("YYYY-MM-DD");
                return `${bDate} -> ${eDate}`;
            }
        },

        {
            path: "ruleName",
            fieldPath: ["scoringConfig.tName"],
            f: function() { return _.get(this, "scoringConfig.tName"); }
        },
        {
            path: "periodicity",
            fieldPath: ["scoringConfig.timeWindow.tKey"],
            f: function() { return _.get(this, "scoringConfig.timeWindow.tKey"); }
        },
        {
            path: "description",
            fieldPath: ["scoringConfig.description"],
            f: function() { return _.get(this, "scoringConfig.description"); }
        },
        {
            path: "filterFieldsRefs",
            fieldPath: ["scoringConfig.filterFields.completeName"],
            f: function() {
                return this.scoringConfig.filterFields.length ? this.scoringConfig.filterFields.map(filterField => filterField.completeName).join(", ") : "";
            }
        },

        {
            path: "scoredElementsName",
            fieldPath: ["scoringConfig.scoredElements.tName"],
            f: function () { return  _.get(this, "scoringConfig.scoredElements", []).map(elem => elem.tName).join(' | ') }
        },

        {
            path: "comparisonAxisName",
            fieldPath: ["scoringConfig.comparisonAxis.tName"],
            f: function () { return _.get(this, "scoringConfig.comparisonAxis.tName"); }
        },

        {
            path: "filteredScoreLines",
            type: "ScoreLine",
            lazy: true,
            link: {
                type: "OTM",
                list: true
            },
            fieldPath: ['id'],
            $f: function (scoring, context, callback) {

                if (!(context.user && context.user.id)) {
                    console.log('missing user in context', context.user);
                    return callback(null, []);
                }

                const group = context.group;
                const fieldPath = ["elementsName", "comparisonName", "scoreInValue", "elementValue", "groupComparisonMean"];

                let query = {
                    scoring: new global.ObjectID(scoring.id),
                    group: new global.ObjectID(group.id)
                };

                global.app.S.ScoreLine.find({query, group, fieldPath, forceQuery: true}, (error, objects) => {
                    if (error) return callback(error);

                    // sort in descending order by score
                    const sortedObjects = _.orderBy(objects, 'score', 'desc')

                    callback(undefined, sortedObjects);
                });
            }
        },
        {
            path: "linesNb",
            lazy: true,
            fieldPath: ['id'],
            $f: function (scoring, context, callback) {

                if (!(context.user && context.user.id)) {
                    console.log('missing user in context', context.user);
                    return callback(null, []);
                }

                global.app.S.ScoreLine.collection.find(
                    {
                        scoring: new global.ObjectID(scoring.id),
                        group: new global.ObjectID(context.group.id)
                    }).toArray((error, objects) => {
                        if (error) return callback(error);
                        callback(null, objects.length);
                })
            }
        },
        {
            path: "oldLinesNb",
            lazy: true,
            fieldPath: ["filteredScoreLines"],
            $f: function(scoring, context, callback)  {
                callback(null, scoring.filteredScoreLines.length);
            }
        },
        {
            path: "settings",
            list: true,
            f: function(){
                return [this];
            }
        }
    ]
}

export const module_ = {
    object: "Scoring",
    tKey: "mTitle_followBenchmarks",
    defaultSortBy: "baseDay",
    defaultSortDirection: "ASC",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {path: "ruleName", tKey: "scoringType"},
            {path: "periodicity", translate: true, width: 50},
            {path: "filterFieldsRefs", tKey: "perimeter", width: 130},
            {path: "scoredElementsName", tKey: "scoredObjects", width: 50},
            {path: "comparisonAxisName", tKey: "comparisonGroup", width: 50},
            {path: 'baseDay', tKey: 'date'},
            {path: "dataPeriod", tKey: "dataPeriod", width: 160},
            {path: "linesNb", tKey: "NumberOfScoredElements", width: 20}
        ],
        form: [
            {path: "ruleName", tKey: "scoringType", disabled: true},
            {path: "description", tKey: "description", disabled: true},
            {path: "dataPeriod", disabled: true},
            {
                path: "settings",
                tKey: "context",
                type: "dtObjects",
                fields: [
                    {path: "filterFieldsRefs", tKey: "perimeter"},
                    {path: "scoredElementsName", tKey: "scoredObjects"},
                    {path: "comparisonAxisName", tKey: "comparisonGroup"},
                    {path: "periodicity", translate: true}
                ]
            },
            {
                path: "filteredScoreLines",
                tKey: "result_plural",
                type: "dtObjects",
                applyBoard: true,
                maxRows: 20,
                fields: [
                    {path: "order", tKey: "ranking", width: 50},
                    {path: "elementsName", tKey: "scoredElements", width: 150},
                    {path: "elementValue", tKey: 'value', width: 70},
                    {path: "comparisonName", tKey: "comparisonGroup", width: 150},
                    {path: "groupComparisonMean", tKey: 'average', width: 70},
                    {path: "scoreInValue", tKey: "scoreInValue", width: 30}

                ]
            }
        ]
    },
    filters: [
        // {
        //     value: "userHaveLines",
        //     fieldPath: ["linesNb"], // TODO integrate filter fieldPath
        //     client: false,
        //     match: function(object) {
        //         // KLUDGE _.isUndefined(object.linesNb) for match on get
        //         return _.isUndefined(object.linesNb) || object.linesNb;
        //
        //     },
        // },
        // {
        //     value: 'userHaveLines',
        //     client: false,
        //     query: function (context, callback) {
        //
        //     }
        // },
        {
            path: 'scoringConfig',
            object: 'ScoringConfig',
            tKey: 'scoringConfig',
            display: 'tName',
            filters: ['scoringConfigurationsByHabFunctions'],
            client: true,
            query: function (context) {
                const scoringConfigId = _.get(context, 'data.scoringConfig.id');
                return scoringConfigId
                    ? {'scoringConfig': global.ObjectID(scoringConfigId)}
                    : {}
            }
        },
        {
            path: "period",
            type: "dateRange",
            ranges: "C",
            client: true,
            default: [todayISO, todayISO],
            async: true,
            query: function(context, callback) {

                const dates = context.data.period.map(
                    date => moment.utc(date.trim(), "YYYY-MM-DD")
                )
                const baseDayQuery = {
                    $gte: dates[0].toDate(),
                    $lte: dates[1].add(1, "days").toDate()
                }
                const scoringConfigId = _.get(context, 'data.scoringConfig.id');
                if(scoringConfigId) {
                    callback(null, {baseDay: baseDayQuery})
                } else {
                    global.app.S.User.getUserHabFunctions(
                        context.user,
                        context,
                        (e, userHabFunctions) => {
                            if(e) return callback(e)
                            global.app.S.ScoringConfig.collection.aggregate([
                                {
                                    $match: {
                                        $or: [
                                            {habFunctions: {$size: 0}},
                                            {habFunctions: {$in: userHabFunctions.map(hf => new global.ObjectID(hf.id))}}
                                        ]
                                    }
                                },
                                {
                                    $lookup: {
                                        from: 's.scoreLine',
                                        localField: '_id',
                                        foreignField: 'scoringConfig',
                                        as: 'scoreLine'
                                    }
                                },
                                {   $unwind: '$scoreLine' },
                                {
                                    $match: {
                                        'scoreLine.baseDay': baseDayQuery
                                    }
                                },
                                {
                                    $group: {_id: null, scoring: {$addToSet: "$scoreLine.scoring"}}
                                }
                            ], (e, result) => {
                                if(e) return callback(e)
                                const query = result && result.length && _.first(result).scoring.length
                                    ? {_id: {$in: _.first(result).scoring}}
                                    : {_id: null}
                                callback(null, query)
                            })
                        }
                    )
                }
            }
        }
    ],
    removable: false,
    updatable: false,
    newable: false
}
