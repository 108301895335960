/* eslint-disable no-unused-vars */
const _ = require('lodash')
const moment = require('moment')

const { basicContext } = require('../../utils/contextUtils')
const {
    irr,
    is_eur,
    getYear,
    min,
    max,
    sum,
    avg
} = require('./hermesFunctions')

async function calculateState(idStateModel, context, callback) {
    console.time('dataSearch')

    const businessPlanCode = _.get(context, 'data.shopBp.bp')
    const realDate = moment(_.get(context, 'data.shopBp.real'), 'YYYY-MM-DD')
    const beginYear = realDate.year() - 1
    const stateYears = _.range(beginYear, beginYear + 11).map(year => year)

    const shop = await global.app.F.HermesShop.get(
        {
            _id: new global.ObjectID(
                _.get(context, 'data.shopBp.hermesShop.id')
            )
        },
        { ...basicContext(context), fieldPath: ['subsidiary.id'] }
    )
    const subsidiary = await global.app.F.HermesSubsidiary.get(
        { _id: shop.subsidiary },
        { ...basicContext(context), fieldPath: ['country.id'] }
    )

    const bp = await global.app.F.HermesBusinessPlan.get(
        { code: businessPlanCode },
        { ...basicContext(context) }
    )

    const f_year = realDate.year()
    const project_type = _.get(context, 'data.shopBp.hermesProjectType.id')
    const project_real_opening_date = _.get(context, 'data.shopBp.real') // "YYYY-MM-DD"

    // les modèles de comptes
    const accountModels = await global.app.F.AccountModel.collection
        .find()
        .toArray()
    const accountModelsByCode = accountModels.reduce(
        (acc, model) => ({
            ...acc,
            [model.code]: {
                id: model._id,
                formula: model.formula
            }
        }),
        {}
    )

    // Get state model
    const selectedStateModel = await global.app.F.StateModel.get(idStateModel, {
        ...basicContext(context),
        fieldPath: [
            'id',
            'code',
            'name',
            'yearsStateModelLines.order',
            'yearsStateModelLines.accountModel',
            'yearsStateModelLines.accountModel.id',
            'yearsStateModelLines.accountModel.code',
            'yearsStateModelLines.accountModel.script',
            'valueStateModelLines.order',
            'valueStateModelLines.accountModel',
            'valueStateModelLines.accountModel.id',
            'valueStateModelLines.accountModel.code',
            'valueStateModelLines.accountModel.script'
        ]
    })

    const orderedYearsAccountModels = _(selectedStateModel.yearsStateModelLines)
        .sortBy('order')
        .map('accountModel')

    const orderedValueAccountModels = _(selectedStateModel.valueStateModelLines)
        .sortBy('order')
        .map('accountModel')

    const types = {
        F: { mesh: 1, subsidiary: shop.subsidiary },
        H: { mesh: 2 },
        P: { mesh: 3, country: subsidiary.country },
        M: { mesh: 4, shop: new global.ObjectID(shop.id) },
        B: { mesh: 5, bp: new global.ObjectID(bp.id) }
    }

    const hermesData = await global.app.F.HermesData.collection
        .find({
            $or: _.values(types)
        })
        .toArray()

    console.timeEnd('dataSearch')

    const data = (type, name, year) => {
        const dataRows = _.filter(hermesData, { ...types[type], name, year })
        return dataRows.reduce((acc, o) => acc + o.value, 0)
    }

    const cacheObject = {}

    const formula = (...args) => formulaM(...args)

    const formulaM = (code, beginYear, lastYear) => {
        //if (beginYear < 2010) return 0

        const accountModel = accountModelsByCode[code]
        if (!accountModel || accountModel === Infinity) return 0
        if (lastYear) {
            const results = []
            for (let year = beginYear; year < lastYear; year++) {
                if (cacheObject[`${code}-${year}`]) {
                    results.push(cacheObject[`${code}-${year}`])
                } else {
                    const result = eval(accountModel.formula)
                    cacheObject[`${code}-${year}`] = result
                    results.push(result)
                }
            }
            return results
        } else if (beginYear) {
            let year = beginYear

            if (cacheObject[`${code}-${year}`]) {
                return cacheObject[`${code}-${year}`]
            }

            const result = eval(accountModel.formula)
            cacheObject[`${code}-${year}`] = result
            return result
        } else {
            const result = eval(accountModel.formula)
            cacheObject[`${code}`] = result
            return result
        }
    }

    console.time('computeTime')

    const yearsStateLines = orderedYearsAccountModels.map(am => {
        return stateYears.reduce(
            (acc, year) => {
                return {
                    ...acc,
                    years: {
                        ...acc.years,
                        [year]: _.floor(formulaM(am.code, year) / 1000)
                    }
                }
            },
            { id: am.id, name: am.code, years: {} }
        )
    })

    const valueStateLines = orderedValueAccountModels.map(am => ({
        id: am.id,
        name: am.code,
        value: {
            data: formulaM(am.code),
            type: 'editText',
        }
    }))

    console.timeEnd('computeTime')

    return {
        ..._.pick(selectedStateModel, ['id', 'name']),
        yearsStateLines,
        valueStateLines
    }
}

async function findData(context) {
    const stateModels = await global.app.F.StateModel.find(context)
    return stateModels.map(o => _.pick(o, ['id', 'code', 'name']))
}

export const entity = {
    name: 'State',
    fields: ['name'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error)
            else
                calculateState(id, context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    }
}

export const module_ = {
    name: 'State',
    object: 'State',
    tKey: 'mTitle_state',
    category: {
        path: 'hermes',
        icon: 'server'
    },
    viewMap: {
        dt: [{ path: 'name' }],
        form: [
            { path: 'name', type: 'readOnly', fullWidth: true },
            {
                path: 'yearsStateLines',
                type: 'styledTable',
                autoGrow: true,
                fullWidth: true,
                fields: [
                    {
                        path: 'name',
                        style: {
                            flex: '1 1 500px',
                            fontSize: '12px',
                            whiteSpace: 'initial'
                        }
                    },
                    { path: 'years', dynamic: true }
                    // { path: 'style', hidden: true },
                    // { path: 'isHeader', hidden: true }
                ]
            },
            {
                path: 'valueStateLines',
                type: 'styledTable',
                autoGrow: true,
                fullWidth: true,
                fields: [
                    {
                        path: 'name',
                        style: {
                            flex: '1 1 500px',
                            fontSize: '12px',
                            whiteSpace: 'initial'
                        }
                    },
                    { path: 'value' }
                ]
            }
        ]
    },
    filters: [
        {
            title: 'Shop - Bp',
            path: 'shopBp',
            object: 'HermesShopBP',
            display: 'fullInformations',
            fieldPath: [
                'bp',
                'hermesShop.id',
                'hermesShop.openingDate',
                'endorsed',
                'real',
                'hermesProjectType'
            ],
            client: true,
            //clearable: true,
            autoFetch: false
        }
    ]
}
/* eslint-enable no-unused-vars */
