const _ = require('lodash')
const {setFieldListOptions} = require('../../../apps/KpModule/actions')
const {generateFetchFieldListAction} = require('../../../apps/KpModule/actions/api')

export const modules = [
    {
        object: 'Project',
        category: {
            path: 'actions',
            icon: 'briefcase'
        },
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                //{ path: 'project', initiallyNotVisible: true },
                'company',
                { path: 'subject', display: 'fullName' },
                'code',
                'name',
                { path: 'contacts', tKey: 'interlocutor' },
                { path: 'team', display: 'firstname', initiallyNotVisible: true },
                'status',
                { path: 'creation', initiallyNotVisible: true },
                { path: 'update', initiallyNotVisible: true },
                { path: 'user', display: 'firstname', initiallyNotVisible: true },
            ],
            form: {
                fields: [
                    {
                        path: 'company',
                        display: 'fullName',
                        subscriptions: {
                            onChange: (newValue, oldValue, { store }) => {
                                if (newValue && newValue.id) {
                                    const contactQuery = {company: {id: newValue.id}}
                                    store.dispatch(
                                        generateFetchFieldListAction(
                                            'm-K-project.Project_contacts',
                                            store.getState,
                                            'form',
                                            {data: contactQuery}
                                        )
                                    )
                                }
                                else {
                                    store.dispatch(setFieldListOptions('e_contacts', []))
                                }
                            }
                        }
                    },
                    { path: 'subject', display: 'fullName' },
                    {path: 'code', required: false, disabled: true},
                    'name',
                    {
                        path: 'contacts',
                        tKey: 'interlocutor',
                        filters: ['byCompany']
                    },
                    'team',
                    { path: 'description', type: 'richTextEditor' },
                    { path: 'status', default: {id: 'ongoing'} },
                    { path: 'comments', tKey: 'note' }
                ],
                onOpen: ({ state, store }) => {
                    const company = _.get(state, 'edit.object.data.company.id')
                    if (company) {
                        store.dispatch(
                            generateFetchFieldListAction(
                                'm-K-project.Project_contacts',
                                store.getState,
                                'form',
                                {data: {company}}
                            )
                        )
                    } else {
                        store.dispatch(setFieldListOptions('e_contacts', []))
                    }
                }
            }
        },
        filters: [
            {
                path: 'subject',
                object: 'Subject',
                display: 'fullName',
                client: true,
                clearable: true,
                placeholder: 'selectSubject',
                default: {id: null},
                query: function(context) {
                    const subjectId = _.get(context.data, 'subject.id')
                    if (subjectId)
                        return { subject: global.ObjectID(subjectId) }
                    return {}
                }
            },
            {
                path: 'contacts',
                object: 'Contact',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectContact',
                default: {id: null},
                query: function(context) {
                    const contactId = _.get(context.data, 'contacts.id')
                    if (contactId)
                        return { contacts: global.ObjectID(contactId) }
                    return {}
                }
            },
            {
                path: 'projectStatus',
                object: 'ProjectStatus',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectSubjectStatus',
                default: {id: 'ongoing'},
                query: function(context) {
                    const projectStatusId = _.get(
                        context.data,
                        'projectStatus.id'
                    )
                    if (projectStatusId)
                        return { status: projectStatusId }
                    return {}
                }
            },
            'onlyOwner'
        ]
    },
    {
        object: 'Action',
        category: {
            path: 'actions',
            icon: 'briefcase'
        },
        defaultSortBy: 'lastDeadlineDate',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                { path: 'project', display: 'fullName', initiallyNotVisible: true },
                'name',
                { path: 'lastDeadlineDate', tKey: 'deadline' },
                { path: 'lastDeadlineNbDays', tKey: 'nbDays', initiallyNotVisible: true },
                { path: 'responsible', display: 'firstname'},
                { path: 'type', display: 'fullName', initiallyNotVisible: true},
                'status',
                { path: 'creation', initiallyNotVisible: true },
                { path: 'update', initiallyNotVisible: true },
                { path: 'user', display: 'firstname', initiallyNotVisible: true },
            ],
            form: [
                {
                    path: 'project',
                    display: 'fullName',
                    filters: ['onlyOngoingProspectiveProject', 'onlyUserInTeam'],
                    fieldPath: ['id', 'fullName', 'team.id', 'subject.actionType'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { store }) => {

                            if (newValue && newValue.id) {
                                const actionTypes = _.get(newValue, 'subject.actionType') || []
                                const actionTypeQuery = { actionType: actionTypes.map(o => o.id) }
                                const responsibleQuery = { team: (newValue.team || []).map(o => o.id) }

                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-K-action.Action_type',
                                        store.getState,
                                        'form',
                                        {data: actionTypeQuery}
                                    )
                                )
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        'm-K-action.Action_responsible',
                                        store.getState,
                                        'form',
                                        {data: responsibleQuery}
                                    )
                                )
                            }
                            else {
                                store.dispatch(setFieldListOptions('e_type', []))
                                store.dispatch(setFieldListOptions('e_responsible', []))
                            }
                        }
                    }
                },
                {
                    path: 'type',
                    display: 'fullName',
                    filters: [
                        {
                            query: function(context) {
                                const actionType = _.get(context.data, 'actionType')
                                if (actionType) {
                                    const actionTypeObjectId = actionType.map(typeId => global.ObjectID(typeId))
                                    return { _id: { $in: actionTypeObjectId } }
                                }
                                return {_id: null}
                            }
                        }
                    ],
                },
                { path: 'name', required: true },
                {
                    path: 'deadlines',
                    required: true,
                    defaultSortBy: 'inputDate',
                    defaultSortDirection: 'DESC',
                    viewMap: {
                        dt: [
                            'date',
                            'nbDays',
                            'note',
                            { path: 'inputDate', tKey: 'creation' }
                        ],
                        form: [
                            { path: 'date', required: true, editable: false },
                            { path: 'nbDays', default: 0, type: 'text', required: true, editable: false, positiveNumberStrict: true },
                            { path: 'note', type: 'textarea', editable: false },
                        ],
                    }
                },
                {
                    path: 'responsible',
                    filters: [
                        {
                            query: function(context) {
                                const team = _.get(context.data, 'team')
                                if (team) {
                                    const teamObjectId = team.map(member => global.ObjectID(member))
                                    return { _id: {$in: teamObjectId} }
                                }
                                return {_id: null}
                            }
                        }
                    ],
                },
                { path: 'description', type: 'richTextEditor' },
                { path: 'status', default: {id: 'ongoing'} },
                'files',
                { path: 'comments', tKey: 'note' }
            ]
        },
        filters: [
            {
                path: 'project',
                object: 'Project',
                display: 'fullName',
                client: true,
                clearable: true,
                placeholder: 'selectProject',
                default: {id: null},
                query: function(context) {
                    const projectId = _.get(context.data, 'project.id')
                    if (projectId)
                        return { subject: global.ObjectID(projectId) }
                    return {}
                }
            },
            {
                path: 'statusAction',
                object: 'ActionStatus',
                display: 'name',
                client: true,
                clearable: true,
                placeholder: 'selectActionStatus',
                default: {id: 'ongoing'},
                query: function(context) {
                    const actionStatusId = _.get(
                        context.data,
                        'statusAction.id'
                    )
                    if (actionStatusId)
                        return { status: actionStatusId }
                    return {}
                }
            },
            {
                objectFieldPath: ['project.team.id'],
                match: function(object, context) {
                    const userId = _.get(context, 'user.id')
                    return userId
                        ? object.project.team.some(o => o.id === userId)
                        : false
                }
            }
        ]
    }
]
