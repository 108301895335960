import React from 'react'
import { Field } from 'redux-form'
import TextAreaField from '../../../../components/Form/TextAreaField'
import { connect } from 'react-redux'
import getFormValidations from './formValidations'

// binding for redux-form
const Input = ({ input, meta:{touched, error}, path, required, placeholder, editable, disabled, dynamicHeight, t  }) => {
    const translatedError = t(error)
    const rows = dynamicHeight
        ? input.value.split(/\r\n|\r|\n/).length
        : 3
    return <TextAreaField {...input} path={path} required={required} touched={touched} rows={rows} error={translatedError} placeholder={placeholder} disabled={!editable || disabled} t={t} />
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormTextField = ({ field, t, objectMode }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            required={field.required}
            component={Input}
            validate={validations}
            placeholder={field.placeholder}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            disabled={field.disabled || !field.writable}
            dynamicHeight={field.dynamicHeight}
            t={t}
        />
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(FormTextField)
