export const dependencies = [
        {
            name: "DashboardElement",
            type: "static",
            fields: [
                {path: "id", type: "string"},
                "name",
                "type",
                "url",
                "suffix",
                "link",
                "keys",
                "colors",
                "width",
                "sum",
                "sumColor",
                "absolute"
            ],
            objects: [
                {
                    id: "balanceByUser",
                    name: "balanceByUser",
                    width: 1,
                    type: 'multipleBarChart',
                    url: "BalanceByUser",
                    keys: ['paidRefund', 'postedRefund', 'refundBalance', 'refundLimit'],
                    colors: ['#15a021', '#1b638e', '#bbb', '#656565']
                },
                {
                    id: "animatedVideo",
                    name: "animatedVideo",
                    width: 1,
                    type: 'video',
                    url: "AnimatedVideo",
                },
                {
                    id: "holidaysVideo",
                    name: "holidaysVideo",
                    width: 1/2,
                    type: 'video',
                    url: "HolidaysVideo",
                },
                {
                    id: "burnoutVideo",
                    name: "burnoutVideo",
                    width: 1,
                    type: 'video',
                    url: "BurnoutVideo",
                },
                {
                    id: "newYearVideo",
                    name: "newYearVideo",
                    width: 1/2,
                    type: 'video',
                    url: "NewYearVideo",
                },
                {
                    id: "votingVideo",
                    name: "votingVideo",
                    width: 1,
                    type: 'video',
                    url: "VotingVideo",
                }
            ]
        }
    ]

export const entity = {
    name: "DashboardAffectation",
    fields: [
        {type: "User"},
        {
            path: "elements",
            type: "DashboardElement",
            link: {
                type: "OTM",
                onParent: true,
                onChild: false
            },
            notEmpty: true
        },
    ]
}

export const module_ = {
    object: "DashboardAffectation",
    tKey: "dashboard",
    noLeftBoard: true,
    displayLog: false,
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            "user"
        ],
        form: [
            "user",
            {path: "elements", type: "tags", display:"name"}
        ]
    }
}
