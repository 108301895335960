const _ = require("lodash");
const moment = require("moment");
const async = require("async");
const {basicContext} = require('../../../utils/contextUtils')

const recomposeBusinessActivityLines = (referentialBusiness, daysByActivitys) => {
    const previousDays = _(daysByActivitys)
        .get("userDaysByActivitys", [])
        .reduce(
            (acc, userDaysByActivity) => Object.assign(acc, {[userDaysByActivity.businessActivity.id]: userDaysByActivity.days}),
            {}
        );

    return _(referentialBusiness.businessActivityConfigs)
        .filter({active: true})
        .reduce(
            (acc, activityConfig) => {
                acc.push({
                    id: `${activityConfig.businessActivity.id}-${referentialBusiness.id}`,
                    referentialBusiness,
                    businessActivity: activityConfig.businessActivity,
                    codeBis: activityConfig.codeBis,
                    days: previousDays[activityConfig.businessActivity.id] || 0
                });

                return acc;
            },
            []
        );
};

const extractActivityLines = (referentialBusiness, userActivityInBusineses) => {
    const userActivityInBusiness = userActivityInBusineses.find(
        o => o.referentialBusiness.id === referentialBusiness.id
    );

    return recomposeBusinessActivityLines(referentialBusiness, userActivityInBusiness);
};

const businessActivityObjects = require("../staticEntities").default.find(
    o => o.name === "BusinessActivity"
).objects;

export const entities = [
    {
        name: "UserDaysByActivity",
        type: "mongoInternal",
        fields: [
            {path: "days", type: "integer"},
            "BusinessActivity"
        ]
    },
    {
        name: "UserActivityInBusiness",
        fields: [
            {path: "month", index: true},
            {path: "year", index: true},
            {type: "User", index: true},
            {type: "ReferentialBusiness", index: true},
            {type: "UserDaysByActivity", link: "OTM"}
        ]
    },

    // Special entities for module
    {
        name: "BusinessActivityLine",
        type: null,
        fields: [
            {path: "id", type: "string"},
            "ReferentialBusiness",
            "BusinessActivity",
            "codeBis",
            {path: "days", type: "integer"}
        ]
    },
    {
        name: "BusinessActivitiesByUser",
        type: null,
        fields: [
            {path: "id", type: "string"},
            "monthAndYear",
            {type: "BusinessActivityLine", link: "OTM"}
        ],
        db: {
            find: (context, callback) => {
                const months = [
                    moment().subtract(1, "month"),
                    moment()
                ];

                const objects = months.map(
                    month => {
                        const monthAndYear = month.format("YYYY-MM");

                        return {
                            id: monthAndYear,
                            monthAndYear
                        };
                    }
                );

                callback(null, objects);
            },
            get: (id, context, callback) => {
                const [year, month] = id.split("-");

                async.parallel(
                    {
                        referentialBusineses: callback => async.waterfall([
                            callback => global.app.I.TeamMember.get(
                                {user: global.app.I.User.db.objectToRefId(context.user)},
                                {ignoreNotFound: true, user: context.user, group: context.group, fieldPath: ["countrys.id"]},
                                callback
                            ),
                            (teamMember, callback) => {
                                if(teamMember && teamMember.countrys){
                                    const ids =  teamMember.countrys.map(o => new global.ObjectID(o.id));
                                    global.app.I.ReferentialBusiness.db.find(
                                        {
                                            query: {country: {$in: ids}},
                                            user: context.user,
                                            group: context.group
                                        },
                                        callback
                                    )
                                }
                                else callback()
                            }
                        ], callback),
                        userActivityInBusineses: callback => global.app.I.UserActivityInBusiness.db.find(
                            {
                                user: context.user, group: context.group,
                                query: {
                                    month, year,
                                    user: global.app.I.User.db.objectToRefId(context.user)
                                }
                            },
                            callback
                        )
                    },
                    (e, {referentialBusineses, userActivityInBusineses}) => {
                        if (e) return callback(e);

                        const object = {
                            id,
                            monthAndYear: `${year}-${month}`,
                            businessActivityLines: _.flatMap(
                                referentialBusineses,
                                referentialBusiness => extractActivityLines(referentialBusiness, userActivityInBusineses)
                            )
                        };

                        callback(null, object);
                    }
                )
            }
        },
        save: (newObject, context, callback) => {
            const [year, month] = newObject.id.split("-");

            global.app.I.UserActivityInBusiness.db.find(
                {
                    user: context.user,
                    group: context.group,
                    query: {
                        month,
                        year,
                        user: global.app.I.User.db.objectToRefId(context.user)
                    }
                },
                (e, userActivityInBusineses) => {
                    if (e) return callback(e);

                    const toSave = _(newObject.businessActivityLines)
                        .map(o => {
                            const [activityId, projectId] = o.id.split("-");
                            return Object.assign(o, {activityId, projectId});
                        })
                        .groupBy("projectId")
                        .map(
                            (objects, projectId) => {
                                const previous = userActivityInBusineses.find(
                                    o => o.referentialBusiness.id === projectId
                                );

                                const previousDaysByActivity = _.get(previous, "userDaysByActivitys", []).reduce(
                                    (acc, o) => Object.assign(acc, {[o.businessActivity.id]: o.days}),
                                    {}
                                );

                                const daysByActivity = objects.reduce(
                                    (acc, o) => Object.assign(acc, {[o.activityId]: o.days}),
                                    {}
                                );

                                return {
                                    id: previous && previous.id,
                                    month,
                                    year,
                                    user: {id: context.user.id},
                                    referentialBusiness: {id: projectId},
                                    userDaysByActivitys: businessActivityObjects.map(
                                        o => ({
                                            days: daysByActivity[o.id] || previousDaysByActivity[o.id] || 0,
                                            businessActivity: {id: o.id}
                                        })
                                    )
                                };
                            }
                        )
                        .value();

                    async.each(
                        toSave,
                        (businessActivityLine, callback) => {
                            global.app.I.UserActivityInBusiness.save(
                                {
                                    ...businessActivityLine,
                                    group: context.group
                                },
                                basicContext(context),
                                callback
                            )
                        },
                        e => callback(e, newObject)
                    );
                }
            );
        }
    }
]

export const businessActivitiesByUser = {
    object: "BusinessActivitiesByUser",
    tKey: "timeSheet",
    category: {
        path: "reporting",
        icon: 'layers'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            "monthAndYear"
        ],
        form: [
            {path: "monthAndYear", editable: false},
            {path: "businessActivityLines", tKey: "activityDays", type: "dtObjects", wholePositiveNumbers: true, options: {searchBar: true}, fields: [
                    //{path: "referentialBusiness.country.zone.name", tKey: "zone"},
                    {path: "referentialBusiness.country.name", tKey: "country"},
                    {path: "referentialBusiness.name", tKey: "wording"},
                    {path: "referentialBusiness.code", tKey: "code"},
                    {path: "businessActivity.name", tKey: "activity"},
                    {path: "days", tKey: "nbj", type: "editText", componentClass: "mini-input"}
                ]}
        ]
    }
}
