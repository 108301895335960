import _ from 'lodash'
import {
    INITIALIZE_MODULE,
    TOGGLE_CALENDAR,
    CHANGE_RANGE_CALENDAR,
    SET_FIELD_START_DATE,
    SET_FIELD_END_DATE,
    SET_FIELD_LIST_OPTIONS,
    CHANGE_FIELD_DISABLED,
    SET_FIELD_VISIBILITY,
    SET_FIELD_EDITION,
    SET_FIELD_ACTIVE,
    SET_DATA_LIST,
    CHANGE_FIELD_REQUIRED,
    CHANGE_FIELD_PROPERTY,
    UPDATE_OBJECT,
    CHANGE_FIELD_DEFAULT, SET_FIELD_REMOVABLE, SET_FIELD_NEWABLE, SET_FIELD_WIDTH, PROCESS_DYNAMIC_COLUMNS
} from '../actions'
import {FIELD_LIST_SUCCESS, DT_SUCCESS, GET_OBJECT_SUCCESS} from '../actions/api'

const defaultKeys = ['id', 'path', 'tKey','type', 'required', 'editable', 'sortable', 'creationOnly', 'writable', 'fullWidth', 'hidden', 'autoGrow', 'maxRows', 'parentHeader', 'applyBoard', 'headerClassName', 'headerHeight', 'readOnly', 'options', 'validations']

export const defaultFieldReducer = (state={}, action) => {
    switch (action.type) {
        case SET_FIELD_VISIBILITY:
            const hidden = !action.visible
            return hidden === state.hidden ? state : {
                ...state,
                hidden
            }
        case SET_FIELD_EDITION:
            return {
                ...state,
                editable: action.editable
            }
        case CHANGE_FIELD_DISABLED:
            return {
                ...state,
                disabled: action.disabled
            }
        case SET_FIELD_WIDTH:
            return {
                ...state,
                width: action.width
            }
        case CHANGE_FIELD_DEFAULT:
            return {
                ...state,
                default: action.value
            }
        case CHANGE_FIELD_REQUIRED:
            return {
                ...state,
                required: action.required
            }
        case CHANGE_FIELD_PROPERTY:
            return {
                ...state,
                [action.property]: action.value
            }
        case SET_FIELD_REMOVABLE:
            return {
                ...state,
                removable: action.removable
            }
        case SET_FIELD_NEWABLE:
            return {
                ...state,
                newable: action.newable
            }
        case SET_FIELD_ACTIVE:
            return {
                ...state,
                active: action.active
            }
        default:
            return state
    }
}

export const datePickerField = (state={}, action) => {
    switch (action.type) {
        case SET_FIELD_VISIBILITY:
            const hidden = !action.visible
            return hidden === state.hidden ? state : {
                ...state,
                hidden
            }
        case SET_FIELD_WIDTH:
            return {
                ...state,
                width: action.width
            }
        case SET_FIELD_EDITION:
            return {
                ...state,
                editable: action.editable
            }
        case CHANGE_FIELD_DISABLED:
            return {
                ...state,
                disabled: action.disabled
            }
        case CHANGE_FIELD_DEFAULT:
            return {
                ...state,
                default: action.value
            }
        case CHANGE_FIELD_REQUIRED:
            return {
                ...state,
                required: action.required
            }
        case TOGGLE_CALENDAR:
            return {
                ...state,
                opened: !state.opened
            }
        case SET_FIELD_START_DATE:
            return {
                ...state,
                startDate: action.startDate
            }
        case SET_FIELD_END_DATE:
            return {
                ...state,
                endDate: action.endDate
            }
        default:
            return state
    }
}

export const dateRangeField = (state={}, action) => {
    switch (action.type) {
        case SET_FIELD_VISIBILITY:
            const hidden = !action.visible
            return hidden === state.hidden ? state : {
                ...state,
                hidden
            }
        case SET_FIELD_EDITION:
            return {
                ...state,
                editable: action.editable
            }
        case SET_FIELD_WIDTH:
            return {
                ...state,
                width: action.width
            }
        case CHANGE_FIELD_DISABLED:
            return {
                ...state,
                disabled: action.disabled
            }
        case CHANGE_FIELD_DEFAULT:
            return {
                ...state,
                default: action.value
            }
        case CHANGE_FIELD_REQUIRED:
            return {
                ...state,
                required: action.required
            }
        case CHANGE_RANGE_CALENDAR:
            return {
                ...state,
                [action.index]: action.opened
            }
        default:
            return state
    }
}

const defaultFieldWithOptions = {
    options: []
}

export const fieldWithOptions = (state=defaultFieldWithOptions, action) => {
    switch (action.type) {
        case SET_FIELD_VISIBILITY:
            const hidden = !action.visible
            return hidden === state.hidden ? state : {
                ...state,
                hidden
            }
        case SET_FIELD_EDITION:
            return {
                ...state,
                editable: action.editable
            }
        case SET_FIELD_WIDTH:
            return {
                ...state,
                width: action.width
            }
        case CHANGE_FIELD_REQUIRED:
            return {
                ...state,
                required: action.required
            }
        case FIELD_LIST_SUCCESS:
        case SET_DATA_LIST:
            return state.dataList === action.id ? {
                ...state,
                options: action.data.map(o => o.id)
            } : state
        case SET_FIELD_LIST_OPTIONS:
            return {
                ...state,
                options: action.ids
            }
        case CHANGE_FIELD_DISABLED:
            return {
                ...state,
                disabled: action.disabled
            }
        case CHANGE_FIELD_DEFAULT:
            return {
                ...state,
                default: action.value
            }
        default:
            return state
    }
}

const reducerByType = (type) => {
    switch (type) {
        case 'dropdownObject':
        case 'tags':
        case 'toggle':
        case 'dualBoxList':
            return fieldWithOptions
        case 'datePicker':
        case 'monthPicker':
            return datePickerField
        case 'dateRange':
        case 'monthPickerRange':
            return dateRangeField
        default:
            return defaultFieldReducer
    }
}

const fieldsForNewModule = (state, fields) => {
    const byId = fields.allIds.reduce(
        (acc, id) => {
            const field = fields.byId[id]
            acc[id] = {
                ...reducerByType(field.type)(undefined, {}),
                ...field
            }
            return acc
        },
        {}
    )
    return {
        ...fields,
        initialIds: fields.allIds,
        initialById: byId,
        byId
    }
}

const fieldsForDynamicColumn = (state, data) => {
    const object = data[0]

    if(!object) return {
        ...state,
        allIds: state.initialIds,
        byId: state.initialById
    }

    const allIds = []

    state.initialIds
        .forEach(fieldId => {
            if(!state.initialById[fieldId].dynamic || fieldId[0] !== 'l'){
                allIds.push(fieldId)
            } else {
                const tempoFields = []
                data.forEach(
                    object => {
                        Object.keys(object[fieldId.split('_')[1]]).forEach(
                            key =>{
                                const newFieldId = `${fieldId}.${key}`
                                if(!tempoFields.includes(newFieldId)) {
                                    tempoFields.push(newFieldId)
                                }
                            }
                        )
                    }
                )
                if(state.initialById[fieldId].disableColumnsSort) {
                    tempoFields.forEach(fieldId => allIds.push(fieldId))
                } else {
                    tempoFields.sort().reverse().forEach(fieldId => allIds.push(fieldId))
                }

            }
    })
    const byId = allIds.reduce((acc, id) => {
        const path = id.split('_')[1]
        const label = id.split('.')[1]
        acc[id] = id[0] !== 'l'
            ? state.initialById[id]
            : state.initialById[id] || {
            ...state.initialById[id.split('.')[0]],
            dataKey: path,
            id: id,
            label: label,
            path: path,
            tKey: label,
            dynamic: false
        }
        return acc
    }, state.initialById)


    return {...state, allIds, byId}
}

const fieldsForEditDynamicColumn = (state, data) => {
    const allIds = []
    const byId = {}
    // keep all list and filter fields intact
    state.allIds.filter(id => ['f', 'l'].includes(id[0])).forEach(id => {
        allIds.push(id)
        byId[id] = state.byId[id]
    })
    // compute all edit dynamic fields
    state.initialIds
        .filter(id => id[0] === 'e')
        .forEach(fieldId => {
            if(!state.initialById[fieldId].dynamic) {
                allIds.push(fieldId)
                byId[fieldId] = state.byId[fieldId]
            }
            else {
                const keys = fieldId.split('.')
                const fieldKey = keys[0].split('_')[1]
                const subFieldKey = keys[1].split('_')[1]

                const dynamicFieldData = data[fieldKey] || []
                const allKeys = []

                // going through all dynamic field data to figure out all columns
                dynamicFieldData.forEach(obj => {
                    if(obj[subFieldKey]) {
                        Object.keys(obj[subFieldKey]).forEach(
                            key => allKeys.push(key)
                        )
                    }
                })

                const listFields = []

                _.uniq(allKeys).forEach(
                    key => {
                        allIds.push(`${fieldId}.${key}`)
                        listFields.push(`${fieldId}.${key}`)
                    }
                )

                listFields.forEach((listFieldId) => {
                    const path = listFieldId.split('_')[2]
                    const label = listFieldId.split('.')[2]
                    byId[listFieldId] = {
                        dataKey: path,
                        id: listFieldId,
                        label: label,
                        path: path,
                        tKey: label,
                        style: state.initialById[fieldId].style,
                        type: state.initialById[fieldId].type,
                        parentColumn: state.initialById[fieldId].parentColumn,
                        width: state.initialById[fieldId].width,
                        editable: state.initialById[fieldId].editable,
                        disabled: state.initialById[fieldId].disabled,
                        generated: true
                    }
                })

                const dtId = fieldId.split('.')[0]
                const currentById = byId[dtId]
                const filteredIds = currentById.listFields.filter(id => {
                    return id.split('.')[1] !== fieldId.split('.')[1]
                })
                byId[dtId] = {
                    ..._.pick(byId[dtId], defaultKeys),
                    listFields: _.uniq( [...filteredIds, ...listFields])
                }
            }
        })
    return {...state, allIds, byId}
}

const reduceAllFields = (state, action) => {

    let hasChanged = false

    const result = state.allIds.reduce(
        (acc, id) => {
            const field = state.byId[id]
            const nextField = reducerByType(field.type)(field, action)
            hasChanged = hasChanged || field !== nextField
            acc.byId[id] = nextField
            if(state.initialById[id]) {
                acc.initialById[id] = nextField
            }
            return acc
        },
        {byId: {}, initialById: {}}
    )

    return hasChanged ? { ...state, byId: result.byId, initialById: {...state.initialById, ...result.initialById}} : state
}

const reduceIfIdMatches = (state, action) => {
    const field = action.id && state.byId && state.byId[action.id]

    if (field) {
        const nextField = reducerByType(field.type)(field, action)

        if (nextField !== field) {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: nextField
                },
                initialById: state.initialById[action.id]
                    ? {
                        ...state.initialById,
                        [action.id]: nextField
                    }
                    : state.initialById
            }
        } else {
            return state
        }
    } else {
        return state
    }
}

const defaultState = {
    initialIds: [],
    allIds: [],
    initialById: {},
    byId: {}
}

export const fields = (state=defaultState, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return fieldsForNewModule(state, action.module.fields)
        case DT_SUCCESS:
            return fieldsForDynamicColumn(state, action.data)
        case UPDATE_OBJECT:
        case GET_OBJECT_SUCCESS:
        case PROCESS_DYNAMIC_COLUMNS:
            return fieldsForEditDynamicColumn(state, action.data)
        case FIELD_LIST_SUCCESS:
        case SET_DATA_LIST:
            return reduceAllFields(state, action)
        default:
            return reduceIfIdMatches(state, action)
    }
}

export default fields
