const _ = require('lodash');

export const gestion = {
    name: "gestion",
    tKey: 'mTitle_projectFollowUp',
    object: "BusinessProject",
    category: {
        path: "management",
        icon: 'trendingUp'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", display: "codeNameRelation"},
            {path: "typeOfOffer", initiallyNotVisible: true},
            {path: "fullName", tKey: 'name', width: 800},
            {path: "beginYear", initiallyNotVisible: true},

            {path: "expectedTurnOver", width: 80, tooltip: true},
            {path: "sumActualTurnOver", width: 80, tooltip: true},

            {path: "expectedContractCost", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumCommittedExpenses", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualContractExpenses", width: 80, tooltip: true},

            {path: "expectedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumCommittedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},

            {path: "sumActualBilled", width: 80, tooltip: true},
            {path: "sumActualCashed", width: 80, tooltip: true},

            {path: "commercialTeamMember", initiallyNotVisible: true},
            {path: "technicalTeamMember", initiallyNotVisible: true},
            {path: "revueDate", initiallyNotVisible: true},

            {path: 'risk', display: 'name'},
            {path: "currentFunctions", width: 80, initiallyNotVisible: true},
            {path: "statusAndOrder", width: 100, initiallyNotVisible: true},
            {path: "file", tKey: "FA"}

        ],
        form: [
            "identifier",
            {path: "name", writable: false},
            {path: "beginYear", writable: false},

            {path: "expectedTurnOver", writable: false},
            {path: "expectedContractCost", writable: false},
            {path: "expectedNumberOfDays", writable: false},

            /// Suivi réalisé
            {
                path: 'executedFollowUps', removable: false, viewMap: {
                    dt: [
                        {path: "year"},
                        {path: "name", tKey: "block / Lot / Livrable"},
                        {path: "executedTurnOver", tKey: "CA réalisé (k€)", tooltip: true},
                        {path: "notedExpenses", tKey: "Coût constaté (k€)", tooltip: true},
                        {path: "notedNumberOfDays", tKey: "Nbj constaté", tooltip: true},
                    ],
                    form: [
                        {path: "year", editable: false},
                        {path: "name", tKey: "block / Lot / Livrable", required: true},
                        {path: "executedTurnOver", tKey: "CA réalisé (k€)", wholePositiveNumber: true},
                        {path: "notedExpenses", tKey: "Coût constaté (k€)", wholePositiveNumber: true},
                        {path: "notedNumberOfDays", tKey: "Nbj constaté", wholePositiveNumber: true},
                    ]
                }
            },

            // Suivi facturation
            {
                path: "billingFollowUps", viewMap: {
                    dt: [
                        {path: "date", tKey: "billDate"},
                        {path: "object", tKey: "object"},
                        {path: "amount", tKey: "amountKE"},
                        "invoicedeadline",
                        "paymentdate"
                    ],
                    form: [
                        {path: "date", tKey: "billDate", required: true},
                        {path: "object", tKey: "object", required: true},
                        {path: "amount", tKey: "amountKE", required: true},
                        {path: "invoicedeadline", required: true},
                        "paymentdate"
                    ]
                }
            },

            "comments",

            {path: "status.step", hidden: true},
            {path: "status.order", hidden: true},

            {path: "businessProject", hidden: true}
        ]

    },
    filters: [
        "byZone",
        {
            path: "workflowStep",
            object: "WorkflowStep",
            type: "tags",
            client: true,
            display: "name",
            isDefault: false,
            default: {id: 'realisation'},
            timer: 3,
            query: function(context) {
                const steps = _.get(context, "data.workflowStep")
                return steps
                    ? {'workflow.step': {$in: steps.map(step => step.id)}}
                    : {_id: false}
            }
        },
    ]
}
