const async = require('async')
const _ = require('lodash')
const Errors = require("../../utils/Errors").default
const { basicContext } = require("../../utils/contextUtils");
const { storeData, handleRefundRequests, generateTaxReturnDocument, purgeData, purgeUsers} = require('./utils/exerciseUtils')
const { setFieldVisibility} = require("../../../apps/KpModule/actions")

const defaultTranslation = {
    t: path => path,
    tc: path => _.upperFirst(path)
}

const translation = (context={}) => ({
    t: context.t || defaultTranslation.t,
    tc: context.tc || defaultTranslation.tc,
})

export const entity = {
    name: 'Exercise',
    fields: [
        {path: 'code', unique: true},
        {path: 'startDate', type: 'date'},
        {path: 'endDate', type: 'date'},
        'ExerciseStatus',
        {path: 'fixedDot', type: 'integer'},
        {path: 'variableDot', type: 'integer'},
        {path: 'taxReturnDocument', type: 'boolean'},
        {
            path: 'codeAndStatus',
            fieldPath: ['code', 'exerciseStatus.id'],
            $f: function(exercise, context, callback) {
                callback(null, `${exercise.code} (${translation(context).tc(exercise.exerciseStatus.id)})`)
            }
        },
    ],
    filters: [
        {
            name: 'notProvisionallyClosed',
            isDefault: false,
            query: () => {
                return {exerciseStatus: {$ne: 'provisionalClosure'}}
            }
        },
        {
            name: 'ongoing',
            isDefault: false,
            query: () => {
                return {exerciseStatus: 'ongoing'}
            }
        },
        {
            name: 'closed',
            isDefault: false,
            query: () => {
                return {exerciseStatus: 'finalClosure'}
            }
        },
        {
            name: 'notClosed',
            isDefault: false,
            query: () => {
                return {exerciseStatus: {$ne: 'finalClosure'}}
            }
        }
    ],
    sorters: [{
        name: "byCode",
        query: () => ({code: -1})
    }],
    uniqueActiveExercise: function(newObject, oldObject, context, callback) {
        if(newObject.exerciseStatus.id === 'ongoing') {
            const objectUpdateQuery = oldObject
                ? {_id: {$ne : new global.ObjectID(newObject.id)}}
                : {}
            global.app.R.Exercise.find({
                ...basicContext(context),
                fieldPath: ['exerciseStatus'],
                query: {
                    ...objectUpdateQuery,
                    exerciseStatus: 'ongoing'
                }
            }, (e, exercises) => {
                if(exercises && exercises.length !== 0) {
                    callback(new Errors.ValidationError('UniqueOngoingExercice'))
                }
                else callback()
            })
        } else {
            callback()
        }
    },
    finalClosure: function(newObject, oldObject, context, callback) {
        if(oldObject && oldObject.exerciseStatus.id === 'finalClosure' && newObject.exerciseStatus.id !== 'finalClosure' ) {
            callback(new Errors.ValidationError('exerciseStatusFinalClosure'))
        }else {
            callback()
        }
    },
    cannotCloseExerciseBeforeEndDate: function(newObject, oldObject, context, callback) {
        const todayDate = new Date()
        if(newObject.exerciseStatus.id === 'finalClosure' && newObject.endDate > todayDate ) {
            callback(new Errors.ValidationError('cannotCloseExerciseBeforeEndDate'))
        }else {
            callback()
        }
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.uniqueActiveExercise(newObject, oldObject, context, callback),
            //callback => this.finalClosure(newObject, oldObject, context, callback),
            callback => this.cannotCloseExerciseBeforeEndDate(newObject, oldObject, context, callback),

        ], callback)
    },
    afterSave: function(newObject, oldObject, context, callback) {
        if( _.get(oldObject, 'exerciseStatus.id') !== 'finalClosure' && _.get(newObject, 'exerciseStatus.id') === 'finalClosure' ) {
            async.parallel([
                    callback => {
                        storeData(newObject, context)
                            .then(() => callback())
                            .catch(error => callback(error))
                    },
                    callback => {
                        if(newObject.taxReturnDocument) {
                            generateTaxReturnDocument(newObject, oldObject, context)
                                .then(() => callback())
                                .catch(error => callback(error))
                        } else callback(null)
                    },
                    callback => async.series([
                        callback => {
                            purgeData(newObject, context)
                                .then(() => callback())
                                .catch(error => callback(error))
                        },
                        callback => {
                            purgeUsers(newObject, context)
                                .then(() => callback())
                                .catch(error => callback(error))
                        }
                    ], callback)
                ],err => callback(err)
            )
        } else if( _.get(newObject, 'exerciseStatus.id') === 'ongoing' ) {
            handleRefundRequests(newObject, context)
                .then(() => callback())
                .catch(error => callback(error))
        } else callback()
    }
}

export const module_ = {
    object: 'Exercise',
    tKey: 'mTitle_exercise',
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            'code',
            {path: 'startDate', tKey: 'start'},
            {path: 'endDate', tKey: 'end'},
            {path: 'exerciseStatus',  tKey: 'status', translate: true}
        ],
        form: {
            fields: [
                {path: 'code', required: true},
                {path: 'startDate', tKey: 'start', required: true},
                {path: 'endDate', tKey: 'end', required: true},
                {path: 'fixedDot', wholeNumber: true, required: true},
                {path: 'variableDot', wholeNumber: true, required: true},
                {
                    path: 'exerciseStatus',
                    tKey: 'status',
                    required: true,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if (newValue && newValue.id) {
                                const isFinalClosure = newValue.id === 'finalClosure'
                                store.dispatch(setFieldVisibility(`e_taxReturnDocument`, isFinalClosure))
                                const taxReturnDocumentField = module.viewMap.form.fields.find(
                                    field => field.path === 'taxReturnDocument'
                                )
                                taxReturnDocumentField.setValue(isFinalClosure)
                            }
                        }
                    }
                },
                {path: 'taxReturnDocument', tKey: 'generateCertificates', default: true}
            ],
            onOpen: ({module, store}) => {
                const exerciseStatus = module.viewMap.form.fields.find(
                    field => field.path === 'exerciseStatus'
                )
                const status = exerciseStatus.getValue()

                const visible = status && status.id === 'finalClosure'
                store.dispatch(setFieldVisibility(`e_taxReturnDocument`, visible))
            }
        }
    }
}
