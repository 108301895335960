import React, {Component} from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'
import { scaleOrdinal } from 'd3-scale'
import { schemeTableau10 } from 'd3-scale-chromatic'

const parseDate = d3.timeParse('%Y-%m-%d')
const margin = { top: 20, right: 25, bottom: 60, left: 30 }

const defaultColors = ['#ff6361', '#bc5090', '#ffa600', '#58508d',
    '#52b69a', 'rgb(1, 132, 188)', '#D46F4D',
    '#B8CBD0', '#354E52', '#C8BED1']

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.transformLegend = this.transformLegend.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    transformLegend(d, i) {
        const {w, t, dataKeys , defaultKeys} = this.props
        const keys = dataKeys || defaultKeys

        let line = 0, previousDataLength = -10, j = 0

        while(j < i){

            previousDataLength += (t(keys[j]).length * 5) + 35
            const currentLength = previousDataLength + (t(keys[j+1]).length * 5) + 25
            if(w - margin.right - 10 < currentLength ) {
                previousDataLength = -10
                line++
            }
            j++
        }
        return `translate(${-previousDataLength},${-20 + (15*line)})`
    }

    render() {
        const {h ,w, data = [], dataKeys, colors = defaultColors , defaultKeys = []} = this.props

        const keys = dataKeys || defaultKeys
        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom

        const x = d3.scalePoint()
            .rangeRound([0, width])
            .padding(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const xAxis = d3.axisBottom()
            .scale(x)
            .tickFormat(d3.timeFormat('%d-%m-%Y'))

        const yAxis = d3.axisLeft()
            .scale(y)
            .ticks(6)

        const giveMeEmpty = d3.line()
            .x(function(d) { return x(parseDate(d.date)) })
            .y(height)

        const container = select(".LineChart")
            .select("svg")
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const legendContainer = select(".LineChart")
            .select("svg")
            .select('.legendContainer')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        x.domain([...data.map(d => parseDate(d['date']))])

        y.domain([0, d3.max(data, function(d) { return d3.max(keys, function(key) { return d[key] }) + 5 })]).nice();

        const myColor = scaleOrdinal().domain(keys)
            .range(schemeTableau10)

        keys.forEach((key, index) => {

            const pathClassName = `path${key.replace(/\s/g, '')}`

            const path = container
                .selectAll(`.${pathClassName}`)
                .data([data])

            const valueLine = d3.line()
                .x(function(d) { return x(parseDate(d.date)) })
                .y(function(d) { return y(d[key])})


            path
                .style("stroke", colors[index])
                .style("stroke-width", "2")
                .style("fill", "none")
                // .transition()
                // .duration(1000)
                .attr("d", valueLine)

            path.enter().append('path')
                .attr('class', pathClassName)
                .style("stroke", myColor(key))
                .style("stroke-width", "2")
                .style("fill", "none")
                .attr('d', giveMeEmpty)
                .transition()
                .duration(1000)
                .attr("d", valueLine)
        })

        container.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("y", 0)
            .attr("x", -9)
            .attr("dy", ".35em")
            .attr("transform", "rotate(-55)")
            .style("text-anchor", "end")

        container.select('.axis--y')
            .attr('transform', `translate(0,0)`)
            .call(yAxis)


        const legend = legendContainer
            .selectAll(".legend")
            .data(keys)

        legend
            .attr("transform", this.transformLegend)
        legend
            .select("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return myColor(key);})

        legend
            .select("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return key
            });


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", this.transformLegend)

        enterSelection
            .append("rect")
            .attr("x", width - 25)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(key) {return myColor(key);})

        enterSelection
            .append("text")
            .attr("x", width - 30)
            .attr("y", 8)
            .attr("dy", ".1em")
            .style("text-anchor", "end")
            .style("font-size", "11px")
            .text(function(key) {
                return key
            });

        return (
            <div className="LineChart">
                <svg>
                    <g className="container">
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                    </g>
                    <g className="legendContainer"/>
                </svg>
            </div>
        )
    }
}

export default LineChart;
