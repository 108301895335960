import {returnButton, saveButton, sendButton} from "../utils/demandUtils";
import {
    changeFieldDisabled,
    changeFieldProperty,
    processDynamicColumns, setFieldVisibility,
    setFormButtons
} from "../../../../apps/KpModule/actions";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import moment from "moment/moment";
import _ from "lodash";
import {GET_OBJECT_SUCCESS} from "../../../../apps/KpModule/actions/api";
import {translateName} from "../../../../utils/i18n";
import {addFloats} from "../utils/usefulFunctions";
import {handleParentColumnsForTable} from "../utils/trackingUtils";

export const module_ = {
    name: "pilotageCommittee",
    object: 'Demand',
    category: {
        path: 'tracking',
    },
    tKey: 'comité pilotage',
    newable: false,
    viewMap: {
        dt: [
            {path: 'demandNumber', tKey: 'N° Demande'},
            {path: 'title'},
            {path: 'organizationAndMesh'},
            //{path: 'demandCategory', tKey: 'processType'},
            {path: 'demandNature', tKey: 'category', translateName: true},
            {path: 'pilotageCommitteeLastUpdate',},
            {path: 'noDeleteButtonAccess', hidden: true}
        ],
        form: {
            fields: [
                {path: 'demandNumber', tKey: 'N° Demande',disabled: true},
                {path: 'title', disabled: true},
                {path: 'organizationAndMesh', tKey: 'organisation',disabled: true},
                {path: 'demandCategory', tKey: 'processType',disabled: true, hidden: true},
                {path: 'demandNature', tKey: 'category',disabled: true, translateName: true},
                {
                    path: 'meetingsPlanning',
                    display: 'code',
                    disabled: true,
                    required: true,
                    fieldPath:['id', 'code', 'revisedReportingPeriod', 'meetingsPlanningRange'],
                },
                {path: 'revueDateP', disabled: true},
                {
                    path: 'revisedTable',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        //{path: 'dynamicAmounts', hidden: true},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ]
                },
                {
                    path: 'revisedTableHistory',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ],
                },
                {path: 'synthesisP', type: 'richTextEditor'},
                {
                    path: 'newsP',
                    type: 'richTextEditor'
                },
                {path: 'deadlineP', type: 'richTextEditor'},
                {
                    path: 'costP',
                    type: 'richTextEditor'
                },
                {path: 'treasuryP', type: 'richTextEditor'},
                {path: 'committeeMinutesP', type: 'richTextEditor'},
                {
                    path : 'sendToP',
                    tKey: 'destinataires',
                    filters: ['isInGroupModel'],
                },
                {
                    path: 'sendToOthersP',
                    tKey: 'autres destinataires',
                    //type: 'textarea',
                    placeholder: "taper une adresse mail et appuyer sur entrer",
                    type: 'creatableTags'
                },
                {path: 'displayEngagementData', hidden: true},
                {path: 'currentFiscalYear', hidden: true},
                {path: 'pilotageCommitteeFiles', removable: false}
            ],
            onOpen: ({ module, state, store, t }) => {
                store.dispatch(setFormButtons([saveButton, sendButton, returnButton]))
                const meetingsPlanningField = module.viewMap.form.fields.find(field => field.path === 'meetingsPlanning')
                const meetingsPlannings = getDataListList(state, "m-SE-pilotageCommittee.Demand_meetingsPlanning")
                const ongoingMeetingPlanning = meetingsPlannings.find(obj => moment().isBetween(obj.meetingsPlanningRange[0], obj.meetingsPlanningRange[1], 'day', '[]'))
                const meetingPlanningToConsider = ongoingMeetingPlanning
                const revueDateField = module.viewMap.form.fields.find(field => field.path === 'revueDateP')
                revueDateField.setValue(moment().format('YYYY-MM-DD'))
                meetingsPlanningField.setValue(meetingPlanningToConsider)
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()
                store.dispatch(setFieldVisibility(revisedTableHistoryField.id, !!revisedTableHistory && !!revisedTableHistory.length))

                const displayEngagementDataField =  module.viewMap.form.fields.find(field => field.path === 'displayEngagementData')
                const displayEngagementData =  !!displayEngagementDataField && displayEngagementDataField.getValue()
                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const validatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')
                const validatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')

                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementHistoryColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementHistoryColumn.id, displayEngagementData))

                const referenceCurrencies = getDataListList(state, "PilotageCommitte-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_revisedTable", 'tKey', `${t("revisedTable")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_revisedTableHistory", 'tKey', `${t("revisedTableHistory")} (k${referenceCurrency.symbol})`))
                }

            },
            dataLists: [
                "PilotageCommitte-RealizationType",
                "PilotageCommitte-FiscalYear",
                "PilotageCommitte-Currency",
            ],
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const language = _.get(state, 'ui.language')

                const realizationTypes = getDataListList(state, "PilotageCommitte-RealizationType")
                const sortedTypes = _.sortBy(realizationTypes, ['order']);

                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTable = !!revisedTableField && revisedTableField.getValue()
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()

                const filteredRevisedTable = _.cloneDeep(revisedTable).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const filteredRevisedTableHistory = _.cloneDeep(revisedTableHistory).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const result = []
                const result2 = []
                if (!!filteredRevisedTable && !!filteredRevisedTable.length){

                    filteredRevisedTable.forEach(line => {
                        result.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')

                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })

                    if (filteredRevisedTable.length > 1){
                        const dynamicAmounts = result.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})
                        const validatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearBudget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearValidatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearEstimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            budget,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }

                    result.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableField.setValue(result)
                }
                if (!!filteredRevisedTableHistory && !!filteredRevisedTableHistory.length){
                    filteredRevisedTableHistory.forEach(line => {
                        result2.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })
                    if (filteredRevisedTableHistory.length > 1){
                        const dynamicAmounts = result2.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})

                        const validatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result2.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            budget,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }
                    result2.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableHistoryField.setValue(result2)
                }
                store.dispatch(processDynamicColumns({revisedTable: result, revisedTableHistory: result2}))
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const fiscalYearsList = getDataListList(state, 'PilotageCommitte-FiscalYear')
                const ongoingFiscalYear = fiscalYearsList.find( fiscalYear => moment().isBetween(fiscalYear.fiscalYearRange[0], fiscalYear.fiscalYearRange[1], 'day', '[]'))
                const tableIds = ["e_revisedTable", "e_revisedTableHistory"]
                tableIds.forEach(tableId => handleParentColumnsForTable(store, tableId, ongoingFiscalYear))
            }
        }
    ],
    accesses: [
        {
            id: "PilotageCommitte-RealizationType",
            entity: "RealizationType",
            fieldPath: ['id', 'code', 'name', 'order'],
            filters: ['onlyActifOnes']
        },
        {
            id: "PilotageCommitte-FiscalYear",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: []
        },
        {
            id: "PilotageCommitte-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        },
    ],
    filters: ['inPilotageCommittee']
}
