const _ = require('lodash');
const moment = require('moment')
const { directoryPath } = require('../templates/index')

export const businessPlanPdfConfig = function(
    businessProject,
    refBusiness,
    outputName,
    context
) {
    const status = businessProject.workflow
        ? context.tc(businessProject.workflow.step)
        : context.tc('formX')

    const beginYear = _.get(businessProject, 'beginYear.id')

    const bpCode = refBusiness.code ? refBusiness.code : " ";
    const bpName = refBusiness.name ? refBusiness.name : " ";

    const bpCountryNameAndSecurity = (businessProject.country && businessProject.country.nameAndSecurity)  ? businessProject.country.nameAndSecurity : " ";
    const creationDate = moment(businessProject.creationDate).format("DD/MM/YYYY")
    const today =  moment().format("DD/MM/YYYY")

    const header = "<header style=\"font-size: 8px; margin-left: 10px; font-family: Verdana, Arial, sans-serif\"><p>" +
        "<strong>" + bpCountryNameAndSecurity + "</strong><br>" +
        bpName + " / " + bpCode + "<br>" +
        "<small> Créée le " + creationDate + "   -   Statut au " + today + "  :  " + status + ".</small><br>" +
        "</p></header>"


    const footer = "<footer style=\"font-size: 8px; margin-left: 10px; font-family: Verdana, Arial, sans-serif\"><div class=\"row\">" +
        "<div class=\"col-md-8\" style=\"float: left\"><small>EDF IN - Fiche affaire</small></div>" +
        "</div></footer>"

    const bpDates = {
        offerSubmissionDate: businessProject.offerSubmissionDate ? moment(businessProject.offerSubmissionDate).format("DD/MM/YYYY") : "",
        effectiveOfferSubmissionDate: businessProject.effectiveOfferSubmissionDate ? moment(businessProject.effectiveOfferSubmissionDate).format("DD/MM/YYYY") : "",
        estimatedResponseDate: businessProject.estimatedResponseDate ? moment(businessProject.estimatedResponseDate).format("DD/MM/YYYY") : "",
        offerAcceptedDate: businessProject.offerAcceptedDate ? moment(businessProject.offerAcceptedDate).format("DD/MM/YYYY") : "",
        signatureDate: businessProject.signatureDate ? moment(businessProject.signatureDate).format("DD/MM/YYYY") : ""
    }
    return {
        content: [
            { path: 'BusinessPlanTemplate.html' }
        ],
        context: {
            bpDates,
            bpObject: businessProject,
            comments: _.orderBy(businessProject.comments, 'date', 'desc'),
            years: _.range(beginYear, beginYear +10)

        },
        configuration: {
            landscape: true,
            header: {
                height: '2cm',
                html: header.replace(new RegExp("'", 'g'), "")
            },
            footer: {
                height: '1cm',
                html: footer.replace(new RegExp("'", 'g'), "")
            },
            pdfName: outputName.replace(new RegExp("'", 'g'), ""),
            outputInDB: true,
            cssSheet: '',
            templateDir: directoryPath.pdfs
        }
    }
}
