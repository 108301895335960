const _ = require('lodash')
const moment = require('moment')
const { basicContext } = require('../../../utils/contextUtils')

const configureWorkbook = (headers, csvData, context) => workbook => {
    workbook.creator = 'Keenpoint'
    workbook.lastModifiedBy = 'Keenpoint'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('dpei_data', {
        defaultColWidth: 300
    })

    let row = 1
    let col = 1

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }

    const header = dataSheet.getRow(row)
    headers.forEach(column => {
        header.getCell(col).value = column
        header.getCell(col).fill = darkGrayBG
        header.getCell(col).font = {
            size: 12,
            bold: true
        }
        header.getCell(col).border = {
            bottom: { style: 'thin' }
        }
        col++
    })
    header.getCell(col).border = { left: { style: 'thin' } }

    row = 2

    csvData.forEach(line => {
        col = 1
        headers.forEach(path => {
            dataSheet.getRow(row).getCell(col).value = line[path]
            dataSheet.getRow(row).getCell(col).font = {
                size: 12
            }
            col++
        })
        dataSheet.getRow(row).getCell(col).border = {
            left: { style: 'thin' }
        }
        row++
    })
}

export const generateXlsx = (query, newObject, context, callback) => {
    global.app.B.Data.find(
        {
            ...basicContext(context),
            query,
            fieldPath: [
                'mesh.code',
                'shop.code',
                'subsidiary.code',
                'country.code',
                'bp.code',
                'type.code',
                'source.code',
                'deadline',
                'exercise',
                'year'
            ]
        },
        (error, dataList) => {
            const paths = Object.values(headersCorrespondences).map(o => o.path)
            const { objects, dataHeaders } = groupBy(dataList, headersCorrespondences)
            const fields = Object.keys(headersCorrespondences)
            const data = objects.map(object =>
                fields.reduce(
                    (acc, field) => ({
                        ...acc,
                        [field]: headersCorrespondences[field].getter(object)
                    }),
                    { ..._.omit(object, paths) }
                )
            )
            const fileName = `${moment().format(
                'YYYY-MM-DD_HH-mm-ss'
            )}_${newObject.importExportType.id}_${newObject.meshDataType.name}`

            global.excel.generateExcel(
                configureWorkbook([...fields, ...dataHeaders], data, context),
                fileName,
                callback
            )

            //const csvData = parse(data, opts)
            // global.csv.generateCsv(fileName, csvData, callback)
        }
    )
}

const groupBy = (array, headersCorrespondences) => {
    let dataHeaders = []
    const elements = Object.values(headersCorrespondences)
    const elementsPaths = elements.map(o => o.path)
    const groups = array.reduce(function(acc, o) {
        if (!dataHeaders.includes(o.name)) dataHeaders.push(o.name)
        const group = _.join(elements.map(e => e.getter(o)), '-')
        return acc[group]
            ? { ...acc, [group]: { ...acc[group], [o.name]: o.value } }
            : { ...acc, [group]: { ..._.pick(o, elementsPaths), [o.name]: o.value } }
    }, {})
    return { objects: Object.values(groups), dataHeaders }
}

const headersCorrespondences = {
    MAILLE: {
        path: 'mesh',
        getter: object => `${_.get(object, 'mesh.code', '')}`
    },
    BP: {
        path: 'bp',
        getter: object => `${_.get(object, 'bp.code', '')}`
    },
    MAGASIN: {
        path: 'shop',
        getter: object => `${_.get(object, 'shop.code', '')}`
    },
    FILIALE: {
        path: 'subsidiary',
        getter: object => `${_.get(object, 'subsidiary.code', '')}`
    },
    PAYS: {
        path: 'country',
        getter: object => `${_.get(object, 'country.code', '')}`
    },
    ANNEE: {
        path: 'year',
        getter: object => `${_.get(object, 'year')}`
    },
    TYPE: {
        path: 'type',
        getter: object => `${_.get(object, 'type.code', '')}`
    },
    EXERCICE: {
        path: 'exercise',
        getter: object =>
            `${_.get(object, 'exercise') !== null
                ? _.get(object, 'exercise')
                : ''}`
    },
    ECHEANCE: {
        path: 'deadline',
        getter: object => `${_.get(object, 'deadline')}`
    },
    SOURCE: {
        path: 'source',
        getter: object => `${_.get(object, 'source.code', '')}`
    }
}
