import React from 'react'
import PropTypes from 'prop-types'
import './RadioButtonField.css'
import HTMLRenderer from 'react-html-renderer'
import {translateName, translateName2} from "../../utils/i18n";

const RadioButtonField = props => (
    <div>
        <div className="RadioButtonField-question-label">{props.label} {props.required && '*'}</div>
        {props.subtitle && <div className="RadioButtonField-question-subtitle">{props.subtitle}</div>}
        <div className="RadioButtonField-wrapper">
            {props.options.map(option => (
                <div className="RadioButton" key={option.id}>
                    <input
                        type="radio"
                        className="RadioButton-input"
                        value={option.id}
                        checked={ props.valueId === option.id}
                        onChange={() => undefined}
                        disabled={props.disabled}
                    />
                    <span
                        className="cr"
                        onClick={() => {
                            if(!props.disabled) {
                                props.onChange(option.id)
                            }
                        }}
                    >
                            <i className="cr-icon glyphicon glyphicon-asterisk" onClick={event => {
                                if(!props.onChange) {
                                    event.stopPropagation()
                                }
                            }} />
                    </span>
                    <label className="RadioButton-label">
                        <HTMLRenderer
                            html={translateName2(option.name, props.language)}
                        />
                    </label>
                </div>
            ))}
        </div>
        <div className="RadioButtonField-textarea-container">
            {
                props.hasDetailedResponse && <textarea
                className="RadioButtonField-textarea"
                    value={props.detailedResponse}
                    disabled={props.disabled}
                    placeholder={props.detailedResponsePlaceholder}
                    onChange={props.onResponseDetailsChange}
                    rows={3}
                />
            }
        </div>
    </div>
)

RadioButtonField.propTypes = {
    options: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default RadioButtonField
