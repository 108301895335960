export const entity = {
    name: 'Category',
    facets: ['codeName']
}

export const module_ = {
    object: 'Category',
    tKey: 'mTitle_category',
    category: {
        path: 'referential',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'}

        ],
        form: [
            {path: 'code'},
            {path: 'name'}
        ]
    }
}
