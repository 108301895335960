export const entities = [
    {
        name: 'HermesZone',
        fields: ['code', 'name']
    },
    {
        name: 'HermesCountry',
        fields: ['code', 'name', { object: 'HermesZone', path: 'zone' }]
    },
    {
        name: 'HermesSubsidiary',
        fields: [
            'code',
            'name',
            { object: 'HermesCountry', path: 'country' }
        ]
    },
    {
        name: 'HermesShop',
        fields: [
            'code',
            'name',
            'openingDate',
            { object: 'HermesSubsidiary', path: 'subsidiary' }
        ]
    },
    {
        name: 'HermesBusinessPlan',
        fields: ['code', 'name', { object: 'HermesShop', path: 'shop' }]
    },
    {
        name: 'HermesData',
        fields: [
            'mysqlId',
            'country',
            'subsidiary',
            'shop',
            'bp',
            'year',
            'name',
            'value',
            'type',
            'mesh',
            'exercise',
            'deadline',
            'source'
        ],
        indexes: [
            { key: { mesh: 1 } },
            { key: { mesh: 1, shop: 1 } },
            { key: { mesh: 1, subsidiary: 1 } },
            { key: { mesh: 1, country: 1 } },
            { key: { mesh: 1, bp: 1 } }
        ]
    }
]
