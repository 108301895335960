const { workflowParameters } = require("../workflow/workflow");
const _ = require("lodash")

const toPercentage = n => Number.isNaN(n) ? "-" : `${Math.round(100*n)}%`;

const inProgressOrValidatedSteps = workflowParameters.steps.filter(
    step => _.includes(["inProgress", "validated"], step.type)
);

const groupBpsByAxis = (bps, axisId) => _.groupBy(bps, axisId
    ? global.app.I.ProjectGroupAxis.byId(axisId).groupper
    : ''
);


const computeProjectEfficiency = ({axis1, axis2, startYear, endYear}, context, callback) => global.app.I.BusinessProject.find(
    {
        fieldPath: [axis1, axis2].filter(axis => axis).reduce(
            (fieldPath, axisId) => [...fieldPath, ...global.app.I.ProjectGroupAxis.byId(axisId).fieldPath],
            ["workflow.step"]
        ),
        filters: ["inYearPeriod", "inSteps"],
        data: {startYear, endYear, filterSteps: _.map(inProgressOrValidatedSteps, "name")},
        group: context.group,
        user: context.user
    },
    (e, bps) => {
        if (e) return callback(e);

        const enhancedBps = bps.map(
            bp => Object.assign(bp, {
                number: workflowParameters.steps.find(
                    step => step.name === bp.workflow.step
                ).previousSteps.reduce(
                    (acc, step) => Object.assign(
                        step === 'submitOffer' && bp.responseMode === 'ami'
                            ? {[step]: 1, 'submitOfferAmi': 1}
                            : {[step]: 1}, acc
                    ),
                    bp.workflow.step === 'submitOffer' && bp.responseMode === 'ami'
                        ? {[bp.workflow.step]: 1, 'submitOfferAmi': 1}
                        : {[bp.workflow.step]: 1}
                )
            })
        );

        const sumYearValues = yearValuesArray => yearValuesArray.reduce(
            (sumYearValues, yearValues) => [..._.map(inProgressOrValidatedSteps, "name"), 'submitOfferAmi'].reduce(
                (acc, step) => Object.assign({
                    [step]: (sumYearValues[step] || 0) + (yearValues[step] || 0)
                }, acc),
                {}
            ),
            {}
        );

        const efficiencyResults = _.flatMap(groupBpsByAxis(enhancedBps, axis1), (groupBps, element1) =>
            _.flatMap(groupBpsByAxis(groupBps, axis2), (groupBps, element2) => {
                const reducedAmount = sumYearValues(_.map(groupBps, "number"));

                const stepEfficiencyRatio = inProgressOrValidatedSteps.reduce(
                    (acc, step, i, steps) => {
                        const stepName = step.name
                        const previousStep = step.previousSteps.slice().reverse()[0]
                        return Object.assign({
                            [stepName]: i
                                ? stepName === 'realisation'
                                    ? reducedAmount[stepName] / (reducedAmount[previousStep] - reducedAmount['submitOfferAmi'])
                                    : stepName === 'acceptedAmi'
                                        ? reducedAmount[stepName] / reducedAmount['submitOfferAmi']
                                        : reducedAmount[stepName] / reducedAmount[previousStep]
                                : NaN
                        }, acc)
                    }, {}
                );

                return [
                    Object.assign(
                        {
                            id: new global.ObjectID().toString(),
                            element1,
                            element2,
                            value: "Projet (Nb)"
                        },
                        reducedAmount
                    ),
                    Object.assign(
                        {
                            id: new global.ObjectID().toString(),
                            element1,
                            element2,
                            value: "% Passage"
                        },
                        _.mapValues(stepEfficiencyRatio, toPercentage)
                    )
                ];
            })
        );

        callback(null, efficiencyResults);
    }
);

export const entities = [
    {
        name: "ProjectEfficiency",
        fields: [
            "element1",
            "element2",
            "value",
            ..._.map(inProgressOrValidatedSteps, "name")
        ],
        db: {
            find: (context, callback) => {
                const axis1 = _.get(context, "data.axis1.id");
                const axis2 = _.get(context, "data.axis2.id");
                const startYear = Number(_.get(context, "data.startYear.id"));
                const endYear = Number(_.get(context, "data.endYear.id"));

                computeProjectEfficiency({axis1, axis2, startYear, endYear}, context, callback);
            }
        }
    }
]

export const projectEfficiency = {
    object: "ProjectEfficiency",
    tKey: "process",
    category: {
        path: "analysis",
        icon: 'sliders'
    },
    removable: false,
    viewMap: {
        dt: [
            {path: "element1", width: 500, translate: true},
            {path: "element2", width: 500, translate: true},
            {path:"value", tKey:"indicator", width: 150},
            ...inProgressOrValidatedSteps.map(
                step => ({path: step.name, tKey: step.title, width: 80})
            )
        ]
    },
    filters: [
        {
            object: "ProjectGroupAxis",
            title: "Axe principal",
            placeholder: "Séléctionner l'axe principal",
            path: "axis1",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "ProjectGroupAxis",
            title: "Axe secondaire",
            placeholder: "Séléctionner l'axe secondaire",
            path: "axis2",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "AnalysisYear",
            path: "startYear",
            title: "Début analyse",
            placeholder: 'Année début',
            client: true,
            display: "name",
            clearable: false
        },
        {
            object: "AnalysisYear",
            path: "endYear",
            title: "Année fin",
            placeholder: 'Année fin',
            client: true,
            display: "name",
            clearable: false
        }
    ]
}
