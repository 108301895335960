const { byCompany, byClient } = require('./filters')

export const dependencies = [
    {
        name: 'ClientDepositHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith: ['deposit', 'client'], index: true},
            'Deposit',
            {path: 'affiliatedCompany', type: 'Company'},
            {type: 'Company', index: true},
            {path: 'perimeter', type: 'boolean'},
            {path: 'state', type: 'boolean'},
            'type',
            'HistoryDataType'
        ],
        filters:[byClient]
    }
]
export const entity = {
    name: "Client",
    facets: [
        {name:"codeName"}
    ],
    fields: [
        'Company',
        {
            type: 'ClientDepositHistory',
            path: 'depositAndCompany',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        }
    ],
    filters:[
        {
            ...byCompany,
            autoFetch: true
        },
    ]
}
export const modules = [
    {
        object: "ClientDepositHistory",
        tKey: "mTitle_client",
        newable: false,
        removable: false,
        category: {
            path: "referential",
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'company',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                {path: 'client.code', tKey: 'code', width: 100},
                {path: 'client.name', tKey: 'name'},
                {path: 'date', width: 100},
                {
                    path: 'deposit',
                    tKey: 'affiliatedDeposit',
                    display: 'fullName'
                },
                {path: 'affiliatedCompany', display: 'fullName'},
                'perimeter',
                'state',
                'type',
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:['byClient']
    }
]
