import moment from "moment";
import async from "async";

export const entity = {
    name: "DelayUpdate",
    fields: [
        {path: "code"},
        {path: "delay", type: "integer"},
        {path: "date", type: "date", nullable: true},
        {path: 'user', type: 'User', nullable: true}
    ],
    beforeSave: (newObject, oldObject, context, callback) => {
        newObject.date = moment().format('YYYY-MM-DD HH:mm:ss')
        newObject.user = context.user
        async.parallel([
            callback => global.app.S.AlertConfiguration.collection.updateMany({}, {$set: {delay: newObject.delay}}, callback),
            callback => global.app.S.ScoringConfig.collection.updateMany({}, {$set: {delay: newObject.delay}}, callback)
        ], (e) => {
            callback(e, newObject, oldObject)
        })
    }

}

export const module_ = {
    object: "DelayUpdate",
    tKey: "mTitle_delayUpdate",
    updatable: false,
    category: {
        path: "setting",
        icon: "settings"
    },
    defaultSortBy: "date",
    defaultSortDirection: "DESC",
    viewMap: {
        dt: [
            {path: "date"},
            {path: "user"},
            {path: "code"},
            {path: "delay"},
        ],
        form: [
            {path: "code"},
            {path: "delay"}
        ]
    }
}
