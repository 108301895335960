import moment from 'moment'
import _ from 'lodash'
import { getStoreHabilitationQuery } from '../../utils/habilitations'

async function findData(context) {

    const habQuery = getStoreHabilitationQuery(context)
    const [habilitations] = await Promise.all([habQuery])

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    if (habilitations.length) {
        const datesQuery = {
            'date.gte': { $gte: dates[0].toDate() },
            'date.lt': { $lte: dates[1].toDate() }
        }

        const habilitation = habilitations[0]
        const store = habilitation.store
        const organization = habilitation.organization

        const alertConfigurationCollection =
            global.app.S.AlertConfiguration.collection
        const alertConfigurationQuery = alertConfigurationCollection
            .find({
                code: 'TauxRattachement7Jours'
            })
            .toArray()

        const [alertConfigurations] = await Promise.all([
            alertConfigurationQuery
        ])
        const alertConfiguration = alertConfigurations[0]

        const alertConfQuery = {
            alertConfiguration: alertConfiguration._id
        }

        const alertCollection = global.app.S.Alert.collection
        const storeAlertQuery = alertCollection
            .find({
                ...datesQuery,
                ...alertConfQuery,
                store: store._id,
                'theme-NbClient': { $exists: true },
                'theme-NbTicket': { $exists: true }
            })
            .toArray()

        const regionAlertQuery = alertCollection
            .aggregate([
                {
                    $match: {
                        ...datesQuery, ...alertConfQuery,
                        organizations: organization._id,
                        'theme-NbClient': {$exists: true},
                        'theme-NbTicket': {$exists: true}
                    }
                },
                {
                    $group: {
                        _id: 'attachmentRateRegion',
                        nbClient: {$sum: '$theme-NbClient.Nb'},
                        nbTicket: {$sum: '$theme-NbTicket.Nb'}
                    }
                }
            ])
            .toArray()

        const [storeAlerts, regionAlerts] = await Promise.all([
            storeAlertQuery,
            regionAlertQuery
        ])

        const attachmentRateStore = storeAlerts[0] && _.round(
            (storeAlerts[0]['theme-NbClient'].Nb / storeAlerts[0]['theme-NbTicket'].Nb)*100,
            1
        )

        const attachmentRateOrganizationAlert = regionAlerts[0]
        const attachmentRateRegion = attachmentRateOrganizationAlert && _.round(
            (attachmentRateOrganizationAlert.nbClient /
            attachmentRateOrganizationAlert.nbTicket)*100,
            1
        )

        return [
            {
                id: 'attachmentRate',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: [
                    {
                        'indicateur': 'Taux de rattachement %',
                        'Mag.': attachmentRateStore,
                        'Moy. Reg.': attachmentRateRegion
                    }
                ]
            }
        ]
    } else {
        return [
            {
                id: 'attachmentRate',
                subTitle: {
                    text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                    font: '11px',
                    color: '#888383'
                },
                data: []
            }
        ]
    }
}

export const entity = {
    name: 'AttachmentRate',
    dashboard: true,
    fields: [
        "id", "subTitle", "data"
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })

    }
}
