const path = require("path");
const _ = require("lodash");
const {decrypt} = require("../../../utils/crypto");

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: "assistance@mycsemanco.com",
    verbose: {
        general: true,
    },
    service: "mailjet"
}

export const sendReleaseMail = function (user, date, context, done) {
    const language = _.get(user, 'language.id') || 'fr'
    const link = global.isProd
        ? 'https://mycsemanco.com'
        : 'http://localhost:3000/business/5efc87106bba6116d6e3b06a'

    const mail = _.defaults({
        to: user.mail && decrypt(user.mail),
        context: {
            firstname : user.firstname,
            lastname : user.lastname,
            date,
            civility_abbrev : context.tl(user.civility.id, language),
            civility: context.tl(user.civility.name, language),
            link : link
        },
        subject: {template: `myCSEManCo : ${context.tl(`${user.status.id}ReleaseNotification`, language)}`},
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/fncs/mails/templates/'
                : 'src/server/models/fncs/mails/templates',
            language
        ),
        content: `${user.status.id}UserRelease.html`,
    }, defaultMail);
    global.mailer.sendMail(mail, error => done(error, 'emailSent'))
}
