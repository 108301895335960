const { translateName } = require("../../../utils/i18n");
const _ = require('lodash');

const translate = (object, context) => {
    switch (object.joinedEntity) {
        case "OrganizationAxis":
            return translateName(object.name, _.get(context, "language.id"));
        default:
            return context.tc ? context.tc(object.tKey) : object.tKey;
    }
};

export const entity = require("./../../utils/entityJoin").joinEntities(
    {
        name: "OrganizationAxisJoin",
        fields: [
            {
                path: "tName",
                fieldPath: ["name", "tKey"],
                $f: (object, context, callback) => {
                    callback(null, translate(object, context));
                }
            }
        ],
        filters: [
            {
                name: "usedInScope",
                isDefault: false,
                match: function (object) {
                    return object.joinedEntity !== 'OrganizationAxis' || object.useInScope
                }
            }
        ]
    },
    ["OrganizationAxis", "CentralAxis", "StoreAxis"]
)
