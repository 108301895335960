export const entities = [
    {
        name: "ContentType",
        type: "static",
        fields: [{path: "id", type: "string"}, {path: "name", type: "string"}],
        objects: [
            {id: "html", name: "html"},
            {id: "template", name: "template"}
        ]
    },
    {
        name: "EmailsQueue",
        facets: [{path: 'attachments', name: 'files'}],
        fields: [
            "messageId",
            "from",
            "to",
            "replyTo",
            "subject",
            "ContentType",
            "content",
            "templateDir",
            {path: "signature", type: "boolean"},
            {path: "signatureFiles", list: true},
            "status",
            {path: "creationDate", type: "date"},
            {path: "sendingDate", type: "date"}
        ]
    }
]

export const modules = []
