import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_CONFIRMATION_SUCCESS,
    LOGIN_CONFIRMATION_FAILURE,
    LOGIN_CONFIRMATION_RESET_SUCCESS,
    LOGIN_CONFIRMATION_RESET_FAILURE,
    SEND_MAIL_SUCCESS, SEND_MAIL_FAILURE, REGISTRATION_SUCCESS, REGISTRATION_FAILURE
} from '../apps/MainPage/actions'
import { CREATE_OBJECT_SUCCESS, CREATE_OBJECT_FAILURE, SAVE_OBJECT_SUCCESS, SAVE_OBJECT_FAILURE, FAKE_CREATE_OBJECT_SUCCESS, FAKE_SAVE_OBJECT_SUCCESS, GET_OBJECT_FAILURE} from '../apps/KpModule/actions/api'
import {htmlDecode} from "../utils/utils";

function parseJson(json) {
    let returnValue = {message: "undefined message"}
    if(!json) return returnValue
    try {
        returnValue = JSON.parse(json)
    } catch {
        return returnValue
    }
    return returnValue
}

export default () => next => action => {
    if(action.type) {
        const t = _.get(action, 'meta.t') || function(c){ return c}
        const errorObject = parseJson(action.error)

        switch (action.type) {
            case LOGIN_SUCCESS:
            case REGISTRATION_SUCCESS:
            case SEND_MAIL_SUCCESS:
            case LOGIN_CONFIRMATION_SUCCESS:
                if(action.data.message) {
                    toastr[action.data.type](
                        t('login'),
                        t(action.data.message, action.data.data)
                    )
                }
                break;
            case LOGIN_FAILURE:
            case REGISTRATION_FAILURE:
            case SEND_MAIL_FAILURE:
            case LOGIN_CONFIRMATION_FAILURE:

                toastr.error(
                    t('login'),
                    t(errorObject.message)
                )
                break;
            case LOGIN_CONFIRMATION_RESET_SUCCESS:
                if(action.data.message) {
                    toastr[action.data.type](
                        t('login'),
                        t(action.data.message, action.data.data)
                    )
                }
                break;
            case LOGIN_CONFIRMATION_RESET_FAILURE:
                toastr.error(
                    t('login'),
                    t(errorObject.message)
                )
                break;
            case CREATE_OBJECT_SUCCESS:
                toastr.success(t('creation'), t('creationSuccess'))
                break;
            case CREATE_OBJECT_FAILURE:
                toastr.error(t('creation'), t(htmlDecode(action.error)))
                break;
            case SAVE_OBJECT_SUCCESS:
                toastr.success(t('modification'), t('saveSuccess'))
                break;
            case SAVE_OBJECT_FAILURE:
                toastr.error(t('modification'), t(htmlDecode(action.error)))
                break;
            case FAKE_CREATE_OBJECT_SUCCESS:
                toastr.info(t('creation'), t('processingInProgress'))
                break;
            case FAKE_SAVE_OBJECT_SUCCESS:
                toastr.info(t('modification'), t('processingInProgress'))
                break;
            case GET_OBJECT_FAILURE:
                toastr.error(t('reading'), t(htmlDecode(action.error)))
                break;
        }
    }
    next(action)
}
