const _ = require('lodash')
const { fieldPathJoinGetter } = require('../../utils/kp3Utils')

export const entity = {
    name: 'ProductAxis',
    facets: [
        { name: 'codeName', codeUniqueWith: 'productAxisType' },
        'translatedField'
    ],
    fields: [
        'ProductAxisType',
        {
            path: 'completeName',
            fieldPath: ['productAxisType.tName', 'tName'],
            f: function() {
                return (
                    (this.productAxisType ? `${this.productAxisType.tName}: ` : '') +
                    this.tName
                )
            }
        },
        fieldPathJoinGetter({
            path: 'tFullName',
            fieldPath: ['code', 'tName']
        })
    ]
}

export const module_ = {
    object: 'ProductAxis',
    tKey: 'mTitle_productAxis',
    category: {
        path: 'product',
        icon: 'server'
    },
    viewMap: {
        dt: ['code', { path: 'name', type: 'translatedText' }],
        form: [
            'code',
            { path: 'name', type: 'textarea' },
            {
                path: 'productAxisType',
                display: 'tFullName'
            }
        ]
    },
    filters: [
        {
            path: 'productAxisType',
            object: 'ProductAxisType',
            display: 'tFullName',
            client: true,
            query: function(context) {
                const productAxisTypeId = _.get(context, 'data.productAxisType.id')
                if (productAxisTypeId)
                    return {
                        productAxisType: global.ObjectID(productAxisTypeId)
                    }
            },
            clearable: false
        }
    ]
}
