export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "FamilyJoin",
    fields: [
        "name",
        {
            path: "familyAxisJoin",
            fieldPath: ["familyAxis"],
            f: function () {
                return this.joinedEntity === "Product" ? global.app.S.ProductAxis.objects[0] : this.familyAxis;
            }
        },
        {
            path: "completeName",
            fieldPath: ["familyAxisJoin", "name"],
            $f: function (object, context, callback) {
                callback(null, object.familyAxisJoin ? object.familyAxisJoin.name + " : " + object.name : object.name);
            }
        }
    ]
}, ["Family", "Product"])
