export const entity = {
    name: 'Zone',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true}
    ]
}

export const module_ = {
    object: 'Zone',
    tKey: 'mTitle_zone',
    objectIdentifier: 'code',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'code',
            'name'
        ],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true}
        ]
    }
}
