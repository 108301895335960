const _ = require('lodash')
const {newContext} = require("../utils/contextUtils")

if (!global.isClient) {
    var async = require('async')
}

const addJoinedEntity = entity => object => (object.joinedEntity = entity.name)

export const joinEntities = function(parentEntity, childEntities) {
    //kludge, waiting for integration into enhancers
    const getChildEntities = () =>
        childEntities.map(
            childEntity =>
                _.isString(childEntity)
                    ? _.find(parentEntity.model.entities, { name: childEntity })
                    : childEntity
        )

    return _.defaults(parentEntity, {
        name: parentEntity.name,
        isJoinedEntity: true,
        db: {
            find: function(context, callback) {
                async.map(
                    getChildEntities(),
                    (childEntity, callback) => {
                        const context_ = _.clone(context)
                        if(childEntity.type === 'static') {
                            context_.filters = context_.filters.filter(filter => filter.name !== "internal:group" )
                        }
                        childEntity.db.find(context_, (e, child) => {
                            if (e) return callback(e)
                            child.forEach(addJoinedEntity(childEntity))
                            callback(null, child)
                        })
                    },
                    (e, childrenList) => {
                        if (e) return callback(e)
                        const children = _.flatten(childrenList)
                        parentEntity.db.fromDBO(children, context, callback)
                    }
                )
            },

            get: function(id, context, callback) {
                let childResult
                async.some(
                    getChildEntities(),
                    (childEntity, callback) => {
                        const context_ = _.clone(context)
                        if(childEntity.type === 'static') {
                            context_.filters = context_.filters.filter(filter => filter.name !== "internal:group" )
                        }
                        childEntity.db.get(id, context_, (e, child) => {
                            if (child) addJoinedEntity(childEntity)(child)
                            callback(e, (childResult = child))
                        })
                    },
                    e => callback(e, childResult)
                )
            },

            getDBObject: function(id, context, callback) {
                let childResult
                async.some(
                    getChildEntities(),
                    (childEntity, callback) => {
                        childEntity.db.getDBObject(id, context, (e, child) => {
                            if (child) addJoinedEntity(childEntity)(child)
                            callback(e, (childResult = child))
                        })
                    },
                    e => callback(e, childResult)
                )
            },

            createRef: function(object, dbType) {
                return global.DBRef(
                    parentEntity.collectionName,
                    object._id || global.ObjectID(object.id)
                )
            }
        }
    })
}
