export const entity = {
    name: 'Category',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true}
    ]
}

export const module_ = {
    object: 'Category',
    tKey: 'mTitle_categorie',
    objectIdentifier: 'code',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: ['code', 'name'],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
        ]
    }
}
