export const entity = {
    name: 'StateModelAssignment',
    fields: [
        { type: 'User', unique: true, index: true},
        {
            path: 'stateModels',
            type: 'StateModel',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'numberOfAssignedState',
            fieldPath: ['stateModels.id'],
            f: function() {
                return this.stateModels ? this.stateModels.length : 0
            }
        }
    ],
    indexes: [
        { key: {user: 1}}
    ]
}

export const module_ = {
    name: 'StateModelAssignment',
    object: 'StateModelAssignment',
    tKey: 'mTitle_stateModelAssignment',
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    category: {
        path: 'assignment',
        icon: 'user-check'
    },
    viewMap: {
        dt: ['user', 'numberOfAssignedState'],
        form: [
            { path: 'user', editable: false },
            { path: 'stateModels', tKey: 'state(s)', display: 'translatedFullName', required: true }
        ]
    }
}
