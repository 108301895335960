// const { setFieldListOptions } = require('../../../../../apps/KpModule/actions/index')
// const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')

export const kpBusinessProject = {
    name: "kpBusinessProject",
    object: "BusinessProject",
    newable: false,
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "typeOfOffer"},
            "creationDate",
            "effectiveOfferSubmissionDate",
            "signatureDate",
            "beginningOfRealisationDate"
        ],
        form: {
            fields: [
                "country",
                "fullName",
                "typeOfOffer",
                "creationDate",
                "effectiveOfferSubmissionDate",
                "signatureDate",
                "beginningOfRealisationDate"
            ]
        }
    },
    filters: ["byTypeOfOffer"]
}
