const _ = require('lodash')

const { setFieldListOptions, changeFieldRequired, setFieldVisibility} = require('../../../../apps/KpModule/actions')
const {
    generateFetchFieldListAction
} = require('../../../../apps/KpModule/actions/api')

export const entity = {
    name: 'ModelFolder',
    fields: [
        {type: 'ModelFolderType', unique: true, uniqueWith: ['bPProjectType', 'stateModel', 'projectContextM1M2']},
        'BPProjectType',
        { path: 'order', type: 'integer' },
        'StateModel',
        { type: 'ProjectContextM1M2', nullable: true },
        'suffix',
        { path: 'customStateName', type: 'boolean' }
    ],
    indexes: [
        { key: { modelFolderType: 1, bPProjectType: 1, stateModel: 1} }
    ]
}

export const module_ = {
    kp: true,
    object: 'ModelFolder',
    tKey: 'mTitle_modelFolder',
    defaultSortBy: 'order',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'modelFolderType',
            {path: 'bPProjectType', tKey: 'projectType'},
            'order',
            {path: 'stateModel', tKey: 'state', display: 'tName'},
            {path: 'projectContextM1M2', tKey: 'scenario'},
            'suffix',
            {path: 'customStateName', width: 50}
        ],
        form: [
            {
                path: 'modelFolderType',
                default: {id: 'bpstore'},
                editable: false,
                subscriptions: {
                    onChange: (newValue, oldValue, { module, store }) => {
                        if (newValue && newValue.id) {
                            const isBPStore = newValue.id === 'bpstore'
                            const kindQuery = isBPStore
                                ? {kind: ['6', '7']} // bpstore
                                : {kind: ['9']} // empiric

                            store.dispatch(
                                generateFetchFieldListAction(
                                    'm-B-modelFolder.ModelFolder_stateModel',
                                    store.getState,
                                    'form',
                                    { data: kindQuery }
                                )
                            )

                            const modelFolderTypeQuery = {modelFolderType: newValue.id}

                            store.dispatch(
                                generateFetchFieldListAction(
                                    'm-B-modelFolder.ModelFolder_projectContextM1M2',
                                    store.getState,
                                    'form',
                                    { data: modelFolderTypeQuery }
                                )
                            )

                            if(!isBPStore) {
                                const projectContextM1M2 = module.viewMap.form.fields.find(
                                    field => field.path === 'projectContextM1M2'
                                )
                                projectContextM1M2.setValue({id: null})
                            }

                            store.dispatch(changeFieldRequired('e_projectContextM1M2', isBPStore))
                            store.dispatch(setFieldVisibility('e_projectContextM1M2',isBPStore))

                        } else {
                            store.dispatch(setFieldListOptions('e_stateModel', []))
                            store.dispatch(setFieldListOptions('e_projectContextM1M2', []))
                        }
                    }
                }
            },
            { path: 'bPProjectType', tKey: 'projectType', editable: false },
            { path: 'order', required: true, default: 1},
            {path: 'stateModel', tKey: 'state', filters: ['byKindProject'], display: 'tName'},
            {path: 'projectContextM1M2', tKey: 'scenario', filters: ['onlyForBPStore']},
            'suffix',
            {
                path: 'customStateName',
                default: false,
                subscriptions: {
                    onChange: (newValue, oldValue, { module, store }) => {
                        store.dispatch(changeFieldRequired('e_suffix', !newValue))
                    }
                }
            }
        ]
    },
    filters: [{
        title: 'byModelFolderType',
        path: 'modelFolderType',
        object: 'ModelFolderType',
        display: 'name',
        client: true,
        width: 12,
        default: { id: 'bpstore', name: 'bpstore' },
        placeholder: 'selectModelFolderType',
        query: function(context) {
            const modelFolderType = _.get(
                context.data,
                'modelFolderType.id'
            )
            return modelFolderType
                ? { modelFolderType }
                : {}
        }
    }]
}
