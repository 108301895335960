import {
    FIELD_LIST_FAILURE,
    FIELD_LIST_REQUEST,
    FIELD_LIST_SUCCESS
} from '../actions/api'
import { CLEAR_ERRORS, INITIALIZE_MODULE, SET_DATA_LIST } from '../actions'

const defaultDataList = {
    list: {
        isFetching: false,
        allIds: [],
        byId: {},
        error: null,
        errorStatus: null
    }
}

export const dataList = (state = defaultDataList, action) => {
    switch (action.type) {
        case FIELD_LIST_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    error: null,
                    errorStatus: null
                }
            }
        case FIELD_LIST_SUCCESS:
        case SET_DATA_LIST:
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    allIds: action.data.map(o => o.id),
                    byId: action.data.reduce(
                        (acc, o) => ({ ...acc, [o.id]: o }),
                        {}
                    )
                }
            }
        case FIELD_LIST_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    error: action.error,
                    errorStatus: action.status
                }
            }
        case CLEAR_ERRORS:
            return (state.list.error === null) || (state.list.errorStatus === null)
                ? state
                : {
                      ...state,
                      list: {
                          ...state.list,
                          error: null,
                          errorStatus: null
                      }
                  }
        default:
            return state
    }
}

const fieldsForNewModule = (state, dataLists) => {
    const byId = dataLists.allIds.reduce((acc, id) => {
        const field = dataLists.byId[id]
        acc[id] = {
            ...dataList(undefined, {}),
            ...field
        }
        return acc
    }, {})

    return { ...dataLists, byId }
}

const reduceAllFields = (state, action) => {
    let hasChanged = false

    const byId = state.allIds.reduce((acc, id) => {
        const field = state.byId[id]
        const nextField = dataList(field, action)
        hasChanged = hasChanged || field !== nextField
        acc[id] = nextField
        return acc
    }, {})

    return hasChanged ? { ...state, byId } : state
}

const reduceIfIdMatches = (state, action) => {
    const listObject = action.id && state.byId && state.byId[action.id]

    if (listObject) {
        const nextField = dataList(listObject, action)

        if (nextField !== listObject) {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: nextField
                }
            }
        } else {
            return state
        }
    } else {
        return state
    }
}

const defaultState = {
    allIds: [],
    byId: {}
}

export const fields = (state = defaultState, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return fieldsForNewModule(state, action.module.dataLists)
        case CLEAR_ERRORS:
            return reduceAllFields(state, action)
        default:
            return reduceIfIdMatches(state, action)
    }
}

export default fields
