export const entity = {
    name: 'Supplier',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields: [
        {path: 'status', type: 'Status'},
        'complementaryInformation'
    ]
}


export const module_ = {
    object: 'Supplier',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    tKey: 'fournisseurs',
    viewMap: {
        dt: [
            'code',
            'name',
            {path: 'status'}
        ],
        form: [
            'code',
            'name',
            {path: 'status', type: 'toggle'},
            {path: 'complementaryInformation', tKey: 'informations complémentaires', type: 'textarea'}
        ],
    }
}