// eslint-disable-next-line
const _ = require("lodash")
const moment = require("moment")
const todayISO = moment().format("YYYY-MM-DD")
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD")
const path = require("path");
const familyMailsPath = path.join(global.appRoot, "src/server/models/family/mails")
const { updateObject } = require('../../apps/KpModule/actions')
const { getFormValues } = require('../../apps/KpModule/selectors')

export default {
    id: "F",
    name: "Family",
    entities: _.concat(
        require('./family/hermesStatics').entities,
        require('./family/hermesRefs').entities,
        require('./family/stateModel').dependencies,
        [
            {
                name: "Janitor",
                fields: [
                    {path: "name"}
                ]
            },
            {
                name: "Course",
                facets: ["codeName"],
                fields: []
            },
            {
                name: "Teacher",
                facets: ["codeName"],
                fields: [
                    {type: "Child", nullable: false},
                    {path: 'birthMonth', type: 'month'},
                    {path: 'birthRange', type: 'monthRange'},
                    //{type: "PetInternal", link: "OTM"},
                    {path: "courses", type: "Course", link: "MTM"},
                    {path: "pets", type: "Pet", link: "MTM"},
                    {path: "courses", type: "Course", link: "MTM"},
                    {path: "date", type: 'date'},
                    {path: "dateRange", type: "dateRange"},
                    // {
                    //     type: "Course",
                    //     link: "OTM",
                    //     oppositeField: {
                    //         path: "teacher"
                    //     }
                    // }
                ],
                filters: [
                    {
                        name: "byMonth",
                        path: "month",
                        type: "monthPickerRange",
                        client: true,
                        isDefault: false,
                        query: function(context) {
                            const month = context.data && context.data.month ;

                            if(month) return {"birthMonth": { $gte : month[0], $lte: month[1]}};
                        }
                    }
                ]
            },
            {
                name: "Parent",
                //{name: "files", linkType: "OTM"}
                facets: [{name: "comments", entityName: "SpecialComments"}, "files"],
                // filters: [
                //     {name: "active", index: true, query: function(context) {
                //         const active = context.data ? context.data.active : true;
                //         const isNotActive = {active: false};
                //
                //         return active ? {$not: isNotActive} : isNotActive;
                //     }},
                //     {name: "noJob", query: {job: null}}
                // ],
                sorters: [
                    {name: "supermarketNumDesc", after: function(o1, o2) { return o1.supermarkets.length - o2.supermarkets.length; }}
                ],
                fields: [
                    'location',
                    {path: "name", notEmpty: true},
                    {type: "House", link: "OTM"},
                    {type: "Job", nullable: false},
                    {type: "Supermarket", link: "MTM", notEmpty: true}
                ],
                get: function(id, context, callback) {
                    this.prepareContext(context, 'R', (error, context) => {
                        if (error) callback(error)
                        else
                            callback(null, {
                                id:"5ef99db6745c380447ddabc6",
                                name:"Yassine BELGHITI",
                                houses:[
                                    {location: {address: "toztoz", country: "France"}, habitable: true, date: "2021-10-07", id: "60c2211fd4e87e591cc2877e", years: {'2010': {data: 41783, type: 'editText'}, '2011': {data: 7641, type: 'editText'}, '2012': {data: 5981, type: 'editText'}}},
                                    {location: {address: "6 rue buzelin", country: "France"}, habitable : true, date: "2021-12-24", id: "5ef99eda9978c504c246e861", years: {'2010': {data: 187245, type: 'editText'}, '2011': {data: -124, type: 'editText'}, '2012': {data: -1296, type: 'editText'}}}
                                ]
                            })
                    })
                }
            },
            {
                name: "Child",
                fields: [
                    {
                        path: "nameSetter",
                        fieldPath: ["fixedName"],
                        $f: function(child, context, callback) {
                            callback(null, child.fixedName)
                        },
                        $s: function(nameSetter, child, context, callback) {
                            child.fixedName = nameSetter
                            callback()
                        }
                    },
                    {path: "name", unique: true, uniqueWith: ["age", "parent"], notEmpty: true},
                    {type: "Parent", nullable: true},
                    {path: "age", type: "integer", default: 3},
                    "Color",
                    {path: "birthDate", type: "date"},
                    {path: "birthTime", type: "time"},
                    {path: "manual", type: "manual"},
                    {path: "modifManual", fieldPath: ["manual"], $f: function(child, context, callback) { callback(null, child.manual || ""); }, $s: function(value, child, context, callback) { console.log(child.modifManual);child.manual = child.modifManual; callback(); }},

                    // {path: "nameSetter", fieldPath: ["name"], f: function() { return this.name; }},

                    {path: "genBirthDate", type: "date", f: function() { return new Date(); }},
                    {
                        path: "fullName",
                        fieldPath: ["name", "manual", "parent"],
                        f: function() { return `${this.name} ${this.parent ? ("from " + this.parent.name) : "(orphelin)"} ${this.manual ? ", yeah" : ""}`; }
                    }
                ],
                filters: [
                    {
                        name: "byParent",
                        path: "parent",
                        object: "Parent",
                        isDefault: false,
                        query: function(context) {
                            const parentId = context.data && context.data.parent && context.data.parent.id;

                            if(parentId) return {"parent.$id": global.ObjectID(parentId)};
                        }
                    }
                ]
            },
            {
                name: "House",
                type: "mongoInternal",
                fields: [
                    {path: "address"},
                    "habitable",
                    {path: "country"},
                    {path: 'date', type: 'date'}
                ]
            },
            {
                name: "Supermarket",
                facets: ["translatedField"],
                fields: [
                    {path: "name", notEmpty: true},
                    {path: "date", type: "date", nullable: true},
                    {path: "dateRange", type: "dateRange"},
                    {path: "opened", type: "boolean", default: true},
                    "Color"
                ],
                filters: [
                    {
                        name: "opened",
                        isDefault: false,
                        match: function(object) { return object.opened !== false; }
                    }
                ],
                afterSave: function (newObject, oldObject, context, callback) {
                    const data = []
                    console.log(context.user.id + 'just saved an object')
                    global.ioSocket.emit('afterSave', data)
                    callback()
                }
            },
            {
                name: "Zone",
                fields: [
                    {path: "code", unique: true},
                    {path: "name", notEmpty: true},
                    {path: "time", type: "time"}
                ]
            },
            {
                name: "Country",
                fields: [
                    {path: "name", notEmpty: true},
                    "Zone",
                    {path: "independenceDay", type: "date"},
                ],
                filters: [
                    {
                        name: "byZone",
                        object: "Zone",
                        query: function(context) {
                            const zoneId = _.get(context.data, 'zone.id');
                            if(zoneId) return {"zone.$id": global.ObjectID(zoneId)};
                        }
                    },
                    // {
                    //     name: "byIndependenceDay",
                    //     type: "dateRange",
                    //     path: "independenceDay",
                    //     default: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
                    //     client: true,
                    //     match: function (object, context) {
                    //         const filter
                    //         Date = _.get(context, 'data.independenceDay');
                    //         if(filterDate && filterDate.length) {
                    //             const startDate = moment(filterDate[0])
                    //             const endDate = moment(filterDate[1])
                    //             const date = moment(object.independenceDay);
                    //             return date.isBetween(startDate, endDate);
                    //         }
                    //         return true;
                    //     }
                    // }
                    {
                        name: "byIndependenceDay",
                        type: "datePicker",
                        path: "independenceDay",
                        default: moment().format("YYYY-MM-DD"),
                        client: true,
                        match: function (object, context) {
                            const filterDate = _.get(context, 'data.independenceDay');
                            if(filterDate) {
                                const date = moment(object.independenceDay).format("YYYY-MM-DD");
                                return filterDate === date;
                            }
                            return true;
                        }
                    }
                ]
            },
            {
                name: "Team",
                fields: [
                    {path: "code",unique: true, uniqueWith: "country"},
                    {path: "name", notEmpty: true},
                    "Zone",
                    {type: "Country", link: 'MTM'}
                ]
            },
            {
                name: "Pet",
                fields: [
                    {path: "name", notEmpty: true, tKey: 'dada'},
                    "age",
                    {path:"calm", type:"boolean"}
                ]
            },
            {
                name: "PetInternal",
                type: "mongoInternal",
                fields: [
                    "name",
                    {type: "Pet", nullable: false},
                    {path:"calm", type:"boolean"}
                ]
            },
            {
                name: "Job",
                type: "static",
                fields: [
                    "name",
                    {path: "direction", type: "boolean", default: false}
                ],
                objects: [
                    {id: "000000000000000000000001", name: "Président", direction: true},
                    {id: "000000000000000000000002", name: "DSI", direction: true},
                    {id: "000000000000000000000003", name: "Stagiaire"}
                ]
            },
            {
                name: "Color",
                type: "static",
                fields: [
                    {path: "id", type: "string"},
                    "name"
                ],
                objects: [
                    {id: "red", name: "Red"},
                    {id: "green", name: "Green"},
                    {id: "blue", name: "Blue"}
                ]
            },
            {
                name: "DataHermes",
                fields: [
                    "type",
                    "shop", "subsidiary", "country",
                    "year",
                    "data",
                    "value"
                ]
            },
            {
                name: 'Event',
                facets: ['files', 'comments'],
                fields: [
                    {path: 'category', type: 'EventCategory'},
                    {path: 'name', notEmpty: true},
                    {path: 'description'},
                    {path: 'images', type: 'Image', link: {type: 'MTM', deleteType: 'cascade'}},
                    {path: 'videos', type: 'Video', link: {type: 'MTM', deleteType: 'cascade'}},
                    {
                        path: 'enhancedDescription',
                        fieldPath: ['category.name', 'name', 'description'],
                        f: function() { return `<strong>${this.category.name} - ${this.name}</strong><br /><br />${this.description}` }
                    },
                    {
                        path: 'commentsNumber',
                        f: function() { return this.comments.length }
                    },
                ],
                filters: [
                    {
                        name: 'byEventCategory',
                        path: 'category',
                        object: 'EventCategory',
                        display: 'name',
                        client: true,
                        clearable: true,
                        query: function(context) {
                            const eventCategoryId = _.get(context.data, 'category.id')
                            if(eventCategoryId)
                                return {'category': eventCategoryId}
                            else
                                return {}
                        }
                    },
                ]
            },
            {
                name: 'EventCategory',
                type: 'static',
                fields: [
                    {path: 'id', type: 'string'},
                    'name'
                ],
                objects: [
                    {id: 'communication', name: 'Communication'},
                    {id: 'webinar', name: 'Webinar'},
                    {id: 'private', name: 'Private'},
                ]
            },
            {
                name: 'Image',
                fields: [
                    {path: 'name', notEmpty: true},
                    'User',
                    {path: 'date', notEmpty: true}
                ]
            },
            {
                name: 'Video',
                fields: [
                    {path: 'name', notEmpty: true},
                    'User',
                    {path: 'date', notEmpty: true}
                ]
            },
            require('./family/accountModel').entity,
            require('./family/stateModel').entity,
            require('./family/state').entity,
            require('./family/hermesShopBP').entity,
        ]
    ),
    modules: [
        require('./family/stateModel').module_,
        require('./family/accountModel').module_,
        require('./family/state').module_,
        require('./family/hermesShopBP').module_,
        {
            object: "Teacher",
            category: "human",
            kp: true,
            viewMap: {
                dt: [
                    "code",
                    {path: "name", type: 'richText'}
                ],
                form: [
                    {path: "code", editable: false, required: true},
                    "date",
                    "dateRange",
                    {path: "birthMonth", rules: {start: '2020-03'}},
                    "birthRange",
                    {path: "pets", type: "tags"},
                    {path: "separator", type: "separator"},
                    {path: "name", type: "richTextEditor", required: true},
                    {path: "courses", type: 'dualBoxList', display: 'code'},
                ]
            },
            filters: [{
                name: "byMonth",
                default: [moment().add(-1, 'month').format("YYYY-MM"), moment().format("YYYY-MM")]
            }]
        },
        {
            object: "Parent",
            category: "human",
            kp: true,
            defaultSortBy: "job",
            defaultSortDirection: "ASC",
            viewMap: {
                dt: [
                    "name",
                    "job"
                ],
                form: [
                    "name",
                    {path: "supermarkets", filters: ["opened"]},
                    "job",
                    {
                        path: "houses",
                        type: 'styledTable',
                        fields: [
                            {path: "address", dynamic: true},
                            "country",
                            {path: "habitable"},
                            {path: "years", dynamic: true},
                            'date'
                        ]
                    },
                    "comments",
                    "files"
                ]
            }
        },
        {
            object: "Parent",
            name: "ParentHouse",
            category: "human",
            viewMap: {
                dt: [
                    "name"
                ],
                form: [
                    {
                        path: "name",
                        subscriptions: {
                            onChange: (newValue, oldValue, { formInitialize, module, store }) => {

                                if (newValue) {
                                    const state = store.getState()
                                    const formValues = getFormValues(state)

                                    if(newValue.length > 10) {
                                        store.dispatch(updateObject({
                                            ...formValues,
                                            houses: formValues.houses.map(house => {
                                                return {
                                                    ...house,
                                                    location: {
                                                        address: house.location.address,
                                                        country: house.location.country,
                                                        zone: 'default zone'
                                                    }
                                                }
                                            })
                                        }))
                                    }
                                    if(newValue.length < 10) {
                                        store.dispatch(updateObject({
                                            ...formValues,
                                            houses: formValues.houses.map(house => {
                                                return {
                                                    id: house.id,
                                                    date: house.date,
                                                    habitable: house.habitable,
                                                    location: {
                                                        address: house.location.address,
                                                        country: house.location.country,
                                                    }
                                                }
                                            })
                                        }))
                                    }
                                }
                            }

                        }
                    },
                    {
                        path: 'houses',
                        type: "dtObjects",
                        parentHeader: true,
                        fields: [
                            {path: 'location', type: 'editText', dynamic: true, parentColumn: 'location' },
                            /*
                            {path: "address", type: 'editText', parentColumn: ''},
                            {path: "country", parentColumn: 'position'},
                             */
                            {path: "habitable", type: 'boolean', parentColumn: 'information'},
                            {path: "date", type: 'datePicker', parentColumn: 'information'}
                        ]
                    }
                ]
            }
        },
        {
            object: "Child",
            category: "human",
            viewMap: {
                dt: [
                    "name",
                    "age",
                    "nameSetter",
                    "color",
                    "parent",
                    "birthDate"
                    //"age"z
                ],
                form: [
                    {path: "nameSetter", writable: true},
                    "parent",
                    "color",
                    {path:"age", required: true, wholePositiveNumber: true},
                    "birthDate",
                ]
            }
        },
        {
            object: "Child",
            name: "ChildFilter",
            category: "human",
            viewMap: {
                dt: [
                    "name",
                    "parent"
                ],
                form: [
                    {path: "name", writable: false},
                    "parent"
                ]
            },
            filters: [
                {
                    // title: "Parent (query)",
                    path: "parent2",
                    object: "Parent",
                    display: "name",
                    client: true,
                    width: 12,
                    query: function(context) {
                        const parentId = _.get(context.data, "parent2.id");

                        if(parentId) return {"parent.$id": global.ObjectID(parentId)};
                    }
                },
                {
                    // title: "Parent (match)",
                    path: "parent3",
                    object: "Parent",
                    display: "name",
                    objectFieldPath: ["parent.id"],
                    client: true,
                    match: function(object, context) {
                        const parentId = _.get(context.data, "parent3.id");

                        return parentId ?
                            object.parent && (object.parent.id === parentId) :
                            true;
                    }
                },
                {
                    // title: "Parent (query on entity)",
                    name: "byParent",
                    display: "name",
                    client: true
                }
            ]
        },
        {
            object: "Janitor",
            category: "human",
            viewMap: {
                dt: [
                    "name",
                ]
            }
        },
        {
            object: "Course",
            category: "human",
            viewMap: {
                dt: ["code", "name"],
                form: ["code", 'name']
            }
        },
        {
            object: "Supermarket",
            category: "other",
            viewMap: {
                dt: [
                    // "name",
                    "tName",
                    "color"
                ],
                form: [
                    {path: "name", type: "textarea"},
                    {path: "date", default: todayISO},
                    {path: "dateRange", type: "dateRange", default: [lastWeekISO, todayISO]},
                    "opened",
                    "color"
                ]
            },
            filters: [
                // {match: function(object) { return object.opened !== false; }}
                // "opened"
            ]
        },
        {
            object: "Pet",
            category: "other",
            defaultSortBy: "age",
            defaultSortDirection: "DESC",
            chart: {
                type: "donutChart",
                keys: ["name"],
                data: 'age'
            },
            viewMap: {
                dt: [
                    "name",
                    { path: "age", tooltip: true },
                    "calm"
                ],
                form: [
                    "name",
                    {path: "age", default: "12"},
                    "calm"
                ],
                chart: ["name", "age", "calm"]
            }
        },
        {
            object: "Zone",
            category: "other",
            viewMap: {
                dt: ["code", "name", "time", "countrys"],
                form: ["code", "name", "time"]
            }
        },
        {
            object: "Country",
            category: "other",
            viewMap: {
                dt: ["name", "zone"],
                form: [
                    "name",
                    "zone",
                    "independenceDay"
                ]
            },
            filters: ["byIndependenceDay"]
        },
        {
            object: "Team",
            category: "other",
            viewMap: {
                dt: ["code", "name", "zone", "country"],
                form: {
                    fields : [
                        "code",
                        "name",
                        {
                            path: "zone",
                            /*
                            subscriptions: {
                                onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                                    if (newValue && newValue.id) {
                                        const data = {zone: {id: newValue.id}}
                                        store.dispatch(generateFetchFieldListAction("m-F-team.Team_country", store.getState, {data}))
                                    } else {
                                        store.dispatch(setFieldListOptions("e_country", []))
                                    }
                                }
                            }

                             */
                        },
                        {path: "countrys", type: "dualBoxList"}
                    ]
                }
            }
        },
        {
            object: 'Event',
            name: 'Event-Admin',
            tKey: 'event-admin',
            category: {
                path: 'media',
                icon: 'media'
            },
            viewMap: {
                dt: [
                    'category',
                    'name',
                    {path: 'commentsNumber', tKey: 'comments', tooltip: true}
                ],
                form: [
                    'category',
                    'name',
                    {path: 'description', type: 'richTextEditor'},
                    'files',
                    {path: 'images', type: 'image', fieldPath: ['id', 'name', 'user.name', 'date']},
                    {path: 'videos', type: 'video', fieldPath: ['id', 'name', 'user.name', 'date']},
                    'comments',
                ],
            },
            filters: [
                'byEventCategory'
            ],
        },
        {
            object: 'Event',
            category: {
                path: 'media',
                icon: 'media'
            },
            newable: false,
            removable: false,
            viewMap: {
                dt: [
                    'category',
                    'name',
                    {path: 'commentsNumber', tKey: 'comments', tooltip: true}
                ],
                form: [
                    {path: 'enhancedDescription', type: 'richTextEditor', editable: false, fullWidth: true},
                    {path: 'files', fullWidth: true, editable: false},
                    {path: 'images', type: 'image', fieldPath: ['id', 'name', 'user.name', 'date'], editable: false, fullWidth: true},
                    {path: 'videos', type: 'video', fieldPath: ['id', 'name', 'user.name', 'date'], editable: false, fullWidth: true},
                    {path: 'comments', fullWidth: true},
                ],
            },
            filters: [
                'byEventCategory'
            ],
        },
    ],
    jobs: [
        {
            name: "testJob",
            execute: function(context, callback) {

                const mails = [
                    {
                        from: '"PilotFlow" <pilotflow@keenpoint.com>',
                        replyTo: 'support@keenpoint.com',
                        // to: context.user.mail,
                        to: "ybelghiti@keenpoint.com",
                        context: {
                            user: context.user,
                            richText : "<p>Hi there !!!</p><p>checkout my list for best movies of <strong>2018</strong> :</p><ol><li>deadpool 2</li><li>avengers : revenge of the ...</li></ol>"
                        },
                        content: "mailContent.html",
                        subject: {
                            template: "This is a mail for {{user.name}}"
                        },
                        templateDir: path.join(familyMailsPath, "templates"),
                        cssSheet: path.join(familyMailsPath, "css/familyMailCss.css"),
                        verbose: {
                            general: true,
                        },
                        // attachments: [
                        //     {
                        //         id: "57962887bfe0566ceb2bd74b",
                        //         filename: "Logo.png"
                        //     }
                        // ],
                    },
                    {
                        from: "'Bob Morane' <bar@blurdybloop.com>",
                        to: "huangyingzheng123@gmail.com",
                        // replyTo: "mytoloks@gmail.com",
                        context: {
                            people: [
                                {firstName: "Albert", lastName: "Einstein"},
                                {firstName: "Isaac", lastName: "Newton"},
                            ],
                            subjectName: "Charles Darwin",
                            contentTest: "MochaMocha"
                        },
                        content: "welcome/coreHTML.html",
                        subject: {template: "Test {{contentTest}}"},
                        attachments: [
                            // {
                            //     filename: "text2.txt",
                            //     content: new Buffer("hello world!", "utf-8")
                            // },
                            // {
                            //     id:"594a35023cd0f2594cc8b52d",
                            //     filename : "J01022017HICdu03_02_2017.pdf"
                            // }
                            {   // file on disk as an attachment
                                path: __dirname + "/testAttachment.txt" // stream this file
                            },
                        ],
                        verbose: {
                            general: true,
                            mailContent: false
                        }
                    },
                    {
                        from: "'Bob Morane' <bar@blurdybloop.com>",
                        to: "huangyingzheng123@gmail.com",
                        replyTo: "mytoloks@gmail.com",
                        content: "welcome/coreHTML.html",
                        subject: {html: "Direct subject"},
                        attachments: [{
                            path: "https://raw.github.com/nodemailer/nodemailer/master/LICENSE"
                        }],
                        verbose: {
                            general: true,
                            mailContent: false
                        }
                    }
                ]
                global.mailer.sendMail(mails, function(e) {
                    if(e) callback(null, {message: e});
                    else callback(null, {message: "Test job done."});
                });
            }
        },
        // {
        //     name: "testCron",
        //     title: "test cron",
        //     cron: "*/5 * * * * *",
        //     execute: function (context, callback) {
        //         console.log("test cron executed");
        //         this.sJob.cancel();
        //         callback();
        //     }
        // }
    ]
};
