import React from 'react'
import PropTypes from 'prop-types'

const FormField = ({children, width, minWidth}) => {

    const additionalStyle = minWidth ? {minWidth: `${minWidth}px`} : {}

    return (

            <div className={`form-group col-${width} col-sm-${width} col-md-${width} col-lg-${width} col-xl-${width}`} style={additionalStyle}>
                <div style={{marginBottom: '25px'}}>{children}</div>
            </div>
    )
}

FormField.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    t: PropTypes.func
}

export default FormField
