import moment from 'moment'
import {getHabilitations} from "../../utils/habilitations";
const _ = require("lodash");

async function findData(context, callback) {

    const yesterdayISO = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const todayISO = moment().format('DD-MM-YYYY')
    const lastWeekISO = moment().subtract(7, 'days').format('DD-MM-YYYY')

    const dashboardDates = [lastWeekISO, yesterdayISO]
    const dates = [lastWeekISO, todayISO].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
    const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habQuery = stores.length || organizations.length
        ? {
            $or: [
                {store: {$in : stores}},
                {organizations: {$in: organizations}}
            ],
        }
        : {}

    const datesQuery = {
        'date.gte': { $gte: dates[0].toDate() },
        'date.lt': { $lte: dates[1].toDate() }
    }

    const alertConfigurationCollection =
        global.app.S.AlertConfiguration.collection
    const alertConfigurationQuery = alertConfigurationCollection
        .find({
            code: 'DashboardDRRemboursementEspeceSansTicketOrigineMagasin7jours'
        })
        .toArray()

    const [alertConfigurations] = await Promise.all([
        alertConfigurationQuery
    ])

    const alertConfiguration = alertConfigurations[0]

    const alertConfQuery = {
        alertConfiguration: alertConfiguration._id
    }

    const alertCollection = global.app.S.Alert.collection
    const alertQuery = alertCollection
        .aggregate([
            {
                $match: {
                    ...datesQuery,
                    ...alertConfQuery,
                    ...habQuery,
                    'theme-RemboursementSansTicketOrigine.Espèce.Mt': { $exists: true }
                }
            },
            { $sort : { 'theme-RemboursementSansTicketOrigine.Espèce.Mt' : 1} }
        ])
        .toArray()

    const [alerts] = await Promise.all([alertQuery])

    const formatedAlerts = _.slice(alerts, 0, 10).map( o => ({
        store: _.get(o, 'store'),
        value: _.round(_.get(o, 'theme-RemboursementSansTicketOrigine.Espèce.Mt'), 1)
    }))

    return [
        {
            id: "drRefundCashWithoutTicketPerStore",
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: formatedAlerts
        }
    ]
}

export const entity = {
    name: 'DrRefundCashWithoutTicketPerStore',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
