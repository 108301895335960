import React from 'react'
import ValidationUI from './ValidationUI'
import {ProgressBar} from './ProgressBar/ProgressBar'
import {Info} from 'react-feather'
import {MEDIUM_PASS, STRONG_PASS, WEAK_PASS} from '../utils/regExps'
import {StyledInput} from "./Form/StyledInput";
import HoverPopover from "./HoverPopover";

const passwordStrengthColors = {
    1: '#fd3333c7',
    2: 'orange',
    3: '#53a552'
}

class PasswordField extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            score: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.passwordStrength = this.passwordStrength.bind(this);
    }

    handleChange(event){
        this.props.onChange(event)
        this.passwordStrength(event)
    }

    passwordStrength(event){
        const pwd = event.target.value
        if(pwd === ''){
            this.setState({
                score: 0
            })
        }
        else{
            const strongRegex = new RegExp(STRONG_PASS, "g")
            const mediumRegex = new RegExp(MEDIUM_PASS, "g")
            const enoughRegex = new RegExp(WEAK_PASS, "g")

            if(strongRegex.test(pwd)) {
                this.setState({score: 3})
            } else if(mediumRegex.test(pwd)){
                this.setState({score: 2})
            } else if(enoughRegex.test(pwd)) {
                this.setState({score: 1})
            } else this.setState({score: 0})
        }

    }

    render(){
        return(
            <div>
                <StyledInput
                    {...this.props}
                    onChange={this.handleChange}
                    displayError={false}
                />
                <div style={{display: 'flex', padding: '0 5px', alignItems: 'center'}}>
                    <ProgressBar percentage={(1000 * this.state.score)/30} color={passwordStrengthColors[this.state.score]}/>
                    <HoverPopover id={'passwordStrength'} message={this.props.t("weakPassword")} placement={"left"}>
                        <Info size={12} style={{marginLeft: '3px', color: '#525252', cursor: 'pointer'}}/>
                    </HoverPopover>
                </div>
                { this.props.touched && (this.props.error && <ValidationUI error={this.props.error}/>) }
            </div>
        )
    }
}

export default PasswordField
