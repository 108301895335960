import React from 'react'
import PropTypes from 'prop-types'
import './RankOptionsField.css'
import {Menu} from 'react-feather'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {arrayMoveImmutable} from 'array-move'
import {translateName} from "../../utils/i18n";


const SortableItem = SortableElement(({value, sortIndex}) => {
    return (
        <div className="RankOptionsField-option-container">
            <Menu className="RankOptionsField-option-icon" size={14}/>
            <div className="RankOptionsField-option-label">{value}</div>
            <div className="RankOptionsField-option-rank">{sortIndex + 1}</div>
        </div>
    )
})

const SortableList = SortableContainer(({value, optionsById, language}) => {
    return (
        <div className="RankOptionsField-options-container">
            {value.map((id, index) => (
                <SortableItem key={id} index={index} sortIndex={index} value={translateName(optionsById[id].name, language)} />
            ))}
        </div>
    );
});

const RankOptionsField = props => {
    return (
        <div className="RankOptionsField-wrapper">
            <div className="RankOptionsField-label">{props.label} {props.required && '*'}</div>
            {props.subtitle && <div className="RankOptionsField-subtitle">{props.subtitle}</div>}
            <div>
                <SortableList value={props.value || []} optionsById={props.optionsById} language={props.language} onSortEnd={({oldIndex, newIndex}) => {
                    props.onChange(arrayMoveImmutable(props.value, oldIndex, newIndex))
                }}/>
            </div>
        </div>
    )
}

RankOptionsField.propTypes = {
    value: PropTypes.any.isRequired,
    options: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default RankOptionsField
