const _ = require('lodash')
const moment = require('moment')
const { generateEmpiricFolder } = require('../utils/empiricExportXlsx')

export const entity = {
    name: 'EmpiricFolderExport',
    facets: [{ name: 'files', linkType: 'OTO' }],
    fields: [
        {
            type: 'ShopBPEndorsed',
            link: { oppositeField: { link: { deleteType: 'cascade' } } }
        },
        { path: 'user', type: 'User', nullable: true },
        { path: 'date', dateFormat: 'YYYY-MM-DD HH:mm', type: 'date', nullable: true }
    ],
    beforeSave: function(object, oldObject, context, callback) {
        object.user = _.pick(context.user, ['id'])
        object.date = new Date()

        callback(null, object, oldObject)
    },
    afterSave: function(object, oldObject, context, callback) {
        generateEmpiricFolder(object, context)
            .then(result => {
                global.ioSocket.emit(
                    `fetchEditObject-success-${context.user.id}${context.clientContext.accessId}`,
                    result
                )
            })
            .catch(err => {
                console.error(err.message)
                global.ioSocket.emit(
                    `fetchEditObject-failure-${context.user.id}${context.clientContext.accessId}`,
                    {error: err.message}
                )
            })
        callback()
    },
    filters: [{
        name: 'onlyOwner',
        isDefault: true,
        query: function(context) {
            const userId = _.get(context, 'user.id')
            return userId
                ? { user: global.ObjectID(userId) }
                : {_id: false}
        }
    }]
}
export const module_ = {
    object: 'EmpiricFolderExport',
    tKey: 'mTitle_empiricFolderExport',
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    useSocketsOnSave: true,
    updatable: false,
    editable: false,
    category: {
        path: 'empiric',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            {
                path: 'shopBPEndorsed',
                tKey: 'folder',
                display: 'fullInformation',
                width: 250
            },
            {path: 'date', width: 50},
            {path: 'file', width: 50}
        ],
        form: [
            {
                path: 'shopBPEndorsed',
                tKey: 'folder',
                display: 'fullInformation',
                filters: ['onlyAffectedSubsidiary']
            }
        ]
    }
}
