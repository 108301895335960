const getCompleteName = object => {
    switch (object.joinedEntity) {
        case 'SurFamily':
            return `SRF - ${object.company.code} - ${object.name}`
        case 'Family':
            return `FAM - ${object.company.code} - ${object.name}`
        case 'Company':
            return `SOC - ${object.name}`
        case 'Zone':
            return `ZON - ${object.name}`
        case 'Deposit':
            return `DEP - ${object.name}`
        default:
            return object.name

    }
}

const getHeaderName = object => {
    switch (object.joinedEntity) {
        case 'SurFamily':
            return 'surFamilyCode'
        case 'Family':
            return 'familyCode'
        case 'Company':
            return 'companyCode'
        case 'Zone':
            return 'depositZoneCode'
        case 'Deposit':
            return 'depositCode'
        default:
            return object.name

    }
}

export const entity = require('./../../utils/entityJoin').joinEntities(
    {
        name: 'ReferentialJoin',
        fields: [
            {
                path: 'completeName',
                fieldPath: ['name', 'company.code'],
                $f: (object, context, callback) => {
                    callback(null, getCompleteName(object))
                }
            },
            {
                path: 'headerName',
                fieldPath: ['joinedEntity'],
                $f: (object, context, callback) => {
                    callback(null, getHeaderName(object))
                }
            }
        ]
    },
    ['Company', 'Zone', 'Deposit']
)
