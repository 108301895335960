const moment = require('moment')

export const entity = {
    name: 'HermesShopBP',
    fields: [
        'HermesShop', 'bp', {path: 'endorsed', type: 'date'}, {path: 'real', type: 'date'}, 'HermesProjectType',
        {
            path: "fullInformations",
            fieldPath: ['hermesShop.code', 'bp', 'endorsed', 'real'],
            f: function() {
                return `${this.hermesShop.code} / ${this.bp} / ${moment(this.endorsed).year()} / ${moment(this.real).year()}`;
            }
        }
    ],

}

export const module_ = {
    object: 'HermesShopBP',
    tKey: 'mTitle_hermesShopBP',
    defaultSortBy: 'bp',
    defaultSortDirection: 'ASC',
    category: {
        path: 'hermes',
        icon: 'server'
    },
    viewMap: {
        dt: ['hermesShop', 'bp', 'endorsed', 'real', 'hermesProjectType'],
        form: ['hermesShop', 'bp', 'endorsed', 'real', 'hermesProjectType']
    }
}
