import React, { useLayoutEffect } from 'react'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import VideoThumbnail from 'react-video-thumbnail'
import './VideosViewer.css'


const MAX_SIZE_THUMB = 50

// the videothumbnail also make a GET request to /video/:name
const customRenderThumb = children => {
    return children.map(item => {
        const urlSplit = item.props.src.split('_')
        let videoName = urlSplit.slice(2).join('_').split('.')[0]
        if (videoName.length > MAX_SIZE_THUMB) {
            videoName = videoName.slice(0, MAX_SIZE_THUMB)
            videoName = videoName + '...'
        }

        return (
            <div
                className='videoThumbnail'
                key={`videoThumbnail:${item.props.src}`}
                onClick={sizeCalc}
            >
                <VideoThumbnail
                    snapshotAtTime={0}
                    videoUrl={item.props.src}
                />
                <p>{videoName}</p>
            </div>
        )
    })
}

const customRenderItem = item => {
    const urlSplit = item.props.src.split('_')
    let videoName = urlSplit.slice(2).join('_').split('.')[0]

    return (
        <div className='videoItem'>
            {item}
            <p>{videoName}</p>
        </div>
    )
}

const heightCalc = () => {
    /* dynamic calculation of playlist height */
    const videoItemDiv = document.querySelectorAll('.vids .slider-wrapper.axis-horizontal')[0]
    const playlistDiv = document.querySelectorAll('.vids .thumbs-wrapper.axis-vertical')[0]
    playlistDiv.style.height = videoItemDiv.offsetHeight + 'px'
}
const widthCalc = () => {
    /* Safari canvas from video workaround */
    const thumbsBadWidth = document.querySelectorAll('.vids .react-thumbnail-generator video')
    const thumbsGoodWidth = document.querySelectorAll('.vids .react-thumbnail-generator img')
    const thumbsTitle = document.querySelectorAll('.vids .videoThumbnail p')

    /* Convert NodeList to Array then calculate max width */
    if (thumbsBadWidth.length > 0) {
        const thumbsGoodWidthSizeArray = [...thumbsGoodWidth].map(img => img.width)
        const thumbMaxWidth = Math.max(...thumbsGoodWidthSizeArray)
        if (thumbMaxWidth ) {
            thumbsBadWidth.forEach( thumb => {
                thumb.style.width = thumbMaxWidth + 'px'
            })
        }
    }
    if (thumbsTitle.length > 0) {
        const thumbsTitleSizeArray = [...thumbsTitle].map(p => p.offsetWidth)
        const thumbTitleMax = Math.max(...thumbsTitleSizeArray)
        if (thumbTitleMax) {
            thumbsTitle.forEach( p => {
                p.style.width = thumbTitleMax + 'px'
            })
        }
    }
}
const sizeCalc = () => {
    heightCalc()
    widthCalc()
}


const VideosViewer = props => {

    useLayoutEffect(() => {
        if (props.data.videos?.length !== 0) {
            window.addEventListener('resize', heightCalc)
            return () => {
                window.removeEventListener('resize', heightCalc)
            }
        }
    }, [props.data.videos?.length])

    return (
        <div className='vids'>

            <Carousel
                renderItem={customRenderItem}
                renderThumbs={customRenderThumb}
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                swipeable={false}
            >
                { props.data.videos?.map(video => {
                    const fullDate = moment(video.date).format('YYYY-MM-DD_HH-mm-ss')
                    return (
                        <video
                            key={`${video.date}_${video.name}`}
                            src={`/video/${fullDate}_${video.name}`}
                            onCanPlay={heightCalc}
                            width='100%'
                            height='auto'
                            style={{outline: 'none'}}
                            controls
                        />
                    )
                })}
            </Carousel>
        </div>
    )
}

export default VideosViewer
