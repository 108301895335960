const _ = require('lodash')
const Errors = require("../../utils/Errors").default

function uniqueById(value, index, self) {
    const ids = self.map(o => o.id)
    return ids.indexOf(value.id) === index;
}

export const entity = {
    name: 'ContractMonitoring',
    facets:['files', 'comments'],
    fields: [
        /*
        {
            path: 'contractId', unique: true, ps: {
                object: [{
                    type: 'nextSequence',
                    sequenceId: 'i.contractId',
                    formatResult: result => {
                        const parsedResult = `${result}`
                        switch (parsedResult.length) {
                            case 1:
                                return '00' + parsedResult
                            case 2:
                                return '0'+ parsedResult
                            default:
                                return parsedResult

                        }
                    }
                }]
            }
        },
        {
            path: 'contractNumber', unique: true, ps: {
                object: [{
                    type: 'nextSequence',
                    sequenceId: 'i.contractNumber',
                    formatResult: result => {
                        const parsedResult = `${result}`
                        switch (parsedResult.length) {
                            case 1:
                                return '00' + parsedResult
                            case 2:
                                return '0'+ parsedResult
                            default:
                                return parsedResult

                        }
                    }
                }]
            }
        },
         */
        'contractFullId',
        'identifier',
        'number',
        'wording',
        {path: 'type', type:'ContractType'},
        'analyticalCode',
        {type: 'Provider', nullable: true},
        {type: 'Customer', nullable: true},
        {
            type: 'BusinessProject',
            link: {type: "MTM", deleteType: "block"},
            filters: [{
                name: 'isUserInCountryScope',
                isDefault: true,
                query: () => ({})
            }],
            nullable: true
        },
        {path: 'state', type: 'ContractState'},
        {path: 'version', type: 'ContractVersion'},
        {path: 'kickOff', type: 'date', nullable: true},
        {type: "Year", sorters: ['byDescYear']},
        {path: 'begin', type: 'date', nullable: true},
        {path: 'end', type: 'date', nullable: true},
        {path: 'status', type: 'ContractStatus'},

        {path: "amount", type: "integer", default: 0},
        {
            path: "origin",
            fieldPath: ["provider.id", "provider.providerOrigin.name"],
            f: function () {
                return this.provider && this.provider.providerOrigin
                    ? this.provider.providerOrigin.name
                    : ''
            }
        },
        {
            path: "businessProjectsFN",
            fieldPath: [
                "businessProjects.id",
                "businessProjects.code",
                "businessProjects.name"
            ],
            f: function () {
                return this.businessProjects
                    ? this.businessProjects
                        .map(bp => `${bp.code} - ${bp.name}`)
                        .join(', ')
                    : ''
            }
        },
        {
            path: "providerAndCustomer",
            fieldPath: [
                "provider.name",
                "customer.name"
            ],
            f: function () {
                const provider = this.provider ? this.provider.name : '----'
                const customer = this.customer ? this.customer.name : '----'
                return `${provider} / ${customer}`
            }
        },
        {
            path: "technicalTeamMember",
            fieldPath: [
                "businessProjects.id",
                "businessProjects.technicalTeamMember.id",
                "businessProjects.technicalTeamMember.fullName"
            ],
            f: function () {
                return this.businessProjects
                    ? this.businessProjects
                        .map(bp => bp.technicalTeamMember)
                        .filter(value => !!value)
                        .filter(uniqueById)
                        .map(techMember => techMember.fullName)
                        .join(', ')
                    : ''
            }
        },
        {
            path: "country",
            fieldPath: [
                "businessProjects.id",
                "businessProjects.country.id",
                "businessProjects.country.name"
            ],
            f: function () {
                return this.businessProjects
                    ? this.businessProjects
                        .map(bp => bp.country)
                        .filter(value => !!value)
                        .filter(uniqueById)
                        .map(country => country.name)
                        .join(', ')
                    : ''
            }
        },
        {
            path: "zone",
            fieldPath: ["businessProjects.id", "businessProjects.country.zone.id", "businessProjects.country.zone.name"],
            f: function () {
                return this.businessProjects
                    ? this.businessProjects
                        .map(bp => bp.country.zone)
                        .filter(value => !!value)
                        .filter(uniqueById)
                        .map(zone => zone.name)
                        .join(', ')
                    : ''
            }
        }
    ],
    filters: [
        {
            name: 'byProvider',
            title: 'Provider',
            path: 'provider',
            object: 'Provider',
            display: 'name',
            placeholder: 'selectProvider',
            objectFieldPath: ['provider.id'],
            default: {id: null},
            client: true,
            query: context => {
                const providerId = _.get(context , "data.provider.id");

                if (!providerId) return {};
                return {provider: new global.ObjectID(providerId)};
            }
        },
        {
            name: 'byCustomer',
            title: 'Customer',
            path: 'customer',
            object: 'Customer',
            display: 'name',
            placeholder: 'selectCustomer',
            objectFieldPath: ['customer.id'],
            default: {id: null},
            client: true,
            query: context => {
                const customerId = _.get(context , "data.customer.id");

                if (!customerId) return {};
                return {customer: new global.ObjectID(customerId)};
            }
        },
        {
            name: 'byStatus',
            title: 'Status',
            type: "tags",
            path: 'status',
            object: 'ContractStatus',
            display: 'name',
            default: {id: 'active'},
            client: true,
            timer: 2,
            query: context => {
                const status = _.get(context , "data.status");

                if (!status) return {_id: null};
                return {status: {$in: status.map(o => o.id)}};
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === 'S' && context.restAction && context.restAction.crudType === 'C') {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            'contractId', 'contractNumber'
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    beforeSave: function (newObject, oldObject, context, callback) {
        //const action  = context.restAction && context.restAction.crudType
        const prefix = newObject.analyticalCode && newObject.analyticalCode.substring(0, 2)

        if(!newObject.provider && !newObject.customer) {
            return callback(new Errors.ValidationError('providerOrCustomerShouldBeCompleted'))
        }

        if(newObject.analyticalCode && prefix !== 'BU' ) callback(new Errors.ValidationError('analyticalCodePrefixError'))
        else callback(null, newObject, oldObject)
        /*
        else {
            if(action === 'C') {
                newObject.identifier = newObject.contractId
                newObject.number = newObject.contractNumber

                newObject.contractFullId = `${newObject.identifier}-${newObject.type.id}-${newObject.number}-${newObject.year.id}`
                callback(null, newObject, oldObject)

            } else {
                newObject.contractFullId = `${oldObject.identifier}-${newObject.type.id}-${oldObject.number}-${newObject.year.id}`
                callback(null, newObject, oldObject)
            }
        }
        */
    },
}
