import React from 'react'
import _ from 'lodash'
import {translateName} from "../../../../utils/i18n";
import './Title.css'

const Title2 = (props) => {
    const translatedValue = translateName(_.get(props, 'field.label'), props.language)
    const subtitle = _.get(props, 'field.subtitle')
    const translatedSubtitle = translateName(subtitle, props.language)
    return (
        <>
            <div className="Title-container">
                <div className="Title-label Title2"> {translatedValue} </div>
            </div>
            { subtitle && <div className="Title-subtitle">{translatedSubtitle}</div>}
        </>
    )
}

export default Title2
