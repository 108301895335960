const _ = require("lodash");

export const entity = {
    name: "Family",
    facets: [
        {name: "codeName", codeUniqueWith: "familyAxis"},
        "translatedField"
    ],
    fields: [
        "FamilyAxis",
        {
            path: "completeName",
            fieldPath: ["familyAxis.tName", "tName"],
            f: function () {
                return (this.familyAxis ? `${this.familyAxis.tName}: ` : "") + this.tName;
            }
        }
    ],
    filters: [
        {
            name: "usedInScope",
            fieldPath: ["familyAxis.usedInScope"],
            isDefault: false,
            match: function(object) {
                return _.get(object, "familyAxis.usedInScope") && object.familyAxis.usedInScope;
            },
        },
    ]
}

export const module_ = {
    object: "Family",
    tKey: "mTitle_family",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: "name",
    defaultSortDirection: "ASC",
    maxResults: 2000,
    removable: false,
    viewMap: {
        dt: [
            {path: "familyAxis", display: "tName", initiallyNotVisible: true},
            "code",
            {path: "name", type: "translatedText"}
        ],
        form: [
            {path: "code", editable: false},
            {path: "name", type: "textarea"},
            {
                path: "familyAxis",
                display: "tName",
                editable: false
                // family axis is not editable.
                // If it was, it would raise an error,
                // by the interaction with the filter,
                // each time it is edited.
            }
        ]
    },
    filters: [
        {
            path: "familyAxis",
            object: "FamilyAxis",
            display: "tName",
            client: true,
            query: function (context) {
                const familyAxisId = _.get(context, 'data.familyAxis.id');
                if (familyAxisId) return {
                    "familyAxis": global.ObjectID(familyAxisId)
                };
            },
            clearable: false
        }
    ]
}
