import {arbitrateButton, delegateButton, draftButton, returnButton, transmitButton, validateDemandeurButton, warnAndValidateButton} from "../../utils/demandUtils";
import {generateFetchFieldListAction, GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import {
    changeFieldDisabled, changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE, setFieldEndDate,
    setFieldStartDate,
    setFieldVisibility,
    setFormButtons
} from "../../../../../apps/KpModule/actions";
import moment from "moment/moment";
import _ from "lodash";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import news from "../../../slp/engines/news";

export const module_ = {
    name: "preValidation",
    object: 'Demand',
    category: {
        path: 'Projet',
    },
    tKey: 'pré-validation',
    newable: true,
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° Projet'},
            {path: 'relatedProject', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'largeImputationsTotalAmount', width: 230},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true}
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                },
                {path: 'demandNumber',tKey: 'N° Projet', disabled: true},
                {
                    path: 'relatedProject',
                    display: 'demandNumberAndTitle',
                    fieldPath: ['id', 'demandNumberAndTitle', 'organizationAndMesh', 'startMonth'],
                    filters: ['isProject', 'approvedOrInRealization', 'noRelatedProject'],
                    subscriptions : {
                        onChange (newValue, oldValue, {module, state, store}){
                            const startMonthField = module.viewMap.form.fields.find( field => field.path === 'startMonth')
                            const endMonthField = module.viewMap.form.fields.find( field => field.path === 'endMonth')
                            const objectMode = store.getState().ui.objectMode
                            const organizationAndMeshField = module.viewMap.form.fields.find(field=>field.path==='organizationAndMesh')

                            if ( objectMode === 'newObject'){
                                if (newValue){
                                    const selectedMonth = moment().isAfter(newValue.startMonth, 'month') ? moment().format("YYYY-MM") : newValue.startMonth
                                    organizationAndMeshField.setValue( {id: newValue.organizationAndMesh.id} )
                                    store.dispatch(changeFieldDisabled(organizationAndMeshField.id, true))
                                    store.dispatch(setFieldStartDate(startMonthField.id, selectedMonth))
                                    store.dispatch(setFieldStartDate(endMonthField.id, selectedMonth))
                                }
                                else{
                                    organizationAndMeshField.setValue(null)
                                    store.dispatch(changeFieldDisabled(organizationAndMeshField.id, false))
                                    store.dispatch(setFieldStartDate(startMonthField.id, moment().format("YYYY-MM")))
                                    store.dispatch(setFieldStartDate(endMonthField.id, moment().format("YYYY-MM")))
                                }
                            }
                        }
                    }
                },
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    filters: ['inWorkflowEnabledAxe', 'byHabilitation', 'inWorkflow'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {

                            if(newValue) {
                                const state = store.getState()
                                const previousLargeImputations = _.get(
                                    state,
                                    'edit.object.data.largeImputations',
                                    []
                                )

                                const previousOrganizationsIds = previousLargeImputations.map( row => row.organization.id )
                                store.dispatch(generateFetchFieldListAction(
                                    'Demand-Organization',
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            organizationId: newValue.id
                                        }
                                    }

                                )).then(() => {
                                    const newState = store.getState()
                                    const organizations = getDataListList(
                                        newState,
                                        'Demand-Organization'
                                    )
                                    let result = [...previousLargeImputations]
                                    if ( !!organizations.length ){
                                        organizations.forEach( organization => {
                                            if ( !previousOrganizationsIds.includes(organization.id) ){
                                                result.push({
                                                    organization,
                                                    //currency: null,
                                                    amount: 0
                                                })
                                            }
                                        })
                                        const largeImputationsField = module.viewMap.form.fields.find(
                                            field => field.path === 'largeImputations'
                                        )
                                        largeImputationsField.setValue(result)
                                    }
                                })
                            }
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true,
                    fieldPath: ['id', 'name', 'category.id']
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    translateName: true,
                    fieldPath: ['id', 'code', 'name']
                },
                {
                    path: 'title',
                    required: true,
                },
                {path: 'description', type: 'richTextEditor', required: true},
                {path: 'enjeux', type: 'richTextEditor', required: true},
                {path: 'objectifs', type: 'richTextEditor', required: true},
                {path: 'pointsOfAttention', tKey: `points d'attention`},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    editable: true,
                    //endDate: ''
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const state = store.getState()
                            const endMonthField = module.viewMap.form.fields.find(field => field.path === 'endMonth')
                            const endMonth = endMonthField.getValue()
                            if (newValue) {
                                store.dispatch(setFieldStartDate(endMonthField.id, newValue))
                                if ( !!endMonth && !moment(endMonth).isSameOrAfter(newValue)){
                                    endMonthField.setValue(null)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    editable: true,
                },
                {
                    path: 'largeImputations',
                    type: 'dtObjects',
                    hidden: false,
                    fields: [
                        {path: "organization", tKey: 'direction', display: 'name', translateName: true},
                        //{path: 'currency', type: 'dropDown'},
                        {path: "amount", type: "editText"},
                    ],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.amount)
                                })
                                const largeImputationsTotalAmountField = module.viewMap.form.fields.find( field => field.path === 'largeImputationsTotalAmount')
                                largeImputationsTotalAmountField.setValue(total)
                            }
                        }
                    }
                },
                {
                    path: 'largeImputationsTotalAmount',
                    disabled: true,
                },
                {
                    path: 'plafond',
                    tKey: "estimatedFinancialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                },
                {
                    path: 'indicatorKeys',
                    type: 'accordion',
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            'objectif'
                        ],
                        form: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            {path: 'objectif', required: true}
                        ]
                    }
                },
                {
                    path: 'comments',
                    //translateName: true,
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                            if ( newValue && oldValue && newValue.length !== oldValue.length ) {
                                let commentsCopy = _.cloneDeep(newValue)
                                const commentsSorted = commentsCopy.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss'))
                                const lastComment = commentsSorted[commentsSorted.length-1]
                                lastComment.date = moment().format("YYYY-MM-DD HH:mm:ss")
                                const comments = commentsSorted.slice(0, newValue.length - 1)
                                comments.push(lastComment)
                                commentsField.setValue(comments)
                            }
                        }
                    }
                },
                'files',
                {
                    path: 'delegateToCheckBox',
                    tKey: 'je souhaite déléguer ma décision',
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const delegateToField = module.viewMap.form.fields.find(field=>field.path==='delegateTo')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(delegateToField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    store.dispatch(setFormButtons([delegateButton, returnButton]))
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(delegateToField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path: 'delegateTo',
                    filters: [ 'isInGroupModel', 'isNotCurrentContributor' ],
                    clearable: false
                },
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'workflow',
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    hidden: true,
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {path: 'buttons', hidden: true, default: [draftButton, validateDemandeurButton, transmitButton, returnButton]},
                {
                    path: 'arbitrateTextList',
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            {path: 'user', display: 'name'
                            },
                            'step',
                            'order',
                            "arbitrationType",
                            "date"
                        ],
                        form: [
                            {
                                path: 'user',
                                display: 'name',
                            },
                            'step',
                            'order',
                            {path: 'motif', type: 'richTextEditor'},
                            "arbitrationType",
                            "date"
                        ]
                    },
                    hidden: true
                },
                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'category',
                    hidden: true
                }
            ],
            dataLists: [
                "Demand-Organization",
                "Demand-FiscalYears",
                "Demand-Currency"
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const demandNumberField = module.viewMap.form.fields.find( field => field.path === 'demandNumber')
                const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                const comments = !!commentsField && !!commentsField.getValue() ? commentsField.getValue() : []

                /*
                comments.forEach(comment => {
                    console.log('comment', comment)
                    const commentWords = comment.text.split(',')
                    const steps = ["draft", "pre-validation", "instruction", "control", "validation", "realization"]
                    const actions = ["save", "validate", "transmit", "revise", "refuse", "abandon", "delegate", "arbitrate"]
                    const isAutomaticComment = commentWords.length === 2 && steps.includes(commentWords[0].trim()) && actions.includes(commentWords[1].trim())
                    console.log('isAutomaticComment1', commentWords, isAutomaticComment)
                    if (isAutomaticComment) {
                        const translatedWords = commentWords.map(word => t(word.trim()))
                        const translatedComment = translatedWords.join(', ')
                        comment.text = translatedComment
                        console.log('isAutomaticComment', commentWords, translatedWords, translatedComment)
                    }
                })

                 */

                const plafondField = module.viewMap.form.fields.find( field => field.path === 'plafond')
                const demandNatureField = module.viewMap.form.fields.find( field => field.path === 'demandNature')
                const demandField = module.viewMap.form.fields.find( field => field.path === 'relatedProject')

                const startMonthField = module.viewMap.form.fields.find( field => field.path === 'startMonth')
                const endMonthField = module.viewMap.form.fields.find( field => field.path === 'endMonth')

                const arbitrationTypeField = module.viewMap.form.fields.find( field => field.path === 'arbitrationType')
                const delegationOptionField = module.viewMap.form.fields.find( field => field.path === 'delegationOption')
                const delegateToCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'delegateToCheckBox')
                const workFlowField = module.viewMap.form.fields.find( field => field.path === 'workflow')
                const statusField = module.viewMap.form.fields.find( field => field.path === 'status')
                const demandCategoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')
                const workflow = workFlowField.getValue()
                const arbitrationType = arbitrationTypeField.getValue()
                const hasNoDelegation = delegationOptionField.getValue() && delegationOptionField.getValue().id === "1"

                const organizationAndMeshField = module.viewMap.form.fields.find( field => field.path === 'organizationAndMesh')
                const contributorsFunctionsField = module.viewMap.form.fields.find( field => field.path === 'contributorsFunctions')
                const demandOrganization = organizationAndMeshField.getValue()
                const contributorsFunctions = contributorsFunctionsField.getValue()

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-preValidation.Demand_delegateTo",
                        store.getState,
                        'form',
                        {
                            data: {
                                demandOrganization : demandOrganization,
                                contributorsFunctions : contributorsFunctions
                            }
                        }
                    )
                )


                delegateToCheckBoxField.setValue(false)

                if ( objectMode === 'newObject' || (workflow && ["draft", "approved", "refused", "closed"].includes(workflow[0]) || hasNoDelegation)
                    //    || userIsInDelegateTo
                ) {
                    store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, false))
                }
                else {
                    store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, true))
                }
                store.dispatch(setFieldVisibility(demandCategoryField.id, false))
                store.dispatch(setFieldVisibility(statusField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(demandNumberField.id, objectMode !== 'newObject'))

                store.dispatch(changeFieldDisabled(plafondField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(organizationAndMeshField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(demandNatureField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(demandField.id, objectMode !== 'newObject'))

                const nonClosedFiscalYears = getDataListList(
                    state,
                    "Demand-FiscalYears"
                )
                if ( !!nonClosedFiscalYears && !!nonClosedFiscalYears.length){
                    const months = nonClosedFiscalYears.map( fiscalYear => moment(fiscalYear.fiscalYearRange[1]))
                    const endMonth = moment.max(months).add(1, 'months').format('YYYY-MM')
                    store.dispatch(setFieldEndDate(startMonthField.id, endMonth))
                    store.dispatch(setFieldEndDate(endMonthField.id, endMonth))
                }

                //moment.max(moments)
                if(objectMode === 'newObject') {
                    const categories = getDataListList(
                        state,
                        'm-SE-preValidation.Demand_demandCategory'
                    )
                    const projectCategory = categories.find(category => _.get(category, 'category.id') === '1')
                    demandCategoryField.setValue(projectCategory)
                }
                const referenceCurrencies = getDataListList(state, "Demand-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_largeImputationsTotalAmount", 'tKey', `${t("largeImputationsTotalAmount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_largeImputations.l_amount", 'tKey', `${t("amount")} (k${referenceCurrency.symbol})`))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "Demand-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_largeImputationsTotalAmount", 'tKey', `engagement estimé (k${referenceCurrency.symbol})`))
                }
            }
        },
    ],
    accesses: [
        {
            id: "Demand-Organization",
            entity: "Organization",
            fieldPath: ['id', 'code', 'name'],
            filters: ['attachedToOrganization']
        },
        {
            id: "Demand-FiscalYears",
            entity: "FiscalYear",
            //fieldPath: ['id', 'code', 'name'],
            filters: ['nonClosed']
        },
        {
            id: "Demand-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters : ['byModuleStep']
}
