import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {BoxList} from './BoxList'
import "./DualBox.css";

function isEmpty(str) {
  return (!str || 0 === str.length);
}

function valueMatchesTarget(value, target) {
  if (isEmpty(target)) return true
  const matchAsString =
      _.isString(value) &&
      value.toLowerCase().search(target.toLowerCase()) !== -1
  const matchAsNumber = _.isNumber(value) && value === _.parseInt(target)
  return matchAsString || matchAsNumber
}

function filterList(list, filterValue, options) {
  if(list.length === 0 || !filterValue) return list
  return list.filter(id => {
    return valueMatchesTarget(options[id].label, filterValue)
  })
}

class DualBoxList extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    alignText: PropTypes.string,
    fontSize: PropTypes.number,
    sizeCelulle: PropTypes.string,
    selected: PropTypes.array,
    nonSelected: PropTypes.array,
    onChange: PropTypes.func
  }

  constructor(props){
    super(props)
    this.state = {
      searchText1: "",
      searchText2: ""
    }
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }


  handleFilterChange(filter, val) {
    this.setState({[filter]: val})
  }


  addElement = element => {
    const { selected, onChange } = this.props;
    onChange(selected.concat([element.rowData.value]));
  };

  removeElement = element => {
    const { selected, onChange } = this.props;
    onChange(selected.filter(item => item !== element.rowData.value))
  };

  addAll = () => {
    const { selected, nonSelected, onChange, optionsById } = this.props
    const { searchText1 } = this.state

    const filteredNonSelectedList = filterList(nonSelected, searchText1, optionsById)
    onChange(selected.concat(filteredNonSelectedList))
  };

  removeAll = () => {
    const { selected, optionsById, onChange } = this.props
    const { searchText2 } = this.state

    const filteredSelectedList = filterList(selected, searchText2, optionsById)
    onChange(_.difference(selected, filteredSelectedList))
  };

  render() {
    const {selected, nonSelected, optionsById, path, height, required, disabled, t } = this.props;
    const { searchText1, searchText2} = this.state;

    const filteredNonSelectedList = filterList(nonSelected, searchText1, optionsById)
    const preFilteredSelectedList = selected.filter(id => {
      return !!optionsById[id]
    })
    const filteredSelectedList = filterList(preFilteredSelectedList, searchText2, optionsById)

    return (
      <div className={'DualBox-Container px-4'}>
        <div className='BoxList-container'>
          <label className="BoxList-label" >{t(path)} {required && '*'}</label>
          <BoxList
              path={path}
              height={height}
              disabled={disabled}
              columnLabel={'non sélectionné(s)'}
              position={'left'}
              tooltip={'clickToAdd'}
              handleRowClick={this.addElement}
              objects={filteredNonSelectedList.map( id => optionsById[id])}
              handleButtonCLick={this.addAll}
              filterValue={searchText1}
              handleFilterChange={(val) => this.handleFilterChange('searchText1', val)}
              t={t}
          />
        </div>
        <div className='BoxList-container'>
          <label className="BoxList-label" >{t(path)} {required && '*'}</label>
          <BoxList
              path={path}
              height={height}
              disabled={disabled}
              columnLabel={'sélectionné(s)'}
              position={'right'}
              tooltip={'clickToRemove'}
              handleRowClick={this.removeElement}
              objects={filteredSelectedList.map( id => optionsById[id])}
              handleButtonCLick={this.removeAll}
              filterValue={searchText2}
              handleFilterChange={(val) => this.handleFilterChange('searchText2', val)}
              t={t}
          />
        </div>
      </div>
    );
  }
}

export default DualBoxList;
