export const entity = {
    name: "Reason",
    facets: ["codeName", "translatedField"],
    fields: [
        {path: "coefficient", type: "integer"},
        {path: "automatic", type: "boolean"}
    ]
    ,
    filters: [
        {
            name: "manual",
            isDefault: false,
            query: () => ({automatic: false})
        }
    ]
}

export const module_ = {
    object: "Reason",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: "code", tKey: "code", width: 100},
            {path: "name", type: "translatedText", width: 200},
            {path: "coefficient", tKey: "coefficient", width: 50},
            "automatic"
        ],
        form: [
            "code",
            {path: "name", type: "textarea"},
            {path: "coefficient", default: 1, required: true, wholePositiveNumber: true},
            {path: "automatic", disabled: true}
        ]
    }
}
