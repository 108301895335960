import React from 'react'
import { Field } from 'redux-form'
import CursorField from '../../../../components/Form/CursorField'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input: {value, onChange}, language, label, required, subtitle, options, editable, disabled}) =>  {
    const translatedOptions = options.map(option => ({...option, name: translateName(option.name, language) }))
    const translatedLabel = translateName(label, language)
    const translatedSubtitle = translateName(subtitle, language)
    return (
        <div>
            <div style={{fontSize: '15px'}}>{translatedLabel} {required && '*'}</div>
            {subtitle && <div style={{fontStyle: 'italic', marginLeft: '5px', fontSize: '12px', color: '#676767'}}>{translatedSubtitle}</div>}
            <CursorField
                options={translatedOptions}
                value={value}
                onChange={onChange}
                disabled={disabled || !editable}
                language={language}
                display="name"
            />
        </div>
        )
}

const FormCursorField = ({ field, language}) => {
    return (
        <Field
            name={field.path}
            label={field.label}
            required={field.required}
            subtitle={field.subtitle}
            language={language}
            options={field.options}
            component={Input}
            editable={field.editable}
            disabled={field.disabled}
        />
    )
}

export default FormCursorField
