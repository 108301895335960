import { createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import api from '../../middleware/api'
import moduleMiddleware from '../../middleware/module'
import errorMiddleware from '../../middleware/error'
import loginRedirect from '../../middleware/loginRedirect'
import notificationsMiddleware from '../../middleware/notifications'
import family from '../../server/models/family'
import keenpointActions from '../../server/models/keenpointActions'
import slp from '../../server/models/slp.client'
import edfin from '../../server/models/edf-in'
import valodial from '../../server/models/valodial'
import authentication from '../../server/models/authentication'
import commitment from '../../server/models/commitment'
import uapl from '../../server/models/uapl.client'
import fncs from '../../server/models/fncs'
import cnda from '../../server/models/cnda'
import bpshop from '../../server/models/bpshop'
import rte from '../../server/models/rte'
import technical from '../../server/models/technical'
import suiviEngagement from "../../server/models/suiviEngagement";

import * as alerts from '../../server/models/crossModels/alerts'
import * as documents from '../../server/models/crossModels/documents'
import * as messaging from '../../server/models/crossModels/messaging'
import * as texting from '../../server/models/crossModels/texting'
import * as survey from '../../server/models/crossModels/survey'
import * as tickets from '../../server/models/crossModels/tickets'
import * as documentDb from '../../server/models/crossModels/documentDatabase'
import * as saveLog from '../../server/models/crossModels/saveLog'
import * as mediaBase from '../../server/models/crossModels/mediaBase'
import * as emailsQueue from '../../server/models/crossModels/emailsQueue'

import ClientEnhancer from '../../utils/ClientEnhancer'
import _ from "lodash";

const modelsList = [family, technical, slp, edfin, valodial, keenpointActions, authentication, uapl, commitment, fncs, cnda, bpshop, rte,
    //barberShop,
    suiviEngagement]

const elementsCrossModels = [alerts, documents, messaging, texting, survey, tickets, documentDb, saveLog, mediaBase, emailsQueue]

const models = modelsList.map(model => {
    return model.id === 'T'
        ? model
        : {
            ...model,
            entities: _.concat(
                model.entities,
                elementsCrossModels.flatMap(element => _.cloneDeep(element.entities))
            ),
            modules:
                _.concat(
                    model.modules,
                    elementsCrossModels.flatMap(element => _.cloneDeep(element.modules))
                )

        }
})

const enhanceStoreWithClientEnhancer = createStore => (...args) => {
    const store = createStore(...args)

    const clientEnhancer = new ClientEnhancer(models)

    return {
        ...store,
        clientEnhancer
    }
}

function applyMiddlewareCustom(middlewares, {setTheme}) {
    return createStore => (...args) => {
        const store = createStore(...args)
        let dispatch = () => {
            throw new Error(
                `Dispatching while constructing your middleware is not allowed. ` +
                `Other middleware would not be applied to this dispatch.`
            )
        }
        let chain

        const middlewareAPI = {
            getState: store.getState,
            dispatch: (...args) => dispatch(...args),
            clientEnhancer: store.clientEnhancer,
            setTheme
        }
        chain = middlewares.map(middleware => middleware(middlewareAPI))
        dispatch = compose(...chain)(store.dispatch)

        return {
            ...store,
            dispatch
        }
    }
}

export default function(name, reducer, params) {

    // if (process.env.NODE_ENV !== "production") {
    //     middlewares.push(createLogger())
    // }

    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  name,
                  actionsBlacklist: ['REDUX_STORAGE_SAVE']
              })
            : compose

    return createStore(
        enableBatching(reducer),
        composeEnhancers(
            applyMiddlewareCustom([thunk, api, moduleMiddleware, loginRedirect, errorMiddleware, notificationsMiddleware], params),
            enhanceStoreWithClientEnhancer
        )
    )
}
