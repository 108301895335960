import React from 'react'
import PropTypes from 'prop-types'

const FormButtons = ({ children }) => (
    <div className="form-group">
        <div style={{padding: '0 20px'}}>
            {children}
        </div>
    </div>
)

FormButtons.propTypes = {
    children: PropTypes.any.isRequired
}

export default FormButtons
