export const entity =  {
    name: 'HypothesisModelAssignment',
    fields: [
        { type: 'User', unique: true },
        {
            path: 'hypothesisModels',
            type: 'HypothesisModel',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'numberOfAssignedHypothesis',
            fieldPath: ['hypothesisModels.id'],
            f: function() {
                return this.hypothesisModels ? this.hypothesisModels.length : 0
            }
        }
    ]
}

export const module_ = {
    name: 'HypothesisModelAssignment',
    object: 'HypothesisModelAssignment',
    tKey: 'mTitle_hypothesisModelAssignment',
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    category: {
        path: 'assignment',
        icon: 'user-check'
    },
    viewMap: {
        dt: ['user', 'numberOfAssignedHypothesis'],
        form: [
            { path: 'user', editable: false },
            { path: 'hypothesisModels', display: 'translatedFullName', required: true }
        ]
    }
}
