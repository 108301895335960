import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
import _ from "lodash";
import {setFieldEdition} from "../../../../../apps/KpModule/actions";

const { setFieldListOptions, setFieldVisibility } = require('../../../../../apps/KpModule/actions/index')
const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')

export const detection = {
    name: "bp1",
    object: "BusinessProject",
    category: {
        path: "projects",
        icon: 'briefcase'
    },
    workflowActions: [
        'validate',
        'question',
        'noGo'
    ],
    step: "formX",
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", width: 200, initiallyNotVisible: true},
            {path: "typeOfOffer", width: 300, initiallyNotVisible: true},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "description", type: 'richText', width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},

            {path: "estimatedTurnover", tooltip: true, width: 80},
            {path: "expectedTurnOver", tooltip: true, width: 80},
            {path: "expectedContractCost", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "marginOverContract", initiallyNotVisible: true},
            {path: "expectedContractMarginInPercent", width: 80, initiallyNotVisible: true},
            {path: "estimatedNumberOfDays", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "expectedNumberOfDays", tooltip: true, width: 80, initiallyNotVisible: true},

            {path: "offerSubmissionDate", width: 150},
            {path: "creationDate", width: 150, initiallyNotVisible: true},
            {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", initiallyNotVisible: true},
            {path: "currentFunctions", width: 100},
            {path: "file", tKey: "FA"},

            {path: 'orangeStyledRow', hidden : true},
            {path: 'redStyledRow', hidden : true}
        ],
        form: {
            fields: [
                {
                    path: "country",
                    display: "fullInformations",
                    filters: ["inUserTeamAndHasWorkflowConfig"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            if (newValue && newValue.id) {
                                const refBusinessCountry = {refBusinessCountry: {id: newValue.id}}
                                const projectCountry = {country: {id: newValue.id}}
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-I-bp1.BusinessProject_businessProject",
                                        store.getState,
                                        'form',
                                        {data: refBusinessCountry}
                                    )
                                )
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-I-bp1.BusinessProject_delegatedUsers",
                                        store.getState,
                                        'form',
                                        {data: projectCountry}
                                    )
                                )

                            } else {

                                store.dispatch(setFieldListOptions("e_businessProject", []))
                                store.dispatch(setFieldListOptions("e_delegatedUsers", []))
                            }
                        }
                    }
                },
                {path: "typeOfOffer", display: "nameWithRange"},
                {path: "code", writable: false},
                "name",
                {path: "description", type: "richTextEditor", required: true},
                {path: "developerPitch", tKey: 'detectionDeveloperPitch', type: "richTextEditor", required: true},
                {path: "beginYear", sortList: false, editable: false},
                {path: "opportunitys", tKey: "opportunities", required: false},
                {path: "securityLevel", tKey: "securityRisk"},
                {path: "customer", display: "codeNameRelation", required: true},
                {path: "estimatedTurnover", editable: false, required: false},
                "globalTurnover",
                {path: "marginOverContract", required: true},
                {path: "resourceTypes", required: true},
                {path: "estimatedNumberOfDays", tKey: "estimatedNumberOfDaysForRealisation"},

                {path: "responseMode", required: true},
                {path: "offerSubmissionDate", required: true},
                {
                    path: "businessProject",
                    tKey: "relatedToProject",
                    autoList: false,
                    display: "name",
                    filters: ["byReferentialBusiness"],
                    fieldPath: [
                        "id",
                        "name",
                        "referentialBusinesss.country.id",
                        "referentialBusinesss.businessProject.id"
                    ]
                },
                {path: "projectFunding", required: true},
                {path: "compensationModes", required: true},
                {path: "interventionMode", required: true},
                {path: "contractualSchema", required: true},
                {path: "needLocalStructure"},
                {path: "partners", filters: ["partners"], required: false},
                {path: "subcontractors", filters: ["subcontractors"], required: false},
                {path: "competitors", filters: ["competitors"], required: false},
                {path: "buildOfferNumberOfDays", tKey: 'buildOfferNumberOfDaysDetection', required: true},
                {path: "originOfTheCase", required: true},
                "descriptionOfOrigin",

                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], required: true},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"], disabled: true},
                {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", filters: ["projectMonitor"]},

                {path: "comments", tKey: "comment_plural"},
                {path: "files", tKey: "file_plural"},

                {path:"delegatedUsers", filters:["byCountry"], fieldPath: ["id", "userName", "firstName", "lastName", "email"], display: "userName"},

                {path: "revisedTCA", tKey: "T.CA Révisé", wholePositiveNumber: true, required: true, default: 0},
                {path: "plannedCA", tKey: "CA Prévu (N)", wholePositiveNumber: true, required: true, default: 0},
                {path: "brOrderTakingMonth", tKey: 'orderTakingMonth', type: "monthPicker", required: true},
                {path: "probability", tKey: "Probabilité (%)", wholePositiveNumber: true, required: true, default: 0},
                {path: "plannedCAPlusOne", tKey: "CA Prévu (N+1)", wholePositiveNumber: true, required: true, default: 0},

                {path: "delegatedUsersGetter", hidden: true},
                {path: "buttons", hidden: true},
                {path: "workflow", hidden: true},
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}
                const objectMode = state.ui.objectMode

                const businessRevueFields = ['revisedTCA', 'plannedCA', 'brOrderTakingMonth', 'probability', 'plannedCAPlusOne']
                const businessProjectFields = ['beginYear', 'estimatedTurnover', "delegatedUsers"]

                businessRevueFields.forEach(field => store.dispatch(setFieldVisibility(`e_${field}`, objectMode === 'newObject')))
                businessProjectFields.forEach(field => store.dispatch(setFieldVisibility(`e_${field}`, objectMode !== 'newObject')))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-bp1.BusinessProject_delegatedUsers",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const delegatedUsers = _.get(state, 'edit.object.data.delegatedUsersGetter')
                const delegatedUsersField = module.viewMap.form.fields.find(field => field.id === "e_delegatedUsers")
                delegatedUsersField.setValue(delegatedUsers)
            }
        }
    ],
    filters: [
        "byTypeOfOffer",
        {query: () => ({
            $or: [
                {"workflow.step": "formX"},
                {"workflow": null},
                {"workflow": {$exists: false}}
            ]
        })}
    ]
}
