import { CALL_API, CALL_APIS_PARALLEL } from '../../../middleware/api'
import { initialize as reduxFormInitialize, getFormValues } from 'redux-form'
import { FILTER_FORM } from '../components/Filter/FilterForm'
import { CHART_FILTER_FORM } from '../components/Filter/ChartFilterForm'
import {
    getModule,
    getEdit,
    getUser,
    getChart,
    getList,
    getListById,
    getFilterObject,
    getLanguage,
    getDefaultFilterObject,
    getFilterDataListIds,
    getEditDataListIds,
    getDataList,
    getFilterFields,
    getFilteredSortedIds
} from '../selectors'
import {executeLoadSubscriptions} from './module'
import {doneFetchingFormDataLists, setObjectMode} from './index'
import {
    fetchFormObjectListeners,
    fetchDTListeners,
    fetchEditObjectListeners,
    fetchChartListeners
} from '../../../utils/socketAPI'

export const GET_OBJECT_REQUEST = 'GET_OBJECT_REQUEST'
export const GET_OBJECT_SUCCESS = 'GET_OBJECT_SUCCESS'
export const FAKE_GET_OBJECT_SUCCESS = 'FAKE_GET_OBJECT_SUCCESS'
export const GET_OBJECT_FAILURE = 'GET_OBJECT_FAILURE'

export const fetchFormObject = id => (dispatch, getState) => {
    const state = getState()
    const data = getFormValues(FILTER_FORM)(state)
    const { module, groupModel } = state
    const edit = getEdit(state)
    const user = getUser(state)

    const context = {
        accessId: edit.accessId,
        moduleId: module.id,
        groupModelId: groupModel ? groupModel.id : null,
        data,
        language: getLanguage(state)
    }

    if(module.useSocketsOnGet) {
        fetchFormObjectListeners(
            (type, data) => type === 'success'
                ? dispatch({type: GET_OBJECT_SUCCESS, data})
                : dispatch({type: GET_OBJECT_FAILURE, error: data.error}),
            `${user.id}${edit.accessId}`
        )
    }
    return dispatch({
        [CALL_API]: {
            types: [
                GET_OBJECT_REQUEST,
                module.useSocketsOnGet
                    ? FAKE_GET_OBJECT_SUCCESS
                    : GET_OBJECT_SUCCESS,
                GET_OBJECT_FAILURE
            ],
            method: 'GET',
            url: `/api/${module.accessUrl}/${id}`,
            params: context
        }
    })
}

const avoidEmptyObjects = o => (o && o.id ? o : null)

export const EXPORT_TABLE_REQUEST = 'EXPORT_TABLE_REQUEST'
export const EXPORT_TABLE_SUCCESS = 'EXPORT_TABLE_SUCCESS'
export const EXPORT_TABLE_FAILURE = 'EXPORT_TABLE_FAILURE'

export const exportTableObject = (title, columns, objects, user, protectedExport, language, groupModel) => (
    dispatch
) => {
    return dispatch({
        [CALL_API]: {
            types: [
                EXPORT_TABLE_REQUEST,
                EXPORT_TABLE_SUCCESS,
                EXPORT_TABLE_FAILURE
            ],
            method: 'POST',
            url: `/exportTable`,
            responseType: 'stream',
            params: {
                context: {
                    title,
                    fileName: `${title}.xlsx`,
                    columns,
                    objects,
                    protectedExport,
                    groupModelId: groupModel.id
                }
            }
        }
    })
}

export const EXPORT_STATE_REQUEST = 'EXPORT_STATE_REQUEST'
export const EXPORT_STATE_SUCCESS = 'EXPORT_STATE_SUCCESS'
export const EXPORT_STATE_FAILURE = 'EXPORT_STATE_FAILURE'

export const exportState = (title, columns, objects= []) => (
    dispatch
) => {
    return dispatch({
        [CALL_API]: {
            types: [
                EXPORT_STATE_REQUEST,
                EXPORT_STATE_SUCCESS,
                EXPORT_STATE_FAILURE
            ],
            method: 'POST',
            url: `/exportState`,
            responseType: 'stream',
            params: {
                context: {
                    title,
                    fileName: `${title}.xlsx`,
                    columns,
                    objects
                }
            }
        }
    })
}

export const ACTION_OBJECT_REQUEST = 'ACTION_OBJECT_REQUEST'
export const ACTION_OBJECT_SUCCESS = 'ACTION_OBJECT_SUCCESS'
export const ACTION_OBJECT_FAILURE = 'ACTION_OBJECT_FAILURE'

export const applyAction = (action) => (dispatch, getState) => {
    const state = getState()
    const { module, groupModel } = state
    const list = getList(state)
    const listById = getListById(state)
    const edit = getEdit(state)
    const filteredSortedIds = getFilteredSortedIds(state)
        .filter( id => listById[id].selected)

    const actions = filteredSortedIds.map( id => ({
        id: id,
        accessId: list.accessId,
        [CALL_API]: {
            types: [
                ACTION_OBJECT_REQUEST,
                ACTION_OBJECT_SUCCESS,
                ACTION_OBJECT_FAILURE
            ],
            method: 'PUT',
            url: `/api/${module.accessUrl}/${id}`,
            params: {
                o: {id},
                context: {
                    accessId: edit.accessId,
                    moduleId: module.id,
                    groupModelId: groupModel ? groupModel.id : null,
                    language: getLanguage(state),
                    action: action,
                    objectWithoutModification: true
                }
            },
            transpose: avoidEmptyObjects
        }
    }))

    return dispatch({[CALL_APIS_PARALLEL]: actions})
}

export const CREATE_OBJECT_REQUEST = 'CREATE_OBJECT_REQUEST'
export const CREATE_OBJECT_SUCCESS = 'CREATE_OBJECT_SUCCESS'
export const FAKE_CREATE_OBJECT_SUCCESS = 'FAKE_CREATE_OBJECT_SUCCESS'
export const CREATE_OBJECT_FAILURE = 'CREATE_OBJECT_FAILURE'

export const createObject = (object, context = {}) => (
    dispatch, getState
) => {
    const state = getState()
    const data = getFilterObject(state)
    const { module, groupModel } = state
    const list = getList(state)
    const edit = getEdit(state)
    const user = getUser(state)

    if(module.useSocketsOnSave) {
        fetchEditObjectListeners(
            (type, data) => type === 'success'
                ? dispatch({type: CREATE_OBJECT_SUCCESS, data})
                : dispatch({type: CREATE_OBJECT_FAILURE, error: data.error}),
            `${user.id}${edit.accessId}`
        )
    }
    return dispatch({
        accessId: list.accessId,
        [CALL_API]: {
            types: [
                CREATE_OBJECT_REQUEST,
                module.useSocketsOnSave
                    ? FAKE_CREATE_OBJECT_SUCCESS
                    : CREATE_OBJECT_SUCCESS,
                CREATE_OBJECT_FAILURE
            ],
            method: 'POST',
            url: `/api/${module.accessUrl}`,
            params: {
                o: object,
                context: {
                    accessId: edit.accessId,
                    moduleId: module.id,
                    groupModelId: groupModel ? groupModel.id : null,
                    data,
                    language: getLanguage(state),
                    ...context
                }
            },
            transpose: avoidEmptyObjects
        }
    })
}

export const SAVE_OBJECT_REQUEST = 'SAVE_OBJECT_REQUEST'
export const SAVE_OBJECT_SUCCESS = 'SAVE_OBJECT_SUCCESS'
export const FAKE_SAVE_OBJECT_SUCCESS = 'FAKE_SAVE_OBJECT_SUCCESS'
export const SAVE_OBJECT_FAILURE = 'SAVE_OBJECT_FAILURE'

export const saveFormObject = (object, context = {}) => (
    dispatch,
    getState
) => {
    const state = getState()
    const data = getFilterObject(state)
    const { module, groupModel } = state
    const list = getList(state)
    const edit = getEdit(state)
    const user = getUser(state)

    if(module.useSocketsOnSave) {
        fetchEditObjectListeners(
            (type, data) => type === 'success'
                ? dispatch({type: SAVE_OBJECT_SUCCESS, data})
                : dispatch({type: SAVE_OBJECT_FAILURE, error: data.error}),
            `${user.id}${edit.accessId}`
        )
    }
    return dispatch({
        id: object.id,
        accessId: list.accessId,
        [CALL_API]: {
            types: [
                SAVE_OBJECT_REQUEST,
                module.useSocketsOnSave
                    ? FAKE_SAVE_OBJECT_SUCCESS
                    : SAVE_OBJECT_SUCCESS,
                SAVE_OBJECT_FAILURE
            ],
            method: 'PUT',
            url: `/api/${module.accessUrl}/${object.id}`,
            params: {
                o: object,
                context: {
                    accessId: edit.accessId,
                    moduleId: module.id,
                    groupModelId: groupModel ? groupModel.id : null,
                    data,
                    language: getLanguage(state),
                    ...context
                }
            },
            transpose: avoidEmptyObjects
        }
    })
}

export const DELETE_OBJECT_REQUEST = 'DELETE_OBJECT_REQUEST'
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS'
export const DELETE_OBJECT_FAILURE = 'DELETE_OBJECT_FAILURE'

export const deleteObject = id => (dispatch, getState) => {
    const state = getState()
    const { module, groupModel } = state
    const list = getList(state)

    const context = {
        accessId: list.deleteAccessId,
        moduleId: module.id,
        groupModelId: groupModel ? groupModel.id : null,
        language: getLanguage(state)
    }

    return dispatch({
        id,
        accessId: list.accessId,
        [CALL_API]: {
            types: [
                DELETE_OBJECT_REQUEST,
                DELETE_OBJECT_SUCCESS,
                DELETE_OBJECT_FAILURE
            ],
            method: 'DELETE',
            url: `/api/${module.accessUrl}/${id}`,
            params: context
        }
    })
}

export const deleteObjects = () => (dispatch, getState) => {
    const state = getState()
    const { module, groupModel } = state
    const list = getList(state)
    const listById = getListById(state)
    const edit = getEdit(state)
    const filteredSortedIds = getFilteredSortedIds(state)

    const context = {
        accessId: edit.deleteAccessId,
        moduleId: module.id,
        groupModelId: groupModel ? groupModel.id : null,
        language: getLanguage(state)
    }

    const actions = filteredSortedIds
        .filter( id => listById[id].selected)
        .map( id => ({
            id,
            accessId: list.accessId,
            [CALL_API]: {
            types: [
                DELETE_OBJECT_REQUEST,
                DELETE_OBJECT_SUCCESS,
                DELETE_OBJECT_FAILURE
            ],
                method: 'DELETE',
                url: `/api/${module.accessUrl}/${id}`,
                params: context
            }
        }))
    return dispatch({[CALL_APIS_PARALLEL]: actions})
}

export const DT_REQUEST = 'DT_REQUEST'
export const DT_SUCCESS = 'DT_SUCCESS'
export const FAKE_DT_SUCCESS = 'FAKE_DT_SUCCESS'
export const DT_FAILURE = 'DT_FAILURE'

export const fetchDtData = moduleId => (dispatch, getState) => {
    const state = getState()
    const {module, groupModel} = state

    if (moduleId && moduleId !== module.id) {
      console.log(`No need to fetch DT data, module has changed`)
    } else {
        const data = getFormValues(FILTER_FORM)(state)
        const list = getList(state)
        const user = getUser(state)

        const context = {
            accessId: list.accessId,
            moduleId: module.id,
            groupModelId: groupModel ? groupModel.id : null,
            data,
            language: getLanguage(state)
        }

        if(module.useSocketsOnFind) {
            fetchDTListeners(
                (type, data) => type === 'success'
                    ? dispatch({type: DT_SUCCESS, data})
                    : dispatch({type: DT_FAILURE, error: data.error}),
                `${user.id}${list.accessId}`
            )
            return dispatch({
                accessId: list.accessId,
                [CALL_API]: {
                    types: [DT_REQUEST, FAKE_DT_SUCCESS, DT_FAILURE],
                    method: 'GET',
                    url: `/api/${module.accessUrl}`,
                    params: context
                }
            })
        } else {
            return dispatch({
                accessId: list.accessId,
                [CALL_API]: {
                    types: [DT_REQUEST, DT_SUCCESS, DT_FAILURE],
                    method: 'GET',
                    url: `/api/${module.accessUrl}`,
                    params: context
                }
            })
        }
    }
}

export const fetchFilterFieldsLists = (type = 'form') => (dispatch, getState) => {
    const state = getState()
    const filterFieldsWithList = getFilterDataListIds(state)

    dispatch({
        [CALL_APIS_PARALLEL]: [
            ...filterFieldsWithList,
        ].map(id => generateFetchFieldListAction(id, getState, type))
    })
}

export const CHART_REQUEST = 'CHART_REQUEST'
export const CHART_SUCCESS = 'CHART_SUCCESS'
export const FAKE_CHART_SUCCESS = 'FAKE_CHART_SUCCESS'
export const CHART_FAILURE = 'CHART_FAILURE'

export const fetchChartData = () => (dispatch, getState) => {
    const state = getState()
    const { module, groupModel } = state

    const data = getFormValues(CHART_FILTER_FORM)(state)
    const chart = getChart(state)
    const user = getUser(state)

    const context = {
        accessId: chart.accessId,
        moduleId: module.id,
        groupModelId: groupModel ? groupModel.id : null,
        data,
        language: getLanguage(state)
    }

    if(module.useSocketsOnFind) {
        fetchChartListeners(
            (type, data) => type === 'success'
                ? dispatch({type: CHART_SUCCESS, data})
                : dispatch({type: CHART_FAILURE, error: data.error}),
            `${user.id}${chart.accessId}`
        )
        return dispatch({
            accessId: chart.accessId,
            [CALL_API]: {
                types: [CHART_REQUEST, FAKE_CHART_SUCCESS, CHART_FAILURE],
                method: 'GET',
                url: `/api/${module.accessUrl}`,
                params: context
            }
        })
    } else {
        return dispatch({
            accessId: chart.accessId,
            [CALL_API]: {
                types: [CHART_REQUEST, CHART_SUCCESS, CHART_FAILURE],
                method: 'GET',
                url: `/api/${module.accessUrl}`,
                params: context
            }
        })
    }
}

export const fetchInitialChartData = () => (dispatch, getState) => {
    const state = getState()
    const filterFields= getFilterFields(state)

    if(filterFields.length) {
        const defaultFilterValues = getDefaultFilterObject(state)
        dispatch(reduxFormInitialize(CHART_FILTER_FORM, defaultFilterValues))
    }
    dispatch(fetchChartData())
}

export const fetchInitialDtData = () => (dispatch, getState) => {
    const state = getState()
    const filterFields= getFilterFields(state)

    if(filterFields.length) {
        const defaultFilterValues = getDefaultFilterObject(state)
        dispatch(reduxFormInitialize(FILTER_FORM, defaultFilterValues))
    }
    dispatch(fetchDtData())
}

export const FIELD_LIST_REQUEST = 'FIELD_LIST_REQUEST'
export const FIELD_LIST_SUCCESS = 'FIELD_LIST_SUCCESS'
export const FIELD_LIST_FAILURE = 'FIELD_LIST_FAILURE'

export const generateFetchFieldListAction = (dataListId, getState, type, params = {}) => {
    const state = getState()
    const { module, groupModel } = state
    const dataList = getDataList(state, dataListId)
    const form = type === 'form' ? FILTER_FORM : CHART_FILTER_FORM
    const data = getFormValues(form)(state)

    return {
        id: dataList.id,
        [CALL_API]: {
            types: [FIELD_LIST_REQUEST, FIELD_LIST_SUCCESS, FIELD_LIST_FAILURE],
            method: 'GET',
            url: `/api/${dataList.url}`,
            params: {
                accessId: dataList.id,
                moduleId: module.id,
                groupModelId: groupModel ? groupModel.id : null,
                language: getLanguage(state),
                data,
                ...params
            }
        }
    }
}

export const fetchBasicData = () => (dispatch, getState) => {
    const state = getState()
    const module = getModule(state)

    const formFieldsWithList = getEditDataListIds(state)
    const filterFieldsWithList = getFilterDataListIds(state)

    dispatch({
        [CALL_APIS_PARALLEL]: formFieldsWithList.map(id => generateFetchFieldListAction(id, getState))
    }).then(() => {
        dispatch(doneFetchingFormDataLists())
    })

    dispatch({
        [CALL_APIS_PARALLEL]: filterFieldsWithList.map(id => generateFetchFieldListAction(id, getState))
    }).then(() => {

        const state = getState()
        //const filterFieldsWithList = getFilterDataListIds(state)
        const filterFields = getFilterFields(state)

        if(filterFields.length) {
            const defaultFilterValues = getDefaultFilterObject(state)
            if(module.defaultPanel === 'chart') {
                dispatch(reduxFormInitialize(CHART_FILTER_FORM, defaultFilterValues))

            } else if(module.defaultPanel === 'list') {
                dispatch(reduxFormInitialize(FILTER_FORM, defaultFilterValues))

                /*
                dispatch({
                    [CALL_APIS_PARALLEL]: [
                        ...filterFieldsWithList
                    ].map(id => generateFetchFieldListAction(id, getState))
                })
                 */
            }
        }
        if(module.defaultPanel === 'list') {
            dispatch(fetchDtData())
        }
        if(module.defaultPanel === 'chart') {
            dispatch(fetchChartData())
        }
        if(module.defaultPanel === 'form') {

            dispatch(fetchDtData()).then(response => {
                const id = response.data[0] && response.data[0]['id']

                if(id) {

                    dispatch(fetchFormObject(id)).then(({ error }) => {
                        if(!error) dispatch(executeLoadSubscriptions())
                        dispatch(setObjectMode('editObject'))
                    })
                } else {
                    dispatch(setObjectMode('newObject'))
                }
            })
        }
    })
}

export const FETCH_LIST_REQUEST = 'FETCH_LIST_REQUEST'
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'FETCH_LIST_FAILURE'

export const fetchList = (data, moduleId, moduleAccessUrl, accessId) => (dispatch, getState) => {
    const state = getState()
    const { groupModel } = state

    const context = {
        accessId,
        moduleId,
        groupModelId: groupModel ? groupModel.id : null,
        data,
        language: getLanguage(state)
    }

    return dispatch({
        accessId,
        [CALL_API]: {
            types: [FETCH_LIST_REQUEST, FETCH_LIST_SUCCESS, FETCH_LIST_FAILURE],
            method: 'GET',
            url: `/api/${moduleAccessUrl}`,
            params: context
        }
    })
}
