import React, {Component} from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'


const randomColorGenerator = () => '#' + Math.floor(Math.random()*16777215).toString(16)

class SimpleBarChart extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const {h ,w, data=[], dataKeys, defaultKeys, className ='SimpleBarChart', t = k => k, language, absolute, useColorsByCashier, useColorsByShop, axisHeight = 60} = this.props

        let margin = { top: 20, right: 20, bottom: axisHeight, left: 20 }

        const keys = dataKeys || defaultKeys

        let colorsByKey = useColorsByCashier
            ? JSON.parse(localStorage.getItem('colorsByCashier')) || {}
            : useColorsByShop
                ? JSON.parse(localStorage.getItem('colorsByShop')) || {}
                : {}


        if(useColorsByCashier || useColorsByShop) {
            data.forEach(object => {
                if(!colorsByKey[object[keys[0]]]) {
                    /*
                    const unusedColor = (useColorsByCashier ? cashierColors : shopColors ).find(color => {
                        return Object.keys(colorsByKey).every(key => colorsByKey[key] !== color )
                    })
                     */
                    colorsByKey[object[keys[0]]] = randomColorGenerator()
                }
            })

            localStorage.setItem(useColorsByCashier ? 'colorsByCashier' : 'colorsByShop',
                JSON.stringify(colorsByKey)
            )
        }

        const width = w - margin.left -margin.right
        const height = h - margin.top - margin.bottom

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.3);

        const y = d3.scaleLinear()
            .rangeRound([height, 0])


        const xAxis = d3.axisBottom()
            .scale(x)


        // d3.select(`.${className}`).select('svg').select('g').remove()

        const svg = select(`.${className}`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)


        x.domain(data.map(d => (d[keys[0]])))


        y.domain([0, d3.max(data, d => Math.abs(d[keys[1]]))]).nice();

        const bars = svg
            .select(".bars")
            .selectAll("rect")
            .data(data)

        const values = svg
            .select(".values")
            .selectAll("text")
            .data(data)

        bars.exit()
            .remove()

        bars.enter().append("rect")
            .attr("x", function(d) { return x(d[keys[0]]); })
            .attr("width", x.bandwidth())
            .attr('height', 0)
            .attr('y', height)
            .transition()
            .duration(1000)
            .attr("y", function(d) {
                return absolute
                    ? y(Math.abs(d[keys[1]]))
                    : d[keys[1]] > 0 ? y(d[keys[1]]) : height
            })
            .attr("height", function(d) { return Math.abs(height - y(d[keys[1]])) })
            .attr("fill", d => (d.color || colorsByKey[d[keys[0]]] || '#ff6361'))

        bars
            .attr("width", x.bandwidth())
            .attr("height", function(d) { return Math.abs(height - y(d[keys[1]])) })
            .attr("x", function(d) { return x(d[keys[0]]); })
            .attr("y", function(d) {
                return absolute
                    ? y(Math.abs(d[keys[1]]))
                    : d[keys[1]] > 0 ? y(d[keys[1]]) : height
            })
            .attr("fill", d => (d.color || colorsByKey[d[keys[0]]] || '#ff6361'))


        values.exit()
            .remove()

        values.enter().append("text")
            .attr("x", function(d) { return x(d[keys[0]]) + x.bandwidth()/2; })
            .attr("y", height)
            .transition()
            .duration(1000)
            .attr("y", function(d) {
                return absolute
                    ? y(Math.abs(d[keys[1]])) - 10
                    : d[keys[1]] > 0 ? y(d[keys[1]]) - 10 : y(d[keys[1]]) + 10
            })
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")
            .text(d => absolute ? Math.abs(d[keys[1]]) : d[keys[1]]);

        values
            .attr("x", function(d) { return x(d[keys[0]]) + x.bandwidth()/2; })
            .attr("y", function(d) {
                return absolute
                    ? y(Math.abs(d[keys[1]])) - 10
                    : d[keys[1]] > 0 ? y(d[keys[1]]) - 10 : y(d[keys[1]]) + 10
            })
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")
            .text(d => absolute ? Math.abs(d[keys[1]]) : d[keys[1]]);

        svg.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("y", 5)
            .attr("x", -6)
            .attr("dy", ".35em")
            .attr("transform", "rotate(-55)")
            .style("text-anchor", "end")
            .call(function(k){
                k.each(function(d){ // for each one
                    const self = d3.select(this);
                    const s = self.text().split(';')  // get the text and split it
                    if(s.length !== 1){
                        self.text(''); // clear it out
                        for(let i=0; i< s.length; i++){
                            self.append("tspan") // insert tspan for every bit
                                .attr("y", i*12)
                                .attr("x", -9)
                                .attr("dy",".35em")
                                .text(t(s[i]))
                        }
                    }

                })
            })

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars"/>
                        <g className="values"/>
                        <g className="axis axis--x"/>
                    </g>
                </svg>
            </div>
        )
    }
}

export default SimpleBarChart;
