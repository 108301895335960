import {change} from "redux-form";
import {generateFetchFieldListAction} from "../../../../apps/KpModule/actions/api";
import {basicContext} from "../../../utils/contextUtils";
import {setFieldVisibility, setFormButtons} from "../../../../apps/KpModule/actions";
import {decrypt} from "../../../utils/crypto";
import path from "path";
const Errors = require("../../../utils/Errors").default
import async from 'async'
const _ = require('lodash')
import {
    getRoleWorkflowConfigs,
    getStepWorkflowConfig,
    thereIsAtLeastOneInDemand,
    thereIsAtLeastOneInValidation,
    organizationsHaveSameAxis,
    thereIsAtLeastOneInPreValidation
} from "../utils/workflowUtils";
import {generateMaxOrder, generateOrderOfSteps, correctStepAndOrder, getPreviousStepPreviousOrder} from "../utils/workflowUtils";
import {areOrdersContinuous, ordersVerification, ordersAreStrictPositive} from "../utils/workflowUtils";
import {substractedItems, getNextStepStatus, getStatusName2} from "../utils/workflowUtils";
import {
    arbitrateButton,
    draftButton,
    returnButton, transmitButton,
    validateDemandeurButton,
    warnAndValidateButton
} from "../utils/demandUtils";


const steps = [ "preValidation", "demand", "control", "validation", "realization"]

//workFlow.workFlowConfigs =  [ {role : {}, step:'step1', stepOrder:1}, [{role : {}, step:'step1', stepOrder:1} ]

export const entities = [
    {
        name: 'WorkFlow',
        fields: [
            {path : 'organizationAndMesh', type :'OrganizationAndMesh', link: 'MTM'},
            {path: 'demandCategory', type:'DemandCategory', link:'MTO'},
            {path: 'demandNature', type:'DemandNature', link:'MTM'},
            {path: 'minMaxAmount', type: 'MinMaxAmount', link: 'MTM'},
            {
                type: "ArbitrationType",
                nullable: true
            },
            {
                type: "DelegationOption",
            },
            {
                //path: 'ManualWorkFlowConfigs',
                type: 'ManualWorkFlowConfig',
                lazy: true,
                fieldPath: [
                    'workFlowConfigs.id',
                    'workFlowConfigs.role.id',
                ],
                list: true,
                $f: (workFlow,context,callback) => callback(null, []),
                $s: ( manualWorkFlowConfigs,workFlow, context, callback) => {
                    const configsAndReaders = {'configs' :[], 'readers': [], 'arbitrators': []}
                    manualWorkFlowConfigs.forEach(config => {
                        const role = config.role
                        if (config['reading']){
                            configsAndReaders['readers'].push(
                                role
                            )
                        }
                        if (config['arbitration']){
                            configsAndReaders['arbitrators'].push(
                                role
                            )
                        }
                        //const previousReaders = workFlow.readers.filter( reader => reader.role.id === role.id)
                        const previousRoleConfigs = getRoleWorkflowConfigs(workFlow.workFlowConfigs, role)
                        steps.forEach(step => {
                            if(config[step]) {    //only checked steps on the dataTable
                                const previousStepConfig = getStepWorkflowConfig(previousRoleConfigs, step) || {}
                                configsAndReaders['configs'].push({
                                    ...previousStepConfig,
                                    role,
                                    step: step,
                                    order: config[`${step}Order`],
                                })
                            }
                        })
                    })
                    workFlow.workFlowConfigs = configsAndReaders['configs']
                    workFlow.readers = configsAndReaders['readers']
                    workFlow.arbitrators = configsAndReaders['arbitrators']
                    callback()
                }
            },
            {
                path: 'workFlowConfigs',
                type:'WorkFlowConfig',
                link: {
                    type: "OTM",
                    onParent: true,     //parent est le workFlow
                    onChild: false,     //child est WorkFlowConfig
                }
            },
            {
                path: 'readers',
                type:'Role',
                link: 'MTM'
            },
            {
                path: 'arbitrators',
                type:'Role',
                link: 'MTM'
            }
        ],
        uniqueProcess: function ( workflowID, configs, organizationsList, demandCategory, demandNatureList, minMaxList, context, callback) {
            const organizationsIDsList = organizationsList.map(organization => global.ObjectID(organization.id))
            const demandCategoryID = global.ObjectID(demandCategory.id)
            const demandNatureIDsList = demandNatureList.map( type => global.ObjectID(type.id))
            const minMaxIDsList = minMaxList.map( minMax => global.ObjectID(minMax.id))
            global.app.SE.WorkFlow.find({
                ...basicContext(context),
                fieldPath: [],
                query: {
                    "_id": {$ne: global.ObjectID(workflowID)},
                    "organizationAndMesh": {$elemMatch: { $in: organizationsIDsList }},
                    "demandCategory": {$eq: demandCategoryID },
                    "demandNature": {$elemMatch: { $in: demandNatureIDsList }},
                    "minMaxAmount": {$elemMatch: { $in: minMaxIDsList }}
                }
            }, (e, combinations) => {
                const isWorkflowForProject = demandCategory.category.id === '1'
                if(combinations && combinations.length !== 0) {
                    //and normally it should be only one combination
                    callback(new Errors.ValidationError('this combination already exists'))
                }
                else if (!configs.length) {
                    callback(new Errors.ValidationError('The workflow cannot be empty'))
                }
                else if (!ordersAreStrictPositive(configs)){
                    callback(new Errors.ValidationError('Orders should be greater or equal to 1'))
                }
                else if ( !thereIsAtLeastOneInPreValidation(configs) && isWorkflowForProject) {
                    callback(new Errors.ValidationError('The workflow should have at least one function checked in Pre-validation'))
                }
                else if ( !thereIsAtLeastOneInDemand(configs)) {
                    callback(new Errors.ValidationError('The workflow should have at least one function checked in Instruction'))
                }
                else if (!thereIsAtLeastOneInValidation(configs)) {
                    callback(new Errors.ValidationError('The workflow should have at least one function checked in Validation '))
                }
                else if (!areOrdersContinuous(configs)){
                    callback(new Errors.ValidationError('Orders should be continuous and start from 1'))
                }
                else if (!areOrdersContinuous(configs)){
                    callback(new Errors.ValidationError('Orders should be continuous and start from 1'))
                }
                else if (!organizationsHaveSameAxis(organizationsList)){
                    callback(new Errors.ValidationError('Selected organizations should belong to the same axis'))
                }
                else return callback()
            })


        },
        validateSave: function (newObject, oldObject, context, callback) {
            this.uniqueProcess(newObject.id, newObject.workFlowConfigs, newObject.organizationAndMesh, newObject.demandCategory, newObject.demandNature, newObject.minMaxAmount, context, callback)
        },
        beforeSave: function(newObject, oldObject, context, callback) {
            const arbitrationTypeWasModified = (!oldObject && newObject) || (oldObject && newObject && newObject.arbitrationType !== oldObject.arbitrationType) ? {arbitrationType: newObject.arbitrationType} : {}
            const delegationOptionWasModified = (!oldObject && newObject) || (oldObject && newObject && newObject.delegationOption !== oldObject.delegationOption) ? {delegationOption: newObject.delegationOption} : {}
            let rolesAddedToReading = (!oldObject && newObject) ? newObject.readers.map( role => global.ObjectID(role.id)) : substractedItems(newObject.readers.map( role => global.ObjectID(role.id)), oldObject.readers.map( role => global.ObjectID(role.id)))
            let rolesRemovedFromReading = (!!oldObject && !!newObject) ? substractedItems(oldObject.readers.map( role => global.ObjectID(role.id)), newObject.readers.map( role => global.ObjectID(role.id))) : []
            const ORGS = newObject.organizationAndMesh.map( org => org.name )
            const CATEGORY = newObject.demandCategory.name
            const NATURES = newObject.demandNature.map( nature => nature.name )
            const PLAGES = newObject.minMaxAmount.map( minMax => minMax.code )
            global.app.SE.Demand.find(
                {
                    ...basicContext(context),
                    fieldPath: ["delegateTo.id", "status.id"],
                    query: {
                        organizationAndMesh: { $in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id)) },
                        demandCategory: { $eq: global.ObjectID(newObject.demandCategory.id) },
                        demandNature: { $in: newObject.demandNature.map( type => global.ObjectID(type.id)) },
                        plafond: { $in: newObject.minMaxAmount.map( plafond => global.ObjectID(plafond.id)) }
                    }
                }, (e, demands) => {
                    if(e) return callback(e)
                    let usersRemovedFromDelegateTo = {}   // { mail: ["demands.ids"]... }
                    let usersRemovedFromDelegateTo2 = {}
                    if ( demands && !!demands.length ){
                        let usersRemovedFromStepOrder = {}    // { role: [['demands.ids', 'step', 'order'], ...], ... }
                        let arbitratorsAddedToStepOrder = {}
                        let arbitratorsRemovedFromStepOrder = {}    // { role: [['demands.ids', 'step', 'order'], ...], ... }
                        let usersAddedToStepOrder = {}
                        async.series(demands.filter( demand => !["6", "8", "9", "5"].includes(demand.status.id) ).map(demand => callback => {
                            let statusUpdate = {}
                            //let delegationOption = {}
                            let delegationOption2 = { delegateTo: [] }
                            /*
                            if ( delegationOptionWasModified ){
                                delegationOption = demand.status.id === '1' || newObject.delegationOption.id === "2" ? { delegationOption: newObject.delegationOption } : { delegationOption: newObject.delegationOption, delegateTo: [] }
                            }
                             */
                            //draft case
                            let [correctStep, correctOrder] = demand.workflow
                            let correctConfigs = []
                            let correctRolesIDs = []
                            let correctArbitrators = []
                            let correctStatusID = demand.status.id
                            const correctConsultantsFunctions = newObject.workFlowConfigs.map(conf => global.ObjectID(conf.role.id))
                            const correctAllArbitratorsFunctions = newObject.arbitrators.map(arbiter => global.ObjectID(arbiter.id))

                            if ( demand.status.id === '4'){//information request
                                let configsExists = !!newObject.workFlowConfigs.filter(conf => conf.step === correctStep && conf.order === parseInt(correctOrder)).length
                                while ( !configsExists ){
                                    [correctStep, correctOrder] = getPreviousStepPreviousOrder(correctStep, parseInt(correctOrder), newObject.workFlowConfigs)
                                    configsExists = !!newObject.workFlowConfigs.filter(conf => conf.step === correctStep && conf.order === parseInt(correctOrder)).length
                                }
                                correctConfigs = newObject.workFlowConfigs.filter(conf => conf.step === correctStep && conf.order === parseInt(correctOrder))
                                correctRolesIDs = correctConfigs.map(conf => global.ObjectID(conf.role.id))
                            }
                            else if ( demand.status.id === "11" ) {// different arbitration
                                correctArbitrators = newObject.arbitrators.map(arbiter => global.ObjectID(arbiter.id))
                            }
                            else if ( !["1"].includes(demand.status.id)) {// different from draft
                                [correctStep, correctOrder] = correctStepAndOrder( demand.workflow[0], parseInt(demand.workflow[1]), newObject.workFlowConfigs, demand.alreadyTreatedByFunctions )
                                correctConfigs = newObject.workFlowConfigs.filter(conf => conf.step === correctStep && conf.order === parseInt(correctOrder))
                                correctRolesIDs = correctConfigs.map(conf => global.ObjectID(conf.role.id))
                                statusUpdate = { status: getNextStepStatus(correctStep) }
                                correctStatusID = getNextStepStatus(correctStep)
                            }
                            /*
                            if ( !!delegationOption.delegateTo && !!demand.delegateTo.length ){
                                demand.delegateTo.forEach( user => {
                                    if ( !!usersRemovedFromDelegateTo[user.mail] ){
                                        usersRemovedFromDelegateTo[user.mail].push(demand.demandNumber)
                                    }
                                    else{
                                        usersRemovedFromDelegateTo[user.mail] = [demand.demandNumber]
                                    }
                                })
                            }
                             */
                            if ( !!demand.delegateTo.length ){
                                demand.delegateTo.forEach( user => {
                                    if ( !!usersRemovedFromDelegateTo2[user.mail] ){
                                        usersRemovedFromDelegateTo2[user.mail].push([demand.demandNumber, demand.status.name2])
                                    }
                                    else{
                                        usersRemovedFromDelegateTo2[user.mail] = [[demand.demandNumber, demand.status.name2]]
                                    }
                                })
                            }
                            let addedRoles = substractedItems(correctRolesIDs, demand.contributorsFunctions)
                            let removedRoles = substractedItems(demand.contributorsFunctions, correctRolesIDs)
                            const addedArbitratorsRoles = substractedItems(correctArbitrators, demand.arbitratorsFunctions)
                            const removedArbitratorsRoles = substractedItems(demand.arbitratorsFunctions, correctArbitrators)
                            addedRoles = addedRoles.concat(addedArbitratorsRoles)
                            removedRoles = removedRoles.concat(removedArbitratorsRoles)
                            async.parallel([
                                callback => {
                                    if ( !addedRoles.length ){
                                        callback(null, [])
                                    }
                                    else{
                                        global.app.SE.Habilitation.find({
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: addedRoles.map( id => global.ObjectID(id))},
                                                $or: [ {"grantedAccess": [],"grantedMesh": [] }, {"grantedAccess": {$elemMatch: { $eq: global.ObjectID(demand.organizationAndMesh)} }, "grantedMesh":[] }, {"grantedAccess": [] , "grantedMesh":{$elemMatch: { $eq: global.ObjectID(demand.organizationAndMesh)} } }]
                                            }
                                        }, (e, concernedUsersHab) => {
                                            if ( !!concernedUsersHab.length ){
                                                let uniqueMails = []
                                                concernedUsersHab.forEach( hab => {
                                                    if ( !uniqueMails.includes(hab.user.mail) ){
                                                        uniqueMails.push( hab.user.mail )
                                                    }
                                                })
                                                return callback(null, uniqueMails)
                                            }
                                            else {
                                                return callback(null, [])
                                            }
                                        })
                                    }
                                },
                                callback => {
                                    if ( !removedRoles.length ){
                                        callback(null, [])
                                    }
                                    else{
                                        global.app.SE.Habilitation.find({
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: removedRoles.map( id => global.ObjectID(id))},
                                                $or: [ {"grantedAccess": [],"grantedMesh": [] }, {"grantedAccess": {$elemMatch: { $eq: global.ObjectID(demand.organizationAndMesh.id)} }, "grantedMesh":[] }, {"grantedAccess": [] , "grantedMesh":{$elemMatch: { $eq: global.ObjectID(demand.organizationAndMesh.id)} } }]
                                            }
                                        }, (e, concernedUsersHab) => {
                                            if ( !!concernedUsersHab.length ){
                                                let uniqueMails = []
                                                concernedUsersHab.forEach( hab => {
                                                    if ( !uniqueMails.includes(hab.user.mail) ){
                                                        uniqueMails.push( hab.user.mail )
                                                    }
                                                })
                                                return callback(null, uniqueMails)
                                            }
                                            else {
                                                return callback(null, [])
                                            }
                                        })
                                    }
                                },
                                callback => {
                                    global.app.SE.Demand.collection.updateOne(
                                        {
                                            _id: global.ObjectID(demand.id)
                                        },
                                        {
                                            $set: {
                                                workflow: [correctStep, correctOrder],
                                                contributorsFunctions: correctRolesIDs,
                                                arbitratorsFunctions: correctArbitrators,
                                                consultantsFunctions: correctConsultantsFunctions,
                                                allArbitratorsFunctions: correctAllArbitratorsFunctions,
                                                ...delegationOption2,
                                                ...statusUpdate,
                                                ...arbitrationTypeWasModified,
                                                ...delegationOptionWasModified
                                                //...delegationOption
                                            }
                                        },
                                        e => {
                                            if(e) {
                                                console.log(`erreur d'update de la demande ${demand.id}`, e)
                                                return callback(e)
                                            }
                                            return callback()
                                        }
                                    )
                                }
                            ], (error, results) => {
                                if(error) return callback(error)
                                const [ addedMails, removedMails] = results
                                if ( !!addedMails.length) {
                                    addedMails.forEach( mail => {
                                        if ( !!usersAddedToStepOrder[mail] ){
                                            usersAddedToStepOrder[mail].push([demand.demandNumber, getStatusName2(correctStatusID)])
                                        }
                                        else{
                                            usersAddedToStepOrder[mail] = [[demand.demandNumber, getStatusName2(correctStatusID)]]
                                        }
                                    })
                                }
                                if ( !!removedMails.length) {
                                    removedMails.forEach( mail => {
                                        if ( !!usersRemovedFromStepOrder[mail] ){
                                            usersRemovedFromStepOrder[mail].push([demand.demandNumber, demand.status.name2])
                                        }
                                        else{
                                            usersRemovedFromStepOrder[mail] = [[demand.demandNumber, demand.status.name2]]
                                        }
                                    })
                                }
                                callback()
                            })
                        }), (error, result) => {
                            if (error) return callback(error)
                            /*
                            if ( !!Object.keys(usersRemovedFromDelegateTo).length ){
                                const mails = Object.keys(usersRemovedFromDelegateTo).map( mail => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(mail),
                                        subject: {template: `Délégation retirée`},
                                        context : {
                                            concernedDemands: usersRemovedFromDelegateTo[mail].map( title => ' '+title),
                                        },
                                        content: 'removingDelegation.html',
                                        verbose: {
                                            general: true,
                                        },
                                        templateDir: path.join(
                                            global.appRoot,
                                            global.isProd
                                                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                        ),
                                        service: "mailjet"
                                    }
                                })
                                global.mailer.sendMail(mails, (error) => {
                                    if (error) console.warn(error)
                                })
                            }

                             */
                            if ( !!rolesAddedToReading.length ){
                                global.app.SE.Habilitation.find({
                                    ...basicContext(context),
                                    fieldPath: ["user.id"],
                                    query: {
                                        "role": {$in: rolesAddedToReading.map( id => global.ObjectID(id))},
                                        $or: [ {"grantedAccess": [],"grantedMesh": [] }, {"grantedAccess": {$elemMatch: {$in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id))} }, "grantedMesh":[] }, {"grantedAccess": [] , "grantedMesh":{$elemMatch: {$in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id))} } }]
                                    }
                                }, (e, concernedUsersHab) => {
                                    if ( !!concernedUsersHab.length ){
                                        let uniqueMails = []
                                        concernedUsersHab.forEach( hab => {
                                            if ( !uniqueMails.includes(hab.user.mail) ){
                                                uniqueMails.push( hab.user.mail )
                                            }
                                        })
                                        const mails = uniqueMails.map( mail => {
                                            return {
                                                from: 'support@keenpoint.com',
                                                to: decrypt(mail),
                                                subject: {template: `Nouveau lecteur assigné`},
                                                context : {
                                                    concernedOrgs: ORGS.map( org => ' '+org),
                                                    concernedCategory: ' '+CATEGORY,
                                                    concernedNatures: NATURES.map( nat => ' '+nat),
                                                    concernedPlages: PLAGES.map( plage => ' '+plage),
                                                },
                                                content: 'readersWorkflowMails.html',
                                                verbose: {
                                                    general: true,
                                                },
                                                templateDir: path.join(
                                                    global.appRoot,
                                                    global.isProd
                                                        ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                        : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                ),
                                                service: "mailjet"
                                            }
                                        })
                                        global.mailer.sendMail(mails, (error) => {
                                            if (error) console.warn(error)
                                        })
                                    }
                                })
                            }
                            if ( !!rolesRemovedFromReading.length ){
                                global.app.SE.Habilitation.find({
                                    ...basicContext(context),
                                    fieldPath: ["user.id"],
                                    query: {
                                        "role": {$in: rolesRemovedFromReading.map( id => global.ObjectID(id))},
                                        $or: [ {"grantedAccess": [],"grantedMesh": [] }, {"grantedAccess": {$elemMatch: {$in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id))} }, "grantedMesh":[] }, {"grantedAccess": [] , "grantedMesh":{$elemMatch: {$in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id))} } }]
                                    }
                                }, (e, concernedUsersHab) => {
                                    if ( !!concernedUsersHab.length ){
                                        let uniqueMails = []
                                        concernedUsersHab.forEach( hab => {
                                            if ( !uniqueMails.includes(hab.user.mail) ){
                                                uniqueMails.push( hab.user.mail )
                                            }
                                        })
                                        const mails = uniqueMails.map( mail => {
                                            return {
                                                from: 'support@keenpoint.com',
                                                to: decrypt(mail),
                                                subject: {template: `Suppression de vos droits de lecteur`},
                                                context : {
                                                    concernedOrgs: ORGS.map( org => ' '+org),
                                                    concernedCategory: ' '+CATEGORY,
                                                    concernedNatures: NATURES.map( nat => ' '+nat),
                                                    concernedPlages: PLAGES.map( plage => ' '+plage),
                                                },
                                                content: 'removingReadersWorkflowMails.html',
                                                verbose: {
                                                    general: true,
                                                },
                                                templateDir: path.join(
                                                    global.appRoot,
                                                    global.isProd
                                                        ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                        : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                ),
                                                service: "mailjet"
                                            }
                                        })
                                        global.mailer.sendMail(mails, (error) => {
                                            if (error) console.warn(error)
                                        })
                                    }
                                })
                            }
                            if ( !!Object.keys(usersRemovedFromDelegateTo2).length ){
                                const mails = Object.keys(usersRemovedFromDelegateTo2).map( mail => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(mail), //context.user.mail,
                                        subject: {template: `Délégation retirée`}, //nextStep
                                        context : {
                                            concernedDemands: usersRemovedFromDelegateTo2[mail].map( demandAndStatus => ' '+demandAndStatus[0] + ' (' + _.capitalize(demandAndStatus[1])+ ')'),
                                        },
                                        content: 'removingDelegation.html',
                                        verbose: {
                                            general: true,
                                        },
                                        templateDir: path.join(
                                            global.appRoot,
                                            global.isProd
                                                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                        ),
                                        service: "mailjet"
                                    }
                                })
                                global.mailer.sendMail(mails, (error) => {
                                    if (error) console.warn(error)
                                })
                            }
                            if ( !!Object.keys(usersAddedToStepOrder).length ){
                                const mails = Object.keys(usersAddedToStepOrder).map( mail => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(mail),
                                        subject: {template: `Projet(s) en attente de votre décision suite à une modification du Workflow`}, //nextStep
                                        context : {
                                            concernedDemands: usersAddedToStepOrder[mail].map( demandAndStatus => ' '+demandAndStatus[0] + ' (' + _.capitalize(demandAndStatus[1])+ ')'),
                                        },
                                        content: 'addedToWorkflow.html',
                                        verbose: {
                                            general: true,
                                        },
                                        templateDir: path.join(
                                            global.appRoot,
                                            global.isProd
                                                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                        ),
                                        service: "mailjet"
                                    }
                                })
                                global.mailer.sendMail(mails, (error) => {
                                    if (error) console.warn(error)
                                })
                            }
                            if ( !!Object.keys(usersRemovedFromStepOrder).length ){
                                const mails = Object.keys(usersRemovedFromStepOrder).map( mail => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(mail), //context.user.mail,
                                        subject: {template: `Intervention annulée`}, //nextStep
                                        context : {
                                            concernedDemands: usersRemovedFromStepOrder[mail].map(demandAndStatus => ' '+demandAndStatus[0] + ' (' + _.capitalize(demandAndStatus[1]) + ')'),
                                        },
                                        content: 'removedFromWorkflow.html',
                                        verbose: {
                                            general: true,
                                        },
                                        templateDir: path.join(
                                            global.appRoot,
                                            global.isProd
                                                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                                        ),
                                        service: "mailjet"
                                    }
                                })
                                global.mailer.sendMail(mails, (error) => {
                                    if (error) console.warn(error)
                                })
                            }
                            callback(null, newObject, oldObject)
                        })
                    }
                    else {
                        return callback(null, newObject, oldObject)
                    }
                }
            )
            /*
            if (arbitrationTypeWasModified || delegationOptionWasModified){
                global.app.SE.Demand.collection.updateMany(
                    {
                        organizationAndMesh: { $in: newObject.organizationAndMesh.map( org => global.ObjectID(org.id)) },
                        demandType: { $in: newObject.demandType.map( type => global.ObjectID(type.id)) },
                        imputationType: { $in: newObject.imputationType.map( type => global.ObjectID(type.id)) },
                        plafond: { $in: newObject.minMaxAmount.map( plafond => global.ObjectID(plafond.id)) },
                    },
                    {
                        $set: {
                            arbitrationType: newObject.arbitrationType,
                            delegationOption: newObject.delegationOption
                        }
                    },
                    e => {
                        if(e) return callback(e)
                        callback(null, newObject, oldObject)
                    }
                )
            }
            else {
                callback(null, newObject, oldObject)
            }

             */
        }
    },
    {
        name: 'WorkFlowConfig',
        fields: [
            {path: 'role', type: 'Role'},
            {path: 'step'},
            {path: 'order', type: 'integer'},
        ],
    },
    {
        name: "ManualWorkFlowConfig",
        type: "mongoInternal",
        //il cree les champs dans le composant père, pour eviter la création des collections pour cette entité
        fields: [
            //en se basant sur les steps
            {path: "role", type: "Role"},    // par defaut le link est celui de dropdown, MTO
            {path: "preValidation", type: "boolean"},
            {path: "preValidationOrder", type: "integer"},
            {path: "demand", type: "boolean"},
            {path: "demandOrder", type: "integer"},
            {path: "control", type: "boolean"},
            {path: "controlOrder", type: "integer"},
            {path: "validation", type: "boolean"},
            {path: "validationOrder", type: "integer"},
            {path: "realization", type: "boolean"},
            {path: "realizationOrder", type: "integer"},
            {path: "arbitration", type: "boolean"},
            {path: "reading", type: "boolean"},
        ]
    }
]


export const module_ = {
    object: 'WorkFlow',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    //defaultSortBy: 'organization',
    //defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {
                path: 'organizationAndMesh',
                tKey: 'organisation'
            },
            {
                path: 'demandCategory',
                tKey: 'processType',
                translateName: true,
                //type: 'translatedText'
            },
            {
                path: 'demandNature',
                tKey: 'category',
                translateName: true,
                //display: 'name',
                //type: 'translatedText'
            },
            {path:'minMaxAmount', tKey: 'financialBracket', display: 'code'},
        ],
        form: {
            fields : [
                {
                    path: 'organizationAndMesh',
                    display: 'fullName',
                    tKey: 'organisation',
                    filters: ['inWorkflowEnabledAxe'],
                    editable: false,
                    fieldPath: ['id', 'fullName', 'joinedEntity'],
                    subscriptions: {    //not yet
                        onChange: (newValue, oldValue, {module, store}) => {
                            const state = store.getState()

                            store.dispatch(
                                generateFetchFieldListAction(
                                    "Workflow-Role",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            organizations : newValue
                                        }
                                    }
                                )
                            ).then((response) => {
                                const workFlowConfigs= _.get(state, 'edit.object.data.workFlowConfigs')
                                const readers = _.get(state, 'edit.object.data.readers', [])
                                const arbitrators = _.get(state, 'edit.object.data.arbitrators', [])
                                const roles = response.data || []
                                const manualWorkFlowConfigs = roles.map( role=> {
                                    const previousRoleConfigs = getRoleWorkflowConfigs(workFlowConfigs, role)
                                    let object = {role: {id: role.id, name: role.profile.name}}
                                    steps.forEach( step => {
                                        const previousStepConfig = getStepWorkflowConfig(previousRoleConfigs, step)
                                        if(!previousStepConfig) {
                                            object[step] = false
                                            object[step + 'Order'] = 0
                                        } else {
                                            object[step] = true
                                            object[step + 'Order'] = previousStepConfig.order
                                        }
                                    })
                                    if (readers===[]){
                                        object["reading"] = false
                                    }
                                    else {
                                        const previousReader = readers.filter( reader => reader.id === role.id)
                                        if (previousReader.length!==0){
                                            object["reading"] = true
                                        }
                                        else {
                                            object["reading"] = false
                                        }
                                    }
                                    if (arbitrators===[]){
                                        object["arbitration"] = false
                                    }
                                    else {
                                        const previousArbitrator = arbitrators.filter( arbitrator => arbitrator.id === role.id)
                                        if (previousArbitrator.length!==0){
                                            object["arbitration"] = true
                                        }
                                        else {
                                            object["arbitration"] = false
                                        }
                                    }
                                    return object
                                })
                                store.dispatch(change('editObject' //editObject = le nom du formulaire dans le store (redux-form)
                                    , 'manualWorkFlowConfigs' // ina champs f lformulaire
                                    , manualWorkFlowConfigs))
                            })
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    translateName: true,
                    editable: false,
                    fieldPath: ['id', 'name', 'category.id'],
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const manualWorkFlowConfigsField = module.viewMap.form.fields.find(field => field.path === 'manualWorkFlowConfigs')
                            const preValidationField = manualWorkFlowConfigsField.fields.find(field => field.path === 'preValidation')
                            const preValidationOrderField = manualWorkFlowConfigsField.fields.find(field => field.path === 'preValidationOrder')
                            const realizationField = manualWorkFlowConfigsField.fields.find(field => field.path === 'realization')
                            const realizationOrderField = manualWorkFlowConfigsField.fields.find(field => field.path === 'realizationOrder')
                            console.log("manualWorkFlowConfigsField", manualWorkFlowConfigsField.getValue())
                            if (newValue) {
                                let newWorkflow = _.cloneDeep(manualWorkFlowConfigsField.getValue())
                                newWorkflow.forEach( row => {
                                    row.preValidation = false
                                    row.preValidationOrder = 0
                                    row.realization = false
                                    row.realizationOrder = 0
                                })
                                store.dispatch(setFieldVisibility(preValidationField.id, newValue.category.id === '1'))
                                store.dispatch(setFieldVisibility(realizationField.id, newValue.category.id === '1'))
                                store.dispatch(setFieldVisibility(preValidationOrderField.id, newValue.category.id === '1'))
                                store.dispatch(setFieldVisibility(realizationOrderField.id, newValue.category.id === '1'))
                                store.dispatch(change('editObject', 'manualWorkFlowConfigs', newWorkflow))
                            }
                        }
                    }
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    translateName: true,
                    editable: false
                },
                {
                    path:'minMaxAmount',
                    tKey: 'financialBracket',
                    display: 'code',
                    editable: false,
                },
                {
                    path: 'manualWorkFlowConfigs',
                    tKey: 'workflow',
                    type: 'dtObjects',
                    autoGrow: true,
                    fields: [
                        {path: "role", tKey: 'fonction', translateName: true},
                        {path: "preValidation", tKey: 'pré-validation', type: "checkbox"},
                        {path: "preValidationOrder", tKey: 'ordre', type: "editText"},
                        {path: "demand", tKey: 'instruction', type: "checkbox",},
                        {path: "demandOrder", tKey: 'ordre', type: "editText"},
                        {path: "control", tKey: 'contrôle', type: "checkbox"},
                        {path: "controlOrder", tKey: 'ordre', type: "editText"},
                        {path: "validation", type: "checkbox"},
                        {path: "validationOrder", tKey: 'ordre', type: "editText"},
                        {path: "realization", tKey: 'réalisation', type: "checkbox"},
                        {path: "realizationOrder", tKey: 'ordre', type: "editText"},
                        {path: "arbitration", tKey: 'arbitrage', type: "checkbox"},
                        {path: "reading", tKey: 'lecture', type: "checkbox"},
                    ],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const manualWorkFlowConfigsField = module.viewMap.form.fields.find( field => field.path === 'manualWorkFlowConfigs')
                            const arbitrationTypeField = module.viewMap.form.fields.find( field => field.path === 'arbitrationType')
                            if (newValue && oldValue){
                                if (newValue.length === oldValue.length && JSON.stringify(newValue)!==JSON.stringify(oldValue)){
                                    console.log('ok')
                                    let cup = []
                                    let arbitration = false
                                    for (let i = 0; i < newValue.length; i++) {
                                        if (newValue[i].arbitration) {arbitration = newValue[i].arbitration} //true
                                        const findOldValue = oldValue.find( row => row.role.id === newValue[i].role.id)
                                        if ( newValue[i] === findOldValue ) {
                                            cup.push(newValue[i])
                                        }
                                        else {
                                            let newLine = {'role' : newValue[i]['role'], 'reading' : newValue[i]['reading'], 'arbitration' : newValue[i]['arbitration']}
                                            steps.forEach(step => {
                                                newLine[step] = newValue[i][step]
                                                if ( newValue[i][step] === findOldValue[step] ){
                                                    newLine[step + 'Order'] = newValue[i][step + 'Order']
                                                }
                                                else if (newValue[i][step] === true && findOldValue[step] === false){
                                                    newLine[step + 'Order'] = 1
                                                    if ( step === "demand"){
                                                        newLine["reading"] = true
                                                    }
                                                }
                                                else if (newValue[i][step] === false && findOldValue[step] === true){
                                                    newLine[step + 'Order'] = 0
                                                    if ( step === "demand"){
                                                        newLine["reading"] = false
                                                    }
                                                }
                                            })
                                            cup.push(newLine)
                                        }
                                    }
                                    manualWorkFlowConfigsField.setValue(cup)
                                    if (arbitration){
                                        store.dispatch(setFieldVisibility(arbitrationTypeField.id, true))
                                    }
                                    else {
                                        store.dispatch(setFieldVisibility(arbitrationTypeField.id, false))
                                        arbitrationTypeField.setValue(null)
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    path: "delegationOption",
                    required: true,  //!!!!!!!!not working
                    default: {id: "2"},
                    type: "toggle"
                },
                {
                    path: "arbitrationType",
                    //tKey: "mode d'arbitrage",
                    //clearable: false,
                    required: true,
                    type: 'toggle'
                },
                {path: 'workFlowConfigs', fieldPath: ['role', 'step', 'order'], hidden: true},
                {path: 'readers', fieldPath: ['id'], hidden: true},
                {path: 'arbitrators', fieldPath: ['id'], hidden: true}
            ],
            onOpen: ({ module, state, store }) => {
                const objectMode = state.ui.objectMode
                const arbitrationTypeField = module.viewMap.form.fields.find( field => field.path === 'arbitrationType')
                if(objectMode === 'newObject') {
                    store.dispatch(setFieldVisibility(arbitrationTypeField.id, false))
                }
                else {
                    if ( !arbitrationTypeField.getValue() ){
                        store.dispatch(setFieldVisibility(arbitrationTypeField.id, false))
                    }
                    else {
                        store.dispatch(setFieldVisibility(arbitrationTypeField.id, true))
                    }
                }
            },
            dataLists: [
                "Workflow-Role" //c'est le id d'une dataList qu'on veut ajouter manuellement
            ],
        },
    },
    accesses: [ // goes with the dataList. On va definir les données qu'on veut apporter
        {
            id: "Workflow-Role",
            entity: "Role",
            fieldPath: ['id', 'profile.name'],
            filters: [ 'byOrganizationAndMesh']
        },
    ]
}
