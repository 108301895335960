export const entity = {
    name: 'Provider',
    facets:[
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields:[
        {type: 'Project', link: 'MTM'}
    ]
}
export const module_ = {
    object: 'Provider',
    tKey: 'mTitle_provider',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'code','name',
            {path: 'projects', tKey: 'attachments', display: 'fullName'}
        ],
        form: [
            'code', 'name',
            {path: 'projects', tKey: 'attachments', display: 'fullName'}
        ]
    }
}
