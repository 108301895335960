import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import './Menu.css'
import PropTypes from 'prop-types'
import classnames from "classnames";

class Menu extends Component {
    // Required by onClickOutSide
    handleClickOutside = (...args) => {
        this.props.opened &&
        this.props.onClickOutside &&
        this.props.onClickOutside(args)
    }

    render() {
        return this.props.opened
            ? (
                <div
                    className={
                        classnames(
                            'Menu-container',
                            {
                                opened: this.props.opened,
                            },
                            this.props.position
                        )
                    }
                >
                    {this.props.children}
                </div>
            ): <div className='Menu-container'/>
    }
}

const WrappedMenu = onClickOutside(Menu)

WrappedMenu.propTypes = {
    opened: PropTypes.bool.isRequired,
    position: PropTypes.string.isRequired,
    handleClickOutside: PropTypes.func
}

export default WrappedMenu
