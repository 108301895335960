import React from 'react'
import { FieldArray } from 'redux-form'
import ImagesUploader from './ImagesUploader'
import getFormValidations from "../apps/KpModule/components/Form/formValidations";
import ValidationUI from "./ValidationUI";


const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const VisualComponent = ({fields:{ push, remove, getAll }, meta: { error }, t, placeholder, user, editable}) => {
    const objects = getAll()
    const allIds = objects && objects.map(o => o.id)
    const byId = arrayToObject(objects)

    return (
        <div>
            <ImagesUploader
                byId={byId}
                allIds={allIds}
                push={push}
                remove={remove}
                user={user}
                editable={editable}
                placeholder={placeholder}
                t={t}
            />
            {error && <ValidationUI error={t(error)} />}
        </div>
    )
}

// binding for redux-form
const ImagesUploaderField = ({ field, module, user, t }) => {
    const validations = getFormValidations(field, t)
    return (
        <FieldArray
            name={field.path}
            placeholder={field.placeholder}
            component={VisualComponent}
            module={module}
            validate={validations}
            rerenderOnEveryChange={true}
            user={user}
            editable={field.editable}
            t={t}
        />
    )
}


export default ImagesUploaderField
