import React from 'react'
import PropTypes from 'prop-types'
import ValidationUI from '../ValidationUI'
import './TextAreaField.css'
import classnames from "classnames"

const TextAreaField = (props) => {


    return (
        <div className="TextAreaField-container">
            <label className="TextAreaField-label">
                {props.t(props.path)} {props.required && "*"}
            </label>
            <textarea
                className={classnames('TextAreaField-textArea form-control', {
                    disabled: props.disabled
                })}
                placeholder=""
                rows={3}
                {...props}
            />
            { props.touched && (props.error && <ValidationUI error={props.error}/>) }
        </div>
    )

}

TextAreaField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default TextAreaField
