import React from "react";
import ReduxToastr from 'react-redux-toastr';
import { translate } from 'react-i18next';
import './Toastr.css';

const Toastr = ({t}) => {
    const options = {
        okText: t('yes'),
        cancelText: t('no')
    };
    return(
        <ReduxToastr
            timeOut={10000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            confirmOptions={options}
        />
    )
};

export default translate('platform')(Toastr);
