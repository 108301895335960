const { byCompany } = require('./filters')
const { fieldPathJoinGetter } = require("../../utils/kp3Utils");

export const entity = {
    name: 'SurFamily',
    facets: [{name: 'codeName', codeUniqueWith: 'company'}],
    fields: [
        'Company',
        fieldPathJoinGetter({
            path: "fullNameWithCompanyCode",
            fieldPath: ["company.code", "code", "name"]
        }),
        fieldPathJoinGetter({
            path: "nameWithCompanyCode",
            fieldPath: ["company.code", "name"]
        })
    ],
    filters: [{
        ...byCompany,
        autoFetch: true
    }]
}

export const module_ = {
    object: 'SurFamily',
    tKey: 'mTitle_surFamily',
    newable: false,
    removable: false,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'company', display:'fullName', initiallyNotVisible: true},
            {path: 'code', width: 100},
            'name'
        ],
        form: [
            { path: 'code', required: true, editable: false },
            { path: 'name', required: true },
            {
                path: 'company',
                display: 'name'
            }
        ]
    },
    filters: ['byCompany']
}
