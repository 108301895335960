const _ = require('lodash')
// const moment = require('moment')
const { FILTER_FORM } = require('../../../apps/KpModule/components/Filter/FilterForm')
const { setEditDefaultObject } = require('../../../apps/KpModule/actions')
const { getEditDefaultData } = require('../../../apps/KpModule/selectors')
const { actionTypes: reduxFormActions } = require('redux-form')

const defaultMeetingSubscriptions = [
    {
        actionType: reduxFormActions.INITIALIZE,
        subscription: ({ store, action }) => {
            if (action.meta.form === FILTER_FORM) {
                const state = store.getState()
                const meetingId = _.get(action, 'payload.meeting.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    meeting: meetingId ? { id: meetingId } : null
                }))
            }
        }
    },
    {
        actionType: reduxFormActions.CHANGE,
        subscription: ({ store, action }) => {
            if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'meeting')) {
                const state = store.getState()
                const meetingId = _.get(action, 'payload.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    meeting: meetingId ? { id: meetingId } : null
                }))
            }
        }
    }
]

export const entities = [
    {
        name: 'QuestionStatus',
        type: 'static',
        fields: [{ path: 'id', type: 'string' }, 'tKey'],
        objects: [
            { id: 'inProgress', tKey: 'questionInProgress' },
            { id: 'closed', tKey: 'questionClosed' },
            { id: 'abandoned', tKey: 'questionAbandoned' },
            { id: 'delayed', tKey: 'questionPostponed' }
        ]
    },
    {
        name: 'Question',
        facets: ["comments"],
        fields: [
            {
                type: 'Meeting',
                link: {type: "MTO", oppositeField: {path: "questions"}}
            },
            'Subject',
            'context',
            'draftReply',
            'response',
            { type: 'QuestionStatus', nullable: true },
            { path: 'owner', type: 'User', nullable: true },
            { path: 'creationDate', type: 'date', nullable: true },
            { path: 'lastUser', type: 'User', nullable: true },
            { path: 'lastModificationDate', type: 'date', nullable: true },
            {
                path: "subjectTheme",
                fieldPath: ["subject.theme.id"],
                f: function () { return this.subject.theme.name }
            },
            {
                path: "meetingEntity",
                fieldPath: ["meeting.entity.id"],
                f: function () { return this.meeting.entity.name }
            },
            {
                path: "meetingDate",
                fieldPath: ["meeting.id"],
                f: function () {
                    const date = this.meeting.date
                    const month = date.getUTCMonth() + 1; //months from 1-12
                    const day = date.getUTCDate();
                    const year = date.getUTCFullYear();
                    return year + '-' + month + '-' + day
                }
            },
            {
                path: "meetingObject",
                fieldPath: ["meeting.id"],
                f: function () { return this.meeting.object }
            }
        ],
        filters: [
            {
                name: 'byHabilitatedAndRespectDaysUpstreamInProgressMeeting',
                path: 'meeting',
                object: 'Meeting',
                display: 'code',
                clearable: false,
                client: true,
                filters: [
                    'userHasHabilitationForEntity',
                    'shouldRespectDaysUpstream',
                    'meetingInProgress',
                ],
                query: context => {
                    const meetingId = _.get(context, 'data.meeting.id')

                    if(!meetingId) return {_id: false};
                    return { meeting: new global.ObjectID(meetingId) }
                }
            },
            {
                name: 'byHabilitatedToDaysInProgressMeeting',
                path: 'meeting',
                object: 'Meeting',
                display: 'code',
                clearable: false,
                client: true,
                filters: [
                    'userHasHabilitationForEntity',
                    'isToday',
                    'meetingInProgress'
                ],
                query: context => {
                    const meetingId = _.get(context, 'data.meeting.id')
                    if(!meetingId) return {_id: false};
                    return { meeting: new global.ObjectID(meetingId) }
                }
            },
            {
                name: 'byHabilitatedPastDaysInProgressMeeting',
                path: 'meeting',
                object: 'Meeting',
                display: 'code',
                clearable: false,
                client: true,
                filters: [
                    'userHasHabilitationForEntity',
                    'pastDated',
                    'meetingInProgress'
                ],
                query: context => {
                    const meetingId = _.get(context, 'data.meeting.id')
                    if(!meetingId) return {_id: false};
                    return { meeting: new global.ObjectID(meetingId) }
                }
            },
            {
                name: 'byHabilitatedUpToDateInProgressMeeting',
                path: 'meeting',
                object: 'Meeting',
                display: 'code',
                clearable: false,
                client: true,
                filters: [
                    'userHasHabilitationForEntity',
                    'upToDate',
                    'meetingInProgress'
                ],
                query: context => {
                    const meetingId = _.get(context, 'data.meeting.id')
                    if(!meetingId) return {_id: false};
                    return { meeting: new global.ObjectID(meetingId) }
                }
            }
        ],
        beforeSave: (object, oldObject, context, callback) => {
            console.log(oldObject)
            if(!oldObject) {
                object.owner = new global.ObjectID(context.user.id);
                object.creationDate = new Date();
            }else if(oldObject) {
                object.lastUser = new global.ObjectID(context.user.id);
                object.lastModificationDate = new Date();
            }
            callback(null, object)
        }
    }
]

export const agenda = {
    name: 'agenda',
    object: 'Question',
    category: 'manageTheMeeting',
    tKey: 'mTitle_agenda',
    defaultSortBy: "subject",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            { path: "meetingEntity", tKey: "entity", initiallyNotVisible: true },
            { path: 'meetingDate', tKey: 'date', initiallyNotVisible: true },
            { path: 'meetingObject', tKey: 'object', initiallyNotVisible: true },
            { path: "subjectTheme", tKey: "theme", initiallyNotVisible: true },
            { path: 'subject', width: 200 },
            { path: 'context', width: 400 },
            { path: 'owner', width: 150 },
            { path: 'creationDate', width: 150 }
        ],
        form: [
            { path: 'meeting', display: 'code', writable: false },
            { path: 'subject', nullable: false },
            { path:'context', type: 'textarea', required: true },
        ]
    },
    filters: ['byHabilitatedAndRespectDaysUpstreamInProgressMeeting'],
    actionSubscriptions: [...defaultMeetingSubscriptions]
}

export const preparation = {
    name: 'preparation',
    object: 'Question',
    category: 'manageTheMeeting',
    tKey: 'mTitle_preparation',
    newable: false,
    removable: false,
    defaultSortBy: "subject",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            { path: "meetingEntity", tKey: "entity", initiallyNotVisible: true },
            { path: 'meetingDate', tKey: 'date', initiallyNotVisible: true },
            { path: 'meetingObject', tKey: 'object', initiallyNotVisible: true },
            { path: "subjectTheme", tKey: "theme", initiallyNotVisible: true, width: 200 },
            { path: 'subject', width: 200 },
            { path: 'context', width: 400 },
            { path: 'draftReply', width: 400 },
            { path: 'owner', width: 150 },
            { path: 'creationDate', width: 150, initiallyNotVisible: true },
            { path: 'lastUser', width: 150, initiallyNotVisible: true },
            { path: 'lastModificationDate', width: 150, initiallyNotVisible: true }
        ],
        form: [
            { path: 'meeting', display: 'code', writable: false },
            { path: 'subject', writable: false },
            { path:'context', type: 'textarea', required: true, writable: false},
            { path:'draftReply', type: 'textarea'},
            'comments'
        ]
    },
    filters: ['byHabilitatedUpToDateInProgressMeeting']
}

export const animation = {
    name: 'animation',
    object: 'Question',
    category: 'manageTheMeeting',
    tKey: 'mTitle_animation',
    newable: false,
    removable: false,
    defaultSortBy: "subject",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            { path: "meetingEntity", tKey: "entity", initiallyNotVisible: true },
            { path: 'meetingDate', tKey: 'date', initiallyNotVisible: true },
            { path: 'meetingObject', tKey: 'object', initiallyNotVisible: true },
            { path: 'subjectTheme', tKey: 'theme', initiallyNotVisible: true, width: 200 },
            { path: 'subject', width: 200 },
            { path: 'context', width: 500 },
            { path: 'draftReply', tKey: 'exchanges' , width: 400, initiallyNotVisible: true },
            { path:'response'},
            { path: "questionStatus", display: "tKey", translate: true, width: 150 },
            { path: 'owner', initiallyNotVisible: true, width: 150},
            { path: 'creationDate', width: 150, initiallyNotVisible: true },
            { path: 'lastUser', width: 150, initiallyNotVisible: true },
            { path: 'lastModificationDate', width: 150, initiallyNotVisible: true }
        ],
        form: [
            { path: 'meeting', display: 'code', writable: false },
            { path: 'subject', writable: false },
            { path:'context', type: 'textarea', writable: false },
            { path:'draftReply', type: 'textarea', tKey: 'exchanges' },
            { path:'response', type: 'textarea' },
            { path: "questionStatus", display: "tKey", translate: true },
            'comments'
        ]
    },
    filters: ['byHabilitatedToDaysInProgressMeeting']
}

export const report = {
    name: 'report',
    object: 'Question',
    category: 'manageTheMeeting',
    tKey: 'mTitle_report',
    newable: false,
    removable: false,
    defaultSortBy: "subject",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            { path: "meetingEntity", tKey: "entity", initiallyNotVisible: true },
            { path: 'meetingDate', tKey: 'date', initiallyNotVisible: true },
            { path: 'meetingObject', tKey: 'object', initiallyNotVisible: true },
            { path: 'subjectTheme', tKey: 'theme', initiallyNotVisible: true, width: 200 },
            { path: 'subject', width: 200 },
            { path: 'context', width: 500 },
            { path: 'draftReply', initiallyNotVisible: true, tKey: 'exchanges', width: 500 },
            { path: 'response', width: 500 },
            { path: "questionStatus", display: "tKey", translate: true, width: 150},
            { path: 'owner', initiallyNotVisible: true, width: 150},
            { path: 'creationDate', width: 150, initiallyNotVisible: true },
            { path: 'lastUser', width: 150, initiallyNotVisible: true },
            { path: 'lastModificationDate', width: 150, initiallyNotVisible: true }
        ],
        form: [
            { path: 'meeting', display: 'code', writable: false },
            { path: 'subject', writable: false },
            { path:'context', type: 'textarea', writable: false },
            { path:'draftReply', type: 'textarea', tKey: 'exchanges', writable: false },
            { path:'response', type: 'textarea' },
            { path: "questionStatus", display: "tKey", translate: true, required: true  },
            'comments'
        ]
    },
    filters: ['byHabilitatedPastDaysInProgressMeeting']
}
