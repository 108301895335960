const _ = require('lodash')
const {basicContext} = require('../../../utils/contextUtils')

async function findData(context) {
    const objectiveId = _.get(context, 'data.objective.id')

    if(!objectiveId) return []
    const formationObjectives = await global.app.FT.FormationObjective.find({
        ...basicContext(context),
        fieldPath: ['objective', 'formation'],
        query: {objective: new global.ObjectID(objectiveId)}
    })

    const sessions = await global.app.FT.Course.find({
        ...basicContext(context),
        fieldPath: ['code', 'formation', 'collaborator'],
        query: {formation: {$in: formationObjectives.map(obj => new global.ObjectID(obj.formation.id))}}
    })

    const sessionsGroupedByFormation = _.groupBy(sessions, 'formation.id')

    const synthesisCollection = global.app.FT.Synthesis.collection

    const fetchPromise = synthesisCollection
        .aggregate([
            {
                $match: {
                    objective: {$in: formationObjectives.map(obj => new global.ObjectID(obj.objective.id))},
                    course: {$in: sessions.map(session => new global.ObjectID(session.id))}
                }
            }
        ])
        .toArray()

    const syntheses = await fetchPromise

    const synthesesGroupedByObjective = _.groupBy(syntheses, synthesis => synthesis.objective.toString())

    return _.flatMap(formationObjectives, obj => {
        const synthesesGroupedBySession = _.groupBy(synthesesGroupedByObjective[obj.objective.id], (syntheses, synthesis => synthesis.course.toString()))
        return sessionsGroupedByFormation[obj.formation.id].map(session => {
            return {
                id: `${obj.objective.id}-${session.id}`,
                objective: obj.objective,
                formation: obj.formation,
                course: session,
                start: session.start,
                end: session.end,
                collaborator: session.collaborator,
                nbParticipants: session.participants.length,
                target: session.participants.length * parseInt(obj.level),
                acquired: synthesesGroupedBySession[session.id] ? synthesesGroupedBySession[session.id].reduce((acc, synthesis) => acc + parseInt(synthesis.acquired)  , 0) : 0
            }
        })
    })
}


export const entity = {
    name: 'Scoring',
    fields: [
        {type: 'Objective'},
        {type: 'Formation'},
        {type: 'Course'},
        {path: 'start', type: 'date'},
        {path: 'end', type: 'date'},
        {type: 'Collaborator'},
        {path: 'nbParticipants', type: 'integer'},
        {path: 'target', type: 'integer'},
        {path: 'acquired', type: 'integer'},
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context, callback)
                    .then(response => callback(null, response))
                    .catch(error => callback(error))
        })
    }
}
export const module_ = {
    object: 'Scoring',
    tKey: 'mTitle_scoring',
    category: {
        path: 'analysis',
        icon: 'trendingUp'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'objective', display: 'fullName'},
            {path: 'formation', display: 'fullTitle'},
            {path: 'course', display: 'code', tKey: 'session'},
            {path: 'start', type: 'date'},
            {path: 'end', type: 'date'},
            {path: 'collaborator', display: 'fullName'},
            {path: 'nbParticipants', type: 'integer'},
            {path: 'target', type: 'integer', tooltip: true},
            {path: 'acquired', type: 'integer', tooltip: true},
        ]
    },
    filters: [{
        path: 'objective',
        object: 'Objective',
        client: true,
        width: 12,
        display: 'fullName'
    }]
}
