const _ = require("lodash");
const { translateName } = require("../../../utils/i18n");

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "ThemeJoin",
    fields: [
        "code",
        "name",
        {
            type: "ThemeConfig",
            link: "MTO",
            nullable: true
        },
        {
            type: "SubTheme",
            link: "MTO",
            nullable: true
        },
        {
            path: "completeCode",
            fieldPath: [
                "themeConfig.themeKeyName",
                "themeConfig.completeCode",
                "subTheme.completeCode",
                "subTheme.themeConfig.themeKeyName",
                "subTheme.themeConfig.completeCode",
                "name"
            ],
            f: function() {
                if (this.joinedEntity === "ThemeConfig") {
                    return `theme-${this.code}`;
                }
                else if (this.joinedEntity === "SubTheme") {
                    return `${this.themeConfig.completeCode}.${this.code}`;
                }
                else if (this.joinedEntity === "SubThemeElement") {
                    return `${this.subTheme.completeCode}.${this.code}`;
                }
                else {
                    return `theme-${this.code}`;
                }
            }
        },
        {
            path: "completeCodeWithoutPrefix",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode.replace("theme-", "");
            }
        },
        {
            path: "completeName",
            lazy: true,
            fieldPath: [
                "subTheme.themeConfig.name",
                "themeConfig.name",
                "name"
            ],
            $f: function(themeJoin, context, callback) {
                const translate = name => context.translateName
                    ? context.translateName(name)
                    : translateName(name, _.get(context, 'language.id'))

                const objects = themeJoin => {
                    switch (themeJoin.joinedEntity) {
                        case "ThemeConfig":
                            return [themeJoin];
                        case "SubTheme":
                            return [themeJoin.themeConfig, themeJoin];
                        case "SubThemeElement":
                            return [themeJoin.subTheme.themeConfig, themeJoin.subTheme, themeJoin];
                        default:
                            return [];
                    }
                };

                const completeName = _(themeJoin)
                    .thru(objects)
                    .map("name")
                    .map(translate)
                    .value()
                    .join(" > ");

                callback(null, completeName);
            }
        },
        {
            path: "mtKeyName",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode + ".Mt";
            }
        },
        {
            path: "nbKeyName",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode + ".Nb";
            }
        }
    ]
}, ["ThemeConfig", "SubTheme", "SubThemeElement"])
