const { translateName } = require("../../../utils/i18n");
const _ = require("lodash");

const translate = (object, context) => {
    switch (object.joinedEntity) {
        case "FamilyAxis":
        case "OrganizationAxis":
        case "SubStoreAxis":
            return translateName(object.name, _.get(context, "language.id"));
        default:
            return context.tc ? context.tc(object.tKey) : object.tKey;
    }
};

const joinParams = [
    {
        entityName: "FamilyAxis",
        childEntityName: "Family"
    },
    {
        entityName: "ProductAxis",
        childEntityName: "Product"
    },
    {
        entityName: "OrganizationAxis",
        childEntityName: "Organization"
    },
    {
        entityName: "SubStoreAxis"
    },
    {
        entityName: "StoreAxis",
        childEntityName: "Store"
    },
];

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "GroupAxis",
    facets: ["codeName", "translatedField"],
    fields: [
        {
            path: "groupIdObjectForMongo",
            f: function() {
                return {[this.code]: `$${this.code}`}
            }
        },
        {
            path: "projectObjectForMongo",
            f: function() {
                return {[this.code]: `$_id.${this.code}`};
            }
        },
        {
            path: "childOfAxisEntityName",
            f: function() {
                return joinParams.find(o => o.entityName === this.joinedEntity).childEntityName;
            }
        },
        {
            path: "tName",
            fieldPath: ["name"],
            $f: (object, context, callback) => {
                callback(null, translate(object, context));
            }
        },
        {
            path: "includeInReportAnalysis",
            fieldPath: ["isAlertReportAnalysisAxis"],
            $f: (object, context, callback) => {
                if(object.joinedEntity === 'StoreAxis') return callback(null, true)
                if(object.joinedEntity === 'SubStoreAxis') return callback(null, object.isAlertReportAnalysisAxis)
                return callback(null, false)
            }
        }
    ],
    filters: [
        {
            name: 'byAlertConf',
            isDefault: false,
            match: (object, context) => {
                const alertConf = context.data && context.data.alertConfiguration

                const authorisedGroupElements = alertConf && alertConf.groupAxes.reduce((acc, groupAxis) => {
                    switch(groupAxis.joinedEntity){
                        case "SubStoreAxis":
                            return [...acc, groupAxis.code]
                        case "StoreAxis":
                            return [...acc, "StoreAxis", "OrganizationAxis"]
                        case "ProductAxis":
                            return [...acc, "ProductAxis", "FamilyAxis"]
                        case "FamilyAxis":
                            return [...acc, "FamilyAxis"]
                        case "OrganizationAxis":
                            return [...acc, "OrganizationAxis"]
                        default:
                            return []
                    }
                }, []);

                return alertConf && authorisedGroupElements.includes(
                    object.joinedEntity === "SubStoreAxis" ? object.code : object.joinedEntity
                )
            }
        }
    ],

    // translateName is used when tName is not working
    translateName: translate
}, joinParams.map(o => o.entityName))
