import moment from "moment";
import {setFieldVisibility, setFieldEdition} from "../../../apps/KpModule/actions";
import {getUser, isGroupModelCommunicationAdmin} from "../../../apps/KpModule/selectors";
import {translateName} from "../../../utils/i18n";

const _ = require('lodash')
export const entities = [
    {
        name: 'DocumentType',
        fields: [
            { path: 'name', unique: true },
            {
                path: 'tName',
                fieldPath: ['name'],
                $f: (documentType, context, callback) => {
                    return callback(null, translateName(documentType.name, _.get(context, "language.id")))
                }
            }
        ]
    },
    {
        name: 'DocumentDatabase',
        facets: [
            'comments',
            { path: 'document', name: 'files', linkType: 'OTO' },
            { name: 'files', linkType: 'OTM' },
        ],
        fields: [
            'DocumentType',
            { path: 'title', uniqueWith: 'user' },
            'description',
            { type: 'Language', nullable: true },
            { type: "Profile", link: "MTM", nullable: true },
            { path: 'visible', type: 'boolean' },
            { type: 'User', nullable: true },
            { path: 'adminDocument', type: 'boolean', nullable: true},
            'dateAndTime',
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['adminDocument', 'user.id'],
                $f: function (documentDatabase, context, callback) {
                    const isUserAdmin = context.userModel.superCom_
                    return callback(
                        null,
                        documentDatabase.adminDocument
                            ? !isUserAdmin
                            : _.get(documentDatabase, 'user.id') !== context.user.id
                    )

                }
            }
        ],
        filters: [
            {
                name: 'eligibleForDocument',
                isDefault: false,
                query: context => {
                    if(context.userModel) {
                        const userProfiles =  context.userModel.profiles.map(profile => global.ObjectID(profile.id))
                        const isUserAdmin = context.userModel.superCom_
                        return isUserAdmin
                            ? {
                                $or: [
                                    {adminDocument: true},
                                    { user: global.ObjectID(context.user.id) }
                                ]
                            }
                            : {
                                $or: [
                                    { user: global.ObjectID(context.user.id) },
                                    {
                                        visible: true,
                                        profiles: { $elemMatch: {$in: userProfiles} },
                                        language: _.get(context, 'user.language.id')
                                    }
                                ]
                            }
                    }
                    return {}
                }
            }
        ],
        beforeSave: (object, oldObject, context, callback) => {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') {
                object.user = context.user
                object.adminDocument = !!context.userModel.superCom_
                if(!context.userModel.superCom_) {
                    object.language = context.user.language
                }
            }
            object.dateAndTime = moment().format('YYYY-MM-DD HH:mm')
            object.document = _.sortBy(object.files, 'date' ).reverse()[0]

            callback(null, object, oldObject)
        }
    }
]

export const modules = [
    {
        tKey: 'm-T-DocumentType',
        object: 'DocumentType',
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'name',
        defaultSortBy: 'name',
        defaultSortDirection: 'ASC',
        viewMap: {
            dt: [
                {path: 'name', type: 'translatedText'}
            ],
            form: [
                {path: 'name', type: 'textarea', placeholder: 'fr:: nom en français\nen:: nom en Anglais\nnl:: nom en Anglais'}
            ]
        }
    },
    {
        tKey: 'm-T-DocumentDatabase',
        object: 'DocumentDatabase',
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'title',
        defaultSortBy: 'dateAndTime',
        defaultSortDirection: 'DESC',
        viewMap: {
            dt: {
                fields: [
                    {path: 'documentType', display: 'tName'},
                    'title',
                    'dateAndTime',
                    'language',
                    'profiles',
                    'visible',
                    'document',
                    {path: 'noDeleteButtonAccess', hidden: true}
                ],
                onLoad: ({state, store}) => {
                    const isAdmin = isGroupModelCommunicationAdmin(state)
                    const fields = ['l_language', 'l_profiles', 'l_visible']
                    fields.forEach(
                        fieldId => store.dispatch(setFieldVisibility(fieldId, isAdmin))
                    )
                }
            },
            form: {
                fields: [
                    {path: 'documentType', display: 'tName'},
                    {path: 'title'},
                    {path: 'description', type: 'textarea'},
                    {path: 'language', editable: false},
                    {path: 'profiles', type: 'tags', filters: ['thisModel']},
                    {path: 'visible', default: false},
                    {path: 'files', type: 'files2'},
                    'comments',
                    {path: 'adminDocument', hidden: true},
                    {path: 'user', hidden: true}
                ],
                onOpen: ({ store }) => {
                    const state = store.getState()
                    const objectMode = state.ui.objectMode
                    const user = getUser(state)
                    const isAdmin = isGroupModelCommunicationAdmin(state)

                    const fields = ['e_language', 'e_profiles', 'e_visible']
                    fields.forEach(
                        fieldId => store.dispatch(setFieldVisibility(fieldId, isAdmin))
                    )

                    if(objectMode === 'editObject') {
                        const fileCreatorId = _.get(state, 'edit.object.data.user.id')
                        const createdByUser = user.id === fileCreatorId
                        const objectIsEditable = isAdmin || createdByUser
                        const formFields = state.fields.allIds.filter(id => id.split('_')[0] === 'e')
                        formFields.forEach(
                            fieldId => store.dispatch(setFieldEdition(fieldId, objectIsEditable))
                        )
                    }
                }
            }
        },
        filters: ['eligibleForDocument']
    }
]
