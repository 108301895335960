import React, { Fragment, Component } from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createStore from './createStore'
import reducer from './reducers'
import App from './components/App'
import Toastr from './components/Toastr'
import history from '../../history'

import { I18nextProvider } from 'react-i18next'
import kpI18n, { LANGUAGES } from '../../utils/i18nClient'
import { setLanguage } from './actions/index'

const getLanguagePartFromCode = code => {
    if (!code || code.indexOf('-') < 0) return code
    return code.split('-')[0]
}

export default class extends Component {
    constructor(props) {
        super(props)

        this.store = createStore('MainPage', reducer)
        this.state = { language: kpI18n.language }

        kpI18n.on('languageChanged', this.handleLanguageChange)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.language !== this.state.language
    }

    handleLanguageChange = language => {
        const lng = getLanguagePartFromCode(language)
        if (LANGUAGES.includes(lng)) {
            this.setState({ language: lng })
            this.store.dispatch(setLanguage(lng))
        } else {
            kpI18n.changeLanguage(LANGUAGES[0])
        }
    }

    render() {
        return this.state.language ? (
            <Provider store={this.store}>
                <I18nextProvider i18n={kpI18n}>
                    <Fragment>
                        <Router history={history}>
                            <App />
                        </Router>
                        <Toastr />
                    </Fragment>
                </I18nextProvider>
            </Provider>
        ) : null
    }
}
