import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Form.css'

const Form = ({ children, onSubmit, hideScroll }) => (
    <form className={classnames('AccordionForm',' form-horizontal', {'AccordionForm-HideScroll': hideScroll})} onSubmit={onSubmit}>
        {children}
    </form>
)

Form.propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func
}

export default Form
