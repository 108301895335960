import _ from "lodash"
import moment from "moment"
import Errors from "../../../utils/Errors"
import {translateName} from "../../../../utils/i18n"
// find the id in three collections, export names and code
/*
le principe de ce fonction: on connais que il y a des correspendance entre les trois collections,
par exemple: dans chaque record de subThemeElementDate, il existe un champ subTheme reference
un record dans collection subTheme. il est pareil avec subTheme et themeConfig.
Donc, il nous faut savoir que le id pour faire la requete a partir de quel collection.
s'il n'exist pas dans les trois collection, retour error.
s'il exist dans un collection, faire une requete en chaine(chaque fois,idInput se remplacer par la value
du champ reference de ce record.)
* */
export async function findInTripleCollection(id, strings) {
    let idInput = new global.ObjectID(id);

    const subThemeElementCollection = global.db.collection("s.subThemeElement");
    const subThemeCollection = global.db.collection("s.subTheme");
    const themeConfigCollection = global.db.collection("s.themeConfig");

    const subThemeElementInstance = await subThemeElementCollection.findOne({
        _id: idInput
    });

    idInput = _.get(subThemeElementInstance, ["subTheme"], idInput);
    const subThemeElementName = _.get(subThemeElementInstance, ["name"], "");
    const subThemeElementCode = _.get(subThemeElementInstance, ["code"], "");

    const subThemeInstance = await subThemeCollection.findOne({ _id: idInput });
    idInput = _.get(subThemeInstance, ["themeConfig"], idInput);

    const subThemeName = _.get(subThemeInstance, ["name"], "");
    const subThemeCode = _.get(subThemeInstance, ["code"], "");

    const themeConfigInstance = await themeConfigCollection.findOne({
        _id: idInput
    });
    const themeConfigName = _.get(themeConfigInstance, ["name"], "");
    const themeConfigCode = "theme-" + _.get(themeConfigInstance, ["code"], "");

    if (themeConfigInstance) {
        return strings.map(string => {
            const name = _.compact([
                themeConfigName,
                subThemeName,
                subThemeElementName,
                string
            ]).join("-");

            const key = _.compact([
                themeConfigCode,
                subThemeCode,
                subThemeElementCode,
                string
            ]).join(".");

            return {
                name,
                key
            };
        });
    } else {
        throw new Errors.ValidationError("Error: Id input erroneous, no record corresponds.");
    }
}

// program used for split the names, then we can translate by a function translate.
export function splitName(context, inputNames) {
    const names = inputNames.split("-");
    const nameInArray = names.map(name => context.translateName(name));
    return nameInArray.join(".");
}

// identify indicator we needed, field id is an array of ids inputs, field string is the field we needed.
export async function inputIdArray(context, strings, ids) {
    const names = await Promise.all(
        ids.map(async id => {
            return await findInTripleCollection(id, strings);
        })
    );

    const flattenNames = _.flattenDeep(names);

    return flattenNames.map(name => ({
        header: splitName(context, name.name),
        key: name.name,
        code: name.key,
        width: 30
    }));
}

//retrieve all organizations Axis; return an array
export async function allOrganizationAxis(context) {
    const organizationAxes = await global.db
        .collection("s.organizationAxis")
        .find()
        .toArray();

    return organizationAxes.map(organizationAxis => ({
        header: context.translateName(organizationAxis.name),
        key: organizationAxis.code,
        id: organizationAxis._id,
        width: 20
    }));
}

export async function tabOrganizationAxis() {
    const cursor = await global.db
        .collection("s.organizationAxis")
        .find()
        .toArray();

    return cursor.reduce(
        (acc, curr) => ({ ...acc, [curr._id]: curr.code }),
        {}
    );
}

async function generateFilter(context){
    const habilitations = await global.app.S.Habilitation.find({
        group: context.group,
        fieldPath: [
            "id",
            "isCentral",
            "habFunction.organizationAxisJoin.id",
            "habFunction.organizationAxisJoin.joinedEntity",
            "organizationsJoin.id"
        ],
        query: {
            user: new global.ObjectID(context.user.id)
        }
    });
    let habilitaionQueryOr = {};
    if (habilitations.length && !habilitations.some(habilitation => habilitation.isCentral)) {
        const { storeCodes, organizationIds } = habilitations.reduce(
            (storesAndOrganizations, habilitation) => {
                if (
                    habilitation.habFunction.organizationAxisJoin
                        .joinedEntity === "StoreAxis"
                ) {
                    Array.prototype.push.apply(
                        storesAndOrganizations.storeCodes,
                        habilitation.organizationsJoin.map(org => org.code)
                    )
                }
                if (
                    habilitation.habFunction.organizationAxisJoin
                        .joinedEntity === "OrganizationAxis"
                ) {
                    Array.prototype.push.apply(
                        storesAndOrganizations.organizationIds,
                        habilitation.organizationsJoin.map(org => new global.ObjectID(org.id))
                    )
                }
                return storesAndOrganizations;
            },
            { storeCodes: [], organizationIds: [] }
        );

        habilitaionQueryOr = {
            $or: _.flattenDeep(
                _.compact([
                    storeCodes.length && { store: { $in: storeCodes } },
                    organizationIds.length && {
                        organizations: { $in: organizationIds }
                    }
                ])
            )
        };
    }
    return habilitations.length ? habilitaionQueryOr : {_id: false};
}

export async function objectAddInWorksheet(
    matcher,
    indicator,
    context,
    callback
) {
    //constituent of groupByIndicator, which used to modify the result we are getting.
    const groupForm = {
        _id: {
            store: "$store",
            cashier: "$cashier",
            date: "$date",
            organizations: "$organizations"
        }
    };
    //constituent 2
    const formWithIndicator = indicator.reduce(
        (object, item) => ({
            ...object,
            [item.key.toString()]: { $sum: "$" + item.code.toString() }
        }),
        groupForm
    );

    //constituent 3
    const groupByIndicator = { $group: formWithIndicator };

    // create a cursor for operation following
    const slpElementsCursor = global.db.collection("slp.elemData").aggregate(
        [
            {
                $match: matcher
            },
            groupByIndicator
        ],
        { allowDiskUse: true }
    );

    const tabOrgAxis = await tabOrganizationAxis();

    while (await slpElementsCursor.hasNext()) {
        let object = await slpElementsCursor.next();

        Object.assign(object, object._id);

        const organizations = await global.db
            .collection("s.organization")
            .find({ _id: { $in: object.organizations } })
            .toArray();

        for (const organization of organizations) {
            const organizationAxiId = organization.organizationAxis.toString();
            object[tabOrgAxis[organizationAxiId]] = organization.name;
        }
        const store = await global.db
            .collection("s.store")
            .findOne({ code: object.store.toString() });
        object.store = `${store.code}-${store.name}`;

        callback(object);
    }
}

export async function exportColumns(object, context) {
    const columns = [];

    const ids = object.themeJoins.map(obj => obj.id);
    const indicator = await inputIdArray(context, ["Mt", "Nb"], ids);

    //add special field in columns, then we can perform addRow, if not we need to apply a new row to write.
    columns.push(...(await allOrganizationAxis(context)));

    //regroup version
    columns.push(
        { header: "Date", key: "date", width: 14 },
        { header: "Magasin", key: "store", width: 28 },
        { header: "Cashier", key: "cashier", width: 18 }
    );

    columns.push(...indicator);

    return {
        columns,
        indicator
    };
}

const configureWorkbook = (object, context) => async workbook => {
    const exportColumn = await exportColumns(object, context);

    workbook.creator = "yingzheng";
    workbook.created = new Date(2018, 5, 7);
    workbook.modified = new Date();
    workbook.lastPrinted = new Date(2016, 9, 27);
    workbook.addWorksheet("Reporting");
    const worksheet = workbook.getWorksheet("Reporting");
    worksheet.columns = exportColumn.columns;

    worksheet.getColumn("date").numFmt = "dd-MM-yyyy";
    worksheet.getRow("1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E0E0E0" },
        bgColor: { argb: "E0E0E0" }
    };
    worksheet.getRow("1").font = {
        bold: true
    };

    const writeRow = object => worksheet.addRow(object).commit();
    const habilitationQueryOr = await generateFilter(context);
    const matcher = {
        "date": {
            $gt: object.period[0],
            $lt: object.period[1]
        },
        ...habilitationQueryOr
    }
    await objectAddInWorksheet(
        matcher,
        exportColumn.indicator,
        context,
        writeRow
    );
};

export async function generateThemeExport(object, context) {
    try {
        const generatedFile = await generateExcel(`${context.tc('themeExport')}.xlsx`, object, context)
        const fileObject = {
            ...generatedFile,
            id: new global.ObjectID(generatedFile.id),
            user: _.get(context, 'user.name', 'unknown user'),
            date: moment().format('YYYY-MM-DD HH:mm')
        }
        const alertReportCollection = global.app.S.AlertReport.collection
        await alertReportCollection.updateOne(
            { _id: new global.ObjectID(object.id)},
            { $set: { file: fileObject} }
        )

        return {
            ...object,
            date: moment(object.date).format('YYYY-MM-DD HH:mm:ss'),
            period: object.period.map(o => moment(o).format('YYYY-MM-DD')),
            file: fileObject
        }
    } catch (e) {
        throw(e)
    }
}

async function generateExcel(filename, data, context){
    return new Promise((resolve, reject) => {
        global.excel.generateExcel(
            configureWorkbook(data, context),
            filename,
            (err, file) => {
                if (err) reject(err)
                else resolve(file)
            })
    })
}
