const _ = require("lodash");

const codeNameModuleFieldsForView = (view, options) => {
    const enhancedOptions = Object.assign({}, {editableCode: true}, options);

    const fieldsByView = {
        dt: [{
            path: "fullName"
            // title: "Code - Name"
        }],
        form: [{path: "code", editable: enhancedOptions.editableCode}, "name"]
    };
    return fieldsByView[view];
};

const defaultViews = [
    {name: "dt"},
    {name: "form"}
];

export default [
    {name: "codeName", norm: {fields: [
        {path: "views", default: defaultViews, $p: [
            {
                $if: function(views) { return _.isEmpty(views); },
                $u: function() { return _.cloneDeep(defaultViews); }
            },
            "fields"
        ]},
        {path: "views", fields: [
            {path: "fields", default: [], $p: {$u: function(fields, view) {
                const facetFields = codeNameModuleFieldsForView(view.name, this.options.facet);
                return _([
                    facetFields,
                    fields
                ]).flatten().compact().value();

            }}}
        ]}
    ]}}
];
