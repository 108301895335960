import React, { Component } from 'react'
import FilterDropdownField from './FilterDropdownField'
import FilterAsyncDropdownField from './FilterAsyncDropdownField'
import FilterTagsField from './FilterTagsField'
import FilterTextField from './FilterTextField'
import FilterNumberField from './FilterNumberField'
import FilterDatePicker from './FilterDatePicker'
import FilterDateRange from './FilterDateRange'
import FilterMonthPicker from './FilterMonthPicker'
import FilterMonthRangePicker from './FilterMonthRangePicker'
import Collapse from '../../../../components/Collapse'
import './FilterForm.css'


export const FILTER_FORM = 'filterObject'

const FilterField = ({ field, onChange, fetchFilterData, fetchFilterFieldsLists, t, language, groupModel }) => {
    switch(field.type) {
        case 'asyncDropdownObject':
            return <FilterAsyncDropdownField filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language}/>
        case 'dropdownObject':
            return <FilterDropdownField filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} groupModel={groupModel}/>
        case 'tags':
            return <FilterTagsField filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language}/>
        case 'text':
            return <FilterTextField filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        case 'number':
            return <FilterNumberField filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        case 'datePicker':
            return <FilterDatePicker filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        case 'dateRange':
            return <FilterDateRange filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        case 'monthPicker':
            return <FilterMonthPicker filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        case 'monthPickerRange':
            return <FilterMonthRangePicker filter={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} />
        default:
            return `Filter ${field.type} not available`
    }
}

class FilterForm extends Component {
    handleSubmit = event => {
        event.preventDefault()
    }

    render() {
        const { module, fields, filtersOpened, onChange, fetchFilterData, manuallyFetchFilterData, fetchFilterFieldsLists, t, language, isFormDirty, filterData, groupModel } = this.props
        return (
            <Collapse opened={filtersOpened}>
                <div>
                    <form className="FilterForm-form" onSubmit={this.handleSubmit}>
                        {
                            fields
                                .filter(field => !field.hidden)
                                .map(field => <FilterField key={field.path} field={field} onChange={onChange} fetchFilterData={fetchFilterData} fetchFilterFieldsLists={fetchFilterFieldsLists} t={t} language={language} groupModel={groupModel} />)}
                        {
                            module.manualFilters && <button className="btn btn-primary FilterForm-button" disabled={!isFormDirty} onClick={() => manuallyFetchFilterData(filterData)}>
                                {t('applyFilters')}
                            </button>
                        }
                    </form>
                    <hr className="FilterForm-hr"/>
                </div>
            </Collapse>
        )
    }
}

export default FilterForm
