import moment from "moment";
import _ from "lodash";
import dd from './server/default-engine/engine.js'
import * as workflowNormalizer from './server/enhancers/workflow/workflowCommon'

// engine processes, need to be added once in the code
dd.addProcesses(require('./server/default-engine/basicValidators'))
dd.addProcesses(require('./server/default-engine/basicUpdaters'))
dd.addProcesses(require('./server/default-engine/otherUpdaters'))

global.appRoot = "/"
global.isClient = true
global.moment = moment
global._ = _
global.workflowNormalizer = workflowNormalizer
