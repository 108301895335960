import React from 'react'
import { Field } from 'redux-form'
import PasswordField from '../../../../components/Password'
import getFormValidations from './formValidations'

// binding for redux-form
const Input = ({ input, meta:{touched, error}, path, required, editable, disabled, placeholder, t }) => {
    const translatedError = t(error)
    return <PasswordField {...input} path={path} required={required} touched={touched ? 'true' : 'false'} error={translatedError} readOnly={!editable} disabled={disabled} placeholder={placeholder} t={t}/>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormPasswordField = ({ field, t, objectMode }) => {

    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            required={field.required}
            component={Input}
            placeholder={field.placeholder}
            validate={validations}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            translate={field.translate}
            t={t}
        />
    )
}

export default FormPasswordField
