const moment = require("moment");
const _ = require("lodash");
const {generateAlertReport, generateIndicatorReport} = require("./utils/generateReport")
const {setFieldVisibility} = require("../../../apps/KpModule/actions")

export const dependencies = [
    {
        name: "ReportType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "indicator", name: "indicators"},
            {id: "alert", name: "alerts"}
        ]
    },
    {
        name: "Periodicity",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "day", name: "day"},
            {id: "week", name: "week"},
            {id: "month", name: "month"}
        ]
    }
]

export const entity = {
    name: "Report",
    facets: [{name: "files", linkType: "OTO"}],
    fields: [
        "title",
        {path: "date", type: "date", dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
        {path: "period", type: "dateRange"},
        {path: "periodicity", type: "Periodicity"},
        {
            path: "user",
            type: "User",
            link: "MTO",
            nullable: true
        },
        {path: "cumulative", type: "boolean"},
        {path: "alertConfigurations", type: "AlertConfiguration", link: "MTM"},
        {
            type: "ReportType",
            link: "MTO"
        },
        {path: "indicators", type: "ThemeJoin", link: "MTM", nullable: true}
        //{path: "automatic", type: "boolean", default: true}
    ],
    beforeSave: function(object, oldObject, context, callback) {
        object.user = _.pick(context.user, ['id', 'name'])
        object.date = moment().format('YYYY-MM-DD HH:mm:ss')
        callback(null, object, oldObject)
    },
    afterSave: function(object, oldObject, context, callback) {
        const reportToLaunch = object.reportType.id === 'alert'
            ? generateAlertReport
            : generateIndicatorReport
        reportToLaunch(object, context)
            .then(object => {
                global.ioSocket.emit(
                    `fetchEditObject-success-${context.user.id}${context.clientContext.accessId}`,
                    object
                )
            })
            .catch(err => {
                global.ioSocket.emit(
                    `fetchEditObject-failure-${context.user.id}${context.clientContext.accessId}`,
                    {error: err.message}
                )
            })

        callback()
    }
}

export const module_ = {
    object: "Report",
    tKey: "mTitle_report",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    newable: true,
    editable: false,
    useSocketsOnSave: true,
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            {path: "title", type: "translatedText"},
            {path: "reportType"},
            {path: "period", type: "dateRange"},
            {path: "periodicity"},
            {path: "date", tKey: 'generatedAt'},
            "file"
            //{path: "user", display: "name"},
        ],
        form: {
            fields: [
                {path: "title", type: "textarea"},
                {path: "period", type: "dateRange"},
                {
                    path: "reportType",
                    subscriptions : {
                        onChange: (newValue, oldValue, {store}) => {
                            const isIndicatorReport = newValue && newValue.id === "indicator"
                            const isAlertReport = newValue && newValue.id === "alert"
                            store.dispatch(setFieldVisibility("e_indicators", isIndicatorReport))
                            store.dispatch(setFieldVisibility("e_alertConfigurations", isAlertReport))
                        }
                    }
                },
                {path: "alertConfigurations", translateName: true},
                {path: "indicators", display: 'completeName', fieldPath: ['id', 'code', 'name', 'completeCode', 'completeName', 'mtKeyName'], translateName: true},
                {path: "periodicity"},
                {path: "cumulative", tKey: "cumuler les résultats"}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const isIndicatorReport = state.edit.object.data?.reportType?.id === "indicator"
                const isAlertReport = state.edit.object.data?.reportType?.id === "alert"
                store.dispatch(setFieldVisibility("e_indicators", isIndicatorReport))
                store.dispatch(setFieldVisibility("e_indicators", isAlertReport))
            }
        }
    },
    filters: [
        {
            path: "byUser",
            query: function(context){
                const userId = _.get(context, "user.id")
                return userId
                    ? {user: global.ObjectID(userId)}
                    : {_id: null}
            }
        },
        /*
        {
            type: "datePicker",
            path: "date",
            default: moment().format("YYYY-MM-DD"),
            client: true,
            match: function (object, context) {
                const filterDate = context.data.date;
                const date = moment(object.date).format("YYYY-MM-DD");
                return filterDate === date;
            }
        }
         */
    ],
}
