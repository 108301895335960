import _ from "lodash";
import {changeFieldProperty} from "../../../../apps/KpModule/actions";


export const findFormField = (module, path) => module.viewMap.form.fields.find(field => field.path === path);


export function handleParentColumnsForTable(store, tableId, ongoingFiscalYear) {
    const state = store.getState()
    const fieldIds = _.get(state, 'fields.allIds', [])
    store.dispatch(changeFieldProperty(`${tableId}.l_revised`, 'parentColumn', 'Global'))
    store.dispatch(changeFieldProperty(`${tableId}.l_validated`, 'parentColumn', 'Global'))
    store.dispatch(changeFieldProperty(`${tableId}.l_estimated`, 'parentColumn', 'Global'))
    store.dispatch(changeFieldProperty(`${tableId}.l_budget`, 'parentColumn', 'Global'))
    store.dispatch(changeFieldProperty(`${tableId}.l_validatedEngagement`, 'parentColumn', 'Global'))

    const ongoingFiscalYearLabel = `Exercice ${ongoingFiscalYear.code}`

    store.dispatch(changeFieldProperty(`${tableId}.l_ongoingFiscalYearRevised`, 'parentColumn', ongoingFiscalYearLabel))
    store.dispatch(changeFieldProperty(`${tableId}.l_ongoingFiscalYearValidated`, 'parentColumn', ongoingFiscalYearLabel))
    store.dispatch(changeFieldProperty(`${tableId}.l_ongoingFiscalYearEstimated`, 'parentColumn', ongoingFiscalYearLabel))
    store.dispatch(changeFieldProperty(`${tableId}.l_ongoingFiscalYearBudget`, 'parentColumn', ongoingFiscalYearLabel))
    store.dispatch(changeFieldProperty(`${tableId}.l_ongoingFiscalYearValidatedEngagement`, 'parentColumn', ongoingFiscalYearLabel))

    const dynamicColumns = fieldIds.filter(id => id.startsWith(`${tableId}.l_dynamicAmountsTypes`))

    dynamicColumns.forEach(columnId => {
        const columnPath = columnId.split(".")[2]
        const prefix = "ongoingFiscalYear"
        if(columnPath.startsWith(prefix)) {
            store.dispatch(changeFieldProperty(columnId, 'parentColumn', ongoingFiscalYearLabel))
            store.dispatch(changeFieldProperty(columnId, 'tKey', columnPath.slice(prefix.length)))
            return
        }
        return store.dispatch(changeFieldProperty(columnId, 'parentColumn', 'Global'))
    })
}