import _ from 'lodash'
const { textSearchQuery } = require("../../utils/mongoUtils")

const moment = require('moment')
const { byArticle } = require('./filters')

const todayISO = moment().format("YYYY-MM-DD")
const lastDayISO = moment().subtract(1, "days").format("YYYY-MM-DD")

const byCompany = {
    title: "Company",
    path: "company",
    tKey: "company",
    placeholder: 'selectCompany',
    default: {id: null},
    object: "Company",
    objectFieldPath: ["company", "company.id"],
    display: "fullName",
    isDefault: false,
    clearable: true,
    autoFetch: false,
    query: function (context) {
        const companyId = _.get(context, "data.company.id");

        return companyId
            ? {company: new global.ObjectID(companyId)}
            : {_id: false}
    }
}

async function findData(context) {
    const dates = _.get(context, 'data.dates', [lastDayISO, todayISO])
    const datesQuery = {
        'date': {
            $gte: dates[0],
            $lte: dates[1]
        },
    };

    const companyFullName = _.get(context, 'data.company.fullName')
    const companyCode = companyFullName ? companyFullName.split(" - ")[0] : ''
    const companyCodeQuery = companyCode ? {'companyCode': companyCode} : null

    const subFamilyFullName = _.get(context, 'data.subFamily.fullName')
    const subFamilyCode = subFamilyFullName ? subFamilyFullName.split(" - ")[0] : ''
    const subFamilyCodeQuery = subFamilyCode ? {'subFamilyCode': subFamilyCode} : null

    const familyFullName = _.get(context, 'data.family.fullName')
    const familyCode = familyFullName ? familyFullName.split(" - ")[0] : ''
    const familyCodeQuery = familyCode ? {'familyCode': familyCode} : null

    const surFamilyFullName = _.get(context, 'data.surFamily.fullName')
    const surFamilyCode = surFamilyFullName ? surFamilyFullName.split(" - ")[0] : ''
    const surFamilyCodeQuery = surFamilyCode ? {'surFamilyCode': surFamilyCode} : null

    const groupSurFamilyFullName = _.get(context, 'data.groupSurFamily.fullName')
    const groupSurFamilyCode = groupSurFamilyFullName ? groupSurFamilyFullName.split(" - ")[0] : ''
    const groupSurFamilyCodeQuery = groupSurFamilyCode ? {'groupSurFamilyCode': groupSurFamilyCode} : null

    const articleFullName = _.get(context, 'data.article.fullName')
    const articleCode = articleFullName ? articleFullName.split(" - ")[0] : ''
    const articleCodeQuery = articleCode ? {'articleCode': articleCode} : null

    const clientFullName = _.get(context, 'data.client.fullName')
    const clientCode = clientFullName ? clientFullName.split(" - ")[0] : ''
    const clientCodeQuery = clientCode ? {'clientCode': clientCode} : null

    const providerFullName = _.get(context, 'data.provider.fullName')
    const providerCode = providerFullName ? providerFullName.split(" - ")[0] : ''
    const providerCodeQuery = providerCode ? {'supplierCode': providerCode} : null

    const depositFullName = _.get(context, 'data.deposit.fullName')
    const depositCode = depositFullName ? depositFullName.split(" - ")[0] : ''
    const depositCodeQuery = depositCode ? {'depositCode': depositCode} : null

    const clientDepositFullName = _.get(context, 'data.clientDeposit.fullName')
    const clientDepositCode = clientDepositFullName ? clientDepositFullName.split(" - ")[0] : ''
    const clientDepositCodeQuery = clientDepositCode ? {'clientDepositCode': clientDepositCode} : null

    const dataNature = _.get(context, 'data.dataNature.id')
    const dataNatureQuery = {'dataNature': dataNature}

    const elementaryDataPromise = global.app.U.ElementaryData.collection.find({...datesQuery, ...companyCodeQuery, ...dataNatureQuery, ...clientCodeQuery, ...articleCodeQuery, ...providerCodeQuery, ...subFamilyCodeQuery, ...familyCodeQuery, ...surFamilyCodeQuery, ...groupSurFamilyCodeQuery, ...depositCodeQuery, ...clientDepositCodeQuery}).toArray()

    const [elementaryDatas] = await Promise.all([elementaryDataPromise])

    return  elementaryDatas.map(o => ({...o, id: o._id}))
}


export const entities = [
    {
        name: "DataNature",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "FAC", name: "FAC"},
            {id: "FNL", name: "FNL"},
            {id: "FNP", name: "FNP"},
            {id: "LNF", name: "LNF"},
            {id: "STJ", name: "STJ"},
            {id: "STO", name: "STO"},
            {id: "TRA", name: "TRA"},
            {id: "TRV", name: "TRV"},
            {id: "ACH", name: "ACH"},
            {id: "RFC", name: "RFC"},
            {id: 'CCA', name: 'CCA'}
        ]
    },
    {
        name: "ElementaryData",
        fields: [
            {path: 'companyCode', index: true}, {path: 'dataOrigin', index: true}, 'date', {path: 'dataNature', index: true}, 'depositCode', 'piece', 'mainId', 'secondaryId',
            'dataMesh', 'element', 'clientCode', 'supplierCode', 'quantity', 'value', 'lineIndex', 'importDate',
            'fileName', {path: 'fileYearMonth', index: true}, {path: 'yearMonth', index: true}, 'year', 'month', 'day', 'depositZoneCode', 'clientType',
            'clientDepositCode', 'clientCompanyCode', 'clientState', 'perimeter', 'articleCode', 'articleState',
            'articleCompanyCode', 'subFamilyCode', 'familyCode', 'surFamilyCode', 'groupSurFamilyCode'
        ],
        indexes: [
            {key: {companyCode : 1, fileYearMonth : 1, yearMonth : 1, dataNature : 1, dataOrigin : 1}},
            {key: {fileYearMonth : 1, yearMonth : 1, dataNature : 1, dataOrigin : 1}}
        ]
    },
    {
        name: 'ElementaryDataModule',
        fields: [
            'companyCode', 'dataOrigin', 'date', 'dataNature', 'depositCode', 'piece', 'mainId', 'secondaryId',
            'dataMesh', 'element', 'clientCode', 'supplierCode', 'supplierType', 'supplierState', 'reason', 'quantity', 'value', 'lineIndex', 'importDate',
            'fileName', 'fileYearMonth', 'yearMonth', 'year', 'month', 'day', 'depositZoneCode', 'clientType',
            'clientDepositCode', 'clientCompanyCode', 'clientState', 'perimeter', 'articleCode', 'articleState',
            'articleCompanyCode', 'subFamilyCode', 'familyCode', 'surFamilyCode', 'groupSurFamilyCode', 'depositDate', 'clientDate', 'supplierDate', 'articleDate', 'subFamilyDate', 'familyDate'
        ],
        filters: [byArticle],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else
                    findData(context)
                        .then(objects => callback(null, objects))
                        .catch(error => callback(error))
            })
        }
    }
]

export const module_ = {
    object: "ElementaryDataModule",
    tKey: "Elementary Data",
    newable: false,
    removable: false,
    category: {
        path: 'keenpoint',
        icon: 'server'
    },
    viewMap: {
        dt: [
            'importDate',
            'fileName',
            'fileYearMonth',
            'dataOrigin',
            'date',
            'yearMonth',
            'year',
            'month',
            'day',
            'companyCode',
            'dataNature',
            'depositCode',
            'depositZoneCode',
            'dataMesh',
            'groupSurFamilyCode',
            'surFamilyCode',
            'familyCode',
            'subFamilyCode',
            'articleCode',
            'articleState',
            'articleCompanyCode',
            'piece',
            'mainId',
            'secondaryId',
            'perimeter',
            'clientType',
            'clientCode',
            'clientDepositCode',
            'clientCompanyCode',
            'clientState',
            'supplierCode',
            'supplierType',
            'supplierState',
            'reason',
            'quantity',
            'value',
            'element',
            'lineIndex',
            'depositDate',
            'clientDate',
            'supplierDate',
            'articleDate',
            'subFamilyDate',
            'familyDate'
        ]
    },
    filters: [
        {
            path: 'dates',
            type: 'dateRange',
            client: true,
            default: [lastDayISO, todayISO]
        },
        {
            title: 'Client',
            path: 'client',
            object: 'Client',
            placeholder: 'selectClient',
            objectFieldPath: ["client.id"],
            type: 'asyncDropdownObject',
            display: 'fullName',
            filters:[
                {
                    title: "byClientCode",
                    path: "clientCode",
                    tKey: "clientCode",
                    type: "text",
                    query: context => {
                        const params = context.clientContext.params
                        const search = params && params.search

                        const keys = ["code", "name"]

                        return textSearchQuery(search, keys);
                    }
                },
                {
                    ...byCompany,
                    client: true
                }
            ],
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'Article',
            path: 'article',
            object: 'Article',
            placeholder: 'selectArticle',
            type: 'asyncDropdownObject',
            objectFieldPath: ["article.id"],
            display: 'fullName',
            filters:[
                {
                    title: "byCode",
                    path: "byCode",
                    tKey: "byCode",
                    type: "text",
                    query: context => {
                        const params = context.clientContext.params
                        const search = params && params.search

                        const keys = ["code", "name"]

                        return textSearchQuery(search, keys);
                    }
                },
                byCompany
            ],
            clearable: true,
            isDefault: false,
            client: true,
        },
        {
            title: 'Provider',
            path: 'provider',
            object: 'Provider',
            placeholder: 'selectProvider',
            objectFieldPath: ["provider.id"],
            type: 'asyncDropdownObject',
            display: 'fullName',
            filters:[
                {
                    title: "byProviderCode",
                    path: "providerCode",
                    tKey: "providerCode",
                    type: "text",
                    query: context => {
                        const params = context.clientContext.params
                        const search = params && params.search

                        const keys = ["code", "name"]

                        return textSearchQuery(search, keys);
                    }
                },
                byCompany
            ],
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'SubFamily',
            path: 'subFamily',
            object: 'SubFamily',
            placeholder: 'selectSubFamily',
            objectFieldPath: ["subFamily.id"],
            display: 'fullName',
            filters:[byCompany],
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'Family',
            path: 'family',
            object: 'Family',
            placeholder: 'selectFamily',
            objectFieldPath: ["family.id"],
            display: 'fullName',
            filters:[byCompany],
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'SurFamily',
            path: 'surFamily',
            object: 'SurFamily',
            placeholder: 'selectSurFamily',
            objectFieldPath: ["surFamily.id"],
            display: 'fullName',
            filters:[byCompany],
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'GroupSurFamily',
            path: 'groupSurFamily',
            object: 'GroupSurFamily',
            default: {id: null},
            placeholder: 'selectGroupSurFamily',
            objectFieldPath: ["groupSurFamily.id"],
            display: 'fullName',
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'Deposit',
            path: 'deposit',
            object: 'Deposit',
            placeholder: 'selectDeposit',
            default: {id: null},
            objectFieldPath: ["deposit.id"],
            display: 'fullName',
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            title: 'ClientDeposit',
            path: 'clientDeposit',
            object: 'Deposit',
            placeholder: 'selectClientDeposit',
            default: {id: null},
            objectFieldPath: ["deposit.id"],
            display: 'fullName',
            clearable: true,
            isDefault: false,
            client: true
        },
        {
            path: "dataNature",
            object: "DataNature",
            placeholder: 'selectDataNature',
            display: "name",
            client: true,
            clearable: true,
            default: {id: null}
        }
    ]
}
