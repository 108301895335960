import React, { useLayoutEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import './ImagesViewer.css'


const MAX_SIZE_THUMB = 50

const customRenderThumb = children => {
    return children.map( item => {
        const srcSplit = item.props.src.split('_')
        let itemName = srcSplit.slice(2).join('_').split('.')[0]
        if (itemName.length > MAX_SIZE_THUMB) {
            itemName = itemName.slice(0, MAX_SIZE_THUMB)
            itemName = itemName + '...'
        }

        return (
            <div
                className='imageThumbnail'
                key={`imageThumbnail:${item.props.src}`}
                onClick={heightCalc}
            >
                <img src={item.props.src} alt='imageThumbnail'/>
                <p>{itemName}</p>
            </div>
        )
    })
}

const customRenderItem = item => {
    const srcSplit = item.props.src.split('_')
    let itemName = srcSplit.slice(2).join('_').split('.')[0]

    return (
        <div className='imageItem'>
            {item}
        </div>
    )
}

const heightCalc = () => {
    /* dynamic calculation of playlist height */
    const imageItemDiv = document.querySelectorAll('.imgs .slider-wrapper.axis-horizontal')[0]
    const playlistDiv = document.querySelectorAll('.imgs .thumbs-wrapper.axis-vertical')[0]
    if(imageItemDiv && playlistDiv) {
        playlistDiv.style.height = imageItemDiv.offsetHeight + 'px'
    }
}


const MediaViewer = props => {

    useLayoutEffect(() => {
        if (props.data[props.path] && props.data[props.path].length !== 0) {
            window.addEventListener('resize', heightCalc)
            return () => {
                window.removeEventListener('resize', heightCalc)
            }
        }
    }, [props.data[props.path] ? props.data[props.path].length : 0])


    const mediaELements = _.get(props, `data.${props.path}`) || []

    return (
        <div className='imgs' style={{...(props.style || {}), ...(props.containerStyle || {})}}>
            <Carousel
                renderItem={customRenderItem}
                renderThumbs={customRenderThumb}
                showThumbs={false}
                showIndicators={mediaELements?.length > 1}
                showStatus={false}
                //showArrows={true}
                swipeable={false}
            >
                { mediaELements.map(element => {
                    const fullDate = moment(element.date).format('YYYY-MM-DD_HH-mm-ss')
                    if(element.type === 'image') return (
                        <img
                            key={`${element.date}_${element.name}`}
                            src={`/image/${fullDate}_${element.name}`}
                            alt={element.name}
                            onLoad={heightCalc}
                            width='100%'
                            height={props.mediaHeight || 'auto'}
                            style={props.style}
                        />
                    )
                    const fullPosterDate = element.poster && moment(element.poster.date).format('YYYY-MM-DD_HH-mm-ss')
                    const poster = element.poster && `/image/${fullPosterDate}_${element.poster.name}`
                    return (
                        <video
                            key={`${element.date}_${element.name}`}
                            src={`/video/${fullDate}_${element.name}`}
                            poster={poster}
                            onCanPlay={heightCalc}
                            width='100%'
                            height={props.mediaHeight || 'auto'}
                            style={{outline: 'none'}}
                            controls
                        />
                    )
                })}
            </Carousel>
        </div>
    )
}

export default MediaViewer
