import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './QuillTextEditor.css'
import ValidationUI from "../ValidationUI";
import axios from 'axios'
import {toastr} from "react-redux-toastr";
import moment from "moment";

const Quill = ReactQuill.Quill
const Font = Quill.import('formats/font')
Font.whitelist = ['', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times', 'Trebuchet', 'Verdana']
Quill.register(Font, true)


const BlockEmbed = Quill.import('blots/block/embed')
class ImageBlot extends BlockEmbed {
    static create(value) {
        let node = super.create();
        node.setAttribute('src', value.src)
        if (value.style) {
            node.setAttribute('style', value.style)
        }
        return node;
    }

    static value(node) {
        return {
            src: node.getAttribute('src'),
            style: node.getAttribute('style')
        };
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot)

class Editor extends React.Component {
    constructor (props) {
        super(props)
        this.quillRef = React.createRef()
        this.handleChange = this.handleChange.bind(this)
        this.selectLocalImage = this.selectLocalImage.bind(this)
        this.saveToServer = this.saveToServer.bind(this)
        this.insertToEditor = this.insertToEditor.bind(this)
    }

    handleChange (html) {
        this.props.onChange(html)
    }

    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file)
            } else {
                console.warn('You could only upload images.');
            }
        };
    }

    saveToServer(file) {
        const data = new FormData()

        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024

        let fileChunk = null, chunkNb = 0
        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('imageChunks', fileChunk, file.name)
        }

        if(this.props.user) {
            data.append('user', JSON.stringify(this.props.user))
        }
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        axios.post('/image', data, options)
            .then(res => {
                const {name, date} = res.data
                const fullDate = moment(date).format('YYYY-MM-DD_HH-mm-ss')
                const url = `/image/${fullDate}_${name}`
                this.insertToEditor(url)
            })
            .catch(() => {
                toastr.error(this.props.t('errorUploading'))
            })
    }

    insertToEditor(url) {
        const editor = this.quillRef.current.getEditor()
        const range = editor.getSelection()

        const hostname = window.location.hostname
        const link = hostname === 'localhost'
            ?  `http://${hostname}:9003${url}`
            :  `https://${hostname}${url}`

        editor.insertEmbed(range.index, 'image', {
            src: link,
            style: 'max-width: 100%; height: auto; display: block;'
        });
    }
    static formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background', 'list', 'bullet', 'indent', 'align',
        'link',
        'image'
        //, 'video'
    ]

    render() {
        const { value, touched, error, path, required, t, readOnly, disabled, placeholder } = this.props

        const modules = {
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': Font.whitelist }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}, { 'align': [] }],
                    ['link', 'image'],
                    ['clean']
                ],
                handlers: {
                    image: this.selectLocalImage
                }
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        }

        if(readOnly) {
            return (
                <div className="ql-container ql-snow ql-disabled" style={{border: 'none'}}>
                    <div className="ql-editor" data-gramm="false" contentEditable="false">
                        <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <label className="QuillTextEditor-label">
                    {t(path)} {required && "*"}
                </label>
                <div style={{padding: '5px'}}>
                    <ReactQuill
                        ref={this.quillRef}
                        theme={'snow'}
                        readOnly={disabled}
                        onChange={this.handleChange}
                        value={value || ""}
                        modules={modules}
                        formats={Editor.formats}
                        placeholder={placeholder}
                    />
                </div>
                {touched === 'true' && (error && <ValidationUI error={error} />)}
            </div>
        )
    }
}

export default Editor
