const _ = require('lodash')
const { basicContext } = require("../../utils/contextUtils")
const { fieldPathJoinGetter } = require("../../utils/kp3Utils")
const Errors = require("../../utils/Errors").default

export const entity = {
    name: 'GroupSurFamily',
    fields: [
        { path: 'code', unique: true},
        { path: 'name', unique: true},
        { path: 'correspondence', type: 'SurFamily', link: 'MTM'},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"]
        })
    ],
    validateSave: function(groupSurFamily, oldGroupSurFamily, context, callback) {


        global.app.U.GroupSurFamily.find(
            {
                ...basicContext(context),
                fieldPath: ['code', 'name', 'correspondence'],
                query: {_id: { $ne: global.ObjectID(groupSurFamily.id) }}
            },
            (e, groups) => {
                if (e) return callback(e)
                else {
                    const surFamilysIds =_.flatten(groups.map(group => group.correspondence)).map(family => family.id)

                    if (surFamilysIds.length === 0) {
                        return callback()
                    }
                    else {
                        const duplicate = groupSurFamily.correspondence.some(surFamily => {
                            return surFamilysIds.includes(surFamily.id)
                        })
                        if(duplicate) return callback(new Errors.ValidationError('surFamilyBelongsToOnlyOneGroup'))
                        else return callback()
                    }
                }
            })
    }
}

export const module_ = {
    object: 'GroupSurFamily',
    tKey: 'mTitle_groupSurFamily',
    removable: false,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'referential',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'code', width: 100},
            'name',
            {path: 'correspondence', tKey: 'correspondence_plural', display: 'nameWithCompanyCode'}
        ],
        form: [
            { path: 'code', required: true, editable: false },
            { path: 'name', required: true },
            { path: 'correspondence', tKey: 'surFamily_plural', display: 'nameWithCompanyCode'}
        ]
    }
}
