export const entities = [
    {
        name: 'Anticipation',
        fields: [
            {path: 'year', type: 'integer', notEmpty: true},
            {path: 'amount', type: 'integer'}
        ]
    },
    {
        name: 'Budget',
        facets:[
            {name: 'codeName', codeUniqueWith: 'sublot', nameOptional: false}
        ],
        fields:[
            {path: 'amount', type: 'integer'},
            'Sublot',
            'Nature',
            'Currency',
            {type: 'Anticipation', link: "OTM"},
            {
                path: 'lot',
                fieldPath: ['sublot.id', 'sublot.lot', 'sublot.lot.fullName'],
                f: function () {
                    return this.sublot && this.sublot.lot && this.sublot.lot.fullName
                }
            },
            {
                path: 'project',
                fieldPath: ['sublot.id', 'sublot.lot', 'sublot.lot.project', 'sublot.lot.project.fullName'],
                f: function () {
                    return this.sublot && this.sublot.lot && this.sublot.lot.project && this.sublot.lot.project.fullName
                }
            }
        ],
        validateSave: function(object, oldObject, context, callback) {
            const years = object.anticipations.map(o => o.year)
            if(years.length < 1) {
                callback()
            } else {
                global.app.E.ExchangeRate.find({_id: global.ObjectID(object.currency.id), group: context.group}, (e, results) => {
                    if(e) callback(e)
                    else {
                        const array = years.filter(year => {
                            const exchangeRate = results.find(result => result.year === year)
                            return !exchangeRate
                        })
                        if(array.length === 0) {
                            return callback()
                        } else if(array.length === 1) {
                            return callback(new Error(context.tc('exchangeRateNotFound', {year: array[0]})))
                        } else if(array.length > 1) {
                            return callback(new Error(context.tc('exchangeRatesNotFound', {years: array.join(' , ')})))
                        }
                    }
                })
            }
        },
    }
]

export const module_ = {
    object: 'Budget',
    tKey: 'mTitle_budget',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'settings',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            'project',
            'lot',
            {path: 'sublot', display: 'fullName'},
            {path: 'nature', display: 'fullName'},
            'code','name',
            {path: 'currency', display: 'code'},
            {path: 'amount', tKey: 'amount(k)'}
        ],
        form: [
            {path: 'sublot', display: 'fullNameWithLotAndProject'},
            {path: 'nature', display: 'fullName'},
            'code', 'name',
            {path: 'currency', display: 'code'},
            {path: 'amount', tKey: 'amount(k)', disabled: true},
            {
                path: 'anticipations',
                defaultSortBy: 'year',
                defaultSortDirection: 'ASC',
                viewMap: {
                    dt: [
                        'year',
                        {path: 'amount', tKey: 'amount(k)'}
                    ],
                    form: [
                        {path: 'year', editable: false, conversionRateYear: true},
                        {path: 'amount', tKey: 'amount(k)', default: 0, positiveNumber: true}
                    ]
                },
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (!formInitialize && newValue && newValue.length > 0) {
                            console.log(newValue)
                            const field = module.viewMap.form.fields.find(
                                field => field.path === 'amount'
                            )
                            const amount = newValue.reduce((acc, line) => acc + parseInt(line.amount, 10), 0)

                            field.setValue(amount)
                        }
                    }
                }
            }
        ]
    }
}
