import React from 'react'
import './SpinningDots.css'

const SpinningDots = () => {
    return(
        <div className="SpinningDots">
            <div className="SpinningDots-loader">
                <span className="SpinningDot dot_1" />
                <span className="SpinningDot dot_2" />
                <span className="SpinningDot dot_3" />
                <span className="SpinningDot dot_4" />
            </div>
        </div>
    )
}

export default SpinningDots