const _ = require('lodash');
const {change} = require('redux-form');
const { actionTypes: reduxFormActions } = require('redux-form')
const { FILTER_FORM } = require('../../../../../apps/KpModule/components/Filter/FilterForm')
const { setEditDefaultObject } = require('../../../../../apps/KpModule/actions')
const { getEditDefaultData } = require('../../../../../apps/KpModule/selectors')


const defaultBusinessProjectSubscriptions = [
    {
        actionType: reduxFormActions.INITIALIZE,
        subscription: ({ store, action }) => {
            if (action.meta.form === FILTER_FORM) {
                const state = store.getState()
                const businessProjectId = _.get(action, 'payload.businessProject.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    businessProject: businessProjectId ? { id: businessProjectId } : null
                }))
            }
        }
    },
    {
        actionType: reduxFormActions.CHANGE,
        subscription: ({ store, action }) => {
            if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'businessProject')) {
                const state = store.getState()
                const businessProjectId = _.get(action, 'payload.id')
                const defaultData = getEditDefaultData(state)

                store.dispatch(setEditDefaultObject({
                    ...defaultData,
                    businessProject: businessProjectId ? { id: businessProjectId } : null
                }))
            }
        }
    }
]

const getProjectNatureFromDataList = (rawObject, dataList) => {
    if (rawObject && rawObject.id) return dataList.list.byId[rawObject.id];
}

const defaultSammariesUnits = nYears => {
    const yearValues = _.range(1, nYears).reduce(
        (acc, i) => Object.assign({[`N+${i}`]: 0}, acc),
        {N: 0}
    );

    return require("../../staticEntities").default.find(
        o => o.name === "SummariesUnit"
    ).objects.map(
        summariesUnit => Object.assign({summariesUnit}, yearValues)
    )
};

export const financialSummary = {
    name: "financialSummaries",
    object: "FinancialSummary",
    category: {
        path: "projects",
        icon: 'briefcase'
    },
    defaultSortBy: "projectNature",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            {
                path: "businessProject.countryAndFullName",
                tKey: "pays - Projet",
                initiallyNotVisible: true,
                width: 400
            },
            {
                path: "businessProject.beginYear.name",
                tKey: "beginYear",
                width: 100
            },
            {path: "projectNature", tKey: "lotType", width: 100},
            {path: "lot", width: 500},
            {path: "financialTurnover", tKey: "turnoverKE", tooltip: true, width: 80},
            {path: "financialExpenses", tKey: "costKE", tooltip: true, width: 80},
            {path: "financialNumberOfDays", tKey: "numberOfDaysAbb", tooltip: true, width: 60},
            {
                path: "businessProject.statusAndOrder",
                tKey: "statusAndOrder",
                initiallyNotVisible: true,
                width: 300
            }
        ],
        form: [
            {
                path: "businessProject",
                tKey: "beginYearAndProject",
                display: "beginYearAndProject",
                writable: false,
                filters: ["businessProjectInProgress"],
            },
            {
                path: "projectNature",
                tKey: "lotType",
                required: true,
                editable: false,
                clearable: false
            },
            {path: "lot", required: true},
            {path: "description", type: "richTextEditor"},
            {
                path: "summaryTableHeaders",
                type: "dtObjects",
                tKey: "synthesis",
                wholePositiveNumbers: true,
                fields: [
                    {path: "summariesUnit.id", tKey: "", hidden: true},
                    {path: "summariesUnit.name", tKey: "composant"},
                    {path: "N", type: "editText", componentClass: "mini-input"},
                    {path: "N+1", type: "editText", componentClass: "mini-input"},
                    {path: "N+2", type: "editText", componentClass: "mini-input"},
                    {path: "N+3", type: "editText", componentClass: "mini-input"},
                    {path: "N+4", type: "editText", componentClass: "mini-input"},
                    {path: "N+5", type: "editText", componentClass: "mini-input"},
                    {path: "N+6", type: "editText", componentClass: "mini-input"},
                    {path: "N+7", type: "editText", componentClass: "mini-input"},
                    {path: "N+8", type: "editText", componentClass: "mini-input"},
                    {path: "N+9", type: "editText", componentClass: "mini-input"}
                ],
                default: defaultSammariesUnits(10)
            }
        ]
    },
    filters: ["byBusinessProject"],
    actionSubscriptions: [...defaultBusinessProjectSubscriptions],
    stateSubscriptions: [{
        statePath: 'form.editObject.values.projectNature',
        subscription: (newValue, previousValue, {store, formInitialize, formDestroy}) => {
            if (!formInitialize && !formDestroy) {
                try {
                    const state = store.getState();

                    const projectNature = getProjectNatureFromDataList(
                        newValue,
                        state.dataLists.byId["m-I-financialSummaries.FinancialSummary_projectNature"]
                    )
                    const oldObject = _.get(state, "form.editObject.values.summaryTableHeaders");

                    const newObject = projectNature.id !== "sale"
                        ? oldObject.length === 1 ? oldObject : _.slice(oldObject, 1, 2)
                        : oldObject.length === 1 ? defaultSammariesUnits(10).map(o => o.summariesUnit.id === "2" ? oldObject[0] : o) : oldObject


                    store.dispatch(change('editObject', 'summaryTableHeaders', newObject))

                } catch (error) {
                    console.log("error executing subscription");
                    console.warn(error);
                }
            }
        }
    }]
}
