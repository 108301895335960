const async = require('async')
const { basicContext } = require("../../utils/contextUtils")
const Errors = require("../../utils/Errors").default

export const entity = {
    name: 'Bank',
    fields: [
        'bank',
        {path: 'bic', encrypted: true},
        {path: 'iban', unique: true, encrypted: true},
        {path: 'temporaryAccount'},
        {path: 'provisionalAccount', unique: true},
        {path: 'definitiveAccount', unique: true},
        {path: 'process', type: 'Process', link: 'MTM'},
        {path: 'active', type: 'boolean'}
    ],
    uniqueProcess: function (id, iban, process, context, callback) {
        global.app.R.Bank.find({
            ...basicContext(context),
            fieldPath: ['iban', 'process', 'bank', 'active'],
            query: {
                _id: {$ne : new global.ObjectID(id)},
                active: true,
                process: { $elemMatch: {$eq : process} }
            }
        }, (e, accounts) => {
            if(accounts && accounts.length !== 0) {
                callback(new Errors.ValidationError('uniqueActiveProcess'))
            }
            else callback()
        })
    },
    validateSave: function (newObject, oldObject, context, callback) {
        if(newObject.active) {
            async.series(
                newObject.process.map(process =>
                    callback => this.uniqueProcess(newObject.id, newObject.iban, process.id, context, callback)
                ),
                callback);
        }
        else callback()
    }
}

export const module_ = {
    object: 'Bank',
    tKey: 'mTitle_bank',
    objectIdentifier: 'bank',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'bank'},
            {path: 'iban', initiallyNotVisible: true},
            {path: 'bic', initiallyNotVisible: true},
            {path: 'temporaryAccount', initiallyNotVisible: true},
            {path: 'provisionalAccount', initiallyNotVisible: true},
            {path: 'definitiveAccount', initiallyNotVisible: true},
            {path: 'process', translate: true},
            {path: 'active'}
        ],
        form: [
            {path: 'bank', required: true},
            {path: 'iban', required: true, noSpaces: true, maxLength34: true, bankData: true},
            {path: 'bic', required: true, noSpaces: true, lengthEquals8or11: true, bankData: true},
            {path: 'temporaryAccount', required: true},
            {path: 'provisionalAccount', required: true},
            {path: 'definitiveAccount', required: true},
            {path: 'process', type: 'tags', required: true},
            {path: 'active', default: true}
        ]
    }
}
