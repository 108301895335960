import './polyfills/index'
import 'core-js/modules/es6.array.iterator'
import 'core-js/es6/symbol'
import 'core-js/es6/weak-set'
import 'element-closest'
import React from 'react'
import './clientGlobals'
import MainPage from './apps/MainPage'
import './globalStyles'
import ErrorBoundary from './components/ErrorBoundary'
// import registerServiceWorker from './registerServiceWorker'

export default () => (
    <ErrorBoundary>
        <MainPage/>
    </ErrorBoundary>
)
