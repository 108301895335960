import {getBusinessProjectTeamMemberProfile, workflowParameters} from "./workflow";

const _ = require('lodash');
const moment = require('moment');
const { basicContext } = require("../../../utils/contextUtils")
const {
    workflowManager,
    getWorkflowModel,
    getMaxOrders
} = require("../workflow/workflow");

export const initializeWorkflowObject = async function(businessProject) {

    const workflowObject = workflowManager.getInitialStatus()

    await global.db.collection("i.businessProject").updateOne(
        {_id: new global.ObjectID(businessProject.id)},
        {$set: {workflow: workflowObject}}
    )

    return { ...businessProject, workflow: workflowObject }
}

export const updateWorkflowObject = async function(businessProject, currentStatus, context) {

    const workflowModel = await getWorkflowModel(businessProject, context)
    const maxOrders = getMaxOrders(workflowModel.workflowConfigs)

    const user = context.user && {id: context.user.id, name: context.user.name}
    const date = moment().format("YYYY-MM-DD HH:mm")
    const text = `${context.tc(context.action)} ${context.tc(currentStatus.step)}`
    const group = new global.ObjectID(context.group.id)

    let query = {
        comments: [
            ...businessProject.comments || [],
            { user, date, text, group , _id: new global.ObjectID()}
        ]
    }
    let nextStatus = {}
    if(context.action !== 'save') {
        nextStatus = workflowManager.getNextStatus(
            currentStatus,
            context.action,
            maxOrders
        )

        query["workflow.step"] = nextStatus.step
        query["workflow.order"] = nextStatus.order
        query["workflow.type"] = nextStatus.type
        query["workflow.lastUpdate"] = new Date()

        businessProject.workflow = {
            ...businessProject.workflow,
            ...nextStatus
        }

        businessProject.currentFunctions = getBusinessProjectTeamMemberProfile(businessProject, context)
    }

    const businessProjectCollection = global.app.I.BusinessProject.collection
    await businessProjectCollection.updateOne(
        { _id: new global.ObjectID(businessProject.id) },
        { $set: query }
    )

    return businessProject
}

export const updateWorkflowBP = async function(workflowModel, previousWorkflowModel, context) {
    const currentMaxOrders = getMaxOrders(workflowModel.workflowConfigs)
    const previousMaxOrders = previousWorkflowModel && getMaxOrders(previousWorkflowModel.workflowConfigs)

    const filteredSteps = workflowParameters.steps
            .filter(step => step.type === 'inProgress')
            .filter(step => {
                if(previousMaxOrders) {
                    return currentMaxOrders[step.name] < previousMaxOrders[step.name]
                }
                return true
            })
            .map(step => step.name)

    if(!filteredSteps.length) return

    const countryQuery = {country: {$in: workflowModel.countries.map(country => global.ObjectID(country.id))}}
    const typeOfOfferQuery = !!workflowModel.typesOfOffer?.length
        ? {typeOfOffer: {$in: workflowModel.typesOfOffer.map(typeOfOffer => global.ObjectID(typeOfOffer.id))}}
        : {}

    const estimatedTurnoverQuery = !!workflowModel.minTurnover && !!workflowModel.maxTurnover
        ? {estimatedTurnover: {$gte: workflowModel.minTurnover, $lte: workflowModel.maxTurnover}}
        : {}

    const businessProjects = await global.app.I.BusinessProject.find({
        ...basicContext(context),
        query: {
            'workflow.step': {$in : filteredSteps},
            ...countryQuery,
            ...typeOfOfferQuery,
            ...estimatedTurnoverQuery
        },
        fieldPath: ['id', 'country.id', 'typeOfOffer.id', 'estimatedTurnover', 'workflow.step', 'workflow.order']
    })

    if(!businessProjects.length) return

    await Promise.all(businessProjects.map(
        businessProject => (async () => {
            const bpWorkflowModel = await getWorkflowModel(businessProject, context)
            const currentStep = businessProject.workflow.step
            const currentOrder = businessProject.workflow.order
            if(bpWorkflowModel.id === workflowModel.id && currentOrder > currentMaxOrders[currentStep] ) {
                let previousAvailableOrder = currentOrder -1

                while(previousAvailableOrder > currentMaxOrders[businessProject.workflow.step]) {
                    previousAvailableOrder = previousAvailableOrder - 1
                }

                await global.db.collection("i.businessProject").updateOne(
                    {_id: new global.ObjectID(businessProject.id)},
                    {
                        $set: {
                            "workflow.order": previousAvailableOrder
                        }
                    }
                )
            }
        })()
    ))
}
