import moment from "moment"
import path from "path"

const pdfConfig = function(object) {

    const presentationDate = moment(object.presentationDate).format("DD/MM/YYYY")

    const footer = "<footer style=\"font-size: 8px; margin-left: 10px; font-family: Verdana, Arial, sans-serif\"><div class=\"row\">" +
        "<div class=\"col-md-8\" style=\"float: left\">CNDA</div>" +
        "</div></footer>"


    return {
        content: [
            { path: 'presentationTable.html' }
        ],
        context: {
            generationDate: moment().format("DD/MM/YYYY"),
            date: presentationDate,
            files: object.liberalityFiles
        },
        configuration: {
            landscape: true,
            margin: {
                top: '50px',
                bottom: '100px',
                right: '30px',
                left: '30px',
            },
            header: {
                height: '1cm',
                html: ""
            },
            footer: {
                height: '1cm',
                html: footer.replace(new RegExp("'", 'g'), "")
            },
            pdfName: `Présentation du ${object.presentationDate}.pdf`,
            outputInDB: true,
            cssSheet: '',
            templateDir:  path.join(global.appRoot,  global.isProd ? "buildServer/server/models/cnda/liberality/templates" : "src/server/models/cnda/liberality/templates")
        }
    }
}

export const generatePresentationTable = async (object, context) => {
    return new Promise((resolve, reject) => {
        global.pdf.generatePdf(pdfConfig(object, context), (err, file) => {
            if (err) reject(err)
            else resolve(file)
        })
    })

}
