const { byCompany, byFamily } = require('./filters')
const { fieldPathJoinGetter } = require("../../utils/kp3Utils")

export const dependencies = [
    {
        name: 'FamilySurFamilyHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith: ['surFamily', 'family'], index: true},
            'SurFamily',
            {type: 'Company', index: true},
            { path: 'date', type: 'date' },
            'HistoryDataType'
        ],
        filters:[byFamily]
    }
]

export const entity = {
    name: 'Family',
    facets: [{name: 'codeName', codeUniqueWith: 'company'}],
    fields: [
        'Company',
        {
            type: 'FamilySurFamilyHistory',
            path: 'surFamilies',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        },
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"]
        })
    ],
    filters: [{
        ...byCompany,
        autoFetch: true
    }]
}

export const modules = [
    {
        object: 'FamilySurFamilyHistory',
        tKey: 'mTitle_family',
        newable: false,
        removable: false,
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'company',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                {path: 'family.code', tKey: 'code', width: 100},
                {path: 'family.name', tKey: 'name'},
                {path: 'date', width: 100},
                {
                    path: 'surFamily',
                    display: 'fullName'
                },
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:['byFamily']
    }
]
