export const entity = {
    name: 'HabilitationState',
    fields:[
        {type: "User", unique: true},
        {type: 'StateModel', link:'MTM'}
    ]
}

export const module_ = {
    object: 'HabilitationState',
    tKey: 'mTitle_habilitationState',
    removable: false,
    defaultSortBy: 'user',
    defaultSortDirection: 'ASC',
    category: {
        path: 'keenpoint',
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: 'user',display: 'name', clearable: false}
        ],
        form: [
            {path: 'user',display: 'name', required: true, editable: false},
            {path: 'stateModels', display: 'fullName'}
        ]
    }
}
