const _ = require("lodash");
const moment = require("moment");
const datePattern = ["D-M-YY", "DD-MM-YY", "D/M/YY", "DD/MM/YY", "DD-MM-YYYY", "D-M-YY", "D/M/YYYY", "DD/MM/YYYY", "DD/MM/YYYY HH:mm:ss", "DD/MM/YYYY hh:mm:ss", "DD/MM/YYYY hh:mm a", "DD/MM/YYYY h:m a", "D/M/YY hh:mm a", "D/M/YY h:m a", "DD/MM/YYYY hh:mma", "DD/MM/YYYY h:ma", "D/M/YYYY hh:mma", "D/M/YYYY h:ma", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]

export default [
    {
        name: "CondOperator",
        type: "static",
        fields: [
            "name",
            "mongoSymbol"
        ],
        objects: [
            {
                id: "000000000000000000000012",
                name: "greaterThan",
                func: (value, otherValue) => value > otherValue,
                symbol: ">",
                mongoSymbol: "gt"
            },
            {
                id: "000000000000000000000013",
                name: "greaterThanOrEqualTo",
                func: (value, otherValue) => value >= otherValue,
                symbol: ">=",
                mongoSymbol: "gte"
            },
            {
                id: "000000000000000000000014",
                name: "lessThan",
                func: (value, otherValue) => value < otherValue,
                symbol: "<",
                mongoSymbol: "lt"
            },
            {
                id: "000000000000000000000015",
                name: "lessThanOrEqualTo",
                func: (value, otherValue) => value <= otherValue,
                symbol: "<=",
                mongoSymbol: "lte"
            },
            {
                id: "000000000000000000000016",
                name: "equalTo",
                func: (value, otherValue) => value === otherValue,
                symbol: "==",
                mongoSymbol: "eq"
            },
            {
                id: "000000000000000000000017",
                name: "differentThan",
                func: (value, otherValue) => value !== otherValue,
                symbol: "!=",
                mongoSymbol: "ne"
            },
            {
                id: "000000000000000000000018",
                name: "includedIn",
                func: (value, otherValue) => otherValue.includes(value),
                symbol: "in",
                mongoSymbol: "in"
            },
            {
                id: "000000000000000000000019",
                name: "excludedFrom",
                func: (value, otherValue) => !otherValue.includes(value),
                symbol: "nin",
                mongoSymbol: "nin"
            }
        ]
    },
    {
        name: "Operation",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "name"},
        ],
        objects: [
            {id: "000000000000000000000901", name: "distinctElementsNumber"}
        ]
    },
    {
        name: "DataType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey",
            {path: "isNumber", type: "boolean"}
        ],
        objects: [
            {
                id: "type-integer",
                tKey: "wholeNumber",
                code: "integer",
                cast: value => _.toInteger(value),
                validate: value => value !== "" && !_.isNaN(value) && _.isInteger(+value),
                isNumber: true
            },
            {
                id: "type-float",
                tKey: "realNumber",
                code: "float",
                cast: value => _.toNumber(value.replace(",", ".")),
                validate: value => value !== "" && ! isNaN(value.replace(",", ".")),
                isNumber: true
            },
            {
                id: "type-string",
                tKey: "text",
                code: "string",
                cast: value => value.toString(),
                validate: value => _.isString(value),
                isNumber: false
            },
            {
                id: "type-date",
                tKey: "date",
                code: "date",
                cast: value => moment(value, datePattern, true).isValid() ? moment.utc(value, datePattern).toDate() : value,
                validate: value => value === '0' || moment(value, datePattern, true).isValid(),
                isNumber: false
            }
        ]
    },

    {
        name: "ThemeFieldType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey",
            "code"
        ],
        objects: [
            {id: "000000000000000000000101", tKey: "amount_abreviation", code: "Mt"},
            {id: "000000000000000000000102", tKey: "number_abreviation", code: "Nb"}
        ]
    },

    {
        name: "TimeWindow",
        type: "static",
        fields: ["tKey", "period", "quantity"],
        objects: [
            {id: "00000000000000000000000a", tKey: "1_daily", period: "days", quantity: 1},
            {id: "00000000000000000000000b", tKey: "2_weekly", period: "days", quantity: 7},
            {id: "00000000000000000000000c", tKey: "3_monthly", period: "months", quantity: 1}
        ]
    },

    {
        name: "WeekDay",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "dayNumber", type: "integer"}
        ],
        objects: [
            {id: "sunday",      dayNumber: 0},
            {id: "monday",      dayNumber: 1},
            {id: "tuesday",     dayNumber: 2},
            {id: "wednesday",   dayNumber: 3},
            {id: "thursday",    dayNumber: 4},
            {id: "friday",      dayNumber: 5},
            {id: "saturday",    dayNumber: 6}
        ]
    },

    {
        name: "Month",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "name", type: "string"},
        ],
        objects: [
            {
                id: "01",
                order: 1,
                name: 'january'
            },
            {
                id: "02",
                order: 2,
                name: 'february'
            },
            {
                id: "03",
                order: 3,
                name: 'march'
            },
            {
                id: "04",
                order: 4,
                name: 'april'
            },
            {
                id: "05",
                order: 5,
                name: 'may'
            },
            {
                id: "06",
                order: 6,
                name: 'june'
            },
            {
                id: "07",
                order: 7,
                name: 'july'
            },
            {
                id: "08",
                order: 8,
                name: 'august'
            },
            {
                id: "09",
                order: 9,
                name: 'september'
            },
            {
                id: "10",
                order: 10,
                name: 'october'
            },
            {
                id: "11",
                order: 11,
                name: 'november'
            },
            {
                id: "12",
                order: 12,
                name: 'december'
            }
        ],
        sorters: [
            {
                name: 'byOrder',
                query: () => ({ order: 1 })
            }
        ]
    },

    {
        name: "MonthNumber",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "dayNumber", type: "string"},
            {path: "order", type: "integer"}
        ],
        objects: _.range(28).map(dayNumber => {
            return {
                id: (dayNumber +1).toString(),dayNumber: (dayNumber +1).toString(), order: dayNumber + 1}
        }),
        sorters: [
            {
                name: 'byOrder',
                query: () => ({ order: 1 })
            }
        ]
    },

    {
        name: "MonthDay",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {
                path: "dayNumber",
                type: "integer"
            },
            {
                path: "name",
                f: function() {
                    return this.dayNumber;
                }
            }
        ],
        objects: _.range(28).map(dayNumber => {
            return {
                id: "day-" + (dayNumber +1).toString(),dayNumber: dayNumber +1}
        })
    },

    {
        name: "StoreAxis",
        type: "static",
        fields: ["code", "tKey"],
        objects: [{id: "a00000000000000000000000", code: "store", tKey: "store"}]
    },

    {
        name: "ProductAxis",
        type: "static",
        fields: ["code", "tKey"],
        objects: [{id: "b00000000000000000000000", code: "productCode", tKey: "product"}]
    },

    {
        name: "EmployeeAxis",
        type: "static",
        fields: ["code", "tKey"],
        objects: [{id: "d00000000000000000000000", code: "cashier", tKey: "cashier"}]
    },

    /*
    {
        name: "SubStoreAxis",
        type: "static",
        fields: ["code", "tKey"],
        objects: [
            {id: "c00000000000000000000000", code: "ticketNo", tKey: "ticket"},
            {id: "d00000000000000000000000", code: "cashier", tKey: "cashier"},
            {id: "e00000000000000000000000", code: "checkout", tKey: "checkout"}
        ]
    },
     */

    {
        name: "SubStoreAxisTheme",
        type: "static",
        fields: ["code", "tKey"],
        objects: [
            {id: "d10000000000000000000000", code: "cashier", tKey: "cashier"},
            {id: "e10000000000000000000000", code: "checkout", tKey: "checkout"}
        ]
    },

    {
        name: "CentralAxis",
        type: "static",
        fields: ["code", "tKey"],
        objects: [{id: "f00000000000000000000000", code: "central", tKey: "exhaustive"}]
    },

    {
        name: "WorkflowFilter",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {id: "alertToProcess", tKey: "1_alertToProcess"},
            {id: "alertInProgress", tKey: "2_alertInProgress"},
            {id: "closedAlertRisk", tKey: "3_closedAlertRisk"},
            {id: "closedAlertNoRisk", tKey: "4_closedAlertNoRisk"}
        ]
    },
    {
        name: "ThemeAnalyseCumulate",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {id: "cumulate", tKey: "cumulate"},
            {id: "notCumulate", tKey: "notCumulate"}
        ]
    }
];
