export const entity = {
    name: 'AccountModel',
    fields: ['code', 'formula']
}

export const module_ =  {
    object: 'AccountModel',
    tKey: 'mTitle_accountModel',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'hermes',
        icon: 'server'
    },
    viewMap: {
        dt: ['code'],
        form: [
            { path: 'code', required: true },
            {
                path: 'formula',
                type: 'textarea'
            }
        ]
    }
}
