import moment from 'moment'
import _ from 'lodash'
import {STRONG_PASS} from '../../../../utils/regExps'

const AUTHORIZED_CHARACTERS = [
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    '0','1','2','3','4','5','6','7','8','9'
]

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validatePhoneNumber = number => {
    return number[0] === '+'
}

const wholeNumberCheck = value =>
    (value || value === 0) && !(value % 1 === 0)

const wholePositiveNumberCheck = value =>
    (value || value === 0) && !(value % 1 === 0 && Math.sign(value) !== -1)

const wholePositiveNumberStrictCheck = value =>
    (value || value === 0) && !(value % 1 === 0 && Math.sign(value) !== -1 && value > 0)

const positiveNumberCheck = value =>
    (value || value === 0) && !(Math.sign(value) !== -1)

const positiveNumberStrictCheck = value =>
    (value || value === 0) & !(Math.sign(value) !== -1 && value > 0)

const required = value =>
    (typeof value === 'string') || _.isArray(value)
        ? value.length !== 0
            ? undefined
            : 'requiredField'
        : (value !== undefined && value !== null)
            ? undefined
            : 'requiredField'

const emailAddress = value =>
    value
        ? validateEmail(value)
            ? undefined
            : 'shouldBeAnEmail'
        : undefined

const phoneNumber = value =>
    value
        ? validatePhoneNumber(value)
            ? undefined
            : 'shouldBeAPhoneNumber'
        : undefined

const noSpaces = value =>
    value && value.split(' ').length < 2
        ? undefined
        : 'noSpaces'

const maxLength = maxLength => (value, allValues, props) =>
    value && value.length <= maxLength
        ? undefined
        : props.t && props.t('maxFilesNumberExceeded', {maxLength})

const minValue = minValue => (value, allValues, props) => {
    const condition = !value || parseInt(value) >= minValue
    return condition
        ? undefined
        : props.t && props.t('minValueExceeded', {minValue})
}

const maxValue = maxValue => (value, allValues, props) =>
    !value || parseInt(value) <= maxValue
        ? undefined
        : props.t && props.t('maxValueExceeded', {maxValue})

const maxLength34 = (value, allValues, props) =>
    value && value.length <= 34
        ? undefined
        : props.t && props.t('maxLengthExceeded', {maxLength: 34})

const maxLength11 = (value, allValues, props) =>
    value && value.length <= 11
        ? undefined
        : props.t && props.t('maxLengthExceeded', {maxLength: 11})

const maxLength30 = (value, allValues, props) =>
    value ?
        value.length <= 30
            ? undefined
            : props.t && props.t('maxLengthExceeded', {maxLength: 30})
        : undefined

const singleImage = (value, allValues, props) =>
    value ?
        value.length <= 1
            ? undefined
            : props.t && props.t('singleImage')
        : undefined

const lengthEquals8or11 = value =>
    value && value.length !== 8 && value.length !== 11
        ? 'bicLengthShouldEqual8or11'
        : undefined

const requiredRichText = value =>
    value && value.length !== 0 && value !== '<p></p>'
        ? undefined
        : 'requiredField'

const conversionRateYearCheck = value => {
    const minValue = parseInt(moment().add(-1, 'year').format("YYYY"), 10)
    const maxValue = parseInt(moment().add( 30, 'year').format("YYYY"), 10)

    return value < minValue || value > maxValue
}

const wholeNumber = (value, allValues, props) =>
    wholeNumberCheck(value, props)
        ? 'fieldMustBeWholeNumber'
        : undefined

const wholePositiveNumber = (value, allValues, props) =>
    wholePositiveNumberCheck(value, props)
        ? 'fieldMustBeWholePositiveNumber'
        : undefined

const wholePositiveNumberStrict = (value, allValues, props) =>
    wholePositiveNumberStrictCheck(value, props)
        ? 'fieldMustBeStrictlyWholePositiveNumber'
        : undefined

const positiveNumber = (value, allValues, props) =>
    positiveNumberCheck(value)
        ? 'fieldMustBePositiveNumber'
        : undefined

const positiveNumberStrict = (value, allValues, props) =>
    positiveNumberStrictCheck(value, props)
        ? 'fieldMustBeStrictlyPositiveNumber'
        : undefined

const conversionRateYear = (value, allValues, props) =>
    conversionRateYearCheck(value)
        ? 'yearMustBeBetweenN-1&N+30'
        : undefined

const wholePositiveNumbers = listFields => values => {
    const result =
        values &&
        values.some(object =>
            listFields.some(field => {
                return field.type === 'editText'
                    ? wholePositiveNumberCheck(object[field.path])
                    : false
            })
        )

    return result
        ? 'fieldMustBeWholePositiveNumbers'
        : undefined
}

const requiredStyledTable = listFields => values => {
    const result =
        values &&
        values.some(object =>
            listFields.some(field => {
                if(field.type === 'editText') {
                    return required(_.get(object, field.path)) === 'requiredField'
                }
                const type = _.get(object, `${field.path}.type`)
                return type === 'editText'
                    ? required(_.get(object, `${field.path}.data`)) === 'requiredField'
                    : false
            })
        )

    return result
        ? 'styleTableFieldsRequired'
        : undefined
}

const strongPassword = value => {
    const strongRegex = new RegExp(STRONG_PASS, "g")
    if(!value) return undefined
    return strongRegex.test(value)
        ? undefined
        : 'passwordNotStrongEnough'
}

const bankData = (value, allValues, props) => {
    if(value) {
        for(let i=0; i< value.length; i++) {
            if(!AUTHORIZED_CHARACTERS.includes(value[i])) {
                return props.t
                    ? props.t('unauthorizedCharacter', {character: value[i]})
                    : 'unauthorizedCharacter'
            }
        }
        return undefined
    }
    return undefined
}

const valueNotFound = optionsById => (value, formValues, props) => {
    if(!value) return undefined
    return optionsById[value.id]
        ? undefined
        : props.t('valueNotFoundInOptionsList')
}

const tagValueNotFound = optionsById => (value, formValues, props) => {
    const hasNotFoundValue = value && value.some(o => !optionsById[o.id])
    return hasNotFoundValue
        ? props.t('valuesNotFoundInOptionsList')
        : undefined
}

const getFormValidations = (field, t, listFields, editObjectValues, optionsById) => {
    const validations = [
        ...(field.validations || []).map(validation => validation(editObjectValues))
    ]

    if (field['required']) {
        if (field.type === 'richTextEditor') validations.push(requiredRichText)
        else if(['styledTable', 'dtObjects'].includes(field.type)) validations.push(requiredStyledTable(listFields))
        else validations.push(required)
    }


    if(field.type === 'dropdownObject') validations.push(valueNotFound(optionsById))
    if(field.type === 'tags') validations.push(tagValueNotFound(optionsById))
    if(field['noSpaces']) validations.push(noSpaces)
    if(field['positiveNumber']) validations.push(positiveNumber)
    if(field['positiveNumberStrict']) validations.push(positiveNumberStrict)
    if(field['wholeNumber']) validations.push(wholeNumber)
    if(field['emailAddress']) validations.push(emailAddress)
    if(field['phoneNumber']) validations.push(phoneNumber)
    if(field['wholePositiveNumber']) validations.push(wholePositiveNumber)
    if(field['wholePositiveNumberStrict']) validations.push(wholePositiveNumberStrict)
    if(field['wholePositiveNumbers']) validations.push(wholePositiveNumbers(listFields))
    if(field['conversionRateYear']) validations.push(conversionRateYear)
    if(field['strongPassword']) validations.push(strongPassword)
    if(field['maxLength']) validations.push(maxLength(field.maxLength))
    if(field['minValue']) validations.push(minValue(field.minValue))
    if(field['maxValue']) validations.push(maxValue(field.maxValue))
    if(field['maxLength34']) validations.push(maxLength34)
    if(field['maxLength30']) validations.push(maxLength30)
    if(field['maxLength11']) validations.push(maxLength11)
    if(field['lengthEquals8or11']) validations.push(lengthEquals8or11)
    if(field['bankData']) validations.push(bankData)
    if(field['singleImage']) validations.push(singleImage)

    return validations
}

export default getFormValidations
