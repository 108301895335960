import React from 'react'
import ValidationUI from '../ValidationUI'
import Html from 'slate-html-serializer'
import { Editor } from 'slate-react'
import { isKeyHotkey } from 'is-hotkey'
import './RichTextEditor.css'
import classnames from 'classnames'

const BLOCK_TAGS = {
    blockquote: 'block-quote',
    p: 'paragraph',
    ul: 'bulleted-list',
    li: 'list-item',
    ol: 'numbered-list',
    h4: 'heading-one',
}

const MARK_TAGS = {
    em: 'italic',
    strong: 'bold',
    u: 'underlined'
}

const rules = [
    {
        deserialize(el, next) {
            const type =
                el.style && el.style['0'] === 'list-style-type'
                    ? 'list'
                    : BLOCK_TAGS[el.tagName.toLowerCase()]

            if (type) {
                return {
                    object: 'block',
                    type: type,
                    data: {
                        className: el.getAttribute('class')
                    },
                    nodes: next(el.childNodes)
                }
            }
        },
        serialize(obj, children) {
            if (obj.object === 'block') {
                switch (obj.type) {
                    case 'paragraph':
                        return (
                            <p className={obj.data.get('className')}>
                                {children}
                            </p>
                        )
                    case 'block-quote':
                        return <blockquote>{children}</blockquote>
                    case 'bulleted-list':
                        return <ul>{children}</ul>
                    case 'list':
                        return (
                            <ul style={{ listStyleType: 'none' }}>
                                {children}
                            </ul>
                        )
                    case 'heading-one':
                        return <h4>{children}</h4>
                    case 'list-item':
                        return <li>{children}</li>
                    case 'numbered-list':
                        return <ol>{children}</ol>
                    default:
                        return
                }
            }
        }
    },
    // Add a new rule that handles marks...
    {
        deserialize(el, next) {
            const type = MARK_TAGS[el.tagName.toLowerCase()]
            if (type) {
                return {
                    object: 'mark',
                    type: type,
                    nodes: next(el.childNodes)
                }
            }
        },
        serialize(obj, children) {
            if (obj.object === 'mark') {
                switch (obj.type) {
                    case 'bold':
                        return <strong>{children}</strong>
                    case 'italic':
                        return <em>{children}</em>
                    case 'underlined':
                        return <u>{children}</u>
                    default:
                        return
                }
            }
        }
    }
]

const html = new Html({ rules })

const DEFAULT_NODE = 'paragraph'

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')

const initialValue = value => value || '<p/>'

class RichTextEditor extends React.Component {

    state = {
        focus: false,
        value: html.deserialize(initialValue(this.props.value))
    }

    hasMark = type => {
        const { value } = this.state
        return value.activeMarks.some(mark => mark.type === type)
    }

    hasBlock = type => {
        const { value } = this.state
        return value.blocks.some(node => node.type === type)
    }

    onChange = ({ value }) => {
        if (value.document !== this.state.value.document) {
            const string = html.serialize(value)
            this.props.onChange(string)
        }
        this.setState({ value })
    }

    handleOnFocus = () => {
        this.setState({ focus: true })
    }

    handleOnBlur = () => {
        this.setState({ focus: false })
    }

    onKeyDown = (event, change) => {
        let mark

        if (isBoldHotkey(event)) {
            mark = 'bold'
        } else if (isItalicHotkey(event)) {
            mark = 'italic'
        } else if (isUnderlinedHotkey(event)) {
            mark = 'underlined'
        } else {
            return
        }

        event.preventDefault()
        change.toggleMark(mark)
        return true
    }

    onClickMark = (event, type) => {
        event.preventDefault()
        const { value } = this.state
        const change = value.change().toggleMark(type)
        this.onChange(change)
    }

    onClickBlock = (event, type) => {
        event.preventDefault()
        const { value } = this.state
        const change = value.change()
        const { document } = value

        // Handle everything but list buttons.
        if (
            type !== 'bulleted-list' &&
            type !== 'numbered-list' &&
            type !== 'list'
        ) {
            const isActive = this.hasBlock(type)
            const isList = this.hasBlock('list-item')

            if (isList) {
                change
                    .setBlocks(isActive ? DEFAULT_NODE : type)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
                    .unwrapBlock('list')
            } else {
                change.setBlocks(isActive ? DEFAULT_NODE : type)
            }
        } else {
            // Handle the extra wrapping required for list buttons.
            const isList = this.hasBlock('list-item')
            const isType = value.blocks.some(block => {
                return !!document.getClosest(
                    block.key,
                    parent => parent.type === type
                )
            })

            if (isList && isType) {
                change
                    .setBlocks(DEFAULT_NODE)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
                    .unwrapBlock('list')
            } else if (isList) {
                change
                    .unwrapBlock(
                        type === 'bulleted-list'
                            ? 'numbered-list'
                            : 'bulleted-list'
                    )
                    .wrapBlock(type)
            } else {
                change.setBlocks('list-item').wrapBlock(type)
            }
        }

        this.onChange(change)
    }

    render() {
        const { touched, error, path, required, t, readOnly, style } = this.props
        return (
            <div>
                {
                    !readOnly && <label className="RichTextEditor-label">
                        {t(path)} {required && "*"}
                    </label>
                }
                <div className="RichTextEditor" style={style}>
                    { !readOnly && this.renderToolbar()}
                    {this.renderEditor()}
                </div>
                {touched === 'true' &&
                (error && <ValidationUI error={error} />)}
            </div>
        )
    }

    renderToolbar = () => {
        return (
            <div
                className={`menu toolbar-menu ${this.props.disabled
                    ? 'disabled'
                    : ''}`}
            >
                {this.renderMarkButton('bold', 'format_bold')}
                {this.renderMarkButton('italic', 'format_italic')}
                {this.renderMarkButton('underlined', 'format_underline')}
                {this.renderBlockButton('heading-one', 'looks_one')}
                {this.renderBlockButton('block-quote', 'format_quote')}
                {this.renderBlockButton(
                    'numbered-list',
                    'format_list_numbered'
                )}
                {this.renderBlockButton(
                    'bulleted-list',
                    'format_list_bulleted'
                )}
                {this.renderBlockButton('list', 'format_indent_increase')}
            </div>
        )
    }

    // @param {String} type
    // @param {String} icon

    renderMarkButton = (type, icon) => {
        const isActive = this.hasMark(type)
        const onMouseDown = event => this.onClickMark(event, type)

        return (
            // eslint-disable-next-line react/jsx-no-bind
            <span
                className="button"
                onMouseDown={onMouseDown}
                data-active={isActive}
            >
                <span className="material-icons">{icon}</span>
            </span>
        )
    }

    renderBlockButton = (type, icon) => {
        let isActive = this.hasBlock(type)

        if (['numbered-list', 'bulleted-list', 'list'].includes(type)) {
            const { value } = this.state
            const parent = value.document.getParent(
                value.blocks.first() ? value.blocks.first().key : ''
            )
            isActive =
                this.hasBlock('list-item') && parent && parent.type === type
        }
        const onMouseDown = event => this.onClickBlock(event, type)

        return (
            // eslint-disable-next-line react/jsx-no-bind
            <span
                className="button"
                onMouseDown={onMouseDown}
                data-active={isActive}
            >
                <span className="material-icons">{icon}</span>
            </span>
        )
    }

    renderEditor = () => {
        const { t, placeholder, disabled, readOnly, applyMinHeight = true } = this.props
        return (
            <div className={classnames("editor", {focus: this.state.focus, readOnly, applyMinHeight })}>
                <Editor
                    readOnly={disabled || readOnly}
                    placeholder={readOnly ? "" : (t(placeholder) || t('richTextPlaceholder'))}
                    value={this.state.value}
                    onChange={this.onChange}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    onKeyDown={this.onKeyDown}
                    renderNode={this.renderNode}
                    renderMark={this.renderMark}
                    spellCheck
                    // autoFocus
                />
            </div>
        )
    }

    // @param {Object} props

    renderNode = props => {
        const { attributes, children, node } = props
        switch (node.type) {
            case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'list':
                return (
                    <ul style={{ listStyleType: 'none' }} {...attributes}>
                        {children}
                    </ul>
                )
            case 'heading-one':
                return <h4 {...attributes}>{children}</h4>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            default:
                break
        }
    }

    renderMark = props => {
        const { children, mark, attributes } = props
        switch (mark.type) {
            case 'bold':
                return <strong {...attributes}>{children}</strong>
            case 'italic':
                return <em {...attributes}>{children}</em>
            case 'underlined':
                return <u {...attributes}>{children}</u>
            default:
                break
        }
    }
}

export default RichTextEditor
