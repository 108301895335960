const { workflowParameters } = require("../workflow/workflow");
const _ = require("lodash");
const moment = require('moment');

const groupBpsByAxis = (bps, axisId) => _.groupBy(bps, axisId
    ? global.app.I.ProjectGroupAxis.byId(axisId).groupper
    : ''
);

const computeProjectPorfolio = ({baseYear, axis1, axis2, filterSteps}, context, callback) => global.app.I.BusinessProject.find(
    {
        fieldPath: [axis1, axis2].filter(axis => axis).reduce(
            (fieldPath, axisId) => [...fieldPath, ...global.app.I.ProjectGroupAxis.byId(axisId).fieldPath],
            ["beginYear", "workflow.step", "estimatedTurnover", "financialStatement.turnOver"]
        ),
        filters: ["inSteps"],
        data: {filterSteps},
        group: context.group,
        user: context.user
    },
    (e, bps) => {
        if (e) return callback(e);
        const enhancedBps = bps.map(
            bp => {
                const beginYearBp = Number.parseInt(bp.beginYear.id, 10)
                return Object.assign(bp, {
                    number: {[bp.beginYear.id]: 1},
                    turnover: bp.financialStatement.turnOver && bp.financialStatement.turnOver.total > 0
                        ? _.range(1, 10).reduce((acc, number) => {
                            return {
                                ...acc,
                                [(beginYearBp +number).toString()]: bp.financialStatement.turnOver.values[`N+${number}`]
                            }
                        }, {[bp.beginYear.id]: bp.financialStatement.turnOver.values[`N`]})

                        : {[bp.beginYear.id]: bp.estimatedTurnover}
                })
            }
        );

        const sumYearValues = yearValuesArray => yearValuesArray.reduce(
            (sumYearValues, yearValues) => _.range(baseYear, baseYear + 4).reduce(
                (acc, year) => Object.assign({
                    [year]: (sumYearValues[year] || 0) + (yearValues[year] || 0)
                }, acc),
                {}
            ),
            {}
        );

        const portfolioResults = _.flatMap(groupBpsByAxis(enhancedBps, axis1), (groupBps, element1) =>
            _.flatMap(groupBpsByAxis(groupBps, axis2), (groupBps, element2) => {
                const reducedAmount = sumYearValues(_.map(groupBps, "number"));
                const reducedturnover = sumYearValues(_.map(groupBps, "turnover"));

                return [
                    {
                        id: new global.ObjectID().toString(),
                        element1,
                        element2,
                        value: "Projet (Nb)",
                        n0: reducedAmount[baseYear],
                        n1: reducedAmount[baseYear + 1],
                        n2: reducedAmount[baseYear + 2],
                        n3: reducedAmount[baseYear + 3]
                    },
                    {
                        id: new global.ObjectID().toString(),
                        element1,
                        element2,
                        value: "Chiffre d’affaires (k€)",
                        n0: reducedturnover[baseYear],
                        n1: reducedturnover[baseYear + 1],
                        n2: reducedturnover[baseYear + 2],
                        n3: reducedturnover[baseYear + 3]
                    }
                ];
            })
        );

        callback(null, portfolioResults);
    }
);

const defaultTranslation = {
    t: path => path,
    tc: path => _.upperFirst(path)
}

const translation = (context={}) => ({
    t: context.t || defaultTranslation.t,
    tc: context.tc || defaultTranslation.tc,
})

export const entities = [
    {
        name: "WorkflowStep",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {
                path: "name", fieldPath: ["id"],
                $f: function(workflowStep, context, callback) {
                    callback(null, translation(context).tc(workflowStep.id));
                }
            }
        ],
        objects: workflowParameters.steps.map(
            step => ({id: step.name})
        )
        // objects: _(workflowParameters.steps)
        //     .map(step => ({id: step.name}))
        //     .sortBy(step => step.id)
        //     .value()
    },
    {
        name: "ProjectPortfolio",
        fields: [
            "element1",
            "element2",
            "value",
            "n0",
            "n1",
            "n2",
            "n3"
        ],
        db: {
            find: (context, callback) => {
                const axis1 = _.get(context, "data.axis1.id");
                const axis2 = _.get(context, "data.axis2.id");
                const baseYear = Number(_.get(context, "data.baseYear.id"));
                const filterSteps = _.map(context.data.workflowSteps || [], "id");

                computeProjectPorfolio({baseYear, axis1, axis2, filterSteps}, context, callback);
            }
        }
    }
]

export const projectPortfolio = {
    object: "ProjectPortfolio",
    tKey: "reportingPortfolio",
    category: {
        path: "analysis",
        icon: 'sliders'
    },
    removable: false,
    chart: {
        type: "donutChart",
        keys: ["element1", "element2"],
        data: "n0",
        filters: [
            {value: "Projet (Nb)", label: "Projet (Nb)"},
            {value: "Chiffre d’affaires (k€)", label: "CA (k€)"}
        ]
    },
    viewMap: {
        dt: [
            {path: "element1", width: 500, translate: true},
            {path: "element2", width: 500, translate: true},
            {path: "value", tKey: "indicator", width: 200},
            {path: "n0", tKey: "n", width: 80},
            {path: "n1", tKey: "n+1", width: 80},
            {path: "n2", tKey: "n+2", width: 80},
            {path: "n3", tKey: "n+3", width: 80}
        ],
        chart: ["element1", "element2", "value", "n0", "n1", "n2", "n3"]
    },
    filters: [
        {
            object: "ProjectGroupAxis",
            path: "axis1",
            title: "Axe principal",
            placeholder: "Séléctionner l'axe principal",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "ProjectGroupAxis",
            path: "axis2",
            title: "Axe secondaire",
            placeholder: "Séléctionner l'axe secondaire",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            path: "workflowSteps",
            title: "Etapes analysées",
            object: "WorkflowStep",
            placeholder: "Sélectionner le(s) statut(s)",
            type: "tags",
            client: true,
            timer: 3,
            display: "name",
            default: [
                {id: 'formX'},
                {id: 'buildOffer'},
                {id: 'submitOffer'},
                {id: 'realisation'}
            ]
        },
        {
            object: "AnalysisYear",
            path: "baseYear",
            tKey: 'year',
            title: "Année (N)",
            client: true,
            display: "name",
            default: {id: moment().year()-1},
            clearable: false
        }
    ]
}
