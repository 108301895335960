import { FETCH_LIST_REQUEST, FETCH_LIST_SUCCESS, FETCH_LIST_FAILURE  } from '../actions/api'

const randomList = (
    state= {
        data: [],
        isFetching: false
    },
    action
) => {
    switch (action.type) {
        case FETCH_LIST_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case FETCH_LIST_FAILURE:
            return {
                data: [],
                isFetching: false
            }
        case FETCH_LIST_SUCCESS:
            return {
                ...state,
                data: action.data,
                isFetching: false
            }
        default:
            return state;
    }
};

export default randomList;
