import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'

export class HoverTooltip extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        message: PropTypes.node.isRequired,
        placement: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired
    }

    renderTooltip = () => (
        <Tooltip id={this.props.id}>
            {this.props.message}
        </Tooltip>
    )

    render() {
        return (
            <OverlayTrigger
                placement={this.props.placement}
                overlay={this.renderTooltip()}
            >
                {this.props.children}
            </OverlayTrigger>
        )
    }
}

export default HoverTooltip