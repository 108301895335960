const { fieldPathJoinGetter } = require('../../utils/kp3Utils')

export const entity = {
    name: 'Warehouse',
    fields: [
        { path: 'code', unique: true },
        { path: 'name', notEmpty: true },
        fieldPathJoinGetter({
            path: 'fullName',
            fieldPath: ['code', 'name']
        })
    ]
}

export const module_ = {
    object: 'Warehouse',
    tKey: `mTitle_warehouse`,
    category: {
        path: 'organisation',
        icon: 'server'
    },
    viewMap: {
        dt: ['code', 'name'],
        form: ['code', 'name']
    }
}
