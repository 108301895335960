const _ = require('lodash')

export default {
    id: 'B',
    name: 'BpStore',
    description: 'Business Plan Shop',
    entities: _.concat(
        [

            require('./bpshop/settings/dataType').entity,
            require('./bpshop/settings/accountBPA').entity,
            require('./bpshop/settings/accountCategory').entity,
            require('./bpshop/settings/accountModel').entity,
            require('./bpshop/settings/modelFolder').entity,
            require('./bpshop/bpStore/bPStoreFolderExport').entity,
            require('./bpshop/empiric/empiricFolderExport').entity,
            require('./bpshop/assignments/hypothesisModelAssignment').entity,
            require('./bpshop/assignments/stateModelAssignment').entity,
            require('./bpshop/assignments/subsidiaryAssignment').entity,
            require('./bpshop/empiric/analyseBrBpa').entity,
            require('./bpshop/empiric/accountModelExtration').entity,
            require('./bpshop/bpStore/hypothesisData').entity,
            require('./bpshop/bpStore/updatingHypothesisData').entity,
            require('./bpshop/bpStore/analyzeDetails').entity,
            require('./bpshop/bpStore/submissionAndEndorsement').entity,
            require('./bpshop/bpStore/comments').HypothesisDataUpdateCommentsEntity,
            require('./bpshop/bpStore/comments').DetailAnalysisCommentsEntity,
        ],
        require('./bpshop/staticEntities').entities,
        require('./bpshop/referential').entities,
        require('./bpshop/settings/stateModel').entities,
        require('./bpshop/settings/hypothesisModel').entities,
        require('./bpshop/empiric/shopBPEndorsed').entities,
        require('./bpshop/empiric/bPEndorsed').entities,
        require('./bpshop/empiric/data').entities,
        require('./bpshop/empiric/importExportData').entities,
        require('./bpshop/bpStore/leases').entities,
        require('./bpshop/bpStore/projectConfiguration').entities,
    ),
    modules: _.concat(
        [

            require('./bpshop/settings/dataType').module_,
            require('./bpshop/settings/accountCategory').module_,
            require('./bpshop/settings/accountModel').module_,
            require('./bpshop/settings/hypothesisModel').module_,
            require('./bpshop/settings/stateModel').module_,
            require('./bpshop/settings/modelFolder').module_,
            require('./bpshop/settings/accountBPA').module_,

            require('./bpshop/assignments/hypothesisModelAssignment').module_,
            require('./bpshop/assignments/stateModelAssignment').module_,
            require('./bpshop/assignments/subsidiaryAssignment').module_,
            require('./bpshop/empiric/analyseBrBpa').module_,
            require('./bpshop/empiric/empiricFolderExport').module_,
            require('./bpshop/empiric/accountModelExtration').module_,
            require('./bpshop/bpStore/projectConfiguration').module_,
            require('./bpshop/bpStore/hypothesisData').module_,
            require('./bpshop/bpStore/updatingHypothesisData').module_,
            require('./bpshop/bpStore/analyzeDetails').module_,
            require('./bpshop/bpStore/bPStoreFolderExport').module_,
        ],
        require('./bpshop/referential').modules,
        require('./bpshop/empiric/shopBPEndorsed').modules,
        require('./bpshop/empiric/bPEndorsed').modules,
        require('./bpshop/empiric/importExportData').modules,
        require('./bpshop/empiric/data').modules,
        require('./bpshop/bpStore/submissionAndEndorsement').modules,
    ),
    jobs: [
        require('./bpshop/jobs/fixDataInconsistency').job,
        require('./bpshop/jobs/initaccountModelUser').job,
        require('./bpshop/jobs/initHypothesisModelLineStatus').job
    ]
}
