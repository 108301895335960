const _ = require('lodash')
const { basicContext } = require("../../utils/contextUtils")
const { validateAge } = require('./utils')

async function getAnalysis(context) {
    const exercise = _.get(context, 'data.exercise')

    if(!exercise) return []

    const benefits = _.get(context, 'data.benefits')
    const user = _.get(context, 'data.user')

    const exerciseStartDate = new Date(exercise && exercise.startDate)
    const exerciseEndDate = new Date(exercise && exercise.endDate)

    const query = user
        ? {_id: global.ObjectID(user.id)}
        : {status: {$in: ['retired', 'salaried']}}

    const users = await global.app.R.CUsers.find({
            ...basicContext(context),
            fieldPath: ['fullName', 'registrationNumber', 'rightHolders.id', 'rightHolders.kinship', 'rightHolders.dependent', 'status.id'],
            query
        },
    )

    const exerciseBenefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: new global.ObjectID(exercise.id)}

    })


    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['refund', 'status', 'user'],
        query: {
            benefit: {$in: exerciseBenefits.map(o => new global.ObjectID(o.id))},
            status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

        }
    })

    const userQuery = user
        ? {user: global.ObjectID(user.id)}
        : {}

    const endowmentHistory = await global.app.R.EndowmentHistory.find({
        ...basicContext(context),
        fieldPath: ['exercise.id', 'user.id', 'exerciseLimit', 'limitByBenefit'],
        query: {
            exercise: new global.ObjectID(exercise.id),
            ...userQuery
        }

    })

    const results = users && users.map((user, userIndex) => {

        const userEndowmentHistory = endowmentHistory.find(endowment => endowment.user?.id === user.id)

        const entryDate = user.entryDate
        const releaseDate = user.releaseDate

        if(releaseDate) {
            releaseDate.setDate( releaseDate.getDate() - 1)
        }

        const start = entryDate
            ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
            : exerciseStartDate

        const end = !releaseDate || user.status.id === 'retired'
            ? exerciseEndDate
            : releaseDate < exerciseEndDate
                ? releaseDate
                : exerciseEndDate

        const monthsDiff = (date1, date2) => {
            const years = date2.getFullYear() - date1.getFullYear()
            return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
        }

        const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
            ? 0
            : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

        const userSplit = user['split'] || 0

        const getProratedValue = value => ((value * (100 - userSplit)) / 100) * presenceRatio

        const isGlobalSelected =!benefits || benefits.find(o => o.id === 'global')
        const isExerciseOngoing = _.get(exercise, 'exerciseStatus.id') === 'ongoing'
        const isExerciseClosed = _.get(exercise, 'exerciseStatus.id') === 'finalClosure'
        const childrenNumber = user.rightHolders
            .filter(rh => rh.kinship.id === 'child' && rh.dependent)
            .filter( rh => validateAge(rh.birthDate, exercise.startDate, 25))
            .length

        let initialValue
        if(isGlobalSelected) {
            const exerciseVariableDot = parseFloat(exercise.variableDot)
            const exerciseFixedDot = parseFloat(exercise.fixedDot)


            const limitForTheExerciseBeforeSplit = user.status.id === 'external'
                ? 0
                : childrenNumber * exerciseVariableDot + exerciseFixedDot

            const limitForTheExercise = getProratedValue(limitForTheExerciseBeforeSplit)

            const userRefundRequests = refunds
                .filter(refundRequest => refundRequest.user.id === user.id)

            const totalExerciseRefund = userRefundRequests
                .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)
            const totalExercisePaidRefund = userRefundRequests
                .filter(refund => refund.status === 'paid')
                .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)

            initialValue = [{
                id: `${user.id}`,
                beneficiary: user.fullName,
                registrationNumber: user.registrationNumber,
                exercise: exercise.code,
                refund: 'Global',
                'refundLimit€': isExerciseOngoing
                    ? limitForTheExercise.toFixed(2)
                    : isExerciseClosed && userEndowmentHistory
                        ? userEndowmentHistory.exerciseLimit.toFixed(2)
                        : '---',
                'postedRefund€': totalExerciseRefund.toFixed(2),
                'refundBalance€': isExerciseOngoing
                    ?  _.round(limitForTheExercise - totalExerciseRefund, 2).toFixed(2)
                    : isExerciseClosed && userEndowmentHistory
                        ? _.round(userEndowmentHistory.exerciseLimit - totalExerciseRefund, 2).toFixed(2)
                        : '---',
                'paidRefund€': totalExercisePaidRefund.toFixed(2)
            }]
        } else {
            initialValue = []
        }

        return exerciseBenefits
            .filter(benefit => !benefits || benefits.find(o => benefit.id === o.id))
            .reduce((acc, benefit, benefitIndex) => {

            const childrenNumber = user.rightHolders
                .filter(rh => rh.kinship.id === 'child' && rh.dependent)
                .filter( rh => validateAge(rh.birthDate, exercise.startDate, benefit.ageLimit))
                .length
            const variableLimit = parseFloat(benefit.limit.variable)
            const fixedLimit = parseFloat(benefit.limit.fixed)
            const maximumLimit = parseFloat(benefit.limit.maximum)

            const fixedAndVariable = benefit.childrenPresenceCondition && !childrenNumber
                ?  0
                : (childrenNumber * variableLimit ) + fixedLimit


            const fixAndValueAfterProrating = getProratedValue(fixedAndVariable)

            const limitForTheBenefit = user.status.id === 'external'
                ? 0
                : maximumLimit && fixAndValueAfterProrating >= maximumLimit
                    ? maximumLimit
                    : fixAndValueAfterProrating

            const benefitRefundRequests = refunds.filter(refund => {
                return refund.benefit.toString() === benefit.id
                    && refund.user.id === user.id
            })

            const totalRefund = benefitRefundRequests
                .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)
            const paidRefund = benefitRefundRequests
                .filter(refund => refund.status === 'paid')
                .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)
            return [...acc, {
                id: `${exercise.code}-${userIndex}-${benefitIndex}`,
                beneficiary: user.fullName,
                registrationNumber: user.registrationNumber,
                exercise: exercise.code,
                refund: benefit.name,
                'refundLimit€': isExerciseOngoing
                    ?  limitForTheBenefit.toFixed(2)
                    : isExerciseClosed && userEndowmentHistory
                        ? userEndowmentHistory.limitByBenefit[benefit.code].toFixed(2)
                        : '---',
                'postedRefund€': totalRefund.toFixed(2),
                'refundBalance€': isExerciseOngoing
                    ? _.round(limitForTheBenefit - totalRefund, 2).toFixed(2)
                    : isExerciseClosed && userEndowmentHistory
                        ? _.round(userEndowmentHistory.limitByBenefit[benefit.code] - totalRefund, 2).toFixed(2)
                        : '---',
                'paidRefund€': paidRefund.toFixed(2)
            }]
        },initialValue)
    })

    return _.flatMap(results)
}
export const entity = {
    name: 'RefundAnalysis',
    fields: ['beneficiary', 'registrationNumber', 'exercise', 'benefit', 'refundLimit€', 'postedRefund€', 'refundBalance€', 'paidRefund€'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                getAnalysis(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}

export const module_ = {
    object: 'RefundAnalysis',
    name: 'RefundAnalysis',
    tKey: 'mTitle_analysis',
    newable: false,
    removable: false,
    protectedExport: true,
    displayLog: false,
    defaultSortBy: 'beneficiary',
    defaultSortDirection: 'ASC',
    category: {
        path: 'refund',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            'beneficiary',
            {path: 'exercise', initiallyNotVisible: true},
            {path: 'registrationNumber', initiallyNotVisible: true},
            'refund',
            {path: 'refundLimit€', tooltip: true},
            {path: 'refundBalance€', tooltip: true},
            {path: 'postedRefund€', tooltip: true},
            {path: 'paidRefund€', tooltip: true}
        ]
    },
    filters: [
        {
            object: 'CUsers',
            path: 'user',
            display: 'fullName',
            placeholder: 'selectUsers',
            fieldPath: ['fullName', 'registrationNumber'],
            filters: ['salariedAndRetired'],
            clearable: true,
            client: true
        },
        {
            object: 'GlobalBenefitJoin',
            path: 'benefits',
            fieldPath: ['code', 'name', 'exercise'],
            display: 'name',
            placeholder: 'selectElements',
            type: 'tags',
            clearable: true,
            client: true,
            default: [],
            filters:[
                {
                    object: 'Exercise',
                    path: 'exercise',
                    display: 'code',
                    fieldPath: ['code', 'exerciseStatus', 'variableDot', 'fixedDot', 'startDate', 'endDate'],
                    clearable: false,
                    sortList: false,
                    client: true,
                    autoFetch: true,
                    match: function(object, context) {
                        const exerciseId = _.get(context.data, 'exercise.id')
                        return object.id === 'global' || object.exercise.id === exerciseId;
                    }
                }
            ],
        },
    ]
}

