import React, { Component } from 'react'
import logo from '../img/keenpoint-logo-horizontal.png'
import './login.css'

class LoginConfirmation extends Component {
    constructor() {
        super()
        this.state = {
            token: {},
        }
    }

    handleChange(field, event) {
        const newValue = event.target.value
        this.setState({
            [field]: newValue
        })
    }

    handleTokenChange(key, event) {
        const newValue = event.target.value
        this.setState({
            ...this.state,
            token: {
                ...this.state.token,
                [key]: newValue
            }
        }, () => {
            if(newValue && key !== 5) {
                console.log(newValue)
                document.getElementById(`Login-confirmation-code-input-${parseInt(key) + 1}`).focus();
            } else if(newValue && key === 5) {
                const token = [...Array(6).keys()].map(key => this.state.token[key]).join('')
                this.props.handleTwoFactorAuth(token)
            }
        })
    }

    handlePaste(key, event) {
        const pastedText = event.clipboardData.getData('text')
        const tokenValue = {}
        let currentKey = key
        for(let i = 0; i < pastedText.length; i++) {
            tokenValue[currentKey] = pastedText[i]
            currentKey++
        }
        this.setState({
            ...this.state,
            token: {
                ...this.state.token,
                ...tokenValue
            }
        }, () => {
            const token = [...Array(6).keys()].map(key => this.state.token[key]).join('')
            console.log(token)
            this.props.handleTwoFactorAuth(token)
        })
    }

    render() {
        const {t, maskedMail, maskedPhone} = this.props
        return (
            <div className="Login-container">
                <div className="Login-form-container">
                    <div className="Login-logo-container">
                        <img
                            alt="Login-logo"
                            src={logo}
                            width={250}
                        />
                    </div>
                    <div className='Login-confirmation-text'>
                        {
                            maskedPhone
                                ? t('codeSentToMailAndPhone', {mail: maskedMail, phone: maskedPhone})
                                : t('codeSentToMail', {mail: maskedMail})}
                    </div>
                    <div className="Login-confirmation-code">
                        {
                            [...Array(6).keys()].map(
                                key => <input
                                    id={`Login-confirmation-code-input-${key}`}
                                    className="Login-confirmation-code-input"
                                    type="text"
                                    maxLength={1}
                                    value={this.state.token[key]}
                                    onChange={event => this.handleTokenChange(key, event)}
                                    onPaste={event => this.handlePaste(key, event)}
                                    autoFocus={key === 0}
                                />
                            )
                        }
                    </div>
                    <button type="button" style={{marginTop: '20px'}} onClick={() => this.props.handleTwoFactorAuthReset()}>{t('resendCode')}</button>
                </div>
            </div>
        )
    }
}

export default LoginConfirmation
