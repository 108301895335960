import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";
import moment from "moment/moment";

export const entity = {
    name: 'FiscalYear',
    fields: [
        {path: 'code', unique: true},
        {path: 'fiscalYearRange', type: 'dateRange'},
        {path: 'fiscalYearStatus', type: 'FiscalYearStatus', fieldPath:["id"]},
    ],
    filters:[
        {
            name: "onlyOngoing",
            isDefault: false,
            query: function() {
                return {"fiscalYearStatus": "1"}
            }
        },
        {
            name: "nonClosed",
            isDefault: false,
            query: function () {
                return {"fiscalYearStatus": {$in: ["1", "3"]}}
            }
        },
        {
            name: "ongoingAndClosed",
            isDefault: false,
            query: function () {
                return {"fiscalYearStatus": {$in: ["1", "2"]}}
            }
        },
        { //verified
            name: "byProjectType",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const projectTypes = _.get(context.data, 'projectTypes')
                const ongoingValidationStatusesIds = ['1', '2', '3', '4', '5', '10', '11', '12']
                const postValidationStatusesIds = ['6', '7', '8']
                //we gonna treat 9 (abandoned differently)
                const abandonedStatusQuery1 = {
                    category: "1",
                    status: '9',
                    revisedTable: {$exists: true},
                }
                const abandonedStatusQuery2 = {
                    category: "1",
                    status: '9',
                    revisedTable: {$exists: false}
                }
                const query = !!projectTypes && !!projectTypes.length && projectTypes.length === 1 ?
                    projectTypes[0].id === '2' ?
                        {$or: [{category: "1", status: {$in: ongoingValidationStatusesIds}}, abandonedStatusQuery2]}
                        : {$or: [{category: "1", status: {$in: postValidationStatusesIds}}, abandonedStatusQuery1]}
                    : {}

                global.app.SE.FiscalYear.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {}
                }, (e, fiscalYears) => {
                    if (e) return callback(e)
                    global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: ['imputations'],
                        query
                    }, (e, projects) => {
                        if (e) return callback(e)
                        let fiscalYearsOfProjects = []
                        projects.forEach(project => {
                            const startMonth = project.startMonth
                            const endMonth = project.endMonth
                            const fiscalYearForThisDemand = fiscalYears.filter( fiscalYear => {
                                const fiscalYearStartDate = moment(fiscalYear.fiscalYearRange[0])
                                const fiscalYearEndDate = moment(fiscalYear.fiscalYearRange[1])
                                const firstCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                const secondCondition = fiscalYearStartDate.isSameOrAfter(startMonth, 'month') && fiscalYearEndDate.isSameOrBefore(endMonth, 'month')
                                const thirdCondition = fiscalYearStartDate.isSameOrBefore(endMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                const fourthCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(startMonth, 'month')
                                return firstCondition || secondCondition || thirdCondition || fourthCondition
                            })
                            fiscalYearsOfProjects.push(fiscalYearForThisDemand)
                        })
                        callback (null, {_id: {$in: fiscalYearsOfProjects.flat().map(FY => global.ObjectID(FY.id))}})
                    })
                })
            }
        }
    ]
}

export const module_ = {
    object: 'FiscalYear',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: 'exercice',
    viewMap: {
        dt: [
            'code',
            {path: 'fiscalYearRange'},
            {path: 'fiscalYearStatus', tKey: 'status'}
        ],
        form: {
            fields: [
                {path: 'code', required: true, editable: false},
                {path: 'fiscalYearRange', tKey: "Plage de l'exercice", required: true},
                {path: 'fiscalYearStatus', tKey: 'status', type: 'toggle', default: {id: '1'} },
            ],
        }
    }
}
