const _ = require('lodash')

export default {
    id: 'R',
    name: 'Keros',
    description: '',
    languages: ['fr'],
    entities: _.concat(
        [
            require('./fncs/exercise').entity,
            require('./fncs/reports').entity,
            require('./fncs/endowmentHistory').entity,
            require('./fncs/category').entity,
            require('./fncs/limit').entity,
            require('./fncs/bank').entity,
            require('./fncs/kp/accountsGroup').entity,
            require('./fncs/kp/analyticalCode').entity,
            require('./fncs/benefit').entity,
            require('./fncs/request').entity,
            require('./fncs/requestGroup').entity,
            require('./fncs/supplier').entity,
            require('./fncs/flow/accountingEntries').entity,
            require('./fncs/flow/bankFlow').entity,
            require('./fncs/processJoin').entity,
            require('./fncs/batch/batch').entity,
            require('./fncs/entriesExtraction').entity,
            require('./fncs/refundAnalysis').entity,
            require('./fncs/gifts').entity,
            require('./fncs/reallocation').entity,
            require('./fncs/entityJoin').entity,
            require("./fncs/dashboardCharts/balanceByUser").entity,
            require("./fncs/dashboardCharts/animatedVideo").entity,
            require("./fncs/dashboardCharts/holidaysVideo").entity,
            require("./fncs/dashboardCharts/burnoutVideo").entity,
            require("./fncs/dashboardCharts/newYearVideo").entity,
            require("./fncs/dashboardCharts/votingVideo").entity,
        ],
        require('./fncs/staticEntities').dependencies,
        require('./fncs/contactsModules').entities,
        require('./fncs/kp/accountsTemplate').entities,
        require('./fncs/registration').entities,
        require('./fncs/users').entities,
        require('./fncs/accountStatement').entities,
        require("./fncs/dashboardAffectation").dependencies,
    ),
    modules: _.concat(
        [
            // Administrator
            require('./fncs/exercise').module_,
            require('./fncs/endowmentHistory').module_,
            require('./fncs/category').module_,
            require('./fncs/limit').module_,
            require('./fncs/bank').module_,
            require('./fncs/users').module_,
            require('./fncs/benefit').module_,
            require('./fncs/supplier').module_,
            require('./fncs/contactsModules').administratorModule,
            require('./fncs/gifts').module_,
            // Mon dossier
            require('./fncs/information').module_,
            require('./fncs/request').module_,
        ],
        require('./fncs/requestGroup').modules,
        [
            require('./fncs/contactsModules').userModule,
            require('./fncs/accountStatement').myBinderAccountStatementModule,
            require('./fncs/reallocationRequest').module_
        ],
        // Mes avantages
        require('./fncs/wedoogiftLink').modules,
        [

            // Remboursement
            require('./fncs/supervisor').module_,
            require('./fncs/validator').module_,
            require('./fncs/refundFollowUp').module_,
            require('./fncs/refundAnalysis').module_,
            require('./fncs/accountStatement').refundAccountStatementModule,
            require('./fncs/reallocationValidation').module_,
            // Facture
            require('./fncs/registration').module_,
            require('./fncs/validation').module_,
            require('./fncs/invoiceFollowUp').module_,
            //Reporting
            require('./fncs/reports').module_,
            // Bank
            require('./fncs/batch/generation').module_,
            require('./fncs/batch/control').module_,
            require('./fncs/batch/payment').module_,
            require('./fncs/flow/bankFlow').module_,
            // Accounting
            require('./fncs/kp/accountsGroup').module_,
            require('./fncs/kp/analyticalCode').module_,
            require('./fncs/kp/accountsTemplate').module_,
            require('./fncs/flow/accountingEntries').module_,
            require('./fncs/entriesExtraction').module_,
            //KP
            require('./fncs/refundKpModule').module_,
            require('./fncs/invoiceKpModule').module_
        ]
    ),
    jobs: [
        //require('./fncs/jobs/refund').job,
        require('./fncs/jobs/initBackup').job,
        require('./fncs/jobs/resetDB').job
    ]
}
