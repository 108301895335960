import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { externalFetch } from '../../../../restClient'
import { kpFetch } from '../../../../restClient'
import { toastr } from 'react-redux-toastr'

class ExternalLink extends Component {
    launchLink = (externalLink, user, groupModel, t) => () => {
        if(externalLink.simpleUrl) {
            return window.open(
                externalLink.simpleUrl,
                '_blank'
            )
        }
        const data = { userOnMyAdvantagesLinks: user.id }
        const context = {
            accessId: `m-R-${externalLink.module}-dt`,
            moduleId: `m-R-${externalLink.module}`,
            groupModelId: groupModel.id,
            data
        }

        kpFetch('GET', `/api/r.cusers`, "", context)
            .then(
                ({ ok, json, message }) =>
                    new Promise((resolve, reject) => {
                        if (!ok || !json.length) {
                            reject()
                        } else {
                            console.log(json[0])
                            externalFetch(
                                'POST',
                                externalLink.url,
                                {
                                    login: `eng${json[0].registrationNumber}`,
                                    userId: json[0].userId
                                },
                                externalLink.headers
                            )
                                .then(({ ok, json, message }) => {
                                    if (ok) {
                                        resolve(json.token)
                                    } else {
                                        reject()
                                    }
                                })
                                .catch(reject)
                        }
                    })
            )
            .then((token) => {
                toastr.success(t('redirect'), t('redirectOKMessage'), {
                    timeOut: 0
                })
                window.open(
                    `https://app.glady.com/${externalLink.redirect}?sso=${token}`,
                    '_blank'
                )
            })
            .catch(error =>
                toastr.error(t('redirect'), t('redirectKOMessage'), {
                    timeOut: 0
                })
            )
    }

    render() {
        const { externalLink, user, groupModel, t } = this.props

        return (
            <li className="GroupModel-moduleList-li" key={externalLink.link}>
                <a onClick={this.launchLink(externalLink, user, groupModel, t)}>
                    {t(externalLink.link)}
                </a>
            </li>
        )
    }
}

ExternalLink.propTypes = {
    externalLink: PropTypes.object.isRequired
}

export default ExternalLink
