export const entity =  {
    name: "LocationTag",
    fields: [
        {path: "code", unique: true},
        {type: "Location", unique: true}
    ],
}

export const module_ = {
    object: "LocationTag",
    tKey: "mTitle_locationTag",
    category: {
        path: "organisation",
        icon: 'server'
    },
    maxResults: 2000,
    viewMap: {
        dt: [
            "code",
            {path: "location", display: "fullLocation"}
        ],
        form: [
            {path: "code", editable: false, required: true},
            {path: "location", display: "fullLocation", required: true}
        ]
    }
}
