import React, {Component} from 'react'
import _ from 'lodash'
import './StackedBarChart.css'
import * as d3 from 'd3'
import { select } from 'd3-selection';

const margin = { top: 20, right: 30, bottom: 80, left: 50 }
const z = ['#bf4a4a', '#1568ad', '#0e9729', '#dab350', '#87cbd4', '#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00', '#ffc08d', '#87d4a0', "#1b70fc", "#faff16", "#d50527", "#158940", "#f898fd", "#24c9d7", "#22e67a", "#e509ae", "#9dabfa", "#437e8a", "#b21bff", "#cb9b64", "#866888", "#ff7b91", "#94aa05", "#ac5906", "#82a68d", "#fe6616", "#7a7352", "#f9bc0f", "#b65d66", "#07a2e6", "#c091ae", "#8a91a7", "#88fc07", "#ea42fe", "#9e8010", "#10b437", "#c281fe", "#f92b75", "#07c99d", "#a946aa", "#bfd544", "#16977e", "#ff6ac8", "#a88178", "#5776a9", "#678007", "#fa9316", "#85c070", "#6aa2a9", "#989e5d", "#fe9169", "#cd714a", "#6ed014", "#c5639c", "#c23271", "#698ffc", "#678275", "#c5a121", "#a978ba", "#ee534e", "#d24506", "#59c3fa", "#ca7b0a", "#6f7385", "#9a634a", "#48aa6f", "#ad9ad0", "#d7908c", "#6a8a53", "#8c46fc", "#8f5ab8", "#fd1105", "#7ea7cf", "#d77cd1", "#a9804b", "#0688b4", "#6a9f3e", "#ee8fba", "#a67389", "#9e8cfe", "#bd443c", "#6d63ff", "#d110d5", "#798cc3", "#df5f83", "#b1b853", "#bb59d8", "#1d960c", "#867ba8", "#18acc9", "#25b3a7", "#f3db1d", "#938c6d", "#936a24", "#a964fb", "#92e460", "#a05787", "#9c87a0", "#20c773", "#8b696d", "#78762d", "#e154c6", "#40835f", "#d73656", "#1afd5c", "#c4f546", "#3d88d8", "#bd3896", "#1397a3", "#f940a5", "#66aeff", "#d097e7", "#fe6ef9", "#d86507", "#8b900a", "#d47270", "#e8ac48", "#cf7c97", "#cebb11", "#718a90", "#e78139", "#ff7463", "#bea1fd"]


class DynamicStackedBarChart extends Component {
    constructor(props) {
        super(props);

        const {keys, data, formValues, t} = props
        const filteredData = data.filter(o =>
            o.indicator === _.get(formValues, 'indicator.name')
            && !['Total', 'Total cumulé', 'Sous-total', 'Sous-total cumulé'].includes(o.element1)
        )
        const newKeys = []

        const transformedData = filteredData.length
            ? keys.map(date => filteredData.reduce((acc, object) => {
                const key = [object.element1, object.element2, object.element0].filter(elem => elem).map(elem => t(elem)).join(' , ')
                newKeys.push(key)
                return {
                    ...acc,
                    [key]: object[date]
                }
            }, {date: date }))
            : []

        this.state = {
            data: transformedData,
            keys: _.uniq(newKeys)
        }

        this.drawChart = this.drawChart.bind(this)
        this.timer = setTimeout(() => {
            if(this.state.data.length) this.drawChart()
        }, 700)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }


    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    drawAxisAndLegend() {
        const {h ,w, t} = this.props
        const {data, keys} = this.state

        const width = (w - 20) - margin.left - margin.right
        const height = h/2 - margin.top - margin.bottom

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const xAxis = d3.axisBottom()
            .scale(x)

        const yAxis = d3.axisLeft()
            .scale(y)
            .ticks(7)

        const layers = d3.stack().keys(keys)(data)

        x.domain(layers[0].map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1], d => d[1] )]).nice()

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const legendContainer = select('.BarChart').select('.legend')
            .attr('width', width + margin.left + margin.right)
            .attr('height', 30 * keys.length)
            .attr('transform', `translate(0,10)`)

        const legend = legendContainer
            .selectAll(".legend")
            .data(keys)

        legend.select("rect")
            .attr("x", -50)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(d, i) {return z[i];});

        legend.select("text")
            .attr("x", -25)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function(d, i) {
                return keys[i]
            });

        legend
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })


        const enterSelection = legend.enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function(d, i) { return "translate(100,"+ i*30 +")"; })

        enterSelection
            .append("rect")
            .attr("x", -50)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function(d, i) {return z[i];});


        enterSelection
            .append("text")
            .attr("x", -25)
            .attr("y", 9)
            .attr("dy", ".45em")
            .style("text-anchor", "start")
            .text(function(d, i) {
                return keys[i]
            });

        container.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .selectAll("text")
            .text(d => t(d))
            .attr("y", 5)
            .attr("x", -6)
            .attr("dy", ".45em")
            .attr("transform", "rotate(-55)")
            .style("text-anchor", "end")

        container.select('.axis--y')
            .attr('transform', `translate(0,0)`)
            .call(yAxis)

    }

    drawChart() {

        const {h ,w} = this.props
        const {data, keys} = this.state

        const width = (w - 20) - margin.left - margin.right
        const height = h/2 - margin.top - margin.bottom

        const totalData = data.map(object => {
            return {
                date: object.date,
                total: keys.reduce((acc, key) => acc + object[key], 0)
            }
        })

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const layers = d3.stack().keys(keys)(data)

        x.domain(layers[0].map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1], d => d[1] )]).nice()

        const values = container
            .select(".values")
            .selectAll("text")
            .data(totalData)

        const layer = container.selectAll('.layer')
            .data(layers)
            .enter()
            .append('g')
            .attr('class', 'layer')
            .style('fill', (d, i) => (z[i]))


        layer.selectAll('rect')
            .data(d => d)
            .enter()
            .append('rect')
            .attr('x', d => x(d.data.date))
            .attr('width', x.bandwidth())
            .attr('height', 0)
            .attr("y", height)
            .transition()
            .duration(1000)
            .attr('height', d => Math.abs(y(d[0]) - y(d[1])))
            .attr('y', d => y(d[1]))

        values.enter().append("text")
            .attr("x", function(d) {
                return x(d.date) + x.bandwidth()/2; })
            .attr("y", height - 10)
            .transition()
            .duration(1000)
            .attr("y", function(d) {
                return y(d.total) - 10
            })
            .attr("dy", ".1em")
            .text(d => _.round(d.total, 2))
            .style("text-anchor", "middle")
            .style("font-size", "12px")

    }

    updateChart(){

        const {h ,w} = this.props
        const {data, keys} = this.state

        const width = (w - 20) - margin.left -margin.right
        const height = h/2 - margin.top - margin.bottom

        const totalData = data.map(object => {
            return {
                date: object.date,
                total: keys.reduce((acc, key) => acc + object[key], 0)
            }
        })

        const x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.5)
            .paddingOuter(0.4)

        const y = d3.scaleLinear()
            .rangeRound([height, 0])

        const container = select('.BarChart').select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        const layers = d3.stack().keys(keys)(data)

        x.domain(layers[0].map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1], d => d[1] )]).nice()


        container.selectAll('.layer')
            .data(layers)
            .exit()
            .remove()

        container.selectAll('.layer')
            .data(layers)
            .style('fill', (d, i) => (z[i]))
            .selectAll('rect')
            .data(d => d)
            .attr('width', x.bandwidth())
            .attr('x', d => x(d.data.date))
            .attr('height', d => Math.abs(y(d[0]) - y(d[1])))
            .attr('y', d => y(d[1]))

        container
            .select(".values")
            .exit()
            .remove()

        container
            .select(".values")
            .selectAll("text")
            .attr("x", function(d) {
                return x(d.date) + x.bandwidth()/2; })
            .attr("y", function(d) {
                return y(d.total) - 10
            })
            .text(d => _.round(d.total, 2))
            .attr("dy", ".1em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")

    }

    render() {
        if(this.state.data.length) {
            this.drawAxisAndLegend()
            this.updateChart()
        }
        return (
            <div className="BarChart">
                <svg className="svgChart" style={{marginLeft: "10px"}}>
                    <g className="container">
                        <g className="values"/>
                        <g className="axis axis--x"/>
                        <g className="axis axis--y"/>
                    </g>
                </svg>
                <svg className="legend"/>
            </div>
        )
    }
}

export default DynamicStackedBarChart;
