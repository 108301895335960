import moment from "moment/moment";
import _ from "lodash";

export function substractedItems2(newDelegation, oldDelegationIDs) {
    let result = []
    const newDelegationIDs = newDelegation.map( user => global.ObjectID(user.id) )
    if ( !!newDelegationIDs.length ){
        const newDelegationIDsStrings = newDelegationIDs.map( id => id.toString() )
        const oldDelegationIDsStrings = oldDelegationIDs.map( id => id.toString() )
        newDelegationIDsStrings.forEach( id => {
            if ( !oldDelegationIDsStrings.includes(id) ){
                const index = newDelegationIDsStrings.indexOf(id)
                result.push( newDelegation[index] )
            }
        })
        return result
    }
    else {
        return result
    }
}

export function addFloats(num1, num2) {
    // Determine the precision of both numbers
    const precision1 = _.get(num1.toString().split('.'), '[1].length', 0);
    const precision2 = _.get(num2.toString().split('.'), '[1].length', 0);
    const maxPrecision = Math.max(precision1, precision2);

    // Scale the numbers to integers based on the maximum precision
    const scaleFactor = Math.pow(10, maxPrecision);
    const scaledNum1 = num1 * scaleFactor;
    const scaledNum2 = num2 * scaleFactor;

    // Perform the addition with integers
    const sum = scaledNum1 + scaledNum2;

    // Scale back and round to the original precision
    return _.round(sum / scaleFactor, maxPrecision);
}


export function fiscalYearsMonth (fiscalYears, startMonth, endMonth){
    const monthList = fiscalYears.reduce((acc, fiscalYear, index) => {

        const isItFirstFiscalYear= index === 0
        const isItLastFiscalYear= index === fiscalYears.length-1

        const fiscalYearStartMonth = isItFirstFiscalYear ? startMonth : moment(fiscalYear.fiscalYearRange[0]).format('YYYY-MM')
        const fiscalYearStartMonthPlusOne = moment(fiscalYearStartMonth, 'YYYY-MM').add(1, 'months').format('YYYY-MM')

        const fiscalYearEndMonth = isItLastFiscalYear ? endMonth : moment(fiscalYear.fiscalYearRange[1]).format('YYYY-MM')
        const fiscalYearEndMonthMinusOne = moment(fiscalYearEndMonth, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM')

        const firstMonth = {month: fiscalYearStartMonth + ` (${fiscalYear.code})`, fiscalYearID: fiscalYear.id}
        const lastMonth = {month: fiscalYearEndMonth + ` (${fiscalYear.code})`, fiscalYearID: fiscalYear.id}
        const intermediateMonths = monthRangeToList(fiscalYearStartMonthPlusOne, fiscalYearEndMonthMinusOne).map( month => {
            return {month: month, fiscalYearID: fiscalYear.id}
        })
        return fiscalYearStartMonth === fiscalYearEndMonth ?
            [...acc, firstMonth, ...intermediateMonths]
            :
            [...acc, firstMonth, ...intermediateMonths, lastMonth]
    }, [])
    return monthList
}

export function fiscalYearsMonth2 (fiscalYears, startMonth, endMonth){
    const monthList = fiscalYears.reduce((acc, fiscalYear, index) => {

        const isItFirstFiscalYear= index === 0
        const isItLastFiscalYear= index === fiscalYears.length-1

        const fiscalYearStartMonth = isItFirstFiscalYear ?
            startMonth
            : moment(fiscalYear.fiscalYearRange[0]).format('YYYY-MM')
        const fiscalYearStartMonthPlusOne = moment(fiscalYearStartMonth, 'YYYY-MM')
            .add(1, 'months').format('YYYY-MM')

        const fiscalYearEndMonth = isItLastFiscalYear ?
            endMonth :
            moment(fiscalYear.fiscalYearRange[1]).format('YYYY-MM')
        const fiscalYearEndMonthMinusOne = moment(fiscalYearEndMonth, 'YYYY-MM')
            .subtract(1, 'months').format('YYYY-MM')

        const firstMonth = {
            month: isItFirstFiscalYear && startMonth.slice(5,7) !== moment(fiscalYear.fiscalYearRange[0]).format('YYYY-MM').slice(5,7) ?
                startMonth
                : fiscalYearStartMonth + ` (${fiscalYear.code})`,
            fiscalYearID: fiscalYear.id
        }
        const lastMonth = {
            month: isItLastFiscalYear && endMonth.slice(5,7) !== moment(fiscalYear.fiscalYearRange[1]).format('YYYY-MM').slice(5,7) ?
                endMonth
                : fiscalYearEndMonth + ` (${fiscalYear.code})`,
            fiscalYearID: fiscalYear.id
        }
        const intermediateMonths = monthRangeToList(fiscalYearStartMonthPlusOne, fiscalYearEndMonthMinusOne).map( month => {
            return {month: month, fiscalYearID: fiscalYear.id}
        })
        return fiscalYearStartMonth === fiscalYearEndMonth ?
            [...acc, firstMonth, ...intermediateMonths]
            :
            [...acc, firstMonth, ...intermediateMonths, lastMonth]
    }, [])
    return monthList
}

export function monthRangeToList(startMonth, endMonth){
    if (startMonth && endMonth){
        if (moment(endMonth, 'YYYY-MM').isSameOrAfter(moment(startMonth, 'YYYY-MM'), 'month')){
            let monthList= [startMonth]
            while (monthList[monthList.length-1]!==endMonth){
                monthList.push( moment(monthList[monthList.length-1], 'YYYY-MM').add(1, 'months').format('YYYY-MM')
                )
            }
            return monthList
        }
        return []
    }
}

export function compareArrays(arr1, arr2){
    return !!arr1.length && !!arr2.length && !arr1.some(obj => !arr2.includes(obj)) && !arr2.some(obj => !arr1.includes(obj))
}

export function isArrayInArrayOfArrays(targetArray, arrayOfArrays) {
    return !!arrayOfArrays.length && arrayOfArrays.some(subArray =>
        compareArrays(subArray, targetArray)
    );
}

export function hasDuplicates(array) {
    let valuesSoFar = [];
    for (let i = 0; i < array.length; ++i) {
        let value = array[i];
        if (valuesSoFar.indexOf(value) !== -1) {
            return true;
        }
        valuesSoFar.push(value);
    }
    return false;
}