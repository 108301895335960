import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as selectors from '../../selectors'
import { Route, Switch, withRouter } from 'react-router-dom'
import {
    sendMail,
    login,
    register,
    handleTwoFactorAuth,
    handleTwoFactorAuthReset,
    resetGet,
    resetPost,
    fetchViewer,
    fetchConfig,
    invalidateViewer,
    setNotFoundPage
} from '../../actions'
import LoginPage from './LoginPage'
import RegistrationPage from './UserRegistrationPage'
import LoginConfirmationPage from './LoginConfirmationPage'
import ResetPage from './ResetPage'
import RecoverPassPage from './RecoverPass'
import LogoutPage from './LogoutPage'
import PublicPage from './PublicPage'
import AuthFailure from '../../../../components/AuthFailure'
import { logoutAndRedirect } from '../../actions/index'
import {translate} from "react-i18next";

export const App = ({
    userIsLogged,
    userIsLogging,
    viewerIsFetching,
    viewerError,
    viewerErrorStatus,
    invalidateViewer,
    didInvalidateViewer,
    previousPath,
    fetchViewer,
    fetchConfig,
    login,
    register,
    handleTwoFactorAuth,
    handleTwoFactorAuthReset,
    mail,
    maskedMail,
    maskedPhone,
    resetGet,
    resetPost,
    sendMail,
    logout,
    isNotFoundPage,
    setNotFoundPage,
    t,
    language,
    serverUrl,
    authStrategies,
    client
}) => {
    return (
        <Switch>
            <Route path="/registration">
                <RegistrationPage
                    register={register}
                    t={t}
                />
            </Route>
            <Route
                path="/login"
                render={({ history }) => (
                    <LoginPage
                        client={client}
                        isLoggedIn={userIsLogged}
                        isLogging={userIsLogging}
                        login={login}
                        fetchConfig={fetchConfig}
                        serverUrl={serverUrl}
                        authStrategies={authStrategies}
                        history={history}
                        t={t}
                        previousPath={previousPath}
                    />
                )}
            />
            <Route
                path="/loginConfirmation"
                render={({ history }) => (
                    <LoginConfirmationPage
                        isLoggedIn={userIsLogged}
                        isLogging={userIsLogging}
                        handleTwoFactorAuth={handleTwoFactorAuth}
                        handleTwoFactorAuthReset={handleTwoFactorAuthReset}
                        t={t}
                        mail={mail}
                        maskedMail={maskedMail}
                        maskedPhone={maskedPhone}
                        history={history}
                        previousPath={previousPath}
                    />
                )}
            />
            <Route
                path="/reset/:token"
                render={({ history, match }) => (
                    <ResetPage
                        client={client}
                        fetchConfig={fetchConfig}
                        history={history}
                        resetGet={resetGet}
                        reset={resetPost}
                        isLoggedIn={userIsLogged}
                        isLogging={userIsLogging}
                        t={t}
                        token={match.params.token}
                    />
                )}
            />
            <Route
                path="/forgotten"
                render={({ history }) => (
                    <RecoverPassPage
                        client={client}
                        fetchConfig={fetchConfig}
                        history={history}
                        login={login}
                        sendMail={sendMail}
                        isLoggedIn={userIsLogged}
                        isLogging={userIsLogging}
                        t={t}
                        language={language}
                    />
                )}
            />
            <Route
                path="/authenticationFailure/:strategy"
                render={({history, match}) => (
                    <AuthFailure
                        client={client}
                        strategy={match.params.strategy}
                        t={t}
                        history={history}
                        language={language}
                    />
                )}
            />
            <Route path="/logout" render={() => <LogoutPage logout={logout} />} />
            <Route
                path="/"
                render={() => (
                    <PublicPage
                        didInvalidateViewer={didInvalidateViewer}
                        fetchViewer={fetchViewer}
                        fetchConfig={fetchConfig}
                        viewerIsFetching={viewerIsFetching}
                        viewerError={viewerError}
                        viewerErrorStatus={viewerErrorStatus}
                        retry={invalidateViewer}
                        isNotFoundPage={isNotFoundPage}
                        setNotFoundPage={setNotFoundPage}
                        t={t}
                        language={language}
                    />
                )}
            />
        </Switch>
    )
}

App.propTypes = {
    fetchViewer: PropTypes.func.isRequired,
    fetchConfig: PropTypes.func.isRequired,
    invalidateViewer: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    userIsLogged: PropTypes.bool.isRequired,
    userIsLogging: PropTypes.bool.isRequired,
    didInvalidateViewer: PropTypes.bool.isRequired,
    viewerIsFetching: PropTypes.bool.isRequired,
    viewerError: PropTypes.string,
    viewerErrorStatus: PropTypes.number,
    isNotFoundPage: PropTypes.bool.isRequired
}

/* istanbul ignore next */
const mapStateToProps = state => ({
    client: state.ui.client.client,
    serverUrl: state.ui.client.auth.serverUrl,
    authStrategies: state.ui.client.auth.strategies,
    userIsLogged: selectors.getIsLogged(state),
    mail: state.user.mail,
    maskedMail: state.user.maskedMail,
    maskedPhone: state.user.maskedPhone,
    userIsLogging: selectors.user.getIsLogging(state),
    didInvalidateViewer: selectors.network.getDidInvalidate(state),
    viewerIsFetching: selectors.network.getIsFetching(state),
    viewerError: selectors.network.getError(state),
    viewerErrorStatus: selectors.network.getErrorStatus(state),
    previousPath: selectors.network.getPreviousPath(state),
    isNotFoundPage: selectors.isNotFoundPage(state),
    language: selectors.getLanguage(state)
})

/* istanbul ignore next */
const mapDispatchToProps = {
    login,
    register,
    handleTwoFactorAuth,
    handleTwoFactorAuthReset,
    resetGet,
    resetPost,
    sendMail,
    logout: logoutAndRedirect,
    fetchViewer,
    fetchConfig,
    invalidateViewer,
    setNotFoundPage
}

/* istanbul ignore next */
export default withRouter(translate('platform')(
    connect(mapStateToProps, mapDispatchToProps)(App)
))

