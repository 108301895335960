const _ = require('lodash')

export default {
    id: "I",
    name: "projectsManagement",
    description: "",
    languages: ['en', 'fr'],
    entities: _.concat(
        require("./edf-in/staticEntities").default,
        require("./edf-in/referential").entities,
        require("./edf-in/businessFollowUp/entities").entities,
        require("./edf-in/elaborateBP").entities,
        require("./edf-in/workflow/workflowConfig").entities,

        require("./edf-in/resourcesFollowUp/projectPortfolio").entities,
        require("./edf-in/resourcesFollowUp/commonEntities").entities,
        require("./edf-in/resourcesFollowUp/projectEfficiency").entities,
        require("./edf-in/resourcesFollowUp/orderTaking").entities,
        require("./edf-in/resourcesFollowUp/brAndCommercial").entities,
        require("./edf-in/resourcesFollowUp/activity").entities,
        require("./edf-in/resourcesFollowUp/projectProductivity").entities,
        require("./edf-in/dashboardAffectation").dependencies,
        require("./edf-in/extraction").entities,
        [
            require("./edf-in/businessFollowUp-Modules/businessProject/adminDelegation").delegationEntity,
            require("./edf-in/contractMonitoring").entity,
            require("./edf-in/businessFollowUp-Modules/reporting/portefeuille").portefeuilleEntity,
            // Dashboard
            require("./edf-in/dashboardElements/turnoverPerZone").entity,
            require("./edf-in/dashboardElements/turnoverPerProductLine").entity,
            require("./edf-in/dashboardElements/orderTakingPerZone").entity,
            require("./edf-in/dashboardElements/orderTakingPerProductLine").entity,
            require("./edf-in/dashboardElements/contractsNumberPerZone").entity,
            require("./edf-in/dashboardElements/contractsNumberPerProductLine").entity,
            require("./edf-in/dashboardElements/offerSubmissionPerZone").entity,
            require("./edf-in/dashboardElements/offerSubmissionPerProductLine").entity,
            require("./edf-in/dashboardElements/orderTakingGlobal").entity,
        ]
    ),
    modules: _.concat(
        [

            // Project //
            require("./edf-in/businessFollowUp-Modules/businessProject/detection").detection,
            require("./edf-in/businessFollowUp-Modules/businessProject/montage").montage,
            require("./edf-in/businessFollowUp-Modules/businessProject/contractualisation").contractualisation,
            require("./edf-in/businessFollowUp-Modules/businessProject/realisation").realisation,
            require("./edf-in/businessFollowUp-Modules/financialData/financialSummaries").financialSummary,

            // Technique //
            require("./edf-in/businessFollowUp-Modules/reporting/portefeuille").portefeuille,
            require("./edf-in/businessFollowUp-Modules/reporting/contractMonitoring").ContractMonitoring,


            // Gestion //
            require("./edf-in/businessFollowUp-Modules/financialData/gestion").gestion,
            require("./edf-in/operationsAndservicesContracts").OperationsAndServicesContracts,
            require("./edf-in/referential").referentialBusiness,

            // Reporting //
            require("./edf-in/businessFollowUp-Modules/reporting/businessRevue").businessRevue,
            require("./edf-in/businessFollowUp-Modules/reporting/projectMonitoring").projectMonitoring,
            require("./edf-in/businessFollowUp-Modules/reporting/pointDeSortie").pointDeSortie,
            require("./edf-in/extraction").module_,
            require("./edf-in/resourcesFollowUp/activity").businessActivitiesByUser,

            // Analyse //
            require("./edf-in/resourcesFollowUp/orderTaking").module_,
            require("./edf-in/resourcesFollowUp/brAndCommercial").module_,
            require("./edf-in/resourcesFollowUp/projectPortfolio").projectPortfolio,
            require("./edf-in/resourcesFollowUp/projectEfficiency").projectEfficiency,
            require("./edf-in/resourcesFollowUp/projectProductivity").projectProductivity,


            // Référentiel //
            require("./edf-in/referential").zone,
            require("./edf-in/referential").country,
            require("./edf-in/referential").rangeOfOffer,
            require("./edf-in/referential").typeOfOffer,
            require("./edf-in/referential").customer,
            require("./edf-in/referential").provider,
            require("./edf-in/referential").teamMemberProfile,

            // Configuration
            require("./edf-in/referential").teamMember
        ],
        require("./edf-in/workflow/workflowConfig").modules,
        [
            require("./edf-in/referential").userDelegation,
            require("./edf-in/businessFollowUp-Modules/businessProject/adminDelegation").adminDelegation,

            // Keenpoint
            require("./edf-in/businessFollowUp-Modules/businessProject/realisation").realisationOld,
            require("./edf-in/businessFollowUp-Modules/businessProject/kpBusinessProject").kpBusinessProject,
            require("./edf-in/businessFollowUp-Modules/reporting/kpBusinessRevue").kpBusinessRevue,
            require("./edf-in/elaborateBP").structure,
            require("./edf-in/elaborateBP").hypVentes,
            require("./edf-in/elaborateBP").hypHorsVentes,
            require("./edf-in/elaborateBP").hypFiscales
        ]
    ),
    jobs: require('./edf-in/businessFollowUp/jobs').jobs
};
