export const entity = {
    name: "HistoryTag",
    fields: [
        'tagId',
        'date',
        'user',
        'family',
        'adress',
        {
            path: "latitudeAndLongitude",
            fieldPath: ["localisation"],
            f: function () {
                return this.localisation && `${this.localisation.latitude},${this.localisation.longitude}`
            }
        },
        {
            path: "altitude",
            fieldPath: ["localisation"],
            f: function () {
                return this.localisation && this.localisation.altitude
            }
        },
        {
            path: "accuracy",
            fieldPath: ["localisation"],
            f: function () {
                return this.localisation && this.localisation.accuracy
            }
        },
        'localisation',
        'text'
    ]
}

export const module_ = {
    object: "HistoryTag",
    tKey: "mTitle_history",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    maxResults: 2000,
    viewMap: {
        dt: [
            'tagId',
            'family',
            {path: 'latitudeAndLongitude', tKey: 'Latitude and longitude'},
            'altitude',
            {path: 'accuracy', initiallyNotVisible: true},
            {path: 'address', initiallyNotVisible: true},
            'user',
            {path: 'date', width: 300},
            {path: 'text', tKey: 'comment'}
        ]
    }
}
