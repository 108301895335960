const _ = require("lodash");

export const entity = {
    name: "Organization",
    facets: [
        {name: "codeName", codeUniqueWith: "organizationAxis"},
        "translatedField"
    ],
    fields: [
        "OrganizationAxis",
        {
            path: "completeName",
            fieldPath: ["organizationAxis.tName", "tName"],
            f: function () {
                return (this.organizationAxis ? (this.organizationAxis.tName + " : ") : "") + this.tName;
            }
        }
    ]
}

export const module_ = {
    object: "Organization",
    tKey: "mTitle_organization",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: "name",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            {path: 'organizationAxis', display: 'tName', initiallyNotVisible: true},
            "code",
            {path: "name", type: "translatedText"}
        ],
        form: [
            {path: "code", editable: false},
            {path: "name", type: "textarea"},
            {
                path: "organizationAxis",
                display: "tName",
                editable: false  // organization axis is not editable. If it was, it would raise an error, by the interaction with the filter, each time it is edited.
            }
        ]
    },
    // facets: [{name: "codeName", editableCode: false}],
    filters: [
        {
            path: "organizationAxis",
            object: "OrganizationAxis",
            display: "tName",
            client: true,
            query: function (context) {
                const organizationAxisId = _.get(context, "data.organizationAxis.id");
                if (organizationAxisId) return {
                    "organizationAxis": global.ObjectID(organizationAxisId)
                };
            },
            clearable: false
        }
    ],
    removable: false
}
