import {basicContext} from "../../utils/contextUtils";
import {decrypt} from "../../utils/crypto";
import {setFieldVisibility} from "../../../apps/KpModule/actions";

const _ = require('lodash')

export const entity = {
    name: 'Shelter',
    facets: ['codeName', 'comments', 'files'],
    fields: [
        'Organization',
        {path: 'types', type: 'ShelterType', link: 'MTM', nullable: true},
        {type: 'AnimalType', link: 'MTM', nullable: true},
        'address',
        'additionalAddress',
        'shelterSchedules',
        'adoptionSchedules',
        'zipCode',
        'city',
        {path: 'zone', type: 'Zone'},
        'country',
        { path: 'lastUser', type: 'User', nullable: true },
        { path: 'lastModificationDate', type: 'date', nullable: true },
        {
            path: 'users',
            $f: (shelter, context, callback) => {
                global.app.C.CUser.find({
                    ...basicContext(context),
                    fieldPath: ['id', 'civility.id', 'civility.name', 'firstname', 'lastname', 'mail', 'phone', 'functions.id', 'functions.name'],
                    query: {shelters: {$elemMatch : {$eq: global.ObjectID(shelter.id)}}}
                }, (e, users) => {
                    callback(
                        e,
                        users.map(user => ({
                            ...user,
                            civility: context.tc(user.civility?.name),
                            mail: decrypt(user.mail),
                            phone: decrypt(user.phone),
                            active: user.active,
                            functions: user.functions.map(userFunction => userFunction.name).join(', ')
                        }))
                    )
                })
            }
        },
    ],
    filters: [
        {
            name: 'byOrganization',
            isDefault: false,
            query: (context) => {
                const organizationId = _.get(context, 'data.organizationId')
                return organizationId
                    ? {organization: global.ObjectID(organizationId)}
                    : {_id: null}
            }
        },
        {
            name: "byUserOrganization",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const user = context.user
                global.app.C.CUser.get({kpUser: global.ObjectID(user.id)}, {
                    ...basicContext(context),
                    fieldPath: ['id', 'organization.id', 'organization.organizationType.memberAssociation', 'shelters.id']
                }, (error, cUser) => {
                    if(error) return callback(error)
                    if(!cUser.organization?.organizationType?.memberAssociation) return callback(null, {_id: null})
                    callback(null, {organization: global.ObjectID(cUser.organization.id)})
                })
            }
        },
        {
            name: "byUser",
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const user = context.user
                global.app.C.CUser.get({kpUser: global.ObjectID(user.id)}, {
                    ...basicContext(context),
                    fieldPath: ['id', 'organization.id', 'organization.organizationType.memberAssociation', 'shelters.id']
                }, (error, cUser) => {
                    if(error) return callback(error)
                    if(!cUser.organization?.organizationType?.memberAssociation) return callback(null, {_id: null})
                    callback(
                        null,
                        !!cUser.shelters.length
                            ? {_id: {$in: cUser.shelters.map(shelter => global.ObjectID(shelter.id))}}
                            : {organization: global.ObjectID(cUser.organization.id)}
                    )
                })
            }
        }
    ],
    beforeSave: (object, oldObject, context, callback) => {
        object.lastUser = new global.ObjectID(context.user.id)
        object.lastModificationDate = new Date()
        callback(null, object, oldObject)
    }
}

export const module_ = {
    object: 'Shelter',
    tKey: 'mTitle_shelter',
    objectIdentifier: 'name',
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            'code', 'name',
            {path: 'organization', display: 'fullName'},
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            {path: 'zone'},
            'country',
            {path: 'types'},
            {path: 'animalTypes', tKey: 'animalType', display: 'name'},
            {path: 'lastUser'},
            {path: 'lastModificationDate'},
        ],
        form: [
            'code', 'name',
            {path: 'organization', display: 'fullName', filters: ['isMemberAssociation']},
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            {path: 'zone'},
            'country',
            {
                path: 'types',
                sortList: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {store}) => {
                        const isShelter = !!newValue?.length && newValue.map(value => value.id).includes('shelter')

                        store.dispatch(setFieldVisibility('e_shelterSchedules', isShelter))
                        store.dispatch(setFieldVisibility('e_adoptionSchedules', isShelter))

                    }
                }
            },
            {path: 'shelterSchedules', type: 'textarea'},
            {path: 'adoptionSchedules', type: 'textarea'},
            {path: 'animalTypes', tKey: 'animalType', display: 'name'},
            {
                path: 'users',
                tKey: 'contacts',
                type: 'dtObjects',
                disabled: true,
                fields: [
                    {path: 'civility'},
                    {path: 'firstname'},
                    {path: 'lastname'},
                    {path: 'mail'},
                    {path: 'phone'},
                    {path: 'functions'},
                    {path: 'active', type: 'boolean', width: 100}
                ]
            },
            'files',
            'comments'
        ]
    }
}

export const userModule_ = {
    name: 'MyShelter',
    object: 'Shelter',
    tKey: 'mTitle_myShelter',
    newable: false,
    removable: false,
    objectIdentifier: 'name',
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            'code', 'name',
            {path: 'organization', display: 'fullName'},
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            {path: 'zone'},
            'country',
            {path: 'types'},
            {path: 'animalTypes', tKey: 'animalType', display: 'name'}
        ],
        form: [
            'name',
            {path: 'organization', display: 'fullName', filters: ['isMemberAssociation'], editable: false},
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            {path: 'zone'},
            'country',
            {
                path: 'types',
                sortList: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {store}) => {
                        const isShelter = !!newValue?.length && newValue.map(value => value.id).includes('shelter')

                        store.dispatch(setFieldVisibility('e_shelterSchedules', isShelter))
                        store.dispatch(setFieldVisibility('e_adoptionSchedules', isShelter))

                    }
                }
            },
            {path: 'shelterSchedules', type: 'textarea'},
            {path: 'adoptionSchedules', type: 'textarea'},
            {path: 'animalTypes', tKey: 'animalType', display: 'name', editable: false},
            {
                path: 'users',
                tKey: 'contacts',
                type: 'dtObjects',
                disabled: true,
                fields: [
                    {path: 'civility'},
                    {path: 'firstname'},
                    {path: 'lastname'},
                    {path: 'mail'},
                    {path: 'phone'},
                    {path: 'functions'},
                    {path: 'active', type: 'boolean', width: 100}
                ]
            },
            'files'
        ]
    },
    filters: [
        'byUser'
    ]
}
