import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Select } from '../../../../components/Form/index'
import {
    getListOptions,
    getFilterListsAreFetching,
    getClearable
} from '../../selectors/index'
import './FilterDropdownField.css'
import {colourStyles} from './SelectStyles'
import {translateName} from "../../../../utils/i18n";

const DropDown = ({
    input: { value, onChange },
    id,
    path,
    placeholder = "select",
    selectOptions,
    optionsById,
    originalOptionsById,
    isFetching,
    clearable,
    width,
    selectedColor,
    t
}) => {
    if(value && value.id && !optionsById[value.id] && !isFetching) {
        onChange(null)
    }

    if(value && value.id && originalOptionsById[value.id] && !_.isEqual(value, originalOptionsById[value.id])) {
        onChange(originalOptionsById[value.id])
    }

    return <Select
        id={id}
        path={path}
        className="FilterDropdownField-Select"
        noOptionsMessage={() => t('noOptions')}
        placeholder={t(placeholder)}
        options={selectOptions}
        selectedColor={selectedColor}
        multi={false}
        styles={colourStyles}
        onChange={option =>
            onChange(option && option.value && originalOptionsById[option.value])
        }
        value={(value && optionsById[value.id]) || null}
        isClearable={clearable}
        isLoading={isFetching}
        backspaceRemovesValue={true}
        width={width}
        t={t}
    />
}

const FilterField = ({
    filter,
    options,
    isFetching,
    clearable,
    fetchFilterData,
    fetchFilterFieldsLists,
    onChange,
    t,
    language,
    groupModel
}) => {
    const originalOptionsById = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.id]: option
        }
    }, {})
    const optionsById = options.reduce((acc, option) => {
        const display = _.get(option, filter.display)
        return {
            ...acc,
            [option.id]: {
                value: option.id,
                label: filter.translateName
                    ? translateName(display, language)
                    : filter.translate
                        ? t(display)
                        : display,
                optionData: option
            }
        }},{})

    const selectOptions = options.map(option => optionsById[option.id])
    return (
        <Field
            name={filter.path}
            id={filter.id}
            path={filter.tKey || filter.path}
            display={filter.display}
            placeholder={filter.placeholder}
            selectedColor={groupModel && groupModel.selectedColor}
            component={DropDown}
            clearable={clearable}
            width={filter.width}
            isFetching={isFetching}
            selectOptions={selectOptions}
            optionsById={optionsById}
            originalOptionsById={originalOptionsById}
            onChange={(event, value) => {
                // we need to call fetchDtData after reduxFormChange
                event.preventDefault()
                onChange(filter.path, value)
                // fetch new filter lists if filter filters another filter
                if(filter.filter) {
                    fetchFilterFieldsLists()
                }
                if(!!filter.autoFetch) {
                    fetchFilterData()
                }
            }}
            t={t}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    isFetching: getFilterListsAreFetching(state),
    options: getListOptions(state, filter.id, filter.sortList),
    clearable: getClearable(state, filter.id)
})

export default connect(mapStateToProps)(FilterField)
