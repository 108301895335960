const { byCompany, byArticle } = require('./filters')
const { fieldPathJoinGetter } = require("../../utils/kp3Utils");

export const entities = [
    {
        name: 'ArticleSubFamilyHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith:['subFamily', 'article'], index: true},
            'SubFamily',
            {type: 'Company', index: true},
            {path: 'affiliatedCompany', type: 'Company'},
            {path: 'state', type: 'boolean'},
            'HistoryDataType'
        ],
        filters: [byArticle]
    },
    {
        name: 'Article',
        facets: [{name: 'codeName', codeUniqueWith: 'company'}],
        fields: [
            {path: 'code',notEmpty: true, unique: true, uniqueWith: 'company', index: true},
            {path: 'name', index: true},
            'Company',
            {
                type: 'ArticleSubFamilyHistory',
                path: 'subFamilies',
                link: {
                    type: "OTM",
                    onChild: true,
                    oppositeField: {index: true}
                }
            },
            {path: "active", type: "boolean"},
            fieldPathJoinGetter({
                path: "fullName",
                fieldPath: ["code", "name"]
            })
        ],
        filters: [{
            ...byCompany,
            autoFetch: true
        }]
    }
]

export const modules = [
    {
        object: 'ArticleSubFamilyHistory',
        tKey: 'mTitle_article',
        newable: false,
        removable: false,
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'company',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                {path: 'article.code', tKey: 'code', width: 100},
                {path: 'article.name', tKey: 'name'},
                {path: 'date', width: 100},
                {
                    path: 'subFamily',
                    display: 'fullName'
                },
                {path: 'affiliatedCompany', display: 'fullName'},
                'state',
                {path: 'historyDataType', tKey: 'source'}
            ],
        },
        filters: ['byArticle']
    }
]
