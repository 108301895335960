import React from 'react'
import PropTypes from 'prop-types'
import './DataTableBoardButton.css'

const DataTableBoardButton = ({ onClick, children, title }) => (
    <button
        className="DataTable-Board-icon-container"
        onClick={onClick}
        title={title}
    >
        {children}
    </button>
)

DataTableBoardButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default DataTableBoardButton
