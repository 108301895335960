import 'rc-time-picker/assets/index.css';
import './TimePicker.css'
import React from 'react';
import ValidationUI from '../ValidationUI'

import Picker from 'rc-time-picker';
import PropTypes from 'prop-types'
import moment from 'moment/moment'


const TimePicker = props => {

    return (
        <div className="TimePicker-wrapper">
            <Picker
                style={{ width: 100 }}
                showSecond={props.showSecond}
                onChange={props.onChange}
                disabled={props.disabled}
                minuteStep={props.minuteStep}
                value={props.value ? moment(props.value, "HH:mm") : null}
            />
            { props.touched === 'true' && (props.error && <ValidationUI error={props.error}/>) }
        </div>
    )
}


TimePicker.propTypes = {
    showSecond: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default TimePicker
