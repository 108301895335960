import React from 'react'
import { connect } from 'react-redux'
import {Field, getFormValues} from 'redux-form'
import AsyncSelect from 'react-select/async'
import withQuery from 'with-query'
import {
    getFilterListsAreFetching,
    getClearable,
    getLanguage, getModule, getDataList, getGroupModel
} from '../../selectors/index'
import './FilterDropdownField.css'
import {FILTER_FORM} from './FilterForm'
import {colourStyles} from './SelectStyles'

class DropDown extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            value: null,
            options: []
        }
        this.handleNewOptions = this.handleNewOptions.bind(this)
        this.getOptionsList = this.getOptionsList.bind(this)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // here we verify if any filter value has changed
        if(prevProps.formValues) {
            const result =  this.props.filters.some(key => prevProps.formValues[key] !== this.props.formValues[key])
            if(result) {
                return true
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot){
            this.setState({value: null})
            this.props.input.onChange(null)
        }

    }

    handleNewOptions(options){
        this.setState({options})
    }
    getOptionsList(search){
        const { dataList, module, groupModel, language, filterDisplay, t, formValues } = this.props

        if (!search) {
            return Promise.resolve({ options: [] });
        }

        if(search.length > 1) {
            const params = {
                accessId: dataList.id,
                moduleId: module.id,
                groupModelId: groupModel ? groupModel.id : null,
                language,
                data: formValues,
                params: {search}
            }

            return fetch(withQuery(window.location.origin + '/api/' +dataList.url, params), {
                method: 'GET',
            })
                .then(response => response.json())
                .then(json => {
                    const options = json.map(o => ({label: t(o[filterDisplay]), value: o.id}))

                    this.setState({options})
                    return options
                })
        }
    }

    render(){
        const {input: { onChange }, t, width, clearable, isFetching, filterDisplay, fieldPlaceholder} = this.props
        const placeholder = t(fieldPlaceholder ||'search')
        const getWidthClass = width => {
            if(width) return `col-xs-12 col-sm-12 col-md-${width} col-lg-${width}`
            else return 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
        }

        return (
            <div className={`Select-Container ${getWidthClass(width)}`}>
                <AsyncSelect
                    className={'FilterDropdownField-Select'}
                    placeholder={placeholder}
                    noOptionsMessage={() => t('noOptions')}
                    styles={colourStyles}
                    isClearable={clearable}
                    isLoading={isFetching}
                    loadOptions={this.getOptionsList}
                    backspaceRemovesValue={true}
                    value={this.state.value}
                    onChange={option => {
                        this.setState({value: option})
                        onChange(option && option.value && { id: option.value, [filterDisplay]: option.label })
                    }}
                />
            </div>
        )
    }
}

const FilterField = (props) => {
    const {
        filter,
        isFetching,
        clearable,
        fetchFilterData,
        onChange,
        dataList,
        module,
        groupModel,
        language,
        t,
        formValues
    } = props
    return (
        <Field
            name={filter.path}
            filterId={filter.id}
            filterDisplay={filter.display}
            fieldPlaceholder={filter.placeholder}
            component={DropDown}
            width={filter.width}
            clearable={clearable}
            isFetching={isFetching}
            onChange={(event, value) => {
                // we need to call fetchDtData after reduxFormChange if filter.filter is not false
                event.preventDefault()
                onChange(filter.path, value)
                if(!!filter.autoFetch) {
                    fetchFilterData()
                }
            }}
            dataList={dataList}
            filters={filter.filters}
            module={module}
            groupModel={groupModel}
            language={language}
            t={t}
            formValues={formValues}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    formValues: getFormValues(FILTER_FORM)(state),
    dataList: getDataList(state, filter.dataList),
    module: getModule(state),
    language: getLanguage(state),
    groupModel: getGroupModel(state),
    isFetching: getFilterListsAreFetching(state),
    clearable : getClearable(state, filter.id)
})

export default connect(mapStateToProps)(FilterField)
