const _ = require('lodash')

export const entity = {
    name: 'HypothesisData',
    fields: [
        {
            type: 'ProjectConfiguration',
            link: { oppositeField: { link: { deleteType: 'cascade' } } }
        },
        'exercise',
        'value',
        'withProject',
        'DataType',
        'HypothesisModel',
        'HypothesisModelLine'
    ],
    indexes: [
        { key: { projectConfiguration: 1 } }
    ]
}

export const module_ = {
    kp: true,
    object: 'HypothesisData',
    tKey: 'mTitle_hypothesisData',
    defaultSortBy: 'exercise',
    defaultSortDirection: 'ASC',
    category: {
        path: 'bpStore',
        icon: 'briefcase'
    },
    viewMap: {
        dt: [
            'exercise',
            'value',
            'withProject',
            { path: 'dataType', display: 'tName' },
            { path: 'hypothesisModelLine', tKey: 'model' }
        ],
        form: [
            'exercise',
            'value',
            'withProject',
            { path: 'dataType', display: 'tName' },
            { path: 'hypothesisModelLine', tKey: 'model' }
        ]
    },
    filters: [
        {
            title: 'ProjectConfiguration',
            path: 'projectConfiguration',
            object: 'ProjectConfiguration',
            display: 'projectId',
            width: 12,
            client: true,
            clearable: false,
            fieldPath: ['status.id'],
            query: function(context) {
                const projectConfigurationId = _.get(
                    context.data,
                    'projectConfiguration.id'
                )

                return projectConfigurationId
                    ? {
                          projectConfiguration: new global.ObjectID(
                              projectConfigurationId
                          )
                      }
                    : { _id: false }
            }
        }
    ],
    removable: false,
    updatable: false,
    newable: false
}
