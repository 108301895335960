import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PrivateApp from '../PrivateApp'
import SpinningDots from '../../../../components/Loader/SpinningDots'
import NotFoundPage from '../../../../components/NotFoundPage'
import ErrorPage from '../../../../components/ErrorPage'

class PublicPage extends Component {
    componentDidMount() {
        this.props.fetchViewer()
    }

    componentDidUpdate(prevProps) {
        // if the viewer got invalidated, we must reload the viewer
        if (this.props.didInvalidateViewer && !prevProps.didInvalidateViewer) {
            this.props.fetchViewer()
        }
    }

    render() {
        const {
            viewerIsFetching,
            didInvalidateViewer,
            viewerError,
            viewerErrorStatus,
            isNotFoundPage,
            setNotFoundPage,
            retry,
            t,
            language
        } = this.props

        moment.locale(language);

        return viewerIsFetching || didInvalidateViewer
            ? (
                <div style={{position: 'absolute', height: '100%', width: '100%'}}>
                    <SpinningDots/>
                </div>
            )
            : viewerError
                ? <ErrorPage
                    message={viewerError}
                    status={viewerErrorStatus}
                    retry={retry}
                    t={t}
                />
                : isNotFoundPage
                    ? <NotFoundPage
                            setNotFoundPage={setNotFoundPage}
                            t={t}
                    />
                    : <PrivateApp t={t} />
    }
}

PublicPage.propTypes = {
    fetchViewer: PropTypes.func.isRequired,
    viewerIsFetching: PropTypes.bool.isRequired,
    viewerError: PropTypes.string,
    viewerErrorStatus: PropTypes.number,
    didInvalidateViewer: PropTypes.bool.isRequired,
    retry: PropTypes.func.isRequired,
    isNotFoundPage: PropTypes.bool.isRequired,
    setNotFoundPage: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default PublicPage
