const moment = require('moment')
const _ = require("lodash");

async function findData(context, callback) {


    const businessProjectCollectionName = global.app.I.BusinessProject.collectionName
    const typeOfOfferProjectCollectionName = global.app.I.TypeOfOffer.collectionName
    const rangeOfOfferProjectCollectionName = global.app.I.RangeOfOffer.collectionName
    const executedFollowUpCollection = global.app.I.ExecutedFollowUp.collection
    const years = [moment().year()-1, moment().year()]
    const executedFollowUpQuery = executedFollowUpCollection
        .aggregate([
            {
                $match: {year: {$in: years}}
            },
            {
                $lookup: {
                    from: businessProjectCollectionName,
                    localField: 'businessProject',
                    foreignField: '_id',
                    as: 'bp'
                }
            },
            {
                $lookup: {
                    from: typeOfOfferProjectCollectionName,
                    localField: 'bp.typeOfOffer',
                    foreignField: '_id',
                    as: 'typeOfOffer'
                }
            },
            {
                $lookup: {
                    from: rangeOfOfferProjectCollectionName,
                    localField: 'typeOfOffer.rangeOfOffer',
                    foreignField: '_id',
                    as: 'rangeOfOffer'
                }
            },
            {
                $unwind: '$rangeOfOffer'
            },
            {
                $group: {
                    _id: { zone: '$rangeOfOffer.code', year: '$year' },
                    rangeOfOffer: {$first: '$rangeOfOffer.code'},
                    year: {$first: '$year'},
                    turnover: { $sum: '$executedTurnOver' }
                }
            },
        ])
        .toArray()

    const [executedFollowUps] = await Promise.all([
        executedFollowUpQuery
    ])

    const dataByRangeOfOffer = _.groupBy(executedFollowUps, 'rangeOfOffer')
    const executedFollowUpsData = _.sortBy(Object.keys(dataByRangeOfOffer)).map(rangeOfOffer => {
        const firstYear = dataByRangeOfOffer[rangeOfOffer].find(o => o.year === years[0])
        const secondYear = dataByRangeOfOffer[rangeOfOffer].find(o => o.year === years[1])
        return {
            axis: rangeOfOffer,
            [years[0]]: firstYear ? firstYear.turnover : 0,
            [years[1]]: secondYear ? secondYear.turnover : 0
        }
    })

    const total = executedFollowUpsData.reduce((acc, o) => Object.assign(acc, {
        first: acc.first + o[years[0]],
        second: acc.second + o[years[1]],
    }), {first: 0, second: 0})


    return [
        {
            id: "turnoverPerProductLine",
            subTitle: {
                text: 'turnoverSubtitle',
                data: {
                    firstYear: years[0],
                    firstTotal: total.first,
                    secondYear: years[1],
                    secondTotal: total.second
                }
            },
            data: executedFollowUpsData
        }
    ]
}

export const entity = {
    name: 'TurnoverPerProductLine',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
