import React from 'react'
import { Field } from 'redux-form'
import './Heading.css'
import {translateName} from "../../../../utils/i18n";

// binding for redux-form
const Input = ({ input: {value}, data, style, color, language}) => {

    const translatedValue = data || translateName(value, language)

    return <div className="Heading" style={style}>{translatedValue}</div>
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const Heading = ({ field, language, groupModel }) => {
    const color = field.userGroupSelectedColor ? {color: groupModel.selectedColor}: {}
    const fieldStyle = field.style || {}
    return (
        <Field
            name={field.path}
            data={field.data}
            style={{...fieldStyle, ...color}}
            language={language}
            color={groupModel.selectedColor}
            component={Input}
        />
    )
}

export default Heading
