const { fieldPathJoinGetter } = require("../../utils/kp3Utils");

export const entity = {
    name: "Company",
    fields: [
        {path: "code", unique: true},
        {path: "name", unique: true},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"]
        })
    ]
}

export const module_ = {
    object: "Company",
    tKey: "mTitle_company",
    removable: false,
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            "code",
            "name"
        ],
        form: [
            {path: "code", required: true, editable: false},
            {path: "name", required: true},
        ]
    }
}
