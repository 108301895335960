const _ = require("lodash")
const async = require("async")
const Errors = require("../../utils/Errors").default
const { forceFunction, forceArray, propertyChangeComparator } = require("../../utils/functional")
import {translateName} from "../../../utils/i18n"

const errorIfResult = (message, callback) => (e, result) => {
    const getMessage = forceFunction(message);
    if (e) return callback(e);
    if (result) return callback(new Errors.ValidationError(getMessage(result)));
    callback();
};

export const entity = {
    name: "HabFunction",
    fields: [
        "Profile",
        {
            path: "name",
            fieldPath: ["profile.name"],
            f: function() {
                return this.profile && this.profile.name
            }
        },
        {
            path: "tName",
            fieldPath: ["profile.name"],
            $f: function(habFunction, context, callback) {
                const translate = context.translateName || translateName
                return callback(null, translate(habFunction.profile.name))
            }
        },
        {
            type: "OrganizationAxisJoin",
            link: "MTO"
        },

        // this getter is to avoid a join while listing on habilitation module
        {
            path: "categoryCode",
            lazy: true,
            f: function() {
                return this.organizationAxisJoin.toString()
            }
        }
    ],
    filters: [
        {
            name: "byProfiles",
            isDefault: false,
            query: context => {
                const profiles = _.get(context, 'clientContext.data.profiles') || []
                return {profile: {$in: profiles.map(profile => new global.ObjectID(profile.id))}}
            }
        }
    ],

    isLinkedToWorkflowConfig: function(habFunction, context, callback) {
        global.app.S.WorkflowConfig.get(
            {habFunction: new global.ObjectID(habFunction.id)},
            {ignoreNotFound: true, group: context.group},
            callback
        )
    },

    isLinkedToBlockField: (habFunction, context, callback) => global.app.S.HabFunction.get(
        habFunction.id,
        {fieldPath: global.app.S.HabFunction.deleteFieldPath, group: context.group},
        (e, o) => {
            if (e) return callback(e);

            const blockedField = _(global.app.S.HabFunction.fields)
                .filter(field => _.get(field, "link.deleteType") === "block")
                .find(field => forceArray(o[field.path]).length);

            return callback(null, blockedField);
        }
    ),

    validateSave: function(habFunction, oldHabFunction, context, callback) {
        const propertyChanged = propertyChangeComparator(habFunction, oldHabFunction);

        if (oldHabFunction && propertyChanged("organizationAxisJoin.id")) {

            async.series([
                callback => global.app.S.HabFunction.isLinkedToBlockField(
                    oldHabFunction,
                    context,
                    errorIfResult(
                        context.tc("cannotModifyFunctionWithHabilitations"),
                        callback
                    )
                ),
                callback => global.app.S.HabFunction.isLinkedToWorkflowConfig(
                    oldHabFunction,
                    context,
                    errorIfResult(
                        context.tc("cannotModifyFunctionUsedOnWorkFlowConf"),
                        callback
                    )
                )
            ], callback);

        } else {
            callback();
        }
    },

    ps: {
        delete: [
            {
                $$v: function (habFunction, callback) {
                    global.app.S.HabFunction.isLinkedToWorkflowConfig(
                        habFunction,
                        this.options.context,
                        errorIfResult(this.options.context.tc("cannotDeleteFunctionWithHabilitations"), callback)
                    )
                }
            }
        ]
    }
}

export const module_ = {
    object: "HabFunction",
    tKey: "mTitle_habFunction",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy: "profile",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            {path: 'profile', translateName: true},
            {path: "organizationAxisJoin", display: "tName", tKey: "perimeter"}
        ],
        form: [
            {path: 'profile', translateName: true},
            {path: "organizationAxisJoin", display: "tName", tKey: "perimeter", filters: ["usedInScope"]}
        ]
    }
}
