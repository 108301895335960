const { translateName } = require("../../../utils/i18n");
const _ = require("lodash");

const translate = (object, context) => {
    switch (object.joinedEntity) {
        case "FamilyAxis":
        case "OrganizationAxis":
            return translateName(object.name, _.get(context, "language.id"));
        case "ProductAxis":
        case "StoreAxis":
        case "EmployeeAxis":
            return context.tc && context.tc(object.tKey)
        default:
            return "Invalid joinedEntity";
    }
};

const joinParams = [
    {
        entityName: "FamilyAxis",
        childEntityName: "Family"
    },
    {
        entityName: "OrganizationAxis",
        childEntityName: "Organization"
    },
    {
        entityName: "ProductAxis",
        childEntityName: "Product"
    },
    {
        entityName: "StoreAxis",
        childEntityName: "Store"
    },
    {
        entityName: "EmployeeAxis",
        needStoreAxis: true
    },
];

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "ScoredElement",
    fields: [
        "code",
        "name",
        {
            path: "storeCode",
            f: function() {
                return global.app.S.StoreAxis.objects[0].code;
            }
        },
        {
            path: "needStoreAxis",
            f: function() {
                return joinParams.find(o => o.entityName === this.joinedEntity).needStoreAxis;
            }
        },
        {
            path: "childOfAxisEntityName",
            f: function() {
                return joinParams.find(o => o.entityName === this.joinedEntity).childEntityName;
            }
        },
        {
            path: "tName",
            fieldPath: ["name"],
            $f: (object, context, callback) => {
                callback(null, translate(object, context));
            }
        }
    ]
}, joinParams.map(o => o.entityName))
