import _ from "lodash";
import {changeFieldDefault, setFieldVisibility, updateChartParams} from "../../../../../apps/KpModule/actions";
import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {translateName} from "../../../../../utils/i18n";
import {basicContext} from "../../../../utils/contextUtils";
import {actionTypes as reduxFormActions} from "redux-form";
import React from "react";

function getPath (currentAttachment, alreadyFilledOrgs, context){
    if (currentAttachment){
        let path = ''
        let OrgToCheck = alreadyFilledOrgs.find(axis => axis.id === global.ObjectID(currentAttachment.id).toString())
        while (OrgToCheck.attachment){
            path = translateName(OrgToCheck.name, _.get(context, "language.id")) + ' / ' + path
            OrgToCheck = alreadyFilledOrgs.find(axis => axis.id === OrgToCheck.attachment.id)
        }
        return translateName(OrgToCheck.name, _.get(context, "language.id")) + ' / ' + path
    }
    else {
        return ''
    }
}

function getPath2 (object, language){
    let path = translateName(object.name, language)  // path initialization
    let nextOrgToCheck = object.attachment // initialization of next org to check
    while (nextOrgToCheck){
        path = translateName(nextOrgToCheck.name, language) + ' / ' + path
        nextOrgToCheck = nextOrgToCheck.attachment
    }
    return path
}

export const entity = {
    name: 'StructureElement',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true}
    ],
    fields: [
        {path: 'structure', type:'Structure', index: true},
        {path: 'structureAxis', type: 'StructureAxes'}, //dropdown
        {path: 'attachment', type: 'StructureElement', nullable: true}, //tags for BU
        {
            path: 'nameWithAxis',
            fieldPath: ['structureAxis.id'],
            $f: function (object, context, callback) {
                const language = _.get(context, "language.id")
                const fullName = translateName(object.structureAxis.name, language) + ' : ' + translateName(object.name, language)
                return callback(null, fullName)
            },
        },
        {
            path: 'nameWithStructureAndAxis',
            //fieldPath: ['structureAxis.id'],
            $f: function (object, context, callback) {
                const language = _.get(context, "language.id")
                const fullName = translateName(object.structure.name, language) + ' / ' + translateName(object.structureAxis.name, language) + ' : ' + translateName(object.name, language)
                return callback(null, fullName)
            },

        },
        {
            path: 'fullPath',
            fieldPath: ['attachment.id'],
            $f: function (object, context, callback) {
                const fullPath2 = getPath2(object, _.get(context, "language.id"))
                return callback(null, fullPath2)
            }
        },
    ],
    filters: [
        {
            name: 'byStructure',
            tKey: 'structure',
            isDefault: false,
            client: true,
            path: 'structure',
            object: 'Structure',
            display: "name",    //This fucking line is obligatory apparently
            translateName: true,
            clearable: false,
            autoFetch: true,
            query: function(context) {
                const structureID = _.get(context, 'data.structure.id')
                return structureID ? {"structure": {$eq: global.ObjectID(structureID)}} : {_id: null}
            }
        },
        {
            name: 'byStructureAxisClient',
            client: true,
            display: "name",
            isDefault: false,
            translateName: true,
            path: 'structureAxisClient',
            object: 'StructureAxes',
            clearable: false,
            filters: [
                {
                    name: 'byStructure',
                    isDefault: false,
                    display: "name",    //This fucking line is obligatory apparently
                    query: function(context) {
                        const structureID = _.get(context, 'data.structure.id')
                        return structureID ? {"structure": {$eq: global.ObjectID(structureID)}} : {_id: null}
                    }
                }
            ],
            query: function (context) {
                const structureAxis = _.get(context.data, 'structureAxisClient')
                return structureAxis ? {structureAxis: global.ObjectID(structureAxis.id)} : {}
            }
        },
        {
            name: "byStructureAxis",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                const structureAxis = _.get(context.data, 'structureAxis')
                const query = structureAxis ? {structureAxis: global.ObjectID(structureAxis.id)} : {_id: null}
                return callback(null, query)
            }
        },
        {
            name: 'eliminateSelectedAndIncoherentOptions',
            isDefault: false,
            query: function(context) {
                //const structures = _.get(context, 'data.structures') || []

                const axesPossibleCombinationIds = _.get(context, 'data.axesPossibleCombinationIds') || []
                const couldBeAttachedToTheseAxes = (axesPossibleCombinationIds || []).map(id => global.ObjectID(id))

                const selectedElements = _.get(context, 'data.selectedElements') || []
                const axesToEliminate = selectedElements.flatMap(option => {
                    //we could only choose one element from each axis, so the axes of the selected elements should be eliminated
                    return option.structureAxis.id
                })

                const finalAxes = couldBeAttachedToTheseAxes.filter(id => !axesToEliminate.includes(id.toString()))

                return {
                    $or: [
                        {"structureAxis": {$in: finalAxes}},
                        {_id: {$in: selectedElements.map(obj => global.ObjectID(obj.id))}},
                    ]
                }
            }
        },
        {
            name: "onlyFunctionAxisElements",
            isDefault: false,
            async: true,
            query: async function (context, callback) {
                const functionAxes = await global.app.SE.StructureAxes.find({
                    ...basicContext(context),
                    fieldPath: ['structure.id', 'hierarchyLevel'],
                    query: {
                        axisUtilities: '1'
                    }
                })
                return callback(null, {structureAxis: {$in: functionAxes.map(axis => global.ObjectID(axis.id))}})
            }
        },
    ]
}

export const module_ = {
    object: 'StructureElement',
    tKey: 'myStructuralObjects',
    category: {
        path: 'structure',
        icon: 'layers'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name', type: 'translatedText'},
            {
                path: 'structureAxis',
                display: 'name',
                tKey: 'axis',
                translateName: true
            },
        ],
        form: {
            fields: [
                {
                    path: 'structure',
                    translateName: true
                },
                {
                    path: 'structureAxis',
                    filters: ['axesToShowInStructureElement'],
                    tKey: 'axis',
                    translateName: true,
                    display: 'nameWithStructure',
                    fieldPath: ['id', 'nameWithStructure', 'hierarchyLevel', 'structure', 'structure.id', 'structure.structureAxes.id', 'structure.structureAxes.hierarchyLevel'],
                    editable: false,
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const attachmentField = module.viewMap.form.fields.find( field => field.path === 'attachment')
                            const structureField = module.viewMap.form.fields.find( field => field.path === 'structure')
                            const structure = newValue && newValue.structure
                            structureField.setValue(structure)


                            const upperStructureAxis = (structure?.structureAxes || []).find(str => {
                                const hasAnUpperAxis = !!newValue.hierarchyLevel &&  newValue.hierarchyLevel !== 0
                                return hasAnUpperAxis ?
                                    str.hierarchyLevel === newValue.hierarchyLevel - 1
                                    : false
                            })

                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-structureElement.StructureElement_attachment",
                                    store.getState,
                                    'form',
                                    {
                                        data:{
                                            structureAxis: upperStructureAxis
                                        }
                                    }
                                )
                            ).then((response) => {
                                const newStructuralElements = response.data || []
                                store.dispatch(setFieldVisibility(attachmentField.id, !!newStructuralElements.length))
                            })
                        }
                    }
                },
                {path: 'code'},
                {path: 'name', type: 'textarea', unique: true, placeholder: 'fr:: nom en français\nen:: name in english\n...'},

                {
                    path: 'attachment',
                    required: true,
                    translateName: true,
                    editable: false,
                    display: 'name',
                    filters:['byStructureAxis']
                }
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const structureField = module.viewMap.form.fields.find( field => field.path === 'structure')
                const attachmentField = module.viewMap.form.fields.find( field => field.path === 'attachment')
                store.dispatch(setFieldVisibility(structureField.id, false))
                store.dispatch(setFieldVisibility(attachmentField.id, false))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-structureElement.StructureElement_structureAxis",
                        store.getState,
                        'form',
                        {}
                    )
                )
            }
        }
    },
    /*
    actionSubscriptions: [
        {
            actionType: reduxFormActions.CHANGE,
            subscription: ({ store, action, module, form }) => {
                const state = store.getState()
                //if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'businessProject')) {

                //}
                console.log('test', 'test', action)

                if ((action.meta.form === FILTER_FORM) && (action.meta.field === 'businessProject')) {
                    const state = store.getState()
                    const businessProjectId = _.get(action, 'payload.id')
                    const defaultData = getEditDefaultData(state)

                    store.dispatch(setEditDefaultObject({
                        ...defaultData,
                        businessProject: businessProjectId ? { id: businessProjectId } : null
                    }))
                }


            }
        }
    ],

     */

    filters: [
        'byStructure',
        //'onlyFunctionAxisElements'
        //'byStructureAxisClient'
    ]
}
