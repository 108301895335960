const { byCompany, byProvider } = require('./filters')

export const dependencies = [
    {
        name: 'ProviderHistory',
        fields: [
            {path: 'date', type: 'date', unique: true, uniqueWith: 'provider', index: true},
            'Company',
            {path: 'perimeter', type: 'boolean'},
            {path: 'state', type: 'boolean'},
            'type',
            'HistoryDataType'
        ],
        filters:[byProvider]
    }
]

export const entity = {
    name: "Provider",
    facets: [
        {name:"codeName", codeUniqueWith: 'company'}
    ],
    fields: [
        'Company',
        {
            type: 'ProviderHistory',
            path: 'companies',
            link: {
                type: "OTM",
                onChild: true,
                oppositeField: {index: true}
            }
        }
    ],
    filters:[
        {
            ...byCompany,
            autoFetch: true
        }
    ]
}

export const modules = [
    {
        object: 'ProviderHistory',
        tKey: 'mTitle_provider',
        newable: false,
        removable: false,
        category: {
            path: 'referential',
            icon: 'server'
        },
        viewMap: {
            dt: [
                {
                    path: 'company',
                    display: 'fullName',
                    initiallyNotVisible: true
                },
                {path: 'provider.code', tKey: 'code', width: 100},
                {path: 'provider.name', tKey: 'name'},
                'perimeter',
                'state',
                'type',
                {path: 'date', width: 100},
                {path: 'historyDataType', tKey: 'source'}
            ]
        },
        filters:['byProvider']
    }
]
