const {fieldPathJoinGetter} = require('../../../utils/kp3Utils')

export const entity = {
    name: 'AnalyticalCode',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "name"],
            joinString: " - "
        })
    ]
}

export const module_ = {
    object: 'AnalyticalCode',
    tKey: 'mTitle_analyticalCode',
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    objectIdentifier: 'code',
    category: {
        path: 'accounting',
        icon: 'book'
    },
    viewMap: {
        dt: ['code', 'name'],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
        ]
    }
}
