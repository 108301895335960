const _ = require("lodash");
const dd = require("./../default-engine/engine").default;
const moduleFacets = require("./moduleFacets").default;

const dtu = require("./dataTypeUtils");

const entityTypeToFormType = function(field, entityField) {
    let type;

    switch(entityField.type) {
        case "string":
            type = entityField.long ? "textarea" : "text";
            break;

        case "integer":
        case "decimal":
        case "financial":
            type =  "number";
            break;
        case "date":
            type = "datePicker";
            break;
        case "dateRange":
            type = "dateRange";
            break;
        case "month":
            type = "monthPicker";
            break;
        case "monthRange":
            type = "monthPickerRange";
            break;

        case "time":
            type = "timePicker";
            break;

        case "boolean":
            type = "checkbox";
            break;

        default:
            if(entityField.link && entityField.link.entity.subType === "Comment") type = "comment2";
            else if(entityField.link && entityField.link.entity.subType === "File") type = "files2";
            else if(! entityField.list) type = "dropdownObject";
            else if(entityField.link.type === "OTM") type = "accordion";
            else type = "tags";

            // kludge field should be writable if setter
            // if(! field.writable && !_.includes(["projectSalesCostForecasts", "projectSalesCostActuals"], field.path)) {
            //     field.disabled = true;
            // }
    }

    return type;
};

const entityTypeToDTType = function(field, entityField, module) {
    let type;

    if ((_.get(entityField, "link.type") === "OTO") && (_.get(entityField, "link.entity.subType") === "File")) {
        type = "fileLink"
    } else if(_.get(entityField, "link.type") === 'MTO'){
        const isFieldInnerAccordion = module.type === "accordion"
        type = isFieldInnerAccordion ? "objectFromList" : "object";
    } else if (entityField.type === "boolean") {
        type = "boolean";
    } else if(['OTM', 'MTM'].includes(_.get(entityField, "link.type"))){
        type = "objectArray"
    } else if (entityField.type === "dateRange") {
        type = "dateRange";
    } else {
        type = "text";
    }

    return type;
};

const viewOrder = ["dt", "form", "chart", "impExp"];

const viewNameToType = {
    dt: "dt",
    form: "form",
    chart: "chart",
    impExp: "form"
};

const viewTypes = ["dt", "form", "chart"];

const viewMapExtractFields = function(viewMap) {
    return _.mapValues(viewMap, function(view) {
        return _.isPlainObject(view) ? view : {fields: view};
    });
};

const viewMapToViews = function(viewMap) {
    return _(viewMap)
        .map(function(view, key) {
            view.name = key;
            return view;
        })
        .sortBy(function(view) {
            const index = viewOrder.indexOf(view.name);
            return index === -1 ? Infinity : index;
        })
        .value();
};

const viewFieldsFromString = function(fields, view, views, module) {
    const entityNames = _.map(module.model_.entities, "name");

    return fields.map(function(field) {
        if(_.isString(field)) {
            const key = _.includes(entityNames, field) ? "object" : "path";
            field = {[key]: field};
        }
        return field;
    });
};
//
// const isFileOTO = function(file, field) {
//     return (field.entityField && field.entityField.link && field.entityField.link.type === "OTO" && field.entityField.link.entity.subType === "File");
// };
//
// const isDtView = function(width, field, fields, view) {
//     return view.type === 'dt'
// }

const checkField = ({ type, oneOfType, viewType }) => (param, field, fields, view) => {
    return (!type || (field.type === type))
        && (!oneOfType || (_.includes(oneOfType, field.type)))
        && (!viewType || (view.type === viewType))
}

export const accordionNorm = {name: "accordion"};

accordionNorm.fields = [
    {path: "entity", $if: "../object", $default: function(accordion) {
        return _.find(accordion.model_.entities, {name: accordion.object});
    }},

    {path: "viewMap", $if: function(viewMap, module) { return _.isEmpty(module.views); }, default: {}, $p: viewMapExtractFields},

    {path: "views", fields: [
        {path: "fields", default: [], $p: ["forceArray"]}
    ], $p: [
        {$if: function(views, module) { return _.isEmpty(module.views) && module.viewMap; }, $u: function(views, module) { return viewMapToViews(module.viewMap)}},
        "fields"
    ]},

    {path: "facets", default: [], $p: ["forceArray", "keyToObject", {type: "fusion", list: moduleFacets, id: "name"}]},

    {path: "facets.norm", $p: function(norm, facet, facets, module, modules, model) {
        dd.normalizeInternal(module, norm, {facet}, [modules, model]);
    }},

    {path: "filters", default: [], $p: [
        "keyToObject",
        {$f: function(filters, module) {
            filters.forEach(filter => {
                if(filter.name && module.entity) {
                    const entityFilter = _.find(module.entity.filters, {name: filter.name});
                    if(entityFilter) _.defaults(filter, entityFilter);
                    else console.warn(`Filter ${filter.name} not found`);
                }
            });
        }},
        "fields"
    ], fields: [
        {path: "path", $if: (path, filter) => filter.client, $p: ["required", "string"]},
        {
            path: "entity",
            $if: (entity, filter) => filter.object,
            $default: (filter, filters, module, modules, model) => _.find(model.entities, {name: filter.object})
        },
        {path: "type", default: "dropdownObject"},
        {path: "autoFetch", default: true},
        {path: "clearable", default: true},
        {path: "sortList", default: true},
        {path: "width", default: 4},
        {path: "access", $if: function(access, filter) {
            return filter.entity;
        }, $default: function(filter, filters, accordion) {
            const id = accordion.id + "_filter_" + filter.object;

            return {
                entity: filter.entity,
                id,
                fieldPath: ["id", filter.display, ...(filter.fieldPath || [])],
                filters: filter.filters
            };
        }},
        {path: "autoList", default: true}
    ]},

    {path: "filters.filters", default: [], $p: [
            "keyToObject",
            "fields"
        ],  fields: [
            {path: "path", $if: (path, filter) => filter.client, $p: ["required", "string"]},
            {
                path: "entity",
                $if: (entity, filter) => {
                    return filter.object
                },
                $default: (filter, filters, parentFilter, parentFilters, module, modules, model) => {
                    return _.find(model.entities, {name: filter.object})
                }
            },
            {path: "type", default: "dropdownObject"},
            {path: "autoFetch", default: true},
            {path: "clearable", default: true},
            {path: "sortList", default: true},
            {path: "width", default: 4},
            {path: "access",
                $if: function(access, filter) {
                    return filter.entity
                },
                $default: function(filter, filters, parentFilter) {
                    const moduleId = parentFilter.access.id.split('_')[0]
                    const id = moduleId + "_filter_" + filter.object;

                    return {
                        id,
                        entity: filter.entity,
                        fieldPath: ["id", filter.display, ...(filter.fieldPath || [])],
                    };
                }
            },
            {path: "autoList", default: true}
    ]},

    {path: "views", default: [], fields: [
        {path: "type", $default: function(view) { return viewNameToType[view.name]; }, $p: ["string", {type: "enum", list: viewTypes}]},
        {path: "id", $default: function(view, views, accordion) { return accordion.id + "-" + view.name; }},
        {path: "entity", $default: "../../entity"},

        {path: "fields", default: [], $p: ["forceArray", viewFieldsFromString]},

        {path: "fields", fields: [

            {path: "entityField", $default: function(field, fields, view) {
                return view.entity ?
                _.find(view.entity.fields, {path: field.path}) || _.find(view.entity.fields, {type: field.object}) :
                    null;
            }},

            {path: "object", $default: function(field) {
                if(field.entityField && field.entityField.link) return field.entityField.type;
            }},

            //todo: duplicate
            // {path: "url", $if: function(url, field, fields, view) { return field.object && field.entityField && field.entityField.link.type !== "OTM" && view.name !== "dt"; }, $default: function(field) { return "/rest3/" + field.entityField.link.entity.url; }},

            {path: "path", $default: function(field) {
                if(field.entityField) return field.entityField.path;
            }},

            // defines the key for the translations
            {path: "tKey", $default: "path"},

            {path: "writable", $default: function(field) { return field.entityField ? field.entityField.writable : true; }},

            {path: "editable", default: true},
            {path: "creationOnly", default: false},

            {path: "default", $default: function(field) {
                const entityField = field.entityField;

                if(entityField) {
                    let default_ = entityField.$default();

                    const defaultDefault = entityField.link ? dtu.defaultValues.object : dtu.defaultValues[entityField.link];
                    if(default_ === defaultDefault) default_ = dtu.clientDefaultValues[entityField.link];

                    return default_;
                }
            }},

            // {path: "type", $if: isFileOTO, default: "click"},

            {path: "tooltip", $if: checkField({ viewType: "dt" }), default: false},
            {path: "notVisible", $if: checkField({ viewType: "dt" }), default: false},
            {path: "validations", $if: checkField({ viewType: "form" }), default: []},

            // {path: "class_", $if: isFileOTO, default: "align-center"},

            // {path: "onClick", $if: isFileOTO, default: function(value) {
            //     window.open("/file/" + value.id(), "_newtab");
            // }},
            {
                path: "type",
                $if: checkField({ viewType: "form" }),
                $default: field => field.entityField ? entityTypeToFormType(field, field.entityField) : 'text'
            },
            {
                path: "type",
                $if: checkField({ viewType: "dt" }),
                $default: (field, fields, view, views, module) => field.entityField ? entityTypeToDTType(field, field.entityField, module) : 'text'
            },
            {path: "initiallyNotVisible", $if: checkField({ viewType: "dt" }), default: false},


            {
                path: "required",
                $if: checkField({ viewType: "form" }),
                $default: field => {
                    switch  (field.type) {
                        case "text" :
                        case "textarea":
                        case "integer":
                            return field.entityField && field.entityField.notEmpty
                        case "dropdownObject":
                        case "tags":
                        case "dualBoxList":
                        case 'date':
                            return field.entityField && !field.entityField.nullable
                        default:
                            return false
                    }

            }},
            {path: "editable", $if: checkField({ viewType: "form" }), default: true},
            {path: "hidden", $if: checkField({ viewType: "form" }), default: false},
            {path: "displayEmpty", $if: checkField({ type: "files2" }), default: true},
            {path: "flexGrow", $if: checkField({ viewType: "dt" }), $default: field => {
                switch (field.type) {
                    case "fileLink":
                    case "boolean":
                        return 0;
                    default:
                        return 1;
                }
            }},

            {path: "flexShrink", $if: checkField({ viewType: "dt" }), default: 1},
            {path: "minWidth", $if: checkField({ viewType: "dt" }), $default: field => {
                    switch (field.type) {
                        case "fileLink":
                        case "boolean":
                            return 20;
                        default:
                            return 0;
                    }
            }},

            {path: "width", $if: checkField({ viewType: "dt" }), $default: field => {
                switch (field.type) {
                    case "fileLink":
                    case "boolean":
                        return 100
                    default:
                        return 200;
                }
            }},
            {path: "dynamic", $if: checkField({ viewType: "dt" }), default: false},
            {
                path: "fields",
                $if: (fields, field) => _.includes(["dtObjects", "styledTable"], field.type),
                default: [],
                $p: [{type: "keyToObject", key: "path"}, "fields"],
                fields: [
                    {path: "entityField", $default: function(field, fields, parentField) {
                        const parentFields = _.get(parentField, 'entityField.link.entity.fields')
                        return parentFields ?
                            _.find(parentFields, {path: field.path}) || _.find(parentFields, {type: field.object}) :
                            null;
                    }},
                    {path: "object", $default: function(field) {
                        if(field.entityField && field.entityField.link) return field.entityField.type;
                    }},
                    {path: "type", $default: function(field) {
                        return field.entityField ? entityTypeToDTType(field, field.entityField, {}) : "text";
                    }},
                    {path: "tKey", $default: "path"},
                    {path: "display", $if: function(display, field) { return field.object; }, default: "name"},
                    {path: "width", $default: field => {
                            switch (field.type) {
                                case "fileLink":
                                case "boolean":
                                    return 30
                                default:
                                    return 200;
                            }
                        }},
                ]
            },
            {
                path: "fieldPath",
                $if: (fieldPath, field) => _.includes(["dtObjects", "styledTable"], field.type),
                $default: function(field) {
                    return _(field.fields)
                        .map("path")
                        .push("id")
                        .uniq()
                        .value();
                }
            },

            {
                path: "maxRows",
                $if: (maxRows, field) => {
                    return (_.includes(["dtObjects", "accordion", "styledTable"], field.type)
                        && _.isUndefined(maxRows || field.height || field.autoGrow)
                    )
                },
                default: 7
            },

            {path: 'newable', $if: checkField({ type: "accordion" }), default: true},

            {path: 'sortable', $if: checkField({ oneOfType: ["accordion", "dtObjects"] }), default: true},

            {path: "applyBoard", $if: checkField({ type: "dtObjects" }), default: false},
            {path: "parentHeader", $if: checkField({ type: "dtObjects" }), default: false},

            {path: "fieldPath", $if: checkField({ type: "files2" }), default: ["id","filename", "date", "user", "password"]},

            {path: "removable", $if: checkField({ type: "files2" }), default: true},

            {path: "fieldPath", $if: checkField({ type: "comment2" }), default: ["id", "text", "date", "user"]},

            {path: "display", $if: checkField({ type: "fileLink" }), default: "filename"},

            {path: "display", $if: (display, field) => field.object, default: "name"},

            {
                path: "fieldPath",
                $if: (fieldPath, field) => field.object && field.type !== 'accordion',
                $default: field => ['id'].concat(_.isString(field.display) ? [field.display] : [])
            },

            {path: "access", $if: function(access, field, fields, view) {
                const link = field.entityField ? field.entityField.link : null;
                // exception to the no access for OTM relation rule
                if (field.object === "DashboardElement") return view.type === "form" && link
                return view.type === "form" && link && ! _.includes(dtu.OT, link.type);
            }, $default: function(field, fields, view, views, accordion) {
                const link = field.entityField.link;
                const id = accordion.id + "." + link.id;

                return {
                    entity: link.entity,
                    id,
                    fieldPath: field.fieldPath,
                    filters: field.filters
                };
            }},

            {path: "accesses", $if: "../access", $default: function(field) { return [field.access]; }},

            {path: "searchInList", $if: checkField({ type: "dropdownObject" }), default: true},

            {path: "clearable", $if: checkField({ oneOfType: ["dropdownObject", "tags", "creatableTags"] }), default: true},

            {path: "autoList", $if: checkField({ oneOfType: ["dropdownObject", "accordion", "tags", "dualBoxList", "toggle"] }), default: true},
            {path: "sortList", $if: checkField({ oneOfType: ["dropdownObject", "accordion", "tags", "dualBoxList", "toggle"] }), default: true},

            {path: "disableSort", $if: checkField({
                    oneOfType: ["fileLink"],
                    viewType: "dt"
            }), default: true},

            {path: "disableExport", $if: checkField({
                oneOfType: ["fileLink"],
                viewType: "dt"
            }), default: true},

            {path: "translate", $if: checkField({ oneOfType: ["object", "dropdownObject", "tags", "dualBoxList", "objectFromList", "objectArray"] }), default: true}

        ], $p: [{$f: function(fields, view, views, accordion) {
            fields.forEach(field => {
                if(field.type === "accordion") {
                    field.id = accordion.id;
                    field.model_ = accordion.model_;
                    dd.normalize(field, accordionNorm);
                }
            });
        }}]},

        {path: "fieldPath", $default: function(view, views, accordion) {
            const precedingView = views[views.indexOf(view) -1];
            const dtViewForForm = view.name === "form" && precedingView && precedingView.name === "dt" && precedingView;

            return _(view.fields)
                .concat((accordion.filters || []).map(filter => ({path: filter.objectFieldPath}))) // filter fieldPath for object added to view fieldPath
                .compact()
                .map(field => (field.fieldPath ?
                    field.fieldPath.map(path => field.path + "." + path) :
                    field.path)
                )
                .concat(dtViewForForm && dtViewForForm.fieldPath)
                .flatten()
                .compact()
                .push("id")
                .uniq()
                .value();
        }},

        {path: "access", $if: function(access, view, views, module) { return module.main; }, $default: function(view, views, module) {
            return {
                main: true,
                id: view.id,
                entity: module.entity,
                fieldPath: view.fieldPath,
                filters: module.filters
            };
        }},

        {path: "deleteAccess", $if: function(access, view, views, module) { return module.main; }, $default: function(view, views, module) {//TODO: check if delete is activated
            return {
                main: true,
                id: view.id + "-delete",
                entity: module.entity,
                // fieldPath: // in server.js
                filters: module.filters
            };
        }},

        {path: "accesses", $default: function(view) {
            return _(view.fields).map("accesses").flatten().compact().value();
        }, $p: [
            {$u: function(accesses, view) {
                return _([accesses, view.access, view.deleteAccess]).flatten().compact().value();
            }},
            "fields"
        ], fields: [
            {path: "view", $default: "../../"}
        ]}
    ]},

    {path: 'viewMap', fields: [
        {path: 'dt', fields: [
            {path: 'fields', fields: [
                {
                    path: 'access',
                    $if: (access, field) => field.type === 'objectFromList',
                    $p: [
                        {$v: (access, field, fields, form, viewMap) => {
                            if(!viewMap.form.fields.find(f => f.path === field.path)) {
                                throw new Error(`field objectFromList ${field.path} should have an equivalent in the form view`)
                            }
                        }},
                        {$u: (access, field, fields, form, viewMap) => viewMap.form.fields.find(f => f.path === field.path).access}
                    ]
                }
            ]}
        ]}
    ]},

    {path: "fieldPath", $if: function(fieldPath, accordion) { return ! accordion.main; }, $default: function(accordion) {
        return _(accordion.views)
            .map("fieldPath")
            .flatten()
            .compact()
            .uniq()
            .value();
    }},

    {path: "accesses", default: [], fields: [
        {path: "entity", $p: {$u: function(entity) {
            const model = arguments[5];
            return _.isPlainObject(entity) ? entity : _.find(model.entities, {name: entity});
        }}}
    ], $p: [
        {$u: function(accesses, accordion) {
            return [
                ...accesses,
                ..._.flatMap(accordion.views, "accesses")
            ];
        }}

    ]}
];
