import async from "async";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";

const moment = require('moment')

async function automaticJob(context) {

    const revisedTableElements = await global.app.SE.RevisedTable.find({
        ...basicContext(context),
        fieldPath: ['objet', 'entity', 'imputationType'],
        query: {}
    })
    const revisedTableElementsIds = revisedTableElements.map(obj => global.ObjectID(obj.id))

    const projectsToUpdate = await global.app.SE.Demand.find({
        ...basicContext(context),
        fieldPath: ['demandNumber', 'revisedTable', 'imputations'],
        query: {revisedTable: {$elemMatch: {$in: revisedTableElementsIds}}}
    })

    const analyticalSubAxes = await global.app.SE.AnalyticalSubAxis.find({
        ...basicContext(context),
        fieldPath: [],
        query: {code: {$in: projectsToUpdate.map(project => project.demandNumber)}}
    })

    const analyticalMeshes = await global.app.SE.AnalyticalMesh.find({
        ...basicContext(context),
        fieldPath: ['code'],
        query: {
            attachment: {$elemMatch: {$in: analyticalSubAxes.map(obj => global.ObjectID(obj.id))}}
        }
    })

    const budgetsForThoseProjects = await global.app.SE.Budget.find({
        ...basicContext(context),
        fieldPath: ['mesh', 'analyticalMesh', 'estimatedBudgetFollowUp', 'budgetFollowUp'],
        query: {
            analyticalMesh: {$in: analyticalMeshes.map(obj => global.ObjectID(obj.id))}
        }
    })

    const currentFiscalYear = await global.app.SE.FiscalYear.find({
        ...basicContext(context),
        fieldPath: [],
        query: {
            "fiscalYearRange.0": {$lte: moment().startOf('day').toDate()}, "fiscalYearRange.1": {$gte: moment().startOf('day').toDate()}
        }
    })

    const fiscalYears = await global.app.SE.FiscalYear.find({
        ...basicContext(context),
        fieldPath: [],
        query: {
            $or: [
                {"fiscalYearRange.0": {$lte: moment().startOf('day').toDate()}, "fiscalYearRange.1": {$gte: moment().startOf('day').toDate()}},
                {"fiscalYearRange.0": {$lte: moment().startOf('day').subtract(1, 'days').toDate()}, "fiscalYearRange.1": {$gte: moment().startOf('day').subtract(1, 'days').toDate()}},
            ]
        }
    })

    const today = moment().toDate()
    const yesterday = moment().subtract(1, 'days').toDate()


    const meetingsPlannings = await global.app.SE.MeetingsPlanning.find({
        ...basicContext(context),
        fieldPath: [],
        query: {
            $or: [
                {"meetingsPlanningRange.0": {$lte: moment().startOf('day').toDate()}, "meetingsPlanningRange.1": {$gte: moment().startOf('day').toDate()}},
                {"meetingsPlanningRange.0": {$lte: moment().startOf('day').subtract(1, 'days').toDate()}, "meetingsPlanningRange.1": {$gte: moment().startOf('day').subtract(1, 'days').toDate()}},
            ]
        }
    })

    const engagementCategories = await global.app.SE.DemandCategory.find({
        ...basicContext(context),
        fieldPath: ['code'],
        query: {
            category: '2'
        }
    })


    const bulks = []

    const shouldUpdate = fiscalYears.length === 2 || meetingsPlannings.length === 2
    if (shouldUpdate){
        for (const revisedLine of revisedTableElements) {
            const updates = {}
            const correspondentProject = projectsToUpdate.find( project => project.revisedTable.map(line => line.id.toString()).includes(revisedLine.id.toString()))
            const correspondentAnalyticalMesh = analyticalMeshes.find(mesh => mesh.code === revisedLine.objet)
            const correspondentBudget = budgetsForThoseProjects.find(budget => budget.mesh.id.toString() === revisedLine.entity.id.toString() && budget.analyticalMesh.id.toString() === correspondentAnalyticalMesh.id.toString())
            if (fiscalYears.length === 2){

                const demandsOfThisProject = await global.app.SE.Demand.find({
                    ...basicContext(context),
                    fieldPath: ['imputations'],
                    query: {
                        relatedProject: global.ObjectID(correspondentProject.id),
                        relatedDemand: null, //not complementary project
                        demandCategory: global.ObjectID(engagementCategories[0].id),
                        status: '6', //approved
                    }
                })
                const engagementsImputationsForThisProject = demandsOfThisProject.map(demand => demand.imputations).flat(1)
                const correspondentEngagementImputations = engagementsImputationsForThisProject.filter( imp => imp.budget.analyticalMesh.code === revisedLine.objet && imp.budget.mesh.id.toString() === revisedLine.entity.id.toString() && imp.imputationType.id.toString() === revisedLine.imputationType.id.toString())
                const engagementsMonthsAndAmounts = correspondentEngagementImputations.map(imp => imp.monthAndAmountList).flat(1)
                const desiredEngagementsMonthsAndAmounts = engagementsMonthsAndAmounts.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                const fiscalYearValidatedEngagementAmount = desiredEngagementsMonthsAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                const correspondentImputation = correspondentProject.imputations.find( imp => imp.objet === revisedLine.objet && imp.organizationalMesh.id.toString() === revisedLine.entity.id.toString() && imp.imputationType.id.toString() === revisedLine.imputationType.id.toString())
                const desiredMonthAndAmounts = correspondentImputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                updates["ongoingFiscalYearValidated"] =  fiscalYearValidatedAmount

                const budgetsForThisLine = correspondentBudget.budgetFollowUp.filter( obj => obj.type.id.toString() === revisedLine.imputationType.id.toString() )//&& obj.amount !== 0 )
                const budgetForCurrentFiscalYear = budgetsForThisLine.find(obj => moment().isBetween(obj.fiscalYear.fiscalYearRange[0], obj.fiscalYear.fiscalYearRange[1], 'day', '[]'))
                const yearlyBudget = !!budgetForCurrentFiscalYear ? budgetForCurrentFiscalYear.amount : 0
                updates["ongoingFiscalYearBudget"] = yearlyBudget
                updates[ongoingFiscalYearValidatedEngagement] = fiscalYearValidatedEngagementAmount
                //hna
                bulks.push({
                    updateOne: {
                        filter: {
                            _id: global.ObjectID(revisedLine.id),
                            "amountByRealizationType.budgetColumnType": "2"
                        },
                        update: {
                            $set: {"amountByRealizationType.$.amount": 0}
                        }
                    }
                })
                //updates["dynamicAmounts."+'ongoingFiscalYear'+_.upperFirst(type.code)] =  0
            }
            updates["ongoingFiscalYearRevised"] =  0

            const estimatedBudgetsForThisLine = correspondentBudget.estimatedBudgetFollowUp.filter( obj => obj.type.id.toString() === revisedLine.imputationType.id.toString() )//&& obj.amount !== 0 )

            // filter by fiscalYear
            const estimatedBudgetForCurrentMeetingPlanning = estimatedBudgetsForThisLine.filter(obj => moment().isBetween(obj.meetingsPlanning.meetingsPlanningRange[0], obj.meetingsPlanning.meetingsPlanningRange[1], 'day', '[]'))
            const globalEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '1')
            const yearlyEstimatedBudget = estimatedBudgetForCurrentMeetingPlanning.find( obj => obj.budgetColumnType.id === '2')
            const infosToBeUpdatedWithExist = !!globalEstimatedBudget && !!yearlyEstimatedBudget

            const estimatedAmount = !!globalEstimatedBudget? globalEstimatedBudget.amount : 0
            const ongoingEstimated = !!yearlyEstimatedBudget? yearlyEstimatedBudget.amount : 0
            updates["ongoingFiscalYearEstimated"] =  ongoingEstimated
            updates["estimated"] =  estimatedAmount



            //hna

            bulks.push({
                updateOne: {
                    filter: { _id: global.ObjectID(revisedLine.id) },
                    update: {
                        $set: updates
                    }
                }
            })
        }

        await global.app.SE.RevisedTable.collection.bulkWrite(bulks, {})

    }
}

async function applyAutomaticJob(context) {
    const message = "Setting Revised Table For New FiscalYear successful";

    if (context.autoJob) {
        console.log('Starting Setting Revised Table For New FiscalYear Job')

        // if the job is automatic we have to execute the job for all groups that have this model.

        const suiviEngagementGroupModels = await global.GroupModel.find({
            fieldPath: ["id", "group.id"],
            query: {model: "SE"}
        });

        const suiviEngagementGroups = suiviEngagementGroupModels.map(groupModel => groupModel.group);

        for (const group of suiviEngagementGroups) {
            await automaticJob({ ...context, group });
        }

    } else {
        console.log('Starting Manual Setting Revised Table For New FiscalYear Job')
        await automaticJob(context);
    }

    console.log(message);
    return { message };
}

export const job = {
    name: "settingRevisedTableForNewFiscalYear",
    title: "Setting Revised Table For New FiscalYear",
    cron: "1 0 * * *",
    execute: function(context, callback) {
        applyAutomaticJob(context)
            .then(result => callback(null, result))
            .catch(callback)
    }
}

async function applyPurge(context, collections) {

    const message = "Purge successful"

    await async.parallel(collections.map(col => callback => {
        global.db.collection(col).deleteMany({}, callback)
    }))
    return { message }

}

async function applyBackup(context, collections) {

    const message = "Backup successful"

    const lowercaseModelId = _.get(context, 'groupModel.model.id', '').toLowerCase()

    await async.parallel([
        ...collections.map(col => callback => {
            global.db.collection(col).find({}).toArray((e, elements) => {
                global.db.collection(`${col}_initial`).deleteMany({}, () => {
                    global.db.collection(`${col}_initial`).insertMany(elements, callback)
                })
            })

        }),
        callback => {
            global.db.collection('profile').find({groupModel: global.ObjectID(context.groupModel.id)}).toArray((e, elements) => {
                global.db.collection(`${lowercaseModelId}.profile_initial`).deleteMany({}, () => {
                    global.db.collection(`${lowercaseModelId}.profile_initial`).insertMany(elements, callback)
                })
            })
        },
        callback => {
            global.db.collection('user').find({
                "groups": {
                    $elemMatch: {
                        "groupModels": {
                            $elemMatch: {
                                "groupModel": global.ObjectID(context.groupModel.id),
                                "profiles": { $ne: [] }
                            }
                        }
                    }
                }
            }).toArray((e, elements) => {
                global.db.collection(`${lowercaseModelId}.user_initial`).deleteMany({}, () => {
                    global.db.collection(`${lowercaseModelId}.user_initial`).insertMany(elements, callback)
                })
            })
        }
    ])
    return { message }

}

async function applyReset(context, collections) {

    const message = "Reset successful"

    const lowercaseModelId = _.get(context, 'groupModel.model.id', '').toLowerCase()

    await async.parallel([
        ...collections.map(col => callback => {
            global.db.collection(`${col}_initial`).find({}).toArray((e, elements) => {
                global.db.collection(col).deleteMany({}, () => {
                    global.db.collection(col).insertMany(elements, callback)
                })
            })
        }),
        callback => {
            global.db.collection(`${lowercaseModelId}.profile_initial`).find({}).toArray((e, elements) => {
                global.db.collection('profile').find({}).toArray((e, profiles) => {
                    const filteredElements = elements.filter(element => !profiles.map(profile => profile._id.toString()).includes(element._id.toString()))
                    global.db.collection('profile').insertMany(filteredElements, callback)
                })
            })
        },
        callback => {
            global.db.collection(`${lowercaseModelId}.user_initial`).find({}).toArray((e, elements) => {
                global.db.collection('user').find({}).toArray((e, users) => {
                    const filteredElements = elements.filter(element => !users.map(user => user._id.toString()).includes(element._id.toString()))
                    global.db.collection('user').insertMany(filteredElements, callback)
                })
            })
        }
    ])
    return { message }
}

export const purgeProjectsAndDemandsJob = {
    name: "purgeProjectsAndDemands",
    title: "purge projects and demands",
    execute: function(context, callback) {
        const collections = [
            'se.demand', 'se.largeImputation', 'se.imputation', 'se.monthAndAmountList', 'se.indicatorKey',
            'se.revisedTable', 'se.revisedTableHistory', 'se.completeRevisedTableHistory'
        ]
        applyPurge(context, collections)
            .then(result => callback(null, result))
            .catch(callback)
    }
}

export const purgeAllJob = {
    name: "purgeAll",
    title: "purge all",
    execute: function(context, callback) {
        const collections = [
            'se.analyticalAxis','se.analyticalMesh','se.analyticalSubAxis','se.axisAndMesh','se.budget','se.budgetFollowUp',
            'se.commitment','se.completeRevisedTableHistory','se.currency','se.demand','se.demandCategory','se.demandNature',
            'se.demandType','se.estimatedBudgetFollowUp','se.fiscalYear','se.funding','se.habilitation','se.imputation',
            'se.imputationType','se.indicatorKey','se.indicatorKeyList','se.largeImputation','se.meetingsPlanning','se.minMaxAmount',
            'se.monthAndAmountList','se.organization','se.organizationAndMesh','se.organizationalAxis','se.organizationalMesh',
            'se.pointsOfAttention','se.pointsOfAttentionList','se.realizationType','se.revisedTable','se.revisedTableHistory',
            'se.role','se.workFlow','se.workFlowConfig'
        ]
        applyPurge(context, collections)
            .then(result => callback(null, result))
            .catch(callback)
    }
}

export const backupJob = {
    name: "backupData",
    title: "backup data",
    execute: function(context, callback) {
        const collections = [
            'se.analyticalAxis','se.analyticalMesh','se.analyticalSubAxis','se.axisAndMesh','se.budget','se.budgetFollowUp',
            'se.commitment','se.completeRevisedTableHistory','se.currency','se.demand','se.demandCategory','se.demandNature',
            'se.demandType','se.estimatedBudgetFollowUp','se.fiscalYear','se.funding','se.habilitation','se.imputation',
            'se.imputationType','se.indicatorKey','se.indicatorKeyList','se.largeImputation','se.meetingsPlanning','se.minMaxAmount',
            'se.monthAndAmountList','se.organization','se.organizationAndMesh','se.organizationalAxis','se.organizationalMesh',
            'se.pointsOfAttention','se.pointsOfAttentionList','se.realizationType','se.revisedTable','se.revisedTableHistory',
            'se.role','se.workFlow','se.workFlowConfig'
        ]
        applyBackup(context, collections)
            .then(result => callback(null, result))
            .catch(callback)
    }
}

export const resetJob = {
    name: "resetData",
    title: "reset data",
    execute: function(context, callback) {
        const collections = [
            'se.analyticalAxis','se.analyticalMesh','se.analyticalSubAxis','se.axisAndMesh','se.budget','se.budgetFollowUp',
            'se.commitment','se.completeRevisedTableHistory','se.currency','se.demand','se.demandCategory','se.demandNature',
            'se.demandType','se.estimatedBudgetFollowUp','se.fiscalYear','se.funding','se.habilitation','se.imputation',
            'se.imputationType','se.indicatorKey','se.indicatorKeyList','se.largeImputation','se.meetingsPlanning','se.minMaxAmount',
            'se.monthAndAmountList','se.organization','se.organizationAndMesh','se.organizationalAxis','se.organizationalMesh',
            'se.pointsOfAttention','se.pointsOfAttentionList','se.realizationType','se.revisedTable','se.revisedTableHistory',
            'se.role','se.workFlow','se.workFlowConfig'
        ]
        applyReset(context, collections)
            .then(result => callback(null, result))
            .catch(callback)
    }
}
