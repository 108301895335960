import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Fields } from 'redux-form'
import { I18n } from 'react-i18next'
import MonthRangePicker from '../../../../components/Calendar/MonthPickerRange'
import { changeRangeCalendar } from '../../actions'
import {
    getModule,
    getDateRangeEndOpened,
    getDateRangeStartOpened,
    getLanguage
} from '../../selectors'

// binding for redux-form
const VisualComponent = ({
    path,
    names,
    startOpened,
    endOpened,
    language,
    filter,
    changeRangeCalendar,
    fetchFilterData,
    fetchFilterFieldsLists,
    module,
    ...other
}) => {
    const startInput = _.get(other, names[0]).input
    const endInput = _.get(other, names[1]).input

    return (
        <I18n ns={[module.model, 'platform']}>
            {t => (
                <MonthRangePicker
                    path={path}
                    startDate={startInput.value}
                    endDate={endInput.value}
                    startOpened={startOpened}
                    endOpened={endOpened}
                    onChange={(index, date) => {
                        if (date) {
                            index === 0
                                ? startInput.onChange(date)
                                : endInput.onChange(date)
                        }
                    }}
                    onOpenChange={(calendar, opened) => {
                        changeRangeCalendar(filter.id, calendar, opened)

                        // if we closed the calendar, launch the query after value is updated in state
                        if (calendar === 'endOpened' && !opened)

                            if(filter.filter) {
                                fetchFilterFieldsLists()
                            }
                            if(!!filter.autoFetch) {
                                fetchFilterData()
                            }

                            setTimeout(fetchFilterData, 50)
                    }}
                    language={language}
                    startPlaceholder={t('pickDate')}
                    endPlaceholder={t('pickDate')}
                    t={t}
                />
            )}
        </I18n>
    )
}

const MonthRangePickerComponent = ({
    filter,
    startOpened,
    endOpened,
    module,
    language,
    changeRangeCalendar,
    fetchFilterFieldsLists,
    fetchFilterData
}) => {
    const fieldName = filter.path

    return (
        <Fields
            names={[`${fieldName}[0]`, `${fieldName}[1]`]}
            path={filter.tKey || filter.path}
            component={VisualComponent}
            startOpened={startOpened}
            endOpened={endOpened}
            language={language}
            filter={filter}
            changeRangeCalendar={changeRangeCalendar}
            fetchFilterData={fetchFilterData}
            fetchFilterFieldsLists={fetchFilterFieldsLists}
            module={module}
        />
    )
}

const mapStateToProps = (state, { filter }) => ({
    startOpened: getDateRangeStartOpened(state, filter.id),
    endOpened: getDateRangeEndOpened(state, filter.id),
    language: getLanguage(state),
    module: getModule
})

const mapDispatchToProps = dispatch => ({
    changeRangeCalendar: (filterId, index, opened) =>
        dispatch(changeRangeCalendar(filterId, index, opened))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    MonthRangePickerComponent
)
