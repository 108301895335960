import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Select } from '../../../../components/Form'
import { getListOptions } from '../../selectors/index'
import getFormValidations from './formValidations'
import {colourStyles} from '../Filter/SelectStyles'
import {translateName} from '../../../../utils/i18n'
import './DropDown.css'

// binding for redux-form
const DropDown = ({ input: { value, onChange, onBlur }, meta: { touched, error }, required, path, display, selectOptions, originalOptionsById, optionsById, disabled, editable, clearable, placeholder, t, selectedColor }) => {
    const translatedError = t(error)
    const finalValue = value && (optionsById[value.id] ? optionsById[value.id] : {value: value.id, label: value[display]})
    return (
        <Select
            path={path}
            className="Form-DropDownField"
            required={required}
            options={selectOptions}
            placeholder={t(placeholder || 'select')}
            multi={false}
            styles={colourStyles}
            selectedColor={selectedColor}
            onChange={option => {
                onChange(option && option.value && originalOptionsById[option.value])
            }}
            noOptionsMessage={() => t('noOptions')}
            value={finalValue}
            isClearable={clearable}
            isDisabled={disabled || !editable}
            touched={touched}
            error={translatedError}
            onBlur={() => onBlur(value)}
            t={t}
        />
    )
}

class DropdownObject extends React.Component {
    constructor(props){
        super(props)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const { field, options, t, objectMode, language, groupModel, editObjectValues } = this.props
        const originalOptionsById = options.reduce((acc, option) => {
            return {
                ...acc,
                [option.id]: option
            }
        }, {})
        const optionsById = options.reduce((acc, option) => {
            const display = _.get(option, field.display)
            return {
                ...acc,
                [option.id]: {
                    value: option.id,
                    label: field.translateName
                        ? translateName(display, language)
                        : field.translate
                            ? t(display)
                            : display
                }
            }},{})

        const selectOptions = options.map(option => optionsById[option.id])
        const validations = getFormValidations(field, t, [], editObjectValues, optionsById)
        return (
            <Field
                name={field.path}
                required={field.required}
                path={field.tKey || field.path}
                display={field.display}
                selectedColor={groupModel && groupModel.selectedColor}
                component={DropDown}
                selectOptions={selectOptions}
                originalOptionsById={originalOptionsById}
                optionsById={optionsById}
                disabled={field.disabled || !field.writable}
                validate={validations}
                editable={!field.editable ? objectMode === 'newObject' : field.editable  }
                clearable={field.clearable}
                placeholder={field.placeholder}
                t={t}
            />
        )
    }
}

const mapStateToProps = (state, { field, t }) => ({
    options: getListOptions(state, field.id, field.sortList, field.translate, t)
})

export default connect(mapStateToProps)(DropdownObject)
