const moment = require('moment')
const _ = require('lodash')
const {fieldPathJoinGetter} = require('../../utils/kp3Utils')

export const entity = {
    name: 'Supplier',
    fields: [
        {path: 'code', nullable: false, unique: true},
        'corporateName',
        {path: 'phone'},
        {path: 'mail'},
        'billingAddress',
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "r.supplierSeq",
                    formatResult: result => `${result}`
                }]
            }
        },
        {path: 'bank', encrypted: true},
        {path: 'accountOwner', encrypted: true},
        {path: 'iban', unique: true, encrypted: true},
        {path: 'bic', encrypted: true},
        {path: 'accountNumber', unique: true},
        fieldPathJoinGetter({
            path: "fullName",
            fieldPath: ["code", "corporateName"],
            joinString: " - "
        })
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "sequence"
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    beforeSave: function(newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType

        if(action === 'C') {
            newObject.accountNumber = `${401000000 + parseInt(newObject.sequence)}`
        }
        callback(null, newObject, oldObject)
    },
    afterSave: function (newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType
        const bankInfoUpdated = oldObject && ['bank', 'accountOwner', 'iban', 'bic'].some(path => newObject[path] !== oldObject[path])
        if(action === 'U' && bankInfoUpdated) {
            const newComment = {
                user: _.pick(context.user, ['id', 'name']),
                text: `${context.tc('actualized')}`,
                date: moment().format("YYYY-MM-DD HH:mm")
            }

            global.app.R.Invoice.collection.updateMany(
                {supplier: global.ObjectID(newObject.id), status: {$in: ['waiting', 'validated', 'actualized']}},
                {$set: { status: 'actualized'}, $push: {comments: newComment}},
                e => {
                    if(e) callback(e)
                    else {
                        global.app.R.BankFlow.collection.updateMany(
                            {supplierId: newObject.id, status: {$in: ['waiting', 'validated', 'actualized']} },
                            {$set: {
                                    status: 'actualized',
                                    IBAN: newObject.iban,
                                    BIC: newObject.bic,
                                    accountNumber: newObject.accountNumber
                                }},
                            e => callback(e)
                        )
                    }
                }
            )
        } else {
            callback()
        }
    }
}

export const module_ = {
    object: 'Supplier',
    tKey: 'mTitle_supplier',
    defaultSortBy: 'corporateName',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            'code', 'corporateName', 'phone', 'mail',
            {path: 'billingAddress', tKey: 'address', initiallyNotVisible: true}
        ],
        form: [
            {path: 'code', required: true},
            {path: 'corporateName', required: true},
            {path: 'phone'},
            {path: 'mail'},
            {path: 'billingAddress', tKey: 'address'},
            {path: 'bank', required: true},
            {path: 'accountOwner', required: true},
            {path: 'iban', required: true, noSpaces: true, maxLength34: true, bankData: true},
            {path: 'bic', required: true, noSpaces: true, lengthEquals8or11: true, bankData: true},
            {path: 'accountNumber', disabled: true},
        ]
    }
}
