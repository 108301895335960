const { workflowParameters } = require("../workflow/workflow");
const _ = require("lodash");

const afterFormXSteps = workflowParameters.steps.filter(
    step => !_.includes(["formX", "noGo"], step.name)
);

const groupBpsByAxis = (bps, axisId) => _.groupBy(bps, axisId
    ? global.app.I.ProjectGroupAxis.byId(axisId).groupper
    : ''
);

const computeProjectProductivity = ({creationYear, axis1, axis2}, context, callback) => global.app.I.BusinessProject.find(
    {
        fieldPath: [axis1, axis2].filter(axis => axis).reduce(
            (fieldPath, axisId) => [...fieldPath, ...global.app.I.ProjectGroupAxis.byId(axisId).fieldPath],
            ["creationDate", "workflow.step", "buildOfferNumberOfDays"]
        ),
        filters: ["inYearCreationPeriod", "inSteps"],
        data: {creationYear, filterSteps: _.map(afterFormXSteps, "name") },
        group: context.group,
        user: context.user
    },
    (e, bps) => {
        if (e) return callback(e);

        const enhancedBps = bps.map(
            bp => Object.assign(bp, {
                number: {[bp.creationDate.getFullYear()]: 1},
                daysScheduled: {[bp.creationDate.getFullYear()]: bp.buildOfferNumberOfDays}
            })
        );

        const sumYearValues = yearValuesArray => yearValuesArray.reduce(
            (sumYearValues, yearValues) => _.range(creationYear -2, creationYear +1).reduce(
                (acc, year) => Object.assign(
                    {[year]: (sumYearValues[year] || 0) + (yearValues[year] || 0)},
                    acc
                ), {}
            ),
            {}
        );

        const calculateRate = (nominator, denominator) => {
            return denominator !== 0
                ? nominator / denominator
                : 0
        }

        const productivityResults = _.flatMap(groupBpsByAxis(enhancedBps, axis1), (groupBps, element1) =>
            _.flatMap(groupBpsByAxis(groupBps, axis2), (groupBps, element2) => {
                const reducedNumber = sumYearValues(_.map(groupBps, "number"));
                const reducedDaysScheduled = sumYearValues(_.map(groupBps, "daysScheduled"));
                return [
                    {
                        id: new global.ObjectID().toString(),
                        element1,
                        element2,
                        value: "Nb dossiers",
                        "n-2": reducedNumber[creationYear -2],
                        "n-1": reducedNumber[creationYear -1],
                        "n": reducedNumber[creationYear],
                    },
                    {
                        id: new global.ObjectID().toString(),
                        element1,
                        element2,
                        value: "Nbj moy. / Dossier",
                        "n-2": calculateRate(reducedDaysScheduled[creationYear -2], reducedNumber[creationYear -2]),
                        "n-1": calculateRate(reducedDaysScheduled[creationYear -1], reducedNumber[creationYear -1]),
                        "n": calculateRate(reducedDaysScheduled[creationYear], reducedNumber[creationYear])
                    }
                ];
            })
        );

        callback(null, productivityResults);
    }
);

export const entities = [
    {
        name: "ProjectProductivity",
        fields: [
            "element1",
            "element2",
            "value",
            "n-2",
            "n-1",
            "n"

        ],
        db: {
            find: (context, callback) => {
                const axis1 = _.get(context, "data.axis1.id");
                const axis2 = _.get(context, "data.axis2.id");
                const creationYear = Number(_.get(context, "data.creationYear.id"));

                computeProjectProductivity({axis1, axis2, creationYear}, context, callback);
            }
        }
    }
]
export const projectProductivity = {
    object: "ProjectProductivity",
    tKey: "reportingMO",
    category: {
        path: "analysis",
        icon: 'sliders'
    },
    removable: false,
    viewMap: {
        dt: [
            {path: "element1", width: 500, translate: true},
            {path: "element2", width: 500, translate: true},
            {path: "value", tKey: "indicator", width: 200},
            {path: "n-2", width: 80},
            {path: "n-1", width: 80},
            {path: "n", width: 80}
        ]
    },
    filters: [
        {
            object: "ProjectGroupAxis",
            title: "Axe principal",
            placeholder: "Séléctionner l'axe principal",
            path: "axis1",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "ProjectGroupAxis",
            title: "Axe secondaire",
            placeholder: "Séléctionner l'axe secondaire",
            path: "axis2",
            client: true,
            display: "name",
            clearable: true,
            default: {id: null}
        },
        {
            object: "AnalysisYear",
            path: "creationYear",
            title: "Année",
            placeholder: "Année",
            client: true,
            display: "name",
            clearable: false
        }
    ]
}
