const _ = require('lodash')
const moment = require('moment')
const async = require("async")
const Errors = require('../../utils/Errors').default
const staticEntities = require("./staticEntities").default
const { fieldPathJoinGetter } = require("../../utils/kp3Utils")
const { decrypt } = require("../../utils/crypto")
const {setFieldListOptions} = require('../../../apps/KpModule/actions')
const { getListOption } = require('../../../apps/KpModule/selectors')
const {generateFetchFieldListAction} = require('../../../apps/KpModule/actions/api')

const businessActivityObjects = _.find(
    staticEntities, {name: "BusinessActivity"}
).objects;

export const entities = [
    {
        name: "Zone",
        facets: [{name: "codeName"}],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }],
        filters: [
            {
                name: "inUserTeam",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.I.Country.find(
                        {
                            user: context.user,
                            group: context.group,
                            fieldPath: ["zone", "zone.id"],
                            filters: ["inUserTeam"]
                        },
                        (e, countries) => {
                            const ids = countries.map(c => new global.ObjectID(c.zone.id))
                            callback(e, { _id: { $in: ids } });
                        }
                    )
                }
            }
        ]
    },

    {
        name: "Country",
        facets: ["codeName"],
        fields: [
            "Zone",
            "CountryType",
            "SecurityLevel",
            {
                path: "fullInformations",
                fieldPath: ["code", "name", "countryType.name", "securityLevel.name"],
                f: function() {
                    return `${this.name} / ${this.countryType.name} / ${this.securityLevel.name}`;
                }
            },
            {
                path: "nameAndSecurity",
                fieldPath: ["name", "securityLevel.name"],
                f: function() {
                    return `${this.name} / ${this.securityLevel.name}`;
                }
            }
        ],
        filters: [
            {
                name: "inUserTeamAndHasWorkflowConfig",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.I.TeamMember.collection.find({
                        user: new global.ObjectID(context.user.id),
                        group: new global.ObjectID(context.group.id)
                    }).toArray((e, userTeams) => {
                        const userTeamCountries = _.flatMap(userTeams, team => team.countrys)
                        callback(e, {_id: {$in: userTeamCountries}})
                    })
                }
            },
            {
                name: "inUserTeam",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.I.TeamMember.collection.find({
                        user: new global.ObjectID(context.user.id),
                        group: new global.ObjectID(context.group.id)
                    }).toArray(
                        (error, teams) => {
                            const ids = _.flatMap(
                                teams,
                                team => team.countrys
                            )

                            callback(error, { _id: { $in: ids } });
                        });
                }
            },
            {
                name: "inTeamMemberCountries",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    const teamMember = context.data && context.data.teamMember

                    if(teamMember) {
                        global.app.I.TeamMember.collection.findOne({
                            _id: new global.ObjectID(teamMember.id),
                            group: new global.ObjectID(context.group.id)
                        }, (error, teamMember) => {
                            const ids = teamMember.countrys
                            callback(error, { _id: { $in: ids } });
                        })

                    } else {
                        callback(null, {_id: null})
                    }
                }
            }
        ],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }]
    },

    {
        name: "RangeOfOffer",
        facets: [{name: "codeName"}],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }]
    },

    {
        name: "TypeOfOffer",
        facets: ["codeName"],
        fields: [
            "RangeOfOffer",
            {path: "priority", type: "boolean"},
            {
                path: "nameWithRange",
                fieldPath: ["code", "name", "priority", "rangeOfOffer.code"],
                f: function() {
                    return `${this.name} / ${this.rangeOfOffer.code}`;
                }
            }
        ],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }]
    },

    {
        name: "Customer",
        facets: ["codeName"],
        fields: [
            "CustomerRelation",
            {
                path: "codeNameRelation",
                fieldPath: ["code", "name", "customerRelation.name"],
                f: function() {
                    return `${this.code} - ${this.name} / ${this.customerRelation.name}`;
                }
            }
        ],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }]
    },

    {
        name: "Provider",
        facets: ["codeName"],
        fields: [
            {type: "ProviderRelation", link: "MTM"},
            'ProviderOrigin'
        ],
        filters: [
            {
                name: "subcontractors",
                isDefault: false,
                query: () => ({providerRelations: "subcontractor"})
            },
            {
                name: "competitors",
                isDefault: false,
                query: () => ({providerRelations: "competitor"})
            },
            {
                name: "partners",
                isDefault: false,
                query: () => ({providerRelations: "partner"})
            }
        ],
        sorters: [{
            name: "byCode",
            query: () => ({code: 1})
        }]
    },

    {
        name: "TeamMemberProfile",
        fields: [
            {path: "name", unique: true, nullable: false},
            "abreviation",
            {type: "ProfileType", nullable: false},
            {type: "Provider", nullable: true},
            {path: "cost", type: "integer", default: 0},
            {path: "price", type: "integer", default: 0},
            fieldPathJoinGetter({
                path: "nameAndProvider",
                fieldPath: ["name", "provider.name"]
            }),
            fieldPathJoinGetter({
                path: "fullName",
                fieldPath: ["abreviation", "name"],
                joinString: " - "
            }),
            {
                path: "nameAndPriceAndCost",
                fieldPath: ["name", "cost", "price"],
                f: function() {
                    return `${this.name} (PV : ${this.price}€ - PA : ${this.cost}€)`;
                }
            }
        ],
        filters: [
            {
                name: "edfin",
                async: true,
                isDefault: false,
                query: (context, callback) => {
                    global.app.I.Provider.find(
                        {
                            group: context.group,
                            filters: ["edfin"]
                        },
                        (e, providers) => callback(null, {
                            provider: {$in: providers.map(global.app.I.Provider.db.objectToRefId)}
                        })
                    )
                }
            },
            {
                name: "byPovider",
                query: context => {
                    const providerId = _.get(context , "data.provider.id");

                    if (!providerId) return {};
                    return {provider: new global.ObjectID(providerId)};
                }
            }
        ],
        sorters: [{
            name: "byName",
            query: () => ({name: 1})
        }]
    },

    {
        name: "TeamMember",
        fields: [
            {type: "User", index: true},
            {path: "email", unique: true, nullable: false, encrypted: true},
            "firstName",
            "lastName",
            "TeamMemberProfile",
            {type: "Country", link: "MTM"},
            {path: "willReceiveMails", type: "boolean", default: false},
            {type: "TeamReferent", nullable: true},
            {path: "projectManager", type: "boolean", default: false},
            {path: "projectMonitor", type: "boolean", default: false},
            fieldPathJoinGetter({
                path: "fullName",
                fieldPath: ["firstName", "lastName"],
                joinString: " "
            }),
            {
                path: "numberOfCountrys",
                fieldPath: ["countrys.id"],
                f: function() {
                    return this.countrys ? this.countrys.length : 0;
                }
            },
            {
                path: "userName",
                fieldPath: ["user", "user.id", "user.name"],
                f: function() {
                    return this.user ? this.user.name : 'No User'
                }
            }
        ],
        filters: [
            {
                name: "byCountry",
                isDefault: false,
                query: context => {
                    const countryId = _.get(context , "data.country.id");
                    if (!countryId) return {_id: null};
                    return {
                        countrys: { $elemMatch: { $eq : global.ObjectID(countryId)} }
                    }
                }
            },
            {
                name: "byCountryAndwillReceiveMails",
                isDefault: false,
                query: context => {
                    const countryId = _.get(context , "data.country.id");

                    if (!countryId) return {};
                    return {
                        countrys: new global.ObjectID(countryId),
                        "willReceiveMails": true
                    };
                }
            },
            {
                name: "technicalReferent",
                isDefault: false,
                query: () => ({teamReferent: "technicalReferent"})
            },
            {
                name: "projectMonitor",
                isDefault: false,
                query: () => ({projectMonitor: true})
            },
            {
                name: "commercialReferent",
                isDefault: false,
                query: () => ({teamReferent: "commercialReferent"})
            },
            {
                name: "byProfile",
                isDefault: false,
                query: context => {
                    const ProfileId = _.get(context , "data.teamMemberProfile.id");
                    if (!ProfileId) return {};
                    return {
                        teamMemberProfile: { $eq : global.ObjectID(ProfileId)}
                    }
                }
            },
            {
                name: "notTeamMember",
                isDefault: false,
                query: context => {
                    const ProfileId = _.get(context , "data.teamMember.id");
                    if (!ProfileId) return {};
                    return {
                        _id: { $ne : global.ObjectID(ProfileId)}
                    }
                }
            },
            {
                name: "isActive",
                isDefault: false,
                fieldPath: ["user.active"],
                match: object => _.get(object, "user.active")
            }

        ],
        sorters: [{
            name: "byEmail",
            query: () => ({email: 1})
        }],
        afterSave: async (newObject, oldObject, context, callback) => {
            if(newObject.projectMonitor || !oldObject?.projectMonitor) {
                return callback()
            }

            try {
                await global.app.I.BusinessProject.collection.updateMany(
                    {monitoringTeamMembers: {$elemMatch: {$eq: global.ObjectID(newObject.id)}}},
                    { $pull: {monitoringTeamMembers: global.ObjectID(newObject.id) } }
                )
            } catch (e) {
                console.log('error updating business projects projectMonitor', e)
            } finally {
                callback()
            }
        }
    },

    // this is used in the getter of ReferentialBusiness
    {
        name: "ReferentialBusinessActivity",
        type: "mongoInternal",
        fields: [
            {path: "id", type: "string"},
            "name",
            "codeBis",
            {path: "active", type: "boolean"}
        ]
    },
    {
        name: "BusinessActivityConfig",
        type: "mongoInternal",
        fields: [
            "BusinessActivity",
            "codeBis",
            {path: "active", type: "boolean"}
        ]
    },
    {
        name: "ReferentialBusiness",
        fields: [
            {type: "BusinessProject", nullable: true},
            {path: "code", unique: true, nullable: false},
            "name",
            "Country",
            {type: "BusinessActivityConfig", link: "OTM"},
            {
                path: "activities",
                type: "ReferentialBusinessActivity",
                link: {
                    type: "OTM",
                    external: false,
                    updateType: null
                },
                fieldPath: [
                    "businessActivityConfigs.id", "businessActivityConfigs.codeBis", "businessActivityConfigs.active",
                    "businessActivityConfigs.businessActivity.id", "businessActivityConfigs.businessActivity.name"
                ],
                $f: function (referentialBusiness, context, callback) {
                    // Getter : BusinessActivityConfig -> ReferentialBusinessActivity

                    const clientConfigs = context.clientObject && context.clientObject.activities

                    callback(null, businessActivityObjects.map(
                        businessActivity => {
                            const previous = referentialBusiness.businessActivityConfigs.find(
                                o => o.businessActivity && o.businessActivity.id === businessActivity.id
                            );

                            const clientConfig = clientConfigs && clientConfigs.find(
                                activity => activity.id === businessActivity.id
                            )

                            return {
                                codeBis: "*",
                                active: false,
                                ...previous,
                                ...businessActivity,
                                ...clientConfig
                            };
                        }
                    ));
                },

                $s: function(activities, referentialBusiness, context, callback) {
                    // Setter : ReferentialBusinessActivity -> BusinessActivityConfig

                    referentialBusiness.businessActivityConfigs = activities.map(
                        activity => {
                            const previous = referentialBusiness.businessActivityConfigs.find(
                                o => o.businessActivity && o.businessActivity.id === activity.id
                            );

                            return {
                                ...previous,
                                businessActivity: {id: activity.id},
                                codeBis: activity.codeBis,
                                active: activity.active
                            }
                        }
                    );

                    callback();
                }
            }
        ],
        filters: [
            {
                name: "isUserInCountryScope",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.I.Country.find(
                        {
                            user: context.user,
                            group: context.group,
                            filters: ["inUserTeam"]
                        },
                        (e, countrys) => callback(null, {
                            country: {$in: countrys.map(global.app.I.Country.db.objectToRefId)}
                        })
                    )
                }
            },
            {
                name: "byZone",
                title: "Zone",
                path: "zone",
                object: "Zone",
                display: "name",
                objectFieldPath: ["businessProject.country.zone.id"],
                isDefault: false,
                default: {id: null},
                client: true,
                match: function (object, context) {
                    const zoneId = _.get(context.data, "zone.id");

                    return zoneId
                        ? _.get(object, "businessProject.country.zone.id") === zoneId
                        : true;
                }
            },
            {
                name: "byWorkflowStep",
                path: "workflowStep",
                object: "WorkflowStep",
                type: "tags",
                display: "name",
                objectFieldPath: ["businessProject.workflow.step"],
                default: [{id: 'formX'}, {id: 'buildOffer'}, {id: 'submitOffer'}, {id: 'realisation'}],
                isDefault: false,
                timer: 3,
                client: true,
                match: function (object, context) {
                    const steps = _.get(context, "data.workflowStep")

                    return steps
                        ? steps.map(step => step.id).includes(_.get(object, "businessProject.workflow.step"))
                        : false;
                }
            }
        ],
        ps: {
            object: [{
                $v: function (refBusiness) {
                    if( refBusiness.businessProject && refBusiness.businessProject.country.id !== refBusiness.country.id) {
                        return new Errors.ValidationError("The country must be the same as the project's country");
                    }
                }
            }]
        },
        afterSave: function (object, oldObject, context, callback) {
            if(object.businessProject){
                global.db.collection("i.businessProject").updateOne(
                    {_id: new global.ObjectID(object.businessProject.id)},
                    {$set: {code: object.code, name: object.name}},
                    callback
                );
            }
            else callback();
        }
    },
    {
        name: "UserDelegation",
        fields: [
            {path: "oldTeamMember", type: "TeamMember"},
            {path: "newTeamMember", type: "TeamMember"},
            {type: "Country", link: "MTM", nullable: true},
            {path: "date", nullable: true},
            {
                path: "numberOfCountrys",
                fieldPath: ["countrys.id"],
                f: function() {
                    return this.countrys ? this.countrys.length : 0;
                }
            }
        ],
        validateSave: function(object, oldObject, context, callback) {
            async.parallel({
                oldTeamMember: callback => global.app.I.TeamMember.collection.findOne({_id: new global.ObjectID(object.oldTeamMember.id)}, callback),
                newTeamMember: callback => global.app.I.TeamMember.collection.findOne({_id: new global.ObjectID(object.newTeamMember.id)}, callback)
            }, (e, results) => {
                if (e) return callback(e);

                const {oldTeamMember, newTeamMember} = results
                const oldFunction = oldTeamMember.teamMemberProfile.toString()
                const newFunction = newTeamMember.teamMemberProfile.toString()

                if (oldFunction !== newFunction) {
                    return callback(new Errors.ValidationError('newTeamMemberMustHaveSameFunction'))
                } else {
                    callback(null, object, oldObject)
                }
            })
        },
        beforeSave: function (object, oldObject, context, callback) {
            object.date = moment().format("YYYY-MM-DD HH:mm")
            callback(null, object, oldObject)
        },
        afterSave: function (object, oldObject, context, callback) {

            async.parallel({
                oldTeamMember: callback => global.app.I.TeamMember.collection.findOne({_id: new global.ObjectID(object.oldTeamMember.id)}, callback),
                newTeamMember: callback => global.app.I.TeamMember.collection.findOne({_id: new global.ObjectID(object.newTeamMember.id)}, callback)
            }, (e, results) => {
                if (e) return callback(e);

                const {oldTeamMember, newTeamMember} = results
                if(oldTeamMember && newTeamMember && oldTeamMember.user && newTeamMember.user) {
                    const queryContry = object.countrys && object.countrys.length
                        ? {country: {$in: object.countrys.map(o => new global.ObjectID(o.id))}}
                        : {}
                    const oldUser = new global.ObjectID(object.oldTeamMember.user.id)
                    const newUser = new global.ObjectID(object.newTeamMember.user.id)
                    const oldTeamMemberId = new global.ObjectID(object.oldTeamMember.id)
                    const newTeamMemberId = new global.ObjectID(object.newTeamMember.id)

                    async.series([
                        callback => global.db.collection("i.statusUser").updateMany(
                            {user: oldUser, ...queryContry},
                            {$set: {user: newUser, teamMember: newTeamMemberId}},
                            {}, callback),
                        callback => global.db.collection("i.staticWorkflow").updateMany(
                            {teamMember: oldTeamMemberId, ...queryContry},
                            {$set: {teamMember: newTeamMemberId}},
                            {}, callback)
                    ], callback);

                    const newTeamMemberCountries = newTeamMember.countrys.map(countryId => countryId.toString())
                    object.countrys.forEach(countryObject => {
                        if(newTeamMemberCountries.indexOf(countryObject.id) === -1) {
                            newTeamMemberCountries.push(countryObject.id)
                        }
                    })

                    global.app.I.TeamMember.collection.updateOne(
                        {_id: new global.ObjectID(object.newTeamMember.id)},
                        { $set: { countrys: newTeamMemberCountries.map(id => new global.ObjectID(id)) }}

                    )
                } else {
                    callback(new Errors.ValidationError("teamMemberNotFilledInOrDoesNotReferenceAUser"))
                }
            })

        }
    }
]

export const userDelegation = {
    object: "UserDelegation",
    removable: false,
    category: {
        path: "configuration",
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path:"oldTeamMember", display: "userName"},
            {path:"newTeamMember", display: "userName"},
            {path: "numberOfCountrys", tKey: "country_plural", width: 100},
            "date"
        ],
        form: {
            fields: [
                {
                    path:"oldTeamMember",
                    display: "userName",
                    editable: false,
                    fieldPath: ["id", "userName", "teamMemberProfile"],
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {

                            if (newValue && newValue.id) {
                                const countryQuery = {teamMember: {id: newValue.id}}
                                const teamMembersQuery = {
                                    teamMember: {id: newValue.id},
                                    teamMemberProfile: {id: newValue.teamMemberProfile && newValue.teamMemberProfile.id}
                                }
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-I-userDelegation.UserDelegation_countrys",
                                        store.getState,
                                        'form',
                                        {data: countryQuery}
                                    )
                                )

                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-I-userDelegation.UserDelegation_newTeamMember",
                                        store.getState,
                                        'form',
                                        {data: teamMembersQuery}
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions("e_countrys", []))
                                store.dispatch(setFieldListOptions("e_newTeamMember", []))
                            }
                        }
                    }
                },
                {path:"newTeamMember", filters:["byProfile", "notTeamMember"], display: "userName", editable: false},
                {path: "countrys", filters: ["inTeamMemberCountries"], tKey: "country_plural", display: "fullName", editable: false}
            ]
        }
    }
}

export const zone = {
    object: "Zone",
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 800}
        ],
        form: [
            "code",
            "name"
        ]
    }
}

export const country = {
    object: "Country",
    tKey: "country_plural",
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 500},
            {path: "zone", width: 500},
            {path: "countryType", tKey: "type", width: 300},
            {path: "securityLevel", tKey: "risk", width: 120}
        ],
        form: [
            "code",
            "name",
            {path: "zone", display: "fullName"},
            {path: "countryType", tKey: "type"},
            {path: "securityLevel", tKey: "risk"}
        ]
    }
}

export const rangeOfOffer = {
    object: "RangeOfOffer",
    tKey: "rangeOfOffer_plural",
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 800}
        ],
        form: [
            "code",
            "name"
        ]
    }
}

export const typeOfOffer = {
    object: "TypeOfOffer",
    tKey: "typeOfOffer_plural",
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 600},
            {path: "rangeOfOffer", width: 300},
            "priority"
        ],
        form: [
            "code",
            "name",
            {path: "rangeOfOffer", display: "fullName"},
            "priority"
        ]
    }
}

export const customer = {
    object: "Customer",
    tKey: "customer",
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 600},
            {path: "customerRelation", tKey: "connection", width: 300}
        ],
        form: [
            "code",
            "name",
            {path: "customerRelation", tKey: "connection", clearable: false}
        ]
    }
}

export const provider = {
    object: "Provider",
    defaultSortBy: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: "referential",
        icon: 'server'
    },
    viewMap: {
        dt: [
            {path: "code", width: 100},
            {path: "name", width: 600},
            {path: "providerRelations", tKey: "connection", width: 300},
            {path: 'providerOrigin', tKey: 'origin', initiallyNotVisible: true}
        ],
        form: [
            "code",
            "name",
            {path: "providerRelations", tKey: "connection", clearable: false},
            {path: 'providerOrigin', tKey: 'origin'}
        ]
    }
}

export const teamMemberProfile = {
    object: "TeamMemberProfile",
    tKey: "function",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: "name", width: 700},
            {path: "abreviation", width: 150},
            /*
            {path: "provider", width: 300},
            {path: "profileType", width: 300},
            {path: "cost", width: 150},
            {path: "price", width: 150}
             */
        ],
        form: [
            {path: "name", required: true},
            {path: "abreviation", required: true},
            /*
            {path: "provider"},
            {path: "profileType", required: true},
            "cost",
            "price"
             */
        ]
    }
}

export const teamMember = {
    object: "TeamMember",
    tKey: "team",
    category: {
        path: "configuration",
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: "email", width: 600},
            {path: "firstName", width: 200},
            {path: "lastName", width: 300},
            {path: "teamMemberProfile", tKey: "function", display: "fullName", width: 400},
            {path: "numberOfCountrys", tKey: "country_plural", width: 100},
            {path: "willReceiveMails"}
        ],
        form: {
            fields: [
                {
                    path: "user", filters: ["isInGroupModel"], editable: false, required: true,
                    fieldPath: ["id", "mail", "firstname", "lastname", "name"],
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            if (! formInitialize && newValue && newValue.id) {
                                const state = store.getState()

                                const user = getListOption(state, 'e_user', newValue.id)
                                const paths = [
                                    {fieldPath: 'email', userPath: 'mail', encrypted: true},
                                    {fieldPath: 'firstName', userPath: 'firstname'},
                                    {fieldPath: 'lastName', userPath: 'lastname'}
                                ]

                                paths.forEach(
                                    ({ fieldPath, userPath, encrypted }) => {
                                        const field = module.viewMap.form.fields.find(
                                            field => field.path === fieldPath
                                        )

                                        const value = encrypted
                                            ? decrypt(user[userPath])
                                            : user[userPath]

                                        field.setValue(value)
                                    }
                                )
                            }
                        }
                    }
                },
                {path: "email", editable: false, required: true},
                {path: "firstName", editable: false, required: true},
                {path: "lastName", editable: false, required: true},
                {path: "teamMemberProfile", tKey: "function", display: "fullName", required: true},
                "teamReferent",
                "projectMonitor",
                "projectManager",
                {path: "countrys", tKey: "country_plural", display: "fullName", required: true},
                "willReceiveMails"
            ]
        }
    }
}

export const referentialBusiness = {
    object: "ReferentialBusiness",
    tKey: "affair",
    category: {
        path: "management",
        icon: 'trendingUp'
    },
    removable: false,
    newable: false,
    viewMap: {
        dt: [
            {path: "businessProject", tKey: "name", display: "fullName", width: 600},
            {path: "code", width: 100},
            {path: "name", tKey: "affaire", width: 600},
            {path: "country", width: 300},
            {path: "businessProject.status", tKey: "status", width: 200, initiallyNotVisible: true, translate: true}
        ],
        form: [
            {
                path: "businessProject",
                tKey: "name",
                display: "fullName",
                filters: [{
                    name: 'isUserInCountryScope',
                    query: () => ({})
                }],
                writable: false
            },
            {path: "code", required: true},
            {path: "name", tKey: "affaire", required: true},
            {path: "country", display: "fullName", writable: false},
            {path: "activities", tKey: "activity_plural", writable:true, type: "dtObjects", fields: [
                    {path: "name", tKey:"activityType", title: "activityType"},
                    {path: "codeBis", tKey:"codeBis", type: "editText", componentClass: "table-input"},
                    {path: "active", tKey:"active", type: "checkbox"}
                ], default: businessActivityObjects.map(
                    o => ({codeBis: "*", active: false, ...o})
                )}
        ]
    },
    filters: [
        "byZone",
        "byWorkflowStep",
        "isUserInCountryScope"
    ]
}
