import {setFieldVisibility} from "../../../../../apps/KpModule/actions";
import _ from "lodash";

export const module_ = {
    name: 'Study',
    tKey: 'Étude dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            'status'
        ],
        form: {
            fields: [
                {path: 'object', editable: false},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', editable: false},
                {path: 'associationUpdatedStatutes', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', editable: false},
                {path: 'associationStatusNotice', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', editable: false},
                {path: 'latestAssociationReport', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', editable: false},
                {path: 'certifiedAccountStatement', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', editable: false},
                {path: 'associationInformationUpToDate', editable: false},
                {path: 'shelterInformationUpToDate', editable: false},
                {path: 'shelterCapacityInformationUpToDate', editable: false},
                {path: 'landType', display: 'name2', editable: false, translate: true},
                {
                    path: 'needs',
                    tKey: 'Besoins',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount' },
                            { path: 'requestedAmount' }
                        ],
                        form: [
                            { path: 'natureOfHelp', editable: false},
                            { path: 'totalAmount', editable: false },
                            { path: 'requestedAmount', editable: false }
                        ]
                    }
                },
                {
                    path: 'currentLiberalities',
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            store.dispatch(setFieldVisibility('e_currentSuccessionsName', !!newValue))
                            store.dispatch(setFieldVisibility('e_estimatedAmount', !!newValue))
                        }
                    }
                },
                {path: 'currentSuccessionsName'},
                {path: 'estimatedAmount'},
                {path: 'files', tKey: 'Fichiers', editable: false},
                {path: 'comments', tKey: 'Commentaires'},
                {path: 'studyCommitteeComment', type: 'richTextEditor'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'submitterHasShelters', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({state, store}) => {
                const submitterHasShelters = _.get(state, 'edit.object.data.submitterHasShelters')

                store.dispatch(setFieldVisibility( 'e_shelterInformationUpToDate', submitterHasShelters))
                store.dispatch(setFieldVisibility( 'e_shelterCapacityInformationUpToDate', submitterHasShelters))
            }
        }
    },
    filters: [
        'userIsController',
        {
            name: 'byStatusForStudy',
            query: () => {
                return { status: 'ongoing' }
            }
        }
    ]
}
